import React, {useContext} from "react";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/styles";
import {lighten} from "@material-ui/core";
import SidebarThemeContext from "../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext";
import {useHistory} from "react-router-dom";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles(theme => ({
  list: {
    padding: "16px 16px",
    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      padding: "30px 20px"
    }
  },
  listItem: {
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.3s ease",

    ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
      width: 40,
      height: 40,
      padding: 3,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiListItemIcon-root": {
        marginTop: 0
      }
    },
    "&:not(:last-child)": {
      marginBottom: 16
    },

    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      color: lighten(theme.palette.common.black, 0.5)
    },
    "& .MuiListItemText-root": {
      marginLeft: 20,

      ".Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &": {
        display: "none"
      }
    }
  }
}));

const buttons = [
  {
    title: {
      text: "Changelog",
      color: "#384CC4"
    },
    icon: <RotateLeftIcon />,
    backgroundColor: "#EAEDFF",
    link: "/changelog",
    external: false,
  },
  {
    title: {
      text: <IntlMessages id='sidebar.help' />,
      color: "#ffffff"
    },
    icon: <HelpIcon />,
    backgroundColor: "#e7a501",
    link: "https://tms-wiki.4gflota.com/",
    external: true,
  }
];

const SidebarSystemButtons = () => {
  const {sidebarTheme} = useContext(SidebarThemeContext);
  const classes = useStyles({sidebarTheme});
  const history = useHistory();
  return (
    <List className={classes.list} disablePadding>
      {buttons.map((button, index) => (
        <ListItem
          key={index}
          alignItems="flex-start"
          button
          component="a"
          onClick={() => !button.external && history.push(button.link)}
          style={{
            backgroundColor: button.backgroundColor,
            color: button.title.color
          }}
          className={classes.listItem}
          href={button.external ? button.link : undefined}
          target={button.external ? '_blank' : undefined}
        >
          <ListItemIcon style={{color: button.title.color}}>
            {button.icon}
          </ListItemIcon>
          <ListItemText
            primary={button.title.text}
            secondary={button.subTitle ? button.subTitle.text : ""}
            secondaryTypographyProps={{
              style: button.subTitle ? {color: button.subTitle.color} : {}
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarSystemButtons;
