import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {addService, editService, editServiceState, getAllServices} from "../../../services/Treasury/BillServiceService";

const baseNamespace = 'treasury/services';
export const fetchAllServices = createAsyncThunk(
    `${baseNamespace}/fetchAllServices`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const services = await getAllServices();
            dispatch(fetchSuccess());
            return {services};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const createService = createAsyncThunk(
    `${baseNamespace}/createService`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addService(request);
            const newService = {
                ...request,
                id: response.transactionKey,
                state: true,
                unitPrice: +request.unitPrice,
                total: +request.total,
                taxId: request.tax,
            };
            dispatch(fetchSuccess(response.transactionMessage));
            return newService;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateService = createAsyncThunk(
    `${baseNamespace}/updateService`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editService(request);
            const editedService = {
                ...request,
                unitPrice: +request.unitPrice,
                total: +request.total,
            };
            dispatch(fetchSuccess(response.transactionMessage));
            return editedService;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateServiceState = createAsyncThunk(
    `${baseNamespace}/removeService`,
    async ({serviceId, state}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editServiceState({serviceId, state})
            dispatch(fetchSuccess(response.transactionMessage));
            return serviceId;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);