import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import OutlinedTimePicker from "../../../../Common/Forms/OutlinedTimePicker";
import useForm from "../../../../../hooks/Common/useForm";

const Form = ({initialInfo, setInitialInfo}) => {
  const {formData, setFormData} = useForm({...initialInfo});
  React.useEffect(
    () => {
      setInitialInfo(formData);
    },
    [ formData ]
  );
  return (
    <GridContainer spacing={4} style={{marginBottom: "16px"}}>
      <Grid item xs={12} sm={12} lg={12}>
        <Typography variant="h2">
          <IntlMessages id="administrator.logistic.assignment.initialInformation.title" />
        </Typography>
      </Grid>
      <Grid item xs={12} md={2} style={{display: "flex", alignItems: "center"}}>
        <IntlMessages id="administrator.logistic.assignment.initialInformation.date" />
      </Grid>
      <Grid item xd={12} md={10}>
        <OutlinedDatePicker
          value={formData.date}
          onChange={value => setFormData("date", value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={2} style={{display: "flex", alignItems: "center"}}>
        <IntlMessages id="administrator.logistic.assignment.initialInformation.schedule" />
      </Grid>
      <Grid item xs={12} md={4}>
        <OutlinedTimePicker
          value={formData.startTime}
          onChange={value => setFormData("startTime", value)}
        />
      </Grid>
      <Grid item xs={12} md={2} style={{display: "flex", alignItems: "center"}}>
        <IntlMessages id="administrator.logistic.assignment.initialInformation.to" />
      </Grid>
      <Grid item xs={12} md={4}>
        <OutlinedTimePicker
          value={formData.endTime}
          onChange={value => setFormData("endTime", value)}
        />
      </Grid>
    </GridContainer>
  );
};

export default Form;
