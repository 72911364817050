import DestinationBreakdown from "./DestinationBreakdown";
import {minutesToTime} from "../utils/parsers";
import moment from "moment";

class WorkOrderBreakdown {
    constructor(id, realTime, estimatedTime, destinations, route) {
        this.detail = {
            id,
            route,
            realTime,
            estimatedTime
        };
        this.destinations = destinations;
    }

    static map = breakdown => {
        return new WorkOrderBreakdown(
            breakdown.id,
            {
                head: breakdown.cabeza,
                trailer: breakdown.remolque,
                usedTime: minutesToTime(breakdown.tiempo),
                clientTime: minutesToTime(breakdown.tiempoEnCliente),
                traveledKilometers: breakdown.distancia.toFixed(2),
                clientKilometers: breakdown.distanciaEnCliente.toFixed(2),
                plantOut: moment(breakdown.inicioViaje).format("DD/MM/YYYY HH:mm"),
                plantArrival: moment(breakdown.finViaje).format("DD/MM/YYYY HH:mm")
            },
            {
                didTimes: breakdown.nVecesRealizada,
                average: minutesToTime(breakdown.tiempoMedio),
                clientTime: minutesToTime(breakdown.tiempoMedioEnCliente),
                googleTime: minutesToTime(breakdown.tiempoGoogle),
                centralTime: minutesToTime(breakdown.tiempoMatriz),
                traveledKilometers: breakdown.distanciaMedia.toFixed(2),
                clientKilometers: breakdown.distanciaMediaEnCliente.toFixed(2),
                googleKilometers: breakdown.distanciaGoogle.toFixed(2),
                centralKilometers: breakdown.distanciaMatriz.toFixed(2)
            },
            breakdown.destinos.map(d => DestinationBreakdown.map(d)),
            breakdown.puntosRuta,
        );
    };
}

export default WorkOrderBreakdown;
