import {createAccounting} from "../../../services/AccountingService";
import {fetchAllAccountingStarted} from "./Accounting";

export const startCreateAccounting = (data, closeDialog, filters) => {
  return dispatch => {
    dispatch(startLoading(data));
    createAccounting(data)
      .then(response => {
        dispatch(accountingCreated());
        dispatch(fetchAllAccountingStarted(filters));
        closeDialog();
      })
      .catch(error => {
        accountingCreated();
        closeDialog();
      });
  };
};

const accountingCreated = () => {
  return {
    type: "ACCOUNTING_CREATED"
  };
};

const startLoading = data => {
  return {type: "START_CREATE_ACCOUNTING", payload: {data, isLoading: true}};
};
