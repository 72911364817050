import React from "react";
import PropTypes from "prop-types";

import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.dashboard.section"} />,
    link: "/control-panel/kpi/dashboard"
  },
  {
    label: <IntlMessages id={"control.panel.dashboard.sidebar"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="control.panel.dashboard.title" />}
    description={
      <IntlMessages id="control.panel.dashboard.description" />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
