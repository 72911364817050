import {
  ADD_ADVANCE_STARTED,
  ADD_ADVANCE_SUCCESSFUL,
  RESET_ADD_ADVANCE_FORM
} from "../../types/CustomTypes";
import {insertAdvance} from "../../../services/AdvanceService";
import {ADD_ADVANCE_ERROR} from "../../types/Guardian/AdvancesCustomTypes";

const submitAddAdvanceFormSucceeded = response => {
  if (response.transactionState === "1") {
    return {
      type: ADD_ADVANCE_SUCCESSFUL
    };
  }
  throw Error(response.transactionMessage);
};

export const submitAddAdvanceForm = (data, resetForm, history) => {
  return dispatch => {
    dispatch({type: ADD_ADVANCE_STARTED, payload: {...data}});
    insertAdvance({...data})
      .then(response => {
        dispatch(submitAddAdvanceFormSucceeded(response));
        resetForm();
        history.push("/treasury/cash-management/advances-list");
      })
      .catch(e =>
        dispatch({type: ADD_ADVANCE_ERROR, payload: {message: e.message}})
      );
  };
};

export const resetAddAdvanceForm = () => {
  return {
    type: RESET_ADD_ADVANCE_FORM
  };
};
