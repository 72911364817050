import React, {useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {parseLoweCamelCaseToTitleText} from "../../../../../../utils/parsers";
import ListItem from "./ListItem";
import {v4 as uuidV4} from 'uuid';
import {IconButton} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {openDialog, setImageViewerFile} from "../../../../../../redux/reducers/FleetManagement/filesReader";
import {useDispatch} from "react-redux";
import {dialogsList} from "../../constants";
import moment from "moment/moment";
import {useIntl} from "react-intl";
import LocationOnIcon from '@material-ui/icons/LocationOn';

const Table = ({title, data = [], isList = true}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const openImageViewerDialog = (value) => {
        dispatch(openDialog(dialogsList.imageViewer));
        dispatch(setImageViewerFile(value));
    }
    const columns = useMemo(() => {
        if(!data?.length) {
            return [];
        }
        const entries = Object.entries(data[0]);
        const columns = entries
            .filter(([_, value]) => typeof value !== 'object')
            .sort(([header1, _], __) => header1.toLowerCase().includes('grafica') || header1.toLowerCase().includes('direccionurl') ? -1 : 1)
            .map(([header, value], _) => {
                if(header.toLowerCase().includes('grafica')) {
                    return {
                        field: header,
                        headerName: parseLoweCamelCaseToTitleText(header),
                        type: 'actions',
                        minWidth: Math.max(150, (header?.length ?? 0) * 12),
                        renderCell: ({row}) => {
                            return (
                                <>
                                    <IconButton onClick={() => openImageViewerDialog(value)}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </>
                            )
                        },
                    }
                }
                if(header.toLowerCase().includes('direccionurl')) {
                    return {
                        field: header,
                        headerName: intl.messages['localization'],
                        type: 'actions',
                        minWidth: Math.max(150, (intl.messages['localization']?.length ?? 0) * 12),
                        renderCell: ({value}) => {
                            return (
                                <>
                                    <IconButton href={value} target="_blank">
                                        <LocationOnIcon />
                                    </IconButton>
                                </>
                            )
                        },
                    }
                }
                let headerName = header;
                if(header.toLowerCase().includes('tipoentrada')) {
                    headerName = intl.messages['type.of.load'];
                }
                return {
                    field: header,
                    headerName: parseLoweCamelCaseToTitleText(headerName),
                    minWidth: Math.max(150, Math.max(...data.map(row => row[header]?.length ?? 0)) * 12),
                    sortComparator: (v1, v2) => {
                        if(!isNaN(v1)) {
                            return v1 - v2;
                        }else if(moment.isDate(v1)) {
                            return moment(v1).diff(moment(v2), "seconds");
                        }
                        return String(v1).localeCompare(v2);
                    }
                }
            });
        return [
            {
                field: 'id',
                hide: true
            },
            ...columns
        ];
    }, [data]);
    if(!data) {
        return null;
    }
    if (isList) {
        return !data?.length
            ? null : (
            <ListItem
                title={title}
            >
                <DataGrid
                    style={{
                        height: Math.min(400, 100 + (data?.length ?? 0) * 80),
                    }}
                    columns={columns}
                    rows={data.map(row => ({
                        id: uuidV4(),
                        ...row,
                    }))}
                    disableSelectionOnClick
                    disableColumnMenu
                />
            </ListItem>
        );
    }
    return data.length > 0 &&
        <DataGrid
            style={{
                height: Math.min(400, 100 + (data?.length ?? 0) * 80),
            }}
            columns={columns}
            rows={data.map(row => ({
                id: uuidV4(),
                ...row,
            }))}
            disableSelectionOnClick
            disableColumnMenu
        />

};

export default Table;