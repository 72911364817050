import {combineReducers} from "redux";
import teltonika from "./teltonika";
import historyData from "./historyData";
import parser from "./parser";
import indexFiles from "./indexFiles";

export default combineReducers({
    teltonika,
    historyData,
    parser,
    indexFiles,
})