import {
  APPLY_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS,
  FETCH_ALL_TACHOGRAPH_TRAFFIC_CHARTS_DATA,
  FETCH_ALL_TACHOGRAPH_TRAFFIC_DRIVERS_LIST,
  RESET_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS,
  RESET_TACHOGRAPH_TRAFFIC_STATE
} from "../../types/FleetManagement/TrafficTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  getDriverExcel,
  getTachographChartsData, getTachographDriverActivitiesFile,
  getTachographDriverList
} from "../../../services/FleetManagement/Tachograph/TachographTrafficService";
import {downloadFile} from "../../../services/FileService";

export const resetTachographTrafficState = () => ({
  type: RESET_TACHOGRAPH_TRAFFIC_STATE
});
export const applyTachographTrafficDriversListFilters = filters => ({
  type: APPLY_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS,
  payload: filters
});
export const resetTachographTrafficDriversListFilters = () => ({
  type: RESET_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS
});
export const fetchTachographTrafficChartsData = () => {
  return async dispatch => {
    dispatch(fetchStart());
    getTachographChartsData()
      .then(chartsData => {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_ALL_TACHOGRAPH_TRAFFIC_CHARTS_DATA,
          payload: chartsData
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const fetchTachographTrafficDriversList = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getTachographDriverList(filters)
      .then(driversList => {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_ALL_TACHOGRAPH_TRAFFIC_DRIVERS_LIST,
          payload: driversList
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const fetchDriverExcel = driver => {
  return async dispatch => {
    try {
      const base64 = await getDriverExcel(driver.id);
      const name = driver?.name?.replaceAll(' ', '_') ?? 'conductor';
      downloadFile(
          `${name}.xlsx`,
          `data:application/vnd.ms-excel;base64,${base64}`);
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}

export const fetchDriverActivitiesFile = driver => {
  return async dispatch => {
    try {
      const base64 = await getTachographDriverActivitiesFile(driver.id);
      const name = driver?.name?.replaceAll(' ', '_') ?? 'conductor';
      downloadFile(
          `${name}_actividades.xlsx`,
          `data:application/vnd.ms-excel;base64,${base64}`);
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}
