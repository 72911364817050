import React from 'react';
import TableContainer from "../../../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import {IconButton} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import TableHeader from "./TableHeader";
import {getDeliverNote} from "../../../../../../../../services/WorkOrdersService";
import {useDownloadLocalFile} from "../../../../../../../../hooks/Common/useDownloadLocalFile";

const NotesTable = ({notes, destinationId}) => {
    const {downloadLocalFile} = useDownloadLocalFile();
    const onClickDownload = async (e, id) => {
        e.stopPropagation();
        await downloadLocalFile(
            `${id}.pdf`,
            getDeliverNote,
            {
                id,
                dispatchId: destinationId
            });
    }

    return (
        <TableContainer
            head={<TableHeader/>}
        >
            {notes.map(note => (
                <StyledTableRow key={note.id}>
                    {/*<StyledTableCell>{note.id}</StyledTableCell>*/}
                    <StyledTableCell>
                        <IconButton onClick={(e) => onClickDownload(e, note.id)}>
                            <CloudDownloadIcon/>
                        </IconButton>
                    </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableContainer>
    );
};

export default NotesTable;