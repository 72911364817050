import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import Boolean from "components/Common/Text/Boolean";

const TableValues = ({row}) => (
  <React.Fragment>
    <StyledTableCell>{row.description}</StyledTableCell>
    <StyledTableCell>
      <Boolean value={row.approvalOfExpenses} />
    </StyledTableCell>
    <StyledTableCell>
      <Boolean value={row.obligationOfOT} />
    </StyledTableCell>
  </React.Fragment>
);

TableValues.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string,
    approvalOfExpense: PropTypes.bool,
    obligationOfOT: PropTypes.bool
  })
};

export default TableValues;
