import React from "react";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";



export const titles = [
    {
        id: 25,
        name: (
            <IntlMessages id="control.panel.kpi.areas.grid.location.activity.summary.title" />
        )
    },
    {
        id: 26,
        name: (
            <IntlMessages id="control.panel.kpi.areas.grid.location.activity.alerts.title" />
        )
    },
    {
        id: 27,
        name: (
            <IntlMessages id="control.panel.kpi.areas.grid.location.activity.speed.title" />
        )
    },
];