class DestinationProduct {
  constructor(id, name, quantity, incoming, missing, refund, toReturn) {
    this.id = id;
    this.name = name;
    this.quantity = quantity;
    this.incoming = incoming;
    this.missing = missing;
    this.refund = refund;
    this.toReturn = toReturn;
  }

  static map = product => {
    return new DestinationProduct(
      product.nu_id_producto,
      product.vc_desc_producto,
      product.nu_cantidad_asignada,
      product.nu_cantidad_entregada,
      product.nu_cantidad_faltante,
      product.nu_cantidad_devuelta,
      product.nu_cantidad_rechazada
    );
  };
}

export default DestinationProduct;
