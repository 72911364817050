import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../../../@jumbo/utils/IntlMessages";
import {fetchAllEmployeesByEntity} from "../../../../../../../../../../services/EmployeeService";
import MultipleSelect from "../../../../../../../../../Common/Forms/Select/MultipleSelect";

const DriversPicker = ({values, onChange}) => {
  const [ drivers, setDrivers ] = React.useState([]);
  useEffect(() => {
    fetchAllEmployeesByEntity().then(employees => {
      setDrivers([ ...employees ]);
    });
  }, []);
  return (
    <MultipleSelect
      id={"administrator-expenses-reports-drivers-picker"}
      options={drivers}
      value={values}
      onChange={onChange}
      fullWidth
      label={<IntlMessages id={"administrator.reports.drivers.title"} />}
      headerText={<IntlMessages id="administrator.reports.drivers.header" />}
      noOptionsText={
        <IntlMessages id="administrator.reports.drivers.no.options" />
      }
    />
  );
};

export default DriversPicker;
