import ui from "./ui";
import {
  cancelPrevCampusSelectFetchAll,
  fetchAllCampusSelect
} from "../../../services/CampusSelectService";
import {FETCH_WAREHOUSE_CAMPUS} from "../../types/Administrator/WarehouseCampusSelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevCampusSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchCampusSelectSucceeded = data => ({
  type: FETCH_WAREHOUSE_CAMPUS,
  payload: {
    data
  }
});

export const fetchCampusSelect = id => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevCampusSelectFetchAll();
    fetchAllCampusSelect(id).then(campusSelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchCampusSelectSucceeded(campusSelect));
    });
  };
};

export default {
  get: fetchCampusSelect,
  resetState
};
