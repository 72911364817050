import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useDispatch, useSelector} from "react-redux";
import ExportToolbar from "../../../../../../Common/Tables/DataGrid/ExportToolbar";
import {useIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";
import {getReportFile} from "../../../../../../../services/ControlPanel/Kpi/ReportsService";
import {fetchError, fetchStart, fetchSuccess} from "../../../../../../../redux/actions";
import {downloadFile} from "../../../../../../../services/FileService";
import moment from "moment";

const TimeProcess = () => {
    const {file: data} = useSelector(({controlPanel}) => controlPanel.kpi.current);
    const intl = useIntl();
    const dispatch = useDispatch();
    const downloadReport = async (value, reportId) => {
        try {
            dispatch(fetchStart());
            const report = await getReportFile(reportId, 1, {reportId: value}, true);
            downloadFile("report.pdf", `data:application/pdf;base64,${report}`);
            dispatch(fetchSuccess());
        } catch (e) {
            dispatch(fetchError(e.message));
        }

    }
    const columns = Object.freeze([
        {field: 'id', hide: true},
        {
            field: 'destare',
            headerName: intl.messages['destare'],
            minWidth: 200,
            sortable: false,
            renderCell: ({value}) => (
                <Typography
                    style={value ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    } : {}}
                    onClick={() => value && downloadReport(value, 34)}>
                    {value ? intl.messages['see.report'] : intl.messages['no.available.report']}
                </Typography>
            ),
        },
        {
            field: 'birdWeight',
            headerName: intl.messages['bird.weight'],
            minWidth: 200,
            sortable: false,
            renderCell: ({value}) => (
                <Typography
                    style={value ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    } : {}}
                    onClick={() => value && downloadReport(value, 33)}>
                    {value ? intl.messages['see.report'] : intl.messages['no.available.report']}
                </Typography>
            ),
        },
        {
            field: 'vehicle',
            headerName: intl.messages['vehicle'],
            minWidth: 200,
            sortable: false,
            renderCell: ({value}) => (
                <Typography
                    style={value ? {
                        textDecoration: 'underline',
                        cursor: 'pointer',
                    } : {}}
                    onClick={() => value && downloadReport(value, 35)}>
                    {value ? intl.messages['see.report'] : intl.messages['no.available.report']}
                </Typography>
            ),
        },
        {
            field: 'date',
            headerName: intl.messages['date'],
            type: 'date',
            valueFormatter: ({value}) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'progress',
            headerName: intl.messages['progress'],
            minWidth: 300,
            sortable: true,
        },
        {
            field: 'planningPlace',
            headerName: intl.messages['planning.place'],
            minWidth: 300,
            sortable: true,
        },
        {
            field: 'ot',
            headerName: intl.messages['ot'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'client',
            headerName: intl.messages['client'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'clientDepartureTime',
            headerName: intl.messages['client.departure.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balance',
            headerName: intl.messages['balance'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceEntryTime',
            headerName: intl.messages['balance.entry.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceJabasEntry',
            headerName: intl.messages['balance.jabas.entry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceDocumentEntry',
            headerName: intl.messages['balance.document.entry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceUnloadStartTime',
            headerName: intl.messages['balance.unload.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceUnloadEndTime',
            headerName: intl.messages['balance.unload.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceStowageStartTime',
            headerName: intl.messages['balance.stowage.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceStowageEndTime',
            headerName: intl.messages['balance.stowage.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceJabasDeparture',
            headerName: intl.messages['balance.jabas.departure'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceDocumentDeparture',
            headerName: intl.messages['balance.document.departure'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'balanceDepartureTime',
            headerName: intl.messages['balance.departure.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundry',
            headerName: intl.messages['laundry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryProgress',
            headerName: `${intl.messages['laundry']} ${intl.messages['progress']}`,
            minWidth: 300,
            sortable: true,
        },
        {
            field: 'laundryEntryTime',
            headerName: intl.messages['laundry.entry.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryJabasEntry',
            headerName: intl.messages['laundry.jabas.entry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryDocumentEntry',
            headerName: intl.messages['laundry.document.entry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundrySoakingStartTime',
            headerName: intl.messages['laundry.soaking.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundrySoakingEndTime',
            headerName: intl.messages['laundry.soaking.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryUnloadStartTime',
            headerName: intl.messages['laundry.unload.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryUnloadEndTime',
            headerName: intl.messages['laundry.unload.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryStartTime',
            headerName: intl.messages['laundry.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryEndTime',
            headerName: intl.messages['laundry.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryStowageStartTime',
            headerName: intl.messages['laundry.stowage.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryStowageEndTime',
            headerName: intl.messages['laundry.stowage.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryJabasDeparture',
            headerName: intl.messages['laundry.jabas.departure'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryDocumentDeparture',
            headerName: intl.messages['laundry.document.departure'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'laundryDepartureTime',
            headerName: intl.messages['laundry.departure.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farm',
            headerName: intl.messages['farm'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmProgress',
            headerName: `${intl.messages['farm']} ${intl.messages['progress']}`,
            minWidth: 300,
            sortable: true,
        },
        {
            field: 'farmEntryTime',
            headerName: intl.messages['farm.entry.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmJabasEntry',
            headerName: intl.messages['farm.jabas.entry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmDocumentEntry',
            headerName: intl.messages['farm.document.entry'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmUnloadStartTime',
            headerName: intl.messages['farm.unload.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmUnloadEndTime',
            headerName: intl.messages['farm.unload.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmTrappedStartTime',
            headerName: intl.messages['farm.trapped.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmTrappedEndTime',
            headerName: intl.messages['farm.trapped.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmStowageStartTime',
            headerName: intl.messages['farm.stowage.start.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmStowageEndTime',
            headerName: intl.messages['farm.stowage.end.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmDocumentDeparture',
            headerName: intl.messages['farm.document.departure'],
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'farmDepartureTime',
            headerName: intl.messages['farm.departure.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
        {
            field: 'clientArriveTime',
            headerName: intl.messages['client.arrive.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            minWidth: 200,
            sortable: true,
        },
    ])
    return (
        <DataGrid
            columns={columns}
            rows={data || []}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            components={{
                Toolbar: ExportToolbar,
            }}
        />
    );
};

export default TimeProcess;