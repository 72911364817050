import React, {useCallback, useEffect} from "react";
import Filters from "./Filters";
import TableContainer from "../../../Common/Tables/TableContainer";
import useTableSort from "../../../../hooks/Common/useTableSort";
import TableHeader from "./TableHeader";
import {connect} from "react-redux";
import FilterBuilder from "../../../../utils/filters";
import {fetchAllClientsStarted} from "../../../../redux/actions/Administrator/Clients";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import TableBody from "./TableBody";
import DeleteConfirmationDialog from "../DeleteConfirmationDialog";

const ClientsTable = ({data, dispatch}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  const applyFilters = useCallback(filters => dispatch(), [ dispatch ]);
  useEffect(() => {
    dispatch(fetchAllClientsStarted());
  }, []);
  return (
    <React.Fragment>
      <Filters applyFilters={applyFilters} onClose={() => dispatch()} />
      <TableContainer
        head={<TableHeader onSort={onSort} order={order} orderBy={orderBy} />}
      >
        {tableData.map(client => (
          <StyledTableRow key={client.id}>
            <TableBody client={client} />
          </StyledTableRow>
        ))}
      </TableContainer>
        <DeleteConfirmationDialog />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.clients;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("name", filters.name)
    .byFieldIncludes("clientCode", filters.clientCode)
    .byFieldIncludes("businessName", filters.businessName)
    .build();
  return {data: filtered};
};

export default connect(mapStateToProps)(ClientsTable);
