import {mapToBoolean} from "../../../utils/mappers";

class WorkshopTaskType {
    constructor(id, description, state) {
        this.id = id;
        this.description = description;
        this.state = state;
    }

    static map = type => {
        return new WorkshopTaskType(
          type.nu_id_tipo_trabajo,
          type.vc_desc_tipo_trabajo,
          mapToBoolean(type.bi_estado),
        );
    }
}

export default WorkshopTaskType;