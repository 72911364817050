import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  createIncidenceType,
  cancelPrevIncidenceTypeFetchAll,
  fetchAllIncidenceType,
  editIncidenceType,
  changeStateIncidenceType
} from "../../../services/IncidenceTypeService";

import {
  FETCH_INCIDENCE_TYPE_SUCCEEDED,
  APPLY_INCIDENCE_TYPE_FILTERS,
  RESET_INCIDENCE_TYPE_FILTERS,
  INCIDENCE_TYPE_CHANGE_STATE,
  INCIDENCE_TYPE_EDIT,
  INCIDENCE_TYPE_CREATE
} from "../../types/Administrator/IncidenceTypes";

import IncidenceType from "../../../domain/IncidenceType";

export const resetState = () => dispatch => {
  cancelPrevIncidenceTypeFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchIncidenceTypeSucceeded = data => ({
  type: FETCH_INCIDENCE_TYPE_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchIncidenceType = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevIncidenceTypeFetchAll();
  try {
    const incidenceType = await fetchAllIncidenceType();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchIncidenceTypeSucceeded(incidenceType));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applyIncidenceTypeFilter = filters => ({
  type: APPLY_INCIDENCE_TYPE_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetIncidenceTypeFilters = () => ({
  type: RESET_INCIDENCE_TYPE_FILTERS
});

const createIncidenceTypeAction = data => ({
  type: INCIDENCE_TYPE_CREATE,
  payload: {
    ...data
  }
});

export const createIncidenceTypeStarted = data => async dispatch => {
  dispatch(closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const response = await createIncidenceType(data);
    const incidenceType = new IncidenceType(
      response.id,
      data.description,
      true
    );
    dispatch(createIncidenceTypeAction(incidenceType));
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const editIncidenceTypeAction = (id, data) => ({
  type: INCIDENCE_TYPE_EDIT,
  payload: {
    ...data,
    id
  }
});

export const editIncidenceTypeStarted = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await editIncidenceType(id, data);
    dispatch(editIncidenceTypeAction(id, data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeIncidenceTypeSucceeded = id => ({
  type: INCIDENCE_TYPE_CHANGE_STATE,
  payload: {
    id
  }
});

const changeIncidenceType = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  await changeStateIncidenceType(id);
  dispatch(changeIncidenceTypeSucceeded(id));
  dispatch(ui.changeStateSucceeded());
};

export const deleteIncidenceTypeStarted = id => dispatch => {
  dispatch(changeIncidenceType(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreIncidenceTypeStarted = id => dispatch => {
  dispatch(changeIncidenceType(id));
  dispatch(ui.closeRestoreConfirmation());
};

export default {
  get: fetchIncidenceType,
  delete: deleteIncidenceTypeStarted,
  restore: restoreIncidenceTypeStarted,
  edit: editIncidenceTypeStarted,
  create: createIncidenceTypeStarted,
  applyIncidenceTypeFilter,
  resetIncidenceTypeFilters,
  resetState
};
