import React from "react";
import {useDispatch} from "react-redux";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import useUiState from "../../../../hooks/Administrator/useUiState";
import {
  deleteTagTypeStarted,
  setCurrentTagType
} from "../../../../redux/actions/Administrator/Tags";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const TableRow = ({type}) => {
  const dispatch = useDispatch();
  const ui = useUiState();

  const onShowDetail = () => {
    dispatch(setCurrentTagType(type));
  };

  const onEdit = () => {
    dispatch(ui.tableProps.onEdit(type.id));
  };
  const onDelete = () => {
    dispatch(deleteTagTypeStarted(type.id));
  };

  return (
    <React.Fragment>
      <StyledTableCell>{type.id}</StyledTableCell>
      <StyledTableCell>{type.description}</StyledTableCell>
      <StyledTableCell>
        {type.state ? (
          <IntlMessages id="administrator.tag.types.table.active" />
        ) : (
          <IntlMessages id="administrator.tag.types.table.inactive" />
        )}
      </StyledTableCell>
      <StyledTableCell>
        <IconButton onClick={onShowDetail}>
          <VisibilityIcon />
        </IconButton>
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </React.Fragment>
  );
};

export default TableRow;
