import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import {StyledTablePagination} from "./styles";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableFooter = ({
  vehicles,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage
}) => {
  const rows = vehicles.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <StyledTableRow>
      <StyledTablePagination
        rowsPerPageOptions={[ 5, 10, 15, {label: "Todos", value: -1} ]}
        count={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": <IntlMessages id={"table.footer.pagination.text"} />
          },
          native: true,
          style: {
            backgroundColor: "#8092e3",
            border: "solid",
            borderRadius: "8px",
            borderColor: "white"
          }
        }}
        align="center"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </StyledTableRow>
  );
};

export default TableFooter;
