import React from 'react';
import useForm from "../../../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ClientSelect
    from "../../../../../../../ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog/ElevatorInformation/ClientSelect";
import HeadquarterSelect
    from "../../../../../../../ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog/ElevatorInformation/HeadquarterSelect";
import ClientHeadquarterSelect from "../../../../DestinationsDialog/ClientHeadquarteSelect";
import {editDispatchClient} from "../../../../../../../../redux/actions/Administrator/WorkOrders";
import ClientHeadquarter from "../../../../../../../../domain/Logistic/Distribution/ClientHeadquarter";


const initialData = {
    clientHeadquarter: ''
}

const Form = ({id, destinationId, onClose}) => {
    const {current} = useSelector(({administrator}) => administrator.workOrders)
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit} = useForm(initialData);
    React.useEffect(() => {
        if(current) {
            const destination = current?.destinations?.find(d => d.id === destinationId);
            const {clientId, headquarterId, name, headquarterName,} = destination;
            const clientHeadquarter = new ClientHeadquarter(clientId, name, headquarterId, headquarterName);
            setFormData('clientHeadquarter', clientHeadquarter);
        }
    }, [current, destinationId]);
    const onSubmit = data => {
        onClose && onClose();
        const {clientId, headquarterId} = data.clientHeadquarter;
        dispatch(editDispatchClient({current, destinationId, clientId, headquarterId}));
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} >
                    <ClientHeadquarterSelect
                        clientHeadquarter={formData.clientHeadquarter}
                        setValue={value => setFormData("clientHeadquarter", value)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;