import React, {useEffect, useState} from 'react';
import Select from '../../../Common/Forms/Select';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import {Box, Button, Grid, MenuItem} from '@material-ui/core';
import useForm from '../../../../hooks/Common/useForm';
import {useDispatch, useSelector} from 'react-redux';
import {clearCurrentBox} from '../../../../redux/actions/Guardians/CurrentBox';
import {clearCurrentAdvance} from '../../../../redux/actions/Guardians/CurrentAdvance';
import validations from './validations';
import RouteInput from '../../Common/Inputs/RouteInput';
import BoxInput from '../../Common/Inputs/BoxInput';
import AdvanceInput from '../../Common/Inputs/AdvanceInput';
import DriverInput from '../../Common/Inputs/DriverInput';
import AmountInput from '../../Common/Inputs/AmountInput';
import ObservationsInput from '../../Common/Inputs/ObservationsInput';
import {fetchAdvanceTypes} from "../../../../redux/actions/Administrator/AdvanceTypes";
import {cancelPrevFetchAllAdvancesByDriver, fetchAllAdvancesByDriver} from "../../../../services/AdvanceService";

const AddAdvance = ({ data, isLoading, onSubmit }) => {
  const { currentBox, currentAdvance } = useSelector(state => state.guardian);
  const {administrator} = useSelector(state => state);
  const dispatch = useDispatch();
  const [advanceTypes, setAdvanceTypes] = useState([]);
  const initialData = {
    box: data.box,
    travel: data.travel || data.id,
    driver: data.driver,
    route: data.route,
    amount: data.amount,
    advanceType: data.advanceType,
    observations: data.observations,
  };
  const { formData, errors, handleSubmit, setFormData, resetForm } = useForm(initialData, validations);
  const [advances, setAdvances] = useState([]);
  useEffect(() => {
    if(formData.driver && formData.box) {
      cancelPrevFetchAllAdvancesByDriver();
      fetchAllAdvancesByDriver(formData.driver.id, formData.box).then(advances => {
        setAdvances([...advances]);
      });
    }
  }, [formData.driver, formData.box]);
  const onSubmitWrapper = async advance => {
    // console.log(advance);
    onSubmit({ ...advance }, resetForm);
  };

  useEffect(() => {
    if (administrator.advanceTypes.data.length <= 0) {
      dispatch(fetchAdvanceTypes());
    }
  }, []);
  useEffect(() => {
    setAdvanceTypes([...administrator.advanceTypes.data.filter(advance => advance.active)]);
  }, [administrator.advanceTypes.data])
  useEffect(() => {
    if(formData.travel !== "new" && formData.travel !== "" && advances.length > 0 && advanceTypes.length > 0) {
      setFormData('advanceType', advances?.find(advance => advance.id === formData.travel)?.typeId)
    }
  }, [formData.travel, advances, advanceTypes]);


  const {list} = useSelector(state => state.guardian.pettyCashes);
  const condition = () => {
    if(list.length > 0 && formData.amount > 0) {
      return  list?.find(pettyCash => pettyCash.id === formData.box)?.cash < formData.amount;
    }
    return false
  }

  // useEffect(() => {console.log(advanceTypes.find(type => type.id === formData.advanceType))}, [formData.advanceType]);

  return (
    <React.Fragment>
      <form id="add-advance-form" onSubmit={handleSubmit(onSubmitWrapper)}>
        <GridContainer spacing={4}>
          <BoxInput
            box={formData.box}
            errors={errors}
            isLoading={isLoading}
            currentBox={currentBox.box}
            currentAdvanceBox={currentAdvance.box}
            setBox={data => setFormData('box', data)}
          />
          <DriverInput
            driver={formData.driver}
            setDriver={data => setFormData('driver', data)}
            errors={errors}
            isLoading={isLoading}
            currentAdvanceDriver={currentAdvance.driver}
            box={formData.box}
          />
          <AdvanceInput
            travel={formData.travel}
            isLoading={isLoading}
            errors={errors}
            setTravel={data => setFormData('travel', data)}
            currentTravel={currentAdvance.id}
            advances={advances}
            disabled={isLoading || currentAdvance?.id !== '' || !formData.driver}
            driver={formData.driver}
          >
            <MenuItem value="new">
              <IntlMessages id={'guardian.add.advance.form.new.advance'} />
            </MenuItem>
          </AdvanceInput>
          <Grid item xs={12} sm={12}>
            <Select
              value={formData.advanceType}
              onChange={e => setFormData('advanceType', e.target.value)}
              id="advanceType"
              label={<IntlMessages id={'guardian.add.advance.form.advance.type'} />}
              fullWidth
              disabled={isLoading || formData.travel === '' || formData.travel !== "new"}
              error={errors.advanceType.length > 0}
              errorMessage={errors.advanceType[0]}>
              <MenuItem value="">
                <IntlMessages id={'guardian.add.advance.form.advance.type.select.item'} />
              </MenuItem>
              {advanceTypes.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <RouteInput
            route={formData.route}
            driver={formData.driver}
            setRoute={data => setFormData('route', data)}
            isLoading={isLoading}
            currentRoute={currentAdvance.route}
            includeReference
            advanceTypeOtObligation={
                !currentAdvance.adjust &&
                formData.advanceType &&
                advanceTypes?.find(type => type.id === formData.advanceType)?.obligationOfOT}
            advanceRoute={formData.travel !== "new" && formData.travel !== "" && currentAdvance.route === ''? advances?.find(advance => advance.id === formData.travel)?.route?.id : ''}
          />
          <AmountInput
            amount={formData.amount}
            setAmount={data => setFormData('amount', data)}
            isLoading={isLoading}
            errors={errors}
            label={'guardian.add.advance.form.amount'}
            currentBalance={currentAdvance.balance}
            condition={condition()}
            addAdvance
          />
          <ObservationsInput
            observations={formData.observations}
            setObservations={data => setFormData('observations', data)}
            isLoading={isLoading}
            errors={errors}
            label={'guardian.add.advance.form.observations'}
          />
          <Grid item xs={12} sm={12}>
            <Box display="flex" alignContent="center" justifyContent="center">
              <Button
                variant="contained"
                style={{
                  marginRight: '16px',
                }}
                color="primary"
                type="submit"
                form="add-advance-form"
                disabled={isLoading || condition() || (!currentAdvance.adjust && advanceTypes?.find(type => type.id === formData.advanceType)?.obligationOfOT && formData.route === '')}>
                <IntlMessages id={'guardian.add.advance.form.assign'} />
              </Button>
              <Button
                variant="contained"
                mx={3}
                color="primary"
                onClick={() => {
                  dispatch(clearCurrentAdvance());
                  dispatch(clearCurrentBox());
                  resetForm();
                }}>
                <IntlMessages id={'guardian.add.advance.form.reset'} />
              </Button>
            </Box>
          </Grid>
        </GridContainer>
      </form>
    </React.Fragment>
  );
};

export default AddAdvance;
