import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  plaque: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.accidents.add.field.error" />
    }
  ],
  date: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.accidents.add.field.error" />
    }
  ],
  driver: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.accidents.add.field.error" />
    }
  ],
  type: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.accidents.add.field.error" />
    }
  ],
  insuranceCompany: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.accidents.add.field.error" />
    }
  ],
  communicationDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.accidents.add.field.error" />
    }
  ]
};
