import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  editInsurance,
  setCurrentInsurance
} from "../../../../../redux/actions/FleetManagement/Insurances";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "../AddInsuranceDialog/Form";
import moment from "moment";
import FilesArea from "../../../Common/FilesArea";
import {Box} from "@material-ui/core";

const EditInsuranceDialog = ({open, setOpen}) => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.insurances
  );
  const [ files, setFiles ] = React.useState(current.files || []);
  const id = "edit-insurance-dialog";
  const dispatch = useDispatch();
  const onSubmit = insurance => {
    dispatch(
      editInsurance({id: current.id, ...insurance}, () => setOpen(false))
    );
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.insurance.edit.title"} />}
      isOpen={open}
      onClose={() => {
        setOpen(false);
        dispatch(setCurrentInsurance(null));
      }}
      submitText={<IntlMessages id={"fleet.management.insurance.edit.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
    >
      <Form
        id={id}
        onSubmit={onSubmit}
        initialData={{
          plaque: current.plaque || "",
          terminationDate:
            moment(current.terminationDate).format("YYYY-MM-DD") || "",
          company: current.companyId || "",
          type: current.typeId || "",
          commencementDate:
            moment(current.commencementDate).format("YYYY-MM-DD") || "",
          expirationDate:
            moment(current.expirationDate).format("YYYY-MM-DD") || "",
          policy: current.policy || "",
          premium: current.premium || "",
          phone: current.phone || "",
          contact: current.contact || ""
        }}
      />
      <Box>
        <FilesArea
          id={"form-insurance-files"}
          files={files}
          setFiles={setFiles}
        />
      </Box>
    </FormDialog>
  );
};

export default EditInsuranceDialog;
