import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../hooks/Common/useForm";
import validations from "./Form/validations";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import PlaqueSelect from "../../Common/Form/Selects/PlaqueSelect";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import OutlinedTimePicker from "../../../../Common/Forms/OutlinedTimePicker";
import DriverSelect from "../../Common/Form/Selects/DriverSelect";
import OrganizationSelect from "./Form/OrganizationSelect";
import CostCentralSelect from "../../Common/Form/Selects/CostCentralSelect";
import InfringedRuleSelect from "./Form/InfringedRuleSelect";
import DefenseSelect from "./Form/DefenseSelect";
import StateSelect from "./Form/StateSelect";
import {useDispatch} from "react-redux";
import {addInfraction} from "../../../../../redux/actions/FleetManagement/Infractions";
import Form from "./Form";

const initData = {
  plaque: "",
  date: "",
  time: "",
  driver: "",
  fileNumber: "",
  organization: "",
  costCentral: "",
  denunciationPlace: "",
  postalCode: "",
  district: "",
  infringedRule: "",
  denunciation: "",
  notificationDate: "",
  amount: "",
  discountExpirationDate: "",
  discount: "",
  result: "",
  defense: "",
  limitDate: "",
  management: "",
  state: ""
};
const AddInfractionDialog = ({open, setOpen}) => {
  const id = "add-infraction-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addInfraction(data, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.infractions.add.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.infractions.add.ok"} />}
      cancelText={
        <IntlMessages id={"fleet.management.infractions.add.cancel"} />
      }
    >
      <Form id={id} onSubmit={onSubmit} initialData={initData} />
    </FormDialog>
  );
};

export default AddInfractionDialog;
