import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import EventSelect from "domain/EventSelect";

let fetchAllCancelToken = undefined;

export const cancelPrevEventSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllEventSelect = async () => {
  try {
    const response = await axiosInstance.post(
      "/Eventos/sel",
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(event => EventSelect.map(event));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
