import React, {useEffect, useCallback} from "react";

import PageContainer from "../../../../../components/FleetManagement/DailyOperation/Refueling/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {resetRefuelingState} from "../../../../../redux/actions/FleetManagement/Refueling";
import RefuelingTable from "../../../../../components/FleetManagement/DailyOperation/Refueling/RefuelingTable";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddRefuelingDialog from "../../../../../components/FleetManagement/DailyOperation/Refueling/AddRefuelingDialog";
import EditRefuelingDialog from "../../../../../components/FleetManagement/DailyOperation/Refueling/EditRefuelingDialog";

const Refueling = () => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.refueling
  );
  const dispatch = useDispatch();
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetRefuelingState());
    };
  }, []);
  return (
    <PageContainer>
      <RefuelingTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd
        onAddClicked={() => {
          setOpenAddDialog(true);
        }}
      />
      {openAddDialog && (
        <AddRefuelingDialog open={openAddDialog} setOpen={setOpenAddDialog} />
      )}
      {openEditDialog &&
      current && (
        <EditRefuelingDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
        />
      )}
    </PageContainer>
  );
};

export default Refueling;
