import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const TurnSelect = ({id, label, value, setValue, error, hasError}) => {
  const [ turns, setTurns ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setTurns([
      {
        id: 1,
        label: "Turn 1"
      },
      {
        id: 2,
        label: "Turn 2"
      }
    ]);
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      error={hasError}
      errorMessage={error}
    >
      {turns.map(t => (
        <MenuItem key={`turn-${t.id}`} value={t.id}>
          {t.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TurnSelect;
