import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const DefenseSelect = ({id, label, value, setValue}) => {
  const [ defenses, setDefenses ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setDefenses([
      {
        id: 1,
        label: "Defensa 1"
      },
      {
        id: 2,
        label: "Defensa 2"
      }
    ]);
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {defenses.map(e => (
        <MenuItem key={`defense-${e.id}`} value={e.id}>
          {e.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DefenseSelect;
