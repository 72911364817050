import WorkOrderTypeState from "./WorkOrderTypeState";
import {mapToBoolean} from "../../../utils/mappers";

class WorkOrderType {
  constructor(
    id,
    name,
    status,
    principalStates,
    complementaryStates,
    automaticStates
  ) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.principalStates = principalStates;
    this.complementaryStates = complementaryStates;
    this.automaticStates = automaticStates;
    this.states = [ ...principalStates, ...complementaryStates ];
    this.statesNames = this.getStates(this.states);
  }

  static map = type => {
    const states = type.estados.map(state => WorkOrderTypeState.map(state));
    return new WorkOrderType(
      type.nu_id_tipo_despacho,
      type.vc_desc_tipo_despacho,
      mapToBoolean(type.bi_estado),
      states.filter(state => state.order === "1" || state.order === "2"),
      states.filter(state => state.order !== "1" && state.order !== "2"),
      []
    );
  };

  getStates(states) {
    let statesString = "";
    states.map(s => {
      statesString += s.name;
      statesString += ",";
      return null;
    });
    return statesString;
  }
}

export default WorkOrderType;
