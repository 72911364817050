import React from "react";
import {ListItem, Typography} from "@material-ui/core";
import DeleteRoundedIcon from "@material-ui/icons/DeleteRounded";

const FileListItem = ({file, onRemoveFile}) => (
  <ListItem
    style={{display: "flex", justifyContent: "space-between"}}
    disableGutters
  >
    <Typography component="p" variant="inherit">
      {file.name}
    </Typography>
    <div>
      <DeleteRoundedIcon
        style={{cursor: "pointer", color: "D72934"}}
        onClick={() => onRemoveFile()}
      />
    </div>
  </ListItem>
);

export default FileListItem;
