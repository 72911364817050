import React from "react";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../../../Common/Forms/OutlinedDatePicker";
import useForm from "../../../../../../../hooks/Common/useForm";
import Checkbox from "../../../../../../Common/Forms/Checkbox";
import ModelSelect from "./ModelSelect";
import CategorySelect from "./CategorySelect";
import BaseCard from "../BaseCard";
import ClientSelect from "./ClientSelect";
import HeadquarterSelect from "./HeadquarterSelect";
import GenreSelect from "./GenreSelect";
import TypeSelect from "./TypeSelect";
import ManufacturerSelect from "./ManufacturerSelect";

const ElevatorInformation = ({initialData, onCancel, onSave, add = false}) => {
  const id = "elevator-info-form";
  const {formData, setFormData, resetForm, handleSubmit} = useForm(initialData);
  const [ edit, setEdit ] = React.useState(add);
  const onClickCancel = () => {
    setEdit(false);
    onCancel && onCancel();
  };
  const onSaveClick = data => {
    setEdit(false);
    onSave && onSave(data);
  };

  return (
    <BaseCard
      onSubmit={handleSubmit(onSaveClick)}
      onCancel={onClickCancel}
      title={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.elevator"
          }
        />
      }
      edit={edit}
      setEdit={setEdit}
    >
      <form id={id}>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <OutlinedInput
              id="elevator-name"
              value={formData.name}
              label={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.elevator.name"
                  }
                />
              }
              fullWidth
              onChange={value => setFormData("name", value)}
              disabled={!edit}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ClientSelect
              id="elevator-client"
              value={formData.client}
              disabled={!edit}
              setValue={value => {
                  setFormData("client", value);
                  setFormData("headquarter", "");
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <HeadquarterSelect
              id="elevator-headquarter"
              value={formData.headquarter}
              disabled={!edit}
              setValue={value => setFormData("headquarter", value)}
              client={formData.client}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ModelSelect
              id="elevator-model"
              value={formData.model}
              disabled={!edit}
              setValue={value => setFormData("model", value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id="elevator-ken"
              value={formData.ken}
              label={<IntlMessages id="manufacturer.code" />}
              fullWidth
              onChange={value => setFormData("ken", value)}
              disabled={!edit}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CategorySelect
              id="elevator-category"
              value={formData.category}
              disabled={!edit}
              setValue={value => setFormData("category", value)}
            />
          </Grid>
            <Grid item xs={12} md={6}>
                <GenreSelect
                    value={formData.genre}
                    disabled={!edit}
                    setValue={value => setFormData("genre", value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TypeSelect
                    value={formData.type}
                    disabled={!edit}
                    setValue={value => setFormData("type", value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ManufacturerSelect
                    value={formData.manufacturer}
                    disabled={!edit}
                    setValue={value => setFormData("manufacturer", value)}
                />
            </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id="elevator-stops"
              value={formData.stops}
              label={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.elevator.stops"
                  }
                />
              }
              fullWidth
              onChange={value => setFormData("stops", value)}
              disabled={!edit}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedDatePicker
              id="elevator-shipment-date"
              value={formData.shipmentDate}
              label={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.elevator.shipment.date"
                  }
                />
              }
              fullWidth
              onChange={value => setFormData("shipmentDate", value)}
              disabled={!edit}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox
              id="elevator-emergency"
              checked={formData.emergency}
              label={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.elevator.emergency"
                  }
                />
              }
              fullWidth
              onChange={value => setFormData("emergency", value)}
              disabled={!edit}
            />
          </Grid>
        </GridContainer>
      </form>
    </BaseCard>
  );
};

export default ElevatorInformation;
