import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import Unit from "../domain/Unit";
import Response from "../domain/Response";

let fetchAllCancelToken = undefined;

const unit = [
  {
    nu_id_unidad: 1,
    vc_desc_unidad: "Unidad 1",
    bi_estado: "1"
  },
  {
    nu_id_unidad: 2,
    vc_desc_unidad: "Unidad 2",
    bi_estado: "1"
  },
  {
    nu_id_unidad: 3,
    vc_desc_unidad: "Unidad 3",
    bi_estado: "0"
  }
];

export const cancelPrevUnitFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllUnits = async () => {
  //return unit.map(unit => Unit.map(unit));
  try {
    const response = await axiosInstance.post(
      "/Inventario_Unidad_Medida/sel",
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(unity => Unit.map(unity));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
