import React, {useEffect, useState} from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import { sidebarNavs } from '../Menus/menus';
import {useSelector} from "react-redux";
import {Box, Divider, MenuItem} from "@material-ui/core";
import SidebarSystemButtons from "../../../../../components/System/SidebarSystemList";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  const {authUser, selectedCompany} = useSelector(state => state.auth)
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if(authUser?.companyId){
      setOptions([...sidebarNavs(selectedCompany)]);
    }
  }, [authUser])

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={options} />
      <Box mt={4}>
        <Divider />
      </Box>
      <SidebarSystemButtons />
    </PerfectScrollbar>
  );
};

export default SideBar;
