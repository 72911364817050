import useForm from "../../../hooks/Common/useForm";
import React from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {creditNoteTypes} from "./constants";

const INIT_DATA = {
    id: '',
    serviceId: '',
    ncQuantity: 1,
    unitPrice: 1,
}

const ServiceForm = ({id, service = {}, onSubmit, creditNoteType}) => {
    const {formData, setFormData, handleSubmit, setValues} = useForm(INIT_DATA);
    React.useEffect(() => {
        service && setValues(service);
    }, []);

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='ncQuantity'
                        type='number'
                        label={<>NC <IntlMessages id='quantity' /></>}
                        value={formData.ncQuantity}
                        onChange={value => +value <= service.quantity && setFormData('ncQuantity', value)}
                        fullWidth
                        inputProps={{min: 1}}
                        required
                        disabled={creditNoteType !== creditNoteTypes.partial}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='unitPrice'
                        type='number'
                        label={<IntlMessages id='unit.price' />}
                        value={formData.unitPrice}
                        onChange={value => setFormData('unitPrice', value)}
                        fullWidth
                        inputProps={{min: 1, step: 0.01}}
                        required
                        disabled={creditNoteType !== creditNoteTypes.discount}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default ServiceForm;