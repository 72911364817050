import React from 'react';
import {Grid, MenuItem} from "@material-ui/core";
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {fetchAllVehicleSelect} from "../../../../../services/VehicleServiceSelect";

const TractSelect = ({value, setValue, edit, errors}) => {
    const [tracts, setTracts] = React.useState([]);

    React.useEffect(() => {
        fetchAllVehicleSelect()
            .then(vehicles => setTracts(vehicles))
            .catch(e => setTracts([]));
    }, []);
    return(
        <Grid item sm={4}>
            <Select
                value={value}
                onChange={e => setValue(e.target.value)}
                id="tract-select"
                label={<IntlMessages id={"logistic.work.orders.dialog.detail.tract.label"} />}
                fullWidth
                disabled={edit}
                error={errors?.tract?.length > 0}
                errorMessage={errors?.tract[0]}>
                <MenuItem value="">
                    <IntlMessages id="logistic.work.orders.dialog.detail.tract.select.one" />
                </MenuItem>
                {tracts.length > 0? tracts.map(tract => (
                    <MenuItem key={tract.id} value={tract.id}>
                        {tract.name}
                    </MenuItem>
                )) :
                <MenuItem key={value} value={value}>

                </MenuItem>}
            </Select>
        </Grid>
    );
}

export default TractSelect;