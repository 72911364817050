import WorkshopTask from "./WorkshopTask";

class WorkshopRequest {
  constructor(id, vehicle,
              workshop,
              date, amount,
              status, responseDate,
              entranceDate, tasks
              ) {
    this.id = id;
    this.vehicle = vehicle;
    this.workshop = workshop;
    this.date = date;
    this.amount = amount;
    this.status = status;
    this.statusId = status.id;
    this.statusDescription = status.description;
    this.responseDate = responseDate;
    this.entranceDate = entranceDate;
    this.tasks = tasks;
  }

  static map = request => {
    return new WorkshopRequest(
        request.nu_id_solicitud_taller,
        {
            id: request.nu_id_vehiculo || 0,
            description: request.vc_placa
        },
        {
          id: request.nu_id_taller_proveedor || 0,
          description: request.vc_desc_taller_proveedor
        },
        request.dt_fec_solicitud,
        request.nu_presupuesto_total,
        {
          id: request.nu_id_solicitud_taller_estado || 0,
          description: request.vc_desc_solicitud_taller_estado
        },
        request.dt_fec_respuesta || "",
        request.dt_fec_entrada_taller || "",
        request?.trabajos?.map(t => WorkshopTask.map(t)) || []
    );
  }
}

export default WorkshopRequest;
