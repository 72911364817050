import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const EditReceiverDialog = ({initData, isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="edit-receivers-form"
    isOpen={isOpen}
    title={
      <IntlMessages id="administrator.logistic.receivers.edit.form.title" />
    }
    onClose={onClose}
    disableEnforceFocus
  >
    <Form id="edit-receivers-form" initData={initData} onSubmit={onSubmit} />
  </FormDialog>
);

export default EditReceiverDialog;
