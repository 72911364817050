import React from "react";
import FormDialog from "../../../../../../Common/Dialogs/FormDialog";
import Form from "./Form";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {addComplementaryWorkOrderTypeState} from "../../../../../../../redux/actions/Logistic/WorkOrdersTypes";

const AddComplementaryDialog = ({open, setOpen}) => {
  const id = "add-complementary-form";
  const {current} = useSelector(({logistic}) => logistic.workOrdersTypes);
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addComplementaryWorkOrderTypeState(current, data));
    setOpen(false);
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      title={
        <IntlMessages
          id={
            "logistic.distribution.work.orders.types.detail.state.complementary.add"
          }
        />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      onClose={() => setOpen(false)}
    >
      {open && <Form id={id} onSave={onAdd} />}
    </FormDialog>
  );
};

export default AddComplementaryDialog;
