import {
  ADD_DESTINATION_STARTED,
  ADD_DESTINATION_SUCCESS,
  ADD_WORK_ORDER_STARTED,
  ADD_WORK_ORDER_SUCCESS,
  APPLY_WORK_ORDERS_FILTERS,
  DELETE_DESTINATION_STARTED,
  DELETE_DESTINATION_SUCCESS,
  DELETE_WORK_ORDER_STARTED,
  DELETE_WORK_ORDER_SUCCESS,
  EDIT_DESTINATION_STARTED,
  EDIT_DESTINATION_SUCCESS,
  EDIT_WORK_ORDER_STARTED,
  EDIT_WORK_ORDER_SUCCESS,
  FETCH_ALL_WORK_ORDERS_STARTED,
  FETCH_ALL_WORK_ORDERS_SUCCEEDED,
  OPERATION_FAILED,
  RESET_CURRENT_WORK_ORDER,
  RESET_OPERATION,
  RESET_WORK_ORDERS_FILTERS,
  SET_CURRENT_WORK_ORDER,
  UPDATE_DESTINATIONS_ORDER
} from "../../types/Administrator/WorkOrdersTypes";
import moment from "moment";

const today = moment(Date.now()).format("yyyy-MM-DD");

const INIT_STATE = {
  data: [],
  filters: {
    state: 0,
    driver: [],
    period: 4,
    startDate: today,
    endDate: today
  },
  current: undefined,
  isLoading: false,
  operation: {
    data: undefined,
    type: "",
    message: ""
  }
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_WORK_ORDERS_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_WORK_ORDERS_SUCCEEDED: {
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    }
    case APPLY_WORK_ORDERS_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_WORK_ORDERS_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case SET_CURRENT_WORK_ORDER: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_WORK_ORDER: {
      return {
        ...state,
        current: undefined
      };
    }
    case EDIT_WORK_ORDER_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          type: "edit-work-order",
          data: action.payload
        }
      };
    }
    case EDIT_WORK_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        // data: [
        //     ...state.data.map(
        //         d => {
        //             if(d.id === state.operation.data.id) {
        //                 return {...d, ...state.operation.data}
        //             }
        //             return d;
        //         }
        //     )
        // ],
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case DELETE_WORK_ORDER_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          type: "delete-work-order",
          data: action.payload
        }
      };
    }
    case DELETE_WORK_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: [ ...state.data.filter(d => d.id !== state.operation.data.id) ],
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case ADD_WORK_ORDER_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          type: "add-work-order",
          data: action.payload
        }
      };
    }
    case ADD_WORK_ORDER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: [
          ...state.data,
          {id: state.data.length + 1, ...state.operation.data}
        ],
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case OPERATION_FAILED: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case RESET_OPERATION: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation
        }
      };
    }
    case EDIT_DESTINATION_STARTED: {
      return {
        ...state,
        isLoading: false,
        operation: {
          type: "edit-destination",
          data: action.payload
        }
      };
    }
    case EDIT_DESTINATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: [
          ...state.data.map(d => {
            if (d.id === state.current.id) {
              return {
                ...d,
                destinations: [
                  ...d.destinations.map(destination => {
                    if (destination.id === state.operation.data.id) {
                      return {...destination, ...state.operation.data};
                    }
                    return destination;
                  })
                ]
              };
            }
            return d;
          })
        ],
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case DELETE_DESTINATION_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          type: "delete-destination",
          data: action.payload
        }
      };
    }
    case DELETE_DESTINATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: [
          ...state.data.map(d => {
            if (d.id === state.current.id) {
              return {
                ...d,
                destinations: [
                  ...state.current.destinations.filter(
                    des => des.id !== state.operation.data.id
                  )
                ]
              };
            }
            return d;
          })
        ],
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case ADD_DESTINATION_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          type: "add-destination",
          data: action.payload
        }
      };
    }
    case ADD_DESTINATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: [
          ...state.data.map(d => {
            if (d.id === state.current.id) {
              return {
                ...d,
                destinations: [
                  ...state.current.destinations,
                  {
                    id: state.current.destinations.length + 1,
                    history: [ {name: "Estado1", date: Date.now()} ],
                    ...state.operation.data
                  }
                ]
              };
            }
            return d;
          })
        ],
        operation: {
          ...INIT_STATE.operation,
          message: action.payload
        }
      };
    }
    case UPDATE_DESTINATIONS_ORDER: {
      return {
        ...state,
        data: [
          ...state.data.map(d => {
            if (d.id === state.current.id) {
              return {
                ...d,
                destinations: [ ...action.payload ]
              };
            }
            return d;
          })
        ]
      };
    }
    default: {
      return state;
    }
  }
};
