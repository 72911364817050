import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import WarehouseTransfer from "../domain/WarehouseTransfer";
import Response from "domain/Response";
import {getCompanyEntityId, getUserName} from "./StorageService";
import TransferProduct from "../domain/TransferProduct";

let fetchAllCancelToken = undefined;

export const cancelPrevWarehouseTransferFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllWarehouseTransfer = async () => {
  try {
    const {data} = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return data.map(transfer => WarehouseTransfer.map(transfer));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const getAllProductsByWarehouseTransferId = async transferId => {
  try {
    const {data} = await axiosInstance.post("/Inventario_Movimiento_Det/sel", {
      nu_id_movimiento: transferId
    });
    return data.map(p => TransferProduct.map(p));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createWarehouseTransfer = async data => {
  //console.log("CREATE TRANSFER DATA: ",data);
  //return 1;
  try {
    const response = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_ins",
      {
        nu_id_almacen_orig: data.warehouseOrigin.value,
        nu_id_almacen_dest: data.warehouseDestiny.value,
        vc_usuario: getUserName(),
        ls_detalle: data.products.map(e => ({
          nu_id_producto: e.value,
          nu_cantidad: e.amount
        }))
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editWarehouseTransfer = async (id, data) => {
  //console.log("EDIT TRANSFER: ",id, data);
  //return 1;
  try {
    const response = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_upd",
      {
        nu_id_movimiento: id,
        nu_id_almacen_orig: data.warehouseOrigin.value,
        nu_id_almacen_dest: data.warehouseDestiny.value,
        vc_usuario: getUserName(),
        ls_detalle: data.products.map(e => ({
          nu_id_producto: e.id,
          nu_cantidad: e.amount
        }))
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeStateWarehouseTransfer = async id => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_del",
      {
        nu_id_movimiento: id
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportTransferExcelFile = async file => {
  try {
    const {data} = await axiosInstance.post("/Documentos/ins_excel_traspaso", {
      archivobase64: file.base64File,
      contentType: file.contentType,
      extension: file.extension,
      nombre: file.name.split(".")[0]
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const exportOtBreakdownExcelFile = async file => {
  try {
    const {data} = await axiosInstance.post("/Documentos/ins_excel_desglose", {
      archivobase64: file.base64File,
      contentType: file.contentType,
      extension: file.extension,
      nombre: file.name.split(".")[0]
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
