import {withStyles} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import {PaginationItem} from "@material-ui/lab";

export const CustomPagination = withStyles(theme => ({
  root: {
    padding: "8px"
  }
}))(Pagination);

export const CustomPaginationItem = withStyles(theme => ({
  root: {
    color: "white",
    borderColor: "white"
  },
  selected: {
    backgroundColor: "#adadad"
  }
}))(PaginationItem);
