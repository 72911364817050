import moment from "moment";
import DestinationProduct from "./DestinationProduct";
import DestinationTag from "./DestinationTag";

class Destination {
  constructor(
    id,
    description,
    state,
    stateDescription,
    action,
    reference,
    referenceNumber,
    clientId,
    headquarterId,
    name,
    headquarterName,
    fiscal,
    phone,
    street,
    number,
    door,
    postalCode,
    district,
    province,
    country,
    latitude,
    longitude,
    startDate,
    endDate,
    email,
    comment,
    history,
    products,
    tags,
    typeId,
    deliveryNotes,
  ) {
    this.id = id;
    this.description = description;
    this.state = state;
    this.stateDescription = stateDescription;
    this.action = action;
    this.reference = reference;
    this.referenceNumber = referenceNumber;
    this.clientId = clientId;
    this.headquarterId = headquarterId;
    this.name = name;
    this.headquarterName = headquarterName;
    this.fiscal = fiscal;
    this.phone = phone;
    this.street = street;
    this.number = number;
    this.door = door;
    this.postalCode = postalCode;
    this.district = district;
    this.province = province;
    this.country = country;
    this.latitude = latitude;
    this.longitude = longitude;
    this.startDate = startDate;
    this.endDate = endDate;
    this.startTime = moment(startDate).format("HH:mm");
    this.endTime = moment(endDate).format("HH:mm");
    this.email = email;
    this.comment = comment;
    this.history = history;
    this.products = products;
    this.tags = tags;
    this.typeId = typeId;
    this.deliveryNotes = deliveryNotes;
  }

  static map = destination => {
    return new Destination(
      destination.nu_id_despacho,
      destination.vc_desc_despacho || " ",
      destination.nu_id_estado_actual,
      destination.vc_desc_estado_actual,
      destination.nu_id_accion,
      destination.bi_referencia ? 1 : 2,
      destination.vc_numero_referencia,
        destination.nu_id_entidad_cliente,
        destination.nu_id_entidad_sede_clente,
      destination.vc_nombre,
      destination.vc_nombre_sede,
      destination.bi_sede_fiscal,
      destination.vc_telefono,
      destination.vc_calle,
      destination.vc_numero,
      destination.vc_piso,
      destination.vc_cod_postal,
      destination.vc_municipio,
      destination.vc_provincia,
      destination.vc_pais,
      destination.nu_latitud,
      destination.nu_longitud,
      destination.dt_fec_ini,
      destination.dt_fec_fin,
      destination.vc_correo,
      destination.vc_comentario,
      destination.estados.map(state => ({
        name: state.vc_desc_estado,
        date: state.dt_fec_reg,
        inChecklist: state.checklist_entrada,
        outChecklist: state.checklist_salida,
        user: state.vc_usuario
      })),
      destination.productos.map(product => DestinationProduct.map(product)),
      destination.tags.map(tag => DestinationTag.map(tag)),
      destination.nu_id_tipo_despacho,
        destination.albaranes.map(e => ({id: e.nu_id_albaran_generado})),
    );
  };
}

export default Destination;
