import React from 'react';
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const TagsTableHeader = () => {
    return (
        <StyledTableRow>
            <StyledTableCell>
                <IntlMessages id="logistic.work.orders.dialog.destination.tags.table.description" />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id="logistic.work.orders.dialog.destination.tags.table.type" />
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default TagsTableHeader;