import {Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";

export const LastUpdateText = withStyles(theme => ({
  root: {
    color: "#d51e20",
    marginLeft: "8px",
    marginTop: "6px"
  }
}))(Typography);

export const HistoryButton = withStyles(theme => ({
  root: {
    color: "#d51e20",
    textDecoration: "underline"
  }
}))(Button);

export const useStyles = makeStyles((theme) => ({
  historyListContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
  },
  text: {
    gridColumnEnd: 'span 4',
  },
  date: {
    gridColumnEnd: 'span 5',
  },
  historyListItem: {
    display: 'flex',
    justifyContent: 'center'
  },
  outIcon: {
    transform: 'rotateY(-180deg)'
  }
}));

export const CostumedAssignmentReturnIcon = withStyles(theme => ({
  root: {
    cursor: 'pointer',
  }
}))(AssignmentReturnIcon);