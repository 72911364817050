import {
  ADD_REJECTION_TYPE,
  APPLY_REJECTION_TYPES_FILTERS,
  EDIT_REJECTION_TYPE,
  FETCH_ALL_REJECTION_TYPES,
  RESET_CURRENT_REJECTION_TYPE,
  RESET_REJECTION_TYPES_FILTERS,
  RESET_REJECTION_TYPES_STATE,
  SET_CURRENT_REJECTION_TYPE
} from "../../types/Logistic/RejectionTypesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    description: "",
    code: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_REJECTION_TYPES: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_REJECTION_TYPES_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_REJECTION_TYPES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case SET_CURRENT_REJECTION_TYPE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_REJECTION_TYPE: {
      return {
        ...state,
        current: null
      };
    }
    case ADD_REJECTION_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_REJECTION_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case RESET_REJECTION_TYPES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
