class WarehouseTransfer {
  constructor(
    id,
    idEntityOrigin,
    entityNameOrigin,
    idCampusOrigin,
    campusNameOrigin,
    idWarehouseOrigin,
    warehouseNameOrigin,
    idEntityDestiny,
    entityNameDestiny,
    idCampusDestiny,
    campusNameDestiny,
    idWarehouseDestiny,
    warehouseNameDestiny,
    date,
    active
  ) {
    this.id = id;
    this.idEntityOrigin = idEntityOrigin;
    this.entityNameOrigin = entityNameOrigin;
    this.idCampusOrigin = idCampusOrigin;
    this.campusNameOrigin = campusNameOrigin;
    this.idWarehouseOrigin = idWarehouseOrigin;
    this.warehouseNameOrigin = warehouseNameOrigin;
    this.idEntityDestiny = idEntityDestiny;
    this.entityNameDestiny = entityNameDestiny;
    this.idCampusDestiny = idCampusDestiny;
    this.campusNameDestiny = campusNameDestiny;
    this.idWarehouseDestiny = idWarehouseDestiny;
    this.warehouseNameDestiny = warehouseNameDestiny;
    this.date = date;
    this.active = active;
    this.transfer = [];
    this.product = [];
  }

  static map = warehouseTransfer => {
    return new WarehouseTransfer(
      warehouseTransfer.nu_id_movimiento,
      warehouseTransfer.nu_id_entidad_orig,
      warehouseTransfer.vc_nombre_entidad_orig,
      warehouseTransfer.nu_id_entidad_sede_orig,
      warehouseTransfer.vc_nombre_sede_orig,
      warehouseTransfer.nu_id_almacen_orig,
      warehouseTransfer.vc_desc_almacen_orig,
      warehouseTransfer.nu_id_entidad_dest,
      warehouseTransfer.vc_nombre_entidad_dest,
      warehouseTransfer.nu_id_entidad_sede_dest,
      warehouseTransfer.vc_nombre_sede_dest,
      warehouseTransfer.nu_id_almacen_dest,
      warehouseTransfer.vc_desc_almacen_dest,
      warehouseTransfer.dt_fec_movimiento,
      warehouseTransfer.bi_estado === "1"
    );
  };

  set setTransferProducts(products) {
    this.transfer = products;
    this.product = products.map(p => p.label);
  }
}

export default WarehouseTransfer;
