import React from "react";
import {Alert} from "@material-ui/lab";
import BaseAlert from "../../../Common/SnackBars/BaseAlert";
import {useDispatch, useSelector} from "react-redux";
import {resetUnResolvedState} from "../../../../redux/actions/Supervisor/UnresolvedAdvances";

const UpdateAdvanceStateAlert = ({}) => {
  const {response} = useSelector(state => state.supervisor.unresolvedAdvances);
  const dispatch = useDispatch();
  return (
    response.status !== null &&
    (response.status === true ? (
      <BaseAlert
        setOpen={() => dispatch(resetUnResolvedState())}
        open={response.status === true}
      >
        <Alert
          onClose={() => () => dispatch(resetUnResolvedState())}
          severity="success"
        >
          {response.message}
        </Alert>
      </BaseAlert>
    ) : (
      <BaseAlert
        setOpen={() => dispatch(resetUnResolvedState())}
        open={response.status === false}
      >
        <Alert
          onClose={() => dispatch(resetUnResolvedState())}
          severity="error"
        >
          {response.message}
        </Alert>
      </BaseAlert>
    ))
  );
};

export default UpdateAdvanceStateAlert;
