import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import PettyCashType from "../domain/PettyCashType";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevPettyCashTypeFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllPettyCashTypes = async () => {
  try {
    const types = await axiosInstance.post(
      "/Caja_Chica_Tipo_Caja_Chica/sel",
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return types.data.map(type => PettyCashType.map(type));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const fetchPettyCashTypeById = async id => {
  try {
    const type = await axiosInstance.post("/Caja_Chica_Tipo_Caja_Chica/get", {
      nu_id_tipo_caja_chica: id,
      nu_id_entidad: getCompanyEntityId()
    });
    return PettyCashType.map(type.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPettyCashType = async data => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Tipo_Caja_Chica/ins",
      {
        nu_id_entidad: getCompanyEntityId(),
        vc_desc_tipo_caja_chica: data.description,
        bi_aprobacion_anticipo: data.approvalOfAdvances,
        bi_obligacion_anticipo: data.obligationOfAdvances,
        bi_conforme_automatico: data.automaticSatisfied,
        vc_usuario: "USER"
      }
    );
    const result = Response.map(response.data);
    if (result.transactionState === "0") {
      throw new Error(result.transactionMessage);
    }
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updatePettyCashType = async (editingId, data) => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Tipo_Caja_Chica/upd",
      {
        nu_id_tipo_caja_chica: parseInt(editingId),
        nu_id_entidad: getCompanyEntityId(),
        vc_desc_tipo_caja_chica: data.description,
        bi_aprobacion_anticipo: data.approvalOfAdvances,
        bi_obligacion_anticipo: data.obligationOfAdvances,
        vc_usuario: "USER"
      }
    );
    const {transactionState, transactionMessage, transactionKey} = Response.map(
      response.data
    );
    if (transactionState === "1") {
      return transactionKey;
    } else {
      throw new Error(transactionMessage);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeStatePettyCashType = async id => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Tipo_Caja_Chica/del",
      {
        nu_id_tipo_caja_chica: id,
        nu_id_entidad: getCompanyEntityId()
      }
    );
    const {transactionState, transactionMessage, transactionKey} = Response.map(
      response.data
    );
    if (transactionState === "1") {
      return transactionKey;
    } else {
      throw new Error(transactionMessage);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
