import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import TableCellWithSorting from "../../../Common/Tables/TableCellWithSorting";

const TableHeader = ({orderBy, order, onSort, isGuardian}) => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="guardian.advance.detail.expenses.header.advances" />
    </StyledTableCell>
    <TableCellWithSorting
      property="costCenter"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="cost.center" />
    </TableCellWithSorting>
      <TableCellWithSorting
      property="event"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="guardian.drivers.expenseDetails.tableHeader.event" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="documentType"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="guardian.drivers.expenseDetails.tableHeader.documentType" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="amount"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="guardian.drivers.expenseDetails.tableHeader.amount" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="state"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="guardian.drivers.expenseDetails.tableHeader.state" />
    </TableCellWithSorting>
    <StyledTableCell>
      <IntlMessages id="guardian.drivers.expenseDetails.tableHeader.evidence" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="guardian.drivers.expenseDetails.tableHeader.rejection.reasons" />
    </StyledTableCell>
    {!isGuardian && (
      <StyledTableCell>
        <IntlMessages id={"guardians.operative.plannings.table.actions"} />
      </StyledTableCell>
    )}
  </StyledTableRow>
);

export default TableHeader;
