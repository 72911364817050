import React from "react";
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import FilesTable from "./FilesTable";
import FilesDialog from "./FilesDialog";
import ContainedButton from "../../../Common/Buttons/ContainedButton";

const FilesArea = ({formId = "", files, setFiles}) => {
  const id = formId + "form-files-area";
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Box
        display="flex"
        flexDirection="row"
        my={4}
        justifyContent="space-between"
        style={{backgroundColor: "#3f51b5"}}
        py={4}
      >
        <Box mx={4}>
          <Typography variant="h2" style={{color: "white"}}>
            <IntlMessages id={"fleet.management.common.form.files"} />
          </Typography>
        </Box>
        <Box mx={4}>
          <ContainedButton
            onClick={() => setOpenAddDialog(true)}
            text={
              <IntlMessages
                id={"fleet.management.common.form.files.add.button"}
              />
            }
          />
        </Box>
      </Box>
      {files.length > 0 && <FilesTable files={files} setFiles={setFiles} />}
      {openAddDialog && (
        <FilesDialog
          listFiles={files}
          setListFiles={setFiles}
          id={id}
          open={openAddDialog}
          setOpen={setOpenAddDialog}
        />
      )}
    </Box>
  );
};

export default FilesArea;
