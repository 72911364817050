import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  ADD_ELEVATOR_CONTRACT,
  APPLY_ELEVATORS_CONTRACTS_FILTERS,
  EDIT_ELEVATOR_CONTRACT,
  FETCH_ALL_ELEVATORS_CONTRACTS,
  RESET_CURRENT_ELEVATOR_CONTRACT,
  RESET_ELEVATORS_CONTRACTS_FILTERS,
  RESET_ELEVATORS_CONTRACTS_STATE,
  SET_CURRENT_ELEVATOR_CONTRACT
} from "../../types/ElevatorsManagement/ContractsTypes";
import {
  createElevatorContract,
  getAllElevatorsContracts,
  updateElevatorContract,
  updateElevatorContractState
} from "../../../services/ElevatorsManagement/ContractsService";
import ElevatorContract from "../../../domain/ElevatorsManagement/ElevatorContract";

export const resetElevatorsContractsState = () => ({
  type: RESET_ELEVATORS_CONTRACTS_STATE
});
export const applyElevatorsContractsFilters = filters => ({
  type: APPLY_ELEVATORS_CONTRACTS_FILTERS,
  payload: filters
});
export const resetElevatorsContractsFilters = () => ({
  type: RESET_ELEVATORS_CONTRACTS_FILTERS
});
export const resetCurrentElevatorContract = () => ({
  type: RESET_CURRENT_ELEVATOR_CONTRACT
});
export const setCurrentElevatorContract = current => ({
  type: SET_CURRENT_ELEVATOR_CONTRACT,
  payload: current
});
export const fetchAllElevatorsContracts = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllElevatorsContracts(filters)
      .then(contracts => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_ELEVATORS_CONTRACTS, payload: contracts});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const editElevatorContract = (current, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorContract(current, data.name)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.name = data.name;
        dispatch({type: EDIT_ELEVATOR_CONTRACT, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editElevatorContractState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorContractState(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.state = state;
        dispatch({type: EDIT_ELEVATOR_CONTRACT, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addElevatorContract = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createElevatorContract(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newContract = new ElevatorContract(
          response.transactionKey,
          data.name,
          true
        );
        dispatch({type: ADD_ELEVATOR_CONTRACT, payload: newContract});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
