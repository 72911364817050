import {
  APPLY_EMPLOYEES_LIST_DOCUMENTS_FILTERS,
  APPLY_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS,
  FETCH_ALL_LIST_EMPLOYEES_DOCUMENTS,
  FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS,
  FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_VIEWS, FETCH_EMPLOYEES_DOCUMENTS_DASHBOARD,
  RESET_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_DATA,
  RESET_CURRENT_LIST_DOCUMENT,
  RESET_EMPLOYEES_DOCUMENTS_STATE,
  RESET_EMPLOYEES_LIST_DOCUMENTS_FILTERS,
  RESET_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS,
  SET_CURRENT_LIST_DOCUMENT
} from "../../types/RRHH/DocumentationTypes";

const INIT_STATE = {
  list: {
    filters: {
      vehicle: "",
      types: [],
      active: true,
      expiration: "",
      tags: []
    },
    data: [],
    current: null
  },
  summary: {
    filters: {
      view: "",
      expiration: "",
      vehicle: "",
      tags: []
    },
    views: [],
    data: [],
    headers: [],
    selectedInfo: {
      viewId: "",
      name: "",
      isPublic: true,
      editable: null
    }
  },
  dashboard: {
    data: [],
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_LIST_EMPLOYEES_DOCUMENTS: {
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload
        }
      };
    }
    case APPLY_EMPLOYEES_LIST_DOCUMENTS_FILTERS: {
      return {
        ...state,
        list: {
          ...state.list,
          filters: action.payload
        }
      };
    }
    case RESET_EMPLOYEES_LIST_DOCUMENTS_FILTERS: {
      return {
        ...state,
        list: {
          ...state.list,
          filters: {
            ...INIT_STATE.list.filters
          }
        }
      };
    }
    case SET_CURRENT_LIST_DOCUMENT: {
      return {
        ...state,
        list: {
          ...state.list,
          current: action.payload
        }
      };
    }
    case RESET_CURRENT_LIST_DOCUMENT: {
      return {
        ...state,
        list: {
          ...state.list,
          current: null
        }
      };
    }

    case APPLY_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS: {
      return {
        ...state,
        summary: {
          ...state.summary,
          filters: action.payload
        }
      };
    }
    case RESET_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS: {
      return {
        ...state,
        summary: {
          ...state.summary,
          filters: {
            ...INIT_STATE.summary.filters
          }
        }
      };
    }
    case FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS: {
      return {
        ...state,
        summary: {
          ...state.summary,
          data: action.payload.data,
          headers: action.payload.headers,
          selectedInfo: action.payload.selectedInfo
        }
      };
    }
    case FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_VIEWS: {
      return {
        ...state,
        summary: {
          ...state.summary,
          views: action.payload
        }
      };
    }

    case RESET_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_DATA: {
      return {
        ...state,
        summary: {...INIT_STATE.summary}
      };
    }
    case FETCH_EMPLOYEES_DOCUMENTS_DASHBOARD: {
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          data: action.payload
        }
      };
    }
    case RESET_EMPLOYEES_DOCUMENTS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
