import React from "react";
import Filters from "./Filters";
import Viewer from "./Viewer";
import {useSelector} from "react-redux";

const PettyCashReports = () => {
  const {file} = useSelector(({controlPanel}) => controlPanel.kpi.current);
  return (
    <React.Fragment>
      <Filters />
      {file && <Viewer />}
    </React.Fragment>
  );
};

export default PettyCashReports;
