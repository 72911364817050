import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";

const initialData = {
  active: undefined,
  entityOrigin: "",
  entityDestiny: "",
  date: "",
  product: "",
  OT: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.entityOrigin}
            id="entity-origin-filter"
            label={
              <IntlMessages id="logistic.traceability.filter.entityOrigin" />
            }
            onChange={value => setFormData("entityOrigin", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.entityDestiny}
            id="entity-destiny-filter"
            label={
              <IntlMessages id="logistic.traceability.filter.entityDestiny" />
            }
            onChange={value => setFormData("entityDestiny", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
