import React from "react";
import {DefaultStyledTableRow, StyledTableCell} from "./styles";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({orderBy, order, onSort, isGuardian}) => {
  return (
    <DefaultStyledTableRow>
      <StyledTableCell />
      <TableCellWithSorting property="name">
        <IntlMessages id={"rrhh.page.historicalSigning.header.day"} />
      </TableCellWithSorting>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.historicalSigning.header.employee"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.historicalSigning.header.marker"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.historicalSigning.header.prev"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.historicalSigning.header.totalWorked"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.historicalSigning.header.dif"} />
      </StyledTableCell>
      <StyledTableCell />
      {/* {!isGuardian && (
        <StyledTableCell align="left">
          <IntlMessages id={'guardians.operative.plannings.table.actions'} />
        </StyledTableCell>
      )} */}
    </DefaultStyledTableRow>
  );
};

export default TableHeader;
