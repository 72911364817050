class TagType {
  constructor(id, description, state) {
    this.id = id;
    this.description = description;
    this.state = state;
    this.tags = [];
  }

  static map = type => {
    return new TagType(
      type.nu_id_tipo_tag,
      type.vc_desc_tipo_tag,
      type.bi_estado === "1"
    );
  };
}

export default TagType;
