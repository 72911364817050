import React from 'react';
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {useSelector} from "react-redux";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import {parseDateToDateString} from "../../../../../../utils/dates";
import {timeOverDayToTimeDay} from "../../../../../../utils/parsers";
import Typography from "@material-ui/core/Typography";

const HistoryTable = ({onClickOt}) => {
    const {currentDestination, filters} = useSelector(
        state => state.administrator.breakdown
    );
    return (
        <TableContainer
            head={<TableHeader isOt={Boolean(filters.ot)} />}
        >
            {currentDestination?.history?.map((row, index) =>(
              <StyledTableRow key={index}>
                  <StyledTableCell>
                      {parseDateToDateString(row.date)}
                  </StyledTableCell>
                  <StyledTableCell>
                      <Typography
                          style={{
                              textDecoration: 'underline',
                              cursor: 'pointer'
                          }}
                          onClick={() => onClickOt(row.ot)}>
                          {row.ot}
                      </Typography>
                  </StyledTableCell>
                  {filters.ot?
                      <>
                          <StyledTableCell>
                              {row.travelStartDate}
                          </StyledTableCell>
                          <StyledTableCell>
                              {row.travelEndDate}
                          </StyledTableCell>
                      </>
                      :<>
                          <StyledTableCell>
                              {timeOverDayToTimeDay(row.arriveTime)}
                          </StyledTableCell>
                          <StyledTableCell>
                              {timeOverDayToTimeDay(row.departureTime)}
                          </StyledTableCell>
                      </>
                  }
                  <StyledTableCell>
                      {row.routeTime}
                  </StyledTableCell>
                  <StyledTableCell>
                      {row.routeKilometers}
                  </StyledTableCell>
                  <StyledTableCell>
                      {row.stayTime}
                  </StyledTableCell>
                  <StyledTableCell>
                      {row.stayKilometers}
                  </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableContainer>
    );
};

export default HistoryTable;