import React from "react";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const TableRow = ({headquarter, onEdit, onDelete}) => (
  <StyledTableRow>
    <StyledTableCell>{headquarter.name}</StyledTableCell>
    <StyledTableCell>{headquarter.fiscal ? "Si" : "No"}</StyledTableCell>
    <StyledTableCell>{headquarter.address}</StyledTableCell>
    <StyledTableCell>{headquarter.number}</StyledTableCell>
    <StyledTableCell>{headquarter.postalCode}</StyledTableCell>
    <StyledTableCell>{headquarter.municipality}</StyledTableCell>
    <StyledTableCell>{headquarter.province}</StyledTableCell>
    <StyledTableCell>{headquarter.country}</StyledTableCell>
    <StyledTableCell>{headquarter.latitude}</StyledTableCell>
    <StyledTableCell>{headquarter.longitude}</StyledTableCell>
    <StyledTableCell>
      <IconButton onClick={() => onEdit(headquarter)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onDelete(headquarter)}>
        <DeleteIcon />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
);

export default TableRow;
