import React, {useEffect, useCallback} from "react";
import "./index.css";
import {makeStyles} from "@material-ui/core/styles";
import {connect, useSelector, useDispatch} from "react-redux";
import FilterBuilder from "utils/filters";
import currentDay from "redux/actions/RRHH/DailyOperations/CurrentDay";
import Filters from "components/RRHH/DailyOperations/Filters";
import Box from "@material-ui/core/Box";
import PageContainer from "components/RRHH/DailyOperations/PageContainer";
import Table from "components/RRHH/DailyOperations/Table/";

import useUiState from "hooks/Administrator/useUiState";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

const DailyOperations = ({tableData, dispatch}) => {
  const ui = useUiState(tableData);
  useEffect(() => {
    dispatch(currentDay.get());
    // return () => dispatch(warehouse.resetState());
  }, []);

  const applyFilters = useCallback(
    filters => dispatch(currentDay.applyCurrentDayFilters(filters)),
    [ dispatch ]
  );

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        /*onClose={resetFilters}*/ showLoadingIndicator={
          ui.showLoadingIndicator
        }
      />
      <div style={{marginTop: 30}}>
        <Table data={tableData} />
      </div>
    </PageContainer>
  );
};
// filtrado local.
const mapStateToProps = state => {
  const {data, filters} = state.rrhh.currentDay;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("name", filters.name)
    .build();
  return {tableData: filtered};
};
// export default DailyOperations;
export default connect(mapStateToProps)(DailyOperations);
