import ChecklistTypeGroup from "./ChecklistTypeGroup";

class ChecklistType {
  constructor(id, name, groups, state, markOption) {
    this.id = id;
    this.name = name;
    this.groups = groups;
    this.total =
      groups.length > 0
        ? groups.map(g => g.list.length).reduce((total, num) => total + num)
        : 0;
    this.groupsNames = this.setGroupNames();
    this.state = state;
    this.markOption = markOption;
  }

  setGroupNames() {
    return this.groups.length > 0
      ? this.groups.map(g => g.name).toString()
      : "";
  }

  addGroup(group) {
    this.groups.push(group);
    this.groupsNames = this.setGroupNames();
  }

  editGroup(group) {
    this.groups = this.groups.map(g => {
      if (group.id === g.id) {
        g.name = group.name;
      }
      return g;
    });
    this.groupsNames = this.setGroupNames();
  }

  addOptionToGroup(groupId, option) {
    this.groups = this.groups.map(g => {
      if (groupId === g.id) {
        g.list.push(option);
      }
      return g;
    });
    ++this.total;
  }

  editOptionOfGroup(group, item) {
    this.groups = this.groups.map(g => {
      if (group.id === g.id) {
        group.updateItem(item);
      }
      return g;
    });
  }

  static map = type => {
    return new ChecklistType(
      type.nu_id_tipo_checklist,
      type.vc_desc_tipo_checklist,
      type.grupos.map(g => ChecklistTypeGroup.map(g)),
      type.bi_estado_tipo,
        type.nu_id_tipo_checklist_respuestas_opciones,
    );
  };
}

export default ChecklistType;
