import React from "react";
import CdsReports from "./CdsReports";
import {useSelector} from "react-redux";
import OverTruckReports from "./OverTruckReports";
import ActivityReports from "./ActivityReports";

const LocationReports = () => {
    const {type: selected} = useSelector(
        ({controlPanel}) => controlPanel.kpi.current
    );
    if(selected === 23){
        return <OverTruckReports />;
    }
    if (selected >= 25 && selected<=27) {
        return <ActivityReports />;
    }
    return <CdsReports/>;
};

export default LocationReports;
