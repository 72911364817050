import axiosInstance from "../../../../utils/axiosInstance";
import KoneIndicator from "../../../../domain/ControlPanel/KoneIndicator";

export const getAllKoneIndicators = async () => {
    try {
        const {data} = await axiosInstance
            .post("/Informes/sel_list_indicadores_kone",
                {});
        return data.map(indicator => KoneIndicator.map(indicator));
    } catch (e) {
        return [];
    }
}