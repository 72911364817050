import React from "react";
import PageContainer from "../../../../../components/Configuration/UserSettings/Roles/PageContainer";
import RolesTable from "../../../../../components/Configuration/UserSettings/Roles/RolesTable";
import {useDispatch, useSelector} from "react-redux";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {
  resetConfigurationRoleState,
  setCurrentConfigurationRole,
  updateConfigurationRoleState
} from "../../../../../redux/actions/Configuration/Roles";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddRoleDialog from "../../../../../components/Configuration/UserSettings/Roles/AddRoleDialog";
import EditRoleDialog from "../../../../../components/Configuration/UserSettings/Roles/EditRoleDialog";

const Roles = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(({configuration}) => configuration.roles);
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);

  React.useEffect(() => {
    return () => {
      dispatch(resetConfigurationRoleState());
    };
  }, []);
  return (
    <PageContainer>
      <RolesTable
        openEdit={() => setOpenEdit(true)}
        openDelete={() => setOpenDelete(true)}
        openRestore={() => setOpenRestore(true)}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages id={"configuration.user.settings.roles.delete.text"} />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(setCurrentConfigurationRole(null));
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(updateConfigurationRoleState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages id={"configuration.user.settings.roles.restore.text"} />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(setCurrentConfigurationRole(null));
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(updateConfigurationRoleState(current, true));
        }}
      />
      <AddRoleDialog openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <EditRoleDialog openEdit={openEdit} setOpenEdit={setOpenEdit} />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
    </PageContainer>
  );
};

export default Roles;
