import {
  APPLY_SPECIFICATIONS_VEHICLES_FILTERS,
  FETCH_ALL_SPECIFICATIONS_VEHICLES_STARTED,
  FETCH_ALL_SPECIFICATIONS_VEHICLES_SUCCEED,
  RESET_CURRENT_SPECIFICATION,
  RESET_SPECIFICATIONS_VEHICLES_FILTERS,
  SET_CURRENT_SPECIFICATION
} from "../../types/FleetManagement/TechnicalSpecificationsTypes";
import {
  fetchAllVehiclesSpecifications,
  getVehicleSheetById,
  updateBasicSheet,
  updateTechnicalSheet,
  updateVehicleImage
} from "../../../services/FleetManagement/Vehicles/VehiclesService";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {getFileUrlByName} from "../../../services/FileService";
import {createTag, updateTag} from "../../../services/TagService";
import {addVehicleType} from "../../../services/FleetManagement/Vehicles/VehicleTypeService";

const fetchAllVehiclesSpecificationsSucceed = vehicles => {
  return dispatch => {
    dispatch({
      type: FETCH_ALL_SPECIFICATIONS_VEHICLES_SUCCEED,
      payload: vehicles
    });
  };
};

export const fetchAllVehiclesSpecificationsStarted = () => {
  return dispatch => {
    dispatch({type: FETCH_ALL_SPECIFICATIONS_VEHICLES_STARTED});
    fetchAllVehiclesSpecifications()
      .then(vehicles => {
        dispatch(fetchAllVehiclesSpecificationsSucceed(vehicles));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const applySpecificationsFilters = filters => ({
  type: APPLY_SPECIFICATIONS_VEHICLES_FILTERS,
  payload: filters
});
export const resetSpecificationsFilters = () => ({
  type: RESET_SPECIFICATIONS_VEHICLES_FILTERS
});
export const resetCurrentVehicleSpecification = () => ({
  type: RESET_CURRENT_SPECIFICATION
});
export const setCurrentVehicleSpecification = current => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const sheet = await getVehicleSheetById(current);
      const image = await getFileUrlByName(sheet.url);
      sheet.url = image;
      dispatch(fetchSuccess());
      dispatch({type: SET_CURRENT_SPECIFICATION, payload: sheet});
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const editFileImage = (vehicle, image) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateVehicleImage(vehicle, image)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(
          () => dispatch(fetchAllVehiclesSpecificationsStarted(vehicle)),
          500
        );
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editVehicleBasicSheet = (vehicle, data, onAccept, onError) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateBasicSheet({id: vehicle.id, ...data})
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        onAccept && onAccept();
        setTimeout(
          () => dispatch(fetchAllVehiclesSpecificationsStarted()),
          1500
        );
      })
      .catch(e => {
        dispatch(fetchError(e.message));
        onError && onError();
      });
  };
};

export const editVehicleTechnicalSheet = (vehicle, data, onAccept, onError) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateTechnicalSheet({id: vehicle.id, ...data})
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        onAccept && onAccept();
        setTimeout(
          () => dispatch(fetchAllVehiclesSpecificationsStarted()),
          1500
        );
      })
      .catch(e => {
        dispatch(fetchError(e.message));
        onError && onError();
      });
  };
};

export const editVehicleTag = tag => {
  return async dispatch => {
    dispatch(fetchStart());
    updateTag(tag)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const createVehicleTag = tag => {
  return async dispatch => {
    dispatch(fetchStart());
    createTag(tag)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const createVehicleType = data => {
  return async dispatch => {
    dispatch(fetchStart());
    addVehicleType(data)
      .then(response => dispatch(fetchSuccess(response.transactionMessage)))
      .catch(e => dispatch(fetchError(e.message)));
  };
};
