import {mapToBoolean} from "../../../utils/mappers";

class VehicleBrand {
  constructor(id, name, status) {
    this.id = id;
    this.name = name;
    this.status = status;
  }

  static map = brand => {
    return new VehicleBrand(
      brand.nu_id_marca,
      brand.vc_marca,
      mapToBoolean(brand.bi_estado)
    );
  };
}
export default VehicleBrand;
