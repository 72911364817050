import {
  FETCH_INCIDENCE_SUCCEEDED,
  INCIDENCE_CREATE
} from "../../types/Administrator/Incidence";

const INIT_STATE = {
  data: [],
  filters: {
    origin: "",
    destination: "",
    fromDate: null,
    toDate: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_INCIDENCE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data,
        filters: { ...action.payload.filters }
      };
    }
    case INCIDENCE_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    default:
      return state;
  }
};
