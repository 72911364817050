import React from "react";
import PropTypes from "prop-types";
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import Form from "./Form";

const VehicleDetailDialog = ({initialData, open, onClose, onSubmit}) => {
    const id = "vehicle-detail-dialog";
    return (
        <FormDialog id={id} isOpen={open} onClose={onClose}>
            {open &&
                initialData && (
                    <Form id={id} initialData={initialData} onClose={onClose} onSubmitForm={onSubmit}/>
                )}
        </FormDialog>
    );
};

VehicleDetailDialog.propTypes = {
    initialData: PropTypes.object,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
};

export default VehicleDetailDialog;
