import {
  ADD_CONFIG_ROLE,
  APPLY_CONFIG_ROLES_FILTERS,
  EDIT_CONFIG_ROLE,
  EDIT_CONFIG_ROLE_STATE,
  FETCH_ALL_CONFIG_ROLES,
  RESET_CONFIG_ROLES_FILTERS,
  RESET_CONFIG_ROLES_STATES,
  SET_CURRENT_CONFIG_ROLE
} from "../../types/Configuration/RolesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    code: "",
    description: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CONFIG_ROLES: {
      let roles = {};
      action.payload.map(u => {
        roles[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: roles
      };
    }

    case ADD_CONFIG_ROLE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }

    case EDIT_CONFIG_ROLE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            ...action.payload
          }
        }
      };
    }

    case EDIT_CONFIG_ROLE_STATE: {
      const id = action.payload.id;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            state: action.payload.state
          }
        }
      };
    }

    case SET_CURRENT_CONFIG_ROLE: {
      return {
        ...state,
        current: action.payload
      };
    }

    case APPLY_CONFIG_ROLES_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }

    case RESET_CONFIG_ROLES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }

    case RESET_CONFIG_ROLES_STATES: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
