import React from "react";
import Select from "../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const AutomaticStateSelect = ({
  id,
  label,
  value,
  setValue,
  disabled,
  states
}) => {
  return (
    <Select
      id={id}
      label={label}
      disabled={disabled}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {states.map(s => (
        <MenuItem key={`type-${s.id}`} value={s.id}>
          {s.name}
        </MenuItem>
      ))}
    </Select>
  );
};
export default AutomaticStateSelect;
