import React from 'react';
import {Box, Button, FormControlLabel, Grid, Typography} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import FilterListIcon from '@material-ui/icons/FilterList';
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import ChatIcon from "@material-ui/icons/Chat";
import SendIcon from '@material-ui/icons/Send';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import {AlertButton, ChatButton, ExportButton, StatesGridContainer} from "./styles";
import {EventNote} from "@material-ui/icons";
import Checkbox from "../../../Common/Forms/Checkbox";
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {useSelector} from "react-redux";

const InfoBar = ({setOpenFilters, search, onSearch, expandAll, setExpandAll,
                     openChatDialog, openAlertsDialog, openDispatchesDialog}) => {
    const {alerts, info: generalData} = useSelector(state => state.logistic.monitoring);
    return (
        <Box display="flex" flexDirection="column">
            <GridContainer spacing={4}>
                <Grid item xs={12} sm={3}>
                    <Typography>
                        {generalData?.vehiclesNumber} <IntlMessages id={"logistic.monitoring.vehicles.on.route"}/>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={3} style={{display: "flex", flexDirection: "row"}}>
                    <ImportExportIcon style={{color: "#00c8ca"}} />
                    <Typography>
                        {generalData?.completed}% <IntlMessages id={"logistic.monitoring.completed"}/>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end">
                        {/*<ChatButton*/}
                        {/*    onClick={() => openChatDialog()}*/}
                        {/*    variant={"contained"}*/}
                        {/*    startIcon={<ChatIcon />}>*/}
                        {/*    <IntlMessages id={"logistic.monitoring.chat"}/>*/}
                        {/*</ChatButton>*/}
                        <AlertButton
                            onClick={() => openAlertsDialog()}
                            offcolor={(alerts.data.filter(alert => !alert.read).length > 0).toString()}
                            variant={"contained"}
                            startIcon={<ReportProblemIcon />}>
                            <IntlMessages id={"logistic.monitoring.alert"}/>
                        </AlertButton>
                        <ExportButton
                            onClick={() => openDispatchesDialog()}
                            variant={"contained"}
                            startIcon={<SendIcon />}
                        >
                            <IntlMessages id={"logistic.monitoring.dispatches"}/>
                        </ExportButton>
                    </Box>
                </Grid>
            </GridContainer>
            <StatesGridContainer spacing={4}>
                <Grid container item xs={12} spacing={4}>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <EventAvailableIcon style={{color: "#4caf50", marginRight: "8px"}} />
                            <Typography
                                variant="h4">
                                {generalData?.onTimeOk}{' '}<IntlMessages id={"logistic.monitoring.time.ok"}/></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <EventAvailableIcon style={{color: "#ff8f00", marginRight: "8px"}} />
                            <Typography
                                variant="h4"
                            >{generalData?.lateOk}{' '}<IntlMessages id={"logistic.monitoring.late.ok"}/></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <EventNote style={{color: "#ff8f00", marginRight: "8px"}} />
                            <Typography
                                variant="h4"
                            >{generalData?.latePartial}{' '}<IntlMessages id={"logistic.monitoring.late.partial"}/></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <EventBusyIcon style={{color: "#ff8f00", marginRight: "8px"}} />
                            <Typography
                                variant="h4"
                            >{generalData?.lateReject}{' '}<IntlMessages id={"logistic.monitoring.late.total"}/></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <EventBusyIcon style={{color: "#4caf50", marginRight: "8px"}} />
                            <Typography
                                variant="h4"
                            >{generalData?.onTimePartial}{' '}<IntlMessages id={"logistic.monitoring.time.partial"}/></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <EventNote style={{color: "#4caf50", marginRight: "8px"}} />
                            <Typography
                                variant="h4"
                            >{generalData?.onTimeReject}{' '}<IntlMessages id={"logistic.monitoring.time.total"}/></Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <CalendarTodayIcon
                                style={{color: "#888888", marginRight: "8px"}}
                            />
                            <Typography
                                variant="h4"
                            >{generalData?.pending}{' '}<IntlMessages id={"logistic.monitoring.pending"}/></Typography>
                        </Box>
                    </Grid>
                </Grid>
            </StatesGridContainer>
            <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                <OutlinedInput
                    style={{marginRight: "32px"}}
                    fullWidth
                    value={search}
                    onChange={value => onSearch(value)}
                    label={<IntlMessages id={"logistic.monitoring.search"}/>}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={expandAll}
                            onChange={e => setExpandAll(!expandAll)}
                            name="expand-check"
                            indeterminate
                        />
                    }
                    label={expandAll?
                        <IntlMessages id={"logistic.monitoring.minimize.all"} /> :
                        <IntlMessages id={"logistic.monitoring.expand.all"} />}
                />
                <Button
                    style={{bottom: "4px"}}
                    startIcon={<FilterListIcon />}
                    variant={"contained"}
                    onClick={() => setOpenFilters(true)}
                >
                    <IntlMessages id={"logistic.monitoring.filters.button"} />
                </Button>
            </Box>
        </Box>
    );
}

export default InfoBar;