import {
  ADD_WORKSHOP_REQUEST,
  APPLY_WORKSHOP_REQUEST_FILTERS,
  CLOSE_DIALOG_WORKSHOP_REQUEST,
  EDIT_WORKSHOP_REQUEST,
  FETCH_ALL_WORKSHOP_REQUESTS,
  OPEN_DIALOG_WORKSHOP_REQUEST,
  RESET_WORKSHOP_REQUEST_FILTERS,
  RESET_WORKSHOP_REQUEST_STATE,
  SET_CURRENT_WORKSHOP_REQUEST
} from "../../types/FleetManagement/WorkshopRequestTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import WorkshopRequest from "../../../domain/FleetManagement/DailyOperations/WorkshopRequest";
import WorkshopTask from "../../../domain/FleetManagement/DailyOperations/WorkshopTask";
import {
    createWorkshopRequest,
    getAllWorkshopRequests, getWorkshopRequestById, updateWorkshopRequest
} from "../../../services/FleetManagement/DailyOperation/WorkshopRequestService";
import {addWorkshopTaskToRequest} from "../../../services/FleetManagement/DailyOperation/WorkshopTaskService";
import {cubicIn} from "@amcharts/amcharts4/.internal/core/utils/Ease";

export const resetWorkshopRequestState = () => ({
  type: RESET_WORKSHOP_REQUEST_STATE
});
export const applyWorkshopRequestFilters = filters => ({
  type: APPLY_WORKSHOP_REQUEST_FILTERS,
  payload: filters
});
export const resetWorkshopRequestFilters = () => ({
  type: RESET_WORKSHOP_REQUEST_FILTERS
});
export const setCurrentWorkshopRequest = current => async dispatch => {
    if(!current) {
        dispatch({
            type: SET_CURRENT_WORKSHOP_REQUEST,
            payload: null,
        })
    }else {
        dispatch(fetchStart());
        getWorkshopRequestById(current)
            .then(request => {
                dispatch(fetchSuccess());
                dispatch({
                    type: SET_CURRENT_WORKSHOP_REQUEST,
                    payload: request
                });
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }

}

export const openWorkshopRequestDialog = name => ({
  type: OPEN_DIALOG_WORKSHOP_REQUEST,
  payload: name
});
export const closeWorkshopRequestDialog = () => ({
  type: CLOSE_DIALOG_WORKSHOP_REQUEST
});

export const fetchAllWorkshopRequests = filters => {
  return async dispatch => {
    dispatch(fetchStart());
      getAllWorkshopRequests()
          .then(requests => {
              dispatch(fetchSuccess());
              dispatch({type: FETCH_ALL_WORKSHOP_REQUESTS, payload: requests});
          }).catch(e => {
              dispatch(fetchError(e.message));
      });
  };
};

export const addWorkshopRequest = (request, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
      createWorkshopRequest(request)
          .then(response => {
              dispatch(fetchSuccess(response.transactionMessage));
              onClose && onClose();
              setTimeout(() => dispatch(fetchAllWorkshopRequests({})), 1500);
          }).catch(e => {
          dispatch(fetchError(e.message));
      });
  };
};

export const editWorkshopRequest = (request) => {
  return async dispatch => {
    dispatch(fetchStart());
      updateWorkshopRequest(request)
          .then(response => {
              dispatch(fetchSuccess(response.transactionMessage));
              setTimeout(() => dispatch(fetchAllWorkshopRequests({})), 1500);
          }).catch(e => {
          dispatch(fetchError(e.message));
      });
  };
};

export const addWorkshopTask = (data) => {
    return (dispatch, getState) => {
        dispatch(fetchStart());
        const {current} = getState().fleetManagement.workshopRequests;
        addWorkshopTaskToRequest({workshopRequestId: current.id, ...data})
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => dispatch(setCurrentWorkshopRequest(current)), 1500);
            }).catch(e => {
                dispatch(fetchError(e.message));
        });
    }
}
