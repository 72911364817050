import {
  FETCH_INCIDENCE_TYPE_SUCCEEDED,
  APPLY_INCIDENCE_TYPE_FILTERS,
  RESET_INCIDENCE_TYPE_FILTERS,
  INCIDENCE_TYPE_CHANGE_STATE,
  INCIDENCE_TYPE_EDIT,
  INCIDENCE_TYPE_CREATE
} from "../../types/Administrator/IncidenceTypes";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_INCIDENCE_TYPE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_INCIDENCE_TYPE_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_INCIDENCE_TYPE_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case INCIDENCE_TYPE_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, {active: !type.active});
          return type;
        })
      };
    }
    case INCIDENCE_TYPE_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case INCIDENCE_TYPE_EDIT: {
      return {
        ...state,
        data: state.data.map(type => {
          if (Number(type.id) === Number(action.payload.id))
            return {...type, description: action.payload.description};
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};

/*import * as IncidenceTypes from "redux/types/Administrator/IncidenceTypes"

//dataItem = {id, descripcion}
const INIT_STATE = {
  data: [
    {id: 1, description: "data1", active: true},
    {id: 2, description: "data2", active: true},
    {id: 3, description: "data3", active: true},
    {id: 4, description: "data4", active: true},
  ],
  filters: {
    active: undefined,
    description: '',
  },
};

export default (state = INIT_STATE, { type, payload })=>{
  let copy = null;
  let index = -1;
  switch(type){
    case IncidenceTypes.RESET_DATA:
      return INIT_STATE
    case IncidenceTypes.ADD_DATA:
      //payload: {id, descripcion, active}
      copy = [...state.data];
      copy.push(payload);
      return {...state, data: copy}
    case IncidenceTypes.REMOVE_DATA:
      //payload: id
      copy = [...state.data];
      index = copy.findIndex(item=>item.id===payload);
      copy[index].active = false;
      console.log("copy remove>>> ", copy);
      return {...state, data: copy}
    case IncidenceTypes.EDIT_DATA:
      // const newState = {...state, data: state.data.map(item => item.id===payload.id ? {...item, description: payload.newDescription} : item)}
      //payload: {id, newDescription}
      copy = [...state.data];
      index = copy.findIndex(item=>item.id===payload.id);
      console.log("newDesc =>", payload.newDescription)
      copy[index].description = payload.newDescription;
      console.log("copy edit>>> ", copy);
      return {...state, data: copy}
    case IncidenceTypes.SET_FILTER:
      //payload: {active, description}
      return {...state, filters: payload}
    case IncidenceTypes.RESET_FILTER:
      return INIT_STATE  
    default: return state
  }
}*/
