import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/AdvanceTypes/PageContainer";
import CreateAdvanceTypeDialog from "components/Administrator/AdvanceTypes/SaveAdvanceTypeDialog/CreateAdvanceTypeDialog";
import EditAdvanceTypeDialog from "components/Administrator/AdvanceTypes/SaveAdvanceTypeDialog/EditAdvanceTypeDialog";
import DeleteConfirmation from "components/Administrator/AdvanceTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/AdvanceTypes/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/AdvanceTypes/Table";
import Filters from "components/Administrator/AdvanceTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";

import useUiState from "hooks/Administrator/useUiState";

import advanceTypes from "redux/actions/Administrator/AdvanceTypes";
import FilterBuilder from "../../../../utils/filters";
import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";

const AdvanceTypes = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(advanceTypes.get());
      return () => dispatch(advanceTypes.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(advanceTypes.applyAdvanceTypesFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(advanceTypes.resetAdvanceTypesFilters());

  const onDelete = () => dispatch(advanceTypes.delete(ui.deletingId));
  const onRestore = () => dispatch(advanceTypes.restore(ui.restoringId));
  const onEdit = data => dispatch(advanceTypes.edit(data, ui.editingId));
  const onCreate = data => dispatch(advanceTypes.create(data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateAdvanceTypeDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditAdvanceTypeDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.advanceTypes;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("description", filters.description)
    .byFieldEquals("approvalOfExpenses", filters.approvalOfExpenses)
    .byFieldEquals("obligationOfOT", filters.obligationOfOT)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(AdvanceTypes);
