import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import FuelSelect from "./FuelSelect";
import StatusSelect from "../../../../../../Common/Forms/Select/StatusSelect";

const Form = ({formData, setFormData, edit}) => {
  return (
    <form id="vehicle-technical-sheet-form">
      <GridContainer spacing={1}>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.engine"
                }
              />
            }
            fullWidth
            value={formData.engine}
            onChange={value => setFormData("engine", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.bodywork"
                }
              />
            }
            fullWidth
            value={formData.bodywork}
            onChange={value => setFormData("bodywork", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.cylinder"
                }
              />
            }
            fullWidth
            value={formData.cylinder}
            onChange={value => setFormData("cylinder", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.power"
                }
              />
            }
            fullWidth
            value={formData.power}
            onChange={value => setFormData("power", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <FuelSelect
            value={formData.fuel}
            onChange={value => setFormData("fuel", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.volume"
                }
              />
            }
            fullWidth
            value={formData.volume}
            type="number"
            onChange={value => setFormData("volume", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.axles"
                }
              />
            }
            fullWidth
            value={formData.axles}
            type="number"
            onChange={value => setFormData("axles", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.passengers"
                }
              />
            }
            fullWidth
            value={formData.passengers}
            type="number"
            onChange={value => setFormData("passengers", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.color"
                }
              />
            }
            fullWidth
            value={formData.color}
            onChange={value => setFormData("color", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.door"
                }
              />
            }
            fullWidth
            value={formData.door}
            onChange={value => setFormData("door", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.speed"
                }
              />
            }
            fullWidth
            value={formData.speed}
            onChange={value => setFormData("speed", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.seat"
                }
              />
            }
            fullWidth
            value={formData.seat}
            onChange={value => setFormData("seat", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.radius"
                }
              />
            }
            fullWidth
            value={formData.radius}
            onChange={value => setFormData("radius", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.key"
                }
              />
            }
            fullWidth
            value={formData.key}
            onChange={value => setFormData("key", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.control"
                }
              />
            }
            fullWidth
            value={formData.control}
            onChange={value => setFormData("control", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.extras"
                }
              />
            }
            fullWidth
            value={formData.extras}
            onChange={value => setFormData("extras", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <StatusSelect
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.state"
                }
              />
            }
            fullWidth
            value={formData.state}
            onChange={value => setFormData("state", value)}
            disabled={!edit}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.technical.observations"
                }
              />
            }
            fullWidth
            value={formData.observations}
            onChange={value => setFormData("observations", value)}
            disabled={!edit}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
