import {combineReducers} from "redux";
import costCenterType from "./costCenterType";
import taxes from "./taxes";
import services from "./services";
import budgets from "./budgets";
import bills from "./bills";
import creditNotes from "./creditNotes";

export default combineReducers({
    costCenterType,
    taxes,
    services,
    budgets,
    bills,
    creditNotes,
});