import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  defaultBigBox: {
    display: "flex",
    flexDirection: "column",
    gap: "10px"
  },
  defaultShortBox: {
    display: "flex",
    flexDirection: "column"
  },
  principalItemBox: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    gap: "10px"
  },
  titleArea: {
    backgroundColor: theme.palette.horizontalNav.textActiveColor,
    textAlign: "start",
    color: "#ffffff",
    paddingLeft: "8px",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    marginBottom: "2px"
  },
  buttonBox: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    gap: "8px"
  },
  complementaryBox: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    marginTop: "32px"
  },
  complementaryListBox: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  complementaryItemBox: {
    display: "flex",
    flexDirection: "column"
  },
  automaticsBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginTop: "32px"
  },
  automaticsListBox: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  automaticsItemBox: {}
}));
