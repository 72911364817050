class Unit {
  constructor(id, description, active) {
    this.id = id;
    this.description = description;
    this.active = active;
  }

  static map = unit => {
    return new Unit(unit.nu_id_unidad, unit.vc_desc_unidad, unit.bi_estado);
  };
}

export default Unit;
