import React from "react";
import RoleSelect from "./RoleSelect";
import useForm from "../../../../../../hooks/Common/useForm";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import VehicleRoleSelect from "./VehicleRoleSelect";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";

const Form = ({id, initialData, onEditUser}) => {
  const {formData, setFormData, handleSubmit} = useForm({
    roles: initialData.roles || [],
    vehicleRoles: initialData.vehicleRoles || []
  });
  return (
    <form id={id} onSubmit={handleSubmit(onEditUser)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <RoleSelect
            label={
              <IntlMessages
                id={"configuration.user.settings.users.edit.form.role"}
              />
            }
            fullWidth
            value={formData.roles}
            setValue={value => setFormData("roles", value)}
            id={"edit-user-roles"}
          />
        </Grid>
        <Grid item xs={12}>
          <VehicleRoleSelect
            label={
              <IntlMessages
                id={"configuration.user.settings.users.edit.form.vehicle.role"}
              />
            }
            fullWidth
            value={formData.vehicleRoles}
            setValue={value => setFormData("vehicleRoles", value)}
            id={"edit-user-vehicle-roles"}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
