import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "./Form";

const ViewTripInformationDialog = ({
  initialData,
  isOpen,
  onClose,
  onSubmit
}) => (
  <FormDialog
    id="view-trip-information-forms"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.dataCollection.viewTripFormTitle" />}
    onClose={onClose}
  >
    <Form
      id="view-trip-information-form"
      initialData={initialData}
      onSubmit={onSubmit}
    />
  </FormDialog>
);

export default ViewTripInformationDialog;
