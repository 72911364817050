import React from 'react';
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {useDispatch, useSelector} from "react-redux";
import {
    getExcelOfDispatchesHistoryByOT,
    getExcelOfOTDispatchHistory
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import {Box, Button} from "@material-ui/core";

const DestinationDetails = () => {
    const {currentDestination, filters} = useSelector(
        state => state.administrator.breakdown
    );
    const dispatch = useDispatch();
    const downloadExcel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(!filters.ot) {
            dispatch(getExcelOfOTDispatchHistory(currentDestination?.route?.origin, currentDestination?.route?.destination));
        }else {
            dispatch(getExcelOfDispatchesHistoryByOT(filters.ot))
        }
    }
    return (
        <GridContainer spacing={4}>
            <Grid item container xs={12} md={6} spacing={2} alignItems='center'>
                {!filters?.ot
                    ?<>
                        <Grid item xs={12} md={6}>
                            <OutlinedInput
                                value={currentDestination?.route?.origin}
                                label={<IntlMessages id='origin'/>}
                                disabled
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <OutlinedInput
                                value={currentDestination?.route?.destination}
                                label={<IntlMessages id='destination'/>}
                                disabled
                                fullWidth
                            />
                        </Grid>
                    </>
                    :<Grid item xs={12}>
                        <Typography
                            variant='h3'
                            color='primary'
                            style={{
                                border: '1px solid #3f51b5',
                                padding: '1rem'
                            }}
                        >
                            <IntlMessages id="route" />: {currentDestination.route.route}
                        </Typography>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Button
                        variant='contained'
                        color="primary"
                        onClick={downloadExcel}
                    >
                        <IntlMessages id="generate.format.excel" />
                    </Button>
                </Grid>
            </Grid>

            <Grid item xs={12} md={6} container spacing={2}>
                <Grid item xs={12} md={6}
                      style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '.5rem'
                      }}
                >
                    <Typography variant='subtitle1' color='primary'>
                        <IntlMessages id='trajectory'/>{' '}<IntlMessages id='hh.mm'/>
                    </Typography>
                    <OutlinedInput
                        value={currentDestination?.estimatedTime?.average}
                        label={<IntlMessages id='average'/>}
                        disabled
                        fullWidth
                    />
                    <OutlinedInput
                        value={currentDestination?.estimatedTime?.googleTime}
                        label={<IntlMessages id='google'/>}
                        disabled
                        fullWidth
                    />
                    <OutlinedInput
                        value={currentDestination?.estimatedTime?.centralTime}
                        label={<IntlMessages id='matrix'/>}
                        disabled
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12} md={6}
                      style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '.5rem'
                      }}
                >
                    <Typography variant='subtitle1' color='primary'>
                        <IntlMessages id='trajectory'/>{' '}<IntlMessages id='kms'/>
                    </Typography>
                    <OutlinedInput
                        value={currentDestination?.estimatedTime?.traveledKilometers}
                        label={<IntlMessages id='average'/>}
                        disabled
                        fullWidth
                    />
                    <OutlinedInput
                        value={currentDestination?.estimatedTime?.googleKilometers}
                        label={<IntlMessages id='google'/>}
                        disabled
                        fullWidth
                    />
                    <OutlinedInput
                        value={currentDestination?.estimatedTime?.centralKilometers}
                        label={<IntlMessages id='matrix'/>}
                        disabled
                        fullWidth
                    />
                </Grid>
            </Grid>
        </GridContainer>
    );
};

export default DestinationDetails;