import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import ElevatorCategory from "../../domain/ElevatorsManagement/ElevatorCategory";

export const getAllElevatorsCategories = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Categoria/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(category => ElevatorCategory.map(category));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createElevatorCategory = async category => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Categoria/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_categoria: category.name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorCategory = async (category, name) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Categoria/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_categoria: category.id,
      vc_desc_tipo_categoria: name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorCategoryState = async (category, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Categoria/del", {
      nu_id_tipo_categoria: category.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
