import axiosInstance from "../../utils/axiosInstance";
import {mapToBoolean} from "../../utils/mappers";

export const fetchAllDocumentsTypes = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/TipoDocumento/sel_fe'
        );
        return data.map(type => ({
            value: type.nu_id_tipo_documento,
            name: type.vc_desc_tipo_documento,
            state: mapToBoolean(type.bi_estado),
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}