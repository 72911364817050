import React from "react";

import TableHeader from "./TableHeader";
import TableComponent from "components/Common/Tables/Table";
import TableRow from "./TableRow";

const Table = ({data, isLoading}) => {
  return (
    <TableComponent
      data={data}
      isLoading={isLoading}
      head={<TableHeader />}
      renderRow={({row}) => <TableRow rowData={row} />}
      getRowKey={row => row.label}
    />
  );
};

export default Table;
