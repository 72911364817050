import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "components/Common/Forms/Select/ActiveSelect";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";

const initialData = {
  name: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.description}
            id="name-filter"
            label={<IntlMessages id="rrhh.page.dailyOperations.input.name" />}
            onChange={value => setFormData("name", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
