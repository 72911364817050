import React from "react";

import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableValues = ({row}) => (
  <React.Fragment>
    <StyledTableCell>{row.names}</StyledTableCell>
    <StyledTableCell>
      {row.tags.map(tag => <div key={tag}>{tag}</div>)}
    </StyledTableCell>
  </React.Fragment>
);

export default TableValues;
