import axiosInstance from "../../../utils/axiosInstance";
import Response from "../../../domain/Response";
export const deleteUserFromDatabase = async (userId, companyId) => {
  try {
    const {data} = await axiosInstance.post("/Usuario/del_usuario", {
      nu_id_entidad: userId,
      nu_cod: companyId
    });
    return Response.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};
