import {CLEAR_CURRENT_BOX, UPDATE_CURRENT_BOX} from "../../types/CustomTypes";

const initState = {
  tags: {
    planning: [],
    cost: []
  },
  box: "",
  fund: 0,
  cash: 0,
  traffic: 0,
  liquidated: 0,
  refund: 0,
  state: null
};

export default (state = initState, action) => {
  switch (action.type) {
    case CLEAR_CURRENT_BOX: {
      return {
        ...state,
        ...initState
      };
    }
    case UPDATE_CURRENT_BOX: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};
