import React from "react";
import PropTypes from "prop-types";

import TableSortLabel from "@material-ui/core/TableSortLabel";
import StyledTableCell from "../StyledTableCell";
import {createStyles, makeStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";

const StyledTableSortLabel = withStyles(theme =>
  createStyles({
    root: {
      color: "white",
      "&:hover": {
        color: "white"
      },
      "&$active": {
        color: "white"
      }
    },
    active: {},
    icon: {
      color: "inherit !important"
    }
  })
)(TableSortLabel);

const useStyles = makeStyles(() => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const TableCellWithSorting = ({
  orderBy,
  order,
  onSort,
  property,
  children,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <StyledTableCell
      sortDirection={orderBy === property ? order : false}
      {...rest}
    >
      <StyledTableSortLabel
        active={orderBy === property}
        direction={orderBy === property ? order : "asc"}
        onClick={() => onSort(property)}
      >
        {children}
        {orderBy === property ? (
          <span className={classes.visuallyHidden}>
            {order === "desc" ? "sorted descending" : "sorted ascending"}
          </span>
        ) : null}
      </StyledTableSortLabel>
    </StyledTableCell>
  );
};

TableCellWithSorting.propTypes = {
  orderBy: PropTypes.string,
  order: PropTypes.oneOf([ "asc", "desc" ]),
  onSort: PropTypes.func,
  property: PropTypes.string.isRequired,
  children: PropTypes.node
};

TableCellWithSorting.defaultProps = {
  orderBy: undefined,
  order: "asc",
  onSort: () => {},
  children: null
};

export default TableCellWithSorting;
