import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

export const checklistBasicValidations = {
    name: [
        { rule: v => v.toString().trim() !== '', message: <IntlMessages id="form.field.error" />}
    ],
    markOption: [
        { rule: v => v.toString().trim() !== '', message: <IntlMessages id={"form.field.error"} />}
    ]
}