import React from 'react';
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => {
    return (
        <StyledTableRow>
            <StyledTableCell>
                <IntlMessages id='date' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='duration' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='distance' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='user' />
            </StyledTableCell>

        </StyledTableRow>
    );
};

export default TableHeader;