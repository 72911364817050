import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const ControlSelect = ({name = 'control', value, onChange, error, errorMessage, required = false, disabled = false}) => {
    const controlType = [
        {
            id: 1,
            name: 'Aplicable'
        },
        {
            id: 2,
            name: 'No Aplicable'
        }
    ]
    const handleOnChange = ({target}) => {
        const {value} = target;
        onChange(value);
    }
    return (
        <Select
            label={<IntlMessages id="control.t"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
            disabled={disabled}
        >
            {controlType?.map(type => (
                <MenuItem key={type.id} value={type.id}>
                    {type.name}
                </MenuItem>
            ))}
        </Select>
    );
};
export default ControlSelect;