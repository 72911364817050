import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";
import CommonMultiSelect from "./CommonMultiSelect";
import {useSelector} from "react-redux";

const initialData = {
  hoppers: [],
  trailers: [],
  clients: [],
  states: [],
  saleOrganizing: [],
  drivers: [],
  serviceOrder: ""
};

const Form = ({onFilter}) => {
  const id = "service-list-filters";
  const {data} = useSelector(({logistic}) => logistic.serviceList);
  const {formData, setFormData} = useForm(initialData);
  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form id={id}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-hoppers"}
            options={[
              ...new Set(Object.values(data).map(e => e.hopper).sort())
            ]}
            filter={formData.hoppers}
            onChange={value => setFormData("hoppers", value)}
            label={"Cabeza"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-trailers"}
            options={[
              ...new Set(Object.values(data).map(e => e.trailer).sort())
            ]}
            filter={formData.trailers}
            onChange={value => setFormData("trailers", value)}
            label={"Remolque"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-clients"}
            options={[
              ...new Set(Object.values(data).map(e => e.destination).sort())
            ]}
            filter={formData.clients}
            onChange={value => setFormData("clients", value)}
            limitTags={1}
            label={"Cliente"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-states"}
            options={[
              ...new Set(
                Object.values(data).map(e => e.statesDescription).sort()
              )
            ]}
            filter={formData.states}
            onChange={value => setFormData("states", value)}
            label={"Estado"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-saleOrganizing"}
            options={[
              ...new Set(Object.values(data).map(e => e.saleOrganizing).sort())
            ]}
            filter={formData.saleOrganizing}
            onChange={value => setFormData("saleOrganizing", value)}
            limitTags={1}
            label={"Pto Planificacion"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-drivers"}
            options={[
              ...new Set(Object.values(data).map(e => e.driver).sort())
            ]}
            filter={formData.drivers}
            onChange={value => setFormData("drivers", value)}
            limitTags={1}
            label={"Conductor"}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.serviceOrder}
            id={id + "-serviceOrder"}
            label={
              <IntlMessages id="logistic.distribution.daily.operation.services.list.table.service.order" />
            }
            onChange={value => setFormData("serviceOrder", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
