import Module from "./Configuration/Module";

class UserEntity {
  constructor(
    id,
    name,
    code,
    email,
    state,
    type = null,
    companyId,
    permissions,
    token,
    refreshToken
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.email = email;
    this.state = state;
    this.type = type;
    this.companyId = companyId;
    this.permissions = permissions;
    this.token = token;
    this.refreshToken = refreshToken;
  }

  static map = entity => {
    return new UserEntity(
      entity.nu_id_entidad_empleado,
      entity.vc_nombre,
      entity.vc_cif_codigo,
      entity.vc_email,
      entity.bi_estado,
      UserEntity.mapType(entity.datos_bi),
      entity.nu_id_empresa_seleccionada,
      entity.modulos.map(m => Module.map(m)),
        entity.vc_token,
        entity.vc_refresh_token,
    );
  };

  static mapType = type => {
    let types = [];
    if (type.bi_administrador === "1") {
      types.push("administrator");
    }
    if (type.bi_gestor_caja === "1") {
      types.push("guardian");
    }
    if (type.bi_supervisor === "1") {
      types.push("supervisor");
    }
    return types;
  };
}
export default UserEntity;
