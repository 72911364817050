import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ItemsTable from "./ItemsTable";
import {dialogsList} from "../../../constants";
import FormDialog from "../../../../../../Common/Dialogs/FormDialog";
import {closeDialog} from "../../../../../../../redux/reducers/Logistic/orders";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const BillItemsDialog = () => {
    const id = dialogsList.billItems;
    const {currentOrder, dialogs} = useSelector(({logistic}) => logistic.orders);
    const items = currentOrder?.items?.filter(item => item.billable)
        || [];
    const isOpen = dialogs.includes(id);
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    const onSubmit = () => {
        onClose();
    }
    return (
        <FormDialog
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            title={<IntlMessages id="bill" />}
        >
            <ItemsTable items={items} onSubmit={onSubmit} id={id}/>
        </FormDialog>
    );
};

export default BillItemsDialog;