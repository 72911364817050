import {fetchStart, fetchSuccess} from "../Common";
import {
  fetchAllHistoricalSinging,
  fetchAllDriverSelect
} from "../../../services/RRHH/HistoricalSigningService";
import {
  FETCH_HISTORICALSINGING_SUCCEEDED,
  FETCH_DRIVER_SELECT
} from "../../types/RRHH/HistoricalSigning";

import SigningTypes from "../../../domain/RRHH/SigningTypes";
import {getCompanyEntityId} from "../../../services/StorageService";
import {Data} from "@react-google-maps/api";

const fetchHistoricalSigningSucceeded = data => ({
  type: FETCH_HISTORICALSINGING_SUCCEEDED,
  payload: {
    data
  }
});

const fetchAllDriverSelectSecceeded = data => ({
  type: FETCH_DRIVER_SELECT,
  payload: {
    data
  }
});
export const fetchHistoricalSigning = filters => async dispatch => {
  dispatch(fetchStart());
  // cancelPrevWarehouseFetchAll();s
  try {
    const singingTypes = await fetchAllHistoricalSinging(filters);
    dispatch(fetchSuccess());
    dispatch(fetchHistoricalSigningSucceeded(singingTypes));
  } catch (error) {
    // dispatch(ui.fetchFailed());
  }
};

export const fetchDriverSelect = () => {
  return async dispatch => {
    // dispatch(fetchStart());
    // cancelPrevDriverSelectFetchAll();
    fetchAllDriverSelect().then(driverSelect => {
      // dispatch(fetchSuccess());
      dispatch(fetchAllDriverSelectSecceeded(driverSelect));
    });
  };
};
