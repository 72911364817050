import React from "react";
import CmtTimeLineItem from "../../../../../../@coremat/CmtTimeLine/CmtTimeLineItem";
import CmtTimelineContent from "../../../../../../@coremat/CmtTimeLine/CmtTimelineContent";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid
} from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import CheckInOutDetail from "./CheckInOutDetail";
import EstimatedTimeTable from "./EstimatedTimeTable";
import RealTimeTable from "./RealTimeTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  details: {
    alignItems: "center"
  }
}));

const TimeLineItem = ({destination, final = false}) => {
  const classes = useStyles();
  return (
    <CmtTimeLineItem
      point={<AssignmentTurnedInIcon style={{color: "blue"}} />}
      content={
        <CmtTimelineContent isWrapper={true}>
          <GridContainer style={{marginBottom: "16px"}}>
            <Grid item xs={12} sm={6}>
              <Box component="h2" fontSize={20} mt={0} mb={3}>
                <GridContainer spacing={2}>
                  <Grid item sm={12} md={2}>
                    <IntlMessages
                      id={"administrator.ot.breakdown.destination.title"}
                    />:
                  </Grid>
                  <Grid item sm={12} md={10}>
                    <p>{destination.route.origin}</p>
                    <p>{destination.route.destination}</p>
                  </Grid>
                </GridContainer>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <CheckInOutDetail realTime={destination.realTime} final={final} />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Box mt={4}>
              <Accordion style={{marginBottom: "4px"}}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="destination-content"
                  id="destination-header"
                >
                  <RealTimeTable realTime={destination.realTime} />
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <React.Fragment>
                    <Divider variant="inset" flexItem />
                    <EstimatedTimeTable
                      estimatedTime={destination.estimatedTime}
                    />
                  </React.Fragment>
                </AccordionDetails>
              </Accordion>
            </Box>
          </GridContainer>
        </CmtTimelineContent>
      }
    />
  );
};

export default TimeLineItem;
