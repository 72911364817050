import VehicleReportSubgroup from "./VehicleReportSubgroup";

class VehicleReportGroup {
  constructor(id, description, subgroups) {
    this.id = id;
    this.description = description;
    this.subgroups = subgroups;
  }

  static map = group => {
    return new VehicleReportGroup(
      group.nu_id_tipo_checklist_grupo,
      group.vc_desc_tipo_checklist_grupo,
      group.listinfochecklistrespuestas.map(s => VehicleReportSubgroup.map(s))
    );
  };
}

export default VehicleReportGroup;
