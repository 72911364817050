import React from "react";
import PropTypes from "prop-types";

import FiltersContainer from "components/Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = ({onFilter, onClose, showLoadingIndicator}) => (
  <FiltersContainer
    marginBottom={5}
    active
    onClose={onClose}
    showLoadingIndicator={showLoadingIndicator}
  >
    <Form onFilter={onFilter} />
  </FiltersContainer>
);

Filters.propTypes = {
  onFilter: PropTypes.func,
  onClose: PropTypes.func,
  showLoadingIndicator: PropTypes.bool
};

Filters.defaultProps = {
  onFilter: () => {},
  onClose: () => {},
  showLoadingIndicator: false
};

export default Filters;
