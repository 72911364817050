import React from "react";
import {Box, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../../../hooks/Common/useForm";
import {useStyles} from "../../../styles";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {editOptionFromChecklistTypeGroup} from "../../../../../../../redux/actions/Logistic/ChecklistTypes";
import {useDispatch} from "react-redux";

const OptionForm = ({option, checklist, group}) => {
  const [ edit, setEdit ] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const {formData, setFormData, resetForm} = useForm({
    name: option.name
  });
  const onSave = () => {
    dispatch(
      editOptionFromChecklistTypeGroup(
        {...option, ...formData},
        checklist,
        group,
        setEdit
      )
    );
  };
  const onCancel = () => {
    resetForm();
    setEdit(false);
  };
  return (
    <Grid item xs={12} md={4}>
      <GridContainer spacing={2}>
        <Grid item xs={12} sm={edit ? 12 : 9}>
          <OutlinedInput
            id={"name-input"}
            value={formData.name}
            onChange={value => setFormData("name", value)}
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "logistic.distribution.checklist.types.table.edit.groups.option.add.name"
                }
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={edit ? 12 : 3}>
          <Box className={classes.buttonBox}>
            {edit ? (
              <React.Fragment>
                <ContainedButton
                  text={<IntlMessages id={"form.button.cancel"} />}
                  color={"primary"}
                  onClick={onCancel}
                />
                <ContainedButton
                  text={<IntlMessages id={"form.button.save"} />}
                  color={"primary"}
                  onClick={() => onSave()}
                />
              </React.Fragment>
            ) : (
              <ContainedButton
                text={<IntlMessages id={"form.button.edit"} />}
                color={"primary"}
                onClick={() => setEdit(true)}
              />
            )}
          </Box>
        </Grid>
      </GridContainer>
    </Grid>
  );
};

export default OptionForm;
