import React from "react";
import {
  fetchUserPermissions,
  loadUserPermissions,
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser
} from "../../../redux/actions/Auth";
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions";

import {Auth} from "aws-amplify";
import {getUserEntityByEmail} from "../../UserEntityService";
import {getUserId, resetUserData} from "../../StorageService";
import {deleteUserFromDatabase} from "../db/UserServices";

const CognitoAuth = {
  onRegister: ({email, password, newUserId, companyId}, onSuccess) => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        Auth.signUp(email, password, email)
          .then(authUser => {
            dispatch(fetchSuccess("User Created"));
            // dispatch(setAuthUser(authUser));
            onSuccess();
          })
          .catch(async error => {
            const response = await deleteUserFromDatabase(newUserId, companyId);
            dispatch(
              fetchError(error.message + "\n" + response.transactionMessage)
            );
            // dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogin: ({email, password}, history) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        Auth.signIn(email, password)
          .then(authUser => {
            getUserEntityByEmail(authUser.signInUserSession.accessToken.jwtToken).then(user => {
              dispatch(fetchSuccess());
              dispatch(setAuthUser({...authUser, companyId: user.companyId}));
              dispatch(fetchUserPermissions(user.permissions));
            });
          })
          .catch(error => {
            dispatch(fetchError(error.message));
            if (error.message.includes("not confirmed")) {
              history.push("/resend-signup");
            }
          })
          .catch(error => {
            dispatch(fetchError(error.message));
            if (error.message.includes("not confirmed")) {
              history.push("/resend-signup");
            }
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      try {
        Auth.signOut()
          .then(authUser => {
            resetUserData();
            dispatch(fetchSuccess());
            dispatch(setAuthUser(null));
          })
          .catch(error => {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onForgotPassword: ({email}, history) => {
    return dispatch => {
      dispatch(fetchStart());
      Auth.forgotPassword(email)
        .then(authUser => {
          setTimeout(() => {
            dispatch(setForgetPassMailSent(true));
            dispatch(fetchSuccess());
          }, 300);
        })
        .catch(error => {
          dispatch(fetchError(error.message));
          if (error.message.includes("no registered/verified")) {
            history && history.push("/resend-signup");
          }
        });
    };
  },
  forgotPasswordResetRequest: ({email, codeEntered, newPassword}) => {
    return dispatch => {
      dispatch(fetchStart());

      Auth.forgotPasswordSubmit(email, codeEntered, newPassword)
        .then(authUser => {
          setTimeout(() => {
            console.log(authUser);
            dispatch(fetchSuccess());
          }, 300);
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
      /* try {
				console.log(code);
				
			} catch (error) {
				dispatch(fetchError(error.message));
			} */
    };
  },
  getAuthUser: (loaded = false) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(loadUserPermissions());
      dispatch(updateLoadUser(loaded));
      setTimeout(() => {
        dispatch(fetchSuccess());
        dispatch(setAuthUser(JSON.parse(localStorage.getItem("user"))));
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
  onResendSignUp: (username, onSend) => {
    return dispatch => {
      dispatch(fetchStart());
      Auth.resendSignUp(username)
        .then(() => {
          dispatch(
            fetchSuccess("Se envio un correo de confirmacion a tu email.")
          );
          onSend && onSend();
        })
        .catch(error => {
          dispatch(fetchError(error.message));
        });
    };
  },
  onConfirmSignUp: (username, code, history) => {
    return dispatch => {
      dispatch(fetchStart());
      Auth.confirmSignUp(username, code)
        .then(() => {
          dispatch(fetchSuccess("User confirmed"));
          setTimeout(() => {
            history && history.goBack();
          }, 1500);
        })
        .catch(error => {});
    };
  }
};

export default CognitoAuth;
