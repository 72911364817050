import {useDispatch} from "react-redux";
import {fetchError, fetchStart, fetchSuccess} from "../../redux/actions";
import {downloadFile} from "../../services/FileService";

export const useDownloadLocalFile = () => {
    const dispatch = useDispatch();

    const downloadLocalFile = async (fileName, getFile, request) => {
        dispatch(fetchStart());
        try {
            const file = await getFile(request);
            await downloadFile(
                fileName,
                file
            );
            dispatch(fetchSuccess());
        } catch (e) {
            dispatch(fetchError(e.message));
        }
    }
    return {downloadLocalFile};
}