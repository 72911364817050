import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={"logistic.distribution.return.types.table.description"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.distribution.return.types.table.code"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
