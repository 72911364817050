import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {getUserType} from "../../../../../services/StorageService";
import {useSelector} from "react-redux";
import {Checkbox} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";

const TableHeader = ({orderBy, order, onSort, onSelectAll, isSelectedAll}) => {
  const {currentAccounting} = useSelector(state => state.guardian);
  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <Checkbox
              style={{
                color: blueGrey[100],
              }}
              checked={isSelectedAll}
              onChange={onSelectAll}
          />
        </StyledTableCell>
        <TableCellWithSorting
          property="ot"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id="guardian.accounting.dialog.expenses.table.header.ot" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="executor"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id="guardian.accounting.dialog.expenses.table.header.executor" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="costCenter"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id="cost.center" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="date"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id="guardian.accounting.dialog.expenses.table.header.date" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="type"
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        >
          <IntlMessages id="guardian.accounting.dialog.expenses.table.header.type" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="accountingAccount"
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        >
          <IntlMessages id="guardian.accounting.dialog.expenses.table.header.accounting.account" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="documentType"
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        >
          <IntlMessages id="document.type" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="documentNumber"
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        >
          <IntlMessages id="document.number" />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="amount"
          order={order}
          orderBy={orderBy}
          onSort={onSort}
        >
          <IntlMessages id="guardian.accounting.dialog.expenses.table.header.amount" />
        </TableCellWithSorting>
        {getUserType().includes("administrator") && (
          <StyledTableCell>
            <IntlMessages id="guardian.accounting.dialog.expenses.table.header.document" />
          </StyledTableCell>
        )}
        {getUserType().includes("guardian") &&
        (currentAccounting.state.id === "1" ||
          currentAccounting.state.id === "4") && (
          <StyledTableCell>
            <IntlMessages id="guardian.accounting.dialog.expenses.table.header.delete" />
          </StyledTableCell>
        )}
      </StyledTableRow>
    </React.Fragment>
  );
};

export default TableHeader;
