import React from "react";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import Form from "./Form";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";
import {useDispatch} from "react-redux";
import {addChecklistType} from "../../../../redux/actions/Logistic/ChecklistTypes";

const AddChecklistType = ({open, setOpen}) => {
  const id = "add-checklist-form";
  const dispatch = useDispatch();
  const onAddType = data => {
    dispatch(addChecklistType(data));
    setOpen(false);
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      title={
        <IntlMessages
          id={"logistic.distribution.checklist.types.table.add.title"}
        />
      }
      onClose={() => setOpen(false)}
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
    >
      <Box style={{overflow: "hidden"}}>
        {open && <Form id={id} onAddType={onAddType} />}
      </Box>
    </FormDialog>
  );
};

export default AddChecklistType;
