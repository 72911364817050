import React from 'react';
import {Grid, IconButton} from "@material-ui/core";
import ContactsList from "./ContactsList";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import OutlinedDateTimePicker from "../../../../../../Common/Forms/OutlinedDateTimePicker";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import AddressMap from "../../../../../WorkOrders/WorkOrderDialog/DestinationDetail/Form/AddressArea/AddressMap";
import Typography from "@material-ui/core/Typography";
import ClientHeadquarterSelect
    from "../../../../../WorkOrders/WorkOrderDialog/DestinationsDialog/ClientHeadquarteSelect";
const LocationForm = ({name, location}) => {
    const [showMap, setShowMap] = React.useState(false);
    const handleShowMap = () => {
        setShowMap(!showMap);
    }
    return (
        <fieldset>
            <legend>
                <Typography>
                    <IntlMessages id="data.of"/> <IntlMessages id={name}/>
                </Typography>
            </legend>
            <GridContainer spacing={2}
                           style={{
                               display: "flex",
                               alignItems: "center",
                           }}
            >
                <Grid item xs={10} md={6}>
                    <ClientHeadquarterSelect
                        label={<IntlMessages id={name}/>}
                        clientHeadquarter={location.location}
                        disabled
                        name={name}
                    />
                </Grid>
                <Grid item xs={2} md={1}>
                    <IconButton onClick={handleShowMap}>
                        <LocationOnIcon/>
                    </IconButton>
                </Grid>
                <Grid item xs={12} md={5}>
                    <OutlinedDateTimePicker
                        value={location.date}
                        label={<IntlMessages id="date"/>}
                        fullWidth
                        disabled
                    />
                </Grid>
                {showMap && <Grid item xs={12}>
                    {(location.latitude && location.longitude)
                        ? <AddressMap latitude={Number(location.latitude)} longitude={Number(location.longitude)}
                                      name={location.location.description}/>
                        : <Typography
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '5px',
                                border: "1px solid #000",
                            }}
                        >
                            <IntlMessages id="no.location.data"/>
                        </Typography>
                    }
                </Grid>}
                <Grid item xs={12}>
                    <OutlinedInput
                        value={location.indications}
                        multiline
                        rows={4}
                        label={<IntlMessages id="indications"/>}
                        fullWidth
                        disabled
                    />
                </Grid>
                <Grid item xs={12}
                      style={{
                          marginTop: '4px'
                      }}
                >
                    <ContactsList
                        name={name}
                        contacts={location.contacts}
                    />
                </Grid>
            </GridContainer>
        </fieldset>
    );
};

export default LocationForm;