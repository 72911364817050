import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import OutlinedDatePicker from "components/Common/Forms/OutlinedDatePicker";
import {mapDateToOutput, mapDateToValue} from "utils/parsers";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {resetRegulationFilters} from "../../../../../redux/actions/Administrator/Regulation";
import moment from "moment";

const initialData = {
  active: undefined,
  destiny: "",
  products: "",
  fromDate: moment(new Date().toISOString())
    .subtract(30, "days")
    .format("YYYY-MM-DD"),
  toDate: moment(new Date().toISOString()).format("YYYY-MM-DD")
};

const Form = ({onFilter}) => {
  const dispatch = useDispatch();
  const {formData, setFormData} = useForm(initialData);

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.destiny}
            id="destiny-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.destiny" />
            }
            onChange={value => setFormData("destiny", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.products}
            id="products-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.product" />
            }
            onChange={value => setFormData("products", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={formData.fromDate}
            id="date-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.fromDate" />
            }
            onChange={value => setFormData("fromDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={formData.toDate}
            id="date-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.toDate" />
            }
            onChange={value => setFormData("toDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button
            onClick={() => {
              dispatch(resetRegulationFilters());
            }}
            variant="contained"
          >
            <IntlMessages id={"filters.button.clear"} />
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button
            onClick={() => {
              onFilter(formData);
            }}
            variant="contained"
            color="primary"
          >
            <IntlMessages id={"filters.button.apply"} />
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
