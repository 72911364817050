import React from 'react';
import {Bar, CartesianGrid, ComposedChart, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {hoursToTime} from "../../../../../utils/parsers";

const BaseGraphic = ({id, title, data, barKey, barColor, barName, yAxisName, hours = false, onClickBar=()=>{}, type=0}) => {
    const CustomTooltip = ({active, payload, label}) => {
        if (active && payload && payload.length > 0) {
            return (
                <div >
                    <Typography variant="subtitle1" >{label}</Typography>
                    <Typography variant="body1" >{hoursToTime(payload[0].payload.drivingHours)}</Typography>
                </div>
            );
        }
        return <></>
    }
    const handleClick = (data) => {
        onClickBar && onClickBar(data, type);
    }
    return(
        <Box id={id}>
            <Typography variant="subtitle1" align="center">
                {title}
            </Typography>
            {data.length > 0
                ? <ResponsiveContainer width="100%" height={250}>
                    <ComposedChart layout="vertical" data={data} margin={{top: 10, right: 0, left: 0, bottom: 0}}>
                        <XAxis type="number" allowDecimals={false}/>
                        <YAxis dataKey="name" type="category" width={62}>
                            <Label value={yAxisName} offset={-5} position="insideTop"/>
                        </YAxis>
                        <Tooltip
                            labelStyle={{color: 'black'}}
                            itemStyle={{color: 'black'}}
                            cursor={type !== 0 &&{ stroke: 'red', strokeWidth: 10 }}
                            content={hours && <CustomTooltip />}
                        />
                        <Legend/>
                        <CartesianGrid stroke="#f5f5f5"/>
                        <Bar
                            cursor={type !== 0 && 'pointer'}
                            dataKey={barKey}
                            barSize={20}
                            fill={barColor}
                            name={barName}
                            onClick={handleClick}
                        />
                    </ComposedChart>
                </ResponsiveContainer>
                : <Box display="flex"
                       border="solid"
                       borderRadius="10px"
                       borderColor="#3f51b5"
                       width="100%"
                       height={250}
                       alignItems="center"
                       justifyContent="center">
                    <Typography variant="subtitle2" align="center">
                        <IntlMessages id={"fleet.management.tachograph.traffic.graphics.no.data"}/>
                    </Typography>
                </Box>
            }
        </Box>
    );
}

export default BaseGraphic;