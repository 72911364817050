import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {getAllElevatorsModels} from "../../../../../../../services/ElevatorsManagement/ModelsService";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";

const ModelSelect = ({id, value, setValue, disabled}) => {
  const {data: models} = useFetch(getAllElevatorsModels, {}, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.elevator.model"
          }
        />
      }
      fullWidth
    >
      {models.map(model => (
        <MenuItem value={model.id} key={model.id}>
          {model.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ModelSelect;
