import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="name"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouse.table.warehouseName" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="campusName"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouse.table.sede" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="allowNegativeStock"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouse.table.allowNegativeStock" />
    </TableCellWithSorting>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
