import React, {useRef} from "react";
import PropTypes from "prop-types";
import {Box, IconButton, TextField} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import BaseMap from "../../../../../../Common/Maps/Google/BaseMap";
import {Autocomplete, Marker} from "@react-google-maps/api";

const AddressArea = ({address, onChange}) => {
  const [ add, setAdd ] = React.useState(false);
  const [ center, setCenter ] = React.useState({
    lat: -12.099264,
    lng: -77.050987
  });
  const directionRef = useRef();
  const onAdd = () => {
    setAdd(!add);
  };
  const onChangeText = e => {
    onChange(e.target.value);
  };
  const onClickMap = event => {
    setCenter({lat: event.latLng.lat(), lng: event.latLng.lng()});
    let geocoder = new window.google.maps.Geocoder();
    geocoder.geocode(
      {
        latLng: event.latLng
      },
      function(results, status){
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            onChange(results[0].formatted_address);
          }
        }
      }
    );
  };
  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flexGrow={1}>
          <Autocomplete>
            <TextField
              id={"add-accident-address"}
              fullWidth
              value={address}
              onChange={onChangeText}
              label={
                <IntlMessages id={"fleet.management.accidents.form.address"} />
              }
              ref={directionRef}
              variant="outlined"
            />
          </Autocomplete>
        </Box>
        <IconButton onClick={onAdd}>
          {!add ? <AddCircleOutlineIcon /> : <RemoveCircleIcon />}
        </IconButton>
      </Box>
      {add && (
        <Box paddingY={2}>
          <BaseMap
            id="autocomplete-map"
            mapContainerStyle={{
              height: "400px",
              width: "auto"
            }}
            zoom={2.5}
            center={center}
            onClick={onClickMap}
          >
            <Marker position={center} />
          </BaseMap>
        </Box>
      )}
    </Box>
  );
};

AddressArea.propTypes = {
  address: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AddressArea;
