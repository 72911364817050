import {mapToBoolean} from "../../utils/mappers";

class VehicleRole {
  constructor(id, description, state, vehicles) {
    this.id = id;
    this.description = description;
    this.state = state;
    this.vehicles = vehicles;
  }

  static map = rol => {
    return new VehicleRole(
      rol.nu_id_rol,
      rol.vc_desc_rol,
      mapToBoolean(rol.bi_estado),
      []
    );
  };
}

export default VehicleRole;
