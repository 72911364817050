import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import DeleteTableRow from "components/Common/Tables/CrudTable/DeleteTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import {OutlinedInput} from "@material-ui/core";
import {ErrorSharp} from "@material-ui/icons";
import TableRow from "./TableRow";

const Table = ({
  data,
  isLoading,
  handleAmount,
  setData,
  focusAmount,
  errors,
  isEdit
}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const handleDelete = id => {
    //setList(tableData);
    let copy = [ ...tableData ];
    //console.log("DATOS DE PROD TABLA LIST 2: ",copy);
    let item = copy ? copy.find(e => e.id === id) : undefined;
    //console.log("ITEM: ", id);
    //console.log("ITEM: ", item);
    copy = copy.filter(item => item.id !== id);
    //console.log("COPY FILTER: ", copy);
    setData(copy);
    //tableData = [...copy];
  };

  if (isEdit) {
    const RenderRow = ({row}) => (
      <StyledTableRow>
        <StyledTableCell>{row.label}</StyledTableCell>
        <StyledTableCell>{row.stock}</StyledTableCell>
        <StyledTableCell>
          <OutlinedInput
            type="number"
            value={row.amount}
            //hasError={errors.amount.length > 0}
            //error={errors.amount[0]}
            fullWidth
            id="amount"
            // onChange={value => handleAmount(row, value)}
            //onChange={value => console.log("outline ", value)}
            onChange={e => handleAmount(row, e.target.value, row.stock)}
            disabled
          />
        </StyledTableCell>
        <StyledTableCell />
      </StyledTableRow>
    );

    return (
      <TableComponent
        data={tableData}
        isLoading={isLoading}
        head={<TableHeader orderby={orderBy} order={order} onSort={onSort} />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    );
  } else {
    const RenderRow = ({row}) => {
      return (
        <TableRow
          row={row}
          handleAmount={handleAmount}
          handleDelete={handleDelete}
          focusAmount={focusAmount}
        />
      );
    };

    return (
      <React.Fragment>
        {errors.products.length > 0 && (
          <p style={{color: "red", marginBottom: "16px"}}>
            {errors.products[0]}
          </p>
        )}
        <TableComponent
          data={tableData}
          isLoading={isLoading}
          head={<TableHeader orderby={orderBy} order={order} onSort={onSort} />}
          renderRow={RenderRow}
          getRowKey={row => row.id}
        />
      </React.Fragment>
    );
  }
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      product: PropTypes.string,
      stock: PropTypes.string
    })
  )
};

Table.defaultProps = {
  data: []
};

export default Table;
