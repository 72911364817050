import React from "react";
import PropTypes from "prop-types";
import FiltersContainer from "components/Common/Forms/FiltersContainer";
import {resetReportStateCDS} from "../../../../../../../redux/actions/Administrator/ReportCDS";
import {useDispatch, useSelector} from "react-redux";
import Form from "./Form";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";

const Filters = () => {
  const {type: selected} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  const titles = [
    {
      id: 9,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.mina.report.title" />
      )
    },
    {
      id: 10,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.events.report.title" />
      )
    },
    {
      id: 11,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.graphic.event.report.title" />
      )
    },
    {
      id: 12,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.pick.A1A2.report.title" />
      )
    },
    {
      id: 13,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.distribution.A1A2.report.title" />
      )
    },
    {
      id: 14,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.pick.B1B2.report.title" />
      )
    },
    {
      id: 15,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.distribution.B1B2.report.title" />
      )
    },
    {
      id: 16,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.staggered.report.title" />
      )
    },
    {
      id: 17,
      name: (
        <IntlMessages id="administrator.dataCollectionHistory.administrative.turn.report.title" />
      )
    }
  ];
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Box my={4}>
        <Typography variant="h2">
          {titles.find(t => t.id === selected).name}
        </Typography>
      </Box>
      <FiltersContainer
        active
        marginBottom={5}
        onClose={() => dispatch(resetReportStateCDS())}
      >
        <Form service={selected} />
      </FiltersContainer>
    </React.Fragment>
  );
};

export default Filters;
