const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    supervisor: "",
    tag: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "ADMINISTRATOR_GUARDIANS_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case "ADMINISTRATOR_GUARDIANS_APPLY_FILTERS": {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case "ADMINISTRATOR_GUARDIANS_RESET_FILTERS": {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case "ADMINISTRATOR_GUARDIANS_CHANGE_STATE": {
      return {
        ...state,
        data: state.data.map(guardian => {
          if (guardian.id === action.payload.id)
            return Object.assign(guardian, {active: !guardian.active});
          return guardian;
        })
      };
    }
    case "ADMINISTRATOR_GUARDIANS_CREATE": {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case "ADMINISTRATOR_GUARDIANS_EDIT": {
      return {
        ...state,
        data: state.data.map(guardian => {
          if (guardian.id === action.payload.id)
            return Object.assign(guardian, action.payload.data);
          return guardian;
        })
      };
    }
    default: {
      return state;
    }
  }
};
