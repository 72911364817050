const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    currency: "",
    cashManager: "",
    tag: "",
    fund: "",
    cash: "",
    transit: "",
    liquidated: "",
    refund: ""
  },
  userDetails: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "CASH_MANAGERS_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: action.payload.data,
        userDetails: action.payload.userDetails
      };
    }
    case "CASH_MANAGERS_APPLY_FILTERS": {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case "CASH_MANAGERS_RESET_FILTERS": {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case "CASH_MANAGERS_DELETE": {
      return {
        ...state,
        data: state.data.filter(
          cashManager => cashManager.id !== action.payload.id
        ),
        userDetails: action.payload.userDetails
      };
    }
    case "CASH_MANAGERS_EDIT": {
      return {
        ...state,
        data: state.data.map(cashManager => {
          if (cashManager.id === action.payload.id)
            return Object.assign(cashManager, {
              fund: action.payload.data.fund,
              cash:
                action.payload.data.fund - cashManager.fund + cashManager.cash
            });
          return cashManager;
        }),
        userDetails: action.payload.userDetails
      };
    }
    case "CASH_MANAGERS_CREATE": {
      return {
        ...state,
        data: [ ...state.data, action.payload.data ],
        userDetails: action.payload.userDetails
      };
    }
    case "CREATE_CASH_MANAGERS": {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    default: {
      return state;
    }
  }
};
