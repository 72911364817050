import RucData from "../domain/RucData";
import sunatAxiosInstance, {
  CancelToken,
  isCancel
} from "../utils/sunatAxiosInstance";
import axiosInstance from "../utils/axiosInstance";

let fetchRucDataCancelToken = undefined;

export const cancelPrevFetchRucData = () => {
  fetchRucDataCancelToken && fetchRucDataCancelToken();
};

export const fetchRucData = async ruc => {
  try {
    const rucData = await sunatAxiosInstance.get(`Get?nroRuc=${ruc}`, {
      cancelToken: new CancelToken(c => (fetchRucDataCancelToken = c))
    });
    if (Object.keys(rucData.data).length === 0) {
      throw Error("RUC Not Founded");
    }
    return RucData.map(rucData.data);
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
};

export const fetchRucDataTest = async ruc => {
  try {
    const rucData = await axiosInstance.get("/Sunat/Get", {
      params: {nroRuc: ruc}
    });
    if (Object.keys(rucData.data).length === 0) {
      throw Error("RUC Not Founded");
    }
    return RucData.map(rucData.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
