import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import {addConfigurationRole} from "../../../../../redux/actions/Configuration/Roles";
import Form from "./Form";

const AddRoleDialog = ({openAdd, setOpenAdd}) => {
  const id = "add-role-form";
  const dispatch = useDispatch();
  const onAddRole = role => {
    dispatch(addConfigurationRole(role));
    setOpenAdd(false);
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"configuration.user.settings.roles.add.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={openAdd}
      onClose={() => setOpenAdd(false)}
    >
      {openAdd && (
        <Form initialData={{description: ""}} id={id} onAddRole={onAddRole} />
      )}
    </FormDialog>
  );
};

export default AddRoleDialog;
