import React from "react";
import {Snackbar} from "@material-ui/core";

const BaseAlert = ({children, open, setOpen}) => (
  <Snackbar open={open} autoHideDuration={7000} onClose={() => setOpen(false)}>
    {children}
  </Snackbar>
);

export default BaseAlert;
