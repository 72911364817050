import React from "react";
import FiltersContainer from "../../../../../../Common/Forms/FiltersContainer";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../../hooks/Common/useForm";
import {
  applyElevatorsFilters,
  resetElevatorsFilters
} from "../../../../../../../redux/actions/ElevatorsManagement/Elevators";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";

const INIT_STATE = {
    headquarter: "",
    name: "",
    state: "",
    contract: "",
    detail: "",
    client: "",
    type: "",
    model: "",
    manufacturer: "",
    category: "",
};

const Filters = () => {
  const {filters} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const dispatch = useDispatch();
  const {formData, setFormData, resetForm, setValues} = useForm(INIT_STATE);
  const onApplyFilters = () => {
    dispatch(applyElevatorsFilters(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetElevatorsFilters());
  };
  React.useEffect(() => {
    setValues(filters);
  }, []);
  return (
    <Box my={4}>
      <FiltersContainer onClose={() => {}} active>
        <form>
          <GridContainer spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                  <OutlinedInput
                      fullWidth
                      value={formData.client}
                      onChange={value => setFormData("client", value)}
                      label={<IntlMessages id="client"/>}
                  />
              </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"elevators-headquarter"}
                fullWidth
                value={formData.headquarter}
                onChange={value => setFormData("headquarter", value)}
                label={
                  <IntlMessages
                    id={
                      "elevators.management.elevators.index.card.table.headquarter"
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"elevators-name"}
                fullWidth
                value={formData.name}
                onChange={value => setFormData("name", value)}
                label={<IntlMessages id="name" />}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"elevators-state"}
                fullWidth
                value={formData.state}
                onChange={value => setFormData("state", value)}
                label={
                  <IntlMessages
                    id={"elevators.management.elevators.index.card.table.state"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"elevators-contract"}
                fullWidth
                value={formData.contract}
                onChange={value => setFormData("contract", value)}
                label={
                  <IntlMessages
                    id={
                      "elevators.management.elevators.index.card.table.contract"
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"elevators-detail"}
                fullWidth
                value={formData.detail}
                onChange={value => setFormData("detail", value)}
                label={
                  <IntlMessages
                    id={
                      "elevators.management.elevators.index.card.table.detail"
                    }
                  />
                }
              />
            </Grid>
              <Grid item xs={12} md={6} lg={4}>
                  <OutlinedInput
                    fullWidth
                    value={formData.type}
                    onChange={value => setFormData("type", value)}
                    label={<IntlMessages id="type"/>}
                  />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                  <OutlinedInput
                    fullWidth
                    value={formData.model}
                    onChange={value => setFormData("model", value)}
                    label={<IntlMessages id="model"/>}
                  />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                  <OutlinedInput
                    fullWidth
                    value={formData.manufacturer}
                    onChange={value => setFormData("manufacturer", value)}
                    label={<IntlMessages id="manufacturer"/>}
                  />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                  <OutlinedInput
                    fullWidth
                    value={formData.category}
                    onChange={value => setFormData("category", value)}
                    label={<IntlMessages id="category"/>}
                  />
              </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <ContainedButton
                onClick={e => {
                  e.preventDefault();
                  onResetFilters();
                }}
                text={<IntlMessages id={"filters.button.clear"} />}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <ContainedButton
                onClick={e => {
                  e.preventDefault();
                  onApplyFilters();
                }}
                color="primary"
                text={<IntlMessages id={"filters.button.apply"} />}
              />
            </Grid>
          </GridContainer>
        </form>
      </FiltersContainer>
    </Box>
  );
};

export default Filters;
