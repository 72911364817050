import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    addContactToOrder,
    addItemToOrder,
    addProviderToOrder,
    createProviderOrder,
    editOrderControlDetails,
    editOrderDestinationDetails,
    editOrderOriginDetails,
    editOrderState,
    fetchAllProviderOrders,
    fetchProviderOrderDetails,
    removeContactFromOrder,
    removeItemFromOrder,
    removeProviderFromOrder
} from "../../../services/Logistics/Purchases/ProviderOrdersService";
import {locationType} from "../../../components/Logistic/Purchases/ProviderOrders/constants";


const nameSpace = "logistic/providerOrders";
export const getAllProviderOrders = createAsyncThunk(
    `${nameSpace}/getAllProviderOrders`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const orders = await fetchAllProviderOrders();
            dispatch(fetchSuccess());
            return orders;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getOrderDetails = createAsyncThunk(
    `${nameSpace}/getOrderDetails`,
    async ({order}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const details = await fetchProviderOrderDetails(order.id)
            dispatch(fetchSuccess());
            return {
                id: order.id,
                orderDate: order.orderDate,
                ...details,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const updateProviderOrderState = createAsyncThunk(
    `${nameSpace}/updateProviderOrderState`,
    async ({order, stateId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editOrderState({
                orderId: order.id,
                stateId,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addProviderOrder = createAsyncThunk(
    `${nameSpace}/addProviderOrder`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await createProviderOrder();
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getAllProviderOrders());
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addProviderToProviderOrder = createAsyncThunk(
    `${nameSpace}/addProviderToProviderOrder`,
    async ({order, providerId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addProviderToOrder({
                providerId,
                orderId: order.id,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);


export const deleteProviderToProviderOrder = createAsyncThunk(
    `${nameSpace}/deleteProviderToProviderOrder`,
    async ({order, providerId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await removeProviderFromOrder(providerId);
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addContactToProviderOrder = createAsyncThunk(
    `${nameSpace}/addContactToProviderOrder`,
    async ({order, request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addContactToOrder({
                request,
                orderId: order.id,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);


export const deleteContactToProviderOrder = createAsyncThunk(
    `${nameSpace}/deleteContactToProviderOrder`,
    async ({order, contactId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await removeContactFromOrder(contactId);
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateProviderOrderLocationDetails =  createAsyncThunk(
    `${nameSpace}/updateProviderOrderLocationDetails`,
    async ({order, request, type}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            let response;
            if(type === locationType.origin){
                response = await editOrderOriginDetails({
                    orderId: order.id,
                    request,
                });
            } else {
                response = await editOrderDestinationDetails({
                    orderId: order.id,
                    request,
                });
            }
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateProviderOrderControlDetails =  createAsyncThunk(
    `${nameSpace}/updateProviderOrderControlDetails`,
    async ({order, request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editOrderControlDetails({
               orderId: order.id,
               request,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addItemToProviderOrder = createAsyncThunk(
    `${nameSpace}/addItemToProviderOrder`,
    async ({order, request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addItemToOrder({
                request,
                orderId: order.id,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);


export const deleteItemToProviderOrder = createAsyncThunk(
    `${nameSpace}/deleteItemToProviderOrder`,
    async ({order, itemId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await removeItemFromOrder(itemId);
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);