class CashManager {
  constructor(
    id,
    typeId,
    type,
    administratorId,
    managerId,
    names,
    currencyId,
    fund,
    cash,
    transit,
    liquidated,
    refund,
    tags,
    costCenter,
    costCenterId,
  ) {
    this.id = id;
    this.typeId = typeId;
    this.type = type;
    this.administratorId = administratorId;
    this.managerId = managerId;
    this.names = names;
    this.currencyId = currencyId;
    this.fund = fund;
    this.cash = cash;
    this.transit = transit;
    this.liquidated = liquidated;
    this.refund = refund;
    this.tags = tags;
    this.costCenter = costCenter;
    this.costCenterId = costCenterId;
  }

  static map = cashManager => {
    return new CashManager(
      cashManager.nu_id_caja_chica,
      cashManager.nu_id_tipo_caja_chica,
      cashManager.vc_desc_tipo_caja_chica,
      cashManager.nu_id_entidad_administrador,
      cashManager.nu_id_entidad_gestor_caja,
      cashManager.vc_nombre_entidad_gestor_caja,
      cashManager.nu_id_moneda,
      parseFloat(cashManager.nu_imp_fondo),
      parseFloat(cashManager.nu_imp_efectivo),
      parseFloat(cashManager.nu_imp_transito),
      parseFloat(cashManager.nu_imp_liquidado),
      parseFloat(cashManager.nu_imp_para_reposicion),
      cashManager.vc_desc_tag.split(","),
        cashManager.vc_desc_centro_costo,
        cashManager.nu_id_centro_costo,
    );
  };
}

export default CashManager;
