import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {closeDialog, setCurrentBudget} from "../../../redux/reducers/Treasury/budgets";
import {updateBudget} from "../../../redux/thunks/Treasury/budgets";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import BillForm from "../common/BillForm";
import {parseDateToInput} from "../../../utils/dates";
import {cloneDeep} from "lodash";

const EditDialog = () => {
    const id = 'edit-budget-form';
    const {dialogs, currentBudget} = useSelector(({treasury}) => treasury.budgets);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.EDIT_BUDGET);
    const disableEdit = !currentBudget?.state?.toLowerCase()?.includes('pendiente');
    const onSubmit = (data) => {
        dispatch(updateBudget({
            request: {
                ...currentBudget,
                ...data,
            }
        }));
        onClose();
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.EDIT_BUDGET));
        dispatch(setCurrentBudget(null));
    }
    return (
        <>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="edit"/>}
                fullScreen
                disabled={disableEdit}
            >
                {(open && currentBudget) &&
                    <BillForm
                        id={id}
                        onSubmit={onSubmit}
                        initialData={{
                            id: currentBudget.id,
                            billNumber: currentBudget.billNumber,
                            documentType: currentBudget.documentType,
                            client:  {
                                id: currentBudget.clientId,
                                name: currentBudget.clientName,
                            },
                            creationDate: parseDateToInput(currentBudget.creationDate),
                            expirationDate: parseDateToInput(currentBudget.expirationDate),
                            discount: currentBudget.discount,
                            services: cloneDeep(currentBudget.services),
                            observations: currentBudget.observations,
                            isCredit: currentBudget.isCredit,
                            creditDays: currentBudget.creditDays,
                            orderType: currentBudget.orderType,
                            serviceDescription: currentBudget.serviceDescription,
                            serviceMonth: currentBudget.serviceMonth,
                            reference: currentBudget.reference,
                        }}
                        disableEdit={disableEdit}
                    />
                }
            </FormDialog>
        </>
    );
};

export default EditDialog;