import ServiceState from "./ServiceState";

class Service {
  constructor(
    id,
    state,
    saleOrganizing,
    serviceOrder,
    hopper,
    trailer,
    driver,
    driverPhone,
    destination,
    regionDestinationId,
    products,
    regionOriginId,
    originExit,
    originArrival,
    etaEstimatedArrival,
    etaEstimatedExit,
    etaDestinationArrival,
    destinationArrival,
    destinationExit,
    etaEstimatedArrivalOrigin,
    etaArrivalOrigin,
    estimatedKilometers,
    traveledKilometers,
    averageSpeed,
    order,
    activationDate,
    serviceDate,
    regionOriginEndId,
    otTime,
    serviceId,
    temperature,
    disableRemove,
  ) {
    this.id = id;
    this.state = state;
    this.statesDescription = state.description;
    this.saleOrganizing = saleOrganizing;
    this.serviceOrder = serviceOrder;
    this.hopper = hopper;
    this.trailer = trailer;
    this.driver = driver;
    this.driverPhone = driverPhone;
    this.destination = destination;
    this.regionDestinationId = regionDestinationId;
    this.products = products;
    this.regionOriginId = regionOriginId;
    this.originExit = originExit;
    this.originArrival = originArrival;
    this.etaEstimatedArrival = etaEstimatedArrival;
    this.etaEstimatedExit = etaEstimatedExit;
    this.etaDestinationArrival = etaDestinationArrival;
    this.destinationArrival = destinationArrival;
    this.destinationExit = destinationExit;
    this.etaEstimatedArrivalOrigin = etaEstimatedArrivalOrigin;
    this.etaArrivalOrigin = etaArrivalOrigin;
    this.estimatedKilometers = estimatedKilometers;
    this.traveledKilometers = traveledKilometers;
    this.averageSpeed = averageSpeed;
    this.order = order;
    this.activationDate = activationDate;
    this.serviceDate = serviceDate;
    this.regionOriginEndId = regionOriginEndId;
    this.otTime = otTime || "";
    this.serviceId = serviceId;
    this.temperature = temperature;
    this.disableRemove = disableRemove;
  }

  static map = service => {
    return new Service(
      service.id,
      new ServiceState(service.estado),
      service.orgVentas,
      service.descripcion,
      service.cabeza,
      service.remolque,
      service.conductor,
      service.tfnoConductor,
      service.destino,
      service.idRegionDestino,
      service.products &&
        JSON.parse(JSON.parse(service.productos)).map(p => p.N).toString(),
      service.idRegionCargadero,
      service.salidaCargadero,
      service.llegadaCargadero,
      service.etaEstimadaLlegada,
      service.etaEstimadaSalida,
      service.etaLlegadaCliente,
      service.llegadaCliente,
      service.salidaCliente,
      service.etaEstimadaLlegadaCargadero,
      service.etaLlegadaCargadero,
      service.kmsEstimados,
      service.kmsRecorridos,
      service.velocidadMedia,
      service.orden,
      service.fechaActivacion,
      service.fechaProgramada,
      service.idRegionFinCargadero,
      service.diffTimeOt,
      service.idServicio,
        service.temperatura,
        service.estaEnSeguimiento,
    );
  };
}

export default Service;
