import {createSlice} from "@reduxjs/toolkit";
import {
    addManufacturer,
    editManufacturerState,
    editManufacturer,
    fetchAllManufacturers,
} from "../../thunks/ElevatorManagement/manufacturers";
import {cloneDeep} from "lodash";

const INITIAL_STATE = {
    data: {},
    filters: {
        code: "",
        name: ""
    },
    current: null,
    dialogs: [],
};

const manufacturersSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "manufacturers",
    reducers: {
        setCurrentManufacturers: (state, action) => {
            state.current = action.payload;
        },
        openManufacturersDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload,
            ];
        },
        closeManufacturersDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setManufacturersFilters: (state, action) => {
            if (action.payload) {
                state.filters = action.payload;
            }else {
                state.filters = cloneDeep(INITIAL_STATE.filters);
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllManufacturers.fulfilled, (state, action) => {
            const list = {};
            for(let manufacturer of action.payload.manufacturers) {
                list[manufacturer.id] = manufacturer;
            }
            state.data = list;
        });
        builder.addCase(addManufacturer.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload;
        });
        builder.addCase(editManufacturer.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload;
        });
        builder.addCase(editManufacturerState.fulfilled, (state, action) => {
            state.data[action.payload.id].state = action.payload.state;
        });
    }
});

export default manufacturersSlice.reducer;

export const {
    setCurrentManufacturers,
    openManufacturersDialog,
    closeManufacturersDialog,
    setManufacturersFilters,
} = manufacturersSlice.actions;