import React from "react";
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";

const BaseAlertBox = ({title, children, email, setEmail}) => (
  <Box p="1em">
    <Box mb="4px">
      <Typography variant="subtitle1" color="primary">
        {title}
      </Typography>
    </Box>
    <Box>{children}</Box>
    <Box>
      <OutlinedInput
        fullWidth
        label={
          <IntlMessages
            id={
              "fleet.management.tachograph.alerts.table.configuration.send.email"
            }
          />
        }
        value={email}
        onChange={value => setEmail(value)}
        type="email"
      />
    </Box>
  </Box>
);

export default BaseAlertBox;
