import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "../Form";

const CreateAdvanceTypeDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-advance-type-form"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.advanceTypes.createFormTitle" />}
    onClose={onClose}
  >
    <Form id="create-advance-type-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateAdvanceTypeDialog;
