import React from "react";
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={
          "elevators.management.elevators.index.card.detail.dialog.form.contact.client.table.name"
        }
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={
          "elevators.management.elevators.index.card.detail.dialog.form.contact.client.table.email"
        }
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={
          "elevators.management.elevators.index.card.detail.dialog.form.contact.client.table.phone"
        }
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
