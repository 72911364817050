import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleFuel from "../../../domain/FleetManagement/Vehicles/VehicleFuel";

export const getAllFuelTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_tipo_Combustible/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(b => VehicleFuel.map(b));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addFuelType = async fuel => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_tipo_Combustible/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_tipo_combustible: fuel.name
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
