class ConfigurationData {
  constructor(
    id,
    vehicleData,
    driverData,
    organizationCard,
    driverCard,
    driverEmail,
    vehicleEmail
  ) {
    this.id = id;
    this.vehicleDataDownloadDays = vehicleData.download;
    this.vehicleDataNoticeDays = vehicleData.notice;
    this.vehicleDataEmail = vehicleData.email;
    this.driverDataDownloadDays = driverData.download;
    this.driverDataNoticeDays = driverData.notice;
    this.driverDataEmail = driverData.email;
    this.organizationCardExpiration = organizationCard.expiration;
    this.organizationCardEmail = organizationCard.email;
    this.driverCardExpiration = driverCard.expiration;
    this.driverCardEmail = driverCard.email;
    this.driverFileEmail = driverEmail;
    this.vehicleFileEmail = vehicleEmail;
  }

  static map = configuration => {
    return new ConfigurationData(
      configuration.nu_id_alerta_configuracion,
      {
        download: configuration.nu_frecuencia_datos_vehiculo,
        notice: configuration.nu_antelacion_datos_vehiculo,
        email: configuration.vc_email_datos_vehiculo
      },
      {
        download: configuration.nu_frecuencia_datos_conductor,
        notice: configuration.nu_antelacion_datos_conductor,
        email: configuration.vc_email_datos_conductor
      },
      {
        expiration: configuration.nu_antelacion_caducidad_tarjeta_empresa,
        email: configuration.vc_email_caducidad_tarjeta_empresa
      },
      {
        expiration: configuration.nu_antelacion_caducidad_tarjeta_conductor,
        email: configuration.vc_email_caducidad_tarjeta_conductor
      },
      configuration.vc_email_ficheros_no_estandar_conductor,
      configuration.vc_email_ficheros_no_estandar_vehiculo
    );
  };
}

export default ConfigurationData;
