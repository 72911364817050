import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="origin"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouseTransfer.table.origin" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="destiny"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouseTransfer.table.destiny" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouseTransfer.table.date" />
    </TableCellWithSorting>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
