import axiosInstance, {CancelToken, isCancel} from '../utils/axiosInstance';
import CashManagerLog from '../domain/CashManagerLog';

let fetchAllHistoryCancelToken = undefined;

export const cancelPrevFetchAllHistory = () => {
  fetchAllHistoryCancelToken && fetchAllHistoryCancelToken();
};

export const fetchAll = async (filters) => {
  try {
    const history = await axiosInstance.post(
      '/Caja_Chica_Log/sel',
      {
        dt_fec_inicio: filters.fromDate || null,
          dt_fec_final: filters.toDate || null,
        vc_nombre_entidad_gestor_caja: filters.cashManager || null,
        nu_id_accion: filters.action || null,
        vc_cantidad: filters.amount || null,
        vc_desc_tag: filters.tag || null
      },
      {
        cancelToken: new CancelToken(c => (fetchAllHistoryCancelToken = c)),
      },
    );
    return history.data.map(log => CashManagerLog.map(log));
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};
