import ui from "./ui";
import {
  cancelPrevCampusOriginSelectFetchAll,
  fetchAllCampusOriginSelect
} from "../../../services/CampusOriginSelectService";
import {FETCH_CAMPUS_ORIGIN} from "../../types/Administrator/CampusOriginSelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevCampusOriginSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchCampusSelectSucceeded = data => ({
  type: FETCH_CAMPUS_ORIGIN,
  payload: {
    data
  }
});

export const fetchDataCampusOrigin = id => {
  //console.log("DATA CAMPUS ORIGIN ID: ",id);
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevCampusOriginSelectFetchAll();
    fetchAllCampusOriginSelect(id).then(campusSelect => {
      //console.log("DATA CAMPUS ORIGIN campusSelect: ",campusSelect, id);
      dispatch(ui.fetchSucceeded());
      dispatch(fetchCampusSelectSucceeded(campusSelect));
    });
  };
};

export default {
  get: fetchDataCampusOrigin,
  resetState
};
