import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import VehicleRole from "../../domain/Configuration/VehicleRole";
import Response from "../../domain/Response";
import Vehicle from "../../domain/Configuration/Vehicle";

export const fetchAllConfigVehicleRoles = async () => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_roles_vehiculos", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(u => VehicleRole.map(u));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigVehicleRoleVehicleState = async (role, vehicle) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Usuario/upd_vehiculos_rol_estado", {
      nu_id_rol: role.id,
      nu_id_vehiculo: vehicle.id,
      bi_estado: vehicle.state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllVehiclesByVehicleRole = async role => {
  try {
    const {data} = await axiosInstance.post("Usuario/sel_vehiculos_rol", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_rol: role.id
    });
    return data.map(m => Vehicle.map(m));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addConfigVehicleRole = async role => {
  try {
    const {data} = await axiosInstance.post("/Usuario/ins_rol_vehiculo", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_rol: role.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigVehicleRoleState = async (rol, state) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Usuario/usp_rol_vehiculo_estado_upd", {
      nu_id_rol: rol.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigVehicleRoleData = async rol => {
  try {
    const {data} = await axiosInstance.post("/Usuario/upd_rol_vehiculo", {
      nu_id_rol: rol.id,
      vc_desc_rol: rol.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
