import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../hooks/Common/useForm";
import SideScreenDialog from "../../../Common/Dialogs/SideScreenDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import CentralSelect from "./CentralSelect";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Box, Button, Grid} from "@material-ui/core";
import VehiclesSelect from "./VehiclesSelect";
import ServiceSelect from "./ServiceSelect";
import RoutesSelect from "../../../ControlPanel/Reports/ReportsContainer/TreasuryReports/PettyCashReports/AverageReport/FIlters/Form/RoutesSelect";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import {applyMonitoringWorkOrdersFilters} from "../../../../redux/actions/Logistic/Monitoring";

const initData = {
  centrals: [],
  vehicles: [],
  routes: [],
  startDate: "",
  endDate: "",
  services: []
};

const FiltersDialog = ({open, setOpen}) => {
  const {filters} = useSelector(state => state.logistic.monitoring);
  const {formData, setFormData, handleSubmit, resetForm} = useForm(filters);
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(applyMonitoringWorkOrdersFilters(formData));
    setOpen(false);
  };
  return (
    <SideScreenDialog
      disableEnforceFocus
      id={"monitoring-filters"}
      open={open}
      handleClose={() => setOpen(false)}
      title={"logistic.monitoring.filters.title"}
    >
      <Box style={{margin: "32px"}}>
        <form id="monitoring-filters" onSubmit={handleSubmit(onSubmit)}>
          <GridContainer spacing={4}>
            <Grid item xs={12}>
              <VehiclesSelect
                vehicles={formData.vehicles}
                onChange={value => setFormData("vehicles", value)}
              />
            </Grid>
            <Grid item xs={12}>
              <RoutesSelect
                values={formData.routes}
                isLoading={false}
                onChange={value => setFormData("routes", value)}
              />
            </Grid>
            <Grid item xs={12}>
              <CentralSelect
                centrals={formData.centrals}
                onChange={value => setFormData("centrals", value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OutlinedDatePicker
                fullWidth
                value={formData.startDate}
                id="start-date-filter"
                label={
                  <IntlMessages id="logistic.monitoring.filters.start.date" />
                }
                onChange={value => setFormData("startDate", value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <OutlinedDatePicker
                fullWidth
                value={formData.endDate}
                id="end-date-filter"
                label={
                  <IntlMessages id="logistic.monitoring.filters.end.date" />
                }
                onChange={value => setFormData("endDate", value)}
              />
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <ServiceSelect*/}
            {/*        services={formData.services}*/}
            {/*        onChange={value => setFormData('services', value)}*/}
            {/*    />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <Box
                mt={8}
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
              >
                <Button
                  variant="outlined"
                  onClick={() => {
                    resetForm();
                    setOpen(false);
                  }}
                >
                  <IntlMessages id={"logistic.monitoring.filters.reset"} />
                </Button>
                <Button
                  variant="outlined"
                  type="submit"
                  form="monitoring-filters"
                >
                  <IntlMessages id={"logistic.monitoring.filters.apply"} />
                </Button>
              </Box>
            </Grid>
          </GridContainer>
        </form>
      </Box>
    </SideScreenDialog>
  );
};

export default FiltersDialog;
