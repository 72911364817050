import {
  APPLY_ASSIGMENT_VEHICLE_FILTERS,
  CONFIRM_WORK_ORDER_STARTED,
  CONFIRM_WORK_ORDER_SUCCEED,
  FETCH_ASSIGMENT_ORDERS_STARTED,
  FETCH_ASSIGMENT_ORDERS_SUCCEED,
  FETCH_ASSIGMENT_VEHICLES_STARTED,
  FETCH_ASSIGMENT_VEHICLES_SUCCEED,
  GENERATE_WORK_ORDER_STARTED,
  GENERATE_WORK_ORDER_SUCCEED,
  RESET_ASSIGMENT_RESULT,
  RESET_ASSIGMENT_STATE,
  RESET_ASSIGMENT_VEHICLE_FILTERS,
  RESET_ASSIGMENT_WORK_ORDERS,
  SET_OPTIMIZATION_ID,
  SET_ORDERS_FROM_EXCEL
} from "../../types/Logistic/AssigmentTypes";
import {
  confirmWorkOrder,
  generateOptimization,
  getAssigmentOrders,
  getAssigmentVehicles
} from "../../../services/AssigmentService";

export const resetAssigmentWorkOrders = () => ({
  type: RESET_ASSIGMENT_WORK_ORDERS
});

const generateWorkOrdersSucceed = (workOrders, outDate) => {
  return dispatch => {
    dispatch({
      type: GENERATE_WORK_ORDER_SUCCEED,
      payload: {workOrders, outDate}
    });
  };
};

export const setOptimizationId = optimizationId => ({
  type: SET_OPTIMIZATION_ID,
  payload: optimizationId
});

export const generateWorkOrdersStarted = (
  initialInfo,
  vehicles,
  orders,
  options,
  optimizationId
) => {
  return async dispatch => {
    dispatch({type: GENERATE_WORK_ORDER_STARTED});
    const response = await generateOptimization(
      {
        ...initialInfo,
        vehicles: vehicles.map(v => v.id),
        orders: [ ...orders ],
        options: options.map(o => o.id)
      },
      optimizationId
    );
    // dispatch(generateWorkOrdersSucceed(data.workOrders, data.outDate));
    if (response.transactionState === 1) {
      dispatch(setOptimizationId(response.transactionKey));
      dispatch(
        confirmWorkOrderCreationSucceed({
          ok: true,
          message: response.transactionMessage
        })
      );
    } else {
      dispatch(setOptimizationId(null));
      dispatch(
        confirmWorkOrderCreationSucceed({
          ok: false,
          message: response.transactionMessage
        })
      );
    }
  };
};

const fetchAssigmentVehiclesSucceed = vehicles => {
  return dispatch => {
    dispatch({type: FETCH_ASSIGMENT_VEHICLES_SUCCEED, payload: vehicles});
  };
};

export const fetchAssigmentVehiclesStarted = filters => {
  return async dispatch => {
    dispatch({type: FETCH_ASSIGMENT_VEHICLES_STARTED});
    const vehicles = await getAssigmentVehicles();
    dispatch(fetchAssigmentVehiclesSucceed(vehicles));
  };
};

export const applyAssigmentVehicleFilters = filters => ({
  type: APPLY_ASSIGMENT_VEHICLE_FILTERS,
  payload: filters
});
export const resetAssigmentVehicleFilters = () => ({
  type: RESET_ASSIGMENT_VEHICLE_FILTERS
});

const fetchAssigmentOrdersSucceed = orders => {
  return dispatch => {
    const data = orders.map(order => ({
      ...order,
      color: "#" + Math.floor(Math.random() * 16777215).toString(16)
    }));
    dispatch({type: FETCH_ASSIGMENT_ORDERS_SUCCEED, payload: data});
  };
};

export const fetchAssigmentOrdersStarted = filters => {
  return async dispatch => {
    dispatch({type: FETCH_ASSIGMENT_ORDERS_STARTED});
    const orders = await getAssigmentOrders(filters);
    dispatch(fetchAssigmentOrdersSucceed(orders));
  };
};

export const setAssigmentOrdersFromExcel = data => {
  return dispatch => {
    dispatch({type: SET_ORDERS_FROM_EXCEL, payload: data});
  };
};

export const resetAssigmentState = () => ({type: RESET_ASSIGMENT_STATE});

const confirmWorkOrderCreationSucceed = result => {
  return dispatch => {
    dispatch({type: CONFIRM_WORK_ORDER_SUCCEED, payload: result});
  };
};

export const resetAssigmentResult = () => ({type: RESET_ASSIGMENT_RESULT});

export const confirmWorkOrderCreationStarted = (data, onFinish) => {
  return async dispatch => {
    dispatch({type: CONFIRM_WORK_ORDER_STARTED});
    const response = await confirmWorkOrder(data);
    if (response.transactionState === 1) {
      dispatch(resetAssigmentState());
      onFinish && onFinish();
      dispatch(
        confirmWorkOrderCreationSucceed({
          ok: true,
          message: response.transactionMessage
        })
      );
    } else {
      dispatch(
        confirmWorkOrderCreationSucceed({
          ok: false,
          message: response.transactionMessage
        })
      );
    }
  };
};
