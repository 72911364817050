import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const InfringedRuleSelect = ({id, label, value, setValue}) => {
  const [ rules, setRules ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setRules([
      {
        id: 1,
        label: "Regla 1"
      },
      {
        id: 2,
        label: "Regla 2"
      }
    ]);
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {rules.map(e => (
        <MenuItem key={`rule-${e.id}`} value={e.id}>
          {e.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default InfringedRuleSelect;
