import {
  FETCH_DATA_COLLECTION_SUCCEEDED,
  APPLY_DATA_COLLECTION_FILTERS,
  RESET_DATA_COLLECTION_FILTERS,
  DATA_COLLECTION_CHANGE_STATE,
  DATA_COLLECTION_EDIT,
  DATA_COLLECTION_CREATE
} from "../../types/Administrator/DataCollection";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_DATA_COLLECTION_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_DATA_COLLECTION_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_DATA_COLLECTION_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case DATA_COLLECTION_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, {active: !type.active});
          return type;
        })
      };
    }
    case DATA_COLLECTION_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case DATA_COLLECTION_EDIT: {
      return {
        //...state,
        //data: state.data.map(type => {
        //    if(type.id === action.payload.id) return Object.assign(type, action.payload.data);
        //    return type
        //}),
      };
    }
    default: {
      return state;
    }
  }
};
