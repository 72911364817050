import React from "react";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import Filters from "./Filters";
import {Box} from "@material-ui/core";
import AlertsTable from "./AlertsTable";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllAlertsStarted} from "../../../../redux/actions/Logistic/Monitoring";

const AlertsDialog = ({open, setOpen}) => {
  const {filters} = useSelector(state => state.logistic.monitoring.alerts);
  const dispatch = useDispatch();

  React.useEffect(
    () => {
      dispatch(fetchAllAlertsStarted(filters));
    },
    [ filters ]
  );
  return (
    <FullScreenDialog
      open={open}
      handleClose={() => setOpen(false)}
      title={"logistic.monitoring.alerts.title"}
    >
      <Box paddingX={16} paddingY={8} display="flex" flexDirection="column">
        <Filters />
        <Box marginTop={8}>
          <AlertsTable />
        </Box>
      </Box>
    </FullScreenDialog>
  );
};

export default AlertsDialog;
