import axiosInstance, {CancelToken} from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleModel from "../../../domain/FleetManagement/Vehicles/VehicleModel";

let cancelFetchAllToken = undefined;

const cancelPrevFetchAll = () => {
  cancelFetchAllToken && cancelFetchAllToken();
};

export const getAllModels = async brand => {
  try {
    cancelPrevFetchAll();
    const {data} = await axiosInstance.post(
      "/Vehiculo_Modelo/sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_marca: brand
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchAllToken = c))
      }
    );
    return data.map(b => VehicleModel.map(b));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addModel = async (model, brand) => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Modelo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_marca: brand,
      vc_marca_modelo: model.name
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
