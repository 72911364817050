class ServiceHeader {
  constructor(id, label, content, state) {
    this.id = id;
    this.label = label;
    this.content = content;
    this.state = state;
  }
  static map = header => {
    return new ServiceHeader(
      header.id,
      header.label,
      header.content,
      header.state
    );
  };
}

export default ServiceHeader;
