import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.infractions.table.plaque"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.infractions.table.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.infractions.table.driver"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.infractions.table.infringed.rule"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.infractions.table.amount"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
