import axiosInstance from "../utils/axiosInstance";
import {getCompanyEntityId} from "./StorageService";
import ContractType from "../domain/ContractType";

export const fetchAllContractsTypes = async () => {
  try {
    const {
      data
    } = await axiosInstance.post("/Jornada_Laboral_Fichaje_Tipo/sel", {
      nu_id_entidad_empresa: getCompanyEntityId()
    });

    return data.map(t => ContractType.map(t));
  } catch (e) {
    return Promise.reject(e);
  }
};
