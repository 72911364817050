import React from "react";
import {Box} from "@material-ui/core";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import ImportFileDialog from "../../../../Common/Dialogs/ImportFileDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {exportOtBreakdownExcelFile} from "../../../../../services/WarehouseTransfer";
import {fetchError, fetchSuccess} from "../../../../../redux/actions";
import {useDispatch} from "react-redux";
import {downloadFile} from "../../../../../services/FileService";

const ImportFileArea = () => {
  const [ open, setOpen ] = React.useState(false);
  const dispatch = useDispatch();
  const onImport = file => {
    exportOtBreakdownExcelFile(file)
      .then(response => {
        dispatch(fetchSuccess("Archivo Guardado"));
        setOpen && setOpen(false);
      })
      .catch(e => {
        dispatch(fetchError("Error guardando el archivo"));
      });
  };
  const onDownload = e => {
    e.preventDefault();
    downloadFile(
      "EjemploExcelMatrizPosiciones.xlsx",
      "https://cdn.4gflota.com/logos-panel-control/EjemploExcelMatrizPosiciones.xlsx"
    );
  };

  return (
    <React.Fragment>
      <Box my={2} display="flex" flexWrap="wrap" style={{gap: "8px"}}>
        <ContainedButton
          text={<IntlMessages id={"form.button.import.excel"} />}
          color="primary"
          onClick={() => setOpen(true)}
        />
        <ContainedButton
          text={<IntlMessages id={"form.button.download.example"} />}
          color="primary"
          onClick={e => onDownload(e)}
        />
      </Box>
      <ImportFileDialog
        id="ot-breakdown-import-dialog"
        title={
          <IntlMessages id={"administrator.ot.breakdown.import.dialog.title"} />
        }
        open={open}
        setOpen={setOpen}
        onImport={onImport}
      />
    </React.Fragment>
  );
};

export default ImportFileArea;
