import React from "react";
import ElevatorInformation from "../ElevatorDetailDIalog/ElevatorInformation";
import DialogBase from "../../../../../Common/Dialogs/DialogBase";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {addElevator} from "../../../../../../redux/actions/ElevatorsManagement/Elevators";

const AddElevatorDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const {filters} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const onSave = data => {
    dispatch(addElevator(data, filters));
    setOpen(false);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <DialogBase
      title={
        <IntlMessages
          id={"elevators.management.elevators.index.card.add.dialog.title"}
        />
      }
      open={open}
      close={() => setOpen(false)}
    >
      {open && (
        <ElevatorInformation
          add
          initialData={{
            name: "",
            client: "",
            headquarter: "",
            model: "",
            ken: "",
            category: "",
            stops: "",
            shipmentDate: "",
            emergency: false,
            genre: "",
            type: "",
            manufacturer: "",
          }}
          onSave={onSave}
          onCancel={onClose}
        />
      )}
    </DialogBase>
  );
};

export default AddElevatorDialog;
