import React from 'react';
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {
    clearDestinationsList,
    closeBreakdownDialog, setBreakdownFilters,
    setCurrentBreakdownDestination
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import {dialogsList} from "../../constants";
import {Box} from "@material-ui/core";
import DestinationDetails from "./DestinationDetails";
import HistoryTable from "./HistoryTable";

const HistoryDialog = ({setTabToOtBreakdown}) => {
    const {dialogs, currentDestination} = useSelector(
        state => state.administrator.breakdown
    );
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeBreakdownDialog(dialogsList.historyData));
        dispatch(setCurrentBreakdownDestination(null));
    }
    const open = dialogs.some(d => d === dialogsList.historyData);
    const onClickTableOt = (ot) => {
        onClose();
        setTabToOtBreakdown();
        dispatch(clearDestinationsList());
        dispatch(setBreakdownFilters({ot}));
    }
    return (
        <FullScreenDialog
            open={open}
            handleClose={onClose}
            title={'historic'}
        >
            {currentDestination &&
                <Box
                    py={5}
                    px={5}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1rem'
                    }}
                >
                    <DestinationDetails/>
                    <HistoryTable onClickOt={onClickTableOt} />
                </Box>
            }
        </FullScreenDialog>
    );
};

export default HistoryDialog;