import moment from "moment";

class Accounting {
  constructor(
    id,
    accountingNumber,
    openingDate,
    liquidationDate,
    tags,
    quantity,
    state,
    pettyCash,
    pettyCashAmount,
    observations,
    file,
  ) {
    this.id = id;
    this.accountingNumber = accountingNumber;
    this.openingDate = openingDate;
    this.liquidationDate = liquidationDate;
    this.tags = tags;
    this.quantity = quantity;
    this.state = state;
    this.pettyCash = pettyCash;
    this.pettyCashAmount = pettyCashAmount;
    this.observations = observations;
    this.file = file;
  }

  static map = accounting => {
    return new Accounting(
      parseInt(accounting.nu_id_contabilizacion),
      accounting.vc_nro_liquidacion,
      accounting.dt_fec_apertura &&
        moment(accounting.dt_fec_apertura, "MM/DD/YYYY hh:mm:ss A").toDate(),
      accounting.dt_fec_liquidacion &&
        moment(accounting.dt_fec_liquidacion, "MM/DD/YYYY hh:mm:ss A").toDate(),
      accounting.vc_desc_tag.split(","),
      parseFloat(accounting.nu_cantidad || "0"),
      {
        id: accounting.nu_id_estado,
        description: accounting.vc_desc_estado
      },
      accounting.nu_id_caja_chica,
      accounting.nu_importe_caja,
        accounting.vc_observacion,
        accounting.vc_id_documento
    );
  };
}

export default Accounting;
