import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import moment from "moment/moment";
import {v4 as uuidV4} from 'uuid';

export const requests = {
    1: (data) => {
        return {
            ls_custodios: [...data.guardians.map(guardian => guardian.id)],
            ls_tags: [...data.tags.map(tag => tag.id)],
            ls_conductores: [...data.drivers.map(driver => driver.id)],
            ls_tipo_gasto: [...data.types.map(type => type.id)],
            ls_estado: [...data.states.map(state => state.id)],
            fechaInicio: data.startDate,
            fechaFinal: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD'),
            tipoAgrupacion: data.groupType
        }
    },
    2: (data) => {
        return {
            ls_tipo_documento: [...data.documentTypes.map(t => t.id)],
            ls_tags: [...data.tags.map(tag => tag.id)],
            ls_tipo_gasto: [...data.types.map(type => type.id)],
            fechaInicio: data.startDate,
            fechaFinal: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD'),
            tipoAgrupacion: data.groupType
        }
    },
    3: (data) => {
        return {
            fechaInicio: data.startDate,
            fechaFinal: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_rutas: [...data.routes.map(route => route.id)]
        }
    },
    4: (data) => {
        return {
            ls_tags: [...data.tags.map(tag => tag.id)],
            ls_tipo_gasto: [...data.types.map(type => type.id)],
            fechaInicio: data.startDate,
            fechaFinal: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD'),
            tipoAgrupacion: data.groupType
        }
    },
    5: (data) => {
        return {
            ls_clientes: [...data.clients.map(client => client.id)],
            ls_productos: [...data.products.map(product => product.id)],
            fechaInicio: data.startDate,
            fechaFin: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    6: (data) => {
        return {
            ls_clientes: [...data.clients.map(client => client.id)],
            ls_productos: [...data.products.map(product => product.id)],
            fechaInicio: data.startDate,
            fechaFin: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    7: (data) => {
        return {
            ls_clientes: [...data.clients.map(client => client.id)],
            ls_productos: [...data.products.map(product => product.id)],
            fechaInicio: data.startDate,
            fechaFin: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    8: data => {
        return {
            ls_clientes: [...data.clients.map(client => client.id)],
            ls_productos: [...data.products.map(product => product.id)],
            fechaInicio: data.startDate,
            fechaFin: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    9: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD'),
            nu_option_region:
                data.location === "Todo"
                    ? 1
                    : data.location === "En Carretera" ? 3 : 2,
            nu_option: data.visualization
        };
    },
    10: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: data.startDate,
            fechaFin: data.endDate,
            nu_option_region:
                data.location === "Todo"
                    ? 1
                    : data.location === "En Carretera" ? 3 : 2
        };
    },
    11: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD'),
            nu_option_region:
                data.location === "Todo"
                    ? 1
                    : data.location === "En Carretera" ? 3 : 2
        };
    },
    12: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD')
        };
    },
    13: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    14: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    15: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    16: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    17: data => {
        return {
            vehiculoseventos: [
                ...data.vehicles.map(vehicle => ({
                    id: vehicle.id,
                    id_externo: vehicle.externalId,
                    vc_alias: vehicle.name
                }))
            ],
            conductores: [...data.drivers.map(driver => driver.id)],
            eventos: [...data.events.map(event => event.id)],
            fechaInicio: new Date(data.startDate),
            fechaFin: moment(new Date(data.endDate)).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    18: data => {
        return {
            nu_id_entidad_proveedor: data.provider
        }
    },
    19: data => {
        return {
            nu_id_entidad_proveedor: data.provider,
            fechaInicio: data.startDate,
            fechaFin: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    20: filters => {
        return {
            periodo: filters.period,
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_conductores: [...filters.drivers.map(d => d.id)],
            nu_reglamento_tacografo: filters.regulation,
            bi_solo_infracciones: filters.onlyInfractions
        }
    },
    21: filters => {
        return {
            periodo: filters.period,
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_conductores: [...filters.drivers.map(d => d.id)]
        }
    },
    22: filters => {
        return {
            periodo: filters.period,
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_conductores: [...filters.drivers.map(d => d.id)]
        }
    },
    23: data => {
        return {
            vehiculos: data.vehicles.map(vehicle => vehicle.id),
            conductores: data.drivers.map(driver => driver.id),
            dt_fec_inicio: data.startDate,
            dt_fec_fin: moment(data.endDate).add(1, 'day').format('yyyy-MM-DD')
        }
    },
    25: filters => {
        return {
            dt_fecha_inicio: filters.startDate,
            dt_fecha_fin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_vehiculos: [...filters.vehicles.map(d => d.id)],
        }
    },
    26: filters => {
        return {
            dt_fecha_inicio: filters.startDate,
            dt_fecha_fin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_vehiculos: [...filters.vehicles.map(d => d.id)],
        }
    },
    27: filters => {
        return {
            dt_fecha_inicio: filters.startDate,
            dt_fecha_fin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_vehiculos: [...filters.vehicles.map(d => d.id)],
        }
    },
    30: filters => {
        return {
            repartidores: filters.deliveries.map(d => d.id),
            tiposServicios: filters.serviceTypes.map(d => d.id),
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            indicador: filters.indicator,
        }
    },
    31: filters => {
        return {
            ls_vehiculos: filters.vehicles.map(d => d.id),
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
        }
    },
    32: filters => {
        let startDate = moment()
            .set({date: 1, month: 0, year: filters.year})
            .startOf('day');
        let endDate;
        const parseDate = {
            1: () => { //monthly
                startDate = moment()
                    .month(filters.dateNumber - 1)
                    .set({date: 1, year: filters.year})
                    .startOf('day');
                endDate = moment(startDate)
                    .add(1, 'month');
            },
            2: () => { //weekly
                startDate = moment()
                    .set({year: filters.year, date: 1, month: 0})
                    .add((filters.dateNumber - 1) * 7, 'day');
                endDate = moment(startDate)
                    .add(1, 'week');
            },
            3: () => { //accumulated monthly
                endDate = moment(startDate)
                    .add(filters.dateNumber - 1, 'month')
                    .endOf('month')
                    .startOf('day');
            },
            4: () => { //accumulated weekly
                endDate = moment(startDate)
                    .add(filters.dateNumber, 'week');
            }
        }
        parseDate[filters.dateFormat]();
        return {
            nu_año: filters.year,
            nu_mes: filters.dateFormat === 1 || filters.dateFormat === 3 ? filters.dateNumber : 0,
            nu_semana: filters.dateFormat === 2 || filters.dateFormat === 4 ? filters.dateNumber : 0,
            fechaInicio: moment(startDate).format('yyyy-MM-DD'),
            fechaFin: moment(endDate).format('yyyy-MM-DD'),
            nu_puesto_planificacion: filters.planningPlace,
            bi_acumulado: !(filters.dateFormat === 1 || filters.dateFormat === 2)
        };
    },
    33: (filters) => {
      return {
          checklistvehiculo_aves_id: filters.reportId
      };
    },
    34: (filters) => {
        return {
            checklistvehiculo_aves_id : filters.reportId
        };
    },
    35: (filters) => {
        return {
            IdParteRevision : filters.reportId
        }
    },
    38: filters => {
        return {
            periodo: filters.period,
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_conductores: [...filters.drivers.map(d => d.id)]
        }
    },
    39: filters => {
        return {
            periodo: filters.period,
            fechaInicio: filters.startDate,
            fechaFin: moment(filters.endDate).add(1, 'day').format('yyyy-MM-DD'),
            ls_conductores: [...filters.drivers.map(d => d.id)],
            ls_vehiculos: [...filters.vehicles.map(d => d.id)],
        }
    },
    40: filters => {
        return {
            nu_tipo_visualizacion: filters.visualizationType,
            ls_vehiculos: [...filters.vehicles.map(d => d.id)],
        }
    },
    41: filters => {
        return {
            ls_conductores: [...filters.drivers.map(d => d.id)],
        }
    },
};

export const sendEmailService = async (emailData, reportId, filters) => {
    try {
        let reportIdToUse = reportId;
        if(reportId === 32 && emailData.format=== 2) {
            reportIdToUse = 36;
        }
        const {data} = await axiosInstance.post(
            '/Informes/send',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_informe: reportIdToUse,
                nu_id_formato: emailData.format,
                vc_filtro: JSON.stringify(requests[reportId](filters)),
                vc_email: emailData.email,
                vc_email_cc: emailData.cc,
                vc_nombre: emailData.name,
            }
        );
        return {message: data};
    } catch (e) {
        return Promise.reject(e);
    }
}

const getTimeProcessReportData = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/Informes/informe_tiempo_proceso',
            {
                nu_id_entidad: getCompanyEntityId(),
                dt_fec_inicio: filters.startDate,
                dt_fec_fin: filters.endDate,
                ls_puesto_planificacion: filters.planningPlaces.map(p => p.id)
            }
        );
        return data.map(row => ( {
            id: uuidV4(),
            destare: row.checkListVehiculo_destare_id,
            birdWeight: row.checkListVehiculo_aves_id,
            vehicle: row.checkList_id,
            date: row.dt_fecha,
            planningPlace: row.vc_puesto_planificacion,
            ot: row.vc_desc_ot,
            progress: row.vc_progreso,
            client: row.vc_desc_cliente,
            clientDepartureTime: row.vc_hora_salida_cliente,
            balance: row.vc_desc_balanza,
            balanceEntryTime: row.vc_hora_ingreso_balanza,
            balanceJabasEntry: row.vc_jabas_ingreso_balanza,
            balanceDocumentEntry: row.vc_documento_ingreso_balanza,
            balanceUnloadStartTime: row.vc_hora_inicio_descarga_balanza,
            balanceUnloadEndTime: row.vc_hora_fin_descarga_balanza,
            balanceStowageStartTime: row.vc_hora_inicio_estiba_balanza,
            balanceStowageEndTime: row.vc_hora_fin_estiba_balanza,
            balanceJabasDeparture: row.vc_jabas_salida_balanza,
            balanceDocumentDeparture: row.vc_documento_salida_balanza,
            balanceDepartureTime: row.vc_hora_salida_balanza,
            laundry: row.vc_desc_lavadero,
            laundryProgress: row.vc_progreso_lavadero,
            laundryEntryTime: row.vc_hora_ingreso_lavadero,
            laundryJabasEntry: row.vc_jabas_ingreso_lavadero,
            laundryDocumentEntry: row.vc_doc_ingreso_lavadero,
            laundrySoakingStartTime: row.vc_hora_inicio_remojo_lavadero,
            laundrySoakingEndTime: row.vc_hora_fin_remojo_lavadero,
            laundryUnloadStartTime: row.vc_hora_inicio_descarga_lavadero,
            laundryUnloadEndTime: row.vc_hora_fin_descarga_lavadero,
            laundryStartTime: row.vc_hora_inicio_lavado_lavadero,
            laundryEndTime: row.vc_hora_fin_lavado_lavadero,
            laundryStowageStartTime: row.vc_hora_inicio_estiba_lavadero,
            laundryStowageEndTime: row.vc_hora_fin_estiba_lavadero,
            laundryJabasDeparture: row.vc_jabas_salida_lavadero,
            laundryDocumentDeparture: row.vc_documento_salida_lavadero,
            laundryDepartureTime: row.vc_hora_salida_lavadero,
            farm: row.vc_desc_granja,
            farmProgress: row.vc_progreso_granja,
            farmEntryTime: row.vc_hora_ingreso_granja,
            farmJabasEntry: row.vc_jabas_ingreso_granja,
            farmDocumentEntry: row.vc_documento_ingreso_granja,
            farmUnloadStartTime: row.vc_hora_inicio_descarga_granja,
            farmUnloadEndTime: row.vc_hora_fin_descarga_granja,
            farmTrappedStartTime: row.vc_hora_inicio_atrapado_granja,
            farmTrappedEndTime: row.vc_hora_fin_atrapado_granja,
            farmStowageStartTime: row.vc_hora_inicio_estiba_granja,
            farmStowageEndTime: row.vc_hora_fin_estiba_granja,
            farmDocumentDeparture: row.vc_doc_salida_granja,
            farmDepartureTime: row.vc_hora_salida_granja,
            clientArriveTime: row.vc_hora_llegada_cliente
        }))
    }catch (e) {
        return Promise.reject(e);
    }
}

const getLogisticInverseReportData = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/Informes/informe_logistica_inversa',
            {
                nu_id_tipo_visualizacion: filters.type,
                dt_fec_inicio: filters.startDate,
                dt_fec_fin: filters.endDate,
            }
        );
        switch (filters.type){
            case 1: {
                return data.map(row => ({
                    id: uuidV4(),
                    date: row.dt_fecha,
                    origin: row.vc_centro_origen,
                    route: row.vc_ruta,
                    plaque: row.vc_placa_tracto,
                    clientCode: row.vc_cod_cliente,
                    client: row.vc_nombre,
                    sequence: row.vc_secuencia,
                    deliveryMinTime: row.vc_hora_min_entrega,
                    deliveryMaxTime: row.vc_hora_max_entrega,
                    arrivalDestination: row.vc_llegada_destino,
                    loadStartTime: row.vc_inicio_carga,
                    unloadStartTime: row.vc_inicio_descarga,
                    loadEndTime: row.vc_fin_carga,
                    unloadEndTime: row.vc_fin_descarga,
                    returning: row.vc_regresare,
                    returningGuide: row.vc_regresare_guias,
                    arrivalDestinationPickUp: row.vc_llegada_destino_recogida,
                    startCollection: row.vc_inicio_recogida,
                    endCollection: row.vc_fin_recogida,
                    finishTime: row.vc_finalizado,
                    status: row.vc_status_cump_vh,
                    reasonCode: row.vc_cod_motivo,
                    lateReason: row.vc_motivo_tardanza,
                    area: row.vc_area,
                    carrier: row.vc_transportista,
                    driverCode: row.vc_cod_conductor,
                    driver: row.vc_conductor,
                    assistant: row.vc_auxiliar,
                    carrierNumber: row.vc_nro_transporte,
                    dwellTime: row.vc_tiempo_permanencia,
                    waitTime: row.vc_tiempo_espera,
                    attentionTime: row.vc_tiempo_atencion,
                    documentationDeliveryTime: row.vc_tiempo_entrega_doc_activos,
                    entryValidation: row.vc_validacion_ingreso_data,
                    population: row.vc_poblacion
                }))
            }
            default: {
                return data.map(row => ( {
                    id: uuidV4(),
                    date: row.dt_fecha,
                    origin: row.vc_centro_origen,
                    route: row.vc_ruta,
                    clientCode: row.vc_cod_cliente,
                    client: row.vc_nombre,
                    materialCode: row.vc_cod_material,
                    material:  row.vc_nombre_material,
                    saleQuantity: row.vc_und_venta,
                    dispatchedQuantity: row.vc_unidad_despachada,
                    dispatchedKg: row.vc_kg_despachados,
                    receivedQuantity: row.vc_und_recibidas,
                    receivedKg: row.vc_kg_recibidos,
                    notDelivered: row.vc_nro_entregados,
                    reason: row.vc_motivo,
                    carrier: row.vc_transportista,
                    plaque: row.vc_placa_tracto,
                    driverCode: row.vc_cod_conductor,
                    driver: row.vc_conductor,
                    castAssistant: row.vc_auxiliar_reparto
                }))
            }
        }
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getReportFile = async (reportId, format, filters, download = false) => {
    if(reportId === 33 && !download) {
        return getTimeProcessReportData(filters);
    }
    if(reportId === 34 && !download) {
        return getLogisticInverseReportData(filters);
    }
    let reportIdToUse = reportId;
    if(reportId === 32 && format=== 2) {
        reportIdToUse = 36;
    }
    try {
        const {data} = await axiosInstance.post(
            '/Informes/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_informe: reportIdToUse,
                nu_id_formato: format,
                vc_filtro: JSON.stringify({
                    ...requests[reportId](filters), periodo: filters.period,
                }),
            }
        );
        return data.vc_reporte;
    } catch (e) {
        return Promise.reject(e);
    }
}