import ui, {
  closeCreateForm,
  closeDeleteConfirmation,
  closeEditForm,
  closeRestoreConfirmation,
  editFailed
} from "./ui";
import {
  cancelPrevAdvanceTypeFetchAll,
  changeAdvanceTypeState,
  createAdvanceType,
  fetchAllAdvanceTypes,
  updateAdvanceType
} from "../../../services/AdvanceTypeService";
import {restoreAdministratorState} from "./Common";
import {
  ADVANCE_TYPES_CHANGE_STATE,
  ADVANCE_TYPES_CREATE,
  ADVANCE_TYPES_EDIT,
  APPLY_ADVANCE_TYPES_FILTERS,
  FETCH_ADVANCE_TYPES_SUCCEEDED,
  RESET_ADVANCE_TYPES_FILTERS
} from "../../types/Administrator/AdvanceTypesCustomTypes";
import AdvanceType from "../../../domain/AdvanceType";

export const resetState = () => dispatch => {
  cancelPrevAdvanceTypeFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchAdvanceTypesSucceeded = data => ({
  type: FETCH_ADVANCE_TYPES_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchAdvanceTypes = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevAdvanceTypeFetchAll();
    fetchAllAdvanceTypes()
      .then(advanceTypes => {
        dispatch(ui.fetchSucceeded());
        dispatch(fetchAdvanceTypesSucceeded(advanceTypes));
      })
      .catch(e => {
        dispatch(ui.fetchFailed());
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

export const applyAdvanceTypesFilters = filters => ({
  type: APPLY_ADVANCE_TYPES_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetAdvanceTypesFilters = () => ({
  type: RESET_ADVANCE_TYPES_FILTERS
});

const changeAdvanceTypeStateSucceeded = id => ({
  type: ADVANCE_TYPES_CHANGE_STATE,
  payload: {
    id
  }
});

const changeAdvanceTypeStateStarted = id => dispatch => {
  dispatch(ui.changeStateStarted());
  changeAdvanceTypeState(id)
    .then(transactionKey => {
      dispatch(changeAdvanceTypeStateSucceeded(transactionKey));
      dispatch(ui.changeStateSucceeded());
    })
    .catch(e => {
      dispatch(ui.changeStateFailed());
      dispatch(ui.setOnErrorMessage(e.message));
    });
};

export const deleteAdvanceType = id => dispatch => {
  dispatch(changeAdvanceTypeStateStarted(id));
  dispatch(closeDeleteConfirmation());
};

export const restoreAdvanceType = id => dispatch => {
  dispatch(changeAdvanceTypeStateStarted(id));
  dispatch(closeRestoreConfirmation());
};

const createEditAdvanceTypeAction = (id, data) => ({
  type: ADVANCE_TYPES_EDIT,
  payload: {
    id: id,
    data: {
      ...data
    }
  }
});

export const editAdvanceType = (data, editingId) => dispatch => {
  dispatch(ui.editStarted());
  updateAdvanceType(editingId, data)
    .then(transactionKey => {
      dispatch(createEditAdvanceTypeAction(transactionKey, data));
      dispatch(closeEditForm());
      dispatch(ui.editSucceeded());
    })
    .catch(e => {
      dispatch(ui.editFailed());
      dispatch(ui.setOnErrorMessage(e.message));
    });
};

const createAdvanceTypeAction = data => ({
  type: ADVANCE_TYPES_CREATE,
  payload: {
    ...data
  }
});

export const createAdvanceTypeStarted = data => dispatch => {
  dispatch(ui.createStarted());
  createAdvanceType(data)
    .then(response => {
      if (response.transactionKey) {
        const advanceType = new AdvanceType(
          response.transactionKey,
          "1",
          data.description,
          data.approvalOfExpenses,
          data.obligationOfOT,
          false,
          true
        );
        dispatch(createAdvanceTypeAction(advanceType));
        dispatch(closeCreateForm());
        dispatch(ui.createSucceeded());
      } else {
        dispatch(ui.createSucceeded());
        throw new Error(response.transactionMessage);
      }
    })
    .catch(e => {
      dispatch(ui.createFailed());
      dispatch(ui.setOnErrorMessage(e.message));
    });
};

export default {
  get: fetchAdvanceTypes,
  delete: deleteAdvanceType,
  restore: restoreAdvanceType,
  edit: editAdvanceType,
  create: createAdvanceTypeStarted,
  applyAdvanceTypesFilters,
  resetAdvanceTypesFilters,
  resetState
};
