import {
  ADD_HEADQUARTER_STARTED,
  ADD_HEADQUARTERS_SUCCESS,
  APPLY_CLIENTS_FILTERS,
  CREATE_CLIENTS_STARTED,
  CREATE_CLIENTS_SUCCESS,
  EDIT_CLIENT_STARTED,
  EDIT_CLIENT_SUCCESS,
  EDIT_HEADQUARTER_STARTED,
  EDIT_HEADQUARTER_SUCCESS,
  ERROR_ON_TRANSACTION,
  FETCH_ALL_CLIENTS_STARTED,
  FETCH_ALL_CLIENTS_SUCCESS,
  FETCH_ALL_HEADQUARTERS_BY_CLIENT_STARTED,
  FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS,
  RESET_CLIENTS_FILTERS,
  RESET_CURRENT_CLIENT,
  RESET_TRANSACTION,
  SET_CURRENT_CLIENT
} from "../../types/Administrator/ClientsTypes";

const INIT_STATE = {
  current: null,
  data: [],
  isLoading: false,
  operation: {
    name: undefined,
    data: null,
    code: undefined,
    message: ""
  },
  filters: {
    clientCode: "",
    name: "",
    businessName: "",
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CLIENTS_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_CLIENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    }
    case SET_CURRENT_CLIENT: {
      return {
        ...state,
        current: {...action.payload}
      };
    }
    case RESET_CURRENT_CLIENT: {
      return {
        ...state,
        current: null
      };
    }
    case APPLY_CLIENTS_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_CLIENTS_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case CREATE_CLIENTS_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "create",
          data: action.payload
        }
      };
    }
    case CREATE_CLIENTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case ERROR_ON_TRANSACTION: {
      return {
        ...state,
        operation: {
          code: false,
          message: action.payload
        }
      };
    }
    case RESET_TRANSACTION: {
      return {
        ...state,
        operation: {...INIT_STATE.operation}
      };
    }
    case FETCH_ALL_HEADQUARTERS_BY_CLIENT_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          headquarters: action.payload
        }
      };
    }
    case ADD_HEADQUARTER_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ADD_HEADQUARTERS_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    case EDIT_CLIENT_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          ...INIT_STATE.operation,
          name: "edit",
          data: action.payload
        }
      };
    }
    case EDIT_CLIENT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case EDIT_HEADQUARTER_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case EDIT_HEADQUARTER_SUCCESS: {
      return {
        ...state,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
