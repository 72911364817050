export const FETCH_WAREHOUSE_TRANSFER_SUCCEEDED =
  "FETCH_WAREHOUSE_TRANSFER_SUCCEEDED";
export const APPLY_WAREHOUSE_TRANSFER_FILTERS =
  "APPLY_WAREHOUSE_TRANSFER_FILTERS";
export const RESET_WAREHOUSE_TRANSFER_FILTERS =
  "RESET_WAREHOUSE_TRANSFER_FILTERS";
export const WAREHOUSE_TRANSFER_CHANGE_STATE =
  "WAREHOUSE_TRANSFER_CHANGE_STATE";
export const WAREHOUSE_TRANSFER_EDIT = "WAREHOUSE_TRANSFER_EDIT";
export const WAREHOUSE_TRANSFER_CREATE = "WAREHOUSE_TRANSFER_CREATE";
