export const FETCH_SINGINGTYPES_SUCCEEDED = "FETCH_SINGINGTYPES_SUCCEEDED";
export const APPLY_SINGINGTYPES_FILTERS = "APPLY_SINGINGTYPES_FILTERS";
export const RESET_SINGINGTYPES_FILTERS = "RESET_SINGINGTYPES_FILTERS";
export const SINGINGTYPES_CREATE = "SINGINGTYPES_CREATE";
export const SINGINGTYPES_DELETE = "SINGINGTYPES_DELETE";
export const SINGINGTYPES_RESTORE = "SINGINGTYPES_RESTORE";
export const SINGINGTYPES_EDIT = "SINGINGTYPES_EDIT";
export const SINGINGTYPES_CHANGE_STATE = "SINGINGTYPES_CHANGE_STATE";
export const SINGINGTYPES_OPEN_DELETE_CONFIRMATION =
  "SINGINGTYPES_OPEN_DELETE_CONFIRMATION";
