import React from "react";
import {Box, Typography} from "@material-ui/core";
import {useStyles} from "../styles";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";

const DatesBox = ({title, children}) => {
  const classes = useStyles();
  return (
    <Box className={classes.datesBos}>
      <Box>
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box>
        <GridContainer spacing={2}>{children}</GridContainer>
      </Box>
    </Box>
  );
};

export default DatesBox;
