import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {connect} from "react-redux";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import CrudTableRow from "../../../../Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "../../../../Common/Tables/CrudTable/RestoreTableRow";
import {
  fetchAllConfigurationUsers,
  setCurrentConfigurationUser
} from "../../../../../redux/actions/Configuration/Users";
import {Box} from "@material-ui/core";
import Filters from "./Filters";
import FilterBuilder from "../../../../../utils/filters";

const UsersTable = ({
  data,
  dispatch,
  openEditDialog,
  openDeleteDialog,
  openRestoreDialog
}) => {
  React.useEffect(() => {
    dispatch(fetchAllConfigurationUsers());
  }, []);

  const onEdit = user => {
    dispatch(setCurrentConfigurationUser(user));
    openEditDialog && openEditDialog();
  };
  const onDelete = user => {
    dispatch(setCurrentConfigurationUser(user));
    openDeleteDialog && openDeleteDialog();
  };
  const onRestore = user => {
    dispatch(setCurrentConfigurationUser(user));
    openRestoreDialog && openRestoreDialog();
  };
  return (
    <Box display="flex" flexDirection="column" style={{gap: "10px"}}>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {Object.values(data).map(user => (
          <React.Fragment key={user.id}>
            {user.state ? (
              <CrudTableRow
                onEdit={() => onEdit(user)}
                onDelete={() => onDelete(user)}
              >
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell>{user.rolesNames}</StyledTableCell>
                <StyledTableCell>{user.vehicleRolesNames}</StyledTableCell>
              </CrudTableRow>
            ) : (
              <RestoreTableRow onRestore={() => onRestore(user)}>
                <StyledTableCell>{user.email}</StyledTableCell>
                <StyledTableCell>{user.rolesNames}</StyledTableCell>
                <StyledTableCell>{user.vehicleRolesNames}</StyledTableCell>
              </RestoreTableRow>
            )}
          </React.Fragment>
        ))}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = ({configuration}) => {
  const {data, filters} = configuration.users;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("email", filters.email)
    .byFieldIncludes("rolesNames", filters.role)
    .build();
  return {data: filteredData};
};

export default connect(mapStateToProps)(UsersTable);
