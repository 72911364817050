import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllCurrenciesStarted} from "../../../../../../../redux/actions/CustomCommon/Currencies";

const CurrencySelect = ({id, value, setValue, disabled}) => {
  const {currencies} = useSelector(({customCommon}) => customCommon.currencies);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllCurrenciesStarted());
  }, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.contract.currency"
          }
        />
      }
      fullWidth
    >
      {currencies.map(currency => (
        <MenuItem value={currency.id} key={currency.id}>
          {currency.symbol}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelect;
