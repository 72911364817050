class MonitoringDestination {
  constructor(id, name, state, date, otId) {
    this.id = id;
    this.state = state;
    this.name = name;
    this.date = date;
    this.otId = otId;
  }

  static map = destination => {
    return new MonitoringDestination(
      destination.idDespacho,
      destination.nombreCliente,
      Number(destination.estado),
      destination.fechaEstado,
      destination.idOT
    );
  };
}

export default MonitoringDestination;
