export const ACCOUNTING_FETCH_ALL_BY_USER_STARTED =
  "ACCOUNTING_FETCH_ALL_BY_USER_STARTED";
export const ACCOUNTING_FETCH_ALL_BY_USER_SUCCESS =
  "ACCOUNTING_FETCH_ALL_BY_USER_SUCCESS";
export const APPLY_ACCOUNTING_FILTERS = "APPLY_ACCOUNTING_FILTERS";
export const RESET_ACCOUNTING_FILTERS = "RESET_ACCOUNTING_FILTERS";
export const FETCH_ALL_EXPENSES_BY_ACCOUNTING_STARTED =
  "FETCH_ALL_EXPENSES_BY_ACCOUNTING_STARTED";
export const FETCH_ALL_EXPENSES_BY_ACCOUNTING_SUCCESS =
  "FETCH_ALL_EXPENSES_BY_ACCOUNTING_SUCCESS";
export const SET_TRANSACTION_RESULT = "SET_TRANSACTION_RESULT";
export const RESET_TRANSACTION_RESULT = "RESET_TRANSACTION_RESULT";
export const APPROVE_ACCOUNTING_STARTED = "APPROVE_ACCOUNTING_STARTED";
export const APPROVE_ACCOUNTING_SUCCESS = "APPROVE_ACCOUNTING_STARTED";
export const REJECT_ACCOUNTING_STARTED = "REJECT_ACCOUNTING_STARTED";
export const REJECT_ACCOUNTING_SUCCESS = "REJECT_ACCOUNTING_SUCCESS";
export const DELETE_ACCOUNTING_STARTED = "DELETE_ACCOUNTING_STARTED";
export const DELETE_ACCOUNTING_SUCCESS = "DELETE_ACCOUNTING_SUCCESS";

export const SET_ACCOUNTING_ACTIVE_FILTERS = "SET_ACCOUNTING_ACTIVE_FILTERS";
export const RESET_ACCOUNTING_ACTIVE_FILTERS =
  "RESET_ACCOUNTING_ACTIVE_FILTERS";

export const SET_CURRENT_ACCOUNTING_EXPENSE = "SET_CURRENT_ACCOUNTING_EXPENSE";