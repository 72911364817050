import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {fetchError} from "../../../../../../../redux/actions";
import {getAllBrands} from "../../../../../../../services/FleetManagement/Vehicles/BrandService";

const BrandSelect = ({
  id,
  label,
  value,
  setValue,
  error,
  hasError,
  disabled = false
}) => {
  const [ brands, setBrands ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    getAllBrands()
      .then(data => setBrands(data))
      .catch(e => dispatch(fetchError(e.message)));
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      disabled={disabled}
      error={hasError}
      errorMessage={error}
    >
      {brands.map(b => (
        <MenuItem key={`brand-${b.id}`} value={b.id}>
          {b.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BrandSelect;
