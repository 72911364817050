import ui from "../Administrator/ui";
import {
  FETCH_PAUSESTYPES_SUCCEEDED,
  APPLY_PAUSESTYPES_FILTERS
} from "../../types/RRHH/PausesTypes";
import {getCompanyEntityId} from "../../../services/StorageService";
import {
  fetchAllPausesTypes,
  createNewPausesTypes,
  updateNewPausesTypes,
  deletePausesTypesApi,
  restorePausesTypesApi
} from "../../../services/RRHH/PausesTypesServices";

const fetchPausesTypesSucceeded = data => ({
  type: FETCH_PAUSESTYPES_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchPausesTypes = () => async dispatch => {
  dispatch(ui.fetchStarted());

  try {
    const pausesTypes = await fetchAllPausesTypes();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchPausesTypesSucceeded(pausesTypes));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const createPausesTypes = data => async dispatch => {
  dispatch(ui.closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const {transactionKey} = await createNewPausesTypes(data);
    dispatch({
      type: "PAUSESTYPES_CREATE",
      payload: {
        id: transactionKey,
        stateType: data.description,
        active: true,
        entityId: getCompanyEntityId()
      }
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};

export const editPausesTypes = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());

  try {
    await updateNewPausesTypes(id, data);
    dispatch({
      type: "PAUSESTYPES_EDIT",
      payload: {
        id,
        data: {
          id: id,
          stateType: data.description,
          active: true,
          entityId: getCompanyEntityId()
        }
      }
    });
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
  }
};

export const deletePausesTypes = data => async dispatch => {
  dispatch(ui.closeDeleteConfirmation());
  dispatch(ui.createStarted());
  try {
    await deletePausesTypesApi(data);
    dispatch({
      type: "PAUSESTYPES_DELETE",
      payload: {
        id: data
      }
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};

export const restorePausesTypes = id => async dispatch => {
  dispatch(ui.closeRestoreConfirmation());
  dispatch(ui.createStarted());
  try {
    await restorePausesTypesApi(id);
    dispatch({
      type: "PAUSESTYPES_DELETE",
      payload: {
        id
      }
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};

export const applyPausesTypesFilters = filters => ({
  type: APPLY_PAUSESTYPES_FILTERS,
  payload: {
    filters: filters
  }
});

export default {
  get: fetchPausesTypes,
  create: createPausesTypes,
  edit: editPausesTypes,
  delete: deletePausesTypes,
  restore: restorePausesTypes,
  applyPausesTypesFilters
};
