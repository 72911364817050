import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "../Form";

const CreateProductDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-product-form"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.Products.createFormTitle" />}
    onClose={onClose}
  >
    <Form id="create-product-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateProductDialog;
