import React from "react";
import {StyledPaper} from "../../../AddAdvance/Form/styles";

const BaseField = ({children}) => {
  return (
    <StyledPaper elevation={3} outlined="true">
      {children}
    </StyledPaper>
  );
};

export default BaseField;
