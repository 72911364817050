import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import Operation from "../../domain/Configuration/Operation";
import Module from "../../domain/Configuration/Module";
import Submodule from "../../domain/Configuration/Submodule";

export const fetchAllConfigModules = async () => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_modulos", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => Module.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addConfigModule = async module => {
  try {
    const {data} = await axiosInstance.post("/Usuario/ins_modulo", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_modulo: module.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllConfigSubmodulesByModule = async module => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_sub_modulos", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_modulo: module.id
    });
    return data.map(d => Submodule.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addConfigSubmoduleToModule = async (module, submodule) => {
  try {
    const {data} = await axiosInstance.post("/Usuario/ins_sub_modulo", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_modulo: module.id,
      vc_desc_sub_modulo: submodule.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllConfigOperationsBySubmodule = async submodule => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_operaciones", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_sub_modulo: submodule.id
    });
    return data.map(d => Operation.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addConfigOperationToSubmodule = async (submodule, operation) => {
  try {
    const {data} = await axiosInstance.post("/Usuario/ins_operaciones", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_sub_modulo: submodule.id,
      vc_desc_operacion: operation.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
