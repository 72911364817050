import MonitoringDevice from "./MonitoringDevice";
import MonitoringDestination from "./MonitoringDestination";

class MonitoringWorkOrder {
  constructor(
    id,
    name,
    plaque,
    route,
    alias,
    driver,
    assigned,
    negotiated,
    delivered,
    partials,
    notDelivered,
    gps,
    signal,
    battery,
    disc,
    destinations
  ) {
    this.id = id;
    this.name = name;
    this.plaque = plaque;
    this.route = route;
    this.alias = alias;
    this.driver = driver;
    this.assigned = assigned;
    this.negotiated = negotiated;
    this.progress = delivered / assigned * 100;
    this.delivered = delivered;
    this.partials = partials;
    this.notDelivered = notDelivered;
    this.gps = gps;
    this.signal = signal;
    this.battery = battery;
    this.disc = disc;
    this.destinations = destinations;
  }

  static map = workOrder => {
    return new MonitoringWorkOrder(
      workOrder.ot,
      workOrder.vehiculo1,
      workOrder.vehiculo2,
      workOrder.ruta,
      workOrder.ot,
      {
        id: workOrder.conductor.id,
        name: workOrder.conductor.nombre
      },
      workOrder.totalDespachos,
      workOrder.totalDespachosGestionados,
      workOrder.totalDespachosEntregados,
      workOrder.totalDespachosParciales,
      workOrder.totalDespachosNoEntregados,
      MonitoringDevice.map(workOrder.gps),
      MonitoringDevice.map(workOrder.senial),
      MonitoringDevice.map(workOrder.bateria),
      MonitoringDevice.map(workOrder.disco),
      workOrder.despachos.map(d => MonitoringDestination.map(d))
    );
  };
}

export default MonitoringWorkOrder;
