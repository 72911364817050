import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import StyledTableRow from "components/Common/Tables/StyledTableRow";

import {parseNumberToMoney} from "../../../../../../utils/parsers";

const TableFooter = ({data}) => {
  const totalOf = field => data.reduce((amount, row) => amount + row[field], 0);

  const totals = {
    fund: totalOf("fund"),
    cash: totalOf("cash"),
    traffic: totalOf("transit"),
    liquidated: totalOf("liquidated"),
    refund: totalOf("refund")
  };

  return (
    <StyledTableRow>
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell />
      <StyledTableCell align="right">
        {parseNumberToMoney(totals.fund)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {parseNumberToMoney(totals.cash)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {parseNumberToMoney(totals.traffic)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {parseNumberToMoney(totals.liquidated)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {parseNumberToMoney(totals.refund)}
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableFooter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fund: PropTypes.number,
      cash: PropTypes.number,
      traffic: PropTypes.number,
      liquidated: PropTypes.number,
      refund: PropTypes.number
    })
  )
};

TableFooter.defaultProps = {
  data: []
};

export default TableFooter;
