export const RESET_CONFIG_MODULES_STATES = "RESET_CONFIG_MODULES_STATES";
export const FETCH_ALL_CONFIG_MODULES = "FETCH_ALL_CONFIG_MODULES";
export const SET_CURRENT_CONFIG_MODULE = "SET_CURRENT_CONFIG_MODULE";
export const ADD_CONFIG_MODULE = "ADD_CONFIG_MODULE";
export const EDIT_CONFIG_MODULE = "EDIT_CONFIG_MODULE";
export const EDIT_CONFIG_MODULE_STATE = "EDIT_CONFIG_MODULE_STATE";
export const RESET_CURRENT_CONFIG_MODULE = "RESET_CURRENT_CONFIG_MODULE";
export const APPLY_CONFIG_MODULES_FILTERS = "APPLY_CONFIG_MODULES_FILTERS";
export const RESET_CONFIG_MODULES_FILTERS = "RESET_CONFIG_MODULES_FILTERS";

export const SET_CURRENT_CONFIG_SUBMODULES = "SET_CURRENT_CONFIG_SUBMODULES";
export const ADD_CONFIG_SUBMODULE = "ADD_CONFIG_SUBMODULE";

export const FETCH_ALL_CONFIG_MODULE_OPERATIONS =
  "FETCH_ALL_CONFIG_MODULE_OPERATIONS";
export const SET_CURRENT_CONFIG_SUBMODULE_OPERATIONS =
  "SET_CURRENT_CONFIG_SUBMODULE_OPERATIONS";
export const RESET_CURRENT_CONFIG_SUBMODULE_OPERATIONS =
  "RESET_CURRENT_CONFIG_SUBMODULE_OPERATIONS";
export const ADD_CONFIG_SUBMODULE_OPERATION = "ADD_CONFIG_SUBMODULE_OPERATION";
export const EDIT_CONFIG_MODULE_OPERATION = "EDIT_CONFIG_MODULE_OPERATION";
export const EDIT_CONFIG_MODULE_OPERATION_STATE =
  "EDIT_CONFIG_MODULE_OPERATION_STATE";
