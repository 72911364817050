import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import IncidenceType from "../domain/IncidenceType";
import Response from "../domain/Response";

let fetchAllCancelToken = undefined;

const tipoIncidencia = [
  {
    nu_id_tipo_incidencia: 1,
    vc_desc_tipo_incidencia: "Incidencia 1",
    bi_estado: "1"
  },
  {
    nu_id_tipo_incidencia: 2,
    vc_desc_tipo_incidencia: "Incidencia 2",
    bi_estado: "1"
  },
  {
    nu_id_tipo_incidencia: 3,
    vc_desc_tipo_incidencia: "Incidencia 3",
    bi_estado: "1"
  }
];

export const cancelPrevIncidenceTypeFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllIncidenceType = async () => {
  //return tipoIncidencia.map(tipoIncidencia => IncidenceType.map(tipoIncidencia));
  try {
    const response = await axiosInstance.post(
      "/Inventario_Tipo_Incidencia/sel",
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(tiposIncidencia =>
      IncidenceType.map(tiposIncidencia)
    );
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createIncidenceType = async data => {
  //console.log("DATA INCIDENCE: ",data);
  //return 1;
  try {
    const response = await axiosInstance.post(
      "/Inventario_Tipo_Incidencia/ins",
      {
        vc_desc_tipo_incidencia: data.description,
        vc_usuario: "administradorcajachica@4gflota.com"
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editIncidenceType = async (id, data) => {
  //return 1;
  try {
    const response = await axiosInstance.post(
      "/Inventario_Tipo_Incidencia/upd",
      {
        nu_id_tipo_incidencia: id,
        vc_desc_tipo_incidencia: data.description,
        vc_usuario: "administradorcajachica@4gflota.com"
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeStateIncidenceType = async id => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Tipo_Incidencia/del",
      {
        nu_id_tipo_incidencia: id
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
