import {
  CREATE_TAG_STARTED,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_TYPE_STARTED,
  CREATE_TAG_TYPE_SUCCESS,
  DELETE_TAG_STARTED,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_TYPE_STARTED,
  DELETE_TAG_TYPE_SUCCESS,
  EDIT_TAG_STARTED,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_TYPE_STARTED,
  EDIT_TAG_TYPE_SUCCESS,
  ERROR_ON_TRANSACTION,
  FETCH_ALL_TAG_TYPES_STARTED,
  FETCH_ALL_TAG_TYPES_SUCCESS,
  FETCH_ALL_TAGS_BY_TYPE_STARTED,
  FETCH_ALL_TAGS_BY_TYPE_SUCCESS,
  RESET_CURRENT_TAG_TYPE,
  RESET_TRANSACTION,
  SET_CURRENT_TAG_TYPE
} from "../../types/Administrator/TagsTypes";

const INIT_STATE = {
  current: null,
  data: [],
  isLoading: false,
  operation: {
    name: undefined,
    data: null,
    code: undefined,
    message: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TAG_TYPES_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_TAG_TYPES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload
      };
    }
    case CREATE_TAG_TYPE_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "create-type",
          data: action.payload
        }
      };
    }
    case CREATE_TAG_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case EDIT_TAG_TYPE_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "edit-type",
          data: action.payload
        }
      };
    }
    case EDIT_TAG_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case DELETE_TAG_TYPE_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "delete-type",
          data: action.payload
        }
      };
    }
    case DELETE_TAG_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case CREATE_TAG_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "create-tag",
          data: action.payload
        }
      };
    }
    case CREATE_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case EDIT_TAG_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "edit-tag",
          data: action.payload
        }
      };
    }
    case EDIT_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case DELETE_TAG_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {
          name: "delete-tag",
          data: action.payload
        }
      };
    }
    case DELETE_TAG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: {
          ...INIT_STATE.operation,
          code: true,
          message: action.payload
        }
      };
    }
    case SET_CURRENT_TAG_TYPE: {
      return {
        ...state,
        current: {...action.payload}
      };
    }
    case RESET_CURRENT_TAG_TYPE: {
      return {
        ...state,
        current: null
      };
    }
    case ERROR_ON_TRANSACTION: {
      return {
        ...state,
        operation: {
          code: false,
          message: action.payload
        }
      };
    }
    case RESET_TRANSACTION: {
      return {
        ...state,
        operation: {
          code: false,
          message: action.payload
        }
      };
    }
    case FETCH_ALL_TAGS_BY_TYPE_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_TAGS_BY_TYPE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        current: {
          ...state.current,
          tags: action.payload
        }
      };
    }
    default: {
      return state;
    }
  }
};
