import React from 'react';
import {useSelector} from "react-redux";
import Form from "../common/Form";
import Table from "../common/Table";
import NestedList from "../common/NestedList";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const Driver = () => {
    const fileData = useSelector(({fleetManagement}) => fleetManagement.filesReader.fileData);
    return (
        <>
            <Form
                title={<IntlMessages id='id.card'/>}
                data={fileData.cardInfo}
            />
            <Table
                title={<IntlMessages id='event2'/>}
                data={fileData.events}
            />
            <NestedList
                title={<IntlMessages id='activities'/>}
                data={fileData.activities}
                subElementKey='FechaActividad'
            />
            <NestedList
                title={<IntlMessages id='activities.summary'/>}
                data={fileData.activitiesSummary}
                subElementKey='Mes'
            />
            <Table
                title={<IntlMessages id='used.vehicles'/>}
                data={fileData.usedVehicles}
            />
            <Table
                title={<IntlMessages id='places'/>}
                data={fileData.places}
            />
            <Table
                title={<IntlMessages id='activity.control'/>}
                data={fileData.activityControl}
            />
            <Table
                title={<IntlMessages id='specific.conditions'/>}
                data={fileData.specificConditions}
            />
            <Table
                title={<IntlMessages id='frontier.crosses'/>}
                data={fileData.frontierCrosses}
            />
            <Table
                title={<IntlMessages id='load.unload.operations'/>}
                data={fileData.loadUnloadOperations}
            />
            <Table
                title={<IntlMessages id='load.type'/>}
                data={fileData.deliveryLoadTypes}
            />
        </>
    );
};

export default Driver;