import axios from "axios";
import {getToken} from "../services/StorageService";
import {getNewToken} from "./tokenInstance";

export const CancelToken = axios.CancelToken;

export const isCancel = thrown => axios.isCancel(thrown);

const axiosInstance = axios.create({
  //baseURL: "https://oramnc0sc5.execute-api.us-east-1.amazonaws.com/Prod/api",
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    "Content-Type": "application/json"
  },
});

axiosInstance.interceptors.request.use(config => {
  const token = getToken();
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
});


axiosInstance.interceptors.response.use(config => {
  return config;
}, async error => {
  if(error.message === undefined)  {
    return Promise.reject(error);
  }
  const {response, config} = error;
  const {status} = response;
  if(status === 401) {
    await getNewToken();
    return axiosInstance.request(config);
  }
  return Promise.reject(error);
});

export default axiosInstance;
