import {mapToBoolean} from "../../../utils/mappers";

class Insurance {
  constructor(
    id,
    vehicleId,
    plaque,
    policy,
    terminationDate,
    commencementDate,
    expirationDate,
    price,
    companyId,
    typeId,
    phone,
    contact,
    status
  ) {
    this.id = id;
    this.plaque = plaque;
    this.policy = policy;
    this.terminationDate = terminationDate;
    this.commencementDate = expirationDate;
    this.expirationDate = expirationDate;
    this.price = price;
    this.companyId = companyId;
    this.typeId = typeId;
    this.phone = phone;
    this.contact = contact;
    this.status = status;
  }

  static map = insurance => {
    return new Insurance(
      insurance.nu_id_seguro_tipo,
      insurance.nu_id_vehiculo,
      insurance.vc_placa,
      insurance.vc_nro_poliza,
      insurance.dt_fec_alta,
      insurance.dt_fec_vencimiento,
      insurance.nu_prima,
      insurance.nu_id_aseguradora,
      insurance.nu_id_seguro_tipo,
      insurance.vc_telefono,
      insurance.vc_persona_contacto,
      mapToBoolean(insurance.bi_estado)
    );
  };
}

export default Insurance;
