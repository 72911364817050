import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/RejectionTypes/PageContainer";
import CreateRejectionTypeDialog from "components/Administrator/RejectionTypes/SaveRejectionTypeDialog/CreateRejectionTypeDialog";
import EditRejectionTypeDialog from "components/Administrator/RejectionTypes/SaveRejectionTypeDialog/EditRejectionTypeDialog";
import DeleteConfirmation from "components/Administrator/RejectionTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/RejectionTypes/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/RejectionTypes/Table";
import Filters from "components/Administrator/RejectionTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";

import useUiState from "hooks/Administrator/useUiState";

import rejectionTypes from "redux/actions/Administrator/RejectionTypes";
import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";

const RejectionTypes = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(rejectionTypes.get());
      return () => dispatch(rejectionTypes.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(rejectionTypes.applyFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(rejectionTypes.resetFilters());

  const onDelete = () => dispatch(rejectionTypes.delete(ui.deletingId));
  const onRestore = () => dispatch(rejectionTypes.restore(ui.restoringId));
  const onEdit = data => dispatch(rejectionTypes.edit(ui.editingId, data));
  const onCreate = data => dispatch(rejectionTypes.create(data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateRejectionTypeDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditRejectionTypeDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.rejectionTypes;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("description", filters.description)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(RejectionTypes);
