import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import NameForm from "../NameForm";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";
import {useStyles} from "../styles";
import {useDispatch, useSelector} from "react-redux";
import {addGroupToChecklistType} from "../../../../../redux/actions/Logistic/ChecklistTypes";

const AddGroupDialog = ({open, setOpen}) => {
  const id = "add-group-form";
  const {current} = useSelector(({logistic}) => logistic.checklistTypes);
  const classes = useStyles();
  const dispatch = useDispatch();
  const onSave = data => {
    dispatch(addGroupToChecklistType(data, current));
    setOpen(false);
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"logistic.distribution.checklist.types.table.edit.groups.add"}
        />
      }
      onClose={() => setOpen(false)}
      isOpen={open}
      cancelText={<IntlMessages id={"form.button.cancel"} />}
      submitText={<IntlMessages id={"form.button.save"} />}
    >
      {open && (
        <Box className={classes.nameFormBox}>
          <NameForm
            id={id}
            onSave={onSave}
            inputLabel={
              <IntlMessages
                id={
                  "logistic.distribution.checklist.types.table.edit.groups.add.name"
                }
              />
            }
          />
        </Box>
      )}
    </FormDialog>
  );
};

export default AddGroupDialog;
