import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
    costCenter: [
        {
            rule: v => v.toString().trim() !== "",
            message: (
                <IntlMessages id="form.field.error" />
            )
        }
    ],
}