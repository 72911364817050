import ui from "./ui";
import {
  cancelPrevWarehouseTransferSelectFetchAll,
  fetchAllWarehouseTransferSelect
} from "../../../services/WarehouseTransferSelectService";
import {FETCH_WAREHOUSE_TRANSFER_SELECT} from "../../types/Administrator/WarehouseTransferSelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevWarehouseTransferSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchWarehouseTransferSelectSucceeded = data => ({
  type: FETCH_WAREHOUSE_TRANSFER_SELECT,
  payload: {
    data
  }
});

export const fetchDataWarehouseTransferSelect = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevWarehouseTransferSelectFetchAll();
    fetchAllWarehouseTransferSelect().then(transferSelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchWarehouseTransferSelectSucceeded(transferSelect));
    });
  };
};

export default {
  get: fetchDataWarehouseTransferSelect,
  resetState
};
