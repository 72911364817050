export const ADD_ADVANCE_STARTED = "assign_advance_started";
export const ADD_ADVANCE_SUCCESSFUL = "assign_advance_successful";
export const RESET_ADD_ADVANCE_FORM = "reset_assign_advance_form";

export const CLEAR_CURRENT_BOX = "clear_current_box";
export const UPDATE_CURRENT_BOX = "update_current_box";

export const SET_ADVANCES_LIST_FILTER = "activate_advances_list_filter";
export const CLEAR_ADVANCES_LIST_FILTER = "clear_advances_list_filter";

export const ADD_EXPENSE_STARTED = "add_expense_started";
export const ADD_EXPENSE_SUCCESSFUL = "add_expense_successful";
export const ADD_EXPENSE_FAILED = "ADD_EXPENSE_FAILED";
export const RESET_ADD_EXPENSE_FORM = "reset_add_expense_form";

export const SET_CURRENT_ADVANCE = "set_current_advance";
export const CLEAR_CURRENT_ADVANCE = "clear_current_advance";

export const SET_CURRENT_ACCOUNTING = "SET_CURRENT_ACCOUNTING";
export const CLEAR_CURRENT_ACCOUNTING = "CLEAR_CURRENT_ACCOUNTING";

export const SET_SUPERVISOR_ROLE = "SET_SUPERVISOR_ROLE";
export const CLEAR_SUPERVISOR_ROLE = "CLEAR_SUPERVISOR_ROLE";
