import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import ProductSelect from "../domain/ProductSelect";
import Response from "../domain/Response";

let fetchAllCancelToken = undefined;

export const cancelPrevProductSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllProductSelect = async idWarehouse => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Producto/sel_producto_for_almacen",
      {
        nu_id_almacen: idWarehouse
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(productSelect => ProductSelect.map(productSelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const fetchAllProductSelectRegulation = async idWarehouse => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Producto/sel_producto_for_almacen_regulacion",
      {
        nu_id_almacen: idWarehouse
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(productSelect => ProductSelect.map(productSelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
