import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {fetchAllCreditNotes, getAndSetCreditNoteDetails, removeCreditNote} from "../../thunks/Treasury/creditNotes";

const INIT_STATE = {
    creditNotes: {},
    dialogs: [],
    currentCreditNote: null,
}

const billSlice = createSlice({
    name: 'treasury/creditNotes',
    initialState: cloneDeep(INIT_STATE),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentCreditNote: (state, action) => {
          state.currentCreditNote = action.payload;
        },
        resetCreditNotesState: () => cloneDeep(INIT_STATE)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllCreditNotes.fulfilled, (state, action) => {
                state.creditNotes = action.payload.creditNotes.reduce((obj, creditNote) => {
                    obj[creditNote.id] = creditNote;
                    return obj;
                }, {});
            })
            .addCase(removeCreditNote.fulfilled, (state, action) => {
                const {creditNoteId} = action.payload;
                const creditNotes = cloneDeep(state.creditNotes);
                delete creditNotes[creditNoteId];
                state.creditNotes = creditNotes;
            })
            .addCase(getAndSetCreditNoteDetails.fulfilled, (state, action) => {
                state.currentCreditNote = action.payload;
            });
    }
});

export default billSlice.reducer;
export const {
    openDialog,
    closeDialog,
    setCurrentCreditNote,
    resetCreditNotesState,
} = billSlice.actions;