import React from "react";

import PageContainer from "../../../../../components/FleetManagement/Vehicles/File/PageContainer";

import VehiclesTable from "../../../../../components/FleetManagement/Vehicles/File/VehiclesTable";
import VehicleDetailDialog from "../../../../../components/FleetManagement/Vehicles/File/VehicleDetailDialog";
import AddVehicleDialog from "../../../../../components/FleetManagement/Vehicles/File/AddVehicleDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import AddBoxIcon from "@material-ui/icons/AddBox";
import TypeManagementDialog from "../../../../../components/FleetManagement/Vehicles/File/TypeManagementDialog";
import TagManagementDialog from "../../../../../components/FleetManagement/Vehicles/File/TagManagementDialog";
import FloatingButton from "../../../../../components/Common/Buttons/FloatingButton";

const Files = () => {
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openAddType, setOpenAddType ] = React.useState(false);
  const [ openAddTag, setOpenAddTag ] = React.useState(false);
  const otherActions = [
    {
      name: "manage-types",
      icon: <AddToPhotosIcon />,
      title: (
        <IntlMessages
          id={"fleet.management.vehicles.specifications.management.type.title"}
        />
      ),
      onClick: () => setOpenAddType(true)
    },
    {
      name: "manage-tags",
      icon: <AddBoxIcon />,
      title: (
        <IntlMessages
          id={"fleet.management.vehicles.specifications.management.tag.title"}
        />
      ),
      onClick: () => setOpenAddTag(true)
    }
  ];
  return (
    <PageContainer>
      <VehiclesTable />
      <VehicleDetailDialog />
      <FloatingButton actions={otherActions} />
      <AddVehicleDialog open={openAdd} setOpen={setOpenAdd} />
      <TypeManagementDialog open={openAddType} setOpen={setOpenAddType} />
      <TagManagementDialog open={openAddTag} setOpen={setOpenAddTag} />
    </PageContainer>
  );
};

export default Files;
