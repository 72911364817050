import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import BoxInput from "../../../Common/Inputs/BoxInput";
import ObservationsInput from "../../../Common/Inputs/ObservationsInput";
import validations from "./validations";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {useSelector} from "react-redux";

const initialData = {
  box: "",
  observations: ""
};

const Form = ({id, onSubmit}) => {
  const {formData, errors, setFormData, handleSubmit} = useForm(
    initialData,
    validations
  );
  const {isLoading} = useSelector(state => state.guardian.newAccounting);
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <BoxInput
          box={formData.box}
          errors={errors}
          setBox={data => setFormData("box", data)}
          isLoading={isLoading}
          label={"guardian.accounting.new.accounting.dialog.select.box"}
          className="mb-5"
        />
        <ObservationsInput
          observations={formData.observations}
          errors={errors}
          setObservations={data => setFormData("observations", data)}
          isLoading={isLoading}
          label={"guardian.accounting.new.accounting.dialog.observations"}
        />
      </GridContainer>
    </form>
  );
};

export default Form;
