import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import validations from "./validations";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import CountrySelect from "../../../../Common/Forms/Select/CountrySelect";

const Form = ({initialData, id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initialData,
    validations
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.clientCode.length > 0}
            error={errors.clientCode[0]}
            value={formData.clientCode}
            id="edit-client-client-code"
            label={<IntlMessages id="administrator.clients.form.client.code" />}
            onChange={value => setFormData("clientCode", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.name.length > 0}
            error={errors.name[0]}
            value={formData.name}
            id="edit-client-name"
            label={<IntlMessages id="administrator.clients.form.name" />}
            onChange={value => setFormData("name", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.businessName.length > 0}
            error={errors.businessName[0]}
            value={formData.businessName}
            id="edit-client-business-name"
            label={
              <IntlMessages id="administrator.clients.form.business.name" />
            }
            onChange={value => setFormData("businessName", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CountrySelect
            fullWidth
            hasError={errors.country.length > 0}
            error={errors.country[0]}
            value={formData.country}
            id="create-client-country"
            label={<IntlMessages id="administrator.clients.form.country" />}
            onChange={value => setFormData("country", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.code.length > 0}
            error={errors.code[0]}
            value={formData.code}
            id="edit-client-code"
            label={<IntlMessages id="administrator.clients.form.code" />}
            onChange={value => setFormData("code", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.email.length > 0}
            error={errors.email[0]}
            value={formData.email}
            id="edit-client-email"
            label={<IntlMessages id="administrator.clients.form.email" />}
            onChange={value => setFormData("email", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
