import {Grid, Typography, withStyles} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import {makeStyles} from "@material-ui/styles";

const K_WIDTH = 40;
const K_HEIGHT = 80;

export const LocationMarker = withStyles(theme => ({
  root: {
    width: K_WIDTH,
    height: K_HEIGHT,
    maxHeight: "200px",

    // border: '5px solid #f44336',
    // borderRadius: K_HEIGHT,
    textAlign: "center",
    fontSize: "80px",
    fontWeight: "bold"
    // padding: 4
  }
}))(RoomIcon);

export const useStyles = makeStyles({
  root: {
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center"
  },
  count: {
    position: "absolute",
    top: K_HEIGHT / 2 - 2,
    marginRight: "2px",
    lineHeight: 1,
    fontSize: K_HEIGHT / 5
  },
  markerDetailBox: {
    border: "none"
  }
});
