import ui from "../../reducers/Administrator/ui";
import {
  GENERATE_REPORT_CDS_STARTED,
  GENERATE_REPORT_CDS_SUCCESS,
  RESET_REPORT_CDS_STATE
} from "../../types/Administrator/ReportCDS";
import {
  fetchAdministrativeTurnReport,
  fetchDistributionA1A2Report,
  fetchDistributionB1B2Report,
  fetchEventsListReport,
  fetchPickA1A2Report,
  fetchPickB1B2Report,
  fetchStaggeredReport,
  fetchGraphicEventReport,
  fetchMinaReport
} from "../../../services/ControlPanel/Kpi/Reports/ReportCDSService";

const services = {
  1: fetchEventsListReport,
  2: fetchPickA1A2Report,
  3: fetchDistributionA1A2Report,
  4: fetchPickB1B2Report,
  5: fetchDistributionB1B2Report,
  6: fetchStaggeredReport,
  7: fetchAdministrativeTurnReport,
  8: fetchGraphicEventReport,
  9: fetchMinaReport
};

const generateReportCDSSuccess = (file = "", message = "") => {
  return {
    type: GENERATE_REPORT_CDS_SUCCESS,
    payload: {
      file: file,
      message: message
    }
  };
};

export const generatePDFReportStarted = (filters, service) => {
  return async dispatch => {
    dispatch({type: GENERATE_REPORT_CDS_STARTED, payload: 1});
    services
      [service](filters, 1)
      .then(file => dispatch(generateReportCDSSuccess(file)))
      .catch(e => dispatch(generateReportCDSSuccess("", e.message)));
  };
};

export const generateExcelReportStarted = (filters, service) => {
  return async dispatch => {
    dispatch({type: GENERATE_REPORT_CDS_STARTED, payload: 2});
    services
      [service](filters, 2)
      .then(file => dispatch(generateReportCDSSuccess(file)))
      .catch(e => dispatch(generateReportCDSSuccess("", e.message)));
  };
};

export const resetReportStateCDS = () => {
  return {
    type: RESET_REPORT_CDS_STATE
  };
};
