import React, {useCallback, useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {clearAdvancesListFilter, setAdvancesListFilter} from "../../../../redux/actions/Guardians/AdvancesListFilter";
import {setSupervisorRole} from "../../../../redux/actions/Guardians/SupervisorRole";
import {
    applyAdvancesListFilters,
    fetchAllAdvancesByGuardianStarted,
    resetAdvancesListFilters
} from "../../../../redux/actions/Guardians/Advances";
import {clearCurrentAdvance, setCurrentAdvance} from "../../../../redux/actions/Guardians/CurrentAdvance";
import {Box} from "@material-ui/core";
import AdvancesListHeader from "../../../../components/Guardian/AdvancesList/AdvancesListHeader";
import AdvancesFilters from "../../../../components/Guardian/AdvancesList/AdvancesFilters";
import AdvancesTable from "../../../../components/Guardian/AdvancesList/AdvancesTable";
import AdvanceDetailDialog from "../../../../components/Guardian/AdvancesList/AdvanceDetailDialog";
import SuccessAlert from "../../../../components/Common/SnackBars/SuccessAlert";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import UpdateAdvanceStateAlert from "../../../../components/Supervisor/UnresolvedAdvance/UpdateAdvanceStateAlert";
import AdvancesFilterBuilder from "../../../../components/Guardian/AdvancesList/AdvancesFilters/AdvancesFilterBuilder";
import PageContainer from "../../../../components/Supervisor/UnresolvedAdvance/PageContainer";

const SupervisorsUnresolvedAdvancesView = ({tableData, filters, list, dispatch}) => {
  const {currentAdvance} = useSelector(state => state.guardian);

  useEffect(() => {
    dispatch(setAdvancesListFilter({state: "1", active: true}));
    dispatch(setSupervisorRole());
  }, []);

  const [ advanceLiquidateAlert, setAdvanceLiquidateAlert ] = React.useState(
      false
  );
  const applyFilters = useCallback(
      filters => dispatch(applyAdvancesListFilters(filters)),
      [ dispatch ]
  );
  const resetFilters = () => {
    dispatch(resetAdvancesListFilters());
    dispatch(clearAdvancesListFilter());
  };

  const [ openDialog, setOpenDialog ] = useState(false);

  useEffect(
      () => {
        dispatch(fetchAllAdvancesByGuardianStarted(filters));
      },
      [ filters ]
  );
  useEffect(
      () => {
        if (currentAdvance.id && list.length > 0 && currentAdvance.id !== "") {
          dispatch(
              setCurrentAdvance(
                  list.find(advance => currentAdvance.id === advance.id)
              )
          );
          setOpenDialog(true);
        }
      },
      [ list ]
  );

  const handleSelectAdvance = advance => {
    dispatch(setCurrentAdvance(advance));
    setOpenDialog(true);
  };

  const handleClose = () => {
    dispatch(clearCurrentAdvance());
    setOpenDialog(false);
  };

  return (
      <PageContainer>
        <Box display="flex" flexDirection="column">
          <AdvancesListHeader />
          <AdvancesFilters applyFilters={applyFilters} onClose={resetFilters} />
          <AdvancesTable
              drivers={tableData}
              setSelectedAdvance={handleSelectAdvance}
              isGuardian={false}
          />
          {/*{selectedAdvance && <AdvanceDetail advance={selectedAdvance} />}*/}
        </Box>
        <AdvanceDetailDialog
            handleClose={handleClose}
            open={openDialog}
            isGuardian={false}
            setAdvanceLiquidateAlert={setAdvanceLiquidateAlert}
        />
        <SuccessAlert
            message={<IntlMessages id={"guardian.advances.liquidate.advance.ok"} />}
            openAlert={advanceLiquidateAlert}
            setOpenAlert={setAdvanceLiquidateAlert}
        />
        <UpdateAdvanceStateAlert />
      </PageContainer>
  );
};

const mapStateToProps = (state) => {
    const {data, filters, list} = state.guardian.advancesList;
    const tableData = new AdvancesFilterBuilder(data)
        .byFieldIncludes('routeCode', filters.route)
        .build();
    return {
        tableData,
        filters,
        list
    }
}

export default connect(mapStateToProps)(SupervisorsUnresolvedAdvancesView);
