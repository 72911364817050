import * as XLSX from "xlsx";

const toBase64 = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export const getTypeFromName = name => {
  const dotIndex = name.lastIndexOf(".");
  return dotIndex === -1 ? "" : name.substring(dotIndex + 1, name.length);
};

export const readDataFromExcel = (file, saveData) => {
  if (!file) return null;
  const fileReader = new FileReader();

  fileReader.readAsBinaryString(file);
  fileReader.onload = e => {
    var data = e.target.result;
    let readData = XLSX.read(data, {type: "binary"});
    const wsname = readData.SheetNames[0];
    const ws = readData.Sheets[wsname];
    saveData && saveData(XLSX.utils.sheet_to_json(ws, {header: 0}));
  };

  fileReader.onerror = () => {
    saveData && saveData(fileReader.error);
  };
};

export const generateFileObject = async file => {
  try {
    const base64File = await toBase64(file);
    return {
      name: file.name,
      extension: file.name.split(".")[1],
      contentType: file.type,
      base64File: base64File.split(",")[1],
      file
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export default toBase64;
