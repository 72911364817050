import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.workshop.table.plaque"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.workshop.table.entry"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.workshop.table.exit"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.workshop.table.status"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.workshop.table.workshop"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.workshop.table.amount"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
