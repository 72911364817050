import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import OutlinedDatePicker from "components/Common/Forms/OutlinedDatePicker";
import {mapDateToOutput, mapDateToValue} from "utils/parsers";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import incidence from "../../../../../redux/actions/Administrator/Incidence";

const INIT_DATA = {
  origin: "",
  destination: "",
  fromDate: null,
  toDate: null
};

const Form = () => {
  const {formData, setFormData, resetForm} = useForm(INIT_DATA);
  const dispatch = useDispatch();
  const resetFilters = () => {
    resetForm();
  }
  const applyFilters = () => {
    dispatch(incidence.get(formData));
  }
  useEffect(() => {
    dispatch(incidence.get(formData));
  }, []);
  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.origin}
            id="incidents-origin-filter"
            label={<IntlMessages id="logistics.incidents.filters.origin" />}
            onChange={value => setFormData("origin", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.destination}
            id="incidents-destination-filter"
            label={
              <IntlMessages id="logistics.incidents.filters.destination" />
            }
            onChange={value => setFormData("destination", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.fromDate)}
            id="incidents-from-date-filter"
            label={<IntlMessages id="logistics.incidents.filters.fromDate" />}
            onChange={value => setFormData("fromDate", mapDateToOutput(value))}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.toDate)}
            id="incidents-to-date-filter"
            label={<IntlMessages id="logistics.incidents.filters.toDate" />}
            onChange={value => setFormData("toDate", mapDateToOutput(value))}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button
            onClick={resetFilters}
            variant="contained"
          >
            <IntlMessages id={"filters.button.clear"} />
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button
            onClick={applyFilters}
            variant="contained"
            color="primary"
          >
            <IntlMessages id={"filters.button.apply"} />
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
