import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const RegulationSelect = ({value, onChange}) => {
  const options = [
    {
      id: 1,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.regulation.island"
          }
        />
      )
    },
    {
      id: 0,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.regulation.peninsula"
          }
        />
      )
    }
  ];
  return (
    <Select
      label={
        <IntlMessages
          id={"control.panel.kpi.areas.grid.tachograph.filters.regulation"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      {options.map(o => (
        <MenuItem key={o.id} value={o.id}>
          {o.description}
        </MenuItem>
      ))}
    </Select>
  );
};

RegulationSelect.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RegulationSelect;
