import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  Tooltip,
  Typography
} from "@material-ui/core";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {OperativeStyledTableCell} from "./styles";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import {setCurrentBox} from "../../../../redux/actions/Guardians/CurrentBox";
import {parseNumberToMoney} from "../../../../utils/parsers";
import useTableSort from "../../../../hooks/Common/useTableSort";
import {setAdvancesListFilter} from "../../../../redux/actions/Guardians/AdvancesListFilter";
import PendingStates from "../../../../models/Guardian/PendingStates";
import PettyCashesTableHeader from "./PettyCashesTableHeader";

const PettyCashesTable = ({boxes, currency}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {tableData, onSort, order, orderBy} = useTableSort(boxes);

  const totalOf = field =>
    tableData.reduce((amount, box) => amount + box[field], 0);
  let tagKey = 0;

  const totals = {
    fund: totalOf("fund"),
    cash: totalOf("cash"),
    traffic: totalOf("traffic"),
    liquidated: totalOf("liquidated"),
    refund: totalOf("refund")
  };

  const actions = [
    {
      label: (
        <IntlMessages id={"guardian.operative.boxes.row.option.add.expense"} />
      ),
      link: "/treasury/cash-management/add-expenses",
      option: "add-expense"
    },
    {
      label: (
        <IntlMessages id={"guardian.operative.boxes.row.option.add.advance"} />
      ),
      link: "/treasury/cash-management/add-advances",
      option: "add-advance"
    }
  ];

  const onClickHandler = (field, box) => {
    // console.log(`${planning} - ${field}`);
    dispatch(setCurrentBox(box));
    if (field === "traffic" || field === "liquidated") {
      const filter = field === "traffic" ? "2" : "4";
      dispatch(
        setAdvancesListFilter({state: filter, active: true, box: box.box})
      );
      history.push("/treasury/cash-management/advances-list");
    } else if (field === "refund") {
      history.push("/treasury/cash-management/accounting-list");
    }
  };

  const optionClickHandler = (option, link, box) => {
    // console.log(option);
    dispatch(setCurrentBox(box));
    history.push(link);
  };

  return (
    <React.Fragment>
      {tableData && tableData.length > 0 ? (
        <TableContainer component={Paper} style={{marginBottom: "20px"}}>
          <Table size="small">
            <PettyCashesTableHeader
              onSort={onSort}
              order={order}
              orderBy={orderBy}
            />
            <TableBody>
              {tableData.map(row => (
                <StyledTableRow key={tableData.indexOf(row)}>
                  <StyledTableCell align="left">
                    {row.tags.map(tag => (
                      <div
                        className="mb-2 mt-2"
                        key={`Box-${row.box}-${tag}-${tagKey++}`}
                      >
                        {tag}
                      </div>
                    ))}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.costCenter}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {parseNumberToMoney(row.fund)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {parseNumberToMoney(row.cash)}
                  </StyledTableCell>
                  <OperativeStyledTableCell
                    onClick={() => onClickHandler("traffic", row)}
                    align="left"
                  >
                    {parseNumberToMoney(row.traffic)}
                  </OperativeStyledTableCell>
                  <OperativeStyledTableCell
                    onClick={() => onClickHandler("liquidated", row)}
                    align="left"
                  >
                    {parseNumberToMoney(row.liquidated)}
                  </OperativeStyledTableCell>
                  {/*<OperativeStyledTableCell onClick={() => onClickHandler('refund', row)} align="left">*/}
                  {/*  {parseNumberToMoney(row.refund)}*/}
                  {/*</OperativeStyledTableCell>*/}
                  <StyledTableCell align="left">
                    {parseNumberToMoney(row.refund)}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {row.state ? (
                      <IntlMessages
                        id={"guardians.operative.plannings.table.state.active"}
                      />
                    ) : (
                      <IntlMessages
                        id={
                          "guardians.operative.plannings.table.state.inactive"
                        }
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <CmtDropdownMenu
                      TriggerComponent={
                        <Tooltip title="More">
                          <IconButton style={{marginLeft: 4}}>
                            <MoreVertIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      items={actions}
                      onItemClick={item => {
                        optionClickHandler(item.option, item.link, row);
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <StyledTableCell>Totals</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell align="left">
                  {parseNumberToMoney(totals.fund)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {parseNumberToMoney(totals.cash)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {parseNumberToMoney(totals.traffic)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {parseNumberToMoney(totals.liquidated)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {parseNumberToMoney(totals.refund)}
                </StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
              </StyledTableRow>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[ 5, 10, 25 ]}
                  count={50}
                  rowsPerPage={5}
                  page={0}
                  onPageChange={() => {}}
                  onRowsPerPageChange={() => {}}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : (
        <Typography align="center" style={{margin: "20px"}}>
          <IntlMessages id={"guardians.operative.plannings.no.found"} />
        </Typography>
      )}
    </React.Fragment>
  );
};

export default PettyCashesTable;
