import React from "react";
import {useDispatch} from "react-redux";
import {fetchError} from "../../../../../../../redux/actions";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const CostCentralSelect = ({id, label, value, setValue}) => {
  const [ centrals, setCentrals ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setCentrals([
      {
        id: 1,
        label: "Central 1"
      },
      {
        id: 2,
        label: "Central 2"
      }
    ]);
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {centrals.map(e => (
        <MenuItem key={`central-${e.id}`} value={e.id}>
          {e.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CostCentralSelect;
