import React from "react";
import BaseAlertBox from "./BaseAlertBox";
import {Box, Typography} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const CardBox = ({title, expiration, setExpiration, email, setEmail}) => {
  return (
    <BaseAlertBox title={title} email={email} setEmail={setEmail}>
      <Box display="flex" alignItems="center" mb="1em">
        <Typography variant="overline">
          <IntlMessages
            id={
              "fleet.management.tachograph.alerts.table.configuration.card.send.notice"
            }
          />
        </Typography>
        <Box mx="1em">
          <OutlinedInput
            style={{width: "50px"}}
            value={expiration}
            onChange={value => setExpiration(value)}
          />
        </Box>
        <Typography variant="overline">
          <IntlMessages
            id={
              "fleet.management.tachograph.alerts.table.configuration.card.days"
            }
          />
        </Typography>
      </Box>
    </BaseAlertBox>
  );
};

export default CardBox;
