import React from 'react';
import PlanningPlaceSelect from "./PlanningPlaceSelect";
import Grid from "@material-ui/core/Grid";

const TimeProcessFilters = ({formData, setFormData}) => {
    return (
        <>
            <Grid item xs={12} sm={6}>
                <PlanningPlaceSelect
                    value={formData.planningPlaces}
                    onChange={value => setFormData('planningPlaces', value)}
                />
            </Grid>
         </>
    );
};

export default TimeProcessFilters;