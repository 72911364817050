class ClientHeadquarter {
    constructor(clientId, clientDescription,
                headquarterId, headquarterDescription) {
        this.clientId = clientId;
        this.headquarterId = headquarterId;
        this.id = `${clientId}-${headquarterId}`;
        this.description = `${clientDescription}-${headquarterDescription}`;
    }

    static map = (e) => {
        return new ClientHeadquarter(
          e.nu_id_entidad,
            e.vc_nombre,
            e.nu_id_entidad_sede,
            e.vc_nombre_sede
        );
    }
}

export default ClientHeadquarter;