export const FETCH_ALL_WORK_ORDERS_STARTED = "FETCH_ALL_WORK_ORDERS_STARTED";
export const FETCH_ALL_WORK_ORDERS_SUCCEEDED =
  "FETCH_ALL_WORK_ORDERS_SUCCEEDED";
export const APPLY_WORK_ORDERS_FILTERS = "APPLY_WORK_ORDERS_FILTERS";
export const RESET_WORK_ORDERS_FILTERS = "RESET_WORK_ORDERS_FILTERS";
export const SET_CURRENT_WORK_ORDER = "SET_CURRENT_WORK_ORDER";
export const RESET_CURRENT_WORK_ORDER = "RESET_CURRENT_WORK_ORDER";
export const EDIT_WORK_ORDER_STARTED = "EDIT_WORK_ORDER_STARTED";
export const EDIT_WORK_ORDER_SUCCESS = "EDIT_WORK_ORDER_SUCCESS";
export const DELETE_WORK_ORDER_STARTED = "DELETE_WORK_ORDER_STARTED";
export const DELETE_WORK_ORDER_SUCCESS = "DELETE_WORK_ORDER_SUCCESS";
export const ADD_WORK_ORDER_STARTED = "ADD_WORK_ORDER_STARTED";
export const ADD_WORK_ORDER_SUCCESS = "ADD_WORK_ORDER_SUCCESS";
export const OPERATION_FAILED = "OPERATION_FAILED";
export const RESET_OPERATION = "RESET_OPERATION";
export const EDIT_DESTINATION_STARTED = "EDIT_DESTINATION_STARTED";
export const EDIT_DESTINATION_SUCCESS = "EDIT_DESTINATION_SUCCESS";
export const DELETE_DESTINATION_STARTED = "DELETE_DESTINATION_STARTED";
export const DELETE_DESTINATION_SUCCESS = "DELETE_DESTINATION_SUCCESS";
export const ADD_DESTINATION_STARTED = "ADD_DESTINATION_STARTED";
export const ADD_DESTINATION_SUCCESS = "ADD_DESTINATION_SUCCESS";
export const UPDATE_DESTINATIONS_ORDER = "UPDATE_DESTINATIONS_ORDER";
