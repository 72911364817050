import React from "react";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.elevators"} />,
    link: "/elevators-management/elevators/index-cards"
  },
  {
    label: <IntlMessages id={"sidebar.elevators.data.master.category.type"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={
      <IntlMessages id="elevators.management.elevators.category.type.title" />
    }
    description={
      <IntlMessages id="elevators.management.elevators.category.type.description" />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
