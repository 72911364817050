import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";
import Box from '@material-ui/core/Box';
import Table from './Table';
import useUiState from "hooks/Administrator/useUiState";

const Form = ({id, initialData, onSubmit}) => {
    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            entityOrigin: initialData?.entityNameOrigin || '',
            campusOrigin: initialData?.campusNameOrigin || '',
            warehouseOrigin: initialData?.warehouseNameOrigin || '',
            entityDestiny: initialData?.entityNameDestiny || '',
            campusDestiny: initialData?.campusNameDestiny || '',
            warehouseDestiny: initialData?.warehouseNameDestiny || '',
            products: initialData?.transfer || [],            
        },
    );

    const ui = useUiState();

    if(formData.entityOrigin !== ""){
        return(
            <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <GridContainer spacing={4}>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.entityOrigin}
                            fullWidth
                            disabled={true}
                            id="entityOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityOrigin"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.campusOrigin}
                            fullWidth
                            disabled={true}
                            id="campusOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusOrigin"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.warehouseOrigin}
                            fullWidth
                            disabled={true}
                            id="warehouseOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseOrigin"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.entityDestiny}
                            fullWidth
                            disabled={true}
                            id="entityDestiny"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityDestiny"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.campusDestiny}
                            fullWidth
                            disabled={true}
                            id="campusDestiny"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusDestiny"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.warehouseDestiny}
                            fullWidth
                            disabled={true}
                            id="warehouseOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseDestiny"/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box marginBottom={25}>
                            <Table data={formData.products} {...ui.tableProps}/>
                        </Box>
                    </Grid>
                </GridContainer>
            </form>
        )
    }else{
        return(
            <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <GridContainer spacing={4}>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.entityDestiny}
                            fullWidth
                            disabled={true}
                            id="entityDestiny"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityDestiny"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.campusDestiny}
                            fullWidth
                            disabled={true}
                            id="campusDestiny"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusDestiny"/>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            value={formData.warehouseDestiny}
                            fullWidth
                            disabled={true}
                            id="warehouseOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseDestiny"/>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box marginBottom={25}>
                            <Table data={formData.products} {...ui.tableProps}/>
                        </Box>
                    </Grid>
                </GridContainer>
            </form>
        )
    }
};

export default Form;