import React from "react";
import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import HeadersList from "./HeadersList";
import {arrayMove} from "react-sortable-hoc";
import {editServicesTableHeaders} from "../../../../../../redux/actions/Logistic/ServicesList";

const EditHeadersDialog = ({open, setOpen}) => {
  const id = "edit-headers-form";
  const {tableHeaders} = useSelector(({logistic}) => logistic.serviceList);
  const dispatch = useDispatch();
  const [ headers, setHeaders ] = React.useState(Object.values(tableHeaders));
  React.useEffect(
    () => {
      setHeaders(Object.values(tableHeaders));
    },
    [ tableHeaders ]
  );
  const onChangeHeader = (e, header) => {
    e.preventDefault();
    setHeaders(
      headers.map(h => {
        if (header.id === h.id) {
          h.state = !h.state;
        }
        return h;
      })
    );
  };
  const onSortEnd = ({oldIndex, newIndex}) => {
    setHeaders(arrayMove(headers, oldIndex, newIndex));
  };
  const onSubmit = e => {
    e.preventDefault();
    dispatch(editServicesTableHeaders(headers));
    setOpen(false);
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id="form.button.view" />}
      open={open}
      onClose={() => setOpen(false)}
      submitText={<IntlMessages id="form.button.save" />}
      cancelText={<IntlMessages id="form.button.cancel" />}
    >
      <form id={id} onSubmit={onSubmit}>
        <HeadersList
          headers={headers}
          onChangeStarred={onChangeHeader}
          onSortEnd={onSortEnd}
          useDragHandle={true}
        />
      </form>
    </FormDialog>
  );
};

export default EditHeadersDialog;
