import ui, {closeCreateForm} from "../../Administrator/ui";
import {fetchAllCurrentDay} from "../../../../services/RRHH/DailyOperations/CurrentDayService";
import {
  FETCH_CURRENTDAY_SUCCEEDED,
  APPLY_CURRENTDAY_FILTERS
} from "../../../types/RRHH/DailyOperations/CurrentDay";

import CurrentDay from "../../../../domain/RRHH/DailyOperations/CurrentDay";

const fetchCurrentDaySucceeded = data => ({
  type: FETCH_CURRENTDAY_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchCurrentDay = () => async dispatch => {
  // dispatch(ui.fetchStarted());
  // cancelPrevWarehouseFetchAll();
  try {
    const currentday = await fetchAllCurrentDay();
    // dispatch(ui.fetchSucceeded());
    dispatch(fetchCurrentDaySucceeded(currentday));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};
export const applyCurrentDayFilters = filters => ({
  type: APPLY_CURRENTDAY_FILTERS,
  payload: {
    filters: filters
  }
});

export default {
  get: fetchCurrentDay,
  // delete: deleteWarehouseStarted,
  // restore: restoreWarehouseStarted,
  // edit: editWarehouseStarted,
  // create: createWarehouseStarted,
  applyCurrentDayFilters
  // resetWarehouseFilters,
  // resetState,
};
