class MerchandiseReception {
  constructor(
    id,
    idEntityOrigin,
    entityNameOrigin,
    idCampusOrigin,
    campusNameOrigin,
    idWarehouseOrigin,
    warehouseNameOrigin,
    idEntityDestiny,
    entityNameDestiny,
    idCampusDestiny,
    campusNameDestiny,
    idWarehouseDestiny,
    warehouseNameDestiny,
    date,
    active,
    transfer
  ) {
    this.id = id;
    this.idEntityOrigin = idEntityOrigin;
    this.entityNameOrigin = entityNameOrigin;
    this.idCampusOrigin = idCampusOrigin;
    this.campusNameOrigin = campusNameOrigin;
    this.idWarehouseOrigin = idWarehouseOrigin;
    this.warehouseNameOrigin = warehouseNameOrigin;
    this.idEntityDestiny = idEntityDestiny;
    this.entityNameDestiny = entityNameDestiny;
    this.idCampusDestiny = idCampusDestiny;
    this.campusNameDestiny = campusNameDestiny;
    this.idWarehouseDestiny = idWarehouseDestiny;
    this.warehouseNameDestiny = warehouseNameDestiny;
    this.date = date;
    this.active = active;
    this.transfer = transfer;
  }

  static map = merchandiseReception => {
    return new MerchandiseReception(
      merchandiseReception.nu_id_movimiento,
      merchandiseReception.nu_id_entidad_orig,
      merchandiseReception.vc_nombre_entidad_orig,
      merchandiseReception.nu_id_entidad_sede_orig,
      merchandiseReception.vc_nombre_sede_orig,
      merchandiseReception.nu_id_almacen_orig,
      merchandiseReception.vc_desc_almacen_orig,
      merchandiseReception.nu_id_entidad_dest,
      merchandiseReception.vc_nombre_entidad_dest,
      merchandiseReception.nu_id_entidad_sede_dest,
      merchandiseReception.vc_nombre_sede_dest,
      merchandiseReception.nu_id_almacen_dest,
      merchandiseReception.vc_desc_almacen_dest,
      merchandiseReception.dt_fec_movimiento,
      merchandiseReception.bi_estado,
      merchandiseReception.ls_detalle || []
    );
  };
}

export default MerchandiseReception;
