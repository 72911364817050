import {
  fetchAll,
  cancelPrevFetchAll,
  changeState,
  update,
  create
} from "services/RejectionTypeService";
import {restoreAdministratorState} from "./Common";
import ui from "./ui";

export const fetchRejectionTypes = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevFetchAll();
  try {
    const data = await fetchAll();
    dispatch(ui.fetchSucceeded());
    dispatch({type: "REJECTION_TYPES_FETCH_SUCCEEDED", payload: {data}});
  } catch (error) {
    dispatch(ui.fetchFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeRejectionTypeState = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  try {
    await changeState(id);
    dispatch({type: "REJECTION_TYPES_CHANGE_STATE", payload: {id}});
    dispatch(ui.changeStateSucceeded());
  } catch (error) {
    dispatch(ui.changeStateFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export const editRejectionType = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await update(id, data);
    dispatch({type: "REJECTION_TYPES_EDIT", payload: {id, data}});
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export const createRejectionType = data => async dispatch => {
  dispatch(ui.closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const {id} = await create(data);
    dispatch({
      type: "REJECTION_TYPES_CREATE",
      payload: {id, ...data, active: true}
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export const applyFilters = filters => ({
  type: "REJECTION_TYPES_APPLY_FILTERS",
  payload: {filters}
});
export const resetFilters = () => ({type: "REJECTION_TYPES_RESET_FILTERS"});

export const deleteRejectionType = id => dispatch => {
  dispatch(changeRejectionTypeState(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreRejectionType = id => dispatch => {
  dispatch(changeRejectionTypeState(id));
  dispatch(ui.closeRestoreConfirmation());
};

export const resetState = () => dispatch => {
  cancelPrevFetchAll();
  dispatch(restoreAdministratorState());
};

export default {
  get: fetchRejectionTypes,
  delete: deleteRejectionType,
  restore: restoreRejectionType,
  edit: editRejectionType,
  create: createRejectionType,
  applyFilters,
  resetFilters,
  resetState
};
