import React from "react";

import PageContainer from "../../../../../components/FleetManagement/DailyOperation/Tires/PageContainer";
import TiresTable from "../../../../../components/FleetManagement/DailyOperation/Tires/TiresTable";
import {useDispatch, useSelector} from "react-redux";
import {resetTiresState} from "../../../../../redux/actions/FleetManagement/Tires";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddTireDialog from "../../../../../components/FleetManagement/DailyOperation/Tires/AddTireDialog";
import EditTireDialog from "../../../../../components/FleetManagement/DailyOperation/Tires/EditTireDialog";

const Tires = () => {
  const {current} = useSelector(({fleetManagement}) => fleetManagement.tires);
  const dispatch = useDispatch();
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetTiresState());
    };
  }, []);
  return (
    <PageContainer>
      <TiresTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd
        onAddClicked={() => {
          setOpenAddDialog(true);
        }}
      />
      {openAddDialog && (
        <AddTireDialog open={openAddDialog} setOpen={setOpenAddDialog} />
      )}
      {openEditDialog &&
      current && (
        <EditTireDialog open={openEditDialog} setOpen={setOpenEditDialog} />
      )}
    </PageContainer>
  );
};

export default Tires;
