import {
  ADD_CHECKLIST_TYPE,
  EDIT_CHECKLIST_TYPE,
  FETCH_ALL_CHECKLIST_TYPES,
  RESET_CHECKLIST_TYPES,
  RESET_CURRENT_CHECKLIST_TYPE,
  SET_CURRENT_CHECKLIST_TYPE
} from "../../types/Logistic/ChecklistTypesTypes";

const INIT_STATE = {
  data: {},
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CHECKLIST_TYPES: {
      let types = {};
      action.payload.map(u => {
        types[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: types
      };
    }
    case SET_CURRENT_CHECKLIST_TYPE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_CHECKLIST_TYPE: {
      return {
        ...state,
        current: INIT_STATE.current
      };
    }
    case ADD_CHECKLIST_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_CHECKLIST_TYPE: {
      const id = action.payload.id;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            ...action.payload
          }
        }
      };
    }
    case RESET_CHECKLIST_TYPES: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
