import React from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import YearSelect from "./YearSelect";
import DateFormatSelect from "./DateFormatSelect";
import DateNumberSelect from "./DateNumberSelect";
import PlanningPlaceSelect from "./PlanningPlaceSelect";

const GeneralDashboardFilters = ({formData, setFormData}) => {
    return (
        <React.Fragment>
            <Grid item xs={12} sm={6}>
                <YearSelect
                    value={formData.year}
                    onChange={value => setFormData('year', value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateFormatSelect
                    value={formData.dateFormat}
                    onChange={value => setFormData('dateFormat', value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <DateNumberSelect
                    value={formData.dateNumber}
                    onChange={value => setFormData('dateNumber', value)}
                    format={formData.dateFormat}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <PlanningPlaceSelect
                    value={formData.planningPlace}
                    onChange={value => setFormData('planningPlace', value)}
                />
            </Grid>
        </React.Fragment>
    );
};

GeneralDashboardFilters.propTypes = {
    formData: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired
};

export default GeneralDashboardFilters;