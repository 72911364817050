import React from "react";
import {connect} from "react-redux";
import BaseTable from "../../Common/BaseTable";
import {
  applyElevatorsStatesFilters,
  fetchAllElevatorsStates,
  resetElevatorsStatesFilters,
  setCurrentElevatorState
} from "../../../../../../redux/actions/ElevatorsManagement/States";
import FilterBuilder from "../../../../../../utils/filters";

const StatesTable = ({
  setEdit,
  setDelete,
  setRestore,
  data,
  filters,
  dispatch
}) => {
  React.useEffect(() => {
    dispatch(fetchAllElevatorsStates(filters));
  }, []);
  const onEdit = current => {
    dispatch(setCurrentElevatorState(current));
    setEdit && setEdit(true);
  };
  const onDelete = current => {
    dispatch(setCurrentElevatorState(current));
    setEdit && setDelete(true);
  };
  const onRestore = current => {
    dispatch(setCurrentElevatorState(current));
    setEdit && setRestore(true);
  };
  const onApplyFilters = filters => {
    dispatch(applyElevatorsStatesFilters(filters));
  };
  const onResetFilters = () => {
    dispatch(resetElevatorsStatesFilters());
  };
  return (
    <BaseTable
      data={Object.values(data)}
      initData={filters}
      onRestore={onRestore}
      onEdit={onEdit}
      onDelete={onDelete}
      onApplyFilters={onApplyFilters}
      onResetFilters={onResetFilters}
    />
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.elevatorsManagement.states;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("id", filters.code)
    .byFieldIncludes("name", filters.name)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(StatesTable);
