import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import ControlSelect from "../../../../../Purchases/ProviderOrders/ProviderOrderForm/ControlForm/ControlSelect";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


const ControlForm = ({control}) => {
    return (
        <fieldset>
            <legend>
                <Typography>
                    <IntlMessages id="data.of"/> <IntlMessages id="control"/>
                </Typography>
            </legend>
            <GridContainer spacing={2} className="control-form">
                <Grid item xs={12} md={6}>
                    <ControlSelect
                        value={control.type}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <OutlinedInput
                        label={<IntlMessages id="min"/>}
                        fullWidth
                        value={control.min}
                        disabled
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <OutlinedInput
                        label={<IntlMessages id="max"/>}
                        fullWidth
                        value={control.max}
                        disabled
                    />
                </Grid>
            </GridContainer>
        </fieldset>
    );
};

export default ControlForm;