import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import TreasuryReports from "./TreasuryReports";
import LogisticReports from "./LogisticReports";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {openKpiDialog, resetCurrentReport} from "../../../../redux/actions/ControlPanel/Kpi";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import LocationReports from "./LocationReports";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import {ButtonsBox} from "./styles";
import AddFavoriteDialog from "./AddFavoriteDialog";
import AddConsultDialog from "./AddConsultDialog";
import AddScheduleDialog from "./AddScheduleDialog";
import TachographReports from "./TachographReports";
import DistributionReports from "./DistributionReports";
import {dialogs} from "./constants";

const ReportsContainer = () => {
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    const [openAddFavorite, setOpenAddFavorite] = React.useState(false);
    const [openAddConsult, setOpenAddConsult] = React.useState(false);
    const renderReportContent = () => {
        if (current.type < 5) {
            return (
                <TreasuryReports/>
            );
        } else if (current.type < 9 || current.type === 18 || current.type === 19) {
            return (
                <LogisticReports/>
            );
        } else if (current.type < 18 || current.type === 23 || (current.type >= 25 && current.type<=27)) {
            return (
                <LocationReports/>
            )

        }else if ((current.type >= 20 && current.type < 30) || current.type >= 38) {
            return (
                <TachographReports />
            )
        }else if(current.type >= 30) {
            return (
                <DistributionReports />
            )
        }
        else {
            return (<></>);
        }
    }

    return (
        <React.Fragment>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={4}>
                    <ContainedButton
                        color="primary"
                        onClick={() => dispatch(resetCurrentReport())}
                        text={<IntlMessages id={"control.panel.kpi.back"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={8}>
                    <ButtonsBox>
                        <ContainedButton
                            color="primary"
                            onClick={() => {
                                setOpenAddFavorite(true);
                            }}
                            text={<IntlMessages id={"control.panel.kpi.save.favorite"}/>}
                        />
                        <ContainedButton
                            color="primary"
                            onClick={() => {
                                dispatch(openKpiDialog(dialogs.scheduleReport));
                            }}
                            text={<IntlMessages id={"control.panel.kpi.add.schedule"}/>}
                        />
                        <ContainedButton
                            color="primary"
                            onClick={() => {
                                setOpenAddConsult(true);
                            }}
                            text={<IntlMessages id={"control.panel.kpi.save.consult"}/>}
                            disabled={!current?.id}
                        />
                    </ButtonsBox>
                </Grid>
            </GridContainer>
            {renderReportContent()}
            <AddFavoriteDialog
                open={openAddFavorite}
                setOpen={setOpenAddFavorite}
            />
            <AddConsultDialog
                open={openAddConsult}
                setOpen={setOpenAddConsult}
            />
            <AddScheduleDialog/>
        </React.Fragment>
    );


}

export default ReportsContainer;