import React from "react";
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useStyles} from "../../styles";
import ComplementaryStateItem from "./ComplementaryStateItem";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import AddComplementaryDialog from "./AddComplementaryDialog";

const ComplementaryStatesList = ({current}) => {
  const {complementaryStates} = current;
  const classes = useStyles();
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const onMapChecklist = (checklist, state) => {
    return current.states
        .filter(s => s.id !== state.id)
        .map(item => ({
      id: item.id,
      name: item.name,
          status: checklist.find(s => s.id === item.id)?.status || false,
    }));
  };
  return (
    <React.Fragment>
      <Box className={classes.complementaryBox}>
        <Box display="flex" flex="auto" style={{gap: "16px"}}>
          <Box alignSelf="center">
            <Typography variant="h1">
              <IntlMessages
                id={
                  "logistic.distribution.work.orders.types.detail.state.complementary.title"
                }
              />
            </Typography>
          </Box>
          <Box>
            <ContainedButton
              onClick={() => setOpenAdd(true)}
              color="primary"
              text={
                <IntlMessages
                  id={
                    "logistic.distribution.work.orders.types.detail.state.complementary.add"
                  }
                />
              }
            />
          </Box>
        </Box>
        <Box className={classes.complementaryListBox}>
          {complementaryStates.map(state => (
            <ComplementaryStateItem
              key={`complementary-state-${state.id}`}
              state={state}
              checklist={onMapChecklist(state.checklist, state)}
            />
          ))}
        </Box>
      </Box>
      <AddComplementaryDialog open={openAdd} setOpen={setOpenAdd} />
    </React.Fragment>
  );
};

export default ComplementaryStatesList;
