export const RESET_EMPLOYEES_DOCUMENTS_STATE =
  "RESET_EMPLOYEES_DOCUMENTS_STATE";
export const FETCH_ALL_LIST_EMPLOYEES_DOCUMENTS =
  "FETCH_ALL_LIST_EMPLOYEES_DOCUMENTS";
export const APPLY_EMPLOYEES_LIST_DOCUMENTS_FILTERS =
  "APPLY_EMPLOYEES_LIST_DOCUMENTS_FILTERS";
export const RESET_EMPLOYEES_LIST_DOCUMENTS_FILTERS =
  "RESET_EMPLOYEES_LIST_DOCUMENTS_FILTERS";
export const SET_CURRENT_LIST_DOCUMENT = "SET_CURRENT_LIST_DOCUMENT";
export const RESET_CURRENT_LIST_DOCUMENT = "RESET_CURRENT_LIST_DOCUMENT";
export const CREATE_VEHICLE_DOCUMENT = "CREATE_VEHICLE_DOCUMENT";
export const UPDATE_VEHICLE_DOCUMENT = "UPDATE_VEHICLE_DOCUMENT";
export const APPLY_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS =
  "APPLY_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS";
export const RESET_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS =
  "RESET_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS";
export const FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS =
  "FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS";
export const RESET_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_DATA =
  "RESET_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_DATA";
export const FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_VIEWS =
  "FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_VIEWS";

export const FETCH_EMPLOYEES_DOCUMENTS_DASHBOARD =
    "FETCH_EMPLOYEES_DOCUMENTS_DASHBOARD";