import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import PropTypes from "prop-types";

const PlanningPlaceSelect = ({value, onChange, error, hasError}) => {
    const id = 'planning-place-filter';
    const places = Array.from({length: 10}, (_, i) => i + 1001)
        .concat(Array.from({length: 4}, (_, i) => i + 1018));
    return (
        <Select
            id={id}
            label={<IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.planning.place" />}
            value={value}
            onChange={e => onChange(e.target.value)}
            error={hasError}
            errorMessage={error}
            fullWidth
        >
            {places.map(o => (
                <MenuItem key={o} value={o}>
                    {o}
                </MenuItem>
            ))}
        </Select>
    );
}

PlanningPlaceSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any
};

export default PlanningPlaceSelect;