import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.cash.management"} />,
    link: "/treasury/cash-management/operative"
  },
  {label: <IntlMessages id={"sidebar.advances.list"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"guardian.advances.page.header"} />}
    description={<IntlMessages id="guardian.advances.page.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
