import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllSinisterTypes} from "../../../../../../../services/FleetManagement/DailyOperation/SinisterTypesService";

const TypeSelect = ({id, label, value, setValue, error, hasError}) => {
  const {data: types} = useFetch(getAllSinisterTypes, {}, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      error={hasError}
      errorMessage={error}
    >
      {types.map(d => (
        <MenuItem key={`type-${d.id}`} value={d.id}>
          {d.description}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TypeSelect;
