class RouteSelect {
  constructor(id, route, active) {
    this.id = id;
    this.route = route;
    this.active = active;
  }

  static map = route => {
    return new RouteSelect(
      route.nu_id_ruta,
      route.vc_desc_ruta,
      route.bi_estado
    );
  };
}

export default RouteSelect;
