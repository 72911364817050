import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
// import Warehouse from '../domain/Warehouse';
// import Response from "domain/Response";
import CurrentDay from "../../../domain/RRHH/DailyOperations/CurrentDay";
import {getCompanyEntityId} from "../../StorageService";

let fetchAllCancelToken = undefined;

export const fetchAllCurrentDay = async () => {
  //return almacen.map(almacen => Warehouse.map(almacen));
  try {
    const response = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje/actual_sel",
      {nu_id_entidad_empresa: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );

    return response.data.map(customer => CurrentDay.map(customer));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
