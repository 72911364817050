import {makeStyles} from "@material-ui/core/styles";

export const useAdvanceDetailStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1
  }
}));
