import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/LogHistory/PageContainer";
import Table from "components/Administrator/LogHistory/Table";
import Filters from "components/Administrator/LogHistory/Filters";
import useUiState from "hooks/Administrator/useUiState";
import Box from "@material-ui/core/Box";

import logHistory from "redux/actions/Administrator/LogHistory";
import {downloadFile, getFileByName} from "services/FileService";
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const LogHistory = ({tableData, filters, dispatch}) => {
  const ui = useUiState();
  useEffect(
    () => {
      dispatch(logHistory.get({}));
      return () => dispatch(logHistory.resetState());
    },
    [ dispatch ]
  );

  useEffect(
    () => {
      dispatch(logHistory.get(filters));
    },
    [ filters ]
  );

  const applyFilters = useCallback(
    filters => dispatch(logHistory.applyFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(logHistory.resetFilters());

  const onFileNameClick = name =>
    getFileByName(name).then(({base64File}) =>
      downloadFile(name, `data:application/pdf;base64,${base64File}`)
    );

  return (
    <PageContainer>
        <Box
            my={2}
        >
            <Link to='/treasury/cash-management/cash-managers'>
                <Typography >
                    <IntlMessages id="back.to.previous.page" />
                </Typography>
            </Link>
        </Box>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table
            data={tableData}
            onEdit={ui.tableProps.onEdit}
            onDelete={ui.tableProps.onDelete}
            onFileNameClick={onFileNameClick}
          />
        </Box>
      )}
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.logHistory;
  // const filtered = new FilterBuilder(data)
  //   .byFieldIncludes('cashManager', filters.cashManager)
  //   .byFieldBetweenDates('date', filters.fromDate, filters.toDate)
  //   .byFieldEquals('action', filters.action)
  //   .byFieldEquals('amount', filters.amount)
  //   .byFieldIncludes('tags', filters.tag)
  //   .build();
  return {tableData: data, filters};
};

export default connect(mapStateToProps)(LogHistory);
