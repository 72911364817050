import React from "react";
import {Box} from "@material-ui/core";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {deleteTachographFiles} from "../../../../../../redux/actions/FleetManagement/TachographFiles";
import {downloadTachographFile} from "../../../../../../services/FleetManagement/Tachograph/TachographsFilesService";
import {downloadFile} from "../../../../../../services/FileService";

const ButtonsArea = ({
  selectedFiles,
  setOpenZipDialog,
  setOpenIndividualDialog
}) => {
  const dispatch = useDispatch();
  const {data} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographFiles
  );
  const onClickDownload = async e => {
    const file = await downloadTachographFile(selectedFiles);
    downloadFile(file.filename, file.resourceLink);
  };
  const onClickDelete = e => {
    dispatch(
      deleteTachographFiles(selectedFiles.map(f => f.id), Object.values(data))
    );
  };
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="space-around"
      style={{gap: "2em"}}
      my="1em"
    >
      <Box>
        <ContainedButton
          onClick={() => setOpenZipDialog(true)}
          fullWidth
          color="primary"
          text={
            <IntlMessages
              id={"fleet.management.tachograph.files.table.import.zip"}
            />
          }
        />
      </Box>
      <Box>
        <ContainedButton
          onClick={() => setOpenIndividualDialog(true)}
          fullWidth
          color="primary"
          text={
            <IntlMessages
              id={"fleet.management.tachograph.files.table.import.individual"}
            />
          }
        />
      </Box>
      {selectedFiles.length > 0 && (
        <React.Fragment>
          <Box>
            <ContainedButton
              onClick={onClickDownload}
              fullWidth
              color="primary"
              text={<IntlMessages id={"form.button.download"} />}
            />
          </Box>
          <Box>
            <ContainedButton
              onClick={onClickDelete}
              fullWidth
              color="primary"
              text={<IntlMessages id={"form.button.delete"} />}
            />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ButtonsArea;
