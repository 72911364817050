import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../../../@jumbo/utils/IntlMessages";
import {fetchAllEntities} from "../../../../../../../../../../services/CashManagers";
import MultipleSelect from "../../../../../../../../../Common/Forms/Select/MultipleSelect";

const GuardiansPicker = ({values, onChange}) => {
  const [ guardians, setGuardians ] = React.useState([]);
  useEffect(() => {
    fetchAllEntities().then(employees => {
      setGuardians([ ...employees.filter(guardian => guardian.state === "1") ]);
    });
  }, []);
  return (
    <MultipleSelect
      id={"administrator-expenses-reports-guardians-picker"}
      options={guardians}
      value={values}
      onChange={onChange}
      fullWidth
      label={<IntlMessages id={"administrator.reports.guardians.title"} />}
      headerText={<IntlMessages id="administrator.reports.guardians.header" />}
      noOptionsText={
        <IntlMessages id="administrator.reports.guardians.no.options" />
      }
    />
  );
};

export default GuardiansPicker;
