import React from "react";
import Select from "../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {useSelector} from "react-redux";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const ChecklistSelect = ({id, label, value, setValue, disabled}) => {
  const {checklistData: checklist} = useSelector(
    ({logistic}) => logistic.workOrdersTypes
  );
  return (
    <Select
      id={id}
      label={label}
      disabled={disabled}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      <MenuItem value={""}>
        <IntlMessages id={"select.option.none"} />
      </MenuItem>
      {checklist.map(s => (
        <MenuItem key={`${id}-item-${s.id}`} value={s.id}>
          {s.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ChecklistSelect;
