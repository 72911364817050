import React from "react";
import {Box, Paper, TableContainer, Typography} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const ContainerWithHeader = ({children, title}) => (
  <React.Fragment>
    <Box
      display="flex"
      flexDirection="column"
      alignContent="center"
      style={{
        width: "100%"
      }}
    >
      <Box
        style={{
          width: "90%",
          margin: "24px auto 4px"
        }}
        display="flex"
        flexDirection="row"
        flexGrow={1}
      >
        <Typography variant="h2">
          <IntlMessages id={title} />
        </Typography>
      </Box>
      <TableContainer
        component={Paper}
        style={{
          width: "90%",
          margin: "16px auto"
        }}
      >
        {children}
      </TableContainer>
    </Box>
  </React.Fragment>
);

export default ContainerWithHeader;
