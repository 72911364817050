import {
  PRODUCTS_CHANGE_STATE,
  PRODUCTS_CREATE,
  PRODUCTS_EDIT,
  APPLY_PRODUCTS_FILTERS,
  FETCH_PRODUCTS_SUCCEEDED,
  RESET_PRODUCTS_FILTERS
} from "../../types/Administrator/ProductCustomTypes";

const INIT_STATE = {
  data: [],
  rowCount: 0,
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_PRODUCTS_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.products ?? [],
        rowCount: action.payload.rowCount ?? 0,
      };
    }
    case APPLY_PRODUCTS_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_PRODUCTS_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case PRODUCTS_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, {active: !type.active});
          return type;
        })
      };
    }
    case PRODUCTS_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case PRODUCTS_EDIT: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, action.payload.data);
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};
