import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import OutlinedDatePicker from "components/Common/Forms/OutlinedDatePicker";
import Select from "components/Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {mapDateToOutput, mapDateToValue} from "utils/parsers";
import Button from "@material-ui/core/Button";
import {useDispatch} from "react-redux";
import {resetWarehouseTransferFilters} from "../../../../../redux/actions/Administrator/Traceability";
import moment from "moment";
import DirectionSelect from "./DirectionSelect";

const initialData = {
  active: undefined,
  entityOrigin: "",
  entityDestiny: "",
  product: "",
  type: "",
  OT: "",
  fromDate: moment(new Date().toISOString())
    .subtract(30, "days")
    .format("YYYY-MM-DD"),
  toDate: moment(new Date().toISOString()).format("YYYY-MM-DD"),
  direction: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.entityOrigin}
            id="entity-origin-filter"
            label={
              <IntlMessages id="logistic.traceability.filter.entityOrigin" />
            }
            onChange={value => setFormData("entityOrigin", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.entityDestiny}
            id="entity-destiny-filter"
            label={
              <IntlMessages id="logistic.traceability.filter.entityDestiny" />
            }
            onChange={value => setFormData("entityDestiny", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={formData.fromDate}
            id="date-filter"
            label={<IntlMessages id="logistic.traceability.filter.fromDate" />}
            onChange={value => setFormData("fromDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={formData.toDate}
            id="date-filter"
            label={<IntlMessages id="logistic.traceability.filter.toDate" />}
            onChange={value => setFormData("toDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.product}
            id="product-filter"
            label={<IntlMessages id="logistic.traceability.filter.product" />}
            onChange={value => setFormData("product", value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Select
            id="type-filter"
            label={<IntlMessages id="logistic.traceability.filter.type" />}
            value={formData.type}
            onChange={e => setFormData("type", e.target.value)}
            fullWidth
          >
            <MenuItem value="">
              <IntlMessages id="logistic.traceability.filter.type.all" />
            </MenuItem>
            <MenuItem value="2">
              <IntlMessages id="logistic.traceability.filter.type.regulation" />
            </MenuItem>
            <MenuItem value="1">
              <IntlMessages id="logistic.traceability.filter.type.transfer" />
            </MenuItem>
            <MenuItem value="3">
              <IntlMessages id="logistic.traceability.filter.type.incidence" />
            </MenuItem>
          </Select>

          {/*<OutlinedInput
                        fullWidth
                        value={formData.type}
                        id="type-filter"
                        label={<IntlMessages id="logistic.traceability.filter.type"/>}
                        onChange={value => setFormData('type',value)}
                    />*/}
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.OT}
            id="OT-filter"
            label={<IntlMessages id="logistic.traceability.filter.OT" />}
            onChange={value => setFormData("OT", value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <DirectionSelect
            fullWidth
            value={formData.direction}
            id="direction-filter"
            label={
              <IntlMessages id={"logistic.traceability.filter.direction"} />
            }
            onChange={value => setFormData("direction", value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Button
            onClick={() => {
              dispatch(resetWarehouseTransferFilters());
            }}
            variant="contained"
          >
            <IntlMessages id={"filters.button.clear"} />
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <Button
            onClick={() => {
              onFilter(formData);
            }}
            variant="contained"
            color="primary"
          >
            <IntlMessages id={"filters.button.apply"} />
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
