import React from "react";
import AddAdvance from "../../../../components/Guardian/AddAdvance/Form";
import {useDispatch, useSelector} from "react-redux";
import {Card, CardContent} from "@material-ui/core";
import {submitAddAdvanceForm} from "../../../../redux/actions/Guardians/AddAdvanceForm";
import PageContainer from "../../../../components/Guardian/AddAdvance/PageContainer";
import {useHistory} from "react-router-dom";

const AssignAdvance = () => {
  const dispatch = useDispatch();
  const {formData, isLoading} = useSelector(
    state => state.guardian.addAdvanceForm
  );
  const history = useHistory();
  const onSubmit = (data, resetForm) => {
    dispatch(submitAddAdvanceForm(data, resetForm, history));
  };
  return (
    <PageContainer>
      <Card
        style={{
          paddingTop: "24px"
        }}
      >
        <CardContent>
          <AddAdvance
            data={formData}
            isLoading={isLoading}
            onSubmit={onSubmit}
          />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AssignAdvance;
