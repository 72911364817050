import React, {useState} from "react";
import PropTypes from "prop-types";

import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import FloatingButtonContainer from "components/Common/Buttons/FloatingButtonContainer";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

const FloatingButton = ({actions}) => {
  const [ dialIsOpen, setDialIsOpen ] = useState(false);

  const onItemClicked = f => () => {
    setDialIsOpen(false);
    f();
  };

  const handleOpenDial = (event, reason) => {
    if (reason !== "focus") {
      setDialIsOpen(true);
    }
  };

  const floatingButtonActions = actions.map(action => ({
    ...action,
    onClick: onItemClicked(action.onClick)
  }));

  return (
    <FloatingButtonContainer>
      <SpeedDial
        ariaLabel="actions menu"
        icon={<SpeedDialIcon />}
        onClose={() => setDialIsOpen(false)}
        onOpen={handleOpenDial}
        open={dialIsOpen}
        direction="up"
      >
        {floatingButtonActions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.title}
            onClick={action.onClick}
          />
        ))}
      </SpeedDial>
    </FloatingButtonContainer>
  );
};

FloatingButton.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.node,
      title: PropTypes.node,
      onClick: PropTypes.func
    })
  )
};

FloatingButton.defaultProps = {
  otherActions: []
};

export default FloatingButton;
