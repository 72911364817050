import React from "react";

import TableByProduct from "./ByProduct";
import TableByEntity from "./ByEntity";

const Table = ({data, isLoading, byProduct}) =>
  byProduct ? (
    <TableByProduct data={data} isLoading={isLoading} />
  ) : (
    <TableByEntity data={data} isLoading={isLoading} />
  );

export default Table;
