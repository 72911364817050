import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {closeDialog, setCurrentBill} from "../../../redux/reducers/Treasury/bills";
import {updateBill} from "../../../redux/thunks/Treasury/bills";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import BillForm from "../common/BillForm";
import {parseDateToInput} from "../../../utils/dates";
import {cloneDeep} from "lodash";

const EditDialog = () => {
    const id = 'edit-bill-form';
    const {dialogs, currentBill} = useSelector(({treasury}) => treasury.bills);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.EDIT_BILL);
    const disableEdit = !currentBill?.state?.toLowerCase()?.includes('pendiente');
    const onSubmit = (data) => {
        dispatch(updateBill({
            request: {
                ...currentBill,
                ...data,
            }
        }));
        onClose();
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.EDIT_BILL));
        dispatch(setCurrentBill(null));
    }
    return (
        <>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="edit"/>}
                fullScreen
                disabled={disableEdit}
            >
                {(open && currentBill) &&
                    <BillForm
                        id={id}
                        onSubmit={onSubmit}
                        initialData={{
                            id: currentBill.id,
                            billNumber: currentBill.billNumber,
                            documentType: currentBill.documentType,
                            client: {
                                id: currentBill.clientId,
                                name: currentBill.clientName,
                            },
                            creationDate: parseDateToInput(currentBill.creationDate),
                            expirationDate: parseDateToInput(currentBill.expirationDate),
                            discount: currentBill.discount,
                            services: cloneDeep(currentBill.services),
                            observations: currentBill.observations,
                            isCredit: currentBill.isCredit,
                            creditDays: currentBill.creditDays,
                            orderType: currentBill.orderType,
                            serviceDescription: currentBill.serviceDescription,
                            serviceMonth: currentBill.serviceMonth,
                            reference: currentBill.reference,
                        }}
                        disableEdit={disableEdit}
                    />
                }
            </FormDialog>
        </>
    );
};

export default EditDialog;