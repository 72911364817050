import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../../../hooks/Common/useFetch";
import {
    getAllWorkshopTaskInformation
} from "../../../../../../../../../services/FleetManagement/DailyOperation/WorkshopTaskService";

const InformationSelect = ({value, onChange, vehicle, type}) => {
    const {data: options, executeService} = useFetch(getAllWorkshopTaskInformation, {vehicle, type}, []);
    React.useEffect(() => {
        executeService();
    }, [vehicle, type]);
    return (
        <Select
            id='workshop-task-info-select'
            label={
                <IntlMessages id="fleet.management.workshop.request.form.tasks.form.information" />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
        >
            <MenuItem value={""}>
                <IntlMessages id="select.all" />
            </MenuItem>
            {options.map(i => (
                <MenuItem value={i.id} key={i.id}>
                    {i.description}
                </MenuItem>
            ))}
        </Select>
    );
};

InformationSelect.propTypes = {

};

export default InformationSelect;