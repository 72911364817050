import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import TagSelect from "./TagSelect";
import {useDispatch} from "react-redux";
import {
  createVehicleTag,
  editVehicleTag
} from "../../../../../redux/actions/FleetManagement/TechnicalSpecification";

const TagManagementDialog = ({open, setOpen}) => {
  const id = "vehicle-tag-management-form";
  const dispatch = useDispatch();
  const [ selected, setSelected ] = React.useState({id: "0", name: ""});
  const {formData, setFormData, resetForm, handleSubmit} = useForm({
    name: ""
  });
  const onSubmit = data => {
    if (selected.id === "0") {
      dispatch(
        createVehicleTag({
          description: data.name,
          type: 2,
          color: ""
        })
      );
    } else {
      dispatch(
        editVehicleTag({
          id: selected.id,
          description: data.name,
          typeID: 2
        })
      );
    }
    onClose();
  };
  const onClose = () => {
    resetForm();
    setOpen(false);
  };
  React.useEffect(
    () => {
      setFormData("name", selected.name);
    },
    [ selected ]
  );
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.management.tag.title"}
        />
      }
      isOpen={open}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <TagSelect
              value={selected}
              onChange={value => setSelected(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              label={
                <IntlMessages
                  id={
                    "fleet.management.vehicles.specifications.management.tag.form.name"
                  }
                />
              }
              value={formData.name}
              onChange={value => setFormData("name", value)}
              fullWidth
            />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default TagManagementDialog;
