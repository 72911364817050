import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
// import Warehouse from '../domain/Warehouse';
// import Response from "domain/Response";
import DriverSelect from "domain/DriverSelect";
import HistoricalSigning from "../../domain/RRHH/HistoricalSigning";
import Response from "../../domain/Response";
import {getCompanyEntityId} from "../StorageService";
import moment from "moment";

let fetchAllCancelToken = undefined;

export const fetchAllHistoricalSinging = async filters => {
  let employeesIds = [];
  if (filters.drivers.length > 0) {
    filters.drivers.forEach(element => {
      employeesIds.push(element.id);
    });
  }
  try {
    const response = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje/historico_sel",
      {
        nu_id_entidades: employeesIds,
        nu_id_entidad_empresa: getCompanyEntityId(),
        dt_fec_ini: filters.startDate,
        dt_fec_fin: filters.endDate
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(historicalSigning =>
      HistoricalSigning.map(historicalSigning)
    );
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
export const fetchAllDriverSelect = async () => {
  try {
    const response = await axiosInstance.post(
      "/Empleado/sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(driverSelect => DriverSelect.map(driverSelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
