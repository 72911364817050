import {
  RECEIVERS_APPLY_FILTERS,
  RECEIVERS_BY_ADMINISTRATOR_FETCH_ALL_SUCCEEDED,
  RECEIVERS_CHANGE_STATE,
  RECEIVERS_CREATE,
  RECEIVERS_EDIT,
  RECEIVERS_RESET_FILTERS,
  RECEIVERS_RESTORE_STATE
} from "../../types/Administrator/ReceiversCustomTypes";

const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    names: "",
    tag: "",
    code: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RECEIVERS_RESTORE_STATE: {
      return {
        ...INIT_STATE
      };
    }
    case RECEIVERS_BY_ADMINISTRATOR_FETCH_ALL_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case RECEIVERS_APPLY_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RECEIVERS_RESET_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case RECEIVERS_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(receiver => {
          if (receiver.id === action.payload.id)
            return Object.assign(receiver, {active: !receiver.active});
          return receiver;
        })
      };
    }
    case RECEIVERS_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case RECEIVERS_EDIT: {
      return {
        ...state,
        data: state.data.map(receiver => {
          if (receiver.id === action.payload.id)
            return Object.assign(receiver, action.payload.data);
          return receiver;
        })
      };
    }

    default: {
      return state;
    }
  }
};
