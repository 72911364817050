import {
  ADD_ACCIDENT,
  APPLY_ACCIDENTS_FILTERS,
  EDIT_ACCIDENT,
  FETCH_ALL_ACCIDENTS,
  RESET_ACCIDENTS_FILTERS,
  RESET_ACCIDENTS_STATE,
  SET_CURRENT_ACCIDENT
} from "../../types/FleetManagement/AccidentsType";
import moment from "moment";

const INIT_STATE = {
  data: [],
  filters: {
    plaque: "",
    date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    type: "",
    driver: "",
    reference: "",
    insuranceCompany: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_ACCIDENTS_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_ACCIDENTS_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }

    case FETCH_ALL_ACCIDENTS: {
      return {
        ...state,
        data: action.payload
      };
    }

    case ADD_ACCIDENT: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: state.data.length + 1,
            ...action.payload
          }
        ]
      };
    }

    case SET_CURRENT_ACCIDENT: {
      return {
        ...state,
        current: action.payload
      };
    }

    case EDIT_ACCIDENT: {
      return {
        ...state,
        data: state.data.map(d => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        })
      };
    }

    case RESET_ACCIDENTS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }

    default: {
      return state;
    }
  }
};
