import React from "react";

import PageContainer from "../../../../../components/FleetManagement/Vehicles/Sinister/PageContainer";
import AccidentsTable from "../../../../../components/FleetManagement/Vehicles/Sinister/AccidentsTable";
import {useDispatch, useSelector} from "react-redux";
import {resetAccidentsState} from "../../../../../redux/actions/FleetManagement/Accidents";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddAccidentDialog from "../../../../../components/FleetManagement/Vehicles/Sinister/AddAccidentDialog";
import EditAccidentDialog from "../../../../../components/FleetManagement/Vehicles/Sinister/EditAccidentDialog";

const Sinister = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.accidents
  );
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetAccidentsState());
    };
  }, []);
  return (
    <PageContainer>
      <AccidentsTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAddDialog(true)} />
      {openAddDialog && (
        <AddAccidentDialog open={openAddDialog} setOpen={setOpenAddDialog} />
      )}
      {openEditDialog &&
      current && (
        <EditAccidentDialog open={openEditDialog} setOpen={setOpenEditDialog} />
      )}
    </PageContainer>
  );
};

export default Sinister;
