class ChecklistTypeItem {
  constructor(id, name, order, state) {
    this.id = id;
    this.name = name;
    this.order = order;
    this.state = state;
  }

  static map = item => {
    return new ChecklistTypeItem(
      item.nu_id_tipo_checklist_subgrupo,
      item.vc_desc_tipo_checklist_subgrupo,
      item.nu_id_orden_subgrupo,
      item.bi_estado_tipo_subgrupo
    );
  };
}

export default ChecklistTypeItem;
