import React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput as MaterialUIOutlinedInput,
  FormHelperText
} from "@material-ui/core";

const OutlinedInput = ({
  hasError,
  error,
  fullWidth,
  id,
  label,
  onChange = () => {},
    required = false,
  ...rest
}) => (
  <FormControl
    error={hasError}
    variant="outlined"
    size="small"
    fullWidth={fullWidth}
    required={required}
  >
    <InputLabel htmlFor={id}>{label}</InputLabel>
    <MaterialUIOutlinedInput
      id={id}
      label={label}
      onChange={e => onChange(e.target.value)}
      {...rest}
    />
    <FormHelperText>{error}</FormHelperText>
  </FormControl>
);

export default OutlinedInput;
