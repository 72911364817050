import React from 'react';
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import Checkbox from "../../../Common/Forms/Checkbox";
import DocumentTypeSelect from "./DocumentTypeSelect";
import ClientSelect from "./ClientSelect";
import OrderType from "./OrderType";


const BillDetails = ({formData, setFormData, isAdd, disableEdit}) => {
    return (
        <GridContainer
            spacing={2}
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Grid item xs={12} md={6}>
                <DocumentTypeSelect
                    value={formData.documentType}
                    onChange={value => setFormData('documentType', value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <ClientSelect
                    value={formData.client}
                    onChange={value => setFormData('client', value)}
                    documentTypeId={formData.documentType}
                />
            </Grid>
            {!isAdd &&
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='billNumber'
                        label={<IntlMessages id='document.number'/>}
                        value={formData.billNumber}
                        fullWidth
                        disabled
                    />
                </Grid>
            }
            <Grid item xs={12} md={6}>
                <OutlinedDatePicker
                    name='creationDate'
                    label={<IntlMessages id='date'/>}
                    value={formData.creationDate}
                    onChange={value => setFormData('creationDate', value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='observations'
                    label={<IntlMessages id='observations'/>}
                    value={formData.observations}
                    onChange={value => setFormData('observations', value)}
                    fullWidth
                    disabled={disableEdit}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OrderType
                    value={formData.orderType}
                    onChange={value => setFormData('orderType', value)}
                    disabled={disableEdit}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='serviceDescription'
                    label={<IntlMessages id='service.description'/>}
                    value={formData.serviceDescription}
                    onChange={value => setFormData('serviceDescription', value)}
                    fullWidth
                    disabled={disableEdit}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='serviceMonth'
                    label={<IntlMessages id='service.month'/>}
                    value={formData.serviceMonth}
                    onChange={value => setFormData('serviceMonth', value)}
                    fullWidth
                    disabled={disableEdit}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    name='reference'
                    label={<IntlMessages id='reference'/>}
                    value={formData.reference}
                    onChange={value => setFormData('reference', value)}
                    fullWidth
                    disabled={disableEdit}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Checkbox
                    name='applyDeduction'
                    label={<IntlMessages id='apply.deduction'/>}
                    checked={formData.applyDeduction}
                    onChange={value => setFormData('applyDeduction', value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <Checkbox
                    name='isCredit'
                    label={<IntlMessages id='credit'/>}
                    checked={formData.isCredit}
                    onChange={value => setFormData('isCredit', value)}
                    fullWidth
                />
            </Grid>
            {formData.isCredit &&
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='creditDays'
                        label={<IntlMessages id='credit.days'/>}
                        value={formData.creditDays}
                        onChange={value => setFormData('creditDays', value)}
                        fullWidth
                        disabled={disableEdit}
                        type="number"
                    />
                </Grid>
            }
        </GridContainer>
    );
};

export default BillDetails;