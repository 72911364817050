class MonitoringDevice {
  constructor(state, lastUpdate, details) {
    this.state = state;
    this.lastUpdate = lastUpdate;
    this.details = details;
  }

  static map = device => {
    return new MonitoringDevice(
      device.estado,
      device.ultimaActualizacion,
      device.detalles
    );
  };
}

export default MonitoringDevice;
