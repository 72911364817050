import React from "react";
import {List} from "@material-ui/core";
import FileListItem from "./FileListItem";

const FileList = ({files, onRemoveFile}) => {
  return (
    <List>
      {files.map((file, index) => (
        <FileListItem
          key={file.path}
          file={file}
          onRemoveFile={() => onRemoveFile(index)}
        />
      ))}
    </List>
  );
};

export default FileList;
