import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {COLORS} from "./constants";


const ActivityGraph = ({base64}) => {
    return (
        base64 ?
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
            }}
        >
            <img height="100%" width="auto"
                 src={`data:image/svg+xml;base64,${base64}`}
                 alt="activityGraph"/>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    gap: '2rem'
                }}
            >
                {Object.values(COLORS).map((c, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <Box
                            height="10px"
                            width="20px"
                            sx={{
                                backgroundColor: c.color
                            }}
                        />
                        <Typography variant="subtitle2">{c.text}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
            : <Box display="flex"
                   border="solid"
                   borderRadius="10px"
                   borderColor="#3f51b5"
                   width="100%"
                   height={210}
                   alignItems="center"
                   justifyContent="center">
                <Typography variant="subtitle2" align="center">
                    <IntlMessages id={"fleet.management.tachograph.traffic.drivers.list.item.today.activities.none"}/>
                </Typography>
            </Box>
    );
};

ActivityGraph.propTypes = {
    base64: PropTypes.string.isRequired,
};

export default ActivityGraph;