import React from "react";
import {useDispatch} from "react-redux";
import {addReserve} from "../../../../../redux/actions/FleetManagement/Reserves";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const initData = {
  plaque: "",
  driver: "",
  startDate: "",
  endDate: "",
  turn: ""
};

const AddReserveDialog = ({open, setOpen}) => {
  const id = "add-reserve-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addReserve(data, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.reserves.add.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.reserves.add.save"} />}
      cancelText={<IntlMessages id={"fleet.management.reserves.add.cancel"} />}
    >
      <Form id={id} onSubmit={onSubmit} initialData={initData} />
    </FormDialog>
  );
};
export default AddReserveDialog;
