import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  vehicles: [
    {
      rule: v => v.length > 0,
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.vehicle.error" />
      )
    }
  ],
  events: [
    {
      rule: v => v.length > 0,
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.events.error" />
      )
    }
  ],
  startDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.date.error" />
      )
    }
  ],
  endDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.date.error" />
      )
    }
  ]
};
