import React, {useCallback, useEffect} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/PettyCashTypes/PageContainers";
import CreatePettyCashTypeDialog from "components/Administrator/PettyCashTypes/SavePettyCashDialog/CreateAdvanceTypeDialog";
import EditPettyCashTypeDialog from "components/Administrator/PettyCashTypes/SavePettyCashDialog/EditPettyCashTypeDialog";
import DeleteConfirmation from "components/Administrator/PettyCashTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/PettyCashTypes/Confirmations/RestoreConfirmation";
import Table from "../../../../components/Administrator/PettyCashTypes/Table";
import Filters from "components/Administrator/PettyCashTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";

import useUiState from "hooks/Administrator/useUiState";

import pettyCashTypes from "redux/actions/Administrator/PettyCashTypes";
import FilterBuilder from "../../../../utils/filters";
import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";

const PettyCashTypes = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(pettyCashTypes.get());
      return () => dispatch(pettyCashTypes.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(pettyCashTypes.applyPettyCashTypesFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () =>
    dispatch(pettyCashTypes.resetPettyCashTypesFilters());

  const onDelete = () => dispatch(pettyCashTypes.delete(ui.deletingId));
  const onRestore = () => dispatch(pettyCashTypes.restore(ui.restoringId));
  const onEdit = data => dispatch(pettyCashTypes.edit(data, ui.editingId));
  const onCreate = data => dispatch(pettyCashTypes.create(data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreatePettyCashTypeDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditPettyCashTypeDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.pettyCashTypes;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("description", filters.description)
    .byFieldEquals("approvalOfAdvances", filters.approvalOfAdvances)
    .byFieldEquals("obligationOfAdvances", filters.obligationOfAdvances)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(PettyCashTypes);
