import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import Regulation from "../domain/Regulation";
import Response from "domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

/*const regulation = [
    {
        nu_id_regulation: 1,
        nu_id_entity_origin: 1,
        vc_entity_name_origin: "San Fernando",
        nu_id_campus_origin: 1,
        vc_campus_name_origin: "Sede 1",
        nu_id_warehouse_origin: 1,
        vc_warehouse_name_origin: "Almacen 1",
        dt_date: "9/25/2021",
        bi_active: '1'
    }
];*/

export const cancelPrevRegulationFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllRegulation = async filters => {
  //return regulation.map(regulation => Regulation.map(regulation));
  try {
    const {data} = await axiosInstance.post(
      "/Inventario_Regulacion/sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        dt_fecha_inicio: filters.fromDate || "",
        dt_fecha_fin: filters.toDate || "",
        vc_destino: filters.destiny,
        vc_desc_producto: filters.products
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return data.map(regulation => Regulation.map(regulation));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createRegulation = async data => {
  //console.log("CREATE REGULACION: ",data);
  //return 1;
  try {
    const response = await axiosInstance.post("/Inventario_Regulacion/ins", {
      nu_id_almacen_dest: data.warehouseOrigin.value,
      vc_usuario: "administradorcajachica@4gflota.com",
      ls_detalle: data.products.map(e => ({
        nu_id_producto: e.value,
        nu_cantidad: e.amount
      }))
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editRegulation = async (id, data) => {
  //console.log("EDIT REGULATION: ", id, data);
  //return 1;
  try {
    const response = await axiosInstance.post("/Inventario_Regulacion/upd", {
      nu_id_movimiento: id,
      nu_id_almacen_dest: Number(data.warehouseOrigin.value),
      vc_usuario: "administradorcajachica@4gflota.com",
      ls_detalle: data.products.map(e => ({
        nu_id_producto: e.value,
        nu_cantidad: e.amount
      }))
    });
    //console.log("RESPONSE REGULATION EDIT: ", response);
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeStateRegulation = async id => {
  try {
    const response = await axiosInstance.post("/Inventario_Regulacion/del", {
      nu_id_movimiento: id
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
