import React from 'react';
import {MapContainer, TileLayer} from "react-leaflet";
import MapUseContainer from "../MapUseContainer";

const Map = ({latitude, longitude, children, style, ...rest}) => {
    return (
        <MapContainer
            center={[latitude, longitude]}
            zoom={13}
            scrollWheelZoom={false}
            style={{
                width: 'auto',
                ...style,
            }}
            {...rest}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapUseContainer latitude={latitude} longitude={longitude} />
            {children}
        </MapContainer>
    );
};

export default Map;