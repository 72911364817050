import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../../../../../Common/Forms/Select/MultipleSelect";

const PlanningPlaceSelect = ({value, onChange}) => {
    const id = 'planning-place-filter';
    const places = [
        {
            id: "1002",
            name: "1002",
        },
        {
            id: "1003",
            name: "1003",
        },
        {
            id: "1004",
            name: "1004",
        },
        {
            id: "1013",
            name: "1013",
        },
        {
            id: "1017",
            name: "1017",
        }
    ]
    return (
        <MultipleSelect
            id={id}
            label={<IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.planning.place" />}
            value={value}
            onChange={value => onChange(value)}
            fullWidth
            options={places}
        />
    );
}

PlanningPlaceSelect.propTypes = {
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any
};

export default PlanningPlaceSelect;