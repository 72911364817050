import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import TachographFile from "../../../domain/FleetManagement/Tachograph/TachographFile";
import Response from "../../../domain/Response";
import {uploadDocumentToS3} from "../../aws/s3Services";

export const getAllTachographFiles = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tacografo_Fichero/sel", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      dt_fecha_desde: filters.fromDate,
      dt_fecha_hasta: filters.toDate,
      vc_conductores: filters.drivers.map(d => d.id),
      vc_vehiculos: filters.vehicles.map(v => v.id)
    });
    return data.map(f => TachographFile.map(f));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createZipTachographFile = async file => {
  try {
    const {data} = await axiosInstance.post(
      "/Documentos/ins_fichero_tacografo_ZIP",
      {},
      {
        params: {
          vc_filename: file.name,
          nu_id_entidad_empresa: getCompanyEntityId()
        }
      }
    );
    await uploadDocumentToS3(data, file.file);
    const response = await saveFileOnBd(file);
    if (
      response.transactionState === "0" ||
      response.transactionState === "-1"
    ) {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createIndividualTachographFile = async file => {
  try {
    const {data} = await axiosInstance.post(
      "/Documentos/ins_fichero_tacografo",
      {},
      {
        params: {
          vc_filename: file.name
        }
      }
    );
    await uploadDocumentToS3(data, file.file);
    const response = await saveFileOnBd(file);
    if (
        response.transactionState === "0" ||
        response.transactionState === "-1"
    ) {
      return Promise.reject({message: response.transactionMessage});
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

const saveFileOnBd = async file => {
  try {
    const {data} = await axiosInstance.post("/Tacografo_Fichero/ins", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      vc_nombre_fichero: file.name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

const getBase64File = async files => {
  try {
    const {
      data
    } = await axiosInstance.post("/Documentos/sel_fichero_tacografo", [
      ...files.map(f => f.fileName)
    ]);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const downloadTachographFile = async files => {
  try {
    const base64 = await getBase64File(files);
    if (files.length > 1) {
      return {
        filename: "ficherosTacografo.zip",
        resourceLink: `data:application/zip;base64,${base64}`
      };
    }
    return {
      filename: files[0].fileName,
      resourceLink: `data:application/tgd;base64,${base64}`
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchDriversByEntityId = async () => {
  try {
    const {data} = await axiosInstance.get(
        "Tacografo_Fichero/sel_conductores",
        {
          params: {
            nu_id_entidad_empresa: getCompanyEntityId(),
          }
        }
    );
    return data.map(driver => ({
        id: driver.nu_id_entidad,
        name: driver.vc_nombre
    }));
  }catch (e) {
    return Promise.reject(e);
  }
}
export const fetchVehiclesByEntityId = async () => {
  try {
    const {data} = await axiosInstance.get(
        "Tacografo_Fichero/sel_vehiculos",
        {
          params: {
            nu_id_entidad_empresa: getCompanyEntityId(),
          }
        }
    );
    return data.map(vehicle => ({
        id: vehicle.nu_id_vehiculo,
        name: vehicle.vc_placa
    }));
  }catch (e) {
    return Promise.reject(e);
  }
}
