import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import PettyCash from "../domain/PettyCash";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAllPettyCashesByGuardian = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllPettyCashesByGuardian = async guardianId => {
  try {
    const pettyCashes = await axiosInstance.post(
      "/Caja_Chica/sel_operativa",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_gestor_caja: guardianId
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return pettyCashes.data.map(pettyCash => PettyCash.map(pettyCash));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
