import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from "@material-ui/core";
import {CircleNumber, RedText, useStyles} from "./styles";

const NormalDestinationsList = ({
  destinations,
  makeAddress,
  setSelected,
  selected,
    filteredDestinations,
}) => {
  const classes = useStyles();
  const handleOnclickDestination = (destinationId) => {
    setSelected(destinations.findIndex(d => d.id === destinationId));
  }
  return (
    <List className={classes.root}>
      {filteredDestinations.map((destination, index) => (
        <React.Fragment key={index}>
          <ListItem
            button
            selected={selected === index}
            onClick={e => handleOnclickDestination(destination.id)}
          >
            <ListItemAvatar>
              <CircleNumber>{index + 1}</CircleNumber>
            </ListItemAvatar>
            <ListItemText secondary={makeAddress(destination)}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>{destination.headquarterName}</Typography>
                  <RedText>
                    {destination.stateDescription}
                  </RedText>
              </Box>
            </ListItemText>
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

export default NormalDestinationsList;
