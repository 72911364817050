import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getPettyCashDataMasterOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 41) && options.push({
        name: <IntlMessages id={'pages.guardians'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/guardians',
    });
    submodules.some(s => s.id === 42) && options.push({
        name: <IntlMessages id={'pages.supervisors'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/supervisors',
    });
    submodules.some(s => s.id === 108) && options.push({
        name: <IntlMessages id={'treasury.cost.centers.types.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/cost-center-types',
    });
    submodules.some(s => s.id === 43) && options.push({
        name: <IntlMessages id={'pages.rejection.types'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/rejections',
    });
    submodules.some(s => s.id === 44) && options.push({
        name: <IntlMessages id={'pages.expenseTypes'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/expenses',
    });
    submodules.some(s => s.id === 45) && options.push({
        name: <IntlMessages id={'pages.pettyCashTypes'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/petty-cash',
    });
    submodules.some(s => s.id === 46) && options.push({
        name: <IntlMessages id={'pages.advanceTypes'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/advance-payments',
    });
    return options;
}
const getPettyCashDailyOperationOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 47) && options.push({
        name: <IntlMessages id={'pages.cashManagers'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/cash-managers',
    });
    submodules.some(s => s.id === 48) && options.push({
        name: <IntlMessages id={'pages.unresolved.accounting'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/unresolved-accounting-list',
    });
    return options;
}

const getPettyCashCashManagementOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 49) && options.push({
        name: <IntlMessages id={'sidebar.operative'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/operative',
    });
    submodules.some(s => s.id === 50) && options.push({
        name: <IntlMessages id={'sidebar.add.advances'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/add-advances',
    });
    submodules.some(s => s.id === 51) && options.push({
        name: <IntlMessages id={'sidebar.add.expenses'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/add-expenses',
    });
    submodules.some(s => s.id === 52) && options.push({
        name: <IntlMessages id={'sidebar.advances.list'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/advances-list',
    });
    submodules.some(s => s.id === 53) && options.push({
        name: <IntlMessages id={'pages.guardian.unresolvedExpenses'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/unresolved-expenses',
    });
    submodules.some(s => s.id === 54) && options.push({
        name: <IntlMessages id={'sidebar.accounting.list'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/accounting-list',
    });
    submodules.some(s => s.id === 99) && options.push({
        name: <IntlMessages id={'petty.cash.refund'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/refund',
    });
    return options;
}

const getPettyCashExpenseSupervisionOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 55) && options.push({
        name: <IntlMessages id={'pages.unresolvedExpenses'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/unresolved-expenses',
    });
    submodules.some(s => s.id === 56) && options.push({
        name: <IntlMessages id={'sidebar.unresolved.advances'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/cash-management/unresolved-advances',
    });

    return options;
}

const getPettyCashOptions = submodules => {
    let options = [];
    const dataMaster =  getPettyCashDataMasterOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.masterData'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperation = getPettyCashDailyOperationOptions(submodules);
    dailyOperation?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.daily.operation'}/>,
        type: 'section',
        children: dailyOperation,
    });
    const managementOptions = getPettyCashCashManagementOptions(submodules);
    managementOptions?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.cash.management'}/>,
        type: 'section',
        children: managementOptions,
    });
    const expenseSupervisionOptions = getPettyCashExpenseSupervisionOptions(submodules);
    expenseSupervisionOptions?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.expenseSupervision'}/>,
        type: 'section',
        children: expenseSupervisionOptions,
    });
    return options;
}

const getBillingDataMasterOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 111) && options.push({
        name: <IntlMessages id={'treasury.taxes.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/invoicing/taxes',
    });
    submodules.some(s => s.id === 112) && options.push({
        name: <IntlMessages id={'treasury.services.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/invoicing/services',
    });
    return options;
}
const getBillingDailyOperationOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 113) && options.push({
        name: <IntlMessages id={'treasury.budgets.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/invoicing/budgets',
    });
    submodules.some(s => s.id === 114) && options.push({
        name: <IntlMessages id={'treasury.bills.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/invoicing/bills',
    });
    submodules.some(s => s.id === 114) && options.push({
        name: <IntlMessages id={'treasury.credit.notes.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/invoicing/credit-notes',
    });
    submodules.some(s => s.id === 114) && options.push({
        name: <IntlMessages id={'treasury.debit.notes.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/treasury/invoicing/debit-notes',
    });
    return options;
}
const getBillingOptions = (submodules) => {
    let options = [];
    const dataMaster =  getBillingDataMasterOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.masterData'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperation = getBillingDailyOperationOptions(submodules);
    dailyOperation?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.daily.operation'}/>,
        type: 'section',
        children: dailyOperation,
    });
    return options;
}

export const getTreasuryOptions = () => {
    const permissions = getUserPermissions();
    let treasury = {
        name: <IntlMessages id={'sidebar.treasury'}/>,
        type: 'section',
        children: [],
    };
    let submodules = permissions.find(p => p.id===10)?.submodules;
    if(submodules?.length > 0) {
        const pettyCashOptions = getPettyCashOptions(submodules);
        pettyCashOptions.length > 0 && treasury.children.push({
            name: <IntlMessages id={'sidebar.petty.cash'}/>,
            type: 'collapse',
            children: pettyCashOptions,
        });
    }
    const permission = permissions.find(p => p.id===17);
    submodules = permission?.submodules;
    if(submodules?.length > 0) {
        const billingOptions = getBillingOptions(submodules);
        billingOptions.length > 0 && treasury.children.push({
            name: permission.description,
            type: 'collapse',
            children: billingOptions,
        });
    }

    return treasury.children.length > 0? treasury : null;
}