import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, List, ListItem} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "./styles";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import OperationsList from "./OperationsList";

const ModuleSubmodulesList = ({module, formData,onChange}) => {
    const classes = useStyles();
    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id={`edit-role-module-${module.id}`}
            >
                <Typography className={classes.heading}>{module.description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {module?.submodules?.length > 0
                    ? <List dense className={classes.list}>
                        {
                            module?.submodules?.map(submodule => (
                                <ListItem key={`submodule-${submodule.id}`}>
                                    <OperationsList
                                        submodule={submodule}
                                        formData={formData}
                                        onChange={onChange}/>
                                </ListItem>
                            ))
                        }
                    </List>
                    : <div className={classes.noText}>
                        <Typography variant="h2">
                            <IntlMessages id={"configuration.user.settings.roles.edit.form.no.submodules"}/>
                        </Typography>
                    </div>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export default ModuleSubmodulesList;