import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {cloneDeep} from "lodash";
import Form from "./Form";
import {dialogsList} from "../constants";
import {closeDialog} from "../../../../../redux/reducers/Logistic/orders";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";

const OrderDialog = () => {
    const id = dialogsList.orderDialog;
    const {dialogs, currentOrder} = useSelector(({logistic}) => logistic.orders);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === id);
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    return (
        <FullScreenDialog
            open={isOpen}
            title="edit"
            handleClose={onClose}
        >
            {(isOpen && currentOrder) && <Form
                id={id}
                initData={cloneDeep(currentOrder)}
            />}
        </FullScreenDialog>
    );
};

export default OrderDialog;