import React from "react";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import {StyledTableCell} from "./styles";

import {useDispatch} from "react-redux";

const CheckStockBodyReps = ({
  order,
  reps,
  setSelectedAdvance,
  isGuardian = true
}) => {
  const rows = 6;
  const [ open, setOpen ] = React.useState(rows <= 1);
  const selectHandler = advance => {
    if (rows > 1 && !open) {
      setOpen(true);
    } else {
      setSelectedAdvance({reps: reps, ...advance});
    }
  };
  if (order === "Entity") {
    return (
      <React.Fragment>
        <StyledTableCell
          onClick={() => selectHandler(reps)}
          style={{color: "transparent", paddingRight: 5}}
        >
          <IconButton style={{color: "transparent"}} size="small" disabled>
            <AddIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => selectHandler(reps)}
          style={{color: "transparent", paddingRight: 4, paddingLeft: 0}}
        />
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[1]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[3]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[4]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[5]}
        </StyledTableCell>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <StyledTableCell
          onClick={() => selectHandler(reps)}
          style={{color: "transparent", paddingRight: 5}}
        >
          <IconButton style={{color: "transparent"}} size="small" disabled>
            <AddIcon />
          </IconButton>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => selectHandler(reps)}
          style={{color: "transparent", paddingRight: 4, paddingLeft: 0}}
        />
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[2]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[3]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[4]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(reps)} align="left">
          {reps[5]}
        </StyledTableCell>
      </React.Fragment>
    );
  }
};

export default CheckStockBodyReps;
