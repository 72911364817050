import React, {useMemo} from 'react';
import {parseLoweCamelCaseToTitleText} from "../../../../../../../utils/parsers";
import {v4 as uuidV4} from "uuid";
import {DataGrid} from "@mui/x-data-grid";

const Table = ({data}) => {
    const columns = useMemo(() => {
        if(!data?.length) {
            return [];
        }
        const entries = Object.entries(data[0]);
        const columns = entries
            .map(([header, value], _) => {
                return {
                    field: header,
                    headerName: parseLoweCamelCaseToTitleText(header),
                    minWidth: Math.max(150, Math.max(...data?.map(row => row[header]?.length) ?? 0) * 18),
                }
            });
        return [
            {
                field: 'id',
                hide: true
            },
            ...columns
        ];
    }, [data]);
    return (
        <DataGrid
            style={{
                height: '100%',
            }}
            columns={columns}
            rows={data?.map(row => ({
                id: uuidV4(),
                ...row,
            }))}
            disableSelectionOnClick
            disableColumnMenu
        />
    );
};

export default Table;