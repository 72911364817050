import React, {useEffect} from "react";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {
  clearCurrentAccounting,
  setCurrentAccounting
} from "../../../../redux/actions/Guardians/CurrentAccounting";
import {Box} from "@material-ui/core";
import DetailTable from "./DetailTable";
import ExpensesTable from "./ExpensesTable";
import FloatingButton from "./FloatingButton";
import AddExpenseDialog from "./AddExpenseDIalog";
import {getUserType} from "../../../../services/StorageService";
import EditExpenseDialog from "./EditExpenseDialog";

const AccountingDetail = ({open, setOpen, setAccountingAlert}) => {
  const [ openAddExpense, setOpenAddExpense ] = React.useState(false);
  const dispatch = useDispatch();
  const {currentAccounting, accountingList} = useSelector(
    state => state.guardian
  );
  const {state} = currentAccounting;
  const handleClose = () => {
    dispatch(clearCurrentAccounting());
    setOpen(false);
  };
  const accountingClose = () => {
    dispatch(clearCurrentAccounting());
    setOpen(false);
    setAccountingAlert(true);
  };
  useEffect(
    () => {
      currentAccounting.id &&
        accountingList.data.length > 0 &&
        dispatch(
          setCurrentAccounting(
            accountingList.data.find(
              accounting => accounting.id === currentAccounting.id
            )
          )
        );
    },
    [ accountingList.data ]
  );
  return (
    <FullScreenDialog
      open={open}
      handleClose={handleClose}
      title={"guardian.accounting.dialog.header"}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <DetailTable />
        <ExpensesTable />
        <EditExpenseDialog />
        {(((state.id === "1" || state.id === "4") &&
          getUserType().includes("guardian")) ||
          (state.id === "2" && getUserType().includes("administrator"))) && (
          <FloatingButton
            closeDialog={accountingClose}
            setOpenAddExpense={setOpenAddExpense}
          />
        )}
        <AddExpenseDialog open={openAddExpense} setOpen={setOpenAddExpense} />
      </Box>
    </FullScreenDialog>
  );
};

export default AccountingDetail;
