import React from 'react';
import {Card, CardActions, CardContent, CardHeader, IconButton} from "@material-ui/core";
import {ResponsiveContainer} from "recharts";
import widgets from "../../../../../Viewer/DashboardContainer/WidgetsContainer/widgets";
import {SortableElement, SortableHandle} from "react-sortable-hoc";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DeleteIcon from "@material-ui/icons/Delete";
import {Resizable} from "re-resizable";
import {useTheme} from "@material-ui/core/styles";


const DragHandle = SortableHandle(({title}) => (
    <CardHeader
        title={title}
        action={(
            <IconButton>
                <DragIndicatorIcon/>
            </IconButton>
        )}
    />
));

const DashboardItem = SortableElement(
    ({widget, index, onDelete, updateWidget}) => {
        const baseWidget = widgets[widget.id];
        const theme = useTheme();

        const onDeleteWidget = (e) => {
            e.preventDefault();
            onDelete(widget);
        }

        const getWidth = (width) => {
            let newWidth = width;
            const mainContainer = document.getElementById('graphs-container');
            if (mainContainer.offsetWidth < newWidth) {
                newWidth = mainContainer.offsetWidth;
            }
            return newWidth;
        }
        const onResizeStop = (e, direction, ref, d) => {
            let width = widget.width + d.width;
            let height = widget.height + d.height;
            updateWidget({
                ...widget,
                width: getWidth(width),
                height
            });
        }
        const renderWidget = () => {
            switch (baseWidget.type) {
                case 1:
                    return (
                        <ResponsiveContainer>
                            {baseWidget.renderWidget(widget)}
                        </ResponsiveContainer>
                    );
                case 2:
                    return baseWidget.renderWidget(widget);
                default:
                    return (
                        <>
                            {baseWidget.renderWidget(widget, theme)}
                        </>
                    );
            }
        }
        return (
            <Resizable
                size={{
                    width: getWidth(widget.width || baseWidget.defaultWidth),
                    height: widget.height || baseWidget.defaultHeight
                }}
                onResizeStop={onResizeStop}
                style={{
                    margin: '0.7rem 0.5rem'
                }}
            >
                <Card
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <DragHandle
                        title={baseWidget.name}
                    />
                    <CardActions disableSpacing>
                        <IconButton onClick={onDeleteWidget}>
                            <DeleteIcon/>
                        </IconButton>
                    </CardActions>
                    <CardContent
                        id={`widget-${widget.id}`}
                        style={{
                            height: baseWidget.type === 1? '70%' : 'auto',
                            overflowY: 'hide'
                        }}
                    >
                        {renderWidget()}
                    </CardContent>
                </Card>
            </Resizable>
        );
    }
);


export default DashboardItem;