import React from "react";
import FiltersContainer from "../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../hooks/Common/useForm";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import {useDispatch, useSelector} from "react-redux";
import {applyClientFilters, resetClientFilters} from "../../../../../redux/actions/Administrator/Clients";

const initialData = {
    clientCode: '',
    name: '',
    businessName: '',
}

const Filters = () => {
    const {filters} = useSelector(
        ({administrator}) => administrator.clients
    );
    const {formData, setFormData, resetForm, setValues} = useForm(initialData);
    const dispatch = useDispatch();
    const onApplyFilters = () => {
        dispatch(applyClientFilters(formData));
    };
    const onResetFilters = () => {
        resetForm();
        dispatch(resetClientFilters());
    };
    React.useEffect(() => {
        setValues(filters);
    }, []);
    return (
      <FiltersContainer
        active
        marginBottom={2}
      >
          <form>
              <GridContainer spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                      <OutlinedInput
                          id="client-code-filter"
                          fullWidth
                          value={formData.clientCode}
                          onChange={value => setFormData('clientCode', value)}
                          label={<IntlMessages id="fiscal.identity" />}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <OutlinedInput
                          id="name-filter"
                          fullWidth
                          value={formData.name}
                          onChange={value => setFormData('name', value)}
                          label={<IntlMessages id="registered.name" />}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <OutlinedInput
                          id="business-name-filter"
                          fullWidth
                          value={formData.businessName}
                          onChange={value => setFormData('businessName', value)}
                          label={
                              <IntlMessages id="administrator.clients.table.businessName" />
                          }
                      />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                      <ContainedButton
                          onClick={e => {
                              e.preventDefault();
                              onResetFilters();
                          }}
                          text={<IntlMessages id={"filters.button.clear"} />}
                      />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                      <ContainedButton
                          onClick={e => {
                              e.preventDefault();
                              onApplyFilters();
                          }}
                          color="primary"
                          text={<IntlMessages id={"filters.button.apply"} />}
                      />
                  </Grid>
              </GridContainer>
          </form>
      </FiltersContainer>
    );
};

export default Filters;
