import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FullScreenDialog from "../../../../../Common/Dialogs/FullScreenDialog";
import {
    addConfigurationSubmoduleOperation,
    resetCurrentConfigurationSubmodule
} from "../../../../../../redux/actions/Configuration/Modules";
import OperationsTable from "./OperationsTable";
import FloatingButtonWithAdd from "../../../../../Common/Buttons/FloatingButtonWithAdd";
import AddDialog from "../../AddDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";

const SubmoduleDetailDialog = ({open, setOpen}) => {
    const {selectedSubmodule} = useSelector(({configuration}) => configuration.modules);
    const dispatch = useDispatch();
    const [openAddOperation, setOpenAddOperation] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        dispatch(resetCurrentConfigurationSubmodule());
    }
    const handleAddOperation = data => {
        dispatch(addConfigurationSubmoduleOperation(selectedSubmodule, data));
        setOpenAddOperation(false);
    }
    return(
        <FullScreenDialog
            notLanguageTitle={selectedSubmodule?.description || ""}
            open={open}
            handleClose={() => handleClose()}
        >
            <Box m={16}>
                <OperationsTable/>
                <AddDialog
                    id={'add-operation-form'}
                    title={<IntlMessages id={"configuration.user.settings.modules.submodules.operations.add.title"}/>}
                    openAdd={openAddOperation}
                    setOpenAdd={setOpenAddOperation}
                    onAddHandler={handleAddOperation}
                />
            </Box>
            <FloatingButtonWithAdd
                onAddClicked={() => setOpenAddOperation(true)}
            />

        </FullScreenDialog>
    );
}

export default SubmoduleDetailDialog;