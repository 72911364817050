import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import {downloadFile, getFileByName} from "../../../../services/FileService";
import toBase64 from "../../../../utils/files";
import TableContainer from "../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import Confirmation from "../../../Common/Dialogs/Confirmation";

const FilesTable = ({files = [], setFiles}) => {
  const [ openConfirmation, setOpenConfirmation ] = React.useState(false);
  const [ selectedFile, setSelectedFile ] = React.useState(null);
  const onDownload = file => {
    if (file.id !== 0) {
      getFileByName(file.name || file.link)
        .then(({base64File, contentType}) => {
          downloadFile(file.name, `data:${contentType};base64,${base64File}`);
        })
        .catch(error => {
          throw new Error(error.message);
        });
    } else {
      toBase64(file.file)
        .then(base64File => {
          downloadFile(file.name, base64File);
        })
        .catch(error => {
          throw new Error(error.message);
        });
    }
  };
  return (
    <React.Fragment>
      <TableContainer
        head={
          <StyledTableRow>
            <StyledTableCell>
              <IntlMessages
                id={"fleet.management.common.form.files.table.name"}
              />
            </StyledTableCell>
            <StyledTableCell />
          </StyledTableRow>
        }
      >
        {files.map((file, index) => (
          <StyledTableRow key={`file-${index}`}>
            <StyledTableCell>{file.name}</StyledTableCell>
            <StyledTableCell>
              {file.id === 0 && (
                <IconButton
                  onClick={() => {
                    setSelectedFile(index);
                    setOpenConfirmation(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
              <IconButton onClick={() => onDownload(file)}>
                <GetAppIcon />
              </IconButton>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableContainer>
      <Confirmation
        open={openConfirmation}
        onReject={() => {
          setOpenConfirmation(false);
          setSelectedFile(null);
        }}
        onAccept={() => {
          setFiles(files.filter((file, i) => i !== selectedFile));
          setSelectedFile(null);
        }}
        text={
          <IntlMessages id={"fleet.management.common.form.files.delete.text"} />
        }
      />
    </React.Fragment>
  );
};

export default FilesTable;
