import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  APPLY_TACHOGRAPH_ALERTS_FILTERS,
  FETCH_ALL_ALERTS_CONFIG_DATA,
  FETCH_ALL_TACHOGRAPH_ALERTS,
  RESET_TACHOGRAPH_ALERTS_FILTERS,
  RESET_TACHOGRAPH_ALERTS_STATE
} from "../../types/FleetManagement/AlertsTypes";
import {
  createConfigAlert,
  getAllConfigAlertsData,
  getAllTachographAlerts
} from "../../../services/FleetManagement/Tachograph/TachographAlertsService";

export const resetTachographAlerts = () => ({
  type: RESET_TACHOGRAPH_ALERTS_STATE
});
export const fetchAllTachographAlerts = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllTachographAlerts(filters)
      .then(alerts => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_TACHOGRAPH_ALERTS, payload: alerts});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const applyTachographAlertsFilters = filters => ({
  type: APPLY_TACHOGRAPH_ALERTS_FILTERS,
  payload: filters
});
export const resetTachographAlertsFilters = () => ({
  type: RESET_TACHOGRAPH_ALERTS_FILTERS
});
export const fetchAllAlertsConfigData = () => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllConfigAlertsData()
      .then(data => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_ALERTS_CONFIG_DATA, payload: data});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addAlertConfiguration = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createConfigAlert(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
