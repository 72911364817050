import React from "react";
import FiltersContainer from "components/Common/Forms/FiltersContainer";
import Form from "./Form";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const Filters = () => {
  const {type: selected} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  const titles = {
    5: (
      <IntlMessages
        id={"logistic.inventoryReport.container.control.report.title"}
      />
    ),
    6: <IntlMessages id={"logistic.inventoryReport.debt.report.title"} />,
    7: <IntlMessages id={"logistic.inventoryReport.stock.report.title"} />,
    8: <IntlMessages id={"logistic.inventoryReport.sales.report.title"} />,
    18: (
      <IntlMessages
        id={"logistic.inventoryReport.provider.stock.report.title"}
      />
    ),
    19: (
      <IntlMessages
        id={"logistic.inventoryReport.provider.receipt.report.title"}
      />
    )
  };
  return (
    <React.Fragment>
      <Box my={4}>
        <Typography variant="h2">{titles[selected]}</Typography>
      </Box>
      <FiltersContainer active marginBottom={5} onClose={() => {}}>
        <Form service={selected} />
      </FiltersContainer>
    </React.Fragment>
  );
};

export default Filters;
