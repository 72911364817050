import {
  CLEAR_ADVANCES_LIST_FILTER,
  SET_ADVANCES_LIST_FILTER
} from "../../types/CustomTypes";

const initState = {
  state: null,
  active: false,
  box: ""
};

export default (state = initState, action) => {
  switch (action.type) {
    case CLEAR_ADVANCES_LIST_FILTER: {
      return {
        ...state,
        ...initState
      };
    }
    case SET_ADVANCES_LIST_FILTER: {
      return {
        ...state,
        ...action.payload
      };
    }
    default: {
      return state;
    }
  }
};
