import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import {Box, IconButton} from "@material-ui/core";
import PaginationFooter from "../../../../Common/Tables/PaginationFooter";
import Filters from "./Filters";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchAllInfractions,
  setCurrentInfraction
} from "../../../../../redux/actions/FleetManagement/Infractions";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import moment from "moment";
import {parseNumberToMoney} from "../../../../../utils/parsers";
import TableHeader from "./TableHeader";
import EditIcon from "@material-ui/icons/Edit";

const InfractionsTable = ({openEditDialog}) => {
  const dispatch = useDispatch();
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.infractions
  );
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);
  React.useEffect(
    () => {
      dispatch(fetchAllInfractions(filters));
    },
    [ filters ]
  );
  return (
    <Box display="flex" flexDirection="column">
      <Filters />
      <Box my={4}>
        <TableContainer
          head={<TableHeader />}
          footer={
            <PaginationFooter
              data={data}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          }
        >
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data).map(infraction => (
            <StyledTableRow key={infraction.id}>
              <StyledTableCell>{infraction.plaque}</StyledTableCell>
              <StyledTableCell>
                {moment(infraction.date).format("DD/MM/YYYY")}
              </StyledTableCell>
              <StyledTableCell>{infraction.driver}</StyledTableCell>
              <StyledTableCell>{infraction.infringedRule}</StyledTableCell>
              <StyledTableCell>
                {parseNumberToMoney(infraction.amount)}
              </StyledTableCell>
              <StyledTableCell>
                <Box display="flex" flexDirection="row">
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        dispatch(setCurrentInfraction(infraction));
                        openEditDialog && openEditDialog();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default InfractionsTable;
