import React from 'react';

import Select from 'components/Common/Forms/Select';

const IncidenceTypeSelect = ({ id, label, hasError, error, value, onChange, data = [] }) => {

  const options = [
    {value: '', label: 'Seleccione una opción'},
    ...data.filter(type => type.active !== false).map(type => ({value: type.id, label: type.description}))
  ];

  return (
    <Select
      value={value?.value || ''}
      error={hasError}
      errorMessage={error}
      fullWidth
      id={id}
      label={label}
      onChange={e => onChange(options.find(option => option.value === e.target.value))}
      options={options}
    />
  );
};

export default IncidenceTypeSelect;
