import {
  FETCH_SINGINGTYPES_SUCCEEDED,
  APPLY_SINGINGTYPES_FILTERS,
  RESET_SINGINGTYPES_FILTERS,
  SINGINGTYPES_CREATE,
  SINGINGTYPES_DELETE,
  SINGINGTYPES_RESTORE,
  SINGINGTYPES_EDIT
} from "../../types/RRHH/SigningType";

const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    description: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_SINGINGTYPES_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_SINGINGTYPES_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_SINGINGTYPES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case "SINGINGTYPES_DELETE": {
      return {
        // ...state,
        // data: state.data.map(type => {
        //     if (type.id === action.payload.id) return Object.assign(type, action.payload.data);
        //     return type;
        // }),
        ...state,
        data: state.data.map(signingtype => {
          if (signingtype.id === action.payload.id) {
            return Object.assign(signingtype, {active: !signingtype.active});
          }

          return signingtype;
        })
      };
    }

    case SINGINGTYPES_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case "SINGINGTYPES_EDIT": {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, action.payload.data);
          return type;
        })
      };
    }
    case "SINGINGTYPES_RESTORE": {
      return {
        // ...state,
        // data: state.data.map(type => {
        //     if (type.id === action.payload.id) return Object.assign(type, action.payload.data);
        //     return type;
        // }),
        ...state,
        data: state.data.map(signingtype => {
          if (signingtype.id === action.payload.id) {
            return Object.assign(signingtype, {active: !signingtype.active});
          }

          return signingtype;
        })
      };
    }
    default: {
      return state;
    }
  }
};
