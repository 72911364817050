import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {FormHelperText, Grid, TextField} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {StyledTitle} from "../AddressArea/styles";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import OutlinedDateTimePicker from "../../../../../../Common/Forms/OutlinedDateTimePicker";
import {dateIsBetweenTwoDates} from "../../../../../../../utils/dates";
import {useSelector} from "react-redux";

const AddresseeArea = ({formData, setFormData, errors = null, edit, isNew}) => {
    const {current} = useSelector(({administrator}) => administrator.workOrders);

    const isValidDate = (date) => {
        return date && dateIsBetweenTwoDates(date, current.startDate, current.endDate);
    }
    return (
        <GridContainer spacing={4}>
            <Grid item sm={12}>
                <StyledTitle variant="h2">
                    <IntlMessages id={"logistic.work.orders.dialog.destination.addressee.title"}/></StyledTitle>
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedInput
                    id="destination-name"
                    value={formData.name}
                    onChange={value => setFormData('name', value)}
                    disabled={!isNew}
                    fullWidth
                    hasError={errors?.name?.length > 0}
                    error={errors?.name[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.name.label"}/>}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedInput
                    id="destination-phone"
                    value={formData.phone}
                    onChange={value => setFormData('phone', value)}
                    disabled={!isNew}
                    fullWidth
                    hasError={errors?.phone?.length > 0}
                    error={errors?.phone[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.phone.label"}/>}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedDateTimePicker
                    id="start-time-picker"
                    fullWidth
                    label={<IntlMessages id={'logistic.work.orders.dialog.destination.start.time.label'}/>}
                    disabled={!edit}
                    value={formData.startTime}
                    onChange={value => setFormData('startTime', value)}
                    hasError={edit && !isValidDate(formData.startTime)}
                    error={(edit && !isValidDate(formData.startTime)) && <IntlMessages id="invalid.date" />}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedDateTimePicker
                    id="end-time-picker"
                    fullWidth
                    label={<IntlMessages id={'logistic.work.orders.dialog.destination.end.time.label'}/>}
                    disabled={!edit}
                    value={formData.endTime}
                    onChange={value => setFormData('endTime', value)}
                    hasError={edit && !isValidDate(formData.endTime)}
                    error={(edit && !isValidDate(formData.endTime)) && <IntlMessages id="invalid.date" />}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedInput
                    id="destination-alias"
                    value={formData.alias}
                    onChange={value => setFormData('alias', value)}
                    disabled={!isNew}
                    fullWidth
                    hasError={errors?.alias?.length > 0}
                    error={errors?.alias[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.alias.label"}/>}
                />
            </Grid>
            <Grid item sm={12}>
                <OutlinedInput
                    id="destination-email"
                    value={formData.email}
                    type="email"
                    onChange={value => setFormData('email', value)}
                    disabled={!isNew}
                    fullWidth
                    hasError={errors?.email?.length > 0}
                    error={errors?.email[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.email.label"}/>}
                />
            </Grid>
            <Grid item sm={12}>
                <FormControl error={errors?.comment?.length > 0}
                             variant="outlined"
                             size="small" fullWidth>
                    <TextField
                        variant="outlined"
                        value={formData.comment}
                        id="destination-comment"
                        rows={4}
                        multiline
                        label={<IntlMessages id={"logistic.work.orders.dialog.destination.comment.label"} />}
                        onChange={e => setFormData('comment', e.target.value)}
                        disabled={!edit}
                    />
                    <FormHelperText>{errors?.comment[0]}</FormHelperText>
                </FormControl>
            </Grid>
        </GridContainer>
    );
}

export default AddresseeArea;