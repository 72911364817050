import React, {useState} from "react";
import {Button, FormHelperText, Grid, OutlinedInput} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {
  cancelPrevFetchRucData,
  fetchRucData
} from "../../../../../../services/RucDataService";

const RucInput = ({
  ruc,
  setRuc,
  errors,
  isLoading,
  setRucData,
  clearRucData
}) => {
  const [ disabled, setDisabled ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);

  const retrieveRucData = () => {
    setErrorMessage(null);
    setDisabled(true);
    // cancelPrevFetchRucData();
    // fetchRucData(ruc).then(data => {
    //     setRucData(data);
    //     setDisabled(false);
    // }).catch((e) => setDisabled(false));
    fetchRucData(ruc)
      .then(data => {
        setRucData(data);
        setDisabled(false);
      })
      .catch(e => {
        clearRucData();
        setDisabled(false);
        setErrorMessage(e.message);
      });
  };
  return (
    <Grid item xs={12} sm={12}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl
            error={errors.ruc.length > 0 || errorMessage !== null}
            variant="outlined"
            size="small"
            fullWidth
          >
            <InputLabel htmlFor="ruc">
              <IntlMessages id={"guardian.add.expense.form.ruc"} />
            </InputLabel>
            <OutlinedInput
              value={ruc}
              id="ruc"
              label={<IntlMessages id={"guardian.add.expense.form.ruc"} />}
              onChange={e => setRuc(e.target.value)}
              disabled={isLoading}
            />
            {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
            <FormHelperText>{errors.ruc[0]}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            variant="contained"
            style={{
              marginRight: "16px",
              width: "100%"
            }}
            color="primary"
            disabled={ruc === "" || disabled}
            onClick={() => retrieveRucData()}
          >
            <IntlMessages id={"guardian.add.expense.form.ruc.button.verify"} />
          </Button>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={ruc === "" || disabled}
            style={{
              marginRight: "16px",
              width: "100%"
            }}
            onClick={() => {
              cancelPrevFetchRucData();
              setRuc("");
            }}
          >
            <IntlMessages id={"guardian.add.expense.form.ruc.button.clear"} />
          </Button>
        </Grid>
      </GridContainer>
    </Grid>
  );
};

export default RucInput;
