import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from 'components/Common/Forms/Select';
import selectEntity from '../../../redux/actions/Administrator/WarehouseEntitySelect';
import {getCompanyEntityId} from 'services/StorageService';

const EntityOriginSelect = ({id,label,hasError,error,value,onChange}) => {
    if(value?.value != undefined){value = value.value}
    const {data} = useSelector(state => state.administrator.warehouseEntity);
    
    const dispatch = useDispatch();
    useEffect(() => {
        if(data.length === 0){
            dispatch(selectEntity.get());
        }
        //setEntity(data.find(option => option.id === getCompanyEntityId()))
        //const origin = data.find(e => e.id === getCompanyEntityId());
        //console.log("***ORIGIN: ", origin)
    },[data, dispatch])

    const options = [
        {value: '', label: 'Seleccione una opción'},
        ...data.filter(type => type.active !== 0).map(type => ({value: type.id, label: type.entity})),
    ];

    //console.log("****Value: ", value);
    
    return(
        <Select
            value={value}
            error={hasError}
            errorMessage={error}
            fullWidth
            id={id}
            label={label}
            onChange={e => onChange(options.find(option => option.value === e.target.value))}
            options={options}
            disabled={true}
        />
    );
};

export default EntityOriginSelect