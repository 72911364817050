import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import clsx from "clsx";

import {Avatar, Box, Hidden, IconButton, withWidth} from "@material-ui/core";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {alpha, makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

import CmtDropdownMenu from "../../../../../../@coremat/CmtDropdownMenu";
import CmtAvatar from "../../../../../../@coremat/CmtAvatar";
import SidebarToggleHandler from "../../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler";
import LayoutContext from "../../../../../../@coremat/CmtLayouts/LayoutContext";

import {AuhMethods} from "../../../../../../services/auth";
import {CurrentAuthMethod} from "../../../../../constants/AppConstants";
import Logo from "../../../partials/Logo";
import ActionBarDrawer from "./ActionBarDrawer";
import LanguageSwitcher from "../../../partials/LanguageSwitcher";
import ChatDialog from "../../../../../../components/Logistic/Monitoring/ChatDialog";
import EmployeeFormDialog from "../../../../../../components/RRHH/Employees/EmployeeFormDialog";
import {onUpdateContact, setCurrentContact} from "../../../../../../redux/actions/RRHH/Employees";
import {getUserId} from "../../../../../../services/StorageService";
import moment from "moment";
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: "10px 24px 10px 15px",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      padding: "16px 5px"
    },
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  iconBtn: {
    position: "relative",
    color: alpha(theme.palette.common.white, 0.9),
    "&:hover, &:focus": {
      color: theme.palette.common.white
    }
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  langRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 15,
      paddingRight: 15
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15)
    }
  },
  companyLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 5,
    },
    "&:before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15)
    }
  }
}));

const actionsList = [
  //   {
  //     icon: <PersonIcon />,
  //     label: 'Account',
  //   },
  {
    icon: <ExitToAppIcon />,
    label: "Logout"
  },
  {
    icon: <PersonIcon />,
    label: "Edit Profile"
  },
];

let initSidebarWidth = 0;
const ActionSideBar = ({width}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ chatDialog, setChatDialog ] = React.useState(false);
  const [ isDrawerOpen, setDrawerStatus ] = useState(false);
  const [ activeOption, setActiveOption ] = useState(null);
  const {
    isSidebarOpen,
    setSidebarOpen,
    sidebarWidth,
    setSidebarWidth
  } = useContext(LayoutContext);
  const [ profileDialog, setProfileDialog ] = React.useState(false);
  const {selectedCompany} = useSelector(({auth}) => auth);

  useEffect(() => {
    initSidebarWidth = sidebarWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isSidebarOpen && (width === "lg" || width === "xl")) {
        setSidebarWidth(0);
      } else {
        setSidebarWidth(initSidebarWidth);
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [ isSidebarOpen, width ]
  );

  useEffect(
    () => {
      if (activeOption && !isDrawerOpen) {
        setDrawerStatus(true);
      }
      //eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [ activeOption ]
  );

  const onItemClick = item => {
    if (item.label === "Logout") {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
    } else if (item.label === "Edit Profile") {
      dispatch(
        setCurrentContact({
          id: getUserId()
        })
      );
      setProfileDialog(true);
    }
  };

  const onIconClick = option => {
    setActiveOption(option);
  };

  const onDrawerClose = () => {
    setDrawerStatus(false);
    setActiveOption(null);
  };

  return (
    <div className={clsx(classes.root, "actionSidebar")}>
      <div style={{display: "flex", alignItems: "center"}}>
        <SidebarToggleHandler className={classes.iconBtn}>
          {isSidebarOpen && (width === "lg" || width === "xl") && <CloseIcon />}
        </SidebarToggleHandler>
        <Hidden lgUp>
          <Logo color="white" ml={{xs: 3, sm: 6}} />
        </Hidden>
      </div>
      <Box
        display="flex"
        flexDirection={{xs: "row", lg: "column"}}
        ml={{xs: "auto", lg: "unset"}}
      >
        <IconButton
          className={classes.iconBtn}
          onClick={() => setChatDialog(true)}
        >
          <MessageIcon />
        </IconButton>
        {/* <IconButton className={classes.iconBtn} onClick={() => onIconClick('search')}>
          <SearchIcon />
        </IconButton>

        <IconButton className={classes.iconBtn} onClick={() => onIconClick('messages')}>
          <MessageIcon />
        </IconButton>

        <IconButton className={classes.iconBtn} onClick={() => onIconClick('notifications')}>
          <Badge badgeContent={4} classes={{ badge: classes.counterRoot }}>
            <NotificationsIcon />
          </Badge>
        </IconButton>

        {isSidebarOpen && (width === 'lg' || width === 'xl') && (
          <IconButton className={classes.iconBtn} onClick={() => setSidebarOpen(!isSidebarOpen)}>
            <MoreVertIcon />
          </IconButton>
        )}
       */}
        {/*<Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')}>*/}
        {/*  <LanguageSwitcher />*/}
        {/*</Box>*/}
        <Box className={classes.companyLogo}>
          <Avatar src={`https://cdn.4gflota.com/iconos-panel-control/${selectedCompany?.id || 0}.jpg`}>
            <BusinessIcon />
          </Avatar>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{xs: "row", lg: "column"}}
        mt={{xs: "unset", lg: "auto"}}
      >
        {/* <IconButton className={classes.iconBtn} onClick={() => onIconClick('settings')}>
          <SettingsIcon />
        </IconButton> */}

        <CmtDropdownMenu
          onItemClick={onItemClick}
          TriggerComponent={
            <CmtAvatar src={"https://via.placeholder.com/150"} />
          }
          items={[
              {
                icon:  <Box
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <LanguageSwitcher />
                </Box>,
                // label: ""
              },
              ...actionsList,
            // {
            //   icon: <Avatar src={`https://cdn.4gflota.com/iconos-panel-control/${selectedCompany?.id || 0}.jpg`}>
            //     <BusinessIcon />
            //   </Avatar>,
            //   label: selectedCompany?.name || ""
            // }
          ]}
        />
      </Box>

      <ActionBarDrawer
        activeOption={activeOption}
        open={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        onIconClick={onIconClick}
      />

      <ChatDialog open={chatDialog} setOpen={setChatDialog} />
      {profileDialog && (
        <EmployeeFormDialog
          open={profileDialog}
          handleDialog={() => {
            dispatch(setCurrentContact(null));
            setProfileDialog(false);
          }}
          onSave={(currentContact, generalData, specificData) => {
            dispatch(
              onUpdateContact({
                ...currentContact,
                ...generalData,
                ...specificData,
                commencementDate: moment(specificData.commencementDate).format(
                  "DD/MM/YYYY"
                ),
                terminationDate: moment(specificData.terminationDate).format(
                  "DD/MM/YYYY"
                ),
                birthDate: moment(generalData.birthDate).format("DD/MM/YYYY")
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default withWidth()(ActionSideBar);
