import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import {
  applyWorkshopFilters,
  resetWorkshopFilters
} from "../../../../../../redux/actions/FleetManagement/Workshop";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";

const Filters = () => {
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.workshop
  );
  const {formData, setFormData, resetForm} = useForm(filters);
  const dispatch = useDispatch();
  const onApplyFilters = () => {
    dispatch(applyWorkshopFilters(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetWorkshopFilters());
  };
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"workshop-plaque"}
              fullWidth
              value={formData.plaque}
              onChange={value => setFormData("plaque", value)}
              label={
                <IntlMessages id={"fleet.management.workshop.table.plaque"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedDatePicker
              id={"workshop-entry"}
              fullWidth
              value={formData.entry}
              onChange={value => setFormData("entry", value)}
              label={
                <IntlMessages id={"fleet.management.workshop.table.entry"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedDatePicker
              id={"workshop-exit"}
              fullWidth
              value={formData.exit}
              onChange={value => setFormData("exit", value)}
              label={
                <IntlMessages id={"fleet.management.workshop.table.exit"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"workshop-reason"}
              fullWidth
              value={formData.reason}
              onChange={value => setFormData("reason", value)}
              label={
                <IntlMessages id={"fleet.management.workshop.table.reason"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"workshop-workshop"}
              fullWidth
              value={formData.workshop}
              onChange={value => setFormData("workshop", value)}
              label={
                <IntlMessages id={"fleet.management.workshop.table.workshop"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"workshop-amount"}
              fullWidth
              type="number"
              value={formData.amount}
              onChange={value => setFormData("amount", value)}
              label={
                <IntlMessages id={"fleet.management.workshop.table.amount"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onResetFilters();
              }}
              text={<IntlMessages id={"filters.button.clear"} />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onApplyFilters();
              }}
              color="primary"
              text={<IntlMessages id={"filters.button.apply"} />}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
