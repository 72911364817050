class ElevatorResponsibleList {
  constructor(analyst, supervisor, technical) {
    this.analyst = analyst;
    this.supervisor = supervisor;
    this.technical = technical;
  }

  static map = elevator => {
    return new ElevatorResponsibleList(
      elevator.nu_id_entidad_analista,
      elevator.nu_id_entidad_supervisor,
      elevator.nu_id_entidad_tecnico
    );
  };
}

export default ElevatorResponsibleList;
