class Receiver {
  constructor(id, entityId, names, tags, code, active) {
    this.id = id;
    this.entityId = entityId;
    this.names = names;
    this.tags = tags;
    this.code = code;
    this.active = active;
  }
  static map = receiver => {
    return new Receiver(
      receiver.nu_id_entidad_receptor,
      receiver.nu_id_entidad,
      receiver.vc_nombre_entidad_receptor,
      receiver.vc_desc_tag.split(","),
      receiver.vc_clave,
      receiver.bi_estado === "1"
    );
  };
  static editMap = receiver => {
    return new Receiver(
      receiver.nu_id_entidad_receptor,
      receiver.nu_id_entidad,
      receiver.vc_nombre_entidad_receptor,
      receiver.ls_tag.map(tag => ({
        id: tag.nu_id_tag.toString(),
        name: tag.vc_desc_tag
      })),
      receiver.vc_clave,
      receiver.bi_estado === "1"
    );
  };
}
export default Receiver;
