import React from "react";
import {Box, LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const LinearProgressWithLabel = ({
  value,
  text = null,
  fullBoxStyles = null
}) => {
  return (
    <Box display="flex" flexDirection="column" style={fullBoxStyles}>
      {text && (
        <Typography
          variant="body2"
          color={value < 60 ? "secondary" : "primary"}
        >
          {text}
        </Typography>
      )}
      <Box display="flex" alignItems="center" justifyItems="flex-end">
        <Box width="100%" mr={1}>
          <LinearProgress
            variant="determinate"
            value={value}
            color={value < 60 ? "secondary" : "primary"}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default LinearProgressWithLabel;
