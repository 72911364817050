import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {getAllElevatorsContracts} from "../../../../../../../services/ElevatorsManagement/ContractsService";

const ContractTypeSelect = ({id, value, setValue, disabled}) => {
  const [ types, setTypes ] = React.useState([]);
  React.useEffect(() => {
    getAllElevatorsContracts({})
      .then(data => setTypes(data))
      .catch(e => setTypes([]));
  }, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.contract.type"
          }
        />
      }
      fullWidth
    >
      {types.map(type => (
        <MenuItem value={type.id} key={type.id}>
          {type.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ContractTypeSelect;
