import React from 'react';
import {useMap} from "react-leaflet";

const MapUseContainer = ({longitude, latitude}) => {
    const map = useMap();
    React.useEffect(() => {
        map.setView([latitude, longitude]);
    }, [longitude, latitude])
    return null;
}

export default MapUseContainer;