import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const TypeSelect = ({id, value, setValue, disabled}) => {
  const [ types, setTypes ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setTypes([
      {
        id: 1,
        label: "En Proceso"
      },
      {
        id: 2,
        label: "Final"
      }
    ]);
  }, []);
  return (
    <Select
      id={id}
      label={
        <IntlMessages
          id={"logistic.distribution.work.orders.types.detail.state.type"}
        />
      }
      disabled={disabled}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {types.map(t => (
        <MenuItem key={`type-${t.id}`} value={t.id}>
          {t.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TypeSelect;
