import React from "react";
import {Grid} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";

const EstimatedTimeTable = ({estimatedTime, onClickDidTimes}) => {
  return (
    <GridContainer style={{marginBottom: "4px"}}>
      <Grid item xs={12}>
        <h3>
          <IntlMessages id="administrator.ot.breakdown.detail.estimated.historic" />
        </h3>
      </Grid>
      <Grid item xs={12}>
        <p>
          <IntlMessages id="administrator.ot.breakdown.detail.estimated.did.times" />:{" "}
          <Typography
              color='primary'
              component='span'
              style={{
                cursor: 'pointer',
                textDecorationLine: 'underline',
              }}
              onClick={onClickDidTimes}
          >
            {estimatedTime.didTimes}
          </Typography>
        </p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.average.time" />:{" "}
              {estimatedTime.average}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.client.time" />:{" "}
              {estimatedTime.clientTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.google.time" />:{" "}
              {estimatedTime.googleTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.central.time" />:{" "}
              {estimatedTime.centralTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.traveled.kilometers" />:{" "}
              {estimatedTime.traveledKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.client.kilometers" />:{" "}
              {estimatedTime.clientKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.google.kilometers" />:{" "}
              {estimatedTime.googleKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.detail.estimated.central.kilometers" />:{" "}
              {estimatedTime.centralKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default EstimatedTimeTable;
