import React, {useEffect} from 'react';
import Select from "../../../../Common/Forms/Select";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllDashboards} from "../../../../../redux/thunks/ControlPanel/dashboards";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {ListSubheader} from "@material-ui/core";
import {setCurrentDashboard} from "../../../../../redux/reducers/ControlPanel/dashboards";

const PanelSelect = () => {
    const {dashboards, currentDashboard, filters} = useSelector(({controlPanel}) => controlPanel.dashboards);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchAllDashboards());
    }, []);
    const handleChange = ({target}) => {
        const {value} = target;
        if(value) {
            let dashboard = dashboards?.customDashboards?.find(dashboard => dashboard.id === value);
            if(!dashboard) {
                dashboard = dashboards?.defaultDashboards?.find(dashboard => dashboard.id === value);
                dispatch(setCurrentDashboard(dashboard));
            }else {
                dispatch(setCurrentDashboard(dashboard));
            }
        }
    }
    return (
        <Select
            value={currentDashboard?.id || ''}
            onChange={handleChange}
            label={<IntlMessages id='panel' />}
            style={{
                width: '20rem',
                maxWidth: '100%'
            }}
        >
            {dashboards?.defaultDashboards?.map(dashboard => (
                <MenuItem key={dashboard.id} value={dashboard.id}>
                    {dashboard.description}
                </MenuItem>
            ))}
            <ListSubheader>
                <IntlMessages id='personalized' />
            </ListSubheader>
            {dashboards?.customDashboards?.map(dashboard => (
                <MenuItem key={dashboard.id} value={dashboard.id}>
                    {dashboard.description}
                </MenuItem>
            ))}
        </Select>
    );
};

export default PanelSelect;