import moment from "moment";
import {
  APPLY_SERVICE_API_FILTERS,
  APPLY_SERVICES_FILTERS,
  EDIT_SERVICE_TABLE_HEADERS,
  FETCH_ALL_SERVICES,
  FETCH_SERVICE_TABLE_HEADERS,
  RESET_SERVICE_API_FILTERS,
  RESET_SERVICES_FILTERS,
  RESET_SERVICES_STATE,
  SET_SERVICES_LAST_UPDATE
} from "../../types/Logistic/ServicesListTypes";

// #878787 => pendiente
// #b0ae2c => en ruta
// #f5a623 => en camino
const today = moment(Date.now()).format("yyyy-MM-DD HH:mm:ss");

const INIT_STATE = {
  data: {},
  apiFilters: {
    date: today
  },
  filters: {
    hoppers: [],
    trailers: [],
    clients: [],
    states: [],
    saleOrganizing: [],
    drivers: [],
    serviceOrder: ""
  },
  tableHeaders: {},
  lastUpdate: Date.now()
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SERVICES: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_SERVICES_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_SERVICES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case RESET_SERVICE_API_FILTERS: {
      return {
        ...state,
        apiFilters: {
          ...INIT_STATE.apiFilters
        }
      };
    }
    case APPLY_SERVICE_API_FILTERS: {
      return {
        ...state,
        apiFilters: action.payload
      };
    }
    case FETCH_SERVICE_TABLE_HEADERS: {
      let list = {};
      action.payload.map((u, index) => {
        list[index] = u;
        return null;
      });
      return {
        ...state,
        tableHeaders: list
      };
    }

    case EDIT_SERVICE_TABLE_HEADERS: {
      let list = {};
      action.payload.map((u, index) => {
        list[index] = u;
        return null;
      });
      return {
        ...state,
        tableHeaders: list
      };
    }

    case SET_SERVICES_LAST_UPDATE: {
      return {
        ...state,
        lastUpdate: Date.now()
      };
    }

    case RESET_SERVICES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }

    default: {
      return state;
    }
  }
};
