import React from "react";

import PageContainer from "../../../../components/ControlPanel/Reports/PageContainer";
import KpiReportsList from "../../../../components/ControlPanel/Reports/KpiReportsList";
import {useDispatch, useSelector} from "react-redux";
import ReportsContainer from "../../../../components/ControlPanel/Reports/ReportsContainer";
import {resetKpiState} from "../../../../redux/actions/ControlPanel/Kpi";

const Reports = () => {
  const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetKpiState());
    };
  }, []);
  return (
    <PageContainer>
      {current.id === null ? <KpiReportsList /> : <ReportsContainer />}
    </PageContainer>
  );
};

export default Reports;
