export const gravityColors = Object.freeze({
    serious: '#b60a1c',
    moderate: '#f0bd27',
    light: '#309143'
});

export const eventsTypeColors = Object.freeze({
    acceleration: '#4A148C',
    braking: '#0D47A1',
    sharpTurn: '#FF6F00'
});