import axiosInstance from "../../../utils/axiosInstance";

export const fetchAllOrganizations = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/BackOffice/sel_empresas'
        );
        return data.map(e => ({
            id: e.nu_id_entidad,
            name: e.vc_nombre_entidad,
        }))
    }catch (e) {
        return Promise.reject(e);
    }
};


export const fetchAllLicenses = async ({organizationId}) => {
    if(!organizationId) {
        return [];
    }
    try {
        const {data} = await axiosInstance.post(
            '/BackOffice/sel_licencias',
            {
                nu_id_entidad: organizationId
            }
        );
        return data.map(e => ({
            id: e.nu_id_licencia,
            name: e.vc_nombre_licencia,
        }))
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllDataOptions = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/BackOffice/sel_datos',
        );
        return data.map(e => ({
            id: e.nu_id,
            name: e.vc_dato,
        }))
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllHistoryData = async filters => {
    try {
        const {data} = await axiosInstance.post(
            '/BackOffice/sel',
            {
                nu_id_entidad: filters.organization,
                nu_id_licencia: filters.license,
                dt_fec_inicio: filters.startDate,
                dt_fec_fin: filters.endDate,
                ls_datos: filters.dataOptions.map(option => option.id)
            }
        );
        return data.map(({entityId, assetId, driverId, objectId, localTime, ...rest}) => ({
            id: entityId,
            asset: assetId,
            driver: driverId,
            objectId,
            localTime,
            extraData: {...rest}
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}