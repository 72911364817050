import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  ADD_ELEVATOR_DETAIL,
  APPLY_ELEVATORS_DETAILS_FILTERS,
  FETCH_ALL_ELEVATORS_DETAIL,
  RESET_CURRENT_ELEVATOR_DETAIL,
  RESET_ELEVATORS_DETAILS_FILTERS,
  RESET_ELEVATORS_DETAILS_STATE,
  SET_CURRENT_ELEVATOR_DETAIL
} from "../../types/ElevatorsManagement/DetailsTypes";
import {
  createElevatorDetail,
  getAllElevatorsDetails,
  updateElevatorDetail,
  updateElevatorDetailState
} from "../../../services/ElevatorsManagement/DetailsService";
import ElevatorDetail from "../../../domain/ElevatorsManagement/ElevatorDetail";

export const resetElevatorsDetailsState = () => ({
  type: RESET_ELEVATORS_DETAILS_STATE
});
export const applyElevatorsDetailsFilters = filters => ({
  type: APPLY_ELEVATORS_DETAILS_FILTERS,
  payload: filters
});
export const resetElevatorsDetailsFilters = () => ({
  type: RESET_ELEVATORS_DETAILS_FILTERS
});
export const resetCurrentElevatorDetail = () => ({
  type: RESET_CURRENT_ELEVATOR_DETAIL
});
export const setCurrentElevatorDetail = current => ({
  type: SET_CURRENT_ELEVATOR_DETAIL,
  payload: current
});
export const fetchAllElevatorsDetails = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllElevatorsDetails(filters)
      .then(details => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_ELEVATORS_DETAIL, payload: details});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const editElevatorDetail = (current, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorDetail(current, data.name)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.name = data.name;
        dispatch({type: ADD_ELEVATOR_DETAIL, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editElevatorDetailState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorDetailState(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.state = state;
        dispatch({type: ADD_ELEVATOR_DETAIL, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addElevatorDetail = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createElevatorDetail(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newDetail = new ElevatorDetail(
          response.transactionKey,
          data.name,
          true
        );
        dispatch({type: ADD_ELEVATOR_DETAIL, payload: newDetail});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
