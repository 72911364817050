import {useState} from "react";

const createErrorsObject = defaultData => {
  const obj = {};
  Object.keys(defaultData).forEach(k => (obj[k] = []));
  return obj;
};

const useForm = (defaultData, validators = {}) => {
  const [ formData, setData ] = useState(defaultData);
  const [ errors, setErrors ] = useState(createErrorsObject(defaultData));

  const validate = (field, value) => {
    if (!(field in formData && field in validators)) return true;
    const fieldErrors = validators[field].reduce((errorArray, validator) => {
      if (!validator.rule(value)) {
        return [ ...errorArray, validator.message ];
      }
      return [ ...errorArray ];
    }, []);
    setError(field, fieldErrors);
    return fieldErrors.length === 0;
  };

  const validateAll = () => {
    let isValid = true;
    Object.entries(formData).forEach(([ field, value ]) => {
      if (!validate(field, value)) isValid = false;
    });
    return isValid;
  };

  const resetForm = () => {
    setData(defaultData);
    setErrors(createErrorsObject(defaultData));
  };

  const setFormData = (field, value) => {
    if (field in formData) {
      setData(prev => ({
        ...prev,
        [field]: value
      }));
      validate(field, value);
    }
  };

  const setValues = values => {
    const newFormData = {...formData};
    Object.keys(values).forEach(key => {
      if (key in newFormData) {
        newFormData[key] = values[key];
      }
    });
    setData(newFormData);
  };

  const setError = (field, errorArray) => {
    if (field in errors) {
      setErrors(prev => ({
        ...prev,
        [field]: errorArray
      }));
    }
  };

  const handleSubmit = onSubmit => event => {
    event.preventDefault();
    event.stopPropagation();
    const isValid = validateAll();
    if (isValid) {
      onSubmit(formData);
    }
  };

  return {
    formData,
    errors,
    handleSubmit,
    setFormData,
    resetForm,
    setValues,
    validateAll
  };
};

export default useForm;
