class DataCollectionHistory {
  constructor(
    id,
    idVehicle,
    vehicle,
    idRoute,
    route,
    idTurn,
    turn,
    initDate,
    endDate,
    disinfection,
    firstPickup,
    lastPickup,
    passengers,
    active
  ) {
    this.id = id;
    this.idVehicle = idVehicle;
    this.vehicle = vehicle;
    this.idRoute = idRoute;
    this.route = route;
    this.idTurn = idTurn;
    this.turn = turn;
    this.initDate = initDate;
    this.endDate = endDate;
    this.disinfection = disinfection;
    this.firstPickup = firstPickup;
    this.lastPickup = lastPickup;
    this.passengers = passengers;
    this.active = active;
  }

  static map = dataCollectionHistory => {
    return new DataCollectionHistory(
      dataCollectionHistory.nu_id_viaje,
      dataCollectionHistory.nu_id_vehiculo,
      dataCollectionHistory.vc_placa,
      dataCollectionHistory.nu_id_ruta,
      dataCollectionHistory.vc_desc_ruta,
      dataCollectionHistory.nu_id_turno,
      dataCollectionHistory.vc_desc_turno,
      dataCollectionHistory.dt_fec_ini_real,
      dataCollectionHistory.dt_fec_fin_real,
      dataCollectionHistory.dt_fec_ini_desifeccion,
      dataCollectionHistory.dt_fec_ini_recogida,
      dataCollectionHistory.dt_fec_fin_recogida,
      dataCollectionHistory.nu_id_pasajeros,
      dataCollectionHistory.bi_estado === "1"
    );
  };
}

export default DataCollectionHistory;
