export const RESET_VEHICLE_REPORTS_STATE = "RESET_VEHICLE_REPORTS_STATE";
export const FETCH_ALL_VEHICLE_REPORTS = "FETCH_ALL_VEHICLE_REPORTS";
export const APPLY_VEHICLE_REPORTS_FILTERS = "APPLY_VEHICLE_REPORTS_FILTERS";
export const RESET_VEHICLE_REPORTS_FILTERS = "RESET_VEHICLE_REPORTS_FILTERS";
export const SET_CURRENT_VEHICLE_REPORTS_TYPE =
  "SET_CURRENT_VEHICLE_REPORTS_TYPE";
export const RESET_CURRENT_VEHICLE_REPORTS_TYPE =
  "RESET_CURRENT_VEHICLE_REPORTS_TYPE";
export const ADD_VEHICLE_REPORTS_TYPE = "ADD_VEHICLE_REPORTS_TYPE";
export const EDIT_VEHICLE_REPORTS_TYPE = "EDIT_VEHICLE_REPORTS_TYPE";
export const OPEN_VEHICLE_REPORTS_DIALOG = "OPEN_VEHICLE_REPORTS_DIALOG";
export const CLOSE_VEHICLE_REPORTS_DIALOG = "CLOSE_VEHICLE_REPORTS_DIALOG";
