import React, {useEffect, useCallback} from "react";
import {connect, useSelector} from "react-redux";

import PageContainer from "components/Administrator/OrdersHistory/PageContainer";
import Filters from "components/Administrator/OrdersHistory/Filters";
import Box from "@material-ui/core/Box";
import Table from "components/Administrator/OrdersHistory/Table";

import useUiState from "hooks/Administrator/useUiState";

import orderHistory from "redux/actions/Administrator/OrderHistory";
import FilterBuilder from "../../../../utils/filters";

const OrdersHistory = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(orderHistory.get());
      return () => dispatch(orderHistory.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(orderHistory.applyOrdersHistoryFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(orderHistory.resetOrdersHistoryFilters());

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.orderHistory;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("client", filters.client)
    .byFieldIncludes("state", filters.state)
    .byFieldIncludes("ot", filters.ot)
    .byFieldBetweenDates("date", filters.fromDate, filters.toDate)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(OrdersHistory);
