import React from "react";
import {useDispatch} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {addWorkshopVehicle} from "../../../../../redux/actions/FleetManagement/Workshop";
import Form from "./Form";

const initData = {
  vehicle: "",
  workshop: "",
  kilometer: "",
  ot: "",
  entryDate: "",
  state: "",
  expectedExitDate: "",
  exitDate: "",
  amount: "",
  observations: "",
  tasks: [],
  files: []
};

const AddWorkshopVehicleDialog = ({open, setOpen}) => {
  const id = "add-workshop-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addWorkshopVehicle(data, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.workshop.add.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.workshop.add.save"} />}
      cancelText={<IntlMessages id={"fleet.management.workshop.add.cancel"} />}
      fullScreen
    >
      <Form id={id} onSubmit={onSubmit} initialData={initData} />
    </FormDialog>
  );
};
export default AddWorkshopVehicleDialog;
