import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  cashManager: [
    {
      rule: v => v !== null,
      message: (
        <IntlMessages id="administrator.form.cashManagersAutocomplete.error.empty" />
      )
    }
  ],
  pettyCashType: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="administrator.form.pettyCashTypeSelect.error.empty" />
      )
    }
  ],
  amount: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.cashManagers.createCashManagerDialog.field.amount.error.empty" />
      )
    }
  ],
  currency: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.cashManagers.createCashManagerDialog.field.currency.error.empty" />
      )
    }
  ],
  proofOfAcceptance: [
    {
      rule: v => v.length === 1,
      message: (
        <IntlMessages id="administrator.cashManagers.createCashManagerDialog.field.proofOfAcceptance.error.invalidLength" />
      )
    }
  ],
  tags: [
    {
      rule: v => v.length > 0,
      message: <IntlMessages id="administrator.form.tagsPicker.error.empty" />
    }
  ],
  costCenter: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
};
