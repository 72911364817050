import moment from "moment";

class AddExpensesFiltersBuilder {
  constructor(expenses) {
    this.expenses = [ ...expenses ];
  }

  byFieldIncludes(field, value) {
    if (value === undefined || value === null)
      return new AddExpensesFiltersBuilder(this.expenses);
    const filteredExpenses = this.expenses.filter(expense => {
      if (expense[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      return expense[field]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    });
    return new AddExpensesFiltersBuilder(filteredExpenses);
  }

  byIncludeDates(lowerDate, upperDate) {
    if (!lowerDate && !upperDate) {
      return new AddExpensesFiltersBuilder([ ...this.expenses ]);
    }
    const filteredExpenses = this.expenses.filter(expense => {
      const dateFormat = moment(expense.date, "DD-MM-YYYY").format();
      if (lowerDate && upperDate) {
        return moment(dateFormat).isBetween(lowerDate, moment(upperDate).add(1, 'day'));
      } else if (lowerDate) {
        return moment(dateFormat).isAfter(lowerDate);
      }
      return moment(dateFormat).isBefore(upperDate);
    });
    return new AddExpensesFiltersBuilder(filteredExpenses);
  }

  byFieldEquals(field, value) {
    if (value === undefined || value === null)
      return new AddExpensesFiltersBuilder(this.expenses);
    const filteredData = this.expenses.filter(e => {
      if (e[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      if (value.toString() === "") {
        return true;
      }
      const comparativeValue =
        e[field].id === undefined ? e[field] : e[field].id;
      return (
        comparativeValue.toString().toLowerCase() ===
        value.toString().toLowerCase()
      );
    });
    return new AddExpensesFiltersBuilder(filteredData);
  }

  build() {
    return this.expenses;
  }
}

export default AddExpensesFiltersBuilder;
