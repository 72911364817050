import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  description: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="logistic.incidenceTypes.saveIncidenceTypeDialog.field.description.error.empty" />
      )
    }
  ]
};
