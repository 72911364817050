import {
    CLOSE_REFUNDS_DIALOG,
    FETCH_REFUNDS, OPEN_REFUNDS_DIALOG,
    RESET_REFUNDS_FILTERS,
    RESET_REFUNDS_STATE, SET_REFUNDS_FILTERS,
    SET_SELECTED_REFUNDS
} from "../../types/Guardian/RefundTypes";

const INIT_STATE = {
    data: [],
    filters: {
        accountingNumber: "",
        lowerDate: "",
        upperDate: "",
        quantity: "",
        pettyCashAmount: ""
    },
    dialogs: [],
    selectedRefunds: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_REFUNDS: {
            return {
                ...state,
                data: [...action.payload],
            }
        }
        case SET_SELECTED_REFUNDS: {
            return {
                ...state,
                selectedRefunds: action.payload,
            }
        }
        case RESET_REFUNDS_FILTERS: {
            return {
                ...state,
                filters: {
                    ...INIT_STATE.filters
                }
            }
        }
        case SET_REFUNDS_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload
                }
            }
        }
        case OPEN_REFUNDS_DIALOG: {
            return {
                ...state,
                dialogs: [
                    ...state.dialogs,
                    action.payload,
                ]
            }
        }
        case CLOSE_REFUNDS_DIALOG: {
            return {
                ...state,
                dialogs: state.dialogs.filter(d => d !== action.payload)
            }
        }
        case RESET_REFUNDS_STATE: {
            return {
                ...state,
                ...INIT_STATE,
            }
        }
        default:
            return state;
    }
}