import React from "react";
import {FormControl, TextField} from "@material-ui/core";

const OutlinedTimePicker = ({
  hasError,
  error,
  fullWidth,
  id,
  label,
  onChange,
  ...rest
}) => (
  <FormControl
    error={hasError}
    variant="outlined"
    size="small"
    fullWidth={fullWidth}
  >
    <TextField
      id={id}
      InputLabelProps={{shrink: true}}
      variant="outlined"
      fullWidth={fullWidth}
      type="time"
      error={hasError}
      helperText={error}
      label={label}
      onChange={e => onChange(e.target.value)}
      size="small"
      {...rest}
    />
  </FormControl>
);

export default OutlinedTimePicker;
