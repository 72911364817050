import React, {useEffect} from "react";
import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import useForm from "../../../../hooks/Common/useForm";
import StatesPicker from "./StatesPicker";

const initState = {
  accountingNumber: "",
  lowerDate: "",
  upperDate: "",
  quantity: "",
  state: [],
  pettyCashAmount: "",
  tag: ''
};

const AccountingFilters = ({applyFilters, onClose}) => {
  const {formData, setFormData, resetForm} = useForm(initState);
  useEffect(
    () => {
      applyFilters(formData);
    },
    [ applyFilters, formData ]
  );

  const handleClose = () => {
    resetForm();
    onClose();
  };
  return (
    <FiltersContainer
      active={true}
      onReset={handleClose}
      marginBottom={5}
      onClose={handleClose}
    >
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.id}
              id="accountingNumber"
              label={<IntlMessages id="guardian.accounting.table.id.filter" />}
              onChange={value => setFormData("accountingNumber", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              id="lowerDate"
              label={
                <IntlMessages id="guardian.accounting.table.lower.date.filter" />
              }
              value={formData.lowerDate}
              onChange={value => setFormData("lowerDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              id="upperDate"
              label={
                <IntlMessages id="guardian.accounting.table.upper.date.filter" />
              }
              value={formData.upperDate}
              onChange={value => setFormData("upperDate", value)}
            />
          </Grid>
          {(
            <Grid item xs={12} sm={4} lg={3}>
              <StatesPicker
                onChange={state => setFormData("state", state)}
                values={formData.state}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.quantity}
              id="quantity"
              label={
                <IntlMessages id="guardian.accounting.table.quantity.filter" />
              }
              onChange={value => setFormData("quantity", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
                fullWidth
                value={formData.tag}
                id="guardian-accounting-tag-filter"
                label={
                  <IntlMessages id="tag" />
                }
                onChange={value => setFormData("tag", value)}
            />
          </Grid>     <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
                fullWidth
                value={formData.pettyCashAmount}
                id="guardian-accounting-petty-cash-amount-filter"
                label={
                  <IntlMessages id="petty.cash.amount" />
                }
                onChange={value => setFormData("pettyCashAmount", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default AccountingFilters;
