import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  SelectedStyledTableRow,
  StyledTableCell,
  DefaultStyledTableRow,
  ConditionalButton
} from "./styles";

import {useDispatch, useSelector} from "react-redux";
import {clearCurrentAdvance} from "../../../../../redux/actions/Guardians/CurrentAdvance";
import CheckStockBodyReps from "./CheckStockBodyReps";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const CheckStockBodyUniques = ({
  data,
  driver,
  setSelectedAdvance,
  isGuardian = true
}) => {
  var count = 0;
  const rows = 6;
  const [ open, setOpen ] = React.useState(rows <= 1);
  const classes = useRowStyles();
  const {currentAdvance} = useSelector(state => state.guardian);
  const dispatch = useDispatch();
  const repetidos = [];
  for (const i in data) {
    if (data[i][1] === driver[1]) {
      count = count + 1;
      if (data[i][0] != driver[0]) {
        repetidos.push(data[i]);
      }
    }
  }
  const selectHandler = advance => {
    if (rows > 1 && !open) {
      setOpen(true);
    } else {
      setSelectedAdvance({driver: driver, ...advance});
    }
  };

  const openHandler = () => {
    if (
      open &&
      driver.filter(advance => advance.id === currentAdvance.id).length > 0
    ) {
      dispatch(clearCurrentAdvance());
    }
    setOpen(!open);
  };
  return (
    <React.Fragment>
      <SelectedStyledTableRow className={classes.root}>
        <StyledTableCell>
          <ConditionalButton
            disabled={rows <= 1}
            aria-label="expand row"
            size="small"
            onClick={() => openHandler()}
          >
            {open ? <RemoveIcon /> : <AddIcon />}
          </ConditionalButton>
        </StyledTableCell>

        <StyledTableCell onClick={() => selectHandler(driver)} align="left">
          <React.Fragment>{driver[1]}</React.Fragment>
        </StyledTableCell>
        <StyledTableCell
          onClick={() => selectHandler(driver)}
          component="th"
          scope="row"
        >
          {!open ? count > 1 ? "Varias entidades" : driver[2] : driver[2]}
        </StyledTableCell>

        <StyledTableCell onClick={() => selectHandler(driver)} align="left">
          {!open ? count > 1 ? "Varias sedes" : driver[3] : driver[3]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(driver)} align="left">
          {!open ? count > 1 ? "Varios almacenes " : driver[4] : driver[4]}
        </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(driver)} align="left">
          {!open ? count > 1 ? "varios viajes " : driver[5] : driver[5]}
        </StyledTableCell>
      </SelectedStyledTableRow>
      {open &&
        repetidos.map(element => (
          <SelectedStyledTableRow className={classes.root}>
            <CheckStockBodyReps order="Product" reps={element} />
          </SelectedStyledTableRow>
        ))}
    </React.Fragment>
  );
};

export default CheckStockBodyUniques;
