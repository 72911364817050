import {createSlice} from "@reduxjs/toolkit";
import {
    activateSelectedServices,
    getAllServicesByDate,
    removeSelectedServices,
    updateSelectedServices
} from "../../thunks/Logistic/planner";

const INIT_DATA = {
    filters: {
        searchDate: ''
    },
    data: [],
    dialogs: [],
    selectedServices: [],
    servicesList: [],
}

const plannerSlice = createSlice({
    name: 'planner',
    initialState: {
        ...INIT_DATA
    },
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ]
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setFilters: (state, action) => {
            state.filters = {
                ...state.filters,
                ...action.payload
            }
        },
        resetFilters: (state, action) => {
            state.filters = {
                searchDate: ''
            }
        },
        setSelectedServices: (state, action) => {
            state.selectedServices = action.payload;
            state.servicesList = state.data?.filter(service =>
                state.selectedServices.some(s => s === service.id))?.map(service => `${service.id}-${service.clientCode}`);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllServicesByDate.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.filters = action.payload.filters;
            state.selectedServices = [];
        });
        builder.addCase(activateSelectedServices.fulfilled, (state, action) => {
            state.selectedServices = [];
        });
        builder.addCase(removeSelectedServices.fulfilled, (state, action) => {
            state.selectedServices = [];
        });
        builder.addCase(updateSelectedServices.fulfilled, (state, action) => {
            state.selectedServices = [];
        });
    }
});

export default plannerSlice.reducer;

export const { openDialog, closeDialog, setFilters, resetFilters, setSelectedServices } = plannerSlice.actions;