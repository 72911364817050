import React from 'react';
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import {useDispatch, useSelector} from "react-redux";
import {
    closeBreakdownDialog,
    setCurrentBreakdownDestination
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import {dialogsList} from "../../constants";
import Form from "./Form";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import HistoryTable from "./HistoryTable";

const EditDialog = () => {
    const id = 'edit-breakdown-dialog';
    const {dialogs, currentDestination} = useSelector(
        state => state.administrator.breakdown
    );
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeBreakdownDialog(dialogsList.matrixData));
        dispatch(setCurrentBreakdownDestination(null));
    }
    const open = dialogs.some(d => d === dialogsList.matrixData);
    return (
        <FormDialog
            id={id}
            title={<IntlMessages id='central.data' />}
            onClose={onClose}
            isOpen={open}
        >
            {(open && currentDestination) && (
                <>
                    <Form id={id} onClose={onClose}/>
                    <HistoryTable/>
                </>
            )}

        </FormDialog>
    );
};

export default EditDialog;