import {
  ADD_INFRACTION,
  FETCH_ALL_INFRACTIONS,
  RESET_INFRACTIONS_FILTERS,
  RESET_INFRACTIONS_STATE,
  APPLY_INFRACTIONS_FILTERS,
  EDIT_INFRACTION,
  SET_CURRENT_INFRACTION
} from "../../types/FleetManagement/InfractionsType";
import moment from "moment";

const INIT_STATE = {
  data: [],
  filters: {
    plaque: "",
    date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    driver: "",
    infringedRule: "",
    amount: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_INFRACTIONS_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_INFRACTIONS_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case FETCH_ALL_INFRACTIONS: {
      return {
        ...state,
        data: action.payload
      };
    }
    case ADD_INFRACTION: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case RESET_INFRACTIONS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    case EDIT_INFRACTION: {
      return {
        ...state,
        data: state.data.map(d => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        })
      };
    }
    case SET_CURRENT_INFRACTION: {
      return {
        ...state,
        current: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
