import {
  ADD_CONFIG_MODULE,
  ADD_CONFIG_SUBMODULE,
  ADD_CONFIG_SUBMODULE_OPERATION,
  APPLY_CONFIG_MODULES_FILTERS,
  EDIT_CONFIG_MODULE_STATE,
  FETCH_ALL_CONFIG_MODULES,
  RESET_CONFIG_MODULES_FILTERS,
  RESET_CONFIG_MODULES_STATES,
  RESET_CURRENT_CONFIG_MODULE,
  RESET_CURRENT_CONFIG_SUBMODULE_OPERATIONS,
  SET_CURRENT_CONFIG_MODULE,
  SET_CURRENT_CONFIG_SUBMODULE_OPERATIONS,
  SET_CURRENT_CONFIG_SUBMODULES
} from "../../types/Configuration/ModulesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    code: "",
    description: ""
  },
  current: null,
  currentSubmodules: {},
  selectedSubmodule: null,
  currentOperations: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CONFIG_MODULES: {
      let modules = {};
      action.payload.map(u => {
        modules[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: modules
      };
    }
    case ADD_CONFIG_MODULE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_CONFIG_MODULE_STATE: {
      const id = action.payload.id;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            state: action.payload.state
          }
        }
      };
    }
    case SET_CURRENT_CONFIG_MODULE: {
      return {
        ...state,
        current: action.payload
      };
    }

    case SET_CURRENT_CONFIG_SUBMODULES: {
      let submodules = {};
      action.payload.map(u => {
        submodules[u.id] = u;
        return null;
      });
      return {
        ...state,
        currentSubmodules: submodules
      };
    }
    case ADD_CONFIG_SUBMODULE: {
      return {
        ...state,
        currentSubmodules: {
          ...state.currentSubmodules,
          [action.payload.id]: action.payload
        }
      };
    }

    case SET_CURRENT_CONFIG_SUBMODULE_OPERATIONS: {
      let operations = {};
      action.payload.operations.map(u => {
        operations[u.id] = u;
        return null;
      });
      return {
        ...state,
        selectedSubmodule: action.payload.submodule,
        currentOperations: operations
      };
    }
    case ADD_CONFIG_SUBMODULE_OPERATION: {
      return {
        ...state,
        currentOperations: {
          ...state.currentOperations,
          [action.payload.id]: action.payload
        }
      };
    }
    case RESET_CURRENT_CONFIG_SUBMODULE_OPERATIONS: {
      return {
        ...state,
        selectedSubmodule: INIT_STATE.selectedSubmodule,
        currentOperations: INIT_STATE.currentOperations
      };
    }

    case APPLY_CONFIG_MODULES_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }

    case RESET_CONFIG_MODULES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }

    case RESET_CURRENT_CONFIG_MODULE: {
      return {
        ...state,
        current: INIT_STATE.current,
        currentSubmodules: INIT_STATE.currentSubmodules
      };
    }
    case RESET_CONFIG_MODULES_STATES: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
