import React from 'react';
import SearchWorkOrder from "./SearchWorkOrder";
import WorkOrderDetail from "./WorkOrderDetail";
import WorkOrderTimeLine from "./WorkOrderTimeLine";
import ErrorAlert from "../../Common/Alerts/ErrorAlert";
import {useSelector} from "react-redux";
import ImportFileArea from "./ImportFileArea";

const SearchByWorkOrder = () => {
    const {detail, destinations} = useSelector(
        state => state.administrator.breakdown
    );
    return (
        <>
            <SearchWorkOrder />
            <ImportFileArea />
            {detail && <WorkOrderDetail />}
            {destinations.length > 0 && <WorkOrderTimeLine />}
            <ErrorAlert />
        </>
    );
};

export default SearchByWorkOrder;