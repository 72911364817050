class WorkshopTask {
    constructor(id, type, information,
                observations, workshopObservations,
                cost, status) {
        this.id = id;
        this.type = type;
        this.information = information;
        this.observations = observations;
        this.workshopObservations = workshopObservations;
        this.cost = cost;
        this.status = status;
    }

    static map = task => {
        return new WorkshopTask(
            task.nu_id_solicitud_taller_trabajos || task.nu_id_taller_trabajos || 0,
            {
                id: task.nu_id_tipo_trabajo || 0,
                description: task.vc_desc_tipo_trabajo
            },
            {
                id: task.nu_id_trabajo_informacion || 0,
                description: task.vc_desc_trabajo_informacion
            },
            task.vc_observaciones,
            task.vc_observaciones_taller,
            task.nu_costo,
            {
                id: task.nu_id_solicitud_taller_trabajo_estado || 0,
                description: task.vc_desc_solicitud_taller_trabajo_estado
            }
        );
    }
}

export default WorkshopTask;