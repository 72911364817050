import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";

const TableHeader = ({order, orderBy, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="clientCode"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.clients.table.clientCode" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="name"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="registered.name" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="businessName"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.clients.table.businessName" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="country"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.clients.table.country" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="code"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="fiscal.identity" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="email"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.clients.table.email" />
    </TableCellWithSorting>
    <StyledTableCell>
      <IntlMessages id="administrator.clients.table.actions" />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
