class ExpenseType {
  constructor(id, entityId, code, description, accountingAccount, active) {
    this.id = id;
    this.entityId = entityId;
    this.code = code;
    this.description = description;
    this.name = description;
    this.accountingAccount = accountingAccount;
    this.active = active;
  }

  static map = expenseType => {
    return new ExpenseType(
      expenseType.nu_id_tipo_gasto,
      expenseType.nu_id_entidad,
      expenseType.vc_cod_tipo_gasto,
      expenseType.vc_desc_tipo_gasto,
      expenseType.vc_cta_contable,
      expenseType.bi_estado === "1"
    );
  };
}

export default ExpenseType;
