import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "./StorageService";
import WordOrder from "../domain/WordOrder";
import WorkOrderGlobal from "../domain/WorkOrderGlobal";
import DestinationState from "../domain/DestinationState";
import {formatDateFromTimeAndDateString} from "../utils/dates";
import Response from "../domain/Response";
import moment from "moment/moment";
import Destination from "../domain/Destination";

let fetchAllByFiltersCancelToken = undefined;
let cancelFetchGlobal = undefined;

export const cancelPrevFetchAllByFilters = () => {
  fetchAllByFiltersCancelToken && fetchAllByFiltersCancelToken();
};

const cancelPrevFetchGlobal = () => {
  cancelFetchGlobal && cancelFetchGlobal();
};

export const fetchAllWorkOrdersByFilters = async filters => {
  try {
    const {data} = await axiosInstance.post(
      "/OT/sel_ot",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_empleado: getUserId(),
        nu_id_entidad_conductor: filters.driver || 0,
        // nu_id_estado: filters.state === ""? 0: filters.state,
        nu_id_estado: filters.state || 0,
        dt_fecha_inicio: filters.startDate || null,
        dt_fecha_fin: filters.endDate || null
      },
      {
        cancelToken: new CancelToken(c => (fetchAllByFiltersCancelToken = c))
      }
    );
    return data.map(workOrder => WordOrder.map(workOrder));
  } catch (e) {
    if (isCancel(e)) {
      return [];
    }
    return Promise.reject(e);
  }
};

export const getWorkOrderById = async id => {
  try {
    const {data} = await axiosInstance.post("/OT/get_ot_detalle", {
      nu_id_ot: id
    });
    return WordOrder.map(data[0]);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getWorkOrdersGlobal = async filters => {
  cancelPrevFetchGlobal();
  try {
    const {data} = await axiosInstance.post(
      "/OT/sel_ot_global",
      {
        nu_id_entidad: getCompanyEntityId(),
        conductor: filters.driver.map(d => Number(d.id)) || null,
        ot: filters.ot || null,
        estado: filters.state,
        fechaInicio: filters.startDate || null,
        fechaFin: filters.endDate || null
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchGlobal = c))
      }
    );
    return data.map(workOrder => WorkOrderGlobal.map(workOrder));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllDestinationsStates = async typeId => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Estados/sel", {
      nu_id_tipo_despacho: typeId
    });
    return data.map(state => DestinationState.map(state));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addWorkOrder = async workOrder => {
  try {
    const {data} = await axiosInstance.post("/OT/ins_ot", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_ot: workOrder.description,
      nu_id_entidad_conductor: 0,
      ls_personal: workOrder.driver.map((d, index) => (
          {
            nu_id_entidad: d.id,
            bi_responsable: index === 0,
          }
      )),
      dt_fec_ini_estimada: formatDateFromTimeAndDateString(
        workOrder.startDate,
        workOrder.startTime
      ),
      dt_fec_fin_estimada: formatDateFromTimeAndDateString(
        workOrder.endDate,
        workOrder.endTime
      ),
      nu_id_tracto: workOrder.tract,
      nu_id_tolva: workOrder.hopper
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateWorkOrder = async workOrder => {
  try {
    const {data} = await axiosInstance.post("/OT/upd_ot", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_ot: workOrder.id,
      vc_desc_ot: workOrder.description,
      // nu_id_entidad_conductor: workOrder.driver,
      dt_fec_ini_estimada: formatDateFromTimeAndDateString(
        workOrder.startDate,
        workOrder.startTime
      ),
      dt_fec_fin_estimada: formatDateFromTimeAndDateString(
        workOrder.endDate,
        workOrder.endTime
      ),
      nu_id_tracto: workOrder.tract,
      nu_id_tolva: workOrder.hopper
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteWorkOrder = async workOrder => {
  try {
    const {data} = await axiosInstance.post("/OT/del_ot", {
      nu_id_ot: workOrder.id,
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const updateWorkOrderDrivers = async (workOrder, drivers) => {
  try {
    const {data} = await axiosInstance.post(
        "/OT/upd_personal_ot",
        {
          nu_id_entidad: getCompanyEntityId(),
          nu_id_ot: workOrder.id,
          ls_personal: drivers.map((d, index) => (
              {
                nu_id_entidad: d.id,
                bi_responsable: index === 0,
              }
          ))
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addNewDestination = async (destination, workOrder) => {
  try {
    const {data} = await axiosInstance.post("/OT/ins_despacho", {
      nu_id_ot: workOrder.id,
      nu_id_tipo_despacho: workOrder.description,
      nu_id_entidad_conductor: workOrder.driver,
      dt_fec_ini_estimada: formatDateFromTimeAndDateString(
        workOrder.startDate,
        workOrder.startTime
      ),
      dt_fec_fin_estimada: formatDateFromTimeAndDateString(
        workOrder.endDate,
        workOrder.endTime
      ),
      nu_id_tracto: workOrder.tract,
      nu_id_tolva: workOrder.hopper
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const loadDestination = async (destination, workOrder) => {
  try {
    const {data} = await axiosInstance.post("/OT/ins_despacho", {
      nu_id_ot: workOrder.id,
      nu_id_tipo_despacho: destination.type,
      dt_fec_despacho: workOrder.startDate,
      nu_id_entidad_cliente: destination.clientHeadquarter.clientId,
      nu_id_entidad_sede_cliente: destination.clientHeadquarter.headquarterId,
      nu_secuencia_entrega: workOrder.destinations.length || 0,
      vc_desc_despacho: destination.referenceNumber,
      dt_fec_ini: destination.startTime,
      dt_fec_fin: destination.endTime,
      nu_cant_detalle: 0,
      vc_vehiculos: ""
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateDestination = async (destination, workOrder) => {
  try {
    const {data} = await axiosInstance.post("/OT/upd_despacho", {
      nu_id_ot: workOrder.id,
      nu_id_despacho: destination.id,
      nu_id_tipo_despacho: destination.type,
      dt_fec_ini: destination.startTime,
      dt_fec_fin: destination.endTime,
      vc_comentario: destination.comment,
      vc_nro_referencia: destination.referenceNumber
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteDestination = async (request) => {
  try {
    const {data} = await axiosInstance.post("/OT/del_despacho", {
      nu_id_ot: request.workOrder.id,
      nu_id_despacho: request.destinationId,
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const generateWorkOrdersExcel = async (filters, ot) => {
  try {
    const {data} = await axiosInstance.post(
        '/Informes/ordenes_trabajo',
        {
          filtro: {
            nu_id_entidad: getCompanyEntityId(),
            ot: ot,
            estado: filters.state,
            ls_conductores: filters.driver.map(d => d.id),
            fechaInicio: filters.startDate,
            fechaFin: filters.endDate
          },
          cadenaConexion: "",
          nu_id_tipo: "XLS"
        }
    )
    if(!data.vc_reporte) {
      throw new Error('Reporte no disponible');
    }
    return `data:application/vnd.ms-excel;base64,${data.vc_reporte}`;
  }catch (e) {
    return Promise.reject(e);
  }
}


export const updateDispatchState = async (dispatchId, state) => {
  try {
    const {data} = await axiosInstance.post(
        "/OT/upd_estado_despacho",
        {
          nu_id_despacho: dispatchId,
          nu_id_tipo_despacho_estado: state,
          dt_fec_estado: moment().toISOString()
        }
    );
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getDispatchDetails = async (dispatchId) => {
  try {
    const {data} = await axiosInstance.post(
        '/OT/get_despacho_detalle',
        {
          nu_id_despacho: dispatchId
        }
    );
    return Destination.map(data[0]);
  }catch (e) {
    return Promise.reject(e);
  }
}

export const getDeliverNote = async ({id, dispatchId}) => {
  try {
    const {data} = await axiosInstance.post(
        "/Informes/albaran",
        {
          filtro: {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_despacho: dispatchId,
            nu_id_albaran_generado: id,
          },
          cadenaConexion: "",
          nu_id_tipo: "PDF"
        }
    );
    const base64 = data.vc_reporte;
    if (!base64) throw Error('No se logro recuperar el archivo');
    return `data:application/pdf;base64,${base64}`;
  }catch (e) {
    return Promise.reject(e);
  }
}



export const updateDispatchClient = async ({id, destinationId, clientId, headquarterId}) => {
  try {
    const {data} = await axiosInstance.post(
        "/OT/upd_despacho_cliente",
        {
          nu_id_ot: id,
          nu_id_despacho: destinationId,
          nu_id_entidad_cliente: clientId,
          nu_id_entidad_sede_clente: headquarterId
        }
    );
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
}