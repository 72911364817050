import React, {useEffect} from "react";
import PageContainer from "../../../../components/Guardian/AccountingList/PageContainer";
import AccountingTable from "../../../../components/Guardian/AccountingList/AccountingTable";
import FloatingButton from "../../../../components/Guardian/AccountingList/FloatingButton";
import NewAccountingDialog from "../../../../components/Guardian/AccountingList/NewAccountingDialog";
import AccountingDetail from "../../../../components/Guardian/AccountingList/AccountingDetail";
import ResultAlert from "../../../../components/Guardian/AccountingList/ResultAlert";
import {getUserType} from "../../../../services/StorageService";

const AccountingList = () => {
  const [ openNewAccounting, setOpenNewAccounting ] = React.useState(false);
  const [ openDetails, setOpenDetails ] = React.useState(false);
  const [ accountingAlert, setAccountingSuccessAlert ] = React.useState(false);

  return (
    <PageContainer>
      <AccountingTable
        setOpenDetails={setOpenDetails}
        setOpenResult={setAccountingSuccessAlert}
      />
      {getUserType().includes("guardian") && (
        <FloatingButton setOpenDialog={setOpenNewAccounting} />
      )}
      <NewAccountingDialog
        open={openNewAccounting}
        setOpen={setOpenNewAccounting}
      />
      <AccountingDetail
        open={openDetails}
        setOpen={setOpenDetails}
        setAccountingAlert={setAccountingSuccessAlert}
      />
      <ResultAlert
        setOpenAlert={setAccountingSuccessAlert}
        openAlert={accountingAlert}
      />
    </PageContainer>
  );
};

export default AccountingList;
