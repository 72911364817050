import {mapToBoolean} from "../../../utils/mappers";

class VehicleSpecification {
  constructor(
    id,
    entityId,
    alias,
    plaque,
    chassis,
    brand,
    model,
    status,
    tags
  ) {
    this.id = id;
    this.entityId = entityId;
    this.alias = alias;
    this.plaque = plaque;
    this.chassis = chassis;
    this.brand = brand;
    this.model = model;
    this.status = status;
    this.tags = tags;
  }

  static map = vehicle => {
    return new VehicleSpecification(
      vehicle.nu_id_vehiculo,
      vehicle.nu_id_entidad,
      vehicle.vc_alias || "",
      vehicle.vc_placa || "",
      vehicle.vc_bastidor || "",
      vehicle.vc_marca || "",
      vehicle.vc_marca_modelo || "",
      mapToBoolean(vehicle.bi_estado),
      vehicle.tags.map(t => t.nu_id_tag).join(",")
    );
  };
}

export default VehicleSpecification;
