import React from "react";

import ListAltIcon from "@material-ui/icons/ListAlt";
import IntlMessages from "@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import {useHistory} from "react-router";

const FloatingButton = ({onAddClicked}) => {
  const history = useHistory();

  const actions = [
    {
      name: "logHistory",
      icon: <ListAltIcon />,
      title: (
        <IntlMessages id="administrator.cashManagers.floatingButton.logHistory" />
      ),
      onClick: () => history.push("/treasury/cash-management/log-history")
    }
  ];

  return (
    <FloatingButtonWithAdd onAddClicked={onAddClicked} otherActions={actions} />
  );
};

export default FloatingButton;
