import {Button, withStyles} from "@material-ui/core";
import GridContainer from "../../../../@jumbo/components/GridContainer";

export const ChatButton = withStyles(theme => ({
  root: {
    marginRight: "4px",
    backgroundColor: "#5ba4c8",
    color: "#ffffff"
  }
}))(Button);

export const AlertButton = withStyles(theme => ({
  root: {
    marginRight: "4px",
    marginLeft: "4px",
    backgroundColor: props =>
      props.offcolor === "false" ? "#9e9e9e" : "#c63100",
    color: "#ffffff"
  }
}))(Button);

export const ExportButton = withStyles(theme => ({
  root: {
    marginLeft: "4px",
    backgroundColor: "#00c8ca",
    color: "#ffffff"
  }
}))(Button);

export const StatesGridContainer = withStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "24px",
    marginBottom: "16px",
    marginLeft: "24px"
  }
}))(GridContainer);
