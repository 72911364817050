import {combineReducers} from "redux";

import unresolvedExpenses from "./unresolvedExpenses";
import ui from "./ui";
import UnresolvedAdvances from "./UnresolvedAdvances";

export default combineReducers({
  unresolvedExpenses: unresolvedExpenses,
  unresolvedAdvances: UnresolvedAdvances,
  ui
});
