import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {getAllProviderOrders, getOrderDetails} from "../../thunks/Logistic/providerOrders";

const INITIAL_STATE = {
    orders: [],
    currentOrder: null,
    dialogs: [],
};

const providerOrdersSlide = createSlice({
    name: "providerOrders",
    initialState: INITIAL_STATE,
    reducers: {
        setCurrentOrder: (state, action) => {
            state.currentOrder = action.payload;
        },
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        resetState: () => {
            return cloneDeep(INITIAL_STATE);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProviderOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
        });
        builder.addCase(getOrderDetails.fulfilled, (state, action) => {
            state.currentOrder = action.payload;
        });
    }
});

export default providerOrdersSlide.reducer;

export const { resetState, openDialog, closeDialog, setCurrentOrder} = providerOrdersSlide.actions;
