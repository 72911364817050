import React from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const options = [
  {
    id: 1,
    text: <IntlMessages id={"select.weekly.day.monday"} />
  },
  {
    id: 2,
    text: <IntlMessages id={"select.weekly.day.tuesday"} />
  },
  {
    id: 3,
    text: <IntlMessages id={"select.weekly.day.wednesday"} />
  },
  {
    id: 4,
    text: <IntlMessages id={"select.weekly.day.thursday"} />
  },
  {
    id: 5,
    text: <IntlMessages id={"select.weekly.day.friday"} />
  },
  {
    id: 6,
    text: <IntlMessages id={"select.weekly.day.saturday"} />
  },
  {
    id: 7,
    text: <IntlMessages id={"select.weekly.day.sunday"} />
  }
];

const WeeklyDaySelect = ({
  id,
  onChange,
  style,
  value,
  hasError,
  error,
  disabled,
  fullWidth = false
}) => (
  <Select
    id={id}
    label={<IntlMessages id="select.weekly.day.label" />}
    value={value}
    onChange={e => onChange(e.target.value)}
    style={style}
    error={hasError}
    disabled={disabled}
    errorMessage={error}
    fullWidth={fullWidth}
  >
    {options.map((option, index) => (
      <MenuItem value={option.id} key={`weekly-day-${option.id}`}>
        {option.text}
      </MenuItem>
    ))}
  </Select>
);

export default WeeklyDaySelect;
