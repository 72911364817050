import {
  IconButton,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  withStyles
} from "@material-ui/core";

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  },
  footer: {
    left: 0,
    bottom: 0,
    zIndex: 2,
    position: "sticky",
    backgroundColor: "#8092e3",
    color: theme.palette.common.white
  }
}))(TableCell);

export const DefaultStyledTableRow = withStyles(theme => ({
  root: {
    cursor: "pointer",
    transition: "all .3s ease",
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    },
    "&:focus": {
      borderBottom: "1px solid $primary",
      outline: "none"
    }
  }
}))(TableRow);

export const SelectedStyledTableRow = withStyles(theme => ({
  root: {
    cursor: "pointer",
    transition: "all .3s ease",
    backgroundColor: "white",
    "&:focus": {
      borderBottom: "1px solid $primary",
      outline: "none"
    }
  }
}))(TableRow);

export const StyledTablePagination = withStyles(theme => ({
  selectIcon: {
    color: "white"
  },
  menuItem: {
    backgroundColor: "black",
    color: "black"
  }
}))(TablePagination);

export const ConditionalButton = withStyles(theme => ({
  root: {
    "&:disabled": {
      color: "transparent",
      backgroundColor: "transparent"
    }
  }
}))(IconButton);

export const CustomTableContainer = withStyles(theme => ({
  root: {
    boxShadow: "5px 10px 18px #4e598a",
    marginBottom: "36px"
  }
}))(TableContainer);
