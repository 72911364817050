import React from 'react';
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({isOt}) => {
    return (
        <StyledTableRow>
            <StyledTableCell>
                <IntlMessages id='date' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='ot' />
            </StyledTableCell>
            {isOt
                ?<>
                    <StyledTableCell>
                        <IntlMessages id='start.date' />
                    </StyledTableCell>
                    <StyledTableCell>
                        <IntlMessages id='end.date' />
                    </StyledTableCell>
                </>
                :<>
                    <StyledTableCell>
                        <IntlMessages id='arrive.time' />
                    </StyledTableCell>
                    <StyledTableCell>
                        <IntlMessages id='departure.time' />
                    </StyledTableCell>
                </>
            }
            <StyledTableCell>
                <IntlMessages id='trajectory' />{' '}<IntlMessages id='hh.mm' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='trajectory' />{' '}<IntlMessages id='kms' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='stay' />{' '}<IntlMessages id='hh.mm' />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id='stay' />{' '}<IntlMessages id='kms' />
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default TableHeader;