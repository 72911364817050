import ui, {
  closeCreateForm,
  closeDeleteConfirmation,
  closeEditForm,
  closeRestoreConfirmation
} from "./ui";
import {
  createProduct,
  cancelPrevProductFetchAll,
  fetchAllProducts,
  updateProduct,
  changeProductState
} from "../../../services/ProductService";

import {restoreAdministratorState} from "./Common";

import {
  PRODUCTS_CHANGE_STATE,
  PRODUCTS_CREATE,
  PRODUCTS_EDIT,
  APPLY_PRODUCTS_FILTERS,
  FETCH_PRODUCTS_SUCCEEDED,
  RESET_PRODUCTS_FILTERS
} from "../../types/Administrator/ProductCustomTypes";

import Product from "../../../domain/Product";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevProductFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchProductsSucceeded = ({products, rowCount}) => ({
  type: FETCH_PRODUCTS_SUCCEEDED,
  payload: {
    products,
    rowCount
  }
});

export const fetchProducts = filters => async dispatch => {
  dispatch(fetchStart());
  cancelPrevProductFetchAll();
  fetchAllProducts(filters)
    .then((data) => {
      dispatch(fetchSuccess());
      dispatch(fetchProductsSucceeded(data));
    })
    .catch(err => {
      dispatch(fetchError(err.message));
    });
};

export const applyProductsFilters = filters => ({
  type: APPLY_PRODUCTS_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetProductsFilters = () => ({type: RESET_PRODUCTS_FILTERS});

const createEditProductAction = (id, data) => ({
  type: PRODUCTS_EDIT,
  payload: {
    id: id,
    data: {
      ...data
    }
  }
});

const changeProductStateSucceeded = id => ({
  type: PRODUCTS_CHANGE_STATE,
  payload: {
    id
  }
});

const changeProductStateStarted = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  await changeProductState(id);
  dispatch(changeProductStateSucceeded(id));
  dispatch(ui.changeStateSucceeded());
};

export const deleteProduct = id => dispatch => {
  dispatch(changeProductStateStarted(id));
  dispatch(closeDeleteConfirmation());
};

export const restoreProduct = id => dispatch => {
  dispatch(changeProductStateStarted(id));
  dispatch(closeRestoreConfirmation());
};

export const editProduct = (data, editingId) => async dispatch => {
  dispatch(closeEditForm());
  dispatch(ui.editStarted());
  try {
    await updateProduct(editingId, data);
    dispatch(createEditProductAction(editingId, data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const createProductAction = data => ({
  type: PRODUCTS_CREATE,
  payload: {
    ...data
  }
});

export const createProductStarted = data => async dispatch => {
  dispatch(closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const response = await createProduct(data);
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes();
    const product = new Product(
      response.id,
      data.description,
      data.codExterno,
      data.esEnvase,
      data.unidadType.value,
      data.unidadType.label,
      data.volumenUnidad,
      date,
      "",
      true
    );
    dispatch(createProductAction(product));
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export default {
  get: fetchProducts,
  edit: editProduct,
  delete: deleteProduct,
  restore: restoreProduct,
  create: createProductStarted,
  applyProductsFilters,
  resetProductsFilters,
  resetState
};
