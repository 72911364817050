export const validateFormField = (formData, except = []) => {
  let fields = Object.keys(formData);
  except.forEach(e => {
    fields = fields.filter(field => field !== e);
  });
  const validate = fields.map(field => {
    if (field !== "files" && formData[field] === "") {
      return true;
    }
    return formData["files"].length === 0;
  });
  return validate.filter(v => v === true).length > 0;
};

export const validateFormField2 = (formData, except = []) => {
  let fields = Object.keys(formData);
  except.forEach(e => {
    fields = fields.filter(field => field !== e);
  });
  const validate = fields.map(field => {
    if (formData[field] === "" || formData[field].length === 0) {
      return true;
    }
    //return formData['files'].length === 0;
    return false;
  });
  return validate.filter(v => v === true).length > 0;
};
