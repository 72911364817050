class SigningTypes {
  constructor(id, companyId, description, totalWorkDay, marginWorkday, active) {
    this.id = id;
    this.companyId = companyId;
    this.description = description;
    this.totalWorkDay = totalWorkDay;
    this.marginWorkday = marginWorkday;
    this.active = active;
  }

  static map = signingTypes => {
    return new SigningTypes(
      signingTypes.nu_id_fichaje_tipo,
      signingTypes.nu_id_entidad_empresa,
      signingTypes.vc_desc,
      signingTypes.nu_tiempo_jornada,
      signingTypes.nu_tiempo_jornada_margen,
      signingTypes.bi_activo
    );
  };
}

export default SigningTypes;
