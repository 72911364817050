import React from "react";
import {useStyles} from "./styles";
import {Collapse, List, ListItem} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import ReportsList from "./ReportsList";

const AreaList = ({options, title}) => {
  const classes = useStyles();
  const [ open, setOpen ] = React.useState(false);

  return (
    <List component="nav" className={classes.area}>
      <ListItem button onClick={() => setOpen(!open)}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {options.map((option, index) => (
          <ReportsList key={`option-${index}`} option={option} />
        ))}
      </Collapse>
    </List>
  );
};

export default AreaList;
