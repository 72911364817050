import React from 'react';
import PageContainer from "../../../../components/Treasury/CreditNotes/PageContainer";
import Table from "../../../../components/Treasury/CreditNotes/Table";
import AddDialog from "../../../../components/Treasury/CreditNotes/AddDialog";
import EditDialog from "../../../../components/Treasury/CreditNotes/EditDialog";

const CreditNotes = () => {
    return (
        <PageContainer>
            <AddDialog />
            <Table />
            <EditDialog />
        </PageContainer>
    );
};

export default CreditNotes;