import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import ElevatorDetail from "../../domain/ElevatorsManagement/ElevatorDetail";

export const getAllElevatorsDetails = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Detalle/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(detail => ElevatorDetail.map(detail));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createElevatorDetail = async detail => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Detalle/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_detalle: detail.name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorDetail = async (detail, name) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Detalle/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_detalle: detail.id,
      vc_desc_tipo_detalle: name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorDetailState = async (detail, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Detalle/del", {
      nu_id_tipo_detalle: detail.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
