export const uploadDocumentToS3 = async (s3PreSignedUrl, document) => {
    const requestOptions = {
        method: "PUT",
        body: document
    };
    try {
        return await fetch(s3PreSignedUrl, requestOptions);
    } catch (e) {
        throw Error("Error importando el archivo al servidor S3.");
    }
};