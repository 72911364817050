import React, {useEffect, useState} from "react";
import PageContainer from "../../../../components/Administrator/WorkOrderBreakdown/PageContainer";
import {useDispatch} from "react-redux";
import {resetBreakdownState} from "../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import Tabs from "../../../../components/Common/Tabs";
import SearchByWorkOrder from "../../../../components/Administrator/WorkOrderBreakdown/SearchByWorkOrder";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import SearchByOthers from "../../../../components/Administrator/WorkOrderBreakdown/SearchByOthers";
import HistoryDialog from "../../../../components/Administrator/WorkOrderBreakdown/SearchByOthers/HistoryDialog";

const options = [
    {
        value: 'by-ot',
        label: <IntlMessages id='by.ot' />,
        renderOption: () => <SearchByWorkOrder />
    },
    {
        value: 'others',
        label: <IntlMessages id='others' />,
        renderOption: () => <SearchByOthers />
    }
]
const WorkOrderBreakdown = () => {
    const [selected, setSelected] = useState(options[0].value);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(resetBreakdownState());
    };
  }, []);
  const onChangeTab = (newSelected) => {
      dispatch(resetBreakdownState());
      setSelected(newSelected);
  }
  return (
    <PageContainer>
        <Tabs
            value={selected}
            onChange={onChangeTab}
            tabs={options}
        >
            {options.find(o => o.value === selected)?.renderOption()}
        </Tabs>
        <HistoryDialog setTabToOtBreakdown={()=> setSelected(options[0].value)} />
    </PageContainer>
  );
};

export default WorkOrderBreakdown;
