import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../../actions";
import {getFileData, getReport, uploadFile} from "../../../../services/FleetManagement/Tachograph/FilesReaderService";

const basePrefix = 'filesReader';

export const getDataFromFile =  createAsyncThunk(
    `${basePrefix}/getDataFromFile`,
    async (file, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const fileName = await uploadFile(file);
            const fileData = await getFileData(fileName);
            dispatch(fetchSuccess());
            return fileData;
        }catch (e) {
            dispatch(fetchError('Error por fallo de lectura o corrupción del fichero'));
            return rejectWithValue(e.message);
        }
    }
);

export const downloadReport =  createAsyncThunk(
    `${basePrefix}/downloadReport`,
    async ({fileName, complete}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            await getReport(fileName, complete);
            dispatch(fetchSuccess("El informe será enviado a su correo en unos minutos."));
            return;
        }catch (e) {
            dispatch(fetchError('Error por fallo de lectura o corrupción del fichero'));
            return rejectWithValue(e.message);
        }
    }
);