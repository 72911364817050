import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import Select from "../../../Common/Forms/Select";
import {fetchAllContractsTypes} from "../../../../services/ContractService";

const ContractSelect = ({contract, onChange, defContract = null}) => {
  const [ contracts, setContracts ] = React.useState([]);
  React.useEffect(() => {
    fetchAllContractsTypes()
      .then(types => setContracts(types))
      .catch(e => setContracts([]));
  }, []);
  return (
    <Select
      value={contract}
      onChange={e => onChange(e.target.value)}
      id="employee-contract"
      label={<IntlMessages id={"rrhh.employees.form.workday"} />}
      fullWidth
    >
      {contracts.length < 1 && <MenuItem value={contract}>{""}</MenuItem>}
      {contracts.map(type => (
        <MenuItem key={type.id} value={type.id}>
          {type.description}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ContractSelect;
