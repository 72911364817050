import React from 'react';
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {useSelector} from "react-redux";
import MultipleSelect from "../../../components/Common/Forms/Select/MultipleSelect";

const EventPicker = ({id, values, isLoading, onChange, errors, service}) => {
    const {events} = useSelector(state => state.administrator.eventsSelect);

    const options = events.filter(v => v.name !== 'GPS: Exceso Velocidad');

    React.useEffect(() => {
        if(service === 9 && events.length > 0){
            onChange(options);
        }
    }, [events]);

    return (
        <MultipleSelect
            id={id}
            options={events}
            value={values}
            onChange={onChange}
            fullWidth
            hasError={errors?.events?.length > 0}
            error={errors?.events[0] || ''}
            disabled={isLoading}
            label={<IntlMessages id="administrator.dataCollectionHistory.filters.events"/>}
            headerText={<IntlMessages id="administrator.dataCollectionHistory.filters.events.header" />}
            noOptionsText={<IntlMessages id="administrator.dataCollectionHistory.filters.events.no.options"/>}
        />
    );
}

export default EventPicker;