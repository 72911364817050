import React from "react";
import UserReportsList from "./UserReportsList";
import AreasGrid from "./AreasGrid";

const KpiReportsList = () => {
  return (
    <React.Fragment>
      <AreasGrid />
      <UserReportsList />
    </React.Fragment>
  );
};

export default KpiReportsList;
