import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import TagsPicker from "../../../../../../containers/Administrator/TagsPicker";
import validations from "./validations";
import FormControl from "@material-ui/core/FormControl";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import {FormHelperText, IconButton, InputAdornment} from "@material-ui/core";
import useUiState from "../../../../../../hooks/Administrator/useUiState";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const Form = ({ id, initData, onSubmit }) => {
    
    const { formData, setFormData, errors, handleSubmit } = useForm(
        {
            tags: initData?.tags || [],
            code: initData?.code || '',
        },
        validations,
    );
    const ui = useUiState();
    const [showPassword, setShowPassword] = React.useState(false);
    
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={12} style={{ margin: '10px 0' }}>
                    <TagsPicker
                        id="administrator-edit-receivers-tags-picker"
                        values={formData.tags}
                        hasError={errors.tags.length > 0}
                        error={errors.tags[0]}
                        onChange={value => setFormData('tags', value)}
                    />
                </Grid>
                <Grid item xs={12} style={{margin: '10px 0'}}>
                    <FormControl error={errors.code.length > 0} variant="outlined" size="small" fullWidth>
                        <OutlinedInput
                            value={formData.code}
                            id="receiver-code"
                            label={<IntlMessages id={"administrator.logistic.receivers.form.code"}/>}
                            onChange={e => setFormData('code', e)}
                            disabled={ui.showLoadingIndicator}
                            type={showPassword? "text" : "password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="password-visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        onMouseDown={(event) => event.preventDefault()}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormHelperText>{errors.code[0]}</FormHelperText>
                    </FormControl>
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;
