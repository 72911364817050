import React, {useCallback, useEffect, useState} from "react";
import {Box} from "@material-ui/core";
import Filters from "../../../../../components/Logistic/MerchandiseReception/MerchandiseReceptionFilter/index";
import MerchandiseTable from "../../../../../components/Logistic/MerchandiseReception/Table/index";
import PageContainer from "../../../../../components/Logistic/MerchandiseReception/PageContainer/index";
import {applyAdvancesListFilters, resetAdvancesListFilters} from "../../../../../redux/actions/Guardians/Advances";
import {clearAdvancesListFilter} from "../../../../../redux/actions/Guardians/AdvancesListFilter";

import {connect} from "react-redux";
import merchandiseReception from "redux/actions/Administrator/MerchandiseReception";
import FilterBuilder from "utils/filters";

import CreateIncidenceDialog from "components/Logistic/MerchandiseReception/CreateIncidentDialog";
import incidence from "redux/actions/Administrator/Incidence";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

const MerchandiseReception = ({tableDataMerchandise, dispatch}) => {

  const applyFilters = useCallback(
    filters => dispatch(applyAdvancesListFilters(filters)),
    [ dispatch ]
  );

  const resetFilters = () => {
    dispatch(resetAdvancesListFilters());
    dispatch(clearAdvancesListFilter());
  };

  const [ currentProduct, setCurrentProduct ] = useState([]);
  const [ openDetails, setOpenDetails ] = React.useState(false);
  const [ idMerchandise, setIdMerchandise ] = useState();
  const [ openIncidence, setOpenIncidence ] = React.useState(false);
  const [ dataIncidence, setDataIncidence ] = useState();

  useEffect(
    () => {
      dispatch(merchandiseReception.get());
      return () => dispatch(merchandiseReception.resetState());
    },
    [ dispatch ]
  );

  const onAccept = async () => {
    await dispatch(merchandiseReception.accept(idMerchandise));
    setOpenDetails(false);
    dispatch(merchandiseReception.get());
  };
  const onReject = async () => {
    await dispatch(merchandiseReception.reject(idMerchandise));
    setOpenDetails(false);
    dispatch(merchandiseReception.get());
  };
  const onCreateIncidence = async data => {
    await dispatch(incidence.create(data));
    setOpenIncidence(false);
    setOpenDetails(false);
    dispatch(merchandiseReception.get());
  };
  const handleCloseIncidence = () => {
    setOpenIncidence(false);
  };

  return (
    <PageContainer>
      <Box display="flex" flexDirection="column">
        <Filters applyFilters={applyFilters} onClose={resetFilters} />
        <MerchandiseTable
          data={tableDataMerchandise}
          setCurrent={setCurrentProduct}
          current={currentProduct}
          open={openDetails}
          setOpen={setOpenDetails}
          id={idMerchandise}
          setId={setIdMerchandise}
          onAccept={onAccept}
          onReject={onReject}
          incidenceOpen={openIncidence}
          setIncidenceOpen={setOpenIncidence}
          incidenceData={dataIncidence}
          setIncidenceData={setDataIncidence}
        />
        <CreateIncidenceDialog
          initialData={dataIncidence}
          isOpen={openIncidence}
          onClose={handleCloseIncidence}
          onSubmit={onCreateIncidence}
        />
      </Box>
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.merchandiseReception;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("entityNameOrigin", filters.entityOrigin)
    .byFieldIncludes("entityNameDestiny", filters.entityDestiny)
    .byFieldIncludes("active", filters.active)
    .build();
  return {tableDataMerchandise: filtered};
};

export default connect(mapStateToProps)(MerchandiseReception);
