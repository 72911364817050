import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Form from "./Form";
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {closeDialog, setCurrentOrderItem} from "../../../../../../../../redux/reducers/Logistic/orders";
import {dialogsList} from "../../../../constants";
import {updateOrderItemDetails} from "../../../../../../../../redux/thunks/Logistic/orders";

const ItemForm = () => {
    const id = dialogsList.itemForm;
    const {dialogs, currentItem, currentOrder} = useSelector(({logistic}) => logistic.orders);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === id);
    const handleSubmit = (data) => {
        dispatch(updateOrderItemDetails({
            order: currentOrder,
            itemId: currentItem.id,
            request: data,
        }));
        onClose();
    }
    const onClose = () => {
        dispatch(closeDialog(id));
        dispatch(setCurrentOrderItem(null));
    }
    return (
        <FormDialog
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            title={<IntlMessages id="edit" />}
        >
            {(isOpen && currentItem) &&
                <Form
                    id={id}
                    onSubmit={handleSubmit}
                />
            }
        </FormDialog>
    );
};

export default ItemForm;