import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";
import {useDispatch, useSelector} from "react-redux";

import PageContainer from "components/RRHH/HistoricalSigning/PageContainer";
import EditStartAndEndHistoricalSigningDialog from "components/RRHH/HistoricalSigning/EditHistoricalSigningDialog/EditStartAndEndHistoricalSigningDialog";
import Table from "components/RRHH/HistoricalSigning/Table";
import Filters from "components/RRHH/HistoricalSigning/Filters/";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";
import {fetchHistoricalSigning} from "redux/actions/RRHH/HistoricalSigning";

import useUiState from "hooks/Administrator/useUiState";

// import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";
const HistoricalSigning = () => {
  const ui = useUiState();
  const dispatch = useDispatch();
  const {filters, data, current, alerts} = useSelector(
    state => state.rrhh.historicalSigning
  );
  useEffect(
    () => {
      dispatch(fetchHistoricalSigning(filters));
      // return () => dispatch(rejectionTypes.resetState());
    },
    [ dispatch ]
  );

  // const applyFilters = useCallback(filters => dispatch(rejectionTypes.applyFilters(filters)), [dispatch]);
  // const resetFilters = () => dispatch(rejectionTypes.resetFilters());

  // const onDelete = () => dispatch(rejectionTypes.delete(ui.deletingId));
  // const onRestore = () => dispatch(rejectionTypes.restore(ui.restoringId));
  // const onEdit = data => dispatch(rejectionTypes.edit(ui.editingId, data));
  // const onCreate = data => dispatch(rejectionTypes.create(data));
  // const onDelete = () => dispatch();
  // const onRestore = () => dispatch();
  // const onEdit = data => dispatch();
  // const onCreate = data => dispatch();

  // const editing = ui.editingId ? tableData.find(e => e.id === ui.editingId) : undefined;

  return (
    <PageContainer>
      <Filters
        /*onFilter={applyFilters} onClose={resetFilters}*/ showLoadingIndicator={
          ui.showLoadingIndicator
        }
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={data} {...ui.tableProps} />
        </Box>
      )}
      {/* <CreateRejectionTypeDialog onSubmit={onCreate} {...ui.createFormProps} />*/}
      {/* <EditStartAndEndHistoricalSigningDialog initialData={editing} onSubmit={onEdit} {...ui.editFormProps} />  */}
      {/* <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
            <RestoreConfirmation onAccept={onRestore} {...ui.restoreConfirmationProps} /> */}
      {/* <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} /> */}
      {/* <ErrorAlert/> */}
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data} = state.rrhh.historicalSigning;
  // const filtered = new FilterBuilder(data)
  //     // .byFieldEquals('active', filters.active)
  //     // .byFieldIncludes('description', filters.description)
  //     .build();
  return {tableData: data};
};
export default HistoricalSigning;
// export default connect(mapStateToProps)(HistoricalSigning);
