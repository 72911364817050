import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  dialogPrincipalBox: {
    marginTop: "16px",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  sectionCard: {
    marginTop: "16px"
  },
  buttonsArea: {
    marginBottom: "4px",
    marginLeft: "4px",
    display: "flex",
    flexDirection: "row",
    gap: "8px"
  },
  datesBos: {
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  },
  contactFormBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  }
}));
