import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import FormDialog from 'components/Common/Dialogs/FormDialog';
import Form from './Form';

const EditCashManagerDialog = ({ initialData, isOpen, onClose, onSubmit }) => (
  <FormDialog
    id="edit-cash-manager-form"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.cashManagers.editFormTitle" />}
    onClose={onClose}
    // disabled={initialData?.fund !== initialData?.cash}
  >
    <Form
      id="edit-cash-manager-form"
      initialData={initialData}
      onSubmit={onSubmit}
      disabled={initialData?.fund !== initialData?.cash}
    />
  </FormDialog>
);

export default EditCashManagerDialog;
