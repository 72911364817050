import {withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";

export const ContainedButton = withStyles(theme => ({
  root: {
    color: "rgb(63, 81, 181)",
    marginLeft: "4px",
    marginRight: "4px"
  }
}))(Button);
