import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  ADD_ELEVATOR_MODEL,
  APPLY_ELEVATORS_MODELS_FILTERS,
  EDIT_ELEVATOR_MODEL,
  FETCH_ALL_ELEVATORS_MODELS,
  RESET_CURRENT_ELEVATOR_MODEL,
  RESET_ELEVATORS_MODELS_FILTERS,
  RESET_ELEVATORS_MODELS_STATE,
  SET_CURRENT_ELEVATOR_MODEL
} from "../../types/ElevatorsManagement/ModelsTypes";
import ElevatorModel from "../../../domain/ElevatorsManagement/ElevatorModel";
import {
  createElevatorModel,
  getAllElevatorsModels,
  updateElevatorModel,
  updateElevatorModelState
} from "../../../services/ElevatorsManagement/ModelsService";

export const resetElevatorsModelsState = () => ({
  type: RESET_ELEVATORS_MODELS_STATE
});
export const applyElevatorsModelsFilters = filters => ({
  type: APPLY_ELEVATORS_MODELS_FILTERS,
  payload: filters
});
export const resetElevatorsModelsFilters = () => ({
  type: RESET_ELEVATORS_MODELS_FILTERS
});
export const resetCurrentElevatorModel = () => ({
  type: RESET_CURRENT_ELEVATOR_MODEL
});
export const setCurrentElevatorModel = current => ({
  type: SET_CURRENT_ELEVATOR_MODEL,
  payload: current
});
export const fetchAllElevatorsModels = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllElevatorsModels(filters)
      .then(models => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_ELEVATORS_MODELS, payload: models});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const editElevatorModel = (current, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorModel(current, data.name)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.name = data.name;
        dispatch({type: EDIT_ELEVATOR_MODEL, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editElevatorModelState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorModelState(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.state = state;
        dispatch({type: EDIT_ELEVATOR_MODEL, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addElevatorModel = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createElevatorModel(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newModel = new ElevatorModel(
          response.transactionKey,
          data.name,
          true
        );
        dispatch({type: ADD_ELEVATOR_MODEL, payload: newModel});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
