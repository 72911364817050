import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import Incidence from "../domain/Incident";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevIncidentFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllIncident = async filters => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Incidencia/sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        dt_fec_inicio: filters.fromDate || "",
        dt_fec_final: filters.toDate || "",
        vc_origen: filters.origin,
        vc_destino: filters.destination
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    const newList = await Promise.all(
      response.data.map(async e => {
        const resp = await axiosInstance.post(
          "/Inventario_Movimiento_Det/sel",
          {
            nu_id_movimiento: e.nu_id_movimiento
          },
          {
            cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
          }
        );
        const objTransfer = resp.data.map(e => ({
          id: e.nu_id_mov_det,
          product: e.vc_desc_producto,
          stock: e.nu_cantidad,
          currentStock: e.nu_stock_actual,
          incidenceId: e.nu_id_tipo_incidencia,
          incidence: e.vc_desc_tipo_incidencia
        }));
        return {...e, ls_detalle: objTransfer};
      })
    );
    return newList.map(incidence => Incidence.map(incidence));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createIncidence = async data => {
  try {
    const response = await axiosInstance.post("/Inventario_Incidencia/ins", {
      nu_id_movimiento_ref: data.transfer.value,
      vc_usuario: "administradorcajachica@4gflota.com",
      ls_detalle: data.products.map(e => ({
        nu_id_movimiento: data.transfer.value,
        nu_id_mov_det: e.id,
        nu_id_producto: data.transfer.products.find(p => p.id === e.id).idProd,
        nu_cantidad: e.amount,
        nu_id_tipo_incidencia: e.incident.value
      }))
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
