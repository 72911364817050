const colors = {
    // planta
    planta: {
        id: 1,
        color: '#5d705f'

    },
    // En Ruta
    enruta: {
        id: 2,
        color: '#b0ae2c'
    },
    // Granja
    granja: {
        id: 3,
        color: '#bf9f7a'
    },
    // pendiente
    pendiente: {
        id: 4,
        color: '#878787'
    },
    // cliente =>
    cliente: {
        id: 5,
        color: '#bf9f7a'
    },
    // retorno =>
    retorno: {
        id: 6,
        color: '#4f456e'
    },
    // finalizado
    finalizado: {
        id: 7,
        color: '#5d705f'
    },
    // cargadero
    cargadero: {
        id: 7,
        color: '#5d705f'
    },
}

class ServiceState {
    constructor(description) {
        this.id = colors[description.replace(/\s+/g, '').toLowerCase()]?.id;
        this.description = description;
        this.color = colors[description.replace(/\s+/g, '').toLowerCase()]?.color;
    }
}

export default ServiceState;