import React from "react";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import useUiState from "../../../../hooks/Administrator/useUiState";
import {editClientStarted} from "../../../../redux/actions/Administrator/Clients";

const EditClientDialog = ({isOpen, onClose}) => {
  const id = "edit-client-form";
  const ui = useUiState();
  const dispatch = useDispatch();
  const {filters} = useSelector(state => state.administrator.clients);
  const onEdit = data => {
    dispatch(editClientStarted(data, filters));
  };
  const {data} = useSelector(state => state.administrator.clients);
  const initialData = ui.editingId
    ? data.find(c => c.id === ui.editingId)
    : undefined;

  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      title={<IntlMessages id="administrator.clients.edit.form.title" />}
      onClose={onClose}
    >
      {initialData && (
        <Form id={id} initialData={initialData} onSubmit={onEdit} />
      )}
    </FormDialog>
  );
};

export default EditClientDialog;
