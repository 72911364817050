class VehicleReport {
  constructor(
    id,
    vehicle,
    fulfilledDate,
    doneUser,
    type,
    checklists,
    receivedDate,
    mileage,
    driver,
    observations,
    employeeId,
    images
  ) {
    this.id = id;
    this.vehicle = vehicle;
    this.fulfilledDate = fulfilledDate;
    this.doneUser = doneUser;
    this.type = type;
    this.checklists = checklists;
    this.receivedDate = receivedDate;
    this.mileage = mileage;
    this.driver = driver;
    this.observations = observations;
    this.employeeId = employeeId;
    this.images = images;
  }

  static map = report => {
    return new VehicleReport(
      report.nu_id_checklist_generados,
      report.vc_placa,
      report.dt_fec_reg,
      report.vc_nombre,
      report.vc_desc_tipo_checklist,
      [],
      "",
      0,
      "",
      "",
      report.nu_id_enitdad_empleado,
      []
    );
  };
}

export default VehicleReport;
