import React from 'react';
import { Paper, Table, TableBody, TableContainer, TableHead, Typography } from '@material-ui/core';
import StyledTableRow from '../../../Common/Tables/StyledTableRow';
import StyledTableCell from '../../../Common/Tables/StyledTableCell';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import OperativeTableRow from './OperativeTableRow';
import PettyCashesTable from './PettyCashesTable';
import Box from '@material-ui/core/Box';

const OperativeTable = ({ data, currencies }) => {
  return data.length <= 1 ? (
    <Box margin={1}>
      {data.length > 0 ? (
        <React.Fragment>
          <Typography variant="h6" gutterBottom component="div" style={{ margin: '20px 0' }}>
            <IntlMessages id={'guardian.operative.plannings.table.header.currency'} /> -{' '}
            {currencies.find(currency => currency.id === data[0]?.currency).symbol}
          </Typography>
          <PettyCashesTable boxes={data[0].boxes} currency={data[0].boxes} />
        </React.Fragment>
      ) : (
        <p>No data founded</p>
      )}
    </Box>
  ) : (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell>
              <IntlMessages id={'guardian.operative.plannings.table.header.currency'} />
            </StyledTableCell>
            <StyledTableCell>
              <IntlMessages id={'administrator.guardian.cash'} />
            </StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {data.map(({ currency, boxes }) => (
            <OperativeTableRow key={currency} currency={currency} boxes={boxes} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OperativeTable;
