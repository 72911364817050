import {combineReducers} from "redux";
import Users from "./Users";
import Modules from "./Modules";
import Roles from "./Roles";
import OrganizationModules from "./OrganizationModules";
import VehicleRoles from "./VehicleRoles";

export default combineReducers({
  users: Users,
  modules: Modules,
  roles: Roles,
  organizationsModules: OrganizationModules,
  vehicleRoles: VehicleRoles
});
