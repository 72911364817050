import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export const validations = {
    name: [
        {
            rule: v => v.trim() !== "",
            message: (
                <IntlMessages id="form.field.error" />
            )
        },
    ]
}