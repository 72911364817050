import React from "react";
import PageContainer from "../../../../../components/Logistic/Distribution/Orders/PageContainer";
import OrdersTable from "../../../../../components/Logistic/Distribution/Orders/OrdersTable";
import OrderDialog from "../../../../../components/Logistic/Distribution/Orders/OrderDialog";
import BillItemsDialog from "../../../../../components/Logistic/Distribution/Orders/OrderDialog/Form/BillItemsDialog";

const Orders = () => {


  return (
    <PageContainer>
        <OrdersTable />
        <OrderDialog />
        <BillItemsDialog />
    </PageContainer>
  );
};



export default Orders;
