import React from "react";
import {useSelector} from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import TableHeader from "./TableHeader";
import {parseNumberToMoney} from "../../../../../utils/parsers";
import moment from "moment";

const DetailTable = () => {
  const {currentAccounting} = useSelector(state => state.guardian);
  return (
    <React.Fragment>
      {currentAccounting.id && (
        <TableContainer
          component={Paper}
          style={{
            width: "90%",
            margin: "36px 40px"
          }}
        >
          <Table>
            <TableHead>
              <TableHeader />
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell align="left">
                  {currentAccounting.id}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {currentAccounting.openingDate &&
                    moment(currentAccounting.openingDate).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {currentAccounting.liquidationDate &&
                    moment(currentAccounting.liquidationDate).format(
                      "DD/MM/YYYY HH:mm"
                    )}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {currentAccounting.tags.map(tag => (
                    <p key={`${currentAccounting.id}-${tag}`}>{tag}</p>
                  ))}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {parseNumberToMoney(currentAccounting.quantity)}
                </StyledTableCell>
                <StyledTableCell align="left">
                  {currentAccounting.state.description}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </React.Fragment>
  );
};

export default DetailTable;
