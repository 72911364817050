import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import PlaqueSelect from "../../../Common/Form/Selects/PlaqueSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import OutlinedTimePicker from "../../../../../Common/Forms/OutlinedTimePicker";
import DriverSelect from "../../../Common/Form/Selects/DriverSelect";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import OrganizationSelect from "./OrganizationSelect";
import CostCentralSelect from "../../../Common/Form/Selects/CostCentralSelect";
import InfringedRuleSelect from "./InfringedRuleSelect";
import DefenseSelect from "./DefenseSelect";
import StateSelect from "./StateSelect";
import validations from "./validations";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <PlaqueSelect
                        id={"add-infraction-plaque"}
                        value={formData.plaque}
                        setValue={value => setFormData('plaque', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.plaque"}/>}
                        hasError={errors?.plaque?.length > 0}
                        error={errors?.plaque[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-infraction-date"}
                        fullWidth
                        value={formData.date}
                        onChange={value => setFormData('date', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.date"}/>}
                        hasError={errors?.date?.length > 0}
                        error={errors?.date[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedTimePicker
                        id={"add-infraction-time"}
                        fullWidth
                        value={formData.time}
                        onChange={value => setFormData('time', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.time"}/>}
                        hasError={errors?.time?.length > 0}
                        error={errors?.time[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DriverSelect
                        id={"add-infraction-driver"}
                        value={formData.driver}
                        setValue={value => setFormData('driver', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.driver"}/>}
                        hasError={errors?.driver?.length > 0}
                        error={errors?.driver[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-file-number"}
                        fullWidth
                        value={formData.fileNumber}
                        onChange={value => setFormData('fileNumber', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.file.number"}/>}
                        hasError={errors?.fileNumber?.length > 0}
                        error={errors?.fileNumber[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OrganizationSelect
                        id={"add-infraction-organization"}
                        value={formData.organization}
                        setValue={value => setFormData('organization', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.organization"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CostCentralSelect
                        id={"add-infraction-cost-central"}
                        value={formData.costCentral}
                        setValue={value => setFormData('costCentral', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.cost.central"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-denunciation-place"}
                        fullWidth
                        value={formData.denunciationPlace}
                        onChange={value => setFormData('denunciationPlace', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.denunciation.place"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-postal-code"}
                        fullWidth
                        value={formData.postalCode}
                        onChange={value => setFormData('postalCode', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.postal.code"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-district"}
                        fullWidth
                        value={formData.district}
                        onChange={value => setFormData('district', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.district"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <InfringedRuleSelect
                        id={"add-infraction-infringed-rule"}
                        value={formData.infringedRule}
                        setValue={value => setFormData('infringedRule', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.infringed.rule"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-denunciation"}
                        fullWidth
                        value={formData.denunciation}
                        onChange={value => setFormData('denunciation', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.denunciation"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-infraction-notification-date"}
                        fullWidth
                        value={formData.notificationDate}
                        onChange={value => setFormData('notificationDate', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.notification.date"}/>}
                        hasError={errors?.notificationDate?.length > 0}
                        error={errors?.notificationDate[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-amount"}
                        fullWidth
                        type="number"
                        value={formData.amount}
                        onChange={value => setFormData('amount', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.amount"}/>}
                        hasError={errors?.amount?.length > 0}
                        error={errors?.amount[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-infraction-discount-expiration-date"}
                        fullWidth
                        value={formData.discountExpirationDate}
                        onChange={value => setFormData('discountExpirationDate', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.discount.date"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-discount"}
                        type="number"
                        fullWidth
                        value={formData.discount}
                        onChange={value => setFormData('discount', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.discount"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-result"}
                        fullWidth
                        value={formData.result}
                        onChange={value => setFormData('result', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.result"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DefenseSelect
                        id={"add-infraction-defense"}
                        value={formData.defense}
                        setValue={value => setFormData('defense', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.defense"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-infraction-limit-date"}
                        fullWidth
                        value={formData.limitDate}
                        onChange={value => setFormData('limitDate', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.limit.date"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-management"}
                        fullWidth
                        value={formData.management}
                        onChange={value => setFormData('management', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.management"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StateSelect
                        id={"add-infraction-state"}
                        value={formData.state}
                        setValue={value => setFormData('state', value)}
                        label={<IntlMessages id={"fleet.management.infractions.add.state"}/>}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;