import React from "react";
import useForm from "../../../../../../../hooks/Common/useForm";
import BaseCard from "../BaseCard";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import AnalystSelect from "./AnalystSelect";
import SupervisorSelect from "./SupervisorSelect";
import TechnicalSelect from "./TechnicalSelect";
import {useDispatch, useSelector} from "react-redux";
import {editElevatorResponsibleInfo} from "../../../../../../../redux/actions/ElevatorsManagement/Elevators";

const ResponsibleArea = ({initialData}) => {
  const id = "responsible-info-form";
  const {current, filters} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const dispatch = useDispatch();
  const {formData, setFormData, resetForm, handleSubmit} = useForm(initialData);
  const [ edit, setEdit ] = React.useState(false);
  const onCancel = () => {
    resetForm();
    setEdit(false);
  };
  const onSave = data => {
    dispatch(editElevatorResponsibleInfo(current, data, filters));
    setEdit(false);
  };
  return (
    <BaseCard
      onSubmit={handleSubmit(onSave)}
      title={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.responsible"
          }
        />
      }
      edit={edit}
      setEdit={setEdit}
      onCancel={onCancel}
    >
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <AnalystSelect
              id="responsible-analyst"
              value={formData.analyst}
              disabled={!edit}
              setValue={value => setFormData("analyst", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <SupervisorSelect
              id="responsible-supervisor"
              value={formData.supervisor}
              disabled={!edit}
              setValue={value => setFormData("supervisor", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TechnicalSelect
              id="responsible-technical"
              value={formData.technical}
              disabled={!edit}
              setValue={value => setFormData("technical", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </BaseCard>
  );
};

export default ResponsibleArea;
