import ui from "./ui";
import {
  cancelPrevEntitySelectFetchAll,
  cancelPrevEntitySelectFetchAllByCompanyId,
  fetchAllEntitySelect,
  fetchAllEntitySelectByCompanyId
} from "../../../services/EntitySelectService";
import {FETCH_WAREHOUSE_ENTITY} from "../../types/Administrator/WarehouseEntitySelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevEntitySelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchEntitySelectSucceeded = data => ({
  type: FETCH_WAREHOUSE_ENTITY,
  payload: {
    data
  }
});

export const fetchEntitySelect = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevEntitySelectFetchAllByCompanyId();
    await fetchAllEntitySelectByCompanyId().then(entitySelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchEntitySelectSucceeded(entitySelect));
    });
  };
};

export default {
  get: fetchEntitySelect,
  resetState
};
