import React from 'react';
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {Grid, MenuItem} from "@material-ui/core";

const ReferenceSelect = ({value, setValue, edit, errors = null}) => {
    const options = [
        {
            id: 1,
            name: <IntlMessages id={"logistic.work.orders.dialog.destination.reference.select.yes"}/>
        },
        {
            id: 2,
            name: <IntlMessages id={"logistic.work.orders.dialog.destination.reference.select.no"}/>
        }
    ]
    return (
        <Grid item sm={2}>
            <Select
                value={value}
                onChange={e => setValue(e.target.value)}
                id="reference-select"
                label={<IntlMessages id={"logistic.work.orders.dialog.destination.reference.label"}/>}
                fullWidth
                disabled={!edit}
                error={errors?.reference?.length > 0}
                errorMessage={errors?.reference[0]}>
                {options.length && options.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
}

export default ReferenceSelect;