import {
  cancelPrevFetchAllEntities,
  fetchAllEntities
} from "services/EntityService";
import {
  cancelPrevFetchAll,
  changeState,
  create,
  fetchAll,
  update
} from "services/GuardianService";
import {getCompanyEntityId} from "services/StorageService";
import {cancelPrevFetchAllTags, fetchAllTags} from "services/TagService";
import {clearEntities} from "../CustomCommon/Entities";
import {clearTags} from "../CustomCommon/Tags";
import {restoreAdministratorState} from "./Common";
import ui from "./ui";

export const fetchGuardians = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevFetchAllEntities();
  cancelPrevFetchAllTags();
  cancelPrevFetchAll();
  try {
    const tags = await fetchAllTags();
    const entities = await fetchAllEntities();
    const guardians = await fetchAll();

    dispatch(ui.fetchSucceeded());
    dispatch({type: "TAGS_FETCH_ALL_SUCCEEDED", payload: {data: tags}});
    dispatch({type: "ENTITIES_FETCH_ALL_SUCCEEDED", payload: {data: entities}});
    dispatch({
      type: "ADMINISTRATOR_GUARDIANS_FETCH_SUCCEEDED",
      payload: {data: guardians}
    });
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

const changeGuardianState = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  try {
    await changeState(id);
    dispatch({type: "ADMINISTRATOR_GUARDIANS_CHANGE_STATE", payload: {id}});
    dispatch(ui.changeStateSucceeded());
  } catch (error) {
    dispatch(ui.changeStateFailed());
  }
};

export const applyFilters = filters => ({
  type: "ADMINISTRATOR_GUARDIANS_APPLY_FILTERS",
  payload: {filters}
});
export const resetFilters = () => ({
  type: "ADMINISTRATOR_GUARDIANS_RESET_FILTERS"
});

export const deleteGuardian = id => dispatch => {
  dispatch(changeGuardianState(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreGuardian = id => dispatch => {
  dispatch(changeGuardianState(id));
  dispatch(ui.closeRestoreConfirmation());
};

export const editGuardian = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await update(id, data);
    dispatch({
      type: "ADMINISTRATOR_GUARDIANS_EDIT",
      payload: {
        id,
        data: {tags: data.tags.map(tag => tag.name)}
      }
    });
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
  }
};

export const createGuardian = data => async dispatch => {
  dispatch(ui.closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const {id} = await create(data);
    dispatch({
      type: "ADMINISTRATOR_GUARDIANS_CREATE",
      payload: {
        id,
        entityId: getCompanyEntityId(),
        names: data.supervisor.name,
        tags: data.tags.map(tag => tag.name),
        active: true
      }
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};

const cancelAllRequests = () => {
  cancelPrevFetchAll();
  cancelPrevFetchAllTags();
  cancelPrevFetchAllEntities();
  return ui.fetchCancelled();
};

export const resetState = () => dispatch => {
  dispatch(cancelAllRequests());
  dispatch(clearEntities());
  dispatch(clearTags());
  dispatch(restoreAdministratorState());
};

export default {
  get: fetchGuardians,
  delete: deleteGuardian,
  restore: restoreGuardian,
  edit: editGuardian,
  create: createGuardian,
  applyFilters,
  resetFilters,
  resetState
};
