import React from "react";
import TableContainer from "../../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import CrudTableRow from "../../../../../../Common/Tables/CrudTable/CrudTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import VehicleDetailDialog from "./VehicleDetailDialog";
import {Box, Divider, IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import SinisterVehicle from "../../../../../../../domain/FleetManagement/Vehicles/SinisterVehicle";

const VehiclesTable = ({vehicles, setVehicles}) => {
  const [ open, setOpen ] = React.useState(false);
  const [ vehicle, setVehicle ] = React.useState(null);
  const onEdit = row => {
    setVehicle({
      id: row.id,
      vehicle: row.vehicleId,
      insuranceCompany: row.insuranceCompany,
      policy: row.policy,
      status: row.status,
      observations: row.observations,
      ot: row.ot,
      files: row.files,
    });
    setOpen(true);
  };
  const onDelete = row => {};
  const onAdd = () => {
    setOpen(true);
    setVehicle({
      id: 0,
      vehicle: "",
      insuranceCompany: "",
      policy: "",
      status: "",
      observations: "",
      ot: "",
      files: []
    });
  };
  const onCloseDetail = () => {
    setVehicle(null);
    setOpen(false);
  };
  const onSubmit = (data) => {
    if(data.id) {
      setVehicles(vehicles.map(vehicle => {
        if(vehicle.id === data.id) {
          return new SinisterVehicle(
              vehicle.id,
              data.vehicle,
              data.alias,
              data.plaque,
              data.insuranceCompany,
              data.policy,
              data.status,
              data.observations,
              data.ot,
              data.files,
          );
        }
        return vehicle;
      }));
    }else {
      setVehicles([
          ...vehicles,
          new SinisterVehicle(
              vehicles.length,
              data.vehicle,
              "",
              "",
              data.insuranceCompany,
              data.policy,
              data.status,
              data.observations,
              data.ot,
              data.files,
          )
      ]);
    }
  }
  return (
    <React.Fragment>
      <Box marginY={4}>
        <Divider />
        <Box
          marginY={4}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" color="primary">
            <IntlMessages
              id={"fleet.management.accidents.form.vehicles.table"}
            />
          </Typography>
          <IconButton onClick={onAdd}>
            <AddCircleOutlineIcon />
          </IconButton>
        </Box>
        <Divider />
        <VehicleDetailDialog
          setOpen={setOpen}
          open={open}
          initialData={vehicle}
          onClose={onCloseDetail}
          onSubmit={onSubmit}
        />
      </Box>
      <TableContainer head={<TableHeader />}>
        {vehicles.map(vehicle => (
          <CrudTableRow
              key={vehicle.vehicleId}
            onEdit={() => onEdit(vehicle)}
            onDelete={() => onDelete(vehicle)}
          >
            <StyledTableCell>{vehicle.alias}</StyledTableCell>
            <StyledTableCell>{vehicle.plaque}</StyledTableCell>
            <StyledTableCell>{vehicle.insuranceCompany}</StyledTableCell>
            <StyledTableCell>{vehicle.policy}</StyledTableCell>
            <StyledTableCell>{vehicle.status}</StyledTableCell>
          </CrudTableRow>
        ))}
      </TableContainer>
    </React.Fragment>
  );
};

VehiclesTable.propTypes = {};

export default VehiclesTable;
