import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  description: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.tags.form.description.error.empty" />
      )
    }
  ]
};
