import React, {useCallback} from 'react';
import {
    GoogleMap,
    InfoWindow,
    Marker,
    OverlayView,
    Polyline,
    useJsApiLoader,
    useLoadScript
} from "@react-google-maps/api";
import {Box, Card, CardContent, LinearProgress, Paper, Typography} from "@material-ui/core";
import {classes, LocationMarker} from "./styles";
import moment from "moment";

const mapOptions = {
    zoom: 18,
    minZoom: 3,
    mapTypeId: "roadmap",
    mapTypeControl: true,
    disableDefaultUI: false,
    gestureHandling: "greedy",
    clickableIcons: false,
    restriction: {
        latLngBounds: {
            north: 85,
            south: -85,
            west: -180,
            east: 180
        }
    }
}

const BaseMap = ({centerMap, mapWayPoints, setMapWayPoints, coordsPointer=[], children, ...rest}) => {
    const [map, setMap] = React.useState(null);
    const onLoad = useCallback(function callback(map) {
        map.zoom = 18;
        setMap(map)
    }, [])

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDXhVYV0tKNy9kgA6mdHKX2F2H8LlbGnhk',
        libraries: true,
    })

    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -(height / 2),
    });

    const openAndCloseInfoWindow = (item) => {
        const newArr = mapWayPoints.map(obj => {
            if (obj.position === item.position) {
                return { ...obj, isVisibleInfoWindow: !item.isVisibleInfoWindow };
            }
            return obj;
        });
        map.zoom = 18;
        map.panTo(item.position)
        setMapWayPoints(newArr);
    };

    return isLoaded? (
        <GoogleMap
            mapContainerStyle={classes.containerStyle}
            center={centerMap}
            onLoad={onLoad}
            onUnmount={() => setMap(null)}
            options={mapOptions}
            {...rest}
        >
            {children}
            {coordsPointer?.length > 0 &&
                <Polyline
                    path={coordsPointer}
                    geodesic={true}
                    options={{
                        strokeColor: "#ff2343",
                        strokeOpacity: 0.8,
                        strokeWeight: 5,
                        clickable: true
                    }}
                />
            }
            {mapWayPoints?.length > 0 &&
                mapWayPoints.map((wayPoint, index) => (
                    <Marker
                        // clusterer={clusterer}
                        position={wayPoint.position}
                        visible={false}
                        key={`${wayPoint.position}-${index}`}
                    >
                        <OverlayView
                            position={wayPoint.position}
                            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset={getPixelPositionOffset}

                        >
                            <LocationMarker
                                onClick={() => openAndCloseInfoWindow(wayPoint)}
                                style={wayPoint.state === "success" ? classes.pinSuccess : classes.pinNotArrived}/>
                        </OverlayView>
                        {wayPoint.isVisibleInfoWindow ?
                            <InfoWindow
                                onCloseClick={() => openAndCloseInfoWindow(wayPoint)}
                                position={wayPoint.position}
                            >
                                <Card
                                    style={classes.markerDetailBox}
                                >
                                    <CardContent>
                                        <Typography gutterBottom variant="h2" component="h2">
                                            {wayPoint.name}
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            {moment(wayPoint.date).format("DD/MM/yyyy HH:mm:ss")}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </InfoWindow> : null}
                    </Marker>
                ))
            }
        </GoogleMap>
    ): (
        <Box width={"100%"}>
            <LinearProgress />
        </Box>
    );
}

export default BaseMap;