import axiosInstance from "../utils/axiosInstance";
import Expense from "../domain/Expense";
import toBase64 from "../utils/files";
import Response from "../domain/Response";
import {getCompanyEntityId, getUserName} from "./StorageService";
import AccountingExpense from "../domain/AccountingExpense";
import ExpenseState from "../domain/ExpenseState";

export const createExpense = async data => {
  try {
    const file = await toBase64(data.files[0]).catch(error => {
      throw new Error(error.message);
    });
    const response = await axiosInstance.post("/Caja_Chica_Gasto/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_caja_chica: data.box,
      nu_id_anticipo: data.travel || null,
      nu_id_tipo_gasto: data.expenseType,
      nu_id_entidad_conductor: data.driver.id,
      nu_id_ot: data.route.id,
      vc_ruc: data.ruc,
      vc_razon_social: data.businessName,
      vc_direccion: data.address,
      nu_id_tipo_documento: data.documentType,
      vc_nro_doc: data.documentNumber,
      vc_observacion: data.observations,
      nu_imp_gasto: data.amount,
      archivo: {
        archivobase64: file.split(",")[1],
        contentType: "application/pdf",
        extension: "pdf"
      },
      vc_usuario: "USUARIO"
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return responseObject;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllExpensesByAdvance = async advanceId => {
  try {
    const expenses = await axiosInstance.post(
      "/Caja_Chica_Anticipo/sel_gasto",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_anticipo: advanceId
      }
    );
    return expenses.data.map(expense => Expense.map(expense));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateExpenseState = async (expense, operation) => {
  try {
    const result = await axiosInstance.post("/Caja_Chica_Gasto/upd_estado", {
      nu_id_gasto: expense.id,
      ch_resp_aprobacion: operation.approveMessage || null,
      nu_id_tipo_rechazo: operation.expenseReject || null,
      vc_usuario: getUserName()
    });
    const response = Response.map(result.data);
    if (response.transactionCode === "0") {
      throw Error(response.transactionMessage);
    }
    return response.transactionKey;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addExpenseToAccounting = async (accounting, expense) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Contabilizacion_Gastos/ins", {
      nu_id_contabilizacion: accounting.id,
      nu_id_gasto: expense.id,
      vc_usuario: getUserName()
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllExpensesByAccounting = async accounting => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Contabilizacion_Gastos/sel", {
      nu_id_contabilizacion: accounting.id
    });
    return data.map(d => AccountingExpense.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllLiquidatedExpensesByPettyCash = async pettyCashId => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Gasto/sel_liquidados", {
      nu_id_caja_chica: pettyCashId
    });
    return data.map(expense => AccountingExpense.map(expense));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteExpenseFromAccounting = async (accountingId, expense) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Contabilizacion_Gastos/del", {
      nu_id_contabilizacion: accountingId,
      nu_id_gasto: expense.id
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllExpensesStates = async () => {
  try {
    const states = await axiosInstance.post(
      "/Caja_Chica_Gasto/sel_estado",
      {
        // nu_id_entidad: getCompanyEntityId(),
      }
    );
    return states.data.map(state => ExpenseState.map(state));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateExpenseCostCenter = async ({expenseId, costCenter}) => {
  try {
    const {
      data
    } = await axiosInstance.post(
        "/Caja_Chica_Gasto/upd_centro_costo",
        {
      nu_id_gasto: expenseId,
      nu_id_centro_costo: costCenter
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};