import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  clientCode: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.client.code.error.empty" />
      )
    }
  ],
  name: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="administrator.clients.form.name.error.empty" />
    }
  ],
  businessName: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.business.name.error.empty" />
      )
    }
  ],
  country: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.country.code.error.empty" />
      )
    }
  ],
  code: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="administrator.clients.form.code.error.empty" />
    }
  ],
  email: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.email.error.empty" />
      )
    }
  ]
};
