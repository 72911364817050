class Result {
    constructor(errorCode, value, information) {
        this.errorCode = errorCode;
        this.value = value;
        this.information = information;
    }

    static map = result => {
        return new Result(
            result.ErrorCode,
            result.Value,
            result.Information,
        )
    }
}

export default Result;