import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import VehicleBrand from "../../../domain/FleetManagement/Vehicles/VehicleBrand";
import Response from "../../../domain/Response";

export const getAllBrands = async () => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Marca/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(b => VehicleBrand.map(b));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addBrand = async brand => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Marca/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_marca: brand.name
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
