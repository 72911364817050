import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import useForm from 'hooks/Common/useForm';
import Checkbox from 'components/Common/Forms/Checkbox';

import validations from './validations';
import WarehouseCampusSelect from 'containers/Administrator/WarehouseCampusSelect';

const Form = ({id, initialData, onSubmit, edit = false}) => {

    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            name: initialData?.name || '',
            sede: initialData ? ({value: initialData.idCampus, label: initialData.campusName}) : null,
            allowNegativeStock: initialData?.allowNegativeStock || false,
        },
        validations,
    );

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={12}>
                    {edit
                        ? <OutlinedInput
                            id="warehouse-campus"
                            disabled
                            fullWidth
                            label={<IntlMessages id="logistic.warehouse.saveRejectionTypeDialog.field.sede"/>}
                            value={formData.sede.label}
                        />

                        : <WarehouseCampusSelect
                            id="warehouse-campus"
                            label={<IntlMessages id="logistic.warehouse.saveRejectionTypeDialog.field.sede"/>}
                            value={formData.sede}
                            hasError={errors.sede.length > 0}
                            error={errors.sede[0]}
                            onChange={value => setFormData('sede', value)}
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        value={formData.name}
                        hasError={errors.name.length > 0}
                        error={errors.name[0]}
                        fullWidth
                        id="name"
                        label={<IntlMessages id="logistic.warehouse.saveRejectionTypeDialog.field.name"/>}
                        onChange={value => setFormData('name', value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        checked={Number(formData.allowNegativeStock)}
                        onChange={value => setFormData('allowNegativeStock', value)}
                        name="allowNegativeStock"
                        label={<IntlMessages id="logistic.warehouse.saveRejectionTypeDialog.field.allowNegativeStock"/>}
                        fullWidth
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;