export const RESET_MAINTENANCE_PLANS_STATE = "RESET_MAINTENANCE_PLANS_STATE";
export const FETCH_ALL_MAINTENANCE_PLANS = "FETCH_ALL_MAINTENANCE_PLANS";
export const APPLY_MAINTENANCE_PLANS_FILTERS =
  "APPLY_MAINTENANCE_PLANS_FILTERS";
export const RESET_MAINTENANCE_PLANS_FILTERS =
  "RESET_MAINTENANCE_PLANS_FILTERS";
export const SET_CURRENT_MAINTENANCE_PLAN = "SET_CURRENT_MAINTENANCE_PLAN";
export const RESET_CURRENT_MAINTENANCE_PLAN = "RESET_CURRENT_MAINTENANCE_PLAN";
export const ADD_MAINTENANCE_PLAN = "ADD_MAINTENANCE_PLAN";
export const EDIT_MAINTENANCE_PLAN = "EDIT_MAINTENANCE_PLAN";
export const OPEN_MAINTENANCE_PLANS_DIALOG = "OPEN_MAINTENANCE_PLANS_DIALOG";
export const CLOSE_MAINTENANCE_PLANS_DIALOG = "CLOSE_MAINTENANCE_PLANS_DIALOG";
