import React from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
const StateSelect = ({onChange, style, fullWidth, data, states = []}) => {
  return (
    <Select
      id="state"
      label={<IntlMessages id="logistic.header.state" />}
      value={data}
      onChange={e => onChange(e.target.value)}
      style={style}
      fullWidth={fullWidth}
    >
      <MenuItem value="0">
        <IntlMessages id="logistic.filters.states.all" />
      </MenuItem>
      {states.map(s => (
        <MenuItem value={s.id} key={s.id}>
          {s.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StateSelect;
