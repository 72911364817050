class PettyCashType {
  constructor(
    id,
    entityId,
    description,
    approvalOfAdvances,
    obligationOfAdvances,
    automaticSatisfied,
    active
  ) {
    this.id = id;
    this.entityId = entityId;
    this.description = description;
    this.approvalOfAdvances = approvalOfAdvances;
    this.obligationOfAdvances = obligationOfAdvances;
    this.automaticSatisfied = automaticSatisfied;
    this.active = active;
  }

  static map = pettyCashType => {
    return new PettyCashType(
      pettyCashType.nu_id_tipo_caja_chica,
      pettyCashType.nu_id_entidad,
      pettyCashType.vc_desc_tipo_caja_chica,
      pettyCashType.bi_aprobacion_anticipo === "1",
      pettyCashType.bi_obligacion_anticipo === "1",
      pettyCashType.bi_conforme_automatico === "1",
      pettyCashType.bi_estado === "1"
    );
  };
}
export default PettyCashType;
