import React from "react";

import PageContainer from "../../../../../components/FleetManagement/Tachograph/Files/PageContainer";
import FilesTable from "../../../../../components/FleetManagement/Tachograph/Files/FilesTable";
import {useDispatch} from "react-redux";
import {resetTachographFiles} from "../../../../../redux/actions/FleetManagement/TachographFiles";
import ImportDialogs from "../../../../../components/FleetManagement/Tachograph/Files/ImportDialogs";

const Files = () => {
  const [ openZipDialog, setOpenZipDialog ] = React.useState(false);
  const [ openIndividualDialog, setOpenIndividualDialog ] = React.useState(
    false
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetTachographFiles());
    };
  }, []);
  return (
    <PageContainer>
      <FilesTable
        setOpenIndividualDialog={setOpenIndividualDialog}
        setOpenZipDialog={setOpenZipDialog}
      />
      <ImportDialogs
        openZipDialog={openZipDialog}
        setOpenZipDialog={setOpenZipDialog}
        openIndividualDialog={openIndividualDialog}
        setOpenIndividualDialog={setOpenIndividualDialog}
      />
    </PageContainer>
  );
};

export default Files;
