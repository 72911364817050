import React from "react";

import useForm from "hooks/Common/useForm";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import Select from "components/Common/Forms/Select";
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from "@jumbo/utils/IntlMessages";

const Form = ({id, initialData, onSubmit}) => {
    const {formData, setFormData, handleSubmit} = useForm({
        vehicle: initialData?.vehicle || 'BCA-200',
        route: initialData?.route || 'S3',
        turn: initialData?.turn || 'Recojo 1',
        start: initialData?.start || '22/11/21 12:45',
        end: initialData?.end || '22/11/21 15:30',
        disinfection: initialData?.disinfection || '22/11/21 16:00',
        passengers: initialData?.passengers || '15',
        firstPickup: initialData?.firstPickup || '22/11/21 12:53',
        lastPickup: initialData?.lastPickup || '22/11/21 13:42', 
    });

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.vehicle}
                        id="view-trip-information-vehicle"
                        label="Vehículo"
                        disabled
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Select
                        value={formData.route}
                        fullWidth
                        id="route-trip-view"
                        label="Ruta"
                        onChange={e=> setFormData('route', e.target.value)}
                    >
                        <MenuItem value="">Seleccione una ruta</MenuItem>
                        <MenuItem value="S1">S1</MenuItem>
                        <MenuItem value="S2">S2</MenuItem>
                        <MenuItem value="S3">S3</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Select
                        value={formData.turn}
                        fullWidth
                        id="turn-trip-view"
                        label="Turno"
                        onChange={e=> setFormData('turn', e.target.value)}
                    >
                        <MenuItem value="">Seleccione una turno</MenuItem>
                        <MenuItem value="Recojo 1">Recojo 1</MenuItem>
                        <MenuItem value="Recojo 2">Recojo 2</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}></Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.initDate"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.start}
                        id="view-trip-information-start"
                        label="Inicio"
                        onChange={value => setFormData('start', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.passengers"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.passengers}
                        id="view-trip-information-passengers"
                        label="Pasajeros"
                        onChange={value => setFormData('passengers', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.endDate"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.end}
                        id="view-trip-information-end"
                        label="Fin"
                        onChange={value => setFormData('end', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.firstPickup"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.firstPickup}
                        id="view-trip-information-firstPickup"
                        label="Primer Recojo"
                        onChange={value => setFormData('firstPickup', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.disinfection"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.disinfection}
                        id="view-trip-information-disinfection"
                        label="Fecha Desinfección"
                        onChange={value => setFormData('disinfection', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.lastPickup"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.lastPickup}
                        id="view-trip-information-lastPickup"
                        label="Último Recojo"
                        onChange={value => setFormData('lastPickup', value)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;