import React from "react";
import PageContainer from "../../../../components/System/Changelog/PageContainer";
import UpdatesList from "../../../../components/System/Changelog/UpdatesList";

const Changelog = () => (
  <PageContainer>
    <UpdatesList />
  </PageContainer>
);

export default Changelog;
