import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {cloneDeep} from "lodash";
import {v4 as uuidV4} from 'uuid';
import {getWidgetData} from "../../../services/ControlPanel/Kpi/DashboardService";

export const getEcoDrivingData = createAsyncThunk(
    'dashboards/getEcoDrivingData',
    async ({filters, dashboard}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const securityResults = await Promise.allSettled([
                getWidgetData({
                    id: 13,
                    ...filters,
                }),
                getWidgetData({
                    id: 14,
                    ...filters,
                }),
                getWidgetData({
                    id: 15,
                    ...filters,
                }),
                getWidgetData({
                    id: 16,
                    ...filters,
                }),
                getWidgetData({
                    id: 17,
                    ...filters,
                }),
            ]);
            let securityData = {
                events: [],
                eventsByType: [],
                eventsByTypeGrouped: [],
                eventsByDriver: [],
                eventsByVehicle: [],
                details: {
                    data: [],
                    rowCount: 0,
                },
            };
            securityResults.forEach((result, index) => {
                switch (index) {
                    case 0: {
                        if(result.status === 'fulfilled') {
                            securityData.events = result.value.data;
                        }
                        break;
                    }
                    case 1: {
                        if(result.status === 'fulfilled') {
                            securityData.eventsByType = result.value.data;
                        }
                        break;
                    }
                    case 2: {
                        if(result.status === 'fulfilled') {
                            securityData.eventsByTypeGrouped = result.value.data;
                        }
                        break;
                    }
                    case 3: {
                        if(result.status === 'fulfilled') {
                            securityData.eventsByVehicle = result.value.data;
                        }
                        break;
                    }
                    case 4: {
                        if(result.status === 'fulfilled') {
                            securityData.eventsByDriver = result.value.data;
                        }
                        break;
                    }
                    default:
                        break;
                }
            });
            const efficiencyResults = await Promise.allSettled([
                getWidgetData({
                    id: 19,
                    ...filters,
                }),
                getWidgetData({
                    id: 20,
                    ...filters,
                }),
                getWidgetData({
                    id: 21,
                    ...filters,
                }),
                getWidgetData({
                    id: 22,
                    ...filters,
                }),
                getWidgetData({
                    id: 23,
                    ...filters,
                }),
            ]);
            let efficiencyData = {
                events: [],
                eventsByType: [],
                eventsByTypeGrouped: [],
                eventsByDriver: [],
                eventsByVehicle: [],
                details: {
                    data: [],
                    rowCount: 0,
                },
            };
            efficiencyResults?.forEach((result, index) => {
                switch (index) {
                    case 0: {
                        if(result.status === 'fulfilled') {
                            efficiencyData.events = result.value.data;
                        }
                        break;
                    }
                    case 1: {
                        if(result.status === 'fulfilled') {
                            efficiencyData.eventsByType = result.value.data;
                        }
                        break;
                    }
                    case 2: {
                        if(result.status === 'fulfilled') {
                            efficiencyData.eventsByTypeGrouped = result.value.data;
                        }
                        break;
                    }
                    case 3: {
                        if(result.status === 'fulfilled') {
                            efficiencyData.eventsByVehicle = result.value.data;
                        }
                        break;
                    }
                    case 4: {
                        if(result.status === 'fulfilled') {
                            efficiencyData.eventsByDriver = result.value.data;
                        }
                        break;
                    }
                    default:
                        break;
                }
            });
            dispatch(fetchSuccess());
            const currentDashboard = cloneDeep(dashboard);
            currentDashboard.widgets = {
                security: securityData,
                efficiency: efficiencyData,
            };
            return {filters, currentDashboard};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)


export const getEcoDrivingSecurityTableData = createAsyncThunk(
    'dashboards/getEcoDrivingSecurityTableData',
    async ({filters, page, pageSize}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const widget = await getWidgetData({
                id: 18,
                ...filters,
                page,
                pageSize,
            });
            dispatch(fetchSuccess());
            const details = widget.data.map(row => ({
                id: uuidV4(),
                date: row.fechaInicio,
                plaque: row.Vehiculo,
                group: row.TipoVehiculo,
                address: row.Direccion,
                type: row.idTipoEvento,
            }));
            const rowCount = widget?.data[0]?.total ?? 0;
            return {rowCount, data: details};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const getEcoDrivingEfficiencyTableData = createAsyncThunk(
    'dashboards/getEcoDrivingEfficiencyTableData',
    async ({filters, page, pageSize}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const widget = await getWidgetData({
                id: 24,
                ...filters,
                page,
                pageSize,
            });
            dispatch(fetchSuccess());
            const details = widget.data.map(row => ({
                id: uuidV4(),
                date: row.fechaInicio,
                plaque: row.Vehiculo,
                group: row.TipoVehiculo,
                driver: row.Conductor,
                gravity: row.Gravedad,
                ralentiTime: row.minutosRalentí,
                address: row.Direccion,
            }));
            const rowCount = widget?.data[0]?.total ?? 0;
            return {rowCount, data: details};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)