class VehicleFuel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static map = model => {
    return new VehicleFuel(
      model.nu_id_tipo_combustible,
      model.vc_tipo_combustible
    );
  };
}

export default VehicleFuel;
