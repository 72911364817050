import React from "react";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import RestoreIcon from "@material-ui/icons/Restore";
import {parseDateToGlobalFormatWithSimpleYear} from "../../../../utils/dates";
import {DataGrid} from "@mui/x-data-grid";
import {useDispatch, useSelector} from "react-redux";
import products from "../../../../redux/actions/Administrator/Products";

const Table = ({onEdit, onDelete, onRestore}) => {
    const intl = useIntl();
    const {data, rowCount = 0, filters} = useSelector(({administrator}) => administrator.product);
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const handleDelete = (row) => {
        if (row.active) {
            onDelete(row.id);
        } else {
            onRestore(row.id);
        }
    }

    const onPageChange = (page) => {
        setPage(page);
    }
    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
    }
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'description',
            headerName: intl.messages['description'],
            width: 200,
            filterable: false
        },
        {
            field: 'codExterno',
            headerName: intl.messages["administrator.Products.table.codExterno"],
            width: 200,
            filterable: false
        },
        {
            field: 'esEnvase',
            headerName: intl.messages["administrator.Products.table.esEnvase"],
            width: 200,
            renderCell: ({value}) => value? intl.messages["table.cell.yes"] : intl.messages["table.cell.no"],
            filterable: false
        },
        {
            field: 'unidad',
            headerName: intl.messages["administrator.Products.table.unidad"],
            width: 200,
            filterable: false
        },
        {
            field: 'volumenUnidad',
            headerName: intl.messages["administrator.Products.table.volumenUnidad"],
            width: 200,
            filterable: false
        },
        {
            field: 'fechaAlta',
            headerName: intl.messages["administrator.Products.table.fechaAlta"],
            renderCell: ({value}) => parseDateToGlobalFormatWithSimpleYear(value),
            width: 200,
            filterable: false
        },
        {
            field: 'fechaBaja',
            headerName: intl.messages["administrator.Products.table.fechaBaja"],
            renderCell: ({value}) => parseDateToGlobalFormatWithSimpleYear(value),
            width: 200,
            filterable: false
        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            type: 'actions',
            renderCell: ({row}) => {
                const {active} = row;
                return (
                    <>
                        {active &&
                            <IconButton onClick={() => onEdit(row.id)}>
                                <EditIcon/>
                            </IconButton>
                        }
                        <IconButton onClick={() => handleDelete(row)}>
                            {active ? <DeleteIcon/> : <RestoreIcon/>}
                        </IconButton>
                    </>
                )
            }
        }
    ]
    React.useEffect(() => {
        dispatch(products.get({
            filters,
            page,
            pageSize
        }));
    }, [page, pageSize, filters]);
    return (
        <DataGrid
            columns={columns}
            rows={data}
            style={{
                height: '60vh'
            }}
            rowsPerPageOptions={[10, 20, 50]}
            pagination
            rowHeight={50}
            rowCount={rowCount}
            page={page}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            paginationMode='server'
        />
    );
};

Table.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            description: PropTypes.string,
            active: PropTypes.bool
        })
    ),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onRestore: PropTypes.func
};

Table.defaultProps = {
    data: [],
    onEdit: () => {
    },
    onDelete: () => {
    },
    onRestore: () => {
    }
};

export default Table;
