class CashManagerEntity {
  constructor(id, entityId, name, state) {
    this.id = id;
    this.entityId = entityId;
    this.name = name;
    this.state = state;
  }

  static map = cashManagerEntity => {
    return new CashManagerEntity(
      cashManagerEntity.nu_id_entidad_gestor_caja,
      cashManagerEntity.nu_id_entidad,
      cashManagerEntity.vc_nombre_entidad_gestor_caja,
      cashManagerEntity.bi_estado
    );
  };
}

export default CashManagerEntity;
