const INIT_STATE = {
  entities: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CLEAR_ENTITIES": {
      return {
        ...state,
        entities: []
      };
    }
    case "ENTITIES_FETCH_ALL_SUCCEEDED": {
      return {
        ...state,
        entities: [ ...action.payload.data ]
      };
    }
    default: {
      return state;
    }
  }
};
