import {
  ADD_HEADQUARTERS_SUCCESS,
  APPLY_CLIENTS_FILTERS,
  CREATE_CLIENTS_SUCCESS,
  EDIT_CLIENT_STARTED,
  EDIT_CLIENT_SUCCESS,
  EDIT_HEADQUARTER_STARTED,
  EDIT_HEADQUARTER_SUCCESS,
  ERROR_ON_TRANSACTION,
  FETCH_ALL_CLIENTS_STARTED,
  FETCH_ALL_CLIENTS_SUCCESS,
  FETCH_ALL_HEADQUARTERS_BY_CLIENT_STARTED,
  FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS,
  RESET_CLIENTS_FILTERS,
  RESET_CURRENT_CLIENT,
  SET_CURRENT_CLIENT
} from "../../types/Administrator/ClientsTypes";
import {
  closeCreateForm,
  closeEditForm,
  createFailed,
  createStarted,
  createSucceeded,
  editStarted,
  editSucceeded,
  fetchFailed,
  fetchStarted,
  fetchSucceeded,
  setOnErrorMessage
} from "./ui";
import {
  createClient,
  fetchAllClients,
  updateClient, updateClientState
} from "../../../services/ClientsService";
import {
  addHeadquarterToClient,
  fetchAllHeadquartersByClientId, updateHeadquarterState,
  updateHeadquarterToClient
} from "../../../services/HeadquarterService";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

const fetchAllClientsSuccess = clients => {
  return dispatch => {
    dispatch(fetchSucceeded());
    dispatch({type: FETCH_ALL_CLIENTS_SUCCESS, payload: clients});
  };
};

export const fetchAllClientsStarted = () => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_CLIENTS_STARTED});
    dispatch(fetchStarted());
    fetchAllClients()
      .then(clients => {
        dispatch(fetchAllClientsSuccess(clients));
      })
      .catch(e => {
        dispatch(fetchFailed());
        dispatch(setOnErrorMessage(e.message));
      });
  };
};

export const setCurrentClients = client => {
  return {
    type: SET_CURRENT_CLIENT,
    payload: client
  };
};

export const resetCurrentClient = () => {
  return {
    type: RESET_CURRENT_CLIENT
  };
};

export const applyClientFilters = filters => {
  return {
    type: APPLY_CLIENTS_FILTERS,
    payload: filters
  };
};

export const resetClientFilters = () => {
  return {
    type: RESET_CLIENTS_FILTERS
  };
};

const createClientSuccess = response => {
  return dispatch => {
    dispatch({
      type: CREATE_CLIENTS_SUCCESS,
      payload: response.transactionsMessage
    });
    dispatch(createSucceeded());
    dispatch(fetchAllClientsStarted());
  };
};

export const createClientStarted = data => {
  return async dispatch => {
    dispatch(closeCreateForm());
    dispatch(createStarted());
    createClient(data)
      .then(response => {
        dispatch(createClientSuccess(response));
      })
      .catch(e => {
        dispatch(createFailed());
        dispatch(setOnErrorMessage(e.message));
      });
  };
};

const fetchAllHeadquartersByClientIdSuccess = headquarters => {
  return dispatch => {
    dispatch({
      type: FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS,
      payload: headquarters
    });
  };
};

export const fetchAllHeadquartersByClientIdStarted = clientId => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_HEADQUARTERS_BY_CLIENT_STARTED});
    fetchAllHeadquartersByClientId(clientId)
      .then(headquarters =>
        dispatch(fetchAllHeadquartersByClientIdSuccess(headquarters))
      )
      .catch(e => {
        dispatch(setOnErrorMessage(e.message));
        dispatch({type: FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS});
      });
  };
};

const addHeadquarterToClientSuccess = (response, clientId) => {
  return dispatch => {
    dispatch({type: ADD_HEADQUARTERS_SUCCESS});
    dispatch(fetchAllHeadquartersByClientIdStarted(clientId));
  };
};

export const addHeadquarterToClientStarted = (client, data) => {
  return async dispatch => {
    addHeadquarterToClient(client, data)
      .then(response =>
        dispatch(addHeadquarterToClientSuccess(response, client.id))
      )
      .catch(e => {
        dispatch(setOnErrorMessage(e.message));
        dispatch({type: ADD_HEADQUARTERS_SUCCESS});
      });
  };
};

const editClientSuccess = (message, filters) => {
  return dispatch => {
    dispatch(editSucceeded());
    dispatch(fetchSuccess(message));
    dispatch({type: EDIT_CLIENT_SUCCESS, payload: message});
    dispatch(fetchAllClientsStarted());
  };
};

export const editClientStarted = (client, filters) => {
  return async dispatch => {
    dispatch(closeEditForm());
    dispatch(editStarted());
    dispatch({type: EDIT_CLIENT_STARTED, payload: client});
    dispatch(fetchStart());
    updateClient(client)
      .then(response => {
        dispatch(editClientSuccess(response.transactionMessage, filters));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};


export const editClientState = clientId => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const response = await updateClientState(clientId);
      dispatch(fetchSuccess(response.transactionMessage));
      setTimeout(() => {
        dispatch(fetchAllClientsStarted());
      }, 1500);
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
}

const editHeadquarterSuccess = (message, clientId) => {
  return dispatch => {
    dispatch(fetchAllHeadquartersByClientIdStarted(clientId));
    dispatch({type: EDIT_HEADQUARTER_SUCCESS, payload: message});
  };
};

export const editHeadquarterStarted = (headquarter, client, closeDialog) => {
  return async dispatch => {
    dispatch({type: EDIT_HEADQUARTER_STARTED});
    updateHeadquarterToClient(client, headquarter)
      .then(response => {
        dispatch(
          editHeadquarterSuccess(response.transactionMessage, client.id)
        );
        closeDialog();
      })
      .catch(e => {
        dispatch(setOnErrorMessage(e.message));
        dispatch({type: EDIT_HEADQUARTER_SUCCESS});
      });
  };
};

export const editHeadquarterState = (clientId, headquarterId) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const response = await updateHeadquarterState(clientId, headquarterId);
      dispatch(fetchSuccess(response.transactionMessage));
      setTimeout(() => {
        dispatch(fetchAllHeadquartersByClientIdStarted(clientId));
      }, 1500);
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
}