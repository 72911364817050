import React from 'react';
import {Box, Divider, Grid, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useStyles} from "../../styles";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Checkbox from "../../../../../Common/Forms/Checkbox";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import ChecklistsArea from "../ChecklistsArea";
import DepartureManagementArea from "../Common/DepartureManagementArea";

const DefaultStateItem = ({
                              id,
                              title,
                              description,
                              formData,
                              setFormData,
                              checklist,
                              onSaveChanges,
                              add = false,
                              resetForm
                          }) => {
    const [edit, setEdit] = React.useState(add);
    const [list, setList] = React.useState(checklist);
    const classes = useStyles();
    const onSave = () => {
        onSaveChanges && onSaveChanges(list);
        setEdit(false);
    }
    const onUpdateChecklist = (item) => {
        setList(list.map(c => {
            if (item.id === c.id) {
                return {
                    ...c,
                    status: !c.status,
                };
            }
            return c;
        }))
    }
    return (
        <React.Fragment>
            <Box className={classes.defaultBigBox} component={Paper}>
                <Box className={classes.titleArea} py={3}>
                    <Typography>
                        {title}
                    </Typography>
                </Box>
                <Box className={classes.principalItemBox}>
                    <Box>
                        <Typography>
                            {description}
                        </Typography>
                    </Box>
                    <Box>
                        <OutlinedInput
                            id={id + '-name'}
                            fullWidth
                            value={formData.name}
                            disabled={!edit}
                            onChange={value => setFormData('name', value)}
                            label={<IntlMessages id={"logistic.distribution.work.orders.types.detail.state.label"}/>}
                        />
                    </Box>
                    <Divider/>
                    <GridContainer spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Box className={classes.defaultShortBox}>
                                <Checkbox
                                    id={id + '-confirmation'}
                                    fullWidth
                                    disabled={!edit}
                                    checked={formData.confirmation}
                                    onChange={value => setFormData('confirmation', value)}
                                    label={<IntlMessages
                                        id={"logistic.distribution.work.orders.types.detail.state.confirmation"}/>}
                                />
                                <Checkbox
                                    id={id + '-permission'}
                                    fullWidth
                                    disabled={!edit}
                                    checked={formData.permission}
                                    onChange={value => setFormData('permission', value)}
                                    label={<IntlMessages
                                        id={"logistic.distribution.work.orders.types.detail.state.permission"}/>}

                                />
                            </Box>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <ChecklistsArea
                                edit={edit}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Grid>
                        {list?.length > 0 &&
                            <Grid item sm={12} md={6}>
                                <Box>
                                    <Box>
                                        <Typography>
                                            <IntlMessages
                                                id={"logistic.distribution.work.orders.types.detail.state.checklist"}/>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {
                                            list.map(item => (
                                                <Checkbox
                                                    key={item.id}
                                                    id={id + item.id}
                                                    fullWidth
                                                    disabled={!edit}
                                                    checked={item.status}
                                                    onChange={value => onUpdateChecklist(item)}
                                                    label={item.name}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        }
                        <Grid item sm={12} md={6}>
                            <DepartureManagementArea
                            id={id}
                            formData={formData}
                            edit={edit}
                             setFormData={setFormData}
                            />
                        </Grid>
                    </GridContainer>
                    <Box className={classes.buttonBox}>
                        {
                            edit
                                ? <React.Fragment>
                                    <ContainedButton
                                        text={<IntlMessages id={"form.button.cancel"}/>}
                                        color={"primary"}
                                        onClick={() => {
                                            setEdit(false)
                                            resetForm && resetForm();
                                            setList(checklist)
                                        }}
                                    />
                                    <ContainedButton
                                        text={<IntlMessages id={"form.button.save"}/>}
                                        color={"primary"}
                                        onClick={() => onSave()}
                                    />
                                </React.Fragment>
                                : <ContainedButton
                                    text={<IntlMessages id={"form.button.edit"}/>}
                                    color={"primary"}
                                    onClick={() => setEdit(true)}
                                />
                        }
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default DefaultStateItem;