import {combineReducers} from "redux";
import Elevators from "./Elevators";
import Models from "./Models";
import Kens from "./Kens";
import Categories from "./Categories";
import States from "./States";
import Contracts from "./Contracts";
import Details from "./Details";
import genres from "./genres";
import manufacturers from "./manufacturers";
import types from "./types";

export default combineReducers({
  elevators: Elevators,
  models: Models,
  kens: Kens,
  categories: Categories,
  states: States,
  details: Details,
  contracts: Contracts,
  genres,
  manufacturers,
  types,
});
