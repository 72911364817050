import {
  FETCH_ALL_INFRACTIONS,
  RESET_INFRACTIONS_FILTERS,
  RESET_INFRACTIONS_STATE,
  APPLY_INFRACTIONS_FILTERS,
  ADD_INFRACTION,
  SET_CURRENT_INFRACTION,
  EDIT_INFRACTION
} from "../../types/FleetManagement/InfractionsType";
import {fetchStart, fetchSuccess} from "../Common";

const data = [
  {
    id: 1,
    plaque: "ADC-145",
    date: new Date().toISOString(),
    driver: "Pedro",
    infringedRule: "AAAAAA",
    amount: 1500
  },
  {
    id: 2,
    plaque: "ABC-123",
    date: new Date().toISOString(),
    driver: "Pablo",
    infringedRule: "SSSSS",
    amount: 1600
  }
];

export const resetInfractionsState = () => ({type: RESET_INFRACTIONS_STATE});
export const resetInfractionsFilters = () => ({
  type: RESET_INFRACTIONS_FILTERS
});
export const applyInfractionsFilters = filters => ({
  type: APPLY_INFRACTIONS_FILTERS,
  payload: filters
});
export const fetchAllInfractions = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: FETCH_ALL_INFRACTIONS, payload: data});
    }, 2000);
  };
};
export const addInfraction = (data, onClose) => {
  return async dispatch => {
    const newInfraction = {
      ...data,
      amount: Number(data.amount)
    };
    dispatch({
      type: ADD_INFRACTION,
      payload: {id: data.length + 1, ...newInfraction}
    });
    onClose && onClose();
  };
};

export const editInfraction = (data, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: EDIT_INFRACTION, payload: data});
      onClose && onClose();
    }, 1500);
  };
};

export const setCurrentInfraction = infraction => ({
  type: SET_CURRENT_INFRACTION,
  payload: infraction
});
