import {
  APPLY_CONFIG_USERS_FILTERS,
  EDIT_CONFIG_USER,
  EDIT_CONFIG_USER_STATE,
  FETCH_ALL_CONFIG_USERS,
  RESET_CONFIG_USERS_FILTERS,
  RESET_CONFIG_USERS_STATE,
  SET_CURRENT_CONFIG_USER
} from "../../types/Configuration/UsersTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  fetchAllConfigUsers,
  updateConfigUserRole,
  updateConfigUserState
} from "../../../services/Configuration/ConfigUserService";
import User from "../../../domain/Configuration/User";

export const resetConfigurationUsersState = () => ({
  type: RESET_CONFIG_USERS_STATE
});
export const setCurrentConfigurationUser = user => ({
  type: SET_CURRENT_CONFIG_USER,
  payload: user
});
export const applyConfigUsersFilters = filters => ({
  type: APPLY_CONFIG_USERS_FILTERS,
  payload: filters
});
export const resetConfigUsersFilters = () => ({
  type: RESET_CONFIG_USERS_FILTERS
});

export const fetchAllConfigurationUsers = () => {
  return async dispatch => {
    dispatch(fetchStart());
    fetchAllConfigUsers()
      .then(users => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_CONFIG_USERS, payload: users});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const updateConfigurationUserState = (user, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateConfigUserState(user, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: EDIT_CONFIG_USER_STATE, payload: {id: user.id, state}});
        dispatch(setCurrentConfigurationUser(null));
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const updateConfigurationUser = (user, roles, vehicleRoles) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateConfigUserRole(user, roles, vehicleRoles)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const updatedUser = new User(
          user.id,
          user.email,
          roles,
          vehicleRoles,
          user.state
        );
        dispatch({type: EDIT_CONFIG_USER, payload: updatedUser});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
    setTimeout(() => {}, 1500);
  };
};
