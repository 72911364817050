import React from 'react';
import Select from "../../../Common/Forms/Select";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const OrderType = ({value, onChange, disabled}) => {
    const options = [
        {
            value: 'O',
            label: <IntlMessages id="sale.order" />
        },
        {
            value: 'S',
            label: <IntlMessages id="service.order" />
        }
    ]
    const handleChange = ({target}) => {
        const {value} = target;
        onChange(value);
    }
    return (
        <Select
            name="orderType"
            label={<IntlMessages id="order.type" />}
            value={value}
            onChange={handleChange}
            options={options}
            fullWidth
            disabled={disabled}
        />
    );
};

export default OrderType;