import {minutesToNoStringHours, minutesToTime} from "../../../utils/parsers";

class TrafficChartData {
  constructor(
    id,
    currentAvailability,
    lastWeekAvailability,
    drivingHours,
    currentDate
  ) {
    this.id = id;
    this.currentWeekAvailability = this.parseAvailabilityData(
      currentAvailability
    );
    this.lastWeekAvailability = this.parseAvailabilityData(
      lastWeekAvailability
    );
    this.drivingHours = drivingHours;
    this.currentDate = currentDate;
  }

  parseAvailabilityData(data) {
    const names = Object.keys(data);
    return names.map(name => ({
      name,
      hours: data[name]
    }));
  }

  static map = chartData => {
    const drivingHours = [
      {
        name: "Hoy",
        drivingHours: minutesToNoStringHours(chartData.nu_conduccion_dia)
      },
      {
        name: "Sem. Actual",
        drivingHours: minutesToNoStringHours(
          chartData.nu_conduccion_semana_actual
        )
      },
      {
        name: "Sem. Anterior",
        drivingHours: minutesToNoStringHours(
          chartData.nu_conduccion_semana_anterior
        )
      }
    ];
    return new TrafficChartData(
      chartData.nu_id_trafico,
      JSON.parse(chartData.vc_disponibilidad_dia_actual),
      JSON.parse(chartData.vc_disponibilidad_semana_actual),
      drivingHours,
      chartData.dt_fecha_actual
    );
  };
}

export default TrafficChartData;
