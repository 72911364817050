import React from 'react';
import MapWithRoute from "../../../../../Common/Maps/Google/MapWithRoute";

const WorkOrdersMap = ({destinations=[]}) => {
    const [markerPoints, setMarkerPoints] = React.useState([]);
    React.useEffect(() => {
        setMarkerPoints(destinations.map(d => ({
            position: new window.google.maps.LatLng(d.latitude, d.longitude),
            state: "success",
            name: d.name
        })));
    }, [destinations]);
    return(
        <MapWithRoute
            wayPoints={markerPoints}
            setWayPoints={setMarkerPoints}
            centerMap={
                {
                    lat: destinations[0]?.latitude || -12.064440,
                    lng: destinations[0]?.longitude || -77.039028
                }
            }
        />
    );
}

export default WorkOrdersMap;