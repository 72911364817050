import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import MerchandiseReception from "domain/MerchandiseReception";
import Response from "domain/Response";
import {
  getCompanyEntityId,
  getUserId,
  getUserName,
  getUserType
} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevMerchandiseReceptionFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllMerchandiseReception = async () => {
  try {
    const {data} = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_recepcion_sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_conductor: getUserType().includes("guardian")
          ? getUserId()
          : null
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return data.map(reception => MerchandiseReception.map(reception));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const getReceptionDetails = async ({id: receptionId}) => {
  try {
    const {data} = await axiosInstance.post(
        "/Inventario_Movimiento_Det/sel",
        {
          nu_id_movimiento: receptionId,
        },
        {
          cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
        }
    );
    return data.map(e => ({
      id: e.nu_id_mov_det,
      idProd: e.nu_id_producto,
      product: e.vc_desc_producto,
      amount: e.nu_cantidad,
      incoming: e.nu_entrante,
      missing: e.nu_faltante,
      refund: e.nu_devolucion,
      return: e.nu_retorno,
      currentStock: e.nu_stock_actual
    }));
  }catch (e) {
    return Promise.reject(e);
  }
}

export const acceptTransfer = async id => {
  console.log("*****ACEPTAR TRASPASO: ", id);
  //return 1;
  try {
    const response = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_aprobar",
      {
        nu_id_movimiento: id
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const rejectTransfer = async id => {
  console.log("***********Rechazo traspaso: ", id);
  //return 1;
  try {
    const response = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_rechazar",
      {
        nu_id_movimiento: id
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
