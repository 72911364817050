import AccountingStates from "../../../models/Guardian/AccountingStates";
import {
  ACCOUNTING_FETCH_ALL_BY_USER_STARTED,
  ACCOUNTING_FETCH_ALL_BY_USER_SUCCESS,
  RESET_ACCOUNTING_FILTERS,
  APPLY_ACCOUNTING_FILTERS,
  SET_TRANSACTION_RESULT,
  RESET_TRANSACTION_RESULT,
  APPROVE_ACCOUNTING_STARTED,
  APPROVE_ACCOUNTING_SUCCESS,
  REJECT_ACCOUNTING_STARTED,
  REJECT_ACCOUNTING_SUCCESS,
  SET_ACCOUNTING_ACTIVE_FILTERS,
  RESET_ACCOUNTING_ACTIVE_FILTERS,
  DELETE_ACCOUNTING_STARTED,
  DELETE_ACCOUNTING_SUCCESS, SET_CURRENT_ACCOUNTING_EXPENSE
} from "../../types/Guardian/AccountingTypes";

const INIT_STATE = {
  data: [],
  filters: {
    accountingNumber: "",
    lowerDate: "",
    upperDate: "",
    quantity: "",
    state: [],
    pettyCashAmount: "",
    tag: ''
  },
  isLoading: false,
  operation: undefined,
  transaction: {
    code: undefined,
    message: ""
  },
  activeFilters: undefined,
  currentExpense: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ACCOUNTING_FETCH_ALL_BY_USER_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: "fetch"
      };
    }
    case ACCOUNTING_FETCH_ALL_BY_USER_SUCCESS: {
      return {
        ...state,
        data: [ ...action.payload.data ],
        isLoading: false,
        operation: undefined
      };
    }
    case RESET_ACCOUNTING_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case APPLY_ACCOUNTING_FILTERS: {
      return {
        ...state,
        filters: {...action.payload.filters}
      };
    }
    case SET_TRANSACTION_RESULT: {
      return {
        ...state,
        transaction: {
          ...action.payload
        }
      };
    }
    case RESET_TRANSACTION_RESULT: {
      return {
        ...state,
        transaction: {
          ...INIT_STATE.transaction
        }
      };
    }
    case APPROVE_ACCOUNTING_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: "approve"
      };
    }
    case APPROVE_ACCOUNTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: undefined
      };
    }
    case REJECT_ACCOUNTING_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: "reject"
      };
    }
    case REJECT_ACCOUNTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: undefined
      };
    }
    case DELETE_ACCOUNTING_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: "delete"
      };
    }
    case DELETE_ACCOUNTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        operation: undefined
      };
    }
    case SET_ACCOUNTING_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: {...action.payload}
      };
    }
    case SET_CURRENT_ACCOUNTING_EXPENSE: {
      return {
        ...state,
        currentExpense: action.payload,
      }
    }
    case RESET_ACCOUNTING_ACTIVE_FILTERS: {
      return {
        ...state,
        activeFilters: undefined
      };
    }
    default: {
      return state;
    }
  }
};
