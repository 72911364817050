import React, {useEffect} from 'react';
import Viewer from "./Viewer";
import {useSelector} from "react-redux";
import {downloadFile} from "../../../../../services/FileService";
import Filters from "./Filters";
import TimeProcess from "./Tables/TimeProcess";
import InverseLogistic from "./Tables/InverseLogistic";

const DistributionReports = () => {
    const {file, fileType: type, type: reportId} = useSelector(
        ({controlPanel}) => controlPanel.kpi.current
    );
    useEffect(
        () => {
            if (file && type === 2) {
                downloadFile(
                    "report.xlsx",
                    `data:application/vnd.ms-excel;base64,${file}`
                );
            }
        },
        [ file ]
    );
    return(
        <React.Fragment>
            <Filters />
            {file && type === 1 && <Viewer/>}
            {reportId === 33 && <TimeProcess />}
            {reportId === 34 && <InverseLogistic />}
        </React.Fragment>
    );
}

export default DistributionReports;