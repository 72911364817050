import React from 'react';
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {getTachographDrivers} from "../../../../../../services/FleetManagement/Tachograph/TachographTrafficService";

const DriversSelect = ({value, onChange, disabled=false}) => {
    const {data: drivers} = useFetch(getTachographDrivers, {}, []);
    return (
        <MultipleSelect
            fullWidth
            label={<IntlMessages id="drivers" />}
            value={value}
            onChange={value => onChange(value)}
            disabled={disabled}
            options={drivers}
        />
    );
};

export default DriversSelect;