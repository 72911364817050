import React from "react";
import PropTypes from "prop-types";

import VisibilityIcon from "@material-ui/icons/Visibility";

import StyledTableCell from "../../StyledTableCell";
import StyledTableRow from "../../StyledTableRow";
import {IconButton} from "@material-ui/core";

const ShowTableRow = ({children, onShow, otherActions}) => (
  <StyledTableRow>
    {children}
    <StyledTableCell align="right">
      <IconButton onClick={onShow}>
        <VisibilityIcon />
      </IconButton>
      {otherActions}
    </StyledTableCell>
  </StyledTableRow>
);

ShowTableRow.propTypes = {
  children: PropTypes.node,
  onShow: PropTypes.func,
  otherActions: PropTypes.node
};

ShowTableRow.defaultProps = {
  children: null,
  onShow: () => {},
  otherActions: null
};

export default ShowTableRow;
