import React from "react";
import FiltersContainer from "../../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";

const Filters = ({initData, onApplyFilters, onResetFilters}) => {
  const {formData, setFormData, setValues, resetForm} = useForm({
    code: "",
    name: ""
  });
  React.useEffect(() => {
    setValues(initData);
  }, []);
  const onReset = () => {
    resetForm();
    onResetFilters && onResetFilters();
  };
  return (
    <Box my={2}>
      <FiltersContainer active>
        <form>
          <GridContainer>
            <Grid item xs={12} md={4}>
              <OutlinedInput
                id={"code-filter"}
                label={
                  <IntlMessages id="elevators.management.elevators.types.table.code" />
                }
                value={formData.code}
                fullWidth
                onChange={value => setFormData("code", value)}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <OutlinedInput
                id={"name-filter"}
                label={
                  <IntlMessages id="elevators.management.elevators.types.table.name" />
                }
                value={formData.name}
                fullWidth
                onChange={value => setFormData("name", value)}
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <ContainedButton
                onClick={e => {
                  e.preventDefault();
                  onReset();
                }}
                text={<IntlMessages id={"filters.button.clear"} />}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <ContainedButton
                onClick={e => {
                  e.preventDefault();
                  onApplyFilters && onApplyFilters(formData);
                }}
                color="primary"
                text={<IntlMessages id={"filters.button.apply"} />}
              />
            </Grid>
          </GridContainer>
        </form>
      </FiltersContainer>
    </Box>
  );
};

export default Filters;
