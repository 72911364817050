import {
  APPLY_FILES_FILTERS,
  FETCH_ALL_FILES,
  RESET_FILES_FILTERS,
  RESET_FILES_STATE
} from "../../types/FleetManagement/FilesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    drivers: [],
    vehicles: [],
    fromDate: "",
    toDate: ""
  }
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_FILES: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_FILES_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_FILES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case RESET_FILES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
