import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {editOrderState, fetchProviderOrderDetails} from "../../../services/Logistics/Purchases/ProviderOrdersService";
import {editOrderItemDetails, fetchAllOrders} from "../../../services/Logistics/Distribution/OrdersService";

const nameSpace = "logistic/orders";
export const getAllOrders = createAsyncThunk(
    `${nameSpace}/getAllOrders`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const orders = await fetchAllOrders();
            dispatch(fetchSuccess());
            return orders;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getOrderDetails = createAsyncThunk(
    `${nameSpace}/getOrderDetails`,
    async ({order}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const details = await fetchProviderOrderDetails(order.id)
            dispatch(fetchSuccess());
            return {
                id: order.id,
                orderDate: order.orderDate,
                ...details,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const updateOrderState = createAsyncThunk(
    `${nameSpace}/updateOrderState`,
    async ({order, stateId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editOrderState({
                orderId: order.id,
                stateId,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateOrderItemDetails = createAsyncThunk(
    `${nameSpace}/updateOrderItemDetails`,
    async ({order, itemId, request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editOrderItemDetails({
                orderId: order.id,
                itemId: itemId,
                request,
            });
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() =>{
                dispatch(getOrderDetails({
                    order: order,
                }));
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);