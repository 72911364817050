class AccountingState {
  constructor(id, description) {
    this.id = id;
    this.description = description;
    this.name = description;
  }

  static map = state => {
    return new AccountingState(
      parseInt(state.nu_id_estado),
      state.vc_desc_estado
    );
  };
}

export default AccountingState;
