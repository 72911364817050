import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  supervisor: [
    {
      rule: v => v !== null,
      message: (
        <IntlMessages id="administrator.form.supervisorsAutocomplete.error.empty" />
      )
    }
  ],
  tags: [
    {
      rule: v => v.length > 0,
      message: <IntlMessages id="administrator.form.tagsPicker.error.empty" />
    }
  ]
};
