import React from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MaterialUISelect
} from "@material-ui/core";

const Select = ({
  error,
  label,
  value,
  onChange,
  disabled,
  id,
  children,
  options,
  errorMessage,
  fullWidth,
  style,
  displayEmpty,
    required = false
}) => {
  return (
    <FormControl
      error={error}
      variant="outlined"
      size="small"
      fullWidth={fullWidth}
      style={style}
      required={required}
    >
      <InputLabel
        shrink
        htmlFor={id}
        style={{
          backgroundColor: "#f4f4f7"
        }}
      >
        {label}
      </InputLabel>
      <MaterialUISelect
        value={value}
        id={id}
        label={label}
        onChange={onChange}
        disabled={disabled}
        displayEmpty={displayEmpty}
      >
        {children ? (
          children
        ) : (
          options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label || option.name}
            </MenuItem>
          ))
        )}
      </MaterialUISelect>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
};

Select.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.node,
  value: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.node,
  errorMessage: PropTypes.node,
  fullWidth: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string | PropTypes.number,
      label: PropTypes.node
    })
  )
};

Select.defaultProps = {
  error: false,
  label: "",
  value: "",
  onChange: () => {},
  disabled: false,
  id: "",
  children: null,
  errorMessage: "",
  fullWidth: false,
  displayEmpty: true
};

export default Select;
