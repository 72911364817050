import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="client" />
    </StyledTableCell>
      <StyledTableCell>
      <IntlMessages
        id={"elevators.management.elevators.index.card.table.headquarter"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id="name"
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"elevators.management.elevators.index.card.table.state"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"elevators.management.elevators.index.card.table.detail"}
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
