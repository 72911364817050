import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.orders"} />,
    link: "/logistic/distribution/orders"
  },
  {label: <IntlMessages id={"pages.order.history"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"administrator.logistic.order.history.title"} />}
    description={
      <IntlMessages id={"administrator.logistic.order.history.description"} />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
