export const RESET_CONFIG_VEHICLE_ROLES_STATES =
  "RESET_CONFIG_VEHICLE_ROLES_STATES";
export const FETCH_ALL_CONFIG_VEHICLE_ROLES = "FETCH_ALL_CONFIG_VEHICLE_ROLES";
export const SET_CURRENT_CONFIG_VEHICLE_ROLE =
  "SET_CURRENT_CONFIG_VEHICLE_ROLE";
export const ADD_CONFIG_VEHICLE_ROLE = "ADD_CONFIG_VEHICLE_ROLE";
export const EDIT_CONFIG_VEHICLE_ROLE = "EDIT_CONFIG_VEHICLE_ROLE";
export const EDIT_CONFIG_VEHICLE_ROLE_STATE = "EDIT_CONFIG_VEHICLE_ROLE_STATE";
export const APPLY_CONFIG_VEHICLE_ROLES_FILTERS =
  "APPLY_CONFIG_VEHICLE_ROLES_FILTERS";
export const RESET_CONFIG_VEHICLE_ROLES_FILTERS =
  "RESET_CONFIG_VEHICLE_ROLES_FILTERS";
