import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";

const TableHeader = () => (
  <React.Fragment>
    <StyledTableRow>
      <StyledTableCell align="left">
        <IntlMessages id="guardian.accounting.table.id" />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id="guardian.accounting.table.start.date" />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id="guardian.accounting.table.end.date" />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id="guardian.accounting.table.tags" />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id="guardian.accounting.table.quantity" />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id="guardian.accounting.table.state" />
      </StyledTableCell>
    </StyledTableRow>
  </React.Fragment>
);

export default TableHeader;
