import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "../Form";
import {useDispatch, useSelector} from "react-redux";
import {editRejectionType} from "../../../../../redux/actions/Logistic/RejectionTypes";

const EditRejectionTypeDialog = ({isOpen, onClose}) => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.rejectionTypes);
  const onSubmit = data => {
    dispatch(editRejectionType({...current, ...data}));
    onClose();
  };
  return (
    <FormDialog
      id="edit-rejection-type-form"
      isOpen={isOpen}
      title={
        <IntlMessages id="logistic.distribution.rejection.types.edit.title" />
      }
      onClose={onClose}
    >
      {current && (
        <Form
          id="edit-rejection-type-form"
          initialData={{
            description: current.description || "",
            code: current.code || ""
          }}
          onSubmit={onSubmit}
        />
      )}
    </FormDialog>
  );
};

export default EditRejectionTypeDialog;
