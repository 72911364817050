import React from "react";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import CmtImage from "../../../../../../../@coremat/CmtImage";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.disabled,
    backgroundColor: alpha(theme.palette.common.dark, 0.03),
    height: "100%"
  },
  noRecordRoot: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
    paddingTop: 50
  }
}));

const NoFound = ({content, ...restProps}) => {
  const classes = useStyles();
  return (
    <Box p={4} className={classes.root} {...restProps}>
      <Box className={classes.noRecordRoot} {...restProps}>
        <CmtImage src={"/images/icons/search-result.png"} alt="search result" />
        <Box>
          {content || (
            <IntlMessages
              id={"logistic.monitoring.dispatches.map.sidebar.no.content"}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NoFound;
