import React from "react";
import PropTypes from "prop-types";

import AddIcon from "@material-ui/icons/Add";
import IntlMessages from "@jumbo/utils/IntlMessages";
import FloatingButton from "../FloatingButton";

const FloatingButtonWithAdd = ({onAddClicked, otherActions}) => {
  const actions = [
    {
      name: "add",
      icon: <AddIcon />,
      title: (
        <IntlMessages id="common.buttons.floatingButtonWithAdd.addTitle" />
      ),
      onClick: onAddClicked
    },
    ...otherActions
  ];

  return <FloatingButton actions={actions} />;
};

FloatingButtonWithAdd.propTypes = {
  onAddClicked: PropTypes.func,
  otherActions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      icon: PropTypes.node,
      title: PropTypes.node,
      onClick: PropTypes.func
    })
  )
};

FloatingButtonWithAdd.defaultProps = {
  onAddClicked: () => {},
  otherActions: []
};

export default FloatingButtonWithAdd;
