import {
  APPLY_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS,
  FETCH_ALL_TACHOGRAPH_TRAFFIC_CHARTS_DATA,
  FETCH_ALL_TACHOGRAPH_TRAFFIC_DRIVERS_LIST,
  RESET_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS,
  RESET_TACHOGRAPH_TRAFFIC_STATE
} from "../../types/FleetManagement/TrafficTypes";

const INIT_STATE = {
  chartsData: {
    currentWeekAvailability: [],
    lastWeekAvailability: [],
    drivingHours: []
  },
  driversList: [],
  filters: {
    drivers: [],
    state: 0,
    activities: [],
    period: 1,
    minHours: 0,
    maxHours: 10,
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TACHOGRAPH_TRAFFIC_CHARTS_DATA: {
      return {
        ...state,
        chartsData: action.payload
      };
    }
    case FETCH_ALL_TACHOGRAPH_TRAFFIC_DRIVERS_LIST: {
      return {
        ...state,
        driversList: action.payload,
      };
    }
    case APPLY_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_TACHOGRAPH_TRAFFIC_DRIVERS_LIST_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case RESET_TACHOGRAPH_TRAFFIC_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
