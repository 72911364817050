import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"logistic.distribution.checklist.types.table.name"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.distribution.checklist.types.table.groups"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.distribution.checklist.types.table.total"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
