import ui from "./ui";
import {
  cancelPrevProductPickerFetchAll,
  fetchAllProductsPicker
} from "../../../services/ProductPickerService";
import {FETCH_PRODUCT} from "../../types/Administrator/ProductPicker";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevProductPickerFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchProductPickerSucceeded = data => ({
  type: FETCH_PRODUCT,
  payload: {
    data
  }
});

export const fetchDataProductPicker = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevProductPickerFetchAll();
    fetchAllProductsPicker().then(productPicker => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchProductPickerSucceeded(productPicker));
    });
  };
};

export default {
  get: fetchDataProductPicker,
  resetState
};
