import React from 'react';
import Security from "./Security";
import Efficiency from "./Efficiency";
import '../Common/styles.css';

const EcoDriving = () => {
    return (
        <div className='sections-wrapper'>
            <Security />
            <div className='divider' />
            <Efficiency />
        </div>
    );
};

export default EcoDriving;