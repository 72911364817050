import React from "react";
import PageContainer from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/StateTypes/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {
  editElevatorStateState,
  resetCurrentElevatorState,
  resetElevatorsStatesState
} from "../../../../../../redux/actions/ElevatorsManagement/States";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddStateDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/StateTypes/AddStateDialog";
import EditStateDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/StateTypes/EditStateDialog";
import StatesTable from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/StateTypes/StatesTable";

const StateTypes = props => {
  const {current} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.states
  );
  const dispatch = useDispatch();
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetElevatorsStatesState());
    };
  }, []);
  return (
    <PageContainer>
      <StatesTable
        setEdit={setOpenEdit}
        setDelete={setOpenDelete}
        setRestore={setOpenRestore}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentElevatorState());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editElevatorStateState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentElevatorState());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editElevatorStateState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <AddStateDialog open={openAdd} setOpen={setOpenAdd} />
      <EditStateDialog open={openEdit} setOpen={setOpenEdit} />
    </PageContainer>
  );
};

export default StateTypes;
