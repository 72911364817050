import React from 'react';
import {useSelector} from "react-redux";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = (props) => {
    const {type: selected} = useSelector(
        ({controlPanel}) => controlPanel.kpi.current
    );
    const titles = {
        30: (
            <IntlMessages
                id={"control.panel.kpi.areas.grid.distribution.kone.indicators"}
            />
        ),
        31: (
            <IntlMessages
                id={"control.panel.kpi.areas.grid.distribution.san.fernando.aavv"}
            />
        ),
        33: (
            <IntlMessages
                id={"control.panel.kpi.areas.grid.distribution.san.fernando.aavv.time.process"}
            />
        ),
        34: (
            <IntlMessages
                id={"control.panel.kpi.areas.grid.distribution.san.fernando.inverse.logistic"}
            />
        ),
        32: (
            <IntlMessages
                id={"control.panel.kpi.areas.grid.distribution.san.fernando.general.dashboard"}
            />
        ),
    };
    return(
        <>
            <Box my={4}>
                <Typography variant="h2">{titles[selected]}</Typography>
            </Box>
            <FiltersContainer active marginBottom={5} onClose={() => {}}>
                <Form service={selected}/>
            </FiltersContainer>
        </>
    );
}

export default Filters;