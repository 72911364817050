import React from "react";
import PageContainerComponent from "../../../../components/Common/Layouts/PageContainer";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.orders"} />,
    link: "/logistic/distribution/orders"
  },
  {label: <IntlMessages id={"pages.ot.breakdown"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="administrator.ot.breakdown.title" />}
    description={<IntlMessages id="administrator.ot.breakdown.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
