export const openRejectExpenseDialog = id => ({
  type: "OPEN_SUPERVISOR_REJECT_EXPENSE_DIALOG",
  payload: {
    id
  }
});

export const closeRejectExpenseDialog = () => ({
  type: "CLOSE_SUPERVISOR_REJECT_EXPENSE_DIALOG"
});

export const fetchStarted = () => ({type: "SUPERVISOR_FETCH_STARTED"});
export const fetchSucceeded = () => ({type: "SUPERVISOR_FETCH_SUCCEEDED"});
export const fetchFailed = () => ({type: "SUPERVISOR_FETCH_FAILED"});
export const fetchCancelled = () => ({type: "SUPERVISOR_FETCH_CANCELLED"});

export default {
  openRejectExpenseDialog,
  closeRejectExpenseDialog,
  fetchStarted,
  fetchSucceeded,
  fetchFailed,
  fetchCancelled
};
