import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  inBuildAppContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    flexDirection: "row",
    flexWrap: "nowrap",
    position: "relative",
    boxShadow:
      "0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14)"
  },
  inBuildAppSidebar: {
    width: 260,
    transition: "all 0.3s ease",
    borderRight: `solid 1px ${theme.palette.borderColor.main}`,
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      width: 300
    },
    [theme.breakpoints.up("xl")]: {
      width: 340
    },
    "@media screen and (max-width: 767px)": {
      width: "100%"
    }
  },
  inBuildAppMainContent: {
    width: "calc(100% - 260px)",
    transition: "all 0.3s ease",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 300px)"
    },
    [theme.breakpoints.up("xl")]: {
      width: "calc(100% - 340px)"
    },
    "@media screen and (max-width: 767px)": {
      width: "100%",
      height: "100%",
      position: "absolute",
      left: 0,
      top: 0,
      zIndex: 3
    }
  },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`
  },
  tabRoot: {
    fontSize: 12,
    letterSpacing: 1.25,
    minWidth: 50,
    width: "50%"
  }
}));
