import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import useForm from "hooks/Common/useForm";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import HistoricalSigningSelect from "./Select/HistoricalSingingSelect";
import {useSelector, useDispatch} from "react-redux";
import {fetchHistoricalSigning} from "redux/actions/RRHH/HistoricalSigning";

const initialData = {
  active: true,
  description: ""
};

const Form = ({onFilter}) => {
  const {filters} = useSelector(state => state.rrhh.historicalSigning);
  const dispatch = useDispatch();
  const {formData, setFormData, handleSubmit} = useForm(filters);

  const onSubmit = data => {
    dispatch(fetchHistoricalSigning(formData));
  };

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={formData.startDate}
            id="description-filter"
            label={
              <IntlMessages id="rrhh.page.historicalSigning.inputField.placeholder.intervalDate" />
            }
            onChange={value => setFormData("startDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={formData.endDate}
            id="description-filter"
            label={
              <IntlMessages id="rrhh.page.historicalSigning.inputField.placeholder.intervalDate" />
            }
            onChange={value => setFormData("endDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={6}>
          <HistoricalSigningSelect
            id="2"
            value={formData.drivers}
            onChange={value => setFormData("drivers", value)}
            placeholder={
              "rrhh.page.historicalSigning.inputField.placeholder.employee"
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button
            variant="outlined"
            type="submit"
            form="monitoring-filters"
            style={{height: 40}}
            onClick={handleSubmit(onSubmit)}
          >
            <IntlMessages id={"logistic.monitoring.filters.apply"} />
          </Button>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
