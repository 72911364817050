import React, {useMemo} from "react";
import {Box, IconButton} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteWorkOrderStarted,
    fetchAllWorkersStarted,
    setCurrentWorkOrder
} from "../../../../redux/actions/Administrator/WorkOrders";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import EditIcon from "@material-ui/icons/Edit";
import AirplanemodeActiveIcon from "@material-ui/icons/AirplanemodeActive";
import CheckIcon from "@material-ui/icons/Check";
import moment from "moment/moment";
import DeleteIcon from "@material-ui/icons/Delete";

const WorkOrdersTable = ({data}) => {
    const {filters} = useSelector(state => state.administrator.workOrders);
    const intl = useIntl();
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchAllWorkersStarted({
            ...filters,
            startDate: null,
            endDate: null
        }));
    }, []);
    const openDialog = (workOrder) => {
        dispatch(setCurrentWorkOrder(workOrder));
    };
    const deleteItem = (workOrder) => {
        dispatch(deleteWorkOrderStarted(workOrder));
    };
    const columns = useMemo(() => {
        const columns = [
            {
                field: 'id',
                hide: true,
            },
            {
                field: 'description',
                headerName: intl.messages['logistic.table.workOrder'],
                width: 300

            },
            {
                field: 'startDate',
                headerName: intl.messages['logistic.table.distributionDate'],
                width: 250,
                valueFormatter: ({value}) => moment(value).format("DD/MM/YYYY HH:mm"),

            },
            {
                field: 'driver',
                headerName: intl.messages['logistic.work.orders.table.drivers'],
                width: 180

            },
            {
                field: 'finished',
                headerName: intl.messages["logistic.table.destinations"],
                width: 180,
                valueFormatter: ({row, value}) => `${value} / ${row.total}`,
            },
            {
                field: 'duration',
                headerName: intl.messages['logistic.table.time'],
                width: 180

            },
            {
                field: 'total',
                headerName: intl.messages['logistic.table.state'],
                width: 180,
                valueFormatter: ({row, value}) => row.finished !== value,
                renderCell: ({row, value}) => {
                    return row.finished !== value ? (
                        <AirplanemodeActiveIcon />
                    ) : (
                        <CheckIcon />
                    );
                },
            },
            {
                field: 'actions',
                headerName: intl.messages["table.edit"],
                type: 'actions',
                renderCell: ({row}) => {
                    return (
                        <>
                            <IconButton onClick={() => openDialog(row)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => deleteItem(row)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    )
                }
            }

        ];
        return columns;
    }, [data, intl]);
    return (
        <Box sx={{height: '100%'}}>
            <DataGrid
                style={{
                    height: '50vh'
                }}
                columns={columns}
                rows={data}
                disableSelectionOnClick
                rowsPerPageOptions={[5, 10, 25]}
                pagination
                rowHeight={40}
            />
        </Box>
    );
};

export default WorkOrdersTable;
