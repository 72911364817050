import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.operative"} />,
    link: "/logistic/distribution/orders"
  },
  {label: <IntlMessages id={"sidebar.logistic.monitoring"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"logistic.monitoring.page.header"} />}
    description={<IntlMessages id={"logistic.monitoring.description"} />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
