import React, {useEffect} from "react";
import ExpensesBaseTable from "../../ExpensesBaseTable";
import {parseNumberToMoney} from "../../../../../../utils/parsers";
import useTableSort from "../../../../../../hooks/Common/useTableSort";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import useForm from "../../../../../../hooks/Common/useForm";
import AddExpensesFiltersBuilder from "../Filters/AddExpensesFiltersBuilder";
import Filters from "../Filters";
import {Checkbox, Link} from "@material-ui/core";
import {CheckedIcon, UnCheckedIcon} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllLiquidatedExpensesByPettyCashStarted} from "../../../../../../redux/actions/Guardians/Expenses";
import moment from "moment";
import {
  downloadFile,
  getFileByName
} from "../../../../../../services/FileService";
import {getTypeFromName} from "../../../../../../utils/files";

const SelectExpensesTable = ({selectedExpenses, setSelectedExpenses}) => {
  const dispatch = useDispatch();
  const {currentAccounting, expenses} = useSelector(state => state.guardian);
  const {tableData, onSort, order, orderBy} = useTableSort(expenses.liquidated);

  useEffect(() => {
    dispatch(
      fetchAllLiquidatedExpensesByPettyCashStarted(currentAccounting.pettyCash)
    );
  }, []);

  const filtersForm = useForm({
    ot: "",
    executor: "",
    lowerDate: "",
    upperDate: "",
    expenseType: "",
    accountingAccount: "",
    expense: "",
    amount: ""
  });

  const filteredData = new AddExpensesFiltersBuilder(tableData)
    .byFieldIncludes("ot", filtersForm.formData.ot)
    .byFieldIncludes("executor", filtersForm.formData.executor)
    .byIncludeDates(
      filtersForm.formData.lowerDate,
      filtersForm.formData.upperDate
    )
    .byFieldEquals("expenseType", filtersForm.formData.expenseType)
    .byFieldEquals("accountingAccount", filtersForm.formData.accountingAccount)
    .byFieldIncludes("id", filtersForm.formData.expense)
    .byFieldIncludes("amount", filtersForm.formData.amount)
    .build();

  const [ partialTotal, setPartialTotal ] = React.useState(0);
  React.useEffect(
    () => {
      let sum = 0;
      if (selectedExpenses.length > 0) {
        selectedExpenses.forEach(expense => {
          sum += expense.amount;
        });
      }
      setPartialTotal(sum);
    },
    [ selectedExpenses ]
  );

  const expensesTotal = React.useMemo(() => {
    return filteredData?.reduce((acc, expense) => acc +  expense.amount, 0) || 0;
  }, [filteredData]);

  const handleSelect = expense => {
    if (selectedExpenses.includes(expense)) {
      setSelectedExpenses([ ...selectedExpenses.filter(e => e !== expense) ]);
    } else {
      setSelectedExpenses([ ...selectedExpenses, expense ]);
    }
  };

  const handleMassiveSelection = () => {
    if(selectedExpenses.length === filteredData.length) {
      setSelectedExpenses([]);
    } else {
      setSelectedExpenses(filteredData);
    }
  }

  const onFileNameClick = name =>
    getFileByName(name).then(({base64File}) =>
      downloadFile(
        name,
        `data:image/${getTypeFromName(name)};base64,${base64File}`
      )
    );

  return (
    <React.Fragment>
      <Filters
        formData={filtersForm.formData}
        setFormData={filtersForm.setFormData}
        onReset={filtersForm.resetForm}
      />
      <ExpensesBaseTable
        cols={7}
        total={expensesTotal}
        partialTotal={partialTotal}
        header={<TableHeader
            onSort={onSort}
            order={order}
            orderBy={orderBy}
            onSelectAll={handleMassiveSelection}
            selectedAll={selectedExpenses.length === filteredData.length}
        />}
      >
        <React.Fragment>
          {filteredData.map(data => (
            <StyledTableRow key={data.id}>
              <StyledTableCell>
                <Checkbox
                  icon={<UnCheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                  name="selected"
                  onChange={() => handleSelect(data)}
                  checked={selectedExpenses.includes(data)}
                />
              </StyledTableCell>
              <StyledTableCell align="left">{data.executor}</StyledTableCell>
              <StyledTableCell align="left">
                {data.date && moment(data.date).format("DD/MM/YYYY HH:mm")}
              </StyledTableCell>
              <StyledTableCell align="left">
                {data.expenseType.description}
              </StyledTableCell>
              <StyledTableCell align="left">
                {data.accountingAccount}
              </StyledTableCell>
              <StyledTableCell align="left">{data.ot}</StyledTableCell>
              <StyledTableCell align="left">
                {parseNumberToMoney(data.amount)}
              </StyledTableCell>
              <StyledTableCell align="left">
                <p
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                    width: "200px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                  onClick={() => onFileNameClick(data.document)}
                >
                  {data.document}
                </p>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </React.Fragment>
      </ExpensesBaseTable>
    </React.Fragment>
  );
};

export default SelectExpensesTable;
