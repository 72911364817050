import axiosInstance, { CancelToken, isCancel } from 'utils/axiosInstance';

let getByNameCancelToken = undefined;
let getLinkByNameCancelToken = undefined;

export const cancelPrevGetByName = () => {
  getByNameCancelToken && getByNameCancelToken();
};

export const getFileByName = async name => {
  try {
    const response = await axiosInstance.get(`/Documentos?imageName=${name}`, {
      cancelToken: new CancelToken(c => (getByNameCancelToken = c)),
    });
    return {base64File: response.data.vc_image_64, contentType: response.data.contentType};
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};

export const downloadFile = (filename, linkSource) => {
  const downloadLink = document.createElement('a');
  downloadLink.setAttribute('target', '_blank');
  downloadLink.href = linkSource;
  downloadLink.download = filename;
  downloadLink.click();
  downloadLink.remove();
};

export const getFileUrlByName = async name => {
  try {
    const response = await axiosInstance.get(`/Documentos/get_url?imageName=${name}`, {
      cancelToken: new CancelToken(c => (getLinkByNameCancelToken = c)),
    });
    return response.data;
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};