import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  cancelPrevTraceabilityFetchAll,
  fetchAllTraceability
} from "../../../services/TraceabilityService";

import {
  FETCH_TRACEABILITY_SUCCEEDED,
  APPLY_TRACEABILITY_FILTERS,
  RESET_TRACEABILITY_FILTERS
} from "../../types/Administrator/Traceability";

import Traceability from "../../../domain/Traceability";
import {fetchError} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevTraceabilityFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchTraceabilitySucceeded = data => ({
  type: FETCH_TRACEABILITY_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchTraceability = filters => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevTraceabilityFetchAll();
  fetchAllTraceability(filters)
    .then(data => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchTraceabilitySucceeded(data));
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      dispatch(ui.fetchFailed());
    });
};

export const applyTraceabilityFilter = filters => ({
  type: APPLY_TRACEABILITY_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetWarehouseTransferFilters = () => ({
  type: RESET_TRACEABILITY_FILTERS
});

export const showTraceabilityStarted = (id, data) => dispatch => {
  dispatch(ui.closeEditForm());
};

export default {
  get: fetchTraceability,
  edit: showTraceabilityStarted,
  applyTraceabilityFilter,
  resetWarehouseTransferFilters,
  resetState
};
