import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "700px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "400px"
    }
  }
}));

const DialogBase = ({
  open,
  close,
  title,
  children,
  isLoading,
  actions,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={close}
      classes={{paper: classes.paper}}
      {...rest}
    >
      {isLoading && <LinearProgress style={{marginBottom: "10px"}} />}
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

DialogBase.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  title: PropTypes.node,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  actions: PropTypes.node
};

DialogBase.defaultProps = {
  open: false,
  close: () => {},
  title: "",
  children: null,
  isLoading: false,
  actions: null
};

export default DialogBase;
