import {
  FETCH_REGULATION_SUCCEEDED,
  APPLY_REGULATION_FILTERS,
  RESET_REGULATION_FILTERS,
  REGULATION_CHANGE_STATE,
  REGULATION_EDIT,
  REGULATION_CREATE
} from "../../types/Administrator/RegulationCustomTypes";
import moment from "moment";

const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    destiny: "",
    products: "",
    fromDate: moment(new Date().toISOString())
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    toDate: moment(new Date().toISOString()).format("YYYY-MM-DD")
  }
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_REGULATION_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_REGULATION_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_REGULATION_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case REGULATION_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return {...type, active: !type.active};
          return type;
        })
      };
    }
    case REGULATION_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case REGULATION_EDIT: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return {...type, date: action.payload.date};
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};
