import React from "react";
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const ExpensesContainerWithHeader = ({children}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignContent="center"
    style={{
      width: "100%"
    }}
  >
    <Box
      style={{
        width: "90%",
        margin: "24px auto 4px"
      }}
      display="flex"
      flexDirection="row"
      flexGrow={1}
    >
      <Typography variant="h2">
        <IntlMessages id="guardian.drivers.expenseDetails.title" />
      </Typography>
    </Box>
    {children}
  </Box>
);

export default ExpensesContainerWithHeader;
