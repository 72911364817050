import {
  cancelPrevFetchAll,
  changeState,
  create,
  fetchAll,
  update
} from "services/ExpenseTypeService";
import {restoreAdministratorState} from "./Common";
import ui from "./ui";

export const fetchExpenseTypes = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevFetchAll();
  try {
    const data = await fetchAll();
    dispatch(ui.fetchSucceeded());
    dispatch({type: "EXPENSE_TYPES_FETCH_SUCCEEDED", payload: {data}});
  } catch (error) {
    dispatch(ui.fetchFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeExpenseTypeState = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  try {
    await changeState(id);
    dispatch({type: "EXPENSE_TYPES_CHANGE_STATE", payload: {id}});
    dispatch(ui.changeStateSucceeded());
  } catch (error) {
    dispatch(ui.changeStateFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export const editExpenseType = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await update(id, data);
    dispatch({type: "EXPENSE_TYPES_EDIT", payload: {id, data}});
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export const createExpenseType = data => async dispatch => {
  dispatch(ui.closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const {id} = await create(data);
    dispatch({
      type: "EXPENSE_TYPES_CREATE",
      payload: {id, ...data, active: true}
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

export const applyFilters = filters => ({
  type: "EXPENSE_TYPES_APPLY_FILTERS",
  payload: {filters}
});
export const resetFilters = () => ({type: "EXPENSE_TYPES_RESET_FILTERS"});

export const deleteExpenseType = id => dispatch => {
  dispatch(changeExpenseTypeState(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreExpenseType = id => dispatch => {
  dispatch(changeExpenseTypeState(id));
  dispatch(ui.closeRestoreConfirmation());
};

export const resetState = () => dispatch => {
  cancelPrevFetchAll();
  dispatch(restoreAdministratorState());
};

export default {
  get: fetchExpenseTypes,
  delete: deleteExpenseType,
  restore: restoreExpenseType,
  edit: editExpenseType,
  create: createExpenseType,
  applyFilters,
  resetFilters,
  resetState
};
