import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import StateSelect from "../../../../Purchases/common/StateSelect";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {openDialog} from "../../../../../../redux/reducers/Logistic/orders";
import {dialogsList} from "../../constants";
import {parseDateToDateString} from "../../../../../../utils/dates";
import {updateOrderState} from "../../../../../../redux/thunks/Logistic/orders";

const BasicInfoForm = ({initData}) => {
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const handleChangeState = (value) => {
        dispatch(updateOrderState({
            order: currentOrder,
            stateId: value,
        }));
    }
    return (
        <fieldset>
            <legend>
                <Typography>
                    <IntlMessages id="general.data"/>
                </Typography>
            </legend>
            <GridContainer
                spacing={2}
                className="basic-info-form"
            >
                <Grid item xs={12} md={5}>
                    <Typography>
                        ID: {initData.id}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Typography>
                        {parseDateToDateString(initData.orderDate)}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                    <StateSelect
                        value={initData.state}
                        onChange={handleChangeState}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '5px',
                        marginTop: '5px'
                    }}
                >
                    <Link
                        to="/logistic/distribution/assignment"
                    >
                        <ContainedButton
                            text={<IntlMessages id="assign"/>}
                            color="primary"
                        />
                    </Link>
                    <ContainedButton
                        text={<IntlMessages id="bill"/>}
                        color="primary"
                        onClick={() => dispatch(openDialog(dialogsList.billItems))}
                    />
                    <ContainedButton
                        text={<IntlMessages id="see.history"/>}
                        color="primary"
                    />
                </Grid>
            </GridContainer>
        </fieldset>
    );
};

export default BasicInfoForm;