import {cancelPrevFetchAll, fetchAll} from "services/UnresolvedExpenseService";
import {
  cancelPrevFetchAll as cancelPrevFetchAllRejectionTypes,
  fetchAll as fetchAllRejectionTypes
} from "services/RejectionTypeService";
import {restoreAdministratorState} from "../Administrator/Common";
import {restoreSupervisorState} from "./Common";
import {updateExpenseState} from "services/ExpenseService";
import ui from "./ui";

export const fetchExpenses = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevFetchAll();
  try {
    const data = await fetchAll();
    const rejectionTypes = await fetchAllRejectionTypes();

    dispatch(ui.fetchSucceeded());
    dispatch({type: "UNRESOLVED_EXPENSES_FETCH_SUCCEEDED", payload: {data}});
    dispatch({
      type: "REJECTION_TYPES_FETCH_SUCCEEDED",
      payload: {data: rejectionTypes}
    });
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applyFilters = filters => ({
  type: "UNRESOLVED_EXPENSES_APPLY_FILTERS",
  payload: {filters}
});
export const resetFilters = () => ({type: "UNRESOLVED_EXPENSES_RESET_FILTERS"});

export const approveExpense = id => {
  return async dispatch => {
    updateExpenseState(
      {id},
      {approveMessage: "1", expenseReject: null}
    ).then(() => {
      dispatch({
        type: "APPROVE_EXPENSE",
        payload: {
          id
        }
      });
    });
  };
};

export const rejectExpense = (id, data) => {
  return async dispatch => {
    dispatch(ui.closeRejectExpenseDialog());
    updateExpenseState(
      {id},
      {approveMessage: "2", expenseReject: data.rejectionType.value}
    ).then(() => {
      dispatch({
        type: "REJECT_EXPENSE",
        payload: {
          id,
          reasonOfRejection: data.rejectionType.label
        }
      });
    });
  };
};

export const resetState = () => dispatch => {
  cancelPrevFetchAll();
  cancelPrevFetchAllRejectionTypes();
  dispatch(restoreAdministratorState());
  dispatch(restoreSupervisorState());
};

export default {
  get: fetchExpenses,
  approve: approveExpense,
  reject: rejectExpense,
  applyFilters,
  resetFilters,
  resetState
};
