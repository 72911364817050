import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import ShowTableRow from "components/Common/Tables/CrudTable/ShowTableRow";

const Table = ({data, onEdit, isLoading}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const RenderRow = ({row}) => (
    <ShowTableRow onShow={() => onEdit(row.id)}>
      <StyledTableCell>
        {moment(row.date).format("DD/MM/YY HH:mm")}
      </StyledTableCell>
      <StyledTableCell>
        {row.entityNameOrigin +
          "/" +
          row.campusNameOrigin +
          "/" +
          row.warehouseNameOrigin}
      </StyledTableCell>
      <StyledTableCell>
        {row.entityNameDestiny +
          "/" +
          row.entityNameDestiny +
          "/" +
          row.warehouseNameDestiny}
      </StyledTableCell>
    </ShowTableRow>
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      date: PropTypes.instanceOf(Date),
      origin: PropTypes.string,
      destination: PropTypes.string
    })
  )
};

Table.defaultProps = {
  data: []
};

export default Table;
