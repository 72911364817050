const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    supervisor: "",
    tag: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "ADMINISTRATOR_SUPERVISORS_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case "ADMINISTRATOR_SUPERVISORS_APPLY_FILTERS": {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case "ADMINISTRATOR_SUPERVISORS_RESET_FILTERS": {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case "ADMINISTRATOR_SUPERVISORS_CHANGE_STATE": {
      return {
        ...state,
        data: state.data.map(supervisor => {
          if (supervisor.id === action.payload.id)
            return Object.assign(supervisor, {active: !supervisor.active});
          return supervisor;
        })
      };
    }
    case "ADMINISTRATOR_SUPERVISORS_CREATE": {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case "ADMINISTRATOR_SUPERVISORS_EDIT": {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, action.payload.data);
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};
