import React from 'react';
import Select from "../../../Common/Forms/Select";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../hooks/Common/useFetch";
import {fetchAllStatesSelect} from "../../../../services/Logistics/Purchases/ProviderOrdersService";

const StateSelect = ({name = 'state', value, onChange, error, errorMessage, required = false}) => {
    const {data: states} = useFetch(fetchAllStatesSelect, {}, []);
    const handleOnChange = ({target}) => {
        const {value} = target;
        onChange(value);
    }
    return (
        <Select
            label={<IntlMessages id="state"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
        >
            {states?.map(state => (
                <MenuItem key={state.id} value={state.id}>
                    {state.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default StateSelect;