import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Select from "components/Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import EXPENSE_STATE from "models/Supervisor/ExpenseState";

const StateSelect = ({selected, onChange}) => {
  return (
    <Select
      id="supervisor-unresolved-expenses-state-select"
      label={<IntlMessages id="supervisor.unresolvedExpenses.filters.state" />}
      value={selected}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value="">
        <IntlMessages id="supervisor.unresolvedExpenses.filters.state.all" />
      </MenuItem>
      <MenuItem value={EXPENSE_STATE.PENDING}>
        <IntlMessages id="supervisor.unresolvedExpenses.filters.state.pending" />
      </MenuItem>
      <MenuItem value={EXPENSE_STATE.APPROVED}>
        <IntlMessages id="supervisor.unresolvedExpenses.filters.state.approved" />
      </MenuItem>
      <MenuItem value={EXPENSE_STATE.REJECTED}>
        <IntlMessages id="supervisor.unresolvedExpenses.filters.state.rejected" />
      </MenuItem>
    </Select>
  );
};

export default StateSelect;
