import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {
  addNewEmployee,
  createContact
} from "../../../../redux/actions/RRHH/Employees";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import MembershipForm from "./MembershipForm";
import AddForm from "./AddForm";

const AddEmployee = ({open, setOpen}) => {
  const id = "add-employee-dialog";
  const [ type, setType ] = React.useState(1);
  const {filterType} = useSelector(({rrhh}) => rrhh.employees);
  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
  };
  const onAddMembership = data => {
    dispatch(createContact(data, filterType, setType, onClose));
  };

  const onAddNewEmployee = data => {
    dispatch(addNewEmployee(data, filterType, onClose));
  };

  const forms = {
    1: <MembershipForm onSubmit={onAddMembership} id={id} />,
    2: <AddForm onSubmit={onAddNewEmployee} id={id} />
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      title={<IntlMessages id={"rrhh.employees.add.form.title"} />}
      onClose={onClose}
      cancelText={<IntlMessages id={"rrhh.employees.form.cancel"} />}
      submitText={<IntlMessages id={"rrhh.employees.form.save"} />}
    >
      {forms[type]}
    </FormDialog>
  );
};

export default AddEmployee;
