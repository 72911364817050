import React from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const CountrySelect = ({
  id,
  value,
  onChange,
  style,
  fullWidth,
  error,
  hasError
}) => {
  const countries = [
    {
      id: 1,
      name: "España"
    },
    {
      id: 2,
      name: "Perú"
    }
  ];
  return (
    <Select
      id={id}
      label={<IntlMessages id="select.country.label" />}
      value={value}
      onChange={e => onChange(e.target.value)}
      style={style}
      error={hasError}
      errorMessage={error}
      fullWidth={fullWidth}
    >
      <MenuItem value={""}>
        <IntlMessages id="select.country.all" />
      </MenuItem>
      {countries.map(country => (
        <MenuItem value={country.id} key={country.id}>
          {country.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountrySelect;
