import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchDataProductPicker} from "../../../redux/actions/Administrator/ProductPicker";
import IntlMessages from "@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../components/Common/Forms/Select/MultipleSelect";

const ProductPicker = ({id, values, isLoading, onChange, errors}) => {
  const {data} = useSelector(state => state.administrator.productPicker);

  const options = data.filter(e => e.esEnvase === true && e.active === true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length < 1) {
      dispatch(fetchDataProductPicker());
    }
  }, []);

  return (
    <MultipleSelect
      id={id}
      options={options}
      value={values}
      onChange={onChange}
      fullWidth
      hasError={errors.products.length > 0}
      error={errors.products[0]}
      disabled={isLoading}
      label={<IntlMessages id="logistic.inventoryReport.filters.products" />}
      headerText={
        <IntlMessages id="logistic.inventoryReport.filters.products.header" />
      }
      noOptionsText={
        <IntlMessages id="logistic.inventoryReport.filters.products.no.option" />
      }
    />
  );
};

export default ProductPicker;
