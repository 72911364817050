import {createSlice} from "@reduxjs/toolkit";
import {getParsedData} from "../../thunks/BackOffice/parser.";

const INIT_DATA = {
    filters: {
        device: '',
        packageType: '',
        decodeType: '',
        formatType: '',
        package: ''
    },
    data: {}
};

const parserSlice = createSlice({
    name: 'parser',
    initialState: {
        ...INIT_DATA,
    },
    reducers: {
        resetFilters: (state) => {
            state.data = INIT_DATA.data;
            state.filters = {
                ...INIT_DATA.filters
            }
        },
        resetState: (state) => {
            state.data = INIT_DATA.data;
            state.filters = {
                ...INIT_DATA.filters
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getParsedData.fulfilled, (state, action) => {
            state.filters = action.payload.filters;
            state.data = action.payload.data;
        })
    }
});

export default parserSlice.reducer;

export const {resetState, resetFilters} = parserSlice.actions;