import React from "react";
import PropTypes from "prop-types";

import {
  TableContainer as MaterialUITableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  TableFooter
} from "@material-ui/core";

const TableContainer = ({head, children, footer}) => (
  <MaterialUITableContainer component={Paper}>
    <Table stickyHeader>
      <TableHead>{head}</TableHead>
      <TableBody>{children}</TableBody>
      <TableFooter>{footer}</TableFooter>
    </Table>
  </MaterialUITableContainer>
);

TableContainer.propTypes = {
  head: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node
};

TableContainer.defaultProps = {
  head: null,
  children: null,
  footer: null
};

export default TableContainer;
