import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {fetchTaltonikaData} from "../../../services/BackOffice/Devices/TaltonikaService";

export const getTeltonikaData = createAsyncThunk(
    'teltonika/getTeltonikaData',
    async (filters, {dispatch, rejectWithValue }) => {
            try {
                dispatch(fetchStart());
                const data = await fetchTaltonikaData(filters);
                dispatch(fetchSuccess());
                return {filters, data};
            }catch (e) {
                dispatch(fetchError(e.message));
                return rejectWithValue(e.message);
            }
    }
)