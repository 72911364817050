import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormShowDialog";
import Form from "../Form";

const WatchTraceabilityDialog = ({initialData, isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="watch-traceability-form"
    isOpen={isOpen}
    title={<IntlMessages id="logistic.traceability.showFormTitle" />}
    onClose={onClose}
  >
    <Form
      id="watch-traceability-form"
      initialData={initialData}
      onSubmit={onSubmit}
    />
  </FormDialog>
);

export default WatchTraceabilityDialog;
