import React from 'react';
import clsx from "clsx";
import {Badge, Box, Typography} from "@material-ui/core";
import CmtAvatar from "../../../../../../../@coremat/CmtAvatar";
import moment from "moment";

const DestinationItem = ({classes, data, onSelectDestinations, selectedDestinations}) => (
    <Box
        className={clsx(classes.chatCellItem, {
            active: selectedDestinations.length > 0 && selectedDestinations?.some(d => d.id === data.id),
        })}
        onClick={() => onSelectDestinations(data)}>
        <Box className={classes.chatAvatarRoot}>
            <Badge variant="dot">
                <CmtAvatar size={40} src={""} alt={data.name} />
            </Badge>
        </Box>
        <Box className={classes.chatCellInfo}>
            <Box display="flex" alignItems="center">
                <Typography component="div" variant="subtitle2" className={classes.titleRoot}>
                    {data.name}
                </Typography>
                <Box color="text.secondary" fontSize={12} ml="auto">
                    {data.state.description}
                </Box>
            </Box>
            <Box display="flex" alignItems="center">
                <Typography className={classes.chatDesRoot}>
                    {moment(new Date(data.estimatedDate)).format("DD/MM/yyyy hh:mm:ss A")}
                </Typography>
            </Box>
        </Box>
    </Box>
);

export default DestinationItem;