import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import TableHeader from "./TableHeader";
import EditTableRow from "../../../../../Common/Tables/CrudTable/EditTableRow";
import {setCurrentConfigurationSubmodule} from "../../../../../../redux/actions/Configuration/Modules";

const SubmodulesTable = ({setOpen}) => {
  const {currentSubmodules} = useSelector(
    ({configuration}) => configuration.modules
  );
  const dispatch = useDispatch();
  const handleOpenSubmoduleDetails = submodule => {
    setOpen(true);
    dispatch(setCurrentConfigurationSubmodule(submodule));
  };
  return (
    <TableContainer head={<TableHeader />}>
      {Object.values(currentSubmodules).map(submodule => (
        <EditTableRow
          key={submodule.id}
          onEdit={() => handleOpenSubmoduleDetails(submodule)}
        >
          <StyledTableCell>{submodule.id}</StyledTableCell>
          <StyledTableCell>{submodule.description}</StyledTableCell>
        </EditTableRow>
      ))}
    </TableContainer>
  );
};

export default SubmodulesTable;
