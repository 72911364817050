class TachographAlertType {
  constructor(id, description) {
    this.id = id;
    this.description = description;
  }

  static map = type => {
    return new TachographAlertType(type.nu_id_alerta_tipo, type.vc_descripcion);
  };
}

export default TachographAlertType;
