import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {editReserve} from "../../../../../redux/actions/FleetManagement/Reserves";
import Form from "../AddReserveDialog/Form";
import {parseDateToInput} from "../../../../../utils/dates";
import FilesArea from "../../../Common/FilesArea";
import {Box} from "@material-ui/core";

const EditReserveDialog = ({open, setOpen}) => {
  const id = "edit-reserve-dialog";
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.reserves
  );
  const [ files, setFiles ] = React.useState(current.files || []);
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(editReserve(data, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.reserves.edit.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.reserves.edit.save"} />}
      cancelText={<IntlMessages id={"fleet.management.reserves.edit.cancel"} />}
    >
      <Form
        id={id}
        onSubmit={onSubmit}
        initialData={{
          plaque: current.plaque || "",
          driver: current.driver || "",
          startDate: current.startDate
            ? parseDateToInput(current.startDate)
            : "",
          endDate: current.endDate ? parseDateToInput(current.endDate) : "",
          turn: current.turn || ""
        }}
      />
      <Box>
        <FilesArea
          id={"form-reserves-files"}
          files={files}
          setFiles={setFiles}
        />
      </Box>
    </FormDialog>
  );
};
export default EditReserveDialog;
