import {
  ADD_VEHICLE_DOCUMENTS_TYPE,
  APPLY_VEHICLE_DOCUMENTS_TYPES_FILTERS,
  CLOSE_VEHICLE_DOCUMENTS_TYPES_DIALOG,
  EDIT_VEHICLE_DOCUMENTS_TYPE,
  FETCH_ALL_VEHICLE_DOCUMENTS_TYPES,
  OPEN_VEHICLE_DOCUMENTS_TYPES_DIALOG,
  RESET_CURRENT_VEHICLE_DOCUMENTS_TYPE,
  RESET_VEHICLE_DOCUMENTS_TYPES_FILTERS,
  RESET_VEHICLE_DOCUMENTS_TYPES_STATE,
  SET_CURRENT_VEHICLE_DOCUMENTS_TYPE
} from "../../types/FleetManagement/DocumentsTypesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    description: "",
    securityCode: ""
  },
  current: null,
  dialog: {
    name: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_VEHICLE_DOCUMENTS_TYPES: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_VEHICLE_DOCUMENTS_TYPES_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_VEHICLE_DOCUMENTS_TYPES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case SET_CURRENT_VEHICLE_DOCUMENTS_TYPE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_VEHICLE_DOCUMENTS_TYPE: {
      return {
        ...state,
        current: null
      };
    }
    case ADD_VEHICLE_DOCUMENTS_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_VEHICLE_DOCUMENTS_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case OPEN_VEHICLE_DOCUMENTS_TYPES_DIALOG: {
      return {
        ...state,
        dialog: {
          name: action.payload
        }
      };
    }
    case CLOSE_VEHICLE_DOCUMENTS_TYPES_DIALOG: {
      return {
        ...state,
        dialog: {
          name: ""
        }
      };
    }
    case RESET_VEHICLE_DOCUMENTS_TYPES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
