import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import {
  applyRefuelingFilters,
  resetRefuelingFilters
} from "../../../../../../redux/actions/FleetManagement/Refueling";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";

const Filters = () => {
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.refueling
  );
  const {formData, setFormData, resetForm} = useForm(filters);
  const dispatch = useDispatch();
  const onApplyFilters = () => {
    dispatch(applyRefuelingFilters(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetRefuelingFilters());
  };
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-plaque"}
              fullWidth
              value={formData.plaque}
              onChange={value => setFormData("plaque", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.plaque"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedDatePicker
              id={"refueling-date"}
              fullWidth
              value={formData.date}
              onChange={value => setFormData("date", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.date"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-fuel-type"}
              fullWidth
              value={formData.fuelType}
              onChange={value => setFormData("fuelType", value)}
              label={
                <IntlMessages
                  id={"fleet.management.refueling.table.fuel.type"}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-station"}
              fullWidth
              value={formData.station}
              onChange={value => setFormData("station", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.station"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-driver"}
              fullWidth
              value={formData.driver}
              onChange={value => setFormData("driver", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.driver"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-gallons"}
              fullWidth
              value={formData.gallons}
              onChange={value => setFormData("gallons", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.gallons"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-price"}
              fullWidth
              value={formData.price}
              onChange={value => setFormData("price", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.price"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"refueling-amount"}
              fullWidth
              value={formData.amount}
              onChange={value => setFormData("amount", value)}
              label={
                <IntlMessages id={"fleet.management.refueling.table.amount"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onResetFilters();
              }}
              text={<IntlMessages id={"filters.button.clear"} />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onApplyFilters();
              }}
              color="primary"
              text={<IntlMessages id={"filters.button.apply"} />}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
