import {
  ADD_CORRECTIVE_TYPE,
  APPLY_CORRECTIVE_TYPES_FILTERS,
  CLOSE_CORRECTIVE_TYPES_DIALOG,
  EDIT_CORRECTIVE_TYPE,
  FETCH_ALL_CORRECTIVE_TYPES,
  OPEN_CORRECTIVE_TYPES_DIALOG,
  RESET_CORRECTIVE_TYPES_FILTERS,
  RESET_CORRECTIVE_TYPES_STATE,
  RESET_CURRENT_CORRECTIVE_TYPE,
  SET_CURRENT_CORRECTIVE_TYPE
} from "../../types/FleetManagement/CorrectiveTypesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    description: "",
    active: null
  },
  current: null,
  dialog: {
    name: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CORRECTIVE_TYPES: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_CORRECTIVE_TYPES_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_CORRECTIVE_TYPES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case SET_CURRENT_CORRECTIVE_TYPE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_CORRECTIVE_TYPE: {
      return {
        ...state,
        current: null
      };
    }
    case ADD_CORRECTIVE_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_CORRECTIVE_TYPE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case OPEN_CORRECTIVE_TYPES_DIALOG: {
      return {
        ...state,
        dialog: {
          name: action.payload
        }
      };
    }
    case CLOSE_CORRECTIVE_TYPES_DIALOG: {
      return {
        ...state,
        dialog: {
          name: ""
        }
      };
    }
    case RESET_CORRECTIVE_TYPES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
