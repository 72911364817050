import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import DeleteTableRow from "components/Common/Tables/CrudTable/DeleteTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import {OutlinedInput} from "@material-ui/core";
import {ErrorSharp} from "@material-ui/icons";
import useUiState from "hooks/Administrator/useUiState";
import TableRow from "./TableRow";

import TextField from "@material-ui/core/TextField";

const Table = ({
  data,
  isLoading,
  handleAmount,
  setData,
  focusAmount,
  errors
}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  const ui = useUiState();

  //const [list, setList] = React.useState(tableData);
  //console.log("DATOS DE PROD TABLA LIST: ",list);

  const handleDelete = id => {
    //setList(tableData);
    let copy = [ ...tableData ];

    let item = copy ? copy.find(e => e.id === id) : undefined;
    copy = copy.filter(item => item.id !== id);
    setData(copy);
    //tableData = [...copy];
  };

  const RenderRow = ({row}) => {
    return (
      //<CrudTableRow onDelete={() => onDelete(row.id)}>
      <TableRow
        row={row}
        handleAmount={handleAmount}
        handleDelete={handleDelete}
        focusAmount={focusAmount}
      />
    );
  };

  return (
    <React.Fragment>
      {errors.products.length > 0 && (
        <p style={{color: "red", marginBottom: "16px"}}>{errors.products[0]}</p>
      )}
      <TableComponent
        data={tableData}
        isLoading={isLoading}
        head={<TableHeader orderby={orderBy} order={order} onSort={onSort} />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      product: PropTypes.string,
      stock: PropTypes.number
    })
  )
};

Table.defaultProps = {
  data: [],
  onDelete: () => {}
};

export default Table;
