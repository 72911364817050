class ElevatorContractDetail {
  constructor(
    id,
    name,
    price,
    priceType,
    freeMaintenance,
    warranty,
    startDate,
    endDate
  ) {
    this.id = id;
    this.name = name;
    this.price = price;
    this.priceType = priceType;
    this.freeMaintenance = freeMaintenance;
    this.warranty = warranty;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  static map = elevator => {
    return new ElevatorContractDetail(
      elevator.nu_id_tipo_contrato,
      "",
      elevator.nu_precio_mantenimiento,
      elevator.nu_id_moneda,
      {
        startDate: elevator.dt_fec_inicio_mantenimiento,
        endDate: elevator.dt_fec_fin_mantenimiento
      },
      {
        startDate: elevator.dt_fec_inicio_garantia,
        endDate: elevator.dt_fec_fin_garantia
      },
      elevator.dt_fec_inicio_contrato,
      elevator.dt_fec_fin_contrato
    );
  };
}

export default ElevatorContractDetail;
