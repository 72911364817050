import React from "react";
import {useStyles} from "../../styles";
import {Box} from "@material-ui/core";
import ChecklistSelect from "./ChecklistSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const ChecklistsArea = ({edit, formData, setFormData}) => {
  const classes = useStyles();
  return (
    <Box className={classes.defaultBigBox} mt={2}>
      <Box>
        <ChecklistSelect
          id="previous-checklist"
          label={
            <IntlMessages
              id={
                "logistic.distribution.work.orders.types.detail.states.checklists.previous"
              }
            />
          }
          disabled={!edit}
          value={formData.previousCheck}
          setValue={value => setFormData("previousCheck", value)}
        />
      </Box>
      <Box>
        <ChecklistSelect
          id="confirmation-checklist"
          label={
            <IntlMessages
              id={
                "logistic.distribution.work.orders.types.detail.states.checklists.confirmation"
              }
            />
          }
          disabled={!edit}
          value={formData.confirmationCheck}
          setValue={value => setFormData("confirmationCheck", value)}
        />
      </Box>
    </Box>
  );
};

export default ChecklistsArea;
