import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import incidenceTypeSelect from "../domain/IncidenceTypeSelect";

let fetchAllCancelToken = undefined;

export const cancelPrevIncidenceTypeSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllIncidenceTypeSelect = async () => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Tipo_Incidencia/sel",
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(incidenceSelect =>
      incidenceTypeSelect.map(incidenceSelect)
    );
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
