import EXPENSE_STATE from "models/Supervisor/ExpenseState";

const INIT_STATE = {
  data: [],
  filters: {
    driver: "",
    tag: "",
    event: "",
    documentType: "",
    amount: "",
    state: EXPENSE_STATE.PENDING,
    reasonOfRejection: "",
    lowerDate: null,
    upperDate: null,
    pettyCashAmount: '',
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_SUPERVISOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "UNRESOLVED_EXPENSES_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case "UNRESOLVED_EXPENSES_APPLY_FILTERS": {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case "UNRESOLVED_EXPENSES_RESET_FILTERS": {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case "APPROVE_EXPENSE": {
      return {
        ...state,
        data: state.data.map(expense => {
          if (expense.id === action.payload.id)
            return Object.assign(expense, {state: EXPENSE_STATE.APPROVED});
          return expense;
        })
      };
    }
    case "REJECT_EXPENSE": {
      return {
        ...state,
        data: state.data.map(expense => {
          if (expense.id === action.payload.id)
            return Object.assign(expense, {
              state: EXPENSE_STATE.REJECTED,
              reasonOfRejection: action.payload.reasonOfRejection
            });
          return expense;
        })
      };
    }
    default: {
      return state;
    }
  }
};
