import React from "react";
import {DefaultStyledTableRow, StyledTableCell} from "./styles";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({orderBy, order, onSort, isGuardian}) => {
  return (
    <DefaultStyledTableRow>
      <StyledTableCell align="left">
        <IntlMessages id={"sidebar.workday.dataMaster.pauseTypes"} />
      </StyledTableCell>
      <StyledTableCell />
    </DefaultStyledTableRow>
  );
};

export default TableHeader;
