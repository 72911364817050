import React, {useCallback, useEffect} from "react";
import TableContainer from "../../../Common/Tables/TableContainer";
import useTableSort from "../../../../hooks/Common/useTableSort";
import CrudTableRow from "../../../Common/Tables/CrudTable/CrudTableRow";
import TableHeader from "./TableHeader";
import AccountingFilters from "../AccountingFilters";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentAccounting} from "../../../../redux/actions/Guardians/CurrentAccounting";
import {
  applyAccountingFilters,
  deleteAccountingStarted,
  fetchAllAccountingStarted,
  resetAccountingFilters
} from "../../../../redux/actions/Guardians/Accounting";
import ShowTableRow from "../../../Common/Tables/CrudTable/ShowTableRow";
import RowValues from "./RowValues";
import {getUserType} from "../../../../services/StorageService";

const AccountingTable = ({setOpenDetails, setOpenResult}) => {
  const {data, filters} = useSelector(state => state.guardian.accountingList);
  const dispatch = useDispatch();
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  const applyFilters = useCallback(
    filters => dispatch(applyAccountingFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => {
    dispatch(resetAccountingFilters());
  };
  useEffect(
    () => {
      dispatch(fetchAllAccountingStarted(filters));
    },
    [ filters ]
  );

  const onEdit = accounting => {
    setOpenDetails(true);
    dispatch(setCurrentAccounting(accounting));
  };
  const onDelete = accounting => {
    dispatch(deleteAccountingStarted(accounting, filters, setOpenResult));
  };

  return (
    <React.Fragment>
      <AccountingFilters applyFilters={applyFilters} onClose={resetFilters} />
      <TableContainer
        head={<TableHeader onSort={onSort} order={order} orderBy={orderBy} />}
      >
        {tableData.map(
          accounting =>
            (accounting.state.id === "1" || accounting.state.id === "4") &&
            getUserType().includes("guardian") ? (
              <CrudTableRow
                key={accounting.id}
                onDelete={() => onDelete(accounting)}
                onEdit={() => onEdit(accounting)}
              >
                <RowValues
                  accounting={accounting}
                  setOpenResult={setOpenResult}
                />
              </CrudTableRow>
            ) : (
              <ShowTableRow
                key={accounting.id}
                onShow={() => onEdit(accounting)}
              >
                <RowValues
                  accounting={accounting}
                  setOpenResult={setOpenResult}
                />
              </ShowTableRow>
            )
        )}
      </TableContainer>
    </React.Fragment>
  );
};

export default AccountingTable;
