import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  clientCode: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.client.code.error.empty" />
      )
    }
  ],
  name: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="administrator.clients.form.name.error.empty" />
    }
  ],
  businessName: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.business.name.error.empty" />
      )
    }
  ],
  countryCode: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.country.code.error.empty" />
      )
    }
  ],
  code: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="administrator.clients.form.code.error.empty" />
    }
  ],
  email: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.email.error.empty" />
      )
    }
  ],
  headquarterName: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.headquarter.name.error.empty" />
      )
    }
  ],
  address: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.address.error.empty" />
      )
    }
  ],
  number: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.number.error.empty" />
      )
    }
  ],
  postalCode: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.postal.code.error.empty" />
      )
    }
  ],
  municipality: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.municipality.error.empty" />
      )
    }
  ],
  province: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.province.error.empty" />
      )
    }
  ],
  country: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.country.error.empty" />
      )
    }
  ],
  latitude: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.latitude.error.empty" />
      )
    }
  ],
  longitude: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.form.longitude.error.empty" />
      )
    }
  ]
};
