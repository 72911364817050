import {FETCH_DRIVER_SELECT} from "redux/types/Administrator/DriverSelect";

const INIT_STATE = {
  driver: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_DRIVER_SELECT:
      return {
        ...state,
        driver: action.payload.data
      };
    default:
      return state;
  }
};
