import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import {Button} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import {
    getDispatchesByDetails,
    setBreakdownFilters
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";

const initData = {
    origin: '',
    destination: '',
    planningPlace: '',
}
const SearchFilters = () => {
    const id = 'search-filters';
    const {formData, setFormData, handleSubmit} = useForm(initData);
    const dispatch = useDispatch();
    const onSearch = (data) => {
        dispatch(getDispatchesByDetails(data))
        dispatch(setBreakdownFilters(data));
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSearch)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        label={<IntlMessages id='origin' />}
                        value={formData.origin}
                        onChange={value => setFormData('origin', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        label={<IntlMessages id='destination' />}
                        value={formData.destination}
                        onChange={value => setFormData('destination', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        label={<IntlMessages id='planning.place' />}
                        value={formData.planningPlace}
                        onChange={value => setFormData('planningPlace', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant='contained'
                        color="primary"
                        type='submit'
                        form={id}
                    >
                        <IntlMessages id="administrator.ot.breakdown.search.button" />
                    </Button>
                </Grid>
            </GridContainer>
        </form>
    );
};

export default SearchFilters;