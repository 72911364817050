import {
  APPLY_TACHOGRAPH_ALERTS_FILTERS,
  FETCH_ALL_ALERTS_CONFIG_DATA,
  FETCH_ALL_TACHOGRAPH_ALERTS,
  RESET_TACHOGRAPH_ALERTS_FILTERS,
  RESET_TACHOGRAPH_ALERTS_STATE
} from "../../types/FleetManagement/AlertsTypes";

const INIT_STATE = {
  data: {},
  filters: {
    drivers: [],
    vehicles: [],
    types: []
  },
  configData: {}
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_TACHOGRAPH_ALERTS: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_TACHOGRAPH_ALERTS_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_TACHOGRAPH_ALERTS_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case FETCH_ALL_ALERTS_CONFIG_DATA: {
      return {
        ...state,
        configData: action.payload
      };
    }
    case RESET_TACHOGRAPH_ALERTS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
