import {
  ADD_CONFIG_ORGANIZATION_MODULE,
  APPLY_CONFIG_ORGANIZATION_MODULES_FILTERS,
  EDIT_CONFIG_ORGANIZATION_MODULE_STATE,
  FETCH_ALL_CONFIG_ORGANIZATION_MODULES,
  RESET_CONFIG_ORGANIZATION_MODULES_FILTERS,
  RESET_CONFIG_ORGANIZATION_MODULES_STATES,
  RESET_CURRENT_CONFIG_ORGANIZATION_MODULE,
  SET_CURRENT_CONFIG_ORGANIZATION_MODULE
} from "../../types/Configuration/OrganizationModulesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import Organization from "../../../domain/Configuration/Organization";
import {
  fetchAllOrganizationPermissions, updateMassiveOrganizationsOperations,
  updateOrganizationOperation
} from "../../../services/Configuration/ConfigOrganizationService";
import {fetchAllClients} from "../../../services/ClientsService";

export const resetConfigurationOrganizationModulesState = () => ({
  type: RESET_CONFIG_ORGANIZATION_MODULES_STATES
});
export const resetCurrentConfigOrganizationModule = () => ({
  type: RESET_CURRENT_CONFIG_ORGANIZATION_MODULE
});
export const applyConfigOrganizationModules = filters => ({
  type: APPLY_CONFIG_ORGANIZATION_MODULES_FILTERS,
  payload: filters
});
export const resetConfigOrganizationModules = () => ({
  type: RESET_CONFIG_ORGANIZATION_MODULES_FILTERS
});

export const fetchAllConfigurationOrganizationModules = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    fetchAllClients()
      .then(clients => {
        dispatch(fetchSuccess());
        const organizations = clients.map(
          c => new Organization(c.id, c.name, [], true)
        );
        dispatch({
          type: FETCH_ALL_CONFIG_ORGANIZATION_MODULES,
          payload: organizations
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const setCurrentConfigOrganizationModule = organization => {
  return async dispatch => {
    if (organization === null) {
      dispatch({
        type: SET_CURRENT_CONFIG_ORGANIZATION_MODULE,
        payload: organization
      });
    } else {
      dispatch(fetchStart());
      fetchAllOrganizationPermissions(organization.id)
        .then(permissions => {
          dispatch(fetchSuccess());
          dispatch({
            type: SET_CURRENT_CONFIG_ORGANIZATION_MODULE,
            payload: {
              ...organization,
              permissions
            }
          });
        })
        .catch(e => {
          dispatch(fetchError(e.message));
        });
    }
  };
};

// export const addConfigurationOrganizationModule = module => {
//     return async dispatch => {
//         dispatch(fetchStart());
//         setTimeout(() => {
//             dispatch(fetchSuccess());
//             const newModule = new Organization(
//                 getId(),
//                 module.description,
//                 false
//             )
//             dispatch({type: ADD_CONFIG_ORGANIZATION_MODULE, payload: newModule});
//         }, 1500);
//     }
// }

export const editConfigurationOrganizationModuleState = (module, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const editModule = new Organization(module.id, module.description, state);
      dispatch({
        type: EDIT_CONFIG_ORGANIZATION_MODULE_STATE,
        payload: editModule
      });
    }, 1500);
  };
};

export const updateConfigurationOrganizationModule = (
  organization,
  operations
) => {
  return async dispatch => {
    dispatch(fetchStart());
    Promise.all(
      operations.map(operation =>
        updateOrganizationOperation(organization.id, operation)
      )
    )
      .then(response => {
        dispatch(
          fetchSuccess(
            "Los modulos de la organizacion se modificaron correctamente"
          )
        );
        dispatch(setCurrentConfigOrganizationModule(null));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateOrganizationsMassive = (organizations, operations, option) => {
  return async dispatch => {
    dispatch(fetchStart());
    console.log({organizations, operations, option});
    updateMassiveOrganizationsOperations(organizations, operations, option)
        .then(response => {
          dispatch(fetchSuccess(response.transactionMessage));
        }).catch(e => {
      dispatch(fetchError(e.message));
    });
  }
}