import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import OutlinedDatePicker from "components/Common/Forms/OutlinedDatePicker";
import Select from "components/Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {mapDateToOutput, mapDateToValue} from "utils/parsers";

const initialData = {
  client: "",
  state: "",
  ot: "",
  fromDate: null,
  toDate: null
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={4}>
          <OutlinedInput
            fullWidth
            value={formData.client}
            id="client-filter"
            label={
              <IntlMessages id="administrator.logistic.order.history.filter.distributor" />
            }
            onChange={value => setFormData("client", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <Select
            id="state-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.state" />
            }
            value={formData.state}
            onChange={e => setFormData("state", e.target.value)}
            fullWidth
          >
            <MenuItem value="Pendiente">
              <IntlMessages id="administrator.logistic.order.filter.state.pending" />
            </MenuItem>
            <MenuItem value="En curso">
              <IntlMessages id="administrator.logistic.order.filter.inProgress" />
            </MenuItem>
            <MenuItem value="Finalizado">
              <IntlMessages id="administrator.logistic.order.filter.terminated" />
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <OutlinedInput
            fullWidth
            value={formData.ot}
            id="ot-filter"
            label={
              <IntlMessages id="administrator.logistic.order.history.filter.ot" />
            }
            onChange={value => setFormData("ot", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.fromDate)}
            id="from-date-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.fromDate" />
            }
            onChange={value => setFormData("fromDate", mapDateToOutput(value))}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.toDate)}
            id="to-date-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.toDate" />
            }
            onChange={value => setFormData("toDate", mapDateToOutput(value))}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
