import {
  APPLY_OPERATIVE_FILTERS,
  FETCH_PETTY_CASHES_SUCCEEDED,
  RESET_OPERATIVE_FILTERS
} from "../../types/Guardian/PettyCashesCustomTypes";
import {
  cancelPrevFetchAllPettyCashesByGuardian,
  fetchAllPettyCashesByGuardian
} from "../../../services/PettyCashService";
import {getUserId} from "../../../services/StorageService";

const fetchAllPettyCashesSucceeded = (data, list) => ({
  type: FETCH_PETTY_CASHES_SUCCEEDED,
  payload: {
    data,
    list
  }
});

export const fetchAllPettyCashesStarted = () => {
  return async dispatch => {
    cancelPrevFetchAllPettyCashesByGuardian();
    fetchAllPettyCashesByGuardian(getUserId()).then(pettyCashes => {
      const currencies = [
        ...new Set(pettyCashes.map(pettyCash => pettyCash.currencyId))
      ];
      const groupedPettyCashes = currencies.map(currency => ({
        currency: currency,
        boxes: pettyCashes.filter(
          pettyCash => pettyCash.currencyId === currency
        )
      }));
      dispatch(fetchAllPettyCashesSucceeded(groupedPettyCashes, pettyCashes));
    });
  };
};

export const applyOperativePettyCashFilters = filters => ({
  type: APPLY_OPERATIVE_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetOperativePettyCashFilters = () => ({
  type: RESET_OPERATIVE_FILTERS
});
