import axiosInstance from "../utils/axiosInstance";
import AssigmentOrder from "../domain/AssigmentOrder";
import AssigmentVehicle from "../domain/AssigmentVehicle";
import AssigmentWorkOrder from "../domain/AssigmentWorkOrder";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";
import moment from "moment";

export const generateAssignation = async data => {
  try {
    const {
      data: generatedData
    } = await axiosInstance.post("/OT/sel_asignacion_generacion_ot", {
      fecha: data.date,
      horaInicial: data.startTime,
      horaFinal: data.endTime,
      vehiculosIds: data.vehicles,
      pedidosIds: data.orders,
      opcionesgeneracion: data.options
    });
    return {
      outDate: generatedData.fechaSalida,
      workOrders: generatedData.oTs.map(workOrder =>
        AssigmentWorkOrder.map(workOrder)
      )
    };
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAssigmentOrders = async filters => {
  try {
    const {
      data: orders
    } = await axiosInstance.post("/OT/sel_ot_asignacion_pedidos", {
      cliente: filters.client || null,
      estadoId: filters.state || null,
      tipoId: filters.type || null,
      fechaInicio: filters.fromDate || null,
      fechaFin: filters.toDate || null
    });
    return orders.map(order => AssigmentOrder.map(order));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAssigmentVehicles = async () => {
  // try {
  //     const {data: vehicles} = await axiosInstance.post(
  //         '/OT/sel_ot_asignacion_vehiculos',
  //         {
  //             vehiculo: filters.vehicle || null,
  //             capacidad: filters.capacity || null,
  //             conductor: filters.driver || null,
  //         }
  //     );
  //     return vehicles.map(vehicle => AssigmentVehicle.map(vehicle));
  // }catch (e) {
  //     return Promise.reject(e);
  // }
  try {
    const {data} = await axiosInstance.post("/Vehiculo/sel_ficha_tecnica", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(v => AssigmentVehicle.map(v));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const confirmWorkOrder = async data => {
  try {
    const {data: response} = await axiosInstance.post("/OT/ins_asignacion_ot", {
      fechaSalida: data.outDate,
      idsOTsGenerados: data.workOrders.map(workOrder => workOrder.id)
    });
    return Response.map(response);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const generateOptimization = async (data, optimizationId = null) => {
  const startTime = data.startTime.split(":");
  const endTime = data.endTime.split(":");
  try {
    const {
      data: result
    } = await axiosInstance.post("/Optimizacion/ins_optimizacion", {
      optimizacion: {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_optimizacion: optimizationId,
        dt_fec_optimizacion_ini: moment(data.date).set({
          hour: startTime[0],
          minute: startTime[1]
        }),
        dt_fec_optimizacion_fin: moment(data.date).set({
          hour: endTime[0],
          minute: endTime[1]
        }),
        bi_balanceado: data.options.some(o => o === 1),
        bi_minimizar_rutas: data.options.some(o => o === 2),
        bi_rutas_abiertas: data.options.some(o => o === 3)
      },
      ls_vehiculos: data.vehicles.map(v => ({nu_id_vehiculo: v})),
      ls_destinos: data.orders.map(order => ({
        nu_id_optimizacion_destino_prioridad: order.priority,
        nu_id_optimizacion_destino_estancia: order.stay,
        nu_latitud: order.latitude,
        nu_longitud: order.longitude,
        dt_fec_ven_ini: order.startDate,
        dt_fec_ven_fin: order.endDate,
        nu_carga_peso: order.weight,
        nu_carga_volumen: order.volume
      }))
    });
    // return {
    //     outDate: generatedData.fechaSalida,
    //     workOrders: generatedData.oTs.map(workOrder => AssigmentWorkOrder.map(workOrder)),
    //     optimizationId: "",
    // };
    return Response.map(result);
  } catch (e) {
    return Promise.reject(e);
  }
};
