import React from "react";

import Container from "@jumbo/components/PageComponents/layouts/PageContainer";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const PageContainer = ({
  description,
  heading,
  breadcrumbs,
  children,
  className,
  restProps
}) => (
  <Container
    heading={heading}
    breadcrumbs={breadcrumbs}
    className={className}
    {...restProps}
  >
    <Box marginBottom={5}>
      <div style={{marginBottom: 10}}>{description}</div>
      <Divider />
    </Box>
    {children}
  </Container>
);

export default PageContainer;
