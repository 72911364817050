export const parseNumberToMoney = number => {
  if(isNaN(number)) return '-';
  const rounded = (+number).toFixed(2);
  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return formatter.format(rounded);
};

export const mapDateToOutput = dateString => {
  if (dateString === "") return null;
  const [ fromYear, fromMonth, fromDay ] = dateString.split("-").map(e => +e);
  return new Date(fromYear, fromMonth - 1, fromDay);
};

export const mapDateToValue = dateObj => {
  if (dateObj === null) return "";
  const [ year, m, d ] = [
    dateObj.getFullYear(),
    dateObj.getMonth(),
    dateObj.getDate()
  ];
  const month = m + 1 < 10 ? `0${m + 1}` : m + 1;
  const date = d < 10 ? `0${d}` : d;
  return `${year}-${month}-${date}`;
};

export const minutesToTime = num => {
  let hours = Math.floor(num / 60);
  let minutes = num % 60;
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  return `${hours}:${minutes}`;
};

export const minutesToDayAndTime = num => {
  let hours = Math.floor(num / 60);
  let minutes = Math.floor(num % 60);
  let days = Math.floor(hours / 24);
  hours = Math.floor(hours % 24);
  if(days < 10) days = `0${days}`
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  return `${days} ${hours}:${minutes}`;
};

export const timeOverDayToTimeDay = time => {
  let [hours, minutes] = time.split(':');
  hours = Number(hours);
  if (hours <= 23) return time;
  const days = Math.floor(hours / 24);
  hours = hours % 24;
  if (hours < 10) hours = `0${hours}`;
  return `${hours}:${minutes}+${days}`;
}

export const minutesToNoStringHours = num => {
  return num / 60;
};

export const hoursToTime = num => {
  let hours = Math.floor(num);
  let minutes = Math.floor((num - hours) * 60);
  if (hours < 10) hours = `0${hours}`;
  if (minutes < 10) minutes = `0${minutes}`;
  return `${hours}:${minutes}`;
};


export const parseLoweCamelCaseToTitleText = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}