import {
  ADD_WORK_ORDER_SUCCESS,
  APPLY_WORK_ORDERS_FILTERS,
  DELETE_WORK_ORDER_STARTED,
  DELETE_WORK_ORDER_SUCCESS,
  EDIT_DESTINATION_STARTED,
  EDIT_DESTINATION_SUCCESS,
  EDIT_WORK_ORDER_STARTED,
  EDIT_WORK_ORDER_SUCCESS,
  FETCH_ALL_WORK_ORDERS_STARTED,
  FETCH_ALL_WORK_ORDERS_SUCCEEDED,
  OPERATION_FAILED,
  RESET_CURRENT_WORK_ORDER,
  RESET_OPERATION,
  RESET_WORK_ORDERS_FILTERS,
  SET_CURRENT_WORK_ORDER,
  UPDATE_DESTINATIONS_ORDER
} from "../../types/Administrator/WorkOrdersTypes";
import {fetchFailed, fetchStarted, fetchSucceeded, setOnErrorMessage} from "./ui";
import {
  addWorkOrder,
  deleteDestination,
  deleteWorkOrder,
  generateWorkOrdersExcel,
  getWorkOrderById,
  getWorkOrdersGlobal,
  loadDestination,
  updateDestination,
  updateDispatchClient,
  updateDispatchState,
  updateWorkOrder,
  updateWorkOrderDrivers
} from "../../../services/WorkOrdersService";
import WordOrder from "../../../domain/WordOrder";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {downloadFile} from "../../../services/FileService";

const fetchAllWorkOrdersSuccess = orders => ({
  type: FETCH_ALL_WORK_ORDERS_SUCCEEDED,
  payload: orders
});

export const fetchAllWorkersStarted = filters => {
  return async dispatch => {
    dispatch(fetchStarted());
    dispatch({type: FETCH_ALL_WORK_ORDERS_STARTED});
    getWorkOrdersGlobal(filters)
      .then(workOrders => {
        dispatch(fetchSucceeded());
        dispatch(fetchAllWorkOrdersSuccess(workOrders));
      })
      .catch(e => {
        dispatch(fetchFailed());
        dispatch(setOnErrorMessage(e.message));
        dispatch(fetchAllWorkOrdersSuccess([]));
      });
  };
};

export const applyWorkOrdersFilters = filters => {
  return {
    type: APPLY_WORK_ORDERS_FILTERS,
    payload: filters
  };
};

export const restWorkOrdersFilters = () => {
  return {
    type: RESET_WORK_ORDERS_FILTERS
  };
};

export const setCurrentWorkOrder = workOrder => {
  return dispatch => {
    if (workOrder !== undefined) {
      dispatch({
        type: SET_CURRENT_WORK_ORDER,
        payload: null
      });
      getWorkOrderById(workOrder.id)
        .then(ot => {
          dispatch({
            type: SET_CURRENT_WORK_ORDER,
            payload: ot
          });
        })
        .catch(e => {
          dispatch(resetCurrentWorKOrder());
        });
    }
  };
};

export const resetCurrentWorKOrder = () => ({type: RESET_CURRENT_WORK_ORDER});

export const setCreateWorkOrder = () => ({
  type: SET_CURRENT_WORK_ORDER,
  payload: new WordOrder(0, "", "", "", "", "", [], [], "", "", "")
});

export const operationFailed = message => ({
  type: OPERATION_FAILED,
  payload: message
});

export const resetOperation = () => ({
  type: RESET_OPERATION
});

const editWorkOrderSuccess = message => ({
  type: EDIT_WORK_ORDER_SUCCESS,
  payload: message
});

export const editWorkOrderStarted = (data, filters) => {
  return async dispatch => {
    dispatch({type: EDIT_WORK_ORDER_STARTED, payload: data});
    try {
      const response1 = await updateWorkOrder(data);
      const response2 = await updateWorkOrderDrivers(data, data.driver);
      dispatch(editWorkOrderSuccess(`${response1.transactionMessage} - ${response2.transactionMessage}`));
      setTimeout(() => dispatch(fetchAllWorkersStarted(filters)));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

const addWorkOrderSuccess = message => ({
  type: ADD_WORK_ORDER_SUCCESS,
  payload: message
});

export const addWorkOrderStarted = (data, filters) => {
  return async dispatch => {
    dispatch(fetchStart());
    addWorkOrder(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch(setCurrentWorkOrder({id: response.transactionKey}))
        setTimeout(() => {
          dispatch(fetchAllWorkersStarted(filters));
        }, 1500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
const deleteWorkOrderSuccess = message => ({
  type: DELETE_WORK_ORDER_SUCCESS,
  payload: message
});

export const deleteWorkOrderStarted = workOrder => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      dispatch({type: DELETE_WORK_ORDER_STARTED, payload: workOrder});
      const response = await deleteWorkOrder(workOrder);
      dispatch(deleteWorkOrderSuccess(response.transactionMessage));
      dispatch(fetchSuccess(response.transactionMessage));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
const editDestinationSuccess = message => ({
  type: EDIT_DESTINATION_SUCCESS,
  payload: message
});

export const editDestinationStarted = (data, workOrder) => {
  return async dispatch => {
    dispatch({type: EDIT_DESTINATION_STARTED, payload: data});
    updateDestination(data, workOrder)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(setCurrentWorkOrder(workOrder));
        }, 500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const deleteDestinationStarted = data => {
  return async dispatch => {
    dispatch(fetchStart());
    deleteDestination(data)
        .then(response => {
          dispatch(fetchSuccess(response.transactionMessage));
          setTimeout(() => {
            dispatch(setCurrentWorkOrder(data.workOrder));
          }, 1000);
        }).catch(e => {
          dispatch(fetchError(e.message));
    })
  };
};

const addDestinationSuccess = current => {
  return dispatch => {
    dispatch(setCurrentWorkOrder(current));
  };
};

export const addDestinationStarted = (data, setNew = null, current = null) => {
  return async dispatch => {
    // dispatch({type: ADD_DESTINATION_STARTED, payload: data});
    dispatch(fetchStart());
    loadDestination(data, current)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch(addDestinationSuccess(current));
        setNew && setNew();
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateDestinationsOrder = destinations => {
  return {
    type: UPDATE_DESTINATIONS_ORDER,
    payload: destinations
  };
};

export const editDispatchState = (destination, state, onAccept, onCancel, workOrder) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateDispatchState(destination, state)
        .then(response => {
          dispatch(fetchSuccess(response.transactionMessage));
          onAccept && onAccept();
          dispatch(setCurrentWorkOrder(workOrder));
        }).catch(e => {
          dispatch(fetchError(e.message));
          onCancel && onCancel();
    });
  }
}

export const editDispatchClient = ({current, destinationId, clientId, headquarterId}) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateDispatchClient({id: current.id, destinationId, clientId, headquarterId})
        .then(response => {
          dispatch(fetchSuccess(response.transactionMessage));
          dispatch(setCurrentWorkOrder(current));
        }).catch(e => {
          dispatch(fetchError(e.message));
    });
  }
}


export const generateWorkOrdersExcelFile = (filters, search) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const report = await generateWorkOrdersExcel(filters, search)
      dispatch(fetchSuccess());
      downloadFile(
          "report.xlsx",
          report
      );
      dispatch(applyWorkOrdersFilters(filters));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}