import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {getAllKoneIndicators} from "../../../../../../../../services/ControlPanel/Kpi/Reports/KoneServices";

const IndicatorSelect = ({value, onChange, error, hasError}) => {
    const id = 'indicator-filter';
    const {data: indicators} = useFetch(getAllKoneIndicators, {}, []);
    return (
        <Select
            id={id}
            label={
                <IntlMessages
                    id={"control.panel.kpi.areas.grid.distribution.filters.indicator"}
                />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            error={hasError}
            errorMessage={error}
            fullWidth
        >
            {indicators.map(o => (
                <MenuItem key={o.id} value={o.id}>
                    {o.description}
                </MenuItem>
            ))}
        </Select>
    );
}

IndicatorSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any
};

export default IndicatorSelect;