import React from "react";
import {useStyles} from "../styles";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Paper
} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";

const BaseCard = ({
  onSubmit,
  children,
  title,
  onCancel,
  edit,
  setEdit,
  headerAction = null
}) => {
  const classes = useStyles();
  return (
    <Card component={Paper} className={classes.sectionCard}>
      <CardHeader title={title} action={headerAction} />
      <CardContent>{children}</CardContent>
      <CardActions>
        <Box className={classes.buttonsArea}>
          {edit ? (
            <React.Fragment>
              <Box>
                <ContainedButton
                  text={<IntlMessages id={"form.button.cancel"} />}
                  color={"primary"}
                  onClick={() => onCancel()}
                />
              </Box>
              <Box>
                <ContainedButton
                  text={<IntlMessages id={"form.button.save"} />}
                  color={"primary"}
                  onClick={e => onSubmit(e)}
                />
              </Box>
            </React.Fragment>
          ) : (
            <Box>
              <ContainedButton
                text={<IntlMessages id={"form.button.edit"} />}
                color={"primary"}
                onClick={() => setEdit(true)}
              />
            </Box>
          )}
        </Box>
      </CardActions>
    </Card>
  );
};

export default BaseCard;
