export const ADMINISTRATOR_FETCH_UNIT_STARTED =
  "ADMINISTRATOR_FETCH_UNIT_STARTED";
export const ADMINISTRATOR_FETCH_UNIT_SUCCEEDED =
  "ADMINISTRATOR_FETCH_UNIT_SUCCEEDED";
export const FETCH_UNIT_SUCCEEDED = "FETCH_UNIT_SUCCEEDED";
export const APPLY_UNIT_FILTERS = "APPLY_UNIT_FILTERS";
export const RESET_UNIT_FILTERS = "RESET_UNIT_FILTERS";
export const UNIT_CHANGE_STATE = "UNIT_CHANGE_STATE";
export const UNIT_EDIT = "UNIT_EDIT";
export const UNIT_CREATE = "UNIT_CREATE";
