import React from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../hooks/Common/useFetch";
import {fetchAllClientsByDocumentType} from "../../../../services/Treasury/ClientsService";

const ClientSelect = ({value, onChange, documentTypeId}) => {
    const {data: clients, executeService} = useFetch(fetchAllClientsByDocumentType, {documentTypeId}, []);
    const handleChange = (e, newValue) => {
        onChange(newValue);
    };
    React.useEffect(() => {
        executeService();
    }, [documentTypeId])
    return (
        <Autocomplete
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name || ""}
            options={clients.sort((a, b) => a?.name?.localeCompare(b.name))}
            onChange={handleChange}
            value={value}
            autoComplete
            renderInput={params => (
                <TextField
                    {...params}
                    label={<IntlMessages id="client" />}
                    fullWidth
                    variant="outlined"
                />
            )}
        />
    );
};

export default ClientSelect;