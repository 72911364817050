import {SortableContainer} from "react-sortable-hoc";
import {Box} from "@material-ui/core";
import DashboardItem from "./DashboardItem";
import React from "react";

const GraphsContainer = SortableContainer(({widgets, onDelete, updateWidget}) => {
    return(
        <Box
            id='graphs-container'
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                gap: 5,
                marginTop: '1rem'
            }}
        >
            {widgets?.map((widget, index) => (
                <DashboardItem
                    key={widget.id}
                    widget={widget}
                    index={index}
                    onDelete={onDelete}
                    updateWidget={updateWidget}
                />
            ))}
        </Box>
    )
});

export default GraphsContainer;