import React, {useEffect, useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "../../../Common/Forms/Select";
import RejectionTypes from "../../../../models/Guardian/RejectionTypes";
import unresolvedExpenses from "../../../../redux/actions/Supervisor/UnresolvedExpenses";
import {useDispatch, useSelector} from "react-redux";
import rejectionTypes from "../../../../redux/actions/Administrator/RejectionTypes";

const RejectionDialog = ({expense, open, setOpen, setSelectedExpense}) => {
  const [ reason, setReason ] = useState("");
  const dispatch = useDispatch();
  const {data} = useSelector(state => state.administrator.rejectionTypes);

  useEffect(() => {
    if (data.length < 1) {
      dispatch(rejectionTypes.get());
    }
  }, []);

  const handleClose = () => {
    setSelectedExpense(null);
    setOpen(false);
  };

  const handleReject = () => {
    dispatch(
      unresolvedExpenses.reject(expense.id, {rejectionType: {value: reason}})
    );
    setSelectedExpense(null);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="rejection-dialog-title"
    >
      <DialogTitle id="rejection-dialog-title">
        <IntlMessages id={"guardian.advance.detail.reject.dialog.title"} />
      </DialogTitle>
      <DialogContent>
        <Select
          id="rejectReason"
          label={
            <IntlMessages id="guardian.advance.detail.reject.dialog.combo.label" />
          }
          value={reason}
          onChange={e => setReason(e.target.value)}
          fullWidth
        >
          <MenuItem value="">{RejectionTypes.ALL}</MenuItem>
          {data.map(rejectionType => (
            <MenuItem key={rejectionType.id} value={rejectionType.id}>
              {rejectionType.description}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <IntlMessages id={"guardian.advance.detail.reject.dialog.cancel"} />
        </Button>
        <Button onClick={handleReject} color="primary" disabled={reason === ""}>
          <IntlMessages id={"guardian.advance.detail.reject.dialog.reject"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectionDialog;
