import React from "react";
import {useSelector} from "react-redux";
import TableContainer from "../../../../../../Common/Tables/TableContainer";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import TableHeader from "./TableHeader";

const OperationsTable = () => {
  const {currentOperations} = useSelector(
    ({configuration}) => configuration.modules
  );

  return (
    <TableContainer head={<TableHeader />}>
      {Object.values(currentOperations).map(module => (
        <StyledTableRow key={module.id}>
          <StyledTableCell>{module.id}</StyledTableCell>
          <StyledTableCell>{module.description}</StyledTableCell>
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};

export default OperationsTable;
