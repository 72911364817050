class ExpenseState {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static map = state => {
    return new ExpenseState(state.vc_id_estado, state.vc_desc_estado);
  };
}
export default ExpenseState;
