import React from 'react';
import {TextField} from "@material-ui/core";
import AutocompleteMui from "@material-ui/lab/Autocomplete";


const Autocomplete = ({
                          options,
                          onChange,
                          value,
                          disabled,
                          label,
                          name,
                          required = false,
                        optionTextFieldName = 'name'

                      }) => {
    return (
        <AutocompleteMui
            getOptionSelected={(option, value) => option?.id === value?.id}
            getOptionLabel={option => option[optionTextFieldName] || ""}
            options={options}
            onChange={onChange}
            value={value}
            autoComplete
            renderInput={params => (
                <TextField
                    {...params}
                    disabled={disabled}
                    label={label}
                    fullWidth
                    variant="outlined"
                    name={name}
                    required={required}
                />
            )}
        />
    );
};

export default Autocomplete;