import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  tire: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  brand: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  model: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  measures: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  purchaseDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  purchaseValue: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  pressure: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  retreading: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  original: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ],
  active: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.tires.form.field.error" />
    }
  ]
};
