import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  description: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.common.form.files.add.field.error" />
      )
    }
  ],
  expirationDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.common.form.files.add.field.error" />
      )
    }
  ],
  notice: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.common.form.files.add.field.error" />
      )
    }
  ],
  file: [
    {
      rule: v => v !== null,
      message: (
        <IntlMessages id="fleet.management.common.form.files.add.field.error" />
      )
    }
  ]
};
