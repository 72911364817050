import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.expenseSupervision"} />,
    link: "/treasury/cash-management/unresolved-expenses"
  },
  {label: <IntlMessages id={"pages.unresolvedExpenses"} />, isActive: true}
];

const guardianBreadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.cash.management"} />,
    link: "/treasury/cash-management/operative"
  },
  {
    label: <IntlMessages id={"pages.guardian.unresolvedExpenses"} />,
    isActive: true
  }
];

const PageContainer = ({children, isSupervisor}) => (
  <PageContainerComponent
    heading={
      <IntlMessages
        id={
          isSupervisor ? (
            "supervisor.unresolvedExpenses.title"
          ) : (
            "guardian.unresolvedExpenses.title"
          )
        }
      />
    }
    description={
      <IntlMessages
        id={
          isSupervisor ? (
            "supervisor.unresolvedExpenses.description"
          ) : (
            "guardian.unresolvedExpenses.description"
          )
        }
      />
    }
    breadcrumbs={isSupervisor ? breadcrumbs : guardianBreadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  isSupervisor: PropTypes.bool.isRequired
};

export default PageContainer;
