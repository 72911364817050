import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.operative"} />,
    link: "/logistic/distribution/orders"
  },
  {label: <IntlMessages id={"sidebar.logistic.assigment"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"logistic.assigment.page.header"} />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
