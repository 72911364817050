import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="elevators.management.elevators.types.table.code" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="elevators.management.elevators.types.table.name" />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
