import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const DetailTable = ({type}) => (
  <TableContainer
    component={Paper}
    style={{
      width: "90%",
      margin: "36px 40px"
    }}
  >
    <Table>
      <TableHead>
        <StyledTableRow>
          <StyledTableCell>
            <IntlMessages id="administrator.tag.types.table.id" />
          </StyledTableCell>
          <StyledTableCell>
            <IntlMessages id="administrator.tag.types.table.description" />
          </StyledTableCell>
          <StyledTableCell>
            <IntlMessages id="administrator.tag.types.table.state" />
          </StyledTableCell>
        </StyledTableRow>
      </TableHead>
      <TableBody>
        <StyledTableRow>
          <StyledTableCell>{type.id}</StyledTableCell>
          <StyledTableCell>{type.description}</StyledTableCell>
          <StyledTableCell>
            {type.state ? (
              <IntlMessages id="administrator.tag.types.table.active" />
            ) : (
              <IntlMessages id="administrator.tag.types.table.inactive" />
            )}
          </StyledTableCell>
        </StyledTableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default DetailTable;
