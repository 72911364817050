import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import {useSelector} from "react-redux";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";

const TableHeader = () => {
  const {tableHeaders} = useSelector(({logistic}) => logistic.serviceList);
  return (
    <StyledTableRow>
      <StyledTableCell />
      {Object.values(tableHeaders).filter(h => h.state).map(header => (
        <StyledTableCell key={header.id} align="center">
          {header.label}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
};

export default TableHeader;
