import React, {useState, useCallback, useEffect} from "react";
import {connect} from "react-redux";
import PageContainer from "components/Guardian/Stock/PageContainer";
import Filters from "components/Guardian/Stock/Filters";
import Table from "components/Guardian/Stock/Table";
import FilterBuilder from "utils/filters";

import stock from "redux/actions/Administrator/Stock";

const initialFilters = {
  groupedBy: "1",
  product: "",
  entity: "",
  campus: "",
  warehouse: "",
  tags: "",
  active: undefined
};

const Stock = ({tableData, dispatch}) => {
  const [ filters, setFilters ] = useState(initialFilters);

  const applyFilters = useCallback(
    filters => setFilters(v => ({...v, ...filters})),
    []
  );
  const resetFilters = () => setFilters(initialFilters);

  useEffect(
    () => {
      dispatch(stock.get());
      return () => dispatch(stock.resetState());
    },
    [ dispatch ]
  );

  const filtered = new FilterBuilder(tableData.data)
    .byFieldIncludes("active", filters.active)
    .byFieldIncludes("product", filters.product)
    .byFieldIncludes("entity", filters.entity)
    .byFieldIncludes("campus", filters.campus)
    .byFieldIncludes("warehouse", filters.warehouse)
    .byFieldIncludes("tags", filters.tag)
    .build();

  const tableData2 = filtered.reduce((acc, current) => {
    const label = filters.groupedBy === "1" ? current.product : current.entity;
    const labelIndex = acc.findIndex(e => e.label === label);
    if (labelIndex !== -1) {
      acc[labelIndex].data.push(current);
    } else {
      acc.push({label, data: [ current ]});
    }
    return acc;
  }, []);


  return (
    <PageContainer>
      <Filters onFilter={applyFilters} onClose={resetFilters} />
      <Table data={tableData2} byProduct={filters.groupedBy === "1"} />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const data = state.administrator.stock;
  return {tableData: data};
};

export default connect(mapStateToProps)(Stock);
