import React from "react";
import WorkOrderItem from "./WorkOrderItem";
import {Box} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllMonitoringWorkOrdersStarted} from "../../../../redux/actions/Logistic/Monitoring";

const WorkOrderList = ({
  data,
  expandAll,
  openChatDialog,
  setDevice,
  setSelectedDestination
}) => {
  const {filters} = useSelector(state => state.logistic.monitoring);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(fetchAllMonitoringWorkOrdersStarted(filters));
    },
    [ filters ]
  );
  return (
    <Box display="flex" flexDirection="column">
      {data.map(workOrder => (
        <WorkOrderItem
          setSelectedDestination={setSelectedDestination}
          setDevice={setDevice}
          openChatDialog={openChatDialog}
          expandAll={expandAll}
          key={workOrder.id}
          workOrder={workOrder}
        />
      ))}
    </Box>
  );
};

export default WorkOrderList;
