import React from "react";

import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import CashManagersAutocomplete from "containers/Administrator/CashManagersAutocomplete";
import FileInput from "components/Common/Forms/FileInput";
import CurrencySelect from "components/Common/Forms/Select/CurrencySelect";

import useForm from "hooks/Common/useForm";
import IntlMessages from "@jumbo/utils/IntlMessages";

import validations from "./validations";
import TagsPicker from "containers/Administrator/TagsPicker";
import PettyCashTypesSelect from "containers/Administrator/PettyCashTypesSelect";
import CostCenterSelect from "../../../../../Common/Forms/Select/CostCenterSelect";

const initialData = {
  cashManager: null,
  pettyCashType: null,
  amount: "",
  currency: "",
  proofOfAcceptance: [],
  tags: [],
  costCenter: ''
};

const Form = ({id, onSubmit}) => {
  const {formData, errors, setFormData, handleSubmit} = useForm(
    initialData,
    validations
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} md={8}>
          <GridContainer spacing={4}>
            <Grid item xs={12}>
              <CashManagersAutocomplete
                value={formData.cashManager}
                hasError={errors.cashManager.length > 0}
                error={errors.cashManager[0]}
                onChange={value => setFormData("cashManager", value)}
              />
            </Grid>
            <Grid item xs={12}>
              <PettyCashTypesSelect
                id="petty-cash-type"
                label={
                  <IntlMessages id="administrator.form.pettyCashTypeSelect.label" />
                }
                value={formData.pettyCashType}
                hasError={errors.pettyCashType.length > 0}
                error={errors.pettyCashType[0]}
                onChange={value => setFormData("pettyCashType", value)}
              />
            </Grid>
            <Grid item xs={6} sm={7}>
              <OutlinedInput
                type="number"
                value={formData.amount}
                hasError={errors.amount.length > 0}
                error={errors.amount[0]}
                fullWidth
                id="amount"
                label={
                  <IntlMessages id="administrator.cashManagers.createCashManagerDialog.field.amount" />
                }
                onChange={value => setFormData("amount", value)}
              />
            </Grid>
            <Grid item xs={6} sm={5}>
              <CurrencySelect
                id="currency"
                value={formData.currency}
                onChange={value => setFormData("currency", value)}
                fullWidth
                hasError={errors?.currency?.length > 0}
                error={errors.currency[0]}
              />
            </Grid>
            <Grid item xs={12}>
              <FileInput
                files={formData.proofOfAcceptance}
                error={errors.proofOfAcceptance.length > 0}
                helperText={errors.proofOfAcceptance[0]}
                maxFiles={1}
                accept="application/pdf"
                label={
                  <IntlMessages id="administrator.cashManagers.createCashManagerDialog.field.proofOfAcceptance" />
                }
                onChange={value => setFormData("proofOfAcceptance", value)}
              />
            </Grid>
          </GridContainer>
        </Grid>
        <Grid
            item xs={12} md={4}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem'
            }}
        >
          <TagsPicker
            id="administrator-cash-managers-tags-picker"
            values={formData.tags}
            hasError={errors.tags.length > 0}
            error={errors.tags[0]}
            onChange={value => setFormData("tags", value)}
          />
          <CostCenterSelect
              value={formData.costCenter}
              hasError={errors?.costCenter?.length > 0}
              error={errors.costCenter[0]}
              onChange={value => setFormData("costCenter", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
