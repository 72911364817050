import React from 'react';
import useForm from "../../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentReportFile} from "../../../../../../../redux/actions/ControlPanel/Kpi";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import DriversPicker from "../../../../../../Logistic/WorkOrders/WorkOrdersFilters/DriversPicker";
import RegulationSelect from "./RegulationSelect";
import BooleanSelect from "../../../../../../Common/Forms/Select/BooleanSelect";
import SendEmail from "../../../Common/SendEmailDialog";
import SaveFilters from "../../../Common/SaveFilters";
import DateFilters from "../../../Common/DateFilters";
import VisualizationTypeSelect from "./VisualizationTypeSelect";
import VehiclesSelect from "../../../../../../FleetManagement/Tachograph/Files/FilesTable/Filters/VehiclesSelect";

const initialData ={
    period: 1,
    startDate: '',
    endDate: '',
    drivers: [],
    regulation: 1,
    onlyInfractions: true,
    visualizationType: 1,
    vehicles: [],
}

const Form = ({service}) => {
    const {formData, errors, setFormData, resetForm, setValues} = useForm(initialData, );
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    const handleSubmitPDF = () => {
        dispatch(setCurrentReportFile(service, formData, 1));
    }
    const handleSubmitExcel = () => {
        dispatch(setCurrentReportFile(service, formData, 2));
    }
    React.useEffect(() => {
        resetForm();
        current?.filters && setValues(current.filters);
    }, [service]);

    return (
        <form>
            <GridContainer spacing={4}>
                {(service === 40 || service === 39) &&
                    <Grid item xs={12} sm={6}>
                        <VehiclesSelect
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </Grid>
                }
                {service !== 40 &&
                    <React.Fragment>
                        <Grid item xs={12} sm={6}>
                            <DriversPicker
                                values={formData.drivers}
                                onChange={value => setFormData('drivers', value)}
                            />
                        </Grid>
                        {service === 20 &&
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <RegulationSelect
                                        value={formData.regulation}
                                        onChange={value => setFormData('regulation', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <BooleanSelect
                                        label={<IntlMessages id={"control.panel.kpi.areas.grid.tachograph.filters.only"} />}
                                        value={formData.onlyInfractions}
                                        onChange={value => setFormData('onlyInfractions', value)}
                                        fullWidth
                                    />
                                </Grid>
                            </React.Fragment>

                        }
                        {service !== 41 &&
                            <DateFilters
                                formData={formData}
                                setFormData={setFormData}
                            />
                        }
                    </React.Fragment>
                }
                {service === 40 &&
                    <Grid item xs={12} sm={6}>
                        <VisualizationTypeSelect
                            value={formData.visualizationType}
                            onChange={value => setFormData('visualizationType', value)}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={4} lg={3}>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitPDF()}
                        // disabled={onValidate()}
                        color="primary"
                    >
                        Generar PDF
                    </Button>
                </Grid>
                <SendEmail filters={formData} />
                <SaveFilters filters={formData}/>

                {/*<Grid item xs={12} sm={4} lg={3}>*/}
                {/*    <Button*/}
                {/*        variant="contained"*/}
                {/*        onClick={() => handleSubmitExcel()}*/}
                {/*        // disabled={onValidate()}*/}
                {/*        color="primary"*/}
                {/*    >*/}
                {/*        Generar Excel*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </GridContainer>
        </form>
    );
};

Form.propTypes = {

};

export default Form;