import React from "react";
import {Grid} from "@material-ui/core";
import BaseCard from "../BaseCard";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import AttentionStateSelect from "./AttentionStateSelect";
import DetailSelect from "./DetailSelect";
import {useDispatch, useSelector} from "react-redux";
import {editElevatorStateInfo} from "../../../../../../../redux/actions/ElevatorsManagement/Elevators";

const StateArea = ({initialData}) => {
  const id = "state-info-form";
  const {formData, setFormData, resetForm, handleSubmit} = useForm(initialData);
  const {current, filters} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const dispatch = useDispatch();
  const [ edit, setEdit ] = React.useState(false);
  const onSave = data => {
    dispatch(editElevatorStateInfo(current, data, filters));
    setEdit(false);
  };
  const onCancel = () => {
    resetForm();
    setEdit(false);
  };
  return (
    <BaseCard
      onSubmit={handleSubmit(onSave)}
      title={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.state"
          }
        />
      }
      edit={edit}
      setEdit={setEdit}
      onCancel={onCancel}
    >
      <form id={id}>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6}>
            <AttentionStateSelect
              id="state-attention-state"
              value={formData.state}
              disabled={!edit}
              setValue={value => setFormData("state", value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DetailSelect
              id="state-detail"
              value={formData.detail}
              disabled={!edit}
              setValue={value => setFormData("detail", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              id="state-reason"
              value={formData.reason}
              label={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.state.reason"
                  }
                />
              }
              fullWidth
              onChange={value => setFormData("reason", value)}
              disabled={!edit}
              type="text"
            />
          </Grid>
        </GridContainer>
      </form>
    </BaseCard>
  );
};

export default StateArea;
