import React, {useEffect} from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Grid, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllEmployeesByEntityStarted} from "../../../../../redux/actions/Guardians/Employees";
import Autocomplete from "@material-ui/lab/Autocomplete";

const DriverInput = ({
  driver,
  setDriver,
  isLoading,
  errors,
  currentAdvanceDriver,
  box
}) => {
  const [ disabled, setDisabled ] = React.useState(false);
  const {data} = useSelector(state => state.guardian.employees);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length <= 0) {
      dispatch(fetchAllEmployeesByEntityStarted());
    }
  }, []);

  useEffect(
    () => {
      if (currentAdvanceDriver) {
        setDriver(currentAdvanceDriver);
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    },
    [ currentAdvanceDriver ]
  );
  const handleOnChange = (e, newValue) => {
    setDriver(newValue)
  }
  return (
    <Grid item xs={12} sm={12}>
      <Autocomplete
        value={driver}
        options={data}
        onChange={handleOnChange}
        id="driver"
        disabled={isLoading || disabled || box === ""}
        getOptionSelected={(option, value) => value.id === option.id}
        getOptionLabel={option => `${option?.name || ""}-${option?.doi || ""}`}
        autoComplete
        renderInput={params => (
            <TextField
                {...params}
                label={<IntlMessages id={"guardian.add.advance.form.driver"} />}
                fullWidth
                variant="outlined"
                error={errors.driver.length > 0}
                helperText={errors.driver[0]}
            />
        )}
      />
    </Grid>
  );
};

export default DriverInput;
