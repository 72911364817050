const initialState = {
  data: null,
  isLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "START_CREATE_ACCOUNTING": {
      return {
        ...state,
        ...action.payload
      };
    }
    case "ACCOUNTING_CREATED": {
      return {...state, ...initialState};
    }
    default: {
      return state;
    }
  }
};
