import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";

const CreateTagTypeDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-tag-type"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.tags.type.add.form.title" />}
    onClose={onClose}
  >
    <Form id="create-tag-type" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateTagTypeDialog;
