import {
  ADD_REFUELING,
  APPLY_REFUELING_FILTERS,
  EDIT_REFUELING,
  FETCH_ALL_REFUELING,
  RESET_REFUELING_FILTERS,
  RESET_REFUELING_STATE,
  SET_CURRENT_REFUELING
} from "../../types/FleetManagement/RefuelingTypes";
import {fetchStart, fetchSuccess} from "../Common";
import {formatDateFromTimeAndDateString} from "../../../utils/dates";

const data = [
  {
    id: 1,
    plaque: "ABC-123",
    date: new Date().toISOString(),
    fuelType: "Type 1",
    station: "Sol",
    driver: "Jose",
    gallons: 25,
    price: 1.04,
    amount: 26
  },
  {
    id: 2,
    plaque: "XYZ-456",
    date: new Date().toISOString(),
    fuelType: "Type 2",
    station: "Sol",
    driver: "Jose",
    gallons: 25,
    price: 1.04,
    amount: 26
  }
];

export const resetRefuelingState = () => ({type: RESET_REFUELING_STATE});
export const applyRefuelingFilters = filters => ({
  type: APPLY_REFUELING_FILTERS,
  payload: filters
});
export const resetRefuelingFilters = () => ({type: RESET_REFUELING_FILTERS});
export const setCurrentRefueling = refueling => ({
  type: SET_CURRENT_REFUELING,
  payload: refueling
});
export const fetchAllRefueling = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: FETCH_ALL_REFUELING, payload: data});
    }, 1500);
  };
};

export const addRefueling = (refueling, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const newRefueling = {
        ...refueling,
        date: formatDateFromTimeAndDateString(refueling.date, refueling.time),
        gallons: Number(refueling.gallons) || 0,
        amount: Number(refueling.amount) || 0,
        price: Number(refueling.price) || 0
      };
      dispatch({type: ADD_REFUELING, payload: newRefueling});
      onClose && onClose();
    }, 1500);
  };
};

export const editRefueling = (refueling, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const editedRefueling = {
        ...refueling,
        date: formatDateFromTimeAndDateString(refueling.date, refueling.time),
        gallons: Number(refueling.gallons) || 0,
        amount: Number(refueling.amount) || 0,
        price: Number(refueling.price) || 0
      };
      dispatch({type: EDIT_REFUELING, payload: editedRefueling});
      onClose && onClose();
    }, 1500);
  };
};
