import React, {useEffect, useState} from "react";
import {FormHelperText, Grid, MenuItem, OutlinedInput} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../Common/Forms/Select";
import {fetchAllDocumentTypes} from "../../../../../../services/DocumentTypeService";

const DocumentInput = ({
  documentType,
  setDocumentType,
  documentNumber,
  setDocumentNumber,
  errors,
  isLoading
}) => {
  const [ documentTypes, setDocumentTypes ] = useState([]);

  useEffect(() => {
    fetchAllDocumentTypes().then(documentTypes => {
      setDocumentTypes([ ...documentTypes ]);
    });
  }, []);

  return (
    <Grid item xs={12} sm={12}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={6}>
          <Select
            value={documentType}
            onChange={e => setDocumentType(e.target.value)}
            id="documentType"
            label={
              <IntlMessages
                id={"guardian.add.expense.form.document.type.type"}
              />
            }
            fullWidth
            disabled={isLoading}
            error={errors.documentType.length > 0}
            errorMessage={errors.documentType[0]}
          >
            <MenuItem value="">
              <IntlMessages id="guardian.add.expense.form.document.type.select.item" />
            </MenuItem>
            {documentTypes.length &&
              documentTypes.map(document => (
                <MenuItem key={document.id} value={document.id}>
                  {document.description}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl
            error={errors.documentNumber.length > 0}
            variant="outlined"
            size="small"
            fullWidth
          >
            <InputLabel htmlFor="documentNumber">
              <IntlMessages
                id={"guardian.add.expense.form.document.number.type"}
              />
            </InputLabel>
            <OutlinedInput
              value={documentNumber}
              id="documentNumber"
              label={
                <IntlMessages
                  id={"guardian.add.expense.form.document.number.type"}
                />
              }
              onChange={e => setDocumentNumber(e.target.value)}
              disabled={isLoading}
            />
            <FormHelperText>{errors.documentNumber[0]}</FormHelperText>
          </FormControl>
        </Grid>
      </GridContainer>
    </Grid>
  );
};

export default DocumentInput;
