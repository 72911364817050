export const RESET_VEHICLES_DOCUMENTS_STATE = "RESET_VEHICLES_DOCUMENTS_STATE";
export const FETCH_ALL_LIST_VEHICLES_DOCUMENTS =
  "FETCH_ALL_LIST_VEHICLES_DOCUMENTS";
export const APPLY_VEHICLES_LIST_DOCUMENTS_FILTERS =
  "APPLY_VEHICLES_LIST_DOCUMENTS_FILTERS";
export const RESET_VEHICLES_LIST_DOCUMENTS_FILTERS =
  "RESET_VEHICLES_LIST_DOCUMENTS_FILTERS";
export const SET_CURRENT_LIST_DOCUMENT = "SET_CURRENT_LIST_DOCUMENT";
export const RESET_CURRENT_LIST_DOCUMENT = "RESET_CURRENT_LIST_DOCUMENT";
export const CREATE_VEHICLE_DOCUMENT = "CREATE_VEHICLE_DOCUMENT";
export const UPDATE_VEHICLE_DOCUMENT = "UPDATE_VEHICLE_DOCUMENT";
export const APPLY_VEHICLES_SUMMARY_DOCUMENTS_FILTERS =
  "APPLY_VEHICLES_SUMMARY_DOCUMENTS_FILTERS";
export const RESET_VEHICLES_SUMMARY_DOCUMENTS_FILTERS =
  "RESET_VEHICLES_SUMMARY_DOCUMENTS_FILTERS";
export const FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS =
  "FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS";
export const RESET_ALL_SUMMARY_VEHICLES_DOCUMENTS_DATA =
  "RESET_ALL_SUMMARY_VEHICLES_DOCUMENTS_DATA";
export const FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS_VIEWS =
  "FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS_VIEWS";
export const FETCH_VEHICLES_DOCUMENTS_DASHBOARD =
    "FETCH_VEHICLES_DOCUMENTS_DASHBOARD";