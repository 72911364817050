import ui from "./ui";
import {
  cancelPrevProductSelectFetchAll,
  fetchAllProductSelect,
  fetchAllProductSelectRegulation
} from "../../../services/ProductSelectService";
import {
  FETCH_PRODUCT,
  FETCH_PRODUCT_REGULATION
} from "../../types/Administrator/ProductSelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevProductSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchProductSelectSucceeded = data => ({
  type: FETCH_PRODUCT,
  payload: {
    data
  }
});

export const fetchDataProduct = idWarehouse => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevProductSelectFetchAll();
    fetchAllProductSelect(idWarehouse).then(productSelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchProductSelectSucceeded(productSelect));
    });
  };
};

const fetchProductSelectRegulationSucceeded = data => ({
  type: FETCH_PRODUCT_REGULATION,
  payload: {
    data
  }
});

export const fetchDataProductRegulation = idWarehouse => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevProductSelectFetchAll();
    fetchAllProductSelectRegulation(idWarehouse).then(productSelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchProductSelectRegulationSucceeded(productSelect));
    });
  };
};

export default {
  get: fetchDataProduct,
  getRegulation: fetchDataProductRegulation,
  resetState
};
