export const FETCH_ALL_LIQUIDATED_EXPENSES_STARTED =
  "FETCH_ALL_LIQUIDATED_EXPENSES_STARTED";
export const FETCH_ALL_LIQUIDATED_EXPENSES_SUCCESS =
  "FETCH_ALL_LIQUIDATED_EXPENSES_SUCCESS";

export const ADD_EXPENSES_TO_ACCOUNTING_STARTED =
  "ADD_EXPENSES_TO_ACCOUNTING_STARTED";
export const ADD_EXPENSES_TO_ACCOUNTING_SUCCESS =
  "ADD_EXPENSES_TO_ACCOUNTING_SUCCESS";

export const DELETE_EXPENSES_FROM_ACCOUNTING_STARTED =
  "DELETE_EXPENSES_FROM_ACCOUNTING_STARTED";
export const DELETE_EXPENSES_FROM_ACCOUNTING_SUCCESS =
  "DELETE_EXPENSES_FROM_ACCOUNTING_SUCCESS";
