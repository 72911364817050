import {
  CLEAR_CURRENT_ADVANCE,
  SET_CURRENT_ADVANCE
} from "../../types/CustomTypes";
import {FETCH_CURRENT_ADVANCE_EXPENSES_SUCCEEDED} from "../../types/Guardian/AdvancesCustomTypes";
import {fetchAllExpensesByAdvance} from "../../../services/ExpenseService";

export const clearCurrentAdvance = () => {
  return {
    type: CLEAR_CURRENT_ADVANCE
  };
};

export const setCurrentAdvance = advance => {
  return dispatch => {
    dispatch(fetchCurrentAdvanceExpensesStarted(advance.id));
    dispatch({
      type: SET_CURRENT_ADVANCE,
      payload: advance
    });
  };
};

const fetchCurrentAdvanceExpensesSucceeded = expenses => {
  return {
    type: FETCH_CURRENT_ADVANCE_EXPENSES_SUCCEEDED,
    payload: {
      expenses: expenses
    }
  };
};

export const fetchCurrentAdvanceExpensesStarted = currentAdvanceId => {
  return async dispatch => {
    fetchAllExpensesByAdvance(currentAdvanceId).then(expenses => {
      dispatch(fetchCurrentAdvanceExpensesSucceeded(expenses));
    });
  };
};
