import React, {useState} from "react";

import MuiTableRow from "@material-ui/core/TableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
// import IconButton from '@material-ui/core/IconButton';

const TableRow = ({rowData}) => {
  return (
    <React.Fragment>
      <MuiTableRow>
        <StyledTableCell />
        <StyledTableCell align="left">{rowData.actualDate}</StyledTableCell>
        <StyledTableCell align="left">{rowData.name}</StyledTableCell>
        <StyledTableCell align="left">
          {rowData.startDate + " - " + rowData.endDate}
        </StyledTableCell>
        <StyledTableCell align="left">{rowData.prevTime}</StyledTableCell>
        <StyledTableCell align="left">
          {rowData.totalTimeWorkedFormat}
        </StyledTableCell>
        <StyledTableCell align="left">{rowData.diff}</StyledTableCell>
        <StyledTableCell />
      </MuiTableRow>
    </React.Fragment>
  );
};

export default TableRow;
