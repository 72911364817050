import React, {useEffect, useState} from 'react';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import {Box, Button, Grid, MenuItem} from '@material-ui/core';
import Select from '../../../Common/Forms/Select';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import {clearCurrentAdvance} from '../../../../redux/actions/Guardians/CurrentAdvance';
import {clearCurrentBox} from '../../../../redux/actions/Guardians/CurrentBox';
import {useDispatch, useSelector} from 'react-redux';
import useForm from '../../../../hooks/Common/useForm';
import FileInput from '../../../Common/Forms/FileInput';
import {submitAddExpenseForm} from '../../../../redux/actions/Guardians/AddExpenseForm';
import validations from './validations';
import BoxInput from '../../Common/Inputs/BoxInput';
import AdvanceInput from '../../Common/Inputs/AdvanceInput';
import AmountInput from '../../Common/Inputs/AmountInput';
import ObservationsInput from '../../Common/Inputs/ObservationsInput';
import {fetchExpenseTypes} from "../../../../redux/actions/Administrator/ExpenseTypes";
import DriverField from "../../Common/Fields/DriverField";
import {cancelPrevFetchAllAdvancesByPettyCash, fetchAllAdvancesByPettyCash} from "../../../../services/AdvanceService";
import RouteField from "../../Common/Fields/RouteField";
import DocumentInput from "./Inputs/DocumentInput";
import RucInput from "./Inputs/RucInput";
import BusinessNameField from "../../Common/Fields/BusinessNameField";
import AddressField from "../../Common/Fields/AddressField";
import {validateFormField} from "../../../../utils/validateField";
import {useHistory} from "react-router-dom";

const AddExpensesForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addExpenseForm, currentAdvance, currentBox, pettyCashes } = useSelector(state => state.guardian);
  const {administrator} = useSelector(state => state);
  const { formData: data, isLoading } = addExpenseForm;
  const initialData = {
    box: data.box,
    travel: data.travel,
    expenseType: data.expenseType,
    driver: data.driver,
    route: data.route,
    ruc: data.ruc,
    businessName: data.businessName,
    address: data.address,
    documentType: data.documentType,
    documentNumber: data.documentNumber,
    amount: data.amount,
    observations: data.observations,
    files: data.files,
  };

  const { formData, errors, handleSubmit, setFormData, resetForm } = useForm(initialData, validations);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [advances, setAdvances] = useState([]);
  useEffect(() => {
    cancelPrevFetchAllAdvancesByPettyCash();
    fetchAllAdvancesByPettyCash(formData.box).then(advances => {
      setAdvances([...advances]);
    });
  }, [formData.box]);

  const onSubmitWrapper = async expense => {
    dispatch(submitAddExpenseForm(expense, history));
  };

  useEffect(() => {
    if(administrator.expenseTypes.data.length <= 0) {
      dispatch(fetchExpenseTypes());
    }
  }, []);

  useEffect(() => {
    setExpenseTypes([...administrator.expenseTypes.data.filter(expenseType => expenseType.active)]);
  }, [administrator.expenseTypes.data]);

  useEffect(() => {
    if (advances.length > 0) {
      setFormData('driver', formData.travel !== ''? advances?.find(advance => advance.id === formData.travel).driver : '');
      setFormData('route', formData.travel !== ''? advances?.find(advance => advance.id === formData.travel).route : '');
    }
  }, [formData.travel, advances])

  const validateAdvance = () => {
      if (formData.box && pettyCashes.list.length > 0) {
        return pettyCashes.list.find(p => p.id === formData.box).obligationOfAdvances;
      }
      return false;
  }
  return (
    <React.Fragment>
      <form id="add-advance-form" onSubmit={handleSubmit(onSubmitWrapper)}>
        <GridContainer spacing={4}>
          <BoxInput
            box={formData.box}
            errors={errors}
            isLoading={isLoading}
            currentBox={currentBox.box}
            currentAdvanceBox={currentAdvance.box}
            setBox={data => setFormData('box', data)}
          />
          <AdvanceInput
            travel={formData.travel}
            errors={errors}
            setTravel={data => setFormData('travel', data)}
            currentTravel={currentAdvance.id}
            advances={advances}
            disabled={formData.box === '' || currentAdvance.id !== '' || isLoading}
            obligation={validateAdvance()}
          />
          <Grid item xs={12} sm={12}>
            <Select
              value={formData.expenseType}
              onChange={e => setFormData('expenseType', e.target.value)}
              id="expenseType"
              label={<IntlMessages id={'guardian.add.expense.form.expense.type'} />}
              fullWidth
              disabled={isLoading || (formData.travel === '' && validateAdvance())}
              error={errors.expenseType.length > 0}
              errorMessage={errors.expenseType[0]}>
              <MenuItem value="">
                <IntlMessages id={'guardian.add.expense.form.expense.type.select.item'} />
              </MenuItem>
              {expenseTypes.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <DriverField
            driver={formData.driver}
          />
          <RouteField
              route={formData.route}
          />
          <RucInput
            ruc={formData.ruc}
            setRuc={data => setFormData('ruc', data)}
            errors={errors}
            isLoading={isLoading}
            setRucData={data => {setFormData('businessName', data.businessName); setFormData('address', data.address);}}
            clearRucData={data => {setFormData('businessName', ''); setFormData('address', '');}}
          />
          <BusinessNameField
            businessName={formData.businessName}
          />
          <AddressField
            address={formData.address}
          />
          <DocumentInput
            documentType={formData.documentType}
            documentNumber={formData.documentNumber}
            setDocumentType={data => setFormData('documentType', data)}
            setDocumentNumber={data => setFormData('documentNumber', data)}
            errors={errors}
            isLoading={isLoading}
          />
          <AmountInput
            amount={formData.amount}
            setAmount={data => setFormData('amount', data)}
            isLoading={isLoading}
            errors={errors}
            label={'guardian.add.expense.form.amount'}
            currentBalance={currentAdvance.balance}
            condition={false}
          />
          <ObservationsInput
            observations={formData.observations}
            setObservations={data => setFormData('observations', data)}
            isLoading={isLoading}
            errors={errors}
            label={'guardian.add.advance.form.observations'}
          />
          <Grid item xs={12}>
            <FileInput
              files={formData.files}
              error={errors.files.length > 0}
              helperText={errors.files[0]}
              maxFiles={1}
              accept="image/jpeg"
              label={<IntlMessages id={'guardian.add.expense.form.files'} />}
              onChange={value => setFormData('files', value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box display="flex" alignContent="center" justifyContent="center">
              <Button
                variant="contained"
                style={{
                  marginRight: '16px',
                }}
                color="primary"
                type="submit"
                form="add-advance-form"
                disabled={isLoading || (validateFormField(formData, ['route', 'businessName', 'address', 'driver', 'travel']) && !validateAdvance())}>
                <IntlMessages id={'guardian.add.advance.form.assign'} />
              </Button>
              <Button
                variant="contained"
                mx={3}
                color="primary"
                onClick={() => {
                  dispatch(clearCurrentAdvance());
                  dispatch(clearCurrentBox());
                  resetForm();
                }}>
                <IntlMessages id={'guardian.add.advance.form.reset'} />
              </Button>
            </Box>
          </Grid>
        </GridContainer>
      </form>
    </React.Fragment>
  );
};

export default AddExpensesForm;
