import {
  FETCH_STOCK_SUCCEEDED,
  APPLY_STOCK_FILTERS,
  RESET_STOCK_FILTERS
} from "../../types/Administrator/Stock";

const INIT_STATE = {
  data: [],
  filters: {
    groupedBy: "1",
    product: "",
    entity: "",
    campus: "",
    tag: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_STOCK_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_STOCK_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_STOCK_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    default: {
      return state;
    }
  }
};
