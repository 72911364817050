import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import {Checkbox} from "@material-ui/core";
import {blueGrey} from "@material-ui/core/colors";

const TableHeader = ({orderBy, order, onSort, onSelectAll, selectedAll}) => (
  <React.Fragment>
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id="guardian.accounting.dialog.add.expense.dialog.table.header.selection" />
        <Checkbox
            name="selected"
            onChange={() => onSelectAll()}
            checked={selectedAll}
            style={{
              color: blueGrey[100],
            }}
        />
      </StyledTableCell>
      <TableCellWithSorting
        property="executor"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.dialog.expenses.table.header.executor" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="date"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.dialog.expenses.table.header.date" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="type"
        order={order}
        orderBy={orderBy}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.dialog.expenses.table.header.type" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="accountingAccount"
        order={order}
        orderBy={orderBy}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.dialog.expenses.table.header.accounting.account" />
      </TableCellWithSorting>
      <TableCellWithSorting
          property="ot"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.dialog.expenses.table.header.ot" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="amount"
        order={order}
        orderBy={orderBy}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.dialog.expenses.table.header.amount" />
      </TableCellWithSorting>
      <StyledTableCell>
        <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.table.header.document" />
      </StyledTableCell>
    </StyledTableRow>
  </React.Fragment>
);

export default TableHeader;
