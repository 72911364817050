import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {downloadFile} from "../../../../../../services/FileService";
import Filters from "./Filters";
import Viewer from "../../Common/Viewer";

const ActivityReports = () => {
    const {file, fileType: type} = useSelector(
        ({controlPanel}) => controlPanel.kpi.current
    );
    useEffect(
        () => {
            if (file && type === 2) {
                downloadFile(
                    "report.xls",
                    `data:application/vnd.ms-excel;base64,${file}`
                );
            }
        },
        [ file ]
    );
    return (
        <React.Fragment>
            <Filters />
            {file && type === 1 && <Viewer />}
        </React.Fragment>
    );
};

export default ActivityReports;