import React, {useEffect} from "react";
import {Paper, Table, TableBody, TableContainer} from "@material-ui/core";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import {fetchAllHeadquartersByClientIdStarted} from "../../../../../redux/actions/Administrator/Clients";
import {useDispatch, useSelector} from "react-redux";
import DeleteHeadquarterConfirmation from "./DeleteHeadquarterConfirmation";
import {openDialog} from "../../../../../redux/reducers/general";
import {dialogsList} from "../../constants";

const HeadquartersTable = ({client, onOpenEditDialog}) => {
  const {headquarters} = useSelector(
    state => state.administrator.clients.current
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllHeadquartersByClientIdStarted(client.id));
  }, []);
  const onEdit = headquarter => {
    onOpenEditDialog(headquarter);
  };
  const onDelete = headquarter => {
    dispatch(openDialog({
      id: dialogsList.DELETE_HEADQUARTER_CONFIRMATION,
      headquarter,
    }));
  };
  return (
      <>
        <TableContainer
            component={Paper}
            style={{
              width: "90%",
              margin: "36px 40px"
            }}
        >
          <Table>
            <TableHeader />
            <TableBody>
              {headquarters
                  ?.filter(headquarter => headquarter.state)
                  .map(headquarter => (
                      <TableRow
                          key={headquarter.id}
                          headquarter={headquarter}
                          onEdit={onEdit}
                          onDelete={onDelete}
                      />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <DeleteHeadquarterConfirmation client={client}/>
      </>
  );
};

export default HeadquartersTable;
