import React from "react";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {connect} from "react-redux";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import EditTableRow from "../../../../../Common/Tables/CrudTable/EditTableRow";
import {
  fetchAllElevators,
  setCurrentElevator
} from "../../../../../../redux/actions/ElevatorsManagement/Elevators";
import Filters from "./Filters";
import FilterBuilder from "../../../../../../utils/filters";

const ElevatorsTable = ({dispatch, filters, data, openEdit}) => {
  const onEditElevator = elevator => {
    dispatch(setCurrentElevator(elevator));
    elevator && openEdit && openEdit();
  };
  React.useEffect(() => {
    dispatch(fetchAllElevators(filters));
  }, []);
  return (
    <React.Fragment>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {Object.values(data).map(elevator => (
          <EditTableRow
            key={elevator.id}
            onEdit={() => onEditElevator(elevator)}
          >
            <StyledTableCell>{elevator.client}</StyledTableCell>
            <StyledTableCell>{elevator.headquarter}</StyledTableCell>
            <StyledTableCell>{elevator.name}</StyledTableCell>
            <StyledTableCell>{elevator.state}</StyledTableCell>
            <StyledTableCell>{elevator.detail}</StyledTableCell>
          </EditTableRow>
        ))}
      </TableContainer>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.elevatorsManagement.elevators;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("headquarter", filters.headquarter)
    .byFieldIncludes("name", filters.name)
    .byFieldIncludes("state", filters.state)
    .byFieldIncludes("contract", filters.contract)
    .byFieldIncludes("detail", filters.detail)
    .byFieldIncludes("client", filters.client)
    .byFieldIncludes("type", filters.type)
    .byFieldIncludes("model", filters.model)
    .byFieldIncludes("manufacturer", filters.manufacturer)
    .byFieldIncludes("category", filters.category)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(ElevatorsTable);
