import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import useForm from 'hooks/Common/useForm';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: "100%",

  },
}));

const Form = ({ id, initialData, onSubmit }) => {
  const classes = useStyles();

  const { formData, setFormData, errors, handleSubmit } = useForm(
    {
      description: initialData?.description || '',
      hours: initialData?.totalWorkDay || '',
      margin: initialData?.marginWorkday || 0,
    },
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <OutlinedInput

            value={formData.description}
            hasError={errors.description.length > 0}
            error={errors.description[0]}
            fullWidth
            id="description"
            label={<IntlMessages id="administrator.rejectionTypes.saveRejectionTypeDialog.field.description" />}
            onChange={value => setFormData('description', value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
        <TextField
              id="time"
              value={formData.hours}
              label="horas"
              type="time"
              fullWidth
              variant='outlined'
              size='small'
              className={classes.textField}
              onChange={e => setFormData('hours', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />

        </Grid>
        <Grid item md={6} xs={12}>
        <TextField
              id="time"
              value={formData.margin}
              label="margen(min)"
              type="number"
              fullWidth
              variant='outlined'
              size='small'
              
              className={classes.textField}
              onChange={e => setFormData('margin', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300, // 5 min
              }}
            />
        </Grid>

      </GridContainer>
    </form>
  );
};

export default Form;