import React from "react";
import {Grid, IconButton} from "@material-ui/core";
import {CustomPagination, CustomPaginationItem} from "./styles";
import GetAppIcon from "@material-ui/icons/GetApp";
import {downloadFile} from "../../../../../../../services/FileService";

const Paginator = ({setPageNumber, numPages, pageNumber, base64File}) => {
  const onClickDownload = () => {
    downloadFile("report", `data:application/pdf;base64,${base64File}`);
  };

  return (
    <Grid
      container
      direction="row"
      style={{
        backgroundColor: "#3f51b5"
      }}
    >
      <Grid
        item
        xs={6}
        md={4}
        style={{
          color: "white",
          display: "flex",
          alignSelf: "center",
          paddingLeft: "16px"
        }}
      >
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </Grid>
      <Grid
        item
        xs={6}
        md={4}
        style={{
          color: "white",
          display: "flex",
          alignSelf: "center",
          justifyContent: "center"
        }}
      >
        <IconButton aria-label="download" onClick={() => onClickDownload()}>
          <GetAppIcon
            style={{
              color: "white"
            }}
          />
        </IconButton>
      </Grid>
      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: "flex",
          justifyContent: "right"
        }}
      >
        <CustomPagination
          count={numPages}
          variant="outlined"
          size="large"
          shape="rounded"
          onChange={(e, page) => setPageNumber(page)}
          renderItem={item => <CustomPaginationItem {...item} />}
        />
      </Grid>
    </Grid>
  );
};

export default Paginator;
