import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import ShowTableRow from "components/Common/Tables/CrudTable/ShowTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import moment from "moment";

const Table = ({data, onEdit, isLoading}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const RenderRow = ({row}) => (
    <ShowTableRow onShow={() => onEdit(row.id)}>
      <StyledTableCell>{row.type}</StyledTableCell>
      {row.entityNameOrigin !== "" ? (
        <StyledTableCell>
          {row.entityNameOrigin +
            "/" +
            row.campusNameOrigin +
            "/" +
            row.warehouseNameOrigin}
        </StyledTableCell>
      ) : (
        <StyledTableCell> - </StyledTableCell>
      )}
      <StyledTableCell>
        {row.entityNameDestiny +
          "/" +
          row.campusNameDestiny +
          "/" +
          row.warehouseNameDestiny}
      </StyledTableCell>
      <StyledTableCell>
        {moment(row.date).format("DD/MM/YY HH:mm")}
      </StyledTableCell>
    </ShowTableRow>
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      origin: PropTypes.string,
      destiny: PropTypes.string,
      date: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  onShow: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onShow: () => {}
};

export default Table;
