import React from 'react';
import {addDaysToDate, parseDateTimeToInput} from "../../../../../../utils/dates";
import moment from "moment/moment";
import PeriodSelect from "./Period";
import {Grid} from "@material-ui/core";
import OutlinedDateTimePicker from "../../../../../Common/Forms/OutlinedDateTimePicker";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const DateFilters = ({formData, setFormData}) => {
    React.useEffect(() => {
        switch (formData.period) {
            case 2: {
                setFormData('startDate', parseDateTimeToInput(addDaysToDate(new Date(), -1)));
                setFormData('endDate', parseDateTimeToInput(moment().subtract(1, 'day').endOf('day'), false));
                break;
            }
            case 3: {
                setFormData('startDate', parseDateTimeToInput(moment().subtract(1, 'weeks').startOf('isoWeek')));
                setFormData('endDate', parseDateTimeToInput(moment().subtract(1, 'weeks').endOf('isoWeek'), false));
                break;
            }
            case 4: {
                setFormData('startDate', parseDateTimeToInput(moment().subtract(1, 'month').startOf('month')));
                setFormData('endDate', parseDateTimeToInput(moment().subtract(1, 'month').endOf('month'), false));
                break;
            }
            case 5: {
                setFormData('startDate', parseDateTimeToInput(addDaysToDate(new Date(), -7)));
                setFormData('endDate', parseDateTimeToInput(new Date().toISOString(), false));
                break;
            }
            case 6: {
                setFormData('startDate', parseDateTimeToInput(addDaysToDate(new Date(), -15)));
                setFormData('endDate', parseDateTimeToInput(new Date().toISOString(), false));
                break;
            }
            case 7: {
                setFormData('startDate', parseDateTimeToInput(addDaysToDate(new Date(), -30)));
                setFormData('endDate', parseDateTimeToInput(new Date().toISOString(), false));
                break;
            }
            default: {
                setFormData('startDate', '');
                setFormData('endDate', '');
                break;
            }
        }
    }, [formData.period]);
    return (
        <>
            <Grid item xs={12} sm={6}>
                <PeriodSelect
                    value={formData.period}
                    onChange={value => setFormData('period', value)}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <OutlinedDateTimePicker
                    value={formData.startDate}
                    onChange={value => setFormData('startDate', value)}
                    fullWidth
                    label={<IntlMessages id={"control.panel.kpi.areas.grid.tachograph.filters.start.date"} />}
                    disabled={formData.period !== 1}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <OutlinedDateTimePicker
                    value={formData.endDate}
                    onChange={value => setFormData('endDate', value)}
                    fullWidth
                    label={<IntlMessages id={"control.panel.kpi.areas.grid.tachograph.filters.end.date"} />}
                    disabled={formData.period !== 1}
                />
            </Grid>
        </>
    );
};

export default DateFilters;