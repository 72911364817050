import {mapToBoolean} from "../../utils/mappers";

class Role {
  constructor(id, description, state) {
    this.id = id;
    this.description = description;
    this.state = state;
    this.permissions = [];
  }

  static map = role => {
    return new Role(
      role.nu_id_rol,
      role.vc_desc_rol,
      mapToBoolean(role.bi_estado)
    );
  };

  set setPermissions(permissions) {
    this.permissions = permissions;
  }
}

export default Role;
