import React, {useCallback} from 'react';
import {Card, CardContent, CardHeader} from "@material-ui/core";
import {ResponsiveContainer} from "recharts";
import widgets from "./widgets";
import {useTheme} from "@material-ui/core/styles";

const DynamicWidget = ({widget}) => {
    const defaultWidgetConfig = widgets[widget.id];
    const theme = useTheme();
    const renderWidget = () => {
        switch (defaultWidgetConfig.type) {
            case 1:
                return (
                    <ResponsiveContainer>
                        {defaultWidgetConfig.renderWidget(widget)}
                    </ResponsiveContainer>
                );
            case 2:
                return defaultWidgetConfig.renderWidget(widget);
            default:
                return (
                    <>
                        {defaultWidgetConfig.renderWidget(widget, theme)}
                    </>
                );
        }
    }
    const getWidth = useCallback(() => {
        let newWidth = widget.width || defaultWidgetConfig.defaultWidth;
        const mainContainer = document.getElementById('graphs-container');
        if (mainContainer?.offsetWidth < newWidth) {
            newWidth = mainContainer.offsetWidth;
        }
        return newWidth;
    }, [widget]);
    return (
        <Card
            style={{
                width: getWidth(),
                height: widget.height || defaultWidgetConfig.defaultHeight
            }}
        >
            <CardHeader
                title={defaultWidgetConfig.name}
            />
            <CardContent
                id={`widget-${widget.id}`}
                style={{
                    width: '100%',
                    height: '100%'
                }}
            >
                {renderWidget()}
            </CardContent>
        </Card>
    );
};

export default DynamicWidget;