import React from "react";
import GridContainer from "../../../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import useForm from "../../../../../../../../../hooks/Common/useForm";
import TagsPicker from "../../../../../../../../../containers/Administrator/TagsPicker";
import {useDispatch, useSelector} from "react-redux";
import validations from "./validations";
import GroupTypeSelect from "../../../Selects/GroupTypeSelect";
import ExpensesTypesPicker from "../../../Selects/ExpensesTypesPicker";
import DocumentTypesPicker from "./DocumentTypesPicker";
import {setCurrentReportFile} from "../../../../../../../../../redux/actions/ControlPanel/Kpi";
import {addDaysToDate, parseDateToInput} from "../../../../../../../../../utils/dates";
import SendEmail from "../../../../../Common/SendEmailDialog";
import SaveFilters from "../../../../../Common/SaveFilters";
import DateFilters from "../../../../../Common/DateFilters";

const initialData = {
  documentTypes: [],
  tags: [],
  types: [],
  period: 1,
  startDate: parseDateToInput(addDaysToDate(new Date(), -7)), // -7 dias
  endDate: parseDateToInput(new Date()),
  groupType: ""
};

const options = [
  {
    id: 101,
    name: "Tag"
  },
  {
    id: 102,
    name: "Tipo Documento"
  },
  {
    id: 103,
    name: "Tipo Gasto"
  }
];

const Form = () => {
  const {filters, type} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  const {formData, errors, setFormData, setValues} = useForm(
    initialData,
    validations
  );
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(setCurrentReportFile(type, formData));
  };
  React.useEffect(
    () => {
      filters && setValues(filters);
    },
    [ filters ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <DocumentTypesPicker
            values={formData.documentTypes}
            onChange={value => setFormData("documentTypes", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <TagsPicker
            id="administrator-consolidated-reports-tags-picker"
            values={formData.tags}
            onChange={value => setFormData("tags", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <ExpensesTypesPicker
            id="administrator-consolidated-reports-types-picker"
            values={formData.types}
            onChange={value => setFormData("types", value)}
          />
        </Grid>
        <DateFilters
            formData={formData}
            setFormData={setFormData}
        />
        <Grid item xs={12} sm={4} lg={3}>
          <GroupTypeSelect
            id={"administrator-consolidated-reports-group-type-select"}
            errors={errors}
            options={options}
            value={formData.groupType}
            onChange={data => setFormData("groupType", data)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button onClick={() => handleSubmit()} color="primary">
            Generate Report
          </Button>
        </Grid>
        <SendEmail filters={formData} />
        <SaveFilters filters={formData}/>
      </GridContainer>
    </form>
  );
};

export default Form;
