export const RESET_VEHICLE_DOCUMENTS_TYPES_STATE =
  "RESET_VEHICLE_DOCUMENTS_TYPES_STATE";
export const FETCH_ALL_VEHICLE_DOCUMENTS_TYPES =
  "FETCH_ALL_VEHICLE_DOCUMENTS_TYPES";
export const APPLY_VEHICLE_DOCUMENTS_TYPES_FILTERS =
  "APPLY_VEHICLE_DOCUMENTS_TYPES_FILTERS";
export const RESET_VEHICLE_DOCUMENTS_TYPES_FILTERS =
  "RESET_VEHICLE_DOCUMENTS_TYPES_FILTERS";
export const SET_CURRENT_VEHICLE_DOCUMENTS_TYPE =
  "SET_CURRENT_VEHICLE_DOCUMENTS_TYPE";
export const RESET_CURRENT_VEHICLE_DOCUMENTS_TYPE =
  "RESET_CURRENT_VEHICLE_DOCUMENTS_TYPE";
export const ADD_VEHICLE_DOCUMENTS_TYPE = "ADD_VEHICLE_DOCUMENTS_TYPE";
export const EDIT_VEHICLE_DOCUMENTS_TYPE = "EDIT_VEHICLE_DOCUMENTS_TYPE";
export const OPEN_VEHICLE_DOCUMENTS_TYPES_DIALOG =
  "OPEN_VEHICLE_DOCUMENTS_TYPES_DIALOG";
export const CLOSE_VEHICLE_DOCUMENTS_TYPES_DIALOG =
  "CLOSE_VEHICLE_DOCUMENTS_TYPES_DIALOG";
