import React from "react";
import PropTypes from "prop-types";
import {Box, Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {parseDateToGlobalFormat} from "../../../../../../utils/dates";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {openVehicleReportsDialog} from "../../../../../../redux/actions/FleetManagement/VehicleReports";
import {dialogs} from "../../constants";
import {useDispatch} from "react-redux";

const BasicInfo = ({report}) => {
  const dispatch = useDispatch();

  const onShow = () => {
    dispatch(openVehicleReportsDialog(dialogs.MORE_DIALOG, {...report}));
  };
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "5% 5%",
        marginTop: 10,
        gap: 8,
        border: "3px solid #3f51b5",
        borderRadius: "50px"
      }}
    >
      <GridContainer spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicle.reports.detail.dialog.fulfilled.date"
                }
              />
            }
            value={parseDateToGlobalFormat(report.fulfilledDate)}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicle.reports.detail.dialog.received.date"
                }
              />
            }
            value={
              report.receivedDate &&
              parseDateToGlobalFormat(report.receivedDate)
            }
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OutlinedInput
            label={
              <IntlMessages
                id={"fleet.management.vehicle.reports.detail.dialog.mileage"}
              />
            }
            value={`${report.mileage} Km.`}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OutlinedInput
            label={
              <IntlMessages
                id={"fleet.management.vehicle.reports.detail.dialog.driver"}
              />
            }
            value={report.driver}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicle.reports.detail.dialog.observations"
                }
              />
            }
            value={report.observations}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <ContainedButton
            onClick={onShow}
            color="primary"
            text={
              <IntlMessages
                id={"fleet.management.vehicle.reports.detail.dialog.more"}
              />
            }
          />
        </Grid>
      </GridContainer>
    </Box>
  );
};

BasicInfo.propTypes = {
  report: PropTypes.any.isRequired
};

export default BasicInfo;
