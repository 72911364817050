import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const MultipleSelect = ({
  id,
  value,
  options,
  onChange,
  label,
  size = "small",
  fullWidth,
    limitTags = 2,
    hasError, error,
    headerText,
  ...rest
}) => {
  const handleOnChange = (e, newValue) => {
    if(newValue.some(v => v.id === "All")) {
     onChange(options);
    }else {
      let valuesArray = [];
      newValue.forEach(element => {
        valuesArray.push(element);
      });
      onChange(valuesArray);
    }
  };

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      id={id}
      value={value}
      options={[{name: "Seleccionar todo", id: "All"}, ...options]}
      getOptionSelected={(o, v) => o.id === v.id}
      disableCloseOnSelect
      onChange={(event, newValue) => handleOnChange(event, newValue)}
      size={size}
      getOptionLabel={option => option.name || option.description}
      renderInput={params => (
        <TextField
            {...params}
            variant="outlined"
            label={label}
            error={hasError}
            helperText={error}
        />
      )}
      limitTags={limitTags}
      {...rest}
    />
  );
};

export default MultipleSelect;
