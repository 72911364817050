import React, { useEffect, useState } from 'react';
import GridContainer from '../../../../../@jumbo/components/GridContainer';
import { Grid, MenuItem } from '@material-ui/core';
import Select from '../../../../Common/Forms/Select';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import { StyledPaper } from '../../../AddAdvance/Form/styles';
import {cancelPrevFetchAllRoutesByDriver, fetchAllRoutesByDriver} from "../../../../../services/RouteService";

const RouteInput = ({ route, setRoute, isLoading, currentRoute = '', driver, advanceRoute, advanceTypeOtObligation }) => {
  const [description, setDescription] = useState('');
  const [routes, setRoutes] = useState([]);
  useEffect(() => {
    const selectedDescription = routes.filter(r => r.id === route)[0]?.average;
    setDescription(selectedDescription);
  }, [route]);

  useEffect(() => {
    if (currentRoute && routes.length > 0 && currentRoute.id) {
      const routeId = routes?.find(route => route.id === currentRoute?.id?.toString())?.id || currentRoute.id ;
      setRoute(routeId);
    }
  }, [currentRoute, routes]);

  useEffect(() => {
    if(driver) {
      cancelPrevFetchAllRoutesByDriver();
      fetchAllRoutesByDriver(driver.id).then(data => setRoutes(data));
    }
  }, [driver])
  useEffect(() => {
    if (advanceRoute !== '') {
      setRoute(advanceRoute?.toString() ?? '');
    }
  }, [advanceRoute, routes])
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4}>
            <Select
              value={route}
              onChange={e => setRoute(e.target.value)}
              id="route"
              label={<IntlMessages id={'guardian.add.advance.form.route'} />}
              fullWidth
              disabled={isLoading || currentRoute !== '' || !driver || advanceRoute !== ''}
              error={Boolean(advanceTypeOtObligation && route === '')}
              errorMessage={(advanceTypeOtObligation && route === '') && <IntlMessages id="guardian.add.advance.form.route.error.empty" />}>
              <MenuItem value="">
                <IntlMessages id={'guardian.add.advance.form.route.select.item'} />
              </MenuItem>
              {routes.map(route => (
                <MenuItem key={route.id} value={route.id}>
                  {route.description+'('+route.routeDescription+')'}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={8}>
            <StyledPaper elevation={3} outlined="true">
              <p>
                <IntlMessages id={'guardian.add.advance.form.estimate'} />:{' '}
                {description ? description : <IntlMessages id={'guardian.add.advance.form.estimate.not.found'} />}
              </p>
            </StyledPaper>
          </Grid>
        </GridContainer>
      </Grid>
    </React.Fragment>
  );
};

export default RouteInput;
