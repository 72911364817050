class SinisterVehicle {
  constructor(
    id,
    vehicleId,
    alias,
    plaque,
    insuranceCompany,
    policy,
    status,
    observations,
    ot,
    files
  ) {
    this.id = id;
    this.vehicleId = vehicleId;
    this.alias = alias;
    this.plaque = plaque;
    this.insuranceCompany = insuranceCompany;
    this.policy = policy;
    this.status = status;
    this.observations = observations;
    this.ot = ot;
    this.files = files;
  }

  static map = vehicle => {
    return new SinisterVehicle(
      vehicle.nu_id_siniestro_vehiculos,
      vehicle.nu_id_vehiculo,
      vehicle.vc_placa,
      vehicle.vc_alias || "",
      vehicle.vc_aseguradora,
      vehicle.vc_poliza,
      vehicle.nu_id_tipo_estado_siniestro_vehiculo,
      vehicle.vc_observaciones,
      vehicle.vc_orden_trabajo,
      vehicle.ls_documentos.map(d => ({
        id: d.nu_id_siniestro_vehiculo_documento,
        name: d.vc_nombre_archivo
      }))
    );
  };
}

export default SinisterVehicle;
