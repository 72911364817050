import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import {StyledTitle} from "../AddressArea/styles";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import NotesTable from "./NotesTable";

const DeliveryNotesArea = ({deliveryNotes, destinationId}) => {
    return deliveryNotes.length > 0 && (
        <GridContainer spacing={4}>
            <Grid item sm={12}>
                <StyledTitle variant="h2">
                    <IntlMessages
                        id={"logistic.work.orders.dialog.destination.delivery.notes.title"}
                    />
                </StyledTitle>
            </Grid>
            <Grid item sm={12}>
                <NotesTable
                    notes={deliveryNotes}
                    destinationId={destinationId}
                />
            </Grid>
        </GridContainer>
    );
};

export default DeliveryNotesArea;