import {useDispatch, useSelector} from "react-redux";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import TableComponent from "../../../../Common/Tables/Table";
import React from "react";
import TableHeader from "./TableHeader";
import Filters from "./Filters";
import {dialogs} from "../constants";
import {
  fetchAllVehicleReports,
  openVehicleReportsDialog,
  setCurrentVehicleReports
} from "../../../../../redux/actions/FleetManagement/VehicleReports";
import {parseDateToDateString} from "../../../../../utils/dates";
import ShowTableRow from "../../../../Common/Tables/CrudTable/ShowTableRow";

const Table = () => {
  const dispatch = useDispatch();
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleReports
  );

  const onShow = report => {
    dispatch(setCurrentVehicleReports(report));
    dispatch(openVehicleReportsDialog(dialogs.DETAIL_DIALOG));
  };

  React.useEffect(
    () => {
      dispatch(fetchAllVehicleReports(filters));
    },
    [ filters ]
  );

  const RenderRow = ({row}) => (
    <ShowTableRow onShow={() => onShow(row)}>
      <StyledTableCell>{row.vehicle}</StyledTableCell>
      <StyledTableCell>
        {parseDateToDateString(row.fulfilledDate)}
      </StyledTableCell>
      <StyledTableCell>{row.doneUser}</StyledTableCell>
      <StyledTableCell>{row.type}</StyledTableCell>
    </ShowTableRow>
  );

  return (
    <React.Fragment>
      <Filters />
      <TableComponent
        data={Object.values(data)}
        head={<TableHeader />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

export default Table;
