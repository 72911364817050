import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import {StyledTitle} from "../AddressArea/styles";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import TagsTable from "./TagsTable";

const TagsArea = ({tags}) => {
    return tags.length > 0 && (
        <GridContainer spacing={4}>
            <Grid item sm={12}>
                <StyledTitle variant="h2">
                    <IntlMessages
                        id={"logistic.work.orders.dialog.destination.tags.title"}
                    />
                </StyledTitle>
            </Grid>
            <Grid item sm={12}>
                <TagsTable
                    tags={tags}
                />
            </Grid>
        </GridContainer>
    );
};

TagsArea.propTypes = {

};

export default TagsArea;