import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useStyles} from "./styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import GeneralForm from "./GeneralForm";
import useForm from "../../../../hooks/Common/useForm";
import SpecificForm from "./SpecificForm";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Tab, Tabs} from "@material-ui/core";
import {generalValidations, specificValidations} from "./validations";

const EmployeeFormDialog = ({open, handleDialog, onSave}) => {
  const {currentContact} = useSelector(({rrhh}) => rrhh.employees);
  const [ show, setShow ] = React.useState(1);
  const {
    formData: generalFormData,
    setFormData: setGeneralFormData,
    setValues: setGeneralValues,
    errors: generalErrors
  } = useForm(
    {
      firstName: "",
      lastName: "",
      birthDate: "",
      nif: "",
      email: "",
      generalPhone: "",
      generalIButton: "",
      driverLicense: "",
      generalFiles: [],
      generalDeleteFiles: [],
      profile_pic: {file: null, link: ""}
    },
    generalValidations
  );
  const {
    formData: specificFormData,
    setFormData: setSpecificFormData,
    setValues: setSpecificValues,
    errors: specificErrors
  } = useForm(
    {
      internalCode: "",
      active: false,
      commencementDate: "",
      terminationDate: "",
      specificEmail: "",
      specificPhone: "",
      specificIButton: "",
      contract: "",
      specificFiles: [],
      specificDeleteFiles: []
    },
    specificValidations
  );
  const classes = useStyles();
  const options = {
    1: (
      <GeneralForm
        formData={generalFormData}
        setFormData={setGeneralFormData}
        handleDialog={handleDialog}
        onNext={() => {
          setShow(2);
        }}
        errors={generalErrors}
      />
    ),
    2: (
      <SpecificForm
        formData={specificFormData}
        setFormData={setSpecificFormData}
        handleDialog={handleDialog}
        onSave={() => {
          onSave(currentContact, generalFormData, specificFormData);
          handleDialog();
        }}
        errors={specificErrors}
      />
    )
  };
  React.useEffect(
    () => {
      if (currentContact) {
        setGeneralValues(currentContact);
        setSpecificValues(currentContact);
      }
    },
    [ currentContact ]
  );
  return (
    <Dialog open={open} onClose={handleDialog} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {currentContact ? (
          <IntlMessages id={"rrhh.employees.form.title.edit"} />
        ) : (
          <IntlMessages id={"rrhh.employees.form.title.create"} />
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          variant={"fullWidth"}
          centered
          className={classes.tabContainer}
          value={show}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newValue) => setShow(newValue)}
        >
          <Tab
            className={classes.tabRoot}
            label={<IntlMessages id={"rrhh.employees.form.general.title"} />}
            value={1}
          />
          <Tab
            className={classes.tabRoot}
            label={<IntlMessages id={"rrhh.employees.form.specific.title"} />}
            value={2}
          />
        </Tabs>
        {options[show]}
      </DialogContent>
    </Dialog>
  );
};

export default EmployeeFormDialog;
