import {
  FETCH_WAREHOUSE_SUCCEEDED,
  APPLY_WAREHOUSE_FILTERS,
  RESET_WAREHOUSE_FILTERS,
  WAREHOUSE_CHANGE_STATE,
  WAREHOUSE_EDIT,
  WAREHOUSE_CREATE
} from "../../types/Administrator/Warehouse";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_WAREHOUSE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_WAREHOUSE_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_WAREHOUSE_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case WAREHOUSE_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return {...type, active: !type.active};
          return type;
        })
      };
    }
    case WAREHOUSE_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case WAREHOUSE_EDIT: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return {...type, name: action.payload.name};
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};

/*import * as Warehouse from 'redux/types/Administrator/Warehouse';

//dataItem = {IdAlmacen, NombreAlmacen, IdEntidad, Entidad, IdSede, Sede, PermiteStockNegativo, FechaAlta, FechaBaja, BI_Estado}

const INIT_STATE = {
    data:[
        {id: 1, name: "Warehouse1", idEntity: 1, entity:"San Fernando", idSede: 1, Sede:"Sede1", allowNegativeStock: false, highDate: undefined, lowDate: undefined, stateBI: undefined}
    ],
    filters: {
        active: undefined,
        description: '',
    },
};

export default (state = INIT_STATE, {type, payload}) => {
    switch (type){
        case Warehouse.RESET_WAREHOUSE_DATA:
            return INIT_STATE
        case Warehouse.ADD_WAREHOUSE_DATA:
            return {...state, data: state.data.push(payload)}
        {/*case Warehouse.REMOVE_WAREHOUSE_DATA:
            return {...state, data: state.data.filter(item => !item.id === payload)}
        case Warehouse.EDIT_WAREHOUSE_DATA:
            return {...state, data: state.data.map(item => {
                if(item.id === payload.id) return {...item, name: payload.name}
                else return item
            })}
        default: return state                
    }
}*/
