import ChecklistTypeItem from "./ChecklistTypeItem";

class ChecklistTypeGroup {
  constructor(id, name, list, order, state) {
    this.id = id;
    this.name = name;
    this.list = list;
    this.state = state;
  }

  updateItem(item) {
    this.list = this.list.map(i => {
      if (i.id === item.id) {
        return item;
      }
      return i;
    });
  }

  static map = group => {
    return new ChecklistTypeGroup(
      group.nu_id_tipo_checklist_grupo,
      group.vc_desc_tipo_checklist_grupo,
      group.subgrupos.map(i => ChecklistTypeItem.map(i)),
      group.nu_id_orden_grupo,
      group.bi_estado_tipo_grupo
    );
  };
}

export default ChecklistTypeGroup;
