import moment from "moment";

class CashManagerLog {
  constructor(id, date, cashManager, tags, amount, action, file) {
    this.id = id;
    this.date = date;
    this.cashManager = cashManager;
    this.tags = tags;
    this.amount = amount;
    this.action = action;
    this.file = {name: file};
  }

  static map = cashManagerLog => {
    return new CashManagerLog(
      cashManagerLog.nu_id_caja_chica_log,
      moment(cashManagerLog.dt_fecha, "MM/DD/YYYY hh:mm:ss A").toDate(),
      cashManagerLog.vc_nombre_entidad_gestor_caja,
      cashManagerLog.vc_desc_tag.split(","),
      parseFloat(cashManagerLog.nu_cantidad),
      cashManagerLog.vc_desc_accion,
      cashManagerLog.vc_id_archivo_s3
    );
  };
}

export default CashManagerLog;
