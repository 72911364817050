class ProductSelect {
  constructor(id, product, stock, active) {
    this.id = id;
    this.product = product;
    this.stock = stock;
    this.active = active;
  }

  static map = product => {
    return new ProductSelect(
      product.nu_id_producto,
      product.vc_desc_producto,
      product.nu_stock_actual,
      product.bi_estado === "1"
    );
  };
}

export default ProductSelect;
