import {
  ADD_VEHICLE_REPORTS_TYPE,
  APPLY_VEHICLE_REPORTS_FILTERS,
  CLOSE_VEHICLE_REPORTS_DIALOG,
  EDIT_VEHICLE_REPORTS_TYPE,
  FETCH_ALL_VEHICLE_REPORTS,
  OPEN_VEHICLE_REPORTS_DIALOG,
  RESET_VEHICLE_REPORTS_FILTERS,
  RESET_VEHICLE_REPORTS_STATE,
  RESET_CURRENT_VEHICLE_REPORTS_TYPE,
  SET_CURRENT_VEHICLE_REPORTS_TYPE
} from "../../types/FleetManagement/VehicleReportsTypes";
import {addDaysToDate, parseDateToInput} from "../../../utils/dates";

const INIT_STATE = {
  data: {},
  filters: {
    startDate: parseDateToInput(addDaysToDate(new Date(), -7)),
    endDate: parseDateToInput(new Date().toISOString()),
    types: [],
    vehicles: []
  },
  current: null,
  dialog: {
    names: [],
    data: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_VEHICLE_REPORTS: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_VEHICLE_REPORTS_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_VEHICLE_REPORTS_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case SET_CURRENT_VEHICLE_REPORTS_TYPE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_VEHICLE_REPORTS_TYPE: {
      return {
        ...state,
        current: null
      };
    }
    case OPEN_VEHICLE_REPORTS_DIALOG: {
      return {
        ...state,
        dialog: {
          names: [ ...state.dialog.names, action.payload.name ],
          data: action.payload.data || null
        }
      };
    }
    case CLOSE_VEHICLE_REPORTS_DIALOG: {
      return {
        ...state,
        dialog: {
          names: state.dialog.names.filter(n => n !== action.payload),
          data: null
        }
      };
    }
    case RESET_VEHICLE_REPORTS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
