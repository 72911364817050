import React from "react";
import PageContainer from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ContractTypes/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {
  editElevatorContractState,
  resetCurrentElevatorContract,
  resetElevatorsContractsState
} from "../../../../../../redux/actions/ElevatorsManagement/Contracts";
import ContractsTable from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ContractTypes/ContractsTable";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddContractDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ContractTypes/AddContractDialog";
import EditContractDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ContractTypes/EditContractDialog";

const ContractTypes = () => {
  const {current} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.contracts
  );
  const dispatch = useDispatch();
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetElevatorsContractsState());
    };
  }, []);
  return (
    <PageContainer>
      <ContractsTable
        setEdit={setOpenEdit}
        setDelete={setOpenDelete}
        setRestore={setOpenRestore}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentElevatorContract());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editElevatorContractState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentElevatorContract());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editElevatorContractState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <AddContractDialog open={openAdd} setOpen={setOpenAdd} />
      <EditContractDialog open={openEdit} setOpen={setOpenEdit} />
    </PageContainer>
  );
};

export default ContractTypes;
