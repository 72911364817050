class IncidenceType {
  constructor(id, description, active) {
    this.id = id;
    this.description = description;
    this.active = active;
  }

  static map = incidenceType => {
    return new IncidenceType(
      incidenceType.nu_id_tipo_incidencia,
      incidenceType.vc_desc_tipo_incidencia,
      incidenceType.bi_estado === "1"
    );
  };
}

export default IncidenceType;
