import React from "react";
import {useDispatch} from "react-redux";
import {addAccident, setCurrentAccident} from "../../../../../redux/actions/FleetManagement/Accidents";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const initData = {
  date: "",
  communicationDate: "",
  address: "",
  type: "",
  status: "",
  driver: "",
  version: "",
  opposite: "",
  observations: "",
  images: [],
  vehicles: [],
};

const AddAccidentDialog = ({open, setOpen}) => {
  const id = "add-accident-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addAccident(data, onClose));
  };
  const onClose = () => {
    setOpen(false);
    dispatch(setCurrentAccident(null));
  }
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.accidents.add.title"} />}
      open={open}
      onClose={onClose}
      submitText={<IntlMessages id={"fleet.management.accidents.add.save"} />}
      cancelText={<IntlMessages id={"fleet.management.accidents.add.cancel"} />}
    >
      <Form id={id} onSubmit={onSubmit} initialData={initData} />
    </FormDialog>
  );
};
export default AddAccidentDialog;
