import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = supervisors => [
  {
    label: <IntlMessages id={"sidebar.masterData"} />,
    link: "/treasury/cash-management/cash-managers"
  },
  {
    label: (
      <IntlMessages
        id={supervisors ? "pages.supervisors" : "pages.guardians"}
      />
    ),
    isActive: true
  }
];

const PageContainer = ({children, supervisors}) => (
  <PageContainerComponent
    heading={
      <IntlMessages
        id={
          supervisors ? (
            "administrator.supervisors.title"
          ) : (
            "administrator.guardians.title"
          )
        }
      />
    }
    description={
      <IntlMessages
        id={
          supervisors ? (
            "administrator.supervisors.description"
          ) : (
            "administrator.guardians.description"
          )
        }
      />
    }
    breadcrumbs={breadcrumbs(supervisors)}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  supervisors: PropTypes.bool
};

PageContainer.defaultProps = {
  children: null,
  supervisors: true
};

export default PageContainer;
