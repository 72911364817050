import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="client"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logistic.order.history.table.distributor" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logistic.order.history.table.date" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="state"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logistic.order.history.table.state" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="ot"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logistic.order.history.table.ot" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="observation"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logistic.order.history.table.observation" />
    </TableCellWithSorting>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
