import axiosInstance from "../../../utils/axiosInstance";
import Response from "../../../domain/Response";

export const fetchAllOrders = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/sel_proveedor',
            {}
        );
        return data.map(order => ({
            id: order.nu_id_pedido,
            providerName: order.vc_desc_proveedor,
            orderDate: order.dt_fecha,
            carryingOutDate: order.dt_fecha_ejecucion,
            stateName: order.vc_desc_estado,
            typeName: order.vc_tipo
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editOrderItemDetails = async ({orderId, itemId, request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/upd_estado_detalle',
            {
                nu_id: itemId,
                nu_id_pedido: orderId,
                nu_id_estado: request.stateId,
                deliveredQuantity: request.deliveredQuantity,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}