import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {createTax, fetchAllTaxes, updateTax, updateTaxState} from "../../thunks/Treasury/taxes";

const INITIAL_STATE = {
    taxes: {},
    dialogs: [],
    currentTax: null,
}

const taxesSlice = createSlice({
    name: 'taxes',
    initialState: cloneDeep(INITIAL_STATE),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentTax: (state, action) => {
            state.currentTax = action.payload;
        },
        resetTaxes: () => {
            return cloneDeep(INITIAL_STATE);
        }
    },
    extraReducers: (builder) => {
      builder.addCase(fetchAllTaxes.fulfilled, (state, action) => {
          state.taxes = action.payload.taxes.reduce((acc, tax) => ({...acc, [tax.id]: tax}), {});
      });
      builder.addCase(createTax.fulfilled, (state, action) => {
          state.taxes[action.payload.id] = action.payload;
      });
      builder.addCase(updateTax.fulfilled, (state, action) => {
          const editedTax = action.payload;
          state.taxes[editedTax.id] = {
              ...state.taxes[editedTax.id],
              ...editedTax,
           };
      });
      builder.addCase(updateTaxState.fulfilled, (state, action) => {
          const taxId = action.payload;
          state.taxes[taxId] = {
              ...state.taxes[taxId],
              state: !state.taxes[taxId].state,
          };
      });
    },
});

export default taxesSlice.reducer;

export const {
    openDialog,
    closeDialog,
    resetTaxes,
    setCurrentTax
} = taxesSlice.actions;