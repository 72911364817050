import React, { useState } from "react";

import GridContainer from '@jumbo/components/GridContainer';
import Grid from "@material-ui/core/Grid";
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import FileInput from "components/Common/Forms/FileInput";

import useForm from "hooks/Common/useForm";
import IntlMessages from "@jumbo/utils/IntlMessages";

import validations from './validations';
import WarehouseOriginSelect from 'containers/Administrator/WarehouseOriginSelect';
import EntityDestinySelect from 'containers/Administrator/EntityDestinySelect';
import EntityOriginSelect from "containers/Administrator/EntityOriginSelect";
import WarehouseDestinySelect from 'containers/Administrator/WarehouseDestinySelect';
import CampusDestinySelect from 'containers/Administrator/CampusDestinySelect';
import CampusOriginSelect from 'containers/Administrator/CampusOriginSelect';
import ProductSelect from 'containers/Administrator/ProductSelectRegulation';

import { Box, Button } from "@material-ui/core";
import Table from './Table';
import useUiState from "hooks/Administrator/useUiState";
import { useDispatch, useSelector } from 'react-redux';

import {getCompanyEntityId} from '../../../../../services/StorageService';

const Form = ({id,initialData, onSubmit, isEdit}) => {
    
    const [product, setProduct] = useState(null);
    // const [focusAmount, setFocusAmount] = useState(true);
    const {data} = useSelector(state => state.administrator.warehouseEntity);
    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            entityOrigin: initialData ? ({value: initialData.idEntityOrigin, label: initialData.entityNameOrigin}) : ({value: getCompanyEntityId(), label:data.find(option => option.id === getCompanyEntityId())}),
            //campusOrigin: initialData?.idCampusOrigin || null,
            campusOrigin: initialData ? ({value: initialData.idCampusOrigin, label:initialData.campusNameOrigin}) : null,
            //warehouseOrigin: initialData?.idWarehouseOrigin || null,
            warehouseOrigin: initialData ? ({value: initialData.idWarehouseOrigin, label:initialData.warehouseNameOrigin}) : null, 
            products: initialData?.transfer || [],
            //amount: initialData?.amount || '',
            //añadir arreglo
        },
        validations,
    );

    const ui = useUiState();

    const handleOnCLick = () => {
        // setFocusAmount(true);
        let copy = formData.products.length>0 ? [...formData.products] : [];
        const val = copy.some(e => Number(e.value) === Number(product.value));
        if (product.value && product !== null && product.value!== "" && !val){
            copy.push({...product, amount: 0, id: copy.length+1});
            setFormData('products', copy);
            setProduct({});
        }
        
    }

    const handleAmount = (row, amount, stock) => {
        if (Number(amount) < 0){
            amount = 0;
            let copy = [...formData.products];
            const pos = copy.findIndex(item => item.id===row.id);
            copy[pos].amount = Number(amount);
            setFormData('products', copy);
        }else{
            let copy = [...formData.products];
            const pos = copy.findIndex(item => item.id===row.id);
            copy[pos].amount = Number(amount);
            setFormData('products', copy);
        }
    }

    if(isEdit){
        return(
            <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <GridContainer spacing={4}>
                    <Grid item xs={4}>
                        <OutlinedInput
                            id="entityOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityOrigin"/>}
                            value={formData.entityOrigin.label}
                            disabled
                            fullWidth
                        />
                    </Grid>    
                    <Grid item xs={4}>
                        <OutlinedInput
                            id="campus-origin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusOrigin"/>}
                            value={formData.campusOrigin.label}
                            disabled
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <OutlinedInput
                            id="warehouse-origin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseOrigin"/>}
                            value={formData.warehouseOrigin.label}
                            disabled
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Box marginBottom={25}>
                            <Table data={formData.products} handleAmount={handleAmount} setData={value => setFormData('products', value)} errors={errors} {...ui.tableProps} isEdit={isEdit}/>
                        </Box>                    
                    </Grid>
                </GridContainer>                    
            </form>
        );
    }else{
        return(
            <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <GridContainer spacing={4}>
                    <Grid item xs={4}>
                        <EntityOriginSelect
                            id="entityOrigin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityOrigin"/>}
                            value={formData.entityOrigin}
                        />
                    </Grid>    
                    <Grid item xs={4}>
                        <CampusOriginSelect
                            id="campus-origin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusOrigin"/>}
                            value={formData.campusOrigin}
                            hasError={errors.campusOrigin.length > 0}
                            error={errors.campusOrigin[0]}
                            onChange={value => setFormData('campusOrigin',value)}
                            entityId={getCompanyEntityId()}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <WarehouseOriginSelect
                            id="warehouse-origin"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseOrigin"/>}
                            value={formData.warehouseOrigin}
                            hasError={errors.warehouseOrigin.length > 0}
                            error={errors.warehouseOrigin[0]}
                            onChange={value => setFormData('warehouseOrigin',value)}
                            entityId={getCompanyEntityId()}
                            campusId={formData.campusOrigin}
                        />
                    </Grid>
                    
                    <Grid item xs={6}>
                        <ProductSelect
                            id="product"
                            label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.product"/>}
                            value={product}
                            onChange={value => { //setFocusAmount(false);
                                setProduct(value)}}
                            warehouseId={formData.warehouseOrigin}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleOnCLick()}>Agregar</Button>
                    </Grid>
                        
                    <Grid item xs={12}>
                        <Box marginBottom={25}>
                            <Table data={formData.products} handleAmount={handleAmount}
                                   setData={value => setFormData('products', value)}
                                   // focusAmount={focusAmount}
                                   errors={errors} {...ui.tableProps} isEdit={isEdit}/>
                        </Box>                    
                    </Grid>
                </GridContainer>
            </form>
        );
    }
};

export default Form