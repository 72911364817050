import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "./Form";

const CreateCashManagerDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-cash-manager-form"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.cashManagers.createFormTitle" />}
    onClose={onClose}
    disableEnforceFocus
  >
    <Form id="create-cash-manager-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateCashManagerDialog;
