import React from "react";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import PhoneInput from "./PhoneInput";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ContractSelect from "./ContractSelect";
import {useSelector} from "react-redux";
import FilesArea from "./FilesArea";
import Checkbox from "../../../Common/Forms/Checkbox";

const SpecificForm = ({
  formData,
  setFormData,
  handleDialog,
  onSave,
  errors
}) => {
  const {currentContact} = useSelector(({rrhh}) => rrhh.employees);
  return (
    <Box mt={4}>
      <form>
        <Box>
          <GridContainer>
            <Grid item xs={12} sm={6}>
              <OutlinedInput
                fullWidth
                id={"employee-internal-code"}
                label={
                  <IntlMessages id={"rrhh.employees.form.internal.code"} />
                }
                value={formData.internalCode}
                onChange={value => setFormData("internalCode", value)}
                hasError={errors.internalCode.length > 0}
                error={errors.internalCode[0]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                checked={formData.active}
                onChange={value => setFormData("active", value)}
                label={<IntlMessages id={"rrhh.employees.form.active"} />}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedDatePicker
                fullWidth
                id={"employee-commencement-date"}
                label={
                  <IntlMessages id={"rrhh.employees.form.commencement.date"} />
                }
                value={formData.commencementDate}
                onChange={value => setFormData("commencementDate", value)}
                hasError={errors.internalCode.length > 0}
                error={errors.internalCode[0]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <OutlinedDatePicker
                fullWidth
                id={"employee-termination-date"}
                label={
                  <IntlMessages id={"rrhh.employees.form.termination.date"} />
                }
                value={formData.terminationDate}
                onChange={value => setFormData("terminationDate", value)}
                hasError={errors.internalCode.length > 0}
                error={errors.internalCode[0]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <OutlinedInput
                fullWidth
                id={"employee-specific-email"}
                label={<IntlMessages id={"rrhh.employees.form.email"} />}
                value={formData.specificEmail}
                onChange={value => setFormData("specificEmail", value)}
                hasError={errors.internalCode.length > 0}
                error={errors.internalCode[0]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <OutlinedInput
                fullWidth
                id={"employee-specific-phone"}
                label={<IntlMessages id={"rrhh.employees.form.phone"} />}
                value={formData.specificPhone}
                onChange={number => setFormData("specificPhone", number)}
                hasError={errors.specificPhone.length > 0}
                error={errors.specificPhone[0]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <OutlinedInput
                fullWidth
                id={"employee-specific-i-button"}
                label={<IntlMessages id={"rrhh.employees.form.i.button"} />}
                value={formData.specificIButton}
                onChange={value => setFormData("specificIButton", value)}
                hasError={errors.internalCode.length > 0}
                error={errors.internalCode[0]}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <ContractSelect
                defContract={currentContact.contract}
                contract={formData.contract}
                onChange={value => setFormData("contract", value)}
                // hasError={errors.internalCode.length > 0}
                // error={errors.internalCode[0]}
              />
            </Grid>
          </GridContainer>
        </Box>
        <FilesArea
          id={"specific-files"}
          files={formData.specificFiles}
          setFiles={files => setFormData("specificFiles", files)}
          setDeleteFiles={files =>
            setFormData("specificDeleteFiles", [
              ...formData.specificDeleteFiles,
              ...files
            ])}
        />
        <Box display="flex" justifyContent="flex-end" mb={4}>
          <Button onClick={handleDialog}>
            <IntlMessages id={"rrhh.employees.form.cancel"} />
          </Button>
          <Box ml={2}>
            <Button variant="contained" color="primary" onClick={onSave}>
              <IntlMessages id={"rrhh.employees.form.save"} />
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default SpecificForm;
