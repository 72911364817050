import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "@jumbo/utils/IntlMessages";

const Boolean = ({value}) =>
  value ? (
    <IntlMessages id="common.text.boolean.true" />
  ) : (
    <IntlMessages id="common.text.boolean.false" />
  );

Boolean.propTypes = {
  value: PropTypes.bool.isRequired
};

export default Boolean;
