import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.plaque"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.fuel.type"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.station"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.driver"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.gallons"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.price"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.refueling.table.amount"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
