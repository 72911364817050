import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import VehicleSpecification from "../../../domain/FleetManagement/Vehicles/VehicleSpecification";
import Response from "../../../domain/Response";
import VehicleSheet from "../../../domain/FleetManagement/Vehicles/VehicleSheet";

export const fetchAllVehiclesSpecifications = async () => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo/sel_ficha_tecnica", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(v => VehicleSpecification.map(v));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getVehicleSheetById = async vehicle => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo/get_ficha_tecnica", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_vehiculo: vehicle.id
    });
    return VehicleSheet.map({id: vehicle.id, ...data});
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateBasicSheet = async vehicle => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo/upd_ficha_tecnica_db", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_vehiculo: vehicle.id,
      vc_alias: vehicle.alias,
      vc_bastidor: vehicle.frame,
      nu_id_marca: vehicle.brand,
      nu_id_marca_modelo: vehicle.model,
      nu_id_marca_modelo_version: vehicle.version,
      nu_id_tipo_vehiculo: vehicle.type,
      dt_fec_matriculacion: vehicle.plaqueDate,
      nu_kilometraje: vehicle.mileage,
      ls_tags: vehicle.tags.map(t => ({nu_id_tag: t.id, bi_eliminar: t.remove}))
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateTechnicalSheet = async vehicle => {
  try {
    const {
      data
    } = await axiosInstance.post("/Vehiculo/upd_ficha_tecnica_detalle", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_vehiculo: vehicle.id,
      vc_tipo_motor: vehicle.engine,
      vc_carroceria: vehicle.bodywork,
      nu_potencia: vehicle.power,
      nu_cilindrada: vehicle.cylinder,
      nu_combustible: vehicle.fuel,
      nu_cargavolumen: vehicle.volume,
      nu_ejes: vehicle.axles,
      nu_pasajeros: vehicle.passengers,
      vc_color: vehicle.color,
      nu_num_puertas: vehicle.door,
      nu_velocidades: vehicle.speed,
      nu_num_asientos: vehicle.seat,
      vc_codigoradio: vehicle.radius,
      vc_codigollave: vehicle.key,
      vc_codigomando: vehicle.control,
      vc_extras: vehicle.extras,
      vc_observaciones: vehicle.observations,
      bi_estado: vehicle.state
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateVehicleImage = async (vehicle, file) => {
  const {data} = await axiosInstance.post("/Vehiculo/upd_image_vehiculo", {
    nu_id_vehiculo: vehicle.id,
    archivo: {
      archivobase64: file.base64File,
      contentType: file.contentType,
      extension: file.extension,
      nombre: file.name
    }
  });
  const response = Response.map(data);
  if (response.transactionState !== "0") {
    return response;
  }
  throw Error(response.transactionMessage);
};
