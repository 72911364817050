import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHead = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.destination"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.work.order"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.state"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.vehicle"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.driver"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.dispatches.table.reject.reason"} />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHead;
