import React from "react";
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const SubStateSelect = ({selected, onChange}) => {
  const [ subStates, setSubStates ] = React.useState([
    {id: 1, name: "SubEstado 1"},
    {id: 2, name: "SubEstado 2"},
    {id: 3, name: "SubEstado 3"}
  ]);
  return (
    <Select
      id="alerts-sub-state-select"
      label={<IntlMessages id="logistic.monitoring.alerts.filters.sub.state" />}
      value={selected}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value="">
        <IntlMessages id="logistic.monitoring.alerts.filters.sub.state.all" />
      </MenuItem>
      {subStates.map(state => (
        <MenuItem value={state.id} key={`sub-state-${state.id}`}>
          {state.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SubStateSelect;
