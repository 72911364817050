import React from "react";
import PropTypes from "prop-types";

import EditIcon from "@material-ui/icons/Edit";

import StyledTableCell from "../../StyledTableCell";
import StyledTableRow from "../../StyledTableRow";
import {IconButton} from "@material-ui/core";

const EditTableRow = ({children, onEdit}) => (
  <StyledTableRow>
    {children}
    <StyledTableCell align="right">
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
);

EditTableRow.propTypes = {
  children: PropTypes.node,
  onEdit: PropTypes.func
};

EditTableRow.defaultProps = {
  children: null,
  onEdit: () => {}
};

export default EditTableRow;
