import {mapToBoolean} from "../../utils/mappers";

class ProviderSelect {
  constructor(id, name, status) {
    this.id = id;
    this.name = name;
    this.status = status;
  }

  static map = provider => {
    return new ProviderSelect(
      provider.nu_id_entidad,
      provider.vc_nombre,
      mapToBoolean(provider.bi_estado)
    );
  };
}

export default ProviderSelect;
