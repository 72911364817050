import {
  ADD_TIRE,
  APPLY_TIRES_FILTERS,
  EDIT_TIRE,
  FETCH_ALL_TIRES,
  RESET_TIRES_FILTERS,
  RESET_TIRES_STATE,
  SET_CURRENT_TIRE
} from "../../types/FleetManagement/TiresTypes";
import {fetchStart, fetchSuccess} from "../Common";
import {parseDateFromInput} from "../../../utils/dates";

const data = [
  {
    id: 1,
    identification: "AAAA",
    active: true,
    purchaseDate: new Date().toISOString(),
    brand: "Marca 1",
    measures: "AD",
    retreading: true,
    pressure: 12,
    vehicle: ""
  },
  {
    id: 2,
    identification: "BBB",
    active: true,
    purchaseDate: new Date().toISOString(),
    brand: "Marca 2",
    measures: "AB",
    retreading: false,
    pressure: 12,
    vehicle: ""
  }
];

export const resetTiresState = () => ({type: RESET_TIRES_STATE});
export const applyTiresFilters = filters => ({
  type: APPLY_TIRES_FILTERS,
  payload: filters
});
export const resetTiresFilters = () => ({type: RESET_TIRES_FILTERS});
export const setCurrentTire = tire => ({type: SET_CURRENT_TIRE, payload: tire});
export const fetchALlTires = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: FETCH_ALL_TIRES, payload: data});
    }, 1500);
  };
};
export const addTire = (tire, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const newTire = {
        ...tire,
        identification: tire.tire || "",
        purchaseDate: tire.purchaseDate
          ? parseDateFromInput(tire.purchaseDate)
          : "",
        saleDate: tire.saleDate ? parseDateFromInput(tire.saleDate) : "",
        purchaseValue: Number(tire.purchaseValue) || 0,
        saleValue: Number(tire.saleValue) || 0,
        retreading: tire.retreading === "1",
        original: tire.original === "1",
        active: tire.active === "1"
      };
      dispatch({type: ADD_TIRE, payload: newTire});
      onClose && onClose();
    }, 1500);
  };
};

export const editTire = (tire, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const editTire = {
        ...tire,
        identification: tire.tire || "",
        purchaseDate: tire.purchaseDate
          ? parseDateFromInput(tire.purchaseDate)
          : "",
        saleDate: tire.saleDate ? parseDateFromInput(tire.saleDate) : "",
        purchaseValue: Number(tire.purchaseValue) || 0,
        saleValue: Number(tire.saleValue) || 0,
        retreading: tire.retreading === "1",
        original: tire.original === "1",
        active: tire.active === "1"
      };
      dispatch({type: EDIT_TIRE, payload: editTire});
      onClose && onClose();
    }, 1500);
  };
};
