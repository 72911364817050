import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"pages.warehouseTransfer"} />,
    link: "/logistic/warehouse-transfer"
  },
  {label: <IntlMessages id={"pages.merchandiseReception"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={
      <IntlMessages id={"sidebar.logistic.merchandiseReception.title"} />
    }
    description={
      <IntlMessages id={"sidebar.logistic.merchandiseReception.description"} />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
