import {
  cancelPrevFetchAllHistory,
  fetchAll
} from "services/CashManagersHistoryService";
import {restoreAdministratorState} from "./Common";
import ui from "./ui";

export const fetchLogHistory = filters => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevFetchAllHistory();
  try {
    const data = await fetchAll(filters);
    dispatch(ui.fetchSucceeded());
    dispatch({
      type: "CASH_MANAGERS_LOG_HISTORY_FETCH_SUCCEEDED",
      payload: {data}
    });
  } catch ({cancelled}) {
    !cancelled && dispatch(ui.fetchFailed());
  }
};

export const applyFilters = filters => {
  const amount = parseFloat(filters.amount);
  return {
    type: "CASH_MANAGERS_LOG_HISTORY_APPLY_FILTERS",
    payload: {filters: {...filters, amount: isNaN(amount) ? "" : amount}}
  };
};

export const resetFilters = () => ({
  type: "CASH_MANAGERS_LOG_HISTORY_RESET_FILTERS"
});

export const resetState = () => dispatch => {
  cancelPrevFetchAllHistory();
  dispatch(ui.fetchCancelled);
  dispatch(restoreAdministratorState());
};

export default {
  get: fetchLogHistory,
  applyFilters,
  resetFilters,
  resetState
};
