import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {fetchAllHistoryData} from "../../../services/BackOffice/Licenses/HistoryDataService";

export const getLicensesHistoryData = createAsyncThunk(
    'historyData/getLicensesHistoryData',
    async(filters, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const data = await fetchAllHistoryData(filters);
            dispatch(fetchSuccess());
            return {filters, data};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }

)