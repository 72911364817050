export const FETCH_PAUSESTYPES_SUCCEEDED = "FETCH_PAUSESTYPES_SUCCEEDED";
export const PAUSESTYPES_CREATE = "PAUSESTYPES_CREATE";
export const PAUSESTYPES_EDIT = "PAUSESTYPES_EDIT";
export const PAUSESTYPES_DELETE = "PAUSESTYPES_DELETE";
export const PAUSESTYPES_RESTORE = "PAUSESTYPES_RESTORE";
export const PAUSESTYPES_OPEN_DELETE_CONFIRMATION =
  "PAUSESTYPES_OPEN_DELETE_CONFIRMATION";
export const APPLY_PAUSESTYPES_FILTERS = "APPLY_PAUSESTYPES_FILTERS";
export const RESET_PAUSESTYPES_FILTERS = "RESET_PAUSESTYPES_FILTERS";
export const PAUSESTYPES_CHANGE_STATE = "PAUSESTYPES_CHANGE_STATE";
