import React from "react";
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {ContainedButton} from "../styles";
import {useDispatch, useSelector} from "react-redux";
import {deleteDestinationStarted} from "../../../../../../redux/actions/Administrator/WorkOrders";
import UpdateClientDialog from "./UpdateClientDialog";

const DestinationHeader = ({
  index,
  edit,
  setEdit,
  header = false,
  reset,
  destinationId,
  onSubmit,
  cancelAdd,
    onDeleted,
  noEdit,
}) => {
  const dispatch = useDispatch();
  const {current} = useSelector(state => state.administrator.workOrders);
  const onDelete = () => {
    dispatch(deleteDestinationStarted({destinationId, workOrder: current}));
    onDeleted && onDeleted();
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      style={{backgroundColor: "#3f51b5"}}
      margin={4}
    >
      <Box marginLeft={2} paddingY={2}>
        {header && (
          <h2 style={{color: "#ffffff"}}>
            <IntlMessages id="logistic.work.orders.dialog.destination.title" />{" "}
            {index + 1}
          </h2>
        )}
      </Box>
      {!noEdit && (
        <Box
          marginX={2}
          marginY={2}
          display="flex"
          justifyContent="space-between"
        >
          {edit ? (
            <React.Fragment>
              <ContainedButton
                variant="contained"
                onClick={() => {
                  if (destinationId === 0) {
                    cancelAdd();
                  } else {
                    reset();
                    setEdit(false);
                  }
                }}
              >
                <IntlMessages id="logistic.work.orders.dialog.destination.cancel" />
              </ContainedButton>
              <ContainedButton
                variant="contained"
                onClick={e => {
                  onSubmit(e);
                  setEdit(false);
                }}
              >
                <IntlMessages id="logistic.work.orders.dialog.destination.save" />
              </ContainedButton>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <UpdateClientDialog
                  destinationId={destinationId}
              />
              {/*<ContainedButton variant="contained"><IntlMessages*/}
              {/*    id="logistic.work.orders.dialog.destination.shelve"/></ContainedButton>*/}
              <ContainedButton
                  onClick={() => onDelete()}
                  variant="contained">
                <IntlMessages id="logistic.work.orders.dialog.destination.delete"/>
              </ContainedButton>
              <ContainedButton
                variant="contained"
                onClick={() => setEdit(true)}
              >
                <IntlMessages id="logistic.work.orders.dialog.destination.edit" />
              </ContainedButton>
            </React.Fragment>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DestinationHeader;
