import React from "react";
import {useStyles} from "./styles";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from "@material-ui/core";
import moment from "moment";
import DeleteIcon from "@material-ui/icons/Delete";
import {Pagination} from "@material-ui/lab";
import {useDispatch} from "react-redux";
import {setCurrentReport} from "../../../../../../redux/actions/ControlPanel/Kpi";

const ReportsList = ({title, reports, pagination = false, onDelete = null}) => {
  const classes = useStyles();
  const [ page, setPage ] = React.useState(0);
  const counter = Math.floor(reports.length / 10) + 1;
  const dispatch = useDispatch();
  const onDeleteReport = report => {
    onDelete && onDelete(report);
  };

  const onSelectReport = report => {
    dispatch(setCurrentReport(report.type, report.filters, { reportId: report.id, title: report.name }));
  };

  return (
    <Box display="flex" flexDirection="column">
      <List
        className={classes.reportsList}
        subheader={
          <ListSubheader className={classes.title}>{title}</ListSubheader>
        }
      >
        {reports.slice(page * 10, page * 10 + 10).map((report, index) => (
          <ListItem
            onClick={() => onSelectReport(report)}
            button
            key={`report-${report.id}-${report.type}-${index}`}
          >
            <ListItemText
              primary={report.name}
              secondary={report.date && moment(report.date).format("DD/MM/YYYY HH:mm")}
            />
            {onDelete && (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDeleteReport(report)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
      {pagination &&
      reports.length > 0 && (
        <Pagination
          className={classes.pagination}
          count={counter}
          page={page + 1}
          onChange={(e, selectedPage) => {
            setPage(selectedPage - 1);
          }}
          variant="outlined"
          color="primary"
        />
      )}
    </Box>
  );
};

export default ReportsList;
