export const FETCH_ALL_MONITORING_WORK_ORDERS_STARTED =
  "FETCH_ALL_MONITORING_WORK_ORDERS_STARTED";
export const FETCH_ALL_MONITORING_WORK_ORDERS_SUCCEED =
  "FETCH_ALL_MONITORING_WORK_ORDERS_SUCCEED";
export const RESTORE_MONITORING_STATE = "RESTORE_MONITORING_STATE";
export const APPLY_MONITORING_FILTERS = "APPLY_MONITORING_FILTERS";
export const RESET_MONITORING_FILTERS = "RESET_MONITORING_FILTERS";
export const FETCH_ALL_CHATS_STARTED = "FETCH_ALL_CHATS_STARTED";
export const FETCH_ALL_CHATS_SUCCEED = "FETCH_ALL_CHATS_SUCCEED";
export const FETCH_ALL_CHAT_USERS_STARTED = "FETCH_ALL_CHAT_USERS_STARTED";
export const FETCH_ALL_CHAT_USERS_SUCCEED = "FETCH_ALL_CHAT_USERS_SUCCEED";
export const LOAD_SELECTED_CHAT_STARTED = "LOAD_SELECTED_CHAT_STARTED";
export const LOAD_SELECTED_CHAT_SUCCEED = "LOAD_SELECTED_CHAT_SUCCEED";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const RESET_SELECTED_USER = "RESET_SELECTED_USER";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_CURRENT_MONITORING_WORK_ORDER =
  "SET_CURRENT_MONITORING_WORK_ORDER";
export const RESET_CURRENT_WORK_ORDER = "RESET_CURRENT_WORK_ORDER";
export const FETCH_ALL_ALERTS_STARTED = "FETCH_ALL_ALERTS_STARTED";
export const FETCH_ALL_ALERTS_SUCCEED = " FETCH_ALL_ALERTS_SUCCEED";
export const APPLY_ALERTS_FILTERS = "APPLY_ALERTS_FILTERS";
export const RESET_ALERTS_FILTERS = "RESET_ALERTS_FILTERS";
export const APPLY_DISPATCHES_FILTERS = "APPLY_DISPATCHES_FILTERS";
export const RESET_DISPATCHES_FILTERS = "RESET_DISPATCHES_FILTERS";
export const FETCH_ALL_DISPATCHES_WORK_ORDERS_STARTED =
  "FETCH_ALL_DISPATCHES_WORK_ORDERS_STARTED";
export const FETCH_ALL_DISPATCHES_WORK_ORDERS_SUCCEED =
  "FETCH_ALL_DISPATCHES_WORK_ORDERS_SUCCEED";
export const FETCH_ALL_DISPATCHES_DESTINATIONS_STARTED =
  "FETCH_ALL_DISPATCHES_DESTINATIONS_STARTED";
export const FETCH_ALL_DISPATCHES_DESTINATIONS_SUCCEED =
  "FETCH_ALL_DISPATCHES_DESTINATIONS_SUCCEED";
export const UPDATE_ALERT_STATE = "UPDATE_ALERT_STATE";
export const RESET_RESULT = "RESET_RESULT";
