import React from "react";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import OrdersDialog from "./OrdersDialog";
import Checkbox from "../../../Common/Forms/Checkbox";
import OrdersTable from "./OrdersDialog/OrdersTable";
import ImportDialog from "./ImportDialog";

const Orders = ({setShow, selectedOrders, setSelectedOrders, onCreate}) => {
  const [ ordersDialog, setOrdersDialog ] = React.useState(false);
  const [ importDialog, setImportDialog ] = React.useState(false);
  const [ openMenu, setOpenMenu ] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [ options, setOptions ] = React.useState([
    {
      id: 1,
      text: <IntlMessages id={"logistic.assigment.orders.balanced"} />,
      checked: false
    },
    {
      id: 2,
      text: <IntlMessages id={"logistic.assigment.orders.minimize.routes"} />,
      checked: false
    },
    {
      id: 3,
      text: <IntlMessages id={"logistic.assigment.orders.open.routes"} />,
      checked: false
    }
  ]);

  const handleMenuItemClick = (event, id) => {
    setOptions([
      ...options.map(option => {
        if (id === option.id) {
          return {...option, checked: !option.checked};
        }
        return {...option};
      })
    ]);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const onClickCreate = () => {
    onCreate(options.filter(d => d.checked));
  };

  return (
    <React.Fragment>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">
            <IntlMessages id="logistic.assigment.orders.title" />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: "8px"}}>
          <Divider />
        </Grid>
        {/*<Grid item xs={12} md={6}>*/}
        {/*    <Button*/}
        {/*        onClick={() => setOrdersDialog(true)}*/}
        {/*        fullWidth*/}
        {/*        variant="contained"*/}
        {/*        color="primary"*/}
        {/*    >*/}
        {/*        <IntlMessages id={"logistic.assigment.orders.search.orders"}/>*/}
        {/*    </Button>*/}
        {/*</Grid>*/}
        <Grid item xs={12} md={6}>
          <Button
            onClick={() => setImportDialog(true)}
            fullWidth
            variant="contained"
            color="primary"
          >
            <IntlMessages id={"logistic.assigment.orders.import"} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          {selectedOrders.length > 0 && (
            <OrdersTable
              ordersData={selectedOrders}
              setOrdersData={setSelectedOrders}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <Button onClick={() => setShow(1)}>
            <IntlMessages id={"logistic.assigment.previous"} />
          </Button>
        </Grid>
        <Grid item container xs={12} sm={9} lg={9} justifyContent="flex-end">
          <ButtonGroup
            variant={"contained"}
            color="primary"
            ref={anchorRef}
            aria-label="split button"
          >
            <Button onClick={() => onClickCreate()}>
              <IntlMessages id={"logistic.assigment.orders.create.routes"} />
            </Button>
            <Button size="small" onClick={() => setOpenMenu(!openMenu)}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper style={{marginRight: "42px"}}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu">
                      {options.map(option => (
                        <MenuItem
                          style={{paddingLeft: "30px"}}
                          key={option.id}
                          onClick={event =>
                            handleMenuItemClick(event, option.id)}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={option.checked}
                                onChange={e =>
                                  handleMenuItemClick(e, option.id)}
                              />
                            }
                            label={option.text}
                          />
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </GridContainer>
      <OrdersDialog
        open={ordersDialog}
        setOpen={setOrdersDialog}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
      />
      <ImportDialog
        open={importDialog}
        setOpen={setImportDialog}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
      />
    </React.Fragment>
  );
};
export default Orders;
