import React from "react";
import {connect} from "react-redux";
import BaseTable from "../../Common/BaseTable";
import {
  applyElevatorsContractsFilters,
  fetchAllElevatorsContracts,
  resetElevatorsContractsFilters,
  setCurrentElevatorContract
} from "../../../../../../redux/actions/ElevatorsManagement/Contracts";
import FilterBuilder from "../../../../../../utils/filters";

const ContractsTable = ({
  setEdit,
  setDelete,
  setRestore,
  data,
  filters,
  dispatch
}) => {
  React.useEffect(() => {
    dispatch(fetchAllElevatorsContracts(filters));
  }, []);
  const onEdit = current => {
    dispatch(setCurrentElevatorContract(current));
    setEdit && setEdit(true);
  };
  const onDelete = current => {
    dispatch(setCurrentElevatorContract(current));
    setEdit && setDelete(true);
  };
  const onRestore = current => {
    dispatch(setCurrentElevatorContract(current));
    setEdit && setRestore(true);
  };
  const onApplyFilters = filters => {
    dispatch(applyElevatorsContractsFilters(filters));
  };
  const onResetFilters = () => {
    dispatch(resetElevatorsContractsFilters());
  };
  return (
    <BaseTable
      data={Object.values(data)}
      initData={filters}
      onRestore={onRestore}
      onEdit={onEdit}
      onDelete={onDelete}
      onApplyFilters={onApplyFilters}
      onResetFilters={onResetFilters}
    />
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.elevatorsManagement.contracts;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("id", filters.code)
    .byFieldIncludes("name", filters.name)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(ContractsTable);
