import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import EditPermissionsForm from "../../Roles/EditRoleDialog/EditPermissionsForm";
import {
    setCurrentConfigOrganizationModule,
    updateConfigurationOrganizationModule
} from "../../../../../redux/actions/Configuration/OrganizationsModules";

const EditOrganizationModulesDialog = ({openEdit, setOpenEdit}) => {
    const id = 'edit-organization-modules-form';
    const {current} = useSelector(({configuration}) => configuration.organizationsModules);
    const dispatch = useDispatch();
    const onEditOrganization = data => {
        let operations = [];
        const initialPermissions = parseInitData(current.permissions);
        Object.values(initialPermissions).forEach(permission => {
            permission.state !== data[permission.id].state && operations.push(data[permission.id]);
        });
        dispatch(updateConfigurationOrganizationModule(current, operations));
        setOpenEdit(false);
    }
    const parseInitData = modules => {
        let permissions = {};
        Object.values(modules)
            .map(module => module.submodules).flat()
            .map(submodules => submodules.operations).flat()
            .map(o => {
                permissions[o.id] = o;
                return null;
            });
        return permissions;
    }
    return (
        <FormDialog
            id={id}
            isOpen={openEdit}
            onClose={() => {
                setOpenEdit(false);
                dispatch(setCurrentConfigOrganizationModule(null));
            }}
            title={<IntlMessages id={"configuration.user.settings.organization.modules.edit.title"}/>}
            submitText={<IntlMessages id={"label.form.save"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
        >
            {current?.permissions &&
                <EditPermissionsForm
                    id={id}
                    initialData={parseInitData(current.permissions)}
                    modules={current.permissions}
                    onEditRole={onEditOrganization}
                />
            }
        </FormDialog>
    );
}

export default EditOrganizationModulesDialog;