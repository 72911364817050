import {Typography, withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

export const ResponsiveBox = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    display: "flex",
    flex: "auto",
    width: "91%",
    [theme.breakpoints.between("xs", "md")]: {
      alignItems: "center",
      flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
      alignContent: "center",
      flexDirection: "row"
    }
  }
}))(Box);

export const TextTypography = withStyles(theme => ({
  root: {
    [theme.breakpoints.between("xs", "md")]: {
      margin: "0"
    },
    [theme.breakpoints.up("md")]: {
      margin: "2px auto"
    }
  }
}))(Typography);
