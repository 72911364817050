import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const PlanningPlaceSelect = ({value, onChange, error, hasError}) => {
    const id = 'planning-place-filter';
    const places = [
        {
            id: 1,
            description: 'BB'
        },
        {
            id: 2,
            description: 'HI'
        },
        {
            id: 3,
            description: 'BB y HI'
        },
        {
            id: 4,
            description: 'AABB'
        },
        {
            id: 5,
            description: 'Suministro'
        }
    ]
    return (
        <Select
            id={id}
            label={<IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.planning.place" />}
            value={value}
            onChange={e => onChange(e.target.value)}
            error={hasError}
            errorMessage={error}
            fullWidth
        >
            {places.map(o => (
                <MenuItem key={o.id} value={o.id}>
                    {o.description}
                </MenuItem>
            ))}
        </Select>
    );
}

PlanningPlaceSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any
};

export default PlanningPlaceSelect;