import React from "react";
import TableContainer from "../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import useTableSort from "../../../../hooks/Common/useTableSort";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import {useSelector} from "react-redux";
import TableRow from "./TableRow";

const TagTypesTable = () => {
  const {data} = useSelector(state => state.administrator.tags);
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  return (
    <React.Fragment>
      <TableContainer
        head={<TableHeader onSort={onSort} order={order} orderBy={orderBy} />}
      >
        {tableData.map(type => (
          <StyledTableRow key={type.id}>
            <TableRow type={type} />
          </StyledTableRow>
        ))}
      </TableContainer>
    </React.Fragment>
  );
};

export default TagTypesTable;
