import React from 'react';
import Filters from "./Filters";
import DashboardContainer from "./DashboardContainer";

const Viewer = () => {

    return (
        <>
            <Filters />
            <DashboardContainer />
        </>
    );
};

export default Viewer;