import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.cash.management"} />,
    link: "/treasury/cash-management/operative"
  },
  {label: <IntlMessages id={"sidebar.unresolved.advances"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"treasury.unresolved.advances.title"} />}
    description={<IntlMessages id="treasury.unresolved.advances.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
