import React from "react";
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {getAllFuelTypes} from "../../../../../../../../services/FleetManagement/Vehicles/FuelTypeService";

const FuelSelect = ({value, onChange, error, hasError, disabled}) => {
  const {data: fuels} = useFetch(getAllFuelTypes, {}, []);
  return (
    <Select
      id="vehicle-fuel-select"
      label={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.details.technical.fuel"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      error={hasError}
      errorMessage={error}
      fullWidth
      disabled={disabled}
    >
      {fuels.map(fuel => (
        <MenuItem value={fuel.id} key={fuel.id}>
          {fuel.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default FuelSelect;
