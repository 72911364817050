import React from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  circularProgressRoot: {
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 999999,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "50vh",
    backgroundColor: "rgba(0,0,0,0.34)",
    overflow: "hidden",
  }
}));

const PageLoader = () => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.circularProgressRoot, 'page-loader')}>
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default PageLoader;
