import React from "react";
import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = ({onFilter, onClose, showLoadingIndicator}) => (
  <FiltersContainer
    marginBottom={5}
    onClose={onClose}
    showLoadingIndicator={showLoadingIndicator}
  >
    <Form onFilter={onFilter} />
  </FiltersContainer>
);

export default Filters;
