import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "components/Common/Tables/CrudTable/RestoreTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader/TableHeader";
import TableValues from "./TableValues/";
import TableRow from "./TableRow";

const Table = ({data, onEdit, onDelete, onRestore, isLoading}) => {
  // const { data } = useSelector(state => state.rrhh.historicalSigning);
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  // const RenderRow = ({ row }) =>
  //     row.active ? (
  //       <CrudTableRow onEdit={() => onEdit(row.id)} onlyEdit={true}  onDelete={() => onDelete(row.id)}>
  //         <TableValues row={row} />
  //       </CrudTableRow>
  //     ) : (
  //       <RestoreTableRow onRestore={() => onRestore(row.id)}>
  //         <TableValues row={row} />
  //       </RestoreTableRow>
  //     );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={({row}) => <TableRow rowData={row} />}
      getRowKey={row => row.uniqueId}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      description: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {}
};

export default Table;
