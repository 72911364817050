import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  vehicle: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.workshop.form.field.error" />
    }
  ],
  entryDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.workshop.form.field.error" />
    }
  ],

  kilometer: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.workshop.form.field.error" />
    }
  ],
  ot: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.workshop.form.field.error" />
    }
  ],
  expectedExitDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.workshop.form.field.error" />
    }
  ],

  workshop: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.workshop.form.field.error" />
    }
  ]
};
