import {
  APPROVE_ADVANCE_STARTED,
  APPROVE_ADVANCE_RESULT,
  APPROVE_EXPENSE_STARTED,
  APPROVE_EXPENSE_RESULT,
  REJECT_ADVANCE_STARTED,
  REJECT_ADVANCE_RESULT,
  REJECT_EXPENSE_STARTED,
  REJECT_EXPENSE_RESULT,
  RESET_UNRESOLVED_ADVANCE_STATE
} from "../../types/Supervisor/CustomTypes";

const INIT_STATE = {
  expense: null,
  advance: null,
  isLoading: false,
  response: {
    message: "",
    status: null
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPROVE_ADVANCE_STARTED: {
      return {
        ...state,
        advance: action.payload.advance,
        isLoading: true
      };
    }
    case APPROVE_ADVANCE_RESULT: {
      return {
        ...state,
        advance: {...INIT_STATE.advance},
        isLoading: false,
        response: {...action.payload}
      };
    }
    case REJECT_ADVANCE_STARTED: {
      return {
        ...state,
        advance: action.payload.advance,
        isLoading: true
      };
    }
    case REJECT_ADVANCE_RESULT: {
      return {
        ...state,
        advance: {...INIT_STATE.advance},
        isLoading: false,
        response: {...action.payload}
      };
    }
    case APPROVE_EXPENSE_STARTED: {
      return {
        ...state,
        expense: action.payload.expense,
        isLoading: true
      };
    }
    case APPROVE_EXPENSE_RESULT: {
      return {
        ...state,
        expense: {...INIT_STATE.expense},
        isLoading: false,
        response: {...action.payload}
      };
    }
    case REJECT_EXPENSE_STARTED: {
      return {
        ...state,
        expense: action.payload.expense,
        isLoading: true
      };
    }
    case REJECT_EXPENSE_RESULT: {
      return {
        ...state,
        expense: {...INIT_STATE.expense},
        isLoading: false,
        response: {...action.payload}
      };
    }

    case RESET_UNRESOLVED_ADVANCE_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }

    default: {
      return state;
    }
  }
};
