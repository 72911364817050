import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Role from "../../domain/Configuration/Role";
import Response from "../../domain/Response";
import Module from "../../domain/Configuration/Module";

export const fetchAllConfigRoles = async () => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_roles", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(u => Role.map(u));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigRoleModules = async (role, operation) => {
  try {
    const {data} = await axiosInstance.post("/Usuario/upd_permiso_estado", {
      nu_id_rol: role.id,
      nu_id_operacion: operation.id,
      bi_estado: operation.state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchAllPermissionsByRole = async role => {
  try {
    const {data} = await axiosInstance.post("Usuario/sel_permisos", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_rol: role.id
    });
    return data.map(m => Module.map(m));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addConfigRole = async role => {
  try {
    const {data} = await axiosInstance.post("/Usuario/ins_rol", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_rol: role.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigRoleState = async (rol, state) => {
  try {
    const {data} = await axiosInstance.post("/Usuario/upd_rol_estado", {
      nu_id_rol: rol.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigRoleData = async rol => {
  try {
    const {data} = await axiosInstance.post("/Usuario/upd_rol", {
      nu_id_rol: rol.id,
      vc_desc_rol: rol.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
