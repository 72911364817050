import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getGlobalDataMasterOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 57) && options.push({
        name: <IntlMessages id={'sidebar.clients.list'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/control-panel/general/clients',
    });
    submodules.some(s => s.id === 58) && options.push({
        name: <IntlMessages id={'sidebar.tags'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/control-panel/general/tags',
    });
    return options;
}

const getGlobalOptions = submodules => {

    let options = [];
    const dataMaster =  getGlobalDataMasterOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.masterData'}/>,
        type: 'section',
        children: dataMaster,
    });
    return options;
}

const getKpiDashboardOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 59) && options.push({
        name: <IntlMessages id={'sidebar.dashboard.frame'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/control-panel/kpi/dashboard/viewer',
    });
    submodules.some(s => s.id === 60) && options.push({
        name: <IntlMessages id={'sidebar.dashboard.section.reports'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/control-panel/kpi/reports',
    });
    const gpsReport = submodules.find(s => s.id === 117);
    gpsReport && options.push({
        name: gpsReport.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/control-panel/kpi/gps',
    });
    return options;
}

const getKpiOptions = submodules => {
    let options = [];
    const dashboardOptions =  getKpiDashboardOptions(submodules);
    dashboardOptions?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.dashboard.section'}/>,
        type: 'section',
        children: dashboardOptions,
    });
    return options;
}

export const getGeneralOptions = () => {
    const permissions = getUserPermissions();
    let general = {
        name: <IntlMessages id={'sidebar.general.data'}/>,
        type: 'section',
        children: [],
    };
    let submodules = permissions.find(p => p.id===11)?.submodules;
    if(submodules?.length > 0) {
        const globalOptions = getGlobalOptions(submodules);
        globalOptions.length > 0 && general.children.push({
            name: <IntlMessages id={'sidebar.globals'}/>,
            type: 'collapse',
            children: globalOptions,
        });
    }
    submodules = permissions.find(p => p.id===12)?.submodules;
    if(submodules?.length > 0) {
        const kpiOptions = getKpiOptions(submodules);
        kpiOptions.length > 0 && general.children.push({
            name: <IntlMessages id={'sidebar.kpi'}/>,
            type: 'collapse',
            children: kpiOptions,
        });
    }

    return general.children.length > 0? general : null;
}