import React from "react";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import SummaryDialog from "./SummaryDialog";
import AssignmentIcon from "@material-ui/icons/Assignment";
import useSearch from "../../../../hooks/Common/useSearch";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import FinishDialog from "./FinishDialog";
import WorkOrdersList from "./WorkOrdersList";
import {
  resetAssigmentWorkOrders,
  setOptimizationId
} from "../../../../redux/actions/Logistic/Assigment";
import CircularLoader from "../../../Common/ProgressBar/CircularLoader";

const WorkOrders = ({setShow, workOrders, setWorkOrders, onFinish}) => {
  const dispatch = useDispatch();
  const [ summary, setSummary ] = React.useState(false);
  const [ confirmation, setConfirmation ] = React.useState(false);
  const {filteredData, onSearch, search} = useSearch(workOrders);
  const {optimizationId} = useSelector(state => state.logistic.assigment);
  React.useEffect(
    () => {
      if (optimizationId !== null) {
        setTimeout(() => {
          dispatch(setOptimizationId(null));
        }, 10000);
      } else {
        onFinish();
      }
    },
    [ optimizationId ]
  );
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" mt={32}>
      <CircularLoader />
      <Typography variant="h3">
        <IntlMessages id={"logistic.assigment.work.orders.generate"} />
      </Typography>
    </Box>
    // <React.Fragment>
    //     <GridContainer spacing={4}>
    //         <Grid item xs={12} md={8}>
    //             <Typography variant="h2">
    //                 <IntlMessages id="logistic.assigment.work.orders.title"/>
    //             </Typography>
    //         </Grid>
    //         <Grid item xs={12} md={4}>
    //             <Button
    //                 fullWidth
    //                 variant="contained"
    //                 color="primary"
    //                 onClick={() => setSummary(true)}
    //                 startIcon={<AssignmentIcon />}
    //             >
    //                 <IntlMessages id={"logistic.assigment.work.orders.summary"}/>
    //             </Button>
    //         </Grid>
    //         <Grid item xs={12}>
    //             <OutlinedInput
    //                 id="search-assigment-work-order"
    //                 fullWidth
    //                 value={search}
    //                 onChange={value => onSearch(value)}
    //                 label={<IntlMessages id={"logistic.assigment.work.orders.search"}/>}
    //             />
    //         </Grid>
    //         <Grid item xs={12}>
    //             <WorkOrdersList
    //                 workOrders={filteredData}
    //                 setWorkOrders={setWorkOrders}
    //             />
    //         </Grid>
    //         <Grid item xs={12} sm={3} lg={3}>
    //             <Button onClick={() => {
    //                 setShow(2);
    //                 dispatch(resetAssigmentWorkOrders());
    //             }}>
    //                 <IntlMessages id={"logistic.assigment.previous"}/>
    //             </Button>
    //         </Grid>
    //         <Grid item container xs={12} sm={9} lg={9} justifyContent="flex-end">
    //             <Button
    //                 variant="contained"
    //                 color="primary"
    //                 onClick={() => setConfirmation(true)}
    //             >
    //                 <IntlMessages id={"logistic.assigment.work.orders.finish"}/>
    //             </Button>
    //         </Grid>
    //     </GridContainer>
    //     <SummaryDialog
    //         workOrders={workOrders}
    //         setOpen={setSummary}
    //         open={summary}
    //     />
    //     <FinishDialog
    //         onFinish={onFinish}
    //         setShow={setShow}
    //         workOrders={workOrders}
    //         setOpen={setConfirmation}
    //         open={confirmation}
    //     />
    // </React.Fragment>
  );
};

export default WorkOrders;
