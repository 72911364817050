import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import Response from "../../domain/Response";
import TypesPauses from "../../domain/RRHH/PausesTypes";

import {getCompanyEntityId} from "../StorageService";

let fetchAllCancelToken = undefined;

export const fetchAllPausesTypes = async () => {
  try {
    const response = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje_Tipo_Estados_Subtipos/sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    response.data.forEach(element => {
      element.bi_activo === "False"
        ? (element.bi_activo = false)
        : (element.bi_activo = true);
    });
    return response.data.map(pausesTypes => TypesPauses.map(pausesTypes));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createNewPausesTypes = async pausesTypes => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje_Tipo_Estados_Subtipos/ins",
      {
        nu_id_entidad: getCompanyEntityId(),
        vc_id_tipo_estado_subtipo: pausesTypes.description
      }
    );
    return Response.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateNewPausesTypes = async (id, pausesTypes) => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje_Tipo_Estados_Subtipos/upd",
      {
        nu_id_tipo_estado_subtipo: id,
        vc_id_tipo_estado_subtipo: pausesTypes.description,
        bi_activo: null
      }
    );
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deletePausesTypesApi = async pausesTypes => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje_Tipo_Estados_Subtipos/del",
      {
        nu_id_tipo_estado_subtipo: pausesTypes
      }
    );
    return Response.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const restorePausesTypesApi = async pausesTypes => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje_Tipo_Estados_Subtipos/upd",
      {
        nu_id_tipo_estado_subtipo: pausesTypes,
        vc_id_tipo_estado_subtipo: "",
        bi_activo: true
      }
    );
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
