import React from "react";
import PropTypes from "prop-types";

import DialogBase from "components/Common/Dialogs/DialogBase";
import Button from "@material-ui/core/Button";
import IntlMessages from "@jumbo/utils/IntlMessages";

const FormShowDialog = ({
  id,
  isOpen,
  title,
  onClose,
  children,
  closeText,
  disabled,
  ...rest
}) => (
  <DialogBase
    actions={
      <React.Fragment>
        <Button color="primary" onClick={onClose}>
          {closeText}
        </Button>
      </React.Fragment>
    }
    open={isOpen}
    title={title}
    isLoading={false}
    {...rest}
  >
    {children}
  </DialogBase>
);

FormShowDialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node,
  closeText: PropTypes.node,
  disabled: PropTypes.bool
};

FormShowDialog.defaultProps = {
  isOpen: false,
  title: "",
  onClose: () => {},
  children: null,
  closeText: <IntlMessages id="common.dialogs.formDialog.close" />,
  disabled: false
};

export default FormShowDialog;
