import React from "react";

import PageContainer from "../../../../../components/FleetManagement/Vehicles/Insurance/PageContainer";
import InsurancesTable from "../../../../../components/FleetManagement/Vehicles/Insurance/InsurancesTable";
import {useDispatch, useSelector} from "react-redux";
import {resetInsurancesState} from "../../../../../redux/actions/FleetManagement/Insurances";
import AddInsuranceDialog from "../../../../../components/FleetManagement/Vehicles/Insurance/AddInsuranceDialog";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import EditInsuranceDialog from "../../../../../components/FleetManagement/Vehicles/Insurance/EditInsuranceDialog";

const Insurance = () => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.insurances
  );
  const dispatch = useDispatch();
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetInsurancesState());
    };
  }, []);
  return (
    <PageContainer supervisors={false}>
      <InsurancesTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAddDialog(true)} />
      {openAddDialog && (
        <AddInsuranceDialog open={openAddDialog} setOpen={setOpenAddDialog} />
      )}
      {openEditDialog &&
      current && (
        <EditInsuranceDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
        />
      )}
    </PageContainer>
  );
};

export default Insurance;
