import React from 'react';
import useForm from "../../../hooks/Common/useForm";
import BillSummary from "../common/BillForm/BillSummary";
import CreditNoteDetails from "./CreditNoteDetails";
import ServicesTable from "./ServicesTable";

const INIT_DATA = {
    client: '',
    creditNoteNumber: '',
    creationDate: '',
    discount: 0,
    services: [],
    creditNoteType: '',
    observations: '',
}

const EditForm = ({id, initialData = {}, onSubmit, disableEdit = false}) => {
    const {formData, setFormData, setValues, handleSubmit} = useForm(INIT_DATA);
    React.useEffect(() => {
        if(Object.keys(initialData).length >  0) {
            setValues(initialData);
        }
    }, []);
    return (
        <form
            id={id}
            onSubmit={handleSubmit(onSubmit)}
        >
            <CreditNoteDetails formData={formData} setFormData={setFormData} disabled={disableEdit}/>
            <ServicesTable
                services={formData.services}
                setServices={newServices => setFormData('services', newServices)}
                disableEdit={disableEdit}
            />
            <BillSummary
                services={formData.services}
                hideDiscount
                discount={0}
            />
        </form>
    );
};

export default EditForm;