import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  plaque: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ],
  date: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ],
  time: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ],
  driver: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ],
  fileNumber: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ],
  notificationDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ],
  amount: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="fleet.management.infractions.add.field.error" />
      )
    }
  ]
};
