import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import {Box, Grid} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useStyles} from "../styles";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import {useDispatch} from "react-redux";
import {editChecklistType} from "../../../../../redux/actions/Logistic/ChecklistTypes";
import MarkOptionsSelect from "../../Common/MarkOptionsSelect";
import {checklistBasicValidations} from "../../Common/validations";

const ChecklistNameArea = ({type}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const id = 'checklist-type-name-area-form';
  const {formData, setFormData, resetForm, errors, handleSubmit} = useForm({
    name: type.name,
      markOption: type.markOption,
      checklistBasicValidations,
  });
  const [ edit, setEdit ] = React.useState(false);
  const onSave = (data) => {
    dispatch(editChecklistType({...type, ...data}, setEdit));
  };
  const onCancel = () => {
    setEdit(false);
    resetForm();
  };
  return (
      <form id={id} onSubmit={handleSubmit(onSave)}>
          <GridContainer spacing={2}>
              <Grid item sm={12} md={4}>
                  <OutlinedInput
                      id={"name-input"}
                      value={formData.name}
                      onChange={value => setFormData("name", value)}
                      fullWidth
                      disabled={!edit}
                      required
                      label={
                          <IntlMessages
                              id={"logistic.distribution.checklist.types.table.form.name"}
                          />
                      }
                      error={errors?.name[0]}
                      hasError={errors?.name?.length > 0}
                  />
              </Grid>
              <Grid item xs={12} md={4}>
                  <MarkOptionsSelect
                      value={formData.markOption}
                      onChange={value => setFormData("markOption", value)}
                      disabled={!edit}
                      required
                      errorMessage={errors?.markOption[0]}
                      error={errors?.markOption?.length > 0}
                  />
              </Grid>
              <Grid item sm={12} md={4}>
                  <Box className={classes.buttonBox}>
                      {edit ? (
                          <React.Fragment>
                              <ContainedButton
                                  text={<IntlMessages id={"form.button.cancel"} />}
                                  color={"primary"}
                                  onClick={onCancel}
                              />
                              <ContainedButton
                                  text={<IntlMessages id={"form.button.save"} />}
                                  color={"primary"}
                                  type='submit'
                                  form={id}
                              />
                          </React.Fragment>
                      ) : (
                          <ContainedButton
                              text={<IntlMessages id={"form.button.edit"} />}
                              color={"primary"}
                              onClick={() => setEdit(true)}
                          />
                      )}
                  </Box>
              </Grid>
          </GridContainer>
      </form>
  );
};

export default ChecklistNameArea;
