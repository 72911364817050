import React from 'react';
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import PlaqueSelect from "../../../Common/Form/Selects/PlaqueSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import CompanySelect from "../../../Common/Form/Selects/CompanySelect";
import TypeSelect from "./TypeSelect";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import BaseField from "../../../../../Guardian/Common/Fields/BaseField";
import useForm from "../../../../../../hooks/Common/useForm";
import validations from "./validations";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={12} md={6}>
                    <PlaqueSelect
                        id={"add-insurance-plaque"}
                        value={formData.plaque}
                        setValue={value => setFormData('plaque', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.plaque"}/>}
                        hasError={errors?.plaque?.length > 0}
                        error={errors?.plaque[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-insurance-termination-date"}
                        fullWidth
                        value={formData.terminationDate}
                        onChange={value => setFormData('terminationDate', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.termination.date"}/>}
                        hasError={errors?.terminationDate?.length > 0}
                        error={errors?.terminationDate[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CompanySelect
                        id={"add-insurance-company"}
                        value={formData.company}
                        setValue={value => setFormData('company', value.toString())}
                        label={<IntlMessages id={"fleet.management.insurance.add.company"}/>}
                        hasError={errors?.company?.length > 0}
                        error={errors?.company[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TypeSelect
                        id={"add-insurance-type"}
                        value={formData.type}
                        setValue={value => setFormData('type', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.type"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-insurance-commencement-date"}
                        fullWidth
                        value={formData.commencementDate}
                        onChange={value => setFormData('commencementDate', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.commencement.date"}/>}
                        hasError={errors?.commencementDate?.length > 0}
                        error={errors?.commencementDate[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-insurance-expiration-date"}
                        fullWidth
                        value={formData.expirationDate}
                        onChange={value => setFormData('expirationDate', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.expiration.date"}/>}
                        hasError={errors?.expirationDate?.length > 0}
                        error={errors?.expirationDate[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-policy"}
                        fullWidth
                        value={formData.policy}
                        onChange={value => setFormData('policy', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.policy"}/>}
                        hasError={errors?.policy?.length > 0}
                        error={errors?.policy[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"add-infraction-premium"}
                        fullWidth
                        type="number"
                        value={formData.premium}
                        onChange={value => setFormData('premium', value)}
                        label={<IntlMessages id={"fleet.management.insurance.add.premium"}/>}
                        hasError={errors?.premium?.length > 0}
                        error={errors?.premium[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BaseField>
                        <p><IntlMessages id={"fleet.management.insurance.add.phone"}/>{': '}{formData.phone}</p>
                    </BaseField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <BaseField>
                        <p><IntlMessages id={"fleet.management.insurance.add.contact"}/>{': '}{formData.contact}</p>
                    </BaseField>
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;