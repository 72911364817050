import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import AddWidgetSelect from "./AddWidgetSelect";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import {Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useHistory, useRouteMatch} from "react-router";
import Viewer from "./Viewer";
import {useDispatch} from "react-redux";
import {updateDashboardDetailsAndWidgets} from "../../../../../redux/thunks/ControlPanel/dashboards";

const Form = ({initialData}) => {
    const id = 'edit-dashboard';
    const {formData, setFormData, handleSubmit} = useForm(initialData);
    const {url} = useRouteMatch();
    const dispatch = useDispatch();
    const history = useHistory()
    const onSubmit = (data) => {
        dispatch(updateDashboardDetailsAndWidgets(data))
            .unwrap()
            .then(() => history.replace(url.replace('editor', 'viewer')));
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={3}>
                    <OutlinedInput
                        label={<IntlMessages id='name' />}
                        value={formData.description}
                        onChange={value => setFormData('description', value)}
                        // hasError={errors?.name?.length > 0}
                        // error={errors?.name?.[0]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <AddWidgetSelect
                        formWidgets={formData.widgets}
                        setFormWidgets={(widgets) => setFormData('widgets', widgets)}
                    />
                </Grid>
                <Grid item xs={6} md={2} >
                    <Link
                        to={url.replace('editor', 'viewer')}
                        replace
                    >
                        <Button
                            variant='outlined'
                            style={{
                                height: '100%',
                            }}
                        >
                            <IntlMessages id='cancel' />
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={6} md={2} >
                    <ContainedButton
                        form={id}
                        text={<IntlMessages id='save' />}
                        type='submit'
                        color='primary'
                        style={{
                            height: '100%',
                        }}
                    />
                </Grid>
            </GridContainer>
            <Viewer
                formWidgets={formData.widgets}
                setFormWidgets={widgets => setFormData('widgets', widgets)}
            />
        </form>
    );
};

export default Form;