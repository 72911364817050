import React from 'react';
import {useSelector} from "react-redux";
import TimeView from "./TimeView";
import LoadView from "./LoadView";

const InverseLogistic = () => {
    const {filters} = useSelector(({controlPanel}) => controlPanel.kpi.current)
    if(!filters?.type) {
        return null;
    }
    return (
        <>
            {filters?.type === 1
                ? <TimeView />
                : <LoadView />
            }
        </>
    );
};

export default InverseLogistic;