class Operation {
  constructor(id, description, state) {
    this.id = id;
    this.description = description;
    this.state = state;
  }

  static map = operation => {
    return new Operation(
      Number(operation.nu_id_operacion),
      operation.vc_desc_operacion,
      operation.bi_estado
    );
  };
}

export default Operation;
