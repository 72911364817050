import React, {useState} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";

const ItemsTable = ({items, id, onSubmit}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const intl = useIntl();

    const columns = [
        {
            field: "id",
            hide: true,
            sortable: false,
        },
        {
            field: "name",
            headerName: intl.messages["name"],
            width: 200,
            sortable: false,
        },
        {
            field: "quantity",
            headerName: intl.messages["quantity"],
            width: 200,
            sortable: false,
        },
        {
            field: "deliveredQuantity",
            headerName: intl.messages["delivered"],
            width: 200,
            sortable: false,
        },
    ];
    const handleSubmit = () => {
        onSubmit(selectedItems);
    }
    return (
        <form id={id} onSubmit={handleSubmit}>
            <DataGrid
                style={{
                    height: '30vh',
                    width: '100%',
                }}
                checkboxSelection
                disableColumnMenu
                disableColumnFilter
                rows={items}
                columns={columns}
                selectionModel={selectedItems}
                onSelectionModelChange={(newSelection) => setSelectedItems(newSelection)}
            />
        </form>
    );
};

export default ItemsTable;