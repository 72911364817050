import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider,
    Grid,
    Link,
    Tooltip,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useStyles} from "./styles";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {minutesToTime} from "../../../../../../utils/parsers";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import ProgressBar from "./ProgressBar";
import RestIcon from "../../../../Common/Icons/Tachograph/RestIcon";
import DrivingIcon from "../../../../Common/Icons/Tachograph/DrivingIcon";
import InterruptionIcon from "../../../../Common/Icons/Tachograph/InterruptionIcon";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import PersonIcon from "@material-ui/icons/Person";
import BreakBox from "./BreakBox";
import ActivityGraph from "./ActivityGraph";
import {
    fetchDriverActivitiesFile,
    fetchDriverExcel
} from "../../../../../../redux/actions/FleetManagement/TachographTraffic";
import {useDispatch} from "react-redux";
import {ACTIVITY_STATES} from "./constants";
import {parseDateToDateString} from "../../../../../../utils/dates";
import "./styles.css";

const DriverItem = ({driver}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const fetchExcel = () => {
        dispatch(fetchDriverExcel(driver));
    }
    const fetchActivitiesExcel = () => {
        dispatch(fetchDriverActivitiesFile(driver));
    }
    if (!driver.activityGraph) {
        return (
            <Accordion
                disabled
                className={classes.disabledDriveContainer}
            >
                <AccordionSummary
                    className={classes.disabledDriverContent}
                >
                    <Box display="flex" justifyContent="space-between" px={2} width="100%">
                        <Box width="80%" className={classes.iconBox}>
                            <PersonIcon fontSize="small"/>
                            <Typography className={classes.heading}>
                                {driver.name}
                            </Typography>
                        </Box>
                        <Box width="20%">
                            <Tooltip
                                style={{pointerEvents: "auto"}}
                                title={<IntlMessages id="last.activity.date"/>}
                            >
                                <Typography className={classes.heading}>
                                    {driver.currentActivityDate}
                                </Typography>
                            </Tooltip>
                        </Box>
                    </Box>
                </AccordionSummary>
            </Accordion>
        );
    }
    return (
        <Accordion
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                className={driver.isInactive && classes.nonActiveContainer}
            >
                <Box display="flex" justifyContent="space-between" px={2} width="100%">
                    <Box
                        width="80%"
                        display="flex"
                    >
                        <Box display="flex" mr="1rem"
                             className={classes.iconBox}
                        >
                            <PersonIcon fontSize="small"/>
                            <Typography className={classes.heading}>
                                {driver.name} - <Tooltip
                                title={<p><IntlMessages id="from"/> {driver.vehicleRecord}</p>}><span>{driver.plaque}</span></Tooltip>
                            </Typography>
                        </Box>
                        <Box
                            className="separate-box"
                        >
                            <Tooltip
                                title={<IntlMessages id={"fleet.management.tachograph.traffic.drivers.list.item.ddr.description"}/>}
                            >
                                <Box className={classes.iconBox}>
                                    <RestIcon className={classes.tachographIcon}/>
                                    <Typography>
                                        <IntlMessages
                                            id={"fleet.management.tachograph.traffic.drivers.list.item.ddr"}
                                        />: {driver.breakDays === null || driver.breakDays === undefined || driver.breakDays < 0 ? '-' : driver.breakDays}
                                    </Typography>
                                </Box>
                            </Tooltip>
                            <Tooltip
                                title={<IntlMessages id={"fleet.management.tachograph.traffic.drivers.list.item.dce.description"}/>}
                            >
                                <Box className={classes.iconBox}>
                                    <DrivingIcon className={classes.tachographIcon}/>
                                    <Typography>
                                        <IntlMessages
                                            id={"fleet.management.tachograph.traffic.drivers.list.item.dce"}
                                        />: {driver.extendedDriving}
                                    </Typography>
                                </Box>
                            </Tooltip>
                        </Box>
                    </Box>

                    <Box width="20%">
                        {driver.isInactive
                            ? <Tooltip
                                style={{pointerEvents: "auto"}}
                                title={<IntlMessages id="last.activity.date"/>}
                            >
                                <Typography className={classes.heading}>
                                    {driver.currentActivityDate}
                                </Typography>
                            </Tooltip>
                            :
                            <GridContainer spacing={2}>
                                <Tooltip
                                    title={<IntlMessages id="working.day.driving"/>}
                                >
                                    <Grid item xs={6} className={classes.iconBox}>
                                        <DrivingIcon className={classes.tachographIcon}/>
                                        <Typography className={classes.heading}>
                                            {minutesToTime(driver.todayDriving)}
                                        </Typography>
                                    </Grid>
                                </Tooltip>
                                <Tooltip
                                    title={<IntlMessages id="effective.break.in.actual.driving.period"/>}
                                >
                                    <Grid item xs={6} className={classes.iconBox}>
                                        <DrivingIcon className={classes.tachographIcon}/>
                                        <InterruptionIcon className={classes.tachographIcon}/>
                                        <Typography className={classes.heading}>
                                            {minutesToTime(driver.accumulatedBreak)}
                                        </Typography>
                                    </Grid>
                                </Tooltip>
                            </GridContainer>
                        }
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <GridContainer spacing={4} className={classes.driverItemContentBox}>
                    <Grid
                        item
                        xs={12}
                        // md={8}
                    >
                        <Box className={classes.drivingBox}>
                            <Box
                                style={{
                                    display: 'grid',
                                    alignContent: 'center',
                                    gridTemplateColumns: '1fr 1fr',
                                }}
                            >
                                <Box className={classes.drivingTitleBox}>
                                    <Tooltip
                                        title={
                                            <p>
                                                <IntlMessages id="from"/> {driver.currentActivityTime}
                                            </p>
                                        }
                                    >
                                        <Box className={classes.iconBox}>
                                            {ACTIVITY_STATES[driver.currentActivityId ?? '3'](classes.tachographIcon)}
                                            <Typography className={classes.contentTitle}>
                                                {driver.currentActivityDescription}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                </Box>
                                <Box
                                    sx={{
                                        justifySelf: 'end',
                                        display: 'flex',
                                        gap: '1rem'
                                    }}
                                >
                                    <Link
                                        onClick={fetchActivitiesExcel}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <IntlMessages id='download'/> <IntlMessages id='activities'/>
                                    </Link>
                                    <Link
                                        onClick={fetchExcel}
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <IntlMessages id='download'/> <IntlMessages id='plots'/>
                                    </Link>
                                </Box>
                            </Box>
                            <Divider variant="middle"/>
                            <Box>
                                <ProgressBar
                                    icon={<PlayArrowIcon className={classes.progressIcon}/>}
                                    title={
                                        driver.isInactive
                                            ? <p>{parseDateToDateString(driver.currentDate)}</p>
                                            : <IntlMessages
                                                id="fleet.management.tachograph.traffic.drivers.list.item.today"/>
                                    }
                                    distance={driver.todayDistance}
                                    drivingHours={driver.todayDriving}
                                    availabilityHours={driver.todayAvailability}
                                />
                                {
                                    <Box>
                                        <ActivityGraph base64={driver?.activityGraph?.replaceAll('"', '')}/>
                                    </Box>
                                }
                            </Box>
                            {!driver.isInactive && <Accordion>
                                <AccordionSummary>
                                    <Box className={classes.drivingProgressBarBox}>
                                        <Box width="20%" className={classes.iconBox}>
                                            <PlayArrowIcon className={classes.progressIcon}/>
                                            <Typography className={classes.drivingProgressBarTitle}>
                                                <IntlMessages id='yesterday'/>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ActivityGraph base64={driver?.yesterdayActivityGraph?.replaceAll('"', '')}/>
                                </AccordionDetails>
                            </Accordion>}
                            <ProgressBar
                                icon={<SyncAltIcon className={classes.progressIcon}/>}
                                title={
                                    driver.isInactive
                                        ? <IntlMessages id="week"/>
                                        : <IntlMessages
                                            id="fleet.management.tachograph.traffic.drivers.list.item.current.week"/>
                                }
                                distance={driver.currentWeekDistance}
                                drivingHours={driver.currentWeekDriving}
                                availabilityHours={driver.currentWeekAvailability}
                            />
                        </Box>
                    </Grid>
                    {!driver.isInactive &&
                        <>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    margin: "5px 20px"
                                }}
                            >
                                <Typography className={classes.contentTitle}>
                                    <IntlMessages id="driving"/>
                                </Typography>
                                <Divider variant="middle"/>
                            </Grid>
                            <div className="details_box driving_details" >
                                <BreakBox
                                    minutes={driver.totalDriving}
                                    label={<IntlMessages id="current.driving.period"/>}
                                />
                                <BreakBox
                                    minutes={driver.currentPeriodRemainingTime}
                                    label={
                                        <React.Fragment>
                                            <IntlMessages id="remaining.time"/>{" "}
                                            <span className={classes.lowercaseText}>
                                            <IntlMessages id="current.driving.period"/>
                                        </span>
                                        </React.Fragment>
                                    }
                                />
                                <BreakBox
                                    minutes={driver.nextDriving}
                                    label={<IntlMessages id="next.driving.period"/>}
                                />
                                <BreakBox
                                    minutes={driver.nextDrivingDuration}
                                    label={
                                        <React.Fragment>
                                            <IntlMessages id="duration"/>{" "}
                                            <span className={classes.lowercaseText}>
                                            <IntlMessages id="next.driving.period"/>
                                        </span>
                                        </React.Fragment>
                                    }
                                />
                                <BreakBox
                                    minutes={driver.twiceWeekDriving}
                                    label={<IntlMessages id={"twice.weekly.driving"}/>}
                                />
                            </div>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    margin: "5px 20px"
                                }}
                            >
                                <Typography className={classes.contentTitle}>
                                    <IntlMessages
                                        id={
                                            "fleet.management.tachograph.traffic.drivers.list.item.break"
                                        }
                                    />
                                </Typography>
                                <Divider variant="middle"/>
                            </Grid>
                            <div className="details_box breaks_details">
                                <BreakBox
                                    minutes={driver.nextBreak}
                                    label={
                                        <IntlMessages
                                            id={
                                                "fleet.management.tachograph.traffic.drivers.list.item.next.break"
                                            }
                                        />
                                    }
                                />
                                <BreakBox
                                    minutes={driver.nextBreakDuration}
                                    label={
                                        <React.Fragment>
                                            <IntlMessages id="duration"/>{" "}
                                            <span className={classes.lowercaseText}>
                                            <IntlMessages id="next.break"/>
                                        </span>
                                        </React.Fragment>
                                    }
                                />
                                <BreakBox
                                    minutes={driver.nextDailyBreak}
                                    label={
                                        <IntlMessages
                                            id={
                                                "fleet.management.tachograph.traffic.drivers.list.item.next.daily.break"
                                            }
                                        />
                                    }
                                />
                                <BreakBox
                                    minutes={driver.minDailyBreak}
                                    label={
                                        <IntlMessages
                                            id={
                                                "fleet.management.tachograph.traffic.drivers.list.item.min.daily.break"
                                            }
                                        />
                                    }
                                />
                                <BreakBox
                                    minutes={driver.nextWeeklyBreak}
                                    label={
                                        <IntlMessages
                                            id={
                                                "fleet.management.tachograph.traffic.drivers.list.item.next.weekly.break"
                                            }
                                        />
                                    }
                                />
                                <BreakBox
                                    minutes={driver.minWeeklyBreak}
                                    label={
                                        <IntlMessages
                                            id={
                                                "fleet.management.tachograph.traffic.drivers.list.item.min.weekly.break"
                                            }
                                        />
                                    }
                                />
                            </div>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    margin: "5px 20px"
                                }}
                            >
                                <Typography className={classes.contentTitle}>
                                    <IntlMessages
                                        id={
                                            "fleet.management.tachograph.traffic.drivers.list.item.alerts"
                                        }
                                    />
                                </Typography>
                                <Divider variant="middle"/>
                            </Grid>
                            <Grid item xs={12}>
                                {driver.alerts.map(a => (
                                    <Typography key={a.id} variant="body2" color="error" style={{marginLeft: '20px'}}>
                                        {a.description} - {a.date}
                                    </Typography>
                                ))}
                            </Grid>
                        </>
                    }
                </GridContainer>
            </AccordionDetails>
        </Accordion>
    );
};

export default DriverItem;
