import {mapToBoolean} from "../../../utils/mappers";

class WorkshopTaskInformation {
    constructor(id, description, status) {
        this.id = id;
        this.description = description;
        this.status = status;
    }

    static map = information => {
        return new WorkshopTaskInformation(
            information.nu_id_trabajo_informacion,
            information.vc_desc_trabajo_informacion,
            mapToBoolean(information.bi_estado),
        )
    }
}

export default WorkshopTaskInformation;