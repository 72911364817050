import {
    FETCH_CARBON_FOOTPRINT_DATA,
    RESET_CARBON_FOOTPRINT_FILTERS,
    RESET_CARBON_FOOTPRINT_STATE,
    SET_CARBON_FOOTPRINT_FILTERS
} from "../../types/Logistic/CarbonFootprintTypes";

const INIT_DATA = {
    filters: {
        saleOrganization: "",
        client: '',
        startDate: "",
        density: '',
        calcConsumption: '',
        theoreticalConsumption: '',
        emissionFactor: '',
    },
    data: [],
}


export default (state = INIT_DATA, action) => {
    switch (action.type) {
        case SET_CARBON_FOOTPRINT_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload,
                }
            }
        }
        case RESET_CARBON_FOOTPRINT_FILTERS: {
            return {
                ...state,
                filters: {
                    ...INIT_DATA.filters,
                }
            }
        }
        case FETCH_CARBON_FOOTPRINT_DATA: {
            return {
                ...state,
                data: [
                    ...action.payload,
                ]
            }
        }
        case RESET_CARBON_FOOTPRINT_STATE: {
            return {
                ...state,
                ...INIT_DATA
            }
        }
        default: {
            return state;
        }
    }
}