import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "components/Common/Tables/CrudTable/RestoreTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import moment from "moment";

const Table = ({data, onEdit, onDelete, onRestore, isLoading}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const RenderRow = ({row}) =>
    row.active ? (
      <CrudTableRow
        onEdit={() => onEdit(row.id)}
        onDelete={() => onDelete(row.id)}
      >
        <StyledTableCell>
          {row.entityNameOrigin +
            "/" +
            row.campusNameOrigin +
            "/" +
            row.warehouseNameOrigin}
        </StyledTableCell>
        <StyledTableCell>
          {row.entityNameDestiny +
            "/" +
            row.campusNameDestiny +
            "/" +
            row.warehouseNameDestiny}
        </StyledTableCell>
        <StyledTableCell>
          {moment(row.date).format("DD/MM/YY HH:mm")}
        </StyledTableCell>
      </CrudTableRow>
    ) : (
      <StyledTableRow>
        <StyledTableCell>{row.entityNameOrigin}</StyledTableCell>
        <StyledTableCell>{row.entityNameDestiny}</StyledTableCell>
        <StyledTableCell>
          {moment(row.date).format("DD/MM/YY HH:mm")}
        </StyledTableCell>
        <StyledTableCell />
      </StyledTableRow>
    );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      origin: PropTypes.string,
      destiny: PropTypes.string,
      date: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {}
};

export default Table;
