import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import CountrySelect from "../../../../Common/Forms/Select/CountrySelect";
import useForm from "../../../../../hooks/Common/useForm";
import {addValidations} from "../validations";
import PropTypes from "prop-types";

const AddForm = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    {
      nif: "",
      name: "",
      countryNif: ""
    },
    addValidations
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            id={"add-employee-nif"}
            fullWidth
            label={<IntlMessages id={"rrhh.employees.form.nif"} />}
            value={formData.nif}
            onChange={value => setFormData("nif", value)}
            error={errors.nif[0]}
            hasError={errors.nif.length > 0}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            id={"add-employee-name"}
            fullWidth
            label={<IntlMessages id={"rrhh.employees.form.name"} />}
            value={formData.name}
            onChange={value => setFormData("name", value)}
            error={errors.name[0]}
            hasError={errors.name.length > 0}
          />
        </Grid>
        <Grid item xs={12}>
          <CountrySelect
            id={"add-employee-country-nif"}
            fullWidth
            label={<IntlMessages id={"rrhh.employees.form.country.nif"} />}
            value={formData.countryNif}
            onChange={value => setFormData("countryNif", value)}
            error={errors.countryNif[0]}
            hasError={errors.countryNif.length > 0}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

AddForm.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AddForm;
