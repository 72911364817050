import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const EstimatedTimeTable = ({estimatedTime}) => {
  return (
    <GridContainer style={{marginBottom: "4px"}}>
      <Grid item xs={12}>
        <h3>
          <IntlMessages id="administrator.ot.breakdown.destination.estimated.historic" />
        </h3>
      </Grid>
      <Grid item xs={12}>
        <p>
          <IntlMessages id="administrator.ot.breakdown.destination.estimated.did.times" />:{" "}
          {estimatedTime.didTimes}
        </p>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.average.time" />:{" "}
              {estimatedTime.average}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.client.time" />:{" "}
              {estimatedTime.clientTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.traveled.kilometers" />:{" "}
              {estimatedTime.traveledKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.client.kilometers" />:{" "}
              {estimatedTime.clientKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.google.time" />:{" "}
              {estimatedTime.googleTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.central.time" />:{" "}
              {estimatedTime.centralTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.google.kilometers" />:{" "}
              {estimatedTime.googleKilometers} {" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages id="administrator.ot.breakdown.destination.estimated.central.kilometers" />:{" "}
              {estimatedTime.centralKilometers} {" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default EstimatedTimeTable;
