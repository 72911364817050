import React, {useEffect, useCallback} from "react";

import PageContainer from "components/Administrator/Supervisors/PageContainer";
import Table from "components/Administrator/Supervisors/Table";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import useUiState from "hooks/Administrator/useUiState";
import DeleteConfirmation from "components/Administrator/Supervisors/Confirmations/DeleteConfirmations";
import RestoreConfirmation from "components/Administrator/Supervisors/Confirmations/RestoreCofirmations";
import Filters from "components/Administrator/Supervisors/Filters";
import CreateSupervisorDialog from "components/Administrator/Supervisors/SaveSupervisorsDialog/CreateSupervisorDialog";
import EditSupervisorDialog from "components/Administrator/Supervisors/SaveSupervisorsDialog/EditSupervisorDialog";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";

import supervisors from "redux/actions/Administrator/Supervisors";
import {connect} from "react-redux";
import {getReceiverById} from "../../../../services/ReceiverService";
import {getSupervisorById} from "../../../../services/SupervisorService";

const Supervisors = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(supervisors.get());
      return () => dispatch(supervisors.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(supervisors.applyFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(supervisors.resetFilters());

  const onEdit = data => dispatch(supervisors.edit(ui.editingId, data));
  const onDelete = () => dispatch(supervisors.delete(ui.deletingId));
  const onRestore = () => dispatch(supervisors.restore(ui.restoringId));
  const onCreate = data => dispatch(supervisors.create(data));

  const [ current, setCurrent ] = React.useState(null);
  React.useEffect(
    () => {
      if (ui.editingId !== null) {
        getSupervisorById(ui.editingId).then(supervisor =>
          setCurrent(supervisor)
        );
      } else {
        setCurrent(null);
      }
    },
    [ ui.editingId ]
  );
  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateSupervisorDialog onSubmit={onCreate} {...ui.createFormProps} />
      {current && (
        <EditSupervisorDialog
          initialData={current}
          onSubmit={onEdit}
          {...ui.editFormProps}
        />
      )}
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.supervisors;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("names", filters.supervisor)
    .byFieldIncludes("tags", filters.tag)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(Supervisors);
