import {createSlice} from "@reduxjs/toolkit";
import {
    addType,
    editTypeState,
    editType,
    fetchAllTypes,
} from "../../thunks/ElevatorManagement/types";
import {cloneDeep} from "lodash";

const INITIAL_STATE = {
    data: {},
    filters: {
        code: "",
        name: ""
    },
    current: null,
    dialogs: [],
};

const typesSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "types",
    reducers: {
        setCurrentType: (state, action) => {
            state.current = action.payload;
        },
        openTypesDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload,
            ];
        },
        closeTypesDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setTypesFilters: (state, action) => {
            if (action.payload) {
                state.filters = action.payload;
            }else {
                state.filters = cloneDeep(INITIAL_STATE.filters);
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllTypes.fulfilled, (state, action) => {
            const list = {};
            for(let type of action.payload.types) {
                list[type.id] = type;
            }
            state.data = list;
        });
        builder.addCase(addType.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload;
        });
        builder.addCase(editType.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload;
        });
        builder.addCase(editTypeState.fulfilled, (state, action) => {
            state.data[action.payload.id].state = action.payload.state;
        });
    }
});

export default typesSlice.reducer;

export const {
    setCurrentType,
    openTypesDialog,
    closeTypesDialog,
    setTypesFilters,
} = typesSlice.actions;