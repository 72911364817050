import React from "react";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {
  closeVehicleReportsDialog,
  resetCurrentVehicleReports
} from "../../../../../redux/actions/FleetManagement/VehicleReports";
import {dialogs} from "../constants";
import BasicInfo from "./BasicInfo";
import {Box} from "@material-ui/core";
import GroupList from "./GroupsList";

const DetailDialog = () => {
  const {dialog: {names}, current} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleReports
  );
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(closeVehicleReportsDialog(dialogs.DETAIL_DIALOG));
    dispatch(resetCurrentVehicleReports());
  };

  return (
    <FullScreenDialog
      handleClose={onClose}
      open={names.some(n => n === dialogs.DETAIL_DIALOG)}
      title={"fleet.management.vehicle.reports.detail.dialog.title"}
    >
      {current && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px 10px"
          }}
        >
          <BasicInfo report={current} />
          <GroupList checklists={current.checklists} />
        </Box>
      )}
    </FullScreenDialog>
  );
};

DetailDialog.propTypes = {};

export default DetailDialog;
