import {mapToBoolean} from "../../../utils/mappers";

class RejectionType {
  constructor(id, description, code, status) {
    this.id = id;
    this.description = description;
    this.code = code;
    this.status = status;
  }

  static map = type => {
    return new RejectionType(
      type.nu_id_tipo_retraso,
      type.vc_desc_tipo_retraso,
      type.vc_cod_tipo_retraso,
      mapToBoolean(type.bi_estado)
    );
  };
}

export default RejectionType;
