import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import CampusSelect from "../domain/CampusSelect";
import Response from "../domain/Response";

let fetchAllCancelToken = undefined;

const campus = [
  {
    nu_id_entidad_sede: 1,
    vc_nombre_sede: "Campus 1"
  }
];

export const cancelPrevCampusSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllCampusSelect = async id => {
  try {
    const response = await axiosInstance.post(
      "/Entidad_Sedes/sel",
      {nu_id_entidad: id},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(campusSelect => CampusSelect.map(campusSelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
