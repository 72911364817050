import React from "react";
import MultipleSelect from "../../../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {fetchAllTagsByType} from "../../../../../../../../services/TagService";

const TagsPicker = ({value, onChange, error, hasError, disabled}) => {
  const {data: tags} = useFetch(fetchAllTagsByType, 2, []);
  return (
    <MultipleSelect
      fullWidth
      label={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.details.basic.tags"}
        />
      }
      value={value}
      onChange={onChange}
      options={tags}
      disabled={disabled}
      limitTags={2}
    />
  );
};

export default TagsPicker;
