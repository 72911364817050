import {useSelector} from "react-redux";
import {Redirect, Route} from "react-router";
import React from "react";

const RestrictedRoute = ({ component: Component, ...rest }) => {
    const { authUser } = useSelector(({ auth }) => auth);
    return (
        <Route
            {...rest}
            render={props =>
                authUser ? (
                    authUser?.companyId
                        ? <Component {...props} />
                        : <Redirect to={"/no-company"} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/signin',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
export default RestrictedRoute;
