class BoxesFilterBuilder {
  constructor(data) {
    this.data = [ ...data ];
  }

  byFieldIncludes(field, value) {
    if (value === undefined || value === null)
      return new BoxesFilterBuilder(this.data);
    const filteredData = [];
    this.data.forEach(currency => {
      const filteredCurrency = {
        ...currency,
        boxes: currency.boxes.filter(box => {
          if (box[field] === undefined) {
            throw new Error("Field parameter must be a valid property");
          }
          return box[field]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase());
        })
      };
      if (filteredCurrency.boxes.length > 0) {
        filteredData.push(filteredCurrency);
      }
    });
    return new BoxesFilterBuilder(filteredData);
  }

  byFieldEquals(field, value) {
    if (value === undefined || value === null)
      return new BoxesFilterBuilder(this.data);
    const filteredData = [];
    this.data.forEach(currency => {
      const filteredCurrency = {
        ...currency,
        boxes: currency.boxes.filter(box => {
          if (box[field] === undefined) {
            throw new Error("Field parameter must be a valid property");
          }
          if (value.toString() === "") {
            return true;
          }
          return (
            box[field].toString().toLowerCase() ===
            value.toString().toLowerCase()
          );
        })
      };
      if (filteredCurrency.boxes.length > 0) {
        filteredData.push(filteredCurrency);
      }
    });
    return new BoxesFilterBuilder(filteredData);
  }

  byCurrencies(currency) {
    if (currency === undefined || currency === null)
      return new BoxesFilterBuilder(this.data);
    const filteredData = this.data.filter(box => {
      if (box.currency === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      if (currency.toString() === "") {
        return true;
      }
      return (
        box.currency.toString().toLowerCase() ===
        currency.toString().toLowerCase()
      );
    });
    return new BoxesFilterBuilder(filteredData);
  }

  byState(state) {
    const filteredData = [];
    if (state === null) {
      return new BoxesFilterBuilder([ ...this.data ]);
    }
    this.data.forEach(currency => {
      const filteredCurrency = {
        ...currency,
        boxes: currency.boxes.filter(box => {
          // const mappedState = box.state ? BoxesState.ACTIVE : BoxesState.INACTIVE;
          return box.state === state;
        })
      };
      if (filteredCurrency.boxes.length > 0) {
        filteredData.push(filteredCurrency);
      }
    });
    return new BoxesFilterBuilder(filteredData);
  }

  build() {
    return this.data;
  }
}

export default BoxesFilterBuilder;
