class PausesTypes {
  constructor(id, entityId, stateType, active) {
    this.id = id;
    this.entityId = entityId;
    this.stateType = stateType;
    this.active = active;
  }

  static map = pausesTypes => {
    return new PausesTypes(
      pausesTypes.nu_id_tipo_estado_subtipo,
      pausesTypes.nu_id_entidad,
      pausesTypes.vc_id_tipo_estado_subtipo,
      pausesTypes.bi_activo
    );
  };
}

export default PausesTypes;
