import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {
    fetchAllDrivingControlEvents,
    fetchDrivingControlEventsExcel,
    getAllEventMedia
} from "../../thunks/Localization/drivingControl";

const INITIAL_STATE = {
    events: [],
    currentEvent: null,
    dialogs: [],
    filters: {
        units: [],
        driver: '',
        trip: '',
        eventTypes: [],
        startTime: '',
        endTime: '',
    },
    rowCount: 0,
};


const drivingControl = createSlice({
    name: 'drivingControl',
    initialState: cloneDeep(INITIAL_STATE),
    reducers: {
        openDialog: (state, action) => {
          state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentEvent: (state, action) => {
            state.currentEvent = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetState: () => cloneDeep(INITIAL_STATE),
    },
    extraReducers: builder => {
        builder
            .addCase(fetchAllDrivingControlEvents.fulfilled, (state, action) => {
                state.events = action.payload.events;
                state.rowCount = action.payload.rowCount;
            })
            .addCase(fetchDrivingControlEventsExcel.fulfilled, (state, action) => {
                state.filters = action.payload.filters;
            })
            .addCase(getAllEventMedia.fulfilled, (state, action) => {
                state.currentEvent = {
                    ...state.currentEvent,
                    medias: action.payload,
                };
            });

    }
});

export default drivingControl.reducer;

export const { openDialog, closeDialog, setCurrentEvent, setFilters } = drivingControl.actions;