import React, { useEffect, useState } from "react";
import axiosInstance, { CancelToken, isCancel } from "utils/axiosInstance";
import { getCompanyEntityId, getUserId } from "services/StorageService";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {alpha, makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";


const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%"
  },
  errorNumber: {
    color: theme.palette.text.primary,
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 30,
    textShadow: "10px 6px 8px hsla(0,0%,45.9%,.8)"
  },
  searchRoot: {
    position: "relative",
    width: 260,
    [theme.breakpoints.up("md")]: {
      width: 350
    },
    "& .searchBtn": {
      position: "absolute",
      right: 0,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1
    },
    "& .MuiInputBase-root": {
      width: "100%"
    },
    "& .MuiInputBase-input": {
      height: 48,
      borderRadius: 4,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #ced4da",
      fontSize: 16,
      boxSizing: "border-box",
      padding: "5px 50px 5px 15px",
      transition: theme.transitions.create([ "border-color", "box-shadow" ]),
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  }
}));

const LocalizationPage = () => {
  const classes = useStyles();
  const [isAllowed, setIsAllowed] = useState(true);
  useEffect(() => {
    // fetch data
    async function GetEmployeesAccces() {
      try {
        const response = await axiosInstance.post(
          '/Acceso/sel_acceso_empleado',
          {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_entidad_empleado: getUserId(),
          }
        );

        if(response.data.cadEncriptada != "") {
          fetchData("https://" + response.data.company + ".4gflota.com/swGestionFlotaDesa/GestionFlota.svc/obtener_Informacion?timestamp=1666348936589", { "a": { "cadEntrada": response.data.cadEncriptada } });
        } else {
          setIsAllowed(false);
        }
        

        return response.data;

      } catch (e) {
        alert("Ha ocurrido un error a la hora de cargar el mapa");
        return Promise.reject(e);
      }
    }
    GetEmployeesAccces();

    async function fetchData(url = '', data = {}) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        const res = await response.json();

        let permision = res.d[7];
        if (!permision.includes("pVisualizacion")) {
          setIsAllowed(false);
        }
        const directory = res.d[5].split("?");

        const arrKey = res.d[3].split("%");
        const key = arrKey[0] + "%25" + arrKey[1];

        const company = res.d[11].toLowerCase();

        const user = res.d[1];
        const location = res.d[4];

        const uri = "https://" + company + process.env.REACT_APP_API_4GFLOTA_URI + directory[0] + "&locale=" + location + "&key=" + key + "&user=" + user + "&tupdate=10000";

        const frame = document.getElementById('iframe-map');
        frame.src = uri;
      } else {
        alert("Ha ocurrido un error a la hora de cargar el mapa");
      }
    }

  }, []);



  return (
    (
      <>
        {isAllowed ?
          <iframe
            id="iframe-map"
            width={"100%"}
            height={"100%"}
            frameBorder="0"
          />
          :
          <Box className={classes.root}>
          <Box fontSize={{xs: 100, sm: 160}} className={classes.errorNumber}>
            <NotInterestedIcon color="primary" style={{fontSize: 200}} />
          </Box>
          <Box fontSize={{xs: 16, sm: 24}} mb={8} color="grey.500">
            <IntlMessages id={"not.permissions.iframe.map"} />
          </Box>
        </Box>
        }
      </>


    )
  );
};

export default LocalizationPage;
