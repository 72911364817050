import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import WorkOrderType from "../../../domain/Logistic/Distribution/WorkOrderType";
import Response from "../../../domain/Response";
import DestinationType from "../../../domain/Logistic/Distribution/DestinationType";

export const getAllWorkOrdersTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho/sel", {
      nu_id_entidad: getCompanyEntityId(),
      bi_extentido: true
    });
    return data.map(type => WorkOrderType.map(type));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createWorkOrderType = async type => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_despacho: type.name,
      nu_id_externo: ""
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateWorkOrderType = async type => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_despacho: type.id,
      vc_desc_tipo_despacho: type.name,
      nu_id_externo: ""
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateWorkOrderTypeStatus = async (type, status) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho/del", {
      nu_id_tipo_despacho: type.id,
      bi_estado: status
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addStateToWorkOrderType = async (typeId, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Estados/ins", {
      nu_id_tipo_despacho: typeId,
      vc_desc_tipo_despacho_estado: state.name,
      bi_llegada_despacho: state.arrivalDestination,
      bi_salida_despacho: state.departureDestination,
      bi_finaliza_despacho: state.type === 2,
      nu_id_orden: state.order,
      bi_defecto_despacho: true,
      bi_permite_confirmacion: state.confirmation,
      bi_permite_albaran: state.permission,
      nu_id_checklist_pre_estado: state.previousCheck,
      nu_id_checklist_post_estado: state.confirmationCheck,
      nu_id_futuros_estados: JSON.stringify(state.checklist),
      nu_id_externo: ""
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addComplementaryStateToWorkOrderType = async (type, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Estados/ins", {
      nu_id_tipo_despacho: type.id,
      vc_desc_tipo_despacho_estado: state.name,
      bi_llegada_despacho: state.arrivalDestination || false,
      bi_salida_despacho: state.departureDestination || false,
      bi_finaliza_despacho: state.type === 2,
      nu_id_orden: type.complementaryStates.length + 3,
      bi_defecto_despacho: true,
      bi_permite_confirmacion: false,
      bi_permite_albaran: false,
      nu_id_checklist_pre_estado: "",
      nu_id_checklist_post_estado: "",
      nu_id_futuros_estados: JSON.stringify([]),
      nu_id_externo: ""
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateWorkOrderTypeState = async (type, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Estados/upd", {
      nu_id_tipo_despacho: type.id,
      nu_id_tipo_despacho_estado: state.id,
      vc_desc_tipo_despacho_estado: state.name,
      bi_llegada_despacho: state.arrivalDestination,
      bi_salida_despacho: state.departureDestination,
      bi_finaliza_despacho: state.type === 2,
      nu_id_orden: state.order,
      bi_defecto_despacho: true,
      bi_permite_confirmacion: state.confirmation,
      bi_permite_albaran: state.permission,
      nu_id_checklist_pre_estado: state.previousCheck,
      nu_id_checklist_post_estado: state.confirmationCheck,
      nu_id_futuros_estados: JSON.stringify(state.checklist),
      nu_id_externo: ""
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllDispatchesTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho/sel", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_despacho: 0,
      bi_extentido: false
    });
    return data.map(type => DestinationType.map(type));
  } catch (e) {
    return Promise.reject(e);
  }
};
