export const GENERATE_WORK_ORDER_STARTED = "GENERATE_WORK_ORDER_STARTED";
export const GENERATE_WORK_ORDER_SUCCEED = "GENERATE_WORK_ORDER_SUCCEED";
export const FETCH_ASSIGMENT_VEHICLES_STARTED =
  "FETCH_ASSIGMENT_VEHICLES_STARTED";
export const FETCH_ASSIGMENT_VEHICLES_SUCCEED =
  "FETCH_ASSIGMENT_VEHICLES_SUCCEED";
export const FETCH_ASSIGMENT_ORDERS_STARTED = "FETCH_ASSIGMENT_ORDERS_STARTED";
export const FETCH_ASSIGMENT_ORDERS_SUCCEED = "FETCH_ASSIGMENT_ORDERS_SUCCEED";
export const SET_ORDERS_FROM_EXCEL = "SET_ORDERS_FROM_EXCEL";
export const RESET_ASSIGMENT_WORK_ORDERS = "RESET_ASSIGMENT_WORK_ORDERS";
export const RESET_ASSIGMENT_STATE = "RESET_ASSIGMENT_STATE";
export const CONFIRM_WORK_ORDER_STARTED = "CONFIRM_WORK_ORDER_STARTED";
export const CONFIRM_WORK_ORDER_SUCCEED = "CONFIRM_WORK_ORDER_SUCCEED";
export const RESET_ASSIGMENT_RESULT = "RESET_ASSIGMENT_RESULT";
export const APPLY_ASSIGMENT_VEHICLE_FILTERS =
  "APPLY_ASSIGMENT_VEHICLE_FILTERS";
export const RESET_ASSIGMENT_VEHICLE_FILTERS =
  "RESET_ASSIGMENT_VEHICLE_FILTERS";
export const SET_OPTIMIZATION_ID = "SET_OPTIMIZATION_ID";
