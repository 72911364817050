import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Button, Grid} from "@material-ui/core";
import {
  applyDispatchesFilters,
  resetDispatchesFilters
} from "../../../../../../redux/actions/Logistic/Monitoring";

const Filters = () => {
  const {filters, isLoading} = useSelector(
    state => state.logistic.monitoring.dispatches
  );
  const dispatch = useDispatch();
  const {formData, resetForm, setFormData} = useForm(filters);
  return (
    <FiltersContainer
      active
      onClose={() => {}}
      showLoadingIndicator={isLoading}
    >
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.client}
              id={"dispatches-client"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.dispatches.table.filters.client"}
                />
              }
              onChange={value => setFormData("client", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.state}
              id={"dispatches-state"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.dispatches.table.filters.state"}
                />
              }
              onChange={value => setFormData("state", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.workOrder}
              id={"dispatches-work-order"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.dispatches.table.filters.work.order"}
                />
              }
              onChange={value => setFormData("workOrder", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.vehicle}
              id={"dispatches-vehicle"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.dispatches.table.filters.vehicle"}
                />
              }
              onChange={value => setFormData("vehicle", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.driver}
              id={"dispatches-driver"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.dispatches.table.filters.driver"}
                />
              }
              onChange={value => setFormData("driver", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.route}
              id={"dispatches-route"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.dispatches.table.filters.route"}
                />
              }
              onChange={value => setFormData("route", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.rejectReason}
              id={"dispatches-reject-reason"}
              label={
                <IntlMessages
                  id={
                    "logistic.monitoring.dispatches.table.filters.reject.reason"
                  }
                />
              }
              onChange={value => setFormData("rejectReason", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => dispatch(applyDispatchesFilters(formData))}
              color="primary"
            >
              <IntlMessages
                id={"logistic.monitoring.dispatches.table.filters.apply"}
              />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Button
              variant="contained"
              disabled={isLoading}
              color="primary"
              onClick={() => {
                resetForm();
                dispatch(resetDispatchesFilters());
              }}
            >
              <IntlMessages
                id={"logistic.monitoring.dispatches.table.filters.reset"}
              />
            </Button>
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
