import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    createElevatorGenre,
    getAllElevatorsGenres,
    updateElevatorGenre,
    updateElevatorGenreState
} from "../../../services/ElevatorsManagement/GenresService";

const namespace = 'elevatorManagement/genres';

export const fetchAllGenres = createAsyncThunk(
    `${namespace}/fetchAllGenres`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const genres = await getAllElevatorsGenres();
            dispatch(fetchSuccess());
            return {
                genres,
            };
        } catch (e) {
            const error = e?.message ?? e;
            dispatch(fetchError(error));
            return rejectWithValue(error);
        }
    }
);

export const addGenre = createAsyncThunk(
    `${namespace}/addGenre`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await createElevatorGenre(request);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                ...request,
                id: response.transactionKey,
                state: true,
            };
        } catch (e) {
            const error = e?.message ?? e;
            dispatch(fetchError(error));
            return rejectWithValue(error);
        }
    }
);

export const editGenre = createAsyncThunk(
    `${namespace}/editGenre`,
    async ({request, current}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateElevatorGenre(current.id, request.name);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                ...current,
                ...request,
            };
        } catch (e) {
            const error = e?.message ?? e;
            dispatch(fetchError(error));
            return rejectWithValue(error);
        }
    }
);

export const editGenreState = createAsyncThunk(
    `${namespace}/editGenreState`,
    async ({id, state}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateElevatorGenreState(id, state);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                id,
                state,
            };
        } catch (e) {
            const error = e?.message ?? e;
            dispatch(fetchError(error));
            return rejectWithValue(error);
        }
    }
);