import React, {useEffect} from "react";
import SuccessAlert from "../../../Common/SnackBars/SuccessAlert";
import ErrorAlert from "../../../Common/SnackBars/ErrorAlert";
import {useDispatch, useSelector} from "react-redux";
import {resetTransactionResult} from "../../../../redux/actions/Guardians/Accounting";

const ResultAlert = ({setOpenAlert, openAlert}) => {
  const {code, message} = useSelector(
    state => state.guardian.accountingList.transaction
  );
  const dispatch = useDispatch();
  useEffect(
    () => {
      if (!openAlert) {
        dispatch(resetTransactionResult());
      }
    },
    [ openAlert ]
  );
  return code === "1" ? (
    <SuccessAlert
      message={message}
      setOpenAlert={setOpenAlert}
      openAlert={openAlert}
    />
  ) : (
    code !== undefined && (
      <ErrorAlert
        message={message}
        setOpenAlert={setOpenAlert}
        openAlert={openAlert}
      />
    )
  );
};

export default ResultAlert;
