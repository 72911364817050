import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import reducers from "../reducers";
import {configureStore} from "@reduxjs/toolkit";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

const store = configureStore({
  reducer: {
    ...reducers(history),
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }).concat([routeMiddleware ]),
  devTools: process.env.NODE_ENV !== 'production',
})
export default store;
export {history};
