import React, {useEffect} from "react";
import {DefaultStyledTableRow, StyledTableCell} from "./styles";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({orderGrouped, orderBy, order, onSort, isGuardian}) => {
  if (orderGrouped === "Producto") {
    return (
      <DefaultStyledTableRow>
        <StyledTableCell />
        <TableCellWithSorting
          property="name"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"logistic.table.product"} />
        </TableCellWithSorting>
        <StyledTableCell align="left">
          <IntlMessages id={"logistic.table.entity"} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <IntlMessages id={"logistic.table.campus"} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <IntlMessages id={"logistic.table.store"} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <IntlMessages id={"guardian.advances.table.travel"} />
        </StyledTableCell>

        {!isGuardian && (
          <StyledTableCell align="left">
            <IntlMessages id={"guardians.operative.plannings.table.actions"} />
          </StyledTableCell>
        )}
      </DefaultStyledTableRow>
    );
  } else {
    return (
      <DefaultStyledTableRow>
        <StyledTableCell />
        <StyledTableCell align="left">
          <IntlMessages id={"logistic.table.entity"} />
        </StyledTableCell>
        <TableCellWithSorting
          property="name"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"logistic.table.product"} />
        </TableCellWithSorting>
        <StyledTableCell align="left">
          <IntlMessages id={"logistic.table.campus"} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <IntlMessages id={"logistic.table.store"} />
        </StyledTableCell>
        <StyledTableCell align="left">
          <IntlMessages id={"guardian.advances.table.travel"} />
        </StyledTableCell>

        {!isGuardian && (
          <StyledTableCell align="left">
            <IntlMessages id={"guardians.operative.plannings.table.actions"} />
          </StyledTableCell>
        )}
      </DefaultStyledTableRow>
    );
  }
};

export default TableHeader;
