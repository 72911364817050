import {getTimeFromDate, parseDateToGlobalFormat, PARTIAL_ISO_DATETIME_FORMAT} from "../../../utils/dates";
import {mapToBoolean} from "../../../utils/mappers";
class TrafficDriver {
  constructor(
    id,
    name,
    plaque,
    todayDriving,
    currentWeekDriving,
    todayAvailability,
    currentWeekAvailability,
    breakDays,
    extendedDriving,
    currentDate,
    todayDistance,
    currentWeekDistance,
    lastWeekDistance,
    todayJobHours,
    currentWeekJobHours,
    lastWeekJobHours,
    accumulatedBreak,
    nextBreak,
    nextDailyBreak,
    nextWeeklyBreak,
    minDailyBreak,
    minWeeklyBreak,
    activityGraph,
    alerts,
    currentActivityId,
    currentActivityDescription,
    currentActivityTime,
    totalDriving,
    currentPeriodRemainingTime,
    nextDriving,
    nextDrivingDuration,
    nextBreakDuration,
    twiceWeekDriving,
    yesterdayActivityGraph,
    vehicleRecord,
    isInactive,
    currentActivityDate,
  ) {
    this.id = id;
    this.name = name;
    this.plaque = plaque;
    this.todayDriving = todayDriving;
    this.currentWeekDriving = currentWeekDriving;
    this.todayAvailability = todayAvailability;
    this.currentWeekAvailability = currentWeekAvailability;
    this.breakDays = breakDays;
    this.extendedDriving = extendedDriving;
    this.currentDate = currentDate;
    this.todayDistance = todayDistance;
    this.currentWeekDistance = currentWeekDistance;
    this.lastWeekDistance = lastWeekDistance;
    this.todayJobHours = todayJobHours;
    this.currentWeekJobHours = currentWeekJobHours;
    this.lastWeekJobHours = lastWeekJobHours;
    this.accumulatedBreak = accumulatedBreak;
    this.nextBreak = nextBreak;
    this.nextDailyBreak = nextDailyBreak;
    this.nextWeeklyBreak = nextWeeklyBreak;
    this.minDailyBreak = minDailyBreak;
    this.minWeeklyBreak = minWeeklyBreak;
    this.activityGraph = activityGraph;
    this.alerts = alerts;
    this.currentActivityId = currentActivityId;
    this.currentActivityDescription = currentActivityDescription;
    this.currentActivityTime = currentActivityTime;
    this.totalDriving = totalDriving;
    this.currentPeriodRemainingTime = currentPeriodRemainingTime;
    this.nextDriving = nextDriving;
    this.nextDrivingDuration = nextDrivingDuration;
    this.nextBreakDuration = nextBreakDuration;
    this.twiceWeekDriving = twiceWeekDriving;
    this.yesterdayActivityGraph = yesterdayActivityGraph;
    this.vehicleRecord = vehicleRecord;
    this.isInactive = isInactive;
    this.currentActivityDate = currentActivityDate;
  }

  static map = trafficDriver => {
    return new TrafficDriver(
      // trafficDriver.nu_id_trafico_conductor,
      trafficDriver.nu_id_entidad,
      trafficDriver.vc_conductor,
      trafficDriver.vc_matricula,
      trafficDriver.nu_conduccion_dia,
      trafficDriver.nu_conduccion_semana,
      trafficDriver.nu_disponibilidad_dia,
      trafficDriver.nu_disponibilidad_semana,
      trafficDriver.nu_total_dias_descansos_reducidos_semana,
      trafficDriver.nu_total_dias_conducciones_extendidas_semana,
      trafficDriver.dt_fecha_actual,
      trafficDriver.nu_distancia_recorrida_dia,
      trafficDriver.nu_distancia_recorrida_semana,
      trafficDriver.nu_distancia_recorrida_semana_anterior,
      trafficDriver.nu_actividad_otros_trabajos_duracion_dia,
      trafficDriver.nu_actividad_otros_trabajos_duracion_semana,
      trafficDriver.nu_actividad_otros_trabajos_duracion_semana_anterior,
      trafficDriver.nu_descanso_acumulado,
      trafficDriver.nu_proximo_descanso,
      trafficDriver.nu_proximo_descanso_diario,
      trafficDriver.nu_proximo_descanso_semanal,
      trafficDriver.nu_minimo_descanso_diario,
      trafficDriver.nu_minimo_descanso_semanal,
      trafficDriver.vc_grafica_actividad,
      trafficDriver.ls_alertas_dia.map(a => ({
        id: a.nu_id_trafico_conductor_estado,
        description: a.vc_descripcion,
        date: getTimeFromDate(a.dt_fecha_actual),
      })),
        trafficDriver.nu_id_actividad_actual,
        trafficDriver.vc_actividad_actual,
        getTimeFromDate(trafficDriver.dt_fecha_actividad_actual),
        trafficDriver.nu_conduccion_actual,
        trafficDriver.nu_tiempo_restante_periodo_conduccion_actual,
        trafficDriver.nu_proxima_conduccion,
        trafficDriver.nu_duracion_proxima_conduccion,
        trafficDriver.nu_duracion_proximo_descanso,
        trafficDriver.nu_conduccion_bisemanal,
        trafficDriver.vc_grafica_actividad_ayer,
        getTimeFromDate(trafficDriver.dt_login_vehiculo),
        mapToBoolean(trafficDriver.bi_inactivo),
        parseDateToGlobalFormat(trafficDriver.dt_fecha_actividad_actual),
    );
  };
}

export default TrafficDriver;
