import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const DateNumberSelect = ({value, onChange, error, hasError, format}) => {
    const id = 'date-number-filter';
    const [numbers, setNumbers] = React.useState([]);
    React.useEffect(() => {
        if (format === 1 || format === 3) {
            setNumbers(Array.from({length: 12}, (_, i) => i + 1));
        } else if (format === 2 || format === 4) {
            setNumbers(Array.from({length: 52}, (_, i) => i + 1));
        } else {
            setNumbers([]);
        }
    }, [format]);
    return (
        <Select
            id={id}
            label={
                (format === 1 || format === 3)
                    ? <IntlMessages
                        id={"control.panel.kpi.areas.grid.distribution.filters.month"}
                    />
                    : <IntlMessages
                        id={"control.panel.kpi.areas.grid.distribution.filters.week"}
                    />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            error={hasError}
            errorMessage={error}
            fullWidth
            disabled={!format}
        >
            {numbers.map(o => (
                <MenuItem key={o} value={o}>
                    {o}
                </MenuItem>
            ))}
        </Select>
    );
}

DateNumberSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any,
    format: PropTypes.any
};

export default DateNumberSelect;