import React from 'react';
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {useSelector} from "react-redux";
import MultipleSelect from "../../../components/Common/Forms/Select/MultipleSelect";

const VehiclePicker = ({id, values, isLoading, onChange, errors, service}) => {
    const {vehicle} = useSelector(state => state.administrator.vehicleSelect);
    const options = vehicle.filter(v => v.active).map(v => ({id: v.id, name: v.alias || v.name, externalId: v.externalId}));

    React.useEffect(() => {
        if(service === 9 && vehicle.length >0){
            onChange(options);
        }
    }, [service, vehicle]);

    return (
        <MultipleSelect
            id={id}
            options={options}
            value={values}
            onChange={onChange}
            fullWidth
            hasError={errors?.vehicles?.length > 0}
            error={errors?.vehicles[0] || ''}
            disabled={service === 9 ? true : isLoading}
            label={<IntlMessages id="administrator.dataCollectionHistory.filters.vehicle"/>}
            headerText={<IntlMessages id="administrator.dataCollectionHistory.filters.vehicle.header" />}
            noOptionsText={<IntlMessages id="administrator.dataCollectionHistory.filters.vehicle.no.options"/>}
        />
    );
}

export default VehiclePicker;