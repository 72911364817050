import React, {useState} from 'react';
import DialogBase from "../../../../Common/Dialogs/DialogBase";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Box, Button, Typography} from "@material-ui/core";
import {NotListedLocation} from "@material-ui/icons";
import {readDataFromExcel} from "../../../../../utils/files";

const ImportDialog = ({open, setOpen, selectedOrders, setSelectedOrders}) => {
    const [orders, setOrders] = useState(null);
    const [file, setFile] = useState(null);
    const [error, setError] = useState("");
    const readFile = () => {
        setError("");
        readDataFromExcel(file, (data) => {
            data && typeof data !== "string" ?
                setOrders(data.map(d => ({
                    id: d.Id,
                    client: d.Cliente,
                    number: d.Numero,
                    address: d.Direccion,
                    priority: d.Prioridad,
                    stay: d.Estancia,
                    startDate: d.Fecha_Inicial,
                    endDate: d.Fecha_Final,
                    latitude: d.Latitud,
                    longitude: d.Longitud,
                    weight: d.Capacidad,
                    volume: d.Volumen,
                    color: "#"+Math.floor(Math.random()*16777215).toString(16)
                }))) :
                setError(data);
        });
    }

    const actions = [
        <Button
            variant="contained"
            color="primary"
            key="import-cancel"
            onClick={() => setOpen(false)}
        >
            <IntlMessages id={"logistic.assigment.orders.import.dialog.cancel"}/>
        </Button>,
        <Button
            variant="contained"
            color="primary"
            key="import-ok"
            onClick={() => {
                setSelectedOrders([...orders]);
                setOpen(false);
            }}
        >
            <IntlMessages id={"logistic.assigment.orders.import.dialog.ok"}/>
        </Button>
    ]

    React.useEffect(() => {
        readFile();
    }, [file]);
    return (
        <DialogBase
            open={open}
            close={() => setOpen(false)}
            title={

                <IntlMessages id={"logistic.assigment.orders.import.dialog.title"}/>

            }
            actions={actions}
        >
            <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" mb={8}>
                <Box>
                    <NotListedLocation style={{height: '130px', width: '130px'}}/>
                </Box>
                <Typography variant="h4">
                    {orders?.length > 0 ?
                        (<React.Fragment>
                            <IntlMessages id="logistic.assigment.orders.import.dialog.there"/> {orders.length}
                        </React.Fragment>):
                        (<React.Fragment>
                            {error?
                                error:
                                <IntlMessages id="logistic.assigment.orders.import.dialog.empty"/>}
                        </React.Fragment>)
                    }
                </Typography>
                <Box mt={4}>
                    <input
                        type="file"
                        multiple={false}
                        onChange={e => setFile(e.target.files[0])}
                    />
                </Box>
            </Box>
        </DialogBase>
    );
}

export default ImportDialog;