import React, {useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import PageContainer from "components/Administrator/Regulation/PageContainer";
import CreateRegulationDialog from "components/Administrator/Regulation/SaveRegulationDialog/CreateRegulationDialog";
import Table from "components/Administrator/Regulation/Table";
import Filters from "components/Administrator/Regulation/Filters";
import DeleteConfirmation from "components/Administrator/Regulation/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/Regulation/Confirmations/RestoreConfirmation";
import EditConfirmation from "components/Administrator/Regulation/SaveRegulationDialog/EditRegulationDialog";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

import Box from "@material-ui/core/Box";
import useUiState from "hooks/Administrator/useUiState";

import regulation from "redux/actions/Administrator/Regulation";

const WarehouseTransfer = () => {
  const dispatch = useDispatch();
  const {data: tableData, filters} = useSelector(
    state => state.administrator.regulation
  );
  const ui = useUiState();

  useEffect(
    () => {
      return () => dispatch(regulation.resetState());
    },
    [ dispatch ]
  );

  useEffect(
    () => {
      dispatch(regulation.get(filters));
    },
    [ filters, dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(regulation.applyRegulationFilter(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(regulation.resetRegulationFilters());

  const onRestore = () => {
    dispatch(regulation.restore(ui.restoringId));
    //dispatch(regulation.get());
  };
  const onDelete = () => {
    dispatch(regulation.delete(ui.deletingId));
    //dispatch(regulation.get());
  };
  const onEdit = data => {
    dispatch(regulation.edit(ui.editingId, data));
    //dispatch(regulation.get());
  };
  const onCreate = data => {
    dispatch(regulation.create(data, filters));
    //dispatch(regulation.get());
  };

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      <Box marginBottom={25}>
        <Table data={tableData} {...ui.tableProps} />
      </Box>
      <CreateRegulationDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditConfirmation
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

export default WarehouseTransfer;
