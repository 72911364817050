import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"pages.stock"} />,
    link: "/logistic/warehouse-transfer"
  },
  {label: <IntlMessages id={"pages.regulation"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"logistic.regulation.title"} />}
    description={<IntlMessages id={"logistic.regulation.description"} />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
