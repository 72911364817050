import {useCallback, useMemo, useState} from "react";

export const useSelect = ({items}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const isSelectedAll = useMemo(
        () => items.length === selectedItems.length,
        [items, selectedItems]
    );
    const onSelectItem = (item) => {
        if(selectedItems.some(e => e.id === item.id)) {
            setSelectedItems(selectedItems.filter(e => e.id !== item.id));
        }else {
            setSelectedItems([
                ...selectedItems,
                item,
            ]);
        }
    }
    const onSelectAll = ()  => {
        if(selectedItems.length === items.length) {
            setSelectedItems([]);
        }else {
            setSelectedItems(items);
        }
    }

    const onUnselectAll = ( ) => {
        setSelectedItems([]);
    }

    const itemIsSelected = useCallback(
        (item) => selectedItems.some(e => e.id === item.id)
        , [selectedItems]
    );

    const thereIsSelected = useMemo(
        () => selectedItems.length > 0
        , [selectedItems, items]
    );

    return {
        onSelectItem,
        onSelectAll,
        selectedItems,
        isSelectedAll,
        itemIsSelected,
        thereIsSelected,
        onUnselectAll
    }
}