import React from "react";
import {Grid} from "@material-ui/core";
import BaseField from "../BaseField";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const AddressField = ({address}) => {
  return (
    <Grid item xs={12} sm={12}>
      <BaseField>
        <p>
          {address || (
            <IntlMessages id={"guardian.add.expense.form.address.type"} />
          )}
        </p>
      </BaseField>
    </Grid>
  );
};

export default AddressField;
