import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import TagType from "../domain/TagType";
import {getUserName} from "./StorageService";
import Response from "../domain/Response";

let fetchAllTagTypesCancelToken = undefined;

export const cancelPrevFetchAllTypeTags = () => {
    fetchAllTagTypesCancelToken && fetchAllTagTypesCancelToken();
};

export const fetchAllTagTypes = async () => {
    try {
        const response = await axiosInstance.post(
            '/Tipo_Tag/sel',
            {},
            {
                cancelToken: new CancelToken(c => (fetchAllTagTypesCancelToken = c)),
            },
        );
        return response.data.map(tag => TagType.map(tag));
    } catch (error) {
        if (isCancel(error)) {
            return Promise.reject({ cancelled: true, message: undefined });
        }
        return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
    }
}

export const createTagType = async type => {
    try {
        const {data} = await axiosInstance.post(
            '/Tipo_Tag/ins',
            {
                vc_desc_tipo_tag: type.description,
            },
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject({ cancelled: false, message: e?.message || 'Network error'});
    }
}

export const updateTagType = async type => {
    try {
        const {data} = await axiosInstance.post(
            '/Tipo_Tag/upd',
            {
                nu_id_tipo_tag: type.id,
                vc_desc_tipo_tag: type.description,
            },
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject({ cancelled: false, message: e?.message || 'Network error'});
    }
}

export const deleteTagType = async id => {
    try {
        const {data} = await axiosInstance.post(
            '/Tipo_Tag/del',
            {
                nu_id_tipo_tag: id,
            },
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject({ cancelled: false, message: e?.message || 'Network error'});
    }
}