class MonitoringState {
  constructor(id, description) {
    this.id = id;
    this.description = description;
  }

  static map = state => {
    return new MonitoringState(state.id, state.descripcion);
  };
}

export default MonitoringState;
