import React from "react";
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {fetchDriversByEntityId} from "../../../../../../services/FleetManagement/Tachograph/TachographsFilesService";

const DriversSelect = ({formData, setFormData}) => {
  const [ drivers, setDrivers ] = React.useState([]);
  React.useEffect(() => {
      fetchDriversByEntityId()
      .then(data => setDrivers(data))
      .catch(e => setDrivers([]));
  }, []);
  return (
    <MultipleSelect
      options={drivers}
      fullWidth
      label={
        <IntlMessages id={"fleet.management.tachograph.files.table.driver"} />
      }
      value={formData.drivers}
      onChange={value => setFormData("drivers", value)}
    />
  );
};

export default DriversSelect;
