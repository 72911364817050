import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {fetchAllTagsByType} from "../../../../../../../services/TagService";

const TagSelect = ({value, onChange}) => {
  const {data: tags} = useFetch(fetchAllTagsByType, 2, []);
  return (
    <Select
      id="vehicle-filters-tag-select"
      label={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.table.filters.tag"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value={""}>
        <IntlMessages
          id={"fleet.management.vehicles.specifications.table.filters.tag.all"}
        />
      </MenuItem>
      {tags.map(tag => (
        <MenuItem value={tag.id} key={tag.id}>
          {tag.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TagSelect;
