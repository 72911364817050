import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId, getUserEmail} from "../StorageService";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";

export const getAllCostCenterTypes = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/CentroCosto/sel",
            {
                nu_id_entidad: getCompanyEntityId(),
            },
        );
        return data.map((costCenter) => ({
            id: costCenter.nu_id_centro_costo,
            description: costCenter.vc_desc_centro_costo,
            active: mapToBoolean(costCenter.bi_estado)
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getCostCenterTypeById = async (costCenterId) => {
    try {
        const {data} = await axiosInstance.post(
            '/CentroCosto/get',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_centro_costo: costCenterId,
            }
        );
        return {
            id: data.nu_id_centro_costo,
            description: data.vc_desc_centro_costo,
        };
    }catch (e) {
        return Promise.reject(e);
    }
}

export const createCostCenterType = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/CentroCosto/ins',
            {
                nu_id_entidad: getCompanyEntityId(),
                vc_desc_centro_costo: request.description,
                vc_usuario: getUserEmail(),
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const updateCostCenterType = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/CentroCosto/upd',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_centro_costo: request.id,
                vc_desc_centro_costo: request.description,
                vc_usuario: getUserEmail(),
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const updateCostCenterTypeState = async (costCenterId) => {
    try {
        const {data} = await axiosInstance.post(
            '/CentroCosto/del',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_centro_costo: costCenterId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getAllCostCenterForSelect = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/CentroCosto/sel",
            {
                nu_id_entidad: getCompanyEntityId(),
            },
        );
        return data.map((costCenter) => ({
            id: costCenter.nu_id_centro_costo,
            description: costCenter.vc_desc_centro_costo,
            active: mapToBoolean(costCenter.bi_estado)
        })).filter(costCenter => costCenter.active);
    } catch (e) {
        return Promise.reject(e);
    }
}