import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const RealTimeTable = ({realTime}) => {
  return (
    <GridContainer style={{marginLeft: "4px"}}>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages
                id={"administrator.ot.breakdown.detail.real.used.time"}
              />: {realTime.usedTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages
                id={"administrator.ot.breakdown.detail.real.client.time"}
              />: {realTime.clientTime}{" "}
              <IntlMessages id={"administrator.ot.breakdown.time.format"} />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <GridContainer>
          <Grid item xs={12}>
            <p>
              <IntlMessages
                id={
                  "administrator.ot.breakdown.detail.real.traveled.kilometers"
                }
              />: {realTime.traveledKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
          <Grid item xs={12}>
            <p>
              <IntlMessages
                id={"administrator.ot.breakdown.detail.real.client.kilometers"}
              />: {realTime.clientKilometers}{" "}
              <IntlMessages
                id={"administrator.ot.breakdown.kilometer.format"}
              />
            </p>
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default RealTimeTable;
