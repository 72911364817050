import {
  APPLY_VEHICLE_REPORTS_FILTERS,
  CLOSE_VEHICLE_REPORTS_DIALOG,
  FETCH_ALL_VEHICLE_REPORTS,
  OPEN_VEHICLE_REPORTS_DIALOG,
  RESET_CURRENT_VEHICLE_REPORTS_TYPE,
  RESET_VEHICLE_REPORTS_FILTERS,
  RESET_VEHICLE_REPORTS_STATE,
  SET_CURRENT_VEHICLE_REPORTS_TYPE
} from "../../types/FleetManagement/VehicleReportsTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import VehicleReport from "../../../domain/FleetManagement/DailyOperations/VehicleReport";
import {
  getAllChecklistsByReport,
  getAllVehicleReports
} from "../../../services/FleetManagement/DailyOperation/VehicleReportsService";
import {dialogs} from "../../../components/FleetManagement/DailyOperation/Parts/constants";

let id = 0;
const reports = [
  new VehicleReport(
    ++id,
    {
      id: 1,
      plaque: "ABC-123"
    },
    new Date().toISOString(),
    "Usuario 1",
    {
      id: 1,
      description: "Tipo 1"
    },
    [
      {
        id: 1,
        description: "Grupo 1",
        subgroups: [
          {
            id: 1,
            description: "Subgroup 1",
            status: true
          },
          {
            id: 2,
            description: "Subgroup 2",
            status: false
          }
        ]
      }
    ],
    new Date().toISOString(),
    200,
    "Conductor 1",
    "Texto de oservacion"
  )
];

export const resetVehicleReportsState = () => ({
  type: RESET_VEHICLE_REPORTS_STATE
});
export const applyVehicleReportsFilters = filters => ({
  type: APPLY_VEHICLE_REPORTS_FILTERS,
  payload: filters
});
export const resetVehicleReportsFilters = () => ({
  type: RESET_VEHICLE_REPORTS_FILTERS
});
export const openVehicleReportsDialog = (name, data) => ({
  type: OPEN_VEHICLE_REPORTS_DIALOG,
  payload: {name, data}
});
export const closeVehicleReportsDialog = name => ({
  type: CLOSE_VEHICLE_REPORTS_DIALOG,
  payload: name
});
export const resetCurrentVehicleReports = () => ({
  type: RESET_CURRENT_VEHICLE_REPORTS_TYPE
});
export const setCurrentVehicleReports = report => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllChecklistsByReport(report)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({type: SET_CURRENT_VEHICLE_REPORTS_TYPE, payload: response});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const fetchAllVehicleReports = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllVehicleReports(filters)
      .then(reports => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_VEHICLE_REPORTS, payload: reports});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};


export const setCurrentFromExternalView = (checklistId) =>  {
  return async dispatch => {
    dispatch(fetchStart());
    getAllChecklistsByReport({id : checklistId}, true)
        .then(response => {
          dispatch(fetchSuccess());
          dispatch({type: SET_CURRENT_VEHICLE_REPORTS_TYPE, payload: response});
          dispatch(openVehicleReportsDialog(dialogs.DETAIL_DIALOG));
        })
        .catch(err => {
          dispatch(fetchError(err.message));
        });
  };
}