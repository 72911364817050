import React from "react";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import BaseField from "../BaseField";

const RouteField = ({route}) => {
  return (
    <React.Fragment>
      <Grid item xs={12} sm={12}>
        <BaseField>
          <p>
            {route.description || (
              <IntlMessages id={"guardian.add.advance.form.route"} />
            )}
          </p>
        </BaseField>
      </Grid>
    </React.Fragment>
  );
};

export default RouteField;
