import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";

const initialData = {
  description: "",
  code: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.description}
            id="description-filter"
            label={
              <IntlMessages id="logistic.distribution.rejection.types.table.description" />
            }
            onChange={value => setFormData("description", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.code}
            id="code-filter"
            label={
              <IntlMessages id="logistic.distribution.rejection.types.table.code" />
            }
            onChange={value => setFormData("code", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
