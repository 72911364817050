import React from "react";
import {
  TimelineConnector,
  TimelineContent,
  TimelineItem,
  TimelineSeparator
} from "@material-ui/lab";
import {Box, Card, CardContent, Paper, Typography} from "@material-ui/core";
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import {EventNote} from "@material-ui/icons";
import {HtmlTooltip} from "./styles";
import {useDispatch} from "react-redux";
import {setMonitoringCurrentWorkOrder} from "../../../../../../redux/actions/Logistic/Monitoring";

const useStyles = makeStyles({
  timelineContentContainer: {
    textAlign: "left"
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(90deg)",
    textAlign: "center",
    minWidth: 50
  },
  timelineIcon: {
    transform: "rotate(90deg)",
    cursor: "pointer"
  }
});

const DestinationDot = ({
  destination,
  last,
  setSelectedDestination,
  workOrderId,
  index
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleDestinationClick = () => {
    setSelectedDestination(index);
    dispatch(setMonitoringCurrentWorkOrder(destination.otId));
  };
  const icons = [
    {
      id: 1,
      icon: (
        <CalendarTodayIcon
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#888888", marginRight: "8px"}}
        />
      )
    },
    {
      id: 2,
      icon: (
        <EventAvailableIcon
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#4caf50", marginRight: "8px"}}
        />
      )
    },
    {
      id: 3,
      icon: (
        <EventAvailableIcon
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#ff8f00", marginRight: "8px"}}
        />
      )
    },
    {
      id: 4,
      icon: (
        <EventNote
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#ff8f00", marginRight: "8px"}}
        />
      )
    },
    {
      id: 5,
      icon: (
        <EventBusyIcon
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#ff8f00", marginRight: "8px"}}
        />
      )
    },
    {
      id: 6,
      icon: (
        <EventBusyIcon
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#4caf50", marginRight: "8px"}}
        />
      )
    },
    {
      id: 7,
      icon: (
        <EventNote
          onClick={() => handleDestinationClick()}
          className={classes.timelineIcon}
          style={{color: "#4caf50", marginRight: "8px"}}
        />
      )
    }
  ];

  return (
    <TimelineItem>
      <TimelineSeparator>
        <HtmlTooltip
          title={
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h2" component="h2">
                  {destination.name}
                </Typography>
                <Typography variant="body2" component="p">
                  {moment(destination.date).format("DD/MM/yyyy HH:mm:ss")}
                </Typography>
              </CardContent>
            </Card>
          }
        >
          {icons.find(i => i.id === destination.state).icon}
        </HtmlTooltip>
        {!last && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContentContainer}>
        <Paper className={classes.timelineContent}>
          <Typography variant="body2">
            {moment(destination.date).format("HH:mm:ss")}
          </Typography>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

export default DestinationDot;
