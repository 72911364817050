import React from 'react';
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import {useIntl} from "react-intl";
import {Paper} from "@material-ui/core";
import {minutesToDayAndTime} from "../../../../../../../utils/parsers";

const EventsByVehicle = ({eventsByVehicle, colors, isTime = false}) => {
    const intl = useIntl();
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={eventsByVehicle}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="vehicle" hide />
                <Tooltip
                    cursor={false}
                    content={data =>
                        data?.payload?.at(0) ? (
                            <Paper
                                style={{
                                    padding: "0.5rem 1rem"
                                }}
                            >
                                <p>{data?.payload[0]?.payload?.vehicle}</p>
                                {Object.keys(colors)
                                    .map((key) => (
                                        <p key={key}>{`${intl.messages[key]}: ${isTime? minutesToDayAndTime(data?.payload[0]?.payload?.[key]) : data?.payload[0]?.payload?.[key]}`}</p>
                                    ))
                                }
                            </Paper>
                        ) : null}
                />
                {Object.entries(colors)
                    .map(([key, value]) => (
                        <Bar key={key} dataKey={key} stackId="a" fill={value} />
                    ))
                }
            </BarChart>
        </ResponsiveContainer>
    );
};

export default EventsByVehicle;