import {makeStyles} from "@material-ui/core/styles";

export const useAdvanceDetailStyles = makeStyles(theme => ({
  speedDial: {
    position: "absolute",
    "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
      bottom: theme.spacing(10),
      right: theme.spacing(10)
    }
  }
}));
