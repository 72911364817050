import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MarkOptionsSelect from "../../Common/MarkOptionsSelect";
import {checklistBasicValidations} from "../../Common/validations";

const Form = ({id, onAddType}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm({
    name: "",
      markOption: ''
  }, checklistBasicValidations);
  return (
    <form id={id} onSubmit={handleSubmit(onAddType)}>
      <GridContainer style={{
          marginTop: '1px'
      }}>
        <Grid item xs={12} md={6}>
          <OutlinedInput
            id={`${id}-name`}
            value={formData.name}
            required
            label={
              <IntlMessages
                id={"logistic.distribution.checklist.types.table.form.name"}
              />
            }
            onChange={value => setFormData("name", value)}
            fullWidth
            error={errors?.name[0]}
            hasError={errors?.name?.length > 0}
          />
        </Grid>
          <Grid item xs={12} md={6}>
              <MarkOptionsSelect
                  value={formData.markOption}
                  onChange={value => setFormData("markOption", value)}
                  errorMessage={errors?.markOption[0]}
                  error={errors?.markOption?.length > 0}
                  required
              />
          </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
