import moment from "moment";
import Destination from "./Destination";
import {minutesToTime} from "../utils/parsers";

class WordOrder {
  constructor(
    id,
    type,
    description,
    startDate,
    endDate,
    driver,
    vehicles,
    destinations,
    duration,
    tract,
    hopper
  ) {
    this.id = id;
    this.type = type;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.driver = driver;
    this.vehicles = vehicles;
    this.destinations = destinations;
    this.duration = duration;
    this.tract = tract;
    this.hopper = hopper;
  }

  static map = workOrder => {
    return new WordOrder(
      workOrder.nu_id_ot,
      workOrder.vc_desc_ruta,
      workOrder.vc_desc_ot,
      moment(
        new Date(workOrder.dt_fec_ini_estimada),
        "MM/DD/YYYY hh:mm:ss"
      ).toDate(),
      moment(
        new Date(workOrder.dt_fec_fin_estimada),
        "MM/DD/YYYY hh:mm:ss"
      ).toDate(),
      workOrder.conductores.map(c => ({
        id: c.nu_id_entidad,
        name: c.vc_nombre
      })) || [],
      workOrder.vehiculos.map(c => ({
        id: c.nu_id_vehiculo || c.id,
        name: c.vc_placa || c.pl
      })),
      workOrder.destinos.map(c => Destination.map(c)),
      minutesToTime(workOrder.nu_duracion),
      workOrder.nu_id_placa,
      workOrder.nu_id_tolva
    );
  };
}

export default WordOrder;
