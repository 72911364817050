import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchDriverSelect} from "redux/actions/RRHH/HistoricalSigning";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const HistoricalSigningSelect = ({onChange}) => {
  const {filters} = useSelector(state => state.rrhh.historicalSigning);
  const [ employees, setEmployees ] = React.useState([]);
  const [ isAllSelected, setIsAllSelected ] = React.useState(false);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchDriverSelect());
  }, []);
  const handleOnChange = (e, newValue) => {
    let valuesArray = [];

    if (newValue.length > 0) {
      let filter = newValue.filter(x => x.id == "All");
      if (filter.length > 0) {
        let isAllSelectedCopy = !isAllSelected;
        if(!isAllSelectedCopy) {
          valuesArray = [];
        } else {
          valuesArray = filters.drivers;
        }
        setIsAllSelected(!isAllSelected);
      } else {
        newValue.forEach(element => {
          valuesArray.push(element);
        });
      }
    }
    setEmployees(valuesArray);
    onChange(valuesArray);
  };
  return (
    <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={[{name: "Seleccionar todo", id: "All"}, ...filters.drivers]}
      disableCloseOnSelect
      onChange={(event, newValue) => handleOnChange(event, newValue)}
      value={employees}
      limitTags={2}
      size="small"
      getOptionLabel={option => option.name}
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label="Empleados"
          style={{height: 40}}
        />
      )}
    />
  );
};

export default HistoricalSigningSelect;
