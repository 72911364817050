import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import moment from "moment";
import {editWorkshopVehicle, setCurrentWorkshopVehicle} from "../../../../../redux/actions/FleetManagement/Workshop";
import Form from "../AddWorkshopVehicleDialog/Form";

const EditWorkshopVehicleDialog = ({open, setOpen}) => {
  const id = "edit-workshop-dialog";
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.workshop
  );
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(
      editWorkshopVehicle({id: current.id, ...data}, onClose)
    );
  };
  const onClose = data => {
    setOpen(false);
    dispatch(setCurrentWorkshopVehicle(null));
  }
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.workshop.edit.title"} />}
      open={open}
      onClose={onClose}
      submitText={<IntlMessages id={"fleet.management.workshop.edit.save"} />}
      cancelText={<IntlMessages id={"fleet.management.workshop.edit.cancel"} />}
      fullScreen
    >
      <Form
        id={id}
        onSubmit={onSubmit}
        initialData={{
          vehicle: current.vehicle.id || "",
            workshop: current.workshop.id || "",
            kilometer: current.kilometer || "",
            ot: current.ot || "",
            entryDate: moment(current.entryDate).format("YYYY-MM-DD") || "",
            state: current.state.id || "",
            expectedExitDate:
            moment(current.expectedExitDate).format("YYYY-MM-DD") || "",
            exitDate: current.exitDate
            ? moment(current.exitDate).format("YYYY-MM-DD")
            : "",
            amount: current.amount || "",
            observations: current.observations || "",
            tasks: current.tasks || [],
            files: current.files || [],
        }}
      />
    </FormDialog>
  );
};

export default EditWorkshopVehicleDialog;
