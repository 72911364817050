export const RESET_VEHICLE_MAINTENANCE_PLANS_STATE =
  "RESET_VEHICLE_MAINTENANCE_PLANS_STATE";
export const FETCH_ALL_VEHICLE_MAINTENANCE_PLANS =
  "FETCH_ALL_VEHICLE_MAINTENANCE_PLANS";
export const SET_CURRENT_VEHICLE_MAINTENANCE_PLAN =
  "SET_CURRENT_VEHICLE_MAINTENANCE_PLAN";
export const EDIT_VEHICLE_MAINTENANCE_PLAN = "EDIT_VEHICLE_MAINTENANCE_PLAN";
export const EDIT_VEHICLE_MAINTENANCE_PLAN_STATE =
  "EDIT_VEHICLE_MAINTENANCE_PLAN_STATE";
export const APPLY_VEHICLE_MAINTENANCE_PLANS_FILTERS =
  "APPLY_VEHICLE_MAINTENANCE_PLANS_FILTERS";
export const RESET_VEHICLE_MAINTENANCE_PLANS_FILTERS =
  "RESET_VEHICLE_MAINTENANCE_PLANS_FILTERS";
export const OPEN_VEHICLE_MAINTENANCE_PLANS_DIALOG =
  "OPEN_VEHICLE_MAINTENANCE_PLANS_DIALOG";
export const CLOSE_VEHICLE_MAINTENANCE_PLANS_DIALOG =
  "CLOSE_VEHICLE_MAINTENANCE_PLANS_DIALOG";
