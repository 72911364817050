import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllConfigurationRoles} from "../../../../../../../redux/actions/Configuration/Roles";
import MultipleSelect from "../../../../../../Common/Forms/Select/MultipleSelect";

const RoleSelect = ({setValue, id, fullWidth, label, value}) => {
  const dispatch = useDispatch();
  const {data} = useSelector(({configuration}) => configuration.roles);

  React.useEffect(() => {
    if (Object.values(data).length < 1) {
      dispatch(fetchAllConfigurationRoles());
    }
  }, []);
  return (
    <MultipleSelect
      id={id}
      onChange={value => setValue(value)}
      value={value}
      label={label}
      fullWidth={fullWidth}
      options={Object.values(data)}
    />
  );
};

export default RoleSelect;
