import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  provider: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="logistic.inventoryReport.filters.providers.error" />
      )
    }
  ],
  startDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.date.error" />
      )
    }
  ],
  endDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.date.error" />
      )
    }
  ]
};
