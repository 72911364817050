import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

export const generalValidations = {
  firstName: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  lastName: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  birthDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  email: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  generalPhone: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  generalIButton: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  driverLicense: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ]
};

export const specificValidations = {
  internalCode: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  commencementDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  terminationDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  specificEmail: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  specificPhone: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  specificIButton: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  contract: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ]
};
