import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    activateServices,
    deleteServices,
    fetchAllServicesByDate, updateServices
} from "../../../services/Logistics/Distribution/PlannerService";

const BASE_PREFIX = 'logistic/planner';
export const getAllServicesByDate = createAsyncThunk(
    `${BASE_PREFIX}/getAllServicesByDate`,
    async (filters, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const data = await fetchAllServicesByDate(filters);
            dispatch(fetchSuccess());
            return {filters, data};
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    });

export const activateSelectedServices = createAsyncThunk(
    `${BASE_PREFIX}/activateSelectedServices`,
    async (services, {dispatch, getState, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await activateServices(services);
            dispatch(fetchSuccess(response.transactionMessage));
            const {filters} = getState()['logistic']['planner'];
            setTimeout(() => {
                dispatch(getAllServicesByDate(filters));
            }, 3000);
            return;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    });

export const removeSelectedServices = createAsyncThunk(
    `${BASE_PREFIX}/removeSelectedServices`,
    async (services, {dispatch, getState, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await deleteServices(services);
            dispatch(fetchSuccess(response.transactionMessage));
            const {filters} = getState()['logistic']['planner'];
            setTimeout(() => {
                dispatch(getAllServicesByDate(filters));
            }, 3000);
            return;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    });


export const updateSelectedServices = createAsyncThunk(
    `${BASE_PREFIX}/updateSelectedServices`,
    async ({services, request}, {dispatch, getState, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateServices(services, request);
            dispatch(fetchSuccess(response.transactionMessage));
            const {filters} = getState()['logistic']['planner'];
            setTimeout(() => {
                dispatch(getAllServicesByDate(filters));
            }, 3000);
            return;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    });




