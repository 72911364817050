import {useState} from "react";
import getComparator from "utils/table-sorting";

const useTableSort = data => {
  const [ order, setOrder ] = useState(undefined);
  const [ orderBy, setOrderBy ] = useState(undefined);

  const onSort = column => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  return {
    tableData:
      order !== undefined && orderBy !== undefined
        ? [ ...data ].sort(getComparator(order, orderBy))
        : [ ...data ],
    onSort,
    order,
    orderBy
  };
};

export default useTableSort;
