import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import useForm from 'hooks/Common/useForm';

import validations from './validations';

const Form = ({id, initialData, onSubmit}) => {
    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            description: initialData?.description || '',
            code: initialData?.code || ''
        },
        validations,
    );

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <OutlinedInput
                        value={formData.description}
                        hasError={errors.description.length > 0}
                        error={errors.description[0]}
                        fullWidth
                        id="description"
                        label={<IntlMessages id="logistic.distribution.rejection.types.form.description"/>}
                        onChange={value => setFormData('description', value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        value={formData.code}
                        hasError={errors.code.length > 0}
                        error={errors.code[0]}
                        fullWidth
                        id="code"
                        label={<IntlMessages id="logistic.distribution.rejection.types.form.code"/>}
                        onChange={value => setFormData('code', value)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;
