class AssigmentOrder {
  constructor(
    id,
    client,
    startDate,
    endDate,
    priority,
    stay,
    vehicleId,
    latitude,
    longitude,
    weight,
    volume
  ) {
    this.id = id;
    this.client = client;
    this.startDate = startDate;
    this.endDate = endDate;
    this.priority = priority;
    this.stay = stay;
    this.vehicleId = vehicleId;
    this.latitude = latitude;
    this.longitude = longitude;
    this.weight = weight;
    this.volume = volume;
  }

  static map = order => {
    return new AssigmentOrder(
      order.pedidoId || 0,
      order.vc_cliente,
      order.dt_fec_ven_ini,
      order.dt_fec_ven_fin,
      order.nu_id_optimizacion_destino_prioridad,
      order.nu_id_optimizacion_destino_estancia,
      order.nu_id_vehiculo,
      order.nu_latitud,
      order.nu_longitud,
      order.nu_carga_peso,
      order.nu_carga_volumen
    );
  };
}

export default AssigmentOrder;
