import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {fetchExpenseTypes} from "../../../../../../../../redux/actions/Administrator/ExpenseTypes";
import MultipleSelect from "../../../../../../../Common/Forms/Select/MultipleSelect";

const ExpensesTypesPicker = ({
  id,
  values,
  isLoading,
  onChange,
  error,
  hasError
}) => {
  // const [types, setTypes] = React.useState([]);
  const {data: types} = useSelector(state => state.administrator.expenseTypes);
  const dispatch = useDispatch();
  useEffect(() => {
    if (types.length < 1) {
      dispatch(fetchExpenseTypes());
    }
  }, []);
  return (
    <MultipleSelect
      id={id}
      options={types}
      value={values}
      onChange={onChange}
      fullWidth
      disabled={isLoading}
      error={error}
      hasError={hasError}
      label={<IntlMessages id={"administrator.reports.expenses.types.title"} />}
      headerText={
        <IntlMessages id="administrator.reports.expenses.types.header" />
      }
      noOptionsText={
        <IntlMessages id="administrator.reports.expenses.types.no.options" />
      }
    />
  );
};

export default ExpensesTypesPicker;
