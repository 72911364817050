import React from 'react';
import {CartesianGrid, LabelList, Line, LineChart as BaseLineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";

const LineChart =  ({data, nameKey, dataKey}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BaseLineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={nameKey} />
                <YAxis   />
                <Line type='monotone' dataKey={dataKey} activeDot={{ r: 8 }}>
                    <LabelList  position="top" dataKey={dataKey} formatter={(value) => `${value}%`}/>
                </Line>
            </BaseLineChart>
        </ResponsiveContainer>
    );
};

export default LineChart;