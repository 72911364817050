import moment from "moment";

class Advance {
  constructor(
    id,
    pettyCashId,
    driverId,
    driverName,
    box,
    tags,
    createDate,
    lastUpdate,
    travel,
    route,
    amount,
    expense,
    passed,
    balance,
    stateId,
    stateDescription,
    routeId,
    typeId,
    routeCode,
    advanceAmount,
    extension,
    refund,
  ) {
    this.id = id;
    this.box = {
      id: pettyCashId,
      name: box
    };
    this.driver = {
      id: driverId,
      name: driverName
    };
    this.tags = tags;
    this.createDate = createDate;
    this.lastUpdate = lastUpdate;
    this.travel = travel;
    this.route = {
      id: routeId,
      description: travel
    };
    this.amount = amount;
    this.expense = expense;
    this.passed = passed;
    this.balance = balance;
    this.state = {
      id: stateId,
      description: stateDescription
    };
    this.typeId = typeId;
    this.routeDescription = route;
    this.routeCode = routeCode;
    this.advanceAmount = advanceAmount;
    this.extension = extension;
    this.refund = refund;
  }

  static map = advance => {
    return new Advance(
      parseInt(advance.nu_id_anticipo),
      advance.nu_id_caja_chica,
      advance.nu_id_entidad_conductor,
      advance.vc_nombre_conductor,
      advance.vc_nombre_caja,
      advance.vc_desc_tag.split(","),
      moment(advance.dt_fec_creacion, "MM/DD/YYYY hh:mm:ss A").toDate(),
      advance.dt_fec_modificacion &&
        moment(advance.dt_fec_modificacion, "MM/DD/YYYY hh:mm:ss A").toDate(),
      advance.vc_viaje,
      advance.vc_ruta,
      parseFloat(advance.nu_importe_total),
      parseFloat(advance.nu_importe_gasto_total),
      parseFloat(advance.nu_importe_gasto_aprobado),
      parseFloat(advance.nu_saldo),
      parseInt(advance.nu_id_estado_anticipo),
      advance.vc_desc_estado_anticipo,
      !advance.nu_id_ot || isNaN(advance.nu_id_ot)? null : parseInt(advance.nu_id_ot),
      advance.nu_id_tipo_anticipo,
        advance.vc_cod_ruta,
        +advance.nu_importe,
        +advance.nu_importe_ampliacion,
        +advance.nu_importe_devolucion,
    );
  };
}

export default Advance;
