import {useDispatch, useSelector} from "react-redux";

import uiActions from "redux/actions/Administrator/ui";

const useUiState = () => {
  const dispatch = useDispatch();
  const {
    deleteConfirmationIsOpen,
    restoreConfirmationIsOpen,
    editFormIsOpen,
    createFormIsOpen,
    deletingId,
    restoringId,
    editingId,
    isLoadingData,
    isChangingState,
    isEditing,
    isCreating
  } = useSelector(state => state.administrator.ui);

  const openDeleteConfirmation = id =>
    dispatch(uiActions.openDeleteConfirmation(id));
  const closeDeleteConfirmation = () =>
    dispatch(uiActions.closeDeleteConfirmation());

  const openRestoreConfirmation = id =>
    dispatch(uiActions.openRestoreConfirmation(id));
  const closeRestoreConfirmation = () =>
    dispatch(uiActions.closeRestoreConfirmation());

  const openEditForm = id => dispatch(uiActions.openEditForm(id));
  const closeEditForm = () => dispatch(uiActions.closeEditForm());

  const openCreateForm = () => dispatch(uiActions.openCreateForm());
  const closeCreateForm = () => dispatch(uiActions.closeCreateForm());

  return {
    editingId: editingId,
    deletingId: deletingId,
    restoringId: restoringId,
    isLoadingData: isLoadingData,
    showLoadingIndicator:
      isLoadingData || isChangingState || isEditing || isCreating,
    tableProps: {
      onEdit: openEditForm,
      onDelete: openDeleteConfirmation,
      onRestore: openRestoreConfirmation
    },
    createFormProps: {isOpen: createFormIsOpen, onClose: closeCreateForm},
    editFormProps: {isOpen: editFormIsOpen, onClose: closeEditForm},
    deleteConfirmationProps: {
      open: deleteConfirmationIsOpen,
      onReject: closeDeleteConfirmation
    },
    restoreConfirmationProps: {
      open: restoreConfirmationIsOpen,
      onReject: closeRestoreConfirmation
    },
    floatingButtonProps: {onCreate: openCreateForm}
  };
};

export default useUiState;
