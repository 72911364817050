import React from 'react';
import Form from "./Form";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const EditProductQuantityDialog = ({open, onSubmit, initialQuantity, onClose}) => {
    const id = "product-quantity-form";
    return (
        <FormDialog
            id={id}
            isOpen={open}
            title={
                <span>
                    <IntlMessages id="edit" />{' '}<IntlMessages id="product.quantity" />
                </span>
            }
            onClose={onClose}
            PaperProps={{style: {minWidth: 400}}}
        >
            {open &&
                <Form
                    id={id}
                    onSubmit={onSubmit}
                    initialQuantity={initialQuantity}
                />
            }
        </FormDialog>
    );
};

export default EditProductQuantityDialog;