import axiosInstance from "../../../utils/axiosInstance";
import {readerTypes} from "../../../components/FleetManagement/Tachograph/FilesReader/constants";
import {uploadDocumentToS3} from "../../aws/s3Services";
export const uploadFile = async file => {
    try {
        const {data} = await axiosInstance.post(
            '/Documentos/ins_fichero_tacografo_lectura',
            {},
            {
                params: {
                    vc_filename: file.name,
                }
            }
        );
        if(data) {
            await uploadDocumentToS3(data, file.file);
        }
        return file.name;
    }catch (e) {

    }
}

const getDataStructure = (type, data) => {
    const parseStructure = {
        [readerTypes.driver]: () => {
            const cardInfo = data.IdentificacionTitularTarjeta;
            const events = data.Sucesos;
            const activities = data.Actividades;
            const activitiesSummary = data.ResumenTotalActividades;
            const usedVehicles = data.VehiculosUtilizados;
            const places = data.Lugares;
            const activityControl = data.ControlDatosActividad;
            const specificConditions = data.CondicionesEspecificas;
            const frontierCrosses = data.CrucesFrontera?.map(cross => ({
                DireccionUrl: cross?.PosicionEntrada?.DireccionUrl,
                Fecha: cross?.PosicionEntrada?.Fecha,
                ...cross,
            }));
            const loadUnloadOperations = data.OperacionesCargaDescarga?.map(operation => ({
                ...operation,
                DireccionUrl: operation?.PosicionEntrada?.DireccionUrl,
                Fecha: operation?.PosicionEntrada?.Fecha,
            }));
            const deliveryLoadTypes = data.TipoEntradasCarga;
            return {
                cardInfo,
                events,
                activities,
                activitiesSummary,
                usedVehicles,
                places,
                activityControl,
                specificConditions,
                frontierCrosses,
                loadUnloadOperations,
                deliveryLoadTypes,
            };
        },
        [readerTypes.vehicle]: () => {
            const summary = data.Resumen;
            const activities = data.Actividades;
            const eventsAndFails = data.EventosYFallos;
            const technicalData = data.DatosTecnicos;
            const detailedSpeeds = data.VelocidadesDetalladas;
            return {
                summary,
                activities: activities?.map(activity => ({
                    ...activity,
                    CrucesFrontera: activity?.CrucesFrontera?.map(cross => ({
                        ...cross,
                        DireccionUrl: cross?.PosicionEntrada?.DireccionUrl,
                        Fecha: cross?.PosicionEntrada?.Fecha,
                    })),
                    OperacionesCargaDescarga: activity?.OperacionesCargaDescarga?.map(operation => ({
                        ...operation,
                        DireccionUrl: operation?.PosicionEntrada?.DireccionUrl,
                        Fecha: operation?.PosicionEntrada?.Fecha,
                    })),
                })),
                eventsAndFails,
                detailedSpeeds,
                technicalData,
            };
        },
    };
    return parseStructure[type]();
}

export const getFileData = async (fileName) => {
    try {
        const {data} = await axiosInstance.get("/Tacografo_Fichero/parse", {
            params: {
                fileName
            },
        });
        return {
            type: data.tipo,
            data: getDataStructure(data.tipo, JSON.parse(data.datos)),
            fileName,
        };
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getReport = async (fileName, fullReport) => {
    try {
        await axiosInstance.get("/Tacografo_Fichero/report", {
            params: {
                fileName,
                fullReport,
            },
        });
        return;
    } catch (e) {
        return Promise.reject(e);
    }
}