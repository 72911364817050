import ui from "./ui";
import {
  cancelPrevWarehouseOriginSelectFetchAll,
  fetchAllWarehouseOriginSelect
} from "../../../services/WarehouseOriginSelectService";
import {FETCH_WAREHOUSE_ORIGIN} from "../../types/Administrator/WarehouseOriginSelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevWarehouseOriginSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchWarehouseOriginSelectSucceeded = data => ({
  type: FETCH_WAREHOUSE_ORIGIN,
  payload: {
    data
  }
});

export const fetchDataWarehouseOrigin = (idEntity, idCampus) => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevWarehouseOriginSelectFetchAll();
    fetchAllWarehouseOriginSelect(idEntity, idCampus).then(warehouseSelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchWarehouseOriginSelectSucceeded(warehouseSelect));
    });
  };
};

export default {
  get: fetchDataWarehouseOrigin,
  resetState
};
