import React from 'react';
import PropTypes from 'prop-types';

import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import IntlMessages from '@jumbo/utils/IntlMessages';
import useForm from 'hooks/Common/useForm';

import validations from './validations';
import CostCenterSelect from "../../../../../Common/Forms/Select/CostCenterSelect";

const Form = ({ id, initialData, onSubmit, disabled }) => {
  const { formData, errors, setFormData, handleSubmit } = useForm(
    {
      fund: initialData?.fund?.toString() ?? '',
      costCenter: initialData?.costCenterId ?? ''
    },
    validations,
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <OutlinedInput
            type="number"
            value={formData.fund}
            hasError={errors.fund.length > 0}
            error={errors.fund[0]}
            fullWidth
            id="fund"
            disabled={disabled}
            label={<IntlMessages id="administrator.cashManagers.editCashManagerDialog.field.fund" />}
            onChange={value => setFormData('fund', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CostCenterSelect
              value={formData.costCenter}
              hasError={errors?.costCenter?.length > 0}
              error={errors.costCenter[0]}
              onChange={value => setFormData("costCenter", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

Form.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    fund: PropTypes.number,
  }),
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

Form.defaultProps = {
  initialData: {
    fund: null,
  },
  onSubmit: () => {},
  disabled: false,
};

export default Form;
