import React, {useEffect, useState} from "react";
import FiltersContainer from "components/Common/Forms/FiltersContainer";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";

import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import CheckStockSelect from "../../../../Common/Forms/Select/CheckStockSelect";
import CheckStockTable from "../CheckStockTable";
import {getUserType} from "../../../../../services/StorageService";
import {setCurrentAdvance} from "../../../../../redux/actions/Guardians/CurrentAdvance";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../hooks/Common/useForm";
import CheckStockStates from "../../../../../models/Logistic/CheckStockStates";
import {fetchAllAdvancesStatesStarted} from "../../../../../redux/actions/Guardians/AdvancesStates";

const initState = {
  state: CheckStockStates.SELECT,
  product: "",
  entity: "",
  route: "",
  box: ""
};

const CheckStockFilters = ({applyFilters, onClose}) => {
  const {state, active, box} = useSelector(
    state1 => state1.guardian.advancesListFilter
  );
  const {formData, setFormData, resetForm} = useForm(initState);
  const {data, list} = useSelector(
    state => state.guardian.advancesList
  );
  const {currentAdvance} = useSelector(state => state.guardian);
  const [ tableData, setTableData ] = useState(data);
  const dispatch = useDispatch();
  const [ openDialog, setOpenDialog ] = useState(false);
  useEffect(
    () => {
      if (data.length === 0) {
        dispatch(fetchAllAdvancesStatesStarted());
      }
    },
    [ ]
  );
  useEffect(
    () => {
      setTableData(data);
    },
    [ data ]
  );
  useEffect(
    () => {
      applyFilters(formData);
    },
    [ applyFilters, formData ]
  );

  useEffect(
    () => {
      if (active && data.length > 0) {
        setFormData(
          "state",
          data.filter(s => s.description.toLowerCase() === state)[0].id
        );

        setFormData("box", box);
      }
    },
    [ data ]
  );

  const handleClose = () => {
    resetForm();
    onClose();
  };
  const handleSelectAdvance = advance => {
    dispatch(setCurrentAdvance(advance));
    setOpenDialog(true);
  };
  useEffect(
    () => {
      if (currentAdvance.id !== "") {
        dispatch(
          setCurrentAdvance(
            list.find(advance => currentAdvance.id === advance.id)
          )
        );
        setOpenDialog(true);
      }
    },
    [ list ]
  );
  const id = [ {Producto: 1002, Cliente: 2002} ];
  return (
    <div>
      <FiltersContainer
        onReset={handleClose}
        marginBottom={5}
        active={active}
        onClose={handleClose}
      >
        <form>
          <GridContainer spacing={4}>
            <Grid item xs={12} sm={4} lg={3}>
              <CheckStockSelect
                data={formData.state}
                value={id}
                onChange={value => setFormData("state", value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <OutlinedInput
                fullWidth
                value={formData.driver}
                id="product"
                label={<IntlMessages id="logistic.table.product" />}
                onChange={value => setFormData("product", value)}
              />
            </Grid>

            {/*<Grid item xs={12} sm={4} lg={3}>*/}
            {/*  <OutlinedInput*/}
            {/*    fullWidth*/}
            {/*    value={formData.box}*/}
            {/*    id="box"*/}
            {/*    label={<IntlMessages id="guardian.advances.table.box" />}*/}
            {/*    onChange={value => setFormData('box', value)}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12} sm={4} lg={3}>
              <OutlinedInput
                fullWidth
                value={formData.travel}
                id="entity"
                label={<IntlMessages id="logistic.table.entity" />}
                onChange={value => setFormData("entity", value)}
              />
            </Grid>
          </GridContainer>
        </form>
      </FiltersContainer>
      <CheckStockTable
        orderGrouped={formData.state}
        drivers={tableData}
        setSelectedAdvance={handleSelectAdvance}
        isGuardian={getUserType().includes("guardian")}
      />
    </div>
  );
};

export default CheckStockFilters;
