import React from "react";
import {Card, CardContent} from "@material-ui/core";
import AddExpensesForm from "../../../../components/Guardian/AddExpense/Form";
import PageContainer from "../../../../components/Guardian/AddExpense/PageContainer";

const AddExpenses = () => {
  return (
    <PageContainer>
      <Card
        style={{
          paddingTop: "24px"
        }}
      >
        <CardContent>
          <AddExpensesForm />
        </CardContent>
      </Card>
    </PageContainer>
  );
};

export default AddExpenses;
