export const RESET_ELEVATORS_DETAILS_STATE = "RESET_ELEVATORS_DETAILS_STATE";
export const FETCH_ALL_ELEVATORS_DETAIL = "FETCH_ALL_ELEVATORS_DETAIL";
export const SET_CURRENT_ELEVATOR_DETAIL = "SET_CURRENT_ELEVATOR_DETAIL";
export const RESET_CURRENT_ELEVATOR_DETAIL = "RESET_CURRENT_ELEVATOR_DETAIL";
export const EDIT_ELEVATOR_DETAIL = "EDIT_ELEVATOR_DETAIL";
export const ADD_ELEVATOR_DETAIL = "ADD_ELEVATOR_DETAIL";
export const APPLY_ELEVATORS_DETAILS_FILTERS =
  "APPLY_ELEVATORS_DETAILS_FILTERS";
export const RESET_ELEVATORS_DETAILS_FILTERS =
  "RESET_ELEVATORS_DETAILS_FILTERS";
