export const FETCH_ALL_ADVANCES_BY_GUARDIAN_SUCCEEDED =
  "FETCH_ALL_ADVANCES_BY_GUARDIAN_SUCCEEDED";
export const APPLY_ADVANCES_LIST_FILTERS = "APPLY_ADVANCES_LIST_FILTERS";
export const RESET_ADVANCES_LIST_FILTERS = "RESET_ADVANCES_LIST_FILTERS";

export const FETCH_ALL_ADVANCES_STATES_SUCCEEDED =
  "FETCH_ALL_ADVANCES_STATES_SUCCEEDED";
export const SET_ADVANCES_RESUME = "SET_ADVANCES_RESUME";
export const SET_ADVANCES_LIST_WITHOUT_ORDER =
  "SET_ADVANCES_LIST_WITHOUT_ORDER";

export const FETCH_CURRENT_ADVANCE_EXPENSES_STARTED =
  "FETCH_CURRENT_ADVANCE_EXPENSES_STARTED";
export const FETCH_CURRENT_ADVANCE_EXPENSES_SUCCEEDED =
  "FETCH_CURRENT_ADVANCE_EXPENSES_SUCCEEDED";

export const FETCH_ALL_EMPLOYEES_BY_ENTITY_SUCCEEDED =
  "FETCH_ALL_EMPLOYEES_BY_ENTITY_SUCCEEDED";

export const LIQUIDATE_ADVANCE_STARTED = "LIQUIDATE_ADVANCE_STARTED";
export const LIQUIDATE_ADVANCE_SUCCESS = "LIQUIDATE_ADVANCE_SUCCESS";

export const ADD_ADVANCE_ERROR = "ADD_ADVANCE_ERROR";
