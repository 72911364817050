import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeDialog, openDialog} from "../../../redux/reducers/Treasury/creditNotes";
import Box from "@material-ui/core/Box";
import ContainedButton from "../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import {dialogsList} from "./constants";
import AddForm from "./AddForm";
import {createCreditNote} from "../../../redux/thunks/Treasury/creditNotes";

const AddDialog = () => {
    const id = 'add-credit-note-form';
    const dialogs = useSelector(({treasury}) => treasury.creditNotes.dialogs);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.ADD_CREDIT_NOTE);
    const handleAddDialog = () => {
        dispatch(openDialog(dialogsList.ADD_CREDIT_NOTE));
    };
    const onSubmit = (request) => {
        dispatch(createCreditNote({request}));
        onClose();
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.ADD_CREDIT_NOTE));
    }
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 16,
                }}
            >
                <ContainedButton
                    text={<IntlMessages id="add"/>}
                    color="primary"
                    onClick={handleAddDialog}
                />
            </Box>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="add"/>}
                PaperProps={{
                    style: {
                        minHeight: '50vh',
                    }
                }}
            >
                {open && <AddForm id={id} onSubmit={onSubmit} />}
            </FormDialog>
        </>
    );
};

export default AddDialog;