import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import ActionsSelect from "./ActionsSelect";
import OutlinedDatePicker from "components/Common/Forms/OutlinedDatePicker";
import {mapDateToOutput, mapDateToValue} from "utils/parsers";

const initialData = {
  fromDate: null,
  toDate: null,
  cashManager: "",
  action: "",
  amount: "",
  tag: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.fromDate)}
            id="administrator-log-history-fromDate-filter"
            label={
              <IntlMessages id="administrator.logHistory.filters.fromDate" />
            }
            onChange={value => setFormData("fromDate", mapDateToOutput(value))}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.toDate)}
            id="administrator-log-history-toDate-filter"
            label={
              <IntlMessages id="administrator.logHistory.filters.toDate" />
            }
            onChange={value => setFormData("toDate", mapDateToOutput(value))}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.cashManager}
            id="log-history-cash-manager-filter"
            label={
              <IntlMessages id="administrator.logHistory.filters.cashManager" />
            }
            onChange={value => setFormData("cashManager", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <ActionsSelect
            selected={formData.action}
            onChange={value => setFormData("action", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.amount}
            id="log-history-amount-filter"
            label={
              <IntlMessages id="administrator.logHistory.filters.amount" />
            }
            onChange={value => setFormData("amount", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.tag}
            id="log-history-tag-filter"
            label={<IntlMessages id="administrator.logHistory.filters.tag" />}
            onChange={value => setFormData("tag", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
