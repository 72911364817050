import React from "react";
import {Timeline} from "@material-ui/lab";
import DestinationDot from "./DestinationDot";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  timeline: {
    transform: "rotate(-90deg)"
  }
});

const DestinationsTimeLine = ({
  destinations,
  setSelectedDestination,
  workOrderId
}) => {
  const classes = useStyles();
  return (
    <Timeline className={classes.timeline} align="alternate">
      {destinations.map((destination, index) => (
        <DestinationDot
          workOrderId={workOrderId}
          setSelectedDestination={setSelectedDestination}
          key={index}
          last={index === destinations.length - 1}
          index={index}
          destination={destination}
        />
      ))}
    </Timeline>
  );
};
export default DestinationsTimeLine;
