import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import DataCollection from "../domain/DataCollection";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

const dataCollection = [
  {
    nu_id_data_collection: 1,
    nu_id_vehicle: 1,
    vc_desc_vehicle: "BCA-200",
    vc_desc_start: "22/11/21 12:45",
    vc_desc_end: "22/11/21 15:30",
    nu_id_route: 3,
    vc_desc_route: "S3",
    nu_id_turn: 1,
    vc_desc_turn: "Recojo 1",
    vc_desc_passengers: "4",
    vc_desc_firstPickUp: "22/11/21 12:53",
    vc_desc_lastPickUp: "22/11/21 14:20",
    vc_desc_disinfection: "22/11/21 17:00",
    bi_estado: "1"
  },
  {
    nu_id_data_collection: 2,
    nu_id_vehicle: 2,
    vc_desc_vehicle: "BCA-202",
    vc_desc_start: "22/11/21 10:45",
    vc_desc_end: "22/11/21 14:30",
    nu_id_route: 2,
    vc_desc_route: "S2",
    nu_id_turn: 1,
    vc_desc_turn: "Recojo 1",
    vc_desc_passengers: "7",
    vc_desc_firstPickUp: "22/11/21 10:53",
    vc_desc_lastPickUp: "22/11/21 12:20",
    vc_desc_disinfection: "22/11/21 16:00",
    bi_estado: "1"
  },
  {
    nu_id_data_collection: 3,
    nu_id_vehicle: 3,
    vc_desc_vehicle: "BCS-214",
    vc_desc_start: "22/11/21 14:45",
    vc_desc_end: "22/11/21 16:30",
    nu_id_route: 1,
    vc_desc_route: "S1",
    nu_id_turn: 2,
    vc_desc_turn: "Recojo 2",
    vc_desc_passengers: "",
    vc_desc_firstPickUp: "22/11/21 14:53",
    vc_desc_lastPickUp: "22/11/21 16:20",
    vc_desc_disinfection: "22/11/21 17:00",
    bi_estado: "1"
  },
  {
    nu_id_data_collection: 4,
    nu_id_vehicle: 4,
    vc_desc_vehicle: "FVG-154",
    vc_desc_start: "22/11/21 10:45",
    vc_desc_end: "22/11/21 12:30",
    nu_id_route: 2,
    vc_desc_route: "S2",
    nu_id_turn: 4,
    vc_desc_turn: "Recojo 4",
    vc_desc_passengers: "",
    vc_desc_firstPickUp: "22/11/21 10:50",
    vc_desc_lastPickUp: "22/11/21 11:50",
    vc_desc_disinfection: "22/11/21 13:00",
    bi_estado: "1"
  }
];

export const cancelPrevDataCollectionFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllDataCollection = async () => {
  //return dataCollection.map(dataCollection => DataCollection.map(dataCollection));
  try {
    const response = await axiosInstance.post(
      "/Ruta_Mina/sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(data => DataCollection.map(data));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createDataCollection = async data => {
  return 1;
};

export const updateDataCollection = async (id, data, first, last, desc) => {
  //console.log("DATA: ", data);
  //console.log("FIRST: ", first);
  //console.log("LAST: ", last);
  //console.log("DESC: ", desc);
  //return id;
  try {
    console.log("SE ENVIA: ", id);
    const response = await axiosInstance.post("/Ruta_Mina/upd", {
      nu_id_viaje: id,
      nu_id_vehiculo: data.idVehicel,
      //dt_fec_ini_real: data.start,
      //dt_fec_fin_real: data.end,
      nu_id_ruta: data.idRoute,
      nu_id_turno: data.idTurn,
      nu_id_pasajeros: data.passengers,
      dt_fec_ini_recogida: first,
      dt_fec_fin_recogida: last,
      dt_fec_ini_desifeccion: desc
    });
    console.log("SEGUNDO EDIT: ", response.data);
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeDataCollection = async id => {
  return id;
};
