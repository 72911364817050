import React from "react";
import {useDispatch} from "react-redux";
import {Paper, Table, TableBody, TableContainer} from "@material-ui/core";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import {deleteTagStarted} from "../../../../../redux/actions/Administrator/Tags";

const TagsTable = ({tags, onOpenEditDialog}) => {
  const dispatch = useDispatch();
  const onEdit = tag => {
    onOpenEditDialog(tag);
  };
  const onDelete = tag => {
    dispatch(deleteTagStarted(tag.id));
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "90%",
        margin: "36px 40px"
      }}
    >
      <Table>
        <TableHeader />
        <TableBody>
          {tags.map(tag => (
            <TableRow
              key={tag.id}
              tag={tag}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TagsTable;
