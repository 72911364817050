import React from "react";
import {Box, Typography} from "@material-ui/core";
import FilesTable from "./FilesTable";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Button from "@material-ui/core/Button";
import FilesDialog from "./FilesDialog";

const FilesArea = ({files, setFiles, id, setDeleteFiles}) => {
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  return (
    <Box display="flex" flexDirection="column" mb={4}>
      <Box>
        <Typography variant="h2">
          <IntlMessages id={"rrhh.employees.form.files"} />
        </Typography>
      </Box>
      <Box my={4}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddDialog(true)}
        >
          <IntlMessages id={"rrhh.employees.form.files.add"} />
        </Button>
      </Box>
      {files.length > 0 && (
        <FilesTable
          files={files}
          setFiles={setFiles}
          setDeleteFiles={setDeleteFiles}
        />
      )}
      {openAddDialog && (
        <FilesDialog
          listFiles={files}
          setListFiles={setFiles}
          id={id}
          open={openAddDialog}
          setOpen={setOpenAddDialog}
        />
      )}
    </Box>
  );
};

export default FilesArea;
