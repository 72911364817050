import React, {useEffect} from "react";
import {Alert} from "@material-ui/lab";
import BaseAlert from "../../../../Common/SnackBars/BaseAlert";
import {useDispatch, useSelector} from "react-redux";
import {resetOnError} from "../../../../../redux/actions/Administrator/ui";

const ErrorAlert = () => {
  const dispatch = useDispatch();
  const {message, error} = useSelector(state => state.administrator.ui.onError);
  const [ openAlert, setOpenAlert ] = React.useState(false);
  useEffect(
    () => {
      if (!openAlert) {
        dispatch(resetOnError());
      }
    },
    [ openAlert ]
  );
  useEffect(
    () => {
      if (error) {
        setOpenAlert(true);
      }
    },
    [ error ]
  );
  return (
    <BaseAlert setOpen={setOpenAlert} open={openAlert}>
      <Alert onClose={() => setOpenAlert(false)} severity="error">
        {message}
      </Alert>
    </BaseAlert>
  );
};

export default ErrorAlert;
