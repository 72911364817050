import moment from "moment";
import _ from "lodash";
import {minutesToTime} from "./parsers";

export const PARTIAL_ISO_DATETIME_FORMAT = "YYYY-MM-DDTHH:mm";
export const formatDateFromTimeAndDateString = (date, time, toISO = false) => {
  const newDate = moment(date, "YYYY-MM-DD");
  const timeArray = time.split(":");
  newDate.set("hour", timeArray[0]);
  newDate.set("minute", timeArray[1]);
  if (toISO) {
    return newDate.toISOString();
  }
  return newDate.format("YYYY-MM-DD HH:mm");
};

export const parseDateFromInput = date => {
  if (!date) {
    return "";
  }
  return moment(date, "YYYY-MM-DD").toISOString();
};
export const parseDateAndEndOfTimeFromInput = date => {
  if (!date) {
    return "";
  }
  return moment(date, "YYYY-MM-DD")
    .set({
      hour: "23",
      minute: "59",
      second: "59"
    })
    .toISOString();
};
export const parseDateToInput = date => {
  console.log(date);
  if (!date) {
    return "";
  }
  return moment(date).format("YYYY-MM-DD");
};

export const parseDateTimeToInput = (date, initial=true) => {
  if (!date) {
    return "";
  }
  if(initial) {
    return moment(date).hour(0).minute(0).format("YYYY-MM-DDTHH:mm")
  }
  return moment(date).format("YYYY-MM-DDTHH:mm");
};

export const calculateDaysOfDifferenceBetweenDates = (date1, date2) => {
  if (!date1 || !date2) {
    return "";
  }

  return Math.ceil(
    Math.abs(new Date(date1).getTime() - new Date(date2).getTime()) / 86400000
  );
};

export const parseOnlyDateFromInput = date => {
  if (!date) {
    return "";
  }
  return moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
};

export const parseLocalStringDateToGeneralData = date => {
  if (!date) {
    return "";
  }
  return new Date(date).toLocaleString();
};

export const parseDateToDateString = date => {
  if (!date) {
    return "";
  }
  return moment(new Date(date)).format("DD/MM/YYYY");
};

export const addDaysToDate = (date, days) => {
  const newDate = _.clone(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const parseDateTimeToUtc = date => {
  if (!date) {
    return "";
  }
  return new Date(date).toISOString();
};

export const dateIsOverflowed = date => {
  if (!date) {
    return "";
  }
  return Math.floor(moment().startOf('day').diff(new Date(date)) / 86400000);
};

export const differenceBetweenDatesAndTimes = (
  startDate,
  endDate,
  startTime,
  endTime
) => {
  const start = new moment(
    formatDateFromTimeAndDateString(startDate, startTime)
  );
  const end = new moment(formatDateFromTimeAndDateString(endDate, endTime));
  return minutesToTime(end.diff(start, "minutes"));
};

export const parseDateToGlobalFormat = date => {
  if(!date) return '';
  return moment(new Date(date)).format("DD-MM-YYYY HH:mm");
};

export const parseDateToGlobalFormatWithSeconds = date => {
  if(!date) return '';
  return moment(new Date(date)).format("DD-MM-YYYY HH:mm:ss");
};

export const parseGlobalDateToUtc = date => {
  if(!date) return '';
  return moment(date, "DD/MM/YY HH:mm:ss").toISOString();
}

export const parseDateToGlobalFormatWithSimpleYear = date => {
  if (!date) {
    return "";
  }
  return moment(new Date(date)).format("DD/MM/YY HH:mm");
};

export const parseDateToGlobalMeridianFormat = date => {
  return moment(new Date(date)).format("DD-MM-YYYY hh:mm A");
};

export const differenceBetweenDates = (startDate, endDate, units = "minutes") => {
  const start = new moment(startDate);
  const end = new moment(endDate);
  return end.diff(start, units);
}

export const getExpiredByDateAndDays = (expirationDate, noticeDays) =>{
  if(dateIsOverflowed(moment(expirationDate).startOf('day')) > 0 ) {
    return 3;
  }
  const differenceDays = differenceBetweenDates(new Date().toISOString(), expirationDate, 'days');
  if( differenceDays >= 0 && differenceDays <= noticeDays) {
    return 2;
  }
  return 0;
}

export const dateIsBetweenTwoDates = (date, startDate, endDate) => {
  const differenceStart = differenceBetweenDates(startDate, date, 'minutes');
  const differenceEnd = differenceBetweenDates(endDate, date, 'minutes');
  return differenceStart >= 0 && differenceEnd <= 0;
}

export const getTimeFromDate = (date) => {
  try {
    if(!date) return '-';
    return moment(date).format('HH:mm');
  }catch (e) {
    return '-';
  }
}