class DocumentType {
  constructor(id, code, description, state) {
    this.id = id;
    this.code = code;
    this.description = description;
    this.state = state;
    this.name = description;
  }

  static map = documentType => {
    return new DocumentType(
      documentType.nu_id_tipo_documento,
      documentType.vc_cod_tipo_documento,
      documentType.vc_desc_tipo_documento,
      documentType.bi_estado
    );
  };
}

export default DocumentType;
