import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="elevators.management.elevators.types.form.fields.error" />
      )
    }
  ]
};
