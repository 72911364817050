import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {CenterGrid, RightGrid, StyledSubtitle, StyledTitle} from "./styles";
import AddressMap from "./AddressMap";

const AddressArea = ({formData, setFormData, errors=null, edit}) => {
    const [showMap, setShowMap] = React.useState(false);
    return (
        <GridContainer spacing={4}>
            <Grid item sm={12} md={3}>
               <StyledTitle variant="h2"><IntlMessages id={"logistic.work.orders.dialog.destination.address.title"}/></StyledTitle>
            </Grid>
            <Grid item sm={6} md={6}>
                {edit && <Button><IntlMessages id={"logistic.work.orders.dialog.destination.verify.map"}/></Button>}
            </Grid>
            <RightGrid item sm={6} md={3} >
                <Button
                    onClick={() => setShowMap(!showMap)}
                    variant="outlined" >
                    <IntlMessages id={"logistic.work.orders.dialog.destination.see.map"}/>
                </Button>
            </RightGrid>
            <Grid item sm={12} style={showMap ? {marginRight: "12px"} : {}}>
                {showMap && <AddressMap latitude={Number(formData.latitude)} longitude={Number(formData.longitude)} name={formData.name} />}
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedInput
                    id="destination-street"
                    value={formData.street}
                    onChange={value => setFormData('street', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.street?.length > 0}
                    error={errors?.street[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.street.label"}/>}
                />
            </Grid>
            <Grid item sm={4} md={2}>
                <OutlinedInput
                    id="destination-number"
                    value={formData.number}
                    onChange={value => setFormData('number', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.number?.length > 0}
                    error={errors?.number[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.number.label"}/>}
                />
            </Grid>
            <Grid item sm={4} md={2}>
                <OutlinedInput
                    id="destination-door"
                    value={formData.door}
                    onChange={value => setFormData('door', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.door?.length > 0}
                    error={errors?.door[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.door.label"}/>}
                />
            </Grid>
            <Grid item sm={4} md={2}>
                <OutlinedInput
                    id="destination-postal-code"
                    value={formData.postalCode}
                    onChange={value => setFormData('postalCode', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.postalCode?.length > 0}
                    error={errors?.postalCode[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.postal.code.label"}/>}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedInput
                    id="destination-district"
                    value={formData.district}
                    onChange={value => setFormData('district', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.district?.length > 0}
                    error={errors?.district[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.district.label"}/>}
                />
            </Grid>
            <Grid item sm={12} md={6}>
                <OutlinedInput
                    id="destination-province"
                    value={formData.province}
                    onChange={value => setFormData('province', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.province?.length > 0}
                    error={errors?.province[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.province.label"}/>}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <StyledSubtitle variant="h6"><IntlMessages id={"logistic.work.orders.dialog.destination.gps.title"}/></StyledSubtitle>
            </Grid>
            <Grid item sm={6} md={3}>
                <OutlinedInput
                    id="destination-latitude"
                    value={formData.latitude}
                    onChange={value => setFormData('latitude', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.latitude?.length > 0}
                    error={errors?.latitude[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.latitude.label"}/>}
                />
            </Grid>
            <Grid item sm={6} md={3}>
                <OutlinedInput
                    id="destination-longitude"
                    value={formData.longitude}
                    onChange={value => setFormData('longitude', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.longitude?.length > 0}
                    error={errors?.longitude[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.longitude.label"}/>}
                />
            </Grid>
            <CenterGrid item sm={6} md={3}>
                {edit && <Button><IntlMessages id={"logistic.work.orders.dialog.destination.verify"}/></Button>}
            </CenterGrid>
        </GridContainer>
    );
}

export default AddressArea;