import React from 'react';
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import useForm from "../../../../../../hooks/Common/useForm";
import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import {editVehicleTechnicalSheet} from "../../../../../../redux/actions/FleetManagement/TechnicalSpecification";
import validations from "./validations";

const TechnicalSheet = ({vehicle}) => {
    const [edit, setEdit] = React.useState(false);
    const dispatch = useDispatch();
    const {current} = useSelector(({fleetManagement}) => fleetManagement.technicalSpecification);
    const {formData, setFormData, resetForm, handleSubmit} = useForm({
        engine: vehicle?.engine || '',
        bodywork: vehicle?.bodywork || '',
        cylinder: vehicle?.cylinder || '',
        power: vehicle?.power || '',
        fuel: vehicle?.fuel || '',
        volume: vehicle?.volume || '',
        axles: vehicle?.axles || '',
        passengers: vehicle?.passengers || '',
        color: vehicle?.color || '',
        door: vehicle?.door || '',
        speed: vehicle?.speed || '',
        seat: vehicle?.seat || '',
        radius: vehicle?.radius || '',
        key: vehicle?.key || '',
        control: vehicle?.control || '',
        extras: vehicle?.extras || '',
        state: vehicle?.state || '',
        observations: vehicle?.observations || '',
    }, validations);
    const onSubmit = (data) => {
        dispatch(editVehicleTechnicalSheet(current, data, () => setEdit(false), onCancel));

    }
    const onCancel = () => {
        resetForm();
        setEdit(false);
    }
    return (
        <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" style={{backgroundColor: "#3f51b5"}} py={4}>
                <Box mx={4}>
                    <Typography variant="h2" style={{color: "white"}}>
                        <IntlMessages id="fleet.management.vehicles.specifications.details.technical.title"/>
                    </Typography>
                </Box>
                <Box mx={4}>
                    {edit
                        ? <Box display="flex" flexDirection="row" style={{gap: '1em'}}>
                            <ContainedButton
                                onClick={handleSubmit(onSubmit)}
                                text={<IntlMessages id={"form.button.save"}/>}
                            />
                            <ContainedButton
                                onClick={onCancel}
                                text={<IntlMessages id={"form.button.cancel"}/>}
                            />
                        </Box>
                        : <ContainedButton
                            text={<IntlMessages id={"form.button.edit"}/>}
                            onClick={() => setEdit(true)}
                        />
                    }
                </Box>
            </Box>
            <Box px={8} mt={2}>
                <Form
                    formData={formData}
                    setFormData={setFormData}
                    edit={edit}
                />
            </Box>
        </Box>
    );
};

export default TechnicalSheet;