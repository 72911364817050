import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import VehicleSelect from "../domain/VehicleSelect";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevVehicleSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllVehicleSelect = async () => {
  try {
    const response = await axiosInstance.post(
      "/Vehiculo/sel",
      {
        nu_id_entidad: getCompanyEntityId()
        //nu_id_entidad: 40,
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(vehicleSelect => VehicleSelect.map(vehicleSelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
