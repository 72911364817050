import React from "react";
import LinearProgressWithLabel from "../../../../Common/ProgressBar/LinearProgressWithLabel";

const StateProgressBar = ({quantity, percentage, title}) => (
  <LinearProgressWithLabel
    fullBoxStyles={{
      marginTop: "8px",
      marginBottom: "8px"
    }}
    text={
      <React.Fragment>
        {quantity} {title}
      </React.Fragment>
    }
    value={percentage}
  />
);

export default StateProgressBar;
