import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="origin"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.table.name" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="destiny"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.table.units" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.table.ent" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.table.falt" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.table.dev" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.table.ret" />
    </TableCellWithSorting>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
