import axiosInstance from "../../../utils/axiosInstance";
import Result from "../../../domain/Result";

export const fetchAllServicesByDate = async filters => {
    try {
        const {data} = await axiosInstance.get(
            '/DISAPlanificador/sel_pedidos',
            {
                params: {
                    fecha: filters.searchDate
                }
            }
        );
        if(!data) {
            throw Error('No se encontraron registros');
        }
        return data?.map(e => ({
            id: e.numPedido,
            client: e.cliente,
            turn: e.turno,
            clientCode: e.codCliente,
            driver: e.conductor,
            tract: e.cabezaTractora,
            trailer: e.semirremolque,
            installationDeparture: e.horaSalidaInstalacion,
            installationArrival: e.horaLlegadaInstalacion,
            clientDeparture: e.horaSalidaCliente,
            clientArrival: e.horaLlegadaCliente,
            centralCode: e.codCentro,
            saleOrganization: e.orgVentas,
            channel: e.canal,
            state: e.estado,
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllVehicles = async () => {
    try {
        const {data} = await axiosInstance.get(
            '/DISAPlanificador/sel_vehiculos',
        );
        return data.map(e => ({
            value: e.codigo,
            driver: e.transportista,
            name: e.matricula,
        }))
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllDrivers = async () => {
    try {
        const {data} = await axiosInstance.get(
            '/DISAPlanificador/sel_conductores',
        );
        return data.map(e => ({
            value: e.codigo,
            name: e.nombre,
        }))
    }catch (e) {
        return Promise.reject(e);
    }
}

const validateResults = (results) => {
    if (results.some(({value}) => value === -1)) {
        throw Error('No existen algunos de los servicios Indicados.');
    } else if (results.some(({value}) => value === -2)) {
        throw Error('No se han activado algunos de los servicios indicados.');
    } else if (results.some(({value}) => value === -3)) {
        throw Error('El Conductor ya tiene un servicio asignado a la misma hora.');
    }
    return;
}

export const activateServices = async (services) => {
    try {
        const {data} = await axiosInstance.post(
            '/DISAPlanificador/activacion_servicios',
            {
                ls_pedido: services.map(service => [service, "-100"])
            }
        );
        const results = data?.Result?.map( result => Result.map(result));
        validateResults(results);
        return {transactionMessage: 'Servicios activados'};
    }catch (e) {
        return Promise.reject(e);
    }
}

export const deleteServices = async (services) => {
    try {
        const {data} = await axiosInstance.post(
            '/DISAPlanificador/eliminar_servicios',
            {
                ls_pedido: services.map(service => [service, "-200"]),
            }
        );
        const results = data?.Result?.map( result => Result.map(result));
        validateResults(results);
        return {transactionMessage: 'Servicios borrados'};
    }catch (e) {
        return Promise.reject(e);
    }
}

export const updateServices = async (services, request) => {
    try {
        const {data} = await axiosInstance.post(
            '/DISAPlanificador/actualizar_servicios',
            {
                ls_pedido:  services.map(service => {
                    const operations = [];
                    if(request?.driver) {
                        operations.push([service, 1, request.driver]);
                    }
                    if(request?.trailer) {
                        operations.push([service, 3, request.trailer]);
                    }
                    if(request?.head) {
                        operations.push([service, 2, request.head]);
                    }
                    return operations;
                }).flat(),
            }
        );
        const results = data?.Result?.map( result => Result.map(result));
        validateResults(results);
        return {transactionMessage: 'Servicios actualizados'};
    }catch (e) {
        return Promise.reject(e);
    }
}