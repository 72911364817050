import {
  APPLY_SPECIFICATIONS_VEHICLES_FILTERS,
  FETCH_ALL_SPECIFICATIONS_VEHICLES_STARTED,
  FETCH_ALL_SPECIFICATIONS_VEHICLES_SUCCEED,
  RESET_CURRENT_SPECIFICATION,
  RESET_SPECIFICATIONS_STATE,
  RESET_SPECIFICATIONS_VEHICLES_FILTERS,
  SET_CURRENT_SPECIFICATION
} from "../../types/FleetManagement/TechnicalSpecificationsTypes";

const INIT_STATE = {
  vehicles: [],
  isLoading: false,
  filters: {
    alias: "",
    plaque: "",
    chassis: "",
    brand: "",
    model: "",
    tag: "",
    active: true
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_SPECIFICATIONS_VEHICLES_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }

    case FETCH_ALL_SPECIFICATIONS_VEHICLES_SUCCEED: {
      return {
        ...state,
        vehicles: action.payload,
        isLoading: false
      };
    }

    case APPLY_SPECIFICATIONS_VEHICLES_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }

    case RESET_SPECIFICATIONS_VEHICLES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }

    case SET_CURRENT_SPECIFICATION: {
      return {
        ...state,
        current: action.payload
      };
    }

    case RESET_CURRENT_SPECIFICATION: {
      return {
        ...state,
        current: null
      };
    }

    case RESET_SPECIFICATIONS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }

    default: {
      return state;
    }
  }
};
