import {Divider, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import GridContainer from "../../../../../@jumbo/components/GridContainer";

const {makeStyles} = require("@material-ui/core");

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  }
}));

export const WhiteButton = withStyles(theme => ({
  root: {
    color: "#ffffff"
  }
}))(Button);

export const CircleNumber = withStyles(theme => ({
  root: {
    height: "34px",
    width: "34px",
    padding: "5px",
    color: "#ffffff",
    backgroundColor: "#3f51b5",
    textAlign: "center",
    borderRadius: "50%"
  }
}))(Typography);

export const RedText = withStyles(theme => ({
  root: {
    color: "#d51e44"
  }
}))(Typography);

export const SortableItemContainer = withStyles(theme => ({
  root: {
    marginTop: "8px",
    marginLeft: "16px"
  }
}))(GridContainer);

export const StyledDivider = withStyles(theme => ({
  root: {
    marginTop: "16px",
    marginBottom: "8px"
  }
}))(Divider);
