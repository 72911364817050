import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "../Form";

const CreateNewPauseType = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-rejection-type-form"
    isOpen={isOpen}
    title={<IntlMessages id="rrhh.page.pauseTypes.add.dialog.title" />}
    onClose={onClose}
  >
    <Form id="create-rejection-type-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateNewPauseType;
