import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import ProductPicker from "../domain/ProductPicker";
import Response from "domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevProductPickerFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllProductsPicker = async () => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Producto/sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(productPicker => ProductPicker.map(productPicker));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
