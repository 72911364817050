import axiosInstance, {CancelToken} from "../../../utils/axiosInstance";
import VehicleReport from "../../../domain/FleetManagement/DailyOperations/VehicleReport";
import {getCompanyEntityId} from "../../StorageService";
import VehicleReportGroup from "../../../domain/FleetManagement/DailyOperations/VehicleReportGroup";
import moment from "moment";

let cancelFetchReportsToken = undefined;

const cancelFetch = token => {
  token && token();
};

export const getAllVehicleReports = async filters => {
  try {
    cancelFetch(cancelFetchReportsToken);
    const {data} = await axiosInstance.post(
      "/CheckList/sel_datagrid",
      {
        dt_fec_inicio: filters.startDate,
        dt_fec_fin: moment(filters.endDate).add('day', 1).format('YYYY-MM-DD'),
        ls_vehiculos: filters.vehicles.map(v => v.id),
        ls_tipos: filters.types.map(t => t.id)
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchReportsToken = c))
      }
    );
    return data.map(d => VehicleReport.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllChecklistsByReport = async (report, external=false) => {
  try {
    const {data} = await axiosInstance.post("/CheckList/sel", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: report.employeeId,
      nu_id_checklist_generados: report.id
    });
    if(external) {
      report = VehicleReport.map(data[0]);
    }
    report.observations = data[0].vc_desc_checklist_generados_descripcion;
    report.checklists = data[0].nu_id_checklist_generados_respuestas.map(r =>
      VehicleReportGroup.map(r)
    );
    report.images = data[0].checklist_imagenes.map(
      i => i.vc_checklist_generados_imagen
    );
    return report;
  } catch (e) {
    return Promise.reject(e);
  }
};
