import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";

import {parseNumberToMoney} from "../../../../../../utils/parsers";

const TableValues = ({row}) => (
  <React.Fragment>
    <StyledTableCell>{row.names}</StyledTableCell>
    <StyledTableCell>{row.type}</StyledTableCell>
    <StyledTableCell>
      {row.tags.map((tag, index) => <div key={index}>{tag}</div>)}
    </StyledTableCell>
      <StyledTableCell align="left">
          {row.costCenter}
      </StyledTableCell>
    <StyledTableCell align="right">
      {parseNumberToMoney(row.fund)}
    </StyledTableCell>
    <StyledTableCell align="right">
      {parseNumberToMoney(row.cash)}
    </StyledTableCell>
    <StyledTableCell align="right">
      {parseNumberToMoney(row.transit)}
    </StyledTableCell>
    <StyledTableCell align="right">
      {parseNumberToMoney(row.liquidated)}
    </StyledTableCell>
    <StyledTableCell align="right">
      {parseNumberToMoney(row.refund)}
    </StyledTableCell>
  </React.Fragment>
);

TableValues.propTypes = {
  row: PropTypes.shape({
    names: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.string,
    fund: PropTypes.number,
    cash: PropTypes.number,
    transit: PropTypes.number,
    liquidated: PropTypes.number,
    refund: PropTypes.number
  }).isRequired
};

export default TableValues;
