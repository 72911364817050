import React from "react";

import GridContainer from "@jumbo/components/GridContainer";
import useForm from "hooks/Common/useForm";
import Grid from "@material-ui/core/Grid";
import RejectionTypesSelect from "containers/Supervisor/RejectionTypesSelect";
import IntlMessages from "@jumbo/utils/IntlMessages";
import validation from "./validation";

const Form = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    {
      rejectionType: ""
    },
    validation
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <RejectionTypesSelect
            id="supervisor-rejection-types-select"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.rejectExpenseDialog.field.rejectionType" />
            }
            value={formData.rejectionType}
            onChange={value => setFormData("rejectionType", value)}
            hasError={errors.rejectionType.length > 0}
            error={errors.rejectionType[0]}
            withId
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
