import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import validations from "./validations";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import PlaqueSelect from "../../../../Vehicles/Common/Form/Selects/PlaqueSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import DriverSelect from "../../../../Vehicles/Common/Form/Selects/DriverSelect";
import TurnSelect from "./TurnSelect";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <PlaqueSelect
                        id={"form-reserve-plaque"}
                        value={formData.plaque}
                        setValue={value => setFormData('plaque', value)}
                        label={<IntlMessages id={"fleet.management.reserves.form.plaque"}/>}
                        hasError={errors?.plaque?.length > 0}
                        error={errors?.plaque[0]}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <DriverSelect
                        id={"form-reserve-driver"}
                        value={formData.driver}
                        setValue={value => setFormData('driver', value)}
                        label={<IntlMessages id={"fleet.management.reserves.form.driver"}/>}
                        hasError={errors?.driver?.length > 0}
                        error={errors?.driver[0]}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"form-reserve-start-date"}
                        fullWidth
                        value={formData.startDate}
                        onChange={value => setFormData('startDate', value)}
                        label={<IntlMessages id={"fleet.management.reserves.form.start.date"}/>}
                        hasError={errors?.startDate?.length > 0}
                        error={errors?.startDate[0]}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"form-reserve-end-date"}
                        fullWidth
                        value={formData.endDate}
                        onChange={value => setFormData('endDate', value)}
                        label={<IntlMessages id={"fleet.management.reserves.form.end.date"}/>}
                        hasError={errors?.endDate?.length > 0}
                        error={errors?.endDate[0]}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TurnSelect
                        id={"form-reserve-turn"}
                        value={formData.turn}
                        setValue={value => setFormData('turn', value)}
                        label={<IntlMessages id={"fleet.management.reserves.form.turn"}/>}
                        hasError={errors?.turn?.length > 0}
                        error={errors?.turn[0]}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;