import moment from "moment";

class AccountingExpense {
  constructor(
    id,
    ot,
    executor,
    date,
    expenseType,
    accountingAccount,
    amount,
    document,
    costCenter,
    costCenterId,
    documentType,
    documentNumber,
  ) {
    this.id = id;
    this.ot = ot;
    this.executor = executor;
    this.date = date;
    this.expenseType = expenseType;
    this.accountingAccount = accountingAccount;
    this.amount = amount;
    this.document = document;
    this.costCenter = costCenter;
    this.costCenterId = costCenterId;
    this.documentType = documentType;
    this.documentNumber = documentNumber;
  }
  static map = expense => {
    return new AccountingExpense(
      expense.nu_id_gasto,
      expense.vc_desc_ot,
      expense.vc_nombre_entidad,
      expense.dt_fec_doc &&
        moment(expense.dt_fec_doc, "MM/DD/YYYY hh:mm:ss A").toDate(),
      {
        id: expense.nu_id_tipo_gasto,
        description: expense.vc_desc_tipo_gasto
      },
      expense.vc_cta_contable,
      parseFloat(expense.nu_imp_gasto),
      expense.vc_id_archivo_s3,
        expense.vc_desc_centro_costo,
        expense.nu_id_centro_costo,
        expense.vc_desc_tipo_documento,
        expense.vc_nro_doc,
    );
  };
}

export default AccountingExpense;
