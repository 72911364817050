import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  description: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ],
  code: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ]
};
