import {
  FETCH_ALL_CHECKLIST_DATA,
  FETCH_ALL_WORK_ORDERS_TYPES,
  RESET_CURRENT_WORK_ORDER_TYPE,
  RESET_WORK_ORDERS_TYPES,
  SET_CURRENT_WORK_ORDER_TYPE
} from "../../types/Logistic/WorkOrdersTypesTypes";

const INIT_STATE = {
  data: {},
  current: null,
  checklistData: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_WORK_ORDERS_TYPES: {
      let types = {};
      action.payload.map(u => {
        types[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: types
      };
    }
    case SET_CURRENT_WORK_ORDER_TYPE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_WORK_ORDER_TYPE: {
      return {
        ...state,
        current: INIT_STATE.current
      };
    }
    case FETCH_ALL_CHECKLIST_DATA: {
      return {
        ...state,
        checklistData: action.payload
      };
    }
    case RESET_WORK_ORDERS_TYPES: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
