import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FormShowDialog from "../../../../../Common/Dialogs/FormShowDialog";
import {dialogsList} from "../../constants";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {closeDialog, setImageViewerFile} from "../../../../../../redux/reducers/FleetManagement/filesReader";

const ImageDialog = () => {
    const {currentImage, dialogs} = useSelector(({fleetManagement}) => fleetManagement.filesReader);
    const dispatch = useDispatch();
    const handleOnCloseDialog = () => {
        dispatch(closeDialog(dialogsList.imageViewer));
        dispatch(setImageViewerFile(null));
    }
    return (
        <FormShowDialog
            isOpen={dialogs.some(d => dialogsList.imageViewer === d)}
            id='image-viewer'
            title={<IntlMessages id='activities.graph' />}
            onClose={handleOnCloseDialog}
            maxWidth="md"
        >
            <picture>
                <img src={`data:image/svg+xml;base64,${currentImage}`} alt='grafica'/>
            </picture>
        </FormShowDialog>
    );
};

export default ImageDialog;