import React from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ReferenceLine, XAxis, YAxis} from "recharts";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import ReactSpeedometer from "react-d3-speedometer";
import {Box, Grid, Typography} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";

const widgets = {
    1: {
        id: 1,
        name: 'OT - Resumen Cumplimientos',
        defaultWidth: 800,
        defaultHeight: 750,
        type: 2, //table
        renderWidget: (widget) => {
            const renderHeader = () => {
                return (
                    <StyledTableRow>
                        <StyledTableCell>
                            Sede
                        </StyledTableCell>
                        <StyledTableCell>
                            N° OTS
                        </StyledTableCell>
                        <StyledTableCell>
                            Cantidad transportada
                        </StyledTableCell>
                        <StyledTableCell>
                            OTD
                        </StyledTableCell>
                        <StyledTableCell>
                            CUM PROGRAMA
                        </StyledTableCell>
                        <StyledTableCell>
                            CUM TEMPERATURA
                        </StyledTableCell>
                        <StyledTableCell>
                            % Utilización
                        </StyledTableCell>
                    </StyledTableRow>
                )
            }
            return (
                <TableContainer
                    head={renderHeader()}
                >
                    {widget?.data?.ls_info?.map((e, index) => (
                        <StyledTableRow key={index}>
                            <StyledTableCell>
                                {e.headquarter}
                            </StyledTableCell>
                            <StyledTableCell>
                                {e.ots}
                            </StyledTableCell>
                            <StyledTableCell>
                                {e.transportQuantity}
                            </StyledTableCell>
                            <StyledTableCell>
                                {e.otd}
                            </StyledTableCell>
                            <StyledTableCell>
                                {e.programCum}
                            </StyledTableCell>
                            <StyledTableCell>
                                {e.programTemperature}
                            </StyledTableCell>
                            <StyledTableCell>
                                {e.using}
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                    <StyledTableRow>
                        <StyledTableCell/>
                        <StyledTableCell>
                            {widget?.data?.info?.ots}
                        </StyledTableCell>
                        <StyledTableCell>
                            {widget?.data?.info?.transportQuantity}
                        </StyledTableCell>
                        <StyledTableCell>
                            {widget?.data?.info?.otd}
                        </StyledTableCell>
                        <StyledTableCell>
                            {widget?.data?.info?.programCum}
                        </StyledTableCell>
                        <StyledTableCell>
                            {widget?.data?.info?.programTemperature}
                        </StyledTableCell>
                        <StyledTableCell>
                            {widget?.data?.info?.using}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableContainer>
            );
        }
    },
    2: {
        id: 2,
        name: 'OT - Objetivos Cumplimientos',
        defaultWidth: 400,
        defaultHeight: 500,
        type: 3, //speedometer
        renderWidget: (widget, theme) => {
            return (
                <Box
                    style={{
                        maxHeight: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        overflow: 'auto',
                        justifyContent: 'center'
                    }}
                >
                    {Object.values(widget?.data)?.map((e, index) => (
                        <Box
                            key={index}
                            style={{
                                display: 'grid',
                                placeContent: 'center',
                            }}
                        >
                            <ReactSpeedometer
                                value={e.value * 10}
                                maxSegmentLabels={0}
                                segments={3}
                                ringWidth={25}
                                needleColor={theme.palette.text.secondary}
                                needleHeightRatio={0.5}
                                needleTransitionDuration={4000}
                                needleTransition="easeElastic"
                                customSegmentStops={[0, 750, 900, 1000]}
                                segmentColors={['#E00930', '#FFCA41', '#8DCD03']}
                                currentValueText={`${e.description} - ${e.value}%`}
                                valueTextFontSize={'16px'}
                                textColor={theme.palette.text.primary}
                                width={250}
                                height={200}
                            />
                        </Box>
                    ))}
                </Box>
            );
        }
    },
    3: {
        id: 3,
        name: 'OT - Comparativa Volumen - Vueltas',
        defaultWidth: 400,
        defaultHeight: 700,
        type: 4, //special
        defaultFilters: {
            planningPlace: 1
        },
        renderWidget: (widget) => (
            <GridContainer spacing={2}
                           style={{
                               textAlign: 'center'
                           }}
            >
                <Grid item xs={12} md={6} key={`${widget.internalId} - 0`}
                >
                    <Typography variant='h2'>
                        {widget?.data?.[0]?.description}
                    </Typography>
                    {widget?.data?.[0]?.ls_info_volumen?.map((e, index)=> (
                        <Box key={`${widget.internalId} - ${index}`}
                             my={4}
                        >
                            <Typography variant='body2'>
                                {e.year}
                            </Typography>
                            <Typography variant='h4' component='p'>
                                {e.value}
                            </Typography>
                        </Box>
                    ))}
                    <Box
                        style={{
                            backgroundColor: '#d4d4d4'
                        }}
                    >
                        {widget?.data?.[0]?.comp}
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} key={`${widget.internalId}-1`}>
                    <Typography variant='h2'>
                        {widget?.data?.[1]?.description}
                    </Typography>
                    {widget?.data?.[1]?.ls_info_vueltas?.map((e, index)=> (
                        <Box key={index}
                             my={4}
                        >
                            <Typography variant='body2'>
                                {e.year}
                            </Typography>
                            <Typography variant='h4' component='p'>
                                {e.value}
                            </Typography>
                        </Box>
                    ))}
                    <Box
                        style={{
                            backgroundColor: '#d4d4d4'
                        }}
                    >
                        {widget?.data?.[1]?.comp}
                    </Box>
                </Grid>
            </GridContainer>
        )
    },
    4: {
        id: 4,
        defaultWidth: 800,
        defaultHeight: 700,
        name: 'OT - Comparativa Cumplimientos',
        type: 1, //graph
        defaultY: "Porcentaje",
        defaultFilters: {
            planningPlace: 1
        },
        renderWidget: (widget) => (
            <BarChart
                width={500}
                height={300}
                data={widget.data.data}
                barCategoryGap={1}
                margin={{top: 15, right: 30, left: 20, bottom: 5}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                    label={{
                        value: widget.labelX,
                        position: 'insideBottom',
                        offset: -4
                    }}
                    dataKey="name"
                />
                <YAxis
                    label={{
                        value: widget.labelY,
                        angle: -90,
                        position: 'insideLeft',
                        offset: -9
                    }}
                />
                <Legend/>
                <Bar name={widget?.data?.previousyear} dataKey="py" fill="#3a6598" label/>
                <Bar name={widget?.data?.actualyear} dataKey="ay" fill="#efa252" label/>
            </BarChart>
        )
    },
    5: {
        id: 5,
        name: 'Vehiculos - Exceso Velocidad',
        defaultWidth: 400,
        defaultHeight: 700,
        type: 1, //graph
        defaultFilters: {},
        renderWidget: (widget) => (
            <BarChart
                width={500}
                height={300}
                data={Object.values(widget?.data)}
                barCategoryGap={1}
                margin={{top: 15, right: 30, left: 20, bottom: 5}}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                    label={{
                        value: widget?.labelX,
                        position: 'insideBottom',
                        offset: -4
                    }}
                    dataKey="name"
                />
                <YAxis
                    label={{
                        value: widget?.labelY,
                        angle: -90,
                        position: 'insideLeft',
                        offset: -9
                    }}
                />
                <Legend/>
                <Bar stackId='a' name={'>10 Grave'} dataKey="lv3" fill="#b60a1c" label/>
                <Bar stackId='a' name={'>5 y <10 Moderado'} dataKey="lv2" fill="#f0bd27" label/>
                <Bar stackId='a'  name={'< 4 Leve'} dataKey="lv1" fill="#398a48" label/>
            </BarChart>
        )
    },
    6: {
        id: 6,
        name: 'Vehiculos - Tiempo Productividad',
        defaultWidth: 400,
        defaultHeight: 700,
        type: 1, //graph
        defaultFilters: {},
        renderWidget: (widget) => (
            <BarChart
                width={500}
                height={300}
                data={Object.values(widget?.data)}
                barCategoryGap={1}
                margin={{top: 15, right: 30, left: 20, bottom: 5}}
                layout='vertical'
                stackOffset="sign"
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis
                    label={{
                        value: widget?.labelX,
                        position: 'insideBottom',
                        offset: -4
                    }}
                    type='number'
                />
                <YAxis
                    label={{
                        value: widget?.labelY,
                        angle: -90,
                        position: 'insideLeft',
                        offset: -9
                    }}
                    dataKey="name"
                    type='category'
                />
                <Legend/>
                <ReferenceLine x={0} stroke="#000" />
                <Bar stackId='a' name='Tiempo en Movimiento' dataKey="pv" fill="#efa252" label/>
                <Bar stackId='a' name='Kilometros Recorridos' dataKey="uv" fill="#3a6598" label/>
            </BarChart>
        )
    },
};
export default widgets;