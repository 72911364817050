import {
  FETCH_DATA_COLLECTION_HISTORY_SUCCEEDED,
  APPLY_DATA_COLLECTION_HISTORY_FILTERS,
  RESET_DATA_COLLECTION_HISTORY_FILTERS
} from "../../types/Administrator/DataCollectionHistory";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_DATA_COLLECTION_HISTORY_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_DATA_COLLECTION_HISTORY_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_DATA_COLLECTION_HISTORY_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    default: {
      return state;
    }
  }
};
