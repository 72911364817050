import React from 'react';
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const LogisticReportTypeSelect = ({value, onChange}) => {
    const id = 'LogisticReportTypeSelect';
    return (
        <Select
            id={id}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
        >
            <MenuItem value={1}>
                <IntlMessages
                    id={"time.view"}
                />
            </MenuItem>
            <MenuItem value={2}>
                <IntlMessages
                    id={"load.view"}
                />
            </MenuItem>
        </Select>
    );
};

export default LogisticReportTypeSelect;