import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import ElevatorModel from "../../domain/ElevatorsManagement/ElevatorModel";
import Response from "../../domain/Response";

export const getAllElevatorsModels = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Modelo/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(model => ElevatorModel.map(model));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createElevatorModel = async model => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Modelo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_modelo: model.name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorModel = async (model, name) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Modelo/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_modelo: model.id,
      vc_desc_tipo_modelo: name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorModelState = async (model, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Modelo/del", {
      nu_id_tipo_modelo: model.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
