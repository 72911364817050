import {
  ACCOUNTING_FETCH_ALL_BY_USER_SUCCESS,
  RESET_ACCOUNTING_FILTERS,
  APPLY_ACCOUNTING_FILTERS,
  SET_TRANSACTION_RESULT,
  RESET_TRANSACTION_RESULT,
  REJECT_ACCOUNTING_SUCCESS,
  APPROVE_ACCOUNTING_SUCCESS,
  APPROVE_ACCOUNTING_STARTED,
  REJECT_ACCOUNTING_STARTED,
  ACCOUNTING_FETCH_ALL_BY_USER_STARTED,
  SET_ACCOUNTING_ACTIVE_FILTERS,
  RESET_ACCOUNTING_ACTIVE_FILTERS,
  DELETE_ACCOUNTING_SUCCESS,
  DELETE_ACCOUNTING_STARTED, SET_CURRENT_ACCOUNTING_EXPENSE
} from "../../types/Guardian/AccountingTypes";
import {
  approveAccounting,
  cancelPrevFetchAllAccountingByGuardian,
  deleteAccounting,
  fetchAllAccountingByUser,
  rejectAccounting
} from "../../../services/AccountingService";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {setCurrentAccounting} from "./CurrentAccounting";
import {updateExpenseCostCenter} from "../../../services/ExpenseService";

const fetchAllAccountingSuccess = data => {
  return {
    type: ACCOUNTING_FETCH_ALL_BY_USER_SUCCESS,
    payload: {
      data
    }
  };
};

export const fetchAllAccountingStarted = filters => {
  return async dispatch => {
    dispatch({
      type: ACCOUNTING_FETCH_ALL_BY_USER_STARTED
    });
    cancelPrevFetchAllAccountingByGuardian();
    fetchAllAccountingByUser(filters).then(accountingList => {
      dispatch(fetchAllAccountingSuccess(accountingList));
    });
  };
};

export const applyAccountingFilters = filters => ({
  type: APPLY_ACCOUNTING_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetAccountingFilters = () => ({type: RESET_ACCOUNTING_FILTERS});

export const setTransactionResult = response => {
  return {
    type: SET_TRANSACTION_RESULT,
    payload: {
      code: response.transactionState,
      message: response.transactionMessage
    }
  };
};

export const resetTransactionResult = () => {
  return {
    type: RESET_TRANSACTION_RESULT
  };
};

export const approveAccountingSuccess = () => {
  return {
    type: APPROVE_ACCOUNTING_SUCCESS
  };
};

export const approveAccountingStarted = (
  accounting,
  filters,
  setOpen = null
) => {
  return async dispatch => {
    dispatch({
      type: APPROVE_ACCOUNTING_STARTED
    });
    approveAccounting(accounting)
      .then(response => {
        dispatch(setTransactionResult(response));
        dispatch(fetchAllAccountingStarted(filters));
        dispatch(approveAccountingSuccess());
        setOpen && setOpen(true);
      })
      .catch(e => {
        dispatch(
          setTransactionResult({
            transactionState: "0",
            transactionMessage: e.message
          })
        );
        setOpen && setOpen(true);
      });
  };
};

export const rejectAccountingSuccess = () => {
  return {
    type: REJECT_ACCOUNTING_SUCCESS
  };
};

export const rejectAccountingStarted = (accounting, filters, setOpen) => {
  return async dispatch => {
    dispatch({
      type: REJECT_ACCOUNTING_STARTED
    });
    rejectAccounting(accounting)
      .then(response => {
        dispatch(setTransactionResult(response));
        dispatch(fetchAllAccountingStarted(filters));
        dispatch(rejectAccountingSuccess());
        setOpen && setOpen(true);
      })
      .catch(e => {
        dispatch(
          setTransactionResult({
            transactionState: "0",
            transactionMessage: e.message
          })
        );
        setOpen && setOpen(true);
      });
  };
};

export const deleteAccountingSuccess = () => {
  return {
    type: DELETE_ACCOUNTING_SUCCESS
  };
};

export const deleteAccountingStarted = (accounting, filters, setOpen) => {
  return async dispatch => {
    dispatch({
      type: DELETE_ACCOUNTING_STARTED
    });
    deleteAccounting(accounting)
      .then(response => {
        dispatch(setTransactionResult(response));
        dispatch(fetchAllAccountingStarted(filters));
        dispatch(deleteAccountingSuccess());
        setOpen && setOpen(true);
      })
      .catch(e => {
        dispatch(
          setTransactionResult({
            transactionState: "0",
            transactionMessage: e.message
          })
        );
        setOpen && setOpen(true);
      });
  };
};

export const setAccountingActiveFilters = filters => ({
  type: SET_ACCOUNTING_ACTIVE_FILTERS,
  payload: {...filters}
});

export const resetAccountingActiveFilters = () => ({
  type: RESET_ACCOUNTING_ACTIVE_FILTERS
});

export const setAccountingExpense = (expense) => ({
  type: SET_CURRENT_ACCOUNTING_EXPENSE,
  payload: expense,
});

export const updateAccountingExpense = ({request, accounting}) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const {transactionMessage} = await updateExpenseCostCenter(request);
      dispatch(fetchSuccess(transactionMessage));
      dispatch(setCurrentAccounting(accounting));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}