import React from "react";
import {ResponsiveBox, TextTypography} from "./styles";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";
import AlbumIcon from "@material-ui/icons/Album";
import {useSelector} from "react-redux";
import {parseNumberToMoney} from "../../../../utils/parsers";

const BalanceFooter = () => {
  const {amount, expense, passed, balance} = useSelector(
    state => state.guardian.currentAdvance
  );
  return (
    <ResponsiveBox my={4}>
      <TextTypography component="h3">
        <IntlMessages id={"guardian.advance.detail.advance"} />:{" "}
        {parseNumberToMoney(amount)}
      </TextTypography>
      <TextTypography component="h3">
        <IntlMessages id={"guardian.advance.detail.spend"} />:{" "}
        {parseNumberToMoney(expense)}
      </TextTypography>
      <TextTypography component="h3">
        <IntlMessages id={"guardian.advance.detail.approved"} />:{" "}
        {parseNumberToMoney(passed)}
      </TextTypography>
      <Box display="flex" flexDirection="row">
        <TextTypography component="h3">
          <IntlMessages id={"guardian.advance.detail.difference"} />:{" "}
          {parseNumberToMoney(balance)}
        </TextTypography>
        <AlbumIcon
          style={{
            color: balance !== 0 ? "#f44336" : "#4caf50",
            marginLeft: "6px"
          }}
        />
      </Box>
    </ResponsiveBox>
  );
};

export default BalanceFooter;
