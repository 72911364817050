import React, { useEffect, useState } from 'react';

import Select from 'components/Common/Forms/Select';
import { fetchAllCompanyEntities } from 'services/CompanyEntityService';
import { getCompanyEntityId, setCompanyEntityId, setUserType } from 'services/StorageService';
import { getUserTypeByUserId } from 'services/UserEntityService';
import { useHistory } from 'react-router';
import {useDispatch} from "react-redux";
import {updateSelectedCompany, updateUserTypeAndPermissions} from "../../../redux/actions/Auth";

const CompanyEntitySelect = () => {
  const history = useHistory();
  const [entities, setEntities] = useState([]);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllCompanyEntities().then(entities => {
      setEntities(entities);

      const savedEntityId = getCompanyEntityId();
      if (savedEntityId) {
        setSelected(savedEntityId);
        const company = entities.find(e => e.nu_id_entidad === savedEntityId)
        dispatch(updateSelectedCompany({id: company?.nu_id_entidad, name: company?.vc_nombre}));

      } else {
        const value = entities[0].nu_id_entidad;
        setSelected(value);
        setCompanyEntityId(value);
        const company = entities[0];
        dispatch(updateSelectedCompany({id: company?.nu_id_entidad, name: company?.vc_nombre}));

      }
    });
  }, []);

  const handleChange = value => {
    setSelected(value);
    setCompanyEntityId(value);
    dispatch(updateUserTypeAndPermissions(history));
  };

  const options = [...entities.map(entity => ({ value: entity.nu_id_entidad, label: entity.vc_nombre }))];

  return (
    selected && (
      <Select
        value={selected}
        fullWidth
        id="company-entity-select"
        label="Entidad empresa"
        onChange={e => handleChange(e.target.value)}
        options={options}
      />
    )
  );
};

export default CompanyEntitySelect;
