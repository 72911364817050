import React from 'react';
import RestrictedRoute from "../../RestrictedRoute";
import {Redirect} from "react-router";
import {useSelector} from "react-redux";

const FleetManagementVehiclesRoute = ({path, component}) => {
    const { permissions } = useSelector(({ auth }) => auth);
    const authorization = permissions.some(p => p.id === 3);
        return(
            <>
                    {authorization
                        ? <RestrictedRoute path={path} component={component}/>
                        : <Redirect
                            to='/home-page'
                        />
                    }
            </>
        );
}

export default FleetManagementVehiclesRoute;