import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  reportsList: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  title: {
    backgroundColor: theme.palette.sidebar.textActiveColor,
    color: theme.palette.background.paper
  },
  pagination: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  }
}));
