import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, IconButton} from "@material-ui/core";
import TableContainer from "../../../../Common/Tables/TableContainer";
import PaginationFooter from "../../../../Common/Tables/PaginationFooter";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import {
  fetchALlTires,
  setCurrentTire
} from "../../../../../redux/actions/FleetManagement/Tires";
import Filters from "./Filters";
import TableHeader from "./TableHeader";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TiresTable = ({openEditDialog}) => {
  const dispatch = useDispatch();
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tires
  );
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);
  React.useEffect(
    () => {
      dispatch(fetchALlTires(filters));
    },
    [ filters ]
  );
  return (
    <Box display="flex" flexDirection="column">
      <Filters />
      <Box my={4}>
        <TableContainer
          head={<TableHeader />}
          footer={
            <PaginationFooter
              data={data}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          }
        >
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data).map(tire => (
            <StyledTableRow key={tire.id}>
              <StyledTableCell>{tire.identification}</StyledTableCell>
              <StyledTableCell>
                <IntlMessages
                  id={tire.active ? "table.cell.yes" : "table.cell.no"}
                />
              </StyledTableCell>
              <StyledTableCell>
                {tire.purchaseDate ? (
                  moment(tire.purchaseDate).format("DD/MM/YYYY")
                ) : (
                  ""
                )}
              </StyledTableCell>
              <StyledTableCell>{tire.brand}</StyledTableCell>
              <StyledTableCell>{tire.measures}</StyledTableCell>
              <StyledTableCell>
                <IntlMessages
                  id={tire.retreading ? "table.cell.yes" : "table.cell.no"}
                />
              </StyledTableCell>
              <StyledTableCell>{tire.pressure}</StyledTableCell>
              <StyledTableCell>{tire.vehicle}</StyledTableCell>
              <StyledTableCell>
                <Box display="flex" flexDirection="row">
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        dispatch(setCurrentTire(tire));
                        openEditDialog && openEditDialog();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TiresTable;
