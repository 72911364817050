import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  ADD_CHECKLIST_TYPE,
  EDIT_CHECKLIST_TYPE,
  FETCH_ALL_CHECKLIST_TYPES,
  RESET_CHECKLIST_TYPES,
  RESET_CURRENT_CHECKLIST_TYPE,
  SET_CURRENT_CHECKLIST_TYPE
} from "../../types/Logistic/ChecklistTypesTypes";
import ChecklistType from "../../../domain/Logistic/Distribution/ChecklistType";
import ChecklistTypeItem from "../../../domain/Logistic/Distribution/ChecklistTypeItem";
import {
  addChecklistGroupItemToGroup,
  addChecklistGroupToType,
  createChecklistType,
  getAllChecklistTypes,
  updateChecklistGroup,
  updateChecklistGroupItem,
  updateChecklistState,
  updateChecklistType
} from "../../../services/Logistics/Distribution/ChecklistTypeService";
import ChecklistTypeGroup from "../../../domain/Logistic/Distribution/ChecklistTypeGroup";

export const resetChecklistTypesState = () => ({type: RESET_CHECKLIST_TYPES});
export const setCurrentChecklistType = type => ({
  type: SET_CURRENT_CHECKLIST_TYPE,
  payload: type
});
export const resetCurrentChecklistType = () => ({
  type: RESET_CURRENT_CHECKLIST_TYPE
});
export const fetchAllChecklistTypes = () => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllChecklistTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_CHECKLIST_TYPES, payload: types});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addChecklistType = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createChecklistType(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newChecklistType = new ChecklistType(
          response.transactionKey,
          data.name,
          [],
          true,
            data.markOption
        );
        dispatch({type: ADD_CHECKLIST_TYPE, payload: newChecklistType});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editChecklistType = (type, setEdit) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateChecklistType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const updatedChecklist = new ChecklistType(
          type.id,
          type.name,
          type.groups,
          type.state,
            type.markOption,
        );
        dispatch({type: EDIT_CHECKLIST_TYPE, payload: updatedChecklist});
        dispatch(setCurrentChecklistType(updatedChecklist));
        setEdit && setEdit(false);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateChecklistTypeState = (type, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateChecklistState(type.id, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        type.state = state;
        dispatch({type: EDIT_CHECKLIST_TYPE, payload: type});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addGroupToChecklistType = (data, checklist) => {
  return async dispatch => {
    dispatch(fetchStart());
    addChecklistGroupToType(checklist, data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newGroup = new ChecklistTypeGroup(
          response.transactionKey,
          data.name,
          [],
          checklist.groups.length + 1,
          true
        );
        checklist.addGroup(newGroup);
        dispatch({type: EDIT_CHECKLIST_TYPE, payload: checklist});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editGroupOfChecklistType = (group, checklist, setEdit) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateChecklistGroup(checklist, group)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editedGroup = new ChecklistTypeGroup(
          response.transactionKey,
          group.name,
          group.list,
          group.order,
          group.state
        );
        checklist.editGroup(editedGroup);
        dispatch({type: EDIT_CHECKLIST_TYPE, payload: checklist});
        setEdit && setEdit(false);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addOptionToChecklistTypeGroup = (data, checklist, group) => {
  return async dispatch => {
    dispatch(fetchStart());
    addChecklistGroupItemToGroup(group, data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newItem = new ChecklistTypeItem(
          response.transactionKey,
          data.name,
          group.list.length + 1,
          true
        );
        checklist.addOptionToGroup(group.id, newItem);
        dispatch({type: EDIT_CHECKLIST_TYPE, payload: checklist});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editOptionFromChecklistTypeGroup = (
  item,
  checklist,
  group,
  setEdit
) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateChecklistGroupItem(group, item)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editedItem = new ChecklistTypeItem(
          response.transactionKey,
          item.name,
          item.order,
          item.state
        );
        checklist.editOptionOfGroup(group, editedItem);
        dispatch({type: EDIT_CHECKLIST_TYPE, payload: checklist});
        setEdit && setEdit(false);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
