import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.masterData"} />,
    link: "/treasury/cash-management/cash-managers"
  },
  {
    label: <IntlMessages id={"sidebar.workday.dataMaster.pauseTypes"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="sidebar.workday.dataMaster.pauseTypes" />}
    description={<IntlMessages id="rrhh.page.pauseTypes.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
