import React from "react";

import PageContainer from "../../../../../components/FleetManagement/DailyOperation/Workshop/PageContainer";
import WorkshopTable from "../../../../../components/FleetManagement/DailyOperation/Workshop/WorkshopTable";
import {useDispatch, useSelector} from "react-redux";
import {resetWorkshopState} from "../../../../../redux/actions/FleetManagement/Workshop";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddWorkshopVehicleDialog from "../../../../../components/FleetManagement/DailyOperation/Workshop/AddWorkshopVehicleDialog";
import EditWorkshopVehicleDialog from "../../../../../components/FleetManagement/DailyOperation/Workshop/EditWorkshopVehicleDialog";

const Workshop = () => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.workshop
  );
  const dispatch = useDispatch();
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetWorkshopState());
    };
  }, []);
  return (
    <PageContainer>
      <WorkshopTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd
        onAddClicked={() => {
          setOpenAddDialog(true);
        }}
      />
      {openAddDialog && (
        <AddWorkshopVehicleDialog
          open={openAddDialog}
          setOpen={setOpenAddDialog}
        />
      )}
      {openEditDialog &&
      current && (
        <EditWorkshopVehicleDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
        />
      )}
    </PageContainer>
  );
};

export default Workshop;
