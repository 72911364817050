import React from 'react';
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {fetchAllEmployeesByEntity} from "../../../../../../../../services/EmployeeService";
import MultipleSelect from "../../../../../../../Common/Forms/Select/MultipleSelect";

const DeliveriesPicker = ({values, onChange, errors}) => {
    const {data: deliveries} = useFetch(fetchAllEmployeesByEntity, {}, []);
    return (
        <MultipleSelect
            id="deliveries-picker"
            options={deliveries}
            value={values}
            onChange={onChange}
            fullWidth
            hasError={errors?.deliveries?.length > 0}
            error={errors?.deliveries?.join(', ')}
            label={
                <IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.delivery.man" />
            }
            headerText={
                <IntlMessages id="picker.options.header" />
            }
            noOptionsText={
                <IntlMessages id="picker.options.no.option" />
            }
        />
    );
};

DeliveriesPicker.propTypes = {

};

export default DeliveriesPicker;