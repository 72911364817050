import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import TypeSelect from "./TypeSelect";
import {useDispatch} from "react-redux";
import {createVehicleType} from "../../../../../redux/actions/FleetManagement/TechnicalSpecification";

const TypeManagementDialog = ({open, setOpen}) => {
  const id = "vehicle-type-management-form";
  const [ selected, setSelected ] = React.useState({id: "0", name: ""});
  const {formData, setFormData, resetForm, handleSubmit} = useForm({
    name: ""
  });
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(createVehicleType(data));
    onClose();
  };
  const onClose = () => {
    resetForm();
    setOpen(false);
  };
  React.useEffect(
    () => {
      setFormData("name", selected.name);
    },
    [ selected ]
  );
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.management.type.title"}
        />
      }
      isOpen={open}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <TypeSelect
              value={selected}
              onChange={value => setSelected(value)}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              label={
                <IntlMessages
                  id={
                    "fleet.management.vehicles.specifications.management.type.form.name"
                  }
                />
              }
              value={formData.name}
              onChange={value => setFormData("name", value)}
              fullWidth
            />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default TypeManagementDialog;
