class VehicleModel {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static map = model => {
    return new VehicleModel(model.nu_id_marca_modelo, model.vc_marca_modelo);
  };
}

export default VehicleModel;
