import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import Checkbox from 'components/Common/Forms/Checkbox';
import useForm from 'hooks/Common/useForm';

import validations from './validations';

const Form = ({ id, initialData, onSubmit }) => {
  const { formData, setFormData, errors, handleSubmit } = useForm(
    {
      description: initialData?.description || '',
      approvalOfExpenses: initialData?.approvalOfExpenses || false,
      obligationOfOT: initialData?.obligationOfOT || false,
    },
    validations,
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <OutlinedInput
            value={formData.description}
            hasError={errors.description.length > 0}
            error={errors.description[0]}
            fullWidth
            id="description"
            label={<IntlMessages id="administrator.advanceTypes.saveAdvanceTypeDialog.field.description" />}
            onChange={value => setFormData('description', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={formData.approvalOfExpenses}
            onChange={value => setFormData('approvalOfExpenses', value)}
            name="approvalOfExpenses"
            label={<IntlMessages id="administrator.advanceTypes.saveAdvanceTypeDialog.field.approvalOfExpenses" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={formData.obligationOfOT}
            onChange={value => setFormData('obligationOfOT', value)}
            name="obligationOfOT"
            label={<IntlMessages id="administrator.advanceTypes.saveAdvanceTypeDialog.field.obligationOfOT" />}
            fullWidth
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
