import Tag from 'domain/Tag';
import axiosInstance, {CancelToken, isCancel} from 'utils/axiosInstance';
import {getCompanyEntityId, getUserName} from "./StorageService";
import Response from "../domain/Response";

let fetchAllTagsCancelToken = undefined;

export const cancelPrevFetchAllTags = () => {
    fetchAllTagsCancelToken && fetchAllTagsCancelToken();
};

export const fetchAllTags = async () => {
    try {
        const response = await axiosInstance.post(
            '/Tag/sel',
            {
                nu_id_entidad: getCompanyEntityId()
            },
            {
                cancelToken: new CancelToken(c => (fetchAllTagsCancelToken = c)),
            },
        );
        return response.data.map(tag => Tag.map(tag));
    } catch (error) {
        if (isCancel(error)) {
            return Promise.reject({cancelled: true, message: undefined});
        }
        return Promise.reject({cancelled: false, message: error?.message || 'Network error'});
    }
};

export const fetchAllTagsByType = async typeId => {
    try {
        const response = await axiosInstance.post(
            '/Tag/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tipo_tag: typeId,
            },
        );
        return response.data.map(tag => Tag.map(tag));
    } catch (error) {
        return Promise.reject({cancelled: false, message: error?.message || 'Network error'});
    }
}

export const createTag = async tag => {
    try {
        const {data} = await axiosInstance.post(
            '/Tag/ins',
            {
                nu_id_entidad: getCompanyEntityId(),
                vc_desc_tag: tag.description,
                nu_id_tipo_tag: tag.type,
                vc_usuario: getUserName(),
                vc_color: tag.color
            },
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject({cancelled: false, message: e?.message || 'Network error'});
    }
}

export const updateTag = async tag => {
    try {
        const {data} = await axiosInstance.post(
            '/Tag/upd',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tag: tag.id,
                vc_desc_tag: tag.description,
                nu_id_tipo_tag: tag.typeID,
                vc_color: tag.color || "",
                vc_usuario: getUserName(),
            },
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject({cancelled: false, message: e?.message || 'Network error'});
    }
}

export const deleteTag = async id => {
    try {
        const {data} = await axiosInstance.post(
            '/Tag/del',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tag: id,
            },
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject({cancelled: false, message: e?.message || 'Network error'});
    }
}