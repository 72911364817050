import {createSlice} from "@reduxjs/toolkit";
import {editProviderState, getAllProviders, getProviderHeadquarters} from "../../thunks/Logistic/providers";
import {cloneDeep} from "lodash";

const INITIAL_STATE = {
    data: {},
    currentProvider: null,
    filters: {
        providerCode: '',
        name: '',
        businessName: '',
    },
    dialogs: [],
    currentHeadquarter: null,
};


const providersSlide = createSlice({
    name: "providers",
    initialState: INITIAL_STATE,
    reducers: {
        setCurrentProvider: (state, action) => {
            state.currentProvider = action.payload;
        },
        setCurrentProviderHeadquarter: (state, action) => {
            state.currentHeadquarter = action.payload;
        },
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setFilters: (state, action) => {
            state.filters = {
                ...state.filters,
                ...action.payload
            };
        },
        resetState: () => {
            return {...cloneDeep(INITIAL_STATE)};
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllProviders.fulfilled, (state, action) => {
            let list = {};
            for (let provider of action.payload.data) {
                list[provider.id] = provider;
            }
            state.data = list;
        });
        builder.addCase(getProviderHeadquarters.fulfilled, (state, action) => {
            state.currentProvider = action.payload;
        });
        builder.addCase(editProviderState.fulfilled, (state, action) => {
            state.data[action.payload.id].state = !action.payload.state;
        });
    }
});

export default providersSlide.reducer;

export const {setCurrentProvider,
    setCurrentProviderHeadquarter,
    closeDialog,
    openDialog,
    setFilters,
    resetState
} = providersSlide.actions;