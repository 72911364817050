import React from "react";
import {useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = () => {
  const {type: selected, title} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  return (
    <React.Fragment>
      <Box my={4}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <FiltersContainer active marginBottom={5} onClose={() => {}}>
        <Form service={selected} />
      </FiltersContainer>
    </React.Fragment>
  );
};

Filters.propTypes = {};

export default Filters;
