import React from "react";
import PropTypes from "prop-types";

import FiltersContainer from "components/Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = ({onFilter, onClose, showLoadingIndicator}) => (
  <FiltersContainer
    active={true}
    marginBottom={5}
    onClose={onClose}
    showLoadingIndicator={showLoadingIndicator}
  >
    <Form onFilter={onFilter} />
  </FiltersContainer>
);

Filters.propTypes = {
  onFilter: PropTypes.func,
  onClose: PropTypes.func
};

Filters.defaultProps = {
  onFilter: () => {},
  onClose: () => {}
};

export default Filters;
