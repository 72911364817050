import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getDailyOperativePersonalOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 36) && options.push({
        name: <IntlMessages id={'sidebar.rrhh.personal.dataMaster.employees'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/personal/workers',
    });

    submodules.some(s => s.id === 72) && options.push({
        name: <IntlMessages id={"rrhh.employees.documentation.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/personal/employees-documentation',
    });

    return options;
}

const getDataMasterPersonalOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 91) && options.push({
        name: <IntlMessages id={ "rrhh.employees.documents.types.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/personal/documents-types',
    });


    return options;
}

const getPersonalOptions = (submodules) => {
    let options = [];
    const dataMaster =  getDataMasterPersonalOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.rrhh.personal.dataMaster'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperative =  getDailyOperativePersonalOptions(submodules);
    dailyOperative?.length > 0 && options.push({
        name: <IntlMessages id={"rrhh.employees.diary.operative.sidebar"}/>,
        type: 'section',
        children: dailyOperative,
    });
    return options;
}

const getDataMasterWorkdayOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 37) && options.push({
        name: <IntlMessages id={'sidebar.workday.dataMaster.transferTypes'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/workday/signing/types',
    });
    submodules.some(s => s.id === 38) && options.push({
        name: <IntlMessages id={'sidebar.workday.dataMaster.pauseTypes'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/workday/pause/types',
    });

    return options;
}

const getDailyOperationWorkdayOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 39) && options.push({
        name: <IntlMessages id={'sidebar.workday.dailyOperations.currentDay'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/workday/daily/operations',
    });
    submodules.some(s => s.id === 40) && options.push({
        name: <IntlMessages id={'sidebar.workday.dailyOperations.transferHistory'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/rrhh/workday/historical/signing',
    });


    return options;
}

const getWorkdayOptions = (submodules) => {
    let options = [];
    const dataMaster =  getDataMasterWorkdayOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.workday.dataMaster'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperation = getDailyOperationWorkdayOptions(submodules);
    dailyOperation?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.workday.dailyOperations'}/>,
        type: 'section',
        children: dailyOperation,
    });

    return options;
}

export const getRrhhOptions = () => {
    const permissions = getUserPermissions();
    let rrhh = {
        name: <IntlMessages id={'sidebar.rrhh'}/>,
        type: 'section',
        children: []
    };
    let submodules = permissions.find(p => p.id===8)?.submodules;
    if(submodules?.length > 0) {
        const personalOptions = getPersonalOptions(submodules);
        personalOptions.length > 0 && rrhh.children.push({
            name: <IntlMessages id={'sidebar.rrhh.personal'}/>,
            type: 'collapse',
            children: personalOptions,
        });
    }
    submodules = permissions.find(p => p.id===9)?.submodules;
    if(submodules?.length > 0) {
        const workdayOptions = getWorkdayOptions(submodules);
        workdayOptions.length > 0 && rrhh.children.push({
            name: <IntlMessages id={'sidebar.workday'}/>,
            type: 'collapse',
            children: workdayOptions,
        });
    }

    return rrhh.children.length > 0? rrhh : null;
}