import React from "react";
import OutlinedInput from "../OutlinedInput";

const SearchInput = ({search, onSearch, label = "Search"}) => (
  <OutlinedInput
    fullWidth
    value={search}
    label={label}
    onChange={value => onSearch(value)}
  />
);

export default SearchInput;
