import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import PageContainer from "components/Administrator/Incident/PageContainer";
import Filters from "components/Administrator/Incident/Filters";
import Table from "components/Administrator/Incident/Table";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import CreateIncidentDialog from "components/Administrator/Incident/CreateIncidentDialog";
import useUiState from "hooks/Administrator/useUiState";
import incidence from "redux/actions/Administrator/Incidence";
import ShowIncidentDialog from "components/Administrator/Incident/ShowIncidentDialog";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";


const Incident = () => {
  const {data: tableData, filters} = useSelector(
    ({administrator}) => administrator.incidence
  );
  const dispatch = useDispatch();
  const ui = useUiState();

  useEffect(
    () => {
      return () => {
        dispatch(incidence.resetState());
      };
    },
    []
  );

  const onCreate = data => {
    dispatch(incidence.create(data, filters));
  };

  const onShow = data => dispatch(incidence.edit(ui.editingId, data));
  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;
  return (
    <PageContainer>
      <Filters
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && <Table data={tableData} {...ui.tableProps} />}
      <CreateIncidentDialog onSubmit={onCreate} {...ui.createFormProps} />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ShowIncidentDialog
        initialData={editing}
        onSubmit={onShow}
        {...ui.editFormProps}
      />
      <ErrorAlert />
    </PageContainer>
  );
};

export default Incident;
