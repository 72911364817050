import React from 'react';

import useForm from 'hooks/Common/useForm';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import TransferSelect from 'containers/Administrator/TransferSelect';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import TableComponent from 'components/Common/Tables/Table';
import StyledTableRow from 'components/Common/Tables/StyledTableRow';
import StyledTableCell from 'components/Common/Tables/StyledTableCell';
import IncidenceTypeSelect from 'containers/Administrator/IncidentTypeSelect';

import Box from '@material-ui/core/Box';
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {fetchAllIncidenceTypeSelect} from "../../../../../services/IncidenceTypeSelectService";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import EditProductQuantityDialog from "./EditProductQuantityDialog";

const Form = ({ id, onSubmit }) => {
  const { formData, setFormData, handleSubmit } = useForm({
    transfer: null,
    products: [],
  });
  const {data: incidentTypes} = useFetch(fetchAllIncidenceTypeSelect, {}, []);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const onTransferChange = value => {
    setFormData('transfer', value);
    setFormData('products', value.products.map(p => ({ id: p.id, amount: '', incident: null })));
  };

  const onProductAmountChange = (data) => {
    const {quantity} = data;
    const newProducts = [...formData.products];
    const index = newProducts.findIndex(p => p.id === selectedProduct.id);
    newProducts[index] = { ...newProducts[index], amount: quantity };
    setFormData('products', newProducts);
    setSelectedProduct(null);
  };

  const onProductIncidentChange = (id, incident) => {
    const newProducts = [...formData.products];
    const index = newProducts.findIndex(p => p.id === id);
    newProducts[index] = { ...newProducts[index], incident };
    setFormData('products', newProducts);
  };

  return (
      <React.Fragment>
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
          <GridContainer spacing={4}>
            <Grid item xs={12}>
              <TransferSelect
                  id="create-incident-transfer"
                  label="Traspaso"
                  value={formData.transfer}
                  onChange={value => onTransferChange(value)}
              />
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                  fullWidth
                  value={formData.transfer?.entityNameOrigin || ''}
                  id="create-incident-origin-entity"
                  label="Entidad origen"
                  disabled
              />
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                  fullWidth
                  value={formData.transfer?.campusNameOrigin || ''}
                  id="create-incident-origin-campus"
                  label="Sede origen"
                  disabled
              />
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                  fullWidth
                  value={formData.transfer?.warehouseNameOrigin || ''}
                  id="create-incident-origin-warehouse"
                  label="Almacén origen"
                  disabled
              />
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                  fullWidth
                  value={formData.transfer?.entityNameDestiny || ''}
                  id="create-incident-destination-entity"
                  label="Entidad destino"
                  disabled
              />
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                  fullWidth
                  value={formData.transfer?.campusNameDestiny || ''}
                  id="create-incident-destination-campus"
                  label="Sede destino"
                  disabled
              />
            </Grid>
            <Grid item xs={4}>
              <OutlinedInput
                  fullWidth
                  value={formData.transfer?.warehouseNameDestiny || ''}
                  id="create-incident-destination-warehouse"
                  label="Almacén destino"
                  disabled
              />
            </Grid>
          </GridContainer>
          {formData.transfer?.products && (
              <Box marginTop={4}>
                <TableComponent
                    data={formData.transfer?.products || []}
                    head={
                      <StyledTableRow>
                        <StyledTableCell>Producto</StyledTableCell>
                        <StyledTableCell>Stock</StyledTableCell>
                        <StyledTableCell>Stock actual</StyledTableCell>
                        <StyledTableCell>Cantidad</StyledTableCell>
                        <StyledTableCell width="40%">Incidente</StyledTableCell>
                        <StyledTableCell/>
                      </StyledTableRow>
                    }
                    renderRow={({row}) => (
                        <StyledTableRow>
                          <StyledTableCell>{row.name}</StyledTableCell>
                          <StyledTableCell>{row.stock}</StyledTableCell>
                          <StyledTableCell>{row.currentStock}</StyledTableCell>
                          <StyledTableCell>{formData?.products?.find(p => p.id === row.id)?.amount || 0}</StyledTableCell>
                          <StyledTableCell>
                            <IncidenceTypeSelect
                                id={`create-incident-product-${row.id}-incident-type`}
                                label="Incidencia"
                                value={formData.products.find(p => p.id === row.id)?.incident}
                                onChange={value => onProductIncidentChange(row.id, value)}
                                data={incidentTypes}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton
                              onClick={() => setSelectedProduct(row)}
                            >
                              <EditIcon/>
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                    )}
                    getRowKey={row => row.id}
                />
              </Box>
          )}
        </form>
        <EditProductQuantityDialog
          open={!!selectedProduct}
          onSubmit={onProductAmountChange}
          initialQuantity={formData?.products?.find(p => p.id === selectedProduct?.id)?.amount || 0}
          onClose={() => setSelectedProduct(null)}
        />
      </React.Fragment>

  );
};

export default Form;
