import {
  APPLY_CONFIG_USERS_FILTERS,
  EDIT_CONFIG_USER,
  EDIT_CONFIG_USER_STATE,
  FETCH_ALL_CONFIG_USERS,
  RESET_CONFIG_USERS_FILTERS,
  RESET_CONFIG_USERS_STATE,
  SET_CURRENT_CONFIG_USER
} from "../../types/Configuration/UsersTypes";

const INIT_STATE = {
  data: {},
  filters: {
    email: "",
    role: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CONFIG_USERS: {
      let users = {};
      action.payload.map(u => {
        users[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: users
      };
    }

    case SET_CURRENT_CONFIG_USER: {
      return {
        ...state,
        current: action.payload
      };
    }

    case EDIT_CONFIG_USER: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }

    case EDIT_CONFIG_USER_STATE: {
      const id = action.payload.id;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            state: action.payload.state
          }
        }
      };
    }

    case APPLY_CONFIG_USERS_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }

    case RESET_CONFIG_USERS_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }

    case RESET_CONFIG_USERS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
