import React from "react";
import Box from "@material-ui/core/Box";
import CmtAvatar from "../../../../@coremat/CmtAvatar";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import AppTextInput from "../../../../@jumbo/components/Common/formElements/AppTextInput";
import Button from "@material-ui/core/Button";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import {useDropzone} from "react-dropzone";
import PhoneInput from "./PhoneInput";
import {StyledPaper} from "../../../Guardian/AddAdvance/Form/styles";
import FilesArea from "./FilesArea";

const GeneralForm = ({formData, setFormData, handleDialog, onNext, errors}) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      setFormData("profile_pic", {
        file: acceptedFiles[0],
        link: URL.createObjectURL(acceptedFiles[0])
      });
    }
  });
  return (
    <Box mt={8}>
      <Box
        display="flex"
        flexDirection={{xs: "column", md: "row"}}
        alignItems="center"
        mb={{xs: 6, md: 5}}
      >
        <GridContainer>
          <Grid item xs={12} sm={6}>
            <OutlinedInput
              fullWidth
              id={"employee-first-name"}
              label={<IntlMessages id={"rrhh.employees.form.first.name"} />}
              value={formData.firstName}
              onChange={value => setFormData("firstName", value)}
              hasError={errors.firstName.length > 0}
              error={errors.firstName[0]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedInput
              fullWidth
              id={"employee-last-name"}
              label={<IntlMessages id={"rrhh.employees.form.last.name"} />}
              value={formData.lastName}
              onChange={value => setFormData("lastName", value)}
              hasError={errors.lastName.length > 0}
              error={errors.lastName[0]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <OutlinedDatePicker
              fullWidth
              id={"employee-birthdate"}
              label={<IntlMessages id={"rrhh.employees.form.birthdate"} />}
              value={formData.birthDate}
              onChange={value => setFormData("birthDate", value)}
              hasError={errors.birthDate.length > 0}
              error={errors.birthDate[0]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <StyledPaper elevation={3} outlined="true">
              <p>
                <IntlMessages id={"rrhh.employees.form.nif"} />:{" "}
                {formData.nif ? formData.nif : ""}
              </p>
            </StyledPaper>
            {/*<OutlinedInput*/}
            {/*    fullWidth*/}
            {/*    id={"employee-nif"}*/}
            {/*    label={<IntlMessages id={"rrhh.employees.form.nif"}/>}*/}
            {/*    value={formData.nif}*/}
            {/*    onChange={value => setFormData('nif', value)}*/}

            {/*/>*/}
          </Grid>

          <Grid item xs={12} sm={6}>
            <OutlinedInput
              fullWidth
              id={"employee-email"}
              label={<IntlMessages id={"rrhh.employees.form.email"} />}
              value={formData.email}
              onChange={value => setFormData("email", value)}
              hasError={errors.email.length > 0}
              error={errors.email[0]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <OutlinedInput
              fullWidth
              id={"employee-general-phone"}
              label={<IntlMessages id={"rrhh.employees.form.phone"} />}
              value={formData.generalPhone}
              onChange={number => setFormData("generalPhone", number)}
              hasError={errors.generalPhone.length > 0}
              error={errors.generalPhone[0]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <OutlinedInput
              fullWidth
              id={"employee-general-i-button"}
              label={<IntlMessages id={"rrhh.employees.form.i.button"} />}
              value={formData.generalIButton}
              onChange={value => setFormData("generalIButton", value)}
              hasError={errors.generalIButton.length > 0}
              error={errors.generalIButton[0]}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <OutlinedInput
              fullWidth
              id={"employee-driver-license"}
              label={<IntlMessages id={"rrhh.employees.form.driver.license"} />}
              value={formData.driverLicense}
              onChange={value => setFormData("driverLicense", value)}
              hasError={errors.driverLicense.length > 0}
              error={errors.driverLicense[0]}
            />
          </Grid>
        </GridContainer>
        <Box
          {...getRootProps()}
          mr={{xs: 0, md: 5}}
          mb={{xs: 3, md: 0}}
          className="pointer"
          ml={8}
        >
          <input {...getInputProps()} />
          <CmtAvatar size={70} src={formData.profile_pic.link} />
        </Box>
      </Box>
      <FilesArea
        id={"general-files"}
        files={formData.generalFiles}
        setFiles={files => setFormData("generalFiles", files)}
        setDeleteFiles={files =>
          setFormData("generalDeleteFiles", [
            ...formData.generalDeleteFiles,
            ...files
          ])}
      />
      <Box display="flex" justifyContent="flex-end" mb={4}>
        <Button onClick={handleDialog}>
          <IntlMessages id={"rrhh.employees.form.cancel"} />
        </Button>
        <Box ml={2}>
          <Button variant="contained" color="primary" onClick={onNext}>
            <IntlMessages id={"rrhh.employees.form.next"} />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default GeneralForm;
