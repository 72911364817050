import React, {useState} from "react";

import MuiTableRow from "@material-ui/core/TableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
// import IconButton from '@material-ui/core/IconButton';

const TableRow = ({rowData}) => {
  return (
    <React.Fragment>
      <MuiTableRow>
        <StyledTableCell />
        <StyledTableCell align="left">{rowData.name}</StyledTableCell>
        <StyledTableCell align="left">{rowData.startDate}</StyledTableCell>
        <StyledTableCell align="left">{rowData.totalWorked}</StyledTableCell>
        <StyledTableCell align="left">{rowData.totalPause}</StyledTableCell>
        <StyledTableCell align="left">{rowData.endDate}</StyledTableCell>
      </MuiTableRow>
    </React.Fragment>
  );
};

export default TableRow;
