import React from "react";
import PageContainer from "../../../../../../components/Logistic/ChecklistTypes/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import ChecklistTypesTable from "../../../../../../components/Logistic/ChecklistTypes/ChecklistTypesTable";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {
  resetCurrentChecklistType,
  updateChecklistTypeState
} from "../../../../../../redux/actions/Logistic/ChecklistTypes";
import AddChecklistType from "../../../../../../components/Logistic/ChecklistTypes/AddChecklistType";
import ChecklistTypeDetail from "../../../../../../components/Logistic/ChecklistTypes/ChecklistTypeDetail";

const ChecklistTypes = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.checklistTypes);
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  return (
    <PageContainer>
      <ChecklistTypesTable
        openEdit={() => setOpenEdit(true)}
        openRestore={() => setOpenRestore(true)}
        openDelete={() => setOpenDelete(true)}
      />

      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"logistic.distribution.checklist.types.delete.description"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentChecklistType());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(updateChecklistTypeState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"logistic.distribution.checklist.types.restore.description"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentChecklistType());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(updateChecklistTypeState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <AddChecklistType open={openAdd} setOpen={setOpenAdd} />
      <ChecklistTypeDetail open={openEdit} setOpen={setOpenEdit} />
    </PageContainer>
  );
};

export default ChecklistTypes;
