import React from "react";
import {GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";

function ExportToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default ExportToolbar;