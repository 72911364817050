import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ServiceSelect from "./ServiceSelect";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import TaxSelect from "../../TaxSelect";

const INIT_DATA = {
    id: '',
    serviceId: '',
    serviceName: '',
    description: '',
    quantity: 1,
    unitPrice: 1,
    discount: 0,
    tax: '',
    taxName: '',
    percentage: '',
    detailId: '',
}

const ServiceForm = ({id, service = {}, isConcept = false, onSubmit}) => {
    const {formData, setFormData, handleSubmit, setValues} = useForm(INIT_DATA);
    React.useEffect(() => {
        service.id && setValues(service);
    }, []);

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    {isConcept
                        ? <OutlinedInput
                            name='serviceName'
                            label={<IntlMessages id='concept' />}
                            value={formData.serviceName}
                            onChange={value => setFormData('serviceName', value)}
                            fullWidth
                            required
                        />
                        : <ServiceSelect
                            name='serviceId'
                            label={<IntlMessages id='service' />}
                            value={formData.serviceId}
                            onChange={({value, name, unitPrice, taxId}) => {
                                setFormData('serviceId', value);
                                setFormData('serviceName', name);
                                setFormData('unitPrice', unitPrice);
                                setFormData('tax', taxId);
                            }}
                            required
                        />
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='description'
                        label={<IntlMessages id='observations' />}
                        value={formData.description}
                        onChange={value => setFormData('description', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='quantity'
                        type='number'
                        label={<IntlMessages id='quantity' />}
                        value={formData.quantity}
                        onChange={value => setFormData('quantity', value)}
                        fullWidth
                        inputProps={{min: 1}}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='unitPrice'
                        type='number'
                        label={<IntlMessages id='unit.price' />}
                        value={formData.unitPrice}
                        onChange={value => setFormData('unitPrice', value)}
                        fullWidth
                        inputProps={{min: 1, step: 0.01}}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='discount'
                        type='number'
                        label={<IntlMessages id='discount' />}
                        value={formData.discount}
                        onChange={value => setFormData('discount', value)}
                        fullWidth
                        inputProps={{min: 0}}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TaxSelect
                        value={formData.tax}
                        onChange={({value, taxName, percentage}) => {
                            setFormData('tax', value);
                            setFormData('percentage', percentage);
                            setFormData('taxName', taxName);
                        }}
                        required
                        initValue={service?.tax || null}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default ServiceForm;