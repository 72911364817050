import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {editElevatorDetail} from "../../../../../../redux/actions/ElevatorsManagement/Details";

const EditDetailDialog = ({open, setOpen}) => {
    const {current} = useSelector(({elevatorsManagement}) => elevatorsManagement.details);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(editElevatorDetail(current, data));
        setOpen(false);
    }
    return(
        <BaseDialog
            id='edit-detail-form'
            title={<IntlMessages id={"elevators.management.elevators.detail.type.edit.title"}/>}
            open={open}
            setOpen={setOpen}
            initData={{
                name: current?.name,
            }}
            onSubmit={onSubmit}
        />
    );
}

export default EditDetailDialog;