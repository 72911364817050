import React from 'react';
import EventsGraph from "./EventsGraph";
import EventsByDriversGraph from "./EventsByDriversGraph";
import EventsByVehicle from "./EventsByVehicle";
import EventsByGravity from "./EventsByGravity";
import EventsByGroup from "./EventsByGroup";
import EventsDetailsTable from "./EventsDetailsTable";
import {Paper} from "@material-ui/core";
import {colors} from "./constants";
import Typography from "@material-ui/core/Typography";
import {useIntl} from "react-intl";
import LegendIndicator from "../Common/LegendIndicator";
import '../Common/styles.css';

const ExceedingSpeed = () => {
    const intl = useIntl();
    return (
        <div className='graph-container'>
            <Paper className='legend cols-6'>
                <LegendIndicator
                    text='<5 Leve'
                    color={colors.light}
                />
                <LegendIndicator
                    text='>5 y <10 Moderado'
                    color={colors.moderate}
                />
                <LegendIndicator
                    text='>10 Grave'
                    color={colors.serious}
                />
            </Paper>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['exceeds.speed.events']}
                </Typography>
                <EventsGraph/>
            </div>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['exceeds.speed.events']} ${intl.messages['by']} ${intl.messages['gravity']}`}
                </Typography>
                <EventsByGravity/>
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['exceeds.speed.events']} ${intl.messages['by']} ${intl.messages['group']}`}
                </Typography>
                <EventsByGroup/>
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['exceeds.speed.events']} ${intl.messages['by']} ${intl.messages['vehicle']}`}
                </Typography>
                <EventsByVehicle/>
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['exceeds.speed.events']} ${intl.messages['by']} ${intl.messages['driver']}`}
                </Typography>
                <EventsByDriversGraph/>
            </div>
            {/*<div className='heatmap'>*/}
            {/*    <HeatMap />*/}
            {/*</div>*/}
            {/*<div className='graph'>*/}
            {/*    <Typography*/}
            {/*        align='center'*/}
            {/*        variant='subtitle2'*/}
            {/*        component={Paper}*/}
            {/*    >*/}
            {/*        {`${intl.messages['exceeds.speed.events']} ${intl.messages['by']} ${intl.messages['zone']}`}*/}
            {/*    </Typography>*/}
            {/*    <EventsByZone/>*/}
            {/*</div>*/}
            {/*<div className='graph'>*/}
            {/*    <EventsByMaxSpeed />*/}
            {/*</div>*/}
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['detail']} ${intl.messages['exceeds.speed.events']}`}
                </Typography>
                <EventsDetailsTable/>
            </div>
        </div>

    );
};

export default ExceedingSpeed;