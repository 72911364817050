import axiosInstance from "../utils/axiosInstance";
import Headquarter from "../domain/Headquarter";
import Response from "../domain/Response";

export const fetchAllHeadquartersByClientId = async clientId => {
  try {
    const {data} = await axiosInstance.post("/Entidad_Sedes/sel", {
      nu_id_entidad: clientId
    });
    return data.map(headquarter => Headquarter.map(headquarter));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addHeadquarterToClient = async (client, data) => {
  try {
    const result = await axiosInstance.post("/Entidad_Sedes/ins", {
      nu_id_entidad: client.id,
      vc_nombre_sede: data.name,
      bi_sede_fiscal: data.fiscal,
      vc_calle: data.address,
      vc_numero: data.number,
      vc_cod_postal: data.postalCode,
      vc_municipio: data.municipality,
      vc_provincia: data.province,
      vc_pais: data.country,
      nu_latitud: data.latitude,
      nu_longitud: data.longitude,
      vc_ubigeo: data.ubigeo.id,
    });
    const response = Response.map(result.data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateHeadquarterToClient = async (client, data) => {
  try {
    const result = await axiosInstance.post("/Entidad_Sedes/upd", {
      nu_id_entidad: client.id,
      nu_id_entidad_sede: data.id,
      vc_nombre_sede: data.name,
      bi_sede_fiscal: data.fiscal,
      vc_calle: data.address,
      vc_numero: data.number,
      vc_cod_postal: data.postalCode,
      vc_municipio: data.municipality,
      vc_provincia: data.province,
      vc_pais: data.country,
      nu_latitud: data.latitude,
      nu_longitud: data.longitude,
      vc_ubigeo: data.ubigeo.id,
    });
    const response = Response.map(result.data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};


export const updateHeadquarterState = async (clientId, headquarterId) => {
  try {
    const result = await axiosInstance.post("/Entidad_Sedes/del", {
      nu_id_entidad: clientId,
      nu_id_entidad_sede: headquarterId,
    });
    const response = Response.map(result.data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
}
