import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  fund: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.cashManagers.editCashManagerDialog.field.fund.error.empty" />
      )
    }
  ],
  costCenter: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
};
