import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const EditServiceDialog = ({selectedService, setSelectedService}) => {
  const id = "edit-service-dialog";
  const onClose = () => {
    setSelectedService(null);
  };
  return (
    <FormDialog
      id={id}
      isOpen={Boolean(selectedService)}
      onClose={onClose}
      title={
        <IntlMessages
          id={
            "logistic.distribution.daily.operation.services.list.edit.dialog.title"
          }
        />
      }
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      submitText={<IntlMessages id={"label.form.save"} />}
    >
      {selectedService && (
        <Form
          id={id}
          service={selectedService}
          setService={setSelectedService}
        />
      )}
    </FormDialog>
  );
};

export default EditServiceDialog;
