import React from "react";
import PropTypes from "prop-types";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import MaterialUICheckbox from "@material-ui/core/Checkbox";

const Checkbox = ({
  checked,
  onChange,
  name,
  label,
  fullWidth,
  disabled,
  checkBoxStyle,
  color = "primary"
}) => (
  <FormControl fullWidth={fullWidth}>
    <FormControlLabel
      control={
        <MaterialUICheckbox
          style={checkBoxStyle}
          checked={checked}
          disabled={disabled}
          onChange={e => onChange(e.target.checked)}
          name={name}
          color={color}
        />
      }
      label={label}
    />
  </FormControl>
);

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.node,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  checked: false,
  onChange: () => {},
  name: "",
  label: "",
  fullWidth: false,
  disabled: false
};

export default Checkbox;
