import axiosInstance, {CancelToken} from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import WorkshopTaskType from "../../../domain/FleetManagement/DailyOperations/WorkshopTaskType";
import WorkshopTaskInformation from "../../../domain/FleetManagement/DailyOperations/WorkshopTaskInformation";
import WorkshopTaskState from "../../../domain/FleetManagement/DailyOperations/WorkshopTaskState";
import Response from "../../../domain/Response";

export const getAllWorkshopTaskTypes = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/sel_tipo_trabajo",
            {
                nu_id_entidad: getCompanyEntityId()
            });
        return data.map(d => WorkshopTaskType.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getAllWorkshopTaskStates = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/sel_estados_trabajo",
            {
                nu_id_entidad: getCompanyEntityId()
            });
        return data.map(d => WorkshopTaskState.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

let fetchInfoCancelToken = null;
const cancelPrevFetchInfo = () => {
    fetchInfoCancelToken && fetchInfoCancelToken();
}

export const getAllWorkshopTaskInformation = async (request) => {
    try {
        cancelPrevFetchInfo();
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/sel_trabajo_informacion",
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_vehiculo: request.vehicle,
                nu_id_tipo_trabajo: request.type,
            }, {
                cancelToken: new CancelToken(c => (fetchInfoCancelToken = c))
            });
        return data.map(d => WorkshopTaskInformation.map(d));
    } catch (e) {
        return [];
        // return Promise.reject(e);
    }
};

export const addWorkshopTaskToRequest = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/VehiculoTaller/ins_trabajo_sol_taller',
            {
                nu_id_solicitud_taller: request.workshopRequestId,
                nu_id_solicitud_taller_trabajos: request.id || null,
                nu_id_tipo_trabajo: request.type.id || request.type,
                nu_id_trabajo_informacion: request?.information?.id || request.information,
                vc_observaciones: request.observations,
                vc_observaciones_taller: request.workshopObservations,
                nu_costo: request.cost,
                nu_id_solicitud_taller_trabajo_estado: request.status.id || request.status
            }
        )
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}