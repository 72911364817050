import MonitoringState from "./MonitoringState";

class MonitoringDispatchDestination {
  constructor(
    id,
    name,
    workOrder,
    estimatedDate,
    state,
    vehicle,
    driver,
    latitude,
    longitude,
    rejectReason
  ) {
    this.id = id;
    this.name = name;
    this.workOrder = workOrder;
    this.estimatedDate = estimatedDate;
    this.state = state;
    this.vehicle = vehicle;
    this.driver = driver;
    this.latitude = latitude;
    this.longitude = longitude;
    this.rejectReason = rejectReason;
  }

  static map = destination => {
    return new MonitoringDispatchDestination(
      destination.idDespacho,
      destination.despacho,
      destination.ot,
      destination.fechaEstimada,
      MonitoringState.map(destination.estado || destination.estados)
        .description || "",
      destination.vehiculo,
      destination.conductor,
      Number(destination.latitud),
      Number(destination.longitud),
      destination.motivoRechazo
    );
  };
}

export default MonitoringDispatchDestination;
