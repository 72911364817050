import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "components/Common/Forms/Select/ActiveSelect";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";

const initialData = {
  active: true,
  description: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <ActiveSelect
            id="rejection-types-state-filter"
            value={formData.active}
            onChange={value => setFormData("active", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.description}
            id="description-filter"
            label={
              <IntlMessages id="administrator.rejectionTypes.filters.description" />
            }
            onChange={value => setFormData("description", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
