import React from "react";
import {Box} from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {
  onUpdateContact,
  setCurrentContact
} from "../../../../redux/actions/RRHH/Employees";
import ContactDetail from "../ContactDetail";
import AppHeader from "./AppHeader";
import Sidebar from "../Sidebar";
import ContactsList from "../ContactsList";
import EmployeeFormDialog from "../EmployeeFormDialog";
import moment from "moment";
import AddEmployee from "../AddEmployee";

const Dashboard = props => {
  const classes = useStyles();
  const {isSideBarCollapsed, currentContact} = useSelector(
    state => state.rrhh.employees
  );
  const [ viewMode, setViewMode ] = React.useState("table");
  const [ showContactDetail, setShowContactDetail ] = React.useState(false);
  const [ openCreateDialog, setOpenCreateDialog ] = React.useState(false);
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const dispatch = useDispatch();

  const onChangeViewMode = mode => {
    setViewMode(mode);
  };

  const onShowContactDetail = contact => {
    dispatch(setCurrentContact(contact));
    setShowContactDetail(true);
  };

  const onHideContactDetail = () => {
    dispatch(setCurrentContact(null));
    setShowContactDetail(false);
  };

  const onClickCreateContact = () => {
    setOpenAddDialog(true);
  };

  const onClickEditContact = contact => {
    dispatch(setCurrentContact(contact));
    setOpenCreateDialog(true);
  };

  const onCloseComposeDialog = () => {
    dispatch(setCurrentContact(null));
    setOpenCreateDialog(false);
  };

  return (
    <Box className={classes.inBuildAppCard}>
      <AppHeader onChangeViewMode={onChangeViewMode} viewMode={viewMode} />
      <Box
        className={clsx(
          classes.inBuildAppContainer,
          isSideBarCollapsed ? "collapsed" : ""
        )}
      >
        <Sidebar onClickCreateContact={onClickCreateContact} />
        <ContactsList
          viewMode={viewMode}
          onShowContactDetail={onShowContactDetail}
          onClickEditContact={onClickEditContact}
        />
      </Box>
      {showContactDetail &&
      currentContact && (
        <ContactDetail
          open={showContactDetail}
          handleDialog={onHideContactDetail}
        />
      )}
      {/*{openCreateDialog && <CreateContact open={openCreateDialog} handleDialog={onCloseComposeDialog}/>}*/}
      {openCreateDialog && (
        <EmployeeFormDialog
          open={openCreateDialog}
          handleDialog={onCloseComposeDialog}
          onSave={(currentContact, generalData, specificData) => {
            dispatch(
              onUpdateContact({
                ...currentContact,
                ...generalData,
                ...specificData,
                commencementDate: moment(specificData.commencementDate).format(
                  "DD/MM/YYYY"
                ),
                terminationDate: moment(specificData.terminationDate).format(
                  "DD/MM/YYYY"
                ),
                birthDate: moment(generalData.birthDate).format("DD/MM/YYYY")
              })
            );
          }}
        />
      )}
      {openAddDialog && (
        <AddEmployee setOpen={setOpenAddDialog} open={openAddDialog} />
      )}
    </Box>
  );
};

export default Dashboard;
