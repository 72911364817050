import React from 'react';
import {Grid, MenuItem} from "@material-ui/core";
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {fetchAllVehicleSelect} from "../../../../../services/VehicleServiceSelect";

const HopperSelect = ({value, setValue, edit, errors}) => {
    const [hoppers, setHoppers] = React.useState([]);

    React.useEffect(() => {
        fetchAllVehicleSelect()
            .then(vehicles => setHoppers(vehicles))
            .catch(e => setHoppers([]));
    }, []);
    return(
        <Grid item sm={5}>
            <Select
                value={value}
                onChange={e => setValue(e.target.value)}
                id="hopper-select"
                label={<IntlMessages id={"logistic.work.orders.dialog.detail.hopper.label"} />}
                fullWidth
                disabled={edit}
                error={errors?.hopper?.length > 0}
                errorMessage={errors?.hopper[0]}>
                <MenuItem value="">
                    <IntlMessages id="logistic.work.orders.dialog.detail.hopper.select.one" />
                </MenuItem>
                {hoppers.length > 0 ?
                    hoppers.map(hopper => (
                    <MenuItem key={hopper.id} value={hopper.id}>
                        {hopper.name}
                    </MenuItem>
                )) :
                    <MenuItem key={value} value={value}>

                    </MenuItem>}}
            </Select>
        </Grid>
    );
}

export default HopperSelect;