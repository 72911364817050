import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";

export const getAllElevatorsManufacturers = async () => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Fabricante/sel", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(manufacturer => ({
            id: manufacturer.nu_id_tipo_fabricante,
            name: manufacturer.vc_desc_tipo_fabricante,
            state: mapToBoolean(manufacturer.bi_estado)
        }));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const createElevatorManufacturer = async manufacturer => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Fabricante/ins", {
            nu_id_entidad: getCompanyEntityId(),
            vc_desc_tipo_fabricante: manufacturer.name
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateElevatorManufacturer = async (manufacturerId, name) => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Fabricante/upd", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_tipo_fabricante: manufacturerId,
            vc_desc_tipo_fabricante: name
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateElevatorManufacturerState = async (manufacturerId, state) => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Fabricante/del", {
            nu_id_tipo_fabricante: manufacturerId,
            bi_estado: state
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};