import React, {useState} from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {CircularProgress} from "@material-ui/core";

const FiltersContainer = ({
  children,
  onReset,
  onClose,
  active,
  showLoadingIndicator,
  ...rest
}) => {
  const [ filtersChecked, setFiltersChecked ] = useState(active);

  const handleFiltersCheckedChange = e => {
    const checked = e.target.checked;
    setFiltersChecked(checked);
    if (!checked) onClose && onClose();
    onReset();
  };

  return (
    <Box display="flex" flexDirection="column" {...rest}>
      <Box
        marginBottom={filtersChecked ? 5 : 0}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FormControlLabel
          control={
            <Switch
              checked={filtersChecked}
              onChange={handleFiltersCheckedChange}
              name="filtersChecked"
              color="primary"
            />
          }
          label={<IntlMessages id="common.forms.filters.filtersSwitch.label" />}
        />
        {showLoadingIndicator && <CircularProgress size={30} />}
      </Box>
      <Box>{filtersChecked && children}</Box>
    </Box>
  );
};

FiltersContainer.propTypes = {
  children: PropTypes.node,
  onReset: PropTypes.func,
  active: PropTypes.bool,
  showLoadingIndicator: PropTypes.bool
};

FiltersContainer.defaultProps = {
  children: null,
  onReset: () => {},
  active: false,
  showLoadingIndicator: false
};

export default FiltersContainer;
