import {
  CLEAR_BREAKDOWN_DESTINATIONS_LIST,
  CLOSE_BREAKDOWN_DIALOG,
  FETCH_BREAKDOWN_FAILED,
  FETCH_BREAKDOWN_STARTED,
  FETCH_BREAKDOWN_SUCCESS, OPEN_BREAKDOWN_DIALOG,
  RESET_BREAKDOWN_STATE, SET_BREAKDOWN_FILTERS, SET_CURRENT_BREAKDOWN_DESTINATION
} from "../../types/Administrator/WorkOrdersBreakDownTypes";

const INIT_STATE = {
  detail: null,
  destinations: [],
  isLoading: false,
  dialogs: [],
  currentDestination: null,
  filters: {
    origin: '',
    destination: '',
    planningPlace: '',
    originAndDestination: '',
    ot: ''
  }
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_BREAKDOWN_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        detail: action.payload.detail,
        destinations: action.payload.destinations,
        isLoading: false
      };
    }
    case FETCH_BREAKDOWN_FAILED: {
      return {
        ...state,
        detail: null,
        destinations: [],
        isLoading: false
      };
    }
    case RESET_BREAKDOWN_STATE: {
      return {
        ...INIT_STATE
      };
    }
    case OPEN_BREAKDOWN_DIALOG: {
      return {
        ...state,
        dialogs: [
            ...state.dialogs,
            action.payload,
        ]
      }
    }
    case CLOSE_BREAKDOWN_DIALOG: {
      return {
        ...state,
        dialogs: state.dialogs.filter(d => d !== action.payload)
      }
    }
    case SET_CURRENT_BREAKDOWN_DESTINATION: {
      return {
        ...state,
        currentDestination: action.payload
      }
    }
    case SET_BREAKDOWN_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      }
    }
    case CLEAR_BREAKDOWN_DESTINATIONS_LIST: {
      return {
        ...state,
        destinations: []
      }
    }
    default: {
      return state;
    }
  }
};
