import React from "react";
import PropTypes from "prop-types";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import StyledTableCell from "../../StyledTableCell";
import StyledTableRow from "../../StyledTableRow";

const DeleteTableRow = ({children, onDelete}) => (
  <StyledTableRow>
    {children}
    <StyledTableCell align="right">
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
);

DeleteTableRow.propTypes = {
  children: PropTypes.node,
  onDelete: PropTypes.func
};

DeleteTableRow.defaultProps = {
  children: null,
  onDelete: () => {}
};

export default DeleteTableRow;
