import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid
} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OptionForm from "./OptionForm";
import useForm from "../../../../../../hooks/Common/useForm";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useStyles} from "../../styles";
import AddOptionDialog from "./AddOptionDialog";
import {useDispatch, useSelector} from "react-redux";
import {editGroupOfChecklistType} from "../../../../../../redux/actions/Logistic/ChecklistTypes";

const GroupItem = ({group}) => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.checklistTypes);
  const [ edit, setEdit ] = React.useState(false);
  const [ addOptionOpen, setAddOptionOpen ] = React.useState(false);
  const [ expand, setExpand ] = React.useState(false);
  const classes = useStyles();
  const {formData, setFormData, resetForm} = useForm({
    name: group.name
  });
  const onSave = event => {
    event.stopPropagation();
    dispatch(
      editGroupOfChecklistType({...group, ...formData}, current, setEdit)
    );
  };
  const handleChange = (event, isExpanded) => {
    setExpand(!expand);
  };
  return (
    <Box className={classes.groupItem}>
      <Accordion expanded={expand} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`${group.id}-group-accordion`}
          aria-controls={`group-${group.id}-content`}
        >
          <GridContainer spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <OutlinedInput
                id={"name-input"}
                value={formData.name}
                onChange={value => setFormData("name", value)}
                fullWidth
                disabled={!edit}
                label={
                  <IntlMessages
                    id={
                      "logistic.distribution.checklist.types.table.edit.groups.add.name"
                    }
                  />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              {edit ? (
                <Box className={classes.buttonBox}>
                  <ContainedButton
                    text={<IntlMessages id={"form.button.cancel"} />}
                    color={"primary"}
                    onClick={e => {
                      e.stopPropagation();
                      setEdit(false);
                    }}
                  />
                  <ContainedButton
                    text={<IntlMessages id={"form.button.save"} />}
                    color={"primary"}
                    onClick={onSave}
                  />
                </Box>
              ) : (
                <ContainedButton
                  text={<IntlMessages id={"form.button.edit"} />}
                  color={"primary"}
                  onClick={e => {
                    e.stopPropagation();
                    resetForm();
                    setEdit(true);
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <ContainedButton
                text={
                  <IntlMessages
                    id={
                      "logistic.distribution.checklist.types.table.edit.groups.option.add"
                    }
                  />
                }
                color={"primary"}
                onClick={e => {
                  e.stopPropagation();
                  setAddOptionOpen(true);
                }}
              />
            </Grid>
          </GridContainer>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer>
            {group.list.map(item => (
              <OptionForm
                key={`${item.id}-option`}
                option={item}
                checklist={current}
                group={group}
              />
            ))}
          </GridContainer>
        </AccordionDetails>
      </Accordion>
      <AddOptionDialog
        group={group}
        open={addOptionOpen}
        setOpen={setAddOptionOpen}
      />
    </Box>
  );
};

export default GroupItem;
