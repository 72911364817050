import React from "react";
import FiltersContainer from "../../../../Common/Forms/FiltersContainer";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../hooks/Common/useForm";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import ManagementSelect from "./ManagementSelect";
import StateSelect from "./StateSelect";
import SubStateSelect from "./SubStateSelect";
import {
  applyAlertsFilters,
  resetAlertsFilters
} from "../../../../../redux/actions/Logistic/Monitoring";

const Filters = () => {
  const {filters, isLoading} = useSelector(
    state => state.logistic.monitoring.alerts
  );
  const {formData, resetForm, setFormData} = useForm(filters);
  const dispatch = useDispatch();
  return (
    <FiltersContainer
      active
      marginBottom={5}
      showLoadingIndicator={isLoading}
      onClose={() => {}}
    >
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              value={formData.startDate}
              id={"alerts-start-date"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.alerts.filters.start.date"}
                />
              }
              onChange={value => setFormData("startDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              value={formData.endDate}
              id={"alerts-end-date"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.alerts.filters.end.date"}
                />
              }
              onChange={value => setFormData("endDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <ManagementSelect
              selected={formData.management}
              onChange={value => setFormData("management", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <StateSelect
              selected={formData.state}
              onChange={value => setFormData("state", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <SubStateSelect
              selected={formData.subState}
              onChange={value => setFormData("subState", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.vehicle}
              id={"alerts-vehicle"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.alerts.filters.vehicle"}
                />
              }
              onChange={value => setFormData("vehicle", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.guide}
              id={"alerts-guide"}
              label={
                <IntlMessages id={"logistic.monitoring.alerts.filters.guide"} />
              }
              onChange={value => setFormData("guide", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.contact}
              id={"alerts-contact"}
              label={
                <IntlMessages
                  id={"logistic.monitoring.alerts.filters.contact"}
                />
              }
              onChange={value => setFormData("contact", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => dispatch(applyAlertsFilters(formData))}
              color="primary"
            >
              <IntlMessages id={"logistic.monitoring.alerts.filters.apply"} />
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Button
              variant="contained"
              disabled={isLoading}
              color="primary"
              onClick={() => {
                resetForm();
                dispatch(resetAlertsFilters());
              }}
            >
              <IntlMessages id={"logistic.monitoring.alerts.filters.reset"} />
            </Button>
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
