import React, {useState} from 'react';
import {Polygon, useMapEvents} from "react-leaflet";
import Map from "../Map";
import _ from "lodash";
import ContainedButton from "../../../Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const PolygonCreator = ({positions, setPositions}) => {
    const map = useMapEvents({
        click(event) {
            const {lat, lng} = event.latlng;
            setPositions([...positions, [lat, lng]])
            map.setZoom(18);
        }
    })
    return (
        <Polygon  pathOptions={{color: '#3f51b5'}} positions={positions} />
    )
}

const PolygonMap = ({clearMap=false, center = []}) => {
    const [positions, setPositions] = useState([]);
    const getLatitudeCenter = () => {
        return _.mean(positions.map(([lat, x]) => lat)) || center[0] || 0 ;
    }
    const getLongitudeCenter = () => {
        return _.mean(positions.map(([x, lng]) => lng)) || center[1] || 0;
    }
    const onClearMap = () => {
        setPositions([]);
    }
    return (
        <>
            {clearMap &&
                <ContainedButton
                    color="primary"
                    text={<IntlMessages id="clear.map"/>}
                    onClick={onClearMap}
                    style={{
                        margin: '0.5rem 0'
                    }}
                />
            }
            <Map
                latitude={getLatitudeCenter()}
                longitude={getLongitudeCenter()}
                zoom={positions.length > 0? 50 : 13}
            >
                <PolygonCreator
                    positions={positions}
                    setPositions={setPositions}
                />
            </Map>
        </>
    );
};

export default PolygonMap;