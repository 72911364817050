import React from "react";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {useSelector} from "react-redux";
import MultipleSelect from "../../../components/Common/Forms/Select/MultipleSelect";

const DriverPicker = ({id, values, isLoading, onChange}) => {
  const {driver} = useSelector(state => state.administrator.driverSelect);
  return (
    <MultipleSelect
      id={id}
      options={driver}
      value={values}
      onChange={onChange}
      fullWidth
      disabled={isLoading}
      label={
        <IntlMessages id="administrator.dataCollectionHistory.filters.driver" />
      }
      headerText={
        <IntlMessages id="administrator.dataCollectionHistory.filters.driver.header" />
      }
      noOptionsText={
        <IntlMessages id="administrator.dataCollectionHistory.filters.driver.no.options" />
      }
    />
  );
};

export default DriverPicker;
