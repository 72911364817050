import {combineReducers} from "redux";
import technicalSpecification from "./TechnicalSpecification";
import Insurances from "./Insurances";
import Infractions from "./Infractions";
import Accidents from "./Accidents";
import Workshop from "./Workshop";
import Refueling from "./Refueling";
import Tires from "./Tires";
import Reserves from "./Reserves";
import TachographFiles from "./TachographFiles";
import TachographAlerts from "./TachographAlerts";
import TachographTraffic from "./TachographTraffic";
import Documents from "./Documents";
import MaintenanceTypes from "./MaintenanceTypes";
import MaintenancePlans from "./MaintenancePlans";
import VehicleMaintenancePlans from "./VehicleMaintenancePlans";
import VehicleMaintenances from "./VehicleMaintenances";
import CorrectiveTypes from "./CorrectiveTypes";
import VehicleCorrectives from "./VehicleCorrectives";
import WorkshopRequests from "./WorkshopRequests";
import DocumentsTypes from "./DocumentsTypes";
import VehicleReports from "./VehicleReports";
import SinisterTypes from "./SinisterTypes";
import filesReader from "./filesReader";
import tachographCards from "./tachographCards";

export default combineReducers({
  documentsTypes: DocumentsTypes,
  technicalSpecification: technicalSpecification,
  documents: Documents,
  insurances: Insurances,
  infractions: Infractions,
  accidents: Accidents,
  workshopRequests: WorkshopRequests,
  workshop: Workshop,
  refueling: Refueling,
  tires: Tires,
  vehicleReports: VehicleReports,
  reserves: Reserves,
  tachographFiles: TachographFiles,
  tachographAlerts: TachographAlerts,
  tachographTraffic: TachographTraffic,
  tachographCards,
  maintenanceTypes: MaintenanceTypes,
  maintenancePlans: MaintenancePlans,
  vehicleMaintenancePlans: VehicleMaintenancePlans,
  vehicleMaintenances: VehicleMaintenances,
  correctiveTypes: CorrectiveTypes,
  sinisterTypes: SinisterTypes,
  vehicleCorrectives: VehicleCorrectives,
  filesReader,
});
