import {
  ADD_LABEL,
  CREATE_CONTACT,
  DELETE_CONTACT,
  DELETE_LABEL_ITEM,
  GET_CONTACT_COUNTS,
  GET_CONTACTS_LIST,
  GET_LABELS_LIST,
  SET_CURRENT_CONTACT,
  SET_FILTER_TYPE,
  TOGGLE_SIDEBAR_COLLAPSED,
  UPDATE_CONTACT,
  UPDATE_CONTACT_LABEL,
  UPDATE_LABEL_ITEM,
  UPDATE_STARRED_STATUS
} from "../../../@jumbo/constants/ActionTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  updateEmployeeTag,
  getAllEmployeesByFilter,
  getEmployeesCounters,
  markFavoriteEmployee,
  unmarkFavoriteEmployee,
  getEmployeeGeneralDetails,
  getEmployeeSpecificDetails,
  getEmployeeFiles,
  updateEmployeeGeneralData,
  updateEmployeeSpecificData,
  createOrUpdateFile,
  deleteEmployeeFile,
  updateEmployeePhoto,
  addEmployeeByNif,
  addEmployee
} from "../../../services/EmployeeService";
import {idGenerator} from "../../../@jumbo/utils/commonHelper";
import {
  createTag,
  deleteTag,
  fetchAllTagsByType,
  updateTag
} from "../../../services/TagService";
import {getFileUrlByName} from "../../../services/FileService";

const baseFoldersList = [
  {id: 1, name: "Empleados", slug: "contacts"},
  {id: 2, name: "Starred Contacts", slug: "starred"}
];

const baseLabelsList = [
  {id: 1, name: "Banking", slug: "banking", color: "#FF8C00"},
  {id: 2, name: "Company", slug: "company", color: "#00C4B4"},
  {id: 3, name: "Payments", slug: "payments", color: "#0F9AF7"}
];

const baseContactsList = [
  {
    id: 1457690400,
    name: "Stella Johnson",
    firstName: "Stella",
    lastName: "Johnson",
    birthDate: "28/03/1995",
    nif: "15abC",
    profile_pic: "https://via.placeholder.com/150x150",
    email: "stella.johnson@example.com",
    generalPhone: "(215) 659 752900",
    phones: [ {phone: "(215) 659 752900", label: "home"} ],
    driverLicense: "AAAAAAA",
    generalIButton: "ABCXYZ",
    generalFiles: [],
    designation: "CEO",
    starred: false,
    frequent: true,
    company: "TCS",
    labels: [ 2 ],
    folder: "contacts",
    internalCode: "ABCCODE",
    active: true,
    specificPhone: "(+51) 984 516780",
    specificEmail: "email@email.com",
    specificIButton: "ABCXYZ",
    contract: 1,
    commencementDate: "28/01/2022",
    terminationDate: "28/02/2022",
    specificFiles: []
  },
  {
    id: 1457690401,
    name: "Garry Sobars",
    firstName: "Garry",
    lastName: "Sobars",
    birthDate: "28/03/1995",
    nif: "15abC",
    profile_pic: "https://via.placeholder.com/150x150",
    email: "garry.sobars@example.com",
    generalPhone: "(215) 659 752900",
    phones: [ {phone: "(215) 659 752900", label: "home"} ],
    driverLicense: "AAAAAAA",
    generalIButton: "ABCXYZ",
    generalFiles: [],
    designation: "CFO",
    starred: false,
    frequent: false,
    company: "Infosys",
    labels: [ 2, 1 ],
    folder: "contacts",
    internalCode: "ABCCODE",
    active: true,
    specificPhone: "(+51) 984 516780",
    specificEmail: "email@email.com",
    specificIButton: "ABCXYZ",
    contract: 1,
    commencementDate: "28/01/2022",
    terminationDate: "28/02/2022",
    specificFiles: []
  },
  {
    id: 1457690402,
    name: "Alex Dolgove",
    firstName: "Alex",
    lastName: "Dolgove",
    birthDate: "28/03/1995",
    nif: "15abC",
    profile_pic: "https://via.placeholder.com/150x150",
    email: "alex.dolgove@example.com",
    generalPhone: "(215) 659 752900",
    phones: [ {phone: "(215) 748 785500", label: "home"} ],
    driverLicense: "AAAAAAA",
    generalIButton: "ABCXYZ",
    generalFiles: [],
    designation: "Designer",
    starred: false,
    frequent: false,
    company: "Accenture",
    labels: [ 3 ],
    folder: "contacts",
    internalCode: "ABCCODE",
    active: true,
    specificPhone: "(+51) 984 516780",
    specificEmail: "email@email.com",
    specificIButton: "ABCXYZ",
    contract: 1,
    commencementDate: "28/01/2022",
    terminationDate: "28/02/2022",
    specificFiles: []
  },
  {
    id: 1457690403,
    name: "Domnic Brown",
    firstName: "Domnic",
    lastName: "Brown",
    birthDate: "28/03/1995",
    nif: "15abC",
    profile_pic: "https://via.placeholder.com/150x150",
    email: "domnic.brown@example.com",
    phones: [ {phone: "(215) 659 752900", label: "home"} ],
    generalPhone: "(215) 659 752900",
    driverLicense: "AAAAAAA",
    generalIButton: "ABCXYZ",
    generalFiles: [],
    designation: "PHP Developer",
    starred: false,
    frequent: true,
    company: "Pizza Hut",
    labels: [],
    folder: "contacts",
    internalCode: "ABCCODE",
    active: true,
    specificPhone: "(+51) 984 516780",
    specificEmail: "email@email.com",
    specificIButton: "ABCXYZ",
    contract: 1,
    commencementDate: "28/01/2022",
    terminationDate: "28/02/2022",
    specificFiles: []
  },
  {
    id: 1457690404,
    name: "Kadir M",
    firstName: "Kadir",
    lastName: "M",
    birthDate: "28/03/1995",
    nif: "15abC",
    profile_pic: "https://via.placeholder.com/150x150",
    email: "kadir.m@example.com",
    phones: [ {phone: "(215) 659 896500", label: "home"} ],
    generalPhone: "(215) 659 752900",
    driverLicense: "AAAAAAA",
    generalIButton: "ABCXYZ",
    generalFiles: [],
    designation: "HR Manager",
    starred: true,
    frequent: false,
    company: "Dominos",
    labels: [],
    folder: "contacts",
    internalCode: "ABCCODE",
    active: true,
    specificPhone: "(+51) 984 516780",
    specificEmail: "email@email.com",
    specificIButton: "ABCXYZ",
    contract: 1,
    commencementDate: "28/01/2022",
    terminationDate: "28/02/2022",
    specificFiles: []
  }
];

const extraData = {
  birthDate: "28/03/1995",
  nif: "15abC",
  profile_pic: "https://via.placeholder.com/150x150",
  phones: [ {phone: "(215) 659 896500", label: "home"} ],
  driverLicense: "AAAAAAA",
  generalIButton: "ABCXYZ",
  generalFiles: [],
  designation: "HR Manager",
  starred: true,
  frequent: false,
  company: "Dominos",
  labels: [],
  folder: "contacts",
  internalCode: "ABCCODE",
  active: true,
  specificPhone: "(+51) 984 516780",
  specificEmail: "email@email.com",
  specificIButton: "ABCXYZ",
  contract: 1,
  commencementDate: "28/01/2022",
  terminationDate: "28/02/2022",
  specificFiles: []
};

let foldersList = baseFoldersList;
let labelsList = baseLabelsList;
let contactsList = baseContactsList;

export const toggleExpandSidebar = value => {
  return dispatch => {
    dispatch({
      type: TOGGLE_SIDEBAR_COLLAPSED,
      payload: value
    });
  };
};

//For setting Filtertype
export const setFilterType = filterType => {
  return {
    type: SET_FILTER_TYPE,
    payload: filterType
  };
};

//for getting labels list
export const getLabelsList = () => {
  return dispatch => {
    dispatch(fetchStart());
    fetchAllTagsByType(0)
      .then(tags => {
        dispatch(fetchSuccess());
        dispatch({type: GET_LABELS_LIST, payload: tags});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

//for adding new label
export const addNewLabel = label => {
  return dispatch => {
    dispatch(fetchStart());
    createTag({description: label.name, type: 0, color: label.color})
      .then(response => {
        dispatch(fetchSuccess());
        const newLabel = {
          ...label,
          id: Number(response.transactionKey),
          slug: label.name.toLowerCase(),
          typeID: 0
        };
        dispatch({type: ADD_LABEL, payload: newLabel});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

//For Deleting Label
export const deleteLabel = labelId => {
  return dispatch => {
    dispatch(fetchStart());
    deleteTag(labelId)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({
          type: DELETE_LABEL_ITEM,
          payload: Number(response.transactionKey)
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

//For Editing Label
export const updateLabel = label => {
  return dispatch => {
    dispatch(fetchStart());
    updateTag({
      description: label.name,
      id: label.id,
      typeID: 0,
      color: label.color
    })
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({type: UPDATE_LABEL_ITEM, payload: label});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

//For getting contacts list
export const getContactsList = params => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllEmployeesByFilter(params)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({
          type: GET_CONTACTS_LIST,
          payload: {
            folderContacts: response.folderContacts,
            total: response.total
          }
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const setCurrentContact = contact => {
  return async dispatch => {
    if (contact === null) {
      dispatch({
        type: SET_CURRENT_CONTACT,
        payload: contact
      });
    } else {
      dispatch(fetchStart());
      try {
        const current = await getEmployeeGeneralDetails(contact.id);
        if (current.profile_pic.link) {
          current.profile_pic.link = await getFileUrlByName(
            current.profile_pic.link
          );
        }
        await getEmployeeSpecificDetails(current.id, current);
        await getEmployeeFiles(0, current);
        await getEmployeeFiles(contact.id, current);
        dispatch(fetchSuccess());
        dispatch({
          type: SET_CURRENT_CONTACT,
          payload: {...current}
        });
      } catch (e) {
        dispatch(fetchError(e.message));
      }
    }
  };
};

//for creating new contact
export const createContact = (data, params, setType, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    dispatch({type: CREATE_CONTACT, payload: data.nif});
    addEmployeeByNif(data.nif)
      .then(response => {
        if (response.transactionKey === "0") {
          dispatch(fetchError(response.transactionMessage));
          setType && setType(2);
        } else {
          dispatch(fetchSuccess(response.transactionMessage));
          dispatch({type: CREATE_CONTACT, payload: null});
          dispatch(getContactsList(params));
          onClose && onClose();
        }
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addNewEmployee = (data, params, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    dispatch({type: CREATE_CONTACT, payload: data});
    addEmployee(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: CREATE_CONTACT, payload: null});
        dispatch(getContactsList(params));
        onClose && onClose();
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

//for updating contact through detail page
export const onUpdateContact = contact => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      await updateEmployeeGeneralData(contact);
      await updateEmployeeSpecificData(contact);
      if (contact.profile_pic.file) {
        await updateEmployeePhoto(contact.profile_pic.file, contact.id);
      }
      contact.specificFiles.map(async file => {
        if (file.id === 0) {
          await createOrUpdateFile(0, contact.id, file);
        }
        return null;
      });
      contact.generalFiles.map(async file => {
        if (file.id === 0) {
          await createOrUpdateFile(0, contact.id, file, true);
        }
        return null;
      });
      contact.specificDeleteFiles.map(async fileId => {
        await deleteEmployeeFile(fileId);
        return null;
      });
      contact.generalDeleteFiles.map(async fileId => {
        await deleteEmployeeFile(fileId);
        return null;
      });
      dispatch(fetchSuccess());
      dispatch({type: UPDATE_CONTACT, payload: contact});
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

//for updating contacts starred status(through listing)
export const updateStarredStatus = (contactIds, status) => {
  return async dispatch => {
    if (!status) {
      contactIds.forEach(contactId => {
        dispatch(fetchStart());
        unmarkFavoriteEmployee(contactId)
          .then(response => {
            dispatch(fetchSuccess(response.transactionMessage));
          })
          .catch(e => dispatch(fetchError(e.message)));
      });
    } else {
      contactIds.forEach(contactId => {
        dispatch(fetchStart());
        markFavoriteEmployee(contactId)
          .then(response => {
            dispatch(fetchSuccess(response.transactionMessage));
          })
          .catch(e => dispatch(fetchError(e.message)));
      });
    }
    dispatch({
      type: UPDATE_STARRED_STATUS,
      payload: {contactIds, status}
    });
  };
};

//for updating mails folder(through listing)
export const deleteContact = contactIds => {
  return dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      contactsList = contactsList.map(contact => {
        if (contactIds.includes(contact.id)) {
          contact.folder = "trash";
          return contact;
        } else {
          return contact;
        }
      });
      dispatch({type: DELETE_CONTACT, payload: contactIds});
    }, 1500);
  };
};

//for updating contacts label(through listing)
export const updateContactsLabel = (contactIds, label, deleteTag = false) => {
  return dispatch => {
    dispatch(fetchStart());
    updateEmployeeTag(contactIds, label, deleteTag)
      .then(response => {
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_CONTACT_LABEL,
          payload: {contactIds, label, deleteTag}
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

//for getting contact categories(in sidebar) count
export const getContactCounts = (employees, labels) => {
  return dispatch => {
    const counter = getEmployeesCounters(foldersList, labels, employees);
    dispatch({type: GET_CONTACT_COUNTS, payload: counter});
  };
};
