import React, {useEffect, useCallback} from "react";
import {connect, useSelector, useDispatch} from "react-redux";

import PageContainer from "components/RRHH/SigningType/PageContainer";
import CreateNewSigningType from "components/RRHH/SigningType/Dialog/CreateNewSigningType";
import EditSigningType from "components/RRHH/SigningType/Dialog/EditSigningType";

import DeleteConfirmation from "components/RRHH/SigningType/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/RejectionTypes/Confirmations/RestoreConfirmation";
import Table from "components/RRHH/SigningType/Table";
import Filters from "components/RRHH/SigningType/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";
import ErrorAlert from "../../../../../components/Administrator/Common/Alerts/ErrorAlert";

import SigningTypes from "redux/actions/RRHH/SingingTypes";

import useUiState from "hooks/Administrator/useUiState";

// import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";

const SinginTypes = ({tableData, dispatch}) => {
  const ui = useUiState(tableData);

  useEffect(
    () => {
      dispatch(SigningTypes.get());
      // return () => dispatch(warehouse.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(SigningTypes.applySingingTypesFilters(filters)),
    [ dispatch ]
  );
  // const resetFilters = () => dispatch(rejectionTypes.resetFilters());

  const onDelete = () => dispatch(SigningTypes.delete(ui.deletingId));
  const onEdit = data => dispatch(SigningTypes.edit(ui.editingId, data));
  const onCreate = data => dispatch(SigningTypes.create(data));
  const onRestore = () => dispatch(SigningTypes.restore(ui.restoringId));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;
  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        /*onClose={resetFilters}*/ showLoadingIndicator={
          ui.showLoadingIndicator
        }
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateNewSigningType onSubmit={onCreate} {...ui.createFormProps} />
      <EditSigningType
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.rrhh.signingType;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("description", filters.description)
    .build();
  console.log(filtered);
  return {tableData: filtered};
};

// export default SinginTypes;
export default connect(mapStateToProps)(SinginTypes);
