import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

export const membershipValidations = {
  nif: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ]
};

export const addValidations = {
  nif: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  name: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ],
  countryNif: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="rrhh.employees.form.field.error" />
    }
  ]
};
