import {
  ADD_INSURANCE,
  APPLY_INSURANCES_FILTERS,
  EDIT_INSURANCE,
  FETCH_ALL_INSURANCES,
  RESET_INSURANCES_FILTERS,
  RESET_INSURANCES_STATE,
  SET_CURRENT_INSURANCE
} from "../../types/FleetManagement/InsurancesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {getAllInsurances} from "../../../services/FleetManagement/Vehicles/InsuranceService";

export const resetInsurancesState = () => ({type: RESET_INSURANCES_STATE});
export const applyInsurancesFilters = filters => ({
  type: APPLY_INSURANCES_FILTERS,
  payload: filters
});
export const resetInsurancesFilters = () => ({type: RESET_INSURANCES_FILTERS});
export const fetchAllInsurances = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllInsurances()
      .then(insurances => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_INSURANCES, payload: insurances});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const addInsurance = (insurance, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: ADD_INSURANCE, payload: insurance});
      onClose && onClose();
    }, 1500);
  };
};

export const editInsurance = (insurance, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: EDIT_INSURANCE, payload: insurance});
      onClose && onClose();
    }, 1500);
  };
};

export const setCurrentInsurance = insurance => ({
  type: SET_CURRENT_INSURANCE,
  payload: insurance
});
