import axios from "axios";

const reportsAxiosInstance = axios.create({
  //baseURL: 'https://4aet1fcsvg.execute-api.us-east-1.amazonaws.com/Prod/api/Informes/',
  baseURL: process.env.REACT_APP_API_URI + "/Informes/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

export default reportsAxiosInstance;
