import React from 'react';
import {useIntl} from "react-intl";
import {parseNumberToMoney} from "../../../utils/parsers";
import {IconButton} from "@material-ui/core";
import ShowIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import {DataGrid} from "@mui/x-data-grid";
import EditServiceDialog from "./EditServiceDialog";

const ServicesTable = ({services = {}, setServices, disableEdit = false}) => {
    const servicesList = Object.values(services);
    const [service, setService] = React.useState(null);
    const intl = useIntl();
    const handleEditService = (service) => {
        setService({
            ...service,
            isConcept: !service.serviceId
        });
    }
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'serviceName',
            headerName: intl.messages['description'],
            width: 200,
        },
        {
            field: 'description',
            headerName: intl.messages['observations'],
            width: 200,
        },
        {
            field: 'quantity',
            headerName: intl.messages['quantity'],
            width: 200,
        },
        {
            field: 'ncQuantity',
            headerName: `NC ${intl.messages['quantity']}`,
            width: 200,
        },
        {
            field: 'unitPrice',
            headerName: intl.messages['unit.price'],
            valueFormatter: ({value}) => parseNumberToMoney(value),
            width: 200,
        },
        {
            field: 'discount',
            headerName: `% ${intl.messages['discount']}`,
            width: 200,
        },
        {
            field: 'taxAmount',
            headerName: intl.messages['tax'],
            width: 200,
            valueFormatter: ({row}) => {
                if(row.taxAmount) return `${row.taxAmount}`;
                const taxAmount = (row.unitPrice * row.quantity) * (1 - row.discount/100) * (row.percentage / 100) ;
                return parseNumberToMoney(taxAmount);
            },
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({row}) => {
                if(row.total) return parseNumberToMoney(row.total);
                const subtotal = (row.unitPrice * row.quantity) * (1 - row.discount/100) * (1 + row.percentage / 100);
                return parseNumberToMoney(subtotal);
            },
            width: 200,
        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            type: 'actions',
            renderCell: ({row}) => {
                return disableEdit
                    ? <IconButton onClick={() => handleEditService(row)}>
                        <ShowIcon/>
                    </IconButton>
                    :(
                        <IconButton onClick={() => handleEditService(row)}>
                            <EditIcon/>
                        </IconButton>
                    )
            }
        }
    ];
    const onSubmit = (data) => {
        const serviceIndex = services.findIndex(s => s.id === data.id);
        services[serviceIndex] = data;
        setServices(services);
    }
    return (
        <>
            <DataGrid
                columns={columns}
                rows={servicesList}
                style={{
                    height: '40vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
            />
            <EditServiceDialog
                service={service}
                onClose={() => setService(null)}
                handleSubmit={onSubmit}
            />
        </>
    );
};

export default ServicesTable;