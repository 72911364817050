import React from "react";

import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    zIndex: 2,
    right: theme.spacing(10),
    bottom: theme.spacing(10)
  }
}));

const FloatingButtonContainer = ({children}) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default FloatingButtonContainer;
