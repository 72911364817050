import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PettyCashesTable from "./PettyCashesTable";
import {Box, Collapse, TableCell} from "@material-ui/core";
import {parseNumberToMoney} from "../../../../utils/parsers";
import {useSelector} from "react-redux";

const OperativeTableRow = ({currency, boxes}) => {
  const [ isOpen, setIsOpen ] = React.useState(true);
  const totalFund = () =>
    boxes.reduce((amount, planning) => amount + planning["fund"], 0);
  const {currencies} = useSelector(state => state.customCommon.currencies);

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>
          {currencies.find(c => c.id === currency).symbol}
        </StyledTableCell>
        <StyledTableCell>{parseNumberToMoney(totalFund())}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <PettyCashesTable boxes={boxes} currency={currency} />
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default OperativeTableRow;
