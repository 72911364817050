import React from "react";

import PageContainer from "../../../../../components/FleetManagement/Tachograph/Alerts/PageContainer";
import AlertsTable from "../../../../../components/FleetManagement/Tachograph/Alerts/AlertsTable";
import ConfigurationDialog from "../../../../../components/FleetManagement/Tachograph/Alerts/ConfigurationDialog";

const Alerts = () => {
  const [ openConfiguration, setOpenConfiguration ] = React.useState(false);
  const onClickConfiguration = e => {
    e.preventDefault();
    setOpenConfiguration(true);
  };
  return (
    <PageContainer>
      <AlertsTable onClickConfiguration={onClickConfiguration} />
      <ConfigurationDialog
        open={openConfiguration}
        setOpen={setOpenConfiguration}
      />
    </PageContainer>
  );
};

export default Alerts;
