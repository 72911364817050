import {combineReducers} from "redux";
import ui from "./ui";
import monitoring from "./Monitoring";
import assigment from "./Assigment";
import workOrdersTypes from "./WorkOrdersTypes";
import checklistTypes from "./ChecklistTypes";
import servicesList from "./ServicesList";
import returnTypes from "./ReturnTypes";
import rejectionTypes from "./RejectionTypes";
import carbonFootprint from "./CarbonFootprint";
import planner from "./Planner";
import aavvServices from "./aavvServices";
import providers from "./providers";
import providerOrders from "./providerOrders";
import orders from "./orders";

export default combineReducers({
  monitoring: monitoring,
  assigment: assigment,
  workOrdersTypes: workOrdersTypes,
  checklistTypes: checklistTypes,
  serviceList: servicesList,
  returnTypes,
  rejectionTypes,
  ui,
  carbonFootprint,
  planner,
  aavvServices,
  providers,
  providerOrders,
  orders,
});
