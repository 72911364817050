const INIT_STATE = {
  data: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CASH_MANAGER_ENTITIES_CLEAR": {
      return {
        ...state,
        data: []
      };
    }
    case "CASH_MANAGER_ENTITIES_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: [ ...action.payload.data ]
      };
    }
    default: {
      return state;
    }
  }
};
