import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = props => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"configuration.user.settings.modules.table.code"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"configuration.user.settings.modules.table.description"}
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
