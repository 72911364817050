import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const CreateReceiverDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-receiver-form"
    isOpen={isOpen}
    title={
      <IntlMessages id="administrator.logistic.receivers.create.form.title" />
    }
    onClose={onClose}
    disableEnforceFocus
  >
    <Form id="create-receiver-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateReceiverDialog;
