import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {editDispatchCentralData} from "../../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import PlanningPlaceSelect from "./PlanningPlaceSelect";

const Form = ({id, onClose}) => {
    const {currentDestination, filters} = useSelector(
        state => state.administrator.breakdown
    );
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit} = useForm({
        origin: currentDestination.route.origin || '',
        destination: currentDestination?.route?.destination || '',
        durationHours: currentDestination?.estimatedTime?.centralTime?.split(":")[0] || '',
        durationMinutes: currentDestination?.estimatedTime?.centralTime?.split(":")[1] || '',
        distance: currentDestination.estimatedTime.centralKilometers || '',
        planningPlace: currentDestination.route.planningPlace || ''
    });
    const onSubmit = (data) => {
        onClose();
        dispatch(editDispatchCentralData({id: currentDestination.id, ...data}, filters));
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={1}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        value={formData.origin}
                        label={<IntlMessages id='origin' />}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        value={formData.destination}
                        label={<IntlMessages id='destination' />}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <OutlinedInput
                        value={formData.durationHours}
                        label={<><IntlMessages id='duration' />{' '}<IntlMessages id='hours' /></>}
                        type='number'
                        onChange={value => setFormData('durationHours', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <OutlinedInput
                        type='number'
                        value={formData.durationMinutes}
                        label={<><IntlMessages id='duration' />{' '}<IntlMessages id='minutes' /></>}
                        onChange={value => setFormData('durationMinutes', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        value={formData.distance}
                        label={<><IntlMessages id='distance' />{' '}<IntlMessages id='kms' /></>}
                        onChange={value => setFormData('distance', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PlanningPlaceSelect
                        value={formData.planningPlace}
                        onChange={value => setFormData('planningPlace', value)} />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;