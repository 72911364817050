import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";

const files = [
    {
        id: '250317170101152',
        information: '',
        imei: '250317170101152',
        offline: true,
        queue: false,
        description: '-',
        firmware: '03.27.13.Rev.03',
        configuration: '-',
        lastViewed: Date.now(),
        model: 'FMT100',
        image: '',
        organization: {id: 1, name: '4gFlota'},
        group: '-',
        serialNumber: '1225464842',
        productCode: 'FMT12145',
        iccid: '4545748789',
        imsi: '1524574545',
        tasks: [
            {
                id: '1215465',
                state: true,
                lotId: '124154',
                taskId: '124546',
                taskType: '212456',
                createdAt: Date.now()
            },
            {
                id: '1215462',
                state: false,
                lotId: '124154',
                taskId: '124546',
                taskType: '212456',
                createdAt: Date.now()
            }
        ],
        changelog: [
            {
                id: '124564',
                loadedAt: Date.now(),
                field: 'Firmware',
                valueFrom: '-',
                valueTo: '03.27.13.Rev.03'
            }
        ]
    },
    {
        id: '250317170101153',
        information: '',
        imei: '250317170101153',
        offline: false,
        queue: true,
        description: '-',
        firmware: '03.27.13.Rev.03',
        configuration: '-',
        lastViewed: Date.now(),
        model: 'FMT100',
        image: '',
        organization: {id: 1, name: '4gFlota'},
        group: '-',
        serialNumber: '1225464842',
        productCode: 'FMT12145',
        iccid: '4545748789',
        imsi: '1524574545',
        tasks: [
            {
                id: '1215465',
                state: true,
                lotId: '124154',
                taskId: '124546',
                taskType: '212456',
                createdAt: Date.now()
            },
            {
                id: '1215462',
                state: false,
                lotId: '124154',
                taskId: '124546',
                taskType: '212456',
                createdAt: Date.now()
            }
        ],
        changelog: [
            {
                id: '124564',
                loadedAt: Date.now(),
                field: 'Firmware',
                valueFrom: '-',
                valueTo: '03.27.13.Rev.03'
            }
        ]
    },
]
export const getIndexFiles = createAsyncThunk(
    'indexFiles/getIndexFiles',
    async (filters, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const data = [...files];
            dispatch(fetchSuccess());
            return {filters, data}
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)