import React from 'react';
import ImportFileDialog from "../../../../Common/Dialogs/ImportFileDialog";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog, openDialog} from "../../../../../redux/reducers/FleetManagement/filesReader";
import {dialogsList} from "../constants";
import {downloadReport, getDataFromFile} from "../../../../../redux/thunks/FleetManagement/Tachograph/filesReader";
import './styles.css';

const Header = () => {
    const id = 'file-reader';
    const {dialogs, fileName} = useSelector(({fleetManagement}) => fleetManagement.filesReader);
    const dispatch = useDispatch();
    const handleOpenFileReader = () => {
        dispatch(openDialog(dialogsList.reader));
    }
    const handleCloseFileReader = () => {
        dispatch(closeDialog(dialogsList.reader));
    }
    const handleOnReadFile = (file) => {
        dispatch(getDataFromFile(file));
        handleCloseFileReader();
    }
    const handleDownloadReport = (complete = true) => {
        dispatch(downloadReport({fileName, complete}));
    }
    return (
        <div className="buttons_container">
            <ContainedButton
                text={<IntlMessages id='read.file' />}
                color='primary'
                onClick={handleOpenFileReader}
            />
            <ImportFileDialog
                id={id}
                title={<IntlMessages id='read.file' />}
                setOpen={handleCloseFileReader}
                open={dialogs.some(dialog => dialog === dialogsList.reader)}
                onImport={handleOnReadFile}
                accept='.DDD,.TGD'
            />
            {fileName &&
                <>
                    <ContainedButton
                        text={<>
                            <IntlMessages id='send.report' />{' '}{'('}<IntlMessages id='last.7.days' />{')'}
                        </>}
                        color='primary'
                        onClick={() => handleDownloadReport(false)}
                    />
                    <ContainedButton
                        text={<>
                            <IntlMessages id='send.report' />{' '}{'('}<IntlMessages id='complete' />{')'}
                        </>}
                        color='primary'
                        onClick={() => handleDownloadReport()}
                    />
                </>
            }
        </div>
    );
};

export default Header;