import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../StorageService";
import User from "../../domain/Configuration/User";
import Response from "../../domain/Response";

export const fetchAllConfigUsers = async () => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_usuarios", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(u => User.map(u));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigUserState = async (user, state) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Usuario/upd_estado_empresa_usuario", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_usuario: user.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateConfigUserRole = async (user, roles, vehicleRoles) => {
  try {
    const {data} = await axiosInstance.post("/Usuario/upd_usuario_rol", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: user.id,
      ls_rol: roles.map(r => r.id),
      ls_rol_vehiculo: vehicleRoles.map(r => r.id)
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
