import React from 'react';
import TableContainer from "../../../../../../../Common/Tables/TableContainer";
import TagsTableHeader from "./TagsTableHeader";
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";

const TagsTable = ({tags}) => {
    return (
        <TableContainer
            head={<TagsTableHeader />}
        >
            {tags.map(tag => (
              <StyledTableRow key={tag.id}>
                  <StyledTableCell>{tag.description}</StyledTableCell>
                  <StyledTableCell>{tag.type}</StyledTableCell>
              </StyledTableRow>
            ))}
        </TableContainer>
    );
};

export default TagsTable;