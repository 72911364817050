import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    addWidgetToDashboard,
    getAllDashboards,
    getWidgetData
} from "../../../services/ControlPanel/Kpi/DashboardService";
import {cloneDeep} from "lodash";

export const fetchAllDashboards =  createAsyncThunk(
    'dashboards/fetchAllDashboards',
    async (request, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const dashboards = await getAllDashboards();
            dispatch(fetchSuccess());
            return dashboards;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);


export const getCurrentDashboardWidgetsData =  createAsyncThunk(
    'dashboards/getCurrentDashboardWidgetsData',
    async ({dashboard, filters}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const widgetsWithData = await Promise.allSettled(dashboard.widgets.map(async w => await getWidgetData({...w, ...filters})));
            const widgets = dashboard.widgets.map((w, index) => {
                const result = widgetsWithData[index];
                if (result.status === 'fulfilled') {
                    return result.value;
                }
                return w;
            });
            dispatch(fetchSuccess());
            return {
                ...cloneDeep(dashboard),
                widgets
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateDashboardDetailsAndWidgets =  createAsyncThunk(
    'dashboards/updateDashboardDetailsAndWidgets',
    async (request, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addWidgetToDashboard(request);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                dashboard: request,
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);