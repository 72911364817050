import React from "react";
import useUiState from "../../../../../hooks/Administrator/useUiState";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";
import {editTagTypeStarted} from "../../../../../redux/actions/Administrator/Tags";

const EditTagTypeDialog = ({isOpen, onClose}) => {
  const id = "edit-tag-type-form";
  const ui = useUiState();
  const dispatch = useDispatch();
  const onEdit = data => {
    dispatch(editTagTypeStarted(data));
  };
  const {data} = useSelector(state => state.administrator.tags);
  const initialData = ui.editingId
    ? data.find(t => t.id === ui.editingId)
    : undefined;

  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      title={<IntlMessages id="administrator.tags.type.edit.form.title" />}
    >
      {initialData && (
        <Form id={id} initialData={initialData} onSubmit={onEdit} />
      )}
    </FormDialog>
  );
};

export default EditTagTypeDialog;
