import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import Form from "../VehicleDetailDialog/BasicFile/Form";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const AddVehicleDialog = ({open, setOpen}) => {
  const id = "add-vehicle-dialog";
  const onClose = () => {
    setOpen(false);
  };
  const onSubmit = data => {};
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.add.title"}
        />
      }
      isOpen={open}
      onClose={onClose}
    >
      {open && (
        <Form
          id={id}
          initialData={{
            frame: "",
            brand: "",
            model: "",
            version: "",
            type: "",
            plaqueDate: "",
            alias: "",
            mileage: ""
          }}
          onSubmit={onSubmit}
          newVehicle
        />
      )}
    </FormDialog>
  );
};

export default AddVehicleDialog;
