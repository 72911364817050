export const setUserData = userData => {

    localStorage.setItem('entityId', userData.id);
    localStorage.setItem('user', JSON.stringify(userData));
}

export const setUserType = type => {
    const userData = JSON.parse(localStorage.getItem('user'));

    if (!userData) {
        throw new Error("user data is not defined");
    }

    localStorage.setItem('user', JSON.stringify({ ...userData, type }))
}

export const setCompanyEntityId = id => {
    localStorage.setItem('companyEntityId', id);
}

export const getCompanyEntityId = () => {
    return localStorage.getItem('companyEntityId');
}

export const resetUserData = () => {
    localStorage.removeItem('entityId');
    localStorage.removeItem('user');
    localStorage.removeItem('companyEntityId');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token');
}

export const getUserId = () => {
    return localStorage.getItem('entityId');
}

export const getUserType = () => {
    const user =  JSON.parse(localStorage.getItem('user'));
    return user?.type || [];
}

export const getUserName = () => {
    const user =  JSON.parse(localStorage.getItem('user'));
    return user?.name || 'USUARIO';
}

export const getUserEmail = () => {
    const user =  JSON.parse(localStorage.getItem('user'));
    return user?.email || 'default@email.com';
}


export const setUserPermissions = permissions => {
    localStorage.setItem('permissions', JSON.stringify(permissions));
    const userData = JSON.parse(localStorage.getItem('user'));

    if (!userData) {
        throw new Error("user data is not defined");
    }

    localStorage.setItem('user', JSON.stringify({ ...userData, permissions }))

}

export const getUserPermissions = () => {
    return JSON.parse(localStorage.getItem('permissions'));
}

export const getToken = () => {
    return localStorage.getItem('token');
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const setRefreshToken = (token) => {
    localStorage.setItem('refreshToken', token);
}

export const getRefreshToken = () => {
    return localStorage.getItem('refreshToken');
}