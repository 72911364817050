import React from 'react';
import {Bar, BarChart as BaseBarChart, CartesianGrid, LabelList, ResponsiveContainer, XAxis, YAxis} from "recharts";

const BarChart = ({data, nameKey, dataKey, percentage = true}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BaseBarChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                layout="vertical"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type='number'  />
                <YAxis dataKey={nameKey} type='category' />
                <Bar dataKey={dataKey} fill='#4285f4' >
                    <LabelList  position="right" dataKey={dataKey} formatter={(value) => percentage? `${value}%` : value} />
                </Bar>
            </BaseBarChart>
        </ResponsiveContainer>
    );
};

export default BarChart;