import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  description: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.advanceTypes.saveAdvanceTypeDialog.field.description.error.empty" />
      )
    },
    {
      rule: v => v.length < 50,
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.description.error.length" />
      )
    }
  ]
};
