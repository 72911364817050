import {fetchAllTags} from "../../../services/TagService";

export const clearTags = () => ({type: "CLEAR_TAGS"});
export const fetchTags = () => {
  return async dispatch => {
    fetchAllTags().then(tags =>
      dispatch({type: "TAGS_FETCH_ALL_SUCCEEDED", payload: {data: tags}})
    );
  };
};
