import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={"logistic.distribution.work.orders.types.table.class"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"logistic.distribution.work.orders.types.table.states"}
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
