import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "components/Common/Forms/Select/ActiveSelect";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import Select from "components/Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";

const initialData = {
  active: undefined,
  currency: "",
  cashManager: "",
  tag: "",
  fund: "",
  cash: "",
  transit: "",
  liquidated: "",
  refund: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <Select
            value={formData.currency}
            fullWidth
            id="currency-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.currency" />
            }
            onChange={e => setFormData("currency", e.target.value)}
          >
            <MenuItem value="">
              <IntlMessages id="all" />
            </MenuItem>
            <MenuItem value="PEN">PEN</MenuItem>
            <MenuItem value="USD">USD</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.cashManager}
            id="cash-manager-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.cashManager" />
            }
            onChange={value => setFormData("cashManager", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.tag}
            id="administrator-cash-managers-tag-filter"
            label={<IntlMessages id="administrator.cashManagers.filters.tag" />}
            onChange={value => setFormData("tag", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.fund}
            id="fund-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.fund" />
            }
            onChange={value => setFormData("fund", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.cash}
            id="cash-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.cash" />
            }
            onChange={value => setFormData("cash", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.transit}
            id="transit-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.transit" />
            }
            onChange={value => setFormData("transit", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.liquidated}
            id="liquidated-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.liquidated" />
            }
            onChange={value => setFormData("liquidated", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.refund}
            id="refund-filter"
            label={
              <IntlMessages id="administrator.cashManagers.filters.refund" />
            }
            onChange={value => setFormData("refund", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
