import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  defaultBigBox: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "32px"
  },
  componentBox: {
    display: "flex",
    flexWrap: "wrap"
  },
  buttonBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px"
  },
  nameFormBox: {
    overflow: "hidden",
    width: "100%"
  },
  groupItem: {
    width: "100%",
    marginTop: "16px"
  }
}));
