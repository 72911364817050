import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../../hooks/Common/useForm";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {useDispatch, useSelector} from "react-redux";
import {
  applyConfigOrganizationModules,
  resetConfigOrganizationModules
} from "../../../../../../redux/actions/Configuration/OrganizationsModules";
import Button from "@material-ui/core/Button";

const INIT_DATA = {
  code: "",
  description: ""
};
const Filters = ({openMassive}) => {
  const {filters} = useSelector(
    ({configuration}) => configuration.organizationsModules
  );
  const {formData, setFormData, resetForm, setValues} = useForm(INIT_DATA);
  const dispatch = useDispatch();
  const onApplyFilters = () => {
    dispatch(applyConfigOrganizationModules(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetConfigOrganizationModules());
  };
  React.useEffect(() => {
    setValues(filters);
  }, []);
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"config-organization-modules-code"}
              fullWidth
              value={formData.code}
              onChange={value => setFormData("code", value)}
              label={
                <IntlMessages
                  id={
                    "configuration.user.settings.organization.modules.table.code"
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"config-organization-modules-description"}
              fullWidth
              value={formData.description}
              onChange={value => setFormData("description", value)}
              label={
                <IntlMessages
                  id={
                    "configuration.user.settings.organization.modules.table.description"
                  }
                />
              }
            />
          </Grid>

          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onResetFilters();
              }}
              text={<IntlMessages id={"filters.button.clear"} />}
            />
          </Grid>

          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onApplyFilters();
              }}
              color="primary"
              text={<IntlMessages id={"filters.button.apply"} />}
            />
          </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <ContainedButton
                    onClick={e => {
                        e.preventDefault();
                        openMassive('add');
                    }}
                    color="primary"
                    text={<IntlMessages id="configuration.user.settings.organization.modules.add.massive.title" />}
                />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
                <ContainedButton
                    onClick={e => {
                        e.preventDefault();
                        openMassive('remove');
                    }}
                    color="primary"
                    text={ <IntlMessages id="configuration.user.settings.organization.modules.delete.massive.title" />}
                />
            </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
