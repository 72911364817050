class VehicleVersion {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static map = model => {
    return new VehicleVersion(
      model.nu_id_marca_modelo_version,
      model.vc_marca_modelo_version
    );
  };
}

export default VehicleVersion;
