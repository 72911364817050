import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {getAllVehiclesTypes} from "../../../../../../services/FleetManagement/Vehicles/VehicleTypeService";

const TypeSelect = ({value, onChange}) => {
    const [types, setTypes] = React.useState([]);
    React.useEffect(() => {
        getAllVehiclesTypes()
            .then(data => setTypes(data))
            .catch(e => console.error(e.message));
    }, []);
    const onSelectOption = value => {
        if(value === '0'){
            onChange({id: value, name: ''})
        }else {
            onChange(types.find(t => t.id === value));
        }

    }
    return (
        <Select
            id='vehicle-type-select'
            label={<IntlMessages id={"fleet.management.vehicles.specifications.management.type.select"} />}
            value={value?.id}
            onChange={e => onSelectOption(e.target.value)}
            fullWidth
        >
            <MenuItem value="0">
                <IntlMessages id={"fleet.management.vehicles.specifications.management.type.add"} />
            </MenuItem>
            {types.map(type => (
                <MenuItem value={type.id} key={type.id}>
                    {type.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default TypeSelect;