import axiosInstance from "../../../utils/axiosInstance";

export const fetchParsedData = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/Dispositivos/sel_parseo',
            {
                dispositivo: filters.device,
                paqueteTipo: filters.packageType,
                paqueteDecodificacion: filters.decodeType,
                paqueteFormato: filters.formatType,
                paquete: filters.package,
            }
        );
        return data;
    }catch (e) {
        return Promise.reject(e);
    }
}