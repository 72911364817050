import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getDevicesManagementOptions = (submodules) => {
    let options = [];
    let submodule = submodules.find(s => s.id === 104);
    submodule && options.push({
        name: submodule.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/back-office/devices/parser',
    });
    return options;
}

const getDevicesHistoryOptions = (submodules) => {
    let options = [];
    let submodule = submodules.find(s => s.id === 101);
    submodule && options.push({
        name: submodule.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/back-office/devices/teltonika',
    });
    return options;
}

const getLicensesHistoryOptions = (submodules) => {
    let options = [];
    let submodule = submodules.find(s => s.id === 102);
    submodule && options.push({
        name: submodule.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/back-office/licenses/history-data',
    });
    return options;
}
const getLicensesManagementOptions = (submodules) => {
    let options = [];
    let submodule = submodules.find(s => s.id === 107);
    submodule && options.push({
        name: submodule.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/back-office/licenses/management/index-files',
    });
    return options;
}

export const getBackOfficeOptions = () => {
    const permissions = getUserPermissions();
    let config = {
        name: <IntlMessages id={"back.office"}/>,
        type: 'section',
        children: []
    };
    const devicesSubmodules = permissions.find(p => p.id===15)?.submodules;
    if(devicesSubmodules?.length > 0){
        const devicesMenuOptions = {
            name: <IntlMessages id={"back.office.devices"}/>,
            type: 'collapse',
            children: []
        };
        const devicesHistoryOptions = getDevicesHistoryOptions(devicesSubmodules);
        devicesHistoryOptions?.length> 0 && devicesMenuOptions.children.push({
            name: <IntlMessages id={"back.office.devices.histories"}/>,
            type: 'section',
            children: devicesHistoryOptions,
        });
        const devicesManagementOptions = getDevicesManagementOptions(devicesSubmodules);
        devicesManagementOptions?.length > 0 && devicesMenuOptions.children.push({
            name: <IntlMessages id={"back.office.devices.management"}/>,
            type: 'section',
            children: devicesManagementOptions,
        });
        if(devicesMenuOptions.children.length > 0) {
            config.children.push(devicesMenuOptions);
        }
    }
    const licensesSubmodules = permissions.find(p => p.id===16)?.submodules;
    if(licensesSubmodules?.length > 0){
        const licensesOptions = {
            name: <IntlMessages id={"back.office.licenses"}/>,
            type: 'collapse',
            children: []
        }
        const licensesManagementOptions = getLicensesManagementOptions(licensesSubmodules);
        licensesManagementOptions?.length> 0 && licensesOptions.children.push({
            name: <IntlMessages id={"back.office.licenses.management"}/>,
            type: 'section',
            children: licensesManagementOptions,
        });
        const licensesHistoryOptions = getLicensesHistoryOptions(licensesSubmodules);
        licensesHistoryOptions?.length> 0 && licensesOptions.children.push({
            name: <IntlMessages id={"back.office.licenses.histories"}/>,
            type: 'section',
            children: licensesHistoryOptions,
        });
        config.children.push(licensesOptions);
    }
    return config;
}