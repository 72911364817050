import React from 'react';
import {Cell, Legend, Pie, PieChart as BasePieChart, ResponsiveContainer} from "recharts";

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FA8042', '#FF8C42',];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
const PieChart = ({data}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BasePieChart width={500}
                          height={300}>
                <Legend />
                <Pie
                    data={data}
                    innerRadius={40}
                    outerRadius={80}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                    ))}
                </Pie>
            </BasePieChart>
        </ResponsiveContainer>
    );
};

export default PieChart;