export const FETCH_BREAKDOWN_STARTED = "FETCH_BREAKDOWN_STARTED";
export const FETCH_BREAKDOWN_SUCCESS = "FETCH_BREAKDOWN_SUCCESS";
export const FETCH_BREAKDOWN_FAILED = "FETCH_BREAKDOWN_FAILED";
export const RESET_BREAKDOWN_STATE = "RESET_BREAKDOWN_STATE";

export const OPEN_BREAKDOWN_DIALOG = 'OPEN_BREAKDOWN_DIALOG';
export const CLOSE_BREAKDOWN_DIALOG = 'CLOSE_BREAKDOWN_DIALOG';

export const SET_CURRENT_BREAKDOWN_DESTINATION = 'SET_CURRENT_BREAKDOWN_DESTINATION';
export const SET_BREAKDOWN_FILTERS = 'SET_BREAKDOWN_FILTERS';

export const CLEAR_BREAKDOWN_DESTINATIONS_LIST = 'CLEAR_BREAKDOWN_DESTINATIONS_LIST';