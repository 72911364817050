import {Tooltip, withStyles} from "@material-ui/core";

export const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12)
  }
}))(Tooltip);
