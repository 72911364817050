import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";

export const getAllTaxes = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/Impuesto/sel",
            {
                nu_id_entidad: getCompanyEntityId(),
            },
        );
        return data.map((tax) => ({
            id: tax.nu_id_impuesto,
            name: tax.vc_desc_impuesto,
            percentage: tax.nu_valor,
            state: mapToBoolean(tax.bi_estado),
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const addTax = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/Impuesto/ins',
            {
                nu_id_entidad: getCompanyEntityId(),
                vc_desc_impuesto: request.name,
                nu_valor: request.percentage,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editTax = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/Impuesto/upd',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_impuesto: request.id,
                vc_desc_impuesto: request.name,
                nu_valor: request.percentage,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editTaxState = async ({taxId, state}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Impuesto/del',
            {
                nu_id_impuesto: taxId,
                bi_estado: state,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getAllTaxesForSelect = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/Impuesto/sel",
            {
                nu_id_entidad: getCompanyEntityId(),
            },
        );
        return data.map((tax) => ({
            id: tax.nu_id_impuesto,
            name: tax.vc_desc_impuesto,
            percentage: tax.nu_valor,
            state: mapToBoolean(tax.bi_estado),
        })).filter(tax => tax.state)
    } catch (e) {
        return Promise.reject(e);
    }
}
