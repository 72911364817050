import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import OrderHistory from "../domain/OrderHistory";
import Response from "domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

const orderHistory = [
  {
    nu_id_order: 1,
    nu_id_client: 1,
    vc_desc_client: "Janice Monahan",
    dt_fec: "10/12/2021",
    vc_state: "Pendiente",
    vc_ot: "A35",
    vc_observation: "Texto",
    bi_estado: "1"
  },
  {
    nu_id_order: 2,
    nu_id_client: 2,
    vc_desc_client: "Rollin Fadel",
    dt_fec: "10/12/2021",
    vc_state: "En curso",
    vc_ot: "A41",
    vc_observation: "Texto",
    bi_estado: "1"
  },
  {
    nu_id_order: 3,
    nu_id_client: 3,
    vc_desc_client: "Lera Stroman",
    dt_fec: "10/12/2021",
    vc_state: "Fianlizado",
    vc_ot: "A67",
    vc_observation: "Texto",
    bi_estado: "1"
  },
  {
    nu_id_order: 4,
    nu_id_client: 4,
    vc_desc_client: "Adan Schiller",
    dt_fec: "10/12/2021",
    vc_state: "En curso",
    vc_ot: "A87",
    vc_observation: "Texto",
    bi_estado: "1"
  }
];

export const cancelPrevOrderHistoryFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllOrdersHistory = async () => {
  return orderHistory.map(oreder => OrderHistory.map(oreder));
};
