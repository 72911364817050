import React from "react";
import {TableHead} from "@material-ui/core";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";

const TableHeader = () => (
  <TableHead>
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.name" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.fiscal" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.address" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.number" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.postal.code" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.municipality" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.province" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.country" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.latitude" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.longitude" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.clients.headquarters.actions" />
      </StyledTableCell>
    </StyledTableRow>
  </TableHead>
);

export default TableHeader;
