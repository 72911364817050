import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../../../@jumbo/utils/IntlMessages";
import {fetchAllExpensesStates} from "../../../../../../../../../../services/ExpenseService";
import MultipleSelect from "../../../../../../../../../Common/Forms/Select/MultipleSelect";

const StatesPicker = ({values, onChange}) => {
  const [ states, setStates ] = React.useState([]);
  useEffect(() => {
    fetchAllExpensesStates().then(states => {
      setStates([ ...states ]);
    });
  }, []);
  return (
    <MultipleSelect
      id={"administrator-expenses-reports-states-picker"}
      options={states}
      value={values}
      onChange={onChange}
      fullWidth
      label={<IntlMessages id={"administrator.reports.states.title"} />}
      headerText={<IntlMessages id="administrator.reports.states.header" />}
      noOptionsText={
        <IntlMessages id="administrator.reports.states.no.options" />
      }
    />
  );
};

export default StatesPicker;
