import React from "react";
import {ResponsiveHeader} from "./styles";
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useSelector} from "react-redux";

const AdvancesListHeader = () => {
  const {resume} = useSelector(state => state.guardian.advancesList);
  return (
    <ResponsiveHeader my={8}>
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" mr={5}>
          <h3>
            <IntlMessages id={"guardian.advances.header.drivers"} />:{" "}
            {resume.drivers}
          </h3>
          <h3>
            <IntlMessages id={"guardian.advances.header.travels"} />:{" "}
            {resume.travels}
          </h3>
        </Box>
        <Box display="flex" flexDirection="column" mx={5}>
          <h3>
            <IntlMessages id={"guardian.advances.header.assigned"} />:{" "}
            {resume.assigned}
          </h3>
          <h3>
            <IntlMessages id={"guardian.advances.header.spent"} />:{" "}
            {resume.expended}
          </h3>
        </Box>
        <Box display="flex" flexDirection="column" ml={5}>
          <h3>
            <IntlMessages id={"guardian.advances.header.approved"} />:{" "}
            {resume.approved}
          </h3>
          <h3>
            <IntlMessages id={"guardian.advances.header.balance"} />:{" "}
            {resume.balance}
          </h3>
        </Box>
      </Box>
    </ResponsiveHeader>
  );
};

export default AdvancesListHeader;
