import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {cloneDeep} from "lodash";
import {v4 as uuidV4} from 'uuid';
import {addTax, editTax, editTaxState, getAllTaxes} from "../../../services/Treasury/TaxesService";

const data = [
    {
        id: uuidV4(),
        name: 'IVA 21%',
        percentage: 21,
        state: true,
    },
    {
        id: uuidV4(),
        name: 'IVA 10%',
        percentage: 10,
        state: false,
    },
]

const baseNamespace = 'treasury/taxes';
export const fetchAllTaxes = createAsyncThunk(
    `${baseNamespace}/fetchAllTaxes`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const taxes = await getAllTaxes();
            dispatch(fetchSuccess());
            return {taxes};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const createTax = createAsyncThunk(
    `${baseNamespace}/createTax`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addTax(request);
            const newTax = {
                ...request,
                id: response.transactionKey,
                state: true,
            };
            dispatch(fetchSuccess(response.transactionMessage));
            return newTax;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateTax = createAsyncThunk(
    `${baseNamespace}/updateTax`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editTax(request);
            const editedTax = {
                ...request
            };
            dispatch(fetchSuccess(response.transactionMessage));
            return editedTax;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateTaxState = createAsyncThunk(
    `${baseNamespace}/updateTaxState`,
    async ({taxId, state}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editTaxState({taxId, state})
            dispatch(fetchSuccess(response.transactionMessage));
            return taxId;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);