import axiosInstance from "../utils/axiosInstance";
import WorkOrderBreakdown from "../domain/WorkOrderBreakdown";
import DestinationBreakdown from "../domain/DestinationBreakdown";
import {getUserEmail} from "./StorageService";
import Response from "../domain/Response";
import {minutesToTime} from "../utils/parsers";
import {parseDateToGlobalFormat} from "../utils/dates";

export const fetchWorkOrderByNumber = async number => {
    try {
        const {data} = await axiosInstance.post("/OT/desglose_ot", {
            nombreServicio: number
        });
        if (data.id === null) {
            return {id: null, message: data.response};
        }
        if (data.id === 0) {
            throw Error(`OT con numero ${number} no existe`);
        }
        return WorkOrderBreakdown.map(data);
    } catch (e) {
        return Promise.reject(e);
    }
};


export const fetchDispatchesByOtherParameters = async filters => {
    try {
        const {data} = await axiosInstance.post("/OT/desglose_ot", {
            origen: filters.origin,
            destino: filters.destination,
            puestoPlanificacion: filters.planningPlace
        });
        return data?.map(destination => DestinationBreakdown.map(destination));
    } catch (e) {
        return Promise.reject(e);
    }
}


export const updateWorkOrderDispatch = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            "/OT/upd_routes_point",
            {
                routePointId: request.id,
                Route_EstimatedTime: Number(request.durationHours * 60) + Number(request.durationMinutes),
                Route_EstimatedKms: request.distance,
                route_Org: request.planningPlace,
                usuario: getUserEmail()
            });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getWorkOrderDispatchUpdatesHistory = async dispatchId => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_route_record',
            {
                routePointId: dispatchId
            }
        )
        return data?.map(record => ({
            date: record.fecha,
            duration: minutesToTime(record.tiempo),
            distance: record.distancia.toFixed(2),
            user: record.usuario
        }))
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetchWorkOrderDispatchHistory = async (origin, destination) => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_route_for_points',
            {
                origen: origin.split(' ')[0],
                destino: destination.split(' ')[0],
            }
        )
        return data.map(record => ({
            date: record.fecha,
            ot: record.nameOT,
            arriveTime: minutesToTime(record.entrada),
            departureTime: minutesToTime(record.salida),
            routeTime: minutesToTime(record.tiempo),
            routeKilometers: record.distancia.toFixed(2),
            stayTime: minutesToTime(record.tiempoEnCliente),
            stayKilometers: record.distanciaEnCliente.toFixed(2)
        }))
    } catch (e) {
        return Promise.reject(e);
    }
}


export const fetchAllDispatchesHistoryByOT  = async ot => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_route_for_ot',
            {
                ot
            }
        )
        return data.map(record => ({
            date: record.fecha,
            ot: record.nameOT,
            arriveTime: minutesToTime(record.entrada),
            departureTime: minutesToTime(record.salida),
            routeTime: minutesToTime(record.tiempo),
            routeKilometers: record.distancia.toFixed(2),
            stayTime: minutesToTime(record.tiempoEnCliente),
            stayKilometers: record.distanciaEnCliente.toFixed(2),
            travelStartDate: parseDateToGlobalFormat(record.inicioViaje),
            travelEndDate: parseDateToGlobalFormat(record.finViaje),
        }))
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchExcelDispatchesForOthersFilters = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_desglose_ot_excel',
            {
                origen: filters.origin,
                destino: filters.destination,
                puestoPlanificacion: filters.planningPlace
            }
        )
        const {vc_reporte: report} = data;
        if(!report) {
            throw new Error('No se pudo generar un excel con los datos proveidos')
        }
        return report;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchWorkOrderDispatchUpdatesHistoryExcel = async dispatchId => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_route_record_excel',
            {
                routePointId: dispatchId
            }
        )
        const {vc_reporte: report} = data;
        if(!report) {
            throw new Error('No se pudo generar un excel con los datos proveidos')
        }
        return report;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetchWorkOrderDispatchHistoryExcel = async (origin, destination) => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_route_for_points_excel',
            {
                origen: origin.split(' ')[0],
                destino: destination.split(' ')[0],
            }
        )
        const {vc_reporte: report} = data;
        if(!report) {
            throw new Error('No se pudo generar un excel con los datos proveidos')
        }
        return report;
    } catch (e) {
        return Promise.reject(e);
    }
}


export const fetchAllDispatchesHistoryByOTExcel  = async ot => {
    try {
        const {data} = await axiosInstance.post(
            '/OT/sel_route_for_ot_excel',
            {
                ot
            }
        )
        const {vc_reporte: report} = data;
        if(!report) {
            throw new Error('No se pudo generar un excel con los datos proveidos')
        }
        return report;
    }catch (e) {
        return Promise.reject(e);
    }
}