import React from "react";
import {Box, Typography} from "@material-ui/core";
import {WhiteButton} from "./styles";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import NormalDestinationsList from "./NormalDestinationsList";
import SortableDestinationsList from "./SortableDestinationsList";
import {arrayMove} from "react-sortable-hoc";
import {useDispatch} from "react-redux";
import {updateDestinationsOrder} from "../../../../../redux/actions/Administrator/WorkOrders";
import Confirmation from "../../../../Common/Dialogs/Confirmation";
import DestinationsDialog from "../DestinationsDialog";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../hooks/Common/useForm";

const DestinationsList = ({
  destinations = [],
  selected,
  setSelected,
  isNewWorkOrder = false,
  setDestination,
  noEdit
}) => {
  const dispatch = useDispatch();
  const [ reorder, setReorder ] = React.useState(false);
  const [ confirmation, setConfirmation ] = React.useState(false);
  const [ openAddDestination, setOpenAddDestination ] = React.useState(false);
  const {formData, setFormData} = useForm({referenceNumber: ''});
  const [destinationsList, setDestinationsList] = React.useState(destinations);
  const makeAddress = destination => {
    return `${destination.referenceNumber} - ${destination.street} ${destination.number} ${destination.postalCode}, ${destination.district}, ${destination.province}`;
  };
  const [ initDestinations, setInitDestinations ] = React.useState(
    destinations
  );
  const onSortEnd = ({oldIndex, newIndex}) => {
    setInitDestinations(arrayMove(initDestinations, oldIndex, newIndex));
  };
  const onConfirmation = () => {
    dispatch(updateDestinationsOrder(initDestinations));
    setConfirmation(false);
  };
  const onCancelSort = () => {
    setInitDestinations(destinations);
    setConfirmation(false);
  };
  const onReorder = () => {
    if (
      reorder &&
      destinations.some((d, index) => d.id !== initDestinations[index].id)
    ) {
      setConfirmation(true);
    }
    setReorder(!reorder);
  };

  React.useEffect(() => {
    const filteredData = destinations.filter((destination) => destination.referenceNumber.includes(formData.referenceNumber));
    setDestinationsList(filteredData);
  }, [formData]);

  return (
    <React.Fragment>
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{backgroundColor: "#3f51b5"}}
          margin={4}
        >
          <Box marginX={4} paddingY={2}>
            <h2 style={{color: "#ffffff"}}>
              <IntlMessages id="logistic.work.orders.dialog.destination.list.header" />
            </h2>
          </Box>
          {!isNewWorkOrder &&
          !noEdit && (
            <Box marginX={4} marginY={2}>
              <WhiteButton
                onClick={() => {
                  setOpenAddDestination(true);
                }}
              >
                +{" "}
                <IntlMessages id="logistic.work.orders.dialog.add.destination" />
              </WhiteButton>
              {/*<WhiteButton>+ <IntlMessages id="logistic.work.orders.dialog.import"/></WhiteButton>*/}
              {/*<WhiteButton onClick={() => onReorder()}>*/}
              {/*    {reorder ?*/}
              {/*        <IntlMessages id="logistic.work.orders.dialog.finish"/> :*/}
              {/*        <IntlMessages id="logistic.work.orders.dialog.reorder"/>*/}
              {/*    }*/}
              {/*</WhiteButton>*/}
            </Box>
          )}
        </Box>
        <Box marginX={4}>
          <OutlinedInput
            value={formData.referenceNumber}
            label={<IntlMessages id={"logistic.work.orders.dialog.destination.reference.number.label"}/>}
            onChange={value => setFormData('referenceNumber', value)}
            fullWidth
          />
        </Box>
        <Box
            marginX={4}
            style={{
              maxHeight: '40rem',
              overflowY: 'auto'
            }}
        >
          {destinations.length > 0 ? !reorder ? (
            <NormalDestinationsList
              destinations={destinations}
              selected={selected}
              setSelected={setSelected}
              makeAddress={makeAddress}
              filteredDestinations={destinationsList}
            />
          ) : (
            <SortableDestinationsList
              onSortEnd={onSortEnd}
              destinations={initDestinations}
              makeAddress={makeAddress}
            />
          ) : (
            <Box
              marginX={4}
              border="solid"
              borderColor="#000000"
              display="flex"
              justifyContent="center"
              alignContent="center"
              padding={4}
            >
              <Typography variant="h1">
                <IntlMessages id="logistic.work.orders.dialog.no.destinations" />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Confirmation
        open={confirmation}
        onAccept={onConfirmation}
        onReject={onCancelSort}
        text={
          <IntlMessages
            id={"logistic.work.orders.dialog.reorder.confirmation"}
          />
        }
      />
      {openAddDestination && (
        <DestinationsDialog
          open={openAddDestination}
          setOpen={setOpenAddDestination}
          onNew={() => {
            setDestination({id: 0});
            setOpenAddDestination(false);
          }}
        />
      )}
    </React.Fragment>
  );
};

export default DestinationsList;
