import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StyledTableCell from 'components/Common/Tables/StyledTableCell';
import StyledTableRow from 'components/Common/Tables/StyledTableRow';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import CashManagers from '../../CashManagers';
import { parseNumberToMoney } from 'utils/parsers';

const Row = ({ rowData, onEdit, onDelete, onRestore }) => {
  const [isOpen, setIsOpen] = useState(true);

  if (rowData?.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <StyledTableRow>
        <StyledTableCell>
          <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{rowData.currency}</StyledTableCell>
        <StyledTableCell align="right">{parseNumberToMoney(rowData.amount)}</StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box marginY={5}>
              <CashManagers data={rowData.guardians} onEdit={onEdit} onDelete={onDelete} onRestore={onRestore} />
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  rowData: PropTypes.shape({
    currency: PropTypes.string,
    guardians: PropTypes.array,
    amount: PropTypes.number,
  }),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
};

Row.defaultProps = {
  rowData: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {},
};

export default Row;
