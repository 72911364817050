import React from "react";
import PageContainer from "../../../../components/ControlPanel/Dashboard/PageContainer";
import Viewer from "../../../../components/ControlPanel/Dashboard/Viewer";
import {Route, Switch} from "react-router-dom";
import Edit from "../../../../components/ControlPanel/Dashboard/Edit";
import {useRouteMatch} from "react-router";
import Create from "../../../../components/ControlPanel/Dashboard/Create";

const Dashboard = () => {
    const { path } = useRouteMatch();
    return(
    <PageContainer>
        <Switch>
            <Route path={`${path}/viewer`} component={Viewer} />
            <Route path={`${path}/editor`} component={Edit} />
            <Route path={`${path}/creator`} component={Create} />
        </Switch>
    </PageContainer>
)};

export default Dashboard;
