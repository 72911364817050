import React from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const options = Array.from(Array(30).keys());

const MonthlyDaySelect = ({
  id,
  onChange,
  style,
  value,
  hasError,
  error,
  disabled,
  fullWidth = false
}) => (
  <Select
    id={id}
    label={<IntlMessages id="select.monthly.day.label" />}
    value={value}
    onChange={e => onChange(e.target.value)}
    style={style}
    error={hasError}
    disabled={disabled}
    errorMessage={error}
    fullWidth={fullWidth}
  >
    {options.map((option, index) => (
      <MenuItem value={option + 1} key={`monthly-day-${option + 1}`}>
        {option + 1}
      </MenuItem>
    ))}
  </Select>
);

export default MonthlyDaySelect;
