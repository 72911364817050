import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import widgets from "../../../Viewer/DashboardContainer/WidgetsContainer/widgets";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {getAvailableWidgetsByCompany} from "../../../../../../services/ControlPanel/Kpi/DashboardService";
import Widget from "../../../../../../domain/ControlPanel/Dashboard/Widget";
import {v4 as uuidV4} from "uuid";

const AddWidgetSelect = ({formWidgets, setFormWidgets}) => {
    const {data} = useFetch(getAvailableWidgetsByCompany, {}, []);
    const handleSelectOption = (e) => {
        const widgetId = e.target.value;
        const baseWidget = widgets[widgetId];
        const widget = new Widget(
            baseWidget.id,
            {},
            baseWidget.defaultX,
            baseWidget.defaultY,
            formWidgets?.length || 0,
            {},
            baseWidget.defaultWidth,
            baseWidget.defaultHeight,
            uuidV4(),
        );
        setFormWidgets([
            ...formWidgets,
            widget
        ]);
    }
    return (
        <Select
            label={<IntlMessages id="control.panel.dashboard.configs.add.widget.label" />}
            onChange={handleSelectOption}
            fullWidth
        >
            {data
                .filter(w => !(formWidgets?.some(d => d.id === w.id)))
                .map(w => (
              <MenuItem key={w.id} value={w.id}>
                  {w.name}
              </MenuItem>
            ))}
        </Select>
    );
};

export default AddWidgetSelect;