export const RESET_CORRECTIVE_TYPES_STATE = "RESET_CORRECTIVE_TYPES_STATE";
export const FETCH_ALL_CORRECTIVE_TYPES = "FETCH_ALL_CORRECTIVE_TYPES";
export const APPLY_CORRECTIVE_TYPES_FILTERS = "APPLY_CORRECTIVE_TYPES_FILTERS";
export const RESET_CORRECTIVE_TYPES_FILTERS = "RESET_CORRECTIVE_TYPES_FILTERS";
export const SET_CURRENT_CORRECTIVE_TYPE = "SET_CURRENT_CORRECTIVE_TYPE";
export const RESET_CURRENT_CORRECTIVE_TYPE = "RESET_CURRENT_CORRECTIVE_TYPE";
export const ADD_CORRECTIVE_TYPE = "ADD_CORRECTIVE_TYPE";
export const EDIT_CORRECTIVE_TYPE = "EDIT_CORRECTIVE_TYPE";
export const OPEN_CORRECTIVE_TYPES_DIALOG = "OPEN_CORRECTIVE_TYPES_DIALOG";
export const CLOSE_CORRECTIVE_TYPES_DIALOG = "CLOSE_CORRECTIVE_TYPES_DIALOG";
