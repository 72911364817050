import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import Checkbox from 'components/Common/Forms/Checkbox';

import useForm from 'hooks/Common/useForm';

import validations from './validations';

const Form = ({ id, initialData, onSubmit }) => {
  const { formData, setFormData, errors, handleSubmit } = useForm(
    {
      description: initialData?.description || '',
      approvalOfAdvances: initialData?.approvalOfAdvances || false,
      obligationOfAdvances: initialData?.obligationOfAdvances || false,
    },
    validations,
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <OutlinedInput
            value={formData.description}
            hasError={errors.description.length > 0}
            error={errors.description[0]}
            fullWidth
            id="description"
            label={<IntlMessages id="administrator.pettyCashTypes.savePettyCashTypeDialog.field.description" />}
            onChange={value => setFormData('description', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={formData.approvalOfAdvances}
            onChange={value => setFormData('approvalOfAdvances', value)}
            name="approvalOfAdvances"
            label={<IntlMessages id="administrator.pettyCashTypes.savePettyCashTypeDialog.field.approvalOfAdvances" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Checkbox
            checked={formData.obligationOfAdvances}
            onChange={value => setFormData('obligationOfAdvances', value)}
            name="obligationOfAdvances"
            label={<IntlMessages id="administrator.pettyCashTypes.savePettyCashTypeDialog.field.obligationOfAdvances" />}
            fullWidth
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
