import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import Accounting from "../domain/Accounting";
import AccountingStates from "../models/Guardian/AccountingStates";
import {getCompanyEntityId, getUserEmail, getUserId, getUserName, getUserType} from "./StorageService";
import Response from "../domain/Response";
import toBase64 from "../utils/files";

let fetchAllByGuardian = undefined;

export const cancelPrevFetchAllAccountingByGuardian = () => {
  fetchAllByGuardian && fetchAllByGuardian();
};

export const fetchAllAccountingByUser = async filters => {
  try {
    const accountingList = await axiosInstance.post(
      "/Caja_Chica_Contabilizacion/sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_gestor_caja: getUserType().includes("guardian")
          ? getUserId()
          : null,
        nu_id_entidad_administrador: getUserType().includes("administrator")
          ? getUserId()
          : null,
        vc_nro_liquidacion: filters.accountingNumber || null,
        dt_fec_inicio: filters.lowerDate || null,
        dt_fec_final: filters.upperDate || null,
        vc_id_estado:
          filters.state === AccountingStates.ALL
            ? null
            : filters.state.map(s => s.id).toString(),
        nu_cantidad: parseFloat(filters.quantity) || null,
        nu_importe: parseFloat(filters.pettyCashAmount) || null,
        vc_tag: filters.tag || null,
      },
      {
        cancelToken: new CancelToken(c => (fetchAllByGuardian = c))
      }
    );
    return accountingList.data.map(accounting => Accounting.map(accounting));
  } catch (e) {
    if (isCancel(e)) {
      return [];
    }
    return Promise.reject(e);
  }
};

export const createAccounting = async accounting => {
  try {
    const {data} = await axiosInstance.post("/Caja_Chica_Contabilizacion/ins", {
      nu_id_caja_chica: accounting.box,
      vc_observacion: accounting.observations,
      vc_usuario: getUserName()
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const closeAccounting = async accountingId => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Contabilizacion/upd_cerrar", {
      nu_id_contabilizacion: accountingId,
      vc_usuario: getUserName()
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const approveAccounting = async accounting => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Contabilizacion/upd_aprobar", {
      nu_id_contabilizacion: accounting.id,
      vc_usuario: getUserName()
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const rejectAccounting = async accounting => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Contabilizacion/upd_rechazar", {
      nu_id_contabilizacion: accounting.id,
      vc_usuario: getUserName()
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteAccounting = async accounting => {
  try {
    const {data} = await axiosInstance.post("/Caja_Chica_Contabilizacion/del", {
      nu_id_contabilizacion: accounting.id
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};


export const refundAccounting = async (request) => {
  try {
      const file = request.files[0];
      const base64File = await toBase64(file);
    const {data} = await axiosInstance.post("/Caja_Chica_Contabilizacion/upd_reintegrar", {
      vc_usuario: getUserEmail(),
      nu_id_contabilizacion: request.accounting,
        archivo: {
            archivobase64: base64File.split(",")[1],
            contentType: file.type,
            extension: file.name.split(".")[1],
            nombre: file.name
        },
      vc_observacion: request.observations
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};