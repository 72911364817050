import React from "react";

import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "hooks/Common/useForm";
import SupervisorsAutocomplete from "containers/Administrator/SupervisorsAutocomplete";

import validations from "./validations";
import TagsPicker from "containers/Administrator/TagsPicker";

const initialData = {
  supervisor: null,
  tags: []
};

const Form = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initialData,
    validations
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <SupervisorsAutocomplete
            value={formData.supervisor}
            hasError={errors.supervisor.length > 0}
            error={errors.supervisor[0]}
            onChange={value => setFormData("supervisor", value)}
          />
        </Grid>
        <Grid item xs={12} style={{margin: "10px 0"}}>
          <TagsPicker
            id="administrator-create-supervisors-tags-picker"
            values={formData.tags}
            hasError={errors.tags.length > 0}
            error={errors.tags[0]}
            onChange={value => setFormData("tags", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
