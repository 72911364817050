import React from 'react';
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import useForm from "../../../../../../../../hooks/Common/useForm";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {useSelector} from "react-redux";
import StateSelect from "../../../../../../Purchases/common/StateSelect";


const Form = ({id, onSubmit}) => {
    const {currentItem} = useSelector(({logistic}) => logistic.orders);
    const {formData, setFormData, handleSubmit} = useForm({
        deliveredQuantity: currentItem?.deliveredQuantity ?? 0,
        stateId: currentItem?.stateId ?? '',
    });
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="name"
                        value={currentItem.name}
                        label={<IntlMessages id="item" />}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="quantity"
                        type="number"
                        value={currentItem.quantity}
                        label={<IntlMessages id="quantity" />}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="deliveredQuantity"
                        type="number"
                        value={formData.deliveredQuantity}
                        onChange={value => setFormData('deliveredQuantity', value)}
                        label={<IntlMessages id="delivered" />}
                        required
                        inputProps={{min: 1}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StateSelect
                        value={formData.stateId}
                        onChange={(state) => setFormData('stateId', state)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;