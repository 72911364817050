import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import {fetchAllHeadquartersByClientId} from "../../../../../../../../../services/HeadquarterService";
import MultipleSelect from "../../../../../../../../Common/Forms/Select/MultipleSelect";

const HeadquarterPicker = ({id, values, onChange, errors, client = null}) => {
  const [ headquarters, setHeadquarters ] = React.useState([]);

  useEffect(
    () => {
      if (client) {
        fetchAllHeadquartersByClientId(client)
          .then(data => setHeadquarters(data))
          .catch(e => setHeadquarters([]));
      } else {
        setHeadquarters([]);
      }
    },
    [ client ]
  );

  return (
    <MultipleSelect
      id={id}
      options={headquarters}
      value={values}
      onChange={onChange}
      fullWidth
      hasError={errors.clients.length > 0}
      error={errors.clients[0]}
      label={
        <IntlMessages id="logistic.inventoryReport.filters.headquarters" />
      }
      headerText={
        <IntlMessages id="logistic.inventoryReport.filters.headquarters.header" />
      }
      noOptionsText={
        <IntlMessages id="logistic.inventoryReport.filters.headquarters.no.option" />
      }
    />
  );
};

export default HeadquarterPicker;
