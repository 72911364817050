import React from 'react';
import TableContainer from "../../../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const ContactsList = ({contacts}) => {
    return (
        <TableContainer
            head={
                <StyledTableRow>
                    <StyledTableCell>
                        <IntlMessages id="contact" />
                    </StyledTableCell>
                    <StyledTableCell>
                        <IntlMessages id="phone" />
                    </StyledTableCell>
                    <StyledTableCell>
                        <IntlMessages id="email" />
                    </StyledTableCell>
                </StyledTableRow>
            }
        >
            {contacts.map((contact, index) => (
                <StyledTableRow key={index}>
                    <StyledTableCell>
                        {contact.name}
                    </StyledTableCell>
                    <StyledTableCell>
                        {contact.phone}
                    </StyledTableCell>
                    <StyledTableCell>
                        {contact.email}
                    </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableContainer>
    );
};

export default ContactsList;