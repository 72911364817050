import React from 'react';
import {useSelector} from "react-redux";
import Table from "./Common/Table";
import BarChart from "./Common/BarChart";
import Typography from "@material-ui/core/Typography";
import {Paper} from "@material-ui/core";
import {useIntl} from "react-intl";
import LineChart from "./Common/LineChart";
import PieChart from "./Common/PieChart";

const Aavv = () => {
    const intl = useIntl();
    const {
        processProgress,
        vehiclesByZone,
        vehiclesProgression,
        vehiclesTimes,
        vehiclesSchedules,
        vehiclesPrincipalSteps,
        averageTemperature,
        temperatureDetail,
        monthlyFalseFreight,
        monthlyOtd,
        dailyFalseFreight,
        dailyOtd,
    } = useSelector(({controlPanel}) => controlPanel.dashboards.currentDashboard.widgets);

    return (
        <div className='graph-container'>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['process.progress']}
                </Typography>
                <PieChart
                    data={processProgress}
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['report']} {intl.messages['of']} {intl.messages['progress']} {intl.messages['of']} {intl.messages['units']}
                </Typography>
                <Table
                    data={vehiclesProgression}
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['report']} {intl.messages['of']} {intl.messages['times']} {intl.messages['of']} {intl.messages['units']}
                </Typography>
                <Table
                    data={vehiclesTimes}
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['report']} {intl.messages['detail']} {intl.messages['of']} {intl.messages['schedule']} {intl.messages['and']} {intl.messages['times']} {intl.messages['of']} {intl.messages['units']}

                </Typography>
                <Table
                    data={vehiclesSchedules}
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['report']} {intl.messages['of']} {intl.messages['times']} {intl.messages['traveled']} {intl.messages['between']} {intl.messages['steps']} {intl.messages['principal,plural']}
                </Typography>
                <Table
                    data={vehiclesPrincipalSteps}
                />
            </div>
            <Typography
                align='center'
                variant='subtitle2'
                component={Paper}
                className='cols-6'
            >
                {intl.messages['process.indicators']}
            </Typography>
            <div className='graph cols-6'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['temperature']} {intl.messages['average']}
                </Typography>
                <BarChart
                    data={averageTemperature}
                    nameKey='vehicle'
                    dataKey='temperature'
                    percentage={false}
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['detail']} {intl.messages['of']} {intl.messages['temperature']}
                </Typography>
                <Table
                    data={temperatureDetail}
                />
            </div>

            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['false.freight']} {intl.messages['monthly']}
                </Typography>
                <LineChart
                    data={monthlyFalseFreight}
                    nameKey='vehicle'
                    dataKey='percentage'
                />
            </div>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    OTD {intl.messages['monthly']}
                </Typography>
                <LineChart
                    data={monthlyOtd}
                    nameKey='vehicle'
                    dataKey='percentage'
                />
            </div>

           <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['false.freight']} {intl.messages['day']}
                </Typography>
                <BarChart
                    data={dailyFalseFreight}
                    nameKey='vehicle'
                    dataKey='percentage'
                />
            </div>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    OTD {intl.messages['day']}
                </Typography>
                <BarChart
                    data={dailyOtd}
                    nameKey='vehicle'
                    dataKey='percentage'
                />
            </div>




        </div>
    );
};

export default Aavv;