import {mapToBoolean} from "../../utils/mappers";

class ElevatorDetail {
  constructor(id, name, state) {
    this.id = id;
    this.name = name;
    this.state = state;
  }
  static map = detail => {
    return new ElevatorDetail(
      detail.nu_id_tipo_detalle,
      detail.vc_desc_tipo_detalle,
      mapToBoolean(detail.bi_estado)
    );
  };
}

export default ElevatorDetail;
