import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {fetchAllBills, getAndSetBillDetails, removeBill} from "../../thunks/Treasury/bills";

const INIT_STATE = {
    bills: {},
    dialogs: [],
    currentBill: null,
}

const billSlice = createSlice({
    name: 'treasury/bills',
    initialState: cloneDeep(INIT_STATE),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentBill: (state, action) => {
          state.currentBill = action.payload;
        },
        resetBillsState: () => cloneDeep(INIT_STATE)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBills.fulfilled, (state, action) => {
                state.bills = action.payload.bills.reduce((obj, bill) => {
                    obj[bill.id] = bill;
                    return obj;
                }, {});
            })
            .addCase(removeBill.fulfilled, (state, action) => {
                const {billId} = action.payload;
                const bills = cloneDeep(state.bills);
                delete bills[billId];
                state.bills = bills;
            })
            .addCase(getAndSetBillDetails.fulfilled, (state, action) => {
                state.currentBill = action.payload;
            });
    }
});

export default billSlice.reducer;
export const {
    openDialog,
    closeDialog,
    resetBillsState,
    setCurrentBill,
} = billSlice.actions;