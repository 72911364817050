import React from "react";
import PageContainer from "../../../../../../components/Logistic/WorkOrdersTypes/PageContainer";
import WorkOrdersTypesTable from "../../../../../../components/Logistic/WorkOrdersTypes/WorkOrdersTypesTable";
import {useDispatch, useSelector} from "react-redux";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {
  resetCurrentWorKOrderType,
  resetWorkOrdersStateTypes,
  editWorkOrderTypeStatus
} from "../../../../../../redux/actions/Logistic/WorkOrdersTypes";
import WorkOrderTypeEditDialog from "../../../../../../components/Logistic/WorkOrdersTypes/WorkOrderTypeDetailDialog/WorkOrderTypeEditDialog";
import WorkOrderTypeAddDialog from "../../../../../../components/Logistic/WorkOrdersTypes/WorkOrderTypeDetailDialog/WorkOrderTypeAddDialog";

const WorkOrdersTypes = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.workOrdersTypes);
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetWorkOrdersStateTypes());
    };
  }, []);
  return (
    <PageContainer>
      <WorkOrdersTypesTable
        openEdit={() => setOpenEdit(true)}
        openRestore={() => setOpenRestore(true)}
        openDelete={() => setOpenDelete(true)}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"logistic.distribution.work.orders.types.delete.description"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentWorKOrderType());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editWorkOrderTypeStatus(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"logistic.distribution.work.orders.types.restore.description"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentWorKOrderType());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editWorkOrderTypeStatus(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <WorkOrderTypeAddDialog
        open={openAdd}
        setOpen={setOpenAdd}
        title={"logistic.distribution.work.orders.types.add.title"}
      />
      <WorkOrderTypeEditDialog
        open={openEdit}
        setOpen={setOpenEdit}
        title={"logistic.distribution.work.orders.types.edit.title"}
      />
    </PageContainer>
  );
};

export default WorkOrdersTypes;
