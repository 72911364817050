import {mapToBoolean} from "../../../utils/mappers";

class SelectWidget {
    constructor(id, name, state) {
        this.id = id;
        this.name = name;
        this.state = state;
    }

    static map = widget => {
        return new SelectWidget(
            Number(widget.nu_id_widget),
            widget.vc_desc_widget,
            mapToBoolean(widget.bi_estado)
        )
    }
}

export default SelectWidget;