import React from "react";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ReportsList from "./ReportsList";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {
  fetchAllUserReportsLists,
  removeFavoriteReport,
  removeFiledReport,
  removeScheduledReport
} from "../../../../../redux/actions/ControlPanel/Kpi";

const UserReportsList = () => {
  const {userReportsLists} = useSelector(({controlPanel}) => controlPanel.kpi);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllUserReportsLists());
  }, []);
  const onDeleteFavorite = report => {
    dispatch(removeFavoriteReport(report.id));
  };
  const onDeleteScheduled = report => {
    dispatch(removeScheduledReport(report.id));
  };
  const onDeleteFiled = report => {
    dispatch(removeFiledReport(report.id));
  };
  return (
    <GridContainer spacing={2} style={{marginTop: "8px"}}>
      <Grid item xs={12} md={6} lg={3}>
        <ReportsList
          title={
            <IntlMessages id={"control.panel.kpi.users.list.last.consults"} />
          }
          reports={userReportsLists.lastConsults}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ReportsList
          title={<IntlMessages id={"control.panel.kpi.users.list.favorites"} />}
          reports={userReportsLists.favorites}
          onDelete={onDeleteFavorite}
          pagination
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ReportsList
          title={<IntlMessages id={"control.panel.kpi.users.list.scheduled"} />}
          reports={userReportsLists.scheduled}
          onDelete={onDeleteScheduled}
          pagination
        />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <ReportsList
          title={<IntlMessages id={"control.panel.kpi.users.list.filed"} />}
          reports={userReportsLists.filed}
          onDelete={onDeleteFiled}
          pagination
        />
      </Grid>
    </GridContainer>
  );
};

export default UserReportsList;
