export const FETCH_ALL_SPECIFICATIONS_VEHICLES_STARTED =
  "FETCH_ALL_SPECIFICATIONS_VEHICLES_STARTED";
export const FETCH_ALL_SPECIFICATIONS_VEHICLES_SUCCEED =
  "FETCH_ALL_SPECIFICATIONS_VEHICLES_SUCCEED";
export const APPLY_SPECIFICATIONS_VEHICLES_FILTERS =
  "APPLY_SPECIFICATIONS_VEHICLES_FILTERS";
export const RESET_SPECIFICATIONS_VEHICLES_FILTERS =
  "RESET_SPECIFICATIONS_VEHICLES_FILTERS";
export const RESET_SPECIFICATIONS_STATE = "RESET_SPECIFICATIONS_STATE";
export const SET_CURRENT_SPECIFICATION = "SET_CURRENT_SPECIFICATION";
export const RESET_CURRENT_SPECIFICATION = "RESET_CURRENT_SPECIFICATION";
