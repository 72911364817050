import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Button, Grid, MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import Select from "../../../../../Common/Forms/Select";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";

const Filters = ({formData, resetForm, setFormData, applyFilters}) => (
  <FiltersContainer active marginBottom={5} onClose={() => {}}>
    <form id="order-dialog-filters">
      <GridContainer spacing={4}>
        <Grid item xs={12} md={4}>
          <OutlinedInput
            fullWidth
            value={formData.client}
            id="client-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.client" />
            }
            onChange={value => setFormData("client", value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            id="state-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.state" />
            }
            value={formData.state}
            onChange={e => setFormData("state", e.target.value)}
            fullWidth
          >
            <MenuItem value={1}>
              <IntlMessages id="administrator.logistic.order.filter.state.pending" />
            </MenuItem>
            <MenuItem value={2}>
              <IntlMessages id="administrator.logistic.order.filter.inProgress" />
            </MenuItem>
            <MenuItem value={3}>
              <IntlMessages id="administrator.logistic.order.filter.terminated" />
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <Select
            id="type-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.type" />
            }
            value={formData.type}
            onChange={e => setFormData("type", e.target.value)}
            fullWidth
          >
            <MenuItem value={1}>
              <IntlMessages id="administrator.logistic.order.filter.type.service" />
            </MenuItem>
            <MenuItem value={2}>
              <IntlMessages id="administrator.logistic.order.filter.type.pickup" />
            </MenuItem>
            <MenuItem value={3}>
              <IntlMessages id="administrator.logistic.order.filter.type.delivery" />
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <OutlinedDatePicker
            fullWidth
            value={formData.fromDate}
            id="from-date-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.fromDate" />
            }
            onChange={value => setFormData("fromDate", value)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <OutlinedDatePicker
            fullWidth
            value={formData.toDate}
            id="to-date-filter"
            label={
              <IntlMessages id="administrator.logistic.order.filter.toDate" />
            }
            onChange={value => setFormData("toDate", value)}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={() => resetForm()}>
            <IntlMessages id={"logistic.assigment.orders.filters.clear"} />
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button onClick={() => applyFilters()}>
            <IntlMessages id={"logistic.assigment.orders.filters.apply"} />
          </Button>
        </Grid>
      </GridContainer>
    </form>
  </FiltersContainer>
);

export default Filters;
