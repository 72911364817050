import {FETCH_ALL_CURRENCIES_SUCCEEDED} from "../../types/Common/MoneyCustomTypes";
import {
  cancelPrevFetchAllCurrencies,
  fetchAllCurrencies
} from "../../../services/CurrencySevice";

const fetchAllCurrenciesSucceeded = data => ({
  type: FETCH_ALL_CURRENCIES_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchAllCurrenciesStarted = () => async dispatch => {
  cancelPrevFetchAllCurrencies();
  fetchAllCurrencies().then(currencies => {
    dispatch(fetchAllCurrenciesSucceeded(currencies));
  });
};

export const clearCurrencies = () => ({type: "CLEAR_CURRENCIES"});
