import React from "react";
import {Box, Grid} from "@material-ui/core";
import EmployeeFile from "../../../../../domain/EmployeeFile";
import DialogBase from "../../../../Common/Dialogs/DialogBase";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Button from "@material-ui/core/Button";
import {parseDateFromInput} from "../../../../../utils/dates";

const FilesDialog = ({listFiles, setListFiles, id, open, setOpen}) => {
  const [ files, setFiles ] = React.useState([]);
  const onAddNewFiles = () => {
    const newFiles = files.map(file => {
      return new EmployeeFile(
        0,
        "",
        "",
        file.name,
        parseDateFromInput(file.expirationDate),
        "",
        file.file
      );
    });
    setListFiles([ ...listFiles, ...newFiles ]);
    setOpen(false);
  };
  const actions = [
    <Button key="cancel-b" variant="contained" onClick={() => setOpen(false)}>
      <IntlMessages id={"rrhh.employees.form.files.cancel"} />
    </Button>,
    <Button
      key="ok-b"
      color="primary"
      variant="contained"
      onClick={onAddNewFiles}
    >
      <IntlMessages id={"rrhh.employees.form.files.ok"} />
    </Button>
  ];
  return (
    <DialogBase
      open={open}
      close={() => setOpen(false)}
      title={<IntlMessages id={"rrhh.employees.form.files.add"} />}
      actions={actions}
    >
      <Box display="flex" flexDirection="column">
        <Box my={2}>
          <input
            id={id}
            type="file"
            multiple
            onChange={e => {
              const selectFiles = Object.values(e.target.files)
                .slice(0, e.target.files.length)
                .map(file => ({
                  file,
                  name: "",
                  description: file.name,
                  expirationDate: ""
                }));
              setFiles(selectFiles);
            }}
          />
        </Box>
        <Box my={2}>
          {files.map((file, index) => (
            <GridContainer key={`new-file-${index}`}>
              <Grid item sm={12} md={6}>
                <OutlinedInput
                  id={`new-file-${index}`}
                  fullWidth
                  label={<IntlMessages id={"rrhh.employees.form.files.name"} />}
                  value={file.name}
                  onChange={value => {
                    setFiles(
                      files.map((f, i) => {
                        if (index === i) {
                          return {
                            ...f,
                            name: value
                          };
                        }
                        return f;
                      })
                    );
                  }}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <OutlinedDatePicker
                  id={`new-file-${index}`}
                  fullWidth
                  label={
                    <IntlMessages
                      id={"rrhh.employees.form.files.expiration.date"}
                    />
                  }
                  value={file.expirationDate}
                  onChange={value => {
                    setFiles(
                      files.map((f, i) => {
                        if (index === i) {
                          return {
                            ...f,
                            expirationDate: value
                          };
                        }
                        return f;
                      })
                    );
                  }}
                />
              </Grid>
            </GridContainer>
          ))}
        </Box>
      </Box>
    </DialogBase>
  );
};

export default FilesDialog;
