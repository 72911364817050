import React from 'react';
import Select from "../../../Common/Forms/Select";
import {useFetch} from "../../../../hooks/Common/useFetch";
import {fetchAllDocumentsTypes} from "../../../../services/Treasury/DocumentTypesService";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const DocumentTypeSelect = ({value, onChange}) => {
    const {data: types} = useFetch(fetchAllDocumentsTypes, {}, [], 'treasuryDocumentTypes');
    const handleChange = ({target}) => {
        const {value} = target;
        onChange(value);
    }
    return (
        <Select
            label={<IntlMessages id="document.type" />}
            value={value}
            onChange={handleChange}
            options={types}
            name="documentType"
            fullWidth
        />
    );
};

export default DocumentTypeSelect;