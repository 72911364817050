import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserEmail} from "../../StorageService";
import Dashboard from "../../../domain/ControlPanel/Dashboard/Dashboard";
import SelectWidget from "../../../domain/ControlPanel/Dashboard/SelectWidget";
import Response from "../../../domain/Response";
import moment from "moment/moment";
import {cloneDeep} from "lodash";
import {defaultDashboardsIds} from "../../../components/ControlPanel/Dashboard/constants";

const notCustomDashboards = [
    new Dashboard(
        defaultDashboardsIds.EXCEEDING_SPEED,
        'Eventos de exceso de velocidad',
        {
            events: [],
            eventsByDriver: [],
            eventsByVehicle: [],
            eventsByGravity: [],
            eventsByGroup: [],
            details: {
                data: [],
                rowCount: 0,
            },
        },
        false
    ),
    new Dashboard(
        defaultDashboardsIds.ECO_DRIVING,
        'Eventos de Eco Conducción',
        {
            security: {
                events: [],
                eventsByType: [],
                eventsByTypeGrouped: [],
                eventsByDriver: [],
                eventsByVehicle: [],
                details: {
                    data: [],
                    rowCount: 0,
                },
            },
            efficiency: {
                events: [],
                eventsByType: [],
                eventsByTypeGrouped: [],
                eventsByDriver: [],
                eventsByVehicle: [],
                details: {
                    data: [],
                    rowCount: 0,
                },
            }
        },
        false
    ),
    new Dashboard(
        defaultDashboardsIds.AAVV,
        'AAVV',
        {
            processProgress: [],
            vehiclesByZone: [],
            vehiclesProgression: [],
            vehiclesTimes: [],
            vehiclesSchedules: [],
            vehiclesPrincipalSteps: [],
            averageTemperature: [],
            temperatureDetail: [],
            monthlyFalseFreight: [],
            monthlyOtd: [],
            dailyFalseFreight: [],
            dailyOtd: [],
        }
    ),
]

export const getAllDashboards = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/sel_dashboard',
            {
                nu_id_entidad: getCompanyEntityId(),
            }
        );
        return {
            customDashboards: data.map(d => Dashboard.map(d)),
            defaultDashboards: notCustomDashboards,
        };
    }catch (e) {
        return Promise.reject(e);
    }
}

export const createDashboard = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/ins_dashboard',
            {
                nu_id_entidad: getCompanyEntityId(),
                vc_desc_dashboard: request.name,
                nu_orden: request.orderId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const updateDashboard = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/upd_dashboard',
            {
                nu_id_dashboard: request.id,
                nu_orden: request.orderId
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const removeDashboard = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/del_dashboard',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_dashboard: request.id,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const addWidgetToDashboard = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/ins_widget_dashboard',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_dashboard: request.id,
                vc_desc_dashboard: request.description,
                widgets: request.widgets.map(widget => ({
                    nu_id_widget: widget.id,
                    vc_labelx: widget.labelX || "",
                    vc_labely: widget.labelY || "",
                    nu_orden: widget.orderId,
                    vc_filtros: JSON.stringify({
                        startDate: widget.startDate.toISOString(),
                        endDate: widget.endDate.toISOString(),
                    }),
                    nu_width: widget.width,
                    nu_height: widget.height,
                    nu_id_dashboard_widget_datos: isNaN(widget.internalId)? null : widget.internalId
                })),
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getAvailableWidgetsByCompany = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/sel_widget',
            {
                nu_id_entidad: getCompanyEntityId(),
            }
        );
        return data.map(w => SelectWidget.map(w));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editWidget = async (widget) => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/upd_widget_dashboard',
            {
                nu_id_dashboard_widget_datos: widget.internalId,
                vc_labelx: widget.labelX,
                vc_labely: widget.labelY,
                nu_orden: widget.orderId,
                vc_filtros: JSON.stringify(widget.filters),
                nu_width: widget.width,
                nu_height: widget.height
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const removeWidget = async (dashboardId, widgetId) => {
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/del_widget_dashboard',
            {
                nu_id_entidad: dashboardId,
                nu_id_dashboard_widget_datos: widgetId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getWidgetData = async widget => {
    const {page, pageSize} = widget;
    try {
        const {data} = await axiosInstance.post(
            '/DashBoard/sel_widget_datos',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_widget: widget.id,
                vc_filtro: JSON.stringify({
                    vc_email: getUserEmail(),
                    dt_fecha_ini:  moment(widget.startDate).format('yyyy-MM-DD'),
                    dt_fecha_fin:  moment(widget.endDate).format('yyyy-MM-DD'),
                    nu_pagina: page,
                    nu_registros_pagina: pageSize,
                })
            }
        );
        return {
            ...cloneDeep(widget),
            data
        };
    }catch (e) {
        return Promise.reject(e);
    }
}