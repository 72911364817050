import React from "react";
import {Box, Grid, Paper} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import DataBox from "../Cards/DataBox";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import CardBox from "../Cards/CardBox";
import BaseAlertBox from "../Cards/BaseAlertBox";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import useForm from "../../../../../../hooks/Common/useForm";

const initialData = {
  vehicleDataDownloadDays: 90,
  vehicleDataNoticeDays: 10,
  vehicleDataEmail: "",
  driverDataDownloadDays: 30,
  driverDataNoticeDays: 10,
  driverDataEmail: "",
  organizationCardExpiration: 10,
  organizationCardEmail: "",
  driverCardExpiration: 10,
  driverCardEmail: "",
  driverFileEmail: "",
  vehicleFileEmail: ""
};

const Form = ({id, initData, onSubmit}) => {
  const {formData, setFormData, handleSubmit, setValues} = useForm(initialData);
  React.useEffect(
    () => {
      setValues(initData);
    },
    [ initData ]
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <Box p="2em" display="flex" flexDirection="column">
        <GridContainer spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <Box component={Paper} display="flex" flexDirection="column">
              <DataBox
                title={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.configuration.vehicle.data"
                    }
                  />
                }
                email={formData.vehicleDataEmail}
                setEmail={value => setFormData("vehicleDataEmail", value)}
                download={formData.vehicleDataDownloadDays}
                setDownload={value =>
                  setFormData("vehicleDataDownloadDays", value)}
                notice={formData.vehicleDataNoticeDays}
                setNotice={value => setFormData("vehicleDataNoticeDays", value)}
              />
              <DataBox
                title={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.configuration.driver.data"
                    }
                  />
                }
                email={formData.driverDataEmail}
                setEmail={value => setFormData("driverDataEmail", value)}
                download={formData.driverDataDownloadDays}
                setDownload={value =>
                  setFormData("driverDataDownloadDays", value)}
                notice={formData.driverDataNoticeDays}
                setNotice={value => setFormData("driverDataNoticeDays", value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box component={Paper} display="flex" flexDirection="column">
              <CardBox
                title={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.configuration.organization.card"
                    }
                  />
                }
                email={formData.organizationCardEmail}
                setEmail={value => setFormData("organizationCardEmail", value)}
                expiration={formData.organizationCardExpiration}
                setExpiration={value =>
                  setFormData("organizationCardExpiration", value)}
              />
              <CardBox
                title={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.configuration.driver.card"
                    }
                  />
                }
                email={formData.driverCardEmail}
                setEmail={value => setFormData("driverCardEmail", value)}
                expiration={formData.driverCardExpiration}
                setExpiration={value =>
                  setFormData("driverCardExpiration", value)}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box component={Paper} display="flex" flexDirection="column">
              <BaseAlertBox
                title={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.configuration.driver.no.standard.files"
                    }
                  />
                }
                email={formData.driverFileEmail}
                setEmail={value => setFormData("driverFileEmail", value)}
              />
              <BaseAlertBox
                title={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.configuration.vehicle.no.standard.files"
                    }
                  />
                }
                email={formData.vehicleFileEmail}
                setEmail={value => setFormData("vehicleFileEmail", value)}
              />
            </Box>
          </Grid>
        </GridContainer>
        <Box mt="2em" display="flex" justifyContent="end">
          <ContainedButton
            id={id}
            text={<IntlMessages id={"form.button.save"} />}
            type="submit"
            color="primary"
          />
        </Box>
      </Box>
    </form>
  );
};

export default Form;
