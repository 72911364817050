import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import useForm from "../../../../../../hooks/Common/useForm";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import DriversSelect from "../../../Files/FilesTable/Filters/DriversSelect";
import VehiclesSelect from "../../../Files/FilesTable/Filters/VehiclesSelect";
import TypesSelect from "./TypesSelect";
import {useDispatch, useSelector} from "react-redux";
import {applyTachographAlertsFilters} from "../../../../../../redux/actions/FleetManagement/TachographAlerts";

const initialData = {
  drivers: [],
  vehicles: [],
  types: []
};

const Filters = ({onClickConfiguration}) => {
  const dispatch = useDispatch();
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographAlerts
  );
  const {formData, setFormData, setValues} = useForm(initialData);
  const onClickSearch = e => {
    e.preventDefault();
    dispatch(applyTachographAlertsFilters(formData));
  };
  React.useEffect(() => {
    setValues(filters);
  }, []);
  return (
    <Box my="1em">
      <FiltersContainer active onClose={() => {}}>
        <form>
          <GridContainer>
            <Grid item xs={12} md={6} lg={4}>
              <DriversSelect formData={formData} setFormData={setFormData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <VehiclesSelect formData={formData} setFormData={setFormData} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TypesSelect formData={formData} setFormData={setFormData} />
            </Grid>
            <Grid item xs={6} md={3} lg={4}>
              <ContainedButton
                fullWidth
                color="primary"
                text={<IntlMessages id={"form.button.search"} />}
                onClick={onClickSearch}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={4}>
              <ContainedButton
                fullWidth
                color="primary"
                text={
                  <IntlMessages
                    id={
                      "fleet.management.tachograph.alerts.table.button.configuration"
                    }
                  />
                }
                onClick={onClickConfiguration}
              />
            </Grid>
          </GridContainer>
        </form>
      </FiltersContainer>
    </Box>
  );
};

export default Filters;
