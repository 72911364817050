import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
// import Warehouse from '../domain/Warehouse';
// import Response from "domain/Response";
import SigningTypes from "../../domain/RRHH/SigningTypes";
import Response from "../../domain/Response";
import {getCompanyEntityId} from "../StorageService";

let fetchAllCancelToken = undefined;

export const fetchAllSingingTypes = async () => {
  //return almacen.map(almacen => Warehouse.map(almacen));
  try {
    const response = await axiosInstance.post(
      "/Jornada_Laboral_Fichaje_Tipo/sel",
      {nu_id_entidad_empresa: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    // return response.data.map(alamcenes => Warehouse.map(alamcenes));
    response.data.forEach(element => {
      element.bi_activo === "False"
        ? (element.bi_activo = false)
        : (element.bi_activo = true);
      var hours = Math.floor(element.nu_tiempo_jornada / 60);
      var minutes = element.nu_tiempo_jornada % 60;
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      element.nu_tiempo_jornada = hours + ":" + minutes;
    });
    return response.data.map(signingTypes => SigningTypes.map(signingTypes));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createNewSigningType = async signingType => {
  try {
    const {
      data
    } = await axiosInstance.post("/Jornada_Laboral_Fichaje_Tipo/ins", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      vc_desc: signingType.description,
      nu_tiempo_jornada: stringToMinutes(signingType.hours),
      nu_tiempo_jornada_margen: signingType.margin
    });
    // return response.data.map(signingTypes => SigningTypes.map(signingTypes));
    // const response = Response.map(data);
    // if (response.transactionState === "0") {
    //     throw Error(response.transactionMessage);
    // }
    // return response;
    return Response.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const deleteSigningType = async signingType => {
  try {
    const {
      data
    } = await axiosInstance.post("/Jornada_Laboral_Fichaje_Tipo/del", {
      nu_id_fichaje_tipo: signingType
    });
    return Response.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};
export const restoreSigningType = async signingType => {
  try {
    const {
      data
    } = await axiosInstance.post("/Jornada_Laboral_Fichaje_Tipo/upd", {
      nu_id_fichaje_tipo: signingType,
      vc_desc: "",
      nu_tiempo_jornada: 0,
      nu_tiempo_jornada_margen: 0,
      bi_activo: true
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
export const updateNewSigningType = async (id, signingType) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Jornada_Laboral_Fichaje_Tipo/upd", {
      nu_id_fichaje_tipo: id,
      vc_desc: signingType.description,
      nu_tiempo_jornada: stringToMinutes(signingType.hours),
      nu_tiempo_jornada_margen: signingType.margin
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
const stringToMinutes = str => {
  let hoursAndMinutes = str.split(":");
  let hours = hoursAndMinutes[0];
  let mins = hoursAndMinutes[1];
  var d = new Date(1970, 1, 1, hours, mins);
  var minutes = d.getMinutes() + d.getHours() * 60;
  return minutes;
};
