import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "./Form";

const EditSupervisorDialog = ({initialData, isOpen,
                                onClose, onSubmit,
    title = <IntlMessages id="administrator.supervisors.editFormTitle" />
}) => (
  <FormDialog
    id="edit-supervisor-form"
    isOpen={isOpen}
    title={title}
    onClose={onClose}
    disableEnforceFocus
  >
    <Form
      id="edit-supervisor-form"
      initialData={initialData}
      onSubmit={onSubmit}
    />
  </FormDialog>
);

export default EditSupervisorDialog;
