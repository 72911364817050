import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import WorkshopRequest from "../../../domain/FleetManagement/DailyOperations/WorkshopRequest";
import Response from "../../../domain/Response";
import WorkshopProvider from "../../../domain/FleetManagement/DailyOperations/WorkshopProvider";
import WorkshopRequestState from "../../../domain/FleetManagement/DailyOperations/WorkshopRequestState";

export const getAllWorkshops = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/sel_taller_proveedor",
            {
                nu_id_entidad: getCompanyEntityId()
            });
        return data.map(d => WorkshopProvider.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getAllWorkshopRequestStates = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/sel_estados_solicitud_taller",
            {
                nu_id_entidad: getCompanyEntityId()
            });
        return data.map(d => WorkshopRequestState.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};


export const getAllWorkshopRequests = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/sel_solicitud_taller",
            {
                nu_id_entidad: getCompanyEntityId()
            });
        return data.map(d => WorkshopRequest.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};


export const getWorkshopRequestById = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            "/VehiculoTaller/get_solicitud_taller",
            {
                nu_id_solicitud_taller: request.id
            });
        return WorkshopRequest.map(data);
    } catch (e) {
        return Promise.reject(e);
    }
};

export const createWorkshopRequest = async workshop => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/ins_solicitud_taller", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_vehiculo: workshop.vehicle,
            nu_id_taller_proveedor: workshop.workshop
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateWorkshopRequest = async workshop => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/upd_solicitud_taller", {
            nu_id_solicitud_taller: workshop.id,
            nu_id_vehiculo: workshop.vehicle,
            nu_id_taller_proveedor: workshop.workshop,
            dt_fec_solicitud: workshop.date,
            nu_id_solicitud_taller_estado: workshop.status,
            dt_fec_respuesta: workshop.responseDate,
            dt_fec_entrada_taller: workshop.entranceDate,
            nu_presupuesto_total: workshop.amount,
            ls_trabajos: workshop.tasks.map(t => {
                if(t.id === 0) {
                    return {
                        nu_id_solicitud_taller_trabajos: null,
                        nu_id_tipo_trabajo: t.type,
                        nu_id_trabajo_informacion: t.information,
                        vc_observaciones: t.observations,
                        vc_observaciones_taller: t.workshopObservations,
                        nu_costo: t.cost,
                        nu_id_solicitud_taller_trabajo_estado: t.status
                    }
                }
                return {
                    nu_id_solicitud_taller_trabajos: t.id,
                    nu_id_tipo_trabajo: t.type.id || t.type,
                    nu_id_trabajo_informacion: t.information.id || t.information,
                    vc_observaciones: t.observations,
                    vc_observaciones_taller: t.workshopObservations,
                    nu_costo: t.cost,
                    nu_id_solicitud_taller_trabajo_estado: t.status.id || t.status,
                }
            } )
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateWorkshopRequestTasks = async (workshop, tasks) => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/upd_solicitud_taller_trabajos", {
            nu_id_solicitud_taller: workshop.id,
            bi_estado: tasks
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};
