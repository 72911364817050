import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const DateFormatSelect = ({value, onChange, error, hasError}) => {
    const id = 'date-format-filter';
    const formats = [
        {
            id: 1,
            description: <IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.format.date.monthly" />
        },
        {
            id: 2,
            description: <IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.format.date.weekly" />
        },
        {
            id: 3,
            description: <IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.format.date.month.accumulated" />
        },
        {
            id: 4,
            description: <IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.format.date.week.accumulated" />
        },
    ]
    return (
        <Select
            id={id}
            label={
                <IntlMessages
                    id={"control.panel.kpi.areas.grid.distribution.filters.format.date"}
                />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            error={hasError}
            errorMessage={error}
            fullWidth
        >
            {formats.map(o => (
                <MenuItem key={o.id} value={o.id}>
                    {o.description}
                </MenuItem>
            ))}
        </Select>
    );
}

DateFormatSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any
};

export default DateFormatSelect;