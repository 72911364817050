import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {operations} from "../constants";
import {MenuItem} from "@material-ui/core";

const OperationSelect = ({value, onChange}) => {
    return (
        <Select
            name="operation"
            value={value}
            onChange={e => onChange(e.target.value)}
            label={<IntlMessages id="operation" />}
            fullWidth
        >
            {Object.entries(operations)
                .map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                        <IntlMessages id={value.text} />
                    </MenuItem>
                ))
            }
        </Select>
    );
};

export default OperationSelect;