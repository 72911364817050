import {mapToBoolean} from "../utils/mappers";

class Headquarter {
  constructor(
    id,
    name,
    fiscal,
    address,
    number,
    postalCode,
    municipality,
    province,
    country,
    latitude,
    longitude,
    state,
    ubigeo,
  ) {
    this.id = id;
    this.name = name;
    this.fiscal = fiscal;
    this.address = address;
    this.number = number;
    this.postalCode = postalCode;
    this.municipality = municipality;
    this.province = province;
    this.country = country;
    this.latitude = latitude;
    this.longitude = longitude;
    this.state = state;
    this.ubigeo = {
      id: ubigeo
    };
  }

  static map = headquarter => {
    return new Headquarter(
      headquarter.nu_id_entidad_sede,
      headquarter.vc_nombre_sede,
      headquarter.bi_sede_fiscal === "1",
      headquarter.vc_calle,
      headquarter.vc_numero,
      headquarter.vc_cod_postal,
      headquarter.vc_municipio,
      headquarter.vc_provincia,
      headquarter.vc_pais,
      headquarter.nu_latitud,
      headquarter.nu_longitud,
      mapToBoolean(headquarter.bi_estado),
        headquarter.vc_ubigeo,
    );
  };
}

export default Headquarter;
