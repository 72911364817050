import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  createWarehouse,
  editWarehouse,
  cancelPrevWarehouseFetchAll,
  fetchAllWarehouse,
  changeStateWarehouse
} from "../../../services/WarehouseService";

import {
  FETCH_WAREHOUSE_SUCCEEDED,
  APPLY_WAREHOUSE_FILTERS,
  RESET_WAREHOUSE_FILTERS,
  WAREHOUSE_CHANGE_STATE,
  WAREHOUSE_EDIT,
  WAREHOUSE_CREATE
} from "../../types/Administrator/Warehouse";

import Warehouse from "../../../domain/Warehouse";
import {getCompanyEntityId} from "../../../services/StorageService";

export const resetState = () => dispatch => {
  cancelPrevWarehouseFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchWarehouseSucceeded = data => ({
  type: FETCH_WAREHOUSE_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchWarehouse = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevWarehouseFetchAll();
  try {
    const warehouse = await fetchAllWarehouse();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchWarehouseSucceeded(warehouse));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applyWarehouseFilter = filters => ({
  type: APPLY_WAREHOUSE_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetWarehouseFilters = () => ({type: RESET_WAREHOUSE_FILTERS});

const createWarehouseAction = data => ({
  type: WAREHOUSE_CREATE,
  payload: {
    ...data
  }
});

export const createWarehouseStarted = data => async dispatch => {
  dispatch(closeCreateForm());
  dispatch(ui.createStarted());

  try {
    const response = await createWarehouse(data);
    const warehouse = new Warehouse(
      response.id,
      data.name,
      data.sede.value,
      data.sede.label,
      data.allowNegativeStock,
      "9/25/2021",
      "9/25/2021",
      true
    );
    dispatch(createWarehouseAction(warehouse));
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const editWarehouseAction = (id, data) => ({
  type: WAREHOUSE_EDIT,
  payload: {
    ...data,
    id
  }
});

export const editWarehouseStarted = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await editWarehouse(id, data);
    dispatch(editWarehouseAction(id, data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeWarehouseSucceeded = id => ({
  type: WAREHOUSE_CHANGE_STATE,
  payload: {
    id
  }
});

const changeWarehouse = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  await changeStateWarehouse(id);
  dispatch(changeWarehouseSucceeded(id));
  dispatch(ui.changeStateSucceeded());
};

export const deleteWarehouseStarted = id => dispatch => {
  dispatch(changeWarehouse(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreWarehouseStarted = id => dispatch => {
  dispatch(changeWarehouse(id));
  dispatch(ui.closeRestoreConfirmation());
};

export default {
  get: fetchWarehouse,
  delete: deleteWarehouseStarted,
  restore: restoreWarehouseStarted,
  edit: editWarehouseStarted,
  create: createWarehouseStarted,
  applyWarehouseFilter,
  resetWarehouseFilters,
  resetState
};
