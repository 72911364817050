import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "../../../../../../../Common/Forms/Select";

const GroupTypeSelect = ({id, options, value, onChange, isLoading, errors}) => {
  useEffect(() => {
    onChange(options[0].id);
  }, []);
  return (
    <Select
      id={id}
      label={
        <IntlMessages id="administrator.reports.group.type.select.label" />
      }
      value={value}
      disabled={isLoading}
      onChange={e => onChange(e.target.value)}
      error={errors.groupType.length > 0}
      errorMessage={errors.groupType[0]}
      fullWidth
    >
      {/*<MenuItem value={''}>*/}
      {/*    <IntlMessages id="administrator.reports.group.type.select.one" />*/}
      {/*</MenuItem>*/}
      {options.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default GroupTypeSelect;
