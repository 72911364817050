import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  ADD_ELEVATOR_CATEGORY,
  APPLY_ELEVATORS_CATEGORIES_FILTERS,
  EDIT_ELEVATOR_CATEGORY,
  FETCH_ALL_ELEVATORS_CATEGORIES,
  RESET_CURRENT_ELEVATOR_CATEGORY,
  RESET_ELEVATORS_CATEGORIES_FILTERS,
  RESET_ELEVATORS_CATEGORIES_STATE,
  SET_CURRENT_ELEVATOR_CATEGORY
} from "../../types/ElevatorsManagement/CategoriesTypes";
import {
  createElevatorCategory,
  getAllElevatorsCategories,
  updateElevatorCategory,
  updateElevatorCategoryState
} from "../../../services/ElevatorsManagement/CategoriesService";
import ElevatorCategory from "../../../domain/ElevatorsManagement/ElevatorCategory";

export const resetElevatorsCategoriesState = () => ({
  type: RESET_ELEVATORS_CATEGORIES_STATE
});
export const applyElevatorsCategoriesFilters = filters => ({
  type: APPLY_ELEVATORS_CATEGORIES_FILTERS,
  payload: filters
});
export const resetElevatorsCategoriesFilters = () => ({
  type: RESET_ELEVATORS_CATEGORIES_FILTERS
});
export const resetCurrentElevatorCategory = () => ({
  type: RESET_CURRENT_ELEVATOR_CATEGORY
});
export const setCurrentElevatorCategory = current => ({
  type: SET_CURRENT_ELEVATOR_CATEGORY,
  payload: current
});
export const fetchAllElevatorsCategories = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllElevatorsCategories(filters)
      .then(categories => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_ELEVATORS_CATEGORIES, payload: categories});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const editElevatorCategory = (current, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorCategory(current, data.name)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.name = data.name;
        dispatch({type: EDIT_ELEVATOR_CATEGORY, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editElevatorCategoryState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorCategoryState(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.state = state;
        dispatch({type: EDIT_ELEVATOR_CATEGORY, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addElevatorCategory = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createElevatorCategory(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newCategory = new ElevatorCategory(
          response.transactionKey,
          data.name,
          true
        );
        dispatch({type: ADD_ELEVATOR_CATEGORY, payload: newCategory});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
