import React from "react";
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import GroupItem from "./GroupItem";
import {useStyles} from "../styles";

const GroupList = ({checklists}) => {
  const classes = useStyles();
  return (
    <Box className={classes.listBox}>
      {checklists.map(e => <GroupItem key={e.id} group={e} />)}
    </Box>
  );
};

GroupList.propTypes = {
  checklists: PropTypes.array.isRequired
};

export default GroupList;
