import Submodule from "./Submodule";

class Module {
    constructor(id, description, submodules, state) {
        this.id = id;
        this.description = description;
        this.submodules = submodules;
        this.state = state
    }

    static map = module => {
        return new Module(
            Number(module.nu_id_modulo),
            module.vc_desc_modulo,
            module.submodulos?.map(o => Submodule.map(o)),
            module.bi_estado || true
        );
    }
}

export default Module;