import {
  ADD_EXPENSE_FAILED,
  ADD_EXPENSE_STARTED,
  ADD_EXPENSE_SUCCESSFUL,
  RESET_ADD_EXPENSE_FORM
} from "../../types/CustomTypes";

const initState = {
  formData: {
    box: "",
    travel: "",
    expenseType: "",
    driver: "",
    route: "",
    ruc: "",
    businessName: "",
    address: "",
    documentType: "",
    documentNumber: "",
    amount: "",
    observations: "",
    files: []
  },
  isLoading: false,
  saveSucceeded: undefined,
  fetchSucceeded: undefined
};

export default (state = initState, action) => {
  switch (action.type) {
    case ADD_EXPENSE_STARTED: {
      return {
        ...state,
        formData: action.payload,
        isLoading: true
      };
    }
    case ADD_EXPENSE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        saveSucceeded: true,
        formData: {...initState.formData}
      };
    }
    case ADD_EXPENSE_FAILED: {
      return {
        ...state,
        isLoading: false
      };
    }
    case RESET_ADD_EXPENSE_FORM: {
      return {
        ...state,
        formData: {...initState.formData}
      };
    }
    default:
      return state;
  }
};
