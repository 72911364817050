import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Select from "components/Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LOG_HISTORY_ACTIONS from "models/Administrator/LogHistoryActions";

const ActionsSelect = ({selected, onChange}) => {
  const actions = [
    {
      id: 1,
      text: <IntlMessages id="administrator.logHistory.filters.action.create" />
    },
    {
      id: 2,
      text: <IntlMessages id="administrator.logHistory.filters.action.update" />
    },
    {
      id: 3,
      text: <IntlMessages id="administrator.logHistory.filters.action.delete" />
    }
  ];
  return (
    <Select
      id="administrator-log-history-actions-select"
      label={<IntlMessages id="administrator.logHistory.filters.action" />}
      value={selected}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value="">
        <IntlMessages id="administrator.logHistory.filters.action.all" />
      </MenuItem>
      {actions.map(action => (
        <MenuItem key={action.id} value={action.id}>
          {action.text}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ActionsSelect;
