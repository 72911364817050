import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {cancelPrevIncidentFetchAll, createIncidence, fetchAllIncident} from "../../../services/IncidenceService";
import {FETCH_INCIDENCE_SUCCEEDED} from "../../types/Administrator/Incidence";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevIncidentFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchIncidenceSucceeded = (data, filters) => ({
  type: FETCH_INCIDENCE_SUCCEEDED,
  payload: {
    data,
    filters,
  }
});

export const fetchIncidence = filters => async dispatch => {
  try {
    cancelPrevIncidentFetchAll();
    dispatch(fetchStart());
    const incidence = await fetchAllIncident(filters);
    dispatch(fetchSuccess());
    dispatch(fetchIncidenceSucceeded(incidence, filters));
  } catch (error) {
    dispatch(fetchError(error.message));
  }
};

export const createIncidenceStarted = (data, filters) => async dispatch => {
  try {
    dispatch(closeCreateForm());
    dispatch(fetchStart());
    const response = await createIncidence(data);
    dispatch(fetchSuccess(response.message));
    if(filters) {
      setTimeout(() => {
        dispatch(fetchIncidence(filters));
      }, 800);
    }
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(fetchError(error.message));
  }
};

export const showIncidenceStarted = (id, data) => dispatch => {
  dispatch(ui.closeEditForm());
};

export default {
  get: fetchIncidence,
  create: createIncidenceStarted,
  edit: showIncidenceStarted,
  resetState
};
