import React from "react";
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import Form from "./Form";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const AddDialog = ({openAdd, setOpenAdd, onSave}) => {
  const id = "add-elevator-contact-form";
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.contact.client.add.title"
          }
        />
      }
      isOpen={openAdd}
      onClose={() => setOpenAdd(false)}
    >
      {openAdd && (
        <Form
          id={id}
          initData={{
            name: "",
            email: "",
            phone: ""
          }}
          onSubmit={onSave}
        />
      )}
    </FormDialog>
  );
};

export default AddDialog;
