import {FETCH_ALL_CURRENCIES_SUCCEEDED} from "../../types/Common/MoneyCustomTypes";

const INIT_STATE = {
  currencies: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CLEAR_CURRENCIES": {
      return {
        ...state,
        currencies: []
      };
    }
    case FETCH_ALL_CURRENCIES_SUCCEEDED: {
      return {
        ...state,
        currencies: [ ...action.payload.data ]
      };
    }
    default: {
      return state;
    }
  }
};
