import React from "react";
import {Table, TableBody, TableFooter, TableHead} from "@material-ui/core";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {parseNumberToMoney} from "../../../../../utils/parsers";

const ExpensesBaseTable = ({header, children, cols, total, partialTotal = null}) => (
  <Table>
    <TableHead>{header}</TableHead>
    <TableBody>{children}</TableBody>
    <TableFooter>
      <StyledTableRow>
        <StyledTableCell align="right" colSpan={cols}>
          <IntlMessages
            id={"guardian.accounting.dialog.expenses.table.footer"}
          />{" "}
          : {parseNumberToMoney(total)}
        </StyledTableCell>
          {partialTotal !== null &&
              <StyledTableCell align="right" colSpan={2}>
                  <IntlMessages
                      id={"partial.amount"}
                  />{" "}
                  : {parseNumberToMoney(partialTotal)}
              </StyledTableCell>
          }
      </StyledTableRow>
    </TableFooter>
  </Table>
);

export default ExpensesBaseTable;
