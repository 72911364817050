import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  listBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "10px",
    gap: 10
  },
  accordionSummary: {
    backgroundColor: "#3f51b5",
    color: theme.palette.text.white,
    paddingLeft: "10px",
    paddingRight: "10px",
    borderRadius: "20px 20px 0 0"
  },
  headerIcon: {
    color: theme.palette.text.white
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.white
  },
  accordionContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "1em",
    borderRadius: "0 0 20px 20px"
  }
}));
