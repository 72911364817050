import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    createElevatorType,
    getAllElevatorsTypes, updateElevatorType, updateElevatorTypeState
} from "../../../services/ElevatorsManagement/TypesService";


const namespace = 'elevatorManagement/types';

export const fetchAllTypes = createAsyncThunk(
    `${namespace}/fetchAllTypes`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const types = await getAllElevatorsTypes();
            dispatch(fetchSuccess());
            return {
                types,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);

export const addType = createAsyncThunk(
    `${namespace}/addType`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await createElevatorType(request);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                ...request,
                id: response.transactionKey,
                state: true,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);

export const editType = createAsyncThunk(
    `${namespace}/editType`,
    async ({request, current}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateElevatorType(current.id, request.name);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                ...current,
                ...request,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);

export const editTypeState = createAsyncThunk(
    `${namespace}/editTypeState`,
    async ({id, state}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateElevatorTypeState(id, state);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                id,
                state,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);