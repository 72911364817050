import moment from "moment";
import {
  ADD_RESERVE,
  APPLY_RESERVES_FILTERS,
  EDIT_RESERVE,
  FETCH_ALL_RESERVES,
  RESET_RESERVES_FILTERS,
  RESET_RESERVES_STATE,
  SET_CURRENT_RESERVE
} from "../../types/FleetManagement/ReservesTypes";

const INIT_STATE = {
  data: [],
  filters: {
    plaque: "",
    brand: "",
    model: "",
    startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    driver: "",
    turn: "",
    state: null
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_RESERVES_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_RESERVES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case FETCH_ALL_RESERVES: {
      return {
        ...state,
        data: action.payload
      };
    }
    case SET_CURRENT_RESERVE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case ADD_RESERVE: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: state.data.length + 1,
            ...action.payload
          }
        ]
      };
    }
    case EDIT_RESERVE: {
      return {
        ...state,
        data: state.data.map(d => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        })
      };
    }
    case RESET_RESERVES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
