import {
    APPLY_ACCIDENTS_FILTERS,
    FETCH_ALL_ACCIDENTS,
    RESET_ACCIDENTS_FILTERS,
    RESET_ACCIDENTS_STATE,
    SET_CURRENT_ACCIDENT
} from "../../types/FleetManagement/AccidentsType";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
    createSinister,
    getAllSinisters,
    getSinisterById,
    updateSinister
} from "../../../services/FleetManagement/Vehicles/SinisterService";


export const resetAccidentsState = () => ({type: RESET_ACCIDENTS_STATE});
export const applyAccidentsFilters = filters => ({
    type: APPLY_ACCIDENTS_FILTERS,
    payload: filters
});
export const resetAccidentsFilters = () => ({type: RESET_ACCIDENTS_FILTERS});
export const setCurrentAccident = accident => {
    return async dispatch => {
        if(accident) {
            dispatch(fetchStart());
            getSinisterById(accident)
                .then(sinister => {
                    dispatch(fetchSuccess());
                    dispatch({type: SET_CURRENT_ACCIDENT, payload: sinister});
                })
                .catch(err => {
                    dispatch(fetchError(err.message));
                });
        }else {
            dispatch({type: SET_CURRENT_ACCIDENT, payload: accident});
        }

    };
};
export const fetchAllAccidents = filters => {
    return async dispatch => {
        dispatch(fetchStart());
        getAllSinisters()
            .then(data => {
                dispatch(fetchSuccess());
                dispatch({type: FETCH_ALL_ACCIDENTS, payload: data});
            })
            .catch(err => {
                dispatch(fetchError(err.message));
            });
    };
};

export const addAccident = (accident, onClose) => {
    return async dispatch => {
        dispatch(fetchStart());
        createSinister(accident)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                onClose && onClose();
                setTimeout(() => dispatch(fetchAllAccidents({})), 1500);
            }).catch(err => {
            dispatch(fetchError(err.message));
        })
    };
};

export const editAccident = (accident, onClose) => {
    return async dispatch => {
        dispatch(fetchStart());
        updateSinister(accident)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                onClose && onClose();
                setTimeout(() => dispatch(fetchAllAccidents({})), 1500);
            }).catch(err => {
            dispatch(fetchError(err.message));
        });
    };
};
