import React from "react";

import Confirmation from "components/Common/Dialogs/Confirmation";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  editRejectionTypeState,
  resetCurrentRejectionType
} from "../../../../../redux/actions/Logistic/RejectionTypes";
import {useDispatch, useSelector} from "react-redux";

const RestoreConfirmation = ({open, setOpenRestore}) => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.rejectionTypes);
  const onRestore = () => {
    dispatch(editRejectionTypeState(current, true));
    setOpenRestore && setOpenRestore(false);
  };
  const onCancel = () => {
    dispatch(resetCurrentRejectionType());
    setOpenRestore && setOpenRestore(false);
  };
  return (
    <Confirmation
      open={open}
      onAccept={onRestore}
      onReject={onCancel}
      text={
        <IntlMessages id="logistic.distribution.rejection.types.restore.description" />
      }
    />
  );
};

export default RestoreConfirmation;
