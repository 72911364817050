import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="product"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.field.product" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="stock"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.field.stock" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="amount"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.field.quantity" />
    </TableCellWithSorting>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
