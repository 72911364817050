import React from "react";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {
  addAlertConfiguration,
  fetchAllAlertsConfigData
} from "../../../../../redux/actions/FleetManagement/TachographAlerts";
import Form from "./Form";

const ConfigurationDialog = ({open, setOpen}) => {
  const id = "config-form";
  const dispatch = useDispatch();
  const {configData} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographAlerts
  );
  const onSave = data => {
    dispatch(addAlertConfiguration({id: configData.id, ...data}));
    setOpen(false);
  };
  React.useEffect(() => {
    dispatch(fetchAllAlertsConfigData());
  }, []);

  return (
    <FullScreenDialog
      title={"fleet.management.tachograph.alerts.table.button.configuration"}
      open={open}
      handleClose={() => setOpen(false)}
    >
      {open && <Form id={id} initData={configData} onSubmit={onSave} />}
    </FullScreenDialog>
  );
};

export default ConfigurationDialog;
