import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Divider
} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RealTimeTable from "./RealtTimeTable";
import EstimatedTimeTable from "./EstimatedTimeTable";
import {
    openBreakdownDialog,
    setOtLikeCurrentDestination
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import {dialogsList} from "../../constants";

const useStyles = makeStyles(theme => ({
    details: {
        alignItems: "center"
    }
}));

const WorkOrderDetail = () => {
    const classes = useStyles();
    const {detail, filters} = useSelector(state => state.administrator.breakdown);
    const dispatch = useDispatch();
    const onClickDidTimes = (e) => {
        e.stopPropagation();
        dispatch(openBreakdownDialog(dialogsList.historyData));
        dispatch(setOtLikeCurrentDestination(detail, filters.ot));
    }
    return (
        <div
            style={{
                marginBottom: "32px"
            }}
        >
            {/*<Box component="h2" fontSize={20} mt={0} mb={3}>*/}
            {/*    {detail.id}*/}
            {/*</Box>*/}
            <Box mt={4}>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="detail-content"
                        id="detail-header"
                    >
                        <RealTimeTable realTime={detail.realTime}/>
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        <React.Fragment>
                            <Divider variant="inset" flexItem/>
                            <EstimatedTimeTable estimatedTime={detail.estimatedTime} onClickDidTimes={onClickDidTimes}/>
                        </React.Fragment>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
};

export default WorkOrderDetail;
