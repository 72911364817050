import React from "react";
import {useDispatch, useSelector} from "react-redux";
import SuccessAlert from "../../../Common/SnackBars/SuccessAlert";
import ErrorAlert from "../../../Common/SnackBars/ErrorAlert";
import {resetAssigmentResult} from "../../../../redux/actions/Logistic/Assigment";

const ResultAlert = () => {
  const {ok, message} = useSelector(state => state.logistic.assigment.result);
  const [ open, setOpen ] = React.useState(true);
  const dispatch = useDispatch();
  const onClose = () => {
    dispatch(resetAssigmentResult());
  };
  React.useEffect(
    () => {
      !open && onClose();
    },
    [ open ]
  );

  return (
    <React.Fragment>
      {ok !== undefined &&
        (ok ? (
          <SuccessAlert
            setOpenAlert={setOpen}
            openAlert={open}
            message={message}
          />
        ) : (
          <ErrorAlert
            setOpenAlert={setOpen}
            openAlert={open}
            message={message}
          />
        ))}
    </React.Fragment>
  );
};

export default ResultAlert;
