import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const initialData = {
  description: ""
};

const AddDialog = ({id, openAdd, setOpenAdd, title, onAddHandler}) => {
  return (
    <FormDialog
      id={id}
      title={title}
      isOpen={openAdd}
      onClose={() => setOpenAdd(false)}
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
    >
      {openAdd && (
        <Form initialData={initialData} id={id} onAddHandler={onAddHandler} />
      )}
    </FormDialog>
  );
};

export default AddDialog;
