import React from "react";
import PageContainer from "../../../../../components/Configuration/UserSettings/Users/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {
  resetConfigurationUsersState,
  setCurrentConfigurationUser,
  updateConfigurationUserState
} from "../../../../../redux/actions/Configuration/Users";
import UsersTable from "../../../../../components/Configuration/UserSettings/Users/UsersTable";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import UpdateUserDialog from "../../../../../components/Configuration/UserSettings/Users/UpdateUserDialog";

const Users = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(({configuration}) => configuration.users);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  React.useEffect(
    () => {
      return () => {
        dispatch(resetConfigurationUsersState());
      };
    },
    [ dispatch ]
  );
  return (
    <PageContainer>
      <UsersTable
        openEditDialog={() => setOpenEdit(true)}
        openRestoreDialog={() => setOpenRestore(true)}
        openDeleteDialog={() => setOpenDelete(true)}
      />
      <UpdateUserDialog open={openEdit} setOpen={setOpenEdit} />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages id={"configuration.user.settings.users.delete.text"} />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(setCurrentConfigurationUser(null));
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(updateConfigurationUserState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages id={"configuration.user.settings.users.restore.text"} />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(setCurrentConfigurationUser(null));
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(updateConfigurationUserState(current, true));
        }}
      />
    </PageContainer>
  );
};

export default Users;
