import React, {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ListAltIcon from '@material-ui/icons/ListAlt';
import {
    openBreakdownDialog,
    setCurrentBreakdownDestination
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import {dialogsList} from "../../constants";
import {useIntl} from "react-intl";
import {DataGrid} from "@mui/x-data-grid";
import ExportToolbar from "../../../../Common/Tables/DataGrid/ExportToolbar";

const DestinationsTable = () => {
    const {destinations} = useSelector(
        state => state.administrator.breakdown
    );
    const dispatch = useDispatch();
    const intl = useIntl();
    const data = useMemo(() => {
        return destinations.map(destination => ({
            id: destination.id,
            origin: destination.route.origin,
            destination: destination.route.destination,
            planningPlace: destination.route.planningPlace,
            didTimes: destination.estimatedTime.didTimes,
            average: destination.estimatedTime.average,
            googleTime: destination.estimatedTime.googleTime,
            centralTime: destination.estimatedTime.centralTime,
            traveledKilometers: destination.estimatedTime.traveledKilometers,
            googleKilometers: destination.estimatedTime.googleKilometers,
            centralKilometers: destination.estimatedTime.centralKilometers,
        }));
    }, [destinations]);
    const columns = [
        {
            field: 'id',
            hide: true,
        },
        {
            field: 'origin',
            headerName: intl.messages["origin"],
            minWidth: 180,
        },
        {
            field: 'destination',
            headerName: intl.messages["destination"],
            minWidth: 180,
        },
        {
            field: 'planningPlace',
            headerName: intl.messages["planning.place"],
            minWidth: 180,
        },
        {
            field: 'didTimes',
            headerName: intl.messages["did"],
            minWidth: 180,
        },
        {
            field: 'average',
            headerName: `${intl.messages["average"]} ${intl.messages["hh.mm"]}`,
            minWidth: 180,
        },
        {
            field: 'googleTime',
            headerName: `${intl.messages["google"]} ${intl.messages["hh.mm"]}`,
            minWidth: 180,
        },
        {
            field: 'centralTime',
            headerName: `${intl.messages["matrix"]} ${intl.messages["hh.mm"]}`,
            minWidth: 180,
        },
        {
            field: 'traveledKilometers',
            headerName: `${intl.messages["average"]} ${intl.messages["kms"]}`,
            minWidth: 180,
        },
        {
            field: 'googleKilometers',
            headerName: `${intl.messages["google"]} ${intl.messages["kms"]}`,
            minWidth: 180,
        },
        {
            field: 'centralKilometers',
            headerName: `${intl.messages["matrix"]} ${intl.messages["kms"]}`,
            minWidth: 180,
        },
        {
            field: 'actions',
            headerName: intl.messages["options"],
            type: 'actions',
            disableExport: true,
            renderCell: ({row}) => {
                const destination = destinations.find(destination => destination.id === row.id);
                return (
                    <>
                        <IconButton onClick={() => onEdit(destination)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => onHistoryDetails(destination)}>
                            <ListAltIcon />
                        </IconButton>
                    </>
                )
            }
        }

    ]
    const onEdit = (destination) => {
        dispatch(openBreakdownDialog(dialogsList.matrixData));
        dispatch(setCurrentBreakdownDestination(destination, dialogsList.matrixData));
    }
    const onHistoryDetails = destination => {
        dispatch(openBreakdownDialog(dialogsList.historyData));
        dispatch(setCurrentBreakdownDestination(destination, dialogsList.historyData));
    }
    return (
        <DataGrid
            columns={columns.map(column => ({...column, sortable: false}))}
            rows={data || []}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            disableColumnMenu
            components={{
                Toolbar: ExportToolbar,
            }}
        />
    )
};

export default DestinationsTable;