import {generateColor} from "../utils/colorGenerate";

class Tag {
  constructor(id, description, typeID, state, color = generateColor()) {
    this.id = Number(id);
    this.name = description;
    this.typeID = typeID;
    this.state = state;
    this.description = description;
    this.slug = description?.toLowerCase();
    this.color = color;
  }

  static map = tag => {
    return new Tag(
        tag.nu_id_tag,
        tag.vc_desc_tag,
        tag.nu_id_tipo_tag,
        tag.bi_estado === '1',
        tag.vc_color);
  };
}

export default Tag;
