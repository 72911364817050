import React from 'react';
import {useFetch} from "../../../../hooks/Common/useFetch";
import {getAllChecklistMarkOptions} from "../../../../services/Logistics/Distribution/ChecklistTypeService";
import Select from "../../../Common/Forms/Select";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const MarkOptionsSelect = ({value, onChange, ...rest}) => {
    const id = 'mark-option-select';
    const {data} = useFetch(getAllChecklistMarkOptions, {}, []);
    const handleChange = ({target}) => {
        const {value} = target;
        onChange(value);
    }
    return (
        <Select
            id={id}
            name='markOption'
            label={<IntlMessages id='mark.option' />}
            onChange={handleChange}
            value={value}
            fullWidth
            {...rest}
        >
            {data.map(e => (
              <MenuItem key={e.id} value={e.id}>
                  {e.name}
              </MenuItem>
            ))}
        </Select>
    );
};

export default MarkOptionsSelect;