import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import IntlMessages from "@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <React.Fragment>
    <StyledTableRow>
      <StyledTableCell />
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.entity" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.campus" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.warehouse" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.product" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.amount" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.stockAvailable" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.stockCommitted" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="guardian.stock.table.tags" />
      </StyledTableCell>
    </StyledTableRow>
  </React.Fragment>
);

export default TableHeader;
