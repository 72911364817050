import React from "react";
import PageContainer from "../../../../../../components/ElevatorsManagement/Elevators/DailyOperations/IndexCard/PageContainer";
import ElevatorsTable from "../../../../../../components/ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorsTable";
import {useDispatch, useSelector} from "react-redux";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {resetElevatorsState} from "../../../../../../redux/actions/ElevatorsManagement/Elevators";
import ElevatorDetailDialog from "../../../../../../components/ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog";
import AddElevatorDialog from "../../../../../../components/ElevatorsManagement/Elevators/DailyOperations/IndexCard/AddElevatorDialog";

const IndexCard = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openAdd, setOpenAdd ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetElevatorsState());
    };
  }, []);
  return (
    <PageContainer>
      <ElevatorsTable openEdit={() => setOpenEdit(true)} />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <ElevatorDetailDialog open={openEdit} setOpen={setOpenEdit} />
      <AddElevatorDialog open={openAdd} setOpen={setOpenAdd} />
    </PageContainer>
  );
};

export default IndexCard;
