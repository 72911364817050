import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const YearSelect = ({value, onChange, error, hasError}) => {
    const id = 'years-filter';
    const years = Array.from({length: (new Date().getFullYear() - 2019) + 1}, (_, i) => i + 2019);
    return (
        <Select
            id={id}
            label={
                <IntlMessages
                    id={"control.panel.kpi.areas.grid.distribution.filters.year"}
                />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            error={hasError}
            errorMessage={error}
            fullWidth
        >
            {years.map(o => (
                <MenuItem key={o} value={o}>
                    {o}
                </MenuItem>
            ))}
        </Select>
    );
}

YearSelect.propTypes = {
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.any,
    hasError: PropTypes.any
};

export default YearSelect;