import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";

const TableHeader = ({ orderBy, order, onSort }) => (
  <StyledTableRow>
    <TableCellWithSorting
        property="type"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id={"fleet.management.tachograph.alerts.table.type"} />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="card"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id={"fleet.management.tachograph.alerts.table.card"} />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="expirationDate"
        orderBy={orderBy}
        order={order}
        onSort={onSort}>
      <IntlMessages
        id={"fleet.management.tachograph.alerts.table.expiration.date"}
      />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="identity"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id={"fleet.management.tachograph.alerts.table.identity"} />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="plaque"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id={"fleet.management.tachograph.alerts.table.plaque"} />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="lastDownloadDate"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages
        id={"fleet.management.tachograph.alerts.table.last.download"}
      />
    </TableCellWithSorting>
  </StyledTableRow>
);

export default TableHeader;
