import React from "react";

const DrivingIcon = props => (
  <img
    {...props}
    src={
      process.env.PUBLIC_URL +
      "/images/icons/tachograph-icons/Iconos_Taco_conduccion.svg"
    }
    alt=""
  />
);

export default DrivingIcon;
