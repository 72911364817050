import React from "react";
import Button from "@material-ui/core/Button";

const ContainedButton = ({onClick, type, text, ...rest}) => (
  <Button variant="contained" onClick={onClick} type={type} {...rest}>
    {text}
  </Button>
);

export default ContainedButton;
