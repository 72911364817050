import React, {useState} from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuItem from "@material-ui/core/MenuItem";
import MaterialUIMenu from "@material-ui/core/Menu";

const Menu = ({id, actions, rest}) => {
  const [ anchorEl, setAnchorEl ] = useState(null);

  const onItemClicked = f => () => {
    setAnchorEl(null);
    f();
  };

  const menuActions = actions.map(action => ({
    ...action,
    onClick: onItemClicked(action.onClick)
  }));
  const isOpen = Boolean(anchorEl);

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        aria-controls={id}
        aria-haspopup="true"
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <MaterialUIMenu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={isOpen}
        onClose={() => setAnchorEl(null)}
        {...rest}
      >
        {menuActions.map(action => (
          <MenuItem key={action.name} onClick={action.onClick}>
            {action.label}
          </MenuItem>
        ))}
      </MaterialUIMenu>
    </React.Fragment>
  );
};

Menu.propTypes = {
  id: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    }).isRequired
  )
};

Menu.defaultProps = {
  actions: []
};

export default Menu;
