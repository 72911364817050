import {
  CLEAR_CURRENT_ADVANCE,
  SET_CURRENT_ADVANCE
} from "../../types/CustomTypes";
import {FETCH_CURRENT_ADVANCE_EXPENSES_SUCCEEDED} from "../../types/Guardian/AdvancesCustomTypes";

export const initState = {
  id: "",
  driver: "",
  box: "",
  tags: [],
  createDate: "",
  lastUpdate: "",
  travel: "",
  route: "",
  amount: 0,
  expense: 0,
  passed: 0,
  balance: 0,
  state: null,
  expenses: [],
  adjust: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case CLEAR_CURRENT_ADVANCE: {
      return {
        ...state,
        ...initState
      };
    }
    case SET_CURRENT_ADVANCE: {
      return {
        ...state,
        ...action.payload
      };
    }
    case FETCH_CURRENT_ADVANCE_EXPENSES_SUCCEEDED: {
      return {
        ...state,
        expenses: [ ...action.payload.expenses ]
      };
    }
    default: {
      return state;
    }
  }
};
