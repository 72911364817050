import React from 'react';
import FormDialog from "../../../../../../Common/Dialogs/FormDialog";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const UpdateClientDialog = ({destinationId}) => {
    const id = 'update-client-ot';
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    return (
        <>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={handleClose}
                title={<IntlMessages id='update.client' />}
            >
                {open && <Form id={id} destinationId={destinationId} onClose={handleClose}/>}
            </FormDialog>
            <ContainedButton
                text={<IntlMessages id='update.client' />}
                onClick={handleOpen}
            />
        </>
    );
};

export default UpdateClientDialog;