export const RESET_MAINTENANCE_TYPES_STATE = "RESET_MAINTENANCE_TYPES_STATE";
export const FETCH_ALL_MAINTENANCE_TYPES = "FETCH_ALL_MAINTENANCE_TYPES";
export const APPLY_MAINTENANCE_TYPES_FILTERS =
  "APPLY_MAINTENANCE_TYPES_FILTERS";
export const RESET_MAINTENANCE_TYPES_FILTERS =
  "RESET_MAINTENANCE_TYPES_FILTERS";
export const SET_CURRENT_MAINTENANCE_TYPE = "SET_CURRENT_MAINTENANCE_TYPE";
export const RESET_CURRENT_MAINTENANCE_TYPE = "RESET_CURRENT_MAINTENANCE_TYPE";
export const ADD_MAINTENANCE_TYPE = "ADD_MAINTENANCE_TYPE";
export const EDIT_MAINTENANCE_TYPE = "EDIT_MAINTENANCE_TYPE";
