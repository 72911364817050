import {mapToBoolean} from "../../../utils/mappers";
import WorkOrderTypeChecklistItem from "./WorkOrderTypeChecklistItem";

class WorkOrderTypeState {
  constructor(
    id,
    name,
    type,
    destinyConfirmation,
    allowPermission,
    checklist,
    previous,
    confirmation,
    order,
    status,
    arrivalDestination,
    departureDestination
  ) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.destinyConfirmation = destinyConfirmation;
    this.allowPermission = allowPermission;
    this.checklist = checklist;
    this.previous = previous;
    this.confirmation = confirmation;
    this.order = order;
    this.status = status;
    this.arrivalDestination = arrivalDestination;
    this.departureDestination = departureDestination;
  }

  static map = state => {
    return new WorkOrderTypeState(
      state.nu_id_tipo_despacho_estado,
      state.vc_desc_tipo_despacho_estado,
      mapToBoolean(state.bi_finaliza_despacho) ? 2 : 1,
      mapToBoolean(state.bi_permite_confirmacion),
      mapToBoolean(state.bi_permite_albaran),
      JSON.parse(state.nu_id_futuros_estados).map(
        item => new WorkOrderTypeChecklistItem(item)
      ),
      state.nu_id_checklist_pre_estado || "",
      state.nu_id_checklist_post_estado || "",
      state.nu_id_orden,
      state.bi_estado,
      mapToBoolean(state.bi_llegada_despacho),
      mapToBoolean(state.bi_salida_despacho)
    );
  };
}

export default WorkOrderTypeState;
