import {
  APPLY_VEHICLE_MAINTENANCE_PLANS_FILTERS,
  CLOSE_VEHICLE_MAINTENANCE_PLANS_DIALOG,
  EDIT_VEHICLE_MAINTENANCE_PLAN,
  EDIT_VEHICLE_MAINTENANCE_PLAN_STATE,
  FETCH_ALL_VEHICLE_MAINTENANCE_PLANS,
  OPEN_VEHICLE_MAINTENANCE_PLANS_DIALOG,
  RESET_VEHICLE_MAINTENANCE_PLANS_FILTERS,
  RESET_VEHICLE_MAINTENANCE_PLANS_STATE,
  SET_CURRENT_VEHICLE_MAINTENANCE_PLAN
} from "../../types/FleetManagement/VehicleMaintenancePlansTypes";

const INIT_STATE = {
  data: {},
  filters: {
    vehicle: "",
    status: ""
  },
  current: null,
  dialog: {
    name: "",
    open: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_VEHICLE_MAINTENANCE_PLANS: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }

    case SET_CURRENT_VEHICLE_MAINTENANCE_PLAN: {
      return {
        ...state,
        current: action.payload
      };
    }

    case EDIT_VEHICLE_MAINTENANCE_PLAN: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }

    case EDIT_VEHICLE_MAINTENANCE_PLAN_STATE: {
      const id = action.payload.id;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            state: action.payload.state
          }
        }
      };
    }

    case APPLY_VEHICLE_MAINTENANCE_PLANS_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }

    case RESET_VEHICLE_MAINTENANCE_PLANS_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }

    case OPEN_VEHICLE_MAINTENANCE_PLANS_DIALOG: {
      return {
        ...state,
        dialog: {
          open: true,
          name: action.payload
        }
      };
    }
    case CLOSE_VEHICLE_MAINTENANCE_PLANS_DIALOG: {
      return {
        ...state,
        dialog: {
          open: false,
          name: ""
        }
      };
    }

    case RESET_VEHICLE_MAINTENANCE_PLANS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
