import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import moment from "moment";
import {editRefueling} from "../../../../../redux/actions/FleetManagement/Refueling";
import Form from "../AddRefuelingDialog/Form";
import FilesArea from "../../../Common/FilesArea";
import {Box} from "@material-ui/core";

const EditRefuelingDialog = ({open, setOpen}) => {
  const id = "edit-refueling-dialog";
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.refueling
  );
  const [ files, setFiles ] = React.useState(current.files || []);
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(editRefueling({id: current.id, ...data}, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.refueling.edit.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.refueling.edit.save"} />}
      cancelText={
        <IntlMessages id={"fleet.management.refueling.edit.cancel"} />
      }
    >
      <Form
        id={id}
        onSubmit={onSubmit}
        initialData={{
          plaque: current.plaque || "",
          date: moment(current.date).format("YYYY-MM-DD") || "",
          time: moment(current.date).format("HH:mm") || "",
          kilometer: current.kilometer || "",
          driver: current.driver || "",
          station: current.station || "",
          fuelType: current.fuelType || "",
          gallons: current.gallons || "",
          price: current.price || "",
          amount: current.amount || "",
          bill: current.bill || ""
        }}
      />
      <Box>
        <FilesArea
          id={"form-refueling-files"}
          files={files}
          setFiles={setFiles}
        />
      </Box>
    </FormDialog>
  );
};

export default EditRefuelingDialog;
