import WorkshopTask from "./WorkshopTask";

class Workshop {
    constructor(id, vehicle, kilometer, ot,
                entryDate, expectedExitDate, exitDate,
                state, workshop, amount, observations,
                tasks, files) {
        this.id = id;
        this.vehicle = vehicle;
        this.plaque = vehicle.description;
        this.workshop = workshop;
        this.kilometer = kilometer;
        this.ot = ot;
        this.entryDate = entryDate;
        this.state = state;
        this.exitDate = exitDate;
        this.expectedExitDate = expectedExitDate;
        this.amount = amount;
        this.observations = observations;
        this.tasks = tasks;
        this.files = files;
    }

    static map = (workshop) => {
        return new Workshop(
            workshop.nu_id_taller,
            {
                id: workshop.nu_id_vehiculo || 0,
                description:workshop.vc_placa,
            },
            workshop.nu_kilometraje,
            workshop.vc_orden_trabajo,
            workshop.dt_fec_entrada,
            workshop.dt_fec_prev_salida,
            workshop.dt_fec_real_salida,
            {
                id:workshop.nu_id_taller_estado|| 0,
                description: workshop.vc_desc_taller_proveedor,
            },
            {
                id: workshop.nu_id_taller_proveedor || 0,
                description: workshop.vc_desc_taller_proveedor
            },
            Number(workshop.nu_total_facturado),
            workshop.vc_observaciones,
            workshop?.trabajos?.map(t => WorkshopTask.map(t)) || [],
            workshop?.documentos?.map(f => ({
                name: f.vc_nombre_archivo,
            })) || []
        );
    }
}

export default Workshop;