import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import warehouseTransferSelect from "../domain/WarehouseTransferSelect";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevWarehouseTransferSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllWarehouseTransferSelect = async () => {
  try {
    const {data} = await axiosInstance.post(
      "/Inventario_Movimiento/traspaso_sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return data.map(transfer => warehouseTransferSelect.map(transfer));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const fetchWarehouseTransferDetailsById = async id => {
    try {
        const {data} = await axiosInstance.post(
            "/Inventario_Movimiento_Det/sel",
            {
                nu_id_movimiento: id
            },
            {
                cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
            }
        );
        return data.map(e => ({
            id: e.nu_id_mov_det,
            name: e.vc_desc_producto,
            stock: e.nu_cantidad,
            currentStock: e.nu_stock_actual,
            idProd: e.nu_id_producto
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}
