import React, {useEffect} from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import PendingStates from "../../../../../models/Guardian/PendingStates";
const PendingStatesSelect = ({value, onChange, style, fullWidth, data}) => {
  return (
    <Select
      id="pending"
      label={<IntlMessages id="guardian.advances.header.pending" />}
      value={value}
      onChange={e => onChange(e.target.value)}
      style={style}
      fullWidth={fullWidth}
    >
      <MenuItem value={PendingStates.ALL}>
        <IntlMessages id="guardian.advances.table.state.all" />
      </MenuItem>
      {data.map(state => (
        <MenuItem value={state.id} key={state.id}>
          {state.description}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PendingStatesSelect;
