export const FETCH_ALL_TAGS_BY_TYPE_STARTED = "FETCH_ALL_TAGS_BY_TYPE_STARTED";
export const FETCH_ALL_TAGS_BY_TYPE_SUCCESS = "FETCH_ALL_TAGS_BY_TYPE_SUCCESS";
export const FETCH_ALL_TAGS_BY_TYPE_FAILED = "FETCH_ALL_TAGS_FAILED";
export const FETCH_ALL_TAG_TYPES_STARTED = "FETCH_ALL_TAG_TYPES_STARTED";
export const FETCH_ALL_TAG_TYPES_SUCCESS = "FETCH_ALL_TAG_TYPES_SUCCESS";
export const FETCH_ALL_TAG_TYPES_FAILED = "FETCH_ALL_TAG_TYPES_FAILED";

export const CREATE_TAG_TYPE_STARTED = "CREATE_TAG_TYPE_STARTED";
export const CREATE_TAG_TYPE_SUCCESS = "CREATE_TAG_TYPE_SUCCESS";

export const EDIT_TAG_TYPE_STARTED = "EDIT_TAG_TYPE_STARTED";
export const EDIT_TAG_TYPE_SUCCESS = "EDIT_TAG_TYPE_SUCCESS";

export const DELETE_TAG_TYPE_STARTED = "DELETE_TAG_TYPE_STARTED";
export const DELETE_TAG_TYPE_SUCCESS = "DELETE_TAG_TYPE_SUCCESS";

export const CREATE_TAG_STARTED = "CREATE_TAG_STARTED";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";

export const EDIT_TAG_STARTED = "EDIT_TAG_STARTED";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";

export const DELETE_TAG_STARTED = "DELETE_TAG_STARTED";
export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";

export const SET_CURRENT_TAG_TYPE = "SET_CURRENT_TAG_TYPE";
export const RESET_CURRENT_TAG_TYPE = "RESET_CURRENT_TAG_TYPE";

export const ERROR_ON_TRANSACTION = "ERROR_ON_TRANSACTION";
export const RESET_TRANSACTION = "RESET_TRANSACTION";
