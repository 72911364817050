import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import DataCollectionHistory from "../domain/DataCollectionHistory";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

const dataCollectionHistory = [
  {
    nu_id_data_collection_history: 1,
    vc_desc_vehicle: "BCA_200",
    vc_desc_route: "S3",
    vc_desc_turn: "Recojo 1",
    vc_date_init: "22/11/21 12:45",
    vc_date_end: "22/11/21 15:30",
    vc_date_disinfection: "22/11/21 16:00",
    vc_date_first_pickup: "22/11/21 12:53",
    vc_date_last_pickup: "22/11/21 14:00",
    vc_desc_passengers: "15",
    bi_estado: "1"
  }
];

export const cancelPrevDataCollectionHistoryFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllDataCollectionHistory = async () => {
  //return dataCollectionHistory.map(dataCollectionHistory => DataCollectionHistory.map(dataCollectionHistory));
  try {
    const response = await axiosInstance.post(
      "/Ruta_Mina/sel_historico",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(data => DataCollectionHistory.map(data));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const changeDataCollection = async id => {
  return id;
};
