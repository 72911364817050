import {makeStyles} from "@material-ui/core/styles";
import {COLORS} from "./constants";

export const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular
  },
  driverItemContentBox: {
    marginBottom: "1em"
  },
  contentTitle: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightExtraBold,
    color: "#3f51b5"
  },
  drivingBox: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1em",
    gap: "1em"
  },
  drivingTitleBox: {
    display: "flex",
    color: "#3f51b5",
    flexDirection: "row",
    fontSize: theme.typography.pxToRem(14),
    gap: "1em"
  },
  drivingProgressBarBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  drivingProgressBarTitle: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightBold
  },
  drivingProgressBox: {
    width: "80%",
    display: "flex",
    flexDirection: "column"
  },
  drivingProgressContentText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightRegular
  },
  drivingProgressBar: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    textAlign: "center",
    color: "white"
  },
  drivingProgressBarOnlyUsed: {
    backgroundColor: COLORS[2].color,
    borderRadius: "16px",
    padding: "0.5em",
    lineHeight: "20px"
  },
  drivingProgressBarUsed: {
    backgroundColor: COLORS[2].color,
    borderTopLeftRadius: "16px",
    borderBottomLeftRadius: "16px",
    padding: "0.5em",
    lineHeight: "20px"
  },
  drivingProgressBarUnused: {
    backgroundColor: COLORS[1].color,
    borderTopRightRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "0.5em",
    lineHeight: "20px"
  },
  otherJobsBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1em"
  },
  otherJobsBar: {
    padding: "0.5em",
    borderRadius: "10px",
    width: "100%",
    backgroundColor: "rgba(91,141,184,.7)",
    textAlign: "center",
    color: "white",
    lineHeight: "20px",
    cursor: "pointer"
  },
  iconBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "4px"
  },
  tachographIcon: {
    height: "10px",
    width: "10px"
  },
  progressIcon: {
    height: theme.typography.pxToRem(15),
    width: theme.typography.pxToRem(15)
  },
  progressArrowsIcon: {
    height: theme.typography.pxToRem(15),
    width: theme.typography.pxToRem(15),
    transform: "rotate(90deg)"
  },
  disabledDriveContainer: {
    "&.Mui-disabled": {
      backgroundColor: "#ff5959",
    },
  },
  disabledDriverContent: {
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  lowercaseText: {
    textTransform: "lowercase"
  },
  nonActiveContainer: {
    backgroundColor: "#ff5959",
  }
}));
