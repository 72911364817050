
import axiosInstance, { CancelToken, isCancel } from '../utils/axiosInstance';
import Product from '../domain/Product';
import Response from '../domain/Response';
import { getCompanyEntityId } from './StorageService';

let fetchAllCancelToken = undefined;

export const cancelPrevProductFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllProducts = async ({filters, page, pageSize}) => {
    try{
      const {data} = await axiosInstance.post(
        '/Inventario_Producto/sel',
        {
            nu_id_entidad: getCompanyEntityId(),
            vc_desc_producto: filters?.description || null,
            bi_estado: typeof filters?.active === "boolean" ? filters?.active  : null,
            nu_pagina: page,
            nu_reg_pagina: pageSize,
        },
        {
          cancelToken: new CancelToken(c => (fetchAllCancelToken = c)), 
        },
      );
      return {
          products: data.map(product => Product.map(product)),
          rowCount: +data?.at(0)?.nu_cant_paginas || 0,
      };
    }catch (error){
      if(isCancel(error)){
        return [];
      }
      return Promise.reject(error);
    }

    //return productos.map(producto => Product.map(producto));
};

export const createProduct = async data => {  
    //return 1;
    try{
      const response = await axiosInstance.post(
        '/Inventario_Producto/ins',
        {
          nu_id_externo: data.codExterno,
          nu_id_entidad: getCompanyEntityId(),
          nu_id_tipo_producto: 0,
          vc_desc_producto: data.description,
          bi_envase: data.esEnvase,
          nu_id_unidad: Number(data.unidadType.value),
          nu_volumen_unidad: Number(data.volumenUnidad),
          vc_usuario: "administradorcajachica@4gflota.com",
        }
      );
      const responseObject = Response.map(response.data);
      if (responseObject.transactionState === '0'){
        throw new Error(responseObject.transactionMessage);
      }
      return { id: responseObject.transactionKey, message: responseObject.transactionMessage};
    }catch (error){
      return Promise.reject(error);
    }
};

export const updateProduct = async (id, data) => {
  try {
      //return id;
    const response = await axiosInstance.post(
      '/Inventario_Producto/upd',
      {
        nu_id_producto: id,
        nu_id_entidad: getCompanyEntityId(),
        nu_id_externo: data.codExterno,
        vc_desc_producto: data.description,
        bi_envase: data.esEnvase,
        nu_id_unidad: data.unidadType,
        nu_volumen_unidad: data.volumenUnidad,
        vc_usuario: "administradorcajachica@4gflota.com",
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === '0'){
      throw new Error(responseObject.transactionMessage);
    }
    return {id: responseObject.transactionKey, message: responseObject.transactionMessage};
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeProductState = async id => {
  try {
    //return id;
    const response = await axiosInstance.post(
      '/Inventario_Producto/del',
      {
        nu_id_producto: id,
        nu_id_entidad: getCompanyEntityId(),
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === '0'){
      throw new Error(responseObject.transactionMessage);
    }
    return {id: responseObject.transactionKey, message: responseObject.transactionMessage};
  } catch (error) {
    return Promise.reject(error);
  }
};