import Guardian from "domain/Guardian";
import Response from "domain/Response";
import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAll = async () => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Gestor_Caja/sel",
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(supervisor => Guardian.map(supervisor));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const changeState = async id => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Gestor_Caja/del",
      {
        nu_id_entidad_gestor_caja: id,
        nu_id_entidad: getCompanyEntityId()
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Gestor_Caja/upd",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_gestor_caja: id,
        ls_tag: data.tags.map(tag => ({nu_id_tag: tag.id})),
        vc_usuario: "USUARIO"
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const create = async data => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Gestor_Caja/ins",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_gestor_caja: data.supervisor.id,
        ls_tag: data.tags.map(tag => ({nu_id_tag: tag.id})),
        vc_usuario: "USUARIO"
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getGuardianById = async guardianId => {
  try {
    const {
      data
    } = await axiosInstance.post("/Caja_Chica_Entidad_Gestor_Caja/get", {
      nu_id_entidad_gestor_caja: guardianId
    });
    return Guardian.editMap(data);
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
