import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  tags: [
    {
      rule: v => v.length > 0,
      message: (
        <IntlMessages id="administrator.logistic.receivers.form.tags.error.empty" />
      )
    }
  ],
  code: [
    {
      rule: v => v !== "",
      message: (
        <IntlMessages id="administrator.logistic.receivers.form.code.error.empty" />
      )
    }
  ]
};
