export const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  // {
  //   languageId: 'chinese',
  //   locale: 'zh',
  //   name: 'Chinese',
  //   icon: 'cn',
  // },
  {
    languageId: "spanish",
    locale: "es",
    name: "Spanish",
    icon: "es"
  }
  // {
  //   languageId: 'french',
  //   locale: 'fr',
  //   name: 'French',
  //   icon: 'fr',
  // },
  // {
  //   languageId: 'italian',
  //   locale: 'it',
  //   name: 'Italian',
  //   icon: 'it',
  // },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  //   icon: 'sa',
  // },
];

export const flags = {
  en: "/images/flag/icons8-usa.png",
  // zh: '/images/flag/icons8-china.png',
  es: "/images/flag/icons8-spain.png"
  // fr: '/images/flag/icons8-france.png',
  // it: '/images/flag/icons8-italy.png',
  // ar: '/images/flag/icons8-saudi_arabia.png',
};
