import ui, {closeCreateForm} from "../Administrator/ui";
import {
  fetchAllSingingTypes,
  createNewSigningType,
  deleteSigningType,
  restoreSigningType,
  updateNewSigningType
} from "../../../services/RRHH/SigningTypesService";
import {
  FETCH_SINGINGTYPES_SUCCEEDED,
  APPLY_SINGINGTYPES_FILTERS,
  RESET_SINGINGTYPES_FILTERS,
  SINGINGTYPES_RESTORE,
  SINGINGTYPES_DELETE,
  SINGINGTYPES_EDIT
} from "../../types/RRHH/SigningType";

import SigningTypes from "../../../domain/RRHH/SigningTypes";
import {getCompanyEntityId} from "../../../services/StorageService";
import {Data} from "@react-google-maps/api";

const fetchSigningTypesSucceeded = data => ({
  type: FETCH_SINGINGTYPES_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchSingingTypes = () => async dispatch => {
  dispatch(ui.fetchStarted());
  // cancelPrevWarehouseFetchAll();
  try {
    const singingTypes = await fetchAllSingingTypes();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchSigningTypesSucceeded(singingTypes));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applySingingTypesFilters = filters => ({
  type: APPLY_SINGINGTYPES_FILTERS,
  payload: {
    filters: filters
  }
});

export const createSingingType = data => async dispatch => {
  dispatch(ui.closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const {nu_tran_pkey} = await createNewSigningType(data);
    dispatch({
      type: "SINGINGTYPES_CREATE",
      payload: {
        id: nu_tran_pkey,
        description: data.description,
        totalWorkDay: data.hours,
        marginWorkday: data.margin,
        active: true
      }
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};
export const deleteSingingType = data => async dispatch => {
  dispatch(ui.closeDeleteConfirmation());
  dispatch(ui.createStarted());
  try {
    const {transactionState, transactionMessage} = await deleteSigningType(
      data
    );
    if (transactionState === "-1") {
      dispatch(ui.setOnErrorMessage(transactionMessage));
    } else {
      dispatch({
        type: "SINGINGTYPES_DELETE",
        payload: {
          id: data
        }
      });
    }

    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};
export const restoreSingingType = id => async dispatch => {
  dispatch(ui.closeRestoreConfirmation());
  dispatch(ui.createStarted());
  try {
    await restoreSigningType(id);
    dispatch({
      type: "SINGINGTYPES_RESTORE",
      payload: {
        id: id
      }
    });
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
  }
};

export const editSingingType = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());

  try {
    const response = await updateNewSigningType(id, data);
    dispatch({
      type: "SINGINGTYPES_EDIT",
      payload: {
        id: id,
        data: {
          id: id,
          companyId: getCompanyEntityId(),
          description: data.description,
          totalWorkDay: data.hours,
          marginWorkday: data.margin,
          active: true
        }
      }
    });
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
  }
};

export default {
  get: fetchSingingTypes,
  delete: deleteSingingType,
  restore: restoreSingingType,
  edit: editSingingType,
  create: createSingingType,
  applySingingTypesFilters
  // resetWarehouseFilters,
  // resetState,
};
