import React from "react";
import PageContainer from "../../../../components/Administrator/Clients/PageContainer";
import ClientsTable from "../../../../components/Administrator/Clients/ClientsTable";
import DetailDialog from "../../../../components/Administrator/Clients/DetailDialog";
import AddClientDialog from "../../../../components/Administrator/Clients/AddClientDialog";
import useUiState from "../../../../hooks/Administrator/useUiState";
import {useDispatch} from "react-redux";
import FloatingButtonWithAdd from "../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {createClientStarted} from "../../../../redux/actions/Administrator/Clients";
import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";
import EditClientDialog from "../../../../components/Administrator/Clients/EditClientDialog";
import {restoreAdministratorState} from "../../../../redux/actions/Administrator/Common";

const Clients = () => {
  const ui = useUiState();
  const dispatch = useDispatch();
  const onCreate = data => dispatch(createClientStarted(data));
  React.useEffect(() => {
    return () => {
      dispatch(restoreAdministratorState());
    };
  }, []);
  return (
    <PageContainer>
      <ClientsTable />
      <DetailDialog />
      <AddClientDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditClientDialog {...ui.editFormProps} />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

export default Clients;
