import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.workOrders"} />,
    link: "/logistic/distributions/work-orders"
  },
  {
    label: <IntlMessages id={"sidebar.logistic.work.orders.types"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={
      <IntlMessages id={"logistic.distribution.work.orders.types.title"} />
    }
    description={
      <IntlMessages
        id={"logistic.distribution.work.orders.types.description"}
      />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);
export default PageContainer;
