import {mapToBoolean} from "../../../utils/mappers";

class DestinationType {
  constructor(id, name, status) {
    this.id = id;
    this.name = name;
    this.status = status;
  }

  static map = destination => {
    return new DestinationType(
      destination.nu_id_tipo_despacho,
      destination.vc_desc_tipo_despacho,
      mapToBoolean(destination.bi_estado)
    );
  };
}

export default DestinationType;
