class Route {
  constructor(id, description, routeDescription, average) {
    this.id = id;
    this.description = description;
    this.routeDescription = routeDescription;
    this.average = average;
    this.name = description;
  }

  static map = route => {
    return new Route(
      route.nu_id_ot,
      route.vc_desc_ot,
      route.vc_desc_ruta,
      route.nu_promedio
    );
  };
  static reportMap = route => {
    return new Route(route.nu_id_ruta, route.vc_desc_ruta, null, null);
  };
}

export default Route;
