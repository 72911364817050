import React from "react";

import useForm from "hooks/Common/useForm";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import TableComponent from "components/Common/Tables/Table";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import IncidenceTypeSelect from "containers/Administrator/IncidentTypeSelect";

import Box from "@material-ui/core/Box";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {fetchAllIncidenceTypeSelect} from "../../../../../services/IncidenceTypeSelectService";

const Form = ({id, initialData, onSubmit}) => {
  const {data: incidentTypes} = useFetch(fetchAllIncidenceTypeSelect, {}, []);

  const {formData, setFormData, handleSubmit} = useForm({
    transfer: {
      campusIdDestiny: initialData.idCampusDestiny,
      campusIdOrigin: initialData.idCampusOrigin,
      campusNameDestiny: initialData.campusNameDestiny,
      campusNameOrigin: initialData.campusNameOrigin,
      entityIdDestiny: initialData.idEntityDestiny,
      entityIdOrigin: initialData.idEntityOrigin,
      entityNameDestiny: initialData.entityNameDestiny,
      entityNameOrigin: initialData.entityNameOrigin,
      products: initialData.transfer,
      value: initialData.id,
      warehouseIdDestiny: initialData.idWarehouseDestiny,
      warehouseIdOrigin: initialData.idWarehouseOrigin,
      warehouseNameDestiny: initialData.warehouseNameDestiny,
      warehouseNameOrigin: initialData.warehouseNameOrigin
    },
    products: initialData.transfer.map(p => ({
      id: p.id,
      amount: "",
      incident: null
    }))
  });

  const onProductAmountChange = (id, amount) => {
    const newProducts = [ ...formData.products ];
    const index = newProducts.findIndex(p => p.id === id);
    newProducts[index] = {...newProducts[index], amount};
    setFormData("products", newProducts);
  };

  const onProductIncidentChange = (id, incident) => {
    const newProducts = [ ...formData.products ];
    const index = newProducts.findIndex(p => p.id === id);
    newProducts[index] = {...newProducts[index], incident};
    setFormData("products", newProducts);
  };

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={formData.transfer.entityNameOrigin}
            id="show-incident-origin-entity"
            label="Entidad origen"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={formData.transfer.campusNameOrigin}
            id="show-incident-origin-entity"
            label="Sede origen"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={formData.transfer.warehouseNameOrigin}
            id="show-incident-origin-entity"
            label="Almacén origen"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={formData.transfer.entityNameDestiny}
            id="show-incident-origin-entity"
            label="Entidad destino"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={formData.transfer.campusNameDestiny}
            id="show-incident-origin-entity"
            label="Sede destino"
            disabled
          />
        </Grid>
        <Grid item xs={4}>
          <OutlinedInput
            fullWidth
            value={formData.transfer.warehouseNameDestiny}
            id="show-incident-origin-entity"
            label="Almacén destino"
            disabled
          />
        </Grid>
      </GridContainer>

      <Box marginTop={4}>
        <TableComponent
          data={formData.transfer.products}
          head={
            <StyledTableRow>
              <StyledTableCell width="20%">Producto</StyledTableCell>
              <StyledTableCell width="15%">Stock</StyledTableCell>
              <StyledTableCell width="15%">Stock actual</StyledTableCell>
              <StyledTableCell width="15%">Cantidad</StyledTableCell>
              <StyledTableCell>Incidente</StyledTableCell>
            </StyledTableRow>
          }
          renderRow={({row}) => (
            <StyledTableRow>
              <StyledTableCell>{row.product}</StyledTableCell>
              <StyledTableCell>{row.amount}</StyledTableCell>
              <StyledTableCell>{row.currentStock}</StyledTableCell>
              <StyledTableCell>
                <OutlinedInput
                  fullWidth
                  type="number"
                  id={`create-incident-product-${row.id}-amount`}
                  value={formData.products.find(p => p.id === row.id).amount}
                  onChange={value => onProductAmountChange(row.id, value)}
                />
              </StyledTableCell>
              <StyledTableCell>
                <IncidenceTypeSelect
                  id={`create-incident-product-${row.id}-incident-type`}
                  label="Incidencia"
                  value={formData.products.find(p => p.id === row.id).incident}
                  onChange={value => onProductIncidentChange(row.id, value)}
                  data={incidentTypes}
                />
              </StyledTableCell>
            </StyledTableRow>
          )}
          getRowKey={row => row.id}
        />
      </Box>
    </form>
  );
};

export default Form;
