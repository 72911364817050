import moment from "moment/moment";
import {createSlice} from "@reduxjs/toolkit";
import {editAavvServicesHeaders, fetchAllAavvServices} from "../../thunks/Logistic/aavvServices";
// #878787 => pendiente
// #b0ae2c => en ruta
// #f5a623 => en camino
const today = moment(Date.now()).format("yyyy-MM-DD HH:mm:ss");

const INIT_STATE = {
    data: {},
    apiFilters: {
        date: today
    },
    filters: {
        hoppers: [],
        trailers: [],
        clients: [],
        states: [],
        saleOrganizing: [],
        drivers: [],
        serviceOrder: ""
    },
    tableHeaders: {},
    lastUpdate: Date.now(),
    dialogs: [],
    selectedService: null,
}


const aavvServicesSlice = createSlice({
    name: 'aavvServices',
    initialState: {
      ...INIT_STATE
    },
    reducers: {
        applyFilters: (state, action) => {
            state.filters = action.payload;
        },
        resetFilters: (state) => {
            state.filters = {
                ...INIT_STATE.filters
            };
        },
        resetApiFilters: (state) => {
            state.apiFilters = {
                ...INIT_STATE.apiFilters
            };
        },
        applyApiFilters: (state, action) => {
            state.apiFilters = action.payload;
        },
        setLastUpdate: (state) => {
            state.lastUpdate = Date.now();
        },
        resetState: (state) => {
            state.data = {...INIT_STATE.data};
            state.apiFilters = {...INIT_STATE.apiFilters};
            state.filters = {...INIT_STATE.filters};
            state.tableHeaders = {...INIT_STATE.tableHeaders};
            state.lastUpdate = Date.now();
        },
        openDialog: (state, action) => {
            state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setSelectedService: (state, action) => {
            state.selectedService = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllAavvServices.fulfilled,
            (state, action) => {
                const {services, headers} = action.payload;
                let list = {};
                services.forEach(u => {
                    list[u.id] = u;
                });
                state.data = {
                    ...list
                };
                list = {};
                headers.forEach((u, index) => {
                    list[index] = u;
                });
                state.tableHeaders = {
                    ...list
                };
                state.lastUpdate = Date.now();
            });
        builder.addCase(editAavvServicesHeaders.fulfilled,
            (state, action) => {
                const list = {};
                action.payload.forEach((u, index) => {
                    list[index] = u;
                });
                state.tableHeaders = {
                    ...list
                };
            });
    }
});


export default aavvServicesSlice.reducer;


export const {
    applyApiFilters,
    applyFilters,
    resetApiFilters,
    resetFilters,
    resetState,
    setLastUpdate,
    openDialog,
    closeDialog,
    setSelectedService
} = aavvServicesSlice.actions;
