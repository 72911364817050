import React from 'react';
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../../hooks/Common/useForm";

const INIT_DATA = {
    quantity: 1
}

const Form = ({id, onSubmit, initialQuantity = null}) => {
    const {formData, setFormData, handleSubmit} = useForm(INIT_DATA);
    React.useEffect(() => {
        setFormData('quantity', initialQuantity ?? 1);
    }, []);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <OutlinedInput
                fullWidth
                type="number"
                value={formData.quantity}
                onChange={value => setFormData('quantity', value)}
                inputProps={{min: 1}}
            />
        </form>
    );
};

export default Form;