import {makeStyles, alpha} from "@material-ui/core";

export default makeStyles(theme => ({
  tag: {
    marginTop: 3,
    height: 20,
    margin: "5px 0",
    padding: ".15em 4px",
    lineHeight: "15px",
    borderRadius: 2,
    textAlign: "left",
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.action.hover
  },
  button: {
    width: "100%",
    textAlign: "left",
    paddingBottom: 8,
    color: theme.palette.grey[600],
    fontWeight: 600,
    "&:hover,&:focus": {
      color: theme.palette.primary.main
    },
    "& span": {
      width: "100%"
    },
    "& svg": {
      width: 16,
      height: 16
    }
  },
  popper: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 3,
    width: 300,
    zIndex: 1300,
    fontSize: 13,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.background.paper
  },
  header: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: "8px 10px",
    fontWeight: 600
  },
  input: {
    padding: 10,
    width: "100%",
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    "& input": {
      borderRadius: 4,
      backgroundColor: theme.palette.background.paper,
      padding: 8,
      transition: theme.transitions.create([ "border-color", "box-shadow" ]),
      border: `1px solid ${theme.palette.grey[300]}`,
      fontSize: 14,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main
      }
    }
  },
  paper: {
    boxShadow: "none",
    margin: 0,
    color: theme.palette.text.primary,
    fontSize: 13
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    padding: 8,
    '&[aria-selected="true"]': {
      backgroundColor: "transparent"
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    }
  },
  popperDisablePortal: {
    position: "relative"
  },
  selected: {
    width: 17,
    height: 17,
    marginRight: 5,
    marginLeft: -2
  },
  close: {
    opacity: 0.6,
    width: 18,
    height: 18
  },
  optionWrapper: {
    flexGrow: 1,
    textAlign: "left"
  },
  error: {
    textAlign: "left",
    font: "12px normal",
    color: theme.palette.error.main,
    margin: "4px 14px 0px"
  },
  errorColor: {
    color: theme.palette.error.main
  }
}));
