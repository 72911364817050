import {combineReducers} from "redux";
import CurrentDay from "./CurrentDay";
import SigningType from "./SigningType";
import Employees from "./Employees";
import PausesTypes from "./PausesTypes";
import HistoricalSigning from "./HistoricalSigning";
import Documentation from "./Documentation";
import DocumentsTypes from "./DocumentsTypes";

export default combineReducers({
  currentDay: CurrentDay,
  signingType: SigningType,
  employees: Employees,
  historicalSigning: HistoricalSigning,
  pausesTypes: PausesTypes,
  documentation: Documentation,
  documentsTypes: DocumentsTypes
});
