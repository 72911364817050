import {mapToBoolean} from "../../../utils/mappers";

class WorkshopState {
    constructor(id, description, state) {
        this.id = id;
        this.description = description;
        this.state = state;
    }

    static map = status => {
        return new WorkshopState(
            status.nu_id_taller_estado,
            status.vc_desc_taller_estado,
            mapToBoolean(status.bi_estado),
        );
    }
}

export default WorkshopState;