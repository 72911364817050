import React, {useEffect} from 'react';
import Select from '../../../../Common/Forms/Select';
import IntlMessages from '../../../../../@jumbo/utils/IntlMessages';
import {Grid, MenuItem} from '@material-ui/core';

const AdvanceInput = ({
                        travel, setTravel,
                        errors, currentTravel, children = null,
                        advances = [], obligation=true,
                        disabled }) => {
  useEffect(() => {
    if (currentTravel && advances.length > 0) {
      setTravel(currentTravel);
    }
  }, [currentTravel, advances]);

  const validateError = () => {
    return errors.travel?.length > 0 && obligation;
  }
  return (
    <Grid item xs={12} sm={12}>
      <Select
        value={travel}
        onChange={e => setTravel(e.target.value)}
        id="travel"
        label={<IntlMessages id={'guardian.add.advance.form.advances'} />}
        fullWidth
        disabled={disabled}
        error={validateError()}
        errorMessage={errors.travel[0] || <IntlMessages id="guardian.add.expense.form.advance.error.empty" />}>
        <MenuItem value="">
          <IntlMessages id={'guardian.add.advance.form.travel.select.item'} />
        </MenuItem>
        {children}
        {advances.map(advance => (
          <MenuItem key={advance.id} value={advance.id}>
            {advance.route.description+'('+advance.amount+')'}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default AdvanceInput;
