import {
  ADD_INSURANCE,
  APPLY_INSURANCES_FILTERS,
  EDIT_INSURANCE,
  FETCH_ALL_INSURANCES,
  RESET_INSURANCES_FILTERS,
  RESET_INSURANCES_STATE,
  SET_CURRENT_INSURANCE
} from "../../types/FleetManagement/InsurancesTypes";
import moment from "moment";

const INIT_STATE = {
  data: [],
  filters: {
    plaque: "",
    policy: "",
    commencementDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    expirationDate: "",
    premium: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_INSURANCES_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_INSURANCES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case FETCH_ALL_INSURANCES: {
      return {
        ...state,
        data: action.payload
      };
    }
    case RESET_INSURANCES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    case ADD_INSURANCE: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: state.data.length + 1,
            ...action.payload
          }
        ]
      };
    }
    case EDIT_INSURANCE: {
      return {
        ...state,
        data: state.data.map(d => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        })
      };
    }
    case SET_CURRENT_INSURANCE: {
      return {
        ...state,
        current: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
