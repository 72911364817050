import React from "react";
import Select from "../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const HoursSelect = ({id, label, value, onChange, maxValue = 11, disabled=false}) => (
  <Select
    id={id}
    fullWidth
    label={label}
    value={value}
    onChange={e => onChange(e.target.value)}
    disabled={disabled}
  >
    {Array.from(Array(maxValue).keys()).map(h => (
      <MenuItem key={h} value={h}>
        {h}
      </MenuItem>
    ))}
  </Select>
);

export default HoursSelect;
