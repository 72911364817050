import React from 'react';
import Select from "../../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../../../hooks/Common/useFetch";
import {
    getAllWorkshopTaskTypes
} from "../../../../../../../../../services/FleetManagement/DailyOperation/WorkshopTaskService";

const TypeSelect = ({value, onChange, disabled}) => {
    const {data: types} = useFetch(getAllWorkshopTaskTypes, {}, []);

    return (
        <Select
            id='workshop-task-type-select'
            label={
                <IntlMessages id="fleet.management.workshop.request.form.tasks.form.type" />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
            disabled={disabled}
        >
            <MenuItem value={""}>
                <IntlMessages id="select.all" />
            </MenuItem>
            {types.map(t => (
                <MenuItem value={t.id} key={t.id}>
                    {t.description}
                </MenuItem>
            ))}
        </Select>
    );
};

TypeSelect.propTypes = {

};

export default TypeSelect;