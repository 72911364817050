import {mapToBoolean} from "../../../utils/mappers";

class WorkshopTaskState {
    constructor(id, description, status) {
        this.id = id;
        this.description = description;
        this.status = status;
    }

    static map = state => {
        return new WorkshopTaskState(
            state.nu_id_solicitud_taller_trabajo_estado,
            state.vc_desc_solicitud_taller_trabajo_estado,
            mapToBoolean(state.bi_estado),
        )
    }
}

export default WorkshopTaskState;