import React, {useEffect} from "react";
import {SpeedDial, SpeedDialIcon} from "@material-ui/lab";
import {StyledSpeedDialAction} from "../../../../routes/Pages/GuardiansPages/Operative/styles";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import AdjustIcon from "@material-ui/icons/Adjust";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {useHistory} from "react-router";
import FloatingButtonContainer from "../../../Common/Buttons/FloatingButtonContainer";
import {liquidateAdvance} from "../../../../services/AdvanceService";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchAllAdvancesByGuardianStarted,
  resetAdvancesListFilters
} from "../../../../redux/actions/Guardians/Advances";
import {clearAdvancesListFilter} from "../../../../redux/actions/Guardians/AdvancesListFilter";
import {setCurrentAdvance} from "../../../../redux/actions/Guardians/CurrentAdvance";

const OptionSpeedDial = ({
  classes,
  handleCloseDialog,
  setAdvanceLiquidateAlert,
  currentAdvance
}) => {
  const history = useHistory();
  const [ open, setOpen ] = React.useState(false);
  const [ actions, setActions ] = React.useState([]);
  const dispatch = useDispatch();
  const {filters} = useSelector(state => state.guardian.advancesList);
  useEffect(
    () => {
      if (
        currentAdvance.balance === 0 &&
        currentAdvance.expenses.filter(
          expense => expense.state.toLowerCase() === "pendiente"
        ).length < 1
      ) {
        setActions([
          {
            icon: <AlarmOnIcon />,
            name: <IntlMessages id={"yield.advance"} />,
            key: "yield.advance",
            onCLick: () => {
              liquidateAdvance(currentAdvance)
                .then(advanceId => {
                  handleCloseDialog();
                  setAdvanceLiquidateAlert(true);
                  dispatch(fetchAllAdvancesByGuardianStarted(filters));
                })
                .catch(e => handleCloseDialog());
            }
          }
        ]);
      } else {
        let options = [
          {
            icon: <AddCircleIcon />,
            name: <IntlMessages id={"sidebar.add.expenses"} />,
            key: "sidebar.add.expenses",
            onCLick: () =>
              history.push("/treasury/cash-management/add-expenses")
          }
        ];
        if (
          currentAdvance.expenses.filter(
            expense => expense.state.toLowerCase() === "pendiente"
          ).length < 1
        ) {
          options = [
            {
              icon: <AdjustIcon />,
              name: (
                <IntlMessages id={"guardian.advance.detail.adjust.button"} />
              ),
              key: "guardian.advance.detail.adjust.button",
              onCLick: () => {
                // handleCloseDialog();
                dispatch(setCurrentAdvance({...currentAdvance, adjust: true}))
                history.push("/treasury/cash-management/add-advances");
              }
            },
            ...options
          ];
        }
        setActions([ ...options ]);
      }
    },
    [ currentAdvance ]
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = onClick => {
    setOpen(false);
    onClick();
  };

  return (
    <FloatingButtonContainer>
      <SpeedDial
        ariaLabel="Actions"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(action => (
          <StyledSpeedDialAction
            key={action.key}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleClick(action.onCLick)}
          />
        ))}
      </SpeedDial>
    </FloatingButtonContainer>
  );
};

export default OptionSpeedDial;
