import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";
import {useDispatch} from "react-redux";
import {addWorkshopTask} from "../../../../../../../../redux/actions/FleetManagement/WorkshopRequests";

const TaskDialog = ({onClose, initialData, add = false, open, vehicle}) => {
    const id = 'task-dialog';
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        dispatch(addWorkshopTask(data));
        onClose();
    }
    return (
        <FormDialog
            id={id}
            title={<IntlMessages
                id={add ? "fleet.management.workshop.request.form.tasks.form.add.title" : "fleet.management.workshop.request.form.tasks.form.edit.title"}/>}
            onClose={onClose}
            isOpen={open}
        >
            {initialData && <Form id={id} initialData={initialData} onSubmit={onSubmit} vehicle={vehicle} add={add}/>}
        </FormDialog>
    );
};

TaskDialog.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    initialData: PropTypes.any.isRequired,
    add: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

export default TaskDialog;