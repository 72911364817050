import React from "react";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";

const TableRow = ({row}) => (
  <React.Fragment>
    <StyledTableCell>{row.names}</StyledTableCell>
    <StyledTableCell>
      {row.tags.map(tag => <div key={tag}>{tag}</div>)}
    </StyledTableCell>
  </React.Fragment>
);

export default TableRow;
