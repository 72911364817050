import React, {useState} from "react";
import {Document, Page} from "react-pdf/dist/esm/entry.webpack";
import Paginator from "./Paginator";
import {Grid} from "@material-ui/core";
import {useSelector} from "react-redux";

const Viewer = () => {
  const [ numPages, setNumPages ] = useState(null);
  const [ pageNumber, setPageNumber ] = useState(1);
  const {file: base64} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );

  const base64toUrl = data => {
    return "data:application/pdf;base64," + data;
  };
  const file = base64toUrl(base64);

  const onDocumentLoad = ({numPages}) => {
    setNumPages(numPages);
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="center"
      style={{
        border: "solid",
        borderColor: "black"
      }}
    >
      <Grid item xs={12}>
        <Paginator
          setPageNumber={setPageNumber}
          numPages={numPages}
          pageNumber={pageNumber}
          base64File={base64}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Document file={file} onLoadSuccess={onDocumentLoad}>
          <Page pageNumber={pageNumber} />
        </Document>
      </Grid>
    </Grid>
  );
};

export default Viewer;
