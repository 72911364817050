class DestinationState {
  constructor(
    id,
    externalId,
    typeId,
    typeDescription,
    arrival,
    exit,
    finish,
    status
  ) {
    this.id = id;
    this.externalId = externalId;
    this.typeId = typeId;
    this.typeDescription = typeDescription;
    this.arrival = arrival;
    this.exit = exit;
    this.finish = finish;
    this.status = status;
  }

  static map = state => {
    return new DestinationState(
      state.nu_id_tipo_despacho_estado,
      state.nu_id_externo,
      state.nu_id_tipo_despacho,
      state.vc_desc_tipo_despacho_estado,
      state.bi_llegada_despacho,
      state.bi_salida_despacho,
      state.bi_finaliza_despacho,
      state.bi_estado
    );
  };
}

export default DestinationState;
