import React from 'react';
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {getAllCostCenterForSelect} from "../../../../../services/Treasury/CostCenterTypeService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";

const CostCenterSelect = ({value, onChange, error, hasError, ...rest}) => {
    const {data: costCenters} = useFetch(getAllCostCenterForSelect, {}, []);
    const [costCenter, setCostCenter] = React.useState(null);
    const handleChange = (e, newValue) => {
        setCostCenter(newValue);
    }

    React.useEffect(() => {
        if (value && costCenters.length > 0) {
           setCostCenter(costCenters.find(costCenter => value === costCenter.id) ?? null);
        }
    }, [costCenters]);

    React.useEffect(() => {
        if(costCenters.length > 0) {
            onChange(costCenter?.id ?? '');
        }
    }, [costCenter]);

    return (
        <Autocomplete
            id="cost-center"
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={option => option.description || ""}
            value={costCenter}
            onChange={handleChange}
            options={costCenters ?? []}
            autoComplete
            renderInput={params => (
                <TextField
                    {...params}
                    label={<IntlMessages id="cost.center" />}
                    fullWidth
                    variant="outlined"
                    error={hasError}
                    helperText={error}
                />
            )}
            {...rest}
        />
    );
};

export default CostCenterSelect;