import React from "react";
import FiltersContainer from "../../../../../../../Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = ({onClose}) => {
  return (
    <FiltersContainer active marginBottom={5} onClose={onClose}>
      <Form />
    </FiltersContainer>
  );
};

export default Filters;
