import React, {forwardRef, useEffect} from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "components/Common/Tables/CrudTable/RestoreTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
//new table
import MaterialTable from "material-table";
//Icons
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Search from "@material-ui/icons/Search";
import Save from "@material-ui/icons/Save";

import {Button} from "@material-ui/core";
import {lighten} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1)
  }
}));

const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />)
};

const Table = ({data, vehicles, onEdit, onDelete, onRestore, isLoading}) => {
  const classes = useStyles();
  const {vehicle} = useSelector(state => state.administrator.vehicleSelect);
  const {route} = useSelector(state => state.administrator.routeSelect);
  const {turn} = useSelector(state => state.administrator.turnSelect);
  const {dataTable} = useSelector(state => state.administrator.dataCollection);
  //console.log ("****VEHICULOS PROP: ", vehicles);
  //console.log ("****Data: ", data);

  const [ vehicleOptions, setVehicleOptions ] = React.useState({});

  useEffect(
    () => {
      if (vehicle.length > 0) {
        vehicle.sort((a, b) => (a.vehicle > b.vehicle ? 1 : -1)).map(e => {
          const {id, name} = e;
          //setVehicleOptions({...vehicleOptions, id: vehicle})
          vehicleOptions[id] = name;
        });
      }
    },
    [ vehicle ]
  );

  const [ routeOptions, setRouteOptions ] = React.useState({});

  useEffect(
    () => {
      if (route.length > 0) {
        route.sort((a, b) => (a.route > b.route ? 1 : -1)).map(e => {
          const {id, route} = e;
          routeOptions[id] = route;
        });
      }
    },
    [ route ]
  );

  const [ turnOptions, setTurnOptions ] = React.useState({});

  useEffect(
    () => {
      if (turn.length > 0) {
        turn.map(e => {
          const {id, turn} = e;
          turnOptions[id] = turn;
        });
      }
    },
    [ turn ]
  );

  //console.log ("****Turno: ", turnOptions);

  const [ state, setState ] = React.useState({
    columns: [
      {
        title: "Vehículo",
        field: "idVehicel",
        //lookup: {1: 'BCA-200', 2: 'BCA-202', 3: 'BCS-214', 4: 'FVG-154'}
        lookup: vehicleOptions
        //defaultFilter: vehicleFilter,
      },
      {title: "Inicio", field: "start", filtering: false, editable: "never"},
      {title: "Fin", field: "end", filtering: false, editable: "never"},
      {
        title: "Ruta",
        field: "idRoute",
        //lookup: {1: 'S1', 2: 'S2', 3: 'S3', 4: 'S4', 5: 'S5'}
        lookup: routeOptions
      },
      {
        title: "Turno",
        field: "idTurn",
        //lookup: {1: 'Recojo 1', 2: 'Recojo 2', 3: 'Recojo 3', 4: 'Recojo 4', 5: 'Recojo 5'}
        lookup: turnOptions
      },
      {
        title: "Pasajeros",
        field: "passengers",
        type: "numeric",
        filtering: false
      },
      {title: "Primer pasajero", field: "firstPickUp", filtering: false},
      {title: "Último pasajero", field: "lastPickUp", filtering: false},
      {title: "Desinfección", field: "disinfection", filtering: false}
    ],
    /*data: data.map(e => ({
            id: e.id,
            vehicle: e.vehicle,
            start: e.start,
            end: e.end,
            route: e.route,
            turn: e.turn,
            passengers: e.passengers,
            firstPickUp: e.firstPickUp,
            lastPickUp: e.lastPickUp,
            disinfection: e.disinfection,
            active: e.active
        })),*/
    data: data
  });
  useEffect(
    () => {
      if (data.length > 0) {
        data.map(e => {
          if (e.start !== "")
            e.start = moment(e.start).format("DD/MM/YYYY HH:mm");
          if (e.end !== "") e.end = moment(e.end).format("DD/MM/YYYY HH:mm");
          if (e.firstPickUp !== "")
            e.firstPickUp = moment(e.firstPickUp).format("DD/MM/YYYY HH:mm");
          if (e.lastPickUp !== "")
            e.lastPickUp = moment(e.lastPickUp).format("DD/MM/YYYY HH:mm");
          if (e.disinfection !== "")
            e.disinfection = moment(e.disinfection).format("DD/MM/YYYY HH:mm");
        });
        setState({...state, data: data});
      }
      //else{
      //    setState({...state, data: data})
      //}
    },
    [ data ]
  );

  const today = new Date();
  //const [vehicleFilter, setVehicleFilter] = React.useState('');
  //const [routeFilter, setRouteFilter] = React.useState('');
  //const [turnFilter, setTurnFilter] = React.useState('');

  //let vehicleFilter = '';
  //let routeFilter = '';
  //let turnFilter = ''

  if (vehicleOptions !== {} && routeOptions !== {} && turnOptions !== {}) {
    return (
      <MaterialTable
        className={classes.root}
        icons={tableIcons}
        title=""
        columns={state.columns}
        data={state.data}
        /*
                onFilterChange={(filters) => {
                    //console.log('onFilterChange', filters);
                    filters.map(e => {
                        if(e.column.field === "idVehicel"){
                            //console.log("Vehiculo filtro: ", e.column.lookup[e.value[0]]);
                            vehicleFilter = e.column.lookup[e.value[0]];
                        } 
                    })
                }}
                */
        options={{
          paging: false,
          filtering: true,
          search: false,
          headerStyle: {backgroundColor: "#3c54b3", color: "#FFF"}
        }}
        components={{
          Toolbar: props => <div />
        }}
        editable={{
          onRowUpdate: (newRow, oldRow) =>
            new Promise(resolve => {
              setTimeout(() => {
                let firstTemp = null;
                let lastTemp = null;
                let descTemp = null;
                if (
                  newRow.firstPickUp !== "" &&
                  newRow.firstPickUp.split("/").length < 2
                ) {
                  const hora = newRow.firstPickUp.split(":");
                  //firstTemp = moment(new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()), Number(hora[0]), Number(hora[1]))).format("DD/MM/YY HH:mm");
                  firstTemp = new Date(
                    Date.UTC(
                      Number(today.getFullYear()),
                      Number(today.getMonth()),
                      Number(today.getDate()),
                      Number(hora[0]),
                      Number(hora[1])
                    )
                  );
                  //firstTemp = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate() + ' ' + hora[0] + ':' + hora[1];
                } else {
                  if (newRow.firstPickUp.split("/").length > 2) {
                    const partDate = newRow.firstPickUp.split("/");
                    const partYear = partDate[2].split(" ");
                    const partHora = partYear[1].split(":");
                    //firstTemp = newRow.firstPickUp
                    firstTemp = new Date(
                      Date.UTC(
                        Number(partYear[0]),
                        Number(partDate[1]) - 1,
                        Number(partDate[0]),
                        Number(partHora[0]),
                        Number(partHora[1])
                      )
                    );
                  }
                }
                if (
                  newRow.lastPickUp !== "" &&
                  newRow.lastPickUp.split("/").length < 2
                ) {
                  const hora = newRow.lastPickUp.split(":");
                  //lastTemp = moment(new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()), Number(hora[0]), Number(hora[1]))).format("DD/MM/YY HH:mm");
                  lastTemp = new Date(
                    Date.UTC(
                      Number(today.getFullYear()),
                      Number(today.getMonth()),
                      Number(today.getDate()),
                      Number(hora[0]),
                      Number(hora[1])
                    )
                  );
                } else {
                  if (newRow.lastPickUp.split("/").length > 2) {
                    const partDate = newRow.lastPickUp.split("/");
                    const partYear = partDate[2].split(" ");
                    const partHora = partYear[1].split(":");
                    //lastTemp = newRow.lastPickUp;
                    lastTemp = new Date(
                      Date.UTC(
                        Number(partYear[0]),
                        Number(partDate[1]) - 1,
                        Number(partDate[0]),
                        Number(partHora[0]),
                        Number(partHora[1])
                      )
                    );
                  }
                }
                if (
                  newRow.disinfection !== "" &&
                  newRow.disinfection.split("/").length < 2
                ) {
                  const hora = newRow.disinfection.split(":");
                  //descTemp = moment(new Date(Number(today.getFullYear()), Number(today.getMonth()), Number(today.getDate()), Number(hora[0]), Number(hora[1]))).format("DD/MM/YY HH:mm");
                  descTemp = new Date(
                    Date.UTC(
                      Number(today.getFullYear()),
                      Number(today.getMonth()),
                      Number(today.getDate()),
                      Number(hora[0]),
                      Number(hora[1])
                    )
                  );
                } else {
                  if (newRow.disinfection.split("/").length > 2) {
                    const partDate = newRow.disinfection.split("/");
                    const partYear = partDate[2].split(" ");
                    const partHora = partYear[1].split(":");
                    //descTemp = newRow.disinfection;
                    descTemp = new Date(
                      Date.UTC(
                        Number(partYear[0]),
                        Number(partDate[1]) - 1,
                        Number(partDate[0]),
                        Number(partHora[0]),
                        Number(partHora[1])
                      )
                    );
                  }
                }
                onEdit(
                  newRow.id,
                  newRow,
                  firstTemp,
                  lastTemp,
                  descTemp,
                  resolve
                );
                // const updatedData = [...state.data]
                // updatedData[oldRow.tableData.id] = newRow
                // setState({...state, data: updatedData});
              }, 500);
              //setTimeout(() => resolve(),500)
            })
          /*onRowUpdate: (newData, oldData) =>
                        new Promise(resolve => {
                            setTimeout(() => {
                                resolve();
                                if(oldData) {
                                    //console.log("OLD Data: ", oldData);
                                    console.log("NEW Data: ", newData);
                                    onEdit(newData.id, newData);
                                    setState(prevState => {
                                        const data = [...prevState.data];
                                        data[data.indexOf(oldData)] = newData;
                                        return {...prevState, data};
                                    });
                                }
                            },600)
                        })
                    */

          //onRowDelete: oldData =>
          //    new Promise(resolve => {
          //        setTimeout(() => {
          //            resolve();
          //            setState(prevState => {
          //                const data = [...prevState.data];
          //                data.splice(data.indexOf(oldData), 1);
          //                return {...prevState.data};
          //            });
          //        }, 600);
          //    })
        }}
        //actions={[
        /*
                    {
                        icon: () => <Button>Guardar todo</Button>,
                        tooltip: "Guardar todo",
                        //onClick: (e,data) => console.log("GUARDAR TODO: ", data),
                        onClick: () => console.log("GUARDAR TODO: ", state.data),
                        isFreeAction: true
                    },
                    /*
                    {
                        icon: () => <Save/>, //<button>Guardar</button>,
                        tooltip: "Guardar linea",
                        onClick: (e,data) => {
                            console.log("Linea: ",data);
                            onEdit(data.id, data);
                        }
                    }
                    */
        //]}
      />
    );
  }

  {
    /*const {tableData, onSort, order, orderBy} = useTableSort(data);

    const RenderRow = ({row}) =>
    row.active ? (
        <CrudTableRow onEdit={() => onEdit(row.id)} onDelete={() => onDelete(row.id)}>
            <StyledTableCell>{row.vehicle}</StyledTableCell>
            <StyledTableCell>{row.start}</StyledTableCell>
            <StyledTableCell>{row.end}</StyledTableCell>
            <StyledTableCell>{row.route}</StyledTableCell>
            <StyledTableCell>{row.turn}</StyledTableCell>
            <StyledTableCell>{row.passengers}</StyledTableCell>
        </CrudTableRow>
    ):(
        <RestoreTableRow onRestore={() => onRestore(row.id)}>
            <StyledTableCell>{row.vehicle}</StyledTableCell>
            <StyledTableCell>{row.start}</StyledTableCell>
            <StyledTableCell>{row.end}</StyledTableCell>
            <StyledTableCell>{row.route}</StyledTableCell>
            <StyledTableCell>{row.turn}</StyledTableCell>
            <StyledTableCell>{row.passengers}</StyledTableCell>
        </RestoreTableRow>
    );

    return(
        <TableComponent
            data={tableData}
            isLoading={isLoading}
            head={<TableHeader orderBy={orderBy} order={order} onSort={onSort}/>}
            renderRow={RenderRow}
            getRowKey={row => row.id}
        />
    );
};

Table.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            vehicle: PropTypes.string,
            start: PropTypes.string,
            end: PropTypes.string,
            route: PropTypes.string,
            turn: PropTypes.string,
            passengers: PropTypes.string,
            active: PropTypes.bool,
        }),
    ),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onRestore: PropTypes.func,
};

Table.defaultProps = {
    data: [],
    onEdit: () => {},
    onDelete: () => {},
    onRestore: () => {},
};*/
  }
};

export default Table;
