import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  plaque: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.reserves.form.field.error" />
    }
  ],
  driver: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.reserves.form.field.error" />
    }
  ],
  startDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.reserves.form.field.error" />
    }
  ],
  endDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.reserves.form.field.error" />
    }
  ],
  turn: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.reserves.form.field.error" />
    }
  ]
};
