import React from "react";
import PageContainer from "../../../../../components/Configuration/UserSettings/Modules/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {
  addConfigurationModule,
  resetConfigurationModulesState,
  resetCurrentConfigModule,
  updateConfigurationModuleState
} from "../../../../../redux/actions/Configuration/Modules";
import ModulesTable from "../../../../../components/Configuration/UserSettings/Modules/ModulesTable";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import ModuleDetailDialog from "../../../../../components/Configuration/UserSettings/Modules/ModuleDetailDialog";
import AddDialog from "../../../../../components/Configuration/UserSettings/Modules/AddDialog";

const Modules = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(({configuration}) => configuration.modules);
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  const onAddModuleHandler = data => {
    dispatch(addConfigurationModule(data));
    setOpenAdd(false);
  };
  React.useEffect(() => {
    return () => {
      dispatch(resetConfigurationModulesState());
    };
  }, []);
  return (
    <PageContainer>
      <ModulesTable
        openEdit={() => setOpenEdit(true)}
        openDelete={() => setOpenDelete(true)}
        openRestore={() => setOpenRestore(true)}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"configuration.user.settings.modules.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentConfigModule(null));
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(updateConfigurationModuleState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"configuration.user.settings.modules.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentConfigModule());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(updateConfigurationModuleState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      {current && <ModuleDetailDialog open={openEdit} setOpen={setOpenEdit} />}
      <AddDialog
        id={"add-module-form"}
        title={
          <IntlMessages id={"configuration.user.settings.modules.add.title"} />
        }
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        onAddHandler={onAddModuleHandler}
      />
    </PageContainer>
  );
};

export default Modules;
