import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import LinearProgressWithLabel from "../../../../Common/ProgressBar/LinearProgressWithLabel";
import {ChatButton, VerticalDivider} from "./styles";
import StateProgressBar from "./StateProgressBar";
import DeviceStateItem from "./DeviceStateItem";
import DestinationsTimeLine from "./DestinationsTimeLine";
import {useDispatch} from "react-redux";
import {setSelectedChatUser} from "../../../../../redux/actions/Logistic/Monitoring";

const WorkOrderItem = ({
  workOrder,
  expandAll = true,
  openChatDialog,
  setDevice,
  setSelectedDestination
}) => {
  const [ expanded, setExpanded ] = React.useState(expandAll);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      if (expandAll) {
        setExpanded(true);
      } else {
        setExpanded(false);
      }
    },
    [ expandAll ]
  );
  const handleOpenChatDialog = () => {
    dispatch(setSelectedChatUser({}));
    openChatDialog && openChatDialog();
  };
  return (
    <Box marginY="16px">
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id={`work-order-${workOrder.id}-header`}
        >
          <GridContainer
            spacing={4}
            direction="row"
            alignItems="center"
            justifyContent="space-around"
          >
            <Grid item xs={7} sm={3}>
              <Box display="flex" flexDirection="row">
                <FiberManualRecordIcon style={{marginRight: "8px"}} />
                <Box display="flex" flexDirection="column">
                  <Typography variant="h3">
                    {workOrder.name} - {workOrder.plaque}
                  </Typography>
                  <Typography variant="h5">
                    {workOrder.route} - {workOrder.alias}
                  </Typography>
                  <Typography variant="h5">{workOrder.driver.name}</Typography>
                </Box>
              </Box>
            </Grid>
            <VerticalDivider orientation="vertical" flexItem />
            <Grid item xs={2} sm={2}>
              <Box display="flex" flexDirection="column" textAlign="center">
                <Typography variant="h3">{workOrder.assigned}</Typography>
                <Typography variant="h5">
                  <IntlMessages id={"logistic.monitoring.item.assigned"} />
                </Typography>
              </Box>
            </Grid>
            <VerticalDivider orientation="vertical" flexItem />
            <Grid item xs={2} sm={2}>
              <Box display="flex" flexDirection="column" textAlign="center">
                <Typography variant="h3">{workOrder.negotiated}</Typography>
                <Typography variant="h5">
                  <IntlMessages id={"logistic.monitoring.item.negotiated"} />
                </Typography>
              </Box>
            </Grid>
            <VerticalDivider orientation="vertical" flexItem />
            <Grid item xs={12} sm={4}>
              <LinearProgressWithLabel
                text={<IntlMessages id={"logistic.monitoring.item.complete"} />}
                value={workOrder.progress}
              />
            </Grid>
          </GridContainer>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer spacing={6}>
            <Grid item xs={12} md={3}>
              <Box
                style={{marginLeft: "16px"}}
                display="flex"
                flexDirection="column"
              >
                <StateProgressBar
                  quantity={workOrder.delivered}
                  percentage={workOrder.delivered / workOrder.assigned * 100}
                  title={
                    <IntlMessages id={"logistic.monitoring.item.delivered"} />
                  }
                />
                <StateProgressBar
                  quantity={workOrder.partials}
                  percentage={workOrder.partials / workOrder.assigned * 100}
                  title={
                    <IntlMessages id={"logistic.monitoring.item.partials"} />
                  }
                />
                <StateProgressBar
                  quantity={workOrder.notDelivered}
                  percentage={workOrder.notDelivered / workOrder.assigned * 100}
                  title={
                    <IntlMessages
                      id={"logistic.monitoring.item.not.delivered"}
                    />
                  }
                />
                {/*<Box display="flex" width="50%">*/}
                {/*    <ChatButton*/}
                {/*        onClick={() => handleOpenChatDialog()}*/}
                {/*    >*/}
                {/*        <ChatBubbleIcon style={{color: "#00c8ca"}}/>*/}
                {/*    </ChatButton>*/}
                {/*</Box>*/}
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Box display="flex" flexDirection="column">
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <DeviceStateItem
                    setDevice={setDevice}
                    name={<IntlMessages id={"logistic.monitoring.item.gps"} />}
                    device={workOrder.gps}
                  />
                  <DeviceStateItem
                    setDevice={setDevice}
                    name={
                      <IntlMessages id={"logistic.monitoring.item.signal"} />
                    }
                    device={workOrder.signal}
                  />
                  <DeviceStateItem
                    setDevice={setDevice}
                    name={
                      <IntlMessages id={"logistic.monitoring.item.battery"} />
                    }
                    device={workOrder.battery}
                  />
                  <DeviceStateItem
                    setDevice={setDevice}
                    name={<IntlMessages id={"logistic.monitoring.item.disc"} />}
                    device={workOrder.disc}
                  />
                </Box>
              </Box>
              <Paper
                style={{
                  maxWidth: "100%",
                  overflowX: "auto",
                  overflowY: "hidden"
                }}
              >
                <DestinationsTimeLine
                  workOrderId={workOrder.id}
                  setSelectedDestination={setSelectedDestination}
                  destinations={workOrder.destinations}
                />
              </Paper>
            </Grid>
          </GridContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default WorkOrderItem;
