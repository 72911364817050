import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.identification"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.active"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.purchase.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.brand"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.measures"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.retreading"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.pressure"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.tires.table.vehicle"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
