import React from 'react';

const AvailabilityIcon = props => (
    <img
        {...props}
        src={
            process.env.PUBLIC_URL +
            "/images/icons/tachograph-icons/Iconos_Taco_disponibilidad.svg"
        }
        alt=""
    />
);

export default AvailabilityIcon;