import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER,
  UPDATE_SELECTED_COMPANY
} from "../../@jumbo/constants/ActionTypes";
import {FETCH_USER_PERMISSIONS} from "../types/AuthTypes";
import {fetchError, fetchStart, fetchSuccess} from "./Common";
import {
  getUserPermissions,
  setUserPermissions,
  setUserType
} from "../../services/StorageService";
import {
  getUserPermissionsByUserIdAndCompany,
  getUserTypeByUserId
} from "../../services/UserEntityService";

export const setAuthUser = user => {
  return dispatch => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user
    });
  };
};

export const updateLoadUser = loading => {
  return dispatch => {
    dispatch({
      type: UPDATE_LOAD_USER,
      payload: loading
    });
  };
};

export const setForgetPassMailSent = status => {
  return dispatch => {
    dispatch({
      type: SEND_FORGET_PASSWORD_EMAIL,
      payload: status
    });
  };
};

export const updateSelectedCompany = company => ({
  type: UPDATE_SELECTED_COMPANY,
  payload: company
});

export const fetchUserPermissions = permissions => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: FETCH_USER_PERMISSIONS, payload: permissions});
    }, 1000);
  };
};
export const setPermissions = permissions => ({
  type: FETCH_USER_PERMISSIONS,
  payload: permissions
});

export const loadUserPermissions = () => {
  return dispatch => {
    dispatch({type: FETCH_USER_PERMISSIONS, payload: getUserPermissions()});
  };
};

export const updateUserTypeAndPermissions = history => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const type = await getUserTypeByUserId();
      setUserType(type);
      const permissions = await getUserPermissionsByUserIdAndCompany();
      setUserPermissions(permissions);
      dispatch(loadUserPermissions(permissions));
      dispatch(fetchSuccess());
      history && history.go(0);
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
