import {
  CLEAR_CURRENT_ACCOUNTING,
  SET_CURRENT_ACCOUNTING
} from "../../types/CustomTypes";
import {
  FETCH_ALL_EXPENSES_BY_ACCOUNTING_STARTED,
  FETCH_ALL_EXPENSES_BY_ACCOUNTING_SUCCESS
} from "../../types/Guardian/AccountingTypes";
import {fetchAllExpensesByAccounting} from "../../../services/ExpenseService";

export const setCurrentAccounting = accounting => {
  return dispatch => {
    dispatch({
      type: SET_CURRENT_ACCOUNTING,
      payload: accounting
    });
    dispatch(fetchAllExpensesByAccountingStarted(accounting));
  };
};

export const clearCurrentAccounting = () => {
  return {
    type: CLEAR_CURRENT_ACCOUNTING
  };
};

export const fetchAllExpensesByAccountingSuccess = expenses => {
  return {
    type: FETCH_ALL_EXPENSES_BY_ACCOUNTING_SUCCESS,
    payload: expenses
  };
};

export const fetchAllExpensesByAccountingStarted = accounting => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_EXPENSES_BY_ACCOUNTING_STARTED});
    fetchAllExpensesByAccounting(accounting).then(expenses => {
      dispatch(fetchAllExpensesByAccountingSuccess(expenses));
    });
  };
};
