import {fetchStart, fetchSuccess} from "../Common";
import {
  ADD_RESERVE,
  APPLY_RESERVES_FILTERS,
  EDIT_RESERVE,
  FETCH_ALL_RESERVES,
  RESET_RESERVES_FILTERS,
  RESET_RESERVES_STATE,
  SET_CURRENT_RESERVE
} from "../../types/FleetManagement/ReservesTypes";
import {parseDateFromInput} from "../../../utils/dates";

const data = [
  {
    id: 1,
    plaque: "ABC-123",
    brand: "Brand 1",
    model: "Model 1",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    driver: "Pedro",
    turn: "Turn 1",
    state: true
  },
  {
    id: 2,
    plaque: "XYZ-123",
    brand: "Brand 2",
    model: "Model 2",
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    driver: "Pedro",
    turn: "Turn 2",
    state: false
  }
];

export const resetReservesState = () => ({type: RESET_RESERVES_STATE});
export const applyReservesFilters = filters => ({
  type: APPLY_RESERVES_FILTERS,
  payload: filters
});
export const resetReservesFilters = () => ({type: RESET_RESERVES_FILTERS});
export const setCurrentReserve = reserve => ({
  type: SET_CURRENT_RESERVE,
  payload: reserve
});
export const fetchALlReserves = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({type: FETCH_ALL_RESERVES, payload: data});
    }, 1500);
  };
};
export const addReserve = (reserve, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const newReserve = {
        ...reserve,
        startDate: reserve.startDate
          ? parseDateFromInput(reserve.startDate)
          : "",
        endDate: reserve.endDate ? parseDateFromInput(reserve.endDate) : "",
        state: true
      };
      dispatch({type: ADD_RESERVE, payload: newReserve});
      onClose && onClose();
    }, 1500);
  };
};

export const editReserve = (reserve, onClose) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const editReserve = {
        ...reserve,
        startDate: reserve.startDate
          ? parseDateFromInput(reserve.startDate)
          : "",
        endDate: reserve.endDate ? parseDateFromInput(reserve.endDate) : "",
        state: true
      };
      dispatch({type: EDIT_RESERVE, payload: editReserve});
      onClose && onClose();
    }, 1500);
  };
};
