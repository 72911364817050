const sortDesc = (a, b, orderBy) => {
  if (a[orderBy] > b[orderBy]) {
    return -1;
  }
  if (a[orderBy] < b[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) =>
  order === "desc"
    ? (a, b) => sortDesc(a, b, orderBy)
    : (a, b) => -sortDesc(a, b, orderBy);

export default getComparator;
