import React from 'react';
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => {
    return (
        <StyledTableRow>
            {/*<StyledTableCell>*/}
            {/*    <IntlMessages id="logistic.work.orders.dialog.destination.delivery.notes.table.id" />*/}
            {/*</StyledTableCell>*/}
            <StyledTableCell>
                <IntlMessages id="logistic.work.orders.dialog.destination.delivery.notes.table.download" />
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default TableHeader;