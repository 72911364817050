import React, {useEffect} from "react";
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "../../../../Common/Forms/Select/ActiveSelect";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const initData = {
  active: undefined,
  names: "",
  tag: "",
  code: ""
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initData);
  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );
  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <ActiveSelect
            id="receivers-state-filter"
            value={formData.active}
            onChange={value => setFormData("active", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.names}
            id="receivers-names-filter"
            label={
              <IntlMessages id="administrator.logistic.receivers.filters.names" />
            }
            onChange={value => setFormData("names", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.tag}
            id="receivers-tag-filter"
            label={
              <IntlMessages id="administrator.logistic.receivers.filters.tags" />
            }
            onChange={value => setFormData("tag", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.code}
            id="receivers-code-filter"
            label={
              <IntlMessages id="administrator.logistic.receivers.filters.code" />
            }
            onChange={value => setFormData("code", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
