import React from "react";
import BaseCard from "../BaseCard";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import DatesBox from "./DatesBox";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../../../Common/Forms/OutlinedDatePicker";
import CurrencySelect from "./CurrencySelect";
import ContractTypeSelect from "./ContractTypeSelect";
import {calculateDaysOfDifferenceBetweenDates} from "../../../../../../../utils/dates";
import {useDispatch, useSelector} from "react-redux";
import {editElevatorContractInfo} from "../../../../../../../redux/actions/ElevatorsManagement/Elevators";

const ContractInformation = ({initialData}) => {
  const id = "contact-info-form";
  const {current, filters} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const dispatch = useDispatch();
  const {formData, setFormData, resetForm, handleSubmit} = useForm(initialData);
  const [ edit, setEdit ] = React.useState(false);
  const onSave = data => {
    setEdit(false);
    dispatch(editElevatorContractInfo(current, data, filters));
  };
  const onCancel = () => {
    resetForm();
    setEdit(false);
  };
  return (
    <BaseCard
      onSubmit={handleSubmit(onSave)}
      title={
        <IntlMessages id="elevators.management.elevators.index.card.detail.dialog.form.contract" />
      }
      onCancel={onCancel}
      edit={edit}
      setEdit={setEdit}
    >
      <form id={id}>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <ContractTypeSelect
              id="contract-type"
              value={formData.type}
              disabled={!edit}
              setValue={value => setFormData("type", value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id="contract-price"
              value={formData.price}
              label={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.contract.price"
                  }
                />
              }
              fullWidth
              onChange={value => setFormData("price", value)}
              disabled={!edit}
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <CurrencySelect
              id="contract-currency"
              value={formData.currency}
              disabled={!edit}
              setValue={value => setFormData("currency", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <DatesBox
              title={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.contract.free.maintenance"
                  }
                />
              }
            >
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedDatePicker
                  id="contract-free-maintenance-start-date"
                  value={formData.freeMaintenanceStartDate}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.start.date"
                      }
                    />
                  }
                  fullWidth
                  onChange={value =>
                    setFormData("freeMaintenanceStartDate", value)}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedDatePicker
                  id="contract-free-maintenance-end-date"
                  value={formData.freeMaintenanceEndDate}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.end.date"
                      }
                    />
                  }
                  fullWidth
                  onChange={value =>
                    setFormData("freeMaintenanceEndDate", value)}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedInput
                  id="contract-free-maintenance-duration"
                  value={calculateDaysOfDifferenceBetweenDates(
                    formData.freeMaintenanceStartDate,
                    formData.freeMaintenanceEndDate
                  )}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.duration"
                      }
                    />
                  }
                  fullWidth
                  disabled
                  type="number"
                />
              </Grid>
            </DatesBox>
          </Grid>
          <Grid item xs={12}>
            <DatesBox
              title={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.contract.warranty"
                  }
                />
              }
            >
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedDatePicker
                  id="contract-warranty-start-date"
                  value={formData.warrantyStartDate}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.start.date"
                      }
                    />
                  }
                  fullWidth
                  onChange={value => setFormData("warrantyStartDate", value)}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedDatePicker
                  id="contract-warranty-end-date"
                  value={formData.warrantyEndDate}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.end.date"
                      }
                    />
                  }
                  fullWidth
                  onChange={value => setFormData("warrantyEndDate", value)}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedInput
                  id="contract-warranty-duration"
                  value={calculateDaysOfDifferenceBetweenDates(
                    formData.warrantyStartDate,
                    formData.warrantyEndDate
                  )}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.duration"
                      }
                    />
                  }
                  fullWidth
                  disabled
                  type="number"
                />
              </Grid>
            </DatesBox>
          </Grid>
          <Grid item xs={12}>
            <DatesBox
              title={
                <IntlMessages
                  id={
                    "elevators.management.elevators.index.card.detail.dialog.form.contract.contract"
                  }
                />
              }
            >
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedDatePicker
                  id="contract-start-date"
                  value={formData.startDate}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.start.date"
                      }
                    />
                  }
                  fullWidth
                  onChange={value => setFormData("startDate", value)}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedDatePicker
                  id="contract-end-date"
                  value={formData.endDate}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.end.date"
                      }
                    />
                  }
                  fullWidth
                  onChange={value => setFormData("endDate", value)}
                  disabled={!edit}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <OutlinedInput
                  id="contract-duration"
                  value={calculateDaysOfDifferenceBetweenDates(
                    formData.startDate,
                    formData.endDate
                  )}
                  label={
                    <IntlMessages
                      id={
                        "elevators.management.elevators.index.card.detail.dialog.form.contract.duration"
                      }
                    />
                  }
                  fullWidth
                  disabled
                  type="number"
                />
              </Grid>
            </DatesBox>
          </Grid>
        </GridContainer>
      </form>
    </BaseCard>
  );
};

export default ContractInformation;
