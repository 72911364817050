import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllSinisterStates} from "../../../../../../../services/FleetManagement/Vehicles/SinisterService";

const StateSelect = ({id, label, value, setValue}) => {
  const {data: states} = useFetch(getAllSinisterStates, {}, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {states.map(e => (
        <MenuItem key={`state-${e.id}`} value={e.id}>
          {e.description}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StateSelect;
