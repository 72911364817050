import {
  ADD_ADVANCE_STARTED,
  ADD_ADVANCE_SUCCESSFUL,
  RESET_ADD_ADVANCE_FORM
} from "../../types/CustomTypes";
import {ADD_ADVANCE_ERROR} from "../../types/Guardian/AdvancesCustomTypes";

const INIT_STATE = {
  formData: {
    box: "",
    travel: "",
    driver: "",
    route: "",
    amount: "",
    advanceType: "",
    observations: ""
  },
  isLoading: false,
  errorMessage: "",
  saveSucceeded: undefined,
  fetchSucceeded: undefined
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ADVANCE_STARTED: {
      return {
        ...state,
        formData: action.payload,
        isLoading: true
      };
    }
    case ADD_ADVANCE_SUCCESSFUL: {
      return {
        ...state,
        isLoading: false,
        saveSucceeded: true,
        formData: {...INIT_STATE.formData}
      };
    }
    case RESET_ADD_ADVANCE_FORM: {
      return {
        ...state,
        formData: {...INIT_STATE.formData}
      };
    }
    case ADD_ADVANCE_ERROR: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.message
      };
    }
    default:
      return state;
  }
};
