import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import useForm from 'hooks/Common/useForm';


const Form = ({ id, initialData, onSubmit }) => {
  const { formData, setFormData, errors, handleSubmit } = useForm(
    {
      description: initialData?.stateType || '',
    },
  );
  
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <OutlinedInput
            value={formData.description}
            hasError={errors.description.length > 0}
            error={errors.description[0]}
            fullWidth
            id="description"
            label={<IntlMessages id="rrhh.page.pauseTypes.add.dialog.inputField.placeholder" />}
            onChange={value => setFormData('description', value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
