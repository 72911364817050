import React from 'react';
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const VisualizationTypeSelect = ({value, onChange}) => {
    return (
        <Select
            label={<IntlMessages id='visualization'/>}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
        >
            <MenuItem key='1' value={1}>
                <IntlMessages id='standard' />
            </MenuItem>
            <MenuItem key='2' value={2}>
                <IntlMessages id='tabulated' />
            </MenuItem>
        </Select>
    );
};

export default VisualizationTypeSelect;