import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import ModuleSubmodulesList from "./ModuleSubmoduleList";

const EditPermissionsForm = ({id, initialData, onEditRole, modules}) => {
  const {formData, setFormData, handleSubmit} = useForm(initialData);
  const handleCheck = (check, operation) => {
    setFormData(`${operation.id}`, {
      ...operation,
      state: check
    });
  };
  return (
    <form id={id} onSubmit={handleSubmit(onEditRole)}>
      {Object.values(modules).map(module => (
        <ModuleSubmodulesList
          key={`module-${module.id}`}
          module={module}
          formData={formData}
          onChange={(value, operation) => handleCheck(value, operation)}
        />
      ))}
    </form>
  );
};

export default EditPermissionsForm;
