import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import Filters from "./Filters";
import TableHead from "./TableHead";
import {Box, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PaginationFooter from "../../../../Common/Tables/PaginationFooter";
import {useDispatch, useSelector} from "react-redux";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {
  fetchAllInsurances,
  setCurrentInsurance
} from "../../../../../redux/actions/FleetManagement/Insurances";
import moment from "moment";
import {parseNumberToMoney} from "../../../../../utils/parsers";

const InsurancesTable = ({openEditDialog}) => {
  const dispatch = useDispatch();
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.insurances
  );
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);
  React.useEffect(
    () => {
      dispatch(fetchAllInsurances(filters));
    },
    [ filters ]
  );
  return (
    <Box display="flex" flexDirection="column">
      <Filters />
      <TableContainer
        head={<TableHead />}
        footer={
          <PaginationFooter
            data={data}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      >
        {(rowsPerPage > 0
          ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : data).map(insurance => (
          <StyledTableRow key={insurance.id}>
            <StyledTableCell>{insurance.plaque}</StyledTableCell>
            <StyledTableCell>{insurance.policy}</StyledTableCell>
            <StyledTableCell>
              {moment(insurance.commencementDate).format("DD/MM/YYYY")}
            </StyledTableCell>
            <StyledTableCell>
              {moment(insurance.expirationDate).format("DD/MM/YYYY")}
            </StyledTableCell>
            <StyledTableCell>
              {parseNumberToMoney(insurance.premium)}
            </StyledTableCell>
            <StyledTableCell>
              <Box display="flex" flexDirection="row">
                <Box>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      dispatch(setCurrentInsurance(insurance));
                      openEditDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Box>
              </Box>
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableContainer>
    </Box>
  );
};

export default InsurancesTable;
