import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import FrequencySelect from "../../../../Common/Forms/Select/FrequencySelect";
import WeeklyDaySelect from "../../../../Common/Forms/Select/WeeklyDaySelect";
import MonthlyDaySelect from "../../../../Common/Forms/Select/MonthlyDaySelect";
import FormatSelect from "./FormatSelect";
import validations from "./validations";
import {closeKpiDialog, createScheduledReport} from "../../../../../redux/actions/ControlPanel/Kpi";
import {useDispatch, useSelector} from "react-redux";
import Select from "../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {dialogs} from "../constants";
import {fetchError} from "../../../../../redux/actions";

const initData = {
    name: "",
    frequency: 1,
    day: 1,
    time: 0,
    email: "",
    ccEmail: "",
    format: 1
}

const AddScheduleDialog = () => {
    const id = 'kpi-add-favorite';
    const {current, dialogs: dialogsList} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    const {formData, errors, setFormData, handleSubmit} = useForm(initData, validations);
    const open = dialogsList.some(d => d === dialogs.scheduleReport);

    const renderDay = () => {
        if(formData.frequency === 2){
            return (
                <Grid item xs={12} md={6}>
                    <WeeklyDaySelect
                        id={'schedule-weekly-day'}
                        fullWidth
                        value={formData.day}
                        onChange={value => setFormData('day', value)}
                        hasError={errors?.day?.length > 0}
                        error={errors?.day[0]}
                    />
                </Grid>
            );
        }else if(formData.frequency === 3) {
            return (
                <Grid item xs={12} md={6}>
                    <MonthlyDaySelect
                        id={'schedule-monthly-day'}
                        fullWidth
                        value={formData.day}
                        onChange={value => setFormData('day', value)}
                        hasError={errors?.day?.length > 0}
                        error={errors?.day[0]}
                    />
                </Grid>
            )
        }else {
            return (
                <></>
            )
        }
    }

    const onCloseDialog = () => {
        dispatch(closeKpiDialog(dialogs.scheduleReport));
    }

    const onSaveScheduled = data => {
        if(Object.values(current.filters).length !== 0) {
            dispatch(createScheduledReport(current.type, current.filters, data));
        }else {
            dispatch(fetchError('Es necesario guardar algún filtro'));
        }
        onCloseDialog();
    }

    React.useEffect(() => {
        setFormData('day', 1);
    }, [formData.frequency])

    return (
        <FormDialog
            id={id}
            open={open}
            title={<IntlMessages id={"control.panel.kpi.add.schedule"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
            submitText={<IntlMessages id={"label.form.save"}/>}
            onClose={onCloseDialog}
        >
            {open
                &&
                <form id={id} onSubmit={handleSubmit(onSaveScheduled)}>
                    <GridContainer spacing={2}>
                        <Grid item xs={12} md={6}>
                            <OutlinedInput
                                id={'schedule-name'}
                                fullWidth
                                value={formData.name}
                                onChange={value => setFormData('name', value)}
                                label={<IntlMessages id={ "control.panel.kpi.add.schedule.name"}/>}
                                hasError={errors?.name?.length > 0}
                                error={errors?.name[0]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FrequencySelect
                                id={'schedule-frequency'}
                                fullWidth
                                value={formData.frequency}
                                onChange={value => setFormData('frequency', value)}
                                hasError={errors?.frequency?.length > 0}
                                error={errors?.frequency[0]}
                            />
                        </Grid>
                        {renderDay()}
                        <Grid item xs={12} md={6}>
                            <Select
                                id={'schedule-time'}
                                fullWidth
                                value={formData.time}
                                onChange={(e) => setFormData('time', e.target.value)}
                                label={<IntlMessages id={ "control.panel.kpi.add.schedule.time"}/>}
                                error={errors?.time?.length > 0}
                                errorMessage={errors?.time[0]}
                            >
                                {Array.from(Array(24).keys()).map(n => (
                                    <MenuItem key={n} value={n}>
                                        {n}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <OutlinedInput
                                id={'schedule-email'}
                                fullWidth
                                type="email"
                                value={formData.email}
                                onChange={value => setFormData('email', value)}
                                label={<IntlMessages id={ "control.panel.kpi.add.schedule.email"}/>}
                                hasError={errors?.email?.length > 0}
                                error={errors?.email[0]}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <OutlinedInput
                                id={'schedule-cc-email'}
                                fullWidth
                                value={formData.ccEmail}
                                onChange={value => setFormData('ccEmail', value)}
                                label={<IntlMessages id={ "control.panel.kpi.add.schedule.cc.email"}/>}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormatSelect
                                id={'schedule-format'}
                                fullWidth
                                value={formData.format}
                                onChange={value => setFormData('format', value)}
                                hasError={errors?.format?.length > 0}
                                error={errors?.format[0]}
                            />
                        </Grid>
                    </GridContainer>
                </form>
            }
        </FormDialog>
    );
}

export default AddScheduleDialog;