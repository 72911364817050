class FleetManagementFile {
  constructor(
    id,
    entityId,
    name,
    description,
    expirationDate,
    link,
    file = null,
    notice = ""
  ) {
    this.id = id;
    this.entityId = entityId;
    this.name = name;
    this.description = description;
    this.expirationDate = expirationDate;
    this.link = link;
    this.file = file;
    this.notice = notice;
  }

  static map = file => {
    return new FleetManagementFile(
      file.nu_id_documento,
      file.nu_id_entidad,
      file.vc_nombre_documento,
      file.vc_desc_documento,
      file.dt_fec_caducidad_documento,
      file.vc_desc_fichero,
      file.nu_dias_preaviso
    );
  };
}
export default FleetManagementFile;
