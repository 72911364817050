import axiosInstance from "../utils/axiosInstance";
import {getCompanyEntityId} from "./StorageService";
import Client from "../domain/Client";
import Response from "../domain/Response";

export const fetchAllClients = async () => {
  try {
    const {data} = await axiosInstance.post("/Cliente/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(client => Client.map(client));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createClient = async data => {
  try {
    const result = await axiosInstance.post("/Cliente/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_cod_entidad: data.clientCode,
      vc_nombre: data.name,
      vc_nombre_comercial: data.businessName,
      vc_cif_pais: data.countryCode,
      vc_cif_codigo: data.code,
      vc_email: data.email,
      vc_nombre_sede: data.headquarterName,
      bi_sede_fiscal: data.fiscal,
      vc_calle: data.address,
      vc_numero: data.number,
      vc_cod_postal: data.postalCode,
      vc_municipio: data.municipality,
      vc_provincia: data.province,
      vc_pais: data.country,
      nu_latitud: data.latitude,
      nu_longitud: data.longitude,
      vc_ubigeo: data.ubigeo.id,
    });
    const response = Response.map(result.data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateClient = async client => {
  try {
    const result = await axiosInstance.post("/Cliente/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_cliente: client.id,
      nu_id_externo: client.clientCode,
      vc_nombre: client.name,
      vc_nombre_comercial: client.businessName,
      vc_cif_pais: client.country,
      vc_cif_codigo: client.code,
      vc_email: client.email
    });
    const response = Response.map(result.data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateClientState = async (clientId) => {
  try {
    const result = await axiosInstance.post("/Cliente/del", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_cliente: clientId,
    });
    const response = Response.map(result.data);
    if (response.transactionState === "0") {
      throw new Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
}