import {useMemo, useState} from "react";

const useSearch = data => {
  const [ search, setSearch ] = useState("");

  const filterObject = element => {
    const values = Object.values(element);

    return values.some(value => {
      return value instanceof Object
        ? Object.values(value).some(v =>
            v?.toString()?.toLowerCase()?.includes(search?.toString()?.toLowerCase())
          )
        : value
            ?.toString()
            ?.toLowerCase()
            ?.includes(search?.toString()?.toLowerCase());
    });
  };

  const onSearch = text => {
    setSearch(text);
  };

  const filteredData = useMemo(() => {
    if( data?.length > 0 && search !== "") {
      return data?.filter(d => filterObject(d));
    }
    return data;
  }, [search, data]);

  return {
    filteredData,
    search,
    onSearch
  };
};

export default useSearch;
