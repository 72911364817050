import axiosInstance from "../../../utils/axiosInstance";
import Response from "../../../domain/Response";
import {parseDateTimeToInput} from "../../../utils/dates";
import {mapToBoolean} from "../../../utils/mappers";
import {getCompanyEntityId} from "../../StorageService";
import {itemTypes, locationType} from "../../../components/Logistic/Purchases/ProviderOrders/constants";
import {getAllClientsHeadquarters} from "../Distribution/ClientHeadquarterService";

export const fetchAllProvidersSelect = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Proveedor/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
            }
        );
        return data.map(provider => ({
            id: provider.nu_id_entidad,
            name: provider.vc_nombre,
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllStatesSelect = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/sel_estados',
        );
        return data.map(state => ({
            id: state.nu_id_estado,
            name: state.vc_desc_estado,
            state: mapToBoolean(state.bi_estado),
        })).filter(state => state.state);
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllProductsSelect = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Inventario_Producto/sel_productos',
            {
                nu_id_entidad: getCompanyEntityId(),
                bi_estado: true,
            }
        );
        return data.map(state => ({
            id: state.nu_id_producto,
            name: state.vc_desc_producto,
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}


export const fetchAllProviderOrders = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/sel',
            {}
        );
        return data.map(order => ({
            id: order.nu_id_pedido,
            providerName: order.vc_desc_proveedor,
            orderDate: order.dt_fecha,
            carryingOutDate: order.dt_fecha_ejecucion,
            stateName: order.vc_desc_estado,
            typeName: order.vc_tipo
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchProviderOrderDetails = async (orderId) => {
    try {
        const [
            {data: details},
            {data: providers},
            {data: contacts},
            {data: items},
            clientHeadquarters
        ] = await Promise.all([
            axiosInstance.post(
                '/Pedido/get',
                {
                    nu_id_pedido: orderId,
                }
            ),
            axiosInstance.post(
                '/Pedido/get_provedores',
                {
                    nu_id_pedido: orderId,
                }
            ),
            axiosInstance.post(
                '/Pedido/get_contactos',
                {
                    nu_id_pedido: orderId,
                }
            ),
            axiosInstance.post(
                '/Pedido/get_detalle',
                {
                    nu_id_pedido: orderId,
                }
            ),
            getAllClientsHeadquarters(),
        ]);
        return {
            state: details.nu_id_estado,
            editable: mapToBoolean(details.bi_editable),
            providers: providers.map(provider => ({
                id: provider.nu_id,
                name: provider.vc_nombre,
                // TODO: Estado y date pendientes de validacion
                state: 'Aceptado (Dato pendiente)',
                date: 'Fecha (Dato pendiente)',
            })),
            origin: {
                location: clientHeadquarters.find(e => e.id === `${details.nu_id_entidad_origen}-${details.nu_id_sede_origen}`),
                date: parseDateTimeToInput(details.dt_fecha_origen),
                indications: details.vc_indicaciones_origen,
                latitude: details.nu_latitud_origen,
                longitude: details.nu_longitud_origen,
                contacts: contacts.filter(c => c.ch_tipo === 'O')
                    .map(contact => ({
                        id: contact.nu_id,
                        name: contact.vc_nombre,
                        phone: contact.vc_telefono,
                        email: contact.vc_email
                    }))
            },
            destination: {
                location: clientHeadquarters.find(e => e.id === `${details.nu_id_entidad_destino}-${details.nu_id_sede_destino}`),
                date: parseDateTimeToInput(details.dt_fecha_destino),
                indications: details.vc_indicaciones_destino,
                latitude: details.nu_latitud_destino,
                longitude: details.nu_longitud_destino,
                contacts: contacts.filter(c => c.ch_tipo === 'D')
                    .map(contact => ({
                        id: contact.nu_id,
                        name: contact.vc_nombre,
                        phone: contact.vc_telefono,
                        email: contact.vc_email
                    }))
            },
            control: {
                type: mapToBoolean(details.bi_control_temp) ? 1 : 2,
                min: details.nu_min_temp,
                max: details.nu_max_temp
            },
            items: items.map(item =>({
                id: item?.nu_id,
                name: item?.VC_DESC_PRODUCTO || item?.vc_desc_servicio,
                quantity: item.nu_cantidad,
                description: 'Pendiente de campo',
                billable: mapToBoolean(item.bi_facturable),
            }))
        };
    }catch (e) {
        return Promise.reject(e);
    }
}

export const createProviderOrder = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/ins',
            {
                dt_fecha: new Date().toISOString(),
                nu_id_sede_origen: null,
                dt_fecha_origen: "",
                vc_indicaciones_origen: "",
                nu_id_sede_destino: null,
                dt_fecha_destino: "",
                vc_indicaciones_destino: "",
                bi_control_temp: true,
                nu_min_temp: 0,
                nu_max_temp: 0,
                ls_proveedor: [],
                ls_detalle: [],
                ls_contacto: []
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editOrderState = async ({orderId, stateId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/upd_estado',
            {
                nu_id_pedido: orderId,
                nu_id_estado: stateId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const addProviderToOrder = async ({orderId, providerId}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/ins_proveedor',
            {
                nu_id_pedido: orderId,
                nu_id_entidad_proveedor: providerId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const removeProviderFromOrder = async (providerId) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/del_proveedor',
            {
                nu_id: providerId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const addContactToOrder = async ({orderId, request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/ins_contacto',
            {
                nu_id_pedido: orderId,
                vc_nombre: request.name,
                vc_email: request.email,
                vc_telefono: request.phone,
                ch_tipo: request.type === locationType.origin ? 'O' : 'D',
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const removeContactFromOrder = async (contactId) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/del_contacto',
            {
                nu_id: contactId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editOrderOriginDetails = async ({orderId, request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/upd_origen',
            {
                nu_id_pedido: orderId,
                nu_id_sede_origen: request.location.headquarterId,
                dt_fecha_origen: request.date,
                vc_indicaciones_origen: request.indications,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editOrderDestinationDetails = async ({orderId, request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/upd_destino',
            {
                nu_id_pedido: orderId,
                nu_id_sede_destino: request.location.headquarterId,
                dt_fecha_destino: request.date,
                vc_indicaciones_destino: request.indications,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editOrderControlDetails = async ({orderId, request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/upd_control_temp',
            {
                nu_id_pedido: orderId,
                bi_control_temp: request.type === 1,
                nu_min_temp: request.min,
                nu_max_temp: request.max,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const addItemToOrder = async ({orderId, request}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/ins_detalle',
            {
                nu_id_pedido: orderId,
                nu_id_producto: itemTypes.product === request.type ? request.id : null,
                nu_id_servicio: itemTypes.service === request.type ? request.id : null,
                nu_cantidad: request.quantity,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const removeItemFromOrder = async (itemId) => {
    try {
        const {data} = await axiosInstance.post(
            '/Pedido/del_detalle',
            {
                nu_id: itemId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}