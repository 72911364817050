import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllClientsStarted} from "../../../redux/actions/Administrator/Clients";
import IntlMessages from "@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../components/Common/Forms/Select/MultipleSelect";

const ClientsPicker = ({id, values, isLoading, onChange, errors}) => {
  const {data} = useSelector(state => state.administrator.clients);

  const options = data;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data.length < 1) {
      dispatch(fetchAllClientsStarted());
    }
  }, []);

  return (
    <MultipleSelect
      id={id}
      options={options}
      value={values}
      onChange={onChange}
      fullWidth
      hasError={errors.clients.length > 0}
      error={errors.clients[0]}
      disabled={isLoading}
      label={<IntlMessages id="logistic.inventoryReport.filters.clients" />}
      headerText={
        <IntlMessages id="logistic.inventoryReport.filters.clients.header" />
      }
      noOptionsText={
        <IntlMessages id="logistic.inventoryReport.filters.clients.no.option" />
      }
    />
  );
};

export default ClientsPicker;
