import {combineReducers} from "redux";
import AddAdvanceForm from "./AddAdvanceForm";
import CurrentBox from "./CurrentBox";
import AdvancesListFilter from "./AdvancesListFilter";
import AddExpenseForm from "./AddExpenseForm";
import CurrentAdvance from "./CurrentAdvance";
import NewAccounting from "./NewAccounting";
import CurrentAccounting from "./CurrentAccounting";
import SupervisorRole from "./SupervisorRole";
import OperativePettyCashes from "./PettyCashes";
import Advances from "./Advances";
import AdvancesStates from "./AdvancesStates";
import Employees from "./Employees";
import Accounting from "./Accounting";
import Expenses from "./Expenses";
import Refunds from "./Refunds";

export default combineReducers({
  addAdvanceForm: AddAdvanceForm,
  currentBox: CurrentBox,
  advancesListFilter: AdvancesListFilter,
  addExpenseForm: AddExpenseForm,
  currentAdvance: CurrentAdvance,
  newAccounting: NewAccounting,
  currentAccounting: CurrentAccounting,
  supervisorRole: SupervisorRole,
  pettyCashes: OperativePettyCashes,
  advancesList: Advances,
  advancesStates: AdvancesStates,
  employees: Employees,
  accountingList: Accounting,
  expenses: Expenses,
  refunds: Refunds,
});
