import axiosInstance from "../utils/axiosInstance";
import AccountingState from "../domain/AccountingState";

export const fetchAllAccountingStates = async () => {
  try {
    const states = await axiosInstance.post(
      "/Caja_Chica_Contabilizacion/sel_estado",
      {}
    );
    return states.data.map(state => AccountingState.map(state));
  } catch (e) {
    return Promise.reject(e);
  }
};
