import {
  FETCH_WAREHOUSE_TRANSFER_SUCCEEDED,
  APPLY_WAREHOUSE_TRANSFER_FILTERS,
  RESET_WAREHOUSE_TRANSFER_FILTERS,
  WAREHOUSE_TRANSFER_CHANGE_STATE,
  WAREHOUSE_TRANSFER_EDIT,
  WAREHOUSE_TRANSFER_CREATE
} from "../../types/Administrator/WarehouseTransfer";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_WAREHOUSE_TRANSFER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_WAREHOUSE_TRANSFER_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_WAREHOUSE_TRANSFER_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case WAREHOUSE_TRANSFER_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return {...type, active: !type.active};
          return type;
        })
      };
    }
    case WAREHOUSE_TRANSFER_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case WAREHOUSE_TRANSFER_EDIT: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return {...type, date: action.payload.date};
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};

/*
const INIT_STATE = {
    data: [
        {id:1, origin: "San Fernando", destiny: "Sede 1", date:"01/10/2021", active: true}
    ],
    filters: {
        active: undefined,
        origin: '',
        destiny: '',
        date:'',
    },
};

export default (state = INIT_STATE, {type, payload}) => {
    switch(type){
        case WarehouseTransfer.REMOVE_WAREHOUSE_TRANSFER:
            return INIT_STATE
        case WarehouseTransfer.ADD_WAREHOUSE_TRANSFER:
            return {}
        case WarehouseTransfer.REMOVE_WAREHOUSE_TRANSFER:
            return {}
        case WarehouseTransfer.EDIT_WAREHOUSE_TRANSFER:
            return {}
        default: return state                
    }
}*/
