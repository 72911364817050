import React from "react";
import {useStyles} from "../styles";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {Box} from "@material-ui/core";
import DefaultStatesList from "../Form/DefaultStatesArea";
import NameArea from "../Form/NameArea";
import {useDispatch} from "react-redux";
import {addWorkOrderType} from "../../../../../redux/actions/Logistic/WorkOrdersTypes";

const WorkOrderTypeAddDialog = ({open, setOpen, title}) => {
  const classes = useStyles();
  const [ created, setCreated ] = React.useState(false);
  const dispatch = useDispatch();
  const [ name, setName ] = React.useState("");
  const [ startState, setStartState ] = React.useState(null);
  const [ endState, setEndState ] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
    setName("");
    setCreated(false);
    setStartState(null);
  };
  const onSaveWorkOrderType = data => {
    setCreated(true);
    setName(data.name);
  };
  const onSaveWorkStartState = data => {
    setStartState(data);
  };
  const onSaveWorkEndState = data => {
    setEndState(data);
    dispatch(
      addWorkOrderType(
        {name, startState, endState: {...data, type: 2}},
        handleClose
      )
    );
  };
  return (
    <FullScreenDialog open={open} handleClose={handleClose} title={title}>
      <Box className={classes.defaultBigBox} m={4}>
        <NameArea data={{name}} onSave={onSaveWorkOrderType} add />
        {created && (
          <DefaultStatesList
            startInitialData={{
              name: "",
              confirmation: false,
              permission: false,
              previousCheck: "",
              confirmationCheck: "",
              arrivalDestination: false,
              departureDestination: false
            }}
            endInitialData={{
              name: "",
              confirmation: false,
              permission: false,
              previousCheck: "",
              confirmationCheck: "",
              arrivalDestination: false,
              departureDestination: false
            }}
            startChecklist={[]}
            endChecklist={[]}
            onSaveStartData={onSaveWorkStartState}
            onSaveEndData={onSaveWorkEndState}
            add={true}
          />
        )}
      </Box>
    </FullScreenDialog>
  );
};

export default WorkOrderTypeAddDialog;
