import React from 'react';
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {DataGrid} from "@mui/x-data-grid";
import ExportToolbar from "../../../../../../Common/Tables/DataGrid/ExportToolbar";
import moment from "moment/moment";

const LoadView = () => {
    const {file: data} = useSelector(({controlPanel}) => controlPanel.kpi.current);
    const intl = useIntl();
    const columns = Object.freeze([
        { field: 'id', hide: true},
        {
            field: 'date',
            type: 'date',
            valueFormatter: ({value}) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['date'],
            width: 200
        },
        {
            field: 'origin',
            headerName: intl.messages['origin'],
            width: 200
        },
        {
            field: 'route',
            headerName: intl.messages['route'],
            width: 200
        },
        {
            field: 'clientCode',
            headerName: intl.messages['client.code'],
            width: 200
        },
        {
            field: 'client',
            headerName: intl.messages['client'],
            width: 200
        },
        {
            field: 'materialCode',
            headerName: intl.messages['material.code'],
            width: 200
        },
        {
            field: 'material',
            headerName: intl.messages['material'],
            width: 200
        },
        {
            field: 'saleQuantity',
            headerName: intl.messages['sale.quantity'],
            width: 200
        },
        {
            field: 'dispatchedQuantity',
            headerName: intl.messages['dispatched.quantity'],
            width: 200
        },
        {
            field: 'dispatchedKg',
            headerName: intl.messages['dispatched.kg'],
            width: 200
        },
        {
            field: 'receivedQuantity',
            headerName: intl.messages['received.quantity'],
            width: 200
        },
        {
            field: 'receivedKg',
            headerName: intl.messages['received.kg'],
            width: 200
        },
        {
            field: 'notDelivered',
            headerName: intl.messages['not.delivered'],
            width: 200
        },
        {
            field: 'reason',
            headerName: intl.messages['reason'],
            width: 200
        },
        {
            field: 'carrier',
            headerName: intl.messages['carrier'],
            width: 200
        },
        {
            field: 'plaque',
            headerName: intl.messages['plaque'],
            width: 200
        },
        {
            field: 'driverCode',
            headerName: intl.messages['driver.code'],
            width: 200
        },
        {
            field: 'driver',
            headerName: intl.messages['driver'],
            width: 200
        },
        {
            field: 'castAssistant',
            headerName: intl.messages['cast.assistant'],
            width: 200
        },

    ]);
    return (
        <DataGrid
            columns={columns}
            rows={data || []}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            disableColumnMenu
            components={{
                Toolbar: ExportToolbar,
            }}
        />
    );
};

export default LoadView;