import {
  CLEAR_BREAKDOWN_DESTINATIONS_LIST,
  CLOSE_BREAKDOWN_DIALOG,
  FETCH_BREAKDOWN_FAILED,
  FETCH_BREAKDOWN_STARTED,
  FETCH_BREAKDOWN_SUCCESS, OPEN_BREAKDOWN_DIALOG,
  RESET_BREAKDOWN_STATE, SET_BREAKDOWN_FILTERS, SET_CURRENT_BREAKDOWN_DESTINATION
} from "../../types/Administrator/WorkOrdersBreakDownTypes";
import {
  fetchDispatchesByOtherParameters,
  fetchWorkOrderByNumber,
  updateWorkOrderDispatch,
  getWorkOrderDispatchUpdatesHistory,
  fetchWorkOrderDispatchHistory,
  fetchAllDispatchesHistoryByOT,
  fetchExcelDispatchesForOthersFilters,
  fetchWorkOrderDispatchUpdatesHistoryExcel,
  fetchWorkOrderDispatchHistoryExcel, fetchAllDispatchesHistoryByOTExcel
} from "../../../services/WorkOrderBreakdownService";
import {setOnErrorMessage} from "./ui";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {dialogsList} from "../../../components/Administrator/WorkOrderBreakdown/constants";
import DestinationBreakdown from "../../../domain/DestinationBreakdown";
import {downloadFile} from "../../../services/FileService";


export const clearDestinationsList = () => ({type: CLEAR_BREAKDOWN_DESTINATIONS_LIST});

export const setBreakdownFilters = (filters) => ({type: SET_BREAKDOWN_FILTERS, payload: filters});

const fetchBreakdownFailed = () => {
  return {
    type: FETCH_BREAKDOWN_FAILED
  };
};

const fetchBreakdownSuccess = workOrder => {
  return {
    type: FETCH_BREAKDOWN_SUCCESS,
    payload: {
      detail: workOrder.detail,
      destinations: workOrder.destinations
    }
  };
};

export const fetchBreakdownStarted = workOrderNUmber => {
  return async dispatch => {
    dispatch({type: FETCH_BREAKDOWN_STARTED});
    dispatch(fetchStart());
    fetchWorkOrderByNumber(workOrderNUmber)
      .then(breakdown => {
        // console.log(breakdown);
        if (breakdown.id === null) {
          dispatch(fetchSuccess(breakdown.message));
        } else {
          dispatch(fetchSuccess());
          dispatch(fetchBreakdownSuccess(breakdown));
        }
      })
      .catch(e => {
        dispatch(fetchError(e.message));
        dispatch(setOnErrorMessage(e.message));
        dispatch(fetchBreakdownFailed());
      });
  };
};

export const resetBreakdownState = () => ({type: RESET_BREAKDOWN_STATE});


export const getDispatchesByDetails = (filters) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      dispatch(fetchBreakdownSuccess({destinations: []}));
      const destinations = await fetchDispatchesByOtherParameters(filters);
      dispatch(fetchSuccess());
      dispatch(fetchBreakdownSuccess({destinations}));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}

export const openBreakdownDialog = dialog => ({type: OPEN_BREAKDOWN_DIALOG, payload: dialog});
export const closeBreakdownDialog = dialog => ({type: CLOSE_BREAKDOWN_DIALOG, payload: dialog});

export const setCurrentBreakdownDestination = (destination, dialog) => {
  return async dispatch  => {
    if(!destination) {
      dispatch({type: SET_CURRENT_BREAKDOWN_DESTINATION, payload: destination});
      return;
    }
    try {
      dispatch(fetchStart());
      let currentDestination = {
        ...destination,
      }
      if(dialog === dialogsList.matrixData) {
        const updatesHistory = await getWorkOrderDispatchUpdatesHistory(destination.id);
        currentDestination.updatesHistory = updatesHistory;
      }else if(dialog === dialogsList.historyData){
        const history = await fetchWorkOrderDispatchHistory(destination.route.origin, destination.route.destination);
        currentDestination.history = history;
      }

      dispatch({type: SET_CURRENT_BREAKDOWN_DESTINATION, payload: currentDestination});
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
};

export const editDispatchCentralData = (request, filters) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const response = await updateWorkOrderDispatch(request);
      dispatch(fetchSuccess(response.transactionMessage));
      dispatch(getDispatchesByDetails(filters));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}

export const setOtLikeCurrentDestination = (workOrder, workOrderNumber) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const currentDestination = new DestinationBreakdown(workOrder.id, {route: workOrder.route}, workOrder.realTime, workOrder.estimatedTime, [], []);
      const history = await fetchAllDispatchesHistoryByOT(workOrderNumber);
      currentDestination.history = history;
      dispatch(fetchSuccess());
      dispatch({type: SET_CURRENT_BREAKDOWN_DESTINATION, payload: currentDestination});
    }catch (e) {
      dispatch(fetchError(e.message));

    }
  }
}


export const getExcelOfOthersSearch = (filters) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const base64 = await fetchExcelDispatchesForOthersFilters(filters);
      downloadFile("report.xlsx",
          `data:application/vnd.ms-excel;base64,${base64}`);
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}

export const getExcelOfUpdateHistory = (dispatchId) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const base64 = await fetchWorkOrderDispatchUpdatesHistoryExcel(dispatchId);
      downloadFile("report.xlsx",
          `data:application/vnd.ms-excel;base64,${base64}`);
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}


export const getExcelOfOTDispatchHistory = (origin, destination) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const base64 = await fetchWorkOrderDispatchHistoryExcel(origin, destination);
      downloadFile("report.xlsx",
          `data:application/vnd.ms-excel;base64,${base64}`);
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}

export const getExcelOfDispatchesHistoryByOT = (ot) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const base64 = await fetchAllDispatchesHistoryByOTExcel(ot);
      downloadFile("report.xlsx",
          `data:application/vnd.ms-excel;base64,${base64}`);
      dispatch(fetchSuccess());
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}