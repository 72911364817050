import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import Route from "../domain/Route";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAllRoutesByDriver = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};
export const fetchAllRoutesByDriver = async driverId => {
  try {
    const routes = await axiosInstance.post(
      "/OT/sel_ruta_conductor",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_conductor: driverId
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return routes.data.map(r => Route.map(r));
  } catch (e) {
    if (isCancel(e)) {
      return [];
    }
    return Promise.reject(e);
  }
};

export const fetchAllRoutes = async () => {
  try {
    const routes = await axiosInstance.post("/Ruta/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return routes.data.map(r => Route.reportMap(r));
  } catch (e) {
    return Promise.reject(e);
  }
};
