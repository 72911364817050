import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import validations from "./validations";
import CostCenterSelect from "../../../../Common/Forms/Select/CostCenterSelect";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
const Form = ({id, expense, onSubmit}) => {
    const {formData, setFormData, errors, handleSubmit} = useForm({
        costCenter: expense.costCenterId,
    }, validations);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        value={expense.expenseType.description}
                        label={<IntlMessages id='guardian.accounting.dialog.expenses.table.header.type' />}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        value={expense.accountingAccount}
                        label={<IntlMessages id='guardian.accounting.dialog.expenses.table.header.accounting.account' />}
                        disabled
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        value={expense.amount}
                        label={<IntlMessages id='guardian.accounting.dialog.expenses.table.header.amount' />}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <CostCenterSelect
                        value={formData.costCenter}
                        hasError={errors?.costCenter?.length > 0}
                        error={errors.costCenter[0]}
                        onChange={value => setFormData("costCenter", value)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;