import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import validations from "./validations";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {createFavoriteReport} from "../../../../../redux/actions/ControlPanel/Kpi";
import {fetchError} from "../../../../../redux/actions";

const initData = {
    name: '',
};
const AddFavoriteDialog = ({open, setOpen}) => {
    const id = 'kpi-add-favorite';
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    const {formData, errors, setFormData, handleSubmit} = useForm(initData, validations);
    const onSaveFavorite = data => {
        if(Object.values(current.filters).length !== 0) {
            dispatch(createFavoriteReport(current.type, current.filters, formData));
        }else {
            dispatch(fetchError('Es necesario guardar algún filtro'));
        }
        setOpen(false);
    }
    return (
        <FormDialog
            id={id}
            open={open}
            title={<IntlMessages id={"control.panel.kpi.save.favorite"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
            submitText={<IntlMessages id={"label.form.save"}/>}
            onClose={() => setOpen(false)}
        >
            {open
                &&
                <form id={id} onSubmit={handleSubmit(onSaveFavorite)}>
                    <GridContainer spacing={2}>
                        <Grid item xs={12}>
                            <OutlinedInput
                                id={'favorite-name'}
                                fullWidth
                                value={formData.name}
                                onChange={value => setFormData('name', value)}
                                label={<IntlMessages id={ "control.panel.kpi.save.favorite.name"}/>}
                                hasError={errors?.name?.length > 0}
                                error={errors?.name[0]}
                            />
                        </Grid>
                    </GridContainer>
                </form>
            }
        </FormDialog>
    );
}

export default AddFavoriteDialog;