import React from 'react';
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {getAllDispatchesTypes} from "../../../../../../../../services/Logistics/Distribution/WorkOrderTypeService";
import MultipleSelect from "../../../../../../../Common/Forms/Select/MultipleSelect";

const ServiceTypesPicker = ({values, onChange, errors}) => {
    const {data: types} = useFetch(getAllDispatchesTypes, {}, []);

    return (
        <MultipleSelect
            id="service-types-picker"
            options={types}
            value={values}
            onChange={onChange}
            fullWidth
            hasError={errors?.serviceTypes?.length > 0}
            error={errors?.serviceTypes?.join(', ')}
            label={
                <IntlMessages id="control.panel.kpi.areas.grid.distribution.filters.service.types" />
            }
            headerText={
                <IntlMessages id="picker.options.header" />
            }
            noOptionsText={
                <IntlMessages id="picker.options.no.option" />
            }
        />
    );
};

ServiceTypesPicker.propTypes = {

};

export default ServiceTypesPicker;