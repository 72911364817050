import React, {useEffect, useMemo, memo} from 'react';
import { useDispatch,useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';
import selectWarehouseTransfer from '../../../redux/actions/Administrator/WarehouseTransferSelect';
import {fetchWarehouseTransferDetailsById} from "../../../services/WarehouseTransferSelectService";
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions";

const TransferSelect = ({ id, label, hasError, error, value, onChange }) => {

  const {data} = useSelector(state => state.administrator.transferSelect);

  const options = useMemo(()=> {
    let initialOptions = [{value: '', label: 'Seleccione una opción'}];
    if(data.length>0){
      data.filter(type => type.active !== 0).map(type => {
        initialOptions.push({
          value: type.id, 
          label: type.date + ' ' + type.entityNameOrigin + '/' + type.campusNameOrigin + '/' + type.warehouseNameOrigin
        + ' ' + type.entityNameDestiny + '/' + type.campusNameDestiny + '/' + type.warehouseNameDestiny,
          products: type.transfer,
          campusIdDestiny: type.idCampusDestiny, 
          campusNameDestiny: type.campusNameDestiny,
          campusIdOrigin: type.idCampusOrigin, 
          campusNameOrigin: type.campusNameOrigin,
          entityIdDestiny: type.idEntityDestiny,
          entityNameDestiny: type.entityNameDestiny,
          entityIdOrigin: type.idEntityOrigin,
          entityNameOrigin: type.entityNameOrigin,
          warehouseIdDestiny: type.idWarehouseDestiny,
          warehouseNameDestiny: type.warehouseNameDestiny,
          warehouseIdOrigin: type.idWarehouseOrigin,
          warehouseNameOrigin: type.warehouseNameOrigin
        });
      })
    }
    return initialOptions;
  }, [data])

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(selectWarehouseTransfer.get());
  },[])
  const handleOnChange = async (value) => {
    try {
      dispatch(fetchStart());
      const list = await fetchWarehouseTransferDetailsById(value);
      dispatch(fetchSuccess());
      const option = options.find(option => option.value === value);
      onChange && onChange({...option, products: list});
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
  return (
    <Select
      value={value?.value || ''}
      error={hasError}
      errorMessage={error}
      fullWidth
      id={id}
      label={label}
      onChange={e => handleOnChange(e.target.value)}
      options={options}
    />
  );
};

export default memo(TransferSelect);
