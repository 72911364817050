import React, {useEffect} from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "../../../../Common/Forms/Select/ActiveSelect";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import BooleanSelect from "../../../../Common/Forms/Select/BooleanSelect";

import useForm from "../../../../../hooks/Common/useForm";

const initialData = {
  active: true,
  description: "",
  approvalOfExpenses: undefined,
  obligationOfOT: undefined
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <ActiveSelect
            id="advance-types-state-filter"
            value={formData.active}
            onChange={value => setFormData("active", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.description}
            id="description-filter"
            label={
              <IntlMessages id="administrator.advanceTypes.filters.description" />
            }
            onChange={value => setFormData("description", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <BooleanSelect
            id="advance-approval-select-filter"
            value={formData.approvalOfExpenses}
            label={
              <IntlMessages id="administrator.advanceTypes.filters.approvalOfExpenses" />
            }
            onChange={value => setFormData("approvalOfExpenses", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <BooleanSelect
            id="advance-obligation-select-filter"
            value={formData.obligationOfOT}
            label={
              <IntlMessages id="administrator.advanceTypes.filters.obligationOfOT" />
            }
            onChange={value => setFormData("obligationOfOT", value)}
            fullWidth
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

Form.propTypes = {
  onChange: PropTypes.func
};

Form.defaultProps = {
  onChange: () => {}
};

export default Form;
