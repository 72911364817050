import React, {useMemo} from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {parseDateToGlobalMeridianFormat} from "../../../../../../../utils/dates";
import {useDispatch, useSelector} from "react-redux";
import {getEcoDrivingEfficiencyTableData} from "../../../../../../../redux/thunks/ControlPanel/ecoDriving";
import {minutesToDayAndTime} from "../../../../../../../utils/parsers";

const EventsDetailsTable = ({details}) => {
    const {filters} = useSelector(({controlPanel}) => controlPanel.dashboards);
    const {data, rowCount} = useSelector(({controlPanel}) => controlPanel.dashboards.currentDashboard.widgets.efficiency.details);
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    const intl = useIntl();
    const dispatch = useDispatch();

    const columns = useMemo(() => {
        return [
            {
                field: 'id',
                hide: true
            },
            {
                field: 'date',
                headerName: intl.messages['date'],
                width: 180,
                valueFormatter: ({value}) => parseDateToGlobalMeridianFormat(value)
            },
            {
                field: 'plaque',
                headerName: intl.messages['plaque'],
                width: 300
            },
            {
                field: 'group',
                headerName: intl.messages['group'],
                width: 250
            },
            {
                field: 'driver',
                headerName: intl.messages['driver'],
                width: 250
            },
            {
                field: 'gravity',
                headerName: intl.messages['gravity'],
                width: 180
            },
            {
                field: 'ralentiTime',
                headerName: `${intl.messages['time']} ${intl.messages['ralenti']}`,
                width: 180,
                valueFormatter: ({value}) => minutesToDayAndTime(value)
            },
            {
                field: 'address',
                headerName: intl.messages['address'],
                width: 180
            },

        ]
    }, [intl]);
    const onPageChange = (page) => {
        setPage(page);
    }
    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
    }
    React.useEffect(() => {
        if(filters.startDate && filters.endDate) {
            dispatch(getEcoDrivingEfficiencyTableData({
                filters,
                page,
                pageSize
            }));
        }
    }, [page, pageSize, filters]);
    return (
        <DataGrid
            sx={{
                height: '50vh'
            }}
            columns={columns}
            rows={data}
            disableSelectionOnClick
            rowsPerPageOptions={[50, 100, 200]}
            pagination
            rowHeight={50}
            rowCount={rowCount}
            page={page}
            onPageChange={onPageChange}
            pageSize={pageSize}
            onPageSizeChange={onPageSizeChange}
            paginationMode='server'
        />
    );
};

export default EventsDetailsTable;