import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/CashManagers/PageContainer";
import CreateCashManagerDialog from "components/Administrator/CashManagers/SaveCashManagerDialog/CreateCashManagerDialog";
import EditCashManagerDialog from "components/Administrator/CashManagers/SaveCashManagerDialog/EditCashManagerDialog";
import DeleteConfirmation from "components/Administrator/CashManagers/Confirmations/DeleteConfirmation";
import Table from "components/Administrator/CashManagers/Table";
import Dashboard from "components/Administrator/CashManagers/Dashboard";
import FloatingButton from "components/Administrator/CashManagers/FloatingButton";
import Filters from "components/Administrator/CashManagers/Filters";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";

import useUiState from "hooks/Administrator/useUiState";

import cashManagers from "redux/actions/Administrator/CashManagers";
import UserDetails from "../../../../components/Administrator/CashManagers/UserDetails";

const CashManagers = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(cashManagers.get());
      return () => dispatch(cashManagers.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(cashManagers.applyFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(cashManagers.resetFilters());

  const onDelete = () => dispatch(cashManagers.delete(ui.deletingId));
  const onEdit = data => dispatch(cashManagers.edit(ui.editingId, data));
  const onCreate = data => dispatch(cashManagers.create(data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      {/*<Dashboard />*/}
        <UserDetails />
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table
            data={tableData}
            onEdit={ui.tableProps.onEdit}
            onDelete={ui.tableProps.onDelete}
          />
        </Box>
      )}
      <CreateCashManagerDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditCashManagerDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <FloatingButton onAddClicked={ui.floatingButtonProps.onCreate} />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.cashManagers;
  const {currencies} = state.customCommon.currencies;

  const mappedData = data.map(cashManager => {
    const currency = currencies.find(c => c.id === cashManager.currencyId);
    return {...cashManager, currency: currency.symbol};
  });

  const filtered = new FilterBuilder(mappedData)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("currency", filters.currency)
    .byFieldIncludes("names", filters.cashManager)
    .byFieldIncludes("tags", filters.tag)
    .byFieldEquals("fund", filters.fund)
    .byFieldEquals("cash", filters.cash)
    .byFieldEquals("transit", filters.transit)
    .byFieldEquals("liquidated", filters.liquidated)
    .byFieldEquals("refund", filters.refund)
    .build();

  return {tableData: filtered};
};

export default connect(mapStateToProps)(CashManagers);
