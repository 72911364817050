import React from "react";

import Autocomplete from "components/Administrator/Form/CashManagersAutocomplete";
import {useSelector} from "react-redux";

const CashManagersAutocomplete = ({hasError, error, value, onChange}) => {
  const {data: options} = useSelector(
    state => state.customCommon.cashManagerEntities
  );

  return (
    <Autocomplete
      hasError={hasError}
      error={error}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default CashManagersAutocomplete;
