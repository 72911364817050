import React, {useState} from "react";
import PropTypes from "prop-types";

import ButtonBase from "@material-ui/core/ButtonBase";
import InputBase from "@material-ui/core/InputBase";
import Popper from "@material-ui/core/Popper";
import SettingsIcon from "@material-ui/icons/Settings";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";

import useStyles from "./styles";

const Picker = ({
  id,
  title,
  headerText,
  noOptionsText,
  options,
  values,
  onChange,
  hasError,
  error
}) => {
  const classes = useStyles();

  const [ pendingValue, setPendingValue ] = useState([]);

  const [ anchorEl, setAnchorEl ] = useState(null);
  const open = Boolean(anchorEl);
  const identifier = open ? id : undefined;

  const handleOpen = event => {
    setPendingValue(values);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    onChange(pendingValue);
    setPendingValue([]);
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const filterOptions = (options, {inputValue}) => {
    const filteredOptions = options.filter(o => o.name.includes(inputValue));
    return [
      {name: "Seleccionar todo", value: "Seleccionar todo"},
      ...filteredOptions
    ];
  };

  return (
    <React.Fragment>
      <div>
        <ButtonBase
          disableRipple
          className={classes.button}
          aria-describedby={identifier}
          onClick={handleOpen}
        >
          <span className={hasError ? classes.errorColor : ""}>{title}</span>
          <SettingsIcon
            fontSize="small"
            className={hasError ? classes.errorColor : ""}
          />
        </ButtonBase>
        {values.map(value => (
          <div key={value.id} className={classes.tag}>
            {value.name}
          </div>
        ))}
        {hasError && <p className={classes.error}>{error}</p>}
      </div>
      <Popper
        id={identifier}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={classes.popper}
      >
        <div className={classes.header}>{headerText}</div>
        <Autocomplete
          open
          value={pendingValue}
          onChange={(event, newValues) => {
            if (
              newValues.find(e => e.name === "Seleccionar todo") !== undefined
            ) {
              setPendingValue(options);
            } else {
              setPendingValue(newValues);
            }
          }}
          onClose={handleClose}
          multiple
          disableCloseOnSelect
          disablePortal
          renderTags={() => null}
          getOptionLabel={option => option.name}
          noOptionsText={noOptionsText}
          getOptionSelected={(option, value) => option.id === value.id}
          renderOption={(option, {selected}) => (
            <React.Fragment>
              <DoneIcon
                className={classes.selected}
                style={{visibility: selected ? "visible" : "hidden"}}
              />
              <div className={classes.optionWrapper}>{option.name}</div>
              <CloseIcon
                className={classes.close}
                style={{visibility: selected ? "visible" : "hidden"}}
              />
            </React.Fragment>
          )}
          options={options}
          filterOptions={filterOptions}
          renderInput={params => (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={params.inputProps}
              autoFocus
              className={classes.input}
            />
          )}
          classes={{
            paper: classes.paper,
            option: classes.option,
            popperDisablePortal: classes.popperDisablePortal
          }}
        />
      </Popper>
    </React.Fragment>
  );
};

Picker.propTypes = {
  id: PropTypes.string,
  title: PropTypes.node,
  headerText: PropTypes.node,
  noOptionsText: PropTypes.node,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string
    })
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      name: PropTypes.string
    })
  ),
  onChange: PropTypes.func
};

Picker.defaultProps = {
  id: "",
  title: "",
  headerText: "",
  noOptionsText: "",
  options: [],
  values: [],
  onChange: () => {}
};

export default Picker;
