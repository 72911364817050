export const RESET_VEHICLE_CORRECTIVES_STATE =
  "RESET_VEHICLE_CORRECTIVES_STATE";
export const FETCH_ALL_VEHICLE_CORRECTIVES = "FETCH_ALL_VEHICLE_CORRECTIVES";
export const APPLY_VEHICLE_CORRECTIVES_FILTERS =
  "APPLY_VEHICLE_CORRECTIVES_FILTERS";
export const RESET_VEHICLE_CORRECTIVES_FILTERS =
  "RESET_VEHICLE_CORRECTIVES_FILTERS";
export const SET_CURRENT_VEHICLE_CORRECTIVES_TYPE =
  "SET_CURRENT_VEHICLE_CORRECTIVES_TYPE";
export const RESET_CURRENT_VEHICLE_CORRECTIVES_TYPE =
  "RESET_CURRENT_VEHICLE_CORRECTIVES_TYPE";
export const ADD_VEHICLE_CORRECTIVES_TYPE = "ADD_VEHICLE_CORRECTIVES_TYPE";
export const EDIT_VEHICLE_CORRECTIVES_TYPE = "EDIT_VEHICLE_CORRECTIVES_TYPE";
export const OPEN_VEHICLE_CORRECTIVES_DIALOG =
  "OPEN_VEHICLE_CORRECTIVES_DIALOG";
export const CLOSE_VEHICLE_CORRECTIVES_DIALOG =
  "CLOSE_VEHICLE_CORRECTIVES_DIALOG";
