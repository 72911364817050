import React from "react";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

export default {
  alias: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ]
};
