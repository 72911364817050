import React from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "../../../../Common/Forms/Select";

const StateSelect = ({selected, onChange}) => {
  const [ states, setStates ] = React.useState([
    {id: 1, name: "Estado 1"},
    {id: 2, name: "Estado 2"},
    {id: 3, name: "Estado 3"}
  ]);
  return (
    <Select
      id="alerts-state-select"
      label={<IntlMessages id="logistic.monitoring.alerts.filters.state" />}
      value={selected}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value="">
        <IntlMessages id="logistic.monitoring.alerts.filters.state.all" />
      </MenuItem>
      {states.map(state => (
        <MenuItem value={state.id} key={`state-${state.id}`}>
          {state.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default StateSelect;
