import SelectEmployee from "../domain/SelectEmployee";
import axiosInstance from "../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "./StorageService";
import Employee from "../domain/Employee";
import Response from "../domain/Response";
import moment from "moment";
import toBase64 from "../utils/files";
import EmployeeDetail from "../domain/EmployeeDetail";

export const fetchAllEmployeesByEntity = async () => {
  try {
    const employees = await axiosInstance.post("/Empleado/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return employees.data.map(employee => SelectEmployee.map(employee));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEmployeesCounters = (foldersList, labelsList, employees) => {
  const counter = {folders: {}, labels: {}};
  foldersList.map(item => {
    if (item.slug === "starred") {
      counter.folders[item.id] = employees.filter(
        contact => contact.starred
      ).length;
    } else {
      counter.folders[item.id] = employees.filter(
        contact => !contact.starred
      ).length;
    }
    return null;
  });

  labelsList.map(item => {
    counter.labels[item.id] = employees.filter(contact =>
      contact.labels.includes(item.id)
    ).length;
    return null;
  });

  return counter;
};

export const getAllEmployeesByFilter = async filters => {
  try {
    const {data} = await axiosInstance.post("/Empleado/sel_bandeja", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: getUserId()
    });
    const contactsList = data.map(employee => Employee.map(employee));
    const {selectedFolder, selectedLabel, searchText} = filters;
    let folderContacts = [];
    if (searchText) {
      folderContacts = contactsList.filter(
        contact =>
          contact.name.toLowerCase().includes(searchText.toLowerCase()) ||
          contact.generalPhone.includes(searchText)
      );
    }
    if (selectedFolder) {
      if (selectedFolder === "starred") {
        folderContacts = contactsList.filter(contact => contact.starred);
      } else {
        folderContacts = contactsList.filter(contact => contact);
      }
    }

    if (selectedLabel) {
      folderContacts = contactsList.filter(contact =>
        contact.labels.includes(Number(selectedLabel))
      );
    }

    const total = contactsList.length;
    return {folderContacts, total};
  } catch (e) {
    return Promise.reject(e);
  }
};

export const markFavoriteEmployee = async employeeId => {
  try {
    const {data} = await axiosInstance.post("/Empleado/ins_favorito", {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empleado: employeeId
    });
    const response = Response.map(data);
    if (response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const unmarkFavoriteEmployee = async employeeId => {
  try {
    const {data} = await axiosInstance.post("/Empleado/del_favorito", {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empleado: employeeId
    });
    const response = Response.map(data);
    if (response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateEmployeeTag = async (
  employees,
  tagId,
  deleteTag = false
) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Empleado/ins_empresa_empleado_tag", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tag: tagId,
      ls_empleados: employees.map(employee => ({
        nu_id_empleado: employee,
        bi_eliminar: deleteTag
      }))
    });
    const response = Response.map(data);
    if (response.transactionState === "1" || response.transactionState === 1) {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEmployeeGeneralDetails = async employeeId => {
  try {
    const {data} = await axiosInstance.post("/Empleado/get_ficha_empleado", {
      nu_id_entidad: employeeId
    });
    return EmployeeDetail.map(data, employeeId);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEmployeeSpecificDetails = async (employeeId, employee) => {
  try {
    const {data} = await axiosInstance.post("/Empleado/get_empleado_empresa", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: employeeId
    });
    return employee.setSpecificData(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getEmployeeFiles = async (employeeId, employee) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Empleado/sel_documentos_empleado", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: employeeId
    });
    return employee.setFiles(
      employeeId === 0 ? "generalFiles" : "specificFiles",
      data
    );
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateEmployeeGeneralData = async employee => {
  try {
    const {data} = await axiosInstance.post("/Empleado/upd_ficha_empleado", {
      nu_id_entidad: employee.id,
      dt_fecnacimiento: moment(employee.birthDate).toISOString(),
      vc_nconducir: employee.driverLicense,
      vc_ibutton: employee.generalIButton,
      vc_nif: employee.nif,
      vc_apellidos: employee.lastName,
      vc_email: employee.email,
      vc_telefono: employee.generalPhone,
      vc_nombre: employee.firstName
    });
    const response = Response.map(data);
    if (response.transactionState === 1 || response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateEmployeeSpecificData = async employee => {
  try {
    const {data} = await axiosInstance.post("/Empleado/upd_empleado_empresa", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: employee.id,
      nu_id_externo: employee.internalCode,
      bi_estado: employee.active,
      vc_ibutton: employee.specificIButton,
      vc_telefono: employee.specificPhone,
      vc_email: employee.specificEmail,
      nu_id_fichaje_tipo: employee.contract,
      dt_fec_alta: moment(employee.commencementDate).toISOString(),
      dt_fec_baja: moment(employee.terminationDate).toISOString()
    });
    const response = Response.map(data);
    if (response.transactionState === 1 || response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createOrUpdateFile = async (
  fileId,
  employeeId,
  file,
  general = false
) => {
  try {
    const base64File = await toBase64(file.file).catch(error => {
      throw new Error(error.message);
    });
    const {
      data
    } = await axiosInstance.post("/Empleado/ins_upd_documento_empleado", {
      nu_id_documento: fileId,
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: general ? 0 : employeeId,
      vc_desc_documento: file.name,
      vc_desc_fichero: fileId === 0 ? "" : file.link,
      dt_fec_caducidad_documento: moment(file.expirationDate).toISOString(),
      archivo: {
        archivobase64: base64File.split(",")[1],
        contentType: file.type,
        extension: file.name.split(".")[1],
        nombre: file.name
      }
    });
    const response = Response.map(data);
    if (response.transactionState === 1 || response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteEmployeeFile = async fileId => {
  try {
    const {
      data
    } = await axiosInstance.post("/Empleado/del_documento_empleado", {
      nu_id_documento: fileId
    });
    const response = Response.map(data);
    if (response.transactionState === 1 || response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateEmployeePhoto = async (file, employeeId) => {
  try {
    const base64File = await toBase64(file);
    const {data} = await axiosInstance.post("/Empleado/upd_image_empleado", {
      nu_id_entidad_empleado: employeeId,
      archivo: {
        archivobase64: base64File.split(",")[1],
        contentType: file.type,
        extension: file.name.split(".")[1],
        nombre: file.name
      }
    });
    const response = Response.map(data);
    if (response.transactionState === 1 || response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addEmployeeByNif = async nif => {
  try {
    const {data} = await axiosInstance.post("/Empleado/ins_empleado_empresa", {
      nu_id_entidad: getCompanyEntityId(),
      vc_nif: nif
    });
    const response = Response.map(data);
    if (
      response.transactionState === 1 ||
      response.transactionState === "1" ||
      response.transactionKey === "0"
    ) {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addEmployee = async employee => {
  try {
    const {data} = await axiosInstance.post("/Empleado/ins_empleado", {
      nu_id_entidad: getCompanyEntityId(),
      vc_cif_codigo: employee.nif,
      vc_nombre: employee.name,
      vc_cif_pais: employee.countryNif
    });
    const response = Response.map(data);
    if (response.transactionState === 1 || response.transactionState === "1") {
      return response;
    } else {
      throw new Error(response.transactionMessage);
    }
  } catch (e) {
    return Promise.reject(e);
  }
};
