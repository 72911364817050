class AssigmentVehicle {
  constructor(id, entityId, vehicle, capacity) {
    this.id = id;
    this.entityId = entityId;
    this.vehicle = vehicle;
    this.capacity = capacity;
  }

  static map = vehicle => {
    return new AssigmentVehicle(
      vehicle.nu_id_vehiculo,
      vehicle.nu_id_entidad,
      vehicle.vc_placa,
      vehicle.nu_cargapeso || 0
    );
  };
}

export default AssigmentVehicle;
