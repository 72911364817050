const INIT_STATE = {
  isLoadingData: false,
  rejectingId: null,
  isRejectExpenseDialogOpen: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_SUPERVISOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "OPEN_SUPERVISOR_REJECT_EXPENSE_DIALOG": {
      return {
        ...state,
        rejectingId: action.payload.id,
        isRejectExpenseDialogOpen: true
      };
    }
    case "CLOSE_SUPERVISOR_REJECT_EXPENSE_DIALOG": {
      return {
        ...state,
        rejectingId: null,
        isRejectExpenseDialogOpen: false
      };
    }
    case "SUPERVISOR_FETCH_STARTED": {
      return {
        ...state,
        isLoadingData: true
      };
    }
    case "SUPERVISOR_FETCH_SUCCEEDED": {
      return {
        ...state,
        isLoadingData: false
      };
    }
    case "SUPERVISOR_FETCH_FAILED": {
      return {
        ...state,
        isLoadingData: false
      };
    }
    case "SUPERVISOR_FETCH_CANCELLED": {
      return {
        ...state,
        isLoadingData: false
      };
    }
    default: {
      return state;
    }
  }
};
