import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/ExpenseTypes/PageContainer";
import CreateExpenseTypeDialog from "components/Administrator/ExpenseTypes/SaveExpenseTypeDialog/CreateExpenseTypeDialog";
import EditExpenseTypeDialog from "components/Administrator/ExpenseTypes/SaveExpenseTypeDialog/EditExpenseTypeDialog";
import DeleteConfirmation from "components/Administrator/ExpenseTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/ExpenseTypes/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/ExpenseTypes/Table";
import Filters from "components/Administrator/ExpenseTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";

import useUiState from "hooks/Administrator/useUiState";

import expenseTypes from "redux/actions/Administrator/ExpenseTypes";
import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";

const ExpenseTypes = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(expenseTypes.get());
      return () => dispatch(expenseTypes.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(expenseTypes.applyFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(expenseTypes.resetFilters());

  const onDelete = () => dispatch(expenseTypes.delete(ui.deletingId));
  const onRestore = () => dispatch(expenseTypes.restore(ui.restoringId));
  const onEdit = data => dispatch(expenseTypes.edit(ui.editingId, data));
  const onCreate = data => dispatch(expenseTypes.create(data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateExpenseTypeDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditExpenseTypeDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.expenseTypes;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("code", filters.code)
    .byFieldIncludes("description", filters.description)
    .byFieldIncludes("accountingAccount", filters.accountingAccount)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(ExpenseTypes);
