export const mapToBoolean = value => {
  if (value === undefined || value === null) {
    return false;
  }
  if (typeof value === "boolean") {
    return value;
  }
  return (
    value === 1 ||
    value === true ||
    value === "1" ||
    value.toLowerCase() === "true"
  );
};
