import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import {
  calculateDaysOfDifferenceBetweenDates,
  parseDateFromInput,
  parseOnlyDateFromInput, parseToDateTimeFromInput
} from "../../utils/dates";
import ElevatorRow from "../../domain/ElevatorsManagement/ElevatorRow";
import Elevator from "../../domain/ElevatorsManagement/Elevator";

export const getAllElevators = async filters => {
  try {
    const {data} = await axiosInstance.post("/Ascensores/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(elevator => ElevatorRow.map(elevator));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getElevatorById = async elevator => {
  try {
    const {data} = await axiosInstance.post("/Ascensores/get", {
      nu_id_ficha: elevator.id
    });
    return Elevator.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createElevator = async elevator => {
  try {
    const {data} = await axiosInstance.post("/Ascensores/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_cliente: elevator.client,
      nu_id_entidad_cliente_sede: elevator.headquarter,
      vc_nombre: elevator.name,
      nu_id_tipo_modelo: elevator.model,
      vc_ken: elevator.ken,
      nu_id_tipo_categoria: elevator.category,
      nu_nro_paradas: elevator.stops,
      dt_fec_entrega: elevator.shipmentDate,
      bi_emergencia: elevator.emergency,
      nu_id_tipo_genero: elevator.genre,
      nu_id_tipo_fabricante: elevator.manufacturer,
      nu_id_tipo: elevator.type,
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevator = async elevator => {
  try {
    const {data} = await axiosInstance.post("/Ascensores/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_ficha: elevator.id,
      nu_id_entidad_cliente: elevator.client,
      nu_id_entidad_cliente_sede: elevator.headquarter,
      vc_nombre: elevator.name,
      nu_id_tipo_modelo: elevator.model,
      vc_ken: elevator.ken,
      nu_id_tipo_categoria: elevator.category,
      nu_nro_paradas: elevator.stops,
      dt_fec_entrega: elevator.shipmentDate,
      bi_emergencia: elevator.emergency,
      nu_id_tipo_contrato: elevator.type,
      nu_precio_mantenimiento: elevator.price,
      nu_id_moneda: elevator.currency,
      dt_fec_inicio_mantenimiento: elevator.freeMaintenanceStartDate,
      dt_fec_fin_mantenimiento: elevator.freeMaintenanceEndDate,
      nu_duracion_mantenimiento:
        elevator.freeMaintenanceDuration ||
        calculateDaysOfDifferenceBetweenDates(
          elevator.freeMaintenanceStartDate,
          elevator.freeMaintenanceEndDate
        ),
      dt_fec_inicio_garantia: elevator.warrantyStartDate,
      dt_fec_fin_garantia: elevator.warrantyEndDate,
      nu_duracion_garantia:
        elevator.warrantyDuration ||
        calculateDaysOfDifferenceBetweenDates(
          elevator.warrantyStartDate,
          elevator.warrantyEndDate
        ),
      dt_fec_inicio_contrato: elevator.startDate,
      dt_fec_fin_contrato: elevator.endDate,
      nu_duracion_contrato:
        elevator.duration ||
        calculateDaysOfDifferenceBetweenDates(
          elevator.startDate,
          elevator.endDate
        ),
      nu_id_tipo_estado: elevator.state,
      nu_id_tipo_detalle: elevator.detail,
      vc_motivo_suspension: elevator.reason,
      nu_id_entidad_analista: elevator.analyst,
      nu_id_entidad_supervisor: elevator.supervisor,
      nu_id_entidad_tecnico: elevator.technical,
      ls_contactos: elevator.contactList.map(c => ({
        vc_nombre: c.name,
        vc_email: c.email,
        vc_telefono: c.phone
      })),
      nu_id_tipo_genero: elevator.genre,
      nu_id_tipo_fabricante: elevator.manufacturer,
      nu_id_tipo: elevator.type,
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
