import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const PeriodSelect = ({value, onChange}) => {
  const options = [
    {
      id: 1,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.personalized"
          }
        />
      )
    },
    {
      id: 2,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.yesterday"
          }
        />
      )
    },
    {
      id: 3,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.last.week"
          }
        />
      )
    },
    {
      id: 4,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.last.month"
          }
        />
      )
    },
    {
      id: 5,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.last.seven.days"
          }
        />
      )
    },
    {
      id: 6,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.last.fifteen.days"
          }
        />
      )
    },
    {
      id: 7,
      description: (
        <IntlMessages
          id={
            "control.panel.kpi.areas.grid.tachograph.filters.period.last.thirty.days"
          }
        />
      )
    },
  ];
  return (
    <Select
      label={
        <IntlMessages
          id={"control.panel.kpi.areas.grid.tachograph.filters.period"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      {options.map(o => (
        <MenuItem key={o.id} value={o.id}>
          {o.description}
        </MenuItem>
      ))}
    </Select>
  );
};

PeriodSelect.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PeriodSelect;
