import {
  CLOSE_KPI_DIALOG,
  FETCH_USER_KPI_REPORTS_LISTS,
  OPEN_KPI_DIALOG,
  REMOVE_FAVORITE_REPORT,
  REMOVE_FILED_REPORT,
  REMOVE_SCHEDULED_REPORT,
  RESET_CURRENT_KPI_REPORT,
  RESET_KPI_STATE,
  SET_CURRENT_KPI_REPORT,
  SET_CURRENT_KPI_REPORT_FILE,
  SET_CURRENT_KPI_REPORT_FILTERS
} from "../../types/ControlPanel/KpiTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  addFavoriteReport,
  addFiledReport,
  addLastConsultReport,
  addScheduledReport,
  deleteFavoriteReport,
  deleteFiledReport,
  deleteScheduledReport,
  fetchFavoritesReports,
  fetchFiledReports,
  fetchLastConsultsReports,
  fetchScheduledReports
} from "../../../services/ControlPanel/Kpi/Reports/KpiReportServices";
import {getReportFile, sendEmailService} from "../../../services/ControlPanel/Kpi/ReportsService";

export const resetKpiState = () => ({type: RESET_KPI_STATE});
export const resetCurrentReport = () => ({type: RESET_CURRENT_KPI_REPORT});

export const openKpiDialog = name => ({
  type: OPEN_KPI_DIALOG,
  payload: name
});
export const closeKpiDialog = name => ({
  type: CLOSE_KPI_DIALOG,
  payload: name
});
export const fetchAllUserReportsLists = () => {
  return async dispatch => {
    dispatch(fetchStart());
    Promise.allSettled([
      fetchLastConsultsReports(),
      fetchFavoritesReports(),
      fetchScheduledReports(),
      fetchFiledReports()
    ])
      .then(([ lastConsults, favorites, scheduled, filed ]) => {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_USER_KPI_REPORTS_LISTS,
          payload: {
            lastConsults: lastConsults.status !== 'rejected'? lastConsults.value : [],
            favorites: favorites.status !== 'rejected'? favorites.value : [],
            scheduled: scheduled.status !== 'rejected'? scheduled.value : [],
            filed: filed.status !== 'rejected'? filed.value : []
          }
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const setCurrentKpiReportFilters = filters => ({
  type: SET_CURRENT_KPI_REPORT_FILTERS,
  payload: filters
});

export const setCurrentReport = (typeId, filters = null, {reportId = 0, title = ''}) => {
  return async dispatch => {
    dispatch({
      type: SET_CURRENT_KPI_REPORT,
      payload: {id: reportId, type: Number(typeId), title}
    });
    if (reportId === 0) {
      dispatch({type: SET_CURRENT_KPI_REPORT_FILTERS, payload: filters});
    } else {
      dispatch({type: SET_CURRENT_KPI_REPORT_FILTERS, payload: filters});
    }
  };
};
export const setCurrentReportFile = (serviceId, filters, type = 1) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const file = await getReportFile(serviceId, type, filters);
      dispatch(fetchSuccess());
      dispatch({
        type: SET_CURRENT_KPI_REPORT_FILE,
        payload: {file, fileType: type}
      });
      dispatch(setCurrentKpiReportFilters(filters));
      dispatch(createLastConsultReport(serviceId, filters));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const createLastConsultReport = (serviceId, filters) => {
  return async dispatch => {
    dispatch(fetchStart());
    addLastConsultReport(serviceId, filters)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({
          type: SET_CURRENT_KPI_REPORT,
          payload: {id: response.transactionKey}
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const createFavoriteReport = (serviceId, filters, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    addFavoriteReport(serviceId, filters, data.name)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const createScheduledReport = (serviceId, filters, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    addScheduledReport(serviceId, filters, data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const createFiledReport = (reportId, filters, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    addFiledReport(reportId, filters, data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const removeFavoriteReport = reportId => {
  return async dispatch => {
    dispatch(fetchStart());
    deleteFavoriteReport(reportId)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: REMOVE_FAVORITE_REPORT, payload: reportId});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const removeScheduledReport = reportId => {
  return async dispatch => {
    dispatch(fetchStart());
    deleteScheduledReport(reportId)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: REMOVE_SCHEDULED_REPORT, payload: reportId});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const removeFiledReport = reportId => {
  return async dispatch => {
    dispatch(fetchStart());
    deleteFiledReport(reportId)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: REMOVE_FILED_REPORT, payload: reportId});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};


export const sendEmail = (emailData, reportId, filters) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const {message} = await sendEmailService(emailData, reportId, filters);
      dispatch(fetchSuccess(message));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}