import React from 'react';
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllElevatorsManufacturers} from "../../../../../../../services/ElevatorsManagement/ManufacturersService";

const ManufacturerSelect = ({value, setValue, disabled}) => {
    const {data: manufacturers} = useFetch(getAllElevatorsManufacturers, {}, []);
    return (
        <Select
            value={value}
            disabled={disabled}
            onChange={e => setValue(e.target.value)}
            label={<IntlMessages id="manufacturer"/>}
            fullWidth
        >
            {manufacturers
                ?.filter(manufacturer => manufacturer.state)
                .map(manufacturer => (
                    <MenuItem value={manufacturer.id} key={manufacturer.id}>
                        {manufacturer.name}
                    </MenuItem>
                ))
            }
        </Select>
    );
};

export default ManufacturerSelect;