import {mapToBoolean} from "../../utils/mappers";

class ElevatorCategory {
  constructor(id, name, state) {
    this.id = id;
    this.name = name;
    this.state = state;
  }

  static map = category => {
    return new ElevatorCategory(
      category.nu_id_tipo_categoria,
      category.vc_desc_tipo_categoria,
      mapToBoolean(category.bi_estado)
    );
  };
}

export default ElevatorCategory;
