import ui, {changeStateStarted} from "./ui";
import {
  cancelPrevFetchAllReceiversByAdministratorCancelToken,
  changeReceiverState,
  createReceiver,
  fetchAllReceiversByAdministrator,
  updateReceiver
} from "../../../services/ReceiverService";
import {
  cancelPrevFetchAllTags,
  fetchAllTags
} from "../../../services/TagService";
import {
  cancelPrevFetchAllEntities,
  fetchAllEntities
} from "../../../services/EntityService";
import {
  RECEIVERS_APPLY_FILTERS,
  RECEIVERS_BY_ADMINISTRATOR_FETCH_ALL_SUCCEEDED,
  RECEIVERS_CHANGE_STATE,
  RECEIVERS_CREATE,
  RECEIVERS_EDIT,
  RECEIVERS_RESET_FILTERS
} from "../../types/Administrator/ReceiversCustomTypes";
import {getCompanyEntityId} from "../../../services/StorageService";
import {clearEntities} from "../CustomCommon/Entities";
import {clearTags} from "../CustomCommon/Tags";
import {restoreAdministratorState} from "./Common";

const fetchAllReceiversByAdministratorSucceeded = receivers => {
  return {
    type: RECEIVERS_BY_ADMINISTRATOR_FETCH_ALL_SUCCEEDED,
    payload: {data: receivers}
  };
};

export const fetchAllReceiversByAdministratorStarted = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevFetchAllTags();
    cancelPrevFetchAllReceiversByAdministratorCancelToken();
    try {
      const tags = await fetchAllTags();
      const entities = await fetchAllEntities();
      const receivers = await fetchAllReceiversByAdministrator();
      dispatch(ui.fetchSucceeded());
      dispatch({type: "TAGS_FETCH_ALL_SUCCEEDED", payload: {data: tags}});
      dispatch({
        type: "ENTITIES_FETCH_ALL_SUCCEEDED",
        payload: {data: entities}
      });
      dispatch(fetchAllReceiversByAdministratorSucceeded(receivers));
    } catch (error) {
      dispatch(ui.fetchFailed());
    }
  };
};

export const applyReceiversFilters = filters => ({
  type: RECEIVERS_APPLY_FILTERS,
  payload: {filters}
});
export const resetReceiversFilters = () => ({type: RECEIVERS_RESET_FILTERS});

const changeReceiverStateSucceeded = id => {
  return dispatch => {
    dispatch({type: RECEIVERS_CHANGE_STATE, payload: {id}});
    dispatch(ui.changeStateSucceeded());
  };
};

const changeReceiverStateStarted = id => {
  return async dispatch => {
    dispatch(changeStateStarted());
    try {
      await changeReceiverState(id);
      dispatch(changeReceiverStateSucceeded(id));
    } catch (error) {
      dispatch(ui.changeStateFailed());
    }
  };
};

export const deleteReceiver = id => {
  return dispatch => {
    dispatch(changeReceiverStateStarted(id));
    dispatch(ui.closeDeleteConfirmation());
  };
};

export const restoreReceiver = id => dispatch => {
  dispatch(changeReceiverStateStarted(id));
  dispatch(ui.closeRestoreConfirmation());
};

const editReceiverSucceeded = (id, data) => {
  return dispatch => {
    dispatch({
      type: RECEIVERS_EDIT,
      payload: {
        id,
        data: {tags: data.tags.map(tag => tag.name)},
        code: data.code
      }
    });
    dispatch(ui.editSucceeded());
  };
};

export const editReceiverStarted = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await updateReceiver(id, data);
    dispatch(editReceiverSucceeded(id, data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const createReceiverSucceeded = (id, data) => {
  return dispatch => {
    dispatch({
      type: RECEIVERS_CREATE,
      payload: {
        id,
        entityId: getCompanyEntityId(),
        names: data.receiver.name,
        tags: data.tags.map(tag => tag.name),
        code: data.code,
        active: true
      }
    });
    dispatch(ui.createSucceeded());
  };
};

export const createReceiverStarted = data => {
  return async dispatch => {
    dispatch(ui.closeCreateForm());
    dispatch(ui.createStarted());
    try {
      const {id} = await createReceiver(data);
      dispatch(createReceiverSucceeded(id, data));
    } catch (error) {
      dispatch(ui.createFailed());
      dispatch(ui.setOnErrorMessage(error.message));
    }
  };
};

const cancelAllRequests = () => {
  cancelPrevFetchAllReceiversByAdministratorCancelToken();
  cancelPrevFetchAllTags();
  cancelPrevFetchAllEntities();
  return ui.fetchCancelled();
};

export const resetReceiversState = () => dispatch => {
  dispatch(cancelAllRequests());
  dispatch(clearEntities());
  dispatch(clearTags());
  dispatch(restoreAdministratorState());
};
