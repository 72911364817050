import React from "react";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer
} from "@material-ui/core";
import Checkbox from "../../../../../Common/Forms/Checkbox";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const VehiclesTable = ({vehicles, isDialog = false, setSelectedVehicles}) => (
  <TableContainer
    component={Paper}
    style={{
      width: "100%"
    }}
  >
    <Table>
      <TableHeader />
      <TableBody>
        {vehicles.map(vehicle => (
          <StyledTableRow key={vehicle.id}>
            <StyledTableCell>{vehicle.vehicle}</StyledTableCell>
            <StyledTableCell>{vehicle.capacity}</StyledTableCell>
            <StyledTableCell>
              {isDialog ? (
                <Checkbox
                  checked={vehicle.checked}
                  onChange={() => {
                    setSelectedVehicles &&
                      setSelectedVehicles(
                        vehicles.map(v => {
                          if (v.id === vehicle.id) {
                            return {...v, checked: !v.checked};
                          } else {
                            return {...v};
                          }
                        })
                      );
                  }}
                />
              ) : (
                <IconButton
                  onClick={() => {
                    setSelectedVehicles &&
                      setSelectedVehicles(
                        vehicles.filter(v => vehicle.id !== v.id)
                      );
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default VehiclesTable;
