import {FETCH_WAREHOUSE_TRANSFER_SELECT} from "redux/types/Administrator/WarehouseTransferSelect";

const INIT_STATE = {
  data: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_WAREHOUSE_TRANSFER_SELECT:
      return {
        ...state,
        data: action.payload.data
      };
    default:
      return state;
  }
};
