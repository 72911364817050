import {withStyles} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

export const classes = {
  containerStyle: {
    margin: "16px",
    width: "100%",
    height: "97%"
  },
  pinSuccess: {
    borderColor: "#32a852"
  },
  pinNotArrived: {
    borderColor: "#eb4034"
  },
  markerDetailBox: {
    border: "none"
  }
};

const K_WIDTH = 40;
const K_HEIGHT = 40;

export const LocationMarker = withStyles(theme => ({
  root: {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,
    cursor: "pointer",
    border: "5px solid #f44336",
    borderRadius: K_HEIGHT,
    backgroundColor: "white",
    textAlign: "center",
    color: "#3f51b5",
    fontSize: 16,
    fontWeight: "bold",
    padding: 4
  }
}))(RoomIcon);
