import {
    ADD_WALL_MAP,
    CLOSE_DIALOG_WALL_MAP,
    DELETE_WALL_MAP,
    EDIT_WALL_MAP, FETCH_ALL_WALL_MAP_GEO,
    FETCH_ALL_WALL_MAPS,
    OPEN_DIALOG_WALL_MAP,
    RESET_WALL_MAP_STATE,
    SET_CURRENT_WALL_MAP
} from "../../types/Localization/WallMapTypes";



const INIT_STATE = {
    data: [],
    current: null,
    dialogs: [],
    vehiclesGeo: []
};
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_ALL_WALL_MAPS: {
            let list = {};
            action.payload.map(u => {
                list[u.id] = u;
                return null;
            });
            return {
                ...state,
                data: list
            };
        }
        case SET_CURRENT_WALL_MAP: {
            return {
                ...state,
                current: action.payload
            };
        }
        case FETCH_ALL_WALL_MAP_GEO: {
            return {
                ...state,
                vehiclesGeo: action.payload,
            }
        }
        case ADD_WALL_MAP: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                }
            };
        }
        case EDIT_WALL_MAP: {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                }
            };
        }
        case DELETE_WALL_MAP: {
            const data = state.data;
            delete data[action.payload.id];
            return {
                ...state,
                data,
            };
        }
        case OPEN_DIALOG_WALL_MAP: {
            return {
                ...state,
                dialogs: [
                    ...state.dialogs,
                    action.payload
                ]
            };
        }
        case CLOSE_DIALOG_WALL_MAP: {
            return {
                ...state,
                dialogs: state.dialogs.filter(d => d !== action.payload),
            };
        }
        case RESET_WALL_MAP_STATE: {
            return {
                ...state,
                ...INIT_STATE
            };
        }
        default: {
            return state;
        }
    }
};
