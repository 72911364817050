import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import ElevatorContract from "../../domain/ElevatorsManagement/ElevatorContract";

export const getAllElevatorsContracts = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Contrato/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(contract => ElevatorContract.map(contract));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createElevatorContract = async contract => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Contrato/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_contrato: contract.name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorContract = async (contract, name) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Contrato/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_contrato: contract.id,
      vc_desc_tipo_contrato: name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorContractState = async (contract, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Contrato/del", {
      nu_id_tipo_contrato: contract.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
