import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const CheckInOutDetail = ({realTime, final = false}) => {
  return (
    <GridContainer spacing={0}>
      <Grid item xs={12}>
        <GridContainer>
          <Grid item xs={2}>
            {realTime.inTime ? (
              <CheckCircleIcon style={{color: "green"}} />
            ) : (
              <CancelIcon style={{color: "red"}} />
            )}
          </Grid>
          <Grid item xs={10}>
            <p>
              <IntlMessages
                id={"administrator.ot.breakdown.destination.time.window"}
              />: {realTime.timeWindow}
            </p>
          </Grid>
        </GridContainer>
      </Grid>
      <Grid item xs={12}>
        <GridContainer>
          <Grid item xs={2} />
          <Grid item xs={10}>
            {final ? (
              <p>
                <IntlMessages
                  id={"administrator.ot.breakdown.destination.time.check.in"}
                />: {realTime.arrivalTime}
              </p>
            ) : (
              <p>
                <IntlMessages
                  id={
                    "administrator.ot.breakdown.destination.time.check.in.out"
                  }
                />: {realTime.arrivalOutTime}
              </p>
            )}
          </Grid>
        </GridContainer>
      </Grid>
    </GridContainer>
  );
};

export default CheckInOutDetail;
