class Incident {
  constructor(
    id,
    date,
    idEntityOrigin,
    idCampusOrigin,
    idEntityDestiny,
    idCampusDestiny,
    entityNameOrigin,
    campusNameOrigin,
    warehouseNameOrigin,
    entityNameDestiny,
    campusNameDestiny,
    warehouseNameDestiny,
    idWarehouseOrigin,
    idWarehouseDestiny,
    active,
    transfer
  ) {
    this.id = id;
    this.date = date;
    this.idEntityOrigin = idEntityOrigin;
    this.idCampusOrigin = idCampusOrigin;
    this.idEntityDestiny = idEntityDestiny;
    this.idCampusDestiny = idCampusDestiny;
    this.entityNameOrigin = entityNameOrigin;
    this.campusNameOrigin = campusNameOrigin;
    this.warehouseNameOrigin = warehouseNameOrigin;
    this.entityNameDestiny = entityNameDestiny;
    this.campusNameDestiny = campusNameDestiny;
    this.warehouseNameDestiny = warehouseNameDestiny;
    this.idWarehouseOrigin = idWarehouseOrigin;
    this.idWarehouseDestiny = idWarehouseDestiny;
    this.active = active;
    this.transfer = transfer;
  }

  static map = incident => {
    return new Incident(
      incident.nu_id_movimiento,
      incident.dt_fec_incidencia,
      incident.nu_id_entidad_orig,
      incident.nu_id_entidad_sede_orig,
      incident.nu_id_entidad_dest,
      incident.nu_id_entidad_sede_dest,
      incident.vc_nombre_entidad_orig,
      incident.vc_nombre_sede_orig,
      incident.vc_desc_almacen_orig,
      incident.vc_nombre_entidad_dest,
      incident.vc_nombre_sede_dest,
      incident.vc_desc_almacen_dest,
      incident.nu_id_almacen_orig,
      incident.nu_id_almacen_dest,
      incident.bi_estado,
      incident.ls_detalle
    );
  };
}

export default Incident;
