import {
  APPLY_FILES_FILTERS,
  FETCH_ALL_FILES,
  RESET_FILES_FILTERS,
  RESET_FILES_STATE
} from "../../types/FleetManagement/FilesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  createIndividualTachographFile,
  createZipTachographFile,
  getAllTachographFiles
} from "../../../services/FleetManagement/Tachograph/TachographsFilesService";

export const resetTachographFiles = () => ({type: RESET_FILES_STATE});
export const fetchAllTachographFiles = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllTachographFiles(filters)
      .then(files => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_FILES, payload: files});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const applyTachographFilesFilters = filters => ({
  type: APPLY_FILES_FILTERS,
  payload: filters
});
export const resetTachographFilesFilters = () => ({type: RESET_FILES_FILTERS});

export const deleteTachographFiles = (deleteFiles, files) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      const newFiles = files.filter(f => !deleteFiles.includes(f.id));
      dispatch({type: FETCH_ALL_FILES, payload: newFiles});
    }, 1500);
  };
};

export const addIndividualTachographFile = (file, filters) => {
  return async dispatch => {
    dispatch(fetchStart());
    createIndividualTachographFile(file)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllTachographFiles(filters));
        }, 2000);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addZipTachographFile = (file, filters) => {
  return async dispatch => {
    dispatch(fetchStart());
    createZipTachographFile(file)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllTachographFiles(filters));
        }, 2000);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
