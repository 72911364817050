import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  createTagStarted,
  editTagStarted,
  resetCurrentTagType,
  setCurrentTagType
} from "../../../../redux/actions/Administrator/Tags";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import {Box} from "@material-ui/core";
import DetailTable from "./DetailTable";
import TagsTable from "./TagsTable";
import FloatingButtonWithAdd from "../../../Common/Buttons/FloatingButtonWithAdd";
import ErrorAlert from "../../Common/Alerts/ErrorAlert";
import AddTagDialog from "./SaveTagDialog/AddTagDialog";
import EditTagDialog from "./SaveTagDialog/EditTagDialog";

const TagListDialog = () => {
  const dispatch = useDispatch();
  const {current, data} = useSelector(state => state.administrator.tags);
  const handleClose = () => {
    dispatch(resetCurrentTagType());
  };
  const onAddTag = data => {
    dispatch(createTagStarted(data, current));
    setOpenAddDialog(false);
  };
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  const [ initialData, setInitialData ] = React.useState(undefined);
  const onOpenEditDialog = tag => {
    setOpenEditDialog(true);
    setInitialData(current.tags.find(h => h.id === tag.id));
  };
  const onCloseDialog = () => {
    setOpenEditDialog(false);
    setInitialData(undefined);
  };
  const onEditTag = data => {
    dispatch(editTagStarted(data));
    onCloseDialog();
  };
  React.useEffect(
    () => {
      if (data.length > 0 && current) {
        dispatch(setCurrentTagType(data.find(t => t.id === current.id)));
      }
    },
    [ data ]
  );
  return (
    <FullScreenDialog
      open={current !== null}
      handleClose={handleClose}
      title={"administrator.tag.list.title"}
    >
      {current && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <DetailTable type={current} />
          <TagsTable tags={current.tags} onOpenEditDialog={onOpenEditDialog} />
          <AddTagDialog
            onSubmit={onAddTag}
            isOpen={openAddDialog}
            onClose={() => setOpenAddDialog(false)}
          />
          <EditTagDialog
            onSubmit={onEditTag}
            isOpen={openEditDialog}
            onClose={() => onCloseDialog()}
            initialData={initialData}
          />
          <FloatingButtonWithAdd onAddClicked={() => setOpenAddDialog(true)} />
          <ErrorAlert />
        </Box>
      )}
    </FullScreenDialog>
  );
};

export default TagListDialog;
