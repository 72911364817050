import React, {useEffect, useState} from "react";
import IntlMessages from "../../../../../../../../../../@jumbo/utils/IntlMessages";
import {fetchAllRoutes} from "../../../../../../../../../../services/RouteService";
import MultipleSelect from "../../../../../../../../../Common/Forms/Select/MultipleSelect";

const RoutesSelect = ({values, onChange, error, hasError}) => {
  const [ routes, setRoutes ] = useState([]);
  useEffect(() => {
    fetchAllRoutes().then(routes => {
      setRoutes([ ...routes ]);
    });
  }, []);
  return (
    <MultipleSelect
      id="expenses-report-routes-filter"
      options={routes}
      value={values}
      onChange={onChange}
      fullWidth
      error={error}
      hasError={hasError}
      label={
        <IntlMessages id={"administrator.reports.expenses.routes.title"} />
      }
      headerText={
        <IntlMessages id="administrator.reports.expenses.routes.header" />
      }
      noOptionsText={
        <IntlMessages id="administrator.reports.expenses.routes.no.options" />
      }
    />
  );
};

export default RoutesSelect;
