import {mapToBoolean} from "../../utils/mappers";

class Vehicle {
  constructor(id, plaque, state, tags) {
    this.id = id;
    this.plaque = plaque;
    this.state = state;
    this.tags = tags;
  }

  static map = vehicle => {
    return new Vehicle(
      vehicle.nu_id_vehiculo,
      vehicle.vc_placa,
      mapToBoolean(vehicle.bi_estado),
      vehicle.tags.map(t => t.nu_id_tag)
    );
  };
}

export default Vehicle;
