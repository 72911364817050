import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/IncidenceTypes/PageContainer";
import CreateIncidenceTypeDialog from "components/Administrator/IncidenceTypes/SaveIncidenceTypeDialog/CreateIncidenceTypeDialog";
import EditIncidenceTypeDialog from "components/Administrator/IncidenceTypes/SaveIncidenceTypeDialog/EditIncideceTypeDialog";
import DeleteConfirmation from "components/Administrator/IncidenceTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/IncidenceTypes/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/IncidenceTypes/Table";
import Filters from "components/Administrator/IncidenceTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

import useUiState from "hooks/Administrator/useUiState";
import incidenceType from "redux/actions/Administrator/IncidenceTypes";

const IncidenceTypes = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(incidenceType.get());
      return () => dispatch(incidenceType.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(incidenceType.applyIncidenceTypeFilter(filters)),
    [ dispatch ]
  );
  const resetFilters = () =>
    dispatch(incidenceType.resetIncidenceTypeFilters());

  const onRestore = () => {
    dispatch(incidenceType.restore(ui.restoringId));
  };
  const onDelete = () => {
    dispatch(incidenceType.delete(ui.deletingId));
  };
  const onEdit = data => {
    dispatch(incidenceType.edit(ui.editingId, data));
  };
  const onCreate = data => {
    dispatch(incidenceType.create(data));
  };

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateIncidenceTypeDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditIncidenceTypeDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.incidenceTypes;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("description", filters.description)
    .byFieldIncludes("active", filters.active)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(IncidenceTypes);
