import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  tags: [
    {
      rule: v => v.length > 0,
      message: <IntlMessages id="administrator.form.tagsPicker.error.empty" />
    }
  ]
};
