class EmployeeFile {
  constructor(
    id,
    employeeId,
    entityId,
    name,
    expirationDate,
    link,
    file = null
  ) {
    this.id = id;
    this.employeeId = employeeId;
    this.entityId = entityId;
    this.name = name;
    this.expirationDate = expirationDate;
    this.link = link;
    this.file = file;
  }

  static map = file => {
    return new EmployeeFile(
      file.nu_id_documento,
      file.nu_id_entidad_empleado,
      file.nu_id_entidad,
      file.vc_desc_documento,
      file.dt_fec_caducidad_documento,
      file.vc_desc_fichero
    );
  };
}

export default EmployeeFile;
