import React from "react";
import PropTypes from "prop-types";

import Select from "..";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "@jumbo/utils/IntlMessages";

const VALUES = Object.freeze({
  ALL: "",
  YES: "YES",
  NO: "NO"
});

const getLabelFromValue = value => {
  switch (value) {
    case true: {
      return VALUES.YES;
    }
    case false: {
      return VALUES.NO;
    }
    default: {
      return VALUES.ALL;
    }
  }
};

const getValueFromLabel = label => {
  switch (label) {
    case VALUES.YES: {
      return true;
    }
    case VALUES.NO: {
      return false;
    }
    default: {
      return undefined;
    }
  }
};

const BooleanSelect = ({
  id,
  value,
  onChange,
  style,
  fullWidth,
  label,
  error,
  hasError
}) => {
  const selected = getLabelFromValue(value);

  return (
    <Select
      id={id}
      label={label}
      value={selected}
      onChange={e => onChange(getValueFromLabel(e.target.value))}
      style={style}
      error={hasError}
      errorMessage={error}
      fullWidth={fullWidth}
    >
      <MenuItem value={VALUES.ALL}>
        <IntlMessages id="common.forms.select.booleanSelect.option.all" />
      </MenuItem>
      <MenuItem value={VALUES.YES}>
        <IntlMessages id="common.forms.select.booleanSelect.option.yes" />
      </MenuItem>
      <MenuItem value={VALUES.NO}>
        <IntlMessages id="common.forms.select.booleanSelect.option.no" />
      </MenuItem>
    </Select>
  );
};

BooleanSelect.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOf([ undefined, true, false ]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  label: PropTypes.node,
  error: PropTypes.string,
  hasError: PropTypes.bool
};

BooleanSelect.defaultProps = {
  id: "",
  value: undefined,
  onChange: () => {},
  fullWidth: false,
  label: "",
  error: "",
  hasError: false
};

export default BooleanSelect;
