class Central {
  constructor(id, code, name, headquarter) {
    this.id = id;
    this.code = code;
    this.name = name;
    this.headquarter = headquarter;
  }

  static map = central => {
    return new Central(
      central.nu_id_centro,
      central.vc_cod_centro,
      central.vc_desc_centro,
      central.nu_id_entidad_sede
    );
  };
}

export default Central;
