import React from "react";
import PropTypes from "prop-types";
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {getAllDispatchesTypes} from "../../../../../../services/Logistics/Distribution/WorkOrderTypeService";

const DestinationTypeSelect = ({value, setValue, disabled}) => {
  const {data: types} = useFetch(getAllDispatchesTypes, {}, []);
  return (
    <Select
      value={value}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages id={"logistic.work.orders.dialog.add.destination.type"} />
      }
      fullWidth
      disabled={disabled}
    >
      {types.filter(t => t.status).map(type => (
        <MenuItem value={type.id} key={type.id}>
          {type.name}
        </MenuItem>
      ))}
    </Select>
  );
};

DestinationTypeSelect.propTypes = {
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired
};

export default DestinationTypeSelect;
