export const generateRoute = (wayPoints, saveCoords = null) => {
  const DirectionsService = new window.google.maps.DirectionsService();
  wayPoints.length > 1 &&
    DirectionsService.route(
      {
        origin: wayPoints[0].position,
        destination: wayPoints[wayPoints.length - 1].position,
        waypoints: wayPoints
          .slice(1, wayPoints.length - 1)
          .map(d => ({location: d.position})),
        travelMode: "DRIVING",
        provideRouteAlternatives: true,
        optimizeWaypoints: true
      },
      function(response, status){
        if (status === "OK") {
          const coords = response.routes[0].overview_path;
          saveCoords && saveCoords(coords);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
};
