import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/RRHH/PausesTypes/PageContainer";
import CreateNewPauseType from "components/RRHH/PausesTypes/Dialog/CreateNewPauseType";
import EditPauseType from "components/RRHH/PausesTypes/Dialog/EditPauseType";

import DeleteConfirmation from "components/RRHH/PausesTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/RRHH/PausesTypes/Confirmations/RestoreConfirmation";
import Table from "components/RRHH/PausesTypes/Table";
import Filters from "components/RRHH/PausesTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";

import PausesTypes from "redux/actions/RRHH/PausesTypes";
import useUiState from "hooks/Administrator/useUiState";

const PauseTypes = ({tableData, dispatch}) => {
  const ui = useUiState();
  useEffect(
    () => {
      dispatch(PausesTypes.get());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(PausesTypes.applyPausesTypesFilters(filters)),
    [ dispatch ]
  );

  const onCreate = data => dispatch(PausesTypes.create(data));
  const onDelete = () => dispatch(PausesTypes.delete(ui.deletingId));
  const onRestore = () => dispatch(PausesTypes.restore(ui.restoringId));
  const onEdit = data => dispatch(PausesTypes.edit(ui.editingId, data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;
  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateNewPauseType onSubmit={onCreate} {...ui.createFormProps} />
      <EditPauseType
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      {/* <ErrorAlert/> */}
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.rrhh.pausesTypes;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("stateType", filters.stateType)
    .build();

  return {tableData: filtered};
};

export default connect(mapStateToProps)(PauseTypes);
