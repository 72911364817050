import React from "react";
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import Form from "../AddDialog/Form";

const EditDialog = ({selectedClient, openEdit, setOpenEdit, onSave}) => {
  const id = "edit-elevator-contact-form";
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.contact.client.edit.title"
          }
        />
      }
      isOpen={openEdit}
      onClose={() => setOpenEdit(false)}
    >
      {openEdit && (
        <Form
          id={id}
          initData={{
            name: selectedClient.name,
            email: selectedClient.email,
            phone: selectedClient.phone
          }}
          onSubmit={onSave}
        />
      )}
    </FormDialog>
  );
};

export default EditDialog;
