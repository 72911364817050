import React from "react";
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {getAllVersions} from "../../../../../../../../services/FleetManagement/Vehicles/VersionService";

const VersionSelect = ({value, onChange, error, hasError, disabled, model}) => {
  const [ versions, setVersions ] = React.useState([]);
  React.useEffect(
    () => {
      model &&
        getAllVersions(model)
          .then(data => setVersions(data))
          .catch(e => console.error(e.message));
    },
    [ model ]
  );
  return (
    <Select
      id="vehicle-version-select"
      label={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.details.basic.version"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      error={hasError}
      errorMessage={error}
      fullWidth
      disabled={disabled}
    >
      {versions.map(version => (
        <MenuItem value={version.id} key={version.id}>
          {version.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default VersionSelect;
