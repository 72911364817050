export const dialogsList = Object.freeze({
    ADD_BILL: 'ADD_BILL',
    EDIT_BILL: 'EDIT_BILL',
});


export const billStates = Object.freeze({
    PAID: 'aceptado',
    PENDING: 'pendiente',
    CANCELER: 'anulado',
    BILLED: 'facturado',
});

export const billStatesColors = Object.freeze({
    [billStates.CANCELER]: "#ff0000",
    [billStates.PENDING]: "#6a6a6a",
    [billStates.PAID]: "#4caf50",
    [billStates.BILLED]: "#3f51b5",
});