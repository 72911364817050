import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';

import selectProduct from '../../../redux/actions/Administrator/ProductSelect';

const ProductSelect = ({id, label, hasError, error, value, onChange, warehouseId}) => {
    const {data} = useSelector(state => state.administrator.productSelect);
    const dispatch = useDispatch();
    useEffect(() => {
        const idWarehouse = warehouseId !== null ? warehouseId.value : 0;
        if(data.length === 0){
            dispatch(selectProduct.get(idWarehouse));
        }else{
            dispatch(selectProduct.get(idWarehouse));
        }
    },[warehouseId, dispatch])

    const options = [
        {value: '', label: 'Selecciones una opción', stock: -1},
        ...data.filter(type => type.active !== false).sort((a,b) => a.product > b.product ? 1 : -1).map(type=>({value: type.id, label: type.product, stock: type.stock})),
    ];

    return(
        <Select
            value={value?.value || ''}
            error={hasError}
            errorMessage={error}
            fullWidth
            id={id}
            label={label}
            onChange={e => onChange(options.find(option => option.value === e.target.value))}
            options={options}
        />
    );
};

export default ProductSelect