import React, {useCallback} from "react";
import {Box, IconButton} from "@material-ui/core";
import Filters from "./Filters";
import TableContainer from "../../../Common/Tables/TableContainer";
import {connect} from "react-redux";
import FilterBuilder from "../../../../utils/filters";
import {
    applyServicesFilters,
    deleteService,
    fetchAllServicesTableData,
    getCurrentLocation
} from "../../../../redux/actions/Logistic/ServicesList";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import {invertColor} from "../../../../utils/colorGenerate";
import BasePagination from "../../../Common/Tables/BasePagination";
import ButtonsArea from "./ButtonsArea";
import EditIcon from "@material-ui/icons/Edit";
import RoomIcon from "@material-ui/icons/Room";
import EditServiceDialog from "./EditServiceDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import Confirmation from "../../../Common/Dialogs/Confirmation";
import {useIntl} from "react-intl";

const ServicesTable = ({dispatch, data, apiFilters, headers}) => {
    const intl = useIntl();
  const [ selectedService, setSelectedService ] = React.useState(null);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  const [ page, setPage ] = React.useState(0);
  const [openConfirmation, setOpenConfirmation] = React.useState(null);
  const applyFilters = useCallback(
    filters => dispatch(applyServicesFilters(filters)),
    [ dispatch ]
  );
  const fetchData = () => {
    dispatch(fetchAllServicesTableData(apiFilters));
  };
  React.useEffect(
    () => {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 300000);
      return () => clearInterval(interval);
    },
    [ apiFilters ]
  );
  React.useEffect(() => {
    fetchData();
  }, []);
  const onEdit = service => {
    setSelectedService(service);
  };
  const onMap = service => {
    dispatch(getCurrentLocation(service));
  };
    const onDelete = (service) => {
        setOpenConfirmation(service);
    };
    const handleOnDelete = () => {
        dispatch(deleteService(openConfirmation));
        setOpenConfirmation(null);
    }

  return (
    <React.Fragment>
      <Filters onFilter={applyFilters} />
      <ButtonsArea filteredData={data} />
        <Confirmation
            text={intl.messages["do.you.want.to.delete.tracking.ot"]?.replace("$1", openConfirmation?.serviceOrder)}
            open={Boolean(openConfirmation)}
            onAccept={handleOnDelete}
            onReject={() => setOpenConfirmation(null)}
        />
      <TableContainer head={<TableHeader />}>
        {data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(service => (
            <StyledTableRow key={service.id}>
              <StyledTableCell>
                <IconButton
                    onClick={() => onEdit(service)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onMap(service)}>
                  <RoomIcon />
                </IconButton>
                  <IconButton
                      onClick={() => onDelete(service)}
                      disabled={service.disableRemove}
                  >
                      <DeleteIcon />
                  </IconButton>
              </StyledTableCell>
              {headers.filter(h => h.state).map(header => {
                if (header.content === "state") {
                  return (
                    <StyledTableCell
                      key={header.id + "-" + service.id}
                      style={{
                        backgroundColor: service.state.color,
                        color: invertColor(service.state.color)
                      }}
                    >
                      {service.statesDescription}
                    </StyledTableCell>
                  );
                } else if (
                  header.content === "originExit" ||
                  header.content === "originArrival" ||
                  header.content === "destinationArrival" ||
                  header.content === "destinationExit"
                ) {
                  return (
                    <StyledTableCell
                      align="center"
                      key={header.id + "-" + service.id}
                      style={{
                        backgroundColor: "#4287f5",
                        color: invertColor("#4287f5")
                      }}
                    >
                      {service[header.content]}
                    </StyledTableCell>
                  );
                } else if (header.content === "estimatedKilometers") {
                  return (
                    <StyledTableCell
                      align="center"
                      key={header.id + "-" + service.id}
                      style={{
                        backgroundColor: "#73877B",
                        color: invertColor("#73877B")
                      }}
                    >
                      {service[header.content]}
                    </StyledTableCell>
                  );
                } else if (header.content === "traveledKilometers") {
                  return (
                    <StyledTableCell
                      align="center"
                      key={header.id + "-" + service.id}
                      style={{
                        backgroundColor: "#5C8001",
                        color: invertColor("#5C8001")
                      }}
                    >
                      {service[header.content]}
                    </StyledTableCell>
                  );
                } else {
                  return (
                    <StyledTableCell
                      align="center"
                      key={header.id + "-" + service.id}
                    >
                      {service[header.content]}
                    </StyledTableCell>
                  );
                }
              })}
            </StyledTableRow>
          ))}
      </TableContainer>
      <Box display="flex" justifyContent="end" mt="4px">
        <BasePagination
          data={data}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          page={page}
        />
      </Box>
      <EditServiceDialog
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {data, filters, apiFilters, tableHeaders} = state.logistic.serviceList;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludesMultiOptions("hopper", filters.hoppers)
    .byFieldIncludesMultiOptions("trailer", filters.trailers)
    .byFieldIncludesMultiOptions("destination", filters.clients)
    .byFieldIncludesMultiOptions("statesDescription", filters.states)
    .byFieldIncludesMultiOptions("saleOrganizing", filters.saleOrganizing)
    .byFieldIncludesMultiOptions("driver", filters.drivers)
    .byFieldIncludes("serviceOrder", filters.serviceOrder)
    .build();
  return {data: filteredData, apiFilters, headers: Object.values(tableHeaders)};
};

export default connect(mapStateToProps)(ServicesTable);
