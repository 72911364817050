import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";

const TableHeader = ({order, orderBy, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="names"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.cashManagers.table.names" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="type"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.cashManagers.table.type" />
    </TableCellWithSorting>
    <StyledTableCell>
      <IntlMessages id="administrator.cashManagers.table.tags" />
    </StyledTableCell>
      <StyledTableCell>
          <IntlMessages id="cost.center" />
      </StyledTableCell>
    <TableCellWithSorting
      property="fund"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
      align="right"
    >
      <IntlMessages id="administrator.cashManagers.table.fund" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="cash"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
      align="right"
    >
      <IntlMessages id="administrator.cashManagers.table.cash" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="transit"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
      align="right"
    >
      <IntlMessages id="administrator.cashManagers.table.transit" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="liquidated"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
      align="right"
    >
      <IntlMessages id="administrator.cashManagers.table.liquidated" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="refund"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
      align="right"
    >
      <IntlMessages id="administrator.cashManagers.table.refund" />
    </TableCellWithSorting>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
