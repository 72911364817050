import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"configuration.user.settings.roles.table.code"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"configuration.user.settings.roles.table.description"}
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
