import React from "react";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import moment from "moment";
import {parseNumberToMoney} from "../../../../utils/parsers";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {getUserType} from "../../../../services/StorageService";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import {
  approveAccountingStarted,
  rejectAccountingStarted
} from "../../../../redux/actions/Guardians/Accounting";

const RowValues = ({accounting, setOpenResult}) => {
  const dispatch = useDispatch();
  const {filters} = useSelector(state => state.guardian.accountingList);
  const actions = [
    {
      label: <IntlMessages id={"administrator.accounting.action.approve"} />,
      onCLick: accounting =>
        dispatch(approveAccountingStarted(accounting, filters, setOpenResult))
    },
    {
      label: <IntlMessages id={"administrator.accounting.action.reject"} />,
      onCLick: accounting =>
        dispatch(rejectAccountingStarted(accounting, filters, setOpenResult))
    }
  ];
  const optionClickHandler = (onClick, advance) => {
    onClick(advance);
  };
  return (
    <React.Fragment>
      <StyledTableCell>{accounting.accountingNumber}</StyledTableCell>
      <StyledTableCell>
        {accounting.openingDate &&
          moment(accounting.openingDate).format("DD/MM/YYYY HH:mm")}
      </StyledTableCell>
      <StyledTableCell>
        {accounting.liquidationDate &&
          moment(accounting.liquidationDate).format("DD/MM/YYYY HH:mm")}
      </StyledTableCell>
      <StyledTableCell>
        {accounting.tags.map(tag => (
          <p key={`${accounting.id} - ${tag}`}>{tag}</p>
        ))}
      </StyledTableCell>
        <StyledTableCell>
            {parseNumberToMoney(+accounting.pettyCashAmount)}
        </StyledTableCell>
      <StyledTableCell>
        {parseNumberToMoney(accounting.quantity)}
      </StyledTableCell>
      <StyledTableCell>{accounting.state.description}</StyledTableCell>
      {getUserType().includes("administrator") && (
        <StyledTableCell>
          {accounting.state.id === "2" && (
            <CmtDropdownMenu
              TriggerComponent={
                <Tooltip title="More">
                  <IconButton style={{marginLeft: 4}}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              }
              items={actions}
              onItemClick={item => {
                optionClickHandler(item.onCLick, accounting);
              }}
            />
          )}
        </StyledTableCell>
      )}
    </React.Fragment>
  );
};

export default RowValues;
