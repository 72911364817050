import React from "react";
import {Box, Tooltip, Typography} from "@material-ui/core";
import {useStyles} from "./styles";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {minutesToTime} from "../../../../../../utils/parsers";

const ProgressBar = ({
  title,
  drivingHours,
  availabilityHours,
  distance,
  icon, showAvailability = true
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.drivingProgressBarBox}>
      <Box width="20%" className={classes.iconBox}>
        {icon}
        <Typography className={classes.drivingProgressBarTitle}>
          {title}
        </Typography>
      </Box>
      <Box className={classes.drivingProgressBox}>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <Box className={classes.drivingProgressBar}>
              <Tooltip title={<IntlMessages id="driving" />}>
                <Box
                    flexGrow={drivingHours}
                    className={showAvailability ? classes.drivingProgressBarUsed : classes.drivingProgressBarOnlyUsed}
                >
                  {minutesToTime(drivingHours)}h
                </Box>
              </Tooltip>
              {showAvailability && <Tooltip title={<IntlMessages id="availability"/>}>
                <Box
                    flexGrow={availabilityHours}
                    className={classes.drivingProgressBarUnused}
                >
                  {minutesToTime(availabilityHours)}h
                </Box>
              </Tooltip>}
            </Box>
          </Box>
            {showAvailability &&
                <Box minWidth={35}>
                    <Tooltip
                        title={
                            <p>
                                <IntlMessages id="max.availability"/> {title}
                            </p>
                        }
                    >
                        <Typography className={classes.drivingProgressContentText}>
                            {minutesToTime(Number(drivingHours) + Number(availabilityHours))}h
                        </Typography>
                    </Tooltip>
                </Box>
            }
        </Box>
      </Box>
    </Box>
  );
};

export default ProgressBar;
