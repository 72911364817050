import React from "react";
import PropTypes from "prop-types";
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {membershipValidations} from "../validations";

const MembershipForm = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    {
      nif: "",
      name: "",
      countryNif: ""
    },
    membershipValidations
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            id={id + "-nif"}
            fullWidth
            label={<IntlMessages id={"rrhh.employees.form.nif"} />}
            value={formData.nif}
            onChange={value => setFormData("nif", value)}
            error={errors.nif[0]}
            hasError={errors.nif.length > 0}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

MembershipForm.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MembershipForm;
