import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import {getCompanyEntityId, getUserName} from "./StorageService";
import Receiver from "../domain/Receiver";
import Response from "../domain/Response";

let fetchAllReceiversByAdministratorCancelToken = undefined;

export const cancelPrevFetchAllReceiversByAdministratorCancelToken = () => {
  fetchAllReceiversByAdministratorCancelToken &&
    fetchAllReceiversByAdministratorCancelToken();
};

export const fetchAllReceiversByAdministrator = async () => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Receptor/sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(
          c => (fetchAllReceiversByAdministratorCancelToken = c)
        )
      }
    );
    return response.data.map(receiver => Receiver.map(receiver));
  } catch (e) {
    if (isCancel(e)) {
      return [];
    }
    return Promise.reject(e);
  }
};

export const changeReceiverState = async id => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Receptor/del",
      {
        nu_id_entidad_receptor: id,
        nu_id_entidad: getCompanyEntityId()
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateReceiver = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Receptor/upd",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_receptor: id,
        vc_clave: data.code,
        ls_tag: data.tags.map(tag => ({nu_id_tag: tag.id})),
        vc_usuario: getUserName()
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createReceiver = async data => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Receptor/ins",
      {
        nu_id_entidad_receptor: data.receiver.id,
        nu_id_entidad: getCompanyEntityId(),
        vc_clave: data.code,
        ls_tag: data.tags.map(tag => ({nu_id_tag: tag.id})),
        vc_usuario: getUserName()
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getReceiverById = async receiverId => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Receptor/get",
      {
        nu_id_entidad_receptor: receiverId
      }
    );
    return Receiver.editMap(response.data);
  } catch (e) {
    return Promise.reject(e);
  }
};
