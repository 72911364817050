import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {editServiceArrivalDate} from "../../../../../../redux/actions/Logistic/ServicesList";
import OutlinedDateTimePicker from "../../../../../Common/Forms/OutlinedDateTimePicker";
import OperationSelect from "./OperationSelect";
import {operations} from "../constants";

const Form = ({id, service, setService}) => {
  const dispatch = useDispatch();
  const {apiFilters} = useSelector(({logistic}) => logistic.serviceList);
  const {formData, setFormData, handleSubmit} = useForm({
      date: service.originArrival,
      operation: '',
  });
  const onSubmit = data => {
      const operationDate = service[formData.operation];
    dispatch(
      editServiceArrivalDate(
        {
            serviceId: service.serviceId,
            regionId: service[operations[formData.operation].placeIdField],
            plaque: service.hopper,
            operationDate: operationDate === '--' ? null : operationDate,
            ...data
        },
        apiFilters,
      )
    );
    setService && setService(null);
  };
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
          <Grid item xs={12}>
              <OperationSelect
                value={formData.operation}
                onChange={value => setFormData("operation", value)}
              />
          </Grid>
        <Grid item xs={12}>
          <OutlinedDateTimePicker
            label={<IntlMessages id="date"/>}
            onChange={value => setFormData("date", value)}
            value={formData.date}
            fullWidth
            disabled={!formData.operation}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
