import axiosInstance from "../../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../../StorageService";
import KpiReport from "../../../../domain/ControlPanel/KpiReport";
import Response from "../../../../domain/Response";
import {requests} from "../ReportsService";

const getUrl = service => "/Informes_Gestion/" + service;
export const fetchLastConsultsReports = async () => {
  try {
    const {data} = await axiosInstance.post(getUrl("consultados_sel"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId()
    });
    return data.map(report => KpiReport.map(report));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addLastConsultReport = async (reportId, filters) => {
  try {
    const {data} = await axiosInstance.post(getUrl("consultados_ins"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe: reportId,
      vc_parametros: JSON.stringify(filters)
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchFavoritesReports = async () => {
  try {
    const {data} = await axiosInstance.post(getUrl("favoritos_sel"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId()
    });
    return data.map(report => KpiReport.map(report));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addFavoriteReport = async (reportId, filters, name) => {
  try {
    const {data} = await axiosInstance.post(getUrl("favoritos_ins"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe: reportId,
      vc_parametros: JSON.stringify(filters),
      vc_nombre: name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteFavoriteReport = async reportId => {
  try {
    const {data} = await axiosInstance.post(getUrl("favoritos_del"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe_favorito: reportId
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchScheduledReports = async () => {
  try {
    const {data} = await axiosInstance.post(getUrl("programados_sel"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId()
    });
    return data.map(report => {
      const mapped = KpiReport.map(report);
      mapped.filters = report.vc_parametros_front? JSON.parse(report.vc_parametros_front) : mapped.filters
      return mapped;
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addScheduledReport = async (reportId, filters, formData) => {
  try {
    let reportIdToUse = reportId;
    if(reportId === 32 && formData.format=== 2) {
      reportIdToUse = 36;
    }
    const {data} = await axiosInstance.post(getUrl("programados_ins"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe: reportIdToUse,
      vc_nombre: formData.name,
      vc_parametros: JSON.stringify({...requests[reportId](filters), periodo: filters.period}),
      nu_hora: formData.time,
      nu_frecuencia: formData.frequency,
      vc_email: formData.email,
      vc_email_cc: formData.ccEmail,
      nu_id_fichero_tipo: formData.format,
      vc_parametros_front: JSON.stringify(filters),
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteScheduledReport = async reportId => {
  try {
    const {data} = await axiosInstance.post(getUrl("programados_del"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe_programado: reportId
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const fetchFiledReports = async () => {
  try {
    const {data} = await axiosInstance.post(getUrl("archivados_sel"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId()
    });
    return data.map(report => KpiReport.map(report));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addFiledReport = async (reportId, filters, formData) => {
  try {
    const {data} = await axiosInstance.post(getUrl("archivados_ins"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe_consultado: reportId,
      vc_nombre: formData.name,
      vc_observaciones: formData.note
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteFiledReport = async reportId => {
  try {
    const {data} = await axiosInstance.post(getUrl("archivados_del"), {
      nu_id_entidad: getUserId(),
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_informe_archivado: reportId
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
