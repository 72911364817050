import {FETCH_WAREHOUSE_CAMPUS} from "../../types/Administrator/WarehouseCampusSelect";
//id:1, campus: "Campus1"
const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_WAREHOUSE_CAMPUS: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
};
