import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import AdvanceState from "../domain/AdvanceState";

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAllAdvanceStates = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllAdvanceStates = async () => {
  try {
    const states = await axiosInstance.post(
      "/Caja_Chica_Estado_Anticipo/sel",
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return states.data.map(state => AdvanceState.map(state));
  } catch (e) {
    if (isCancel(e)) {
      return [];
    }
    return Promise.reject(e);
  }
};
