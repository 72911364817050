import React, {useEffect, useCallback} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Supervisor/UnresolvedExpenses/PageContainer";
import Table from "components/Supervisor/UnresolvedExpenses/Table";
import useUiState from "hooks/Supervisor/useUiState";
import RejectExpenseDialog from "components/Supervisor/UnresolvedExpenses/RejectExpenseDialog";
import FilterBuilder from "utils/filters";

import unresolvedExpenses from "redux/actions/Supervisor/UnresolvedExpenses";
import ActiveFilters from "components/Supervisor/UnresolvedExpenses/Filters";
import Box from "@material-ui/core/Box";

import {downloadFile, getFileByName} from "services/FileService";
import {getTypeFromName} from "utils/files";
import {getUserType} from "services/StorageService";

const UnresolvedExpenses = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(unresolvedExpenses.get());
      return () => dispatch(unresolvedExpenses.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(unresolvedExpenses.applyFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(unresolvedExpenses.resetFilters());

  const onApprove = id => dispatch(unresolvedExpenses.approve(id));
  const onReject = data =>
    dispatch(unresolvedExpenses.reject(ui.rejectingId, data));

  const onFileNameClick = name =>
    getFileByName(name).then(({base64File}) =>
      downloadFile(
        name,
        `data:image/${getTypeFromName(name)};base64,${base64File}`
      )
    );

  return (
    <PageContainer isSupervisor={getUserType().includes("supervisor")}>
      <ActiveFilters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.isLoadingData}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table
            data={tableData}
            onApprove={onApprove}
            onFileNameClick={onFileNameClick}
            isSupervisor={getUserType().includes("supervisor")}
            {...ui.tableProps}
          />
        </Box>
      )}
      <RejectExpenseDialog
        onSubmit={onReject}
        {...ui.rejectExpenseDialogProps}
      />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.supervisor.unresolvedExpenses;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("driver", filters.driver)
    .byFieldIncludes("tags", filters.tag)
    .byFieldIncludes("pettyCash", filters.pettyCashAmount)
    .byFieldIncludes("event", filters.event)
    .byFieldIncludes("documentType", filters.documentType)
    .byFieldEquals("amount", filters.amount)
    .byFieldEquals("state", filters.state)
    .byFieldEquals("reasonOfRejection", filters.reasonOfRejection.value)
    .byFieldBetweenDates("date", filters.lowerDate, filters.upperDate)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(UnresolvedExpenses);
