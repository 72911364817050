class UnresolvedExpense {
  constructor(
    id,
    driver,
    pettyCash,
    tags,
    event,
    documentType,
    amount,
    state,
    reasonOfRejection,
    supportDocument,
    date,
    ot,
    advanceDate,
    costCenter
  ) {
    this.id = id;
    this.driver = driver;
    this.pettyCash = pettyCash;
    this.tags = tags;
    this.event = event;
    this.documentType = documentType;
    this.amount = amount;
    this.state = state;
    this.reasonOfRejection = reasonOfRejection;
    this.supportDocument = supportDocument;
    this.date = date;
    this.ot = ot;
    this.advanceDate = advanceDate;
    this.costCenter = costCenter;
  }

  static map = unresolvedExpense => {
    return new UnresolvedExpense(
      unresolvedExpense.nu_id_gasto,
      unresolvedExpense.vc_nombre_conductor,
      unresolvedExpense.vc_nombre_caja,
      unresolvedExpense.vc_desc_tag.split(","),
      unresolvedExpense.vc_desc_tipo_gasto,
      unresolvedExpense.vc_desc_tipo_documento,
      parseFloat(unresolvedExpense.nu_imp_gasto),
      unresolvedExpense.vc_estado,
      unresolvedExpense.vc_desc_tipo_rechazo,
      unresolvedExpense.vc_id_archivo_s3,
      unresolvedExpense.dt_fecha,
        unresolvedExpense.vc_desc_ot,
        unresolvedExpense.dt_fecha_anticipo,
        unresolvedExpense.vc_desc_centro_costo
    );
  };
}

export default UnresolvedExpense;
