import {createSlice} from "@reduxjs/toolkit";
import {
    addOrganizationCard,
    fetchOrganizationAndDriversCards, removeOrganizationCard,
    updateOrganizationCard
} from "../../thunks/FleetManagement/Tachograph/cards";
import {cloneDeep} from "lodash";

const INIT_STATE = {
    organizationCards: {},
    driversCards: [],
    currentCard: null,
    dialogs: [],
}

const tachographCardsSlice = createSlice({
    name: 'tachographCards',
    initialState: INIT_STATE,
    reducers: {
        setCurrentCard: (state, action) => {
            state.currentCard = action.payload;
        },
        openDialog: (state, action) => {
            state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(d => d !== action.payload);
        },
        resetState: (state) => {
            state.organizationCards = {};
            state.driversCards = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchOrganizationAndDriversCards.fulfilled,
            (state, action) => {
                const {organizationCards, driversCards} = action.payload;
                state.organizationCards = organizationCards?.reduce((acc, card) => {
                    acc[card.id] = card;
                    return acc;
                }, {});
                state.driversCards = driversCards?.reduce((acc, card) => {
                    acc[card.id] = card;
                    return acc;
                }, {});
            });
        builder.addCase(addOrganizationCard.fulfilled, (state, action) => {
            const {id, typeId, ...rest} = action.payload;
            if (typeId === "4") {
                state.organizationCards[id] = {
                    id,
                    typeId,
                    ...rest,
                };
            } else if (typeId === "1") {
                state.driversCards[id] = {
                    id,
                    typeId,
                    ...rest,
                };
            }
        });
        builder.addCase(updateOrganizationCard.fulfilled, (state, action) => {
            const {id, typeId, ...rest} = action.payload;
            if (typeId === "4") {
                state.organizationCards[id] = {
                    ...state.organizationCards[id],
                    ...rest,
                };
            } else if (typeId === "1") {
                state.driversCards[id] = {
                    ...state.driversCards[id],
                    ...rest,
                };
            }
        });
        builder.addCase(removeOrganizationCard.fulfilled, (state, action) => {
            const {id, typeId} = action.payload;
            if (typeId === "4") {
                const newState = cloneDeep(state.organizationCards);
                delete newState[id];
                state.organizationCards = newState;
            } else if (typeId === "1") {
                const newState = cloneDeep(state.driversCards);
                delete newState[id];
                state.driversCards = newState;
            }
        });
    }
});

export const {resetState, setCurrentCard, openDialog, closeDialog} = tachographCardsSlice.actions;
export default tachographCardsSlice.reducer;



