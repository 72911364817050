import Supervisor from "domain/Supervisor";
import Response from "domain/Response";
import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAll = async () => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Supervisor/sel",
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(supervisor => Supervisor.map(supervisor));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const changeState = async id => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Supervisor/del",
      {
        nu_id_entidad_supervisor: id,
        nu_id_entidad: getCompanyEntityId()
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update = async (id, data) => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Supervisor/upd",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_supervisor: id,
        ls_tag: data.tags.map(tag => ({nu_id_tag: tag.id})),
        vc_usuario: "USUARIO"
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const create = async data => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Supervisor/ins",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_supervisor: data.supervisor.id,
        ls_tag: data.tags.map(tag => ({nu_id_tag: tag.id})),
        vc_usuario: "USUARIO"
      }
    );
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSupervisorById = async supervisorId => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Entidad_Supervisor/get",
      {nu_id_entidad_supervisor: supervisorId}
    );
    return Supervisor.editMap(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};
