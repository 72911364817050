import React from 'react';
import {useStyles} from "../styles";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {Box} from "@material-ui/core";
import DefaultStatesList from "../Form/DefaultStatesArea";
import {useDispatch, useSelector} from "react-redux";
import NameArea from "../Form/NameArea";
import ComplementaryStatesList from "../Form/ComplementaryStatesList";
import AutomaticStates from "../Form/AutomaticStates";
import {
    addWorkOrderTypeState,
    editWorkOrderType,
    editWorkOrderTypeState, resetCurrentWorKOrderType, setCurrentWorkOrderType
} from "../../../../../redux/actions/Logistic/WorkOrdersTypes";

const WorkOrderTypeEditDialog = ({open, setOpen, title}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {current, data} = useSelector(({logistic}) => logistic.workOrdersTypes);
    const onSaveStartData = (data) => {
        const otTypeState = current.principalStates[0];
        if(!otTypeState) {
            dispatch(addWorkOrderTypeState(current, data));
        }else {
            dispatch(editWorkOrderTypeState(current, {...otTypeState, ...data}));
        }
    }
    const onSaveEndData = data => {
        const otTypeState = current.principalStates[1];
        if(!otTypeState) {
            dispatch(addWorkOrderTypeState(current, {...data, type: 2}));
        }else {
            dispatch(editWorkOrderTypeState(current, {...otTypeState, ...data, type: 2}));
        }
    }
    const onSaveName = (data) => {
        dispatch(editWorkOrderType(current, data));
    }
    React.useEffect(() => {
        if(current) {
            dispatch(resetCurrentWorKOrderType());
            setTimeout(() => dispatch(setCurrentWorkOrderType(data[current.id])), 100);
        }
    }, [data]);
    return (
        <FullScreenDialog
            open={open}
            handleClose={() => setOpen(false)}
            title={title}
        >
            {current?
                <Box className={classes.defaultBigBox} m={4}>
                    <NameArea
                        data={current}
                        onSave={onSaveName}
                    />
                    <DefaultStatesList
                        startInitialData={{
                            id: current.principalStates[0]?.id || "",
                            name: current.principalStates[0]?.name || "",
                            confirmation: current.principalStates[0]?.destinyConfirmation || false,
                            permission: current.principalStates[0]?.allowPermission || false,
                            previousCheck: current.principalStates[0]?.previous || "",
                            confirmationCheck: current.principalStates[0]?.confirmation || "",
                            arrivalDestination: current.principalStates[0]?.arrivalDestination || false,
                            departureDestination: current.principalStates[0]?.departureDestination ||false
                        }}
                        endInitialData={{
                            id: current.principalStates[1]?.id || "",
                            name: current.principalStates[1]?.name || "",
                            confirmation: current.principalStates[1]?.destinyConfirmation || false,
                            permission: current.principalStates[1]?.allowPermission || false,
                            previousCheck: current.principalStates[1]?.previous || "",
                            confirmationCheck: current.principalStates[1]?.confirmation || "",
                            arrivalDestination: current.principalStates[1]?.arrivalDestination || false,
                            departureDestination: current.principalStates[1]?.departureDestination ||false
                        }}
                        startChecklist={current.principalStates[0]?.checklist || []}
                        endChecklist={current.principalStates[1]?.checklist || []}
                        onSaveStartData={onSaveStartData}
                        onSaveEndData={onSaveEndData}
                        statesChecklist={current.states}
                    />
                    <ComplementaryStatesList
                        current={current}
                    />
                    <AutomaticStates
                        current={current}
                    />
                </Box>
                :<></>
            }

        </FullScreenDialog>
    );
}

export default WorkOrderTypeEditDialog;