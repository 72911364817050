import React from "react";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import {addElevatorModel} from "../../../../../../redux/actions/ElevatorsManagement/Models";

const AddModelDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addElevatorModel(data));
    setOpen(false);
  };
  return (
    <BaseDialog
      id="add-model-form"
      title={
        <IntlMessages
          id={"elevators.management.elevators.model.type.add.title"}
        />
      }
      open={open}
      setOpen={setOpen}
      initData={{
        name: ""
      }}
      onSubmit={onSubmit}
    />
  );
};

export default AddModelDialog;
