import React from 'react';
import FiltersContainer from 'components/Common/Forms/FiltersContainer';
import GridContainer from '../../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';
import StateSelect from '../../../Common/Forms/Select/DistributionSelect/StateSelect';
import OutlinedDatePicker from '../../../Common/Forms/OutlinedDatePicker';
import useForm from '../../../../hooks/Common/useForm';
import PeriodSelect from "../../../Common/Forms/Select/PeriodSelect";
import {StyledButton} from "./styles";
import moment from "moment";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {useDispatch} from "react-redux";
import DriversPicker from "./DriversPicker";
import {generateWorkOrdersExcelFile} from "../../../../redux/actions/Administrator/WorkOrders";


const today = Date.now();
const initState = {
    state: 0,
    driver: [],
    period: 4,
    startDate: moment(today).format('yyyy-MM-DD'),
    endDate: moment(today).format('yyyy-MM-DD'),
};

const filterStates = [
    {
        id: 1,
        text: <IntlMessages id={"logistic.work.orders.state.pending"}/>
    },
    {
        id: 2,
        text: <IntlMessages id={"logistic.work.orders.state.finished"}/>
    }
];


const periods = [
    {
        id: 1,
        text: <IntlMessages id="logistic.filters.period.last.15.days"/>,
        setDate: (setFormData) => {
            setFormData('endDate', moment(today).format('yyyy-MM-DD'));
            setFormData('startDate', moment(today).subtract(15, 'days').format('yyyy-MM-DD'));

        }
    },
    {
        id: 2,
        text: <IntlMessages id="logistic.filters.period.last.7.days"/>,
        setDate: (setFormData) => {

            setFormData('endDate', moment(today).format('yyyy-MM-DD'));
            setFormData('startDate', moment(today).subtract(7, 'days').format('yyyy-MM-DD'));

        }
    },
    {
        id: 3,
        text: <IntlMessages id="logistic.filters.period.yesterday"/>,
        setDate: (setFormData) => {

            setFormData('startDate', moment(today).subtract(1, 'days').format('yyyy-MM-DD'));
            setFormData('endDate', moment(today).subtract(1, 'days').format('yyyy-MM-DD'));

        }
    },
    {
        id: 4,
        text: <IntlMessages id="logistic.filters.period.today"/>,
        setDate: (setFormData) => {

            setFormData('startDate', moment(today).format('yyyy-MM-DD'));
            setFormData('endDate', moment(today).format('yyyy-MM-DD'));

        }
    },
    {
        id: 5,
        text: <IntlMessages id="logistic.filters.period.tomorrow"/>,
        setDate: (setFormData) => {

            setFormData('startDate', moment(today).add(1, 'days').format('yyyy-MM-DD'));
            setFormData('endDate', moment(today).add(1, 'days').format('yyyy-MM-DD'));

        }
    }
];

const WorkOrdersFilters = ({applyFilters, search}) => {
    const {formData, setFormData, resetForm} = useForm(initState);
    const dispatch = useDispatch();
    React.useEffect(() => {
        periods.find(p => p.id === formData.period)?.setDate(setFormData);
    }, [formData.period]);
    const generateExcel = (e) => {
        e.preventDefault();
        dispatch(generateWorkOrdersExcelFile(formData, search));
    }
    return (
            <FiltersContainer
                active={true}
                marginBottom={5} onClose={() => {}}>
                <form>
                    <GridContainer spacing={4}>
                        <Grid item xs={12} sm={3} lg={3}>
                            <DriversPicker
                                values={formData.driver}
                                onChange={value => setFormData('driver', value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <StateSelect data={formData.state}
                                         id="work-orders-filter-state"
                                         onChange={value => setFormData('state', value)}
                                         states={filterStates}
                                         fullWidth/>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                           <PeriodSelect period={formData.period}
                                         options={periods}
                                         id={"orders-filter-period"}
                                        onChange={value => setFormData('period', value)}
                           />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <OutlinedDatePicker
                                fullWidth
                                value={formData.startDate}
                                id="from"
                                label={<IntlMessages id="logistic.table.from"/>}
                                onChange={value => setFormData('startDate', value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <OutlinedDatePicker
                                fullWidth
                                value={formData.endDate}
                                id="to"
                                label={<IntlMessages id="logistic.table.to"/>}
                                onChange={value => setFormData('endDate', value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <StyledButton
                                variant="contained" onClick={() => applyFilters(formData)} >
                                <IntlMessages id={"logistic.work.orders.table.filters.apply"}/>
                            </StyledButton>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <StyledButton variant="contained" onClick={() => {
                                resetForm();
                                applyFilters(initState);
                            }} >
                                <IntlMessages id={"logistic.work.orders.table.filters.clear"}/>
                            </StyledButton>
                        </Grid>
                        <Grid item xs={12} sm={3} lg={3}>
                            <ContainedButton
                                text={<IntlMessages id="generate.format.excel" />}
                                onClick={generateExcel}
                                color='primary'
                            />
                        </Grid>
                    </GridContainer>
                </form>
            </FiltersContainer>
    );
};

export default WorkOrdersFilters;
