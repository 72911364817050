import {
  RESTORE_MONITORING_STATE,
  FETCH_ALL_MONITORING_WORK_ORDERS_STARTED,
  FETCH_ALL_MONITORING_WORK_ORDERS_SUCCEED,
  APPLY_MONITORING_FILTERS,
  RESET_MONITORING_FILTERS,
  FETCH_ALL_CHATS_STARTED,
  FETCH_ALL_CHATS_SUCCEED,
  FETCH_ALL_CHAT_USERS_STARTED,
  FETCH_ALL_CHAT_USERS_SUCCEED,
  SET_SELECTED_USER,
  RESET_SELECTED_USER,
  LOAD_SELECTED_CHAT_STARTED,
  LOAD_SELECTED_CHAT_SUCCEED,
  SEND_MESSAGE,
  ADD_MESSAGE,
  SET_CURRENT_MONITORING_WORK_ORDER,
  RESET_CURRENT_WORK_ORDER,
  FETCH_ALL_ALERTS_STARTED,
  FETCH_ALL_ALERTS_SUCCEED,
  APPLY_ALERTS_FILTERS,
  RESET_ALERTS_FILTERS,
  APPLY_DISPATCHES_FILTERS,
  RESET_DISPATCHES_FILTERS,
  FETCH_ALL_DISPATCHES_WORK_ORDERS_STARTED,
  FETCH_ALL_DISPATCHES_DESTINATIONS_STARTED,
  FETCH_ALL_DISPATCHES_DESTINATIONS_SUCCEED,
  FETCH_ALL_DISPATCHES_WORK_ORDERS_SUCCEED,
  UPDATE_ALERT_STATE,
  RESET_RESULT
} from "../../types/Logistic/MonitoringTypes";
import moment from "moment";

const today = moment(Date.now()).format("yyyy-MM-DD");

const INIT_STATE = {
  info: null,
  data: [],
  isLoading: false,
  filters: {
    centrals: [],
    vehicles: [],
    routes: [],
    startDate: today,
    endDate: today,
    services: []
  },
  chatsData: {
    chats: [],
    conversation: [],
    selectedUser: null,
    users: [],
    message: ""
  },
  current: undefined,
  alerts: {
    isLoading: false,
    data: [],
    filters: {
      startDate: "",
      endDate: "",
      management: "",
      state: "",
      subState: "",
      vehicle: "",
      guide: "",
      contact: ""
    }
  },
  dispatches: {
    destinations: [],
    workOrders: [],
    isLoading: false,
    filters: {
      client: "",
      state: "",
      workOrder: "",
      vehicle: "",
      driver: "",
      route: "",
      rejectReason: ""
    }
  },
  operation: {
    message: "",
    ok: undefined
  }
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_MONITORING_WORK_ORDERS_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_MONITORING_WORK_ORDERS_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        info: action.payload.info
      };
    }
    case RESTORE_MONITORING_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    case APPLY_MONITORING_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }
    case RESET_MONITORING_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case FETCH_ALL_CHATS_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_CHATS_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        chatsData: {
          ...state.chatsData,
          chats: action.payload
        }
      };
    }
    case FETCH_ALL_CHAT_USERS_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_CHAT_USERS_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        chatsData: {
          ...state.chatsData,
          users: action.payload
        }
      };
    }
    case SET_SELECTED_USER: {
      return {
        ...state,
        chatsData: {
          ...state.chatsData,
          selectedUser: action.payload
        }
      };
    }
    case RESET_SELECTED_USER: {
      return {
        ...state,
        chatsData: {
          ...state.chatsData,
          conversation: [],
          selectedUser: null
        }
      };
    }
    case LOAD_SELECTED_CHAT_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case LOAD_SELECTED_CHAT_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        chatsData: {
          ...state.chatsData,
          conversation: action.payload
        }
      };
    }
    case SEND_MESSAGE: {
      return {
        ...state,
        isLoading: true,
        chatsData: {
          ...state.chatsData,
          message: action.payload
        }
      };
    }
    case ADD_MESSAGE: {
      return {
        ...state,
        isLoading: false,
        chatsData: {
          ...state.chatsData,
          message: "",
          conversation: [ ...state.chatsData.conversation, action.payload ]
        }
      };
    }
    case SET_CURRENT_MONITORING_WORK_ORDER: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_WORK_ORDER: {
      return {
        ...state,
        current: undefined
      };
    }
    case FETCH_ALL_ALERTS_STARTED: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isLoading: true
        }
      };
    }
    case FETCH_ALL_ALERTS_SUCCEED: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          isLoading: false,
          data: action.payload
        }
      };
    }
    case APPLY_ALERTS_FILTERS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          filters: {
            ...action.payload
          }
        }
      };
    }
    case RESET_ALERTS_FILTERS: {
      return {
        ...state,
        alerts: {
          ...state.alerts,
          filters: {
            ...INIT_STATE.alerts.filters
          }
        }
      };
    }
    case APPLY_DISPATCHES_FILTERS: {
      return {
        ...state,
        dispatches: {
          ...state.dispatches,
          filters: {
            ...action.payload
          }
        }
      };
    }
    case RESET_DISPATCHES_FILTERS: {
      return {
        ...state,
        dispatches: {
          ...state.dispatches,
          filters: {
            ...INIT_STATE.dispatches.filters
          }
        }
      };
    }
    case FETCH_ALL_DISPATCHES_WORK_ORDERS_STARTED: {
      return {
        ...state,
        dispatches: {
          ...state.dispatches,
          isLoading: true
        }
      };
    }
    case FETCH_ALL_DISPATCHES_WORK_ORDERS_SUCCEED: {
      return {
        ...state,
        dispatches: {
          ...state.dispatches,
          workOrders: [ ...action.payload ],
          isLoading: false
        }
      };
    }
    case FETCH_ALL_DISPATCHES_DESTINATIONS_STARTED: {
      return {
        ...state,
        dispatches: {
          ...state.dispatches,
          isLoading: true
        }
      };
    }
    case FETCH_ALL_DISPATCHES_DESTINATIONS_SUCCEED: {
      return {
        ...state,
        dispatches: {
          ...state.dispatches,
          destinations: [ ...action.payload ],
          isLoading: false
        }
      };
    }
    case UPDATE_ALERT_STATE: {
      return {
        ...state,
        operation: {
          ...state.operation,
          ...action.payload
        }
      };
    }
    case RESET_RESULT: {
      return {
        ...state,
        operation: {
          ...state.operation,
          ...INIT_STATE.operation
        }
      };
    }
    default: {
      return state;
    }
  }
};
