import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = supervisors => [
  {
    label: <IntlMessages id={"sidebar.workday.dailyOperations"} />,
    link: "/rrhh/workers/"
  },
  {
    label: (
      <IntlMessages id={"sidebar.workday.dailyOperations.transferHistory"} />
    ),
    isActive: true
  }
];

const PageContainer = ({children, supervisors}) => (
  <PageContainerComponent
    heading={
      <IntlMessages id={"sidebar.workday.dailyOperations.transferHistory"} />
    }
    description={
      <IntlMessages id={"rrhh.page.historicalSigning.description"} />
    }
    breadcrumbs={breadcrumbs(supervisors)}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node,
  supervisors: PropTypes.bool
};

PageContainer.defaultProps = {
  children: null,
  supervisors: true
};

export default PageContainer;
