import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {createService, fetchAllServices, updateService, updateServiceState} from "../../thunks/Treasury/services";

const INIT_STATE = {
    services: {},
    dialogs: [],
    currentService: null,
};

const servicesSlice = createSlice({
    name: "services",
    initialState: cloneDeep(INIT_STATE),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentService: (state, action) => {
            state.currentService = action.payload;
        },
        resetServices: () => cloneDeep(INIT_STATE)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllServices.fulfilled, (state, action) => {
                state.services = action.payload.services.reduce((acc, service) => ({...acc, [service.id]: service}), {});
            })
            .addCase(createService.fulfilled, (state, action) => {
                state.services[action.payload.id] = action.payload;
            })
            .addCase(updateService.fulfilled, (state, action) => {
                const editedService = action.payload;
                state.services[editedService.id] = {
                    ...state.services[editedService.id],
                    ...editedService,
                };
            })
            .addCase(updateServiceState.fulfilled, (state, action) => {
                const serviceId = action.payload;
                state.services[serviceId] = {
                    ...state.services[serviceId],
                    state: !state.services[serviceId].state,
                };
            });
    }
});

export default servicesSlice.reducer;

export const {setCurrentService, closeDialog, openDialog, resetServices} = servicesSlice.actions;