export const RESET_SINISTER_TYPES_STATE = "RESET_SINISTER_TYPES_STATE";
export const FETCH_ALL_SINISTER_TYPES = "FETCH_ALL_SINISTER_TYPES";
export const APPLY_SINISTER_TYPES_FILTERS = "APPLY_SINISTER_TYPES_FILTERS";
export const RESET_SINISTER_TYPES_FILTERS = "RESET_SINISTER_TYPES_FILTERS";
export const SET_CURRENT_SINISTER_TYPE = "SET_CURRENT_SINISTER_TYPE";
export const RESET_CURRENT_SINISTER_TYPE = "RESET_CURRENT_SINISTER_TYPE";
export const ADD_SINISTER_TYPE = "ADD_SINISTER_TYPE";
export const EDIT_SINISTER_TYPE = "EDIT_SINISTER_TYPE";
export const OPEN_SINISTER_TYPES_DIALOG = "OPEN_SINISTER_TYPES_DIALOG";
export const CLOSE_SINISTER_TYPES_DIALOG = "CLOSE_SINISTER_TYPES_DIALOG";
