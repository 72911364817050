import React from 'react';
import {IconButton} from "@material-ui/core";
import {useDispatch} from "react-redux";
import ItemForm from "./ItemForm";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import TableContainer from "../../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {openDialog, setCurrentOrderItem} from "../../../../../../../redux/reducers/Logistic/orders";
import {dialogsList} from "../../../constants";
import Typography from "@material-ui/core/Typography";

const ItemsList = ({items}) => {
    const dispatch = useDispatch();
    const handleEditItem = (item) => {
        dispatch(openDialog(dialogsList.itemForm));
        dispatch(setCurrentOrderItem(item));
    }
    return (
        <fieldset className="table-list">
            <legend>
                <Typography>
                    <IntlMessages id="products" /> y <IntlMessages id="services" />
                </Typography>
            </legend>
            <TableContainer
                head={
                    <StyledTableRow>
                        <StyledTableCell>
                            <IntlMessages id="product" /> / <IntlMessages id="service" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="quantity" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="delivered" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="description" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="state" />
                        </StyledTableCell>
                        <StyledTableCell width="10%"/>
                    </StyledTableRow>
                }
            >
                {items.map((item) => (
                    <StyledTableRow key={item.id}>
                        <StyledTableCell>
                            {item.name}
                        </StyledTableCell>
                        <StyledTableCell>
                            {item.quantity}
                        </StyledTableCell>
                        <StyledTableCell>
                            {item.deliveredQuantity}
                        </StyledTableCell>
                        <StyledTableCell>
                            {item.description}
                        </StyledTableCell>
                        <StyledTableCell>
                            {item.stateName}
                        </StyledTableCell>
                        <StyledTableCell>
                            <IconButton onClick={() => handleEditItem(item)}>
                                <MoreVertIcon/>
                            </IconButton>
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableContainer>
            <ItemForm />
        </fieldset>
    );
};

export default ItemsList;