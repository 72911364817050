import React from 'react';
import PropTypes from 'prop-types';
import {
    setCurrentConfigOrganizationModule,
    updateOrganizationsMassive
} from "../../../../../redux/actions/Configuration/OrganizationsModules";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import EditPermissionsForm from "../../Roles/EditRoleDialog/EditPermissionsForm";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import {useDispatch, useSelector} from "react-redux";
import Stepper from "../../../../Common/Steppers/Stepper";
import OrganizationsList from "./OrganizationsList";

const steps = [
    {
        id: 1,
        label: <IntlMessages
            id="configuration.user.settings.organization.modules.massive.steps.select.organization"/>
    },
    {
        id: 2,
        label: <IntlMessages id="configuration.user.settings.organization.modules.massive.steps.select.modules"/>
    },
];

const MassiveUpdateForm = ({open, setOpen}) => {
    const id = 'edit-organizations-modules-form';
    const {current, data} = useSelector(({configuration}) => configuration.organizationsModules);
    const dispatch = useDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [organizations, setOrganizations] = React.useState([]);
    const onEditOrganizations = data => {
        dispatch(updateOrganizationsMassive(organizations.filter(o => o.checked), Object.values(data).filter(p => p.state), open));
        setOpen(null);
    }
    const parseInitData = modules => {
        let permissions = {};
        Object.values(modules)
            .map(module => module.submodules).flat()
            .map(submodules => submodules.operations).flat()
            .map(o => {
                permissions[o.id] = o;
                return null;
            });
        return permissions;
    }
    React.useEffect(() => {
        setOrganizations(Object.values(data).map(o => ({...o, checked: false})));
    }, [data]);

    return (
        <FormDialog
            id={id}
            isOpen={Boolean(open)}
            onClose={() => {
                setOpen(null);
                dispatch(setCurrentConfigOrganizationModule(null));
            }}
            title={
                <IntlMessages
                    id={open === 'add'
                        ? "configuration.user.settings.organization.modules.add.massive.title"
                        : "configuration.user.settings.organization.modules.delete.massive.title"
                    }/>}
            submitText={<IntlMessages id={"label.form.save"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
        >
            <Stepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                steps={steps}
                finishText={<IntlMessages id={"label.form.save"}/>}
                hideFinish
            >
                {activeStep === 0
                    ? <OrganizationsList
                        organizations={organizations}
                        setOrganizations={setOrganizations}
                    />
                    : current?.permissions &&
                    <EditPermissionsForm
                        id={id}
                        initialData={parseInitData(current.permissions)}
                        modules={current.permissions}
                        onEditRole={onEditOrganizations}
                    />
                }
            </Stepper>

        </FormDialog>
    );
};

MassiveUpdateForm.propTypes = {
    open: PropTypes.any.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default MassiveUpdateForm;