import {minutesToTime} from "../utils/parsers";

class WorkOrderGlobal {
  constructor(
    id,
    description,
    startDate,
    endDate,
    driver,
    duration,
    total,
    finished
  ) {
    this.id = id;
    this.description = description;
    this.startDate = startDate;
    this.endDate = endDate;
    this.driver = driver;
    this.duration = duration;
    this.total = total;
    this.finished = finished;
  }

  static map = workOrder => {
    return new WorkOrderGlobal(
      workOrder.nu_id_ot,
      workOrder.vc_desc_ruta + "-" + workOrder.vc_desc_ot,
      workOrder.dt_fec_ini_estimada,
      workOrder.dt_fec_fin_estimada,
      workOrder.vc_conductor,
      minutesToTime(workOrder.nu_duracion),
      workOrder.nu_total_despacho,
      workOrder.nu_total_despacho_finalizado
    );
  };
}

export default WorkOrderGlobal;
