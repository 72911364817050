import React from "react";
import PropTypes from "prop-types";

import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";

import StyledTableCell from "../../StyledTableCell";
import StyledTableRow from "../../StyledTableRow";

const CrudTableRow = ({children, onEdit, onDelete, onlyDelete, onlyEdit}) => (
  <StyledTableRow>
    {children}
    <StyledTableCell align="right">
      {!onlyDelete && (
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
      )}
      {!onlyEdit && (
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </StyledTableCell>
  </StyledTableRow>
);

CrudTableRow.propTypes = {
  children: PropTypes.node,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onlyDelete: PropTypes.bool
};

CrudTableRow.defaultProps = {
  children: null,
  onEdit: () => {},
  onDelete: () => {},
  onlyDelete: false
};

export default CrudTableRow;
