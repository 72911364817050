class Entity {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static map = entity => {
    return new Entity(entity.nu_id_entidad_empleado, entity.vc_nombre);
  };
}

export default Entity;
