import React from "react";
import Map from "../../../../../../Common/Maps/OpenStreetMap/Map";
import {Marker, Popup} from "react-leaflet";

const AddressMap = ({latitude, longitude, name}) => {
  return (
      <Map
        latitude={latitude}
        longitude={longitude}
      >
          <Marker position={[latitude, longitude]}>
              <Popup>
                  {name}
              </Popup>
          </Marker>
      </Map>
  );
};

export default AddressMap;
