import React from "react";
import PropTypes from "prop-types";
import Select from "../../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const VisualizationSelect = ({value, onChange, error, hasError}) => {
  const id = "mina-visualization-select";
  const options = [
    {
      id: "1",
      description: (
        <IntlMessages
          id={
            "administrator.dataCollectionHistory.filters.visualization.graphic"
          }
        />
      )
    },
    {
      id: "2",
      description: (
        <IntlMessages
          id={"administrator.dataCollectionHistory.filters.visualization.data"}
        />
      )
    }
  ];
  return (
    <Select
      id={id}
      label={
        <IntlMessages
          id={"administrator.dataCollectionHistory.filters.visualization"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      error={hasError}
      errorMessage={error}
      fullWidth
    >
      {options.map(o => (
        <MenuItem key={o.id} value={o.id}>
          {o.description}
        </MenuItem>
      ))}
    </Select>
  );
};

VisualizationSelect.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.any,
  hasError: PropTypes.any
};

export default VisualizationSelect;
