import React from 'react';
import {Box, Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {useStyles} from "../../styles";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../../hooks/Common/useForm";
import validations from "./validations";

const NameArea = ({data, add=false, onSave}) => {
    const {formData, setFormData, validateAll, errors, resetForm} = useForm({
        name: data.name
    }, validations);
    const classes = useStyles();
    const [edit, setEdit] = React.useState(add);
    const onSaveChanges = () => {
        if(validateAll()) {
            setEdit(false);
            onSave && onSave({name: formData.name});
        }
    }
    return(
        <GridContainer spacing={2}>
            <Grid item sm={12} md={8}>
                <OutlinedInput
                    id={'name-input'}
                    value={formData.name}
                    onChange={value => setFormData('name', value)}
                    error={errors?.name[0]}
                    hasError={errors?.name?.length > 0}
                    fullWidth
                    disabled={!edit}
                    label={<IntlMessages id={"logistic.distribution.work.orders.types.detail.name.label"}/>}
                />
            </Grid>
            <Grid item sm={12} md={4}>
                <Box className={classes.buttonBox}>
                    {
                        edit
                            ? <React.Fragment>
                                <ContainedButton
                                    text={<IntlMessages id={"form.button.cancel"}/>}
                                    color={"primary"}
                                    onClick={() => {
                                        setEdit(false);
                                        resetForm();
                                    }}
                                />
                                <ContainedButton
                                    text={<IntlMessages id={"form.button.save"}/>}
                                    color={"primary"}
                                    onClick={() => onSaveChanges()}
                                />
                            </React.Fragment>
                            : <ContainedButton
                                text={<IntlMessages id={"form.button.edit"}/>}
                                color={"primary"}
                                onClick={() => setEdit(true)}
                            />
                    }
                </Box>
            </Grid>
        </GridContainer>

    );
}

export default NameArea;