import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Button, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchBreakdownStarted,
    setBreakdownFilters
} from "../../../../../redux/actions/Administrator/WorkOrdersBreakdown";

const SearchWorkOrder = () => {
    const {filters} = useSelector(({administrator}) => administrator.breakdown);
    const [workOrderNumber, setWorkOrderNumber] = React.useState(filters.ot);
    const dispatch = useDispatch();
    const handleSubmit = () => {
        dispatch(fetchBreakdownStarted(workOrderNumber));
        dispatch(setBreakdownFilters({ot: workOrderNumber}));
    };
    return (
        <GridContainer spacing={4}>
            <Grid item xs={12} sm={8}>
                <OutlinedInput
                    fullWidth
                    value={workOrderNumber}
                    label={
                        <IntlMessages id="administrator.ot.breakdown.search.work.order.number.label"/>
                    }
                    onChange={value => setWorkOrderNumber(value)}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Button
                    onClick={() => handleSubmit()}
                    disabled={workOrderNumber === ""}
                    style={{
                        background: "primary"
                    }}
                >
                    <IntlMessages id="administrator.ot.breakdown.search.button"/>
                </Button>
            </Grid>
        </GridContainer>
    );
};

export default SearchWorkOrder;
