import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  area: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.sidebar.textActiveColor,
    color: "#ffffff"
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  option: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(5),
    color: "#000000"
  },
  report: {
    paddingLeft: theme.spacing(4)
  }
}));
