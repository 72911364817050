import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import {
  setCurrentConfigurationUser,
  updateConfigurationUser
} from "../../../../../redux/actions/Configuration/Users";
import Form from "./Form";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const UpdateUserDialog = ({open, setOpen}) => {
  const id = "edit-config-user";
  const {current} = useSelector(({configuration}) => configuration.users);
  const dispatch = useDispatch();

  const onEditUser = data => {
    dispatch(updateConfigurationUser(current, data.roles, data.vehicleRoles));
    setOpen(false);
  };

  return (
    <FormDialog
      id={id}
      isOpen={open}
      onClose={() => {
        setOpen(false);
        dispatch(setCurrentConfigurationUser(null));
      }}
      title={
        <IntlMessages id={"configuration.user.settings.users.edit.title"} />
      }
    >
      {current && (
        <Form
          id={id}
          initialData={{
            roles: current.roles,
            vehicleRoles: current.vehicleRoles
          }}
          onEditUser={onEditUser}
        />
      )}
    </FormDialog>
  );
};

export default UpdateUserDialog;
