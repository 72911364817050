import React from 'react';
import { useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';

const RejectionTypesSelect = ({ id, label, hasError, error, value, onChange, withId = false }) => {
  const { data } = useSelector(state => state.administrator.rejectionTypes);

  const options = [
    { value: '', label: 'Todos' },
    ...data
      .filter(type => type.active)
      .map(type => ({ value: withId ? type.id : type.description, label: type.description })),
  ];

  return (
    <Select
      value={value?.value || ''}
      error={hasError}
      errorMessage={error}
      fullWidth
      id={id}
      label={label}
      onChange={e => onChange(options.find(option => option.value === e.target.value))}
      options={options}
    />
  );
};

export default RejectionTypesSelect;
