import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const OrganizationsList = ({organizations, setOrganizations}) => {
    const classes = useStyles();
    const handleChange = (event) => {
        const {name, checked} = event.target;
        setOrganizations(organizations.map(o => {
            if(o.id === name) {
                return {
                    ...o,
                    checked
                }
            }
            return o;
        }));
    };
    return (
        <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
                {organizations.map(o => (
                    <FormControlLabel
                        key={o.id}
                        control={<Checkbox
                            color="primary"
                            checked={o.checked} onChange={handleChange} name={o.id} />}
                        label={o.description}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

OrganizationsList.propTypes = {
    organizations: PropTypes.array.isRequired,
    setOrganizations: PropTypes.func.isRequired
};

export default OrganizationsList;