import axiosInstance, { CancelToken, isCancel } from '../utils/axiosInstance';
import Currency from '../domain/Currency';

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAllCurrencies = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllCurrencies = async () => {
  try {
    const currencies = await axiosInstance.post(
      '/Moneda/sel',
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c)),
      },
    );
    return currencies.data.map(currency => Currency.map(currency));
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};
