import React from "react";
import {DefaultStyledTableRow, StyledTableCell} from "./styles";
import TableCellWithSorting from "../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({orderBy, order, onSort, isGuardian}) => {
  return (
    <DefaultStyledTableRow>
      <StyledTableCell />
      <TableCellWithSorting
        property="name"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id={"guardian.advances.table.driver"} />
      </TableCellWithSorting>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.tags"} />
      </StyledTableCell>
        <StyledTableCell align="left">
            <IntlMessages id={"petty.cash.amount"} />
        </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.creation.date"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.latest.update.date"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.travel"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.route"} />
      </StyledTableCell>
        <StyledTableCell align="left">
            <IntlMessages id={"route"} /> {' '} <IntlMessages id={"denomination"} />
        </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.advance"} />
      </StyledTableCell>
        <StyledTableCell align="left">
        <IntlMessages id={"extension.amount"} />
      </StyledTableCell>
        <StyledTableCell align="left">
        <IntlMessages id={"refund.amount"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.expense"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.approved"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.table.balance"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"guardian.advances.header.pending"} />
      </StyledTableCell>

      {!isGuardian && (
        <StyledTableCell align="left">
          <IntlMessages id={"guardians.operative.plannings.table.actions"} />
        </StyledTableCell>
      )}
    </DefaultStyledTableRow>
  );
};

export default TableHeader;
