import IntlMessages from "../../../../utils/IntlMessages";
import {PostAdd} from "@material-ui/icons";
import React from "react";
import {getUserPermissions} from "../../../../../services/StorageService";


const getUserConfigOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 1) && options.push({
        name: <IntlMessages id={"sidebar.configuration.user.settings.users"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/configurations/user-settings/users',
    });
    submodules.some(s => s.id === 2) && options.push({
        name: <IntlMessages id={"sidebar.configuration.user.settings.modules"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/configurations/user-settings/modules',
    });
    submodules.some(s => s.id === 3) && options.push({
        name: <IntlMessages id={"sidebar.configuration.user.settings.roles"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/configurations/user-settings/roles',
    });
    submodules.some(s => s.id === 4) && options.push({
        name: <IntlMessages id={"sidebar.configuration.user.settings.organization.modules"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/configurations/user-settings/organization-modules',
    });
    submodules.some(s => s.id === 73) && options.push({
        name: <IntlMessages id={"configuration.user.settings.vehicle.roles.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/configurations/user-settings/vehicle-roles',
    });
    return options;
}


export const getConfigurationsOptions = () => {
    const permissions = getUserPermissions();
    let config = {
        name: <IntlMessages id={"sidebar.configuration"}/>,
        type: 'section',
        children: []
    };
    const userConfigSubmodules = permissions.find(p => p.id===1)?.submodules;
    if(userConfigSubmodules?.length > 0) {
        const userConfigOptions = getUserConfigOptions(userConfigSubmodules);
        userConfigOptions?.length> 0 && config.children.push({
            name: <IntlMessages id={"sidebar.configuration.user.settings"}/>,
            type: 'collapse',
            children: userConfigOptions
        });
    }

    return config.children.length > 0? config : null;
}