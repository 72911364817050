import Advance from "../domain/Advance";
import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import PendingStates from "../models/Guardian/PendingStates";
import Response from "../domain/Response"
import {getCompanyEntityId, getUserId, getUserName} from "./StorageService";

let fetchAllCancelToken = undefined;
let fetchAllByDriver = undefined;
let fetchAllByPettyCash = undefined;

export const cancelPrevFetchAllAdvancesByGuardian = () => {
    fetchAllCancelToken && fetchAllCancelToken();
};

export const cancelPrevFetchAllAdvancesByDriver = () => {
    fetchAllByDriver && fetchAllByDriver();
}
export const cancelPrevFetchAllAdvancesByPettyCash = () => {
    fetchAllByPettyCash && fetchAllByPettyCash();
}


export const fetchAllAdvancesByGuardian = async filters => {
    try {
        const isUnresolved = window.location.pathname.includes('unresolved');
        const advances = await axiosInstance.post(
            '/Caja_Chica_Anticipo/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_entidad_gestor_caja: isUnresolved ? null : getUserId(),
                // nu_id_entidad_supervisor: getUserType() === 'supervisor' ? getUserId() : null,
                nu_id_estado_anticipo: filters.state === PendingStates.ALL ? null : filters.state,
                vc_nombre_conductor: filters.driver || null,
                nu_importe: filters.box || null,
                vc_viaje: filters.travel || null,
                vc_route: filters.route || null,
                dt_fec_inicio: filters.lowerDate || null,
                dt_fec_final: filters.upperDate || null,
            },
            {
                cancelToken: new CancelToken(c => (fetchAllCancelToken = c)),
            },
        );
        return advances.data.map(advance => Advance.map(advance))
    } catch (e) {
        if (isCancel(e)) {
            return [];
        }
        return Promise.reject(e);
    }
};


export const liquidateAdvance = async advance => {
    try {
        const result = await axiosInstance.post(
            '/Caja_Chica_Anticipo/upd_rendir',
            {
                nu_id_anticipo: advance.id,
                vc_usuario: "USUARIO",
            }
        );
        const response = Response.map(result.data);
        if (response.transactionState !== "0") {
            return response.transactionKey;
        }
        throw Error(response.transactionMessage);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllAdvancesByDriver = async (driverId, pettyCashId) => {
    try {
        const advances = await axiosInstance.post(
            '/Caja_Chica_Anticipo/sel_filtro_conductor',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_entidad_conductor: driverId,
                nu_id_caja_chica: pettyCashId,

            },
            {
                cancelToken: new CancelToken(c => (fetchAllByDriver = c)),
            }
        );
        return advances.data.map(data => Advance.map(data));
    }catch (e) {
        if (isCancel(e)) {
            return [];
        }
        return Promise.reject(e);
    }
}

export const fetchAllAdvancesByPettyCash = async pettyCashId => {
  try {
      const advances = await axiosInstance.post(
          '/Caja_Chica_Anticipo/sel_filtro_caja',
          {
              nu_id_entidad: getCompanyEntityId(),
              nu_id_caja_chica: pettyCashId,
          },
          {
              cancelToken: new CancelToken(c => (fetchAllByPettyCash = c)),
          }
      );
      return advances.data.map(data => Advance.map(data)).filter(advance => advance.state.id === 2);
  } catch (e) {
      if (isCancel(e)) {
          return [];
      }
      return Promise.reject(e);
  }
}

export const insertAdvance = async newAdvance => {
    try {
        const result = await axiosInstance.post(
            '/Caja_Chica_Anticipo/ins',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_anticipo: newAdvance.travel === "new"? null: newAdvance.travel,
                nu_id_caja_chica: newAdvance.box,
                nu_id_entidad_conductor: newAdvance.driver.id,
                nu_id_tipo_anticipo: newAdvance.advanceType,
                nu_id_ot: newAdvance.route? newAdvance.route : null,
                vc_desc_anticipo: "prueba",
                vc_observacion: newAdvance.observations,
                nu_importe: parseFloat(newAdvance.amount),
                vc_usuario: "USUARIO",
            }
        );
        const response = Response.map(result.data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    } catch (e) {
        return Promise.reject(e);
    }

}

export const rejectAdvance = async advance => {
    try {
        const result = await axiosInstance.post(
            '/Caja_Chica_Anticipo/upd_rechazar',
            {
                nu_id_anticipo: advance.id,
                vc_usuario: getUserName(),
            }
        )
        const response = Response.map(result.data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}

export const approveAdvance = async advance => {
    try {
        const result = await axiosInstance.post(
            '/Caja_Chica_Anticipo/upd_aprobar_supervisor',
            {
                nu_id_anticipo: advance.id,
                vc_usuario: getUserName(),
            }
        )
        const response = Response.map(result.data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
}
