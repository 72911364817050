import React from "react";
import {Box, Typography} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const DeviceStateItem = ({device, name, setDevice}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      marginX="8px"
      style={{cursor: "pointer"}}
      onClick={() => setDevice({name, ...device})}
    >
      <Typography variant="body2">{name}</Typography>
      <FiberManualRecordIcon
        style={{marginRight: "8px"}}
        color={device.state ? "primary" : "secondary"}
      />
    </Box>
  );
};

export default DeviceStateItem;
