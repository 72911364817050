import React from "react";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.headquarter.name.error.empty" />
      )
    }
  ],
  address: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.address.error.empty" />
      )
    }
  ],
  number: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.number.error.empty" />
      )
    }
  ],
  postalCode: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.postal.code.error.empty" />
      )
    }
  ],
  municipality: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.municipality.error.empty" />
      )
    }
  ],
  province: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.province.error.empty" />
      )
    }
  ],
  country: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.country.error.empty" />
      )
    }
  ],
  latitude: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.latitude.error.empty" />
      )
    }
  ],
  longitude: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.clients.add.headquarter.form.longitude.error.empty" />
      )
    }
  ]
};
