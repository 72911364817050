import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CheckStockBodyUniques from "./CheckStockBodyUniques";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const CheckStockBodyProduct = ({
  driver,
  setSelectedAdvance,
  isGuardian = true
}) => {
  const rows = 6;
  const unicos = [];
  for (const i in driver) {
    const aux = false;
    const count = 0;
    for (const j in unicos) {
      if (unicos[j][1] === driver[i][1]) {
        aux = true;
      }
    }
    if (!aux) {
      unicos.push(driver[i]);
    }
  }

  return (
    <React.Fragment>
      {unicos.map(element => (
        <CheckStockBodyUniques
          data={driver}
          driver={element}
          setSelectedAdvance={setSelectedAdvance}
          isGuardian={isGuardian}
        />
      ))}
    </React.Fragment>
  );
};

export default CheckStockBodyProduct;
