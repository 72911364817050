import React from "react";
import PropTypes from "prop-types";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import MultipleSelect from "../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {fetchAllVehicleSelect} from "../../../../../services/VehicleServiceSelect";

const VehiclesPicker = ({value, onChange, error, hasError, disabled, label=(<IntlMessages id={"fleet.management.vehicle.reports.table.vehicle"} />)}) => {
  const {data: vehicles} = useFetch(fetchAllVehicleSelect, 2, []);
  return (
    <MultipleSelect
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      options={vehicles}
      disabled={disabled}
      limitTags={2}
    />
  );
};

VehiclesPicker.propTypes = {};

export default VehiclesPicker;
