class ContractType {
  constructor(id, entityId, description, time, margin, active) {
    this.id = id;
    this.entityId = entityId;
    this.description = description;
    this.time = time;
    this.active = active;
    this.margin = margin;
  }

  static map = type => {
    return new ContractType(
      type.nu_id_fichaje_tipo,
      type.nu_id_entidad_empresa,
      type.vc_desc,
      type.nu_tiempo_jornada,
      type.nu_tiempo_jornada_margen,
      type.bi_activo === "True"
    );
  };
}

export default ContractType;
