import React from "react";
import TableContainer from "../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {useDispatch, useSelector} from "react-redux";
import CrudTableRow from "../../../Common/Tables/CrudTable/CrudTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import {
  fetchAllWorkOrdersTypes,
  setCurrentWorkOrderType
} from "../../../../redux/actions/Logistic/WorkOrdersTypes";
import RestoreTableRow from "../../../Common/Tables/CrudTable/RestoreTableRow";

const WorkOrdersTypesTable = ({openEdit, openDelete, openRestore}) => {
  const {data} = useSelector(({logistic}) => logistic.workOrdersTypes);
  const dispatch = useDispatch();
  const onEdit = type => {
    openEdit && openEdit();
    dispatch(setCurrentWorkOrderType(type));
  };
  const onDelete = type => {
    openDelete && openDelete();
    dispatch(setCurrentWorkOrderType(type));
  };
  const onRestore = type => {
    openRestore && openRestore();
    dispatch(setCurrentWorkOrderType(type));
  };
  React.useEffect(() => {
    dispatch(fetchAllWorkOrdersTypes());
  }, []);
  return (
    <TableContainer head={<TableHeader />}>
      {Object.values(data).map(
        type =>
          type.status ? (
            <CrudTableRow
              key={type.id}
              onEdit={() => onEdit(type)}
              onDelete={() => onDelete(type)}
            >
              <StyledTableCell>{type.name}</StyledTableCell>
              <StyledTableCell>{type.statesNames}</StyledTableCell>
            </CrudTableRow>
          ) : (
            <RestoreTableRow key={type.id} onRestore={() => onRestore(type)}>
              <StyledTableCell>{type.name}</StyledTableCell>
              <StyledTableCell>{type.statesNames}</StyledTableCell>
            </RestoreTableRow>
          )
      )}
    </TableContainer>
  );
};

export default WorkOrdersTypesTable;
