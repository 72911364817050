import React from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ]
};
