import React from "react";

import PageContainer from "../../../../../components/FleetManagement/DailyOperation/Reserves/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {resetReservesState} from "../../../../../redux/actions/FleetManagement/Reserves";
import ReservesTable from "../../../../../components/FleetManagement/DailyOperation/Reserves/ReservesTable";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddReserveDialog from "../../../../../components/FleetManagement/DailyOperation/Reserves/AddReserveDialog";
import EditReserveDialog from "../../../../../components/FleetManagement/DailyOperation/Reserves/EditReserveDialog";

const Reserves = () => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.reserves
  );
  const dispatch = useDispatch();
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetReservesState());
    };
  }, []);
  return (
    <PageContainer>
      <ReservesTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd
        onAddClicked={() => {
          setOpenAddDialog(true);
        }}
      />
      {openAddDialog && (
        <AddReserveDialog open={openAddDialog} setOpen={setOpenAddDialog} />
      )}
      {openEditDialog &&
      current && (
        <EditReserveDialog open={openEditDialog} setOpen={setOpenEditDialog} />
      )}
    </PageContainer>
  );
};

export default Reserves;
