import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  sidebarHeaderRoot: {
    padding: "15px 16px 5px"
  },
  searchRoot: {
    position: "relative",
    width: "100%",
    "& .MuiSvgIcon-root": {
      position: "absolute",
      left: 10,
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      color: theme.palette.text.secondary,
      fontSize: 20
    },
    "& .MuiInputBase-root": {
      width: "100%"
    },
    "& .MuiInputBase-input": {
      height: 36,
      borderRadius: 4,
      border: `1px solid ${theme.palette.borderColor.main}`,
      color: theme.palette.text.disabled,
      boxSizing: "border-box",
      padding: "5px 15px 5px 35px",
      transition: "all 0.3s ease",
      fontSize: 12,
      "&:focus": {
        borderColor: theme.palette.primary.main
      }
    }
  }
}));
