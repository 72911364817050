import {
  CLEAR_ADVANCES_LIST_FILTER,
  SET_ADVANCES_LIST_FILTER
} from "../../types/CustomTypes";

export const clearAdvancesListFilter = () => {
  return {
    type: CLEAR_ADVANCES_LIST_FILTER
  };
};

export const setAdvancesListFilter = state => {
  return {
    type: SET_ADVANCES_LIST_FILTER,
    payload: state
  };
};
