import React from "react";
import FloatingSpeedDial from "../../../Guardian/Common/Buttons/SpeedDial";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import AddBoxIcon from "@material-ui/icons/AddBox";
import {useDispatch} from "react-redux";
import {
  setCreateWorkOrder,
  setCurrentWorkOrder
} from "../../../../redux/actions/Administrator/WorkOrders";

const WorkOrderFloatingButton = props => {
  const dispatch = useDispatch();
  const actions = [
    {
      icon: <AddBoxIcon />,
      name: <IntlMessages id={"logistic.work.orders.floating.add"} />,
      key: "logistic.work.orders.add",
      onCLick: () => {
        dispatch(setCreateWorkOrder());
      }
    }
  ];

  return <FloatingSpeedDial actions={actions} />;
};

export default WorkOrderFloatingButton;
