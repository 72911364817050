import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  vehicle: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.vehicle.error.empty" />
      )
    }
  ],
  start: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.start.error.empty" />
      )
    }
  ],
  end: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.end.error.empty" />
      )
    }
  ],
  route: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.route.error.empty" />
      )
    }
  ],
  turn: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.turn.error.empty" />
      )
    }
  ],
  passengers: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.passengers.error.empty" />
      )
    }
  ]
};
