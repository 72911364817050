import React, {useEffect} from "react";
import { useDispatch,useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';

import { getCompanyEntityId } from '../../../services/StorageService';
import selectCampus from '../../../redux/actions/Administrator/CampusOriginSelect';

const CampusOriginSelect = ({id, label, hasError, error, value, onChange, entityId, disabled}) => {
    if(value?.value != undefined){value = value.value}
    const {data} = useSelector(state => state.administrator.campusOrigin);

    const dispatch = useDispatch();
    useEffect(() => {
        if(data.length === 0){
            dispatch(selectCampus.get(entityId));
        }
    },[entityId,dispatch])
    
    const options = [
        {value:'', label: 'Seleccione una opción'},
        ...data.filter(type => type.active !== 0).sort((a,b) => a.campus > b.campus ? 1 : -1).map(type => ({value: type.id, label: type.campus})),
    ];

    return(
        <Select
            value={value}
            error={hasError}
            errorMessage={error}
            fullWidth
            id={id}
            label={label}
            onChange={e => onChange(options.find(option => option.value === e.target.value))}
            options={options}
            disabled={disabled}
        />
    );
};

export default CampusOriginSelect