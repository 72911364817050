class KoneIndicator {
    constructor(id, description) {
        this.id = id;
        this.description = description;
    }

    static map = indicator => {
        return new KoneIndicator(
          indicator.nu_id_informe,
            indicator.vc_informe
        );
    }
}

export default KoneIndicator;