import React from "react";
import StyledTableRow from "../StyledTableRow";
import {StyledTablePagination} from "../../../FleetManagement/Vehicles/File/VehiclesTable/styles";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const PaginationFooter = ({
  data,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  text = ""
}) => {
  const rows = data.length;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <StyledTableRow>
      <StyledTablePagination
        rowsPerPageOptions={[ 5, 10, 15, {label: "Todos", value: -1} ]}
        count={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        labelRowsPerPage={
          text || <IntlMessages id={"table.footer.pagination.text"} />
        }
        SelectProps={{
          native: true,
          style: {
            backgroundColor: "#8092e3",
            border: "solid",
            borderRadius: "8px",
            borderColor: "white"
          }
        }}
        align="center"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </StyledTableRow>
  );
};

export default PaginationFooter;
