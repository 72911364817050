import React, {useCallback} from "react";
import DialogBase from "../../../../../Common/Dialogs/DialogBase";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Button} from "@material-ui/core";
import VehiclesTable from "../VehiclesTable";
import Filters from "./Filters";
import {connect, useDispatch} from "react-redux";
import {
  applyAssigmentVehicleFilters,
  fetchAssigmentVehiclesStarted
} from "../../../../../../redux/actions/Logistic/Assigment";
import FilterBuilder from "../../../../../../utils/filters";

const VehiclesDialog = ({
  vehicles,
  open,
  setOpen,
  selectedVehicles,
  setSelectedVehicles
}) => {
  const dispatch = useDispatch();
  const [ vehiclesData, setVehiclesData ] = React.useState([]);

  const applyFilters = useCallback(
    filters => dispatch(applyAssigmentVehicleFilters(filters)),
    [ dispatch ]
  );

  React.useEffect(() => {
    dispatch(fetchAssigmentVehiclesStarted());
  }, []);
  React.useEffect(
    () => {
      setVehiclesData(
        vehicles.map(v => ({
          ...v,
          checked: selectedVehicles.some(s => s.id === v.id)
        }))
      );
    },
    [ vehicles ]
  );

  const [ selectAll, setSelectAll ] = React.useState(false);
  React.useEffect(
    () => {
      selectAll &&
        setVehiclesData(
          vehiclesData.map(vehicle => ({...vehicle, checked: true}))
        );
    },
    [ selectAll ]
  );
  React.useEffect(
    () => {
      if (vehiclesData.some(vehicle => !vehicle.checked)) {
        setSelectAll(false);
      }
    },
    [ vehiclesData ]
  );

  const actions = [
    <Button key="cancel-button" onClick={() => setOpen(false)}>
      <IntlMessages id={"Logistic.assigment.vehicle.dialog.cancel"} />
    </Button>,
    <Button
      key="ok-button"
      onClick={() => {
        setSelectedVehicles([ ...vehiclesData.filter(v => v.checked) ]);
        setOpen(false);
      }}
    >
      <IntlMessages id={"Logistic.assigment.vehicle.dialog.ok"} />
    </Button>
  ];
  return (
    <DialogBase
      open={open}
      close={() => setOpen(false)}
      title={<IntlMessages id={"Logistic.assigment.vehicle.dialog.title"} />}
      actions={actions}
    >
      <Filters
        selectAll={selectAll}
        setSelectAll={setSelectAll}
        onApplyFilters={applyFilters}
      />
      <VehiclesTable
        isDialog
        vehicles={vehiclesData}
        setSelectedVehicles={setVehiclesData}
      />
    </DialogBase>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.logistic.assigment.vehicles;
  const filteredData = new FilterBuilder(data)
    .byFieldIncludes("vehicle", filters.vehicle)
    .byFieldIncludes("capacity", filters.capacity)
    .build();
  return {vehicles: filteredData};
};

export default connect(mapStateToProps)(VehiclesDialog);
