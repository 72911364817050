import React from "react";
import BaseMap from "../BaseMap";
import {generateRoute} from "../../../../../services/MapService";

const MapWithRoute = ({wayPoints = [], centerMap, setWayPoints}) => {
  const [ coordsPointer, setCoordsPointer ] = React.useState([]);

  React.useEffect(
    () => {
      generateRoute(wayPoints, setCoordsPointer);
    },
    [ wayPoints ]
  );

  return (
    <BaseMap
      mapWayPoints={wayPoints}
      setMapWayPoints={setWayPoints}
      centerMap={centerMap}
      coordsPointer={coordsPointer}
    />
  );
};
export default MapWithRoute;
