import {minutesToTime} from "../utils/parsers";

class DestinationBreakdown {
  constructor(id, route, realTime, estimatedTime, updatesHistory, history) {
    this.id = id;
    this.route = route;
    this.realTime = realTime;
    this.estimatedTime = estimatedTime;
    this.updatesHistory = updatesHistory;
    this.history = history;
  }

  static map = destination => {
    return new DestinationBreakdown(
      destination.id,
      {
        origin: destination.origen,
        destination: destination.destino,
        planningPlace: destination.puestoPlanificacion
      },
      {
        timeWindow: `${minutesToTime(
          destination.entradaVentana
        )} - ${minutesToTime(destination.salidaVentana)}`,
        arrivalOutTime: `${minutesToTime(
          destination.entrada
        )} - ${minutesToTime(destination.salida)}`,
        arrivalTime: minutesToTime(destination.entrada),
        usedTime: minutesToTime(destination.tiempo),
        clientTime: minutesToTime(destination.tiempoEnCliente),
        traveledKilometers: destination.distancia.toFixed(2),
        clientKilometers: destination.distanciaEnCliente.toFixed(2),
        inTime: true
      },
      {
        didTimes: destination.nVecesRealizada,
        average: minutesToTime(destination.tiempoMedio),
        clientTime: minutesToTime(destination.tiempoMedioEnCliente),
        traveledKilometers: destination.distanciaMedia.toFixed(2),
        clientKilometers: destination.distanciaMediaEnCliente.toFixed(2),
        googleTime: minutesToTime(destination.tiempoGoogle),
        centralTime: minutesToTime(destination.tiempoMatriz),
        googleKilometers: destination.distanciaGoogle.toFixed(2),
        centralKilometers: destination.distanciaMatriz.toFixed(2)
      },
        [],
        []
    );
  };
}

export default DestinationBreakdown;
