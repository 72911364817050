import React, {useState} from "react";

import MuiTableRow from "@material-ui/core/TableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import IconButton from "@material-ui/core/IconButton";

const TableRow = ({rowData}) => {
  const [ open, setOpen ] = useState();

  const totalAmount = rowData.data.reduce(
    (sum, e) => Number(sum) + Number(e.amount),
    0
  );
  const totalCommitted = rowData.data.reduce(
    (sum, e) => Number(sum) + Number(e.stockCommitted),
    0
  );
  const totalAvailable = rowData.data.reduce(
    (sum, e) => Number(sum) + Number(e.stockAvailable),
    0
  );

  const header = (
    <MuiTableRow>
      <StyledTableCell align="left">
        <IconButton>
          {open ? (
            <RemoveIcon onClick={() => setOpen(false)} />
          ) : (
            <AddIcon onClick={() => setOpen(true)} />
          )}
        </IconButton>
      </StyledTableCell>
      <StyledTableCell align="left">{rowData.label}</StyledTableCell>
      <StyledTableCell align="left">varios</StyledTableCell>
      <StyledTableCell align="left">varios</StyledTableCell>
      <StyledTableCell align="left">varios</StyledTableCell>
      <StyledTableCell align="left">{totalAmount}</StyledTableCell>
      <StyledTableCell align="left">{totalAvailable}</StyledTableCell>
      <StyledTableCell align="left">{totalCommitted}</StyledTableCell>
      <StyledTableCell align="left">varios</StyledTableCell>
    </MuiTableRow>
  );

  return (
    <React.Fragment>
      {header}
      {open &&
        rowData.data.map(e => (
          <MuiTableRow>
            <StyledTableCell />
            <StyledTableCell align="left">{e.entity}</StyledTableCell>
            <StyledTableCell align="left">{e.campus}</StyledTableCell>
            <StyledTableCell align="left">{e.warehouse}</StyledTableCell>
            <StyledTableCell align="left">{e.product}</StyledTableCell>
            <StyledTableCell align="left">{e.amount}</StyledTableCell>
            <StyledTableCell align="left">{e.stockAvailable}</StyledTableCell>
            <StyledTableCell align="left">{e.stockCommitted}</StyledTableCell>
            {/*<StyledTableCell align="left">
              {e.tags.map((tag, index) => (
                <div key={index}>{tag}</div>
              ))}
              </StyledTableCell>*/}
            <StyledTableCell align="left">{e.tags}</StyledTableCell>
          </MuiTableRow>
        ))}
    </React.Fragment>
  );
};

export default TableRow;
