import ui from "./ui";
import {
  CREATE_TAG_STARTED,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_TYPE_STARTED,
  CREATE_TAG_TYPE_SUCCESS,
  DELETE_TAG_STARTED,
  DELETE_TAG_SUCCESS,
  DELETE_TAG_TYPE_STARTED,
  DELETE_TAG_TYPE_SUCCESS,
  EDIT_TAG_STARTED,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_TYPE_STARTED,
  EDIT_TAG_TYPE_SUCCESS,
  FETCH_ALL_TAG_TYPES_STARTED,
  FETCH_ALL_TAG_TYPES_SUCCESS,
  FETCH_ALL_TAGS_BY_TYPE_STARTED,
  FETCH_ALL_TAGS_BY_TYPE_SUCCESS,
  RESET_CURRENT_TAG_TYPE,
  SET_CURRENT_TAG_TYPE
} from "../../types/Administrator/TagsTypes";
import {
  createTagType,
  deleteTagType,
  fetchAllTagTypes,
  updateTagType
} from "../../../services/TagTypeService";
import {
  createTag,
  deleteTag,
  fetchAllTagsByType,
  updateTag
} from "../../../services/TagService";

const fetchAllTagTypesSuccess = types => {
  return dispatch => {
    dispatch(ui.fetchSucceeded());
    dispatch({type: FETCH_ALL_TAG_TYPES_SUCCESS, payload: types});
  };
};

export const fetchAllTagTypesStarted = () => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_TAG_TYPES_STARTED});
    dispatch(ui.fetchStarted());
    fetchAllTagTypes().then(tags => {
      dispatch(fetchAllTagTypesSuccess(tags));
    });
  };
};

const createTagTypeSuccess = response => {
  return dispatch => {
    dispatch({
      type: CREATE_TAG_TYPE_SUCCESS,
      payload: response.transactionMessage
    });
    dispatch(ui.createSucceeded());
    dispatch(fetchAllTagTypesStarted());
  };
};

export const createTagTypeStarted = data => {
  return async dispatch => {
    dispatch(ui.closeCreateForm());
    dispatch(ui.createStarted());
    dispatch({type: CREATE_TAG_TYPE_STARTED, payload: data});
    createTagType(data)
      .then(response => {
        dispatch(createTagTypeSuccess(response));
      })
      .catch(e => {
        dispatch(ui.createFailed());
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

export const setCurrentTagType = type => {
  return dispatch => {
    dispatch({type: SET_CURRENT_TAG_TYPE, payload: type});
    dispatch(fetchAllTagsByTypeStarted(type));
  };
};
export const resetCurrentTagType = () => ({type: RESET_CURRENT_TAG_TYPE});

const editTagTypeSuccess = response => {
  return dispatch => {
    dispatch({
      type: EDIT_TAG_TYPE_SUCCESS,
      payload: response.transactionMessage
    });
    dispatch(ui.editSucceeded());
    dispatch(fetchAllTagTypesStarted());
  };
};

export const editTagTypeStarted = data => {
  return async dispatch => {
    dispatch(ui.closeEditForm());
    dispatch(ui.editStarted());
    dispatch({type: EDIT_TAG_TYPE_STARTED, payload: data});
    updateTagType(data)
      .then(response => {
        dispatch(editTagTypeSuccess(response));
      })
      .catch(e => {
        dispatch(ui.editFailed());
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};
const deleteTagTypeSuccess = response => {
  return dispatch => {
    dispatch({
      type: DELETE_TAG_TYPE_SUCCESS,
      payload: response.transactionMessage
    });
    dispatch(fetchAllTagTypesStarted());
  };
};

export const deleteTagTypeStarted = id => {
  return async dispatch => {
    dispatch({type: DELETE_TAG_TYPE_STARTED, payload: id});
    deleteTagType(id)
      .then(response => {
        dispatch(deleteTagTypeSuccess(response));
      })
      .catch(e => {
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

const fetchAllTagsByTypeSuccess = tags => {
  return dispatch => {
    dispatch({type: FETCH_ALL_TAGS_BY_TYPE_SUCCESS, payload: tags});
  };
};

export const fetchAllTagsByTypeStarted = type => {
  return dispatch => {
    dispatch({type: FETCH_ALL_TAGS_BY_TYPE_STARTED});
    fetchAllTagsByType(type.id)
      .then(tags => {
        dispatch(fetchAllTagsByTypeSuccess(tags));
      })
      .catch(e => {
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

const createTagSuccess = (response, type) => {
  return dispatch => {
    dispatch({
      type: CREATE_TAG_SUCCESS,
      payload: response.transactionMessage
    });
    dispatch(fetchAllTagsByTypeStarted(type));
  };
};

export const createTagStarted = (data, type) => {
  return async dispatch => {
    dispatch({type: CREATE_TAG_STARTED, payload: data});
    createTag({description: data.description, type: type.id})
      .then(response => {
        dispatch(createTagSuccess(response, type));
      })
      .catch(e => {
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

const editTagSuccess = response => {
  return dispatch => {
    dispatch({
      type: EDIT_TAG_SUCCESS,
      payload: response.transactionMessage
    });
    dispatch(fetchAllTagTypesStarted());
  };
};

export const editTagStarted = data => {
  return async dispatch => {
    dispatch({type: EDIT_TAG_STARTED, payload: data});
    updateTag(data)
      .then(response => {
        dispatch(editTagSuccess(response));
      })
      .catch(e => {
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

const deleteTagSuccess = response => {
  return dispatch => {
    dispatch({type: DELETE_TAG_SUCCESS, payload: response.transactionMessage});
    dispatch(fetchAllTagTypesStarted());
  };
};

export const deleteTagStarted = id => {
  return async dispatch => {
    dispatch({type: DELETE_TAG_STARTED, payload: id});
    deleteTag(id)
      .then(response => {
        dispatch(deleteTagSuccess(response));
      })
      .catch(e => {
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};
