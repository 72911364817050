import React from "react";
import {useSelector} from "react-redux";
import Autocomplete from "../../../components/Administrator/Form/SupervisorsAutocomplete";

const ReceiversAutocomplete = ({hasError, error, value, onChange}) => {
  const {entities} = useSelector(state => state.customCommon.entities);

  return (
    <Autocomplete
      hasError={hasError}
      error={error}
      options={entities}
      value={value}
      onChange={onChange}
    />
  );
};

export default ReceiversAutocomplete;
