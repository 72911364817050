export const APPROVE_ADVANCE_STARTED = "APPROVE_ADVANCE_STARTED";
export const APPROVE_ADVANCE_RESULT = "APPROVE_ADVANCE_RESULT";

export const REJECT_ADVANCE_STARTED = "REJECT_ADVANCE_STARTED";
export const REJECT_ADVANCE_RESULT = "REJECT_ADVANCE_RESULT";

export const APPROVE_EXPENSE_STARTED = "APPROVE_EXPENSE_STARTED";
export const APPROVE_EXPENSE_RESULT = "APPROVE_EXPENSE_RESULT";

export const REJECT_EXPENSE_STARTED = "REJECT_EXPENSE_STARTED";
export const REJECT_EXPENSE_RESULT = "REJECT_EXPENSE_RESULT";

export const RESET_UNRESOLVED_ADVANCE_STATE = "RESET_UNRESOLVED_ADVANCE_STATE";

export const UPDATE_UNRESOLVED_EXPENSE_STATE_STARTED =
  "UPDATE_UNRESOLVED_EXPENSE_STATE_STARTED";
export const UPDATE_UNRESOLVED_EXPENSE_STATE_RESULT =
  "UPDATE_UNRESOLVED_EXPENSE_STATE_RESULT";
