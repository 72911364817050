import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    addBill,
    cancelBill,
    deleteBill,
    editBill,
    fetchBillFile,
    getAllBills,
    getBillServices,
    payBill,
    sentSunatBill
} from "../../../services/Treasury/BillsService";
import {downloadFile} from "../../../services/FileService";

const baseNamespace = 'treasury/bills';
export const fetchAllBills = createAsyncThunk(
    `${baseNamespace}/fetchAllBills`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const bills = await getAllBills({});
            dispatch(fetchSuccess());
            return {bills};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getAndSetBillDetails = createAsyncThunk(
    `${baseNamespace}/getAndSetBillDetails`,
    async ({bill}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const services = await getBillServices(bill.id);
            dispatch(fetchSuccess());
            return {
                ...bill,
                services,
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const createBill = createAsyncThunk(
    `${baseNamespace}/createBill`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addBill({request});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllBills());
            }, 1500);
            return {
                response
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateBill = createAsyncThunk(
    `${baseNamespace}/updateBill`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editBill({request});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllBills());
            }, 1500);
            return response;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const removeBill = createAsyncThunk(
    `${baseNamespace}/removeBill`,
    async ({billId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await deleteBill({billId});
            dispatch(fetchSuccess(response.transactionMessage));
            return {billId};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const paySelectedBills = createAsyncThunk(
    `${baseNamespace}/billSelectedBills`,
    async ({selectedBills}, {dispatch, rejectWithValue}) => {
        try {
            if(selectedBills.length === 0){
                throw new Error('No se ha seleccionado ningún factura');
            }
            dispatch(fetchStart());
            const responses = await Promise.all(selectedBills.map(billId => payBill({billId})));
            dispatch(fetchSuccess('Facturas pagadas correctamente'));
            setTimeout(() => {
                dispatch(fetchAllBills());
            }, 1500);
            return {selectedBills};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const sendSunatSelectedBills = createAsyncThunk(
    `${baseNamespace}/sendSunatSelectedBills`,
    async ({selectedBills}, {dispatch, rejectWithValue}) => {
        try {
            if(selectedBills.length === 0){
                throw new Error('No se ha seleccionado ningún factura');
            }
            dispatch(fetchStart());
            const responses = await Promise.all(selectedBills.map(billId => sentSunatBill({billId})));
            dispatch(fetchSuccess('Facturas enviadas a Sunat correctamente'));
            setTimeout(() => {
                dispatch(fetchAllBills());
            }, 1500);
            return {selectedBills};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const downloadBillFile = createAsyncThunk(
    `${baseNamespace}/downloadBillFile`,
    async ({billId, fileType}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const base64 = await fetchBillFile({billId, fileType});
            downloadFile( `COMPROBANTE.${fileType}`, base64);
            dispatch(fetchSuccess("Descarga exitosa"));
            return;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const cancelSentBill = createAsyncThunk(
    `${baseNamespace}/cancelSentBill`,
    async ({billId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await cancelBill({billId});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllBills());
            }, 1500);
            return;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);