import axiosInstance from "../utils/axiosInstance";
import {getCompanyEntityId} from "./StorageService";
import Central from "../domain/Central";

export const fetchAllCentrals = async () => {
  try {
    const {data} = await axiosInstance.post("/Centros/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(c => Central.map(c));
  } catch (e) {
    return Promise.reject(e);
  }
};
