import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({isDialog}) => (
  <StyledTableRow>
    {!isDialog && <StyledTableCell />}
    <StyledTableCell>
      <IntlMessages id={"logistic.assigment.orders.dialog.table.client"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.assigment.orders.dialog.table.start.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.assigment.orders.dialog.table.end.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.assigment.orders.dialog.table.stay"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.assigment.orders.dialog.table.priority"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
