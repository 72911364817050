import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import RejectionType from "../../../domain/Logistic/Distribution/RejectionType";
import Response from "../../../domain/Response";

export const getAllRejectionTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Retrasos/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(t => RejectionType.map(t));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createRejectionType = async type => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Retrasos/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_retraso: type.description,
      vc_cod_tipo_retraso: type.code
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateRejectionType = async type => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Retrasos/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_retraso: type.id,
      vc_desc_tipo_retraso: type.description,
      vc_cod_tipo_retraso: type.code
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateRejectionTypeStatus = async (type, status) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Retrasos/del", {
      nu_id_tipo_retraso: type.id,
      bi_estado: status
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
