import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    getAllDrivingControlEvents, getAllEventMedias,
    getDrivingEventsExcel
} from "../../../services/Localization/DailyOperation/DrivingControlService";
import {downloadFile} from "../../../services/FileService";

const baseNamespace = 'localization/drivingControl';
export const fetchAllDrivingControlEvents = createAsyncThunk(
    `${baseNamespace}/fetchAllDrivingControlEvents`,
    async ({filters, page, pageSize}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const {events, header} = await getAllDrivingControlEvents({filters, page, pageSize});
            dispatch(fetchSuccess());
            return {events, rowCount: header.totalCount};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const fetchDrivingControlEventsExcel = createAsyncThunk(
    `${baseNamespace}/fetchDrivingControlEventsExcel`,
    async ({filters}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const base64Str = await getDrivingEventsExcel({filters});
            downloadFile(
                `control-data-${Date.now()}.xlsx`,
                `data:application/vnd.ms-excel;base64,${base64Str}`
            );
            dispatch(fetchSuccess());
            return {filters};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getAllEventMedia = createAsyncThunk(
    `${baseNamespace}/getAllEventMedia`,
    async ({event}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            let medias = [];
            if(event.cabinVideoCount) {
                const video = await getAllEventMedias({
                    eventId: event.messageID,
                    mediaType: 'video',
                    deviceId: event.deviceID,
                });
                if(video) {
                    medias = [
                        ...medias,
                        video,
                    ];
                }
            }
            if(event.cabinImageCount) {
                const image = await getAllEventMedias({
                    eventId: event.messageID,
                    mediaType: 'image',
                    deviceId: event.deviceID,
                });
                if(image) {
                    medias = [
                        ...medias,
                        image,
                    ];
                }
            }
            if(event.roadVideoCount) {
                const video = await getAllEventMedias({
                    eventId: event.messageID,
                    mediaType: 'video',
                    deviceId: event.deviceID,
                });
                if(video) {
                    medias = [
                        ...medias,
                        video,
                    ];
                }
            }
            if(event.roadImageCount) {
                const image = await getAllEventMedias({
                    eventId: event.messageID,
                    mediaType: 'image',
                    deviceId: event.deviceID,
                });
                if(image) {
                    medias = [
                        ...medias,
                        image,
                    ];
                }
            }
            dispatch(fetchSuccess());
            return medias;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);