import {alpha, makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  chatCellHeader: {
    backgroundColor: alpha(theme.palette.common.dark, 0.04),
    padding: 16,
    fontSize: 10,
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
    letterSpacing: 1.5,
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.04)}`
  },
  perfectScrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px)`
  },
  chatCellItem: {
    padding: 16,
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover, &.active": {
      backgroundColor: alpha(theme.palette.common.dark, 0.04),
      "& $titleRoot": {
        color: theme.palette.primary.main
      }
    }
  },
  chatCellInfo: {
    width: "calc(100% - 40px)",
    paddingLeft: 16
  },
  titleRoot: {
    position: "relative"
  },
  chatDesRoot: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  chatAvatarRoot: {
    position: "relative"
  },
  statusDot: {
    position: "relative",
    "& .MuiBadge-badge": {
      width: 10,
      height: 10,
      borderRadius: "50%",
      border: `solid 1px ${theme.palette.common.white}`,
      position: "absolute",
      right: 4,
      top: 6,
      zIndex: 1
    }
  },
  badgeOnline: {
    backgroundColor: "#8DCD03"
  },
  badgeAway: {
    backgroundColor: "#FF8C00"
  },
  badgeOffline: {
    backgroundColor: "#C1C1C1"
  }
}));
