import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import TableContainer from "components/Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import {parseNumberToMoney} from "utils/parsers";
import Menu from "components/Common/Buttons/Menu";
import EXPENSE_STATE from "models/Supervisor/ExpenseState";
import {parseDateToGlobalFormat} from "../../../../utils/dates";

const Table = ({data, onApprove, onReject, onFileNameClick, isSupervisor}) => {
  if (data.length === 0) {
    return (
      <Typography variant="h3">
        <IntlMessages id="common.tables.empty" />
      </Typography>
    );
  }

  const createActions = id => [
    {
      name: "approve",
      label: <IntlMessages id="supervisor.unresolvedExpenses.action.approve" />,
      onClick: () => onApprove(id)
    },
    {
      name: "reject",
      label: <IntlMessages id="supervisor.unresolvedExpenses.action.reject" />,
      onClick: () => onReject(id)
    }
  ];

  return (
    <TableContainer head={<TableHeader isSupervisor={isSupervisor} />}>
      {data.map(row => (
        <StyledTableRow key={row.id}>
          <StyledTableCell>{row.driver}</StyledTableCell>
          <StyledTableCell>
            {row.tags.map(tag => <div key={tag}>{tag}</div>)}
          </StyledTableCell>
          <StyledTableCell>{row.costCenter}</StyledTableCell>
          <StyledTableCell>{row.pettyCash}</StyledTableCell>
          <StyledTableCell>{row.event}</StyledTableCell>
          <StyledTableCell>{row.ot}</StyledTableCell>
          <StyledTableCell>{parseDateToGlobalFormat(row.date)}</StyledTableCell>
          <StyledTableCell>{row.documentType}</StyledTableCell>
          <StyledTableCell>{parseNumberToMoney(row.amount)}</StyledTableCell>
          <StyledTableCell>{row.state}</StyledTableCell>
          <StyledTableCell>
            <p
              style={{
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
                width: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap"
              }}
              onClick={() => onFileNameClick(row.supportDocument)}
            >
              {row.supportDocument}
            </p>
          </StyledTableCell>
          <StyledTableCell>{row.reasonOfRejection}</StyledTableCell>
          {isSupervisor && (
            <StyledTableCell align="right">
              {row.state === EXPENSE_STATE.PENDING && (
                <Menu
                  id={`${row.driver}-${row.pettyCash}-expenses-actions`}
                  actions={createActions(row.id)}
                />
              )}
            </StyledTableCell>
          )}
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      driver: PropTypes.string,
      pettyCash: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      event: PropTypes.string,
      documentType: PropTypes.string,
      amount: PropTypes.number,
      state: PropTypes.string,
      supportDocument: PropTypes.string,
      reasonOfRejection: PropTypes.string
    })
  ),
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  onFileNameClick: PropTypes.func,
  isSupervisor: PropTypes.bool.isRequired
};

Table.defaultProps = {
  data: [],
  onApprove: () => {},
  onReject: () => {},
  onFileNameClick: () => {}
};

export default Table;
