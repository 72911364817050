import {
  ADD_ELEVATOR,
  APPLY_ELEVATORS_FILTERS,
  EDIT_ELEVATOR,
  FETCH_ALL_ELEVATORS,
  RESET_CURRENT_ELEVATOR,
  RESET_ELEVATORS_FILTERS,
  RESET_ELEVATORS_STATE,
  SET_CURRENT_ELEVATOR
} from "../../types/ElevatorsManagement/ElevatorsTypes";

const INIT_STATE = {
  data: {},
  filters: {
    headquarter: "",
    name: "",
    state: "",
    contract: "",
    detail: "",
    client: "",
    type: "",
    model: "",
    manufacturer: "",
    category: "",
  },
  current: null
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_ELEVATORS: {
      let elevators = {};
      action.payload.map(e => {
        elevators[e.id] = e;
        return null;
      });
      return {
        ...state,
        data: elevators
      };
    }
    case SET_CURRENT_ELEVATOR: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_ELEVATOR: {
      return {
        ...state,
        current: null
      };
    }
    case ADD_ELEVATOR: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_ELEVATOR: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case APPLY_ELEVATORS_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    }
    case RESET_ELEVATORS_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE
        }
      };
    }
    case RESET_ELEVATORS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
