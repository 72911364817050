import RejectionType from "domain/RejectionType";
import Response from "domain/Response";
import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAll = async () => {
  try {
    const response = await axiosInstance.post(
      "/Caja_Chica_Tipo_Rechazo/sel",
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(type => RejectionType.map(type));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const changeState = async id => {
  try {
    const response = await axiosInstance.post("/Caja_Chica_Tipo_Rechazo/del", {
      nu_id_tipo_rechazo: id,
      nu_id_entidad: getCompanyEntityId()
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update = async (id, data) => {
  try {
    const response = await axiosInstance.post("/Caja_Chica_Tipo_Rechazo/upd", {
      nu_id_tipo_rechazo: id,
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_rechazo: data.description,
      vc_usuario: "USUARIO"
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const create = async data => {
  try {
    const response = await axiosInstance.post("/Caja_Chica_Tipo_Rechazo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_rechazo: data.description,
      vc_usuario: "USUARIO"
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
