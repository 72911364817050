import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import ShowTableRow from "components/Common/Tables/CrudTable/ShowTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import moment from "moment";

const Table = ({data, onEdit, isLoading}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const RenderRow = ({row}) => (
    <ShowTableRow onShow={() => onEdit(row.id)}>
      <StyledTableCell>{row.client}</StyledTableCell>
      <StyledTableCell>{moment(row.date).format("DD/MM/YY")}</StyledTableCell>
      <StyledTableCell>{row.state}</StyledTableCell>
      <StyledTableCell>{row.ot}</StyledTableCell>
      <StyledTableCell>{row.observation}</StyledTableCell>
    </ShowTableRow>
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      client: PropTypes.string,
      date: PropTypes.string,
      state: PropTypes.string,
      ot: PropTypes.string,
      observation: PropTypes.string
    })
  ),
  onShow: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onShow: () => {}
};

export default Table;
