import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import {
  applyTiresFilters,
  resetTiresFilters
} from "../../../../../../redux/actions/FleetManagement/Tires";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";

const Filters = () => {
  const {filters} = useSelector(({fleetManagement}) => fleetManagement.tires);
  const {formData, setFormData, resetForm} = useForm(filters);
  const dispatch = useDispatch();
  const onApplyFilters = () => {
    dispatch(applyTiresFilters(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetTiresFilters());
  };
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-identification"}
              fullWidth
              value={formData.identification}
              onChange={value => setFormData("identification", value)}
              label={
                <IntlMessages
                  id={"fleet.management.tires.table.identification"}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-active"}
              fullWidth
              value={formData.active}
              onChange={value => setFormData("active", value)}
              label={
                <IntlMessages id={"fleet.management.tires.table.active"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-purchase-date"}
              fullWidth
              value={formData.purchaseDate}
              onChange={value => setFormData("purchaseDate", value)}
              label={
                <IntlMessages
                  id={"fleet.management.tires.table.purchase.date"}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-brand"}
              fullWidth
              value={formData.brand}
              onChange={value => setFormData("brand", value)}
              label={<IntlMessages id={"fleet.management.tires.table.brand"} />}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-measures"}
              fullWidth
              value={formData.measures}
              onChange={value => setFormData("measures", value)}
              label={
                <IntlMessages id={"fleet.management.tires.table.measures"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-retreading"}
              fullWidth
              value={formData.retreading}
              onChange={value => setFormData("retreading", value)}
              label={
                <IntlMessages id={"fleet.management.tires.table.retreading"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-pressure"}
              fullWidth
              value={formData.pressure}
              onChange={value => setFormData("pressure", value)}
              label={
                <IntlMessages id={"fleet.management.tires.table.pressure"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"tires-vehicle"}
              fullWidth
              value={formData.vehicle}
              onChange={value => setFormData("vehicle", value)}
              label={
                <IntlMessages id={"fleet.management.tires.table.vehicle"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onResetFilters();
              }}
              text={<IntlMessages id={"filters.button.clear"} />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onApplyFilters();
              }}
              color="primary"
              text={<IntlMessages id={"filters.button.apply"} />}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
