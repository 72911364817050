import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  rejectionType: [
    {
      rule: v => v !== "" && v.value !== "",
      message: (
        <IntlMessages id="supervisor.unresolvedExpenses.rejectExpenseDialog.field.rejectionType.error.empty" />
      )
    }
  ]
};
