import React, {useEffect, useState} from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "../index";
import {useSelector} from "react-redux";

const CurrencySelect = ({id, value, onChange, style, fullWidth,  error, hasError}) => {
  const {currencies} = useSelector(state => state.customCommon.currencies);

  return (
    <Select
      id={id}
      label={
        <IntlMessages id="guardian.operative.plannings.table.header.currency" />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      style={style}
      fullWidth={fullWidth}
      error={hasError}
      errorMessage={error}
    >
      <MenuItem value={""}>
        <IntlMessages id="common.forms.select.activeSelect.option.all" />
      </MenuItem>
      {currencies.map(currency => (
        <MenuItem value={currency.id} key={currency.id}>
          {currency.symbol}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrencySelect;
