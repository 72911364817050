import React from "react";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import Form from "./Form";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";

const BaseDialog = ({id, open, setOpen, initData, onSubmit, title}) => {
  return (
    <FormDialog
      id={id}
      title={title}
      isOpen={open}
      onClose={() => setOpen(false)}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      submitText={<IntlMessages id={"label.form.save"} />}
    >
      <Box style={{overflow: "hidden"}}>
        {open && <Form id={id} initData={initData} onSubmit={onSubmit} />}
      </Box>
    </FormDialog>
  );
};

export default BaseDialog;
