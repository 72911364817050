import React, {useMemo} from "react";
import {useDropzone} from "react-dropzone";
import {Box, Typography, FormHelperText, FormControl} from "@material-ui/core";
import FileList from "./FileList";
import IntlMessages from "@jumbo/utils/IntlMessages";

const baseStyle = {
  cursor: "pointer",
  borderColor: "lightgray"
};

const acceptStyle = {
  borderColor: "#5D9405"
};

const rejectStyle = {
  borderColor: "#F44336"
};

const disabledStyle = {
  cursor: "not-allowed"
};

const FileInput = ({
  label,
  accept,
  maxFiles,
  files = [],
  onChange = () => {},
  error,
  helperText,
  disabled = false
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop: acceptedFiles => onChange(acceptedFiles),
    accept,
    maxFiles
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(error ? rejectStyle : {}),
      ...(disabled ? disabledStyle : {})
    }),
    [ isDragReject, isDragAccept, error, disabled ]
  );

  const onRemoveFile = fileIndex => {
    const newFiles = [ ...files ];
    newFiles.splice(fileIndex, 1);
    onChange(newFiles);
  };

  return (
    <React.Fragment>
      <FormControl error={error} variant="filled" size="small" fullWidth>
        <Box {...getRootProps({className: "dropzone", style})}>
          <input {...getInputProps({disabled})} />
          <Typography style={{fontWeight: "bold", textAlign: "center"}}>
            {label}
          </Typography>
          <Typography style={{fontWeight: "lighter", textAlign: "center"}}>
            <IntlMessages id={"common.forms.fileInput.message"} />
          </Typography>
        </Box>
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
      {files.length > 0 && (
        <aside style={{marginTop: "10px"}}>
          <Typography component="h4" variant="inherit">
            {`Selected file${files.length > 1 ? "s" : ""}:`}
          </Typography>
          <FileList files={files} onRemoveFile={onRemoveFile} />
        </aside>
      )}
    </React.Fragment>
  );
};

export default FileInput;
