import React, {useEffect} from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import {addDaysToDate, parseDateToInput} from "../../../../../../utils/dates";
import VehiclesPicker from "../../../../Common/Selects/VehiclesPicker";
import TypesPicker from "./TypesPicker";
import {applyVehicleReportsFilters} from "../../../../../../redux/actions/FleetManagement/VehicleReports";

const initialData = {
  startDate: parseDateToInput(addDaysToDate(new Date(), -7)),
  endDate: parseDateToInput(new Date()),
  types: [],
  vehicles: []
};

const Filters = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(applyVehicleReportsFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active marginBottom={5} onClose={() => {}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <VehiclesPicker
              value={formData.vehicles}
              onChange={value => setFormData("vehicles", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              value={formData.startDate}
              id="startDate-filter"
              label={
                <IntlMessages id="fleet.management.vehicle.reports.filter.start.date" />
              }
              onChange={value => setFormData("startDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              value={formData.endDate}
              id="endDate-filter"
              label={
                <IntlMessages id="fleet.management.vehicle.reports.filter.end.date" />
              }
              onChange={value => setFormData("endDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TypesPicker
              value={formData.types}
              onChange={value => setFormData("types", value)}
            />
          </Grid>
          {/*<Grid item xs={12} sm={4} lg={3}>*/}
          {/*    <ChecklistTypesSelect*/}
          {/*        value={formData.type}*/}
          {/*        id="type-filter"*/}
          {/*        onChange={value => setFormData('type', value)}*/}
          {/*    />*/}
          {/*</Grid>*/}
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

Filters.propTypes = {};

export default Filters;
