import React from "react";
import PropTypes from "prop-types";

import GrowthCard from "../../../@jumbo/components/Common/GrowthCard";
import {Area, AreaChart, ResponsiveContainer, Tooltip} from "recharts";
import {makeStyles} from "@material-ui/styles";

const useStyles = (tooltipBackgroundColor, tooltipColor) =>
  makeStyles(theme => ({
    tooltip: {
      position: "relative",
      borderRadius: 6,
      padding: "4px 12px",
      backgroundColor: tooltipBackgroundColor || "#8d46ef",
      color: tooltipColor || theme.palette.common.white
    }
  }))();

const DashboardCard = ({
  data,
  dataKey,
  tooltipDataKey,
  growth,
  desc,
  height,
  strokeWidth,
  stroke,
  linearGradient,
  fill,
  fillOpacity,
  tooltipBackgroundColor,
  tooltipColor
}) => {
  const classes = useStyles(tooltipBackgroundColor, tooltipColor);

  return (
    <GrowthCard growth={growth} desc={desc}>
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart data={data} margin={{top: 0, right: 0, left: 0, bottom: 0}}>
          <Tooltip
            cursor={false}
            content={data =>
              data.payload[0] ? (
                <div className={classes.tooltip}>
                  {data.payload[0].payload[tooltipDataKey]}
                </div>
              ) : null}
          />
          <defs>{linearGradient}</defs>
          <Area
            dataKey={dataKey}
            strokeWidth={strokeWidth}
            stackId="2"
            stroke={stroke}
            fill={fill}
            fillOpacity={fillOpacity}
          />
        </AreaChart>
      </ResponsiveContainer>
    </GrowthCard>
  );
};

DashboardCard.propTypes = {
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string.isRequired,
  tooltipDataKey: PropTypes.string.isRequired,
  growth: PropTypes.number.isRequired,
  desc: PropTypes.string.isRequired,
  height: PropTypes.number,
  stroke: PropTypes.string,
  strokeWidth: PropTypes.number,
  fill: PropTypes.string,
  fillOpacity: PropTypes.number,
  tooltipBackgroundColor: PropTypes.string,
  tooltipColor: PropTypes.string,
  linearGradient: PropTypes.node
};

DashboardCard.defaultProps = {
  height: 112,
  strokeWidth: 0,
  stroke: "#6200EE",
  fill: "#6200EE",
  fillOpacity: 1,
  tooltipBackgroundColor: undefined,
  tooltipColor: undefined,
  linearGradient: null
};

export default DashboardCard;
