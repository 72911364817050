import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import CampusOriginSelect from "../domain/CampusOriginSelect";
import Response from "../domain/Response";

let fetchAllCancelToken = undefined;

export const cancelPrevCampusOriginSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllCampusOriginSelect = async id => {
  try {
    const response = await axiosInstance.post(
      "/Entidad_Sedes/sel",
      {nu_id_entidad: id},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(campusOriginSelect =>
      CampusOriginSelect.map(campusOriginSelect)
    );
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
