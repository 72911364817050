import MonitoringDispatchDestination from "./MonitoringDispatchDestination";

class MonitoringDispatchWorkOrder {
    constructor(id, number, driver, vehicle, destinations) {
        this.id = id;
        this.number = number;
        this.driver = driver;
        this.vehicle = vehicle;
        this.destinations = destinations;
    }

    static map = workOrder => {
        return new MonitoringDispatchWorkOrder(
            workOrder.idOT,
            workOrder.ot,
            workOrder.conductor,
            workOrder.vehiculo,
            workOrder.destinos?.map(d => MonitoringDispatchDestination.map(d)),
        )
    }
}

export default MonitoringDispatchWorkOrder;