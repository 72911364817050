import React from "react";
import PropTypes from "prop-types";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = props => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.reports.table.vehicle"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.vehicle.reports.table.fulfilled.date"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.reports.table.done.user"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.reports.table.type"} />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
