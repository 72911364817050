class Stock {
  constructor(
    id,
    product,
    entityName,
    campusName,
    warehouseName,
    stock,
    tag,
    active,
    stockCommitted,
    stockAvailable
  ) {
    this.id = id;
    this.product = product;
    this.entity = entityName;
    this.campus = campusName;
    this.warehouse = warehouseName;
    this.amount = stock;
    this.tags = tag;
    this.active = active;
    this.stockCommitted = stockCommitted;
    this.stockAvailable = stockAvailable;
  }

  static map = stock => {
    return new Stock(
      stock.nu_id_producto,
      stock.vc_desc_producto,
      stock.vc_nombre_entidad,
      stock.vc_nombre_sede,
      stock.vc_desc_almacen,
      stock.nu_stock_actual,
      stock.vc_desc_tag,
      stock.bi_estado === "1",
      stock.nu_stock_comprometido,
      stock.nu_stock_disponible
    );
  };
}

export default Stock;
