import React from "react";
import {Box, Tab, Tabs} from "@material-ui/core";
import {useStyles} from "../styles";
import {useSelector} from "react-redux";
import useSearch from "../../../../../../hooks/Common/useSearch";
import SidebarHeader from "./SidebarHeader";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import WorkOrdersList from "./WorkOrdersList";
import DestinationsList from "./DestinationsLIst";

const Sidebar = ({
  value,
  setValue,
  selectedWorkOrder,
  onSelectWorkOrder,
  selectedDestinations,
  onSelectDestination,
  onSelectAll
}) => {
  const classes = useStyles();
  const {workOrders, destinations} = useSelector(
    state => state.logistic.monitoring.dispatches
  );
  const {
    search: searchWorkOrder,
    filteredData: filteredWorkOrders,
    onSearch: onSearchWorkOrders
  } = useSearch(workOrders);
  const {
    search: searchDestinations,
    filteredData: filteredDestinations,
    onSearch: onSearchDestinations
  } = useSearch(destinations);

  return (
    <Box className={classes.inBuildAppSidebar}>
      <SidebarHeader
        searchText={value === 1 ? searchWorkOrder : searchDestinations}
        setSearchText={value === 1 ? onSearchWorkOrders : onSearchDestinations}
      />
      <Tabs
        className={classes.tabContainer}
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, newValue) => setValue(newValue)}
      >
        <Tab
          className={classes.tabRoot}
          label={
            <IntlMessages id={"logistic.monitoring.dispatches.work.orders"} />
          }
          value={1}
        />
        <Tab
          className={classes.tabRoot}
          label={
            <IntlMessages id={"logistic.monitoring.dispatches.destinations"} />
          }
          value={2}
        />
      </Tabs>
      {value === 1 ? (
        <WorkOrdersList
          selectedWorkOrder={selectedWorkOrder}
          workOrders={filteredWorkOrders}
          onSelectWorkOrder={workOrder => onSelectWorkOrder(workOrder)}
        />
      ) : (
        <DestinationsList
          selectedDestinations={selectedDestinations}
          destinations={filteredDestinations}
          onSelectDestinations={destination => onSelectDestination(destination)}
          onSelectAll={selectAll => onSelectAll(destinations, selectAll)}
        />
      )}
    </Box>
  );
};

export default Sidebar;
