const INIT_STATE = {
  tags: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "CLEAR_TAGS": {
      return {
        ...state,
        tags: []
      };
    }
    case "TAGS_FETCH_ALL_SUCCEEDED": {
      return {
        ...state,
        tags: [ ...action.payload.data ]
      };
    }
    default: {
      return state;
    }
  }
};
