import React from 'react';
import Select from "../../Common/Forms/Select";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../hooks/Common/useFetch";
import {getAllTaxesForSelect} from "../../../services/Treasury/TaxesService";

const TaxSelect = ({name = 'tax', value, onChange, error, errorMessage, required = false, initValue = null}) => {
    const {data: taxes} = useFetch(getAllTaxesForSelect, {}, []);
    const handleOnChange = ({target}) => {
        const {value} = target;
        const tax = taxes.find(tax => tax.id === value);
        onChange({
            value,
            percentage: tax?.percentage,
            taxName: tax?.name,
        });
    }
    React.useEffect(() => {
        if (initValue) {
            const tax = taxes.find(tax => tax.id === initValue);
            onChange({
                value: initValue,
                percentage: tax?.percentage,
                taxName: tax?.name,
            });
        }
    }, []);
    React.useEffect(() => {
        if(value) {
            const tax = taxes.find(tax => tax.id === value);
            onChange({
                value,
                percentage: tax?.percentage,
                taxName: tax?.name,
            });
        }
    }, [value]);
    return (
        <Select
            label={<IntlMessages id="sale.tax"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
        >
            {taxes?.map(tax => (
                <MenuItem key={tax.id} value={tax.id}>
                    {tax.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default TaxSelect;