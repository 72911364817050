import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  groupType: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
        <IntlMessages id="administrator.reports.group.type.select.error.empty" />
      )
    }
  ]
};
