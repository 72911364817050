import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import React from "react";
import PropTypes from "prop-types";

const breadcrumbs = () => [
  {
    label: <IntlMessages id={"sidebar.masterData"} />,
    link: "/logistic/products"
  },
  {label: <IntlMessages id={"sidebar.logistic.receivers"} />, isActive: true}
];

const PageContainer = ({children}) => {
  return (
    <PageContainerComponent
      heading={
        <IntlMessages id={"administrator.logistic.receivers.page.title"} />
      }
      description={
        <IntlMessages
          id={"administrator.logistic.receivers.page.description"}
        />
      }
      breadcrumbs={breadcrumbs()}
    >
      {children}
    </PageContainerComponent>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node
};

PageContainer.defaultProps = {
  children: null
};

export default PageContainer;
