class AavvService {
    constructor(id, ot, date, week,
                month, year, tractPlaque,
                wagonPlaque, jabasCapacity,
                staff, clientScheduledDepartureTime,
                clientRealDepartureTime, laundryScheduledArrivalTime,
                laundryRealArrivalTime, trtDepartureArrivalTime,
                clientDepartureCompliance, laundryScheduledDepartureTime,
                laundryRealDepartureTime, farmScheduledArrivalTime,
                farmRealArrivalTime, trtDepartureRealArrivalTime,
                clientDepartureCompliance2, farmScheduledDepartureTime,
                farmRealDepartureTime, scalesScheduledArrivalTime,
                scalesRealArrivalTime, trtFarmScalesDepartureArrivalTime,
                scalesArrivalCompliance, scalesScheduledDepartureTime,
                scalesRealDepartureTime, clientScheduledArrivalTime,
                clientRealArrivalTime, trtScalesClientDepartureArrivalTime,
                farmArrivalCompliance, trtScheduledTotal, trtRealTotal,
                trtExtraTotal, trtCompliance, nonComplianceReason,
                noComplianceDetail
    ) {
        this.id = id;
        this.ot = ot;
        this.date = date;
        this.week = week;
        this.month = month;
        this.year = year;
        this.tractPlaque = tractPlaque;
        this.wagonPlaque = wagonPlaque;
        this.jabasCapacity = jabasCapacity;
        this.staff = staff;
        this.clientScheduledDepartureTime = clientScheduledDepartureTime;
        this.clientRealDepartureTime = clientRealDepartureTime;
        this.laundryScheduledArrivalTime = laundryScheduledArrivalTime;
        this.laundryRealArrivalTime = laundryRealArrivalTime;
        this.trtDepartureArrivalTime = trtDepartureArrivalTime;
        this.clientDepartureCompliance = clientDepartureCompliance;
        this.laundryScheduledDepartureTime = laundryScheduledDepartureTime;
        this.laundryRealDepartureTime = laundryRealDepartureTime;
        this.farmScheduledArrivalTime = farmScheduledArrivalTime;
        this.farmRealArrivalTime = farmRealArrivalTime;
        this.trtDepartureRealArrivalTime = trtDepartureRealArrivalTime;
        this.clientDepartureCompliance2 = clientDepartureCompliance2;
        this.farmScheduledDepartureTime = farmScheduledDepartureTime;
        this.farmRealDepartureTime = farmRealDepartureTime;
        this.scalesScheduledArrivalTime = scalesScheduledArrivalTime;
        this.scalesRealArrivalTime = scalesRealArrivalTime;
        this.trtFarmScalesDepartureArrivalTime = trtFarmScalesDepartureArrivalTime;
        this.scalesArrivalCompliance = scalesArrivalCompliance;
        this.scalesScheduledDepartureTime = scalesScheduledDepartureTime;
        this.scalesRealDepartureTime = scalesRealDepartureTime;
        this.clientScheduledArrivalTime = clientScheduledArrivalTime;
        this.clientRealArrivalTime = clientRealArrivalTime;
        this.trtScalesClientDepartureArrivalTime = trtScalesClientDepartureArrivalTime;
        this.farmArrivalCompliance = farmArrivalCompliance;
        this.trtScheduledTotal = trtScheduledTotal;
        this.trtRealTotal = trtRealTotal;
        this.trtExtraTotal = trtExtraTotal;
        this.trtCompliance = trtCompliance;
        this.nonComplianceReason = nonComplianceReason;
        this.noComplianceDetail = noComplianceDetail;
    }

    static map = service => {
        return new AavvService(
            service.vc_ot,
            service.vc_ot,
            service.vc_fecha,
            service.vc_sem,
            service.vc_mes,
            service.vc_año,
            service.vc_placa_tracto,
            service.vc_placa_carreta,
            service.vc_capac_jabas,
            service.vc_plantel,
            service.vc_hora_salida_cliente_prog,
            service.vc_hora_salida_cliente_real,
            service.vc_hora_llegada_lav_prog,
            service.vc_hora_llegada_lav_real,
            service.vc_trt_salida_llegada,
            service.vc_cumpli_salida_cliente,
            service.vc_hora_salida_lav_prog,
            service.vc_hora_salida_lav_real,
            service.vc_hora_llegada_granja_prog,
            service.vc_hora_llegada_granja_real,
            service.vc_trt_salida_llegada_lav_granja,
            service.vc_cumpli_salida_cliente_2,
            service.vc_hora_salida_granja_prog,
            service.vc_hora_salida_granja_real,
            service.vc_hora_llegada_bza_prog,
            service.vc_hora_llegada_bza_real,
            service.vc_trt_salida_llegada_granja_bza,
            service.vc_cumpli_llegada_bza,
            service.vc_hora_salida_bza_prog,
            service.vc_hora_salida_bza_real,
            service.vc_hora_llegada_cliente_prog,
            service.vc_hora_llegada_cliente_real,
            service.vc_trt_salida_llegada_bza_cliente,
            service.vc_cumpli_llegada_granja,
            service.vc_trt_total_programado,
            service.vc_trt_total_real,
            service.vc_tiempo_adic_trt_total,
            service.vc_cumpli_trt,
            service.vc_motivo_no_cumplimiento_tiempo_recorrido,
            service.vc_detalle_no_cumplimiento_tiempo_recorrido
        );

    }
}

export default AavvService;