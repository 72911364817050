import {approveAdvance, rejectAdvance} from "../../../services/AdvanceService";
import {
  APPROVE_ADVANCE_RESULT,
  APPROVE_ADVANCE_STARTED,
  REJECT_ADVANCE_RESULT,
  REJECT_ADVANCE_STARTED,
  RESET_UNRESOLVED_ADVANCE_STATE
} from "../../types/Supervisor/CustomTypes";
import {fetchAllAdvancesByGuardianStarted} from "../Guardians/Advances";

const rejectAdvanceResult = (responseMessage, status) => {
  return {
    type: REJECT_ADVANCE_RESULT,
    payload: {
      message: responseMessage,
      status
    }
  };
};

export const rejectAdvanceStarted = (advance, filters) => {
  return async dispatch => {
    dispatch({type: REJECT_ADVANCE_STARTED, payload: {advance: advance}});
    rejectAdvance(advance)
      .then(response => {
        dispatch(rejectAdvanceResult(response.transactionMessage, true));
        dispatch(fetchAllAdvancesByGuardianStarted(filters));
      })
      .catch(e => dispatch(rejectAdvanceResult(e.message, false)));
  };
};

const approveAdvanceResult = (responseMessage, status) => {
  return {
    type: APPROVE_ADVANCE_RESULT,
    payload: {
      message: responseMessage,
      status
    }
  };
};

export const approveAdvanceStarted = (advance, filters) => {
  return async dispatch => {
    dispatch({type: APPROVE_ADVANCE_STARTED, payload: {advance: advance}});
    approveAdvance(advance)
      .then(response => {
        dispatch(approveAdvanceResult(response.transactionMessage, true));
        dispatch(fetchAllAdvancesByGuardianStarted(filters));
      })
      .catch(e => dispatch(approveAdvanceResult(e.message, false)));
  };
};

export const resetUnResolvedState = () => ({
  type: RESET_UNRESOLVED_ADVANCE_STATE
});
