import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting property="origin">
      <IntlMessages id="logistic.table.date" />
    </TableCellWithSorting>
    <TableCellWithSorting property="destiny">
      <IntlMessages id="logistic.table.originWarehouse" />
    </TableCellWithSorting>
    <TableCellWithSorting property="date">
      <IntlMessages id="logistic.table.destinationWarehouse" />
    </TableCellWithSorting>
    <TableCellWithSorting property="date">
      <IntlMessages id="logistic.table.see" />
    </TableCellWithSorting>

    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
