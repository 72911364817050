import {
  ADVANCE_TYPES_CHANGE_STATE,
  ADVANCE_TYPES_CREATE,
  ADVANCE_TYPES_EDIT,
  APPLY_ADVANCE_TYPES_FILTERS,
  FETCH_ADVANCE_TYPES_SUCCEEDED,
  RESET_ADVANCE_TYPES_FILTERS
} from "../../types/Administrator/AdvanceTypesCustomTypes";

const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    description: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_ADVANCE_TYPES_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_ADVANCE_TYPES_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_ADVANCE_TYPES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case ADVANCE_TYPES_CHANGE_STATE: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, {active: !type.active});
          return type;
        })
      };
    }
    case ADVANCE_TYPES_CREATE: {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case ADVANCE_TYPES_EDIT: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, action.payload.data);
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};
