import React from 'react';
import {PostAdd} from '@material-ui/icons';
import IntlMessages from '../../../../utils/IntlMessages';
import {getConfigurationsOptions} from "./configuration";
import {getLocalizationOptions} from "./localization";
import {getFleetManagementOptions} from "./fleetManagement";
import {getLogisticOptions} from "./logistic";
import {getRrhhOptions} from "./rrhh";
import {getTreasuryOptions} from "./treasury";
import {getGeneralOptions} from "./general";
import {getElevatorsManagementOptions} from "./elevatorsManagement";
import {getBackOfficeOptions} from "./backOffice";


export const sidebarNavs = (selectedCompany) => {
    const navOptions = [];
    let newOptions = getConfigurationsOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions =  getLocalizationOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions =  getFleetManagementOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions = getLogisticOptions(selectedCompany);
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions =  getRrhhOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions = getTreasuryOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions = getGeneralOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions = getElevatorsManagementOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);
    newOptions = getBackOfficeOptions();
    newOptions?.children.length >0 && navOptions.push(newOptions);

    return navOptions;
}



export const horizontalDefaultNavs = [
    {
        name: <IntlMessages id={'sidebar.main'}/>,
        type: 'collapse',
        children: [
            {
                name: <IntlMessages id={'pages.samplePage'}/>,
                type: 'item',
                icon: <PostAdd/>,
                link: '/home-page',
            },
        ],
    },
];

export const minimalHorizontalMenus = [
    {
        name: <IntlMessages id={'sidebar.main'}/>,
        type: 'collapse',
        children: [
            {
                name: <IntlMessages id={'pages.samplePage'}/>,
                type: 'item',
                icon: <PostAdd/>,
                link: '/home-page',
            },
        ],
    },
];












