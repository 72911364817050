import axiosInstance from "../../../utils/axiosInstance";
import TeltonikaPlot from "../../../domain/BackOffice/Teltonika/TeltonikaPlot";

export const fetchTaltonikaData = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/Dispositivos/sel_tramas_decode',
            {
                numeroSerie: filters.param === 1? filters.paramValue : undefined ,
                imei: filters.param === 2? filters.paramValue : undefined ,
                fechaInicio: filters.startDate,
                fechaFin: filters.endDate
            }
        );
        if(!data) {
            throw Error('No se encontraron registros');
        }
        return data?.map(plot => TeltonikaPlot.map(plot));
    }catch (e) {
        return Promise.reject(e);
    }
}