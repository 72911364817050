import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";

export const getAllElevatorsTypes = async () => {
    try {
        const {data} = await axiosInstance.post("/Tipo/sel", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(manufacturer => ({
            id: manufacturer.nu_id_tipo,
            name: manufacturer.vc_desc_tipo,
            state: mapToBoolean(manufacturer.bi_estado)
        }));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const createElevatorType = async manufacturer => {
    try {
        const {data} = await axiosInstance.post("/Tipo/ins", {
            nu_id_entidad: getCompanyEntityId(),
            vc_desc_tipo: manufacturer.name
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateElevatorType = async (manufacturerId, name) => {
    try {
        const {data} = await axiosInstance.post("/Tipo/upd", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_tipo: manufacturerId,
            vc_desc_tipo: name
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateElevatorTypeState = async (manufacturerId, state) => {
    try {
        const {data} = await axiosInstance.post("/Tipo/del", {
            nu_id_tipo: manufacturerId,
            bi_estado: state
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};