import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  plaque: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  date: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  time: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  driver: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  station: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  fuelType: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  gallons: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  price: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ],
  amount: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.refueling.form.field.error" />
    }
  ]
};
