import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {fetchAllEmployeesByEntity} from "../../../../../../../services/EmployeeService";

const AnalystSelect = ({id, value, setValue, disabled}) => {
  const [ analysts, setAnalysts ] = React.useState([]);
  React.useEffect(() => {
    fetchAllEmployeesByEntity()
      .then(employees => setAnalysts(employees))
      .catch(e => setAnalysts([]));
  }, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.responsible.analyst"
          }
        />
      }
      fullWidth
    >
      {analysts.map(analyst => (
        <MenuItem value={analyst.id} key={analyst.id}>
          {analyst.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default AnalystSelect;
