import {mapToBoolean} from "../../../utils/mappers";
import Tag from "../../Tag";

class VehicleSheet {
  constructor(
    id,
    url,
    alias,
    plaque,
    frame,
    brand,
    model,
    version,
    type,
    plaqueDate,
    mileage,
    tags,
    engine,
    bodywork,
    cylinder,
    power,
    fuel,
    volume,
    axles,
    passengers,
    color,
    door,
    speed,
    seat,
    radius,
    key,
    control,
    extras,
    state,
    observations
  ) {
    this.id = id;
    this.url = url;
    this.alias = alias;
    this.plaque = plaque;
    this.frame = frame;
    this.brand = brand;
    this.model = model;
    this.version = version;
    this.type = type;
    this.plaqueDate = plaqueDate;
    this.mileage = mileage;
    this.tags = tags;
    this.engine = engine;
    this.bodywork = bodywork;
    this.cylinder = cylinder;
    this.power = power;
    this.fuel = fuel;
    this.volume = volume;
    this.axles = axles;
    this.passengers = passengers;
    this.color = color;
    this.door = door;
    this.speed = speed;
    this.seat = seat;
    this.radius = radius;
    this.key = key;
    this.control = control;
    this.extras = extras;
    this.state = state;
    this.observations = observations;
  }

  static map = vehicle => {
    return new VehicleSheet(
      vehicle.id,
      vehicle.vc_link_image || "",
      vehicle.vc_alias || "",
      vehicle.vc_placa || "",
      vehicle.vc_bastidor || "",
      vehicle.nu_id_marca || "",
      vehicle.nu_id_marca_modelo || "",
      vehicle.nu_id_marca_modelo_version || "",
      vehicle.nu_id_tipo_vehiculo || "",
      vehicle.dt_fec_matriculacion || "",
      vehicle.nu_kilometraje || "",
      vehicle.tags.map(tag => Tag.map(tag)) || "",
      vehicle.vc_tipo_motor || "",
      vehicle.vc_carroceria || "",
      vehicle.nu_cilindrada || "",
      vehicle.nu_potencia || "",
      vehicle.nu_combustible || "",
      vehicle.nu_cargavolumen || "",
      vehicle.nu_ejes || "",
      vehicle.nu_pasajeros || "",
      vehicle.vc_color || "",
      vehicle.nu_num_puertas || "",
      vehicle.nu_velocidades || "",
      vehicle.nu_num_asientos || "",
      vehicle.vc_codigoradio || "",
      vehicle.vc_codigollave || "",
      vehicle.vc_codigomando || "",
      vehicle.vc_extras || "",
      mapToBoolean(vehicle.bi_estado),
      vehicle.vc_observaciones || ""
    );
  };
}

export default VehicleSheet;
