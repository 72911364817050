class OrderHistory {
  constructor(id, idClient, client, date, state, ot, observation, active) {
    this.id = id;
    this.idClient = idClient;
    this.client = client;
    this.date = date;
    this.state = state;
    this.ot = ot;
    this.observation = observation;
    this.active = active;
  }

  static map = order => {
    return new OrderHistory(
      order.nu_id_order,
      order.nu_id_client,
      order.vc_desc_client,
      order.dt_fec,
      order.vc_state,
      order.vc_ot,
      order.vc_observation,
      order.bi_estado === "1"
    );
  };
}

export default OrderHistory;
