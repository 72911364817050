import React from "react";
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Checkbox from "../../../../../Common/Forms/Checkbox";

const DepartureManagementArea = ({id, edit, formData, setFormData}) => {
  const handleChangeArrival = value => {
      if(value && formData.departureDestination) {
          setFormData("departureDestination", false);
      }
      setFormData("arrivalDestination", value);
  }

    const handleChangeDeparture = value => {
        if(value && formData.arrivalDestination) {
            setFormData("arrivalDestination", false);
        }
        setFormData("departureDestination", value);
    }

  return (
    <Box>
      <Box>
        <Typography>
          <IntlMessages
            id={
              "logistic.distribution.work.orders.types.detail.states.common.dispatch.management"
            }
          />
        </Typography>
      </Box>
      <Box>
        <Checkbox
          id={id + "arrival"}
          fullWidth
          disabled={!edit}
          checked={formData.arrivalDestination}
          onChange={handleChangeArrival}
          label={
            <IntlMessages
              id={
                "logistic.distribution.work.orders.types.detail.states.common.dispatch.management.arrival"
              }
            />
          }
        />
        <Checkbox
          id={id + "departure"}
          fullWidth
          disabled={!edit}
          checked={formData.departureDestination}
          onChange={handleChangeDeparture}
          label={
            <IntlMessages
              id={
                "logistic.distribution.work.orders.types.detail.states.common.dispatch.management.departure"
              }
            />
          }
        />
      </Box>
    </Box>
  );
};

DepartureManagementArea.propTypes = {
  id: PropTypes.string,
  edit: PropTypes.bool,
  formData: PropTypes.any,
  setFormData: PropTypes.func.isRequired
};

export default DepartureManagementArea;
