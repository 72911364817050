import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  plaque: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ],
  terminationDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ],
  company: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ],
  commencementDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ],
  expirationDate: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ],
  policy: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ],
  premium: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="fleet.management.insurance.add.field.error" />
    }
  ]
};
