import Entity from 'domain/Entity';

import { getCompanyEntityId } from "./StorageService";
import axiosInstance, { CancelToken, isCancel } from '../utils/axiosInstance';

let fetchAllEntitiesCancelToken = undefined;

export const cancelPrevFetchAllEntities = () => {
  fetchAllEntitiesCancelToken && fetchAllEntitiesCancelToken();
};

export const fetchAllEntities = async () => {
  try {
    const response = await axiosInstance.post(
      '/Empleado/sel',
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllEntitiesCancelToken = c)),
      },
    );
    return response.data.map(entity => Entity.map(entity));
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};
