import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {editAccident, setCurrentAccident} from "../../../../../redux/actions/FleetManagement/Accidents";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "../AddAccidentDialog/Form";
import {parseDateToInput} from "../../../../../utils/dates";

const EditAccidentDialog = ({open, setOpen}) => {
  const id = "edit-accident-dialog";
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.accidents
  );
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(editAccident({id: current.id, ...data}, onClose));
  };
  const onClose = () => {
      setOpen(false);
      dispatch(setCurrentAccident(null));
  }
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.accidents.edit.title"} />}
      open={open}
      onClose={onClose}
      submitText={<IntlMessages id={"fleet.management.accidents.edit.save"} />}
      cancelText={
        <IntlMessages id={"fleet.management.accidents.edit.cancel"} />
      }
    >
        {current &&
            <React.Fragment>
                <Form
                    id={id}
                    onSubmit={onSubmit}
                    initialData={{
                        date: parseDateToInput(current.date) || "",
                        communicationDate: parseDateToInput(current.communicationDate) || "",
                        address: current.address || "",
                        type: current.type || "",
                        status: current.stateId || "",
                        driver: current.driver.id || "",
                        version: current.version || "",
                        opposite: current.opposite || "",
                        observations: current.observations || "",
                        images: current.images || "",
                        vehicles: current.vehicles || []
                    }}
                />
            </React.Fragment>
        }
    </FormDialog>
  );
};
export default EditAccidentDialog;
