import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  campusOrigin: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusOrigin.error" />
      )
    }
  ],
  warehouseOrigin: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseOrigin.error" />
      )
    }
  ],
  entityDestiny: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityDestiny.error" />
      )
    }
  ],
  campusDestiny: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusDestiny.error" />
      )
    }
  ],
  warehouseDestiny: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseDestiny.error" />
      )
    }
  ],
  products: [
    {
      rule: v => v.length > 0,
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.product.error" />
      )
    }
  ],
  amount: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.amount.error" />
      )
    }
  ]
};
