import React from 'react';
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../hooks/Common/useForm";
import {Box, Button, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import moment from "moment";
import HopperSelect from "./HopperSelect";
import TractSelect from "./TractSelect";
import DriverSelect from "./DriverSelect";
import OutlinedTimePicker from "../../../../Common/Forms/OutlinedTimePicker";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import {useDispatch, useSelector} from "react-redux";
import {
    addWorkOrderStarted,
    editWorkOrderStarted,
    resetCurrentWorKOrder
} from "../../../../../redux/actions/Administrator/WorkOrders";
import {differenceBetweenDatesAndTimes} from "../../../../../utils/dates";



const WorkOrderDetail = ({current, noEdit}) => {
    const {filters} = useSelector(({administrator}) => administrator.workOrders);
    const dispatch = useDispatch();
    const initialData = {
        description: current.description || "",
        driver: current.driver || [],
        startDate: moment(current.startDate || Date.now()).format("yyyy-MM-DD"),
        startTime: moment(current.startDate || Date.now()).format("HH:mm"),
        endDate: moment(current.endDate || Date.now()).format("yyyy-MM-DD"),
        endTime: moment(current.endDate || Date.now()).format("HH:mm"),
        duration: current.duration || "",
        tract: current.tract || "",
        hopper: current.hopper || ""
    }
    const {formData, handleSubmit, setFormData, resetForm} = useForm(initialData);
    const [edit, setEdit] = React.useState(current.id === 0);
    const onSubmitWrapper = workOrder => {
        if(current.id !== 0) {
            dispatch(editWorkOrderStarted({
                // id: current.id,
                ...current,
                ...workOrder,
            }, filters));
            setEdit(false);
        } else {
            dispatch(addWorkOrderStarted({
                ...workOrder,
                vehicles: [workOrder.hopper],
                destinations: [],
            }, filters));
        }
    }
    const onClickCancel = () => {
        setEdit(false);
        if(current?.id !== 0) {
            resetForm();
        }
        else {
            dispatch(resetCurrentWorKOrder());
        }
    }
    React.useEffect(() => {
        setFormData('duration',
            differenceBetweenDatesAndTimes(formData.startDate, formData.endDate,
                formData.startTime, formData.endTime))
    }, [formData.startTime, formData.endTime, formData.startDate, formData.endDate]);

    const getProductsQuantityPerOT = () => {
        return  current.destinations.reduce((acc, d) => {
            return acc + (d.products?.length || 0);
        }, 0);
    }
    return (
        <Box marginX={4} paddingY={8}>
            <form id="work-order-detail-form">
                <GridContainer spacing={4}>
                    <Grid item sm={3}>
                        <h2><IntlMessages id={'logistic.work.orders.dialog.detail.title'}/></h2>
                    </Grid>
                    <Grid item sm={9}>
                        <OutlinedInput
                            fullWidth
                            disabled={!edit}
                            id={'work-order-description'}
                            onChange={value => setFormData('description', value)}
                            value={formData.description}
                        />
                    </Grid>
                    <DriverSelect
                        name={current.driver?.name}
                        value={formData.driver}
                        edit={!edit && (getProductsQuantityPerOT() === 0)}
                        setValue={value => setFormData('driver', value)}/>
                    <Grid item sm={8}>
                        <OutlinedDatePicker id="start-date-picker"
                                            fullWidth
                                            label={<IntlMessages id={'logistic.work.orders.dialog.detail.start.date'}/>}
                                            disabled={!edit}
                                            value={formData.startDate}
                                            onChange={value => setFormData('startDate', value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <OutlinedTimePicker id="start-time-picker"
                                            fullWidth
                                            label={<IntlMessages id={'logistic.work.orders.dialog.detail.start.time'}/>}
                                            disabled={!edit}
                                            value={formData.startTime}
                                            onChange={value => setFormData('startTime', value)}/>
                    </Grid>
                    <Grid item sm={8}>
                        <OutlinedDatePicker id="end-date-picker"
                                            fullWidth
                                            label={<IntlMessages id={'logistic.work.orders.dialog.detail.end.date'}/>}
                                            disabled={!edit}
                                            value={formData.endDate}
                                            onChange={value => setFormData('endDate', value)} />
                    </Grid>
                    <Grid item sm={4}>
                        <OutlinedTimePicker id="end-time-picker"
                                            fullWidth
                                            label={<IntlMessages id={'logistic.work.orders.dialog.detail.end.time'}/>}
                                            disabled={!edit}
                                            value={formData.endTime}
                                            onChange={value => setFormData('endTime', value)}/>
                    </Grid>
                    <Grid item sm={3}>
                        <OutlinedTimePicker id="duration-picker"
                                            fullWidth
                                            label={<IntlMessages id={'logistic.work.orders.dialog.detail.duration'}/>}
                                            disabled
                                            value={formData.duration}
                                            onChange={value => setFormData('duration', value)}/>
                    </Grid>
                    <TractSelect value={formData.tract}
                                 setValue={value => setFormData('tract', value)}
                                 edit={!edit}/>
                    <HopperSelect value={formData.hopper}
                                  setValue={value => setFormData('hopper', value)}
                                  edit={!edit}/>
                    {!noEdit && <Grid item sm={12}>
                        <Box display="flex" flexDirection="row" justifyContent="flex-end">
                            {edit ?
                                <React.Fragment>
                                    <Button onClick={onClickCancel}>
                                        <IntlMessages
                                            id={'logistic.work.orders.dialog.detail.cancel'}/>
                                    </Button>
                                    <Button
                                        onClick={handleSubmit(onSubmitWrapper)}
                                    >
                                        <IntlMessages id={'logistic.work.orders.dialog.detail.save'}/>
                                    </Button>
                                </React.Fragment> :
                                <Button onClick={() => {
                                    setEdit(true);
                                }}>
                                    <IntlMessages id={'logistic.work.orders.dialog.detail.edit'}/>
                                </Button>
                            }
                        </Box>
                    </Grid>}
                </GridContainer>
            </form>
        </Box>

    );
}

export default WorkOrderDetail;