import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormShowDialog";
import Form from "./Form";

const ShowIncidentDialog = ({initialData, isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="show-incident-form"
    isOpen={isOpen}
    title={<IntlMessages id="logistics.incidents.showFormTitle" />}
    onClose={onClose}
  >
    <Form
      id="show-incident-form"
      initialData={initialData}
      onSubmit={onSubmit}
    />
  </FormDialog>
);

export default ShowIncidentDialog;
