import React from 'react';
import TableContainer from "../../../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import CrudTableRow from "../../../../../../../Common/Tables/CrudTable/CrudTableRow";

const ClientsTable = ({clients, setSelectedClient, setDelete, setEdit}) => {
    const onEdit = client => {
        setSelectedClient && setSelectedClient(client);
        setEdit && setEdit(true);
    }
    const onDelete = client => {
        setSelectedClient && setSelectedClient(client);
        setDelete && setDelete(true);
    }
    return(
        <TableContainer
            head={<TableHeader/>}
        >
            {
                clients?.map(client => (
                    <CrudTableRow key={client.id}
                                  onEdit={() => onEdit(client)}
                                  onDelete={() => onDelete(client)}
                    >
                        <StyledTableCell>{client.name}</StyledTableCell>
                        <StyledTableCell>{client.email}</StyledTableCell>
                        <StyledTableCell>{client.phone}</StyledTableCell>
                    </CrudTableRow>
                ))
            }
        </TableContainer>
    );
}

export default ClientsTable;