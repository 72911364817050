import React from "react";
import {MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../Common/Forms/Select";

const options = [
  {
    id: 1,
    text: <IntlMessages id={"format.pdf"} />
  },
  {
    id: 2,
    text: <IntlMessages id={"format.excel"} />
  }
];

const FormatSelect = ({
  id,
  onChange,
  style,
  value,
  hasError,
  error,
  disabled,
  fullWidth = false
}) => (
  <Select
    id={id}
    label={<IntlMessages id="control.panel.kpi.add.schedule.export" />}
    value={value}
    onChange={e => onChange(e.target.value)}
    style={style}
    error={hasError}
    disabled={disabled}
    errorMessage={error}
    fullWidth={fullWidth}
  >
    {options.map((option, index) => (
      <MenuItem value={option.id} key={`format-${option.id}`}>
        {option.text}
      </MenuItem>
    ))}
  </Select>
);

export default FormatSelect;
