import {useDispatch, useSelector} from "react-redux";
import {fetchError, fetchStart, fetchSuccess} from "../../redux/actions";
import {useEffect, useState} from "react";
import {updateData} from "../../redux/reducers/general";

export const useFetch = (service, request, initialData = null, nameForRedux = null) => {
  const dispatch = useDispatch();
  const [ data, setData ] = useState(initialData);
  const generalData = useSelector(({general}) => general.data);
  const executeService = () => {
    dispatch(fetchStart());
    service(request)
      .then(result => {
        dispatch(fetchSuccess(data.transactionMessage || ""));
        let filteredResult = result;
        if(Array.isArray(result) && result?.at(0) && Object.hasOwn(result[0], "state")) {
          filteredResult = result.filter(item => item.state);
        }
        setData(filteredResult);
        if(nameForRedux) {
          dispatch(updateData({data: result, name: nameForRedux}));
        }
      })
      .catch(e => dispatch(fetchError(e.message)));
  };
  useEffect(() => {
    if(nameForRedux && generalData[nameForRedux]){
      setData(generalData[nameForRedux]);
    } else {
      executeService();
    }
  }, []);

  return {data, executeService};
};
