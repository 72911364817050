import React from 'react';
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {getAllElevatorsTypes} from "../../../../../../../services/ElevatorsManagement/TypesService";

const TypeSelect = ({value, setValue, disabled}) => {
    const {data: types} = useFetch(getAllElevatorsTypes, {}, []);
    return (
        <Select
            value={value}
            disabled={disabled}
            onChange={e => setValue(e.target.value)}
            label={<IntlMessages id="type"/>}
            fullWidth
        >
            {types.map(type => (
                <MenuItem value={type.id} key={type.id}>
                    {type.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default TypeSelect;