import React from "react";

import PrincipalHeader from "./PrincipalHeader";
import DialogHeader from "./DialogHeader";

const TableHeader = ({header, orderBy, order, onSort}) => (
  <React.Fragment>
    {header === "Principal" ? (
      <PrincipalHeader orderBy={orderBy} order={order} onSort={onSort} />
    ) : (
      <DialogHeader orderBy={orderBy} order={order} onSort={onSort} />
    )}
  </React.Fragment>
);

export default TableHeader;
