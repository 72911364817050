import React from 'react';
import {Box} from "@material-ui/core";
import FormShowDialog from "../../../../Common/Dialogs/FormShowDialog";
import {dialogs} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {closeVehicleReportsDialog} from "../../../../../redux/actions/FleetManagement/VehicleReports";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {getFileByName} from "../../../../../services/FileService";
import Pagination from "@material-ui/lab/Pagination";



const MoreDetailsDialog = () => {
    const id = dialogs.MORE_DIALOG;
    const {dialog: {names, data}} = useSelector(({fleetManagement}) => fleetManagement.vehicleReports);
    const [image, setImage] = React.useState('');
    const [page, setPage] = React.useState(1);
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeVehicleReportsDialog(id));
        setImage('');
        setPage(1);
    }
    const changeImage = async name => {
        if(name) {
            const {contentType, base64File} = await getFileByName(typeof name === 'object'? name.name : name);
            setImage(`data:${contentType};base64,${base64File}`)
        }
    }
    React.useEffect(() => {
        data && changeImage(data.images[page-1]);
    }, [page, data]);
    return (
        <FormShowDialog
            id={id}
            title={<IntlMessages id={"fleet.management.vehicle.reports.detail.dialog.more"} />}
            onClose={onClose}
            isOpen={names.some(n => n===id)}
        >
            {data && <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{
                    gap: 8
                }}
            >
                <OutlinedInput
                    label={<IntlMessages id={"fleet.management.vehicle.reports.detail.dialog.observations"}/>}
                    value={data.observations}
                    disabled
                    fullWidth
                />
                {data?.images?.length > 0 &&
                 <>
                     <Pagination
                         count={data?.images?.length}
                         page={page}
                         onChange={(e, v) => setPage(v)}
                         variant="outlined"
                         shape="rounded"
                     />
                     <img
                         src={image} alt={data.images[page-1] || ''}
                         width="auto"
                         style={{
                             maxHeight: '20vh'
                         }}
                     />
                 </>
                }
            </Box>}
        </FormShowDialog>
    );
};

MoreDetailsDialog.propTypes = {
};

export default MoreDetailsDialog;