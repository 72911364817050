import {withStyles} from "@material-ui/core";
import Box from "@material-ui/core/Box";

export const ResponsiveHeader = withStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.between("xs", "md")]: {
      display: "flex",
      flex: "auto",
      flexDirection: "column"
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flex: "auto",
      flexDirection: "row"
    }
  }
}))(Box);
