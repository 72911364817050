import React from "react";
import GridContainer from "../../../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import useForm from "../../../../../../../../../hooks/Common/useForm";
import RoutesSelect from "./RoutesSelect";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentReportFile} from "../../../../../../../../../redux/actions/ControlPanel/Kpi";
import {addDaysToDate, parseDateToInput} from "../../../../../../../../../utils/dates";
import SendEmail from "../../../../../Common/SendEmailDialog";
import SaveFilters from "../../../../../Common/SaveFilters";
import DateFilters from "../../../../../Common/DateFilters";

const initialData = {
  routes: [],
  period: 1,
  startDate: parseDateToInput(addDaysToDate(new Date(), -7)), // -7 dias
  endDate: parseDateToInput(new Date())
};

const Form = () => {
  const {filters, type} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  const {formData, setFormData, setValues} = useForm(initialData);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(setCurrentReportFile(type, formData));
  };
  React.useEffect(
    () => {
      filters && setValues(filters);
    },
    [ filters ]
  );

  return (
    <form id="monitoring-filters">
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <RoutesSelect
            values={formData.routes}
            onChange={value => setFormData("routes", value)}
          />
        </Grid>
        <DateFilters
            formData={formData}
            setFormData={setFormData}
        />
        <Grid item xs={12} sm={4} lg={3}>
          <Button onClick={() => handleSubmit()} color="primary">
            Generate Report
          </Button>
        </Grid>
        <SendEmail filters={formData} />
        <SaveFilters filters={formData}/>
      </GridContainer>
    </form>
  );
};

export default Form;
