import {
  ADD_CONFIG_ROLE,
  APPLY_CONFIG_ROLES_FILTERS,
  EDIT_CONFIG_ROLE,
  EDIT_CONFIG_ROLE_STATE,
  FETCH_ALL_CONFIG_ROLES,
  RESET_CONFIG_ROLES_FILTERS,
  RESET_CONFIG_ROLES_STATES,
  SET_CURRENT_CONFIG_ROLE
} from "../../types/Configuration/RolesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  addConfigRole,
  fetchAllConfigRoles,
  fetchAllPermissionsByRole,
  updateConfigRoleData,
  updateConfigRoleModules,
  updateConfigRoleState
} from "../../../services/Configuration/ConfigRoleService";
import Role from "../../../domain/Configuration/Role";

export const resetConfigurationRoleState = () => ({
  type: RESET_CONFIG_ROLES_STATES
});
export const applyConfigRolesFilters = filters => ({
  type: APPLY_CONFIG_ROLES_FILTERS,
  payload: filters
});
export const resetConfigRolesFilters = () => ({
  type: RESET_CONFIG_ROLES_FILTERS
});

export const setCurrentConfigurationRole = (role, process = 1) => {
    return async dispatch => {
        if (role !== null && process === 1) {
            dispatch(fetchStart());
            fetchAllPermissionsByRole(role)
                .then(permissions => {
                    dispatch(fetchSuccess());
                    let modules = {};
                    permissions.map(u => {
                        modules[u.id] = u;
                        return null;
                    });
                    dispatch({
                        type: SET_CURRENT_CONFIG_ROLE, payload: {
                            ...role,
                            permissions: modules
                        }
                    });
                })
                .catch(e => {
                    dispatch(fetchError(e.message));
                });
        } else {
            dispatch({type: SET_CURRENT_CONFIG_ROLE, payload: role});
        }
    };
};

export const fetchAllConfigurationRoles = () => {
  return async dispatch => {
    dispatch(fetchStart());
    fetchAllConfigRoles()
      .then(roles => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_CONFIG_ROLES, payload: roles});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateConfigurationRoleState = (role, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateConfigRoleState(role, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: EDIT_CONFIG_ROLE_STATE, payload: {id: role.id, state}});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateConfigurationRole = (role, operations) => {
  return async dispatch => {
    dispatch(fetchStart());
    Promise.all(
      operations.map(operation => updateConfigRoleModules(role, operation))
    )
      .then(response => {
        dispatch(
          fetchSuccess("Los permisos del rol se modificaron correctamente")
        );
        dispatch(setCurrentConfigurationRole(null));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addConfigurationRole = role => {
  return async dispatch => {
    dispatch(fetchStart());
    addConfigRole(role)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newRole = new Role(
          response.transactionKey,
          role.description,
          true,
          []
        );
        dispatch({type: ADD_CONFIG_ROLE, payload: newRole});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateConfigurationRoleData = (role, data) => {
  return async dispatch => {
    const updatedData = {id: role.id, ...data};
    dispatch(fetchStart());
    updateConfigRoleData(updatedData)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: EDIT_CONFIG_ROLE, payload: updatedData});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
