class DataCollection {
  constructor(
    id,
    idVehicel,
    vehicle,
    start,
    end,
    idRoute,
    route,
    idTurn,
    turn,
    passengers,
    firstPickUp,
    lastPickUp,
    disinfection,
    active
  ) {
    this.id = id;
    this.idVehicel = idVehicel;
    this.vehicle = vehicle;
    this.start = start;
    this.end = end;
    this.idRoute = idRoute;
    this.route = route;
    this.idTurn = idTurn;
    this.turn = turn;
    this.passengers = passengers;
    this.firstPickUp = firstPickUp;
    this.lastPickUp = lastPickUp;
    this.disinfection = disinfection;
    this.active = active;
  }

  static map = dataCollection => {
    return new DataCollection(
      dataCollection.nu_id_viaje,
      dataCollection.nu_id_vehiculo,
      dataCollection.vc_placa,
      dataCollection.dt_fec_ini_real,
      dataCollection.dt_fec_fin_real,
      dataCollection.nu_id_ruta,
      dataCollection.vc_desc_ruta,
      dataCollection.nu_id_turno,
      dataCollection.vc_desc_turno,
      dataCollection.nu_id_pasajeros,
      dataCollection.dt_fec_ini_recogida,
      dataCollection.dt_fec_fin_recogida,
      dataCollection.dt_fec_ini_desifeccion,
      dataCollection.bi_estado === "1"
    );
  };
}

export default DataCollection;
