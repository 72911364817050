import React, {useEffect} from "react";
import IntlMessages from "../../../../../../../../../../@jumbo/utils/IntlMessages";
import {fetchAllDocumentTypes} from "../../../../../../../../../../services/DocumentTypeService";
import MultipleSelect from "../../../../../../../../../Common/Forms/Select/MultipleSelect";

const DocumentTypesPicker = ({values, onChange}) => {
  const [ types, setTypes ] = React.useState([]);
  useEffect(() => {
    fetchAllDocumentTypes().then(documentTypes => {
      setTypes([ ...documentTypes ]);
    });
  }, []);
  return (
    <MultipleSelect
      id={"administrator-consolidated-reports-document-types-picker"}
      options={types}
      value={values}
      onChange={onChange}
      fullWidth
      label={<IntlMessages id={"administrator.reports.document.types.title"} />}
      headerText={
        <IntlMessages id="administrator.reports.document.types.header" />
      }
      noOptionsText={
        <IntlMessages id="administrator.reports.document.types.no.options" />
      }
    />
  );
};

export default DocumentTypesPicker;
