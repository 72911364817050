import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";
import StateSelect from "./StateSelect";
import RejectionTypesSelect from "containers/Supervisor/RejectionTypesSelect";
import EXPENSE_STATE from "models/Supervisor/ExpenseState";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";

const initialData = {
  driver: "",
  tag: "",
  event: "",
  documentType: "",
  amount: "",
  state: EXPENSE_STATE.PENDING,
  reasonOfRejection: "",
  lowerDate: null,
  upperDate: null,
  pettyCashAmount: '',
};

const Form = ({onFilter}) => {
  const {formData, setFormData, handleSubmit, resetForm } = useForm(initialData);
  const id = 'expenses-filters';

  const onSubmit = (data) => {
    onFilter(data)
  }

  const onClearFilters = () => {
    resetForm();
  }

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <StateSelect
            selected={formData.state}
            onChange={value => setFormData("state", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.driver}
            id="supervisor-unresolved-expenses-driver-filter"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.filters.driver" />
            }
            onChange={value => setFormData("driver", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.tag}
            id="supervisor-unresolved-expenses-tag-filter"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.filters.tag" />
            }
            onChange={value => setFormData("tag", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
              fullWidth
              value={formData.pettyCashAmount}
              id="supervisor-unresolved-expenses-petty-cash-amount-filter"
              label={
                <IntlMessages id="petty.cash.amount" />
              }
              onChange={value => setFormData("pettyCashAmount", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.event}
            id="supervisor-unresolved-expenses-event-filter"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.filters.event" />
            }
            onChange={value => setFormData("event", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.documentType}
            id="supervisor-unresolved-expenses-document-type-filter"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.filters.documentType" />
            }
            onChange={value => setFormData("documentType", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.amount}
            id="supervisor-unresolved-expenses-amount-filter"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.filters.amount" />
            }
            onChange={value => setFormData("amount", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <RejectionTypesSelect
            id="supervisor-unresolved-expenses-rejection-type-filter"
            label={
              <IntlMessages id="supervisor.unresolvedExpenses.filters.reasonOfRejection" />
            }
            value={formData.reasonOfRejection}
            onChange={value => setFormData("reasonOfRejection", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            id="supervisor-unresolved-expenses-lower-date-filter"
            label={<IntlMessages id="guardian.advances.filter.from" />}
            value={formData.lowerDate}
            onChange={value => setFormData("lowerDate", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            id="supervisor-unresolved-expenses-upper-date-filter"
            label={<IntlMessages id="guardian.advances.filter.to" />}
            value={formData.upperDate}
            onChange={value => setFormData("upperDate", value)}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContainedButton
              type='submit'
              form={id}
              color='primary'
              text={<IntlMessages id='filters.button.apply' />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ContainedButton
              onClick={onClearFilters}
              text={<IntlMessages id='filters.button.clear' />}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
