import React from 'react';
import {Avatar, Box, Icon, IconButton} from "@material-ui/core";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Form from "./Form";
import EditIcon from "@material-ui/icons/Edit";
import UpdateImageDialog from "../UpdateImageDialog";
import {useDispatch, useSelector} from "react-redux";
import {editVehicleBasicSheet} from "../../../../../../redux/actions/FleetManagement/TechnicalSpecification";
import {mapDateToOutput} from "../../../../../../utils/parsers";
import {parseDateToInput} from "../../../../../../utils/dates";

const BasicFile = ({vehicle}) => {
    const [openEditImage, setOpenEditImage] = React.useState(false);
    const dispatch = useDispatch();
    const {current} = useSelector(({fleetManagement}) => fleetManagement.technicalSpecification);
    const onSubmit = (data, onAccept, onError) => {
        dispatch(editVehicleBasicSheet(current, data, onAccept, onError));
    }
    return (
        <Box display="flex"
             flexDirection="column"
             border="solid"
             borderColor="#3f51b5"
             borderRadius="8px 8px"
            padding={4}
        >
            <Box display="flex" justifyContent="center" position="relative">
                <Box zIndex={10} position="absolute" width="250px" height="250px">
                    <IconButton onClick={() => setOpenEditImage(true)}>
                        <EditIcon />
                    </IconButton>
                </Box>
                <Avatar
                    variant="square"
                    src={vehicle.url}
                    alt={vehicle.plaque}
                    style={{
                        width: "250px",
                        height: "250px",
                    }}
                >
                    <DriveEtaIcon style={{
                        width: "200px",
                        height: "200px",
                    }} />
                </Avatar>
            </Box>
            <UpdateImageDialog
                open={openEditImage}
                setOpen={setOpenEditImage}
            />
            <Box  py="1em">
                <Form
                    id={"basic-vehicle-info-form"}
                    initialData={{
                        plaque: vehicle?.plaque || '',
                        frame: vehicle?.frame || '',
                        brand: vehicle?.brand || '',
                        model: vehicle?.model || '',
                        version: vehicle?.version || '',
                        type: vehicle?.type || '',
                        plaqueDate: parseDateToInput(vehicle?.plaqueDate) || '',
                        alias: vehicle?.alias || '',
                        mileage: vehicle?.mileage || '',
                        tags: vehicle?.tags || [],
                    }}
                    onSubmit={onSubmit}

                />
            </Box>
        </Box>
    );
};

export default BasicFile;