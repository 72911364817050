import React from 'react';
import {Collapse, Divider, List, ListItem} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import {useStyles} from "./styles";
import {useDispatch} from "react-redux";
import {setCurrentReport} from "../../../../../../redux/actions/ControlPanel/Kpi";
import {ExpandLess, ExpandMore} from "@material-ui/icons";

const ReportsList = ({option}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const onSelectReport = (id, filters, title) => {
        dispatch(setCurrentReport(id, filters, {title}));
    }
    return (
        <React.Fragment>
            {option
                ? <List component="div" disablePadding className={classes.option}>
                    <ListItem button className={classes.nested} onClick={() => setOpen(!open)}>
                        <ListItemText primary={option.text}/>
                        {open ? <ExpandLess/> : <ExpandMore/>}
                    </ListItem>
                    <Divider/>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {option?.reports?.map((s, index) => (
                            <List component="div" disablePadding key={`report-${s.id}-${index}`} className={classes.report}>
                                <ListItem button className={classes.nested}
                                          onClick={() => onSelectReport(s.id, s.filters, s.text)}>
                                    <ListItemText primary={s.text}/>
                                </ListItem>
                                <Divider/>
                            </List>
                        ))}
                    </Collapse>
                </List>
                : <></>
            }
        </React.Fragment>
    )
}

export default ReportsList;