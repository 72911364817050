import React from "react";
import ImportFileDialog from "../../../../Common/Dialogs/ImportFileDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {
  addIndividualTachographFile,
  addZipTachographFile
} from "../../../../../redux/actions/FleetManagement/TachographFiles";

const ImportDialogs = ({
  openZipDialog,
  setOpenZipDialog,
  openIndividualDialog,
  setOpenIndividualDialog
}) => {
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographFiles
  );
  const dispatch = useDispatch();
  const onImportZip = file => {
    dispatch(addZipTachographFile(file, filters));
    setOpenZipDialog(false);
  };
  const onImportIndividual = file => {
    dispatch(addIndividualTachographFile(file, filters));
    setOpenIndividualDialog(false);
  };
  return (
    <React.Fragment>
      <ImportFileDialog
        id="import-zip-dialog"
        title={
          <IntlMessages
            id={"fleet.management.tachograph.files.table.import.zip"}
          />
        }
        open={openZipDialog}
        setOpen={setOpenZipDialog}
        onImport={onImportZip}
        accept=".zip"
      />
      <ImportFileDialog
        id="import-individual-dialog"
        title={
          <IntlMessages
            id={"fleet.management.tachograph.files.table.import.individual"}
          />
        }
        open={openIndividualDialog}
        setOpen={setOpenIndividualDialog}
        onImport={onImportIndividual}
        accept=".tgd,.ddd"
      />
    </React.Fragment>
  );
};

export default ImportDialogs;
