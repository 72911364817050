import React from 'react';
import {Paper} from "@material-ui/core";
import LegendIndicator from "../../Common/LegendIndicator";
import {eventsTypeColors} from "../constants";
import {useIntl} from "react-intl";
import Typography from "@material-ui/core/Typography";
import EventsGraph from "../Common/EventsGraph";
import {useSelector} from "react-redux";
import EventsByType from "../Common/EventsByType";
import EventsByTypeGrouped from "../Common/EventsByTypeGrouped";
import EventsByDrivers from "../Common/EventsByDrivers";
import EventsByVehicle from "../Common/EventsByVehicle";
import EventsDetailsTable from "./EventsDetailsTable";

const Security = () => {
    const {security} = useSelector(({controlPanel}) => controlPanel.dashboards.currentDashboard.widgets);
    const intl = useIntl();
    return (
        <section className='graph-container'>
            <Typography
                variant='h2'
            >
                {intl.messages['security']}
            </Typography>
            <Paper className='legend cols-6'>
                <LegendIndicator
                    text='Aceleracion'
                    color={eventsTypeColors.acceleration}
                />
                <LegendIndicator
                    text='Frenazo'
                    color={eventsTypeColors.braking}
                />
                <LegendIndicator
                    text='Giro Brusco'
                    color={eventsTypeColors.sharpTurn}
                />
            </Paper>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['events']} {intl.messages['eco.driving']}
                </Typography>
                <EventsGraph
                    events={security?.events}
                />
            </div>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['events']} {intl.messages['eco.driving']} {intl.messages['by']} {intl.messages['type']}
                </Typography>
                <EventsByType
                    eventsByType={security?.eventsByType}
                    colors={eventsTypeColors}
                />
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['events']} ${intl.messages['eco.driving']} ${intl.messages['by']} ${intl.messages['group']}`}
                </Typography>
                <EventsByTypeGrouped
                    eventsByGroup={security?.eventsByTypeGrouped}
                    colors={eventsTypeColors}
                />
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['events']} ${intl.messages['eco.driving']} ${intl.messages['by']} ${intl.messages['vehicle']}`}
                </Typography>
                <EventsByVehicle
                    eventsByVehicle={security?.eventsByVehicle}
                    colors={eventsTypeColors}
                />
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['events']} ${intl.messages['eco.driving']} ${intl.messages['by']} ${intl.messages['driver']}`}
                </Typography>
                <EventsByDrivers
                    eventsByDriver={security?.eventsByDriver}
                    colors={eventsTypeColors}
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['detail']} ${intl.messages['events']} ${intl.messages['eco.driving']}`}
                </Typography>
                <EventsDetailsTable />
            </div>
        </section>
    );
};

export default Security;