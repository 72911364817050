import React from "react";
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {Box, Grid, Typography} from "@material-ui/core";
import {
  CircleNumber,
  RedText,
  SortableItemContainer,
  StyledDivider
} from "./styles";

const SortableItem = SortableElement(({children}) => (
  <React.Fragment>{children}</React.Fragment>
));

const DestinationSortableList = SortableContainer(
  ({destinations, makeAddress}) => {
    return (
      <Box display="flex" flexDirection="column">
        {destinations.map((destination, index) => (
          <SortableItem index={index} key={destination.id}>
            <SortableItemContainer spacing={4}>
              <Grid item sm={1}>
                <CircleNumber>{index + 1}</CircleNumber>
              </Grid>
              <Grid item sm={8}>
                <Box display="flex" flexDirection="column">
                  <Typography variant="h4">{destination.name}</Typography>
                  <Typography variant="h5">
                    {makeAddress(destination)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={3}>
                {destination.state && (
                  <RedText>
                    {destination.stateDescription}
                  </RedText>
                )}
              </Grid>
            </SortableItemContainer>
            <StyledDivider />
          </SortableItem>
        ))}
      </Box>
    );
  }
);

export default DestinationSortableList;
