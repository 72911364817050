import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {Box, IconButton, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {billStatesColors, dialogsList} from "./constants";
import {openDialog} from "../../../redux/reducers/Treasury/bills";
import {
    cancelSentBill,
    fetchAllBills,
    getAndSetBillDetails,
    paySelectedBills,
    removeBill,
    sendSunatSelectedBills
} from "../../../redux/thunks/Treasury/bills";
import {parseNumberToMoney} from "../../../utils/parsers";
import ContainedButton from "../../Common/Buttons/ContainedButton";
import {parseDateToDateString} from "../../../utils/dates";
import ShowIcon from "@material-ui/icons/Visibility";
import FilterToolbar from "../../Common/Tables/DataGrid/FilterToolbar";
import DownloadMenu from "../common/DownloadMenu";
import CancelIcon from '@material-ui/icons/Cancel';

const Table = () => {
    const dispatch = useDispatch();
    const {bills} = useSelector(({treasury}) => treasury.bills);
    const billsList = Object.values(bills);
    const [selectedBills, setSelectedBills] = React.useState([]);
    const intl = useIntl();
    React.useEffect(() => {
        dispatch(fetchAllBills());
    }, []);
    const handleEditTax = (row) => {
        dispatch(openDialog(dialogsList.EDIT_BILL));
        dispatch(getAndSetBillDetails({bill: row}));
    }
    const handleDeleteTax = (row) => {
        dispatch(removeBill({billId: row.id}))
            .unwrap()
            .finally(() => {
                setSelectedBills([]);
            });
    }
    const handlePayBills =  () => {
        dispatch(paySelectedBills({selectedBills}))
            .unwrap()
            .finally(() => {
                setSelectedBills([]);
            });
    }

    const handleSentSunat = () => {
        dispatch(sendSunatSelectedBills({selectedBills}))
            .unwrap()
            .finally(() => {
                setSelectedBills([]);
            });
    }

    const handleCancelBill = (billId) => {
        dispatch(cancelSentBill({billId}))
            .unwrap()
            .finally(() => {
                setSelectedBills([]);
            });
    }
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'creationDate',
            headerName: intl.messages['date'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
            filterable: false,
        },
        {
            field: 'documentTypeDescription',
            headerName: intl.messages['document.type'],
            width: 200,
            filterable: false
        },
        {
            field: 'billNumber',
            headerName: intl.messages['num'],
            width: 200,
            filterable: false,
        },
        {
            field: 'expirationDate',
            headerName: intl.messages['expiration'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
            filterable: false,
        },
        {
            field: 'clientName',
            headerName: intl.messages['client'],
            width: 200,
        },
        {
            field: 'subtotal',
            headerName: intl.messages['subtotal'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'sunatSent',
            headerName: intl.messages['sunat.accepted'],
            valueFormatter: ({value}) => value? intl.messages['yes'] : intl.messages['no'],
            width: 150,
        },
        {
            field: 'state',
            headerName: intl.messages['state'],
            width: 200,
            renderCell: ({value, row}) =>
                (<Typography
                    style={{
                        color: '#fff',
                        backgroundColor: row.stateColor ?? billStatesColors.pendiente,
                        padding: '4px 8px',
                    }}
                >
                    {value}
                </Typography>)

        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            width: 180,
            type: 'actions',
            renderCell: ({row}) => {
                return row?.state?.toLowerCase()?.includes("pendiente") && !row?.sunatSent
                    ?(
                        <>
                            <IconButton onClick={() => handleEditTax(row)}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={() => handleDeleteTax(row)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>
                    )
                    : <>
                        <IconButton onClick={() => handleEditTax(row)}>
                            <ShowIcon/>
                        </IconButton>
                        {row.sunatSent
                            && (
                                <>
                                    <DownloadMenu bill={row}/>
                                    {row.stateId !== "4" &&
                                        <IconButton onClick={() => handleCancelBill(row.id)}>
                                            <CancelIcon/>
                                        </IconButton>
                                    }
                                </>
                            )
                        }
                    </>
            }
        }
    ]
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    marginBottom: '1rem'
                }}
            >
                <ContainedButton
                    text={intl.messages['send.sunat']}
                    color='primary'
                    onClick={handleSentSunat}
                    disabled={selectedBills?.some(billId => bills[billId].sunatSent || +bills[billId]?.total <= 0 || +bills[billId]?.subtotal <= 0)}
                />
                <ContainedButton
                    text={intl.messages['pay']}
                    color='primary'
                    onClick={handlePayBills}
                    disabled={selectedBills?.some(billId => !bills[billId]?.state?.toLowerCase()?.includes("facturado") || +bills[billId]?.total <= 0 || +bills[billId]?.subtotal <= 0)}
                />
            </Box>
            <DataGrid
                columns={columns}
                rows={billsList}
                style={{
                    height: '70vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection
                selectionModel={selectedBills}
                onSelectionModelChange={(newSelection) => {
                    setSelectedBills(newSelection);
                }}
                components={{Toolbar: FilterToolbar}}
            />
        </>
    );
};

export default Table;