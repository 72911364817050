import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.dasboard.fleetManagement"} />,
    link: "/fleet-management/vehicles/file"
  },
  {
    label: (
      <IntlMessages
        id={"sidebar.dasboard.fleetManagement.section.dailyOperations.tires"}
      />
    ),
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="fleet.management.tires.title" />}
    description={<IntlMessages id="fleet.management.tires.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
