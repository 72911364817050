export const FETCH_ALL_CLIENTS_STARTED = "FETCH_ALL_CLIENTS_STARTED";
export const FETCH_ALL_CLIENTS_SUCCESS = "FETCH_ALL_CLIENTS_SUCCESS";

export const APPLY_CLIENTS_FILTERS = "APPLY_CLIENTS_FILTERS";
export const RESET_CLIENTS_FILTERS = "RESET_CLIENTS_FILTERS";

export const CREATE_CLIENTS_STARTED = "CREATE_CLIENTS_STARTED";
export const CREATE_CLIENTS_SUCCESS = "CREATE_CLIENTS_SUCCESS";

export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
export const RESET_CURRENT_CLIENT = "RESET_CURRENT_CLIENT";

export const ERROR_ON_TRANSACTION = "ERROR_ON_TRANSACTION";
export const RESET_TRANSACTION = "RESET_TRANSACTION";

export const FETCH_ALL_HEADQUARTERS_BY_CLIENT_STARTED =
  "FETCH_ALL_HEADQUARTERS_BY_CLIENT_STARTED";
export const FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS =
  "FETCH_ALL_HEADQUARTERS_BY_CLIENT_SUCCESS";

export const ADD_HEADQUARTER_STARTED = "ADD_HEADQUARTER_STARTED";
export const ADD_HEADQUARTERS_SUCCESS = "ADD_HEADQUARTERS_SUCCESS";

export const EDIT_CLIENT_STARTED = "EDIT_CLIENT_STARTED";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";

export const EDIT_HEADQUARTER_STARTED = "EDIT_HEADQUARTER_STARTED";
export const EDIT_HEADQUARTER_SUCCESS = "EDIT_HEADQUARTER_SUCCESS";
