import React, {useEffect} from "react";
import Filters from "./Filters";
import Viewer from "./Viewer";
import {useDispatch, useSelector} from "react-redux";
import {downloadFile} from "../../../../../../services/FileService";
import dataCollectionHistory from "../../../../../../redux/actions/Administrator/DataCollectionHistory";

const CdsReports = () => {
  const {file, fileType: type} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(dataCollectionHistory.get());
      return () => {
        dispatch(dataCollectionHistory.resetState());
      };
    },
    [ dispatch ]
  );
  useEffect(
    () => {
      if (file && type === 2) {
        downloadFile(
          "report.xls",
          `data:application/vnd.ms-excel;base64,${file}`
        );
      }
    },
    [ file ]
  );
  return (
    <React.Fragment>
      <Filters />
      {file && type === 1 && <Viewer />}
    </React.Fragment>
  );
};

export default CdsReports;
