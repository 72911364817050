import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {
    addCostCenterTypes,
    editCostCenterTypes,
    editCostCenterTypeState,
    fetchAllCostCenterTypes
} from "../../thunks/Treasury/costCenterType";

const INIT_STATE = {
    filters: {
        state: true,
        description: ''
    },
    costCenters: [],
    dialogs: [],
    currentCostCenter: null,
}

const costCenterSlice = createSlice({
    name: 'costCenterType',
    initialState: cloneDeep(INIT_STATE),
    reducers: {
        resetState: (state) => {
            return {
                ...state,
                ...cloneDeep(INIT_STATE),
            }
        },
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ]
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentCostCenter: (state, action) => {
            state.currentCostCenter = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = {
                ...state.filters,
                ...cloneDeep(action.payload)
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllCostCenterTypes.fulfilled, (state, action) => {
            state.costCenters = action.payload.costCenters;
        });
        builder.addCase(addCostCenterTypes.fulfilled, (state, action) => {
            state.costCenters = [
                ...state.costCenters,
                action.payload,
            ];
        });
        builder.addCase(editCostCenterTypes.fulfilled, (state, action) => {
            const editedCenter = action.payload;
            state.costCenters = state.costCenters.map(center => {
                if(center.id === editedCenter.id) {
                    return editedCenter;
                }
                return center;
            });
            state.currentCostCenter = null;
        });
        builder.addCase(editCostCenterTypeState.fulfilled, (state, action) => {
            const editedCenter = action.payload;
            state.costCenters = state.costCenters.map(center => {
                if(center.id === editedCenter.id) {
                    return editedCenter;
                }
                return center;
            });
            state.currentCostCenter = null;
        });
    }
});


export default costCenterSlice.reducer;

export const {resetState, openDialog, closeDialog, setCurrentCostCenter, setFilters} = costCenterSlice.actions;
