import React from 'react';
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {DataGrid} from "@mui/x-data-grid";
import ExportToolbar from "../../../../../../Common/Tables/DataGrid/ExportToolbar";
import moment from "moment";

const TimeView = () => {
    const {file: data} = useSelector(({controlPanel}) => controlPanel.kpi.current);
    const intl = useIntl();

    const columns = Object.freeze([
        { field: 'id', hide: true },
        {
            field: 'date',
            type: 'date',
            valueFormatter: ({value}) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['date'],
            width: 200
        },
        {
            field: 'origin',
            headerName: intl.messages['origin'],
            width: 200
        },
        {
            field: 'route',
            headerName: intl.messages['route'],
            width: 200
        },
        {
            field: 'plaque',
            headerName: intl.messages['plaque'],
            width: 200
        },
        {
            field: 'clientCode',
            headerName: intl.messages['client.code'],
            width: 200
        },
        {
            field: 'client',
            headerName: intl.messages['client'],
            width: 200
        },
        {
            field: 'sequence',
            headerName: intl.messages['sequence'],
            width: 200
        },
        {
            field: 'deliveryMinTime',
            headerName: intl.messages['delivery.min.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            width: 200
        },
        {
            field: 'deliveryMaxTime',
            headerName: intl.messages['delivery.max.time'],
            valueFormatter: ({value}) => value && moment(value).format('HH:mm'),
            width: 200
        },
        {
            field: 'arrivalDestination',
            type: 'date',
            valueFormatter: ({value}) => value && moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['arrival.destination'],
            width: 200
        },
        {
            field: 'loadStartTime',
            type: 'date',
            valueFormatter: ({value}) => value && moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['load.start.time'],
            width: 200
        },
        {
            field: 'unloadStartTime',
            type: 'date',
            valueFormatter: ({value}) => value && moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['unload.start.time'],
            width: 200
        },
        {
            field: 'loadEndTime',
            type: 'date',
            valueFormatter: ({value}) => value && moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['load.end.time'],
            width: 200
        },
        {
            field: 'unloadEndTime',
            type: 'date',
            valueFormatter: ({value}) => value && moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['unload.end.time'],
            width: 200
        },
        {
            field: 'returning',
            headerName: intl.messages['returning'],
            width: 200
        },
        {
            field: 'returningGuide',
            headerName: intl.messages['returning.guide'],
            width: 200
        },
        {
            field: 'arrivalDestinationPickUp',
            headerName: intl.messages['arrival.destination.pick.up'],
            width: 200
        },
        {
            field: 'startCollection',
            headerName: intl.messages['start.collection'],
            width: 200
        },
        {
            field: 'endCollection',
            headerName: intl.messages['end.collection'],
            width: 200
        },
        {
            field: 'finishTime',
            type: 'date',
            valueFormatter: ({value}) => value && moment(value).format('DD/MM/YYYY HH:mm:ss'),
            headerName: intl.messages['finish'],
            width: 200
        },
        {
            field: 'status',
            headerName: intl.messages['status'],
            width: 200
        },
        {
            field: 'reasonCode',
            headerName: intl.messages['reason.code'],
            width: 200
        },
        {
            field: 'lateReason',
            headerName: intl.messages['late.reason'],
            width: 200
        },
        {
            field: 'area',
            headerName: intl.messages['area'],
            width: 200
        },
        {
            field: 'carrier',
            headerName: intl.messages['carrier'],
            width: 200
        },
        {
            field: 'driverCode',
            headerName: intl.messages['driver.code'],
            width: 200
        },
        {
            field: 'driver',
            headerName: intl.messages['driver'],
            width: 200
        },
        {
            field: 'assistant',
            headerName: intl.messages['assistant'],
            width: 200
        },
        {
            field: 'carrierNumber',
            headerName: intl.messages['carrier.number'],
            width: 200
        },
        {
            field: 'dwellTime',
            headerName: intl.messages['dwell.time'],
            width: 200
        },
        {
            field: 'waitTime',
            headerName: intl.messages['wait.time'],
            width: 200
        },
        {
            field: 'attentionTime',
            headerName: intl.messages['attention.time'],
            width: 200
        },
        {
            field: 'documentationDeliveryTime',
            headerName: intl.messages['documentation.delivery.time'],
            width: 200
        },
        {
            field: 'entryValidation',
            headerName: intl.messages['entry.validation'],
            width: 200
        },
        {
            field: 'population',
            headerName: intl.messages['population'],
            width: 200
        },


    ]);
    return (
        <DataGrid
            columns={columns}
            rows={data || []}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            disableColumnMenu
            components={{
                Toolbar: ExportToolbar,
            }}
        />
    );
};

export default TimeView;