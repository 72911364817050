import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import MonitoringWorkOrderList from "../domain/MonitoringWorkOrderList";
import MonitoringAlert from "../domain/MonitoringAlert";
import MonitoringState from "../domain/MonitoringState";
import MonitoringDispatchWorkOrder from "../domain/MonitoringDispatchWorkOrder";
import MonitoringDispatchDestination from "../domain/MonitoringDispatchDestination";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";
import {
  parseDateAndEndOfTimeFromInput,
  parseDateFromInput
} from "../utils/dates";

let cancelFetchWorkOrders = undefined;
let cancelFetchAlerts = undefined;

const cancelPrevFetchWorkOrders = () => {
  cancelFetchWorkOrders && cancelFetchWorkOrders();
};

const cancelPrevFetchAlerts = () => {
  cancelFetchAlerts && cancelFetchAlerts();
};

export const getMonitoringWorkOrders = async filters => {
  cancelPrevFetchWorkOrders();
  try {
    const {data} = await axiosInstance.post(
      "/OT/sel_ot_monitorizacion",
      {
        nu_id_entidad: getCompanyEntityId(),
        fechaIni: filters.startDate
          ? parseDateFromInput(filters.startDate)
          : null,
        fechaFin: filters.endDate
          ? parseDateAndEndOfTimeFromInput(filters.endDate)
          : null,
        centros: filters.centrals.map(c => c.id),
        vehiculos: filters.vehicles.map(v => v.id),
        rutas: filters.routes.map(r => r.id),
        estadoOTs: filters.services
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchWorkOrders = c))
      }
    );
    return MonitoringWorkOrderList.map(data);
  } catch (e) {
    if (isCancel(e)) {
      return {summary: null, workOrders: []};
    }
    return Promise.reject(e);
  }
};

export const getMonitoringAlerts = async filters => {
  cancelPrevFetchAlerts();
  try {
    const {data} = await axiosInstance.post(
      "/OT/sel_ot_monitorizacion_alerta",
      {
        fechaIni: filters.startDate || null,
        fechaFin: filters.endDate || null,
        contacto: filters.contact || null,
        ot: filters.guide || null,
        vehiculo: filters.vehicle || null,
        subEstadoId: filters.subState || null,
        estadoId: filters.state || null,
        gestionId: filters.management || null
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchAlerts = c))
      }
    );
    return data.map(alert => MonitoringAlert.map(alert));
  } catch (e) {
    if (isCancel(e)) {
      return [];
    }
    return Promise.reject(e);
  }
};

export const updateMonitoringAlertState = async alertId => {
  try {
    const {
      data
    } = await axiosInstance.post("/OT/upd_ot_monitorizacion_alerta_leido", {
      nu_id_alerta: alertId
    });
    return Response.map(data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getMonitoringStates = async () => {
  try {
    const {data} = await axiosInstance.post(
      "/OT/sel_ot_monitorizacion_estados",
      {}
    );
    return data.map(s => MonitoringState.map(s));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDispatchWorkOrders = async filters => {
  try {
    const {
      data
    } = await axiosInstance.post("/OT/sel_ot_monitorizacion_despachos_ots", {
      nu_id_entidad: getCompanyEntityId(),
      fechaIni: filters.startDate || null,
      fechaFin: filters.endDate || null,
      centros: filters.centrals.map(c => c.id),
      vehiculos: filters.vehicles.map(v => v.id),
      rutas: filters.routes.map(r => r.id),
      estadoOTs: filters.services
    });
    return data.map(ot => MonitoringDispatchWorkOrder.map(ot));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getDispatchDestinations = async filters => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/OT/sel_ot_monitorizacion_despachos_destinos",
      {
        nu_id_entidad: getCompanyEntityId(),
        fechaIni: filters.startDate,
        fechaFinal: filters.endDate
      }
    );
    return data.map(d => MonitoringDispatchDestination.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};
