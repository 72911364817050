import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.plaque"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.brand"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.model"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.start.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.end.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.driver"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.turn"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.reserves.table.state"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
