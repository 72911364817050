import React, {useEffect} from 'react';
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {getAllProviders} from "../../../../../../../../../services/ControlPanel/Kpi/Reports/ProvidersSelectService";

const ProviderSelect = ({id, value, onChange, errors}) => {
    const [providers, serProviders] = React.useState([]);

    useEffect(() => {
        getAllProviders()
            .then(data => serProviders(data))
            .catch(e => console.error(e.message));
    },[]);

    return(
        <Select
            id={id}
            value={value}
            onChange={e => onChange(e.target.value)}
            label={<IntlMessages id="logistic.inventoryReport.filters.providers"/>}
            fullWidth
            hasError={errors.provider?.length > 0}
            error={errors.provider[0]}
        >
            {
                providers.map(provider => (
                    <MenuItem value={provider.id} key={provider.id}>
                        {provider.name}
                    </MenuItem>
                ))
            }
        </Select>
    );
}

export default ProviderSelect;