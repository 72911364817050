import {mapToBoolean} from "../../utils/mappers";

class ElevatorContract {
  constructor(id, name, state) {
    this.id = id;
    this.name = name;
    this.state = state;
  }

  static map = contract => {
    return new ElevatorContract(
      contract.nu_id_tipo_contrato,
      contract.vc_desc_tipo_contrato,
      mapToBoolean(contract.bi_estado)
    );
  };
}

export default ElevatorContract;
