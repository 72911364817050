import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllWorkshops} from "../../../../../../../services/FleetManagement/DailyOperation/WorkshopRequestService";

const WorkshopSelect = ({id, label, value, setValue, error, hasError}) => {
  const {data: workshops} = useFetch(getAllWorkshops, {}, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      error={hasError}
      errorMessage={error}
    >
      {workshops.map(p => (
        <MenuItem key={`workshop-${p.id}`} value={p.id}>
          {p.description}
        </MenuItem>
      ))}
    </Select>
  );
};

export default WorkshopSelect;
