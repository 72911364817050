import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import ShowTableRow from "components/Common/Tables/CrudTable/ShowTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./Tableheader";
import useUiState from "hooks/Administrator/useUiState";
import StyledTableRow from "components/Common/Tables/StyledTableRow";

const Table = ({data, onShow, isLoading}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  const ui = useUiState();

  const RenderRow = ({row}) => (
    <StyledTableRow>
      <StyledTableCell>{row.label}</StyledTableCell>
      <StyledTableCell>{row.stock}</StyledTableCell>
      <StyledTableCell>{row.amount}</StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderby={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      product: PropTypes.string,
      stock: PropTypes.number
    })
  )
};

Table.defaultProps = {
  data: []
};

export default Table;
