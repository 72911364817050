import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";
import {constructSubsection} from "../../../../../utils/menus";



const getVehiclesDailyOperationOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 6) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.vehicles.file'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/vehicles/file',
    });
    submodules.some(s => s.id === 71) && options.push({
        name: <IntlMessages id={"fleet.management.vehicles.documentation.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/vehicles/documentation',
    });
    return options;
}

const getVehiclesDataMasterOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 90) && options.push({
        name: <IntlMessages id="fleet.management.vehicles.documents.types.sidebar"/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/vehicles/documents-types',
    });
    return options;
}


const getVehiclesOptions = (submodules) => {
    let options = [];
    const dataMaster =  getVehiclesDataMasterOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={"fleet.management.vehicles.data.master.sidebar"}/>,
        type: 'section',
        children: dataMaster,
    });

    const dailyOperations =  getVehiclesDailyOperationOptions(submodules);
    dailyOperations?.length > 0 && options.push({
        name: <IntlMessages id={"fleet.management.vehicles.diary.operative.sidebar"}/>,
        type: 'section',
        children: dailyOperations,
    });


    submodules.some(s => s.id === 7) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.vehicles.insurance'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/vehicles/secure',
    });
    submodules.some(s => s.id === 8) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.vehicles.infractions'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/vehicles/infractions',
    });
    submodules.some(s => s.id === 9) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.vehicles.sinister'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/vehicles/sinister',
    });
    return options;
}

const getDailyOperationDataMasterOptions = (submodules) => {
    let options = [];

    submodules.some(s => s.id === 95) && options.push({
        name: <IntlMessages id={"fleet.management.sinister.type.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/sinister-types',
    });

    submodules.some(s => s.id === 87) && options.push({
        name: <IntlMessages id={"fleet.management.corrective.type.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/corrective-types',
    });

    submodules.some(s => s.id === 88) && options.push({
        name: <IntlMessages id={"fleet.management.vehicle.corrective.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/vehicle-correctives',
    });

    submodules.some(s => s.id === 82) && options.push({
        name: <IntlMessages id={"fleet.management.maintenance.type.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/maintenance-types',
    });

    submodules.some(s => s.id === 83) && options.push({
        name: <IntlMessages id={"fleet.management.maintenance.plan.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/maintenance-plans',
    });

    submodules.some(s => s.id === 84) && options.push({
        name: <IntlMessages id={"fleet.management.vehicle.maintenance.plan.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/vehicle-maintenance-plans',
    });

    return options;
}

const getDailyOperationOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 89) && options.push({
        name: <IntlMessages id="fleet.management.workshop.request.sidebar"/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/workshop-requests',
    });
    submodules.some(s => s.id === 10) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.dailyOperations.workshop'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/workshop',
    });
    submodules.some(s => s.id === 11) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.dailyOperations.refueling'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/refueling',
    });
    submodules.some(s => s.id === 12) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.dailyOperations.tires'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/tires',
    });
    submodules.some(s => s.id === 13) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.dailyOperations.parts'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/parts',
    });
    submodules.some(s => s.id === 14) && options.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.dailyOperations.bookings'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/reserves',
    });

    submodules.some(s => s.id === 85) && options.push({
        name: <IntlMessages id={"fleet.management.vehicles.maintenance.sidebar"}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/daily-operations/vehicle-maintenance',
    });
    const dataMaster =  getDailyOperationDataMasterOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={"fleet.management.data.master"}/>,
        type: 'section',
        children: dataMaster,
    });
    return options;
}
const getTachographOptions = submodules => {
    let options = [];
    const dataMaster = constructSubsection('master.data');
    submodules.some(s => s.id === 15) && dataMaster.children.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.tachograph.files'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/tachograph/files',
    });
    submodules.some(s => s.id === 110) && dataMaster.children.push({
        name: <IntlMessages id={'fleet.management.tachograph.cards'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/tachograph/cards',
    });
    dataMaster.children.length > 0 && options.push(dataMaster);

    const dailyOperative = constructSubsection('daily.operative')
    submodules.some(s => s.id === 69) && dailyOperative.children.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.tachograph.traffic'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/tachograph/traffic',
    });
    submodules.some(s => s.id === 109) && dailyOperative.children.push({
        name: <IntlMessages id={'fleet.management.tachograph.files.reader.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/tachograph/files-reader',
    });
    submodules.some(s => s.id === 16) && dailyOperative.children.push({
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.tachograph.alerts'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/fleet-management/tachograph/alerts',
    });
    dailyOperative.children.length > 0 && options.push(dailyOperative);

    return options;
}
export const getFleetManagementOptions = () => {
    const permissions = getUserPermissions();
    let fleetManagement = {
        name: <IntlMessages id={'sidebar.dasboard.fleetManagement'}/>,
        type: 'section',
        children: []
    };
    let submodules = permissions.find(p => p.id===3)?.submodules;
    if(submodules?.length > 0) {
        const vehiclesOptions = getVehiclesOptions(submodules);
        vehiclesOptions.length > 0 && fleetManagement.children.push({
            name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.vehicles'}/>,
            type: 'collapse',
            children: vehiclesOptions,
        });
    }
    submodules = permissions.find(p => p.id===4)?.submodules;
    if(submodules?.length > 0) {
        const dailyOperationOptions = getDailyOperationOptions(submodules);
        dailyOperationOptions.length > 0 && fleetManagement.children.push({
            name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.dailyOperations'}/>,
            type: 'collapse',
            children: dailyOperationOptions,
        });
    }
    submodules = permissions.find(p => p.id===5)?.submodules;
    if(submodules?.length > 0) {
        const tachographOptions = getTachographOptions(submodules);
        tachographOptions.length > 0 && fleetManagement.children.push({
            name: <IntlMessages id={'sidebar.dasboard.fleetManagement.section.tachograph'}/>,
            type: 'collapse',
            children: tachographOptions,
        });
    }

    return fleetManagement.children.length > 0? fleetManagement : null;

}