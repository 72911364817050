import React from "react";
import TableCellWithSorting from "../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";

const TableHeader = ({order, orderBy, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="id"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.tag.types.table.id" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="description"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.tag.types.table.description" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="state"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.tag.types.table.state" />
    </TableCellWithSorting>
    <StyledTableCell>
      <IntlMessages id="administrator.tag.types.table.actions" />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
