import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";

export const getAllElevatorsGenres = async () => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Genero/sel", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(genre => ({
            id: genre.nu_id_tipo_genero,
            name: genre.vc_desc_tipo_genero,
            state: mapToBoolean(genre.bi_estado)
        }));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const createElevatorGenre = async genre => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Genero/ins", {
            nu_id_entidad: getCompanyEntityId(),
            vc_desc_tipo_genero: genre.name
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateElevatorGenre = async (genreId, name) => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Genero/upd", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_tipo_genero: genreId,
            vc_desc_tipo_genero: name
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateElevatorGenreState = async (genreId, state) => {
    try {
        const {data} = await axiosInstance.post("/Tipo_Genero/del", {
            nu_id_tipo_genero: genreId,
            bi_estado: state
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};