import React from 'react';
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Confirmation from "../../Common/Dialogs/Confirmation";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../redux/reducers/general";
import {dialogsList} from "./constants";
import {editClientState} from "../../../redux/actions/Administrator/Clients";

const DeleteConfirmationDialog = () => {
    const dispatch = useDispatch();
    const dialog = useSelector(({general}) => general.dialogs?.find(dialog => dialog.id === dialogsList.DELETE_CLIENT_CONFIRMATION));
    const handleCloseDialog = () => {
        dispatch(closeDialog(dialogsList.DELETE_CLIENT_CONFIRMATION));
    }
    const onDelete = () => {
        dispatch(editClientState(dialog?.client?.id));
        handleCloseDialog();
    }
    return (
        <Confirmation
            open={Boolean(dialog?.id)}
            text={<IntlMessages id="disable.confirmation" />}
            onReject={handleCloseDialog}
            onAccept={onDelete}
        />
    );
};

export default DeleteConfirmationDialog;