import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import Select from "components/Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import ActiveSelect from "components/Common/Forms/Select/ActiveSelect";

const initialData = {
  groupedBy: "1",
  product: "",
  entity: "",
  campus: "",
  warehouse: "",
  tag: "",
  active: undefined
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <ActiveSelect
            id="stock-state-filter"
            value={formData.active}
            onChange={value => setFormData("active", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Select
            id="stock-product-grouped-by"
            label={<IntlMessages id="guardian.stock.filters.groupedBy" />}
            value={formData.groupedBy}
            onChange={e => setFormData("groupedBy", e.target.value)}
            fullWidth
          >
            <MenuItem value="1">
              <IntlMessages id="guardian.stock.filters.groupedBy.product" />
            </MenuItem>
            <MenuItem value="2">
              <IntlMessages id="guardian.stock.filters.groupedBy.entity" />
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.product}
            id="stock-product-filter"
            label={<IntlMessages id="guardian.stock.filters.product" />}
            onChange={value => setFormData("product", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.entity}
            id="stock-entity-filter"
            label={<IntlMessages id="guardian.stock.filters.entity" />}
            onChange={value => setFormData("entity", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.campus}
            id="stock-campus-filter"
            label={<IntlMessages id="guardian.stock.filters.campus" />}
            onChange={value => setFormData("campus", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.warehouse}
            id="stock-warehouse-filter"
            label={<IntlMessages id="guardian.stock.filters.warehouse" />}
            onChange={value => setFormData("warehouse", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.tag}
            id="stock-tag-filter"
            label={<IntlMessages id="guardian.stock.filters.tag" />}
            onChange={value => setFormData("tag", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
