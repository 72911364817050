import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import WarehouseOriginSelect from "../domain/WarehouseOriginSelect";

let fetchAllCancelToken = undefined;

export const cancelPrevWarehouseOriginSelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllWarehouseOriginSelect = async (idEntity, idCampus) => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Almacen/sel",
      {
        nu_id_entidad: idEntity,
        nu_id_entidad_sede: idCampus
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(warehouseOriginSelect =>
      WarehouseOriginSelect.map(warehouseOriginSelect)
    );
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
