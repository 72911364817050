import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {fetchAllEmployeesByEntity} from "../../../../../../../services/EmployeeService";

const SupervisorSelect = ({id, value, setValue, disabled}) => {
  const [ supervisors, setSupervisors ] = React.useState([]);
  React.useEffect(() => {
    fetchAllEmployeesByEntity()
      .then(employees => setSupervisors(employees))
      .catch(e => setSupervisors([]));
  }, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.responsible.supervisor"
          }
        />
      }
      fullWidth
    >
      {supervisors.map(supervisor => (
        <MenuItem value={supervisor.id} key={supervisor.id}>
          {supervisor.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SupervisorSelect;
