import React, {useCallback, useEffect} from "react";
import {connect} from "react-redux";

import PageContainer from "../../../../../../components/Logistic/RejectionTypes/PageContainer/index";
import CreateRejectionTypeDialog from "../../../../../../components/Logistic/RejectionTypes/SaveRejectionTypeDialog/CreateRejectionTypeDialog";
import EditRejectionTypeDialog from "../../../../../../components/Logistic/RejectionTypes/SaveRejectionTypeDialog/EditRejectionTypeDialog";
import DeleteConfirmation from "../../../../../../components/Logistic/RejectionTypes/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "../../../../../../components/Logistic/RejectionTypes/Confirmations/RestoreConfirmation";
import Table from "../../../../../../components/Logistic/RejectionTypes/Table/index";
import Filters from "../../../../../../components/Logistic/RejectionTypes/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";
import {
  addRejectionType,
  applyRejectionTypesFilters,
  fetchAllRejectionTypes,
  resetCurrentRejectionType,
  resetRejectionTypesFilters,
  resetRejectionTypesState
} from "../../../../../../redux/actions/Logistic/RejectionTypes";

const RejectionTypes = ({tableData, current, dispatch}) => {
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  useEffect(
    () => {
      dispatch(fetchAllRejectionTypes());
      return () => dispatch(resetRejectionTypesState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(applyRejectionTypesFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(resetRejectionTypesFilters());
  const onCreate = data => {
    dispatch(addRejectionType(data));
    setOpenAdd(false);
  };

  return (
    <PageContainer>
      <Filters onFilter={applyFilters} onClose={resetFilters} />
      <Box marginBottom={25}>
        <Table
          data={tableData}
          setOpenEdit={setOpenEdit}
          setOpenDelete={setOpenDelete}
          setOpenRestore={setOpenRestore}
        />
      </Box>
      <CreateRejectionTypeDialog
        isOpen={openAdd}
        onClose={() => setOpenAdd(false)}
        onSubmit={onCreate}
      />
      <EditRejectionTypeDialog
        isOpen={openEdit}
        onClose={() => {
          dispatch(resetCurrentRejectionType(current));
          setOpenEdit(false);
        }}
      />
      <DeleteConfirmation open={openDelete} setOpenDelete={setOpenDelete} />
      <RestoreConfirmation open={openRestore} setOpenRestore={setOpenRestore} />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters, current} = state.logistic.rejectionTypes;
  const filtered = new FilterBuilder(Object.values(data))
    .byFieldIncludes("description", filters.description)
    .byFieldIncludes("code", filters.code)
    .build();
  return {tableData: filtered, current};
};

export default connect(mapStateToProps)(RejectionTypes);
