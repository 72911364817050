import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import EntitySelect from "../domain/EntitySelect";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;
let fetchAllByCompanyId = undefined;

const entity = [
  {
    nu_id_entidad: 1,
    vc_nombre_comercial: "Entity 1"
  }
];

export const cancelPrevEntitySelectFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const cancelPrevEntitySelectFetchAllByCompanyId = () => {
  fetchAllByCompanyId && fetchAllByCompanyId();
};

export const fetchAllEntitySelect = async () => {
  //return entity.map(entitySelect => EntitySelect.map(entitySelect));
  try {
    const response = await axiosInstance.post(
      "/Entidad/sel",
      {},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(entitySelect => EntitySelect.map(entitySelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const fetchAllEntitySelectByCompanyId = async () => {
  try {
    const response = await axiosInstance.post(
      "/Entidad/sel_relacionado",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllByCompanyId = c))
      }
    );
    return response.data.map(entitySelect => EntitySelect.map(entitySelect));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
