import React from 'react';
import SearchFilters from "./SearchFilters";
import DestinationsTable from "./DestinationsTable";
import {Box} from "@material-ui/core";
import EditDialog from "./EditDialog";

const SearchByOthers = () => {
    return (
        <Box style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            height: '70vh'
        }}>
            <SearchFilters />
            <DestinationsTable />
            <EditDialog />
        </Box>
    );
};

export default SearchByOthers;