import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    createElevatorManufacturer,
    getAllElevatorsManufacturers,
    updateElevatorManufacturer,
    updateElevatorManufacturerState
} from "../../../services/ElevatorsManagement/ManufacturersService";


const namespace = 'elevatorManagement/manufacturer';

export const fetchAllManufacturers = createAsyncThunk(
    `${namespace}/fetchAllManufacturers`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const manufacturers = await getAllElevatorsManufacturers();
            dispatch(fetchSuccess());
            return {
                manufacturers,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);

export const addManufacturer = createAsyncThunk(
    `${namespace}/addManufacturer`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await createElevatorManufacturer(request);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                ...request,
                id: response.transactionKey,
                state: true,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);

export const editManufacturer = createAsyncThunk(
    `${namespace}/editManufacturer`,
    async ({request, current}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateElevatorManufacturer(current.id, request.name);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                ...current,
                ...request,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);

export const editManufacturerState = createAsyncThunk(
    `${namespace}/editManufacturerState`,
    async ({id, state}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await updateElevatorManufacturerState(id, state);
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                id,
                state,
            };
        } catch (e) {
            dispatch(fetchError(e));
            return rejectWithValue(e);
        }
    }
);