import React from 'react';
import DrivingIcon from "../../../../Common/Icons/Tachograph/DrivingIcon";
import RestIcon from "../../../../Common/Icons/Tachograph/RestIcon";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AvailabilityIcon from "../../../../Common/Icons/Tachograph/AvailabilityIcon";
import JobIcon from "../../../../Common/Icons/Tachograph/JobIcon";
import UnknownIcon from "../../../../Common/Icons/Tachograph/UnknownIcon";
import ClearIcon from '@material-ui/icons/Clear';

export const ACTIVITY_STATES = Object.freeze({
    3: (className) => <DrivingIcon className={className}/>,
    0: (className) => <RestIcon className={className} />,
    2: (className) => <JobIcon className={className} />,
    1: (className) => <AvailabilityIcon className={className} />,
    7: (className) => <PriorityHighIcon className={className} />,
    6: (className) => <ClearIcon className={className} />,
    '-1': (className) => <UnknownIcon className={className} />,
});

export const COLORS = {
    0:{
        color: "#A9A9A9",
        text: "No disponible",
    },
    1: {
        color: "#0080ff",
        text: "Disponible"
    },
    2: {
        color: "#018001",
        text: "Conducción"
    },
    3: {
        color: "#fffe02",
        text: "Trabajo"
    },
    4: {
        color: "#ffa301",
        text: "Descanso corto"
    },
    5: {
        color: "#ff0000",
        text: "Pausa"
    },
    6: {
        color: "#7f017d",
        text: "Desconocido"
    }
};