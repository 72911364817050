import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import moment from "moment";
import {editTire} from "../../../../../redux/actions/FleetManagement/Tires";
import Form from "../AddTireDialog/Form";
import {parseDateToInput} from "../../../../../utils/dates";
import {Box} from "@material-ui/core";
import FilesArea from "../../../Common/FilesArea";

const EditTireDialog = ({open, setOpen}) => {
  const id = "edit-tire-dialog";
  const {current} = useSelector(({fleetManagement}) => fleetManagement.tires);
  const [ files, setFiles ] = React.useState(current.files || []);
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(editTire({id: current.id, ...data}, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.tires.edit.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.tires.edit.save"} />}
      cancelText={<IntlMessages id={"fleet.management.tires.edit.cancel"} />}
    >
      <Form
        id={id}
        onSubmit={onSubmit}
        initialData={{
          tire: current.identification || "",
          brand: current.brand || "",
          model: current.model || "",
          measures: current.measures || "",
          retreading: current.retreading || "",
          purchaseDate: current.purchaseDate
            ? parseDateToInput(current.purchaseDate)
            : "",
          purchaseValue: current.purchaseValue || "",
          purchaseNotes: current.purchaseNotes || "",
          saleDate: current.saleDate ? parseDateToInput(current.saleDate) : "",
          saleValue: current.saleValue || "",
          saleNote: current.saleNote || "",
          pressure: current.pressure || "",
          original: current.original || "",
          active: current.active || "",
          cocada: current.cocada || "",
          observations: current.observations || ""
        }}
      />
      <Box>
        <FilesArea id={"form-tire-files"} files={files} setFiles={setFiles} />
      </Box>
    </FormDialog>
  );
};

export default EditTireDialog;
