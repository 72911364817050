import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  ADD_ELEVATOR_STATE,
  APPLY_ELEVATORS_STATES_FILTERS,
  EDIT_ELEVATOR_STATE,
  FETCH_ALL_ELEVATORS_STATES,
  RESET_CURRENT_ELEVATOR_STATE,
  RESET_ELEVATORS_STATES_FILTERS,
  RESET_ELEVATORS_STATES_STATE,
  SET_CURRENT_ELEVATOR_STATE
} from "../../types/ElevatorsManagement/StatesTypes";
import {
  createElevatorState,
  getAllElevatorsStates,
  updateElevatorState,
  updateElevatorStateState
} from "../../../services/ElevatorsManagement/StatesService";
import ElevatorState from "../../../domain/ElevatorsManagement/ElevatorState";

export const resetElevatorsStatesState = () => ({
  type: RESET_ELEVATORS_STATES_STATE
});
export const applyElevatorsStatesFilters = filters => ({
  type: APPLY_ELEVATORS_STATES_FILTERS,
  payload: filters
});
export const resetElevatorsStatesFilters = () => ({
  type: RESET_ELEVATORS_STATES_FILTERS
});
export const resetCurrentElevatorState = () => ({
  type: RESET_CURRENT_ELEVATOR_STATE
});
export const setCurrentElevatorState = current => ({
  type: SET_CURRENT_ELEVATOR_STATE,
  payload: current
});
export const fetchAllElevatorsStates = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllElevatorsStates(filters)
      .then(states => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_ELEVATORS_STATES, payload: states});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
export const editElevatorState = (current, data) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorState(current, data.name)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.name = data.name;
        dispatch({type: EDIT_ELEVATOR_STATE, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editElevatorStateState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateElevatorStateState(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        current.state = state;
        dispatch({type: EDIT_ELEVATOR_STATE, payload: current});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addElevatorState = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createElevatorState(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newState = new ElevatorState(
          response.transactionKey,
          data.name,
          true
        );
        dispatch({type: ADD_ELEVATOR_STATE, payload: newState});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
