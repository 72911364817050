import axiosInstance, {CancelToken} from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleVersion from "../../../domain/FleetManagement/Vehicles/VehicleVersion";

let cancelFetchAllToken = undefined;

const cancelPrevFetchAll = () => {
  cancelFetchAllToken && cancelFetchAllToken();
};

export const getAllVersions = async model => {
  try {
    cancelPrevFetchAll();
    const {data} = await axiosInstance.post(
      "/Vehiculo_Version/sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_marca_modelo: model
      },
      {
        cancelToken: new CancelToken(c => (cancelFetchAllToken = c))
      }
    );
    return data.map(b => VehicleVersion.map(b));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addVersion = async (version, model) => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Version/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_marca_modelo: model,
      vc_marca_modelo_version: version.name
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
