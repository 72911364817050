import React from 'react';
import PropTypes from 'prop-types';
import useForm from "../../../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../../../Common/Forms/OutlinedInput";
import VehicleSelect from "./VehicleSelect";
import FilesAreaWithoutData from "../../../../../../../Common/FilesAreaWithoutData";
import StateSelect from "./StateSelect";

const Form = ({id, initialData, onClose, onSubmitForm}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData);
    const onSubmit  = data => {
        onSubmitForm(data);
        onClose();
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                    <VehicleSelect
                        id={"add-accident-vehicle"}
                        value={formData.vehicle}
                        setValue={value => setFormData('vehicle', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.plaque"}/>}
                        hasError={errors?.vehicle?.length > 0}
                        error={errors?.vehicle[0]}
                    />
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        label={<IntlMessages id={"fleet.management.accidents.add.company"} />}
                        value={formData.insuranceCompany}
                        onChange={value => setFormData('insuranceCompany', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        label={<IntlMessages id={"fleet.management.accidents.add.policy"} />}
                        value={formData.policy}
                        onChange={value => setFormData('policy', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} >
                    <StateSelect
                        id={"add-accident-state"}
                        value={formData.status}
                        setValue={value => setFormData('status', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.state"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        label={<IntlMessages id={"fleet.management.accidents.form.observations"} />}
                        value={formData.observations}
                        onChange={value => setFormData('observations', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        label={<IntlMessages id={"fleet.management.accidents.form.ot"} />}
                        value={formData.ot}
                        onChange={value => setFormData('ot', value)}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <FilesAreaWithoutData
                        formId={"sinister"}
                        files={formData.files}
                        setFiles={files => setFormData('files', files)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

Form.propTypes = {
    id: PropTypes.string.isRequired,
    initialData: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default Form;