import React from "react";
import BaseAlertBox from "./BaseAlertBox";
import {Box, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";

const DataBox = ({
  title,
  download,
  setDownload,
  notice,
  setNotice,
  email,
  setEmail
}) => {
  return (
    <BaseAlertBox title={title} email={email} setEmail={setEmail}>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" mb="1em">
          <Typography variant="overline">
            <IntlMessages
              id={
                "fleet.management.tachograph.alerts.table.configuration.data.download.reminder"
              }
            />
          </Typography>
          <Box mx="1em">
            <OutlinedInput
              style={{width: "50px"}}
              value={download}
              onChange={value => setDownload(value)}
            />
          </Box>
          <Typography variant="overline">
            <IntlMessages
              id={
                "fleet.management.tachograph.alerts.table.configuration.data.download.days"
              }
            />
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb="1em">
          <Typography variant="overline">
            <IntlMessages
              id={
                "fleet.management.tachograph.alerts.table.configuration.data.notice.send"
              }
            />
          </Typography>
          <Box mx="1em">
            <OutlinedInput
              style={{width: "50px"}}
              value={notice}
              onChange={value => setNotice(value)}
            />
          </Box>
          <Typography variant="overline">
            <IntlMessages
              id={
                "fleet.management.tachograph.alerts.table.configuration.data.notice.days"
              }
            />
          </Typography>
        </Box>
      </Box>
    </BaseAlertBox>
  );
};

export default DataBox;
