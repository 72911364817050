import {mapToBoolean} from "../../../utils/mappers";

class ChecklistMarkOption {
    constructor(id, name, state) {
        this.id = id;
        this.name = name;
        this.state = state;
    }

    static map = (markOption) => {
        return new ChecklistMarkOption(
            markOption.nu_id_tipo_checklist_respuestas_opciones,
            markOption.vc_desc_tipo_checklist_respuestas_opciones_descripcion,
            mapToBoolean(markOption.bi_estado)
        );
    }
}

export default ChecklistMarkOption;