import React from 'react';
import PropTypes from 'prop-types';
import {Button, Step, StepConnector, StepLabel, Stepper as BaseStepper, Typography} from "@material-ui/core";
import {withStyles} from "@material-ui/styles";
import QontoStepIcon from "./QontoStepIcon";
import {makeStyles} from "@material-ui/core/styles";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const QontoConnector = withStyles((theme) =>({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}))(StepConnector);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    buttonsArea: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Stepper = ({steps, handleFinish, finishText, children, activeStep, setActiveStep, hideFinish = false}) => {
    const classes = useStyles();

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            handleFinish();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const finishConditional = () => activeStep === steps.length - 1;
    return (
        <div className={classes.root}>
            <BaseStepper alternativeLabel activeStep={activeStep} connector={<QontoConnector/>}>
                {steps.map((step) => (
                    <Step key={step.id}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                            {step.label}
                        </StepLabel>
                    </Step>
                ))}
            </BaseStepper>
            <div>
                {children}
            </div>
            <div className={classes.buttonsArea}>
                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                    <IntlMessages id="form.button.back"/>
                </Button>
                {(!finishConditional() || !hideFinish) &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                    >
                        {finishConditional()
                            ? finishText
                            : <IntlMessages id="form.button.next"/>
                        }
                    </Button>}
            </div>
        </div>
    );
};

Stepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    setActiveStep: PropTypes.func.isRequired,
    steps: PropTypes.array.isRequired,
    finishText: PropTypes.node.isRequired,
    handleFinish: PropTypes.func,
    children: PropTypes.node,
    hideFinish: PropTypes.bool,
};

export default Stepper;