import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="date"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logHistory.table.date" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="cashManager"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logHistory.table.cashManager" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="action"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logHistory.table.action" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="amount"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.logHistory.table.amount" />
    </TableCellWithSorting>
    <StyledTableCell>
      <IntlMessages id="administrator.logHistory.table.tags" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="administrator.logHistory.table.file" />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
