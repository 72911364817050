import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  clients: [
    {
      rule: v => v.length > 0,
      message: (
        <IntlMessages id="logistic.inventoryReport.filters.clients.error" />
      )
    }
  ],
  products: [
    {
      rule: v => v.length > 0,
      message: (
        <IntlMessages id="logistic.inventoryReport.filters.products.error" />
      )
    }
  ],
  startDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.date.error" />
      )
    }
  ],
  endDate: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.dataCollectionHistory.filters.date.error" />
      )
    }
  ]
};
