import {
  APPLY_ADVANCES_LIST_FILTERS,
  FETCH_ALL_ADVANCES_BY_GUARDIAN_SUCCEEDED,
  LIQUIDATE_ADVANCE_STARTED,
  LIQUIDATE_ADVANCE_SUCCESS,
  RESET_ADVANCES_LIST_FILTERS,
  SET_ADVANCES_LIST_WITHOUT_ORDER,
  SET_ADVANCES_RESUME
} from "../../types/Guardian/AdvancesCustomTypes";
import {
  cancelPrevFetchAllAdvancesByGuardian,
  fetchAllAdvancesByGuardian
} from "../../../services/AdvanceService";
import {parseNumberToMoney} from "../../../utils/parsers";

const setAdvancesResume = advances => {
  const onTransit = advances.filter(advance => advance.state.id === 2);
  const sum = field => {
    let s = 0;
    onTransit.forEach(advance => {
      s += advance[field];
    });
    return s;
  };
  const driversOnTransit = () => {
    return [ ...new Set(onTransit.map(advance => advance.driver.id)) ].length;
  };
  return {
    type: SET_ADVANCES_RESUME,
    payload: {
      resume: {
        drivers: driversOnTransit(),
        travels: advances.filter(advance => advance.state.id === 2).length,
        assigned: parseNumberToMoney(sum("amount")),
        approved: parseNumberToMoney(sum("passed")),
        expended: parseNumberToMoney(sum("expense")),
        balance: parseNumberToMoney(sum("balance"))
      }
    }
  };
};

const setAdvancesWithoutOrder = data => ({
  type: SET_ADVANCES_LIST_WITHOUT_ORDER,
  payload: {
    list: data
  }
});

const fetchAllAdvancesByGuardianSucceeded = data => ({
  type: FETCH_ALL_ADVANCES_BY_GUARDIAN_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchAllAdvancesByGuardianStarted = filters => {
  return async dispatch => {
    cancelPrevFetchAllAdvancesByGuardian();
    fetchAllAdvancesByGuardian(filters).then(advances => {
      const drivers = [
        ...new Set(advances?.map(advance => advance.driver.id))
      ];
      const groupedByDriverName = drivers.map(driver => ({
        id: driver,
        name: advances?.find(advance => advance.driver.id === driver)?.driver
          ?.name,
        advances: advances?.filter(advance => advance.driver.id === driver)
      }));
      dispatch(fetchAllAdvancesByGuardianSucceeded(groupedByDriverName));
      dispatch(setAdvancesResume(advances));
      dispatch(setAdvancesWithoutOrder(advances));
    });
  };
};

export const applyAdvancesListFilters = filters => ({
  type: APPLY_ADVANCES_LIST_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetAdvancesListFilters = () => ({
  type: RESET_ADVANCES_LIST_FILTERS
});

export const liquidateAdvance = advance => {
  return async dispatch => {
    dispatch({type: LIQUIDATE_ADVANCE_STARTED});
    liquidateAdvance(advance).then(advanceId => {
      dispatch({type: LIQUIDATE_ADVANCE_SUCCESS});
      dispatch(fetchAllAdvancesByGuardianStarted({}));
    });
  };
};
