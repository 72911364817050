import React from "react";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import SideBar from "./Sidebar";
import ChatContainer from "./ChatContainer";
import {useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import useStyles from "./styles";

const ChatDialog = ({open, setOpen}) => {
  const {selectedUser} = useSelector(
    state => state.logistic.monitoring.chatsData
  );
  const classes = useStyles();
  return (
    <FullScreenDialog
      open={open}
      handleClose={() => setOpen(false)}
      title={"logistic.monitoring.chats"}
    >
      {/*<Box className={classes.inBuildAppContainer}>*/}
      {/*    <SideBar />*/}
      {/*    {selectedUser && <ChatContainer/>}*/}
      {/*</Box>*/}
      <Box className={classes.frameBox}>
        <iframe
          id="telegram-chat"
          width={"100%"}
          height={"100%"}
          title="4g Flota Telegram"
          src="https://master.dl2imb23jbfud.amplifyapp.com/"
        />
      </Box>
    </FullScreenDialog>
  );
};

export default ChatDialog;
