import {
  FETCH_MERCHANDISE_RECEPTION_SUCCEEDED,
  ACCEPT_MERCHANDISE_RECEPTION,
  REJECT_MERCHANDISE_RECEPTION
} from "../../types/Administrator/MerchandiseReception";
import {cloneDeep} from "lodash";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_MERCHANDISE_RECEPTION_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case ACCEPT_MERCHANDISE_RECEPTION: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(cloneDeep(type), {active: !type.active});
          return type;
        })
      };
    }
    case REJECT_MERCHANDISE_RECEPTION: {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(cloneDeep(type), {active: !type.active});
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};
