class AdvanceType {
  constructor(
    id,
    entityId,
    description,
    approvalOfExpenses,
    obligationOfOT,
    automaticSatisfied,
    active
  ) {
    this.id = id;
    this.entityId = entityId;
    this.description = description;
    this.approvalOfExpenses = approvalOfExpenses;
    this.obligationOfOT = obligationOfOT;
    this.automaticSatisfied = automaticSatisfied;
    this.active = active;
  }

  static map = advanceType => {
    return new AdvanceType(
      advanceType.nu_id_tipo_anticipo,
      advanceType.nu_id_entidad,
      advanceType.vc_desc_anticipo,
      advanceType.bi_aprobacion_gastos === "1",
      advanceType.bi_obligacion_ot === "1",
      advanceType.bi_conforme_automatico === "1",
      advanceType.bi_estado === "1"
    );
  };
}

export default AdvanceType;
