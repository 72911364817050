import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import validations from "./validations";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import PlaqueSelect from "../../../../Vehicles/Common/Form/Selects/PlaqueSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {Divider, Grid, Typography} from "@material-ui/core";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import WorkshopSelect from "./WorkshopSelect";
import StateSelect from "./StateSelect";
import FilesArea from "../../../../Common/FilesArea";
import TasksTable from "../../../WorkshopRequest/TransactionsDialogs/Form/TasksTable";
import FilesAreaWithoutData from "../../../../Common/FilesAreaWithoutData";
import OutlinedDateTimePicker from "../../../../../Common/Forms/OutlinedDateTimePicker";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={4}>
                    <PlaqueSelect
                        id={"form-workshop-plaque"}
                        value={formData.vehicle}
                        setValue={value => setFormData('vehicle', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.plaque"}/>}
                        hasError={errors?.vehicle?.length > 0}
                        error={errors?.vehicle[0]}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        id={"form-workshop-kilometer"}
                        fullWidth
                        value={formData.kilometer}
                        onChange={value => setFormData('kilometer', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.kilometer"}/>}
                        hasError={errors?.kilometer?.length > 0}
                        error={errors?.kilometer[0]}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <WorkshopSelect
                        id={"form-workshop-workshop"}
                        value={formData.workshop}
                        setValue={value => setFormData('workshop', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.workshop"}/>}
                        hasError={errors?.workshop?.length > 0}
                        error={errors?.workshop[0]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">
                        <IntlMessages id="fleet.management.workshop.form.general.data" />
                    </Typography>
                </Grid>
                <Divider flexItem />
                <Grid item xs={12}>
                    <OutlinedInput
                        id={"form-workshop-ot"}
                        fullWidth
                        value={formData.ot}
                        onChange={value => setFormData('ot', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.ot"}/>}
                        hasError={errors?.ot?.length > 0}
                        error={errors?.ot[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDateTimePicker
                        id={"form-workshop-entry-date"}
                        fullWidth
                        value={formData.entryDate}
                        onChange={value => setFormData('entryDate', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.entry.date"}/>}
                        hasError={errors?.entryDate?.length > 0}
                        error={errors?.entryDate[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StateSelect
                        id={"form-workshop-state"}
                        value={formData.state}
                        setValue={value => setFormData('state', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.state"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDateTimePicker
                        id={"form-workshop-expected-exit-date"}
                        fullWidth
                        value={formData.expectedExitDate}
                        onChange={value => setFormData('expectedExitDate', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.expected.exit.date"}/>}
                        hasError={errors?.expectedExitDate?.length > 0}
                        error={errors?.expectedExitDate[0]}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <OutlinedDateTimePicker
                        id={"form-workshop-exit-date"}
                        fullWidth
                        value={formData.exitDate}
                        onChange={value => setFormData('exitDate', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.exit.date"}/>}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-workshop-amount"}
                        fullWidth
                        value={formData.amount}
                        onChange={value => setFormData('amount', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.amount"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={"form-workshop-observations"}
                        fullWidth
                        value={formData.observations}
                        onChange={value => setFormData('observations', value)}
                        label={<IntlMessages id={"fleet.management.workshop.form.observations"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TasksTable
                        tasks={formData.tasks}
                        setTasks={tasks => setFormData('tasks', tasks)}
                        vehicle={formData.vehicle}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FilesAreaWithoutData
                        formId={"form-workshop-files"}
                        files={formData.files}
                        setFiles={files => setFormData('files', files)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;