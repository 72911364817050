import React from "react";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import Form from "./Form";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const AddClientDialog = ({isOpen, onClose, onSubmit}) => {
  return (
    <FormDialog
      id="create-client-form-dialog"
      isOpen={isOpen}
      title={<IntlMessages id="administrator.clients.create.form.title" />}
      onClose={onClose}
    >
      <Form id="create-client-form-dialog" onSubmit={onSubmit} />
    </FormDialog>
  );
};

export default AddClientDialog;
