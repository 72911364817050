import moment from "moment";

class HistoricalSigning {
  constructor(
    uniqueId,
    id,
    companyId,
    stateColor,
    actualDate,
    startDate,
    endDate,
    prevTime,
    totalTimeWorked,
    totalTimePause,
    name
  ) {
    this.uniqueId = "id" + Math.random().toString(16).slice(2);
    this.id = id;
    this.companyId = companyId;
    this.stateColor = stateColor;
    this.actualDate = new Date(actualDate).toLocaleDateString();
    this.startDate = formatTimeWithSeconds(startDate);
    this.endDate = formatTimeWithSeconds(endDate);
    this.prevTime = formatTime(prevTime);
    this.totalTimeWorked = totalTimeWorked;
    this.totalTimeWorkedFormat = formatTime(totalTimeWorked);
    this.totalTimePause = totalTimePause;
    this.name = name;
    this.diff = calculateDifferenceOfTime(prevTime, totalTimeWorked);
  }

  static map = historicalSigning => {
    return new HistoricalSigning(
      historicalSigning.uniqueId,
      historicalSigning.nu_id_entidad,
      historicalSigning.nu_id_entidad_empresa,
      historicalSigning.nu_id_estado_color,
      historicalSigning.dt_fec_res,
      historicalSigning.dt_fec_ini,
      historicalSigning.dt_fec_fin,
      historicalSigning.vc_tiempo_previsto,
      historicalSigning.vc_tiempo_trabajado,
      historicalSigning.vc_tiempo_pausa,
      historicalSigning.vc_nombre,
      historicalSigning.diff
    );
  };
}

const formatTime = time => {
  let timeArray = time.split(":");
  const dateTime = new Date(
    "October 1, 2022 " + timeArray[0] + ":" + timeArray[1]
  );

  if (dateTime.getHours() > 0) {
    return dateTime.getHours() + "h " + dateTime.getMinutes() + "m";
  } else {
    return dateTime.getMinutes() + "m";
  }
};
const formatTimeWithSeconds = date => {
  if (date !== "") {
    let dt = new Date(date);
    return moment(dt).format("HH:mm");
  } else {
    return "";
  }
};

const calculateDifferenceOfTime = (prevTime, totalTimeWorked) => {
  const datePrevTime = new Date("October 1, 2022 " + prevTime);
  const dateTotalTimeWorked = new Date("October 1, 2022 " + totalTimeWorked);
  let diff = (datePrevTime.getTime() - dateTotalTimeWorked.getTime()) / 1000;
  diff /= 60;
  return timeConvert(Math.abs(Math.round(diff)));
};

function timeConvert(n){
  var num = n;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rhours !== 0) return rhours + "h " + rminutes + "m";
  else return rminutes + "m";
}
export default HistoricalSigning;
