class AdvanceState {
  constructor(id, description) {
    this.id = id;
    this.description = description;
  }

  static map = advanceState => {
    return new AdvanceState(
      advanceState.nu_id_estado_anticipo,
      advanceState.vc_desc_estado_anticipo
    );
  };
}

export default AdvanceState;
