import {Grid, Typography, withStyles} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

export const StyledTitle = withStyles(theme => ({
  root: {
    marginTop: "4px"
  }
}))(Typography);

export const RightGrid = withStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end"
  }
}))(Grid);

export const StyledSubtitle = withStyles(theme => ({
  root: {
    marginTop: "8px"
  }
}))(Typography);

export const CenterGrid = withStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center"
  }
}))(Grid);

const K_WIDTH = 40;
const K_HEIGHT = 40;

export const LocationMarker = withStyles(theme => ({
  root: {
    position: "absolute",
    width: K_WIDTH,
    height: K_HEIGHT,
    left: -K_WIDTH / 2,
    top: -K_HEIGHT / 2,

    border: "5px solid #f44336",
    borderRadius: K_HEIGHT,
    backgroundColor: "white",
    textAlign: "center",
    color: "#3f51b5",
    fontSize: 16,
    fontWeight: "bold",
    padding: 4
  }
}))(RoomIcon);
