import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {editElevatorModel} from "../../../../../../redux/actions/ElevatorsManagement/Models";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const EditModelDialog = ({open, setOpen}) => {
    const {current} = useSelector(({elevatorsManagement}) => elevatorsManagement.models);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(editElevatorModel(current, data));
        setOpen(false);
    }
    return(
        <BaseDialog
            id='edit-model-form'
            title={<IntlMessages id={"elevators.management.elevators.model.type.edit.title"}/>}
            open={open}
            setOpen={setOpen}
            initData={{
                name: current?.name,
            }}
            onSubmit={onSubmit}
        />
    );
}

export default EditModelDialog;