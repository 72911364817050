import {
  GENERATE_REPORT_STARTED,
  GENERATE_REPORT_SUCCESS,
  RESET_REPORT_STATE
} from "../../types/Administrator/ReportsTypes";

const INIT_STATE = {
  filters: null,
  file: "",
  isLoading: false,
  message: ""
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GENERATE_REPORT_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case GENERATE_REPORT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        file: action.payload.file,
        message: action.payload.message
      };
    }
    case RESET_REPORT_STATE: {
      return {
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
