import React from "react";

import Confirmation from "components/Common/Dialogs/Confirmation";
import IntlMessages from "@jumbo/utils/IntlMessages";

const RestoreConfirmation = ({open, onAccept, onReject}) => (
  <Confirmation
    open={open}
    onAccept={onAccept}
    onReject={onReject}
    text={
      <IntlMessages id="administrator.pettyCashTypes.restoreConfirmationText" />
    }
  />
);

export default RestoreConfirmation;
