import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "./Form";

const CreateSupervisorDialog = ({
                                  isOpen, onClose,
                                  onSubmit,
                                  title = <IntlMessages id="administrator.supervisors.createFormTitle" />
}) => (
  <FormDialog
    id="create-supervisor-form"
    isOpen={isOpen}
    title={title}
    onClose={onClose}
    disableEnforceFocus
  >
    <Form id="create-supervisor-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateSupervisorDialog;
