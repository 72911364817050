import React from "react";
import PropTypes from "prop-types";

import DialogBase from "components/Common/Dialogs/DialogBase";
import Button from "@material-ui/core/Button";
import IntlMessages from "@jumbo/utils/IntlMessages";

const FormDialog = ({
  id,
  isOpen,
  title,
  onClose,
  children,
  cancelText,
  submitText,
  disabled,
  ...rest
}) => (
  <DialogBase
    actions={
      <React.Fragment>
        <Button color="primary" onClick={onClose}>
          {cancelText}
        </Button>
        <Button color="primary" type="submit" form={id} disabled={disabled}>
          {submitText}
        </Button>
      </React.Fragment>
    }
    open={isOpen}
    title={title}
    isLoading={false}
    {...rest}
  >
    {children}
  </DialogBase>
);

FormDialog.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  title: PropTypes.node,
  onClose: PropTypes.func,
  children: PropTypes.node,
  cancelText: PropTypes.node,
  submitText: PropTypes.node,
  disabled: PropTypes.bool
};

FormDialog.defaultProps = {
  isOpen: false,
  title: "",
  onClose: () => {},
  children: null,
  cancelText: <IntlMessages id="common.dialogs.formDialog.cancel" />,
  submitText: <IntlMessages id="common.dialogs.formDialog.save" />,
  disabled: false
};

export default FormDialog;
