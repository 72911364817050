import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import PageContainer from "components/Administrator/Traceability/PageContainer";
import ShowTraceabilityDialog from "components/Administrator/Traceability/ShowTraceabilityDialog/WatchTraceabilityDialog";
import Table from "components/Administrator/Traceability/Table";
import Filters from "components/Administrator/Traceability/Filters";
import Box from "@material-ui/core/Box";

import useUiState from "hooks/Administrator/useUiState";
import traceability from "redux/actions/Administrator/Traceability";

const Traceability = () => {
  const ui = useUiState();
  const dispatch = useDispatch();
  const {data: tableData, filters} = useSelector(
    state => state.administrator.traceability
  );

  React.useEffect(
    () => {
      return () => {
        dispatch(traceability.resetState());
      };
    },
    [ dispatch ]
  );

  React.useEffect(
    () => {
      dispatch(traceability.get(filters));
    },
    [ filters, dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(traceability.applyTraceabilityFilter(filters)),
    [ dispatch ]
  );
  const resetFilters = () =>
    dispatch(traceability.resetWarehouseTransferFilters());

  const onShow = data => dispatch(traceability.edit(ui.editingId, data));

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <ShowTraceabilityDialog
        initialData={editing}
        onSubmit={onShow}
        {...ui.editFormProps}
      />
    </PageContainer>
  );
};

export default Traceability;
