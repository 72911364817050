import React from 'react';
import {TextField} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {getAllClientsHeadquarters} from "../../../../../../services/Logistics/Distribution/ClientHeadquarterService";

const ClientHeadquarterSelect = ({label, clientHeadquarter, setValue, disabled = false, name = 'client-headquarter'}) => {
    const {data: options} = useFetch(getAllClientsHeadquarters, {}, [], 'clientsHeadquarters');
    const handleChange = (e, newValue) => {
        setValue(newValue);
    }
    return (
        <Autocomplete
            name={name}
            getOptionSelected={(option, value) => value.id === option.id}
            getOptionLabel={option => option.description || ""}
            options={options}
            onChange={handleChange}
            value={clientHeadquarter}
            autoComplete
            disabled={disabled}
            renderInput={params => (
                <TextField
                    {...params}
                    label={
                        label ??
                        <IntlMessages
                            id={
                                "logistic.work.orders.dialog.add.destination.client.headquarter"
                            }
                        />
                    }
                    fullWidth
                    variant="outlined"
                />
            )}
        />
    );
};

export default ClientHeadquarterSelect;