import moment from "moment";

class Expense {
  constructor(
    id,
    expenseTypeDescription,
    documentTypeDescription,
    amount,
    state,
    fileId,
    rejectTypeDescription,
    advanceId,
    advanceAmount,
    date,
    costCenter,
  ) {
    this.id = id;
    this.expenseTypeDescription = expenseTypeDescription;
    this.documentTypeDescription = documentTypeDescription;
    this.amount = amount;
    this.state = state;
    this.fileId = fileId;
    this.rejectTypeDescription = rejectTypeDescription;
    this.advance = {
      id: advanceId,
      amount: advanceAmount
    };
    this.date = date;
    this.costCenter = costCenter;
  }

  static map = expense => {
    return new Expense(
      expense.nu_id_gasto,
      expense.vc_desc_tipo_gasto,
      expense.vc_desc_tipo_documento,
      parseFloat(expense.nu_imp_gasto),
      expense.vc_estado,
      expense.vc_id_archivo_s3,
      expense.vc_desc_tipo_rechazo,
      expense.nu_id_anticipo,
      parseInt(expense.nu_imp_anticipo),
      expense.dt_fecha &&
        moment(expense.dt_fecha, "MM/DD/YYYY hh:mm:ss A").toDate(),
        expense.vc_desc_centro_costo
    );
  };
}

export default Expense;
