import React from 'react';
import FullScreenDialog from "../../../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {editElevatorInfo, resetCurrentElevator} from "../../../../../../redux/actions/ElevatorsManagement/Elevators";
import {Grid} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {useStyles} from "./styles";
import ElevatorInformation from "./ElevatorInformation";
import ContractInformation from "./ContractInformation";
import StateArea from "./StateArea";
import ResponsibleArea from "./ResponsibleArea";
import ClientsContactList from "./ClientsContactList";
import {parseDateToInput} from "../../../../../../utils/dates";

const ElevatorDetailDialog = ({open, setOpen}) => {
    const classes = useStyles();
    const {current, filters} = useSelector(({elevatorsManagement}) => elevatorsManagement.elevators);
    const dispatch = useDispatch();
    const onCloseDialog = () => {
        setOpen && setOpen(false);
        dispatch(resetCurrentElevator());
    }
    const onSaveContractInformation = data => {
        dispatch(editElevatorInfo(current, data, filters));
    }
    return(
        <FullScreenDialog
            title={"elevators.management.elevators.index.card.detail.dialog.title"}
            open={open}
            handleClose={onCloseDialog}
        >
            {current &&
                <GridContainer
                    className={classes.dialogPrincipalBox}
                    spacing={3}>
                    <Grid item xs={12} md={6}>
                        <ElevatorInformation
                            initialData={{
                                name: current.name || "",
                                client: current.client || "",
                                headquarter: current.headquarter || "",
                                model: current.model || "",
                                ken: current.ken || "",
                                category: current.category || "",
                                stops: current.stops || "",
                                shipmentDate: parseDateToInput(current.shipmentDate) || "",
                                emergency: current.emergency,
                                genre: current.genre || "",
                                type: current.type || "",
                                manufacturer: current.manufacturer || "",
                            }}
                            onSave={onSaveContractInformation}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContractInformation
                            initialData={{
                                type: current.contract?.id || "",
                                price: current.contract?.price || "",
                                currency: current.contract?.priceType || "",
                                freeMaintenanceStartDate: parseDateToInput(current.contract?.freeMaintenance?.startDate) || "",
                                freeMaintenanceEndDate: parseDateToInput(current.contract?.freeMaintenance?.endDate) || "",
                                warrantyStartDate: parseDateToInput(current.contract?.warranty?.startDate) || "",
                                warrantyEndDate: parseDateToInput(current.contract?.warranty?.endDate) || "",
                                startDate: parseDateToInput(current.contract?.startDate) || "",
                                endDate: parseDateToInput(current.contract?.endDate) || "",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StateArea
                            initialData={{
                                state: current.state?.id || "",
                                detail: current.state?.detailId || "",
                                reason: current.state?.reason || ""
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ResponsibleArea
                            initialData={{
                                analyst: current.responsibleList?.analyst || "",
                                supervisor: current.responsibleList?.supervisor || "",
                                technical: current.responsibleList?.technical || "",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ClientsContactList
                            clientsList={current.contactClients}
                        />
                    </Grid>
                </GridContainer>
            }
        </FullScreenDialog>
    );
}

export default ElevatorDetailDialog;