import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import NoFound from "../NoFound";
import {useStyles} from "./styles";
import CmtList from "../../../../../../../@coremat/CmtList";
import WorkOrderItem from "./WorkOrderItem";
import {getChatSidebarHeight} from "../../../../../../../@jumbo/constants/AppConstants";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllDispatchesWorkOrdersStarted} from "../../../../../../../redux/actions/Logistic/Monitoring";

const WorkOrdersList = ({
  workOrders,
  onSelectWorkOrder,
  selectedWorkOrder,
  width
}) => {
  const classes = useStyles({height: getChatSidebarHeight(width)});
  const {filters} = useSelector(state => state.logistic.monitoring);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllDispatchesWorkOrdersStarted(filters));
  }, []);
  return (
    <React.Fragment>
      {workOrders.length > 0 ? (
        <PerfectScrollbar className={classes.perfectScrollbarRoot}>
          <CmtList
            data={workOrders}
            renderRow={data => (
              <WorkOrderItem
                classes={classes}
                key={data.id}
                data={data}
                onSelectWorkOrder={onSelectWorkOrder}
                selectedWorkOrder={selectedWorkOrder}
              />
            )}
          />
        </PerfectScrollbar>
      ) : (
        <NoFound />
      )}
    </React.Fragment>
  );
};

export default WorkOrdersList;
