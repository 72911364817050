import {
  FETCH_ALL_CHECKLIST_DATA,
  FETCH_ALL_WORK_ORDERS_TYPES,
  RESET_CURRENT_WORK_ORDER_TYPE,
  RESET_WORK_ORDERS_TYPES,
  SET_CURRENT_WORK_ORDER_TYPE
} from "../../types/Logistic/WorkOrdersTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import WorkOrderType from "../../../domain/Logistic/Distribution/WorkOrderType";
import WorkOrderTypeState from "../../../domain/Logistic/Distribution/WorkOrderTypeState";
import {
  addComplementaryStateToWorkOrderType,
  addStateToWorkOrderType,
  createWorkOrderType,
  getAllWorkOrdersTypes,
  updateWorkOrderType,
  updateWorkOrderTypeState,
  updateWorkOrderTypeStatus
} from "../../../services/Logistics/Distribution/WorkOrderTypeService";
import {getAllChecklistTypesForSelect} from "../../../services/Logistics/Distribution/ChecklistTypeService";

const states = [
  new WorkOrderTypeState(
    1,
    "Pendiente de Inicio",
    {id: 1, description: "Inicio"},
    false,
    false,
    [
      {
        id: 2,
        name: "Recepcion",
        status: true
      },
      {
        id: 3,
        name: "Rechazo",
        status: false
      }
    ],
    0,
    1,
    [],
    [
      {
        id: 1,
        name: "State Siguiente 1"
      },
      {
        id: 2,
        name: "State Siguiente 2"
      }
    ]
  ),
  new WorkOrderTypeState(
    2,
    "Recepcion",
    {id: 2, description: "En Proceso"},
    false,
    false,
    [],
    1,
    2,
    [
      {
        id: 1,
        name: "State Anterior 1"
      },
      {
        id: 2,
        name: "State Anterior 2"
      }
    ],
    [
      {
        id: 1,
        name: "State Siguiente 1"
      },
      {
        id: 2,
        name: "State Siguiente 2"
      }
    ]
  ),
  new WorkOrderTypeState(
    3,
    "Rechazo",
    {id: 2, description: "En Proceso"},
    false,
    false,
    [],
    1,
    2,
    [
      {
        id: 1,
        name: "State Anterior 1"
      },
      {
        id: 2,
        name: "State Anterior 2"
      }
    ],
    [
      {
        id: 1,
        name: "State Siguiente 1"
      },
      {
        id: 2,
        name: "State Siguiente 2"
      }
    ]
  ),
  new WorkOrderTypeState(
    4,
    "Finalizado",
    {id: 3, description: "Final"},
    false,
    false,
    [],
    1,
    0,
    [
      {
        id: 1,
        name: "State Anterior 1"
      },
      {
        id: 2,
        name: "State Anterior 2"
      }
    ],
    []
  )
];

const otTypes = [
  new WorkOrderType(
    1,
    "Ruta Cruz del Sur",
    true,
    [ states[0], states[3] ],
    [ states[1], states[2] ],
    {
      departureState: states[1],
      arrivalState: states[3],
      nextService: states[0]
    }
  )
];

export const resetWorkOrdersStateTypes = () => ({
  type: RESET_WORK_ORDERS_TYPES
});
export const setCurrentWorkOrderType = type => ({
  type: SET_CURRENT_WORK_ORDER_TYPE,
  payload: type
});
export const resetCurrentWorKOrderType = () => ({
  type: RESET_CURRENT_WORK_ORDER_TYPE
});
export const fetchAllWorkOrdersTypes = () => {
  return async dispatch => {
    dispatch(fetchStart());
    dispatch(fetchAllChecklistData());
    getAllWorkOrdersTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_WORK_ORDERS_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const fetchAllChecklistData = () => {
  return async dispatch => {
    getAllChecklistTypesForSelect()
      .then(items => {
        dispatch({type: FETCH_ALL_CHECKLIST_DATA, payload: items});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addWorkOrderType = (type, onAccept) => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const response = await createWorkOrderType(type);
      await addStateToWorkOrderType(response.transactionKey, type.startState);
      await addStateToWorkOrderType(response.transactionKey, type.endState);
      dispatch(fetchSuccess(response.transactionMessage));
      setTimeout(() => {
        dispatch(fetchAllWorkOrdersTypes());
      }, 1500);
    } catch (e) {
      dispatch(fetchError(e.message));
    }
    onAccept && onAccept();
  };
};

export const editWorkOrderTypeStatus = (current, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateWorkOrderTypeStatus(current, status)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllWorkOrdersTypes());
        }, 1500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editWorkOrderType = (current, type) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateWorkOrderType({...current, ...type})
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllWorkOrdersTypes());
        }, 1500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addWorkOrderTypeState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    addStateToWorkOrderType(current.id, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllWorkOrdersTypes());
        }, 1500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editWorkOrderTypeState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateWorkOrderTypeState(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllWorkOrdersTypes());
        }, 1500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addComplementaryWorkOrderTypeState = (current, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    addComplementaryStateToWorkOrderType(current, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const updatedType = new WorkOrderType(
          current.id,
          current.name,
          current.status,
          current.principalStates,
          [
            ...current.complementaryStates,
            new WorkOrderTypeState(
              response.transactionKey,
              state.name,
              state.type,
              false,
              false,
              [],
              "",
              "",
              current.complementaryStates.length + 3,
              true,
                false,
                false
            )
          ],
          current.automaticStates
        );
        dispatch(setCurrentWorkOrderType(updatedType));
        setTimeout(() => {
          dispatch(fetchAllWorkOrdersTypes());
        }, 1500);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
