import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../hooks/Common/useForm";
import validations from "./Form/validations";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import CompanySelect from "../../Common/Form/Selects/CompanySelect";
import TypeSelect from "./Form/TypeSelect";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import BaseField from "../../../../Guardian/Common/Fields/BaseField";
import {useDispatch} from "react-redux";
import {addInsurance} from "../../../../../redux/actions/FleetManagement/Insurances";
import PlaqueSelect from "../../Common/Form/Selects/PlaqueSelect";
import Form from "./Form";

const initialData = {
  plaque: "",
  terminationDate: "",
  company: "",
  type: "",
  commencementDate: "",
  expirationDate: "",
  policy: "",
  premium: "",
  phone: "",
  contact: ""
};
const AddInsuranceDialog = ({open, setOpen}) => {
  const id = "add-insurance-dialog";
  const dispatch = useDispatch();
  const onSubmit = insurance => {
    dispatch(addInsurance(insurance, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      onClose={() => setOpen(false)}
      submitText={<IntlMessages id={"fleet.management.insurance.add.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      title={<IntlMessages id={"fleet.management.insurance.add.title"} />}
    >
      <Form id={id} onSubmit={onSubmit} initialData={initialData} />
    </FormDialog>
  );
};

export default AddInsuranceDialog;
