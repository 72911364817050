import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {
    getAllWorkshopTaskStates
} from "../../../../../../../../../services/FleetManagement/DailyOperation/WorkshopTaskService";
import {useFetch} from "../../../../../../../../../hooks/Common/useFetch";

const StatusSelect =({value, onChange}) => {
    const {data: states} = useFetch(getAllWorkshopTaskStates, {}, []);
    return (
        <Select
            id='workshop-task-type-select'
            label={
                <IntlMessages id="fleet.management.workshop.request.form.tasks.form.status" />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
        >
            <MenuItem value={""}>
                <IntlMessages id="select.all" />
            </MenuItem>
            {states.map(s => (
                <MenuItem value={s.id} key={s.id}>
                    {s.description}
                </MenuItem>
            ))}
        </Select>
    );
};

StatusSelect.propTypes = {

};

export default StatusSelect;