import React from "react";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  SelectedStyledTableRow,
  StyledTableCell,
  DefaultStyledTableRow,
  ConditionalButton
} from "./styles";
import {Tooltip} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {parseNumberToMoney} from "../../../../utils/parsers";
import {useDispatch, useSelector} from "react-redux";
import {clearCurrentAdvance} from "../../../../redux/actions/Guardians/CurrentAdvance";
import moment from "moment";
import {
  approveAdvanceStarted,
  rejectAdvanceStarted
} from "../../../../redux/actions/Supervisor/UnresolvedAdvances";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const AdvancesRow = ({driver, setSelectedAdvance, isGuardian = true}) => {
  const {name, advances} = driver;
  const rows = advances.length;
  const collapseRowsData = advances.slice(1);
  const [ open, setOpen ] = React.useState(rows <= 1);
  const classes = useRowStyles();
  const {currentAdvance, advancesList: advancesState} = useSelector(
    state => state.guardian
  );
  const dispatch = useDispatch();

  const actions = [
    {
      label: <IntlMessages id={"guardian.advances.table.options.approve"} />,
      onCLick: advance =>
        dispatch(approveAdvanceStarted(advance, advancesState.filters))
    },
    {
      label: <IntlMessages id={"guardian.advances.table.options.reject"} />,
      onCLick: advance =>
        dispatch(rejectAdvanceStarted(advance, advancesState.filters))
    }
  ];

  const sumValues = field => {
    let sum = 0;
    advances.forEach(advance => {
      sum += advance[field];
    });
    return sum;
  };

  const selectHandler = advance => {
    if (rows > 1 && !open) {
      setOpen(true);
    } else {
      setSelectedAdvance({driver: driver, ...advance});
    }
  };

  const openHandler = () => {
    if (
      open &&
      advances.filter(advance => advance.id === currentAdvance.id).length > 0
    ) {
      dispatch(clearCurrentAdvance());
    }
    setOpen(!open);
  };

  const optionClickHandler = (onClick, advance) => {
    onClick(advance);
  };

  const renderFirstColumn = (
    <React.Fragment>
      <StyledTableCell>
        <ConditionalButton
          disabled={rows <= 1}
          aria-label="expand row"
          size="small"
          onClick={() => openHandler()}
        >
          {open ? <RemoveIcon /> : <AddIcon />}
        </ConditionalButton>
      </StyledTableCell>
      <StyledTableCell
        onClick={() => selectHandler(advances[0])}
        component="th"
        scope="row"
      >
        {name}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? (
          "Varios"
        ) : (
          <React.Fragment>
            {advances[0].tags.map(tag => (
              <p key={`${advances[0].id} : TAG - ${tag}`}>{tag}</p>
            ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {advances[0].tags.map(tag => (
              <p key={`${advances[0].id} : TAG - ${tag}`}>{tag}</p>
            ))}
          </React.Fragment>
        )}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
            {!open ? rows > 1 ? (
                "Varios"
            ) : (
                <React.Fragment>
                    {advances[0].box?.name}
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {advances[0].box?.name}
                </React.Fragment>
            )}
        </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? (
          "Varios"
        ) : (
          moment(advances[0].createDate).format("DD/MM/YYYY HH:mm")
        ) : (
          moment(advances[0].createDate).format("DD/MM/YYYY HH:mm")
        )}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? (
          "Varios"
        ) : (
          advances[0].lastUpdate &&
          moment(advances[0].lastUpdate).format("DD/MM/YYYY HH:mm")
        ) : (
          advances[0].lastUpdate &&
          moment(advances[0].lastUpdate).format("DD/MM/YYYY HH:mm")
        )}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? "Varios" : advances[0].travel : advances[0].travel}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? (
          "Varios"
        ) : (
          advances[0].routeCode
        ) : (
          advances[0].routeCode
        )}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? (
          "Varios"
        ) : (
          advances[0].routeDescription
        ) : (
          advances[0].routeDescription
        )}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {parseNumberToMoney(!open ? sumValues("advanceAmount") : advances[0].advanceAmount)}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {parseNumberToMoney(!open ? sumValues("extension") : advances[0].extension)}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {parseNumberToMoney(!open ? sumValues("refund") : advances[0].refund)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {parseNumberToMoney(!open ? sumValues("expense") : advances[0].expense)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {parseNumberToMoney(!open ? sumValues("passed") : advances[0].passed)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {parseNumberToMoney(!open ? sumValues("balance") : advances[0].balance)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(advances[0])} align="left">
        {!open ? rows > 1 ? (
          "Varios"
        ) : (
          advances[0].state.description
        ) : (
          advances[0].state.description
        )}
      </StyledTableCell>
      {!isGuardian &&
      advances[0].state.id === 1 && (
        <StyledTableCell align="left">
          {open || rows <= 1 ? (
            <CmtDropdownMenu
              TriggerComponent={
                <Tooltip title="More">
                  <IconButton style={{marginLeft: 4}}>
                    <MoreVertIcon />
                  </IconButton>
                </Tooltip>
              }
              items={actions}
              onItemClick={item => {
                optionClickHandler(item.onCLick, advances[0]);
              }}
            />
          ) : (
            <p />
          )}
        </StyledTableCell>
      )}
    </React.Fragment>
  );

  const renderCollapseRows = row => (
    <React.Fragment>
      <StyledTableCell
        onClick={() => selectHandler(row)}
        style={{color: "transparent", paddingRight: 5}}
      >
        <IconButton style={{color: "transparent"}} size="small" disabled>
          <AddIcon />
        </IconButton>
      </StyledTableCell>
      <StyledTableCell
        onClick={() => selectHandler(row)}
        style={{color: "transparent", paddingRight: 4, paddingLeft: 0}}
      >
        {name}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {row.tags.map(tag => <p key={`${row.id} : TAG - ${tag}`}>{tag}</p>)}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(row)} align="left">
            {row.box?.name}
        </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {moment(row.createDate).format("DD/MM/YYYY HH:mm")}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {row.lastUpdate && moment(row.lastUpdate).format("DD/MM/YYYY HH:mm")}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {row.travel}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {row.routeCode}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {row.routeDescription}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {parseNumberToMoney(row.advanceAmount)}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {parseNumberToMoney(row.extension)}
      </StyledTableCell>
        <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {parseNumberToMoney(row.refund)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {parseNumberToMoney(row.expense)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {parseNumberToMoney(row.passed)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {parseNumberToMoney(row.balance)}
      </StyledTableCell>
      <StyledTableCell onClick={() => selectHandler(row)} align="left">
        {row.state.description}
      </StyledTableCell>
      {!isGuardian &&
      row.state.id === 1 && (
        <StyledTableCell align="left">
          <CmtDropdownMenu
            TriggerComponent={
              <Tooltip title="More">
                <IconButton style={{marginLeft: 4}}>
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            }
            items={actions}
            onItemClick={item => {
              optionClickHandler(item.onCLick, row);
            }}
          />
        </StyledTableCell>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {currentAdvance.travel === advances[0].id ? (
        <SelectedStyledTableRow className={classes.root}>
          {renderFirstColumn}
        </SelectedStyledTableRow>
      ) : (
        <DefaultStyledTableRow className={classes.root}>
          {renderFirstColumn}
        </DefaultStyledTableRow>
      )}
      {open &&
        collapseRowsData.map(
          row =>
            currentAdvance.id === row.id ? (
              <SelectedStyledTableRow key={`${row.id}`}>
                {renderCollapseRows(row)}
              </SelectedStyledTableRow>
            ) : (
              <DefaultStyledTableRow key={`${row.id}`}>
                {renderCollapseRows(row)}
              </DefaultStyledTableRow>
            )
        )}
      {open &&
      rows > 1 && (
        <DefaultStyledTableRow>
          <StyledTableCell colSpan={9} />
          <StyledTableCell align="left">
            {parseNumberToMoney(sumValues("advanceAmount"))}
          </StyledTableCell>
            <StyledTableCell align="left">
            {parseNumberToMoney(sumValues("extension"))}
          </StyledTableCell>
            <StyledTableCell align="left">
            {parseNumberToMoney(sumValues("refund"))}
          </StyledTableCell>
          <StyledTableCell align="left">
            {parseNumberToMoney(sumValues("expense"))}
          </StyledTableCell>
          <StyledTableCell align="left">
            {parseNumberToMoney(sumValues("passed"))}
          </StyledTableCell>
          <StyledTableCell align="left">
            {parseNumberToMoney(sumValues("balance"))}
          </StyledTableCell>
          <StyledTableCell />
          {!isGuardian && <StyledTableCell />}
        </DefaultStyledTableRow>
      )}
    </React.Fragment>
  );
};

export default AdvancesRow;
