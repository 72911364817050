import React from 'react';
import RestrictedRoute from "../../RestrictedRoute";
import {Redirect} from "react-router";
import {useSelector} from "react-redux";

const UserConfigurationRoute = ({path, component}) => {
    const { permissions } = useSelector(({ auth }) => auth);
    const authorization = permissions.some(p => p.id === 1);
    return(
        <>
            {authorization
                ? <RestrictedRoute path={path} component={component}/>
                : <Redirect
                    to='/home-page'
                />
            }
        </>
    );
}

export default UserConfigurationRoute;