import React from 'react';
import useForm from "../../../../../../../hooks/Common/useForm";
import validations from "./validations";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const Form = ({id, initData, onSubmit}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initData, validations);

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={id+'name'}
                        label={<IntlMessages id={'elevators.management.elevators.types.form.name'}/>}
                        fullWidth
                        value={formData.name}
                        onChange={value => setFormData('name', value)}
                        error={errors?.name[0]}
                        hasError={errors?.name?.length > 0}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;