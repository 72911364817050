import React from 'react';
import {useSelector} from "react-redux";
import WidgetsContainer from "./WidgetsContainer";
import Filters from "./Filters";
import defaultDashboards from "../defaultDashboards";

const DashboardContainer = () => {
    const {currentDashboard} = useSelector(({controlPanel}) => controlPanel.dashboards);

    return (
        <>
            <Filters />
            {currentDashboard?.custom
                ?<WidgetsContainer />
                : defaultDashboards[currentDashboard?.id]?.render()
            }
        </>
    );
};

export default DashboardContainer;