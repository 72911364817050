class Employee {
  constructor(id, firstName, lastName, email, generalPhone, starred, labels) {
    this.id = id;
    this.name = firstName + " " + lastName;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.generalPhone = generalPhone;
    this.starred = starred;
    this.labels = labels;
  }

  static map = employee => {
    return new Employee(
      employee.nu_id_entidad_empleado,
      employee.vc_nombre || "",
      employee.vc_apellidos || "",
      employee.vc_email || "",
      employee.vc_telefono || "",
      employee.bi_favorito,
      employee.tags.map(tag => Number(tag.nu_id_tag))
    );
  };
}

export default Employee;
