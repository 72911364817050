import React from 'react';
import {Divider} from "@material-ui/core";
import BasicInfoForm from "./BasicInfoForm";
import LocationForm from "./LocationForm";
import ControlForm from "./ControlForm/ControlForm";
import ItemsList from "./ItemsList";
import "./styles.css";

const Form =  ({initData = {}}) => {
    return (
        <div className="order-form">
            <BasicInfoForm initData={initData} />
            <Divider />
            <LocationForm
                name="origin"
                location={initData.origin}
            />
            <Divider />
            <LocationForm
                name="destination"
                location={initData.destination}
            />
            <Divider />
            <ControlForm
                control={initData.control}
            />
            <Divider />
            <ItemsList
                items={initData.items}
            />
        </div>
    );
};

export default Form;