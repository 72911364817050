import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    addCreditNote,
    cancelCreditNote,
    deleteCreditNote,
    editCreditNote,
    fetchCreditNoteFile,
    sentSunatCreditNote
} from "../../../services/Treasury/CreditNotesService";
import {downloadFile} from "../../../services/FileService";
import {getAllBills, getBillServices} from "../../../services/Treasury/BillsService";

const baseNamespace = 'treasury/creditNotes';
export const fetchAllCreditNotes = createAsyncThunk(
    `${baseNamespace}/fetchAllCreditNotes`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const isDebitNote = window.location.pathname.includes("debit-notes");
            const creditNotes = await getAllBills({type: isDebitNote ? 10 : 9});
            dispatch(fetchSuccess());
            return {creditNotes};
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getAndSetCreditNoteDetails = createAsyncThunk(
    `${baseNamespace}/getAndSetCreditNoteDetails`,
    async ({creditNote}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const services = await getBillServices(creditNote.id);
            dispatch(fetchSuccess());
            return {
                ...creditNote,
                services,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const createCreditNote = createAsyncThunk(
    `${baseNamespace}/createCreditNote`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addCreditNote({request});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllCreditNotes());
            }, 1500);
            return {
                response
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateCreditNote = createAsyncThunk(
    `${baseNamespace}/updateCreditNote`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editCreditNote({request});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllCreditNotes());
            }, 1500);
            return response;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const removeCreditNote = createAsyncThunk(
    `${baseNamespace}/removeCreditNote`,
    async ({creditNoteId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await deleteCreditNote({creditNoteId});
            dispatch(fetchSuccess(response.transactionMessage));
            return {creditNoteId};
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const sendSunatSelectedCreditNotes = createAsyncThunk(
    `${baseNamespace}/sendSunatSelectedCreditNotes`,
    async ({selectedCreditNotes}, {dispatch, rejectWithValue}) => {
        try {
            if (selectedCreditNotes.length === 0) {
                throw new Error('No se ha seleccionado ningún factura');
            }
            dispatch(fetchStart());
            const responses = await Promise.all(selectedCreditNotes.map(creditNoteId => sentSunatCreditNote({creditNoteId})));
            dispatch(fetchSuccess('Facturas enviadas a Sunat correctamente'));
            setTimeout(() => {
                dispatch(fetchAllCreditNotes());
            }, 1500);
            return {selectedCreditNotes};
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const downloadCreditNoteFile = createAsyncThunk(
    `${baseNamespace}/downloadCreditNoteFile`,
    async ({creditNoteId, fileType}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const base64 = await fetchCreditNoteFile({creditNoteId, fileType});
            downloadFile(`COMPROBANTE.${fileType}`, base64);
            dispatch(fetchSuccess("Descarga exitosa"));
            return;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const cancelSentCreditNote = createAsyncThunk(
    `${baseNamespace}/cancelSentCreditNote`,
    async ({creditNoteId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await cancelCreditNote({creditNoteId});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllCreditNotes());
            }, 1500);
            return;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);