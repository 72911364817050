import React from "react";
import {Box} from "@material-ui/core";
import Form from "./Form";
import VehicleList from "./VehiclesList";

const InitialInformation = ({
  selectedVehicles,
  setSelectedVehicles,
  show,
  setShow,
  initialInfo,
  setInitialInfo
}) => {
  return (
    <Box spacing={4} display="flex" flexDirection="column">
      <Form setInitialInfo={setInitialInfo} initialInfo={initialInfo} />
      <VehicleList
        selectedVehicles={selectedVehicles}
        setSelectedVehicles={setSelectedVehicles}
        show={show}
        setShow={setShow}
      />
    </Box>
  );
};

export default InitialInformation;
