export const dialogsList = Object.freeze({
    addOrdersDialog: 'add-provider-orders-dialog',
    editOrderDialog: 'edit-provider-order-dialog',
    contactForm: 'contact-form-dialog',
    itemForm: 'item-form-dialog',
});

export const itemTypes = Object.freeze({
    product: 'product',
    service: 'service'
});


export const locationType = Object.freeze({
    origin: 'origin',
    destination: 'destination'
});
