import {
  cancelPrevDataCollectionHistoryFetchAll,
  fetchAllDataCollectionHistory
} from "../../../services/DataCollectionHistoryService";
import {
  cancelPrevVehicleSelectFetchAll,
  fetchAllVehicleSelect
} from "../../../services/VehicleServiceSelect";
import {
  cancelPrevEventSelectFetchAll,
  fetchAllEventSelect
} from "../../../services/EventSelecService";
import {
  cancelPrevDriverSelectFetchAll,
  fetchAllDriverSelect
} from "../../../services/DriverSelectService";

import {restoreAdministratorState} from "./Common";

import {
  APPLY_DATA_COLLECTION_HISTORY_FILTERS,
  FETCH_DATA_COLLECTION_HISTORY_SUCCEEDED,
  RESET_DATA_COLLECTION_HISTORY_FILTERS
} from "../../types/Administrator/DataCollectionHistory";
import {FETCH_VEHICLE_SELECT} from "../../types/Administrator/VehicleSelect";
import {FETCH_ROUTE_SELECT} from "../../types/Administrator/RouteSelect";
import {FETCH_TURN_SELECT} from "../../types/Administrator/TurnSelect";
import {FETCH_EVENT_SELECT} from "../../types/Administrator/EventSelect";
import {FETCH_DRIVER_SELECT} from "../../types/Administrator/DriverSelect";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevDataCollectionHistoryFetchAll();
  cancelPrevVehicleSelectFetchAll();
  //cancelPrevRouteSelectFetchAll();
  //cancelPrevTurnSelectFetchAll();
  cancelPrevEventSelectFetchAll();
  cancelPrevDriverSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchDataCollectionHistorySucceeded = data => ({
  type: FETCH_DATA_COLLECTION_HISTORY_SUCCEEDED,
  payload: {
    data
  }
});

const fetchVehicleSelectSucceeded = data => ({
  type: FETCH_VEHICLE_SELECT,
  payload: {
    data
  }
});

const fetchRouteSelectSucceeded = data => ({
  type: FETCH_ROUTE_SELECT,
  payload: {
    data
  }
});

const fetchTurnSelectSucceeded = data => ({
  type: FETCH_TURN_SELECT,
  payload: {
    data
  }
});

const fetchEventSelectSucceeded = data => ({
  type: FETCH_EVENT_SELECT,
  payload: {
    data
  }
});

const fetchDriverSelectSucceeded = data => ({
  type: FETCH_DRIVER_SELECT,
  payload: {
    data
  }
});

export const fetchDataCollectionHistory = () => async dispatch => {
  dispatch(fetchStart());
  //cancelPrevTurnSelectFetchAll();
  //cancelPrevRouteSelectFetchAll();
  cancelPrevEventSelectFetchAll();
  cancelPrevDriverSelectFetchAll();
  cancelPrevVehicleSelectFetchAll();
  cancelPrevDataCollectionHistoryFetchAll();
  try {
    const vehicle = await fetchAllVehicleSelect();
    //const route = await fetchAllRouteSelect();
    //const turn = await fetchAllTurnSelect();
    const events = await fetchAllEventSelect();
    const driver = await fetchAllDriverSelect();
    dispatch(fetchSuccess());
    dispatch(fetchVehicleSelectSucceeded(vehicle));
    //dispatch(fetchRouteSelectSucceeded(route));
    //dispatch(fetchTurnSelectSucceeded(turn));
    dispatch(fetchEventSelectSucceeded(events));
    dispatch(fetchDriverSelectSucceeded(driver));
  } catch (error) {
    dispatch(fetchError(error.message));
  }
};

export const applyDataCollectionHistoryFilters = filters => ({
  type: APPLY_DATA_COLLECTION_HISTORY_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetDataCollectionHistoryFilters = () => ({
  type: RESET_DATA_COLLECTION_HISTORY_FILTERS
});

export default {
  get: fetchDataCollectionHistory,
  applyDataCollectionHistoryFilters,
  resetDataCollectionHistoryFilters,
  resetState
};
