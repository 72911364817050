import axiosInstance from "../../utils/axiosInstance";

export const getAllDebitNoteTypes = async () => {
    try {
        const {data} = await axiosInstance.get(
            `/TipoNotaDebitoElectronica/sel`,
        );
        return data.map(type => ({
            id: type.nu_id_tipo_nota_debito_electronica,
            value: type.nu_id_tipo_nota_debito_electronica,
            name: type.vc_desc_tipo_nota_debito_electronica,
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}