class KpiReport {
  constructor(id, type, name, date, filters) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.date = date;
    this.filters = filters;
  }

  static map = report => {
    return new KpiReport(
      report.nu_id_informe_consultado ||
        report.nu_id_informe_favorito ||
        report.nu_id_informe_archivado ||
        report.nu_id_informe_programado,
      report.nu_id_informe,
      report.vc_nombre,
      report.dt_fecha || report.dt_fecha_creacion,
      report.vc_parametros ? JSON.parse(report.vc_parametros) : {}
    );
  };
}

export default KpiReport;
