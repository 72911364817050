import MonitoringWorkOrder from "./MonitoringWorkOrder";

class MonitoringWorkOrderList {
  constructor(
    vehiclesNumber,
    completed,
    onTimeOk,
    onTimePartial,
    onTimeReject,
    lateOk,
    latePartial,
    lateReject,
    pending,
    workOrders
  ) {
    this.summary = {
      vehiclesNumber: vehiclesNumber,
      completed: completed,
      onTimeOk: onTimeOk,
      lateOk: lateOk,
      latePartial: latePartial,
      lateReject: lateReject,
      onTimePartial: onTimePartial,
      onTimeReject: onTimeReject,
      pending: pending
    };
    this.workOrders = workOrders;
  }

  static map = list => {
    return new MonitoringWorkOrderList(
      list.resumen_vehiculos,
      list.resumen_cumplimiento,
      list.resumen_tiempo,
      list.resumen_tiempo_parcial,
      list.resumen_tiempo_total,
      list.resumen_tarde,
      list.resumen_tarde_parcial,
      list.resumen_tarde_total,
      list.resumen_pendiente,
      list.ots.map(ot => MonitoringWorkOrder.map(ot))
    );
  };
}

export default MonitoringWorkOrderList;
