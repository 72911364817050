class TachographAlert {
  constructor(
    id,
    type,
    card,
    expirationDate,
    identity,
    plaque,
    lastDownloadDate
  ) {
    this.id = id;
    this.type = type;
    this.card = card;
    this.expirationDate = expirationDate;
    this.identity = identity;
    this.plaque = plaque;
    this.lastDownloadDate = lastDownloadDate;
  }

  static map = alert => {
    return new TachographAlert(
      alert.id,
      alert.vc_tipo_alerta_descripcion,
      alert.vc_numero_tarjeta,
      alert.dt_fecha_caducidad,
      alert.vc_nombre,
      alert.vc_matricula,
      alert.dt_fecha_ultima_descarga
    );
  };
}
export default TachographAlert;
