import React from "react";
import {Button} from "@material-ui/core";
import useForm from "hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Select from 'components/Common/Forms/Select';
import MenuItem from '@material-ui/core/MenuItem';
import validations from './validations';
import {validateFormField2} from '../../../../../../../../utils/validateField';
import TagsPicker from '../../../../../../../../containers/Administrator/TagsPicker';
import ClientsPicker from '../../../../../../../../containers/Administrator/ClientsPicker';
import ProductsPicker from '../../../../../../../../containers/Administrator/ProductPicker';
import {setCurrentReportFile} from "../../../../../../../../redux/actions/ControlPanel/Kpi";
import ClientsHeadquarterSelect from "./ClientsHeadquarterSelect";
import ProviderSelect from "./ProviderSelect";
import providerValidations from "./providerValidations";
import {addDaysToDate, parseDateToInput} from "../../../../../../../../utils/dates";
import SendEmail from "../../../../Common/SendEmailDialog";
import SaveFilters from "../../../../Common/SaveFilters";
import DateFilters from "../../../../Common/DateFilters";

const initialData = {
    provider: "",
    clients: [],
    tags: [],
    products: [],
    period: 1,
    startDate: parseDateToInput(addDaysToDate(new Date(), -7)), // -7 dias
    endDate: parseDateToInput(new Date()),
    groupBy: 'Mensual',
};

const Form = ({service}) => {
    const {formData, errors, setFormData, resetForm, setValues} = useForm(initialData, service !== 18 && service !== 19?validations : providerValidations);
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    const handleSubmitPDF = () => {
        dispatch(setCurrentReportFile(service, formData, 1));
    }
    const handleSubmitExcel = () => {
        dispatch(setCurrentReportFile(service, formData, 2));
    }

    React.useEffect(() => {
        resetForm();
        current?.filters && setValues(current.filters);
    }, [service]);

    const onValidate = () => {
        if(service !== 18 && service !== 19) {
            return (validateFormField2(formData, ['tags', 'provider']) || errors.clients.length > 0 || errors.products.length > 0);
        }else {
            return formData.provider === "";
        }
    }

    return (
        <form>
            <GridContainer spacing={4}>
                {service !== 4 &&
                    <React.Fragment>
                        {(service !== 18 && service !== 19)
                            ? <React.Fragment>
                                {service !== 7
                                    ? <Grid item xs={12} sm={4} lg={3}>
                                        <ClientsPicker
                                            id="clients-picker-filter"
                                            values={formData.clients}
                                            onChange={value => setFormData('clients', value)}
                                            errors={errors}
                                        />
                                    </Grid>
                                    : <ClientsHeadquarterSelect
                                        id="clients-picker-filter"
                                        values={formData.clients}
                                        onChange={value => setFormData('clients', value)}
                                        errors={errors}
                                    />
                                }

                                <Grid item xs={12} sm={4} lg={3}>
                                    <TagsPicker
                                        id="tags-picker-filter"
                                        values={formData.tags}
                                        onChange={value => setFormData('tags', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} lg={3}>
                                    <ProductsPicker
                                        id="product-picker-filter"
                                        values={formData.products}
                                        onChange={value => setFormData('products', value)}
                                        errors={errors}
                                    />
                                </Grid>
                            </React.Fragment>
                            : <React.Fragment>
                                <Grid item xs={12} sm={4} lg={3}>
                                    <ProviderSelect
                                        id="provider-select-filter"
                                        value={formData.provider}
                                        onChange={value => setFormData('provider', value)}
                                        errors={errors}
                                    />
                                </Grid>
                            </React.Fragment>
                        }
                        {service !== 18 &&
                            <React.Fragment>
                                <DateFilters
                                    formData={formData}
                                    setFormData={setFormData}
                                />
                            </React.Fragment>
                        }
                        {(service !== 18 && service !== 19) &&
                            <Grid item xs={12} sm={4} lg={3}>
                            <Select
                                value={formData.groupBy}
                                fullWidth
                                id="groupBy-filter"
                                label={<IntlMessages id="logistic.inventoryReport.filters.groupBy"/>}
                                onChange={e => setFormData('groupBy', e.target.value)}
                            >
                                <MenuItem value="Diaria">Diaria</MenuItem>
                                <MenuItem value="Mensual">Mensual</MenuItem>
                                <MenuItem value="Anual">Anual</MenuItem>
                            </Select>
                        </Grid>}
                    </React.Fragment>
                }
                <Grid item xs={12} sm={4} lg={3}>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitPDF()}
                        disabled={onValidate()}
                        color="primary"
                    >
                        Generar PDF
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitExcel()}
                        disabled={onValidate()}
                        color="primary"
                    >
                        Generar Excel
                    </Button>
                </Grid>
                <SendEmail filters={formData} />
                <SaveFilters filters={formData}/>
            </GridContainer>
            {(validateFormField2(formData, ['tags', 'provider']) && service !== 18 && service !== 19) &&
                <p style={{color: "red", marginBottom: "16px"}}>Los campos Clientes, Envases, Fecha Inicial y Fecha Fin
                    son obligatorios</p>}
        </form>
    );
}

export default Form;