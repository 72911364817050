export const RESET_CONFIG_ORGANIZATION_MODULES_STATES =
  "RESET_CONFIG_ORGANIZATION_MODULES_STATES";
export const FETCH_ALL_CONFIG_ORGANIZATION_MODULES =
  "FETCH_ALL_CONFIG_ORGANIZATION_MODULES";
export const SET_CURRENT_CONFIG_ORGANIZATION_MODULE =
  "SET_CURRENT_CONFIG_ORGANIZATION_MODULE";
export const ADD_CONFIG_ORGANIZATION_MODULE = "ADD_CONFIG_ORGANIZATION_MODULE";
export const EDIT_CONFIG_ORGANIZATION_MODULE =
  "EDIT_CONFIG_ORGANIZATION_MODULE";
export const EDIT_CONFIG_ORGANIZATION_MODULE_STATE =
  "EDIT_CONFIG_ORGANIZATION_MODULE_STATE";
export const RESET_CURRENT_CONFIG_ORGANIZATION_MODULE =
  "RESET_CURRENT_CONFIG_ORGANIZATION_MODULE";
export const APPLY_CONFIG_ORGANIZATION_MODULES_FILTERS =
  "APPLY_CONFIG_ORGANIZATION_MODULES_FILTERS";
export const RESET_CONFIG_ORGANIZATION_MODULES_FILTERS =
  "RESET_CONFIG_ORGANIZATION_MODULES_FILTERS";
