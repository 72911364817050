class Currency {
  constructor(id, symbol, description, state) {
    this.id = id;
    this.symbol = symbol;
    this.description = description;
    this.state = state;
  }

  static map = currency => {
    return new Currency(
      currency.nu_id_moneda,
      currency.vc_simbolo_moneda,
      currency.vc_desc_moneda,
      currency.bi_estado === "1"
    );
  };
}

export default Currency;
