import {
  ADD_MAINTENANCE_PLAN,
  APPLY_MAINTENANCE_PLANS_FILTERS,
  CLOSE_MAINTENANCE_PLANS_DIALOG,
  EDIT_MAINTENANCE_PLAN,
  FETCH_ALL_MAINTENANCE_PLANS,
  OPEN_MAINTENANCE_PLANS_DIALOG,
  RESET_CURRENT_MAINTENANCE_PLAN,
  RESET_MAINTENANCE_PLANS_FILTERS,
  RESET_MAINTENANCE_PLANS_STATE,
  SET_CURRENT_MAINTENANCE_PLAN
} from "../../types/FleetManagement/MaintenancePlansTypes";

const INIT_STATE = {
  data: {},
  filters: {
    description: "",
    active: null
  },
  current: null,
  dialog: {
    name: "",
    open: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_MAINTENANCE_PLANS: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case APPLY_MAINTENANCE_PLANS_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_MAINTENANCE_PLANS_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case SET_CURRENT_MAINTENANCE_PLAN: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_MAINTENANCE_PLAN: {
      return {
        ...state,
        current: null
      };
    }
    case ADD_MAINTENANCE_PLAN: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_MAINTENANCE_PLAN: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case OPEN_MAINTENANCE_PLANS_DIALOG: {
      return {
        ...state,
        dialog: {
          open: true,
          name: action.payload
        }
      };
    }
    case CLOSE_MAINTENANCE_PLANS_DIALOG: {
      return {
        ...state,
        dialog: {
          open: false,
          name: ""
        }
      };
    }
    case RESET_MAINTENANCE_PLANS_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
