import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import React from "react";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.general.data"} />,
    link: "/administrator/tags"
  },
  {label: <IntlMessages id={"pages.tags"} />, isActive: true}
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="administrator.tags.title" />}
    description={<IntlMessages id="administrator.tags.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
