import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton
} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {useStyles} from "../styles";
import ClientsTable from "./ClientsTable";
import AddIcon from "@material-ui/icons/Add";
import AddDialog from "./AddDialog";
import EditDialog from "./EditDialog";
import Confirmation from "../../../../../../Common/Dialogs/Confirmation";
import ElevatorContactClient from "../../../../../../../domain/ElevatorsManagement/ElevatorContactClient";
import {useDispatch, useSelector} from "react-redux";
import {editElevatorContactList} from "../../../../../../../redux/actions/ElevatorsManagement/Elevators";

const ClientsContactList = ({clientsList}) => {
  const classes = useStyles();
  const {current, filters} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.elevators
  );
  const dispatch = useDispatch();
  const [ add, setAdd ] = React.useState(false);
  const [ edit, setEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ selectedClient, setSelectedClient ] = React.useState(null);
  const [ clients, setClients ] = React.useState(clientsList);
  const onSaveEdit = data => {
    const newList = clients.map(client => {
      if (selectedClient.id === client.id) {
        return {...selectedClient, ...data};
      }
      return client;
    });
    dispatch(editElevatorContactList(current, newList, filters));
    setClients(newList);
    setEdit(false);
  };
  const onSaveAdd = data => {
    const newContact = new ElevatorContactClient(
      clients.length + 1,
      data.name,
      data.email,
      data.phone
    );
    const newList = [ ...clients, newContact ];
    dispatch(editElevatorContactList(current, newList, filters));
    setClients(newList);
    setAdd(false);
  };
  const onDeleteContact = () => {
    const newList = clients.filter(c => c.id !== selectedClient.id);
    dispatch(editElevatorContactList(current, newList, filters));
    setClients(newList);
    setOpenDelete(false);
  };
  return (
    <Card className={classes.sectionCard}>
      <CardHeader
        title={
          <IntlMessages
            id={
              "elevators.management.elevators.index.card.detail.dialog.form.contact.client"
            }
          />
        }
        action={
          <IconButton onClick={() => setAdd(true)}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Box>
          <ClientsTable
            clients={clients}
            setSelectedClient={setSelectedClient}
            setEdit={setEdit}
            setDelete={setOpenDelete}
          />
        </Box>
        <AddDialog setOpenAdd={setAdd} openAdd={add} onSave={onSaveAdd} />
        <EditDialog
          selectedClient={selectedClient}
          setOpenEdit={setEdit}
          openEdit={edit}
          onSave={onSaveEdit}
        />
        <Confirmation
          open={openDelete}
          text={
            <IntlMessages
              id={
                "elevators.management.elevators.index.card.detail.dialog.form.contact.client.delete.text"
              }
            />
          }
          onReject={() => {
            setOpenDelete(false);
            setSelectedClient(null);
          }}
          onAccept={() => {
            setOpenDelete(false);
            onDeleteContact();
          }}
        />
      </CardContent>
    </Card>
  );
};

export default ClientsContactList;
