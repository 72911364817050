import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import TrafficChartData from "../../../domain/FleetManagement/Tachograph/TrafficChartData";
import {parseDateToInput} from "../../../utils/dates";
import TrafficDriver from "../../../domain/FleetManagement/Tachograph/TrafficDriver";
import axios from "axios";
import moment from "moment";

export const getTachographChartsData = async () => {
  try {
    const {data} = await axiosInstance.post("/Tacografo_Trafico/sel", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      dt_fecha_actual: parseDateToInput(new Date())
    });
    if (data.length > 0) {
      return TrafficChartData.map(data[0]);
    } else {
      throw new Error("No hay datos registrados");
    }
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTachographDriverList = async filters => {
  try {
    const {
      data
    } = await axiosInstance.post("/Tacografo_Trafico/conductores_sel", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      ls_conductores: filters.drivers.map(d => d.id),
      nu_id_estado: filters.state,
      ls_actividades: filters.activities.map(a => a.id),
      nu_periodo: filters.period,
      nu_disponibilidad_minima: filters.minHours,
      nu_disponibilidad_maxima: filters.maxHours,
      windowWidth: window.innerWidth,
    });
    return data.map(t => TrafficDriver.map(t));
  } catch (e) {
    return Promise.reject(e);
  }
};


export const getDriverExcel = async (driverId) => {
  try {
    const {
      data
    } = await axios.get(
        process.env.REACT_APP_AWS_LAMBDA,
        {
          params: {
            nu_id_conductor: driverId,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          }
        }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export const getTachographDriverActivitiesFile = async (driverId) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Tacografo_Trafico/conductor_actividades_sel", {
      nu_id_conductor: driverId,
      dt_fecha: moment().format("YYYY-MM-DD")
    });
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getTachographDrivers = async () => {
  try {
    const {
      data
    } = await axiosInstance.post(
        "/Tacografo_Trafico/filtro_conductores_sel",
        {
          nu_id_entidad: getCompanyEntityId()
        },
    );
    return data.map(driver => ({
        id: driver.nu_id_entidad,
        name: driver.vc_nombre
    }));
  } catch (e) {
    return Promise.reject(e);
  }
}