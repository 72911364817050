class MonitoringAlert {
  constructor(id, guide, vehicle, date, detail, read) {
    this.id = id;
    this.guide = guide;
    this.vehicle = vehicle;
    this.date = date;
    this.detail = detail;
    this.read = read;
  }

  static map = alert => {
    return new MonitoringAlert(
      alert.oTs,
      alert.oTs,
      alert.vehiculo,
      alert.fecha,
      alert.alerta,
      alert.leida
    );
  };
}

export default MonitoringAlert;
