import {mapToBoolean} from "../../../utils/mappers";

class InsuranceCompany {
  constructor(id, name, status) {
    this.id = id;
    this.name = name;
    this.status = status;
  }

  static map = company => {
    return new InsuranceCompany(
      company.nu_id_aseguradora,
      company.vc_desc_aseguradora,
      mapToBoolean(company.bi_estado)
    );
  };
}
export default InsuranceCompany;
