import React from "react";
import updatesData from "./updatesData";
import {Box, Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const UpdatesList = () => (
  <Box display="flex" flexDirection="column">
    {updatesData.reverse().map((item, index) => (
      <React.Fragment
          key={item.version}
      >
        <Box
          display="flex"
          flexDirection="column"
          style={{gap: "12px"}}
        >
          <Typography variant="h2">
            {item.version} - {item.date}
          </Typography>
          <Typography variant="h3">{item.title}</Typography>
          <ul>
            {item.updates.map((text, i) => (
              <Typography
                  key={item.version + "-" + i}
                  variant="body2" style={{paddingLeft: "25px"}}>
                <li>{text}</li>
              </Typography>
            ))}
          </ul>
        </Box>
        <Box my={4}>
          <Divider />
        </Box>
      </React.Fragment>
    ))}
  </Box>
);

export default UpdatesList;
