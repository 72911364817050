import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from "@material-ui/icons/GetApp";

import {Box} from "@material-ui/core";
import {alpha, makeStyles} from "@material-ui/core/styles";
import CmtImage from "../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import AuthWrapper from "../../../@jumbo/components/Common/authComponents/AuthWrapper";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  formcontrolLabelRoot: {
    "& .MuiFormControlLabel-label": {
      [theme.breakpoints.down("xs")]: {
        fontSize: 12
      }
    }
  }
}));

const DownloadApp = ({variant = "default", wrapperVariant = "default"}) => {
  const classes = useStyles({variant});
  const handleClick = () => {
    window.open(
      "https://tms-apk-bucket.s3.amazonaws.com/com.tms_4GFlota.apk",
      "_blank"
    );
  };

  const version = process.env.REACT_APP_ANDROID_APP_VERSION;
  const date = process.env.REACT_APP_ANDROID_APP_DATE;

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Descargar app móvil
        </Typography>
        <form id="sign-in-form">
          <Box mb={2}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem button onClick={handleClick}>
                <ListItemIcon>
                  <GetAppIcon />
                </ListItemIcon>
                <ListItemText primary={`QAS - ${date} (v${version} - Android)`} />
              </ListItem>
            </List>
          </Box>
        </form>
      </Box>
    </AuthWrapper>
  );
};
export default DownloadApp;
