export const RESET_ELEVATORS_CONTRACTS_STATE =
  "RESET_ELEVATORS_CONTRACTS_STATE";
export const FETCH_ALL_ELEVATORS_CONTRACTS = "FETCH_ALL_ELEVATORS_CONTRACTS";
export const SET_CURRENT_ELEVATOR_CONTRACT = "SET_CURRENT_ELEVATOR_CONTRACT";
export const RESET_CURRENT_ELEVATOR_CONTRACT =
  "RESET_CURRENT_ELEVATOR_CONTRACT";
export const EDIT_ELEVATOR_CONTRACT = "EDIT_ELEVATOR_CONTRACT";
export const ADD_ELEVATOR_CONTRACT = "ADD_ELEVATOR_CONTRACT";
export const APPLY_ELEVATORS_CONTRACTS_FILTERS =
  "APPLY_ELEVATORS_CONTRACTS_FILTERS";
export const RESET_ELEVATORS_CONTRACTS_FILTERS =
  "RESET_ELEVATORS_CONTRACTS_FILTERS";
