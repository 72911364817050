export const RESET_VEHICLES_MAINTENANCES_STATE =
  "RESET_VEHICLES_MAINTENANCES_STATE";
export const APPLY_VEHICLES_MAINTENANCES_FILTERS =
  "APPLY_VEHICLES_MAINTENANCES_FILTERS";
export const RESET_VEHICLES_MAINTENANCES_FILTERS =
  "RESET_VEHICLES_MAINTENANCES_FILTERS";
export const FETCH_ALL_VEHICLES_MAINTENANCES =
  "FETCH_ALL_VEHICLES_MAINTENANCES";
export const FETCH_ALL_VEHICLES_MAINTENANCES_VIEWS =
  "FETCH_ALL_VEHICLES_MAINTENANCES_VIEWS";
export const OPEN_VEHICLES_MAINTENANCES_VIEWS_DIALOG =
  "OPEN_VEHICLES_MAINTENANCES_VIEWS_DIALOG";
export const CLOSE_VEHICLES_MAINTENANCES_VIEWS_DIALOG =
  "CLOSE_VEHICLES_MAINTENANCES_VIEWS_DIALOG";
