import React from 'react';
import IntlMessages from '../../../../@jumbo/utils/IntlMessages';

export default {
  box: [{ rule: v => v.trim() !== '', message: <IntlMessages id="guardian.add.advance.form.box.error.empty" /> }],
  expenseType: [
    { rule: v => v.trim() !== '', message: <IntlMessages id="guardian.add.expense.form.expense.type.error.empty" /> },
  ],
  driver: [{ rule: v => v.id?.toString().trim() !== '', message: <IntlMessages id="guardian.add.advance.form.driver.error.empty" /> }],
  ruc: [{ rule: v => v.toString().trim() !== '', message: <IntlMessages id="guardian.add.expense.form.ruc.error.empty" /> }],
  businessName: [{ rule: v => v.toString().trim() !== '', message: <IntlMessages id="guardian.add.expense.form.business.name.error.empty" /> }],
  address: [{ rule: v => v.toString().trim() !== '', message: <IntlMessages id="guardian.add.expense.form.address.error.empty" /> }],
  documentType: [{ rule: v => v.id?.toString().trim() !== '', message: <IntlMessages id="guardian.add.expense.form.document.type.error.empty" /> }],
  documentNumber: [{ rule: v => v.toString().trim() !== '', message: <IntlMessages id="guardian.add.expense.form.document.number.error.empty" /> }],
  amount: [
    {
      rule: v => v.trim() !== '',
      message: <IntlMessages id="guardian.add.expense.form.amount.error.empty" />,
    },
    { rule: v => !isNaN(v), message: <IntlMessages id="guardian.add.expense.form.amount.error.number" /> },
  ],
  files: [
    {
      rule: v => v.length === 1,
      message: <IntlMessages id="guardian.add.expense.form.files.error.length" />,
    },
  ],
};
