import React from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ],
  frequency: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ],
  day: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ],
  time: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ],
  email: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ],
  format: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="control.panel.kpi.save.field.error" />
    }
  ]
};
