import ui, {closeCreateForm, closeEditForm} from "./ui";
import {
  cancelPrevOrderHistoryFetchAll,
  fetchAllOrdersHistory
} from "../../../services/OrderHistoryService";

import {restoreAdministratorState} from "./Common";

import {
  FETCH_ORDERS_HISTORY_SUCCEEDED,
  APPLY_ORDERS_HISTORY_FILTERS,
  RESET_ORDERS_HISTORY_FILTERS
} from "../../types/Administrator/OrderHistoryTypes";

import OrderHistory from "../../../domain/OrderHistory";

export const resetState = () => dispatch => {
  cancelPrevOrderHistoryFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchOrdersHistorySucceeded = data => ({
  type: FETCH_ORDERS_HISTORY_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchOrderHistory = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevOrderHistoryFetchAll();
  try {
    const orders = await fetchAllOrdersHistory();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchOrdersHistorySucceeded(orders));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applyOrdersHistoryFilters = filters => ({
  type: APPLY_ORDERS_HISTORY_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetOrdersHistoryFilters = () => ({
  type: RESET_ORDERS_HISTORY_FILTERS
});

export default {
  get: fetchOrderHistory,
  applyOrdersHistoryFilters,
  resetOrdersHistoryFilters,
  resetState
};
