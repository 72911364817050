import React from 'react';
import ListItem from "./ListItem";
import Form from "./Form";
import {parseLoweCamelCaseToTitleText} from "../../../../../../utils/parsers";
import {v4 as uuidV4} from 'uuid';

const NestedList = ({title, data = [], subElementKey}) => {
    if(!data) {
        return null;
    }
    return (
        <ListItem
            title={title}
            contentStyles={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                maxHeight: 700,
                overflowY: 'auto',
            }}
        >
            {data.map((row) => (
                <Form
                    key={uuidV4()}
                    title={`${parseLoweCamelCaseToTitleText(subElementKey)}: ${row[subElementKey]}`}
                    data={row}
                    excludeHeaders={[subElementKey]}
                />
            ))}
        </ListItem>
    );
};

export default NestedList;