import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getDataMasterInventoryOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 17) && options.push({
        name: <IntlMessages id={'sidebar.logistic.products'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/products',
    });
    submodules.some(s => s.id === 18) && options.push({
        name: <IntlMessages id={'sidebar.logistic.receivers'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/receivers',
    });
    submodules.some(s => s.id === 19) && options.push({
        name: <IntlMessages id={'sidebar.logistic.warehouse'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/warehouse',
    });
    submodules.some(s => s.id === 20) && options.push({
        name: <IntlMessages id={'sidebar.logistic.typesIncidence'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/types-incidence',
    });
    return options;
}
const getDailyOperationInventoryOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 21) && options.push({
        name: <IntlMessages id={'sidebar.logistic.warehouse.transfer'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/warehouse-transfer',
    });
    submodules.some(s => s.id === 22) && options.push({
        name: <IntlMessages id={'sidebar.logistic.incidence'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/incidents',
    });
    submodules.some(s => s.id === 23) && options.push({
        name: <IntlMessages id={'sidebar.logistic.check.stock'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/check-stock',
    });
    submodules.some(s => s.id === 24) && options.push({
        name: <IntlMessages id={'sidebar.logistic.traceability'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/traceability',
    });
    submodules.some(s => s.id === 25) && options.push({
        name: <IntlMessages id={'sidebar.logistic.regulation'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/regulation',
    });
    submodules.some(s => s.id === 26) && options.push({
        name: <IntlMessages id={'sidebar.logistic.merchandiseReception'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/merchandiseReception',
    });
    return options;
}
const getInventoryOptions = submodules => {
    let options = [];
    const dataMaster =  getDataMasterInventoryOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.logistic.dataMaster'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperation = getDailyOperationInventoryOptions(submodules);
    dailyOperation?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.daily.operation'}/>,
        type: 'section',
        children: dailyOperation,
    });

    return options;
}

const getDataMasterDistributionOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 27) && options.push({
        name: <IntlMessages id={'pages.rejectionTypes'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/rejection',
    });
    submodules.some(s => s.id === 28) && options.push({
        name: <IntlMessages id={'sidebar.logistic.work.orders.types'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/work-orders-types',
    });
    submodules.some(s => s.id === 29) && options.push({
        name: <IntlMessages id={'sidebar.logistic.checklist.types'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/checklist-types',
    });
    submodules.some(s => s.id === 70) && options.push({
        name: <IntlMessages id={'sidebar.logistic.distribution.return.types'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/return-types',
    });
    return options;
}

const getDailyOperationDistributionOptions = (submodules, selectedCompany) => {
    let options = [];
    submodules.some(s => s.id === 30) && options.push({
        name: <IntlMessages id={'sidebar.logistic.orders'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/orders',
    });
    submodules.some(s => s.id === 31) && options.push({
        name: <IntlMessages id={'sidebar.logistic.assignment'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/assignment',
    });
    submodules.some(s => s.id === 103) && options.push({
        name: <IntlMessages id="logistic.distribution.planner" />,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/planner',
    });
    submodules.some(s => s.id === 32) && options.push({
        name: <IntlMessages id={'sidebar.logistic.workOrders'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/work-orders',
    });
    submodules.some(s => s.id === 33) && options.push({
        name: <IntlMessages id={'sidebar.logistic.monitoring'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/monitoring',
    });
    submodules.some(s => s.id === 34) && options.push({
        name: <IntlMessages id={'sidebar.ot.breakdown'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/ot-breakdown',
    });
    submodules.some(s => s.id === 68) && options.push({
        name: <IntlMessages id={'sidebar.logistic.servicesList.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/services-list',
    });
    submodules.some(s => s.id === 105) && options.push({
        name: <IntlMessages id={'logistic.aavv.services.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/aavv-services',
    });
    submodules.some(s => s.id === 100) && options.push({
        name: <IntlMessages id={'logistic.distribution.carbon.footprint.title'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/distribution/carbon-footprint',
    });
    if(selectedCompany?.name === "CDS" &&  submodules.some(s => s.id === 35)){
        options.push({
            name: <IntlMessages id={'sidebar.logistic.dataCollection'}/>,
            type: 'item',
            icon: <PostAdd/>,
            link: '/logistic/distribution/dataCollection',
        });
    }
    return options;
}

const getDistributionOptions = (submodules, selectedCompany) => {
    let options = [];
    const dataMaster =  getDataMasterDistributionOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.logistic.dataMaster'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperation = getDailyOperationDistributionOptions(submodules, selectedCompany);
    dailyOperation?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.daily.operation'}/>,
        type: 'section',
        children: dailyOperation,
    });

    return options;
}

const getPurchasesDataMasterOptions = (submodules) => {
    let options = [];
    options.push({
        name: submodules.find(s => s.id === 118)?.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/purchases/providers',
    });
    return options.filter(o => o.name);
}
const getPurchasesDailyOperationOptions = (submodules) => {
    let options = [];
    options.push({
        name: submodules.find(s => s.id === 119)?.description,
        type: 'item',
        icon: <PostAdd/>,
        link: '/logistic/purchases/provider-orders',
    });
    return options.filter(o => o.name);
}

const getPurchasesOptions = (submodules) => {
    let options = [];
    const dataMaster =  getPurchasesDataMasterOptions(submodules);
    dataMaster?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.masterData'}/>,
        type: 'section',
        children: dataMaster,
    });
    const dailyOperation = getPurchasesDailyOperationOptions(submodules);
    dailyOperation?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.daily.operation'}/>,
        type: 'section',
        children: dailyOperation,
    });
    return options;
}

export const getLogisticOptions = (selectedCompany) => {
    const permissions = getUserPermissions();
    let logistic = {
        name: <IntlMessages id={'sidebar.logistic.title'}/>,
        type: 'section',
        children: [],
    };
    const module = permissions.find(p => p.id===18);
    if (module && module.submodules.length > 0) {
        const purchasesOptions = getPurchasesOptions(module.submodules);
        purchasesOptions.length > 0 && logistic.children.push({
            name: module.description,
            type: 'collapse',
            children: purchasesOptions,
        });
    }

    let submodules = permissions.find(p => p.id===6)?.submodules;
    if(submodules?.length > 0) {
        const inventoryOptions = getInventoryOptions(submodules);
        inventoryOptions.length > 0 && logistic.children.push({
            name: <IntlMessages id={'sidebar.logistic.inventory'}/>,
            type: 'collapse',
            children: inventoryOptions,
        });
    }
    submodules = permissions.find(p => p.id===7)?.submodules;
    if(submodules?.length > 0) {
        const distributionOptions = getDistributionOptions(submodules, selectedCompany);
        distributionOptions.length > 0 && logistic.children.push({
            name: <IntlMessages id={'sidebar.logistic.distribution'}/>,
            type: 'collapse',
            children: distributionOptions,
        });
    }

    return logistic.children.length > 0? logistic : null;

}