import React, {useState} from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import FloatingSpeedDial from "../../../Common/Buttons/SpeedDial";
import {fetchAllLiquidatedExpensesByPettyCashStarted} from "../../../../../redux/actions/Guardians/Expenses";
import {useDispatch, useSelector} from "react-redux";
import {closeAccounting} from "../../../../../services/AccountingService";
import {
  approveAccountingStarted,
  fetchAllAccountingStarted,
  rejectAccountingStarted,
  setTransactionResult
} from "../../../../../redux/actions/Guardians/Accounting";
import {getUserType} from "../../../../../services/StorageService";

const FloatingButton = ({closeDialog, setOpenAddExpense}) => {
  const dispatch = useDispatch();
  const {currentAccounting, accountingList} = useSelector(
    state => state.guardian
  );

  const guardianActions = [
    {
      icon: <AddCircleIcon />,
      name: (
        <IntlMessages id={"guardian.accounting.dialog.floating.add.expense"} />
      ),
      key: "add-expense",
      onCLick: () => {
        dispatch(
          fetchAllLiquidatedExpensesByPettyCashStarted(
            currentAccounting.pettyCash
          )
        );
        setOpenAddExpense(true);
      }
    }
  ];

  const verifyExpenses = () => {
    let actions = [];
    if (
      currentAccounting.state.id === "1" ||
      currentAccounting.state.id === "4"
    ) {
      actions = [ ...actions, ...guardianActions ];
    }
    if (currentAccounting.expenses.length > 0) {
      return [
        ...actions,
        {
          icon: <AccountBalanceIcon />,
          name: (
            <IntlMessages id={"guardian.accounting.dialog.floating.to.enter"} />
          ),
          key: "enter",
          onCLick: () => {
            closeAccounting(currentAccounting.id)
              .then(response => {
                dispatch(setTransactionResult(response));
                dispatch(fetchAllAccountingStarted(accountingList.filters));
              })
              .catch(message =>
                dispatch(setTransactionResult({code: "0", message}))
              );
            closeDialog();
          }
        }
      ];
    }
    return actions;
  };

  const administratorActions = [
    {
      icon: <AddCircleIcon />,
      name: <IntlMessages id={"administrator.accounting.action.approve"} />,
      key: "approve",
      onCLick: () => {
        dispatch(
          approveAccountingStarted(currentAccounting, accountingList.filters)
        );
        closeDialog();
      }
    },
    {
      icon: <AccountBalanceIcon />,
      name: <IntlMessages id={"administrator.accounting.action.reject"} />,
      key: "reject",
      onCLick: () => {
        dispatch(
          rejectAccountingStarted(currentAccounting, accountingList.filters)
        );
        closeDialog();
      }
    }
  ];

  const actions = getUserType().includes("guardian")
    ? verifyExpenses()
    : administratorActions;

  return <FloatingSpeedDial actions={actions} />;
};

export default FloatingButton;
