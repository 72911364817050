import React, {useCallback} from 'react';
import {Box} from '@material-ui/core';
import WorkOrdersFilters from '../../../../components/Logistic/WorkOrders/WorkOrdersFilters';
import {useDispatch, useSelector} from 'react-redux';
import PageContainerWorkOrders from '../../../../components/Logistic/WorkOrders/PageContainer/PageContainer';
import {
    applyWorkOrdersFilters, fetchAllWorkersStarted,
    resetCurrentWorKOrder,
    setCurrentWorkOrder
} from "../../../../redux/actions/Administrator/WorkOrders";
import WorkOrdersTable from "../../../../components/Logistic/WorkOrders/WorkOrdersTable";
import AddAndSearchBox from "../../../../components/Logistic/WorkOrders/AddAndSearchBox";
import useSearch from "../../../../hooks/Common/useSearch";
import WorkOrderFloatingButton from "../../../../components/Logistic/WorkOrders/WorkOrderFloatingButton";
import WorkOrderDialog from "../../../../components/Logistic/WorkOrders/WorkOrderDialog";

const WorkOrders = () => {
    const dispatch = useDispatch();
    const {data, current} = useSelector(state => state.administrator.workOrders);
    const {filteredData, onSearch, search} = useSearch(data)
    const applyFilters = useCallback(filters => {
        dispatch(applyWorkOrdersFilters(filters));
        dispatch(fetchAllWorkersStarted(filters));
    }, [dispatch]);
    React.useEffect(() => {
        current && dispatch(setCurrentWorkOrder(data.find(workOrder => workOrder?.id === current?.id)));
    }, [data]);

    return (
        <PageContainerWorkOrders>
            <Box display="flex" flexDirection="column">
                <WorkOrdersFilters
                    applyFilters={applyFilters}
                    search={search}
                />
                <AddAndSearchBox search={search} onSearch={onSearch}/>
                <WorkOrdersTable data={filteredData}/>
            </Box>
            <WorkOrderFloatingButton/>
            {current !== undefined && <WorkOrderDialog
                current={current}
                open
                handleClose={() => {
                dispatch(resetCurrentWorKOrder());
            }}/>}
        </PageContainerWorkOrders>
    );
};


export default (WorkOrders);
