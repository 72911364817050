import React from "react";
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const ManagementSelect = ({selected, onChange}) => {
  const [ managements, setManagements ] = React.useState([
    {id: 1, name: "Gestion 1"},
    {id: 2, name: "Gestion 2"},
    {id: 3, name: "Gestion 3"}
  ]);
  return (
    <Select
      id="alerts-management-select"
      label={
        <IntlMessages id="logistic.monitoring.alerts.filters.management" />
      }
      value={selected}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value="">
        <IntlMessages id="logistic.monitoring.alerts.filters.management.all" />
      </MenuItem>
      {managements.map(management => (
        <MenuItem value={management.id} key={`management-${management.id}`}>
          {management.name}
        </MenuItem>
      ))}
    </Select>
  );
};
export default ManagementSelect;
