import {makeStyles} from "@material-ui/core/styles";
import {Dialog, withStyles} from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1
  }
}));

export const CustomizedDialog = withStyles(theme => ({
  root: {
    paddingTop: "0"
  },
  paper: {
    marginTop: "0",
    marginBottom: "0",
    maxHeight: "100%",
    height: "100%",
    width: "50%",
    left: "50%",
    transform: "translate(-50%, 0)"
  }
}))(Dialog);
