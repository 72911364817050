import React, {useEffect, useState} from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ExpenseStates from "../../../../models/Guardian/ExpenseStates";
import {parseNumberToMoney} from "../../../../utils/parsers";
import useTableSort from "../../../../hooks/Common/useTableSort";
import CmtDropdownMenu from "../../../../@coremat/CmtDropdownMenu";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import TableHeader from "./TableHeader";
import BalanceFooter from "./BalanceFooter";
import ExpensesContainerWithHeader from "./ExpensesContainerWithHeader";
import {useDispatch, useSelector} from "react-redux";
import {downloadFile, getFileByName} from "../../../../services/FileService";
import unresolvedExpenses from "../../../../redux/actions/Supervisor/UnresolvedExpenses";
import {fetchAllAdvancesByGuardianStarted} from "../../../../redux/actions/Guardians/Advances";
import {getTypeFromName} from "../../../../utils/files";

const AdvanceExpensesTable = ({
  isGuardian = true,
  setOpenDialog,
  setSelectedExpense
}) => {
  const {expenses} = useSelector(state => state.guardian.currentAdvance);
  const {tableData, onSort, order, orderBy} = useTableSort(expenses);
  const [ advances, setAdvances ] = useState([]);
  const dispatch = useDispatch();
  useEffect(
    () => {
      const temp = [
        ...new Set(expenses.map(expense => expense.advance.id))
      ].map(id => {
        return {
          id: id,
          amount: [
            ...new Set(
              expenses
                .filter(expense => expense.advance.id === id)
                .map(expense => expense.advance.amount)
            )
          ][0]
        };
      });
      setAdvances(temp);
    },
    [ expenses ]
  );

  const actions = [
    {
      label: <IntlMessages id={"guardian.advances.table.options.approve"} />,
      onCLick: expense => dispatch(unresolvedExpenses.approve(expense.id))
    },
    {
      label: <IntlMessages id={"guardian.advances.table.options.reject"} />,
      onCLick: () => setOpenDialog(true)
    }
  ];

  let rows = 0;

  const renderAdvances = () => {
    rows++;
    if (rows > advances.length) {
      return " ";
    }
    return parseNumberToMoney(advances[rows - 1].amount);
  };

  const optionClickHandler = (onClick, expense) => {
    setSelectedExpense(expense);
    onClick(expense);
    dispatch(fetchAllAdvancesByGuardianStarted({}));
  };

  const onFileNameClick = name =>
    getFileByName(name).then(({base64File}) =>
      downloadFile(
        name,
        `data:image/${getTypeFromName(name)};base64,${base64File}`
      )
    );

  return (
    <React.Fragment>
      <ExpensesContainerWithHeader>
        <React.Fragment>
          <Box>
            <TableContainer
              component={Paper}
              style={{
                width: "90%",
                margin: "16px auto"
              }}
            >
              <Table>
                <TableHead>
                  <TableHeader
                    orderBy={orderBy}
                    order={order}
                    onSort={onSort}
                    isGuardian={isGuardian}
                  />
                </TableHead>
                <TableBody>
                  {tableData.map(expense => (
                    <TableRow key={expense.id}>
                      <TableCell align="center">{renderAdvances()}</TableCell>
                      <TableCell>{expense.costCenter}</TableCell>
                      <TableCell>{expense.expenseTypeDescription}</TableCell>
                      <TableCell>{expense.documentTypeDescription}</TableCell>
                      <TableCell>
                        {parseNumberToMoney(expense.amount)}
                      </TableCell>
                      <TableCell>{expense.state}</TableCell>
                      <StyledTableCell>
                        <p
                          style={{
                            color: "blue",
                            cursor: "pointer",
                            textDecoration: "underline",
                            width: "200px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap"
                          }}
                          onClick={() => onFileNameClick(expense.fileId)}
                        >
                          {expense.fileId}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell>
                        {expense.rejectTypeDescription}
                      </StyledTableCell>
                      {!isGuardian &&
                      expense.state.toLowerCase() === "pendiente" && (
                        <StyledTableCell align="left">
                          <CmtDropdownMenu
                            TriggerComponent={
                              <Tooltip title="More">
                                <IconButton style={{marginLeft: 4}}>
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            items={actions}
                            onItemClick={item => {
                              optionClickHandler(item.onCLick, expense);
                            }}
                          />
                        </StyledTableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <BalanceFooter />
        </React.Fragment>
      </ExpensesContainerWithHeader>
    </React.Fragment>
  );
};

export default AdvanceExpensesTable;
