import ui from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  acceptTransfer,
  cancelPrevMerchandiseReceptionFetchAll,
  fetchAllMerchandiseReception,
  rejectTransfer
} from "../../../services/MerchandiseReceptionService";
import {
  ACCEPT_MERCHANDISE_RECEPTION,
  FETCH_MERCHANDISE_RECEPTION_SUCCEEDED,
  REJECT_MERCHANDISE_RECEPTION
} from "../../types/Administrator/MerchandiseReception";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevMerchandiseReceptionFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchMerchandiseReceptionSucceeded = data => ({
  type: FETCH_MERCHANDISE_RECEPTION_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchMerchandiseReception = () => async dispatch => {
  dispatch(fetchStart());
  dispatch(ui.fetchStarted());
  cancelPrevMerchandiseReceptionFetchAll();
  try {
    const merchandiseReception = await fetchAllMerchandiseReception();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchMerchandiseReceptionSucceeded(merchandiseReception));
    dispatch(fetchSuccess());
  } catch (error) {
    dispatch(ui.fetchFailed());
    dispatch(fetchError(error?.message || error))
  }
};

const acceptMerchandiseRecpetionSucceeded = id => ({
  type: ACCEPT_MERCHANDISE_RECEPTION,
  payload: {
    id
  }
});

export const acceptMerchandiseReceptio = id => async dispatch => {
  try {
    dispatch(fetchStart());
    dispatch(ui.changeStateStarted());
    await acceptTransfer(id);
    dispatch(acceptMerchandiseRecpetionSucceeded(id));
    dispatch(ui.changeStateSucceeded());
  }catch (error) {
    dispatch(fetchError(error?.message || error))

  }
};

const rejectMerchandiseReceptionSucceeded = id => ({
  type: REJECT_MERCHANDISE_RECEPTION,
  payload: {
    id
  }
});

export const rejectMerchandiseReception = id => async dispatch => {
  try {
    dispatch(fetchStart());
    dispatch(ui.changeStateStarted());
    await rejectTransfer(id);
    dispatch(rejectMerchandiseReceptionSucceeded(id));
    dispatch(ui.changeStateSucceeded());
  }catch (error) {
    dispatch(fetchError(error?.message || error))
  }
};

export default {
  get: fetchMerchandiseReception,
  accept: acceptMerchandiseReceptio,
  reject: rejectMerchandiseReception,
  resetState
};
