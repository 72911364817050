import React from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.operative"} />,
    link: "/treasury/cash-management/operative"
  },
  {label: <IntlMessages id={"sidebar.logistic.stock"} />, isActive: true}
];

const PageContainerCheckStock = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"guardian.advances.page.headerCheckStock"} />}
    description={
      <IntlMessages id={"guardian.advances.page.descriptionCheckStock"} />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);
export default PageContainerCheckStock;
