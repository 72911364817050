import React from 'react';
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid, Typography} from "@material-ui/core";
import BaseGraphic from "./BaseGraphic";
import {useDispatch, useSelector} from "react-redux";
import {
    applyTachographTrafficDriversListFilters,
    fetchTachographTrafficChartsData
} from "../../../../../redux/actions/FleetManagement/TachographTraffic";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const GraphicsGrid = () => {
    const {chartsData, filters} = useSelector(({fleetManagement}) => fleetManagement.tachographTraffic);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(fetchTachographTrafficChartsData());
    }, []);
    const handleClick = (data, period) => {
        const [min, max] = data?.name?.split('-');
        dispatch(applyTachographTrafficDriversListFilters({...filters, minHours: min, maxHours: max, period, state: 1}));
    }
    return(
        <GridContainer spacing={2}>
            <Grid item xs={12} lg={8}>
                <Typography variant="h2">
                    <IntlMessages id={"fleet.management.tachograph.traffic.graphics.available.drivers.title"}/>
                </Typography>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Typography variant="h2">
                    <IntlMessages id={"fleet.management.tachograph.traffic.graphics.driving.hours.title"}/>
                </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <BaseGraphic
                    id='current-availability-chart'
                    title={<IntlMessages id={"fleet.management.tachograph.traffic.graphics.available.drivers.today"}/>}
                    data={chartsData.currentWeekAvailability}
                    barKey='hours'
                    barColor='#4ecdc0'
                    barName={<IntlMessages id={"fleet.management.tachograph.traffic.graphics.available.drivers"}/>}
                    yAxisName='Horas'
                    onClickBar={handleClick}
                    type={1}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <BaseGraphic
                    id='last-availability-chart'
                    title={<IntlMessages id={"fleet.management.tachograph.traffic.graphics.available.drivers.current.week"}/>}
                    data={chartsData.lastWeekAvailability}
                    barKey='hours'
                    barColor='#4ecdc0'
                    barName={<IntlMessages id={"fleet.management.tachograph.traffic.graphics.available.drivers"}/>}
                    yAxisName='Horas'
                    onClickBar={handleClick}
                    type={2}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <BaseGraphic
                    id='driving-hours-chart'
                    title={<IntlMessages id={"fleet.management.tachograph.traffic.graphics.driving.hours"}/>}
                    data={chartsData.drivingHours}
                    barKey='drivingHours'
                    barColor='#74a6d1'
                    barName={<IntlMessages id={"fleet.management.tachograph.traffic.graphics.driving.hours.title"}/>}
                    yAxisName='Periodo'
                    hours
                />
            </Grid>
        </GridContainer>
    );
}

export default GraphicsGrid;