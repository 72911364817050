import React from "react";

const BasicHome = props => (
  <img
    src={"https://comerciales.4gflota.com/Iconos_Web/CSSBase/img/fondoApp.jpg"}
    alt={"TMS 4GFlota"}
    style={{
      left: "50%",
      marginLeft: "-50vw",
      marginRight: "-50vw",
      marginTop: "-3.1vh",
      maxWidth: "100%",
      position: "absolute",
      right: "50%",
      width: "100%",
      height: "100%",
      maxHeight: "100%"
    }}
  />
);

export default BasicHome;
