import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import IntlMessages from "@jumbo/utils/IntlMessages";

import DialogBase from "../DialogBase";
import {Box} from "@material-ui/core";

const Confirmation = ({text, open, onAccept, onReject, isLoading}) => (
  <DialogBase
    isLoading={isLoading}
    open={open}
    close={onReject}
    title={<IntlMessages id={"common.dialogs.confirmation.title"} />}
    actions={
      <React.Fragment>
        <Button color="primary" onClick={onReject}>
          <IntlMessages id={"common.dialogs.confirmation.reject"} />
        </Button>
        <Button color="primary" onClick={onAccept}>
          <IntlMessages id={"common.dialogs.confirmation.accept"} />
        </Button>
      </React.Fragment>
    }
  >
    <Box marginY={2} fontSize={18} fontWeight="lighter" textAlign="center">
      {text}
    </Box>
  </DialogBase>
);

Confirmation.propTypes = {
  text: PropTypes.node,
  open: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  isLoading: PropTypes.bool
};

Confirmation.defaultProps = {
  text: "",
  open: false,
  onAccept: () => {},
  onReject: () => {},
  isLoading: false
};

export default Confirmation;
