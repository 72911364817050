import React, {useEffect} from "react";
import {Redirect} from "react-router";
import {useDispatch} from "react-redux";
import {setAccountingActiveFilters} from "../../../../redux/actions/Guardians/Accounting";

const UnresolvedAccountingList = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAccountingActiveFilters({state: 2}));
  }, []);
  return <Redirect to={"/treasury/cash-management/accounting-list"} />;
};

export default UnresolvedAccountingList;
