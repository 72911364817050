import React from 'react';
import {useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import FiltersContainer from "../../../../../../Common/Forms/FiltersContainer";
import Form from "./Form";
import {titles} from "../contants";


const Filters = () => {
    const {type: selected} = useSelector(
        ({controlPanel}) => controlPanel.kpi.current
    );

    return (
        <React.Fragment>
            <Box my={4}>
                <Typography variant="h2">
                    {titles.find(t => t.id === selected).name}
                </Typography>
            </Box>
            <FiltersContainer
                active
                marginBottom={5}
                onClose={() => {}}
            >
                <Form service={selected} />
            </FiltersContainer>
        </React.Fragment>
    );
};

export default Filters;