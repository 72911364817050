import React, {useEffect} from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid, MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import Select from "../../../../../Common/Forms/Select";
import {useDispatch, useSelector} from "react-redux";
import {fetchExpenseTypes} from "../../../../../../redux/actions/Administrator/ExpenseTypes";

const Filters = ({formData, setFormData, onReset, onSubmit}) => {
  const {data} = useSelector(state => state.administrator.expenseTypes);
  const dispatch = useDispatch();
  const [ accountingAccounts, setAccountingAccounts ] = React.useState([]);
  useEffect(() => {
    if (data.length <= 0) {
      dispatch(fetchExpenseTypes());
    }
  }, []);
  useEffect(
    () => {
      data.length > 0 &&
        setAccountingAccounts([
          ...new Set(data.map(type => type.accountingAccount))
        ]);
    },
    [ data ]
  );

  return (
    <FiltersContainer onReset={onReset} marginBottom={5} onClose={onReset}>
      <form onSubmit={onSubmit}>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.ot}
              id="ot"
              label={
                <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.ot.filter" />
              }
              onChange={value => setFormData("ot", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.executor}
              id="executor"
              label={
                <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.executor.filter" />
              }
              onChange={value => setFormData("executor", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              id="lowerDate"
              label={
                <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.lower.date.filter" />
              }
              value={formData.lowerDate}
              onChange={value => setFormData("lowerDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              id="upperDate"
              label={
                <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.upper.date.filter" />
              }
              value={formData.upperDate}
              onChange={value => setFormData("upperDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Select
              value={formData.expenseType}
              onChange={e => setFormData("expenseType", e.target.value)}
              id="expenseType"
              label={
                <IntlMessages
                  id={
                    "guardian.accounting.dialog.add.expenses.dialog.type.filter"
                  }
                />
              }
              fullWidth
            >
              <MenuItem value="">
                <IntlMessages
                  id={
                    "guardian.accounting.dialog.add.expenses.dialog.type.filter.item"
                  }
                />
              </MenuItem>
              {data.map(type => (
                <MenuItem key={type.id} value={type.id}>
                  {type.description}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <Select
              value={formData.accountingAccount}
              onChange={e => setFormData("accountingAccount", e.target.value)}
              id="accountingAccount"
              label={
                <IntlMessages
                  id={
                    "guardian.accounting.dialog.add.expenses.dialog.accounting.account.filter"
                  }
                />
              }
              fullWidth
            >
              <MenuItem value="">
                <IntlMessages
                  id={
                    "guardian.accounting.dialog.add.expenses.dialog.accounting.account.filter.item"
                  }
                />
              </MenuItem>
              {accountingAccounts.map(type => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.expense}
              id="expense"
              label={
                <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.expense.filter" />
              }
              onChange={value => setFormData("expense", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.amount}
              id="amount"
              label={
                <IntlMessages id="guardian.accounting.dialog.add.expenses.dialog.amount.filter" />
              }
              onChange={value => setFormData("amount", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
