import ElevatorContractDetail from "./ElevatorContractDetail";
import ElevatorStateDetail from "./ElevatorStateDetail";
import ElevatorResponsibleList from "./ElevatorResponsibleList";
import ElevatorContactClient from "./ElevatorContactClient";

class Elevator {
  constructor(
    id,
    name,
    client,
    headquarter,
    model,
    ken,
    category,
    stops,
    shipmentDate,
    emergency,
    contract,
    state,
    responsibleList,
    contactClients,
    genre,
    type,
    manufacturer,
  ) {
    this.id = id;
    this.name = name;
    this.client = client;
    this.headquarter = headquarter;
    this.model = model;
    this.ken = ken;
    this.category = category;
    this.stops = stops;
    this.shipmentDate = shipmentDate;
    this.emergency = emergency;
    this.contract = contract;
    this.state = state;
    this.responsibleList = responsibleList;
    this.contactClients = contactClients;
    this.genre = genre;
    this.type = type;
    this.manufacturer = manufacturer;
  }

  static map = elevator => {
    return new Elevator(
      elevator.nu_id_ficha,
      elevator.vc_nombre,
      elevator.nu_id_entidad_cliente,
      elevator.nu_id_entidad_cliente_sede,
      elevator.nu_id_tipo_modelo,
      elevator.vc_ken,
      elevator.nu_id_tipo_categoria,
      elevator.nu_nro_paradas,
      elevator.dt_fec_entrega,
      elevator.bi_emergencia,
      ElevatorContractDetail.map(elevator),
      ElevatorStateDetail.map(elevator),
      ElevatorResponsibleList.map(elevator),
      elevator.contactos.map((c, index) => ElevatorContactClient.map(c, index)),
        elevator.nu_id_tipo_genero,
        elevator.nu_id_tipo,
        elevator.nu_id_tipo_fabricante,
    );
  };
}

export default Elevator;
