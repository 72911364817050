import {
  ADD_CONFIG_ORGANIZATION_MODULE,
  APPLY_CONFIG_ORGANIZATION_MODULES_FILTERS,
  EDIT_CONFIG_ORGANIZATION_MODULE,
  EDIT_CONFIG_ORGANIZATION_MODULE_STATE,
  FETCH_ALL_CONFIG_ORGANIZATION_MODULES,
  RESET_CONFIG_ORGANIZATION_MODULES_FILTERS,
  RESET_CONFIG_ORGANIZATION_MODULES_STATES,
  RESET_CURRENT_CONFIG_ORGANIZATION_MODULE,
  SET_CURRENT_CONFIG_ORGANIZATION_MODULE
} from "../../types/Configuration/OrganizationModulesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    code: "",
    description: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_CONFIG_ORGANIZATION_MODULES: {
      let modules = {};
      action.payload.map(u => {
        modules[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: modules
      };
    }
    case ADD_CONFIG_ORGANIZATION_MODULE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_CONFIG_ORGANIZATION_MODULE_STATE: {
      const id = action.payload.id;
      return {
        ...state,
        data: {
          ...state.data,
          [id]: {
            ...state.data[id],
            state: action.payload.state
          }
        }
      };
    }
    case EDIT_CONFIG_ORGANIZATION_MODULE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case SET_CURRENT_CONFIG_ORGANIZATION_MODULE: {
      return {
        ...state,
        current: action.payload
      };
    }

    case RESET_CURRENT_CONFIG_ORGANIZATION_MODULE: {
      return {
        ...state,
        current: INIT_STATE.current
      };
    }
    case APPLY_CONFIG_ORGANIZATION_MODULES_FILTERS: {
      return {
        ...state,
        filters: {...action.payload}
      };
    }
    case RESET_CONFIG_ORGANIZATION_MODULES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case RESET_CONFIG_ORGANIZATION_MODULES_STATES: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
