import React, {useEffect, useRef} from 'react';
import {useFetch} from "../../../hooks/Common/useFetch";
import {fetchAllUbigeo} from "../../../services/ControlPanel/GeneralPanel/Clients/UbigeosService";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Autocomplete from "../../Common/Forms/Autocomplete";

const UbigeoSelect = ({value, onChange, initValue = null}) => {
    const firstSetUbigeo = useRef(!!initValue);
    const {data: locations} = useFetch(fetchAllUbigeo, {}, [], 'ubigeo');
    useEffect(() => {
        if (initValue && locations.length && firstSetUbigeo) {
            onChange(locations?.find(location => location.id === initValue.id));
        } else {
            firstSetUbigeo.current = false;
        }
    }, [locations, initValue]);
    return (
        <Autocomplete
            name="ubigeo"
            label={<IntlMessages id="ubigeo" />}
            value={value}
            onChange={(e, newValue) => onChange(newValue)}
            options={locations}
            required
        />
    );
};

export default UbigeoSelect;