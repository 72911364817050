class Supervisor {
  constructor(id, entityId, names, tags, active) {
    this.id = id;
    this.entityId = entityId;
    this.names = names;
    this.tags = tags;
    this.active = active;
  }

  static map = supervisor => {
    return new Supervisor(
      supervisor.nu_id_entidad_supervisor,
      supervisor.nu_id_entidad,
      supervisor.vc_nombre_entidad_supervisor,
      supervisor.vc_desc_tag.split(","),
      supervisor.bi_estado === "1"
    );
  };

  static editMap = supervisor => {
    return new Supervisor(
      supervisor.nu_id_entidad_supervisor,
      supervisor.nu_id_entidad,
      supervisor.vc_nombre_entidad_supervisor,
      supervisor.ls_tag.map(tag => ({
        id: tag.nu_id_tag,
        name: tag.vc_desc_tag
      })),
      supervisor.bi_estado === "1"
    );
  };
}

export default Supervisor;
