import axiosInstance from "../utils/axiosInstance";
import DocumentType from "../domain/DocumentType";

export const fetchAllDocumentTypes = async () => {
  try {
    const response = await axiosInstance.post("/TipoDocumento/sel");
    return response.data.map(doc => DocumentType.map(doc));
  } catch (e) {
    return Promise.reject(e);
  }
};
