import {
  CLEAR_SUPERVISOR_ROLE,
  SET_SUPERVISOR_ROLE
} from "../../types/CustomTypes";

const initState = {
  active: false
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_SUPERVISOR_ROLE: {
      return {
        ...state,
        active: true
      };
    }
    case CLEAR_SUPERVISOR_ROLE: {
      return {
        ...state,
        ...initState
      };
    }
    default: {
      return state;
    }
  }
};
