class Response {
  constructor(
    transactionState,
    transactionKey,
    transactionCode,
    transactionMessage
  ) {
    this.transactionState = transactionState;
    this.transactionKey = transactionKey;
    this.transactionCode = transactionCode;
    this.transactionMessage = transactionMessage;
  }

  static map = response => {
    return new Response(
      response.nu_tran_stdo,
      response.nu_tran_pkey,
      response.vc_tran_codi,
      response.tx_tran_mnsg
    );
  };
}

export default Response;
