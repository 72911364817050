import React from "react";
import {getExceedingSpeedData} from "../../../../redux/thunks/ControlPanel/exceedingSpeed";
import {getEcoDrivingData} from "../../../../redux/thunks/ControlPanel/ecoDriving";
import ExceedingSpeed from "./DashboardContainer/ExceedingSpeed";
import EcoDriving from "./DashboardContainer/EcoDriving";
import {defaultDashboardsIds} from "../constants";
import Aavv from "./DashboardContainer/Aavv";
import {getAavvDashboardData} from "../../../../redux/thunks/ControlPanel/aavv";

export default Object.freeze({
    [defaultDashboardsIds.EXCEEDING_SPEED]: {
        render: () => (<ExceedingSpeed />),
        thunk: getExceedingSpeedData
    },
    [defaultDashboardsIds.ECO_DRIVING]: {
        render: () => (<EcoDriving />),
        thunk: getEcoDrivingData
    },
    [defaultDashboardsIds.AAVV]: {
        render: () => (<Aavv />),
        thunk: getAavvDashboardData,
    },
});