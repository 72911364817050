import * as EntityDestinySelect from "redux/types/Administrator/EntityDestinySelect";

const INIT_STATE = {
  data: [ {id: 1, entityDestiny: "Entidad destino 1"} ]
};

export default (state = INIT_STATE, {type, payload}) => {
  switch (type) {
    case EntityDestinySelect.FETCH_ENTIY_DESTINY:
      return INIT_STATE;
    default:
      return state;
  }
};
