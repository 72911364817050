import {withStyles} from "@material-ui/styles";
import {Box} from "@material-ui/core";

export const ButtonsBox = withStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    columnGap: "20px",
    rowGap: "10px",
    justifyContent: "end",
    [theme.breakpoints.down(960)]: {
      justifyContent: "center"
    }
  }
}))(Box);
