import React, {useEffect, useState} from "react";
import Picker from "../../../Common/Forms/Pickers/Picker";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {fetchAllVehicleSelect} from "../../../../services/VehicleServiceSelect";
import MultipleSelect from "../../../Common/Forms/Select/MultipleSelect";

const VehiclesSelect = ({
  vehicles,
  onChange,
  isLoading = false,
  hasError,
  error
}) => {
  const [ options, setOptions ] = useState([]);
  useEffect(() => {
    fetchAllVehicleSelect().then(list => {
      setOptions([ ...list ]);
    });
  }, []);
  return (
    <MultipleSelect
      id="vehicles-filter"
      options={options}
      value={vehicles}
      onChange={onChange}
      fullWidth
      disabled={isLoading}
      error={error}
      hasError={hasError}
      label={<IntlMessages id="logistic.monitoring.filters.vehicles.label" />}
      headerText={
        <IntlMessages id="logistic.monitoring.filters.vehicles.select" />
      }
      noOptionsText={
        <IntlMessages id="logistic.monitoring.filters.vehicles.none" />
      }
    />
  );
};

export default VehiclesSelect;
