import React, {useCallback, useEffect} from "react";
import PageContainer from "../../../../components/Administrator/Receivers/PageContainer";
import useUiState from "../../../../hooks/Administrator/useUiState";
import FilterBuilder from "../../../../utils/filters";
import {connect} from "react-redux";
import {
  applyReceiversFilters,
  createReceiverStarted,
  deleteReceiver,
  editReceiverStarted,
  fetchAllReceiversByAdministratorStarted,
  resetReceiversFilters,
  resetReceiversState,
  restoreReceiver
} from "../../../../redux/actions/Administrator/Receivers";
import Box from "@material-ui/core/Box";
import Table from "../../../../components/Administrator/Receivers/Table";
import Filters from "../../../../components/Administrator/Receivers/Filters";
import FloatingButtonWithAdd from "../../../../components/Common/Buttons/FloatingButtonWithAdd";
import DeleteConfirmation from "../../../../components/Administrator/Receivers/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "../../../../components/Administrator/Receivers/Confirmations/RestoreConfirmation";
import CreateReceiverDialog from "../../../../components/Administrator/Receivers/SaveReceiverDialog/CreateReceiverDialog";
import EditReceiverDialog from "../../../../components/Administrator/Receivers/SaveReceiverDialog/EditReceiverDialog";
import {getReceiverById} from "../../../../services/ReceiverService";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

const Receivers = ({tableData, dispatch}) => {
  const ui = useUiState();
  useEffect(
    () => {
      dispatch(fetchAllReceiversByAdministratorStarted());
      return () => dispatch(resetReceiversState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(applyReceiversFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(resetReceiversFilters());

  const onEdit = data => dispatch(editReceiverStarted(ui.editingId, data));
  const onDelete = () => dispatch(deleteReceiver(ui.deletingId));
  const onRestore = () => dispatch(restoreReceiver(ui.restoringId));
  const onCreate = data => dispatch(createReceiverStarted(data));

  const [ current, setCurrent ] = React.useState(null);
  React.useEffect(
    () => {
      if (ui.editingId !== null) {
        getReceiverById(ui.editingId).then(receiver => setCurrent(receiver));
      } else {
        setCurrent(null);
      }
    },
    [ ui.editingId ]
  );

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateReceiverDialog onSubmit={onCreate} {...ui.createFormProps} />
      {current && (
        <EditReceiverDialog
          initData={current}
          onSubmit={onEdit}
          {...ui.editFormProps}
        />
      )}
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.receivers;
  const filtered = new FilterBuilder(data)
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("names", filters.supervisor)
    .byFieldIncludes("tags", filters.tag)
    .byFieldIncludes("code", filters.code)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(Receivers);
