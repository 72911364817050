import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {fetchAllVehicleSelect} from "../../../../../../../../../../services/VehicleServiceSelect";
import {fetchError} from "../../../../../../../../../../redux/actions";
import {Grid, MenuItem} from "@material-ui/core";
import Select from "../../../../../../../../../Common/Forms/Select";
import OutlinedInput from "../../../../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../../../../@jumbo/utils/IntlMessages";

const VehicleSelect = ({id, label, value, setValue, error, hasError}) => {
    const [plaques, setPlaques] = React.useState([]);
    const dispatch = useDispatch();
    React.useEffect(() => {
        fetchAllVehicleSelect()
            .then(data => {
                setPlaques(data.map(v => ({id: v.id, label: v.name})))
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }, []);
    return(
        <React.Fragment>
            <Grid item xs={12} md={6}>
                <Select
                    id={id}
                    label={label}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    fullWidth
                    error={hasError}
                    errorMessage={error}
                >
                    {
                        plaques.map(p => (
                            <MenuItem key={`plaque-${p.id}`} value={p.id}>
                                {p.label}
                            </MenuItem>
                        ))
                    }
                </Select>
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedInput
                    label={<IntlMessages id={ "fleet.management.accidents.form.vehicles.table.alias"} />}
                    value={plaques.find(p => p.id === value)?.label || ''}
                    disabled
                    fullWidth
                />
            </Grid>
        </React.Fragment>
    );
};
VehicleSelect.propTypes = {

};

export default VehicleSelect;