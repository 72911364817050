import React from 'react';
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import {parseNumberToMoney} from "../../../../utils/parsers";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";

const UserDetails = () => {
    const {userDetails} = useSelector(({administrator}) => administrator.cashManagers);
    const intl = useIntl();

    return (
        <Box
            display='flex'
            py={4}
            px={2}
            mb={2}
            sx={{
                flexDirection: 'column',
                gap: 3,
                boxShadow: '2px 3px 3px 3px rgba(0, 0, 0, 0.2)',
                borderRadius: '15px'
            }}
        >
            {userDetails?.map(cash => (
             <Box
                 display='grid'
                 sx={{
                     gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                    gridRowGap: '2rem'
                }}
                 key={cash.currency}
             >
                 <Typography
                     variant="subtitle1"
                 >
                     {intl.messages["assigned.quantity"]}: <span>{parseNumberToMoney(cash?.assignedAmount || 0)} {cash?.currency}</span>
                 </Typography>
                 <Typography
                     variant="subtitle1"
                 >
                     {intl.messages["used.quantity"]}: <span>{parseNumberToMoney(cash?.usedAmount || 0)} {cash?.currency}</span>
                 </Typography>
             </Box>
            ))}
        </Box>
    );
};

export default UserDetails;