class WarehouseDestinySelect {
  constructor(id, warehouse, active) {
    this.id = id;
    this.warehouse = warehouse;
    this.active = active;
  }

  static map = warehouse => {
    return new WarehouseDestinySelect(
      warehouse.nu_id_almacen,
      warehouse.vc_desc_almacen,
      warehouse.bi_estado === "1"
    );
  };
}

export default WarehouseDestinySelect;
