import React from "react";
import PropTypes from "prop-types";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = props => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.accidents.form.vehicles.table.alias"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.accidents.form.vehicles.table.plaque"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.accidents.form.vehicles.table.company"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.accidents.form.vehicles.table.policy"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.accidents.form.vehicles.table.status"}
        />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
