import PendingStates from "../../../models/Guardian/PendingStates";

import {
  APPLY_ADVANCES_LIST_FILTERS,
  FETCH_ALL_ADVANCES_BY_GUARDIAN_SUCCEEDED,
  RESET_ADVANCES_LIST_FILTERS,
  SET_ADVANCES_LIST_WITHOUT_ORDER,
  SET_ADVANCES_RESUME
} from "../../types/Guardian/AdvancesCustomTypes";
import {parseNumberToMoney} from "../../../utils/parsers";

const INIT_STATE = {
  data: [],
  filters: {
    state: "2",
    driver: "",
    travel: "",
    route: "",
    box: "",
    lowerDate: "",
    upperDate: ""
  },
  resume: {
    drivers: 0,
    travels: 0,
    assigned: parseNumberToMoney(0),
    approved: parseNumberToMoney(0),
    expended: parseNumberToMoney(0),
    balance: parseNumberToMoney(0)
  },
  list: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_ADVANCES_BY_GUARDIAN_SUCCEEDED: {
      return {
        ...state,
        data: [ ...action.payload.data ]
      };
    }
    case APPLY_ADVANCES_LIST_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_ADVANCES_LIST_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case SET_ADVANCES_RESUME: {
      return {
        ...state,
        resume: {...action.payload.resume}
      };
    }
    case SET_ADVANCES_LIST_WITHOUT_ORDER: {
      return {
        ...state,
        list: [ ...action.payload.list ]
      };
    }
    default: {
      return state;
    }
  }
};
