class PettyCash {
  constructor(
    id,
    box,
    tags,
    currencyId,
    fund,
    cash,
    traffic,
    liquidated,
    refund,
    state,
    currencySymbol,
    obligationOfAdvances,
    costCenter,
  ) {
    this.id = id;
    this.box = {
      id: id,
      name: box
    };
    this.tags = [ ...tags ];
    this.currencyId = currencyId;
    this.fund = fund;
    this.cash = cash;
    this.traffic = traffic;
    this.liquidated = liquidated;
    this.refund = refund;
    this.state = state;
    this.currencySymbol = currencySymbol;
    this.obligationOfAdvances = obligationOfAdvances;
    this.costCenter = costCenter;
  }

  static map = pettyCash => {
    return new PettyCash(
      pettyCash.nu_id_caja_chica,
      pettyCash.vc_nombre_caja,
      pettyCash.vc_desc_tag.split(","),
      pettyCash.nu_id_moneda,
      parseFloat(pettyCash.nu_imp_fondo),
        parseFloat(pettyCash.nu_imp_efectivo),
        parseFloat(pettyCash.nu_imp_transito),
        parseFloat(pettyCash.nu_imp_liquidado),
        parseFloat(pettyCash.nu_imp_para_reposicion),
      pettyCash.vc_estado === "Activo",
      pettyCash.vc_simbolo_moneda,
      pettyCash.bi_obligacion_anticipo === "1",
        pettyCash.vc_desc_centro_costo,
    );
  };
}

export default PettyCash;
