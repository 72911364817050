import React from "react";
import PageContainer from "components/Logistic/ServicesList/PageContainer";
import ServicesTable from "../../../../components/Logistic/ServicesList/ServicesTable";

const ServicesList = () => {
  return (
    <PageContainer>
      <ServicesTable />
    </PageContainer>
  );
};
export default ServicesList;
