class DriverSelect {
  constructor(id, name, active) {
    this.id = id;
    this.name = name;
    this.active = active;
  }

  static map = driver => {
    return new DriverSelect(
      driver.nu_id_entidad_empleado,
      driver.vc_nombre,
      driver.bi_estado
    );
  };
}

export default DriverSelect;
