import React from "react";
import PropTypes from "prop-types";

import RestoreIcon from "@material-ui/icons/Restore";
import IconButton from "@material-ui/core/IconButton";

import StyledTableCell from "../../StyledTableCell";
import StyledTableRow from "../../StyledTableRow";

const RestoreTableRow = ({children, onRestore}) => (
  <StyledTableRow>
    {children}
    <StyledTableCell align="right">
      <IconButton onClick={onRestore}>
        <RestoreIcon />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
);

RestoreTableRow.propTypes = {
  children: PropTypes.node,
  onRestore: PropTypes.func
};

RestoreTableRow.defaultProps = {
  children: null,
  onRestore: () => {}
};

export default RestoreTableRow;
