import {createSlice} from "@reduxjs/toolkit";
import {getLicensesHistoryData} from "../../thunks/BackOffice/historyData";

const INIT_DATA = {
    data: [],
    localFilters: {
        timeFix: ''
    },
    serviceFilters: {
        organization: '',
        license: '',
        startDate: '',
        endDate: '',
        dataOptions: [],
    }
}

const historyDataSlice = createSlice({
    name: 'historyData',
    initialState: {
        ...INIT_DATA,
    },
    reducers: {
        setLocalFilters: (state, action) => {
            state.localFilters = action.payload;
        },
        setServiceFilters: (state, action) => {
            state.serviceFilters = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLicensesHistoryData.fulfilled, (state, action) => {
            const {filters, data} = action.payload;
            state.serviceFilters = filters;
            state.data = data;
        })
    }
})

export default historyDataSlice.reducer;


export const {setServiceFilters, setLocalFilters} = historyDataSlice.actions;
