import React from 'react';
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {fetchAllDriverSelect} from "../../../../../services/DriverSelectService";
import MultipleSelect from "../../../../Common/Forms/Select/MultipleSelect";

const DriverSelect = ({value, name, setValue, edit, errors}) => {
    const [drivers, setDrivers] = React.useState([]);
    React.useEffect(() => {
        fetchAllDriverSelect()
            .then(data => {
                setDrivers(data);
            }).catch(e => setDrivers([]));
    }, []);
    return(
        <Grid item sm={12}>
            <MultipleSelect
                value={value}
                onChange={newValue => setValue(newValue)}
                id="driver-select"
                label={<IntlMessages id={"logistic.work.orders.dialog.detail.driver.label"} />}
                fullWidth
                disabled={edit}
                // error={errors?.driver?.length > 0}
                // errorMessage={errors?.driver[0]}
                options={drivers}
            />
        </Grid>
    );
}

export default DriverSelect;