import {
  cancelPrevFetchAllAdvanceStates,
  fetchAllAdvanceStates
} from "../../../services/AdvanceStateService";
import {FETCH_ALL_ADVANCES_STATES_SUCCEEDED} from "../../types/Guardian/AdvancesCustomTypes";

const fetchAllAdvanceStatesSucceeded = data => ({
  type: FETCH_ALL_ADVANCES_STATES_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchAllAdvancesStatesStarted = () => {
  return async dispatch => {
    cancelPrevFetchAllAdvanceStates();
    fetchAllAdvanceStates().then(states => {
      dispatch(fetchAllAdvanceStatesSucceeded(states));
    });
  };
};
