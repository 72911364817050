class ElevatorStateDetail {
  constructor(id, name, detailId, detailName, reason) {
    this.id = id;
    this.name = name;
    this.detailId = detailId;
    this.detailName = detailName;
    this.reason = reason;
  }

  static map = elevator => {
    return new ElevatorStateDetail(
      elevator.nu_id_tipo_estado,
      "",
      elevator.nu_id_tipo_detalle,
      "",
      elevator.vc_motivo_suspension
    );
  };
}

export default ElevatorStateDetail;
