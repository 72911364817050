import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.configuration.user.settings"} />,
    link: "/configuration/user-settings/users"
  },
  {
    label: (
      <IntlMessages
        id={"sidebar.configuration.user.settings.organization.modules"}
      />
    ),
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={
      <IntlMessages id="configuration.user.settings.organization.modules.title" />
    }
    description={
      <IntlMessages id="configuration.user.settings.organization.modules.description" />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
