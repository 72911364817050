import React from 'react';
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const LegendIndicator = ({color, text}) => {
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '0.5rem',
            }}
        >
            <Box
                style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: color,
                }}
            />
            <Typography
                variant="subtitle1"
                color="primary"
            >
                {text}
            </Typography>
        </Box>
    );
};

export default LegendIndicator;