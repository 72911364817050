import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Insurance from "../../../domain/FleetManagement/Vehicles/Insurance";
import InsuranceCompany from "../../../domain/FleetManagement/Vehicles/InsuranceCompany";
import InsuranceType from "../../../domain/FleetManagement/Vehicles/InsuranceType";

export const getAllInsurances = async () => {
  try {
    const {data} = await axiosInstance.post("/Seguro/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(i => Insurance.map(i));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllInsurancesTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Seguro_Tipos/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(i => InsuranceType.map(i));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllInsurancesCompanies = async () => {
  try {
    const {data} = await axiosInstance.post("/Seguro_Aseguradoras/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(i => InsuranceCompany.map(i));
  } catch (e) {
    return Promise.reject(e);
  }
};
