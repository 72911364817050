import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../Common/Forms/Select/MultipleSelect";

const TagsPicker = ({id, hasError, error, options, values, onChange}) => {
  return (
    <MultipleSelect
      id={id}
      hasError={hasError}
      error={error}
      value={values}
      label={<IntlMessages id="administrator.form.tagsPicker.title" />}
      headerText={<IntlMessages id="administrator.form.tagsPicker.header" />}
      noOptionsText={<IntlMessages id="administrator.form.tagsPicker.empty" />}
      options={options}
      onChange={onChange}
    />
  );
};

export default TagsPicker;
