import {useDispatch, useSelector} from "react-redux";

import uiActions from "redux/actions/Supervisor/ui";

const useUiState = () => {
  const dispatch = useDispatch();
  const {isLoadingData, rejectingId, isRejectExpenseDialogOpen} = useSelector(
    state => state.supervisor.ui
  );

  const openRejectExpenseDialog = id =>
    dispatch(uiActions.openRejectExpenseDialog(id));
  const closeRejectExpenseDialog = () =>
    dispatch(uiActions.closeRejectExpenseDialog());

  return {
    isLoadingData,
    rejectingId: rejectingId,
    tableProps: {onReject: openRejectExpenseDialog},
    rejectExpenseDialogProps: {
      isOpen: isRejectExpenseDialogOpen,
      onClose: closeRejectExpenseDialog
    }
  };
};

export default useUiState;
