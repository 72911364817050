import {
  LOGISTIC_OPERATION_CANCELED,
  LOGISTIC_OPERATION_FAILED,
  LOGISTIC_OPERATION_STARTED,
  LOGISTIC_OPERATION_SUCCEED,
  RESTORE_LOGISTIC_STATE
} from "../../types/Logistic/UiTypes";

const INIT_STATE = {
  isLoading: false,
  operation: {
    type: undefined,
    data: null
  },
  result: {
    code: undefined,
    message: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESTORE_LOGISTIC_STATE: {
      return {
        ...INIT_STATE
      };
    }
    case LOGISTIC_OPERATION_STARTED: {
      return {
        ...state,
        isLoading: true,
        operation: {...action.payload}
      };
    }
    case LOGISTIC_OPERATION_SUCCEED: {
      return {
        ...state,
        ...INIT_STATE,
        result: {
          code: 0,
          message: action.payload
        }
      };
    }
    case LOGISTIC_OPERATION_FAILED: {
      return {
        ...state,
        ...INIT_STATE,
        result: {
          code: 1,
          message: action.payload
        }
      };
    }
    case LOGISTIC_OPERATION_CANCELED: {
      return {
        ...state,
        isLoading: false
      };
    }
    default: {
      return state;
    }
  }
};
