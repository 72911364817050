import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../../actions";
import {
    createCardsService,
    deleteCardsService,
    editCardsService,
    getCardsService
} from "../../../../services/FleetManagement/Tachograph/CardsService";

const basePrefix = 'tachograph/cards';

export const fetchOrganizationAndDriversCards = createAsyncThunk(
    `${basePrefix}/fetchOrganizationAndDriversCards`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const organizationCards = await getCardsService({type: 4});
            const driversCards = await getCardsService({type: 1});
            dispatch(fetchSuccess());
            return {
                organizationCards,
                driversCards,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addOrganizationCard = createAsyncThunk(
    `${basePrefix}/addOrganizationCard`,
    async (request, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const {typeId, expirationDate, cardNumber, name, address} = request;
            const response = await createCardsService({typeId, expirationDate, cardNumber, name, address});
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                id: response.transactionKey,
                ...request,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateOrganizationCard = createAsyncThunk(
    `${basePrefix}/updateOrganizationCard`,
    async ({id, expirationDate, cardNumber, name, typeId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editCardsService({id, expirationDate, cardNumber, name});
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                id,
                expirationDate,
                cardNumber,
                name,
                typeId,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const removeOrganizationCard = createAsyncThunk(
    `${basePrefix}/removeOrganizationCard`,
    async ({id, typeId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await deleteCardsService({id});
            dispatch(fetchSuccess(response.transactionMessage));
            return {
                id,
                typeId
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);