import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  createRegulation,
  editRegulation,
  cancelPrevRegulationFetchAll,
  fetchAllRegulation,
  changeStateRegulation
} from "../../../services/RegulationService";

import {
  FETCH_REGULATION_SUCCEEDED,
  APPLY_REGULATION_FILTERS,
  RESET_REGULATION_FILTERS,
  REGULATION_CHANGE_STATE,
  REGULATION_EDIT,
  REGULATION_CREATE
} from "../../types/Administrator/RegulationCustomTypes";

import Regulation from "../../../domain/Regulation";
import {changeProductState} from "services/ProductService";
import selectEntity from "redux/actions/Administrator/WarehouseEntitySelect";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevRegulationFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchRegulationSucceeded = data => ({
  type: FETCH_REGULATION_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchRegulations = filters => async dispatch => {
  dispatch(fetchStart());
  cancelPrevRegulationFetchAll();
  fetchAllRegulation(filters)
    .then(data => {
      dispatch(fetchSuccess());
      dispatch(fetchRegulationSucceeded(data));
    })
    .catch(e => {
      dispatch(fetchError(e.message));
      dispatch(ui.fetchFailed());
    });
};

export const applyRegulationFilter = filters => ({
  type: APPLY_REGULATION_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetRegulationFilters = () => ({type: RESET_REGULATION_FILTERS});

const createRegulationAction = data => ({
  type: REGULATION_CREATE,
  payload: {
    ...data
  }
});

export const createRegulationStarted = (data, filters) => async dispatch => {
  try {
    dispatch(closeCreateForm());
    dispatch(fetchStart());
    const response = await createRegulation(data);
    dispatch(fetchSuccess(response.message));
    setTimeout(() => {
      dispatch(fetchRegulations(filters));
    }, 1000);
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(fetchError(error.message));
  }
};

const editRegulationAction = (id, data) => ({
  type: REGULATION_EDIT,
  payload: {
    ...data,
    id
  }
});

export const editRegulationStarted = (id, data) => async dispatch => {
  //console.log("EDIT REGULATION: ", id, data);
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await editRegulation(id, data);
    dispatch(editRegulationAction(id, data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeRegulationStateSucceeded = id => ({
  type: REGULATION_CHANGE_STATE,
  payload: {
    id
  }
});

const changeRegulationState = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  await changeStateRegulation(id);
  dispatch(changeRegulationStateSucceeded(id));
  dispatch(ui.changeStateSucceeded());
};

export const deleteRegulationStarted = id => dispatch => {
  dispatch(changeRegulationState(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreRegulationStarted = id => dispatch => {
  dispatch(changeRegulationState(id));
  dispatch(ui.closeRestoreConfirmation());
};

export default {
  get: fetchRegulations,
  delete: deleteRegulationStarted,
  restore: restoreRegulationStarted,
  edit: editRegulationStarted,
  create: createRegulationStarted,
  applyRegulationFilter,
  resetRegulationFilters,
  resetState
};
