import moment from "moment";
import EmployeeFile from "./EmployeeFile";

class EmployeeDetail {
  constructor(
    id,
    firstName,
    lastName,
    email,
    generalPhone,
    birthDate,
    driverLicense,
    generalIButton,
    nif,
    profile_pic
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.generalPhone = generalPhone;
    this.birthDate = birthDate;
    this.driverLicense = driverLicense;
    this.generalIButton = generalIButton;
    this.nif = nif;
    this.profile_pic = profile_pic;
  }

  static map = (employee, employeeId) => {
    return new EmployeeDetail(
      employeeId,
      employee.vc_nombre || "",
      employee.vc_apellidos || "",
      employee.vc_email || "",
      employee.vc_telefono || "",
      employee.dt_fecnacimiento
        ? moment(employee.dt_fecnacimiento).format("YYYY-MM-DD")
        : "",
      employee.vc_nconducir || "",
      employee.vc_ibutton || "",
      employee.vc_nif || "",
      {link: employee.vc_link_image || "", file: null}
    );
  };

  setSpecificData(specificData) {
    this.internalCode = specificData.nu_id_externo || "";
    this.specificEmail = specificData.vc_email || "";
    this.specificPhone = specificData.vc_telefono || "";
    this.specificIButton = specificData.vc_ibutton || "";
    this.commencementDate = specificData.dt_fec_alta
      ? moment(specificData.dt_fec_alta).format("YYYY-MM-DD")
      : "";
    this.terminationDate = specificData.dt_fec_baja
      ? moment(specificData.dt_fec_baja).format("YYYY-MM-DD")
      : "";
    this.contract = specificData.nu_id_fichaje_tipo || "";
    this.active = specificData.bi_estado
      ? specificData.bi_estado === "True"
      : false;
  }

  setFiles(type = "generalFiles", files) {
    this[type] = files.map(file => EmployeeFile.map(file));
  }
}

export default EmployeeDetail;
