import React from "react";
import FiltersContainer from "../../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Checkbox from "../../../../../../Common/Forms/Checkbox";
import useForm from "../../../../../../../hooks/Common/useForm";
import {useDispatch} from "react-redux";
import {resetAssigmentVehicleFilters} from "../../../../../../../redux/actions/Logistic/Assigment";

const initData = {
  vehicle: "",
  capacity: "",
  driver: ""
};

const Filters = ({onApplyFilters, selectAll, setSelectAll}) => {
  const dispatch = useDispatch();
  const {formData, setFormData, resetForm} = useForm(initData);
  React.useEffect(
    () => {
      onApplyFilters(formData);
    },
    [ formData ]
  );
  return (
    <FiltersContainer active onClose={() => {}} style={{marginBottom: "16px"}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} md={4}>
            <OutlinedInput
              id="vehicle-filter"
              label={
                <IntlMessages id="Logistic.assigment.vehicle.dialog.vehicle" />
              }
              value={formData.vehicle}
              onChange={value => setFormData("vehicle", value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <OutlinedInput
              id="capacity-filter"
              label={
                <IntlMessages id="Logistic.assigment.vehicle.dialog.capacity" />
              }
              value={formData.capacity}
              type="number"
              onChange={value => setFormData("capacity", value)}
            />
          </Grid>
          {/*<Grid item xs={12} md={4}>*/}
          {/*    <OutlinedInput*/}
          {/*        id="driver-filter"*/}
          {/*        label={<IntlMessages id="Logistic.assigment.vehicle.dialog.driver"/>}*/}
          {/*        value={formData.driver}*/}
          {/*        onChange={value => setFormData('driver', value)}*/}
          {/*    />*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                resetForm();
                dispatch(resetAssigmentVehicleFilters());
              }}
            >
              <IntlMessages id="Logistic.assigment.vehicle.dialog.clear" />
            </Button>
          </Grid>
          {/*<Grid item xs={12} md={4}>*/}
          {/*    <Button*/}
          {/*        variant="contained"*/}
          {/*        color="primary"*/}
          {/*        onClick={() => onApplyFilters(formData)}*/}
          {/*    >*/}
          {/*        <IntlMessages id="Logistic.assigment.vehicle.dialog.apply"/>*/}
          {/*    </Button>*/}
          {/*</Grid>*/}
          <Grid item xs={12} md={4}>
            <Checkbox
              checked={selectAll}
              onChange={setSelectAll}
              label={
                <IntlMessages
                  id={"Logistic.assigment.vehicle.dialog.select.all"}
                />
              }
              fullWidth
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
