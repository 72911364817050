import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getDataMasterElevatorsOptions = (submodules) => {
    let options = [];
    submodules.some(s => s.id === 61) && options.push({
        name: <IntlMessages id={'sidebar.elevators.data.master.model.type'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/model-types',
    });

    submodules.some(s => s.id === 63) && options.push({
        name: <IntlMessages id={'sidebar.elevators.data.master.category.type'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/category-types',
    });
    submodules.some(s => s.id === 64) && options.push({
        name: <IntlMessages id={'sidebar.elevators.data.master.state.type'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/state-types',
    });
    submodules.some(s => s.id === 65) && options.push({
        name: <IntlMessages id={'sidebar.elevators.data.master.detail.type'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/detail-types',
    });
    submodules.some(s => s.id === 66) && options.push({
        name: <IntlMessages id={'sidebar.elevators.data.master.contract.type'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/contract-types',
    });
    submodules.some(s => s.id === 120) && options.push({
        name: <IntlMessages id="elevators.management.elevators.genre.type"/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/genre-types',
    });
    submodules.some(s => s.id === 121) && options.push({
        name: <IntlMessages id="elevators.management.elevators.manufacturer.type"/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/manufacturer-types',
    });
    submodules.some(s => s.id === 122) && options.push({
        name: <IntlMessages id="elevators.management.elevators.types"/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/types',
    });
    return options;
}

const getDailyOperationsElevatorsOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 67) && options.push({
        name: <IntlMessages id={'sidebar.elevators.daily.operations.index.card'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/elevators-management/elevators/index-cards',
    });
    return options;
}


const getElevatorsOptions = (submodules) => {
    let options = [];
    const dataMasterOptions =  getDataMasterElevatorsOptions(submodules);
    dataMasterOptions?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.elevators.data.master'}/>,
        type: 'section',
        children: dataMasterOptions,
    });
    const dailyOperationsOptions =  getDailyOperationsElevatorsOptions(submodules);
    dailyOperationsOptions?.length > 0 && options.push({
        name: <IntlMessages id={'sidebar.elevators.daily.operations'}/>,
        type: 'section',
        children: dailyOperationsOptions,
    });
    return options;
}


export const getElevatorsManagementOptions = () => {
    const permissions = getUserPermissions();
    let elevators = {
        name: <IntlMessages id={'sidebar.elevators.management'}/>,
        type: 'section',
        children: []
    }
    let submodules = permissions.find(p => p.id===13)?.submodules;
    if(submodules?.length > 0) {
        const elevatorsOptions = getElevatorsOptions(submodules);
        elevatorsOptions.length > 0 && elevators.children.push({
            name: <IntlMessages id={'sidebar.elevators'}/>,
            type: 'collapse',
            children: elevatorsOptions,
        });
    }

    return elevators.children.length > 0? elevators : null;
}