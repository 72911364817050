import React from "react";
import {Box} from "@material-ui/core";
import Filters from "./Filters";
import {useDispatch, useSelector} from "react-redux";
import DriverItem from "./DriverItem";
import {fetchTachographTrafficDriversList} from "../../../../../redux/actions/FleetManagement/TachographTraffic";

const DriversList = () => {
  const dispatch = useDispatch();
    const firstRender = React.useRef(true);
  const {driversList, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographTraffic
  );
  React.useEffect(
    () => {
        if(!firstRender.current) {
            dispatch(fetchTachographTrafficDriversList(filters));
        }
    }, [ filters ]);
  React.useEffect(() => {
      firstRender.current = false;
  }, []);
  return (
    <Box mt="2em" display="flex" flexDirection="column" style={{gap: "2em"}}>
      <Filters />
      <Box display="flex" flexDirection="column" style={{gap: "1em"}}>
        {driversList
            .map(driver => (
          <DriverItem key={driver.id} driver={driver} />
        ))}
      </Box>
    </Box>
  );
};

export default DriversList;
