import {
  FETCH_ORDERS_HISTORY_SUCCEEDED,
  APPLY_ORDERS_HISTORY_FILTERS,
  RESET_ORDERS_HISTORY_FILTERS
} from "../../types/Administrator/OrderHistoryTypes";

const INIT_STATE = {
  data: [],
  filters: {
    description: "",
    active: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_ORDERS_HISTORY_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_ORDERS_HISTORY_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_ORDERS_HISTORY_FILTERS: {
      return {
        ...state,
        fliters: {...INIT_STATE.filters}
      };
    }
    default: {
      return state;
    }
  }
};
