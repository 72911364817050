import React, {useEffect} from "react";

import Picker from "components/Administrator/Form/TagsPicker";
import {useDispatch, useSelector} from "react-redux";
import {fetchTags} from "../../../redux/actions/CustomCommon/Tags";

const TagsPicker = ({id, hasError, error, values, onChange}) => {
  const {tags} = useSelector(state => state.customCommon.tags);

  const options = tags.filter(tag => tag.state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (tags.length < 1) {
      dispatch(fetchTags());
    }
  }, []);

  return (
    <Picker
      id={id}
      hasError={hasError}
      error={error}
      options={options}
      values={values}
      onChange={onChange}
    />
  );
};

export default TagsPicker;
