import React from 'react';
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import {useDispatch, useSelector} from "react-redux";
import {
    setCurrentConfigurationRole,
    updateConfigurationRole,
    updateConfigurationRoleData
} from "../../../../../redux/actions/Configuration/Roles";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import EditPermissionsForm from "./EditPermissionsForm";
import Tabs from "../../../../Common/Tabs";
import EditRoleDataForm from "./EditRoleDataForm";

const EditRoleDialog = ({openEdit, setOpenEdit}) => {
    const id = 'edit-role-form';
    const {current} = useSelector(({configuration}) => configuration.roles);
    const [selected, setSelected] = React.useState('1');
    const dispatch = useDispatch();
    const onEditRolePermissions = data => {
        let operations = [];
        const initialPermissions = parseInitData(current.permissions);
        Object.values(initialPermissions).forEach(permission => {
            permission.state !== data[permission.id].state && operations.push(data[permission.id]);
        });
        dispatch(updateConfigurationRole(current, operations));
        setOpenEdit(false);
    }
    const onEditRoleData = data => {
        dispatch(updateConfigurationRoleData(current, data));
        setOpenEdit(false);
    }
    const parseInitData = modules => {
        let permissions = {};
        Object.values(modules)
            .map(module => module.submodules).flat()
            .map(submodules => submodules.operations).flat()
            .map(o => {
                permissions[o.id] = o;
                return null;
            });
        return permissions;
    }
    const options = [
        {
            value: '1',
            label: <IntlMessages id={"configuration.user.settings.roles.edit.information.title"}/>,

        },
        {
            value: '2',
            label: <IntlMessages id={"configuration.user.settings.roles.edit.permissions.title"}/>,

        }
    ];
    const renderForm = () => {
        if(selected === '1') {
            return (
                <EditRoleDataForm
                    id={id}
                    initialData={{
                        description: current?.description,
                    }}
                    onEditRole={onEditRoleData}
                />
            )
        }else {
            return (
                <EditPermissionsForm
                    id={id}
                    initialData={parseInitData(current?.permissions)}
                    modules={current?.permissions}
                    onEditRole={onEditRolePermissions}
                />
            )
        }
    }

    return (
        <FormDialog
            id={id}
            isOpen={openEdit}
            onClose={() => {
                setOpenEdit(false);
                dispatch(setCurrentConfigurationRole(null));
            }}
            title={<IntlMessages id={"configuration.user.settings.roles.edit.title"}/>}
            submitText={<IntlMessages id={"label.form.save"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
        >
            {current?.permissions &&
                <Tabs
                    value={selected}
                    onChange={setSelected}
                    tabs={options}
                >
                    {renderForm()}
                </Tabs>
            }
        </FormDialog>
    );
}

export default EditRoleDialog;