import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.cash.management"} />,
    link: "/treasury/cash-management/operative"
  },
  {
    label: <IntlMessages id={"guardian.add.expense.page.header"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"guardian.add.expense.page.header"} />}
    description={<IntlMessages id="guardian.add.expense.page.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
