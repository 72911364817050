import React from 'react';
import BaseMap from "../../../../../Common/Maps/Google/BaseMap";

const DestinationsMap = ({selectedDestinations}) => {
    const [markerPoints, setMarkerPoints] = React.useState([]);
    React.useEffect(() => {
        setMarkerPoints(selectedDestinations.map(d => ({
            position: new window.google.maps.LatLng(d.latitude, d.longitude),
            state: "success",
            name: d.name
        })))
    }, [selectedDestinations]);
    return(
            <BaseMap
                mapWayPoints={markerPoints}
                setMapWayPoints={setMarkerPoints}
                centerMap={
                    {
                        lat: selectedDestinations[0]?.latitude || -12.064440,
                        lng: selectedDestinations[0]?.longitude || -77.039028
                    }
                }
            />
    );
}

export default DestinationsMap;