import React from "react";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.cash.management"} />,
    link: "/treasury/cash-management/operative"
  },
  {
    label: <IntlMessages id={"guardian.accounting.list.breadcrumb"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"guardian.accounting.list.header"} />}
    description={<IntlMessages id="guardian.accounting.list.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
