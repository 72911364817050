import React from "react";
import DialogBase from "../../../../Common/Dialogs/DialogBase";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Button, Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {addDestinationStarted} from "../../../../../redux/actions/Administrator/WorkOrders";
import useForm from "../../../../../hooks/Common/useForm";
import DestinationTypeSelect from "./DestinationTypeSelect";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import ClientHeadquarterSelect from "./ClientHeadquarteSelect";
import OutlinedDateTimePicker from "../../../../Common/Forms/OutlinedDateTimePicker";
import {dateIsBetweenTwoDates} from "../../../../../utils/dates";


const DestinationsDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const {current} = useSelector(({administrator}) => administrator.workOrders);
  const {formData, setFormData} = useForm({
    client: "",
    headquarter: "",
    destination: "",
    type: "",
    referenceNumber: "",
    startTime: "",
    endTime: "",
    clientHeadquarter: "",
  });
  const onAdd = () => {
    if(isValidDate(formData.startTime) && isValidDate(formData.endTime)) {
      dispatch(addDestinationStarted(formData, null, current));
      setOpen(false);
    }
  };

  const isValidDate = (date) => {
    return date && dateIsBetweenTwoDates(date, current.startDate, current.endDate);
  }

  const renderActions = () => (
    <React.Fragment>
      <Button onClick={() => setOpen(false)} variant="outlined">
        <IntlMessages
          id={"logistic.work.orders.dialog.add.destination.cancel.label"}
        />
      </Button>
      <Button onClick={() => onAdd()} variant="outlined">
        <IntlMessages
          id={"logistic.work.orders.dialog.add.destination.load.label"}
        />
      </Button>
    </React.Fragment>
  );

  return (
    <DialogBase
      title={
        <IntlMessages
          id={"logistic.work.orders.dialog.add.destination.title"}
        />
      }
      open={open}
      close={() => setOpen(false)}
      actions={renderActions()}
    >
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <ClientHeadquarterSelect
              clientHeadquarter={formData.clientHeadquarter}
              setValue={value => setFormData("clientHeadquarter", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DestinationTypeSelect
            value={formData.type}
            setValue={value => setFormData("type", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OutlinedInput
            label={
              <IntlMessages
                id={
                  "logistic.work.orders.dialog.add.destination.reference.number"
                }
              />
            }
            fullWidth
            value={formData.referenceNumber}
            onChange={value => setFormData("referenceNumber", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OutlinedDateTimePicker
            label={
              <IntlMessages
                id={"logistic.work.orders.dialog.add.destination.start.time"}
              />
            }
            value={formData.startTime}
            onChange={value => setFormData("startTime", value)}
            fullWidth
            hasError={!isValidDate(formData.startTime)}
            error={!isValidDate(formData.startTime) && <IntlMessages id="invalid.date" />}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <OutlinedDateTimePicker
            label={
              <IntlMessages
                id={"logistic.work.orders.dialog.add.destination.end.time"}
              />
            }
            value={formData.endTime}
            onChange={value => setFormData("endTime", value)}
            fullWidth
            hasError={!isValidDate(formData.endTime)}
            error={!isValidDate(formData.endTime) && <IntlMessages id="invalid.date" />}
          />
        </Grid>
      </GridContainer>
    </DialogBase>
  );
};

export default DestinationsDialog;
