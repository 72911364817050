import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {StyledTitle} from "../AddressArea/styles";
import ProductsTable from "./ProductsTable";

const ProductsArea = ({formData, setFormData, edit, noEdit}) => {
  return formData.products.length > 0 &&(
    <GridContainer spacing={4}>
      <Grid item sm={12}>
        <StyledTitle variant="h2">
          <IntlMessages
            id={"logistic.work.orders.dialog.destination.products.title"}
          />
        </StyledTitle>
      </Grid>
      {/*{!noEdit && (*/}
      {/*  <ProductSelect*/}
      {/*    products={formData.products}*/}
      {/*    edit={edit}*/}
      {/*    setFormData={setFormData}*/}
      {/*  />*/}
      {/*)}*/}
      <Grid item sm={12}>
          <ProductsTable
              products={formData.products}
              edit={edit}
              setFormData={setFormData}
          />
      </Grid>
    </GridContainer>
  );
};

export default ProductsArea;
