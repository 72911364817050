import {
  APPLY_ASSIGMENT_VEHICLE_FILTERS,
  CONFIRM_WORK_ORDER_STARTED,
  CONFIRM_WORK_ORDER_SUCCEED,
  FETCH_ASSIGMENT_ORDERS_STARTED,
  FETCH_ASSIGMENT_ORDERS_SUCCEED,
  FETCH_ASSIGMENT_VEHICLES_STARTED,
  FETCH_ASSIGMENT_VEHICLES_SUCCEED,
  GENERATE_WORK_ORDER_STARTED,
  GENERATE_WORK_ORDER_SUCCEED,
  RESET_ASSIGMENT_RESULT,
  RESET_ASSIGMENT_STATE,
  RESET_ASSIGMENT_VEHICLE_FILTERS,
  RESET_ASSIGMENT_WORK_ORDERS,
  SET_OPTIMIZATION_ID,
  SET_ORDERS_FROM_EXCEL
} from "../../types/Logistic/AssigmentTypes";

const INIT_STATE = {
  isLoading: false,
  outDate: null,
  workOrders: [],
  vehicles: {
    data: [],
    filters: {
      vehicle: "",
      capacity: ""
    }
  },
  orders: [],
  result: {
    ok: undefined,
    message: ""
  },
  optimizationId: null
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ASSIGMENT_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    case GENERATE_WORK_ORDER_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }

    case GENERATE_WORK_ORDER_SUCCEED: {
      return {
        ...state,
        workOrders: action.payload.workOrders,
        outDate: action.payload.outDate,
        isLoading: false
      };
    }
    case FETCH_ASSIGMENT_VEHICLES_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ASSIGMENT_VEHICLES_SUCCEED: {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          data: action.payload
        },
        isLoading: false
      };
    }
    case FETCH_ASSIGMENT_ORDERS_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ASSIGMENT_ORDERS_SUCCEED: {
      return {
        ...state,
        orders: action.payload,
        isLoading: false
      };
    }
    case SET_ORDERS_FROM_EXCEL: {
      return {
        ...state,
        orders: action.payload
      };
    }
    case RESET_ASSIGMENT_WORK_ORDERS: {
      return {
        ...state,
        workOrders: [],
        outDate: null
      };
    }
    case CONFIRM_WORK_ORDER_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case CONFIRM_WORK_ORDER_SUCCEED: {
      return {
        ...state,
        isLoading: false,
        result: action.payload
      };
    }
    case RESET_ASSIGMENT_RESULT: {
      return {
        ...state,
        result: {...INIT_STATE.result}
      };
    }
    case APPLY_ASSIGMENT_VEHICLE_FILTERS: {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          filters: {
            ...state.vehicles.filters,
            ...action.payload
          }
        }
      };
    }
    case RESET_ASSIGMENT_VEHICLE_FILTERS: {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          filters: {
            ...INIT_STATE.vehicles.filters
          }
        }
      };
    }
    case SET_OPTIMIZATION_ID: {
      return {
        ...state,
        optimizationId: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
