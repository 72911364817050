import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useStyles} from "../../styles";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveIcon from "@material-ui/icons/Remove";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {openVehicleReportsDialog} from "../../../../../../../redux/actions/FleetManagement/VehicleReports";
import {dialogs} from "../../../constants";
import {useDispatch} from "react-redux";

const GroupItem = ({group}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const subgroups = (() => {
    let data = {};
    group.subgroups.map(u => {
      data[u.id] = u;
      return null;
    });
    return data;
  })();
  const icons = {
    2: <RemoveIcon style={{color: "#000"}} />,
    3: <CancelIcon style={{ color: "red" }} />,
    4: <HelpIcon style={{ color: "#ffeb3b" }} />,
    1: <CheckCircleIcon style={{ color: "green" }} />
  };
  const onShow = subgroup => {
    dispatch(openVehicleReportsDialog(dialogs.MORE_DIALOG, {...subgroup}));
  };
  return (
    <Accordion>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon className={classes.headerIcon} />}
      >
        <Typography className={classes.heading}>{group.description}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionContent}>
        <GridContainer spacing={4} className={classes.itemContentBox}>
          {Object.values(subgroups).map(s => (
            <Grid key={s.id} item xs={12} sm={4}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body1" color="primary">
                  {s.description}
                </Typography>
                <Box display="flex" flexDirection="row">
                  <IconButton disabled color="primary">
                    {icons[s.response]}
                  </IconButton>
                  <IconButton onClick={() => onShow(s)}>
                    <Badge
                      color="secondary"
                      overlap="circular"
                      badgeContent=" "
                      variant="dot"
                      invisible={s.images.length < 1 && !s.observations}
                    >
                      <MoreVertIcon />
                    </Badge>
                  </IconButton>
                </Box>
              </Box>
            </Grid>
          ))}
        </GridContainer>
      </AccordionDetails>
    </Accordion>
  );
};

GroupItem.propTypes = {};

export default GroupItem;
