import React from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import DeleteIcon from "@material-ui/icons/Delete";

const ProductsTable = ({products, edit, setFormData}) => {
  const onDelete = product => {
    setFormData("products", products.filter(p => p.id !== product.id));
  };
  const headers = [
    <IntlMessages
      id={"logistic.work.orders.dialog.destination.products.table.name"}
    />,
    <IntlMessages
      id={"logistic.work.orders.dialog.destination.products.table.quantity"}
    />,
    <IntlMessages
      id={"logistic.work.orders.dialog.destination.products.table.incoming"}
    />,
    <IntlMessages
      id={"logistic.work.orders.dialog.destination.products.table.missing"}
    />,
    <IntlMessages
      id={"logistic.work.orders.dialog.destination.products.table.refund"}
    />,
    <IntlMessages
      id={"logistic.work.orders.dialog.destination.products.table.return"}
    />
  ];
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "90%",
        marginRight: "28px",
        marginBottom: "40px"
      }}
    >
      <Table>
        <TableHead>
          <StyledTableRow>
            {headers.map((header, index) => (
              <StyledTableCell key={index}>{header}</StyledTableCell>
            ))}
            {edit && <StyledTableCell />}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {products.map(product => (
            <StyledTableRow key={product.id}>
              <StyledTableCell>{product.name}</StyledTableCell>
              <StyledTableCell>{product.quantity}</StyledTableCell>
              <StyledTableCell>{product.incoming}</StyledTableCell>
              <StyledTableCell>{product.missing}</StyledTableCell>
              <StyledTableCell>{product.refund}</StyledTableCell>
              <StyledTableCell>{product.toReturn}</StyledTableCell>
              {edit && (
                <StyledTableCell>
                  <IconButton
                    onClick={() => onDelete(product)}
                    edge="end"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTable;
