import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {cloneDeep} from "lodash";
import {
    createProvider,
    fetchAllProviderHeadquarters,
    fetchAllProviders
} from "../../../services/Logistics/Purchases/ProvidersService";

const namespace = 'logistic/providers';

export const getAllProviders = createAsyncThunk(
    `${namespace}/getAllProviders`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const providers = await fetchAllProviders();
            dispatch(fetchSuccess());
            return {
                data: providers,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getProviderHeadquarters = createAsyncThunk(
    `${namespace}/getProviderHeadquarters`,
    async ({provider}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const headquarters = await fetchAllProviderHeadquarters(provider.id);
            dispatch(fetchSuccess());
            return {
                ...cloneDeep(provider),
                headquarters,
            };
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addProvider = createAsyncThunk(
    `${namespace}/addProvider`,
    async ({request, filters}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await createProvider(request);
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
               dispatch(getAllProviders({filters}));
            }, 1500);
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const editProviderState = createAsyncThunk(
    `${namespace}/editProviderState`,
    async ({provider}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            dispatch(fetchSuccess());
            return provider;
        } catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);