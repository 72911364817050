import Widget from "./Widget";

class Dashboard {
    constructor(id, description,
                widgets = [],
                custom) {
        this.id = id;
        this.description = description;
        this.widgets = widgets;
        this.custom = custom;
    }

    static map = dashboard => {
        return new Dashboard(
            dashboard.id,
            dashboard.description,
            dashboard.widgets.map(w => Widget.map(w)),
            true
        );
    }
}

export default Dashboard;

