import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Form from "./Form";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import {closeKpiDialog, openKpiDialog, sendEmail} from "../../../../../../redux/actions/ControlPanel/Kpi";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import {dialogs} from "../../constants";

const SendEmail = ({filters}) => {
    const id = 'send-report-to-email';
    const {dialogs: dialogsList, current: {type: reportId}} = useSelector(({controlPanel}) => controlPanel.kpi);
    const open = dialogsList.some(d => d === dialogs.sendEmail);
    const dispatch = useDispatch();
    const handleOnClose = () => {
        dispatch(closeKpiDialog(dialogs.sendEmail));
    }
    const handleSendEmail = () => {
        dispatch(openKpiDialog(dialogs.sendEmail));
    }
    const onSubmit = data => {
        dispatch(sendEmail(data, reportId, filters));
        handleOnClose();
    }
    return (
        <>
            <Grid item xs={12} sm={3}>
                <Button
                    variant="contained"
                    onClick={() => handleSendEmail()}
                    // disabled={onValidate()}
                    color="primary"
                >
                    <IntlMessages id="send.email"/>
                </Button>
            </Grid>
            <FormDialog
                id={id}
                isOpen={open}
                title={<IntlMessages id='send.email' />}
                submitText={<IntlMessages id='send' />}
                onClose={handleOnClose}

            >
                {open &&
                    <Form id={id}
                          onSubmit={onSubmit}
                    />
                }
            </FormDialog>
        </>
    );
};

export default SendEmail;