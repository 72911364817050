import React from "react";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import {startCreateAccounting} from "../../../../redux/actions/Guardians/NewAccounting";

const NewAccountingDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const {filters} = useSelector(state => state.guardian.accountingList);
  const handleSubmit = async data => {
    dispatch(startCreateAccounting(data, () => setOpen(false), filters));
  };
  return (
    <FormDialog
      id={"new-accounting-form"}
      isOpen={open}
      onClose={() => setOpen(false)}
      title={
        <IntlMessages id={"guardian.accounting.new.accounting.dialog.header"} />
      }
      cancelText={
        <IntlMessages id={"guardian.accounting.new.accounting.dialog.cancel"} />
      }
      submitText={
        <IntlMessages id={"guardian.accounting.new.accounting.dialog.accept"} />
      }
    >
      <Form id={"new-accounting-form"} onSubmit={handleSubmit} />
    </FormDialog>
  );
};

export default NewAccountingDialog;
