import {
  ADD_EXPENSES_TO_ACCOUNTING_STARTED,
  ADD_EXPENSES_TO_ACCOUNTING_SUCCESS,
  DELETE_EXPENSES_FROM_ACCOUNTING_STARTED,
  DELETE_EXPENSES_FROM_ACCOUNTING_SUCCESS,
  FETCH_ALL_LIQUIDATED_EXPENSES_STARTED,
  FETCH_ALL_LIQUIDATED_EXPENSES_SUCCESS
} from "../../types/Guardian/ExpensesType";

const INIT_STATE = {
  liquidated: [],
  toAdd: [],
  toDelete: undefined,
  isLoading: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_LIQUIDATED_EXPENSES_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_LIQUIDATED_EXPENSES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        liquidated: [ ...action.payload ]
      };
    }
    case ADD_EXPENSES_TO_ACCOUNTING_STARTED: {
      return {
        ...state,
        isLoading: true,
        toAdd: [ ...action.payload ]
      };
    }
    case ADD_EXPENSES_TO_ACCOUNTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        toAdd: []
      };
    }
    case DELETE_EXPENSES_FROM_ACCOUNTING_STARTED: {
      return {
        ...state,
        isLoading: true,
        toDelete: action.payload
      };
    }
    case DELETE_EXPENSES_FROM_ACCOUNTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        toDelete: undefined
      };
    }
    default: {
      return state;
    }
  }
};
