import {CLEAR_CURRENT_BOX, UPDATE_CURRENT_BOX} from "../../types/CustomTypes";

export const clearCurrentBox = () => {
  return {
    type: CLEAR_CURRENT_BOX
  };
};

export const setCurrentBox = box => {
  return {
    type: UPDATE_CURRENT_BOX,
    payload: box
  };
};
