import {Paper, withStyles} from "@material-ui/core";

export const ListContainer = withStyles(theme => ({
  root: {
    width: "100%",
    height: 300,
    maxWidth: "100%",
    overflow: "scroll",
    overflowX: "hidden",
    overflowY: "auto"
  }
}))(Paper);
