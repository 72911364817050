import React from 'react';
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {Grid, MenuItem} from "@material-ui/core";
import Select from "../../../../../../Common/Forms/Select";
import {getAllDestinationsStates} from "../../../../../../../services/WorkOrdersService";
import Confirmation from "../../../../../../Common/Dialogs/Confirmation";
import {useDispatch, useSelector} from "react-redux";
import {editDispatchState} from "../../../../../../../redux/actions/Administrator/WorkOrders";

const StateSelect = ({value, setValue, edit, errors = null, typeId, destinationId}) => {
    const [options, setOptions] = React.useState([]);
    const [state, setState] = React.useState(value);
    const [open, setOpen] = React.useState(null);
    const dispatch = useDispatch();
    const {current} = useSelector(({administrator}) => administrator.workOrders);

    React.useEffect(() => {
        getAllDestinationsStates(typeId)
            .then(states => {
                setOptions(states.map(state => ({id: state.id, name: state.typeDescription})))
            })
            .catch(e => setOptions([]));
    }, [typeId]);

    const handleChangeState = (value) => {
        setOpen(value);
    }
    const handleRejectChange = () => {
        setOpen(null);
    }

    const onSuccessChange = () => {
        setState(open);
        setValue(open);
        handleRejectChange();
    }
    const handleSubmitChange = () => {
        dispatch(editDispatchState(destinationId, open, onSuccessChange, handleRejectChange, current));
    }

    const getStateDescription = () => {
        return options.length > 0 && options.find(o => o.id === open)?.name;
    }

    React.useEffect(() => {
        setState(value);
    }, [value]);
    return (
        <>
            <Grid item xs={12} md={6}>
                <Select
                    value={state}
                    onChange={e => handleChangeState(e.target.value)}
                    id="state-select"
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.state.label"}/>}
                    fullWidth
                    disabled={!edit}
                    error={errors?.state?.length > 0}
                    errorMessage={errors?.state[0]}>
                    {options.length > 0 ? options.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.name}
                        </MenuItem>
                    )) : <MenuItem key={0} value={value}>
                    </MenuItem>}
                </Select>
            </Grid>
            <Confirmation
                open={Boolean(open)}
                text={
                <>
                    <IntlMessages id={"logistic.work.orders.dialog.destination.state.change.dialog"} />{` (${getStateDescription()})?`}
                </>
                }
                onReject={handleRejectChange}
                onAccept={handleSubmitChange}
            />
        </>
    );
}

export default StateSelect;