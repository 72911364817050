import {
  ADD_EXPENSES_TO_ACCOUNTING_STARTED,
  ADD_EXPENSES_TO_ACCOUNTING_SUCCESS,
  DELETE_EXPENSES_FROM_ACCOUNTING_STARTED,
  DELETE_EXPENSES_FROM_ACCOUNTING_SUCCESS,
  FETCH_ALL_LIQUIDATED_EXPENSES_STARTED,
  FETCH_ALL_LIQUIDATED_EXPENSES_SUCCESS
} from "../../types/Guardian/ExpensesType";
import {
  addExpenseToAccounting,
  deleteExpenseFromAccounting,
  fetchAllLiquidatedExpensesByPettyCash
} from "../../../services/ExpenseService";
import {fetchAllExpensesByAccountingStarted} from "./CurrentAccounting";
import {fetchAllAccountingStarted} from "./Accounting";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const fetchAllLiquidatedExpensesByPettyCashSuccess = expenses => {
  return {
    type: FETCH_ALL_LIQUIDATED_EXPENSES_SUCCESS,
    payload: expenses
  };
};
export const fetchAllLiquidatedExpensesByPettyCashStarted = pettyCashId => {
  return async dispatch => {
    dispatch({
      type: FETCH_ALL_LIQUIDATED_EXPENSES_STARTED
    });
    fetchAllLiquidatedExpensesByPettyCash(pettyCashId).then(expenses => {
      dispatch(fetchAllLiquidatedExpensesByPettyCashSuccess(expenses));
    });
  };
};

export const addExpensesToAccountingSuccess = () => {
  return {
    type: ADD_EXPENSES_TO_ACCOUNTING_SUCCESS
  };
};

export const addExpensesToAccountingStarted = (
  accounting,
  expenses,
  filters
) => {
  return async dispatch => {
    dispatch({
      type: ADD_EXPENSES_TO_ACCOUNTING_STARTED,
      payload: expenses
    });
    for (const expense of expenses) {
      await addExpenseToAccounting(accounting, expense);
    }
    dispatch(fetchAllAccountingStarted(filters));
    dispatch(addExpensesToAccountingSuccess());
  };
};

export const deleteExpenseFromAccountingSuccess = () => {
  return {
    type: DELETE_EXPENSES_FROM_ACCOUNTING_SUCCESS
  };
};

export const deleteExpenseFromAccountingStarted = (
  accounting,
  expense,
  filters
) => {
  return async dispatch => {
    dispatch({
      type: DELETE_EXPENSES_FROM_ACCOUNTING_STARTED,
      payload: expense
    });
    deleteExpenseFromAccounting(accounting.id, expense).then(() => {
      dispatch(fetchAllAccountingStarted(filters));
    });
    dispatch(deleteExpenseFromAccountingSuccess());
  };
};

export const onDeleteMultipleAccountingExpenses = ({
                                                     currentAccounting,
                                                     selectedExpenses,
                                                     filters
                                                   }) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      for(const expense of selectedExpenses) {
        dispatch({
          type: DELETE_EXPENSES_FROM_ACCOUNTING_STARTED,
          payload: expense
        });
        await deleteExpenseFromAccounting(currentAccounting.id, expense);
        dispatch(deleteExpenseFromAccountingSuccess());
      }
      dispatch(fetchSuccess('Gastos borrados correctamente'));
      dispatch(fetchAllAccountingStarted(filters));
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  }
}
