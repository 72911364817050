import React, {useEffect} from "react";
import FiltersContainer from "components/Common/Forms/FiltersContainer";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PendingStatesSelect from "../../../Common/Forms/Select/PendingStatesSelect";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../hooks/Common/useForm";
import {fetchAllAdvancesStatesStarted} from "../../../../redux/actions/Guardians/AdvancesStates";

const initState = {
  state: "2",
  driver: "",
  travel: "",
  route: "",
  box: "",
  lowerDate: "",
  upperDate: ""
};

const AdvancesFilters = ({applyFilters, onClose}) => {
  const {state, active, box} = useSelector(
    state1 => state1.guardian.advancesListFilter
  );
  const {formData, setFormData, resetForm} = useForm(initState);

  const {data} = useSelector(state1 => state1.guardian.advancesStates);
  const dispatch = useDispatch();

  useEffect(
    () => {
      if (data.length === 0) {
        dispatch(fetchAllAdvancesStatesStarted());
      }
    },
    [ ]
  );

  useEffect(
    () => {
      applyFilters(formData);
    },
    [ applyFilters, formData ]
  );

  useEffect(
    () => {
      if (active && data.length > 0) {
        setFormData("state", data.filter(s => s.id === state)[0].id);
        setFormData("box", box);
      }
    },
    [ data ]
  );

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <FiltersContainer
      onReset={handleClose}
      marginBottom={5}
      active={true}
      onClose={handleClose}
    >
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <PendingStatesSelect
              data={data}
              value={formData.state}
              onChange={value => setFormData("state", value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.driver}
              id="driver"
              label={<IntlMessages id="guardian.advances.table.driver" />}
              onChange={value => setFormData("driver", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.box}
              id="box"
              label={<IntlMessages id="petty.cash.amount" />}
              onChange={value => setFormData('box', value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.travel}
              id="travel"
              label={<IntlMessages id="guardian.advances.table.travel" />}
              onChange={value => setFormData("travel", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.route}
              id="route"
              label={<IntlMessages id="guardian.advances.table.route" />}
              onChange={value => setFormData("route", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              id="lowerDate"
              label={<IntlMessages id="guardian.advances.filter.from" />}
              value={formData.lowerDate}
              onChange={value => setFormData("lowerDate", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedDatePicker
              fullWidth
              id="upperDate"
              label={<IntlMessages id="guardian.advances.filter.to" />}
              value={formData.upperDate}
              onChange={value => setFormData("upperDate", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default AdvancesFilters;
