import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import {getUserType} from "../../../../services/StorageService";

const TableHeader = ({onSort, order, orderBy}) => (
  <React.Fragment>
    <StyledTableRow>
      <TableCellWithSorting
        property="accountingNumber"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.table.id" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="openingDate"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.table.start.date" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="liquidationDate"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.table.end.date" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="tags"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.table.tags" />
      </TableCellWithSorting>
      <TableCellWithSorting
          property="pettyCashAmount"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
      >
        <IntlMessages id="petty.cash.amount" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="quantity"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.table.quantity" />
      </TableCellWithSorting>
      <TableCellWithSorting
        property="state"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="guardian.accounting.table.state" />
      </TableCellWithSorting>
      {getUserType().includes("administrator") && (
        <StyledTableCell>
          <IntlMessages id="administrator.accounting.table.actions" />
        </StyledTableCell>
      )}
      <StyledTableCell align="right">
        <IntlMessages id="guardian.accounting.table.edit.delete" />
      </StyledTableCell>
    </StyledTableRow>
  </React.Fragment>
);

export default TableHeader;
