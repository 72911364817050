import React from "react";
import {useSelector} from "react-redux";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";

const DetailTable = () => {
  const {current} = useSelector(state => state.administrator.clients);
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "90%",
        margin: "36px 40px"
      }}
    >
      <Table>
        <TableHead>
          <TableHeader />
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{current.clientCode}</StyledTableCell>
            <StyledTableCell>{current.name}</StyledTableCell>
            <StyledTableCell>{current.businessName}</StyledTableCell>
            <StyledTableCell>{current.country}</StyledTableCell>
            <StyledTableCell>{current.code}</StyledTableCell>
            <StyledTableCell>{current.email}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailTable;
