import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import TachographAlert from "../../../domain/FleetManagement/Tachograph/TachographAlert";
import TachographAlertType from "../../../domain/FleetManagement/Tachograph/TachographAlertType";
import Response from "../../../domain/Response";
import ConfigurationData from "../../../domain/FleetManagement/Tachograph/ConfigurationData";

export const getAllTachographAlerts = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tacografo_Alerta/sel", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      vc_vehiculos: filters.vehicles.map(v => v.id),
      vc_conductores: filters.drivers.map(d => d.id),
      vc_alerta_tipos: filters.types.map(t => t.id)
    });
    return data.map((f, index) => TachographAlert.map({...f, id: index}));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllTachographAlertsTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Tacografo_Alerta/sel_tipos", {
      nu_id_entidad_empresa: getCompanyEntityId()
    });
    return data.map(type => TachographAlertType.map(type));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllConfigAlertsData = async () => {
  try {
    const {
      data
    } = await axiosInstance.post("/Tacografo_Alerta/sel_configuracion", {
      nu_id_entidad_empresa: getCompanyEntityId()
    });
    return ConfigurationData.map(data[0]);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createConfigAlert = async config => {
  try {
    const {
      data
    } = await axiosInstance.post("/Tacografo_Alerta/ins_configuracion", {
      nu_id_entidad_empresa: getCompanyEntityId(),
      nu_id_alerta_configuracion: config.id,
      nu_frecuencia_datos_vehiculo: config.vehicleDataDownloadDays,
      nu_antelacion_datos_vehiculo: config.vehicleDataNoticeDays,
      vc_email_datos_vehiculo: config.vehicleDataEmail,
      nu_frecuencia_datos_conductor: config.driverDataDownloadDays,
      nu_antelacion_datos_conductor: config.driverDataNoticeDays,
      vc_email_datos_conductor: config.driverDataEmail,
      nu_antelacion_caducidad_tarjeta_empresa:
        config.organizationCardExpiration,
      vc_email_caducidad_tarjeta_empresa: config.organizationCardEmail,
      nu_antelacion_caducidad_tarjeta_conductor: config.driverCardExpiration,
      vc_email_caducidad_tarjeta_conductor: config.driverCardEmail,
      vc_email_ficheros_no_estandar_vehiculo: config.vehicleFileEmail,
      vc_email_ficheros_no_estandar_conductor: config.driverFileEmail
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
