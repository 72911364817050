import React from "react";
import {Box} from "@material-ui/core";
import Filters from "./Filters";
import Table from "./Table";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllDispatchesDestinationsStarted} from "../../../../../redux/actions/Logistic/Monitoring";

const DispatchesTable = () => {
  const {filters, dispatches} = useSelector(state => state.logistic.monitoring);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(fetchAllDispatchesDestinationsStarted(filters));
    },
    [ filters ]
  );
  return (
    <Box display="flex" flexDirection="column" p={4}>
      <Filters />
      <Box mt={4}>
        <Table />
      </Box>
    </Box>
  );
};

export default DispatchesTable;
