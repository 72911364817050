import React from "react";

import FormDialog from "components/Common/Dialogs/FormDialog";
import IntlMessages from "@jumbo/utils/IntlMessages";
import Form from "./Form";

const RejectExpenseDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="reject-expense-supervisor-form"
    isOpen={isOpen}
    title={
      <IntlMessages id="supervisor.unresolvedExpenses.rejectExpenseDialog" />
    }
    onClose={onClose}
    disableEnforceFocus
  >
    <Form id="reject-expense-supervisor-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default RejectExpenseDialog;
