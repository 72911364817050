import React from "react";
import NoFound from "../NoFound";
import PerfectScrollbar from "react-perfect-scrollbar";
import {useStyles} from "./styles";
import CmtList from "../../../../../../../@coremat/CmtList";
import DestinationItem from "./DestinationItem";
import {getChatSidebarHeight} from "../../../../../../../@jumbo/constants/AppConstants";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllDispatchesDestinationsStarted} from "../../../../../../../redux/actions/Logistic/Monitoring";
import {Box, Divider} from "@material-ui/core";
import Checkbox from "../../../../../../Common/Forms/Checkbox";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const DestinationsList = ({
  destinations,
  onSelectDestinations,
  selectedDestinations,
  onSelectAll,
  width
}) => {
  const classes = useStyles({height: getChatSidebarHeight(width)});
  const {filters} = useSelector(({logistic}) => logistic.monitoring);
  const dispatch = useDispatch();
  const [ selectAll, setSelectAll ] = React.useState(false);

  React.useEffect(() => {
    dispatch(fetchAllDispatchesDestinationsStarted(filters));
  }, []);

  React.useEffect(
    () => {
      onSelectAll(selectAll);
    },
    [ selectAll ]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box paddingX={4}>
        <Checkbox
          checked={selectAll}
          onChange={setSelectAll}
          label={
            <IntlMessages
              id={"logistic.monitoring.dispatches.destinations.select.all"}
            />
          }
          fullWidth
        />
      </Box>
      <Divider />
      <Box>
        {destinations.length > 0 ? (
          <PerfectScrollbar className={classes.perfectScrollbarRoot}>
            <CmtList
              data={destinations}
              renderRow={data => (
                <DestinationItem
                  selectedDestinations={selectedDestinations}
                  classes={classes}
                  key={data.id}
                  data={data}
                  onSelectDestinations={onSelectDestinations}
                />
              )}
            />
          </PerfectScrollbar>
        ) : (
          <NoFound />
        )}
      </Box>
    </Box>
  );
};

export default DestinationsList;
