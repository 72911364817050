import React, {useState} from "react";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import InitialInformation from "components/Logistic/Assignment/InitialInformation";
import Map from "components/Logistic/Assignment/Map";
import PageContainer from "../../../../components/Logistic/Assignment/PageContainer";
import Orders from "../../../../components/Logistic/Assignment/Orders";
import {useDispatch, useSelector} from "react-redux";
import WorkOrders from "../../../../components/Logistic/Assignment/WorkOrders";
import {
  confirmWorkOrderCreationStarted,
  generateWorkOrdersStarted,
  resetAssigmentState
} from "../../../../redux/actions/Logistic/Assigment";
import ResultAlert from "../../../../components/Logistic/Assignment/ResultAlert";

const INIT_INFO = {
  date: "",
  startTime: "",
  endTime: ""
};

const Assignment = () => {
  const dispatch = useDispatch();
  const [ show, setShow ] = useState(1);
  const [ selectedOrders, setSelectedOrders ] = React.useState([]);
  const [ selectedVehicles, setSelectedVehicles ] = React.useState([]);
  const {workOrders, outDate} = useSelector(state => state.logistic.assigment);
  const [ workOrdersData, setWorkOrdersData ] = React.useState([]);
  const [ initialInfo, setInitialInfo ] = React.useState({...INIT_INFO});

  const onCreate = options => {
    dispatch(
      generateWorkOrdersStarted(
        initialInfo,
        selectedVehicles,
        selectedOrders,
        options
      )
    );
    setShow(3);
  };
  const onFinish = () => {
    dispatch(
      confirmWorkOrderCreationStarted({workOrders, outDate}, () => {
        setShow(1);
        setInitialInfo({...INIT_INFO});
        setSelectedOrders([]);
        setSelectedVehicles([]);
        setWorkOrdersData([]);
      })
    );
  };

  const views = {
    1: (
      <InitialInformation
        initialInfo={initialInfo}
        setInitialInfo={setInitialInfo}
        selectedVehicles={selectedVehicles}
        setSelectedVehicles={setSelectedVehicles}
        show={show}
        setShow={setShow}
      />
    ),
    2: (
      <Orders
        onCreate={onCreate}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        setShow={setShow}
      />
    ),
    3: (
      <WorkOrders
        onFinish={onFinish}
        setWorkOrders={setWorkOrdersData}
        workOrders={workOrdersData}
        setShow={setShow}
      />
    )
  };

  React.useEffect(
    () => {
      setWorkOrdersData(
        workOrders.map((ot, index) => ({
          ...ot,
          checked: index === 0,
          color: "#" + Math.floor(Math.random() * 16777215).toString(16)
        }))
      );
    },
    [ workOrders ]
  );

  return (
    <PageContainer>
      <GridContainer spacing={4} style={{height: "100vh", maxHeight: "85vh"}}>
        <Grid item xs={12} sm={5} lg={5}>
          {views[show]}
        </Grid>
        <Grid item xs={12} sm={7} lg={7} style={{paddingLeft: "40px"}}>
          <Map
            workOrders={workOrdersData}
            ordersData={selectedOrders}
            show={show}
          />
        </Grid>
      </GridContainer>
      <ResultAlert />
    </PageContainer>
  );
};
export default Assignment;
