import React from "react";
import PropTypes from "prop-types";

import {AppBar, Tabs as MaterialUITabs, Tab, Box} from "@material-ui/core";

const Tabs = ({tabs, value, onChange, variant = "fullWidth", children, ...rest}) => (
  <React.Fragment>
    <AppBar position="static" color="default">
      <MaterialUITabs
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant={variant}
        {...rest}
      >
        {tabs.map(tab => (
          <Tab key={tab.value} value={tab.value} label={tab.label} icon={tab.icon}/>
        ))}
      </MaterialUITabs>
    </AppBar>
    <Box marginTop={5}>{children}</Box>
  </React.Fragment>
);

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.node
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  children: PropTypes.node
};

Tabs.defaultProps = {
  tabs: [],
  value: "",
  onChange: () => {},
  children: null
};

export default Tabs;
