import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid, Typography} from "@material-ui/core";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {
  applyTachographTrafficDriversListFilters
} from "../../../../../../redux/actions/FleetManagement/TachographTraffic";
import PeriodSelect from "../../../../../Common/Forms/Select/PeriodSelect";
import HoursSelect from "./HoursSelect";
import StateSelect from "./StateSelect";
import ActivitySelect from "./ActivitySelect";
import DriversSelect from "./DriversSelect";

const initData = {
  drivers: [],
  state: 0,
  activities: [],
  period: 1,
  minHours: 0,
  maxHours: 10,
};

const periodOptions = [
  {
    id: 1,
    text: (
      <IntlMessages
        id={
          "fleet.management.tachograph.traffic.graphics.available.drivers.today"
        }
      />
    )
  },
  {
    id: 2,
    text: (
      <IntlMessages
        id={
          "fleet.management.tachograph.traffic.graphics.available.drivers.current.week"
        }
      />
    )
  }
];

const Filters = () => {
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographTraffic
  );
  const dispatch = useDispatch();
  const {formData, setFormData, setValues} = useForm(initData);
  const onApplyFilters = () => {
    dispatch(applyTachographTrafficDriversListFilters(formData));
  };
  React.useEffect(
    () => {
      setValues(filters);
    },
    [ filters ]
  );
  return (
    <FiltersContainer active onClose={() => {}}>
      <GridContainer spacing={1}>
        <Grid item xs={5} md={3}>
          <DriversSelect
            value={formData.drivers}
            onChange={drivers => setFormData("drivers", drivers)}
          />
        </Grid>
        <Grid item xs={5} md={1}>
          <StateSelect
            value={formData.state}
            onChange={value => setFormData("state", value)}
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <ActivitySelect
              value={formData.activities}
              onChange={value => {
                setFormData("activities", value);
                if (value.length > 0) {
                  setFormData("state", 1);
                }
              }}
              disabled={formData.state === 2}
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <PeriodSelect
            period={formData.period}
            onChange={value => setFormData("period", value)}
            options={periodOptions}
            showNothing={false}
            disabled={formData.state === 2}
          />
        </Grid>
        <Grid
          item
          xs={3}
          md={2}
          style={{display: "flex", justifyContent: "end", marginTop: 4}}
        >
          <Typography variant="body1">
            <IntlMessages id="fleet.management.tachograph.traffic.drivers.list.filters.available" />
          </Typography>
        </Grid>
        <Grid item xs={2} md={1}>
          <HoursSelect
            label={
              <IntlMessages id="fleet.management.tachograph.traffic.drivers.list.filters.available.min" />
            }
            value={formData.minHours}
            onChange={value => setFormData("minHours", value)}
            maxValue={formData.period === 1 ? 11 : 57}
            disabled={formData.state === 2}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <HoursSelect
            label={
              <IntlMessages id="fleet.management.tachograph.traffic.drivers.list.filters.available.max" />
            }
            value={formData.maxHours}
            onChange={value => setFormData("maxHours", value)}
            maxValue={formData.period === 1 ? 11 : 57}
            disabled={formData.state === 2}
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <ContainedButton
            fullWidth
            color="primary"
            text={<IntlMessages id={"label.form.apply"} />}
            onClick={onApplyFilters}
          />
        </Grid>
      </GridContainer>
    </FiltersContainer>
  );
};

export default Filters;
