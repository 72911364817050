import React from "react";

import Autocomplete from "components/Administrator/Form/SupervisorsAutocomplete";
import {useSelector} from "react-redux";

const SupervisorsAutocomplete = ({hasError, error, value, onChange}) => {
  const {entities} = useSelector(state => state.customCommon.entities);

  return (
    <Autocomplete
      hasError={hasError}
      error={error}
      options={entities}
      value={value}
      onChange={onChange}
    />
  );
};

export default SupervisorsAutocomplete;
