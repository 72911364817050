import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(theme => ({
  dialogRoot: {
    position: "relative"
  },
  dialogTitleRoot: {
    "& .MuiTypography-h6": {
      fontSize: 18,
      color: theme.palette.common.dark
    }
  },
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`
  },
  tabRoot: {
    fontSize: 12,
    letterSpacing: 1.25,
    minWidth: 50,
    width: "50%"
  }
}));
