import {Divider, IconButton, withStyles} from "@material-ui/core";

export const VerticalDivider = withStyles(theme => ({
  root: {
    marginRight: 0,
    marginLeft: 0
  }
}))(Divider);

export const ChatButton = withStyles(theme => ({
  root: {
    alignSelf: "end",
    border: "solid",
    borderColor: "rgba(196,191,191,0.85)"
  }
}))(IconButton);
