import React from "react";
import {Box} from "@material-ui/core";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import Filters from "./Filters";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllTachographAlerts} from "../../../../../redux/actions/FleetManagement/TachographAlerts";
import PaginationFooter from "../../../../Common/Tables/PaginationFooter";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import moment from "moment";
import useTableSort from "../../../../../hooks/Common/useTableSort";

const AlertsTable = ({onClickConfiguration}) => {
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.tachographAlerts
  );
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(fetchAllTachographAlerts(filters));
    },
    [ filters ]
  );
    const {tableData, onSort, order, orderBy} = useTableSort(Object.values(data));

    return (
    <Box>
      <Filters onClickConfiguration={onClickConfiguration} />
      <TableContainer
        head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
        footer={
          <PaginationFooter
            data={Object.values(data)}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        }
      >
        {(rowsPerPage > 0
          ? tableData.slice(
              page * rowsPerPage,
              page * rowsPerPage + rowsPerPage
            )
          : tableData).map(alert => (
          <StyledTableRow key={alert.id}>
            <StyledTableCell>{alert.type}</StyledTableCell>
            <StyledTableCell>{alert.card}</StyledTableCell>
            <StyledTableCell>
              {alert.expirationDate &&
                moment(alert.expirationDate).format("DD/MM/YYYY, HH:mm:ss")}
            </StyledTableCell>
            <StyledTableCell>{alert.identity}</StyledTableCell>
            <StyledTableCell>{alert.plaque}</StyledTableCell>
            <StyledTableCell>
              {alert.lastDownloadDate &&
                moment(alert.lastDownloadDate).format("DD/MM/YYYY, HH:mm:ss")}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableContainer>
    </Box>
  );
};

export default AlertsTable;
