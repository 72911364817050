import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Response from "../../../domain/Response";

export const getCardTypesService = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Tacografo_Tarjeta/sel_tipo'
        );
        return data;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getCardsService = async ({type}) => {
  try {
      const {data} = await axiosInstance.post(
          '/Tacografo_Tarjeta/sel',
          {
              nu_id_entidad_empresa: getCompanyEntityId(),
              vc_tarjeta_tipos: [type],
          }
      );
      return data.map(card => ({
          id: card.nu_id_tarjeta,
          typeId: card.nu_id_tarjeta_tipo,
          cardNumber: card.vc_tarjeta_numero,
          expirationDate: card.dt_fecha_caducidad,
          entityId: card.nu_id_entidad,
          organizationId: card.nu_id_entidad_empresa,
          name: card.vc_nombre,
          address: card.vc_direccion,
      }));
  }catch (e) {
      return Promise.reject(e);
  }
}

export const createCardsService = async ({typeId, expirationDate, cardNumber, name, address}) => {
  try {
      const {data} = await axiosInstance.post(
          '/Tacografo_Tarjeta/ins',
          {
              nu_id_tarjeta_tipo: typeId,
              dt_fecha_caducidad: expirationDate,
              vc_tarjeta_numero: cardNumber,
              vc_nombre: name,
              vc_direccion: address,
          }
      );
      const response = Response.map(data);
      if (response.transactionState === "0") {
          throw Error(response.transactionMessage);
      }
      return response;
  }catch (e) {
      return Promise.reject(e);
  }
}
export const editCardsService = async ({id, expirationDate, cardNumber, name}) => {
  try {
      const {data} = await axiosInstance.post(
          '/Tacografo_Tarjeta/upd',
          {
              nu_id_tarjeta: id,
              vc_tarjeta_numero: cardNumber,
              vc_nombre: name,
              dt_fecha_caducidad: expirationDate,
          }
      );
      const response = Response.map(data);
      if (response.transactionState === "0") {
          throw Error(response.transactionMessage);
      }
      return response;
  }catch (e) {
      return Promise.reject(e);
  }
}

export const deleteCardsService = async ({id}) => {
  try {
      const {data} = await axiosInstance.post(
          '/Tacografo_Tarjeta/del',
          {
              nu_id_tarjeta: id,
          }
      );
      const response = Response.map(data);
      if (response.transactionState === "0") {
          throw Error(response.transactionMessage);
      }
      return response;
  }catch (e) {
      return Promise.reject(e);
  }
}