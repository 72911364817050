import React from "react";
import {Paper, Table, TableBody, TableHead} from "@material-ui/core";

import StyledTableCell from "../../../Common/Tables/StyledTableCell/index";
import StyledTableRow from "../../../Common/Tables/StyledTableRow/index";
import TableHeader from "./TableHeader";
import MerchandiseDetailDialog
  from "../../../../components/Logistic/MerchandiseReception/Table/MerchandiseDetailDialog/index";
import {CustomTableContainer} from "./styles.js";
import {Visibility} from "@material-ui/icons";
import moment from "moment";

const MerchandiseTable = ({
  data,
  current,
  setCurrent,
  open,
  setOpen,
  id,
  setId,
  onAccept,
  onReject,
  incidenceOpen,
  setIncidenceOpen,
  incidenceData,
  setIncidenceData
}) => {
  const openDialog = (i, idMerchandise, incidence) => {
    setOpen(true);
    setCurrent(i);
    setId(idMerchandise);
    setIncidenceData(incidence);
  };


  return (
    <React.Fragment>
      <CustomTableContainer component={Paper}>
        <Table aria-label="collapsible table" stickyHeader>
          <TableHead>
            <TableHeader header="Principal" />
          </TableHead>
          <TableBody>
            {data.map(element => (
              <StyledTableRow>
                <StyledTableCell>
                  {moment(element.date).format("DD/MM/YY HH:mm")}
                </StyledTableCell>
                <StyledTableCell>
                  {element.entityNameOrigin +
                    "/" +
                    element.campusNameOrigin +
                    "/" +
                    element.warehouseNameOrigin}
                </StyledTableCell>
                <StyledTableCell>
                  {element.entityNameDestiny +
                    "/" +
                    element.campusNameDestiny +
                    "/" +
                    element.warehouseNameDestiny}
                </StyledTableCell>
                <StyledTableCell>
                  <Visibility
                    onClick={() =>
                      openDialog(element.transfer, element.id, element)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        <MerchandiseDetailDialog
          current={incidenceData}
          open={open}
          setOpen={setOpen}
          idTransfer={id}
          onAccept={onAccept}
          onReject={onReject}
          openIncidence={incidenceOpen}
          setOpenIncidence={setIncidenceOpen}
        />
      </CustomTableContainer>
    </React.Fragment>
  );
};

export default MerchandiseTable;
