import React, {useCallback, useEffect} from "react";
import OperativeTable from "../../../../components/Guardian/Operative/OperativeTable";
import {connect} from "react-redux";
import {clearCurrentBox} from "../../../../redux/actions/Guardians/CurrentBox";
import BoxesFilterBuilder from "../../../../components/Guardian/Operative/BoxesFilters/BoxesFilterBuilder";
import BoxesFilters from "../../../../components/Guardian/Operative/BoxesFilters";
import {clearCurrentAdvance} from "../../../../redux/actions/Guardians/CurrentAdvance";
import PageContainer from "../../../../components/Guardian/Operative/PageContainer";
import FloatingButton from "../../../../components/Guardian/Operative/FloatingButton";
import {
  applyOperativePettyCashFilters,
  fetchAllPettyCashesStarted,
  resetOperativePettyCashFilters
} from "../../../../redux/actions/Guardians/PettyCashes";
import {fetchAllCurrenciesStarted} from "../../../../redux/actions/CustomCommon/Currencies";

const Operative = ({tableData, currencies, dispatch}) => {
  const applyFilters = useCallback(
    filters => dispatch(applyOperativePettyCashFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(resetOperativePettyCashFilters());

  useEffect(() => {
    dispatch(clearCurrentBox());
    dispatch(clearCurrentAdvance());
  }, []);

  useEffect(
    () => {
      if (currencies.length < 1) {
        dispatch(fetchAllCurrenciesStarted());
      }
      dispatch(fetchAllPettyCashesStarted());
    },
    [ dispatch ]
  );

  return (
    <PageContainer>
      {/*<OperativeGraphics />*/}
      <BoxesFilters
        applyFilters={applyFilters}
        onReset={resetFilters}
        onClose={resetFilters}
      />
      {currencies.length > 0 ? (
        <OperativeTable data={tableData} currencies={currencies} />
      ) : (
        <p>No data founded</p>
      )}
      <FloatingButton />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.guardian.pettyCashes;
  const {currencies} = state.customCommon.currencies;
  const filtered = new BoxesFilterBuilder(data)
    .byFieldIncludes("tags", filters.tags)
    .byFieldEquals("fund", filters.fund)
    .byFieldEquals("cash", filters.cash)
    .byFieldEquals("traffic", filters.traffic)
    .byFieldEquals("liquidated", filters.liquidated)
    .byFieldEquals("refund", filters.refund)
    .byCurrencies(filters.currency)
    .byState(filters.state)
    .build();
  return {tableData: filtered, currencies: currencies};
};

export default connect(mapStateToProps)(Operative);
