import React from "react";
import {useDispatch} from "react-redux";
import {Paper, Table, TableContainer} from "@material-ui/core";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const TableRow = ({tag, onEdit, onDelete}) => (
  <StyledTableRow>
    <StyledTableCell>{tag.id}</StyledTableCell>
    <StyledTableCell>{tag.description}</StyledTableCell>
    <StyledTableCell>
      {tag.state ? (
        <IntlMessages id="administrator.tag.types.table.active" />
      ) : (
        <IntlMessages id="administrator.tag.types.table.inactive" />
      )}
    </StyledTableCell>
    <StyledTableCell>
      <IconButton onClick={() => onEdit(tag)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onDelete(tag)}>
        <DeleteIcon />
      </IconButton>
    </StyledTableCell>
  </StyledTableRow>
);

export default TableRow;
