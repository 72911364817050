import {mapToBoolean} from "../utils/mappers";

class RejectionType {
  constructor(id, entityId, description, active) {
    this.id = id;
    this.entityId = entityId;
    this.description = description;
    this.active = active;
  }

  static map = rejectionType => {
    return new RejectionType(
      rejectionType.nu_id_tipo_rechazo,
      rejectionType.nu_id_entidad,
      rejectionType.vc_desc_tipo_rechazo,
      mapToBoolean(rejectionType.bi_estado)
    );
  };
}

export default RejectionType;
