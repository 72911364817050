import {
  FETCH_CURRENTDAY_SUCCEEDED,
  APPLY_CURRENTDAY_FILTERS
} from "../../types/RRHH/DailyOperations/CurrentDay";

const INIT_STATE = {
  data: [],
  filters: {
    name: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CURRENTDAY_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_CURRENTDAY_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    // case RESET_WAREHOUSE_FILTERS: {
    //     return{
    //         ...state,
    //         filters: {...INIT_STATE.filters},
    //     };
    // }
    // case WAREHOUSE_CHANGE_STATE: {
    //     return{
    //         ...state,
    //         data: state.data.map(type => {
    //             if (type.id === action.payload.id) return {...type, active: !type.active};
    //             return type;
    //         }),
    //     };
    // }
    // case WAREHOUSE_CREATE: {
    //     return{
    //         ...state,
    //         data: [...state.data, action.payload],
    //     };
    // }
    // case WAREHOUSE_EDIT: {
    //     return{
    //         ...state,
    //         data: state.data.map(type => {
    //             if (type.id === action.payload.id) return {...type, name: action.payload.name};
    //             return type;
    //         }),
    //     };
    // }
    default: {
      return state;
    }
  }
};
