import React from 'react';
import {useStyles} from "../styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText, Switch
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const OperationsList = ({submodule, onChange, formData}) => {
    const classes = useStyles();
    return (
        <Accordion className={classes.root}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id={`edit-role-module-submodule-${submodule.id}`}
            >
                <Typography className={classes.heading}>{submodule.description}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {submodule?.operations?.length > 0
                    ? <List dense className={classes.list}>
                        {
                            submodule?.operations?.map(operation => (
                                <ListItem key={`operation-${operation.id}`}>
                                    <ListItemText
                                        primary={operation.description}
                                    />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            color={"primary"}
                                            checked={formData[operation.id].state}
                                            onChange={e => onChange(e.target.checked, operation)}
                                            name={operation.id}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>
                    : <div className={classes.noText}>
                        <Typography variant="h2">
                            <IntlMessages id={"configuration.user.settings.roles.edit.form.no.operations"}/>
                        </Typography>
                    </div>
                }
            </AccordionDetails>
        </Accordion>
    );
}

export default OperationsList;