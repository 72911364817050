import React from "react";
import BasicInfoArea from "./BasicInfoArea";
import useForm from "../../../../../../hooks/Common/useForm";
import AddressArea from "./AddressArea";
import {StyledDivider} from "./styles";
import AddresseeArea from "./AdresseeArea";
import DestinationHeader from "../DestinationHeader";
import {Box} from "@material-ui/core";
import ProductsArea from "./ProductsArea";
import TagsArea from "./TagsArea";
import DeliveryNotesArea from "./DeliveryNotesArea";

const Form = ({
  initData,
  edit,
  setEdit,
  onSubmit,
  index,
  history,
  destinationId,
  cancelAdd,
  noEdit,
  typeId,
  isNew = false
}) => {
  const {formData, setFormData, handleSubmit, resetForm} = useForm(initData);
  React.useEffect(
    () => {
      resetForm();
    },
    [ initData ]
  );

  return (
    <React.Fragment>
      <DestinationHeader
        noEdit={noEdit}
        cancelAdd={cancelAdd}
        onSubmit={handleSubmit(onSubmit)}
        destinationId={destinationId}
        edit={edit}
        setEdit={setEdit}
        header
        index={index}
        reset={resetForm}
        onDeleted={cancelAdd}
      />
      <Box display="flex" flexDirection="column" marginX={4} marginY={2}>
        <form id="destination-detail-form">
          <BasicInfoArea
            typeId={typeId}
            formData={formData}
            setFormData={setFormData}
            edit={edit}
            history={history}
            isNew={isNew}
            destinationId={destinationId}
          />
          <StyledDivider />
          <AddressArea
            formData={formData}
            setFormData={setFormData}
            edit={isNew}
          />
          <StyledDivider />
          <AddresseeArea
            formData={formData}
            setFormData={setFormData}
            edit={edit}
            isNew={isNew}
          />
          <StyledDivider />
          <ProductsArea noEdit={false} formData={formData} setFormData={setFormData} edit={false}/>
          <StyledDivider />
          <TagsArea tags={formData.tags} />
          <StyledDivider />
          <DeliveryNotesArea
              deliveryNotes={formData.deliveryNotes}
              destinationId={destinationId}
          />
          {/*{(edit && destinationId !== 0) && <TransferServiceArea formData={formData} setFormData={setFormData} edit={edit} />}*/}
        </form>
      </Box>
      {!noEdit && (
        <DestinationHeader
          noEdit={noEdit}
          cancelAdd={cancelAdd}
          onSubmit={handleSubmit(onSubmit)}
          destinationId={destinationId}
          edit={edit}
          setEdit={setEdit}
          index={index}
          reset={resetForm}
          onDeleted={cancelAdd}
        />
      )}
    </React.Fragment>
  );
};

export default Form;
