import React from "react";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {resetCurrentChecklistType} from "../../../../redux/actions/Logistic/ChecklistTypes";
import {Box} from "@material-ui/core";
import {useStyles} from "./styles";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import ChecklistNameArea from "./ChecklistNameArea";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import AddGroupDialog from "./AddGroupDialog";
import GroupList from "./GroupsList";

const ChecklistTypeDetail = ({open, setOpen}) => {
  const classes = useStyles();
  const {current} = useSelector(({logistic}) => logistic.checklistTypes);
  const [ openAddGroup, setOpenAddGroup ] = React.useState(false);
  const dispatch = useDispatch();
  const onClose = () => {
    setOpen(false);
    dispatch(resetCurrentChecklistType());
  };
  return (
    <FullScreenDialog
      title={"logistic.distribution.checklist.types.table.edit.title"}
      open={open}
      handleClose={() => onClose()}
    >
      {current && (
        <React.Fragment>
          <Box className={classes.defaultBigBox}>
            <ChecklistNameArea type={current} />
            <Box>
              <ContainedButton
                color="primary"
                text={
                  <IntlMessages
                    id={
                      "logistic.distribution.checklist.types.table.edit.groups.add"
                    }
                  />
                }
                onClick={() => setOpenAddGroup(true)}
              />
            </Box>
            <GroupList groups={current.groups} />
          </Box>
          <AddGroupDialog open={openAddGroup} setOpen={setOpenAddGroup} />
        </React.Fragment>
      )}
    </FullScreenDialog>
  );
};

export default ChecklistTypeDetail;
