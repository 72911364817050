import React from "react";
import {DefaultStyledTableRow, StyledTableCell} from "./styles";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = ({orderBy, order, onSort, isGuardian}) => {
  return (
    <DefaultStyledTableRow>
      <StyledTableCell />
      <TableCellWithSorting property="name">
        <IntlMessages id={"rrhh.page.dailyOperations.header.name"} />
      </TableCellWithSorting>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.dailyOperations.header.signinAt"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.dailyOperations.header.todayWork"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.dailyOperations.header.availableDays"} />
      </StyledTableCell>
      <StyledTableCell align="left">
        <IntlMessages id={"rrhh.page.dailyOperations.header.endWorkDay"} />
      </StyledTableCell>

      {/* {!isGuardian && (
        <StyledTableCell align="left">
          <IntlMessages id={'guardians.operative.plannings.table.actions'} />
        </StyledTableCell>
      )} */}
    </DefaultStyledTableRow>
  );
};

export default TableHeader;
