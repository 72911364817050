import React from 'react';
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {Paper} from "@material-ui/core";
import LegendIndicator from "../../Common/LegendIndicator";
import {gravityColors} from "../constants";
import Typography from "@material-ui/core/Typography";
import EventsGraph from "../Common/EventsGraph";
import EventsByType from "../Common/EventsByType";
import EventsByTypeGrouped from "../Common/EventsByTypeGrouped";
import EventsByVehicle from "../Common/EventsByVehicle";
import EventsByDrivers from "../Common/EventsByDrivers";
import EventsDetailsTable from "./EventsDetailsTable";

const Efficiency = () => {
    const {efficiency} = useSelector(({controlPanel}) => controlPanel.dashboards.currentDashboard.widgets);
    const intl = useIntl();
    return (
        <section className='graph-container'>
            <Typography
                variant='h2'
            >
                {intl.messages['efficiency']}
            </Typography>
            <Paper className='legend cols-6'>
                <LegendIndicator
                    text='<5 min Leve'
                    color={gravityColors.light}
                />
                <LegendIndicator
                    text='>5 y <10 min Moderado'
                    color={gravityColors.moderate}
                />
                <LegendIndicator
                    text='>10 min Grave'
                    color={gravityColors.serious}
                />
            </Paper>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['time']} {intl.messages['ralenti']}
                </Typography>
                <EventsGraph
                    events={efficiency?.events}
                    label='Minutos'
                    isTime
                />
            </div>
            <div className='graph graph-large'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {intl.messages['events']} {intl.messages['ralenti']} {intl.messages['by']} {intl.messages['type']}
                </Typography>
                <EventsByType
                    eventsByType={efficiency?.eventsByType}
                    colors={gravityColors}
                    isTime
                />
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['events']} ${intl.messages['ralenti']} ${intl.messages['by']} ${intl.messages['group']}`}
                </Typography>
                <EventsByTypeGrouped
                    eventsByGroup={efficiency?.eventsByTypeGrouped}
                    colors={gravityColors}
                    isTime
                />
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['events']} ${intl.messages['ralenti']} ${intl.messages['by']} ${intl.messages['vehicle']}`}
                </Typography>
                <EventsByVehicle
                    eventsByVehicle={efficiency?.eventsByVehicle}
                    colors={gravityColors}
                    isTime
                />
            </div>
            <div className='graph'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['events']} ${intl.messages['ralenti']} ${intl.messages['by']} ${intl.messages['driver']}`}
                </Typography>
                <EventsByDrivers
                    eventsByDriver={efficiency?.eventsByDriver}
                    colors={gravityColors}
                    isTime
                />
            </div>
            <div className='events-table'>
                <Typography
                    align='center'
                    variant='subtitle2'
                    component={Paper}
                >
                    {`${intl.messages['detail']} ${intl.messages['events']} ${intl.messages['ralenti']}`}
                </Typography>
                <EventsDetailsTable
                    details={efficiency?.details}
                />
            </div>
        </section>
    );
};

export default Efficiency;