import axiosInstance from "utils/axiosInstance";
import {getUserId} from "services/StorageService";

export const fetchAllCompanyEntities = async () => {
  try {
    const entities = await axiosInstance.post("/Entidad/sel_entidad_empresa", {
      nu_id_empleado: getUserId()
    });
    return entities.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
