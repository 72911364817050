import React from 'react';
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllElevatorsGenres} from "../../../../../../../services/ElevatorsManagement/GenresService";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";

const GenreSelect = ({value, setValue, disabled}) => {
    const {data: genres} = useFetch(getAllElevatorsGenres, {}, []);
    return (
        <Select
            value={value}
            disabled={disabled}
            onChange={e => setValue(e.target.value)}
            label={<IntlMessages id="genre"/>}
            fullWidth
        >
            {genres.map(genre => (
                <MenuItem value={genre.id} key={genre.id}>
                    {genre.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default GenreSelect;