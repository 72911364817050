class TurnSelect {
  constructor(id, turn, active) {
    this.id = id;
    this.turn = turn;
    this.active = active;
  }

  static map = turn => {
    return new TurnSelect(turn.nu_id_turno, turn.vc_desc_turno, turn.bi_estado);
  };
}

export default TurnSelect;
