import React from "react";

import Confirmation from "components/Common/Dialogs/Confirmation";
import IntlMessages from "@jumbo/utils/IntlMessages";


const DeleteConfirmation = ({open, onAccept, onReject, text=(<IntlMessages id="administrator.supervisors.deleteConfirmationText" />)}) => (
  <Confirmation
    open={open}
    onAccept={onAccept}
    onReject={onReject}
    text={text}
  />
);

export default DeleteConfirmation;
