import moment from "moment";

class Widget {
    constructor(id, data,
                labelX, labelY,
                orderId, filters,
                width, height,
                internalId) {
        this.id = id;
        this.data = data;
        this.labelX = labelX;
        this.labelY = labelY;
        this.orderId = orderId;
        this.filters = filters;
        this.width = width;
        this.height = height;
        this.internalId = internalId;
        this.startDate = moment()
            .set({date: 1, month: 0, year: new Date().getFullYear()})
            .startOf('day');
        this.endDate = moment(new Date())
            .add(1, 'day');
    }

    static map = widget => {
        return new Widget(
            widget.id,
            widget.data || [],
            widget.labelX,
            widget.labelY,
            widget.orderId,
            JSON.parse(widget.filters),
            widget.width,
            widget.height,
            widget.nu_id_dashboard_widget_datos,
        )
    }
}

export default Widget;