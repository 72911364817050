import React, {useCallback, useEffect, useState} from "react";
import {Box, FormControlLabel, Switch} from "@material-ui/core";
import CheckStockFilters from "../../../../../components/Logistic/CheckStock/CheckStockList/CheckStockFilters";
import {useDispatch, useSelector} from "react-redux";
import {
  clearCurrentAdvance,
  setCurrentAdvance
} from "../../../../../redux/actions/Guardians/CurrentAdvance";
import PageContainerCheckStock from "../../../../../components/Logistic/CheckStock/CheckStockList/PageContainer/PageContainer";
import {
  applyAdvancesListFilters,
  fetchAllAdvancesByGuardianStarted,
  resetAdvancesListFilters
} from "../../../../../redux/actions/Guardians/Advances";
import {clearAdvancesListFilter} from "../../../../../redux/actions/Guardians/AdvancesListFilter";
const CheckStock = () => {
  const {data, filters, list} = useSelector(
    state => state.guardian.advancesList
  );
  const {currentAdvance} = useSelector(state => state.guardian);
  const dispatch = useDispatch();
  const [ tableData, setTableData ] = useState(data);
  // const [isGuardian, setIsGuardian] = useState(supervisorRole.active);
  const applyFilters = useCallback(
    filters => dispatch(applyAdvancesListFilters(filters)),
    [ dispatch ]
  );

  const resetFilters = () => {
    dispatch(resetAdvancesListFilters());
    dispatch(clearAdvancesListFilter());
  };

  const [ openDialog, setOpenDialog ] = useState(false);

  useEffect(
    () => {
      dispatch(fetchAllAdvancesByGuardianStarted(filters));
    },
    [ filters ]
  );
  useEffect(
    () => {
      setTableData(data);
    },
    [ data ]
  );
  useEffect(
    () => {
      if (currentAdvance.id !== "") {
        dispatch(
          setCurrentAdvance(
            list.find(advance => currentAdvance.id === advance.id)
          )
        );
        setOpenDialog(true);
      }
    },
    [ list ]
  );

  return (
    <PageContainerCheckStock>
      <Box display="flex" flexDirection="column">
        <CheckStockFilters applyFilters={applyFilters} onClose={resetFilters} />
        {/*{selectedAdvance && <AdvanceDetail advance={selectedAdvance} />}*/}
      </Box>
    </PageContainerCheckStock>
  );
};

export default CheckStock;
