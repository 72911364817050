import React from "react";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import {Box, Tab, Tabs} from "@material-ui/core";
import {useStyles} from "./styles";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import DispatchesTable from "./DispatchesTable";
import DispatchesMap from "./DispatchesMap";
import {useDispatch} from "react-redux";
import {resetDispatchesFilters} from "../../../../redux/actions/Logistic/Monitoring";

const DispatchesDialog = ({open, setOpen}) => {
  const classes = useStyles();
  const [ value, setValue ] = React.useState(1);
  const dispatch = useDispatch();

  return (
    <FullScreenDialog
      open={open}
      handleClose={() => {
        setOpen(false);
        dispatch(resetDispatchesFilters());
      }}
      title={"logistic.monitoring.dispatches.title"}
    >
      <Box p={16}>
        <Tabs
          variant={"fullWidth"}
          centered
          className={classes.tabContainer}
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, newValue) => setValue(newValue)}
        >
          <Tab
            className={classes.tabRoot}
            label={<IntlMessages id={"logistic.monitoring.dispatches.table"} />}
            value={1}
          />
          <Tab
            className={classes.tabRoot}
            label={<IntlMessages id={"logistic.monitoring.dispatches.map"} />}
            value={2}
          />
        </Tabs>
        {value === 1 ? <DispatchesTable /> : <DispatchesMap />}
      </Box>
    </FullScreenDialog>
  );
};

export default DispatchesDialog;
