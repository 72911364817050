import * as CampusDestinySelect from "redux/types/Administrator/CampusDestinySelect";
//import * as CampusDestinySelect from 'redux/types/Administrator/CampusDestinySelect';

const INIT_STATE = {
  data: [ {id: 1, campusDestiny: "Sede destino 1"} ]
};

export default (state = INIT_STATE, {type, payload}) => {
  switch (type) {
    case CampusDestinySelect.FETCH_CAMPUS_DESTINY:
      return INIT_STATE;
    default:
      return state;
  }
};
