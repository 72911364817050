import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Checkbox from "../../../../Common/Forms/Checkbox";
import validations from "./validations";
import CountrySelect from "../../../../Common/Forms/Select/CountrySelect";
import UbigeoSelect from "../../UbigeoSelect";

const initData = {
  clientCode: "",
  name: "",
  businessName: "",
  countryCode: "",
  code: "",
  email: "",
  headquarterName: "",
  fiscal: true,
  address: "",
  number: "",
  postalCode: "",
  municipality: "",
  province: "",
  country: "",
  latitude: "",
  longitude: "",
  ubigeo: "",
};

const Form = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initData,
    validations
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.clientCode.length > 0}
            error={errors.clientCode[0]}
            value={formData.clientCode}
            id="create-client-client-code"
            label={<IntlMessages id="administrator.clients.form.client.code" />}
            onChange={value => setFormData("clientCode", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.name.length > 0}
            error={errors.name[0]}
            value={formData.name}
            id="create-client-name"
            label={<IntlMessages id="administrator.clients.form.name" />}
            onChange={value => setFormData("name", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.businessName.length > 0}
            error={errors.businessName[0]}
            value={formData.businessName}
            id="create-client-business-name"
            label={
              <IntlMessages id="administrator.clients.form.business.name" />
            }
            onChange={value => setFormData("businessName", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.countryCode.length > 0}
            error={errors.countryCode[0]}
            value={formData.countryCode}
            id="create-client-country-code"
            label={
              <IntlMessages id="administrator.clients.form.country.code" />
            }
            onChange={value => setFormData("countryCode", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.code.length > 0}
            error={errors.code[0]}
            value={formData.code}
            id="create-client-code"
            label={<IntlMessages id="administrator.clients.form.code" />}
            onChange={value => setFormData("code", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.email.length > 0}
            error={errors.email[0]}
            value={formData.email}
            id="create-client-email"
            label={<IntlMessages id="administrator.clients.form.email" />}
            onChange={value => setFormData("email", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.headquarterName.length > 0}
            error={errors.headquarterName[0]}
            value={formData.headquarterName}
            id="create-client-headquarter-name"
            label={
              <IntlMessages id="administrator.clients.form.headquarter.name" />
            }
            onChange={value => setFormData("headquarterName", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Checkbox
            checked={formData.fiscal}
            onChange={value => setFormData("fiscal", value)}
            name="create-client-fiscal"
            label={<IntlMessages id="administrator.clients.form.fiscal" />}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.address.length > 0}
            error={errors.address[0]}
            value={formData.address}
            id="create-client-address"
            label={<IntlMessages id="administrator.clients.form.address" />}
            onChange={value => setFormData("address", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.number.length > 0}
            error={errors.number[0]}
            value={formData.number}
            id="create-client-number"
            label={<IntlMessages id="administrator.clients.form.number" />}
            onChange={value => setFormData("number", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.postalCode.length > 0}
            error={errors.postalCode[0]}
            value={formData.postalCode}
            id="create-client-postal-code"
            label={<IntlMessages id="administrator.clients.form.postal.code" />}
            onChange={value => setFormData("postalCode", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.municipality.length > 0}
            error={errors.municipality[0]}
            value={formData.municipality}
            id="create-client-municipality"
            label={
              <IntlMessages id="administrator.clients.form.municipality" />
            }
            onChange={value => setFormData("municipality", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.province.length > 0}
            error={errors.province[0]}
            value={formData.province}
            id="create-client-province"
            label={<IntlMessages id="administrator.clients.form.province" />}
            onChange={value => setFormData("province", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CountrySelect
            fullWidth
            hasError={errors.country.length > 0}
            error={errors.country[0]}
            value={formData.country}
            id="create-client-country"
            label={<IntlMessages id="administrator.clients.form.country" />}
            onChange={value => setFormData("country", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            type="number"
            hasError={errors.latitude.length > 0}
            error={errors.latitude[0]}
            value={formData.latitude}
            id="create-client-latitude"
            label={<IntlMessages id="administrator.clients.form.latitude" />}
            onChange={value => setFormData("latitude", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            type="number"
            hasError={errors.longitude.length > 0}
            error={errors.longitude[0]}
            value={formData.longitude}
            id="create-client-longitude"
            label={<IntlMessages id="administrator.clients.form.longitude" />}
            onChange={value => setFormData("longitude", value)}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <UbigeoSelect
            value={formData.ubigeo}
            onChange={value => setFormData("ubigeo", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
