import React from 'react';
import {generateRoute} from "../../../../services/MapService";
import BaseMap from "../../../Common/Maps/Google/BaseMap";
import {InfoWindow, Marker, OverlayView, Polyline} from "@react-google-maps/api";
import {LocationMarker, useStyles} from "./styles";
import {useDispatch} from "react-redux";
import {fetchAssigmentOrdersStarted} from "../../../../redux/actions/Logistic/Assigment";
import {Card, CardContent, Typography} from "@material-ui/core";
import {invertColor} from "../../../../utils/colorGenerate";


const Map = ({ordersData, show, workOrders}) => {
    const [routes, setRoutes] = React.useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [orders, setOrders] = React.useState([]);
    const getRoute = (otId) => {
        return routes?.find(r => r.ot === otId);
    }
    React.useEffect(() => {
        show === 2 && dispatch(fetchAssigmentOrdersStarted({}));
        show !== 3 && setRoutes([]);
    }, [show]);
    React.useEffect(() => {
        setOrders( ordersData.map(order => ({position: new window.google.maps.LatLng(order.latitude, order.longitude),
            name: order.client,
            color: order.color,
            visibleInfo: false})));
    }, [ordersData])
    React.useEffect(() => {
        show === 3 && workOrders.forEach(ot => {
            const wayPoints = ot.destinations.map(d => ({position: new window.google.maps.LatLng(d.latitude, d.longitude)}));
            !routes.some(r => r.ot === ot.id) && generateRoute(wayPoints, (coords) => {
                setRoutes([...routes, {coords, color: ot.color, ot: ot.id}]);
            });
        });
    }, [workOrders]);
    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -(height / 2),
    });
    const openAndCloseInfoWindow = (item) => {
        const newArr = orders.map(obj => {
            if (obj.position === item.position) {
                return { ...obj, visibleInfo: !item.visibleInfo };
            }
            return obj;
        });
        setOrders(newArr);
    };

    return (
        <BaseMap
            centerMap={
                {
                    lat: workOrders.length > 0 ? workOrders[0]?.destinations[0]?.latitude : (ordersData[0]?.latitude || -12.064440),
                    lng: workOrders.length > 0 ? workOrders[0]?.destinations[0]?.longitude : (ordersData[0]?.longitude || -77.039028)
                }
            }
        >
            {show === 3 &&
                <React.Fragment>
                    {workOrders.map((ot, index) => (
                        ot.checked ?
                            <React.Fragment
                                key={`work-order-map-${index}`}
                            >
                                {
                                    ot.destinations.map((destination, i) => {
                                        const position = new window.google.maps.LatLng(destination.latitude, destination.longitude);
                                        return (
                                            <Marker
                                                position={position}
                                                visible={false}
                                                key={`work-order-${index}-marker-${i}`}
                                            >
                                                <OverlayView
                                                    position={position}
                                                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                                    getPixelPositionOffset={getPixelPositionOffset}
                                                >
                                                    <div className={classes.root}>
                                                        <LocationMarker
                                                            style={{
                                                                borderColor: ot.color,
                                                                cursor: "none",
                                                                color: ot.color,
                                                            }}/>
                                                        <Typography
                                                            style={{color: invertColor(ot.color)}}
                                                            variant="caption"
                                                            className={classes.count}>
                                                            {destination.id}
                                                        </Typography>
                                                    </div>
                                                </OverlayView>
                                            </Marker>
                                        );
                                    })
                                }

                                {getRoute(ot.id) && <Polyline
                                    path={getRoute(ot.id).coords}
                                    geodesic={true}
                                    options={{
                                        strokeColor: `${ot.color}`,
                                        strokeOpacity: 0.8,
                                        strokeWeight: 5,
                                        clickable: true
                                    }}
                                />}
                            </React.Fragment>
                            : <></>
                    ))}
                    }
                </React.Fragment>
            }
            {
                show === 2 &&
                <React.Fragment>
                    {orders.map(order => (
                        <Marker
                            position={order.position}
                            visible={false}
                            key={order.position}
                        >
                            <OverlayView
                                position={order.position}
                                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                getPixelPositionOffset={getPixelPositionOffset}

                            >
                                <LocationMarker
                                    onClick={() => openAndCloseInfoWindow(order)}
                                    style={{
                                        borderColor: order.color,
                                        cursor: "pointer",
                                        color: order.color,
                                    }}/>
                            </OverlayView>
                            {order.visibleInfo && <InfoWindow
                                onCloseClick={() => openAndCloseInfoWindow(order)}
                                position={order.position}
                            >
                                <Card
                                    className={classes.markerDetailBox}
                                >
                                    <CardContent>
                                        <Typography gutterBottom variant="h2" component="h2">
                                            {order.name}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </InfoWindow>}
                        </Marker>
                    ))}
                </React.Fragment>
            }
        </BaseMap>
    );
}

export default Map;