import {connectRouter} from "connected-react-router";

import Administrator from "./Administrator";
import Supervisor from "./Supervisor";
import Guardian from "./Guardian";
import CustomCommon from "./CustomCommon";

import Auth from "./Auth";
import Common from "./Common";
import Logistic from "./Logistic";
import FleetManagement from "./FleetManagement";
import RRHH from "./RRHH";
import ControlPanel from "./ControlPanel";
import Configuration from "./Configuration";
import ElevatorsManagement from "./ElevatorsManagement";
import Localization from "./Localization";
import BackOffice from "./BackOffice";
import Treasury from './Treasury';
import General from "./general";

export default history => ({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    general: General,
    administrator: Administrator,
    supervisor: Supervisor,
    guardian: Guardian,
    treasury: Treasury,
    customCommon: CustomCommon,
    logistic: Logistic,
    fleetManagement: FleetManagement,
    rrhh: RRHH,
    controlPanel: ControlPanel,
    configuration: Configuration,
    elevatorsManagement: ElevatorsManagement,
    localization: Localization,
    backOffice: BackOffice,
})
