import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableValues = ({row}) => (
  <React.Fragment>
    <StyledTableCell align="left">{row.description}</StyledTableCell>
    <StyledTableCell align="left">{row.totalWorkDay}</StyledTableCell>
    <StyledTableCell align="left">{row.marginWorkday}</StyledTableCell>
  </React.Fragment>
);

TableValues.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string,
    hours: PropTypes.string,
    minutes: PropTypes.string
  })
};

export default TableValues;
