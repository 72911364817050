import React from 'react';
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Select from "../index";
import MenuItem from "@material-ui/core/MenuItem";


const PeriodSelect = ({id, onChange, style, period, errors = null, disabled, options = [], showNothing = true}) => (
    <Select
        id={id}
        label={<IntlMessages id="logistic.filters.label.period"/>}
        value={period}
        onChange={e => onChange(e.target.value)}
        style={style}
        error={errors?.period.length > 0}
        disabled={disabled}
        errorMessage={errors?.period[0]}
        fullWidth>
        {showNothing &&
            <MenuItem value={0}>
                <IntlMessages id="logistic.filters.period.nothing"/>
            </MenuItem>
        }
        {
            options.map(option => (
                <MenuItem value={option.id} key={option.id}>
                    {option.text}
                </MenuItem>
            ))
        }
    </Select>
);

export default PeriodSelect;