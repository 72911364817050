import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import validations from "./validations";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import DriverSelect from "../../../Common/Form/Selects/DriverSelect";
import TypeSelect from "./TypeSelect";
import StateSelect from "./StateSelect";
import FilesAreaWithoutData from "../../../../Common/FilesAreaWithoutData";
import AddressArea from "./AddressArea";
import VehiclesTable from "./VehiclesTable";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-accident-date"}
                        fullWidth
                        value={formData.date}
                        onChange={value => setFormData('date', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.date"}/>}
                        hasError={errors?.date?.length > 0}
                        error={errors?.date[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"add-accident-communication-date"}
                        fullWidth
                        value={formData.communicationDate}
                        onChange={value => setFormData('communicationDate', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.communication.date"}/>}
                        hasError={errors?.communicationDate?.length > 0}
                        error={errors?.communicationDate[0]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AddressArea
                        address={formData.address}
                        onChange={value => setFormData('address', value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TypeSelect
                        id={"add-accident-type"}
                        value={formData.type}
                        setValue={value => setFormData('type', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.type"}/>}
                        hasError={errors?.type?.length > 0}
                        error={errors?.type[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StateSelect
                        id={"add-accident-status"}
                        value={formData.status}
                        setValue={value => setFormData('status', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.state"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <DriverSelect
                        id={"add-accident-driver"}
                        value={formData.driver}
                        setValue={value => setFormData('driver', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.driver"}/>}
                        hasError={errors?.driver?.length > 0}
                        error={errors?.driver[0]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={"add-accident-version"}
                        fullWidth
                        value={formData.version}
                        onChange={value => setFormData('version', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.version"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={"add-accident-opposite"}
                        fullWidth
                        value={formData.opposite}
                        onChange={value => setFormData('opposite', value)}
                        label={<IntlMessages id={"fleet.management.accidents.add.opposite"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={"add-accident-observations"}
                        fullWidth
                        value={formData.observations}
                        onChange={value => setFormData('observations', value)}
                        label={<IntlMessages id={"fleet.management.accidents.form.observations"}/>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FilesAreaWithoutData
                        formId={"sinister"}
                        files={formData.images}
                        setFiles={files => setFormData('images', files)}
                    />
                </Grid>
            </GridContainer>
            <VehiclesTable
                vehicles={formData.vehicles}
                setVehicles={vehicles => setFormData('vehicles', vehicles)} />
        </form>
    );
}

export default Form;