import React from 'react';
import useForm from "../../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentReportFile} from "../../../../../../../redux/actions/ControlPanel/Kpi";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {Button} from "@material-ui/core";
import VehiclesPicker from "../../../../../../FleetManagement/Common/Selects/VehiclesPicker";
import DeliveriesPicker from "./DeliveriesPicker";
import ServiceTypesPicker from "./ServiceTypesPicker";
import IndicatorSelect from "./IndicatorSelect";
import GeneralDashboardFilters from "./GeneralDashboardFilters";
import SendEmail from "../../../Common/SendEmailDialog";
import SaveFilters from "../../../Common/SaveFilters";
import DateFilters from "../../../Common/DateFilters";
import LogisticReportTypeSelect from "./LogisticReportTypeSelect";
import TimeProcessFilters from "./TimeProcessFilters";

const initialData = {
    period: 1,
    startDate: '',
    endDate: '',
    serviceTypes: [],
    deliveries: [],
    vehicles: [],
    year: '',
    dateFormat: '',
    dateNumber: '',
    planningPlace: '',
    planningPlaces: [],
    indicator: '',
    type: 1
}

const Form = ({service}) => {

    const {formData, errors, setFormData, resetForm, setValues} = useForm(initialData,);
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    const handleSubmitPDF = () => {
        dispatch(setCurrentReportFile(service, formData, 1));
    }
    const handleSubmitExcel = () => {
        dispatch(setCurrentReportFile(service, formData, 2));
    }
    const handleGenerateTableData = () => {
        dispatch(setCurrentReportFile(service, formData, 3));
    }
    React.useEffect(() => {
        resetForm();
        current?.filters && setValues(current.filters);
    }, [service]);
    return (
        <form>
            <GridContainer spacing={4}>
                {service === 33
                    && <TimeProcessFilters
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                {service !== 32
                    ? <React.Fragment>
                        {service === 30 &&
                            <>
                                <Grid item xs={12} sm={6}>
                                    <DeliveriesPicker
                                        values={formData.deliveries}
                                        onChange={value => setFormData('deliveries', value)}

                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <ServiceTypesPicker
                                        values={formData.serviceTypes}
                                        onChange={value => setFormData('serviceTypes', value)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <IndicatorSelect
                                        onChange={value => setFormData('indicator', value)}
                                        value={formData.indicator}
                                    />
                                </Grid>
                            </>
                        }
                        {service === 31 &&
                            <Grid item xs={12} sm={6}>
                                <VehiclesPicker
                                    value={formData.vehicles}
                                    onChange={value => setFormData('vehicles', value)}
                                    fullWidth

                                />
                            </Grid>
                        }
                        <DateFilters
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </React.Fragment>
                    : <GeneralDashboardFilters
                        setFormData={setFormData}
                        formData={formData} />
                }
                {service === 34 &&
                    <Grid item xs={12} md={6}>
                        <LogisticReportTypeSelect
                            value={formData.type}
                            onChange={value => setFormData('type', value)}
                        />
                    </Grid>
                }
                {(service === 30 || service === 32) &&
                    <Grid item xs={12} sm={4} lg={3}>
                        <Button
                            variant="contained"
                            onClick={() => handleSubmitPDF()}
                            // disabled={onValidate()}
                            color="primary"
                        >
                            <IntlMessages id="generate.format.pdf"/>
                        </Button>
                    </Grid>}
                {service < 33 &&
                    <Grid item xs={12} sm={4} lg={3}>
                        <Button
                            variant="contained"
                            onClick={() => handleSubmitExcel()}
                            // disabled={onValidate()}
                            color="primary"
                        >
                            <IntlMessages id="generate.format.excel"/>
                        </Button>
                    </Grid>
                }
                {service > 32 &&
                    <Grid item xs={12} sm={4} lg={3}>
                        <Button
                            variant="contained"
                            onClick={() => handleGenerateTableData()}
                            // disabled={onValidate()}
                            color="primary"
                        >
                            <IntlMessages id="generate.report"/>
                        </Button>
                    </Grid>

                }
                <SendEmail filters={formData} />
                <SaveFilters filters={formData}/>
            </GridContainer>
        </form>
    );
};

Form.propTypes = {};

export default Form;