import React from 'react';
import PropTypes from 'prop-types';
import useForm from "../../../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import TypeSelect from "./TypeSelect";
import InformationSelect from "./InformationSelect";
import StatusSelect from "./StatusSelect";

const Form = ({id, onSubmit, initialData, vehicle, add}) => {
    const {formData, setFormData, handleSubmit} = useForm(initialData);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)} >
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <TypeSelect
                        value={formData.type}
                        onChange={value => setFormData('type', value)}
                        disabled={!add}
                    />
                </Grid>

                <Grid item xs={12}>
                    {add
                        ? <InformationSelect
                            value={formData.information}
                            onChange={value => setFormData('information', value)}
                            vehicle={vehicle}
                            type={formData.type}
                        />
                        :<OutlinedInput
                            label={<IntlMessages id="fleet.management.workshop.request.form.tasks.form.information" />}
                            disabled
                            value={formData.informationDescription}
                            fullWidth
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        value={formData.observations}
                        id={id + "-observations"}
                        label={
                            <IntlMessages id= "fleet.management.workshop.request.form.tasks.form.observations" />
                        }
                        onChange={value => setFormData("observations", value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        value={formData.workshopObservations}
                        id={id + "-workshop-observations"}
                        label={
                            <IntlMessages id= "fleet.management.workshop.request.form.tasks.form.workshop.observations" />
                        }
                        onChange={value => setFormData("workshopObservations", value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        type="number"
                        value={formData.cost}
                        id={id + "-amount"}
                        label={
                            <IntlMessages id= "fleet.management.workshop.request.form.tasks.form.cost" />
                        }
                        onChange={value => setFormData("cost", value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <StatusSelect
                        value={formData.status}
                        onChange={value => setFormData('status', value)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

Form.propTypes = {
    add: PropTypes.bool.isRequired,
};

export default Form;