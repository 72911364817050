import React from "react";
import PropTypes from "prop-types";
import MultipleSelect from "../../../../../../Common/Forms/Select/MultipleSelect";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {fetchAllConfigVehicleRoles} from "../../../../../../../services/Configuration/ConfigVehicleRoleService";

const VehicleRoleSelect = ({setValue, id, fullWidth = true, label, value}) => {
  const {data} = useFetch(fetchAllConfigVehicleRoles, {}, []);

  return (
    <MultipleSelect
      id={id}
      onChange={value => setValue(value)}
      value={value}
      label={label}
      fullWidth={fullWidth}
      options={Object.values(data)}
    />
  );
};

VehicleRoleSelect.propTypes = {
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  label: PropTypes.node.isRequired,
  value: PropTypes.array.isRequired
};

export default VehicleRoleSelect;
