import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {cloneDeep} from "lodash";
import {getWidgetData} from "../../../services/ControlPanel/Kpi/DashboardService";

export const getAavvDashboardData = createAsyncThunk(
    'dashboards/getAavvDashboardData',
    async ({filters, dashboard}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
           const {data} = await getWidgetData({
               id: 26,
               ...filters,
           });
            dispatch(fetchSuccess());
            const currentDashboard = cloneDeep(dashboard);
            currentDashboard.widgets = data;
            return {filters, currentDashboard};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)