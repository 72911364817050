import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {Box} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../utils/IntlMessages";
import Button from "@material-ui/core/Button";
import {AuhMethods} from "../../../../services/auth";
import ContentLoader from "../../ContentLoader";
import {alpha, makeStyles} from "@material-ui/core/styles";
import CmtImage from "../../../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import {CurrentAuthMethod} from "../../../constants/AppConstants";
import AuthWrapper from "./AuthWrapper";
import {NavLink, useHistory} from "react-router-dom";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {fetchError} from "../../../../redux/actions";
import FormControl from "@material-ui/core/FormControl";
import axiosInstance from "utils/axiosInstance";
import {deleteUserFromDatabase} from "../../../../services/auth/db/UserServices";

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    [theme.breakpoints.up("md")]: {
      width: "50%",
      order: 2
    }
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up("md")]: {
      width: props => (props.variant === "default" ? "50%" : "100%"),
      order: 1
    },
    [theme.breakpoints.up("xl")]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  },
  textCapital: {
    textTransform: "capitalize"
  },
  textAcc: {
    textAlign: "center",
    "& a": {
      marginLeft: 4
    }
  },
  alrTextRoot: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      textAlign: "right"
    }
  }
}));

//variant = 'default', 'standard', 'bgColor'
const SignUp = ({
  method = CurrentAuthMethod,
  variant = "default",
  wrapperVariant = "default"
}) => {
  const countries = [
    {
      id: 1,
      country: "España"
    },
    {
      id: 2,
      country: "Perú"
    }
  ];
  const history = useHistory();

  const [ name, setName ] = useState("");
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const [ codeEntered, setCodeEntered ] = useState("");
  const [ valueContry, setValueCountry ] = useState(countries[0].id);
  const [ responseMsj, setResponseMsj ] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles({variant});
  const validatePassword = () => {
    if (password.length < 8) {
      return [
        false,
        "La longitud de la contraseña no cumple con los requisitos, debe ser mayor a 8 caracteres"
      ];
    }
    if (!password.match(/[0-9]/g)) {
      return [ false, "La contraseña debe contener un numero" ];
    }
    if (!password.match(/[A-Z]/g)) {
      return [ false, "La contraseña debe contener una mayuscula" ];
    }
    if (!password.match(/[a-z]/g)) {
      return [ false, "La contraseña debe contener una minuscula" ];
    }
    return [ true, "" ];
  };
  const onSubmit = async () => {
    /* dispatch(AuhMethods[method].onRegister({ email, password })); */
    /* createNewUser(); */
    let result;
    const [ passwordValid, message ] = validatePassword();
    if (!passwordValid) {
      dispatch(fetchError(message));
      return;
    } else {
      if (valueContry === 1) {
        result = validateNif(codeEntered);
      } else {
        result = ValidateRuc(codeEntered);
      }
      if (!result) {
        dispatch(fetchError("El código indicado no es correcto."));
      } else {
        const [ newUserId, companyId ] = await createNewUser();
        if (newUserId !== null) {
          dispatch(
            AuhMethods[method].onRegister(
              {email, password, newUserId, companyId},
              () => history.replace("/signin")
            )
          );
        } else {
          dispatch(fetchError(responseMsj));
        }
      }
    }
  };

  const createNewUser = async () => {
    try {
      const response = await axiosInstance.post("/Entidad/ins_usuario", {
        vc_cod_entidad: "",
        vc_nombre: name,
        vc_nombre_comercial: "",
        vc_cif_pais: valueContry,
        vc_cif_codigo: codeEntered,
        vc_email: email
      });
      if (response.data.nu_tran_stdo === "1") {
        setResponseMsj(response.data.tx_tran_mnsg);
        return [ response.data.nu_tran_pkey, response.data.vc_tran_codi ];
      } else if (response.data.nu_tran_stdo === "0") {
        setResponseMsj(response.data.tx_tran_mnsg);
        return [ null, response.data.vc_tran_codi ];
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const validateNif = value => {
    var validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    var str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    var nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");

    var letter = str.substr(-1);
    var charIndex = parseInt(nie.substr(0, 8)) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  };

  const ValidateRuc = ruc => {
    return ruc.length === 8 || ruc.length === 9;
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === "default" ? (
        <Box className={classes.authThumb}>
          <CmtImage src={"/images/auth/sign-up-img.png"} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={"/images/logo.png"} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          <IntlMessages id={"signup.title"} />
        </Typography>
        <form>
          <Box mb={2}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
            <TextField
              label={"Nombre"}
              fullWidth
              onChange={event => setName(event.target.value)}
              defaultValue={name}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
            <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            {/* <InputLabel id="demo-simple-select-filled-label">Age</InputLabel> */}
            <FormControl style={{width: "100%"}}>
              <Select
                variant="outlined"
                fullWidth
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={valueContry}
                onChange={event => setValueCountry(event.target.value)}
              >
                {countries.map(country => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label={valueContry === 1 ? "DNI" : "DNI"}
              fullWidth
              onChange={event => setCodeEntered(event.target.value)}
              defaultValue={codeEntered}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>

          <Box
            display="flex"
            flexDirection={{xs: "column", sm: "row"}}
            alignItems={{sm: "center"}}
            justifyContent={{sm: "space-between"}}
            mb={3}
          >
            <Box mb={{xs: 2, sm: 0}}>
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.regsiter" />
              </Button>
            </Box>

            <Typography className={classes.alrTextRoot}>
              <NavLink to="/signin">
                <IntlMessages id="signUp.alreadyMember" />
              </NavLink>
            </Typography>
          </Box>
        </form>

        <Box mb={3}>{dispatch(AuhMethods[method].getSocialMediaIcons())}</Box>

        <Typography className={classes.textAcc}>
          <IntlMessages id={"signup.have.account"} />
          <NavLink to="/signin">
            <IntlMessages id={"login.button.ok"} />
          </NavLink>
        </Typography>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignUp;
