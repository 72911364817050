const INIT_STATE = {
  data: [],
  filters: {
    fromDate: null,
    toDate: null,
    cashManager: "",
    action: "",
    amount: "",
    tag: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "CASH_MANAGERS_LOG_HISTORY_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case "CASH_MANAGERS_LOG_HISTORY_APPLY_FILTERS": {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case "CASH_MANAGERS_LOG_HISTORY_RESET_FILTERS": {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    default: {
      return state;
    }
  }
};
