import axiosInstance, {CancelToken, isCancel} from "../utils/axiosInstance";
import AdvanceType from "../domain/AdvanceType";
import Response from "../domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevAdvanceTypeFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllAdvanceTypes = async () => {
  try {
    const types = await axiosInstance.post(
      "/Caja_Chica_Tipo_Anticipo/sel",
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return types.data.map(type => AdvanceType.map(type));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const fetchAdvanceTypeById = async id => {
  try {
    const type = await axiosInstance.post("/Caja_Chica_Tipo_Anticipo/get", {
      nu_id_tipo_anticipo: id,
      nu_id_entidad: getCompanyEntityId()
    });
    return AdvanceType.map(type.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createAdvanceType = async data => {
  try {
    const response = await axiosInstance.post("/Caja_Chica_Tipo_Anticipo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_anticipo: data.description,
      bi_aprobacion_gastos: data.approvalOfExpenses,
      bi_obligacion_ot: data.obligationOfOT,
      bi_conforme_automatico: data.automaticSatisfied,
      vc_usuario: "USER"
    });
    const result = Response.map(response.data);
    if (result.transactionState === "0") {
      throw new Error(result.transactionMessage);
    }
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateAdvanceType = async (id, data) => {
  try {
    const response = await axiosInstance.post("/Caja_Chica_Tipo_Anticipo/upd", {
      nu_id_tipo_anticipo: id,
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_anticipo: data.description,
      bi_aprobacion_gastos: data.approvalOfExpenses,
      bi_obligacion_ot: data.obligationOfOT,
      bi_conforme_automatico: data.automaticSatisfied,
      vc_usuario: "USER"
    });
    const {transactionState, transactionMessage, transactionKey} = Response.map(
      response.data
    );
    if (transactionState === "1") {
      return transactionKey;
    } else {
      throw new Error(transactionMessage);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeAdvanceTypeState = async id => {
  try {
    const response = await axiosInstance.post("/Caja_Chica_Tipo_Anticipo/del", {
      nu_id_tipo_anticipo: id,
      nu_id_entidad: getCompanyEntityId()
    });
    const {transactionState, transactionMessage, transactionKey} = Response.map(
      response.data
    );
    if (transactionState === "1") {
      return transactionKey;
    } else {
      throw new Error(transactionMessage);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
