import axios from "axios";

export const CancelToken = axios.CancelToken;

export const isCancel = thrown => axios.isCancel(thrown);

const sunatAxiosInstance = axios.create({
  baseURL:
    `${process.env.REACT_APP_API_URI}/Sunat/`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

export default sunatAxiosInstance;
