import AssigmentDestination from "./AssigmentDestination";

class AssigmentWorkOrder {
  constructor(
    id,
    vehicle,
    driver,
    startDate,
    endDate,
    state,
    type,
    time,
    kilometers,
    destinations
  ) {
    this.id = id;
    this.vehicle = vehicle;
    this.driver = driver;
    this.startDate = startDate;
    this.endDate = endDate;
    this.state = state;
    this.type = type;
    this.time = time;
    this.kilometers = kilometers;
    this.destinations = destinations.map(destination =>
      AssigmentDestination.map(destination)
    );
  }

  static map = workOrder => {
    return new AssigmentWorkOrder(
      workOrder.id,
      workOrder.vehiculo,
      workOrder.conductor,
      workOrder.fechaInicio,
      workOrder.fechaFin,
      workOrder.estado,
      workOrder.tipo,
      workOrder.tiempoDuracion,
      workOrder.distancia,
      workOrder.destinos
    );
  };
}

export default AssigmentWorkOrder;
