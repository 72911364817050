import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import {fetchAllVehicleSelect} from "../../../../../../../services/VehicleServiceSelect";
import {useDispatch} from "react-redux";
import {fetchError} from "../../../../../../../redux/actions";
import {MenuItem} from "@material-ui/core";

const PlaqueSelect = ({id, label, value, setValue, error, hasError}) => {
  const [ plaques, setPlaques ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    fetchAllVehicleSelect()
      .then(data => {
        setPlaques(data.map(v => ({id: v.id, label: v.name})));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      error={hasError}
      errorMessage={error}
    >
      {plaques.map(p => (
        <MenuItem key={`plaque-${p.id}`} value={p.id}>
          {p.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default PlaqueSelect;
