import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="logistic.warehouse.saveIncidenceTypeDialog.field.name.error.empty" />
      )
    }
  ],
  entity: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouse.saveWarehouseDialog.field.entity.error.empty" />
      )
    }
  ],
  sede: [
    {
      rule: v => v !== null && v.value !== "",
      message: (
        <IntlMessages id="logistic.warehouse.saveWarehouseDialog.field.sede.error.empty" />
      )
    }
  ]
};
