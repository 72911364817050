class VehicleReportSubgroup {
  constructor(id, description, response, observations, images) {
    this.id = id;
    this.description = description;
    this.response = response;
    this.observations = observations;
    this.images = images;
  }

  static map = subgroup => {
    return new VehicleReportSubgroup(
      subgroup.nu_id_tipo_checklist_subgrupo,
      subgroup.vc_desc_tipo_checklist_subgrupo,
      subgroup.nu_id_tipo_checklist_respuestas,
      subgroup.vc_desc_checklist_generados_respuestas_descripcion,
      subgroup.checklist_respuestas_imagenes.map(i => ({
        name: i.vc_checklist_generados_respuesta_imagen
      }))
    );
  };
}

export default VehicleReportSubgroup;
