import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.alerts.table.guide"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.alerts.table.vehicle"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.alerts.table.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"logistic.monitoring.alerts.table.detail"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
