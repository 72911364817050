import React from 'react';
import {useCalcService} from "../../../hooks/Treasury/services/useCalcService";
import FormDialog from "../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import ServiceForm from "./ServiceForm";

const EditServiceDialog = ({service, onClose, handleSubmit}) => {
    const id = `edit-service-form`;
    const open = service !== null;
    const {calcService} = useCalcService();
    const onSubmit = (data) => {
        const serviceData = {
            ...data,
            id: service.id,
            isConcept: service.isConcept,
        };
        if(serviceData.isConcept) {
            serviceData.total = ((+serviceData.unitPrice) * (+serviceData.quantity) * (1 - +serviceData.discount / 100))
                * (1 + (+serviceData.percentage) / 100);
            handleSubmit(serviceData);
            onClose();
        } else {
            calcService(serviceData)
                .then(({serviceResponse}) => {
                    handleSubmit({
                        ...serviceData,
                        ...serviceResponse,
                    });
                }).finally(() => {
                onClose();
            });
        }
    }
    return (
        <FormDialog
            id={id}
            isOpen={open}
            onClose={onClose}
            title={<IntlMessages id="edit"/>}
        >
            {open &&
                <ServiceForm
                    id={id}
                    service={service}
                    isConcept={service?.isConcept}
                    onSubmit={onSubmit}
                />
            }
        </FormDialog>
    );
};

export default EditServiceDialog;