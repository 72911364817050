import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";
import React from "react";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.logistic.orders"} />,
        link: "/logistic/distribution/orders"
    },
    {label: <IntlMessages id={"sidebar.logistic.orders"} />, isActive: true}
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="administrator.logistic.order.title" />}
        description={<IntlMessages id="administrator.logistic.order.description" />}
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;