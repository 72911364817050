import React from "react";
import CmtTimeLine from "../../../../../@coremat/CmtTimeLine";
import TimeLineItem from "./TimeLineItem";
import {useSelector} from "react-redux";

const WorkOrderTimeLine = () => {
  const {destinations} = useSelector(state => state.administrator.breakdown);
  return (
    <CmtTimeLine align="left">
      {destinations
        .slice(0, destinations.length - 1)
        .map(destination => (
          <TimeLineItem key={destination.id} destination={destination} />
        ))}
      {
        <TimeLineItem
          destination={destinations[destinations.length - 1]}
          final
        />
      }
    </CmtTimeLine>
  );
};

export default WorkOrderTimeLine;
