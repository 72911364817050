import {TablePagination, withStyles} from "@material-ui/core";

export const StyledTablePagination = withStyles(theme => ({
  root: {
    backgroundColor: "#8092e3",
    color: "white",
    border: "none",
    left: 0,
    bottom: 0,
    zIndex: 2
  },
  selectIcon: {
    color: "white"
  },
  menuItem: {
    backgroundColor: "black",
    color: "black"
  }
}))(TablePagination);
