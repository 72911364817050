/*import Firebase from './firebase';
import JWTAuth from './jwt';*/
import BasicAuth from "./Basic";
import CognitoAuth from "./aws";

export const AuhMethods = {
  /*firebase: Firebase,
  jwtAuth: JWTAuth,*/
  basic: BasicAuth,
  cognito: CognitoAuth
};
