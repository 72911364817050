import React from 'react';
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import {parseNumberToMoney} from "../../../../utils/parsers";

const BillSummary = ({discount, services, setDiscount, hideDiscount = false}) => {
    const subtotal = Object.values(services)
        .reduce((acc, service) => acc + service.total, 0);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '1rem',
                marginTop: '1rem'
            }}
        >
            <OutlinedInput
                label={<IntlMessages id='subtotal'/>}
                value={parseNumberToMoney(subtotal)}
                disabled
            />
            {!hideDiscount &&
                <OutlinedInput
                    label={<IntlMessages id='discount'/>}
                    value={discount}
                    onChange={value => setDiscount(value)}
                    type='number'
                    inputProps={{min: 0}}
                />
            }
            <OutlinedInput
                label={<IntlMessages id='total'/>}
                value={parseNumberToMoney(subtotal * (1 - discount / 100))}
                disabled
            />
        </Box>
    );
};

export default BillSummary;