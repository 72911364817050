import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import useForm from "../../../../../../../hooks/Common/useForm";
import OutlinedDatePicker from "../../../../../../Common/Forms/OutlinedDatePicker";
import BrandSelect from "../../../../../DailyOperation/Tires/AddTireDialog/Form/BrandSelect";
import ModelSelect from "../../../../../DailyOperation/Tires/AddTireDialog/Form/ModelSelect";
import TypeSelect from "./TypeSelect";
import VersionSelect from "./VersionSelect";
import TagsPicker from "./TagsPicker";
import validations from "./validations";

const Form = ({id, initialData, onSubmit, newVehicle = false}) => {
  const [ edit, setEdit ] = React.useState(newVehicle);
  const {formData, setFormData, resetForm, handleSubmit, errors} = useForm(
    initialData,
    validations
  );
  const onCancel = () => {
    resetForm();
    setEdit(false);
  };
  const onAccept = () => {
    setEdit(false);
  };
  const onError = () => {
    setEdit(false);
    resetForm();
  };
  const onClickSave = data => {
    onSubmit(data, onAccept, onError);
  };
  return (
    <form id={id} onSubmit={handleSubmit(onClickSave)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.plaque"
                }
              />
            }
            value={formData.plaque}
            onChange={value => setFormData("plaque", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.alias"
                }
              />
            }
            value={formData.alias}
            onChange={value => setFormData("alias", value)}
            hasError={errors.alias.length > 0}
            error={errors.alias.map((e, i) => <React.Fragment key={i}>{e}</React.Fragment>)}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.frame"
                }
              />
            }
            value={formData.frame}
            onChange={value => setFormData("frame", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <BrandSelect
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.brand"
                }
              />
            }
            value={formData.brand}
            setValue={value => setFormData("brand", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <ModelSelect
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.model"
                }
              />
            }
            value={formData.model}
            setValue={value => setFormData("model", value)}
            brand={formData.brand}
          />
        </Grid>
        <Grid item xs={12}>
          <VersionSelect
            disabled={!edit}
            value={formData.version}
            onChange={value => setFormData("version", value)}
            model={formData.model}
          />
        </Grid>
        <Grid item xs={12}>
          <TypeSelect
            disabled={!edit}
            value={formData.type}
            onChange={value => setFormData("type", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedDatePicker
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.plaque.date"
                }
              />
            }
            value={formData.plaqueDate}
            onChange={value => setFormData("plaqueDate", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            fullWidth
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.mileage"
                }
              />
            }
            value={formData.mileage}
            onChange={value => setFormData("mileage", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TagsPicker
            disabled={!edit}
            label={
              <IntlMessages
                id={
                  "fleet.management.vehicles.specifications.details.basic.tags"
                }
              />
            }
            value={formData.tags}
            onChange={value => setFormData("tags", value)}
          />
        </Grid>
        {!newVehicle && (
          <Grid item xs={12}>
            {edit ? (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <ContainedButton
                  color="primary"
                  type="submit"
                  text={<IntlMessages id={"form.button.save"} />}
                />
                <ContainedButton
                  color="primary"
                  onClick={onCancel}
                  text={<IntlMessages id={"form.button.cancel"} />}
                />
              </Box>
            ) : (
              <ContainedButton
                color="primary"
                text={<IntlMessages id={"form.button.edit"} />}
                onClick={() => setEdit(true)}
              />
            )}
          </Grid>
        )}
      </GridContainer>
    </form>
  );
};

export default Form;
