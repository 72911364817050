import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {fetchAllTagsByType} from "../../../../../../services/TagService";

const TagSelect = ({value, onChange}) => {
    const {data: tags} = useFetch(fetchAllTagsByType, 2, []);
    const onSelectOption = value => {
        if(value === '0'){
            onChange({id: value, name: ''})
        }else {
            onChange(tags.find(t => t.id === value));
        }

    }
    return (
        <Select
            id='vehicle-tag-select'
            label={<IntlMessages id={"fleet.management.vehicles.specifications.management.tag.select"} />}
            value={value?.id}
            onChange={e => onSelectOption(e.target.value)}
            fullWidth
        >
            <MenuItem value="0">
                <IntlMessages id={"fleet.management.vehicles.specifications.management.tag.add"} />
            </MenuItem>
            {tags.map(tag => (
                <MenuItem value={tag.id} key={tag.id}>
                    {tag.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default TagSelect;