import {closeCreateForm, closeEditForm} from "./ui";
import {
  cancelPrevPettyCashTypeFetchAll,
  changeStatePettyCashType,
  createPettyCashType,
  fetchAllPettyCashTypes,
  updatePettyCashType
} from "../../../services/PettyCashTypeService";
import {
  APPLY_PETTY_CASH_TYPES_FILTERS,
  FETCH_PETTY_CASH_TYPES_SUCCEEDED,
  PETTY_CASH_TYPES_CHANGE_STATE,
  PETTY_CASH_TYPES_CREATE,
  PETTY_CASH_TYPES_EDIT,
  RESET_PETTY_CASH_TYPES_FILTERS
} from "../../types/Administrator/PettyCashTypesCustomTypes";
import {restoreAdministratorState} from "./Common";
import ui from "./ui";
import PettyCashType from "../../../domain/PettyCashType";

export const resetState = () => dispatch => {
  cancelPrevPettyCashTypeFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchPettyCashTypesSucceeded = data => ({
  type: FETCH_PETTY_CASH_TYPES_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchPettyCashTypes = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevPettyCashTypeFetchAll();
    fetchAllPettyCashTypes()
      .then(pettyCashTypes => {
        dispatch(ui.fetchSucceeded());
        dispatch(fetchPettyCashTypesSucceeded(pettyCashTypes));
      })
      .catch(e => {
        dispatch(ui.fetchFailed());
        dispatch(ui.setOnErrorMessage(e.message));
      });
  };
};

export const applyPettyCashTypesFilters = filters => ({
  type: APPLY_PETTY_CASH_TYPES_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetPettyCashTypesFilters = () => ({
  type: RESET_PETTY_CASH_TYPES_FILTERS
});

const changePettyCashTypeStateSucceeded = id => ({
  type: PETTY_CASH_TYPES_CHANGE_STATE,
  payload: {
    id
  }
});

const changePettyCashTypeStateStarted = id => dispatch => {
  dispatch(ui.changeStateStarted());
  changeStatePettyCashType(id)
    .then(transactionKey => {
      dispatch(changePettyCashTypeStateSucceeded(transactionKey));
      dispatch(ui.changeStateSucceeded());
    })
    .catch(e => {
      dispatch(ui.changeStateFailed());
      dispatch(ui.setOnErrorMessage(e.message));
    });
};

export const deletePettyCashType = id => dispatch => {
  dispatch(changePettyCashTypeStateStarted(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restorePettyCashType = id => dispatch => {
  dispatch(changePettyCashTypeStateStarted(id));
  dispatch(ui.closeRestoreConfirmation());
};

const createEditPettyCashTypeAction = (id, data) => ({
  type: PETTY_CASH_TYPES_EDIT,
  payload: {
    id: id,
    data: {
      ...data
    }
  }
});

export const editPettyCashType = (data, editingId) => dispatch => {
  dispatch(ui.editStarted());
  updatePettyCashType(editingId, data)
    .then(transactionKey => {
      dispatch(createEditPettyCashTypeAction(transactionKey, data));
      dispatch(closeEditForm());
      dispatch(ui.editSucceeded());
    })
    .catch(e => {
      dispatch(ui.editFailed());
      dispatch(ui.setOnErrorMessage(e.message));
    });
};

const createPettyCashTypeAction = data => ({
  type: PETTY_CASH_TYPES_CREATE,
  payload: {
    ...data
  }
});

export const createPettyCashTypeStarted = data => dispatch => {
  dispatch(ui.createStarted());
  createPettyCashType(data)
    .then(response => {
      if (response.transactionKey) {
        const pettyCashType = new PettyCashType(
          response.transactionKey,
          1,
          data.description,
          data.approvalOfAdvances,
          data.obligationOfAdvances,
          false,
          true
        );
        dispatch(createPettyCashTypeAction(pettyCashType));
        dispatch(closeCreateForm());
        dispatch(ui.createSucceeded());
      } else {
        dispatch(ui.createSucceeded());
        throw new Error(response.transactionMessage);
      }
    })
    .catch(e => {
      dispatch(ui.createFailed());
      dispatch(ui.setOnErrorMessage(e.message));
    });
};

export default {
  get: fetchPettyCashTypes,
  delete: deletePettyCashType,
  restore: restorePettyCashType,
  edit: editPettyCashType,
  create: createPettyCashTypeStarted,
  applyPettyCashTypesFilters,
  resetPettyCashTypesFilters,
  resetState
};
