import React from "react";

import Confirmation from "components/Common/Dialogs/Confirmation";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {
  editRejectionTypeState,
  resetCurrentRejectionType
} from "../../../../../redux/actions/Logistic/RejectionTypes";
import {useDispatch, useSelector} from "react-redux";

const DeleteConfirmation = ({open, setOpenDelete}) => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.rejectionTypes);
  const onDelete = () => {
    dispatch(editRejectionTypeState(current, false));
    setOpenDelete && setOpenDelete(false);
  };
  const onCancel = () => {
    dispatch(resetCurrentRejectionType());
    setOpenDelete && setOpenDelete(false);
  };
  return (
    <Confirmation
      open={open}
      onAccept={onDelete}
      onReject={onCancel}
      text={
        <IntlMessages id="logistic.distribution.rejection.types.delete.description" />
      }
    />
  );
};

export default DeleteConfirmation;
