import React from "react";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import RestoreTableRow from "../../../../../Common/Tables/CrudTable/RestoreTableRow";
import CrudTableRow from "../../../../../Common/Tables/CrudTable/CrudTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import Filters from "./Filters";

const BaseTable = ({
  data,
  onEdit,
  onDelete,
  onRestore,
  initData,
  onApplyFilters,
  onResetFilters
}) => (
  <React.Fragment>
    <Filters
      initData={initData}
      onResetFilters={onResetFilters}
      onApplyFilters={onApplyFilters}
    />
    <TableContainer head={<TableHeader />}>
      {data.map(
        element =>
          element.state ? (
            <CrudTableRow
              key={element.id}
              onEdit={() => onEdit(element)}
              onDelete={() => onDelete(element)}
            >
              <StyledTableCell>{element.id}</StyledTableCell>
              <StyledTableCell>{element.name}</StyledTableCell>
            </CrudTableRow>
          ) : (
            <RestoreTableRow
              key={element.id}
              onRestore={() => onRestore(element)}
            >
              <StyledTableCell>{element.id}</StyledTableCell>
              <StyledTableCell>{element.name}</StyledTableCell>
            </RestoreTableRow>
          )
      )}
    </TableContainer>
  </React.Fragment>
);

export default BaseTable;
