import React from 'react';
import DownloadIcon from "@material-ui/icons/GetApp";
import {IconButton, MenuItem} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import {useDispatch} from "react-redux";
import {downloadBillFile} from "../../../redux/thunks/Treasury/bills";
import {downloadOptions} from "./constants";

const DownloadMenu = ({bill}) => {
    const id = 'download-menu';
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = (fileType) => {
        dispatch(downloadBillFile({billId: bill.id, fileType}));
        setAnchorEl(null);
    }
    return (
        <>
            <IconButton aria-controls={id} aria-haspopup="true" onClick={handleClick}>
                <DownloadIcon />
            </IconButton>
            <Menu
                id={id}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleClose(downloadOptions.PDF)}>
                    {downloadOptions.PDF}
                </MenuItem>
                <MenuItem onClick={() => handleClose(downloadOptions.XML)}>
                    {downloadOptions.XML}
                </MenuItem>
                <MenuItem onClick={() => handleClose(downloadOptions.CDR)}>
                    {downloadOptions.CDR}
                </MenuItem>
            </Menu>
        </>
    );
};

export default DownloadMenu;