import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {getAllInsurancesTypes} from "../../../../../../../services/FleetManagement/Vehicles/InsuranceService";
import {fetchError} from "../../../../../../../redux/actions";

const TypeSelect = ({id, label, value, setValue}) => {
  const [ types, setTypes ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    getAllInsurancesTypes()
      .then(data => {
        setTypes(data);
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  }, []);
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
    >
      {types.map(d => (
        <MenuItem key={`type-${d.id}`} value={d.id}>
          {d.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TypeSelect;
