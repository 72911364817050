import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchALlReserves,
  setCurrentReserve
} from "../../../../../redux/actions/FleetManagement/Reserves";
import {Box, IconButton} from "@material-ui/core";
import TableContainer from "../../../../Common/Tables/TableContainer";
import PaginationFooter from "../../../../Common/Tables/PaginationFooter";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import Filters from "./Filters";
import TableHeader from "./TableHeader";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const ReservesTable = ({openEditDialog}) => {
  const dispatch = useDispatch();
  const {data, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.reserves
  );
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);
  React.useEffect(
    () => {
      dispatch(fetchALlReserves(filters));
    },
    [ filters ]
  );
  return (
    <Box display="flex" flexDirection="column">
      <Filters />
      <Box my={4}>
        <TableContainer
          head={<TableHeader />}
          footer={
            <PaginationFooter
              data={data}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
            />
          }
        >
          {(rowsPerPage > 0
            ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : data).map(reserve => (
            <StyledTableRow key={reserve.id}>
              <StyledTableCell>{reserve.plaque}</StyledTableCell>
              <StyledTableCell>{reserve.brand}</StyledTableCell>
              <StyledTableCell>{reserve.model}</StyledTableCell>
              <StyledTableCell>
                {reserve.startDate ? (
                  moment(reserve.startDate).format("DD/MM/YYYY")
                ) : (
                  ""
                )}
              </StyledTableCell>
              <StyledTableCell>
                {reserve.endDate ? (
                  moment(reserve.endDate).format("DD/MM/YYYY")
                ) : (
                  ""
                )}
              </StyledTableCell>
              <StyledTableCell>{reserve.driver}</StyledTableCell>
              <StyledTableCell>{reserve.turn}</StyledTableCell>
              <StyledTableCell>
                {reserve.state ? (
                  <IntlMessages id={"table.cell.active"} />
                ) : (
                  <IntlMessages id={"table.cell.inactive"} />
                )}
              </StyledTableCell>
              <StyledTableCell>
                <Box display="flex" flexDirection="row">
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        dispatch(setCurrentReserve(reserve));
                        openEditDialog && openEditDialog();
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Box>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ReservesTable;
