import React, {useEffect} from 'react';
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../../../../../hooks/Common/useForm";
import {addDaysToDate, parseDateToInput} from "../../../../../../../../utils/dates";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentReportFile} from "../../../../../../../../redux/actions/ControlPanel/Kpi";
import ContainedButton from "../../../../../../../Common/Buttons/ContainedButton";
import DriversPicker from "../../../../../../../Logistic/WorkOrders/WorkOrdersFilters/DriversPicker";
import VehiclesSelect from "../../../../../../../Logistic/Monitoring/FiltersDialog/VehiclesSelect";
import SendEmail from "../../../../Common/SendEmailDialog";
import SaveFilters from "../../../../Common/SaveFilters";
import DateFilters from "../../../../Common/DateFilters";


const initialData = {
    vehicles: [],
    drivers: [],
    period: 1,
    startDate: parseDateToInput(addDaysToDate(new Date(), -7)), // -7 dias
    endDate: parseDateToInput(new Date()),
};

const Form = ({service}) => {
    const id = 'over-truck-report-filters';
    const {formData, errors, setFormData, setValues, handleSubmit} = useForm(initialData);
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();


    useEffect(() => {
        current?.filters && setValues(current.filters);
    },[service]);

    const handleSubmitExcel = (data) => {
        dispatch(setCurrentReportFile(service, formData, 2));
    }

    return (
        <form id={id} onSubmit={handleSubmit(handleSubmitExcel)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} sm={4} lg={3}>
                    <VehiclesSelect
                        id="vehicle-picker-filter"
                        vehicles={formData.vehicles}
                        onChange={value => setFormData('vehicles', value)}
                        // errors={errors}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <DriversPicker
                        id="driver-picker-filter"
                        values={formData.drivers}
                        onChange={value => setFormData('drivers', value)}
                    />
                </Grid>
                <DateFilters
                    formData={formData}
                    setFormData={setFormData}
                />
                <Grid item xs={12} sm={4} lg={3}>
                    <ContainedButton
                        type='submit'
                        id={id}
                        text='Generar Excel'
                        color='primary'
                    />
                </Grid>
                <SendEmail filters={formData} />
                <SaveFilters filters={formData}/>
            </GridContainer>
        </form>
    );
};

export default Form;