import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  CustomTableContainer,
  DefaultStyledTableRow,
  StyledTablePagination
} from "./styles";
import CheckStockRow from "./CheckStockRow";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableFooter from "@material-ui/core/TableFooter";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import TableHeader from "./TableHeader";
import useTableSort from "../../../../../hooks/Common/useTableSort";
const CheckStockTable = ({
  orderGrouped,
  drivers,
  setSelectedAdvance,
  isGuardian = true
}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(drivers);
  const rows = drivers.length;
  const [ page, setPage ] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const data = [
    [ 1, "Producto1", "entidad1", "sede1", "almacen1", "viaje1" ],
    [ 2, "Producto2", "entidad5", "sede2", "almacen2", "viaje2" ],
    [ 3, "Producto1", "entidad4", "sede4", "almacen1", "viaje1" ],
    [ 7, "Producto1", "entidad1", "sede4", "almacen1", "viaje1" ],
    [ 6, "Producto1", "entidad2", "sede4", "almacen1", "viaje1" ],
    [ 4, "Producto3", "entidad2", "sede31", "almacen1", "viaje1" ]
  ];
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <CustomTableContainer component={Paper}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableHeader
            orderGrouped={orderGrouped}
            orderBy={orderBy}
            order={order}
            onSort={onSort}
            isGuardian={isGuardian}
          />
        </TableHead>
        <TableBody>
          <CheckStockRow
            orderGrouped={orderGrouped}
            driver={data}
            setSelectedAdvance={setSelectedAdvance}
            isGuardian={isGuardian}
          />

          {emptyRows > 0 && (
            <TableRow style={{height: 62 * emptyRows}}>
              <TableCell colSpan={9 + (isGuardian ? 0 : 1)} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <DefaultStyledTableRow>
            <StyledTablePagination
              align="center"
              style={{
                backgroundColor: "#8092e3",
                color: "white",
                border: "none",
                left: 0,
                bottom: 0,
                zIndex: 2
              }}
              rowsPerPageOptions={[ 5, 10, 15, {label: "All", value: -1} ]}
              colSpan={13 + (isGuardian ? 0 : 1)}
              count={rows}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {"aria-label": "rows per page"},
                native: true,
                style: {
                  backgroundColor: "#8092e3",
                  border: "solid",
                  borderRadius: "8px",
                  borderColor: "white"
                }
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={
                <IntlMessages
                  id={"guardian.advances.table.drivers.paginator"}
                />
              }
            />
          </DefaultStyledTableRow>
        </TableFooter>
      </Table>
    </CustomTableContainer>
  );
};

export default CheckStockTable;
