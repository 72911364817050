import {
    updateServiceArrivalDate,
    updateServiceTracking
} from "../../../../../services/Logistics/Distribution/ServicesListService";

export const operations = {
    originExit: {
        text: 'origin.departure',
        service: (request) => updateServiceTracking(request),
        isArrival: false,
        placeIdField: 'regionOriginId',
        id: 1,
    },
    destinationArrival: {
        text: 'destination.arrival',
        service: (request) => updateServiceTracking(request),
        isArrival: true,
        placeIdField: 'regionDestinationId',
        id: 2,
    },
    destinationExit: {
        text: 'destination.departure',
        service: (request) => updateServiceTracking(request),
        isArrival: false,
        placeIdField: 'regionDestinationId',
        id: 3,
    },
    originArrival: {
        text: 'origin.arrival',
        service: (request) => updateServiceArrivalDate(request),
        isArrival: true,
        placeIdField: 'regionOriginId',
    },
}
