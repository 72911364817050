import { v4 as uuidv4 } from 'uuid';
class TeltonikaPlot {
    constructor(id, imei, plotDate, receivedDate,
                priority, latitude, longitude, altitude,
                angle, sat, speed, event, extraData) {
        this.id = id;
        this.imei = imei;
        this.plotDate = plotDate;
        this.receivedDate = receivedDate;
        this.priority = priority;
        this.latitude = latitude;
        this.longitude = longitude;
        this.altitude = altitude;
        this.angle = angle;
        this.sat = sat;
        this.speed = speed;
        this.event = event;
        this.extraData = extraData;
    }

    static map = plot => {
        let extraData = {};
        plot.io_elements.forEach(elem => {
            const {io_id, value} = elem;
            extraData = {
                ...extraData,
                [io_id]: value
            };
        });
        return new TeltonikaPlot(
            uuidv4(),
            plot.imei,
            plot.time,
            plot.timearrived,
            plot.priority,
            plot.lat,
            plot.lng,
            plot.altitude,
            plot.angle,
            plot.sat,
            plot.speed,
            plot.io_event,
            extraData,
        );
    }
}

export default TeltonikaPlot;