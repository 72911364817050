import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleType from "../../../domain/FleetManagement/Vehicles/VehicleType";

export const getAllVehiclesTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(b => VehicleType.map(b));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addVehicleType = async type => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_tipo: type.name
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
