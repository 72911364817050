import {FETCH_EVENT_SELECT} from "redux/types/Administrator/EventSelect";

const INIT_STATE = {
  events: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_EVENT_SELECT:
      return {
        ...state,
        events: action.payload.data
      };
    default:
      return state;
  }
};
