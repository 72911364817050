import {mapToBoolean} from "../../../utils/mappers";

class SinisterVehicleState {
  constructor(id, description, status) {
    this.id = id;
    this.description = description;
    this.status = status;
  }

  static map = status => {
    return new SinisterVehicleState(
      status.nu_id_tipo_estado_siniestro_vehiculo,
      status.vc_desc_tipo_estado_siniestro_vehiculo,
      mapToBoolean(status.bi_estado)
    );
  };
}

export default SinisterVehicleState;
