import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.accidents.table.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.accidents.table.type"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.accidents.table.driver"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.accidents.table.vehicles.number"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
