import React from "react";
import DialogBase from "../../../Common/Dialogs/DialogBase";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Box, Button, Typography} from "@material-ui/core";
import moment from "moment";

const DeviceDetailsDialog = ({open = true, onClose, device}) => {
  return (
    <DialogBase
      open={open}
      close={() => onClose()}
      title={
        <IntlMessages id={"logistic.monitoring.device.detail.dialog.title"} />
      }
      actions={[
        <Button
          key={"device-close"}
          onClick={() => onClose()}
          style={{marginRight: "24px"}}
        >
          <IntlMessages id={"logistic.monitoring.device.detail.dialog.close"} />
        </Button>
      ]}
    >
      <Box display="flex" flexDirection="column" style={{gap: "20px"}}>
        <Typography variant="h3">{device.name}</Typography>
        <Typography variant="body2">
          <IntlMessages
            id={"logistic.monitoring.device.detail.dialog.last.update"}
          />{" "}
          {moment(device.lastUpdate).format("DD/MM/yyyy hh:mm:ss A")}
        </Typography>
        <Typography variant="body2">
          <IntlMessages
            id={"logistic.monitoring.device.detail.dialog.state"}
          />{" "}
          {device.details}
        </Typography>
      </Box>
    </DialogBase>
  );
};

export default DeviceDetailsDialog;
