import Operation from "./Operation";

class Submodule {
    constructor(id, description, state, operations) {
        this.id = id;
        this.description = description;
        this.state = state;
        this.operations = operations;
    }

    static map = submodule => {
        return new Submodule(
            Number(submodule.nu_id_sub_modulo),
            submodule.vc_desc_sub_modulo,
            submodule.bi_estado || true,
            submodule.operaciones?.map(o => Operation.map(o))
        );
    }
}

export default Submodule;