import React from "react";
import {useDispatch} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {addRefueling} from "../../../../../redux/actions/FleetManagement/Refueling";
import Form from "./Form";

const initData = {
  plaque: "",
  date: "",
  time: "",
  kilometer: "",
  driver: "",
  station: "",
  fuelType: "",
  gallons: "",
  price: "",
  amount: "",
  bill: ""
};

const AddRefuelingDialog = ({open, setOpen}) => {
  const id = "add-refueling-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addRefueling(data, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.refueling.add.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.refueling.add.save"} />}
      cancelText={<IntlMessages id={"fleet.management.refueling.add.cancel"} />}
    >
      <Form id={id} onSubmit={onSubmit} initialData={initData} />
    </FormDialog>
  );
};

export default AddRefuelingDialog;
