import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {editElevatorCategory} from "../../../../../../redux/actions/ElevatorsManagement/Categories";

const EditCategoryDialog = ({open, setOpen}) => {
    const {current} = useSelector(({elevatorsManagement}) => elevatorsManagement.categories);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(editElevatorCategory(current, data));
        setOpen(false);
    }
    return(
        <BaseDialog
            id='edit-category-form'
            title={<IntlMessages id={"elevators.management.elevators.category.type.edit.title"}/>}
            open={open}
            setOpen={setOpen}
            initData={{
                name: current?.name,
            }}
            onSubmit={onSubmit}
        />
    );
}

export default EditCategoryDialog;