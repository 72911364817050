import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {cloneDeep} from "lodash";
import {getWidgetData} from "../../../services/ControlPanel/Kpi/DashboardService";
import {v4 as uuidV4} from 'uuid';

export const getExceedingSpeedData = createAsyncThunk(
    'dashboards/getExceedingSpeedData',
    async ({filters, dashboard}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const results = await Promise.allSettled([
                getWidgetData({
                    id: 7,
                    ...filters,
                }),
                getWidgetData({
                    id: 8,
                    ...filters,
                }),
                getWidgetData({
                    id: 9,
                    ...filters,
                }),
                getWidgetData({
                    id: 10,
                    ...filters,
                }),
                getWidgetData({
                    id: 11,
                    ...filters,
                }),
            ]);
            let data = {
                events: [],
                eventsByDriver: [],
                eventsByVehicle: [],
                eventsByGravity: [],
                eventsByGroup: [],
                details: {
                    data: [],
                    rowCount: 0,
                },
            };
            const currentDashboard = cloneDeep(dashboard);
            results.forEach((result, index) => {
                switch (index) {
                    case 0: {
                        if(result.status === 'fulfilled') {
                            data.events = result.value.data;
                        }
                        break;
                    }
                    case 1: {
                        if(result.status === 'fulfilled') {
                            data.eventsByDriver = result.value.data;
                        }
                        break;
                    }
                    case 2: {
                        if(result.status === 'fulfilled') {
                            data.eventsByVehicle = result.value.data;
                        }
                        break;
                    }
                    case 3: {
                        if(result.status === 'fulfilled') {
                            data.eventsByGravity = result.value.data;
                        }
                        break;
                    }
                    case 4: {
                        if(result.status === 'fulfilled') {
                            data.eventsByGroup = result.value.data;
                        }
                        break;
                    }
                    default:
                        break;
                }

            });
            dispatch(fetchSuccess());
            currentDashboard.widgets = data;
            return {filters, currentDashboard};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const getExceedingSpeedTableData = createAsyncThunk(
    'dashboards/getExceedingSpeedTableData',
    async ({filters, page, pageSize}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const widget = await getWidgetData({
                id: 12,
                ...filters,
                page,
                pageSize,
            });
            dispatch(fetchSuccess());
            const details = widget.data.map(row => ({
                id: uuidV4(),
                vehicleId: row.idVehiculo,
                date: row.fechaInicio,
                plaque: row.Vehiculo,
                group: row.TipoVehiculo,
                gravity: row.Gravedad,
                address: row.Direccion,
                maxSpeed: row.VelocidadMaxima,
                exceededTime: row.TiempoExceso
            }));
            const rowCount = widget?.data[0]?.total ?? 0;
            return {rowCount, data: details};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)