import React from "react";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import MultipleSelect from "../../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {getAllChecklistTypesForSelect} from "../../../../../../../services/Logistics/Distribution/ChecklistTypeService";

const TypesPicker = ({value, onChange, error, hasError, disabled}) => {
  const {data: types} = useFetch(getAllChecklistTypesForSelect, {type: 0}, []);
  return (
    <MultipleSelect
      fullWidth
      label={
        <IntlMessages id={"fleet.management.vehicle.reports.table.type"} />
      }
      value={value}
      onChange={onChange}
      options={types}
      disabled={disabled}
      limitTags={2}
    />
  );
};

TypesPicker.propTypes = {};

export default TypesPicker;
