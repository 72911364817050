import axios from "axios";
import {getRefreshToken, getToken, resetUserData, setRefreshToken, setToken} from "../services/StorageService";
import {Auth} from "aws-amplify";

const tokenInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URI,
    headers: {
        "Content-Type": "application/json"
    }
});

export const getNewToken = async () => {
    try {
        const token = getToken();
        const refreshToken = getRefreshToken();
        const {data} = await tokenInstance.post(
            '/Entidad/ObtenerRefreshToken',
            {
                tokenExpirado: token,
                refreshToken: refreshToken
            },
        );
        setRefreshToken(data.refreshToken);
        setToken(data.token);
        return data.resultado;
    }catch (e) {
        console.log(e);
        await Auth.signOut();
        resetUserData();
        window.location.reload();
    }
}