import React, {useEffect, useState} from "react";
import useTableSort from "../../../../../hooks/Common/useTableSort";
import {Box, Checkbox, IconButton} from "@material-ui/core";
import ContainerWithHeader from "../../../../Common/Layouts/ContainerWithHeader";
import TableHeader from "./TableHeader";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import {parseNumberToMoney} from "../../../../../utils/parsers";
import ExpensesBaseTable from "../ExpensesBaseTable";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {getUserType} from "../../../../../services/StorageService";
import {
    deleteExpenseFromAccountingStarted,
    onDeleteMultipleAccountingExpenses
} from "../../../../../redux/actions/Guardians/Expenses";
import {downloadFile, getFileByName} from "../../../../../services/FileService";
import {getTypeFromName} from "../../../../../utils/files";
import EditIcon from "@material-ui/icons/Edit";
import {setAccountingExpense} from "../../../../../redux/actions/Guardians/Accounting";
import {useSelect} from "../../../../../hooks/Common/useSelect";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const ExpensesTable = () => {
  const {currentAccounting, accountingList} = useSelector(
    state => state.guardian
  );
  const {expenses} = currentAccounting;
  const {tableData, onSort, order, orderBy} = useTableSort(expenses);
  const [ total, setTotal ] = useState(0);
  const dispatch = useDispatch();
  const {onSelectAll, onSelectItem, selectedItems,
      isSelectedAll, itemIsSelected, thereIsSelected,
        onUnselectAll} = useSelect({items: tableData})
  useEffect(
    () => {
      let s = 0;
      if (expenses.length > 0) {
        expenses.forEach(exp => (s += exp.amount));
      }
      setTotal(s);
    },
    [ expenses ]
  );

  const onClickHandler = expense => {
    dispatch(
      deleteExpenseFromAccountingStarted(
        currentAccounting,
        expense,
        accountingList.filters
      )
    );
  };

  const onFileNameClick = name =>
    getFileByName(name).then(({base64File}) =>
      downloadFile(
        name,
        `data:image/${getTypeFromName(name)};base64,${base64File}`
      )
    );

  const handleOpenEditDialog = (expense) => {
      dispatch(setAccountingExpense(expense));
  }

  const onDeleteSelected = () => {
      dispatch(onDeleteMultipleAccountingExpenses({
          currentAccounting,
          selectedExpenses: selectedItems,
          filters: accountingList.filters
      }));
      onUnselectAll();
  }

  return (
      <>
          <Box
            style={{
                width: '90%',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end'
            }}
          >
             <ContainedButton
                 color='primary'
                 text={<IntlMessages id='delete' />}
                 onClick={onDeleteSelected}
                 disabled={!thereIsSelected}
             />
          </Box>
          <ContainerWithHeader title="guardian.accounting.dialog.expenses.title">
              <ExpensesBaseTable
                  header={
                      <TableHeader
                          onSort={onSort}
                          orderBy={orderBy}
                          order={order}
                          isSelectedAll={isSelectedAll}
                          onSelectAll={onSelectAll}
                      />
                  }
                  total={total}
                  cols={10}
              >
                  <React.Fragment>
                      {tableData.map(data => (
                          <StyledTableRow key={data.id}>
                              <StyledTableCell>
                                  <Checkbox
                                      color='primary'
                                      checked={itemIsSelected(data)}
                                      onChange={() => onSelectItem(data)}
                                  />
                              </StyledTableCell>
                              <StyledTableCell align="left">{data.ot}</StyledTableCell>
                              <StyledTableCell align="left">{data.executor}</StyledTableCell>
                              <StyledTableCell align="left">{data.costCenter}</StyledTableCell>
                              <StyledTableCell align="left">
                                  {data.date && moment(data.date).format("DD/MM/YYYY HH:mm")}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                  {data.expenseType.description}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                  {data.accountingAccount}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                  {data.documentType}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                  {data.documentNumber}
                              </StyledTableCell>
                              <StyledTableCell align="left">
                                  {parseNumberToMoney(data.amount)}
                              </StyledTableCell>
                              {getUserType().includes("administrator") && (
                                  <StyledTableCell align="left">
                                      <p
                                          style={{
                                              color: "blue",
                                              cursor: "pointer",
                                              textDecoration: "underline",
                                              width: "200px",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap"
                                          }}
                                          onClick={() => onFileNameClick(data.document)}
                                      >
                                          {data.document}
                                      </p>
                                  </StyledTableCell>
                              )}
                              {getUserType().includes("guardian") &&
                                  (currentAccounting.state.id === "1" ||
                                      currentAccounting.state.id === "4") && (
                                      <StyledTableCell align="left">
                                          <IconButton onClick={() => onClickHandler(data)}>
                                              <DeleteIcon />
                                          </IconButton>
                                          <IconButton
                                              onClick={() => handleOpenEditDialog(data)}
                                          >
                                              <EditIcon />
                                          </IconButton>
                                      </StyledTableCell>
                                  )}
                          </StyledTableRow>
                      ))}
                  </React.Fragment>
              </ExpensesBaseTable>
          </ContainerWithHeader>
      </>
  );
};

export default ExpensesTable;
