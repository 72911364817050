import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import Traceability from "../domain/Traceability";
import Response from "domain/Response";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

const trazabilidad = [
  {
    nu_id_warehouse_transfer: 1,
    nu_id_entity_origin: 1,
    vc_entity_name_origin: "San Fernando",
    nu_id_campus_origin: 1,
    vc_campus_name_origin: "Sede 1",
    nu_id_warehouse_origin: 1,
    vc_warehouse_name_origin: "Almacen 1",
    nu_id_entity_destiny: 1,
    vc_entity_name_destiny: "Entidad 1",
    nu_id_campus_destiny: 1,
    vc_campus_name_destiny: "Sede 2",
    nu_id_warehouse_destiny: 1,
    vc_warehouse_name_destiny: "Almacen 2",
    dt_date: "9/25/2021",
    bi_active: "1",
    ar_transfer: [
      {
        id: 1,
        product: "Producto 1",
        stock: 30,
        amount: 15
      }
    ]
  }
];

export const cancelPrevTraceabilityFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllTraceability = async filters => {
  try {
    const {data} = await axiosInstance.post(
      "/Inventario_Movimiento/trazabilidad_sel",
      {
        nu_id_entidad: getCompanyEntityId(),
        vc_destino: filters.entityDestiny,
        vc_desc_producto: filters.product,
        dt_fecha_inicio: filters.fromDate || "",
        dt_fecha_fin: filters.toDate || "",
        vc_origen: filters.entityOrigin,
        nu_id_tipo_movimiento: filters.type || null,
        vc_ot: filters.OT,
        nu_id_modo: filters.direction || null
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return data.map(traceability => Traceability.map(traceability));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
