export default [
    {
        version: "1.0.0",
        date: "09-05-2022",
        title: "Release Inicial",
        updates: ["Release inicial del TMS de 4GFlota"]
    },
    {
        version: "1.0.1",
        date: "27-05-2022",
        updates: [
            "Nueva funcionalidad control usuarios - roles",
            "Nueva funcionalidad carga de matrices",
            "Mejoras en Monitoreo / Tiempo Real",
            "Nueva funcionalidad Gestión de Ascensores"
        ]
    },
    {
        version: "1.0.2",
        date: "30-06-2022",
        updates: [
            "Nueva funcionalidad Tipos de Despachos",
            "Nueva funcionalidad Tipos de Retrasos",
            "Nueva funcionalidad Tipos de Devolución",
            "Nueva Funcionalidad Servicios en Linea",
            "Nueva Funcionalidad Tacógrafo",
            "Nueva Funcionalidad CheckList",
            "Informe Stock por Proveedor",
            "Informe Recepciones por Proveedor",
            "Gestión de usuarios no confirmados"
        ]
    },
    {
        version: "1.0.3",
        date: "30-08-2022",
        updates: [
            "Nueva Funcionalidad Ficha de Vehículos",
            "Nueva Funcionalidad Documentos de Vehículos",
            "Nueva Funcionalidad Documentos de Empleados",
            "Nueva Funcionalidad Alta Empleados",
            "Nueva Funcionalidad asignación Vehículos a roles.",
            "Mejoras en Informes",
            "Nueva Funcionalidad Tipo de despachos.",
            "Funcionalidad Móvil CheckList en OTs",
            "Funcionalidad Móvil Permisos por Usuarios",
            "Nueva Funcionalidad Tacógrafos",
            "Funcionalidad Móvil Chequeo RUC"
        ]
    },
    {
        version: "1.0.4",
        date: "13-10-2022",
        updates: [
            "Nueva Funcionalidad Preventivo de Vehículos",
            "Nueva Funcionalidad Correctivo de Vehículos",
            "Nueva Funcionalidad CheckList de Vehículos",
            "Nueva Funcionalidad Tipo Documentos de Vehículos",
            "Nueva Funcionalidad Tipo Documentos de Empleados",
            "Nueva Funcionalidad asignación Informes a roles.",
            "Mejoras en Tipo de Despachos",
            "Mejoras en Filtros de Distribución",
            "Nueva Funcionalidad Tipo de despachos.",
            "Funcionalidad Móvil CheckList Vehículos",
            "Funcionalidad Móvil Correctivo Vehículos",
            "Funcionalidad Móvil mejoras gestión Offline."
        ]
    },
    {
        version: "1.0.5",
        date: "04-11-2022",
        updates: [
            "Mejoras Funcionalidad Tacógrafo",
            "Informe Tacógrafos",
            "Nueva Funcionalidad Siniestro de Vehiculos",
            "Nueva Funcionalidad Mapa de Vehiculos",
            "Añadido opción multiidioma",
        ]
    },
    {
        version: "1.0.6",
        date: "12-12-2022",
        updates: [
            "Nueva Funcionalidad Multi Mapa",
            "Nueva Funcionalidad Manual de Ayuda",
            "Nueva Funcionalidad Identificación Empresa por Icono",
            "Mejora Funcionalidad Despachos (Eliminar y cambiar tipo despacho)",
            "Mejora Funcionalidad Tipo de Despachos",
            "Mejora Funcionalidad Taller y Solicitud de Taller",
            "Mejora Funcionalidad Filtro de Clientes",
        ]
    },
    {
        version: "1.0.7",
        date: "24-01-2023",
        updates: [
            "Mejora Funcionalidad Visualización de CheckList en Despachos.",
            "Mejora Funcionalidad Cambio de estado despacho desde la Web.",
            "Nueva Funcionalidad Bloques Tags y Productos en Despachos.",
            "Nueva Funcionalidad Filtrado de despachos dentro de la OT.",
            "Nueva Funcionalidad Asignación Masiva Permisos",
            "Funcionalidad Móvil Busqueda de Despachos basado en QR"
        ],
    },
    {
        version: "1.0.8",
        date: "11-02-2023",
        updates: [
           "Nueva Funcionalidad Asignación Masiva Operarios en OT.",
           "Nueva Funcionalidad Búsqueda por Cliente o Despacho en OT.",
           "Nueva Funcionalidad Visualización de albaranes en OTs.",
           "Nueva Funcionalidad Búsqueda despacho por Nº Referencia.",
           "Mejora Funcionalidad Caja Chica.",
           "Nueva Funcionalidad App Movil Visualización de OTs de otros días.",
           "Nueva Funcionalidad App Movil Visualización de Fichas en OTs.",
           "Nueva Funcionalidad App Movil Visualización de Búsqueda despacho por Nº Referencia.",
           "Nueva Funcionalidad App Movil Gestión de Albaranes.",
           "Nueva Funcionalidad App Movil Obtener Vehículos cercanos.",
        ],
    },
    {
        version: "1.0.9",
        date: "23-03-2023",
        updates: [
           "Mejora Funcionalidad Optimización Carga Servicios",
           "Nueva Funcionalidad Informes: Programación, Favoritos, Archivados",
           "Nueva Funcionalidad Informes Envíos a email",
           "Funcionalidad Móvil Crear Usuarios",
           "Funcionalidad Móvil Recuperar Contraseña",
           "Funcionalidad Móvil Optimización Carga Servicios",
           "Funcionalidad Móvil Ampliación Información en Fichas en OTs."
        ],
    },
    {
        version: "1.0.10",
        date: "22-05-2023",
        updates: [
            "Nueva Funcionalidad BackOffice",
            "Nueva Funcionalidad Gestion Token",
            "Mejora Funcionalidad Tráfico Tacógrafo.",
            "Mejora Funcionalidad Caja Chica.",
            "Mejora Funcionalidad Desglose OT",
            "Nueva Funcionalidad Sync App Movil .",
            "Mejora Funcionalidad App Movil Distribución",
        ]
    },
    {
        version: "1.0.11",
        date: "03-07-2023",
        updates: [
            "Nueva Funcionalidad Gestión Acceso",
            "Mejora Funcionalidad Caja Chica Gestión Administrador.",
            "Mejora Funcionalidad Gestión Servicios Backend",
            "Nueva Funcionalidad App Movil Copiloto Virtual.",
            "Mejora Funcionalidad App Movil Inventario",
            "Mejora Funcionalidad App Movil Caja Chica",
            "Mejora Funcionalidad App Movil Distribución",
        ]
    },
    {
        version: "1.0.12",
        date: "10-08-2023",
        updates: [
            "Nueva Funcionalidad DashBoard Exceso de Velocidad",
            "Nueva Funcionalidad Eliminación de OTs en Distribución",
            "Nueva Funcionalidad Opciones de Marcado en CheckList",
            "Mejora Funcionalidad Actualizar Cliente en Despachos",
            "Mejora Funcionalidad Ordenación en Distribución",
            "Mejora Funcionalidad Totales en Anticipos",
            "Mejora Funcionalidad Ampliación - Reducción Anticipos",
            "Mejora Funcionalidad App Móvil Gestión Diferido",
            "Mejora Funcionalidad App Móvil Optimización Carga Inventario",
        ]
    },
    {
        version: "1.0.13",
        date: "05-12-2023",
        updates: [
            "Nueva Funcionalidad DashBoard Documentos Vehiculos y Conductores",
            "Nueva Funcionalidad DashBoard EcoConducción",
            "Nueva Funcionalidad Tarjetas de Tacógrafo",
            "Nueva Funcionalidad Desglose de Ficheros de Tacógrafo",
            "Nueva Funcionalidad Caja Chica - Centro de Costo",
            "Mejora Funcionalidad Caja Chica Eliminación Masiva Gastos",
        ]
    },
    {
        version: "1.0.14",
        date: "01-02-2024",
        updates: [
            "Nueva Funcionalidad Control de Conducción",
            "Mejora Funcionalidad Ordenes de Trabajo",
            "Mejora Funcionalidad Tacografo",
        ]
    },
    {
        version: "1.0.15",
        date: "01-03-2024",
        updates: [
            "Nueva Funcionalidad Inpuestos y Servicios",
            "Nueva Funcionalidad Facturación: Presupuestos y Facturas",
            "Nueva Funcionalidad Integración Informes Flotas",
            "Mejora Funcionalidad Ordenes de Trabajo.",
            "Mejora Funcionalidad Tipos de Despacho.",
            "Mejora Funcionalidad Control de Conducción.",
            "Mejora Funcionalidad Tipos de Documentos.",
            "Mejora Funcionalidad Tacógrafo",
        ]
    }
];
