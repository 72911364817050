import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {TableHead} from "@material-ui/core";

const TableHeader = () => (
  <TableHead>
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id={"logistic.assigment.vehicles.table.vehicle"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"logistic.assigment.vehicles.table.capacity"} />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  </TableHead>
);

export default TableHeader;
