import React from 'react';
import Map from "../Map";
import {Marker, useMapEvents} from "react-leaflet";

const ClickedMarker = ({latitude, longitude, setPosition}) => {
    const map = useMapEvents({
        click(event) {
            const {lat, lng} = event.latlng;
            setPosition({latitude: lat, longitude: lng});
            map.setZoom(18);
            map.setView([lat, lng]);
        }
    })
    return (
        <Marker position={[latitude, longitude]}/>

    )
}

const MarkerMap = ({position, setPosition}) => {
    return (
        <Map
            latitude={position.latitude}
            longitude={position.longitude}
        >
            <ClickedMarker
                latitude={position.latitude}
                longitude={position.longitude}
                setPosition={setPosition}
            />
        </Map>
    );
};

export default MarkerMap;