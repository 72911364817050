import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId, setToken} from "../StorageService";
import Module from "../../domain/Configuration/Module";
import Response from "../../domain/Response";

export const fetchAllOrganizationPermissions = async organizationId => {
  try {
    const {data} = await axiosInstance.post("/Usuario/sel_permisos_empresa", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_cliente: organizationId
    });
    return data.modulos.map(m => Module.map(m));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateOrganizationOperation = async (
  organizationId,
  operation
) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Usuario/upd_permiso_empresa_estado", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_cliente: organizationId,
      nu_id_operacion: operation.id,
      bi_estado: operation.state
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateMassiveOrganizationsOperations = async (
    organizations,
    operations,
    option
) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Usuario/up_permiso_empresa_global", {
      nu_id_entidad: getCompanyEntityId(),
      "ls_empresas": organizations.map(o => o.id),
      "ls_operaciones": operations.map(o => o.id),
      bi_estado: option === 'add'
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
