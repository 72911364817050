class SelectEmployee {
  constructor(id, name, doi) {
    this.id = id;
    this.name = name;
    this.doi = doi;
  }

  static map = employee => {
    return new SelectEmployee(
      employee.nu_id_entidad_empleado,
      employee.vc_nombre,
        employee.vc_nif,
    );
  };
}

export default SelectEmployee;
