import {combineReducers} from "redux";

import CashManagerEntities from "./CashManagerEntities";
import Currencies from "./Currencies";
import Entities from "./Entities";
import Tags from "./Tags";

export default combineReducers({
  currencies: Currencies,
  cashManagerEntities: CashManagerEntities,
  tags: Tags,
  entities: Entities
});
