class Warehouse {
  constructor(
    id,
    name,
    idCampus,
    campusName,
    allowNegativeStock,
    highDate,
    lowDate,
    active
  ) {
    this.id = id;
    this.name = name;
    this.idCampus = idCampus;
    this.campusName = campusName;
    this.allowNegativeStock = allowNegativeStock;
    this.highDate = highDate;
    this.lowDate = lowDate;
    this.active = active;
  }

  static map = warehouse => {
    return new Warehouse(
      warehouse.nu_id_almacen,
      warehouse.vc_desc_almacen,
      warehouse.nu_id_entidad_sede,
      warehouse.vc_nombre_entidad_sede,
      warehouse.bi_stock_negativo,
      warehouse.dt_high_date,
      warehouse.dt_low_date,
      warehouse.bi_estado
    );
  };
}

export default Warehouse;
