import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import TagsPicker from "../../../../../../containers/Administrator/TagsPicker";
import ReceiversAutocomplete from "../../../../../../containers/Administrator/ReceiversAutocomplete";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import InputLabel from "@material-ui/core/InputLabel";
import {FormHelperText, IconButton, InputAdornment} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import useUiState from "../../../../../../hooks/Administrator/useUiState";
import validations from "./validations";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const initData = {
  receiver: null,
  tags: [],
  code: ""
};

const Form = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initData,
    validations
  );
  const ui = useUiState();
  const [ showPassword, setShowPassword ] = React.useState(false);
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <ReceiversAutocomplete
            value={formData.receiver}
            hasError={errors.receiver.length > 0}
            error={errors.receiver[0]}
            onChange={value => setFormData("receiver", value)}
          />
        </Grid>
        <Grid item xs={12} style={{margin: "10px 0"}}>
          <TagsPicker
            id="administrator-create-receivers-tags-picker"
            values={formData.tags}
            hasError={errors.tags.length > 0}
            error={errors.tags[0]}
            onChange={value => setFormData("tags", value)}
          />
        </Grid>
        <Grid item xs={12} style={{margin: "10px 0"}}>
          <FormControl
            error={errors.code.length > 0}
            variant="outlined"
            size="small"
            fullWidth
          >
            <OutlinedInput
              value={formData.code}
              id="receiver-code"
              label={
                <IntlMessages
                  id={"administrator.logistic.receivers.form.code"}
                />
              }
              onChange={e => {
                setFormData("code", e);
              }}
              disabled={ui.showLoadingIndicator}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="password-visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={event => event.preventDefault()}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText>{errors.code[0]}</FormHelperText>
          </FormControl>
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
