import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

export default {
  box: [
    {
      rule: v => v?.trim() !== "",
      message: <IntlMessages id="guardian.add.advance.form.box.error.empty" />
    }
  ],
  travel: [
    {
      rule: v => v?.toString()?.trim() !== "",
      message: (
        <IntlMessages id="guardian.add.advance.form.advance.error.empty" />
      )
    }
  ],
  advanceType: [
    {
      rule: v => v?.trim() !== "",
      message: (
        <IntlMessages id="guardian.add.advance.form.advance.type.error.empty" />
      )
    }
  ],
  driver: [
    {
      rule: v => v?.id?.toString().trim() !== "",
      message: (
        <IntlMessages id="guardian.add.advance.form.driver.error.empty" />
      )
    }
  ],
  amount: [
    {
      rule: v => v?.trim() !== "",
      message: (
        <IntlMessages id="guardian.add.advance.form.amount.error.empty" />
      )
    },
    {
      rule: v => !isNaN(v),
      message: (
        <IntlMessages id="guardian.add.advance.form.amount.error.number" />
      )
    }
  ]
};
