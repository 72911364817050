import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {
  CustomTableContainer,
  DefaultStyledTableRow,
  StyledTablePagination
} from "./styles";
import AdvancesRow from "./AdvancesRow";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableFooter from "@material-ui/core/TableFooter";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import TableHeader from "./TableHeader";
import useTableSort from "../../../../hooks/Common/useTableSort";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import {useSelector} from "react-redux";
import {parseNumberToMoney} from "../../../../utils/parsers";

const AdvancesTable = ({drivers, setSelectedAdvance, isGuardian = true}) => {
  const {list} = useSelector(state => state.guardian.advancesList);
  const {tableData, onSort, order, orderBy} = useTableSort(drivers);
  const rows = drivers.length;
  const [ page, setPage ] = React.useState(0);
  const [ rowsPerPage, setRowsPerPage ] = React.useState(15);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows - page * rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <CustomTableContainer component={Paper}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableHeader
            orderBy={orderBy}
            order={order}
            onSort={onSort}
            isGuardian={isGuardian}
          />
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? tableData.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : tableData).map(driver => (
            <AdvancesRow
              key={driver.id}
              driver={driver}
              setSelectedAdvance={setSelectedAdvance}
              isGuardian={isGuardian}
            />
          ))}
          {emptyRows > 0 && (
            <TableRow style={{height: 62 * emptyRows}}>
              <TableCell colSpan={9 + (isGuardian ? 0 : 1)} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            <StyledTableCell colSpan={9} />
            <StyledTableCell>
              {parseNumberToMoney(list?.reduce((acc, v) => acc + v.advanceAmount, 0))}
            </StyledTableCell>
            <StyledTableCell>
              {parseNumberToMoney(list?.reduce((acc, v) => acc + v.extension, 0))}
            </StyledTableCell>
            <StyledTableCell>
              {parseNumberToMoney(list?.reduce((acc, v) => acc + v.refund, 0))}
            </StyledTableCell>
            <StyledTableCell>
              {parseNumberToMoney(list?.reduce((acc, v) => acc + v.expense, 0))}
            </StyledTableCell>
            <StyledTableCell>
              {parseNumberToMoney(list?.reduce((acc, v) => acc + v.passed, 0))}
            </StyledTableCell>
            <StyledTableCell>
              {parseNumberToMoney(list?.reduce((acc, v) => acc + v.balance, 0))}
            </StyledTableCell>
            <StyledTableCell colSpan={2} />
          </StyledTableRow>
          <DefaultStyledTableRow>
            <StyledTablePagination
              align="center"
              style={{
                backgroundColor: "#8092e3",
                color: "white",
                border: "none",
                left: 0,
                bottom: 0,
                zIndex: 2
              }}
              rowsPerPageOptions={[ 5, 10, 15, {label: "All", value: -1} ]}
              colSpan={16 + (isGuardian ? 0 : 1)}
              count={rows}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {"aria-label": "rows per page"},
                native: true,
                style: {
                  backgroundColor: "#8092e3",
                  border: "solid",
                  borderRadius: "8px",
                  borderColor: "white"
                }
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={
                <IntlMessages
                  id={"guardian.advances.table.drivers.paginator"}
                />
              }
            />
          </DefaultStyledTableRow>
        </TableFooter>
      </Table>
    </CustomTableContainer>
  );
};

export default AdvancesTable;
