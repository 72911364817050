import React, {useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';

import selectCampus from '../../../redux/actions/Administrator/WarehouseCampusSelect';
import {getCompanyEntityId} from "../../../services/StorageService";

const WarehouseCampusSelect = ({id, label, hasError, error, value, onChange}) => {
    const {data} = useSelector(state => state.administrator.warehouseCampus);

    const dispatch = useDispatch();
    useEffect(() => {
        if(data.length === 0){
            dispatch(selectCampus.get(getCompanyEntityId()));
        }
    },[])
    const options = [
        {value: '', label: 'Seleccione una opción'},
        ...data.filter(type => type.active !== 0).map(type => ({value: type.id, label: type.campus})),
    ];

    return(
        <Select 
            value={value?.value || ''}
            error={hasError}
            errorMessage={error}
            fullWidth
            id={id}
            label={label}
            onChange={e => onChange(options.find(option => option.value === e.target.value))}
            options={options}
        />
    );
};

export default WarehouseCampusSelect