import React from "react";
import Confirmation from "../../../../Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const RestoreConfirmation = ({open, onAccept, onReject}) => (
  <Confirmation
    open={open}
    onAccept={onAccept}
    onReject={onReject}
    text={
      <IntlMessages id="administrator.logistic.receivers.restore.confirmation.text" />
    }
  />
);

export default RestoreConfirmation;
