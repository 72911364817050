import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import CashManagersByCurrency from "./CashManagersByCurrency";
import CashManagers from "./CashManagers";

import {groupBy} from "utils/arrays";

const Table = ({data, onEdit, onDelete, onRestore}) => {
  const byCurrencies = groupBy(data, "currency");
  const byCurrenciesArray = Object.keys(byCurrencies).map(currency => ({
    currency,
    guardians: byCurrencies[currency]
  }));

  if (byCurrenciesArray.length === 1) {
    const guardiansData = byCurrenciesArray[0].guardians;
    const currency = byCurrenciesArray[0].currency;
    return (
      <React.Fragment>
        <Typography variant="h3" style={{marginBottom: 20}}>
          <IntlMessages id="administrator.cashManagers.table.title.currency" />:{" "}
          {currency}
        </Typography>
        <CashManagers
          data={guardiansData}
          onEdit={onEdit}
          onDelete={onDelete}
          onRestore={onRestore}
        />
      </React.Fragment>
    );
  }

  return (
    <CashManagersByCurrency
      data={byCurrenciesArray}
      onEdit={onEdit}
      onDelete={onDelete}
      onRestore={onRestore}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {}
};

export default Table;
