import React from "react";
import {CustomizedDialog, useStyles} from "./styles";
import {
  AppBar,
  IconButton,
  Slide,
  Toolbar,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="up" ref={ref} {...props} />;
});

const SideScreenDialog = ({
  open,
  handleClose,
  title,
  children,
  disableEnforceFocus
}) => {
  const classes = useStyles();
  return (
    <CustomizedDialog
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h1" className={classes.title}>
            <IntlMessages id={title} />
          </Typography>
        </Toolbar>
      </AppBar>
      {children}
    </CustomizedDialog>
  );
};

export default SideScreenDialog;
