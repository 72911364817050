import React from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import DialogBase from "../../../../Common/Dialogs/DialogBase";
import {Box, Button} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Filters from "./Filters";
import OrdersTable from "./OrdersTable";
import useForm from "../../../../../hooks/Common/useForm";
import {fetchAssigmentOrdersStarted} from "../../../../../redux/actions/Logistic/Assigment";

const initData = {
  client: "",
  state: "",
  type: "",
  fromDate: "",
  toDate: ""
};

const OrdersDialog = ({selectedOrders, setSelectedOrders, open, setOpen}) => {
  const dispatch = useDispatch();
  const {orders} = useSelector(state => state.logistic.assigment);
  const {formData, setFormData, resetForm} = useForm(initData);
  const [ ordersData, setOrdersData ] = React.useState([]);
  React.useEffect(
    () => {
      setOrdersData(orders.map(order => ({...order, checked: false})));
    },
    [ orders ]
  );
  React.useEffect(() => {
    dispatch(fetchAssigmentOrdersStarted(formData));
  }, []);
  const actions = [
    <Button
      key={"orders-cancel"}
      onClick={() => {
        resetForm();
        setOpen(false);
        setSelectedOrders([ ...selectedOrders ]);
      }}
    >
      <IntlMessages id={"logistic.assigment.orders.dialog.cancel"} />
    </Button>,
    <Button
      key={"orders-ok"}
      onClick={() => {
        setOpen(false);
        setSelectedOrders(
          orders.filter(order =>
            ordersData.some(o => o.id === order.id && o.checked)
          )
        );
      }}
    >
      <IntlMessages id={"logistic.assigment.orders.dialog.ok"} />
    </Button>
  ];

  const applyFilters = () => {
    dispatch(fetchAssigmentOrdersStarted(formData));
  };

  return (
    <DialogBase
      open={open}
      close={() => {
        setOpen(false);
        resetForm();
      }}
      title={<IntlMessages id={"logistic.assigment.orders.dialog.title"} />}
      actions={actions}
    >
      <Box>
        <Filters
          formData={formData}
          setFormData={setFormData}
          resetForm={resetForm}
          applyFilters={() => applyFilters()}
        />
        <OrdersTable
          isDialog
          ordersData={ordersData}
          setOrdersData={setOrdersData}
        />
      </Box>
    </DialogBase>
  );
};

export default OrdersDialog;
