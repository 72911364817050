import React from 'react';

import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import TagsPicker from 'containers/Administrator/TagsPicker';
import useForm from 'hooks/Common/useForm';

import validations from './validations';

const Form = ({ id, initialData, onSubmit }) => {
  const { formData, setFormData, errors, handleSubmit } = useForm(
    {
      tags: initialData?.tags || [],
    },
    validations,
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} style={{ margin: '10px 0' }}>
          <TagsPicker
            id="administrator-edit-supervisors-tags-picker"
            values={formData.tags}
            hasError={errors.tags.length > 0}
            error={errors.tags[0]}
            onChange={value => setFormData('tags', value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
