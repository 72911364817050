import React from "react";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import Checkbox from "../../../../../Common/Forms/Checkbox";
import moment from "moment";
import {IconButton} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import {minutesToTime} from "../../../../../../utils/parsers";

const OrdersTable = ({ordersData, setOrdersData, isDialog = false}) => {
  return (
    <TableContainer head={<TableHeader isDialog={isDialog} />}>
      {ordersData.map(order => (
        <StyledTableRow key={order.id}>
          {!isDialog && (
            <StyledTableCell>
              <GpsFixedIcon style={{color: order.color}} />
            </StyledTableCell>
          )}
          <StyledTableCell>{order.client}</StyledTableCell>
          <StyledTableCell>
            {moment(order.startDate).format("DD/MM/yyyy HH:mm:ss")}
          </StyledTableCell>
          <StyledTableCell>
            {moment(order.endDate).format("DD/MM/yyyy HH:mm:ss")}
          </StyledTableCell>
          <StyledTableCell>{minutesToTime(order.stay)}</StyledTableCell>
          <StyledTableCell>{order.priority}</StyledTableCell>
          <StyledTableCell>
            {isDialog ? (
              <Checkbox
                checked={order.checked}
                onChange={() => {
                  setOrdersData &&
                    setOrdersData(
                      ordersData.map(o => {
                        if (o.id === order.id) {
                          return {...o, checked: !o.checked};
                        } else {
                          return {...o};
                        }
                      })
                    );
                }}
              />
            ) : (
              <IconButton
                onClick={() => {
                  setOrdersData &&
                    setOrdersData(ordersData.filter(o => order.id !== o.id));
                }}
              >
                <DeleteForeverIcon />
              </IconButton>
            )}
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};

export default OrdersTable;
