import PendingStates from "../../../../models/Guardian/PendingStates";
import moment from "moment";

class AdvancesFilterBuilder {
  constructor(drivers) {
    this.drivers = [ ...drivers ];
  }

  byPendingState(state) {
    const filteredData = [];
    if (state === PendingStates.ALL) {
      return new AdvancesFilterBuilder([ ...this.drivers ]);
    }
    this.drivers.forEach(driver => {
      const filteredDriver = {
        ...driver,
        advances: driver.advances.filter(advance => {
          return advance.state === state;
        })
      };
      if (filteredDriver.advances.length > 0) {
        filteredData.push(filteredDriver);
      }
    });
    return new AdvancesFilterBuilder(filteredData);
  }

  byFieldIncludes(field, value) {
    const filteredData = [];
    this.drivers.forEach(driver => {
      const filteredDriver = {
        ...driver,
        advances: driver.advances.filter(advance => {
          if (advance[field] === undefined) {
            throw new Error("Field parameter must be a valid property");
          }
          if (typeof advance[field] !== "string") {
            throw new Error("Field value must be a string");
          }
          return advance[field].toLowerCase().includes(value.toLowerCase());
        })
      };
      if (filteredDriver.advances.length > 0) {
        filteredData.push(filteredDriver);
      }
    });
    return new AdvancesFilterBuilder(filteredData);
  }

  byNameIncludes(value) {
    const filteredData = this.drivers.filter(driver => {
      return driver.name.toLowerCase().includes(value.toLowerCase());
    });
    return new AdvancesFilterBuilder(filteredData);
  }

  byIncludesDates(lowerDate, upperDate) {
    const filteredData = [];
    if (!lowerDate && !upperDate) {
      return new AdvancesFilterBuilder([ ...this.drivers ]);
    }
    this.drivers.forEach(driver => {
      const filteredDriver = {
        ...driver,
        advances: driver.advances.filter(advance => {
          const lastFormat = moment(advance.lastUpdate).format();
          const creationFormat = moment(advance.createDate).format();
          if (lowerDate && upperDate) {
            return (
              (moment(lastFormat).isSameOrAfter(lowerDate) &&
                moment(lastFormat).isSameOrBefore(upperDate)) ||
              (moment(creationFormat).isSameOrAfter(lowerDate) &&
                moment(creationFormat).isSameOrBefore(upperDate))
            );
          } else if (lowerDate) {
            return (
              moment(lastFormat).isSameOrAfter(lowerDate) ||
              moment(creationFormat).isSameOrAfter(lowerDate)
            );
          }
          return (
            moment(lastFormat).isSameOrBefore(upperDate) ||
            moment(creationFormat).isSameOrBefore(upperDate)
          );
        })
      };
      if (filteredDriver.advances.length > 0) {
        filteredData.push(filteredDriver);
      }
    });
    return new AdvancesFilterBuilder(filteredData);
  }

  build() {
    return this.drivers;
  }
}

export default AdvancesFilterBuilder;
