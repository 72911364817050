import Headquarter from "./Headquarter";

class Client {
  constructor(id, clientCode, name, businessName, country, code, email) {
    this.id = id;
    this.clientCode = clientCode;
    this.name = name;
    this.businessName = businessName;
    this.country = country;
    this.code = code;
    this.email = email;
    this.headquarters = [];
  }

  static map = client => {
    return new Client(
      client.nu_id_entidad,
      client.vc_cod_entidad,
      client.vc_nombre,
      client.vc_nombre_comercial,
      client.vc_cif_pais,
      client.vc_cif_codigo,
      client.vc_email
    );
  };
}

export default Client;
