import React, {useEffect} from "react";
import {fetchAllEmployeesByEntity} from "../../../../services/EmployeeService";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../Common/Forms/Select/MultipleSelect";

const DriversPicker = ({values, onChange}) => {
  const [ drivers, setDrivers ] = React.useState([]);
  useEffect(() => {
    fetchAllEmployeesByEntity().then(employees => {
      setDrivers([ ...employees ]);
    });
  }, []);
  return (
    <MultipleSelect
      id={"work-orders-drivers-picker"}
      options={drivers}
      value={values}
      onChange={onChange}
      fullWidth
      label={<IntlMessages id={"logistic.work.orders.table.drivers"} />}
    />
  );
};

export default DriversPicker;
