import ui from "./ui";
import {
  cancelPrevWarehouseDestinySelectFetchAll,
  fetchAllWarehouseDestinySelect
} from "../../../services/WarehouseDestinySelectService";
import {FETCH_WAREHOUSE_DESTINY} from "../../types/Administrator/WarehouseDestinySelect";
import {restoreAdministratorState} from "./Common";

export const resetState = () => dispatch => {
  cancelPrevWarehouseDestinySelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchWarehouseDestinySelectSucceeded = data => ({
  type: FETCH_WAREHOUSE_DESTINY,
  payload: {
    data
  }
});

export const fetchDataWarehouseDestiny = (idEntity, idCampus) => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevWarehouseDestinySelectFetchAll();
    fetchAllWarehouseDestinySelect(idEntity, idCampus).then(warehouseSelect => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchWarehouseDestinySelectSucceeded(warehouseSelect));
    });
  };
};

export default {
  get: fetchDataWarehouseDestiny,
  resetState
};
