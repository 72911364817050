export const ADMINISTRATOR_FETCH_PETTY_CASH_TYPES_STARTED =
  "ADMINISTRATOR_FETCH_PETTY_CASH_TYPES_STARTED";
export const ADMINISTRATOR_FETCH_PETTY_CASH_TYPES_SUCCEEDED =
  "ADMINISTRATOR_FETCH_PETTY_CASH_TYPES_SUCCEEDED";
export const FETCH_PETTY_CASH_TYPES_SUCCEEDED =
  "FETCH_PETTY_CASH_TYPES_SUCCEEDED";
export const APPLY_PETTY_CASH_TYPES_FILTERS = "APPLY_PETTY_CASH_TYPES_FILTERS";
export const RESET_PETTY_CASH_TYPES_FILTERS = "RESET_PETTY_CASH_TYPES_FILTERS";
export const PETTY_CASH_TYPES_CHANGE_STATE = "PETTY_CASH_TYPES_CHANGE_STATE";
export const PETTY_CASH_TYPES_EDIT = "PETTY_CASH_TYPES_EDIT";
export const PETTY_CASH_TYPES_CREATE = "PETTY_CASH_TYPES_CREATE";
