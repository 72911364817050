class VehicleType {
  constructor(id, name) {
    this.id = id;
    this.name = name;
  }

  static map = type => {
    return new VehicleType(type.nu_id_tipo_vehiculo, type.vc_tipo);
  };
}

export default VehicleType;
