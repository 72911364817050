import axiosInstance from "../utils/axiosInstance";
import {
  getCompanyEntityId,
  getUserId,
  setCompanyEntityId, setRefreshToken,
  setToken,
  setUserData,
  setUserPermissions,
  setUserType
} from "./StorageService";
import UserEntity from "../domain/UserEntity";
import Module from "../domain/Configuration/Module";
import Operation from "../domain/Configuration/Operation";
import {Auth} from "aws-amplify";

export const getUserEntityByEmail = async email => {
  try {
    const user = await axiosInstance.post("/Entidad/get_entidad_for_email", {
      vc_email: email
    });

    const userEntity = UserEntity.map(user.data);
    setUserData(userEntity);
    setCompanyEntityId(user.data.nu_id_empresa_seleccionada);
    setUserType(userEntity.type);
    setUserPermissions(userEntity.permissions);
    setToken(userEntity.token);
    setRefreshToken(userEntity.refreshToken);
    return userEntity;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserTypeByUserId = async () => {
  try {
    const type = await axiosInstance.post(
      "/Entidad/get_tipo_entidad_empleado",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_entidad_empleado: getUserId()
      }
    );
    return UserEntity.mapType(type.data);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserPermissionsByUserIdAndCompany = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession?.accessToken.jwtToken;
    const {data} = await axiosInstance.post("/Entidad/sel_permisos_entidad", {
      nu_id_empresa: getCompanyEntityId(),
      vc_token: token
    });
    setToken(data.vc_token);
    setRefreshToken(data.vc_refresh_token);
    return data.modulos.map(u => Module.map(u));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getUserSubmoduleOperations = async ({module, submodule}) => {
  try {
    const {
      data
    } = await axiosInstance.post("/Entidad/sel_operaciones_sub_modulo", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_empleado: getUserId(),
      nu_id_modulo: module,
      nu_id_sub_modulo: submodule
    });
    let list = {};
    data.map(u => Operation.map(u)).map(u => {
      list[u.id] = u;
      return null;
    });

    return list;
  } catch (e) {
    return Promise.reject(e);
  }
};
