import React from 'react';
import {Box} from "@material-ui/core";
import {useStyles} from "./styles";
import Sidebar from "./Sidebar";
import WorkOrdersMap from "./WorkOrdersMap";
import DestinationsMap from "./DestinationsMap";

const DispatchesMap = () => {
    const classes = useStyles();
    const [selectedDestinations, setSelectedDestinations] = React.useState([]);
    const [selectedWorkOrder, setSelectedWorkOrder] = React.useState(null);
    const [value, setValue] = React.useState(1);
    const onSelectDestination = destination => {
        if(selectedDestinations.length > 0 && selectedDestinations?.some(d => d.id === destination.id)) {
            setSelectedDestinations(selectedDestinations.filter(d => d.id !== destination.id));
        }else {
            setSelectedDestinations([...selectedDestinations, destination]);
        }
    }
    const onSelectWorkOrder = workOrder => {
        setSelectedWorkOrder(workOrder);
    }
    const onSelectAllDestinations = (destinations, selectAll) => {
        if(selectAll) {
            setSelectedDestinations([...destinations]);
        }else {
            setSelectedDestinations([]);
        }
    }

    return(
        <Box className={classes.inBuildAppContainer}>
            <Sidebar
                selectedWorkOrder={selectedWorkOrder}
                onSelectWorkOrder={onSelectWorkOrder}
                selectedDestinations={selectedDestinations}
                onSelectDestination={onSelectDestination}
                value={value}
                setValue={setValue}
                onSelectAll={onSelectAllDestinations}
            />
            {value === 1?
                <WorkOrdersMap
                    destinations={selectedWorkOrder?.destinations || []}
                />:
                <DestinationsMap
                    selectedDestinations={selectedDestinations}
                />
            }
        </Box>
    );
}

export default DispatchesMap;