import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  cancelPrevStockFetchAll,
  fetchAllStock
} from "../../../services/StockService";

import {
  FETCH_STOCK_SUCCEEDED,
  APPLY_STOCK_FILTERS,
  RESET_STOCK_FILTERS
} from "../../types/Administrator/Stock";

import Stock from "../../../domain/Stock";

export const resetState = () => dispatch => {
  cancelPrevStockFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchStockSucceeded = data => ({
  type: FETCH_STOCK_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchStock = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevStockFetchAll();
  try {
    const stock = await fetchAllStock();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchStockSucceeded(stock));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applyStockFilter = filters => ({
  type: APPLY_STOCK_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetStockFilteres = () => ({type: RESET_STOCK_FILTERS});

export default {
  get: fetchStock,
  applyStockFilter,
  resetStockFilteres,
  resetState
};
