import {
  ADD_ELEVATOR_STATE,
  APPLY_ELEVATORS_STATES_FILTERS,
  EDIT_ELEVATOR_STATE,
  FETCH_ALL_ELEVATORS_STATES,
  RESET_CURRENT_ELEVATOR_STATE,
  RESET_ELEVATORS_STATES_FILTERS,
  RESET_ELEVATORS_STATES_STATE,
  SET_CURRENT_ELEVATOR_STATE
} from "../../types/ElevatorsManagement/StatesTypes";

const INIT_STATE = {
  data: {},
  filters: {
    code: "",
    name: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_ELEVATORS_STATES: {
      let list = {};
      action.payload.map(e => {
        list[e.id] = e;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case SET_CURRENT_ELEVATOR_STATE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case RESET_CURRENT_ELEVATOR_STATE: {
      return {
        ...state,
        current: null
      };
    }
    case ADD_ELEVATOR_STATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_ELEVATOR_STATE: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case APPLY_ELEVATORS_STATES_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };
    }
    case RESET_ELEVATORS_STATES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE
        }
      };
    }
    case RESET_ELEVATORS_STATES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
