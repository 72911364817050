import {mapToBoolean} from "../../../utils/mappers";

class WorkshopProvider {
    constructor(id, description, state) {
        this.id = id;
        this.description = description;
        this.state = state;
    }

    static map = provider => {
        return new WorkshopProvider(
            provider.nu_id_taller_proveedor,
            provider.vc_desc_taller_proveedor,
            mapToBoolean(provider.bi_estado),
        )
    }
}

export default WorkshopProvider;