import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {getAllElevatorsDetails} from "../../../../../../../services/ElevatorsManagement/DetailsService";

const DetailSelect = ({id, value, setValue, disabled}) => {
  const [ details, setDetails ] = React.useState([]);
  React.useEffect(() => {
    getAllElevatorsDetails({})
      .then(data => setDetails(data))
      .catch(e => setDetails([]));
  }, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.state.detail"
          }
        />
      }
      fullWidth
    >
      {details.map(detail => (
        <MenuItem value={detail.id} key={detail.id}>
          {detail.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DetailSelect;
