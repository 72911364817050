import React, { useEffect } from 'react';
import {useDispatch, useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';
 
import unit from  '../../../redux/actions/Administrator/Unit';

const UnitSelect = ({ id, label, hasError, error, value, onChange }) => {
    if(value?.value != undefined){value = value.value}
    const { data } = useSelector(state => state.administrator.unit);
    const dispatch = useDispatch();
      /*useEffect(() => {
        if (data.length === 0) {
          dispatch(unit.get());
        }
      }, [data, dispatch])*/

    //console.log("***DATA: ",data);

    const options = [
      { value: '', label: 'Seleccione una opción' },
      ...data.filter(type => type.active !== 0).map(type => ({ value: type.id, label: type.description })),
    ];
  
    return (
      <Select
        value={value}
        error={hasError}
        errorMessage={error}
        fullWidth
        id={id}
        label={label}
        onChange={e => onChange(options.find(option => option.value === e.target.value))}
        options={options}
      />
    );
  };
  
  export default UnitSelect;