import React from "react";
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {getAllTachographAlertsTypes} from "../../../../../../services/FleetManagement/Tachograph/TachographAlertsService";

const TypesSelect = ({formData, setFormData}) => {
  const [ types, setTypes ] = React.useState([]);
  React.useEffect(() => {
    getAllTachographAlertsTypes()
      .then(types => setTypes(types))
      .catch(e => console.error(e.message));
  }, []);
  return (
    <MultipleSelect
      options={types}
      fullWidth
      label={
        <IntlMessages id={"fleet.management.tachograph.alerts.table.type"} />
      }
      value={formData.types}
      onChange={value => setFormData("types", value)}
    />
  );
};

export default TypesSelect;
