import {makeStyles} from "@material-ui/styles";
import {alpha} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  perfectScrollbarRoot: {
    height: props => `calc(100vh - ${props.height}px)`
  },
  chatCellItem: {
    padding: 16,
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&:hover, &.active": {
      backgroundColor: alpha(theme.palette.common.dark, 0.04),
      "& $titleRoot": {
        color: theme.palette.primary.main
      }
    }
  },
  chatCellInfo: {
    width: "calc(100% - 40px)",
    paddingLeft: 16
  },
  titleRoot: {
    position: "relative"
  },
  chatDesRoot: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: 12,
    color: theme.palette.text.secondary
  },
  chatAvatarRoot: {
    position: "relative"
  }
}));
