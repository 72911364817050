import React from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";

const VALUES = Object.freeze({
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
});

const getLabelFromValue = value => {
  switch (value) {
    case true: {
      return VALUES.ACTIVE;
    }
    case false: {
      return VALUES.INACTIVE;
    }
    default: {
      return VALUES.ACTIVE;
    }
  }
};

const getValueFromLabel = label => {
  switch (label) {
    case VALUES.ACTIVE: {
      return true;
    }
    case VALUES.INACTIVE: {
      return false;
    }
    default: {
      return true;
    }
  }
};

const StatusSelect = ({
  id,
  value,
  onChange,
  style,
  fullWidth,
  disabled = false
}) => {
  const selected = getLabelFromValue(value);

  return (
    <Select
      id={id}
      label={<IntlMessages id="common.forms.select.status.select.label" />}
      value={selected}
      onChange={e => onChange(getValueFromLabel(e.target.value))}
      style={style}
      disabled={disabled}
      fullWidth={fullWidth}
    >
      <MenuItem value={VALUES.ACTIVE}>
        <IntlMessages id="common.forms.select.status.select.option.active" />
      </MenuItem>
      <MenuItem value={VALUES.INACTIVE}>
        <IntlMessages id="common.forms.select.status.select.option.inactive" />
      </MenuItem>
    </Select>
  );
};

StatusSelect.propTypes = {
  value: PropTypes.oneOf([ true, false ]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool
};

StatusSelect.defaultProps = {
  value: undefined,
  onChange: () => {},
  fullWidth: false
};

export default StatusSelect;
