import React from "react";
import useForm from "../../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import TypeSelect from "../../ComplementaryStateItem/TypeSelect";

const Form = ({id, onSave}) => {
  const {formData, setFormData, handleSubmit} = useForm({
    name: "",
    type: ""
  });
  return (
    <form id={id} onSubmit={handleSubmit(onSave)}>
      <GridContainer spacing={2}>
        <Grid item sm={12} md={6}>
          <OutlinedInput
            id={"add-complementary-name"}
            fullWidth
            value={formData.name}
            onChange={value => setFormData("name", value)}
            label={
              <IntlMessages
                id={
                  "logistic.distribution.work.orders.types.detail.state.label"
                }
              />
            }
          />
        </Grid>
        <Grid item sm={12} md={6}>
          <TypeSelect
            id={"add-complementary-type"}
            value={formData.type}
            setValue={value => setFormData("type", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
