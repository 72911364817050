import {
  APPLY_SERVICE_API_FILTERS,
  APPLY_SERVICES_FILTERS,
  EDIT_SERVICE_TABLE_HEADERS,
  FETCH_ALL_SERVICES,
  FETCH_SERVICE_TABLE_HEADERS,
  RESET_SERVICE_API_FILTERS,
  RESET_SERVICES_FILTERS,
  RESET_SERVICES_STATE,
  SET_SERVICES_LAST_UPDATE
} from "../../types/Logistic/ServicesListTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  deleteServiceById,
  getAllServicesByFilters,
  getAllServicesHeaders,
  getCurrentServiceLocation,
  updateServicesHeaders
} from "../../../services/Logistics/Distribution/ServicesListService";
import {operations} from "../../../components/Logistic/ServicesList/ServicesTable/EditServiceDialog/constants";

export const resetServicesState = () => ({type: RESET_SERVICES_STATE});

export const fetchAllServicesTableData = serviceFilters => {
  return async dispatch => {
    dispatch(fetchStart());
    try {
      const headers = await getAllServicesHeaders();
      const services = await getAllServicesByFilters(serviceFilters);
      dispatch({type: FETCH_SERVICE_TABLE_HEADERS, payload: headers});
      dispatch({type: FETCH_ALL_SERVICES, payload: services});
      dispatch({type: SET_SERVICES_LAST_UPDATE});
      dispatch(fetchSuccess());
    } catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const resetServicesFilters = () => ({type: RESET_SERVICES_FILTERS});
export const applyServicesFilters = filters => ({
  type: APPLY_SERVICES_FILTERS,
  payload: filters
});

export const resetServiceApiFilters = () => ({type: RESET_SERVICE_API_FILTERS});
export const applyServiceApiFilters = filters => ({
  type: APPLY_SERVICE_API_FILTERS,
  payload: filters
});

export const editServicesTableHeaders = headers => {
  return async dispatch => {
    dispatch(fetchStart());
    updateServicesHeaders(headers)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: EDIT_SERVICE_TABLE_HEADERS, payload: headers});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const editServiceArrivalDate = (request, filters) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const { service } = operations[request.operation];
      const message = await service(request);
      dispatch(fetchSuccess(message));
      setTimeout(() => {
        dispatch(fetchAllServicesTableData(filters));
      }, 1500);
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};

export const getCurrentLocation = service => {
  return async dispatch => {
    dispatch(fetchStart());
    getCurrentServiceLocation(service)
      .then(({latitude, longitude}) => {
        dispatch(fetchSuccess());
        if (!latitude || !longitude) {
          dispatch(fetchError("Error en obtener la ubicacion"));
        } else {
          window.open(
            `https://www.google.com/maps/search/?api=1&query=${latitude}%2C${longitude}`
          );
        }
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const deleteService = service => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const response = await deleteServiceById(service.serviceId);
      dispatch(fetchSuccess(response.transactionMessage));
    }catch (e) {
        dispatch(fetchError(e.message));
    }
  };
}
