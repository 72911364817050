import CashManager from 'domain/CashManager';
import axiosInstance, { CancelToken, isCancel } from 'utils/axiosInstance';
import Response from 'domain/Response';
import toBase64 from 'utils/files';
import {getCompanyEntityId, getUserEmail, getUserId} from './StorageService';
import CashManagerEntity from "../domain/CashManagerEntity";

let fetchAllCancelToken = undefined;
let fetchAllEntitiesCancelToken = undefined;

export const cancelPrevFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const cancelPrevFetchAllEntities = () => {
  fetchAllEntitiesCancelToken && fetchAllEntitiesCancelToken();
};

export const fetchAllEntities = async () => {
  try {
    const response = await axiosInstance.post(
      '/Caja_Chica_Entidad_Gestor_Caja/sel',
      { nu_id_entidad: getCompanyEntityId() },
      {
        cancelToken: new CancelToken(c => (fetchAllEntitiesCancelToken = c)),
      },
    );
    return response.data.map(type => CashManagerEntity.map(type));
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};

export const fetchAll = async () => {
  try {
    const response = await axiosInstance.post(
      '/Caja_Chica/sel',
      { nu_id_entidad: getCompanyEntityId(), nu_id_entidad_administrador: getUserId() },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c)),
      },
    );
    return response.data.map(type => CashManager.map(type));
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};

export const deleteCashManager = async id => {
  try {
    const response = await axiosInstance.post('/Caja_Chica/del', {
      nu_id_caja_chica: id,
      nu_id_entidad_administrador: getUserId(),
      vc_usuario: getUserEmail(),
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === '0') {
      throw new Error(responseObject.transactionMessage);
    }
    return { id: responseObject.transactionKey, message: responseObject.transactionMessage };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const update = async (id, data) => {
  try {
    const response = await axiosInstance.post('/Caja_Chica/upd', {
      nu_id_caja_chica: id,
      nu_id_entidad_administrador: getUserId(),
      nu_imp_fondo: data.fund,
      nu_id_centro_costo: data.costCenter,
      vc_usuario: getUserEmail(),
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === '0') {
      throw new Error(responseObject.transactionMessage);
    }
    return { id: responseObject.transactionKey, message: responseObject.transactionMessage };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const create = async data => {
  try {
    const file = await toBase64(data.proofOfAcceptance[0]).catch(error => {
      throw new Error(error.message);
    });
    const response = await axiosInstance.post('/Caja_Chica/ins', {
      nu_id_tipo_caja_chica: data.pettyCashType.value,
      nu_id_entidad_administrador: getUserId(),
      nu_id_entidad_gestor_caja: data.cashManager.id,
      nu_id_moneda: data.currency,
      nu_imp_fondo: data.amount,
      nu_imp_efectivo: data.amount,
      ls_tag: data.tags.map(tag => ({ nu_id_tag: tag.id })),
      archivo: {
        archivobase64: file.split(',')[1],
        contentType: "application/pdf",
        extension: "pdf"
      },
      nu_id_centro_costo: data.costCenter,
      vc_usuario: 'USUARIO',
      nu_id_entidad: getCompanyEntityId(),
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === '0') {
      throw new Error(responseObject.transactionMessage);
    }
    const newCashManager = new CashManager(
      responseObject.transactionKey,
      data.pettyCashType.value,
      data.pettyCashType.label,
      getUserId(),
      data.cashManager.id,
      data.cashManager.name,
      data.currency,
      parseFloat(data.amount),
      parseFloat(data.amount),
      0,
      0,
      0,
      data.tags.map(tag => tag.name),
    );
    return { id: responseObject.transactionKey, message: responseObject.transactionMessage, object: newCashManager };
  } catch (error) {
    return Promise.reject(error);
  }
};


export const getCashManagerAverages = async () => {
  try {
    const {data} = await axiosInstance.post(
        '/Caja_Chica/sel_importes_maximos',
        {
          nu_id_entidad: getCompanyEntityId(),
          nu_id_entidad_administrador: getUserId()
        }
    );
    return data.map(row => ({
      currency: row.vc_desc_moneda === 'SOLES' ? 'PEN' : 'USD',
      assignedAmount: +row.nu_importe_maximo_caja,
      usedAmount: +row.nu_importe_utilizado,
    }));
  }catch (e) {
    return Promise.reject(e);
  }
}