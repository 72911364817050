import React from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

export const getAreas = (operations) => ({
    treasury: {
        title: <IntlMessages id={"control.panel.kpi.areas.grid.treasury"}/>,
        options: [
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.treasury.petty.cash"} />,
                reports: [
                    {
                        id: 1,
                        // text: <IntlMessages id="administrator.expenses.reports.title" />,
                        text: operations[110]?.description,
                        filters: {}
                    },
                    {
                        id: 2,
                        // text: <IntlMessages id="administrator.consolidated.reports.title" />,
                        text: operations[111]?.description,
                        filters: {}
                    },
                    {
                        id: 3,
                        // text: <IntlMessages id="administrator.average.reports.title" />,
                        text: operations[112]?.description,
                        filters: {}
                    },
                    {
                        id: 4,
                        // text: <IntlMessages id="administrator.kilometer.reports.title" />,
                        text: operations[113]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            }
        ].filter(c => c.reports.length > 0)
    },
    logistic: {
        title: <IntlMessages id={"control.panel.kpi.areas.grid.logistic"}/>,
        options: [
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.logistic.inventory"} />,
                reports: [
                    {
                        id: 5,
                        // text: <IntlMessages id="logistic.inventoryReport.container.control.report.title"/>,
                        text: operations[104]?.description,
                        filters: {}
                    },
                    {
                        id: 6,
                        // text: <IntlMessages id="logistic.inventoryReport.debt.report.title"/>,
                        text: operations[105]?.description,
                        filters: {}
                    },
                    {
                        id: 7,
                        // text: <IntlMessages id="logistic.inventoryReport.stock.report.title"/>,
                        text: operations[106]?.description,
                        filters: {}
                    },
                    {
                        id: 8,
                        // text: <IntlMessages id="logistic.inventoryReport.sales.report.title"/>,
                        text: operations[107]?.description,
                        filters: {}
                    },
                    {
                        id: 18,
                        // text: <IntlMessages id="logistic.inventoryReport.provider.stock.report.title"/>,
                        text: operations[108]?.description,
                        filters: {}
                    },
                    {
                        id: 19,
                        // text: <IntlMessages id="logistic.inventoryReport.provider.receipt.report.title"/>,
                        text: operations[109]?.description,
                        filters: {}
                    },

                ].filter(r => r.text)

            },
        ].filter(c => c.reports.length > 0)
    },
    location: {
        title: <IntlMessages id={"control.panel.kpi.areas.grid.location"}/>,
        // options: [...getCdsOptions(companyId)]
        options: [
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.location.cds"} />,
                reports: [
                    {
                        id: 9,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.mina.report.title"/>,
                        text: operations[95]?.description,
                        filters: {}

                    },
                    {
                        id: 10,
                        text: operations[96]?.description,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.events.report.title"/>,
                        filters: {}

                    },
                    {
                        id: 11,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.graphic.event.report.title"/>,
                        text: operations[97]?.description,
                        filters: {}

                    },
                    {
                        id: 12,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.pick.A1A2.report.title"/>,
                        text: operations[98]?.description,
                        filters: {}

                    },
                    {
                        id: 13,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.distribution.A1A2.report.title"/>,
                        text: operations[99]?.description,
                        filters: {}

                    },
                    {
                        id: 14,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.pick.B1B2.report.title"/>,
                        text: operations[100]?.description,
                        filters: {}

                    },
                    {
                        id: 15,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.distribution.B1B2.report.title"/>,
                        text: operations[101]?.description,
                        filters: {}

                    },
                    {
                        id: 16,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.staggered.report.title"/>,
                        text: operations[102]?.description,
                        filters: {}

                    },
                    {
                        id: 17,
                        // text: <IntlMessages id="administrator.dataCollectionHistory.administrative.turn.report.title"/>,
                        text: operations[103]?.description,
                        filters: {}

                    },
                ].filter(r => r.text)
            },
            {
                text: <IntlMessages id="control.panel.kpi.areas.grid.location.over.truck" />,
                reports: [
                    {
                        id: 23,
                        text: operations[118]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            },
            {
                text: <IntlMessages id="control.panel.kpi.areas.grid.location.activity" />,
                reports: [
                    {
                        id: 25,
                        text: operations[125]?.description,
                        filters: {}
                    },
                    {
                        id: 26,
                        text: operations[126]?.description,
                        filters: {}
                    },
                    {
                        id: 27,
                        text: operations[127]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            }
        ].filter(c => c.reports.length > 0)
    },
    tachograph: {
        title: <IntlMessages id={"control.panel.kpi.areas.grid.tachograph"}/>,
        options: [
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.tachograph.infractions"} />,
                reports: [
                    {
                        id: 20,
                        text: operations[115]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            },
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.tachograph.activities"} />,
                reports: [
                    {
                        id: 21,
                        text: operations[116]?.description,
                        filters: {}

                    },
                    {
                        id: 22,
                        text: operations[117]?.description,
                        filters: {}
                    },
                    {
                        id: 38,
                        text: operations[140]?.description,
                        filters: {}
                    },
                    {
                        id: 39,
                        text: operations[141]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            },
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.tachograph.general"} />,
                reports: [
                    {
                        id: 40,
                        text: operations[150]?.description,
                        filters: {}
                    },
                    {
                        id: 41,
                        text: operations[151]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            }

        ].filter(c => c.reports.length > 0)
    },
    distribution: {
        title: <IntlMessages id={"control.panel.kpi.areas.grid.distribution"} />,
        options: [
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.distribution.kone"} />,
                reports: [
                    {
                        id: 30,
                        text: operations[122]?.description,
                        filters: {}
                    },
                ].filter(r => r.text)
            },
            {
                text: <IntlMessages id={"control.panel.kpi.areas.grid.distribution.san.fernando"} />,
                reports: [
                    {
                        id: 31,
                        text: operations[123]?.description,
                        filters: {}

                    },
                    {
                        id: 33,
                        text: operations[133]?.description,
                        filters: {}

                    },
                    {
                        id: 34,
                        text: operations[134]?.description,
                        filters: {}

                    },
                    {
                        id: 32,
                        text: operations[124]?.description,
                        filters: {}

                    },
                ].filter(r => r.text)
            },

        ].filter(c => c.reports.length > 0)
    },
})