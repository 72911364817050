import React from "react";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid, Typography} from "@material-ui/core";
import DefaultStateItem from "./DefaultStateItem";
import useForm from "../../../../../../hooks/Common/useForm";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const DefaultStatesList = ({
  startInitialData,
  endInitialData,
  startChecklist,
  endChecklist,
  add = false,
  onSaveStartData,
  onSaveEndData,
  statesChecklist = []
}) => {
  const {
    formData: startData,
    setFormData: setStartData,
    resetForm: resetStartForm
  } = useForm(startInitialData);
  const {
    formData: endData,
    setFormData: setEndData,
    resetForm: resetEndForm
  } = useForm(endInitialData);
  const [ next, setNext ] = React.useState(!add);
  const onMapChecklist = (checklist, state) => {
    return statesChecklist.filter(s => s.id !== state.id).map(item => ({
      id: item.id,
      name: item.name,
      status: checklist.find(s => s.id === item.id)?.status || false,
    }));
  };
  return (
    <GridContainer spacing={4} style={{marginTop: "16px"}}>
      <Grid item xs={12}>
        <Typography variant="h1">
          <IntlMessages
            id={
              "logistic.distribution.work.orders.types.detail.state.principal.title"
            }
          />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2">
          <IntlMessages
            id={
              "logistic.distribution.work.orders.types.detail.state.principal.description"
            }
          />
        </Typography>
      </Grid>
      <Grid item sm={12} md={6}>
        <DefaultStateItem
          id={"default-start-state"}
          title={
            <IntlMessages
              id={
                "logistic.distribution.work.orders.types.detail.start.state.title"
              }
            />
          }
          description={
            <IntlMessages
              id={
                "logistic.distribution.work.orders.types.detail.start.state.description"
              }
            />
          }
          formData={startData}
          setFormData={setStartData}
          checklist={onMapChecklist(startChecklist, startData)}
          onSaveChanges={checklist => {
            setNext(true);
            onSaveStartData({...startData, checklist, type: false, order: 1});
          }}
          add={add}
          resetForm={resetStartForm}
        />
      </Grid>
      {next && (
        <Grid item sm={12} md={6}>
          <DefaultStateItem
            id={"default-end-state"}
            title={
              <IntlMessages
                id={
                  "logistic.distribution.work.orders.types.detail.end.state.title"
                }
              />
            }
            description={
              <IntlMessages
                id={
                  "logistic.distribution.work.orders.types.detail.end.state.description"
                }
              />
            }
            formData={endData}
            setFormData={setEndData}
            checklist={onMapChecklist(endChecklist, endData)}
            onSaveChanges={checklist =>
              onSaveEndData({...endData, checklist, type: true, order: 2})}
            add={add}
            resetForm={resetEndForm}
          />
        </Grid>
      )}
    </GridContainer>
  );
};

export default DefaultStatesList;
