import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import {mapToBoolean} from "../../../utils/mappers";
import Response from "../../../domain/Response";

export const fetchAllProviders = async () => {
    try {
        const {data} = await axiosInstance.post(
            '/Proveedor/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
            }
        );
        return data.map(provider => ({
            id: provider.nu_id_entidad,
            providerCode: provider.vc_cod_entidad,
            name: provider.vc_nombre,
            businessName: provider.vc_nombre_comercial,
            countryId: provider.vc_cif_pais,
            countryName: provider.vc_nombre_pais ?? '',
            code: provider.vc_cif_codigo,
            email: provider.vc_email,
            state: mapToBoolean(provider.bi_estado),
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const fetchAllProviderHeadquarters = async (providerId) => {
    try {
        const {data} = await axiosInstance.post(
            '/Entidad_Sedes/sel',
            {
                nu_id_entidad: providerId,
            }
        );
        return data.map(headquarter => ( {
            id: headquarter.nu_id_entidad_sede,
            name: headquarter.vc_nombre_sede,
            isFiscal: mapToBoolean(headquarter.bi_sede_fiscal),
            streetName: headquarter.vc_calle,
            number: headquarter.vc_numero,
            zipCode: headquarter.vc_cod_postal,
            district: headquarter.vc_distrito,
            province: headquarter.vc_provincia,
            country: headquarter.vc_pais,
            latitude: headquarter.nu_latitud,
            longitude: headquarter.nu_longitud,
            state: mapToBoolean(headquarter.bi_estado),
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}


export const createProvider = async (provider) => {
    try {
        const {data} = await axiosInstance.post(
            '/Proveedor/ins_proveedor_empresa',
            {
                nu_id_entidad: getCompanyEntityId(),
                vc_nif: provider.providerCode,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
};