import React from 'react';
import useForm from "../../../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentReportFile} from "../../../../../../../../redux/actions/ControlPanel/Kpi";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import VehiclesPicker from "../../../../../../../FleetManagement/Common/Selects/VehiclesPicker";
import SendEmail from "../../../../Common/SendEmailDialog";
import SaveFilters from "../../../../Common/SaveFilters";
import DateFilters from "../../../../Common/DateFilters";

const initialData = {
    period: 1,
    startDate: '',
    endDate: '',
    vehicles: [],
}
const Form = ({service}) => {
    const {formData, errors, setFormData, resetForm, setValues} = useForm(initialData,);
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const dispatch = useDispatch();
    React.useEffect(() => {
        resetForm();
        current?.filters && setValues(current.filters);
    }, [service]);
    const handleSubmitPDF = () => {
        dispatch(setCurrentReportFile(service, formData, 1));
    }
    const handleSubmitExcel = () => {
        dispatch(setCurrentReportFile(service, formData, 2));
    }

    return (
        <form>
            <GridContainer spacing={4}>
                <Grid item xs={12} sm={6}>
                    <VehiclesPicker
                        value={formData.vehicles}
                        onChange={value => setFormData('vehicles', value)}
                        fullWidth
                    />
                </Grid>
                <DateFilters
                    formData={formData}
                    setFormData={setFormData}
                />
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitPDF()}
                        // disabled={onValidate()}
                        color="primary"
                    >
                        <IntlMessages id="generate.format.pdf"/>
                    </Button>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                        variant="contained"
                        onClick={() => handleSubmitExcel()}
                        // disabled={onValidate()}
                        color="primary"
                    >
                        <IntlMessages id="generate.format.excel"/>
                    </Button>
                </Grid>
               <SendEmail filters={formData} />
                <SaveFilters filters={formData}/>
            </GridContainer>
        </form>
    );
};

export default Form;