class ElevatorContactClient {
  constructor(id, name, email, phone) {
    this.id = id;
    this.name = name;
    this.email = email;
    this.phone = phone;
  }

  static map = (contact, index) => {
    return new ElevatorContactClient(
      index,
      contact.vc_nombre,
      contact.vc_email,
      contact.vc_telefono
    );
  };
}

export default ElevatorContactClient;
