import React from "react";
import {connect} from "react-redux";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHead from "./TableHead";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import moment from "moment";
import FilterBuilder from "../../../../../../utils/filters";

const Table = ({destinations}) => {
  return (
    <TableContainer head={<TableHead />}>
      <React.Fragment>
        {destinations.length > 0 &&
          destinations.map(destination => (
            <StyledTableRow key={destination.id}>
              <StyledTableCell>{destination.name}</StyledTableCell>
              <StyledTableCell>{destination.workOrder}</StyledTableCell>
              <StyledTableCell>
                {moment(new Date(destination.estimatedDate)).format(
                  "DD-MM-yyyy (HH:mm:ss)"
                )}
              </StyledTableCell>
              <StyledTableCell>{destination.state}</StyledTableCell>
              <StyledTableCell>{destination.vehicle}</StyledTableCell>
              <StyledTableCell>{destination.driver}</StyledTableCell>
              <StyledTableCell>{destination.rejectReason}</StyledTableCell>
            </StyledTableRow>
          ))}
      </React.Fragment>
    </TableContainer>
  );
};

const mapStateToProps = state => {
  const {destinations, filters} = state.logistic.monitoring.dispatches;
  const filteredData = new FilterBuilder(destinations)
    .byFieldIncludes("name", filters.client)
    .byFieldIncludes("workOrder", filters.workOrder)
    .byFieldIncludes("state", filters.state)
    .byFieldIncludes("vehicle", filters.vehicle)
    .byFieldIncludes("workOrder", filters.route)
    .byFieldIncludes("driver", filters.driver)
    .byFieldIncludes("rejectReason", filters.rejectReason)
    .build();
  return {destinations: filteredData};
};

export default connect(mapStateToProps)(Table);
