import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import {
  applyReservesFilters,
  resetReservesFilters
} from "../../../../../../redux/actions/FleetManagement/Reserves";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import ActiveSelect from "../../../../../Common/Forms/Select/ActiveSelect";

const Filters = () => {
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.reserves
  );
  const {formData, setFormData, resetForm} = useForm(filters);
  const dispatch = useDispatch();
  const onApplyFilters = () => {
    dispatch(applyReservesFilters(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetReservesFilters());
  };
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"reserves-plaque"}
              fullWidth
              value={formData.plaque}
              onChange={value => setFormData("plaque", value)}
              label={
                <IntlMessages id={"fleet.management.reserves.table.plaque"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"reserves-brand"}
              fullWidth
              value={formData.brand}
              onChange={value => setFormData("brand", value)}
              label={
                <IntlMessages id={"fleet.management.reserves.table.brand"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"reserves-model"}
              fullWidth
              value={formData.model}
              onChange={value => setFormData("model", value)}
              label={
                <IntlMessages id={"fleet.management.reserves.table.model"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedDatePicker
              id={"reserves-start-date"}
              fullWidth
              value={formData.startDate}
              onChange={value => setFormData("startDate", value)}
              label={
                <IntlMessages
                  id={"fleet.management.reserves.table.start.date"}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedDatePicker
              id={"reserves-end-date"}
              fullWidth
              value={formData.endDate}
              onChange={value => setFormData("endDate", value)}
              label={
                <IntlMessages id={"fleet.management.reserves.table.end.date"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"reserves-driver"}
              fullWidth
              value={formData.driver}
              onChange={value => setFormData("driver", value)}
              label={
                <IntlMessages id={"fleet.management.reserves.table.driver"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"reserves-turn"}
              fullWidth
              value={formData.turn}
              onChange={value => setFormData("turn", value)}
              label={
                <IntlMessages id={"fleet.management.reserves.table.turn"} />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ActiveSelect
              id={"reserves-state"}
              fullWidth
              value={formData.state}
              onChange={value => setFormData("state", value)}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onResetFilters();
              }}
              text={<IntlMessages id={"filters.button.clear"} />}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <ContainedButton
              onClick={e => {
                e.preventDefault();
                onApplyFilters();
              }}
              color="primary"
              text={<IntlMessages id={"filters.button.apply"} />}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
