import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import validations from "./validations";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import PlaqueSelect from "../../../../Vehicles/Common/Form/Selects/PlaqueSelect";
import DriverSelect from "../../../../Vehicles/Common/Form/Selects/DriverSelect";
import OutlinedTimePicker from "../../../../../Common/Forms/OutlinedTimePicker";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import StationSelect from "./StationSelect";
import FuelTypeSelect from "./FuelTypeSelect";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <PlaqueSelect
                        id={"form-refueling-plaque"}
                        value={formData.plaque}
                        setValue={value => setFormData('plaque', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.plaque"}/>}
                        hasError={errors?.plaque?.length > 0}
                        error={errors?.plaque[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"form-refueling-date"}
                        fullWidth
                        value={formData.date}
                        onChange={value => setFormData('date', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.date"}/>}
                        hasError={errors?.date?.length > 0}
                        error={errors?.date[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedTimePicker
                        id={"form-refueling-time"}
                        fullWidth
                        value={formData.time}
                        onChange={value => setFormData('time', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.time"}/>}
                        hasError={errors?.time?.length > 0}
                        error={errors?.time[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-refueling-kilometer"}
                        fullWidth
                        value={formData.kilometer}
                        onChange={value => setFormData('kilometer', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.kilometer"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DriverSelect
                        id={"form-refueling-driver"}
                        value={formData.driver}
                        setValue={value => setFormData('driver', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.driver"}/>}
                        hasError={errors?.driver?.length > 0}
                        error={errors?.driver[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StationSelect
                        id={"form-refueling-station"}
                        value={formData.station}
                        setValue={value => setFormData('station', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.station"}/>}
                        hasError={errors?.station?.length > 0}
                        error={errors?.station[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FuelTypeSelect
                        id={"form-refueling-fuel-type"}
                        value={formData.fuelType}
                        setValue={value => setFormData('fuelType', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.fuel.type"}/>}
                        hasError={errors?.fuelType?.length > 0}
                        error={errors?.fuelType[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-refueling-gallons"}
                        fullWidth
                        type="number"
                        value={formData.gallons}
                        onChange={value => setFormData('gallons', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.gallons"}/>}
                        hasError={errors?.gallons?.length > 0}
                        error={errors?.gallons[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-refueling-price"}
                        fullWidth
                        type="number"
                        value={formData.price}
                        onChange={value => setFormData('price', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.price"}/>}
                        hasError={errors?.price?.length > 0}
                        error={errors?.price[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-refueling-amount"}
                        fullWidth
                        type="number"
                        value={formData.amount}
                        onChange={value => setFormData('amount', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.amount"}/>}
                        hasError={errors?.amount?.length > 0}
                        error={errors?.amount[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-refueling-bill"}
                        fullWidth
                        value={formData.bill}
                        onChange={value => setFormData('bill', value)}
                        label={<IntlMessages id={"fleet.management.refueling.form.bill"}/>}
                    />
                </Grid>
            </GridContainer>

        </form>
    );
}

export default Form;