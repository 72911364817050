const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    code: "",
    description: "",
    accountingAccount: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "EXPENSE_TYPES_FETCH_SUCCEEDED": {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case "EXPENSE_TYPES_APPLY_FILTERS": {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case "EXPENSE_TYPES_RESET_FILTERS": {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    case "EXPENSE_TYPES_CHANGE_STATE": {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, {active: !type.active});
          return type;
        })
      };
    }
    case "EXPENSE_TYPES_CREATE": {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }
    case "EXPENSE_TYPES_EDIT": {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id)
            return Object.assign(type, action.payload.data);
          return type;
        })
      };
    }
    default: {
      return state;
    }
  }
};
