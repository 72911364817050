import {
    APPLY_WORKSHOP_FILTERS,
    EDIT_WORKSHOP_VEHICLE,
    FETCH_ALL_WORKSHOP_VEHICLES,
    RESET_WORKSHOP_FILTERS,
    RESET_WORKSHOP_STATE,
    SET_CURRENT_WORKSHOP_VEHICLE
} from "../../types/FleetManagement/WorkshopTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {formatDateFromTimeAndDateString} from "../../../utils/dates";
import {
    createWorkshop,
    getAllWorkshops,
    getAllWorkshopsById, updateWorkshop
} from "../../../services/FleetManagement/DailyOperation/WorkshopService";

export const resetWorkshopState = () => ({type: RESET_WORKSHOP_STATE});
export const applyWorkshopFilters = filters => ({
    type: APPLY_WORKSHOP_FILTERS,
    payload: filters
});
export const resetWorkshopFilters = () => ({type: RESET_WORKSHOP_FILTERS});
export const setCurrentWorkshopVehicle = current => async dispatch => {
    if (current) {
        dispatch(fetchStart());
        getAllWorkshopsById(current)
            .then(workshop => {
                dispatch(fetchSuccess());
                dispatch({
                    type: SET_CURRENT_WORKSHOP_VEHICLE,
                    payload: workshop
                });
            }).catch(e => {
            dispatch(fetchError(e.message));
        });

    } else {
        dispatch({
            type: SET_CURRENT_WORKSHOP_VEHICLE,
            payload: current
        });
    }

}
export const fetchAllWorkshopVehicles = filters => {
    return async dispatch => {
        dispatch(fetchStart());
        getAllWorkshops()
            .then(workshops => {
                dispatch(fetchSuccess());
                dispatch({type: FETCH_ALL_WORKSHOP_VEHICLES, payload: workshops});
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    };
};

export const addWorkshopVehicle = (workshop, onClose) => {
    return async dispatch => {
        dispatch(fetchStart());
        createWorkshop(workshop)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                onClose && onClose();
                setTimeout(() => {
                    dispatch(fetchAllWorkshopVehicles())
                }, 1500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    };
};

export const editWorkshopVehicle = (workshop, onClose) => {
    return async dispatch => {
        dispatch(fetchStart());
        updateWorkshop(workshop)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllWorkshopVehicles())
                }, 1500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
        onClose && onClose();
    };
};
