import {alpha, makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  chatFooterRoot: {
    borderTop: `1px solid ${theme.palette.borderColor.main}`,
    backgroundColor: alpha(theme.palette.common.dark, 0.04),
    padding: "14px 20px 15px",
    display: "flex",
    alignItems: "center",
    marginTop: "auto",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 30,
      paddingRight: 30
    }
  },
  textFieldRoot: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    "& .MuiOutlinedInput-multiline": {
      padding: "10px 16px"
    },
    "& .MuiInputBase-root": {
      backgroundColor: alpha(theme.palette.background.paper, 0.9)
    }
  },
  iconBtnRoot: {
    padding: 8
  }
}));
