import React from 'react';
import {useStyles} from "../../../styles";
import {Box, Divider, Grid, Paper, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import Checkbox from "../../../../../../Common/Forms/Checkbox";
import useForm from "../../../../../../../hooks/Common/useForm";
import TypeSelect from "./TypeSelect";
import Button from "@material-ui/core/Button";
import ChecklistsArea from "../../ChecklistsArea";
import {useDispatch, useSelector} from "react-redux";
import {editWorkOrderTypeState} from "../../../../../../../redux/actions/Logistic/WorkOrdersTypes";
import validations from "../../NameArea/validations";
import DepartureManagementArea from "../../Common/DepartureManagementArea";

const ComplementaryStateItem = ({state, checklist}) => {
    const classes = useStyles();
    const {current} = useSelector(({logistic}) => logistic.workOrdersTypes);
    const dispatch = useDispatch();
    const [edit, setEdit] = React.useState(false);
    const {formData, setFormData, resetForm, errors, validateAll} = useForm({
        name: state.name,
        type: state.type,
        confirmation: state.destinyConfirmation,
        permission: state.allowPermission,
        previousCheck: state.previous,
        confirmationCheck: state.confirmation,
        arrivalDestination: state.arrivalDestination,
        departureDestination: state.departureDestination,
    }, validations);
    const [list, setList] = React.useState(checklist);
    const onSaveChanges = () => {
        if(validateAll()) {
            dispatch(editWorkOrderTypeState(current, {...state, ...formData, checklist: list}));
            setEdit(false);
        }
    }
    const onUpdateChecklist = (item) => {
        setList(list.map(c => {
            if (item.id === c.id) {
                return {
                    ...c,
                    status: !c.status,
                };
            }
            return c;
        }))
    }
    return (
        <React.Fragment>
            <Box className={classes.complementaryItemBox} component={Paper}>
                <GridContainer spacing={2} className={classes.titleArea}>
                    <Grid item xs={12} sm={4} style={{alignSelf: "center"}}>
                        <Typography variant="h4">
                            {state.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8} className={classes.buttonBox}>
                        {
                            edit
                                ? <React.Fragment>
                                    <Button
                                        style={{color: "white"}}
                                        onClick={() => {
                                            resetForm();
                                            setEdit(false);
                                            setList(checklist)
                                        }}
                                    >
                                        <IntlMessages id={"form.button.cancel"}/>
                                    </Button>
                                    <Button
                                        style={{color: "white"}}
                                        onClick={() => onSaveChanges()}
                                    >
                                        <IntlMessages id={"form.button.save"}/>
                                    </Button>
                                </React.Fragment>
                                : <Button
                                    style={{color: "white"}}
                                    onClick={() => setEdit(true)}
                                >
                                    <IntlMessages id={"form.button.edit"}/>
                                </Button>
                        }
                    </Grid>
                </GridContainer>
                <Box px={4} my={4}>
                    <GridContainer spacing={2}>
                        <Grid item sm={12} md={6}>
                            <OutlinedInput
                                id={state.id + '-name'}
                                fullWidth
                                value={formData.name}
                                disabled={!edit}
                                onChange={value => setFormData('name', value)}
                                label={<IntlMessages
                                    id={"logistic.distribution.work.orders.types.detail.state.label"}/>}
                                error={errors?.name[0]}
                                hasError={errors?.name?.length > 0}
                            />
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <TypeSelect
                                id={state.id + '-type'}
                                value={formData.type}
                                disabled={!edit}
                                setValue={value => setFormData('type', value)}
                            />
                        </Grid>
                    </GridContainer>
                    <Divider/>
                    <GridContainer spacing={2}>
                        <Grid item sm={12} md={6}>
                            <Box className={classes.defaultShortBox}>
                                <Checkbox
                                    id={state.id + '-confirmation'}
                                    fullWidth
                                    disabled={!edit}
                                    checked={formData.confirmation}
                                    onChange={value => setFormData('confirmation', value)}
                                    label={<IntlMessages
                                        id={"logistic.distribution.work.orders.types.detail.state.confirmation"}/>}
                                />
                                <Checkbox
                                    id={state.id + '-permission'}
                                    fullWidth
                                    disabled={!edit}
                                    checked={formData.permission}
                                    onChange={value => setFormData('permission', value)}
                                    label={<IntlMessages
                                        id={"logistic.distribution.work.orders.types.detail.state.permission"}/>}

                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ChecklistsArea
                                edit={edit}
                                formData={formData}
                                setFormData={setFormData}
                            />
                        </Grid>
                        {list.length > 0 &&
                            <Grid item sm={12} md={6}>
                                <Box>
                                    <Box>
                                        <Typography>
                                            <IntlMessages
                                                id={"logistic.distribution.work.orders.types.detail.state.checklist"}/>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        {
                                            list.map(item => (
                                                <Checkbox
                                                    key={item.id}
                                                    id={state.id + item.id}
                                                    fullWidth
                                                    disabled={!edit}
                                                    checked={item.status}
                                                    onChange={value => onUpdateChecklist(item)}
                                                    label={item.name}
                                                />
                                            ))
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        }
                        <Grid item sm={12} md={6}>
                            <DepartureManagementArea
                                id={state.id}
                                formData={formData}
                                edit={edit}
                                setFormData={setFormData}
                            />
                        </Grid>
                    </GridContainer>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default ComplementaryStateItem;