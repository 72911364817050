class AssigmentDestination {
  constructor(id, client, address, state, type, date, latitude, longitude) {
    this.id = id;
    this.client = client;
    this.address = address;
    this.state = state;
    this.type = type;
    this.date = date;
    this.latitude = latitude;
    this.longitude = longitude;
  }

  static map = destination => {
    return new AssigmentDestination(
      destination.id,
      destination.cliente,
      destination.direccion,
      destination.estados,
      destination.tipo,
      destination.fecha,
      destination.latitud,
      destination.longitud
    );
  };
}
export default AssigmentDestination;
