import React from "react";
import {useAdvanceDetailStyles} from "./styles";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import Box from "@material-ui/core/Box";
import AdvanceExpensesTable from "../AdvanceExpensesTable";
import {parseNumberToMoney} from "../../../../utils/parsers";
import OptionSpeedDial from "./OptionSpeedDial";
import RejectionDialog from "../RejectionDialog";
import {useSelector} from "react-redux";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import moment from "moment";
import {getUserType} from "../../../../services/StorageService";

const AdvanceDetailDialog = ({
  open = false,
  handleClose = null,
  isGuardian = true,
  setAdvanceLiquidateAlert
}) => {
  const [ openDialog, setOpenDialog ] = React.useState(false);
  const [ selectedExpense, setSelectedExpense ] = React.useState(null);
  const {currentAdvance} = useSelector(state => state.guardian);

  const classes = useAdvanceDetailStyles();

  const headers = [
    "guardian.advances.table.driver",
    // 'guardian.advances.table.box',
    "guardian.advances.table.tags",
    "guardian.advances.table.creation.date",
    "guardian.advances.table.latest.update.date",
    "guardian.advances.table.travel",
    "guardian.advances.table.route",
    "guardian.advances.table.advance",
      "extension.amount",
      "refund.amount",
    "guardian.advances.table.expense",
    "guardian.advances.table.approved",
    "guardian.advances.table.balance",
    "guardian.advances.header.pending"
  ];

  return (
    <FullScreenDialog
      open={open}
      handleClose={handleClose}
      title={"guardian.advance.detail.header"}
    >
      <React.Fragment>
        {currentAdvance.box && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <TableContainer
              component={Paper}
              style={{
                width: "90%",
                margin: "36px 40px"
              }}
            >
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {headers.map(head => (
                      <StyledTableCell align="left" key={head}>
                        <IntlMessages id={head} />
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left">
                      {currentAdvance.driver.name}
                    </StyledTableCell>
                    {/*<StyledTableCell align="left">{currentAdvance.box.name}</StyledTableCell>*/}
                    <StyledTableCell align="left">
                      {currentAdvance.tags.map(tag => (
                        <p key={tag} className="mt-1 mb-1">
                          {tag}
                        </p>
                      ))}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(currentAdvance.createDate).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currentAdvance.lastUpdate &&
                        moment(currentAdvance.lastUpdate).format(
                          "DD/MM/YYYY HH:mm"
                        )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currentAdvance.travel}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currentAdvance.routeDescription}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {parseNumberToMoney(currentAdvance.advanceAmount)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {parseNumberToMoney(currentAdvance.extension)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {parseNumberToMoney(currentAdvance.refund)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {parseNumberToMoney(currentAdvance.expense)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {parseNumberToMoney(currentAdvance.passed)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {parseNumberToMoney(currentAdvance.balance)}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {currentAdvance.state.description}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <AdvanceExpensesTable
              className="mt-8"
              setOpenDialog={setOpenDialog}
              setSelectedExpense={setSelectedExpense}
              isGuardian={isGuardian}
            />
            {getUserType().filter(t => t === "supervisor").length === 0 &&
            currentAdvance.state.id === 2 && (
              <OptionSpeedDial
                classes={classes}
                handleCloseDialog={handleClose}
                setAdvanceLiquidateAlert={setAdvanceLiquidateAlert}
                currentAdvance={currentAdvance}
              />
            )}
          </Box>
        )}
        <RejectionDialog
          expense={selectedExpense}
          open={openDialog}
          setOpen={setOpenDialog}
          setSelectedExpense={setSelectedExpense}
        />
      </React.Fragment>
    </FullScreenDialog>
  );
};

export default AdvanceDetailDialog;
