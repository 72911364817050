import React from 'react';
import Form from "./Form";
import {useSelector} from "react-redux";
import {cloneDeep} from "lodash";

const Edit = () => {
    const {currentDashboard} = useSelector(({controlPanel}) => controlPanel.dashboards);

    return (
        <>
            <Form
                initialData={{
                    ...cloneDeep(currentDashboard)
                }}
            />
        </>
    );
};

export default Edit;