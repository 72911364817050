import {
  APPLY_OPERATIVE_FILTERS,
  FETCH_PETTY_CASHES_SUCCEEDED,
  RESET_OPERATIVE_FILTERS
} from "../../types/Guardian/PettyCashesCustomTypes";

const INIT_STATE = {
  data: [],
  filters: {
    state: null,
    box: "",
    tags: "",
    fund: "",
    cash: "",
    traffic: "",
    liquidated: "",
    refund: "",
    currency: ""
  },
  list: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PETTY_CASHES_SUCCEEDED: {
      return {
        ...state,
        data: [ ...action.payload.data ],
        list: [ ...action.payload.list ]
      };
    }
    case APPLY_OPERATIVE_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_OPERATIVE_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    default: {
      return state;
    }
  }
};
