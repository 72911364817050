class TransferProduct {
  constructor(id, amount, label, stock) {
    this.id = id;
    this.amount = amount;
    this.label = label;
    this.stock = stock;
  }

  static map = product => {
    return new TransferProduct(
      product.nu_id_producto,
      product.nu_cantidad,
      product.vc_desc_producto,
      product.nu_stock_actual || 0
    );
  };
}

export default TransferProduct;
