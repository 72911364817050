import {FETCH_PETTY_CASH_TYPES_SUCCEEDED} from "redux/types/Administrator/PettyCashTypesCustomTypes";
import {FETCH_ALL_CURRENCIES_SUCCEEDED} from "redux/types/Common/MoneyCustomTypes";
import {
  cancelPrevFetchAll,
  cancelPrevFetchAllEntities,
  create,
  deleteCashManager,
  fetchAll,
  fetchAllEntities, getCashManagerAverages,
  update
} from "services/CashManagers";
import {
  cancelPrevFetchAllCurrencies,
  fetchAllCurrencies
} from "services/CurrencySevice";
import {
  fetchAllPettyCashTypes,
  cancelPrevPettyCashTypeFetchAll
} from "services/PettyCashTypeService";
import {cancelPrevFetchAllTags, fetchAllTags} from "services/TagService";
import {clearCashManagerEntities} from "../CustomCommon/CashManagerEntities";
import {clearCurrencies} from "../CustomCommon/Currencies";
import {clearTags} from "../CustomCommon/Tags";
import {restoreAdministratorState} from "./Common";
import ui from "./ui";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const createCashManager = data => async dispatch => {
  dispatch(ui.closeCreateForm());
  dispatch(ui.createStarted());
  try {
    dispatch(fetchStart());
    const {object, message} = await create(data);
    const userDetails = await getCashManagerAverages();
    dispatch({type: "CASH_MANAGERS_CREATE", payload: {data: object, userDetails}});
    dispatch(ui.createSucceeded());
    dispatch(fetchSuccess(message));
    setTimeout(() => dispatch(fetchCashManagers()), 1500);
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(fetchError(error.message));
  }
};

export const editCashManager = (id, data) => async dispatch => {
  const parsedData = {...data, fund: parseFloat(data.fund)};

  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await update(id, parsedData);
    const userDetails = await getCashManagerAverages();
    dispatch({type: "CASH_MANAGERS_EDIT", payload: {id, data: parsedData, userDetails}});
    dispatch(ui.editSucceeded());
    setTimeout(() => dispatch(fetchCashManagers()), 1500);
  } catch (error) {
    dispatch(ui.editFailed());
  }
};

export const fetchCashManagers = () => async dispatch => {
  dispatch(ui.fetchStarted());
  dispatch(fetchStart());
  cancelPrevFetchAllCurrencies();
  cancelPrevFetchAll();
  try {
    const currencies = await fetchAllCurrencies();
    const pettyCashTypes = await fetchAllPettyCashTypes();
    const tags = await fetchAllTags();
    const cashManagerEntities = await fetchAllEntities();
    const cashManagers = await fetchAll();
    const userDetails = await getCashManagerAverages();


    dispatch(ui.fetchSucceeded());
    dispatch(fetchSuccess());
    dispatch({
      type: FETCH_ALL_CURRENCIES_SUCCEEDED,
      payload: {data: currencies}
    });
    dispatch({
      type: FETCH_PETTY_CASH_TYPES_SUCCEEDED,
      payload: {data: pettyCashTypes}
    });
    dispatch({
      type: "CASH_MANAGERS_FETCH_SUCCEEDED",
      payload: {data: cashManagers, userDetails}
    });
    dispatch({
      type: "CASH_MANAGER_ENTITIES_FETCH_SUCCEEDED",
      payload: {
        data: cashManagerEntities.filter(entities => entities.state === "1")
      }
    });
    dispatch({type: "TAGS_FETCH_ALL_SUCCEEDED", payload: {data: tags}});
  } catch ({cancelled}) {
    !cancelled && dispatch(ui.changeStateFailed());
    !cancelled && dispatch(fetchError('Error obteniendo los datos'));
  }
};

export const changeCashManagerState = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  dispatch(ui.closeDeleteConfirmation());
  try {
    await deleteCashManager(id);
    const userDetails = await getCashManagerAverages();
    dispatch({type: "CASH_MANAGERS_DELETE", payload: {id, userDetails}});
    dispatch(ui.changeStateSucceeded());
  } catch (error) {
    dispatch(ui.changeStateFailed());
  }
};

export const applyFilters = filters => ({
  type: "CASH_MANAGERS_APPLY_FILTERS",
  payload: {filters}
});
export const resetFilters = () => ({type: "CASH_MANAGERS_RESET_FILTERS"});

const cancelAllRequests = () => {
  cancelPrevFetchAll();
  cancelPrevFetchAllCurrencies();
  cancelPrevFetchAllTags();
  cancelPrevFetchAllEntities();
  cancelPrevPettyCashTypeFetchAll();
  return ui.fetchCancelled();
};

export const resetState = () => dispatch => {
  dispatch(cancelAllRequests());
  dispatch(clearCurrencies());
  dispatch(clearTags());
  dispatch(restoreAdministratorState());
  dispatch(clearCashManagerEntities());
};

export default {
  get: fetchCashManagers,
  delete: changeCashManagerState,
  edit: editCashManager,
  create: createCashManager,
  applyFilters,
  resetFilters,
  resetState
};
