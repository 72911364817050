import React from "react";
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {minutesToTime} from "../../../../../../utils/parsers";

const BreakBox = ({minutes, label}) => {
    return minutes === null || minutes === undefined
        ? null
        : (
            <Box>
                <Typography variant="body2" color="primary">
                    {label}: {minutes < 0 ? '-' : minutesToTime(minutes)}
                </Typography>
            </Box>
        );
};

BreakBox.propTypes = {
    minutes: PropTypes.number,
    label: PropTypes.node.isRequired
};

export default BreakBox;
