import React from "react";
import {TablePagination} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const BasePagination = ({
  data,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  text = ""
}) => {
  const rows = data.length;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <TablePagination
      component="div"
      count={rows}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={
        text || <IntlMessages id={"table.footer.pagination.text"} />
      }
    />
  );
};

export default BasePagination;
