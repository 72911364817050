import {mapToBoolean} from "../../../utils/mappers";

class TachographFile {
  constructor(id, date, proc, plaque,
              card, driver, fileName,
              standard, taskStartDate,
              taskEndDate) {
    this.id = id;
    this.date = date;
    this.proc = proc;
    this.plaque = plaque;
    this.card = card;
    this.driver = driver;
    this.fileName = fileName;
    this.standard = standard;
    this.checked = false;
    this.taskStartDate = taskStartDate;
    this.taskEndDate = taskEndDate;
  }

  static map = file => {
    return new TachographFile(
      file.nu_id_fichero,
      file.dt_fecha_lectura,
      // file.dt_fecha_creacion,
      file.nu_id_procedencia === "3",
      file.vc_matricula || "",
      file.vc_tarjeta_numero || "",
      file.vc_nombre_conductor || "",
      file.vc_nombre_fichero || "",
      mapToBoolean(file.bi_corrupto),
        file.dt_fecha_inicio_actividad,
            file.dt_fecha_fin_actividad
    );
  };
}

export default TachographFile;
