import {
  IconButton,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  withStyles
} from "@material-ui/core";

export const CustomTableContainer = withStyles(theme => ({
  root: {
    boxShadow: "5px 10px 18px #4e598a",
    marginBottom: "36px"
  }
}))(TableContainer);
