import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";

const TableHeader = ({orderBy, order, onSort}) => (
  <React.Fragment>
    <StyledTableRow>
      <TableCellWithSorting
        property="description"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="administrator.logistic.receivers.table.names" />
      </TableCellWithSorting>
      <StyledTableCell>
        <IntlMessages id="administrator.logistic.receivers.table.tags" />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  </React.Fragment>
);

export default TableHeader;
