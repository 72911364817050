import React from "react";
import {useDispatch} from "react-redux";
import {addTire} from "../../../../../redux/actions/FleetManagement/Tires";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const initData = {
  tire: "",
  brand: "",
  model: "",
  measures: "",
  retreading: false,
  purchaseDate: "",
  purchaseValue: "",
  purchaseNote: "",
  saleDate: "",
  saleValue: "",
  saleNote: "",
  pressure: "",
  original: false,
  active: true,
  cocada: "",
  observations: ""
};

const AddTireDialog = ({open, setOpen}) => {
  const id = "add-tire-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addTire(data, () => setOpen(false)));
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.tires.add.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={<IntlMessages id={"fleet.management.tires.add.save"} />}
      cancelText={<IntlMessages id={"fleet.management.tires.add.cancel"} />}
    >
      <Form id={id} onSubmit={onSubmit} initialData={initData} />
    </FormDialog>
  );
};

export default AddTireDialog;
