import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import Select from "components/Common/Forms/Select";
import useForm from "hooks/Common/useForm";
import MenuItem from "@material-ui/core/MenuItem";

import RouteSelect from "containers/Administrator/RouteSelect";
import TurnSelect from "containers/Administrator/TurnSelect";

const initialData = {
  vehicle: "",
  route: null,
  turn: null
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  console.log("FORMDATA FILTER: ", formData);

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.vehicle}
            id="vehicle-filter"
            label={
              <IntlMessages id="administrator.dataCollection.filters.vehicle" />
            }
            onChange={value => setFormData("vehicle", value)}
          />
          {/*<Select
                        value={formData.vehicle}
                        fullWidth
                        id="vehicle-filter"
                        label={<IntlMessages id="administrator.dataCollection.filters.vehicle"/>}
                        onChange={e => setFormData('vehicle', e.target.value)}
                    >
                        <MenuItem value="">Seleccione un vehìculo</MenuItem>
                        <MenuItem value="BCA-200">BCA-200</MenuItem>
                        <MenuItem value="BCA-202">BCA-202</MenuItem>
                        <MenuItem value="BCS-214">BCS-214</MenuItem>
                        <MenuItem value="FVG-154">FVG-154</MenuItem>
                    </Select>*/}
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          {/*
                    <Select
                        value={formData.route}
                        fullWidth
                        id="route-filter"
                        label={<IntlMessages id="administrator.dataCollection.filters.route"/>}
                        onChange={e => setFormData('route', e.target.value)}
                    >
                        <MenuItem value="">Seleccione una ruta</MenuItem>
                        <MenuItem value="S1">S1</MenuItem>
                        <MenuItem value="S2">S2</MenuItem>
                        <MenuItem value="S3">S3</MenuItem>
                        <MenuItem value="S4">S4</MenuItem>
                        <MenuItem value="S5">S5</MenuItem>
                    </Select>
                    */}
          <RouteSelect
            id="route-filter"
            label={
              <IntlMessages id="administrator.dataCollection.filters.route" />
            }
            value={formData.route}
            onChange={value => setFormData("route", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          {/*
                    <Select
                        value={formData.turn}
                        fullWidth
                        id="turn-filter"
                        label={<IntlMessages id="administrator.dataCollection.filters.turn"/>}
                        onChange={e => setFormData('turn', e.target.value)}
                    >
                        <MenuItem value="">Seleccione un turno</MenuItem>
                        <MenuItem value="Recojo 1">Recojo 1</MenuItem>
                        <MenuItem value="Recojo 2">Recojo 2</MenuItem>
                        <MenuItem value="Recojo 3">Recojo 3</MenuItem>
                        <MenuItem value="Recojo 4">Recojo 4</MenuItem>
                        <MenuItem value="Recojo 5">Recojo 5</MenuItem>
                    </Select>
                    */}
          <TurnSelect
            id="turn-filter"
            label={
              <IntlMessages id="administrator.dataCollection.filters.turn" />
            }
            value={formData.turn}
            onChange={value => setFormData("turn", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
