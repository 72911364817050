import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {
  addConfigurationModuleSubmodule,
  resetCurrentConfigModule
} from "../../../../../redux/actions/Configuration/Modules";
import SubmodulesTable from "./SubmodulesTable";
import FloatingButtonWithAdd from "../../../../Common/Buttons/FloatingButtonWithAdd";
import AddDialog from "../AddDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Box} from "@material-ui/core";
import SubmoduleDetailDialog from "./SubmoduleDetailDialog";

const ModuleDetailDialog = ({open, setOpen}) => {
  const {current} = useSelector(({configuration}) => configuration.modules);
  const dispatch = useDispatch();
  const [ openAddSubmodule, setOpenAddSubmodule ] = React.useState(false);
  const [ openDetails, setOpenDetails ] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    dispatch(resetCurrentConfigModule());
  };
  const handleAddSubmodule = data => {
    dispatch(addConfigurationModuleSubmodule(current, data));
    setOpenAddSubmodule(false);
  };
  return (
    <FullScreenDialog
      notLanguageTitle={current.description}
      open={open}
      handleClose={() => handleClose()}
    >
      <Box m={16}>
        <SubmodulesTable setOpen={setOpenDetails} />
        <AddDialog
          id={"add-submodule-form"}
          title={
            <IntlMessages
              id={"configuration.user.settings.modules.submodules.add.title"}
            />
          }
          openAdd={openAddSubmodule}
          setOpenAdd={setOpenAddSubmodule}
          onAddHandler={handleAddSubmodule}
        />
      </Box>
      <FloatingButtonWithAdd onAddClicked={() => setOpenAddSubmodule(true)} />
      <SubmoduleDetailDialog open={openDetails} setOpen={setOpenDetails} />
    </FullScreenDialog>
  );
};

export default ModuleDetailDialog;
