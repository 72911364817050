import {combineReducers} from "redux";

import AdvanceTypes from "./AdvanceTypes";
import CashManagers from "./CashManagers";
import ExpenseTypes from "./ExpenseTypes";
import LogHistory from "./LogHistory";
import PettyCashTypes from "./PettyCashTypes";
import RejectionTypes from "./RejectionTypes";
import Supervisors from "./Supervisors";
import Guardians from "./Guardians";
import IncidenceTypes from "./IncidenceTypes";
import Warehouse from "./Warehouse";
import EntityDestinySelect from "./EntityDestinySelect";
import WarehouseEntitySelect from "./WarehouseEntitySelect";
import WarehouseCampusSelect from "./WarehouseCampusSelect";
import WarehouseOriginSelect from "./WarehouseOriginSelect";
import WarehouseDestinySelect from "./WarehouseDestinySelect";
import CampusOriginSelect from "./CampusOriginSelect";
import CampusDestinySelect from "./CampusDestinySelect";
import ProductSelect from "./ProductSelect";
import WarehouseTransfer from "./WarehouseTransfer";
import Regulation from "./Regulation";
import Product from "./Product";
import Unit from "./Unit";
import Traceability from "./Traceability";
import Stock from "./Stock";
import TransferSelect from "./WarehouseTransferSelect";
import IncidenceTypeSelect from "./IncidenceTypeSelect";
import Incidence from "./Incidence";
import MerchandiseReception from "./MerchandiseReception";
import ui from "./ui";
import Receivers from "./Receivers";
import Report from "./Report";
import Clients from "./Clients";
import WorkOrders from "./WorkOrders";
import WorkOrdersBreakdowns from "./WorkOrdersBreakdowns";
import Tags from "./Tags";
import DataCollection from "./DataCollection";
import DataCollectionHistory from "./DataCollectionHistory";
import VehicleSelect from "./VehicleSelect";
import RouteSelect from "./RouteSelect";
import TurnSelect from "./TurnSelect";
import ReportCDS from "./ReportCDS";
import DriverSelect from "./DriverSelect";
import EventSelect from "./EventSelect";
import ReportInventory from "./ReportInventory";
import ProductPicker from "./ProductPicker";
import OrdersHistory from "./OrderHistory";

export default combineReducers({
  cashManagers: CashManagers,
  expenseTypes: ExpenseTypes,
  pettyCashTypes: PettyCashTypes,
  rejectionTypes: RejectionTypes,
  advanceTypes: AdvanceTypes,
  supervisors: Supervisors,
  guardians: Guardians,
  logHistory: LogHistory,
  reports: Report,
  reportsCDS: ReportCDS,
  clients: Clients,
  tags: Tags,
  warehouseTransfer: WarehouseTransfer,
  productSelect: ProductSelect,
  entityDestiny: EntityDestinySelect,
  campusDestiny: CampusDestinySelect,
  campusOrigin: CampusOriginSelect,
  warehouseDestiny: WarehouseDestinySelect,
  warehouseOrigin: WarehouseOriginSelect,
  warehouseCampus: WarehouseCampusSelect,
  warehouseEntity: WarehouseEntitySelect,
  warehouses: Warehouse,
  incidenceTypes: IncidenceTypes,
  product: Product,
  receivers: Receivers,
  unit: Unit,
  regulation: Regulation,
  traceability: Traceability,
  stock: Stock,
  transferSelect: TransferSelect,
  incidenceTypeSelect: IncidenceTypeSelect,
  incidence: Incidence,
  merchandiseReception: MerchandiseReception,
  workOrders: WorkOrders,
  breakdown: WorkOrdersBreakdowns,
  dataCollection: DataCollection,
  dataCollectionHistory: DataCollectionHistory,
  vehicleSelect: VehicleSelect,
  routeSelect: RouteSelect,
  turnSelect: TurnSelect,
  driverSelect: DriverSelect,
  eventsSelect: EventSelect,
  reportInventory: ReportInventory,
  productPicker: ProductPicker,
  orderHistory: OrdersHistory,
  ui
});
