class Regulation {
  constructor(
    id,
    idEntityOrigin,
    entityNameOrigin,
    idCampusOrigin,
    campusNameOrigin,
    idWarehouseOrigin,
    warehouseNameOrigin,
    date,
    active,
    transfer
  ) {
    this.id = id;
    this.idEntityOrigin = idEntityOrigin;
    this.entityNameOrigin = entityNameOrigin;
    this.idCampusOrigin = idCampusOrigin;
    this.campusNameOrigin = campusNameOrigin;
    this.idWarehouseOrigin = idWarehouseOrigin;
    this.warehouseNameOrigin = warehouseNameOrigin;
    this.date = date;
    this.active = active;
    this.transfer = transfer;
    this.product = transfer.map(e => e.label);
  }

  static map = regulation => {
    return new Regulation(
      regulation.nu_id_movimiento,
      regulation.nu_id_entidad_dest,
      regulation.vc_nombre_entidad_dest,
      regulation.nu_id_entidad_sede_dest,
      regulation.vc_nombre_sede_dest,
      regulation.nu_id_almacen_dest,
      regulation.vc_desc_almacen_dest,
      regulation.dt_fecha,
      regulation.bi_estado,
      regulation.detalle.map(e => ({
        id: e.nu_id_producto,
        amount: e.nu_cantidad,
        label: e.vc_desc_producto,
        stock: e.nu_stock_actual || "0"
      }))
    );
  };
}

export default Regulation;
