import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";


const Form = ({id, onSubmit}) => {
    const {email} = useSelector(({auth}) => auth.authUser);
    const {title} = useSelector(
        ({controlPanel}) => controlPanel.kpi.current
    );
    const {formData, setFormData, handleSubmit} = useForm({
        name: '',
        email,
        cc: '',
        format: 1,
    });
    useEffect(() => {
        setFormData('name', title)
    }, []);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={3}>
                <Grid item xs={12}>
                    <OutlinedInput
                        label={<IntlMessages id='name' />}
                        name='name'
                        onChange={value => setFormData('name', value)}
                        value={formData.name}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <OutlinedInput
                        label={<IntlMessages id='email' />}
                        type='email'
                        name='email'
                        onChange={value => setFormData('email', value)}
                        value={formData.email}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <OutlinedInput
                        label={<IntlMessages id='cc' />}
                        type='email'
                        name='cc'
                        onChange={value => setFormData('cc', value)}
                        value={formData.cc}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Select
                        name='format'
                        label={<IntlMessages id='send.format' />}
                        value={formData.format}
                        onChange={e => setFormData('format', e.target.value)}
                        fullWidth
                    >
                        <MenuItem value={1}>
                            <IntlMessages id="format.pdf" />
                        </MenuItem>
                        <MenuItem value={2}>
                            <IntlMessages id="format.excel" />
                        </MenuItem>
                    </Select>
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;