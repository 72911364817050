import {fetchAllEmployeesByEntity} from "../../../services/EmployeeService";
import {FETCH_ALL_EMPLOYEES_BY_ENTITY_SUCCEEDED} from "../../types/Guardian/AdvancesCustomTypes";

const fetchAllEmployeesByEntitySucceeded = data => ({
  type: FETCH_ALL_EMPLOYEES_BY_ENTITY_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchAllEmployeesByEntityStarted = () => {
  return async dispatch => {
    fetchAllEmployeesByEntity().then(employees => {
      dispatch(fetchAllEmployeesByEntitySucceeded(employees));
    });
  };
};
