import {
  ADD_REJECTION_TYPE,
  APPLY_REJECTION_TYPES_FILTERS,
  EDIT_REJECTION_TYPE,
  FETCH_ALL_REJECTION_TYPES,
  RESET_CURRENT_REJECTION_TYPE,
  RESET_REJECTION_TYPES_FILTERS,
  RESET_REJECTION_TYPES_STATE,
  SET_CURRENT_REJECTION_TYPE
} from "../../types/Logistic/RejectionTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  createRejectionType,
  getAllRejectionTypes,
  updateRejectionType,
  updateRejectionTypeStatus
} from "../../../services/Logistics/Distribution/RejectionTypeService";
import RejectionType from "../../../domain/Logistic/Distribution/RejectionType";

export const resetRejectionTypesState = () => ({
  type: RESET_REJECTION_TYPES_STATE
});
export const applyRejectionTypesFilters = filters => ({
  type: APPLY_REJECTION_TYPES_FILTERS,
  payload: filters
});
export const resetRejectionTypesFilters = () => ({
  type: RESET_REJECTION_TYPES_FILTERS
});
export const setCurrentRejectionType = type => ({
  type: SET_CURRENT_REJECTION_TYPE,
  payload: type
});
export const resetCurrentRejectionType = () => ({
  type: RESET_CURRENT_REJECTION_TYPE
});
export const fetchAllRejectionTypes = () => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllRejectionTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_REJECTION_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addRejectionType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createRejectionType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new RejectionType(
          response.transactionKey,
          type.description,
          type.code,
          true
        );
        dispatch({type: ADD_REJECTION_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editRejectionType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateRejectionType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new RejectionType(
          response.transactionKey,
          type.description,
          type.code,
          type.status
        );
        dispatch({type: EDIT_REJECTION_TYPE, payload: editType});
        dispatch(resetCurrentRejectionType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editRejectionTypeState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateRejectionTypeStatus(type, status)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new RejectionType(
          response.transactionKey,
          type.description,
          type.code,
          status
        );
        dispatch({type: EDIT_REJECTION_TYPE, payload: editType});
        dispatch(resetCurrentRejectionType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};
