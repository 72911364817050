import React from 'react';
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import DestinationsList from "./DestinationsList";
import WorkOrderDetail from "./WorkOrderDetail";
import DestinationDetail from "./DestinationDetail";
import CircularLoader from "../../../Common/ProgressBar/CircularLoader";
import {getDispatchDetails} from "../../../../services/WorkOrdersService";

const WorkOrderDialog = ({open, handleClose, current, noEdit = false, preSelectDestination = null}) => {

    const [selectedDestination, setSelectedDestination] = React.useState(preSelectDestination);
    const [destinationDetail, setDestinationDetail] = React.useState(null);

    React.useEffect(() => {
        if(current?.destinations.length > 0) {
            setSelectedDestination(0);
        }
    }, [current]);

    React.useEffect(() => {
        if(selectedDestination === null) {
            setDestinationDetail(null);
        }else if (current?.destinations?.length > 25) {
            getDispatchDetails(current?.destinations[selectedDestination].id)
                .then(destination => {
                    setDestinationDetail(destination);
                }).catch(e => {
                console.error(e);
            });
        }else {
            setDestinationDetail(current?.destinations[selectedDestination] || selectedDestination)
        }
    }, [selectedDestination]);

    return (
        <FullScreenDialog open={open} handleClose={handleClose} title="logistic.work.orders.dialog.title">
            {current === null ?
                <Box display="flex" justifyContent="center" mt={32}>
                    <CircularLoader/>
                </Box> :
                <GridContainer spacing={0}>
                    <Grid item sm={12} md={6}>
                        <Box display="flex" flexDirection="column">
                            <WorkOrderDetail
                                current={current}
                                noEdit={noEdit}
                            />
                            <DestinationsList
                                noEdit={noEdit}
                                setDestination={setSelectedDestination}
                                isNewWorkOrder={current.id === 0}
                                destinations={current?.destinations || []}
                                selected={selectedDestination}
                                setSelected={setSelectedDestination}/>
                        </Box>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        {destinationDetail !== null &&
                            <DestinationDetail
                                noEdit={noEdit}
                                setSelectToNew={() => setSelectedDestination(current.destinations.length)}
                                destination={destinationDetail}
                                index={(typeof selectedDestination === 'object') ?
                                    current?.destinations.length :
                                    selectedDestination}
                                cancelAdd={() => setSelectedDestination(null)}
                            />}
                    </Grid>
                </GridContainer>}
        </FullScreenDialog>
    );
}

export default WorkOrderDialog;