import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles(theme => ({
  tabContainer: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`
  },
  tabRoot: {
    fontSize: 12,
    letterSpacing: 1.25,
    minWidth: 50,
    width: "50%"
  }
}));
