import React from "react";
import DialogBase from "../../../../Common/Dialogs/DialogBase";
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import SelectExpensesTable from "./SelectExpensesTable";
import {makeStyles} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {addExpensesToAccountingStarted} from "../../../../../redux/actions/Guardians/Expenses";

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: "1100px",
    [theme.breakpoints.down("sm")]: {
      minWidth: "900px"
    }
  }
}));

const AddExpenseDialog = ({open, setOpen}) => {
  const classes = useStyles();
  const [ selectedExpenses, setSelectedExpenses ] = React.useState([]);
  const dispatch = useDispatch();
  const {currentAccounting, accountingList} = useSelector(
    state => state.guardian
  );
  const handleSubmit = () => {
    dispatch(
      addExpensesToAccountingStarted(
        currentAccounting,
        selectedExpenses,
        accountingList.filters
      )
    );
    setOpen(false);
    setSelectedExpenses([]);
  };
  return (
    <DialogBase
      classes={{paper: classes.paper}}
      title={
        <IntlMessages
          id={"guardian.accounting.dialog.add.expense.dialog.title"}
        />
      }
      close={() => {
        setOpen(false);
        setSelectedExpenses([]);
      }}
      open={open}
      actions={
        <React.Fragment>
          <Button
            color="primary"
            onClick={() => {
              setOpen(false);
              setSelectedExpenses([]);
            }}
          >
            <IntlMessages
              id={"guardian.accounting.dialog.add.expense.dialog.cancel"}
            />
          </Button>
          <Button color="primary" onClick={() => handleSubmit()}>
            <IntlMessages
              id={"guardian.accounting.dialog.add.expense.dialog.accept"}
            />
          </Button>
        </React.Fragment>
      }
      isLoading={false}
    >
      <SelectExpensesTable
        selectedExpenses={selectedExpenses}
        setSelectedExpenses={setSelectedExpenses}
      />
    </DialogBase>
  );
};

export default AddExpenseDialog;
