import React from "react";
import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from '@material-ui/core/Grid';
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import useForm from "hooks/Common/useForm";
import Select from "components/Common/Forms/Select";
import MenuItem from '@material-ui/core/MenuItem';

import validation from "./validation";

const Form = ({id, initialData, onSubmit}) => {
    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            vehicle: initialData?.vehicle || '',
            start: initialData?.start || '',
            end: initialData?.end || '',
            route: initialData?.route || '',
            turn: initialData?.turn || '',
            passengers: initialData?.passengers || '',
            firstPickup: initialData?.firstPickUp || '',
            lastPickup: initialData?.lastPickUp || '',
            disinfection: initialData?.disinfection || '',
        },
        validation,
    );

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        value={formData.vehicle}
                        hasError={errors.vehicle.length > 0}
                        error={errors.vehicle[0]}
                        fullWidth
                        disabled
                        id="vehicle"
                        label={<IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.vehicle"/>}
                        onChange={value => setFormData('vehicle', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Select
                        value={formData.route}
                        fullWidth
                        id="route"
                        label={<IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.route"/>}
                        onChange={e => setFormData('route', e.target.value)}
                    >
                        <MenuItem value="">Seleccione una ruta</MenuItem>
                        <MenuItem value="S1">S1</MenuItem>
                        <MenuItem value="S2">S2</MenuItem>
                        <MenuItem value="S3">S3</MenuItem>
                        <MenuItem value="S4">S4</MenuItem>
                        <MenuItem value="S5">S5</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <Select
                        value={formData.turn}
                        fullWidth
                        id="turn"
                        label={<IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.turn"/>}
                        onChange={e => setFormData('turn', e.target.value)}
                    >
                        <MenuItem value="">Seleccione un turno</MenuItem>
                        <MenuItem value="Recojo 1">Recojo 1</MenuItem>
                        <MenuItem value="Recojo 2">Recojo 2</MenuItem>
                        <MenuItem value="Recojo 3">Recojo 3</MenuItem>
                        <MenuItem value="Recojo 4">Recojo 4</MenuItem>
                        <MenuItem value="Recojo 5">Recojo 5</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}></Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.initDate"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        value={formData.start}
                        hasError={errors.vehicle.start > 0}
                        error={errors.start[0]}
                        fullWidth
                        id="start"
                        label={<IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.start"/>}
                        onChange={value => setFormData('start', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.passengers"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        value={formData.passengers}
                        hasError={errors.passengers.length > 0}
                        error={errors.passengers[0]}
                        fullWidth
                        id="passengers"
                        label={<IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.passengers"/>}
                        onChange={value => setFormData('passengers', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.endDate"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        value={formData.end}
                        hasError={errors.end.length > 0}
                        error={errors.end[0]}
                        fullWidth
                        id="end"
                        label={<IntlMessages id="administrator.dataCollection.saveDataCollectionDialog.field.end"/>}
                        onChange={value => setFormData('end', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.firstPickup"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.firstPickup}
                        id="firstPickup"
                        label="Primer Recojo"
                        onChange={value => setFormData('firstPickup', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.disinfection"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.disinfection}
                        id="disinfection"
                        label="Fecha Desinfección"
                        onChange={value => setFormData('disinfection', value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <IntlMessages id="administrator.dataCollectionHistory.filters.lastPickup"/>
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.lastPickup}
                        id="lastPickup"
                        label="Último Recojo"
                        onChange={value => setFormData('lastPickup', value)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;