class CurrentDay {
  constructor(id, name, idEntity, startDate, endDate, totalWorked, totalPause) {
    this.id = id;
    this.name = name;
    this.idEntity = idEntity;
    this.startDate = new Date(startDate).toLocaleTimeString();
    this.endDate =
      endDate !== "" ? new Date(endDate).toLocaleDateString() : endDate;
    this.totalWorked = totalWorked;
    this.totalPause = totalPause;
  }

  static map = currentDay => {
    return new CurrentDay(
      currentDay.nu_id_entidad,
      currentDay.vc_nombre,
      currentDay.nu_id_entidad_empresa,
      currentDay.dt_fec_ini,
      currentDay.dt_fec_fin,
      currentDay.vc_tiempo_trabajado,
      currentDay.vc_tiempo_pausa
    );
  };
}

export default CurrentDay;
