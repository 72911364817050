import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import useForm from "../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {
  applyInsurancesFilters,
  resetInsurancesState
} from "../../../../../../redux/actions/FleetManagement/Insurances";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";

const Filters = () => {
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.insurances
  );
  const {formData, setFormData, resetForm} = useForm(filters);
  const dispatch = useDispatch();
  const onApplyFilters = () => {
    dispatch(applyInsurancesFilters(formData));
  };
  const onResetFilters = () => {
    resetForm();
    dispatch(resetInsurancesState());
  };
  return (
    <Box my={4}>
      <FiltersContainer active onClose={() => {}}>
        <form>
          <GridContainer>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"insurance-plaque"}
                fullWidth
                value={formData.plaque}
                onChange={value => setFormData("plaque", value)}
                label={
                  <IntlMessages
                    id={"fleet.management.insurance.table.plaque"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"insurance-policy-number"}
                fullWidth
                value={formData.policy}
                onChange={value => setFormData("policy", value)}
                label={
                  <IntlMessages
                    id={"fleet.management.insurance.table.policy.number"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedDatePicker
                id={"insurance-commencement-date"}
                fullWidth
                value={formData.commencementDate}
                onChange={value => setFormData("commencementDate", value)}
                label={
                  <IntlMessages
                    id={"fleet.management.insurance.table.commencement.date"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedDatePicker
                id={"insurance-expiration-date"}
                fullWidth
                value={formData.expirationDate}
                onChange={value => setFormData("expirationDate", value)}
                label={
                  <IntlMessages
                    id={"fleet.management.insurance.table.expiration.date"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OutlinedInput
                id={"insurance-premium"}
                fullWidth
                value={formData.premium}
                onChange={value => setFormData("premium", value)}
                label={
                  <IntlMessages
                    id={"fleet.management.insurance.table.premium"}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={3} lg={2}>
              <ContainedButton
                onClick={e => {
                  e.preventDefault();
                  onResetFilters();
                }}
                text={<IntlMessages id={"filters.button.clear"} />}
              />
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <ContainedButton
                onClick={e => {
                  e.preventDefault();
                  onApplyFilters();
                }}
                color="primary"
                text={<IntlMessages id={"filters.button.apply"} />}
              />
            </Grid>
          </GridContainer>
        </form>
      </FiltersContainer>
    </Box>
  );
};

export default Filters;
