import React from 'react';
import PropTypes from 'prop-types';
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => {
    return (
        <StyledTableRow>
            <StyledTableCell>
                <IntlMessages id={"fleet.management.workshop.request.form.tasks.table.type"} />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id={"fleet.management.workshop.request.form.tasks.table.description"} />
            </StyledTableCell>
           <StyledTableCell>
                <IntlMessages id={"fleet.management.workshop.request.form.tasks.table.cost"} />
            </StyledTableCell>
            <StyledTableCell>
                <IntlMessages id={"fleet.management.workshop.request.form.tasks.table.status"} />
            </StyledTableCell>
            <StyledTableCell />
        </StyledTableRow>
    );
};

TableHeader.propTypes = {

};

export default TableHeader;