import Module from "./Module";

class Organization {
  constructor(id, description, permissions, state) {
    this.id = id;
    this.description = description;
    this.state = state;
    this.permissions = permissions;
  }

  static map = organization => {
    return new Organization(
      organization.nu_id_organizacion,
      organization.vc_desc_organizacion,
      organization.modules.map(m => Module.map(m)),
      organization.bi_estado === undefined ? true : organization.bi_estado
    );
  };
}

export default Organization;
