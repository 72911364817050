import React from "react";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";

const EditTagDialog = ({isOpen, onClose, onSubmit, initialData}) => {
  const id = "edit-tag-form";
  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      title={<IntlMessages id="administrator.tags.edit.form.title" />}
    >
      {initialData && (
        <Form id={id} initialData={initialData} onSubmit={onSubmit} />
      )}
    </FormDialog>
  );
};
export default EditTagDialog;
