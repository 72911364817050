import React from "react";
import PropTypes from "prop-types";

import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "components/Common/Tables/CrudTable/RestoreTableRow";
import TableValues from "./TableValues";
import TableFooter from "./TableFooter";
import TableHeader from "./TableHeader";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";

const Guardians = ({data, onEdit, onDelete, onRestore, isLoading}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const RenderRow = ({row}) => (
    <CrudTableRow
      onEdit={() => onEdit(row.id)}
      onDelete={() => onDelete(row.id)}
    >
      <TableValues row={row} />
    </CrudTableRow>
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      footer={<TableFooter data={data} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Guardians.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      active: PropTypes.bool
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func
};

Guardians.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {}
};

export default Guardians;
