import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Workshop from "../../../domain/FleetManagement/DailyOperations/Workshop";
import Response from "../../../domain/Response";
import {generateFileObject} from "../../../utils/files";
import WorkshopState from "../../../domain/FleetManagement/DailyOperations/WorkshopState";


export const getAllWorkshopStates = async () => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/sel_estados_taller", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(d => WorkshopState.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};


export const getAllWorkshops = async () => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/sel_taller", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(d => Workshop.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getAllWorkshopsById = async (workshop) => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/get_taller", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_taller: workshop.id
        });
        return Workshop.map(data);
    } catch (e) {
        return Promise.reject(e);
    }
};

export const createWorkshop = async workshop => {
    try {
        const files = await Promise.all(
            workshop.files.map(async f => ({
                ...f,
                file: await generateFileObject(f.file)
            }))
        );
        const {data} = await axiosInstance.post("/VehiculoTaller/ins_taller", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_vehiculo: workshop.vehicle,
            nu_kilometraje: workshop.kilometer,
            nu_id_taller_proveedor: workshop.workshop,
            vc_orden_trabajo: workshop.ot,
            dt_fec_entrada: workshop.entryDate,
            dt_fec_prev_salida: workshop.expectedExitDate,
            dt_fec_real_salida: workshop.exitDate,
            nu_total_facturado: workshop.amount,
            nu_id_taller_estado: workshop.state,
            vc_observaciones: workshop.observations,
            ls_documentos: files.map(f => ({
                archivobase64: f.file.base64File,
                contentType: f.file.contentType,
                extension: f.file.extension,
                nombre: f.file.name.split(".")[0]
            })),
            ls_trabajos: workshop.tasks.map(t => ({
                nu_id_taller_trabajos: null,
                nu_id_tipo_trabajo: t.type,
                nu_id_trabajo_informacion: t.information,
                vc_observaciones: t.observations,
                vc_observaciones_taller: t.workshopObservations,
                nu_costo: t.cost,
                nu_id_solicitud_taller_trabajo_estado: t.status
            }))
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateWorkshop = async workshop => {
    const files = await Promise.all(
        workshop.files.filter(f => f.id === 0).map(async f => ({
            ...f,
            file: await generateFileObject(f.file)
        }))
    );
    try {
        const {data} = await axiosInstance.post("/VehiculoTaller/upd_taller", {
            nu_id_taller: workshop.id,
            nu_id_vehiculo: workshop.vehicle,
            nu_kilometraje: workshop.kilometer,
            nu_id_taller_proveedor: workshop.workshop,
            vc_orden_trabajo: workshop.ot,
            dt_fec_entrada: workshop.entryDate,
            dt_fec_prev_salida: workshop.expectedExitDate,
            dt_fec_real_salida: workshop.exitDate,
            nu_total_facturado: workshop.amount,
            nu_id_taller_estado: workshop.state,
            vc_observaciones: workshop.observations,
            ls_documentos: files.map(f => ({
                archivo: {
                    archivobase64: f.file.base64File,
                    contentType: f.file.contentType,
                    extension: f.file.extension,
                    nombre: f.file.name.split(".")[0]
                }
            })),
            ls_trabajos: workshop.tasks.map(t => {
                if(t.id === 0) {
                    return {
                        nu_id_taller_trabajos: null,
                        nu_id_tipo_trabajo: t.type,
                        nu_id_trabajo_informacion: t.information,
                        vc_observaciones: t.observations,
                        vc_observaciones_taller: t.workshopObservations,
                        nu_costo: t.cost,
                        nu_id_solicitud_taller_trabajo_estado: t.status
                    }
                }
                return {
                    nu_id_taller_trabajos: t.id,
                    nu_id_tipo_trabajo: t.type.id || t.type,
                    nu_id_trabajo_informacion: t.information.id || t.information,
                    vc_observaciones: t.observations,
                    vc_observaciones_taller: t.workshopObservations,
                    nu_costo: t.cost,
                    nu_id_solicitud_taller_trabajo_estado: t.status.id || t.status
                }
            } )
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateWorkshopStatus = async (type, status) => {
    try {
        const {data} = await axiosInstance.post("/VehiculoTipoSiniestro/del", {
            nu_id_tipo_siniestro: type.id,
            bi_estado: status
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};
