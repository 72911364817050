class EntitySelect {
  constructor(id, entity, active) {
    this.id = id;
    this.entity = entity;
    this.active = active;
  }

  static map = entity => {
    return new EntitySelect(
      entity.nu_id_entidad,
      entity.vc_nombre,
      entity.bi_estado === "1"
    );
  };
}

export default EntitySelect;
