import React, { useState, useMemo, useEffect } from "react";

import GridContainer from '@jumbo/components/GridContainer';
import Grid from "@material-ui/core/Grid";
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import FileInput from "components/Common/Forms/FileInput";

import useForm from "hooks/Common/useForm";
import IntlMessages from "@jumbo/utils/IntlMessages";

import validations from './validations';
import WarehouseOriginSelect from 'containers/Administrator/WarehouseOriginSelect';
import EntityDestinySelect from 'containers/Administrator/EntityDestinySelect';
import EntityOriginSelect from "containers/Administrator/EntityOriginSelect";
import WarehouseDestinySelect from 'containers/Administrator/WarehouseDestinySelect';
import CampusDestinySelect from 'containers/Administrator/CampusDestinySelect';
import CampusOriginSelect from 'containers/Administrator/CampusOriginSelect';
import ProductSelect from 'containers/Administrator/ProductSelect';
import DeleteConfirmation from 'components/Administrator/WarehouseTransfer/SaveWarehouseTransferDialog/Form/Confirmation/DeleteConfirmation';

import { Box, Button } from "@material-ui/core";
import Table from './Table';
import useUiState from "hooks/Administrator/useUiState";

import {getCompanyEntityId} from '../../../../../services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import selectEntity from 'redux/actions/Administrator/WarehouseEntitySelect';
import {getAllProductsByWarehouseTransferId} from "../../../../../services/WarehouseTransfer";
import {fetchError, fetchStart, fetchSuccess} from "../../../../../redux/actions";

const Form = ({id,initialData, onSubmit}) => {
    const [product, setProduct] = useState(null);
    //const [focusAmount, setFocusAmount] = useState(true);
    const dispatch = useDispatch();
    const {data} = useSelector(state => state.administrator.warehouseEntity);
    const {selectedCompany} = useSelector(({auth}) => auth);
    //console.log("INITIALDATA: ",initialData);
    /*const initData = useMemo(()=>{
        const obj = {
            entityOrigin: 'San Fernando',
            campusOrigin: initialData ? {value: initialData.idCampusOrigin, label: initialData.campusNameOrigin} : null,
            warehouseOrigin: initialData ? {value: initialData.idWarehouseOrigin, label: initialData.warehouseNameOrigin} : null,
            entityDestiny: initialData ? {value: initialData.idEntityDestiny, label: initialData.entityNameDestiny} : null,
            campusDestiny: initialData ? {value: initialData.idCampusDestiny, label: initialData.campusNameDestiny} : null,
            warehouseDestiny: initialData ? {value: initialData.idWarehouseDestiny, label: initialData.warehouseNameDestiny} : null,
            products: initialData ? initialData.transfer : [],
        };
        return obj;
    }, [initialData]);*/
    React.useEffect(() => {
        console.log(selectedCompany);
    }, []);
    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            //entityOrigin: ({value: getCompanyEntityId()}),
            // entityOrigin: initialData ? ({value: initialData.idEntityOrigin, label: initialData.entityNameOrigin}) : ({value: selectedCompany.id, label: selectedCompany.name}),
            entityOrigin: {value: selectedCompany.id, label: selectedCompany.name},
            //campusOrigin: initialData?.idCampusOrigin || null,
            campusOrigin: initialData ? ({value: initialData.idCampusOrigin, label: initialData.campusNameOrigin}) : null,            
            //warehouseOrigin: initialData?.idWarehouseOrigin || null,
            warehouseOrigin: initialData ? ({value: initialData.idWarehouseOrigin, label: initialData.warehouseNameOrigin}) : null,
            //entityDestiny: initialData?.idEntityDestiny || null,
            entityDestiny: initialData ? ({value: initialData.idEntityDestiny, label: initialData.entityNameDestiny}) : null,
            //campusDestiny: initialData?.idCampusDestiny || null,
            campusDestiny: initialData ? ({value: initialData.idCampusDestiny, label: initialData.campusNameDestiny}) : null,
            //warehouseDestiny: initialData?.idWarehouseDestiny || null,
            warehouseDestiny: initialData ? ({value: initialData.idWarehouseDestiny, label: initialData.warehouseNameDestiny}) : null,
            products: initialData?.transfer || [],
            //amount: initialData?.amount || '',
            //añadir arreglo
        },
        validations,
    );

    const ui = useUiState();

    const handleOnCLick = () => {
        //setFocusAmount(true);        
        let copy = formData.products.length>0 ? [...formData.products] : [];
        const val = copy.some(e => Number(e.value) === Number(product.value));
        if (product.value && product !== null && product.value!== "" && !val){
            copy.push({...product, amount: 0, id: copy.length+1});
            setFormData('products', copy);
            setProduct({});
        }    
    }

    const handleAmount = (row, amount, stock) => {
        if (Number(amount) < 0){
            amount = 0;
            let copy = [...formData.products];
            const pos = copy.findIndex(item => item.id===row.id);
            copy[pos].amount = amount;
            setFormData('products', copy);
        }else{
            if(Number(amount) < Number(stock)){
                let copy = [...formData.products];
                const pos = copy.findIndex(item => item.id===row.id);
                copy[pos].amount = amount;
                setFormData('products', copy);
            }else{
                amount = stock;
                let copy = [...formData.products];
                const pos = copy.findIndex(item => item.id===row.id);
                copy[pos].amount = amount;
                setFormData('products', copy);
            }        
        }
    }

    React.useEffect(() => {
        if(initialData?.id) {
            dispatch(fetchStart());
            getAllProductsByWarehouseTransferId(initialData.id)
                .then(list => {
                    dispatch(fetchSuccess());
                    setFormData('products', list);
                }).catch(e => {
                dispatch(fetchError(e.message));
            });
        }
    }, []);
    
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={4}>
                    <OutlinedInput
                        value={formData.entityOrigin.label}
                        fullWidth
                        disabled
                        id="entityOrigin"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityOrigin"/>}
                    />
                    {/*<EntityOriginSelect*/}
                    {/*    id="entityOrigin"*/}
                    {/*    label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityOrigin"/>}*/}
                    {/*    value={formData.entityOrigin}*/}
                    {/*    onChange={value => {console.log("***VALUE SELECT: ", value); setFormData('entityOrigin',value)}}*/}
                    {/*/>*/}
                </Grid>    
                <Grid item xs={4}>
                    <CampusOriginSelect
                        id="campus-origin"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusOrigin"/>}
                        value={formData.campusOrigin}
                        hasError={errors.campusOrigin.length > 0}
                        error={errors.campusOrigin[0]}
                        onChange={value => setFormData('campusOrigin',value)}
                        entityId={getCompanyEntityId()}
                    />
                </Grid>
                <Grid item xs={4}>
                    <WarehouseOriginSelect
                        id="warehouse-origin"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseOrigin"/>}
                        value={formData.warehouseOrigin}
                        hasError={errors.warehouseOrigin.length > 0}
                        error={errors.warehouseOrigin[0]}
                        onChange={value => setFormData('warehouseOrigin',value)}
                        entityId={getCompanyEntityId()}
                        campusId={formData.campusOrigin}
                    />
                </Grid>
                <Grid item xs={4}>
                    <EntityDestinySelect
                        id="entity-destiny"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.entityDestiny"/>}
                        value={formData.entityDestiny}
                        hasError={errors.entityDestiny.length > 0}
                        error={errors.entityDestiny[0]}
                        onChange={value => setFormData('entityDestiny',value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <CampusDestinySelect
                        id="campus-destiny"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.campusDestiny"/>}
                        value={formData.campusDestiny}
                        hasError={errors.campusDestiny.length > 0}
                        error={errors.campusDestiny[0]}
                        onChange={value => setFormData('campusDestiny',value)}
                        entityId={formData.entityDestiny}
                    />
                </Grid>
                <Grid item xs={4}>
                    <WarehouseDestinySelect
                        id="warehouse-destiny"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.warehouseDestiny"/>}
                        value={formData.warehouseDestiny}
                        hasError={errors.warehouseDestiny.length > 0}
                        error={errors.warehouseDestiny[0]}
                        onChange={value => setFormData('warehouseDestiny',value)}
                        entityId={formData.entityDestiny}
                        campusId={formData.campusDestiny}
                        warehouseId={formData.warehouseOrigin}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ProductSelect
                        id="product"
                        label={<IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.field.product"/>}
                        value={product}
                        // hasError={errors.product.length > 0}
                        // error={errors.product[0]}
                        // onChange={value => console.log("product value ", value)} //label, id, stock
                        onChange={value => {//setFocusAmount(false); 
                            setProduct(value)}}
                        // onChange={value => setFormData('product',value)  || setProduct(productData.id = productDataList.length + 1,
                        //     productData.product = value.label,
                        //     productData.stock = value.stock) || console.log("VALUE: ",value)}
                        warehouseId={formData.warehouseOrigin}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button onClick={() => handleOnCLick()}>Agregar</Button>
                </Grid>    
                <Grid item xs={12}>
                    <Box marginBottom={25}>
                        <Table data={formData.products} handleAmount={handleAmount} setData={value => setFormData('products', value)} errors={errors} {...ui.tableProps}/>
                    </Box>
                    {/*<DeleteConfirmation onAccept={onDelete2} {...ui.deleteConfirmationProps} />*/}
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form