import {FETCH_ALL_ADVANCES_STATES_SUCCEEDED} from "../../types/Guardian/AdvancesCustomTypes";

const INIT_STATE = {
  data: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_ADVANCES_STATES_SUCCEEDED: {
      return {
        ...state,
        data: [ ...action.payload.data ]
      };
    }

    default: {
      return state;
    }
  }
};
