import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import {validations} from "./validations";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import {useDispatch} from "react-redux";
import {createCustomDashboard} from "../../../../../redux/thunks/ControlPanel/customDashboards";
import {useHistory, useRouteMatch} from "react-router";

const initialData = {
    name: ''
}

const Form = () => {
    const id = 'create-dashboard';
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    const dispatch = useDispatch();
    const history = useHistory();
    const {url} = useRouteMatch();
    const onSubmit = (data) => {
        dispatch(createCustomDashboard(data))
            .unwrap()
            .then(() =>{
                history.replace(url.replace("creator", "editor"));
            });
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        label={<IntlMessages id='name' />}
                        value={formData.name}
                        onChange={value => setFormData('name', value)}
                        hasError={errors?.name?.length > 0}
                        error={errors?.name?.[0]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4} >
                    <ContainedButton
                        form={id}
                        text={<IntlMessages id='create.panel' />}
                        type='submit'
                        color='primary'
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;