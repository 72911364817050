import React from "react";
import {Box} from "@material-ui/core";
import {useStyles} from "../styles";
import GroupItem from "./GroupItem";

const GroupList = ({groups}) => {
  const classes = useStyles();
  return (
    <Box>
      {groups.map(group => (
        <GroupItem group={group} key={`group-${group.id}`} />
      ))}
    </Box>
  );
};

export default GroupList;
