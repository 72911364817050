import React from "react";
import PropTypes from "prop-types";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import useTableSort from "hooks/Common/useTableSort";
import TableHeader from "./TableHeader";
import TableComponent from "components/Common/Tables/Table";
import {parseNumberToMoney} from "utils/parsers";
import moment from "moment";

const Table = ({data, isLoading, onFileNameClick}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  const RenderRow = ({row}) => (
    <StyledTableRow>
      <StyledTableCell>
        {moment(row.date).format("DD/MM/YY HH:mm")}
      </StyledTableCell>
      <StyledTableCell>{row.cashManager}</StyledTableCell>
      <StyledTableCell>{row.action}</StyledTableCell>
      <StyledTableCell>{parseNumberToMoney(row.amount)}</StyledTableCell>
      <StyledTableCell>
        {row.tags.map((tag, index) => <div key={index}>{tag}</div>)}
      </StyledTableCell>
      <StyledTableCell>
        <p
          style={{
            color: "blue",
            cursor: "pointer",
            textDecoration: "underline",
            width: "200px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap"
          }}
          onClick={() => onFileNameClick(row.file.name)}
        >
          {row.file.name}
        </p>
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      date: PropTypes.instanceOf(Date),
      cashManager: PropTypes.string,
      action: PropTypes.string,
      amount: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      file: PropTypes.object
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  onFileNameClick: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {},
  onFileNameClick: () => {}
};

export default Table;
