import {closeCreateForm, closeEditForm} from "./ui";
import {
  cancelPrevUnitFetchAll,
  fetchAllUnits
} from "../../../services/UnitService";
import {
  APPLY_UNIT_FILTERS,
  FETCH_UNIT_SUCCEEDED,
  UNIT_CHANGE_STATE,
  UNIT_CREATE,
  UNIT_EDIT,
  RESET_UNIT_FILTERS
} from "../../types/Administrator/UnitCustomTypes";

import {restoreAdministratorState} from "./Common";
import ui from "./ui";

import Unit from "../../../domain/Unit";

export const resetState = () => dispatch => {
  cancelPrevUnitFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchUnitsSucceeded = data => ({
  type: FETCH_UNIT_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchUnits = () => {
  return async dispatch => {
    dispatch(ui.fetchStarted());
    cancelPrevUnitFetchAll();
    fetchAllUnits().then(unit => {
      dispatch(ui.fetchSucceeded());
      dispatch(fetchUnitsSucceeded(unit));
    });
  };
};

export default {
  get: fetchUnits,
  resetState
};
