import {
  ADMINISTRATOR_ERROR_ON_TRANSACTION,
  ADMINISTRATOR_RESET_ON_ERROR,
  ERROR_ON_TRANSACTION,
  RESET_ON_ERROR
} from "../../types/Administrator/UiTypes";

const INIT_STATE = {
  deletingId: null,
  restoringId: null,
  editingId: null,
  deleteConfirmationIsOpen: false,
  restoreConfirmationIsOpen: false,
  editFormIsOpen: false,
  createFormIsOpen: false,
  isLoadingData: false,
  isChangingState: false,
  isEditing: false,
  isCreating: false,
  onError: {
    message: "",
    error: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case "ADMINISTRATOR_OPEN_DELETE_CONFIRMATION": {
      return {
        ...state,
        deletingId: action.payload.id,
        deleteConfirmationIsOpen: true
      };
    }
    case "ADMINISTRATOR_CLOSE_DELETE_CONFIRMATION": {
      return {
        ...state,
        deletingId: null,
        deleteConfirmationIsOpen: false
      };
    }
    case "ADMINISTRATOR_OPEN_RESTORE_CONFIRMATION": {
      return {
        ...state,
        restoringId: action.payload.id,
        restoreConfirmationIsOpen: true
      };
    }
    case "ADMINISTRATOR_CLOSE_RESTORE_CONFIRMATION": {
      return {
        ...state,
        restoringId: null,
        restoreConfirmationIsOpen: false
      };
    }
    case "ADMINISTRATOR_OPEN_EDIT_FORM": {
      return {
        ...state,
        editingId: action.payload.id,
        editFormIsOpen: true
      };
    }
    case "ADMINISTRATOR_CLOSE_EDIT_FORM": {
      return {
        ...state,
        editFormIsOpen: false,
        editingId: null
      };
    }
    case "ADMINISTRATOR_OPEN_CREATE_FORM": {
      return {
        ...state,
        createFormIsOpen: true
      };
    }
    case "ADMINISTRATOR_CLOSE_CREATE_FORM": {
      return {
        ...state,
        createFormIsOpen: false
      };
    }
    case "ADMINISTRATOR_FETCH_STARTED": {
      return {
        ...state,
        isLoadingData: true
      };
    }
    case "ADMINISTRATOR_FETCH_SUCCEEDED": {
      return {
        ...state,
        isLoadingData: false
      };
    }
    case "ADMINISTRATOR_FETCH_FAILED": {
      return {
        ...state,
        isLoadingData: false
      };
    }
    case "ADMINISTRATOR_FETCH_CANCELLED": {
      return {
        ...state,
        isLoadingData: false
      };
    }
    case "ADMINISTRATOR_CHANGE_STATE_STARTED": {
      return {
        ...state,
        isChangingState: true
      };
    }
    case "ADMINISTRATOR_CHANGE_STATE_SUCCEEDED": {
      return {
        ...state,
        isChangingState: false
      };
    }
    case "ADMINISTRATOR_CHANGE_STATE_FAILED": {
      return {
        ...state,
        isChangingState: false
      };
    }
    case "ADMINISTRATOR_EDIT_STARTED": {
      return {
        ...state,
        isEditing: true
      };
    }
    case "ADMINISTRATOR_EDIT_SUCCEEDED": {
      return {
        ...state,
        isEditing: false
      };
    }
    case "ADMINISTRATOR_EDIT_FAILED": {
      return {
        ...state,
        isEditing: false
      };
    }
    case "ADMINISTRATOR_CREATE_STARTED": {
      return {
        ...state,
        isCreating: true
      };
    }
    case "ADMINISTRATOR_CREATE_SUCCEEDED": {
      return {
        ...state,
        isCreating: false
      };
    }
    case "ADMINISTRATOR_CREATE_FAILED": {
      return {
        ...state,
        isCreating: false
      };
    }
    case ADMINISTRATOR_ERROR_ON_TRANSACTION: {
      return {
        ...state,
        onError: {
          message: action.payload,
          error: true
        }
      };
    }
    case ADMINISTRATOR_RESET_ON_ERROR: {
      return {
        ...state,
        onError: {
          ...INIT_STATE.onError
        }
      };
    }

    default: {
      return state;
    }
  }
};
