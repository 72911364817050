import React from 'react';
import {useSelector} from "react-redux";
import Form from "../common/Form";
import NestedList from "../common/NestedList";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";


const Vehicle = () => {
    const {
        summary, activities, eventsAndFails, technicalData, detailedSpeeds
    } = useSelector(({fleetManagement}) => fleetManagement.filesReader?.fileData);
    return (
        <>
            <Form
                title={<IntlMessages id='summary' />}
                data={summary}
            />
            <NestedList
                title={<IntlMessages id='activities' />}
                data={activities}
                subElementKey='Fecha'
            />
            <Form
                title={<IntlMessages id='events.and.fails' />}
                data={eventsAndFails}
            />
            <NestedList
                title={<IntlMessages id='detailed.speeds'/>}
                data={detailedSpeeds}
                subElementKey='Fecha'
            />
            <Form
                title={<IntlMessages id='technical.data' />}
                data={technicalData}
            />
        </>
    );
};

export default Vehicle;