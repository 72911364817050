import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import FleetManagementFile from "../../../../../domain/FleetManagementFile";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import validations from "./validations";
import {parseDateFromInput} from "../../../../../utils/dates";


const initialData = {
    file: null,
    expirationDate: '',
    description: '',
    notice: ''
}

const FilesDialog = ({id, listFiles, setListFiles, open, setOpen}) => {
    const {formData, setFormData, errors, handleSubmit} = useForm(initialData, validations);
    const onAddNewFiles = data => {
        const newFile = new FleetManagementFile(
            0,
            "",
            data.file.name,
            data.description,
            parseDateFromInput(data.expirationDate),
            "",
            data.file.file || data.file,
            data.notice,
        );
        setListFiles([...listFiles, newFile]);
        setOpen(false);
    }

    const onSaveFile = data => {
        onAddNewFiles(data);
    }

    return(
        <FormDialog
            id={id}
            open={open}
            onClose={() => setOpen(false)}
            title={<IntlMessages id={"fleet.management.common.form.files.add.title"}/>}
            submitText={<IntlMessages id={"form.button.save"}/>}
            cancelText={<IntlMessages id={"form.button.cancel"}/>}
        >
            <form id={id} onSubmit={handleSubmit(onSaveFile)}>
                <GridContainer spacing={2}>
                    <Grid item xs={12}>
                        <input
                            id={'new-file-input'}
                            type="file"
                            onChange={e => {
                                const file = e.target.files[0];
                                setFormData('file', file);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedInput
                            id={`new-file-description`}
                            fullWidth
                            label={<IntlMessages id={"fleet.management.common.form.files.add.description"}/>}
                            value={formData.description}
                            onChange={value => setFormData('description', value)}
                            hasError={errors?.description?.length > 0}
                            error={errors?.description[0]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedDatePicker
                            id={`new-file-expiration-date`}
                            fullWidth
                            label={<IntlMessages id={"fleet.management.common.form.files.add.expiration.date"}/>}
                            value={formData.expirationDate}
                            onChange={value => setFormData('expirationDate', value)}
                            hasError={errors?.expirationDate?.length > 0}
                            error={errors?.expirationDate[0]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <OutlinedInput
                            id={`new-file-notice`}
                            fullWidth
                            type="number"
                            label={<IntlMessages id={"fleet.management.common.form.files.add.notice"}/>}
                            value={formData.notice}
                            onChange={value => setFormData('notice', value)}
                            hasError={errors?.notice?.length > 0}
                            error={errors?.notice[0]}
                        />
                    </Grid>
                </GridContainer>
            </form>
        </FormDialog>
    );
}

export default FilesDialog;