import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";

const TableHeader = ({orderBy, order, onSort}) => (
  <StyledTableRow>
    <StyledTableCell />
    <TableCellWithSorting
      property="currency"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="administrator.cashManagers.tableByCurrency.currency" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="amount"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
      align="right"
    >
      <IntlMessages id="administrator.cashManagers.tableByCurrency.amount" />
    </TableCellWithSorting>
  </StyledTableRow>
);

export default TableHeader;
