import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const CommonMultiSelect = ({
  id,
  onChange,
  label,
  filter,
  options = [],
  limitTags = -1
}) => {
  const handleOnChange = (e, newValue) => {
    let valuesArray = [];
    newValue.forEach(element => {
      valuesArray.push(element);
    });
    onChange(valuesArray);
  };
  return (
    <Autocomplete
      fullWidth
      multiple
      id={id}
      options={options}
      disableCloseOnSelect
      onChange={(event, newValue) => handleOnChange(event, newValue)}
      limitTags={limitTags}
      size="small"
      value={filter}
      renderInput={params => (
        <TextField {...params} variant="outlined" label={label} />
      )}
    />
  );
};

export default CommonMultiSelect;
