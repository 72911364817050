import Role from "./Role";
import {mapToBoolean} from "../../utils/mappers";
import VehicleRole from "./VehicleRole";

class User {
  constructor(id, email, roles, vehicleRoles, state) {
    this.id = id;
    this.email = email;
    this.roles = roles;
    this.rolesNames = roles.map(r => r.description).toString();
    this.vehicleRoles = vehicleRoles;
    this.vehicleRolesNames = vehicleRoles.map(r => r.description).toString();
    this.state = state;
  }

  static map = user => {
    return new User(
      user.nu_id_entidad,
      user.vc_email,
      user.roles.map(r => Role.map(r)),
      user.roles_vehiculos.map(r => VehicleRole.map(r)),
      mapToBoolean(user.bi_estado)
    );
  };
}

export default User;
