import React from "react";
import BaseAlert from "../BaseAlert";
import {Alert} from "@material-ui/lab";

const ErrorAlert = ({openAlert, setOpenAlert, message}) => (
  <BaseAlert setOpen={setOpenAlert} open={openAlert}>
    <Alert onClose={() => setOpenAlert(false)} severity="error">
      {message}
    </Alert>
  </BaseAlert>
);

export default ErrorAlert;
