import axiosInstance from "../../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../../StorageService";
import ProviderSelect from "../../../../domain/ControlPanel/ProviderSelect";

export const getAllProviders = async () => {
  try {
    const {data} = await axiosInstance.post("/Proveedor/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(t => ProviderSelect.map(t)).filter(t => t.status);
  } catch (e) {
    return Promise.reject(e);
  }
};
