import {
  ADMINISTRATOR_ERROR_ON_TRANSACTION,
  ADMINISTRATOR_RESET_ON_ERROR
} from "../../types/Administrator/UiTypes";

export const openDeleteConfirmation = id => ({
  type: "ADMINISTRATOR_OPEN_DELETE_CONFIRMATION",
  payload: {
    id
  }
});

export const openRestoreConfirmation = id => ({
  type: "ADMINISTRATOR_OPEN_RESTORE_CONFIRMATION",
  payload: {
    id
  }
});

export const openEditForm = id => ({
  type: "ADMINISTRATOR_OPEN_EDIT_FORM",
  payload: {
    id
  }
});

export const openCreateForm = () => ({type: "ADMINISTRATOR_OPEN_CREATE_FORM"});

export const closeDeleteConfirmation = () => ({
  type: "ADMINISTRATOR_CLOSE_DELETE_CONFIRMATION"
});
export const closeRestoreConfirmation = () => ({
  type: "ADMINISTRATOR_CLOSE_RESTORE_CONFIRMATION"
});
export const closeEditForm = () => ({type: "ADMINISTRATOR_CLOSE_EDIT_FORM"});
export const closeCreateForm = () => ({
  type: "ADMINISTRATOR_CLOSE_CREATE_FORM"
});

export const fetchStarted = () => ({type: "ADMINISTRATOR_FETCH_STARTED"});
export const fetchSucceeded = () => ({type: "ADMINISTRATOR_FETCH_SUCCEEDED"});
export const fetchFailed = () => ({type: "ADMINISTRATOR_FETCH_FAILED"});
export const fetchCancelled = () => ({type: "ADMINISTRATOR_FETCH_CANCELLED"});

export const changeStateStarted = () => ({
  type: "ADMINISTRATOR_CHANGE_STATE_STARTED"
});
export const changeStateSucceeded = () => ({
  type: "ADMINISTRATOR_CHANGE_STATE_SUCCEEDED"
});
export const changeStateFailed = () => ({
  type: "ADMINISTRATOR_CHANGE_STATE_FAILED"
});

export const editStarted = () => ({type: "ADMINISTRATOR_EDIT_STARTED"});
export const editSucceeded = () => ({type: "ADMINISTRATOR_EDIT_SUCCEEDED"});
export const editFailed = () => ({type: "ADMINISTRATOR_EDIT_FAILED"});

export const createStarted = () => ({type: "ADMINISTRATOR_CREATE_STARTED"});
export const createSucceeded = () => ({type: "ADMINISTRATOR_CREATE_SUCCEEDED"});
export const createFailed = () => ({type: "ADMINISTRATOR_CREATE_FAILED"});

export const setOnErrorMessage = message => ({
  type: ADMINISTRATOR_ERROR_ON_TRANSACTION,
  payload: message
});
export const resetOnError = () => ({type: ADMINISTRATOR_RESET_ON_ERROR});

export default {
  openDeleteConfirmation,
  closeDeleteConfirmation,
  openRestoreConfirmation,
  closeRestoreConfirmation,
  openEditForm,
  closeEditForm,
  openCreateForm,
  closeCreateForm,
  fetchStarted,
  fetchSucceeded,
  fetchFailed,
  fetchCancelled,
  changeStateStarted,
  changeStateSucceeded,
  changeStateFailed,
  editStarted,
  editSucceeded,
  editFailed,
  createStarted,
  createSucceeded,
  createFailed,
  setOnErrorMessage,
  resetOnError
};
