import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import {mapToBoolean} from "../../utils/mappers";

export const getAllServices = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/Servicio/sel",
            {
                nu_id_entidad: getCompanyEntityId(),
            },
        );
        return data.map((service) => ({
            id: service.nu_id_servicio,
            name: service.vc_desc_servicio,
            code: service.vc_cod_servicio,
            unitPrice: +service.nu_precio_unitario,
            taxId: service.nu_id_impuesto,
            total: +service.nu_total,
            state: mapToBoolean(service.bi_estado),
            deductionType: {id: service.nu_id_tipo_detraccion},
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getAllServicesForSelect = async () => {
    try {
        const services = await getAllServices();
        return services.filter((service) => service.state);
    } catch (e) {
        return Promise.reject(e);
    }
}

export const getAllDeductions = async () => {
    try {
        const {data} = await axiosInstance.post(
            "/Servicio/sel_tipo_detraccion",
            {
                nu_id_entidad: getCompanyEntityId(),
            },
        );
        return data.map((deduction) => ({
            id: deduction.nu_id_tipo_detraccion,
            name: deduction.vc_desc_tipo_detraccion,
            code: deduction.vc_cod_tipo_detraccion,
            percentage: deduction.nu_porcentaje,
            state: mapToBoolean(deduction.bi_estado),
        }));
    } catch (e) {
        return Promise.reject(e);
    }
}

export const addService = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/Servicio/ins',
            {
                nu_id_entidad: getCompanyEntityId(),
                vc_desc_servicio: request.name,
                vc_cod_servicio: request.code,
                nu_precio_unitario: +request.unitPrice,
                nu_id_impuesto: request.tax,
                nu_total: +request.total,
                nu_id_tipo_detraccion: request.deductionType?.id,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editService = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/Servicio/upd',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_servicio: request.id,
                vc_desc_servicio: request.name,
                vc_cod_servicio: request.code,
                nu_precio_unitario: +request.unitPrice,
                nu_id_impuesto: request.tax,
                nu_total: +request.total,
                nu_id_tipo_detraccion: request.deductionType?.id,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editServiceState = async ({serviceId, state}) => {
    try {
        const {data} = await axiosInstance.post(
            '/Servicio/del',
            {
                nu_id_servicio: serviceId,
                bi_estado: state,
            }
        );
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    }catch (e) {
        return Promise.reject(e);
    }
}
