class DestinationTag {
    constructor(id, description, type) {
        this.id = id;
        this.description = description;
        this.type = type;
    }

    static map = tag => {
        return new DestinationTag(
            tag.nu_id_tag,
            tag.vc_desc_tag,
            tag.vc_desc_tipo_tag,
        );
    }
}

export default DestinationTag;