import {
  APPLY_VEHICLES_MAINTENANCES_FILTERS,
  CLOSE_VEHICLES_MAINTENANCES_VIEWS_DIALOG,
  FETCH_ALL_VEHICLES_MAINTENANCES,
  FETCH_ALL_VEHICLES_MAINTENANCES_VIEWS,
  OPEN_VEHICLES_MAINTENANCES_VIEWS_DIALOG,
  RESET_VEHICLES_MAINTENANCES_FILTERS,
  RESET_VEHICLES_MAINTENANCES_STATE
} from "../../types/FleetManagement/VehicleMaintencesTypes";

const INIT_STATE = {
  filters: {
    view: "",
    expiration: "",
    vehicle: "",
    tags: []
  },
  views: [],
  data: [],
  headers: [],
  selectedInfo: {
    viewId: "",
    name: "",
    isPublic: true,
    editable: null
  },
  dialogs: {
    name: "",
    open: false
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_VEHICLES_MAINTENANCES_FILTERS: {
      return {
        ...state,
        filters: action.payload
      };
    }
    case RESET_VEHICLES_MAINTENANCES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.summary.filters
        }
      };
    }
    case FETCH_ALL_VEHICLES_MAINTENANCES: {
      return {
        ...state,
        data: action.payload.data,
        headers: action.payload.headers,
        selectedInfo: action.payload.selectedInfo
      };
    }
    case FETCH_ALL_VEHICLES_MAINTENANCES_VIEWS: {
      return {
        ...state,
        views: action.payload
      };
    }
    case OPEN_VEHICLES_MAINTENANCES_VIEWS_DIALOG: {
      return {
        ...state,
        dialogs: {
          open: true,
          name: action.payload
        }
      };
    }
    case CLOSE_VEHICLES_MAINTENANCES_VIEWS_DIALOG: {
      return {
        ...state,
        dialogs: {
          ...INIT_STATE.dialogs
        }
      };
    }
    case RESET_VEHICLES_MAINTENANCES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
