class VehicleSelect {
  constructor(id, vehicle, alias, externalId, active) {
    this.id = id;
    this.name = vehicle;
    this.alias = alias;
    this.externalId = externalId;
    this.active = active;
  }

  static map = vehicle => {
    return new VehicleSelect(
      vehicle.nu_id_vehiculo,
      vehicle.vc_placa,
      vehicle.vc_alias,
      vehicle.nu_id_externo,
      vehicle.bi_estado
    );
  };
}

export default VehicleSelect;
