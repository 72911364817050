import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import ChecklistType from "../../../domain/Logistic/Distribution/ChecklistType";
import Response from "../../../domain/Response";
import ChecklistTypeSelectItem from "../../../domain/Logistic/Distribution/ChecklistTypeSelectItem";
import ChecklistMarkOption from "../../../domain/Logistic/Distribution/ChecklistMarkOption";

export const getAllChecklistTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipo/sel", {
      nu_id_entidad: getCompanyEntityId(),
      bi_extentido: true
    });
    return data.map(c => ChecklistType.map(c));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createChecklistType = async type => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_checklist: type.name,
      nu_id_externo: "",
      nu_id_tipo_checklist_respuestas_opciones: type.markOption
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateChecklistType = async type => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipo/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_checklist: type.id,
      vc_desc_tipo_checklist: type.name,
      nu_id_tipo_checklist_respuestas_opciones: type.markOption,
      nu_id_externo: ""
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateChecklistState = async (typeId, state) => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipo/del", {
      nu_id_tipo_checklist: typeId,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addChecklistGroupToType = async (type, group) => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipoGrupo/ins", {
      nu_id_tipo_checklist: type.id,
      vc_desc_tipo_checklist_grupo: group.name,
      nu_id_orden: type.groups.length + 1
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateChecklistGroup = async (type, group) => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipoGrupo/upd", {
      nu_id_tipo_checklist: type.id,
      nu_id_tipo_checklist_grupo: group.id,
      vc_desc_tipo_checklist_grupo: group.name,
      nu_id_orden: group.order
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const addChecklistGroupItemToGroup = async (group, item) => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipoSubGrupo/ins", {
      nu_id_tipo_checklist_grupo: group.id,
      vc_desc_tipo_checklist_subgrupo: item.name,
      nu_id_orden: group.list.length + 1
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateChecklistGroupItem = async (group, item) => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipoSubGrupo/upd", {
      nu_id_tipo_checklist_grupo: group.id,
      nu_id_tipo_checklist_subgrupo: item.id,
      vc_desc_tipo_checklist_subgrupo: item.name,
      nu_id_orden: item.order
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllChecklistTypesForSelect = async (request) => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipo/sel", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_checklist: request?.type,
      bi_extentido: false
    });
    return data.map(c => ChecklistTypeSelectItem.map(c)).filter(t => t.status);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllChecklistMarkOptions = async () => {
  try {
    const {data} = await axiosInstance.post("/CheckListTipo/sel_checklist_tipo_respuestas_opciones", {
      nu_id_entidad: getCompanyEntityId(),
    });
    return data.map(c => ChecklistMarkOption.map(c));
  } catch (e) {
    return Promise.reject(e);
  }
}