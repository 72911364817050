import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.workOrders"} />,
    link: "/logistic/distributions/work-orders"
  },
  {
    label: (
      <IntlMessages id={"sidebar.logistic.distribution.rejection.types"} />
    ),
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="logistic.distribution.rejection.types.title" />}
    description={
      <IntlMessages id="logistic.distribution.rejection.types.description" />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
