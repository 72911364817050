import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "../TableContainer";
import Box from "@material-ui/core/Box";

const Table = ({
  data,
  isLoading,
  head,
  renderRow: RenderRow,
  getRowKey,
  footer
}) => {
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />;
      </Box>
    );
  }

  if (data.length === 0) {
    return (
      <Typography variant="h3">
        <IntlMessages id="common.tables.empty" />
      </Typography>
    );
  }

  return (
    <TableContainer head={head} footer={footer}>
      {data.map(row => <RenderRow key={getRowKey(row)} row={row} />)}
    </TableContainer>
  );
};

Table.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  renderRow: PropTypes.elementType.isRequired,
  getRowKey: PropTypes.func.isRequired
};

Table.defaultProps = {
  data: [],
  isLoading: false
};

export default Table;
