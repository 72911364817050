import {TableCell, withStyles} from "@material-ui/core";

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  },
  footer: {
    position: "sticky",
    backgroundColor: "#8092e3",
    color: theme.palette.common.white
  }
}))(TableCell);

export default StyledTableCell;
