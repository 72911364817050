export const RESET_EMPLOYEE_DOCUMENTS_TYPES_STATE =
  "RESET_EMPLOYEE_DOCUMENTS_TYPES_STATE";
export const FETCH_ALL_EMPLOYEE_DOCUMENTS_TYPES =
  "FETCH_ALL_EMPLOYEE_DOCUMENTS_TYPES";
export const APPLY_EMPLOYEE_DOCUMENTS_TYPES_FILTERS =
  "APPLY_EMPLOYEE_DOCUMENTS_TYPES_FILTERS";
export const RESET_EMPLOYEE_DOCUMENTS_TYPES_FILTERS =
  "RESET_EMPLOYEE_DOCUMENTS_TYPES_FILTERS";
export const SET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE =
  "SET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE";
export const RESET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE =
  "RESET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE";
export const ADD_EMPLOYEE_DOCUMENTS_TYPE = "ADD_EMPLOYEE_DOCUMENTS_TYPE";
export const EDIT_EMPLOYEE_DOCUMENTS_TYPE = "EDIT_EMPLOYEE_DOCUMENTS_TYPE";
export const OPEN_EMPLOYEE_DOCUMENTS_TYPES_DIALOG =
  "OPEN_EMPLOYEE_DOCUMENTS_TYPES_DIALOG";
export const CLOSE_EMPLOYEE_DOCUMENTS_TYPES_DIALOG =
  "CLOSE_EMPLOYEE_DOCUMENTS_TYPES_DIALOG";
