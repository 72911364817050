import React from "react";

import StyledTableRow from "components/Common/Tables/StyledTableRow";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import TableCellWithSorting from "components/Common/Tables/TableCellWithSorting";
import IntlMessages from "@jumbo/utils/IntlMessages";

const TableHeader = ({orderBy, order, onSort}) => (
  <React.Fragment>
    <StyledTableRow>
      <TableCellWithSorting
        property="description"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
      >
        <IntlMessages id="administrator.supervisors.table.names" />
      </TableCellWithSorting>
      <StyledTableCell>
        <IntlMessages id="administrator.supervisors.table.tags" />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  </React.Fragment>
);

export default TableHeader;
