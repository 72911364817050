import React, {useEffect} from "react";
import {Button} from "@material-ui/core";
import useForm from "hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "@jumbo/utils/IntlMessages";
import DriverPicker from "../../../../../../../../containers/Administrator/DriverPicker";
import VehiclePicker from "../../../../../../../../containers/Administrator/VehiclePicker";
import EventPicker from "../../../../../../../../containers/Administrator/EventPicker";
import validations from './validations';
import {validateFormField2} from '../../../../../../../../utils/validateField';
import Select from 'components/Common/Forms/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {setCurrentReportFile} from "../../../../../../../../redux/actions/ControlPanel/Kpi";
import {addDaysToDate, parseDateToInput} from "../../../../../../../../utils/dates";
import VisualizationSelect from "./VisualizationSelect";
import SendEmail from "../../../../Common/SendEmailDialog";
import SaveFilters from "../../../../Common/SaveFilters";
import DateFilters from "../../../../Common/DateFilters";

const initialData = {
    vehicles: [],
    events: [],
    drivers: [],
    period: 1,
    startDate: parseDateToInput(addDaysToDate(new Date(), -7)), // -7 dias
    endDate: parseDateToInput(new Date()),
    location: 'Todo',
    locationMina: 'En Carretera',
    visualization: "1"
};

const Form = ({service}) => {
    const {formData, errors, setFormData, setValues} = useForm(initialData, validations);
    const {current} = useSelector(({controlPanel}) => controlPanel.kpi);
    const {vehicle} = useSelector(state => state.administrator.vehicleSelect);
    const {events} = useSelector(state => state.administrator.eventsSelect);
    const eventsMina = events.filter(v => v.name !== 'GPS: Exceso Velocidad');
    const dispatch = useDispatch();
    const handleSubmitPDF = () => {
        dispatch(setCurrentReportFile(service, formData, 1));
    }
    const handleSubmitExcel = () => {
        dispatch(setCurrentReportFile(service, formData, 2));
    }
    const validateAdvance = () => {
        if (initialData.events.length > 0 || initialData.startDate === '' || initialData.endDate === '') {
            return true
        }
        return false;
    }

    useEffect(() => {
        if (service === 9) {
            setFormData('vehicles', vehicle);
            setFormData('events', eventsMina);
        }
        current?.filters && setValues(current.filters);
    }, [service]);

    return (
        <form>
            <GridContainer spacing={4}>
                <Grid item xs={12} sm={4} lg={3}>
                    <VehiclePicker
                        id="vehicle-picker-filter"
                        values={formData.vehicles}
                        onChange={value => setFormData('vehicles', value)}
                        errors={errors}
                        service={service}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    {service === 1 || service === 8 ?
                        <EventPicker
                            id="events-pickers-filter"
                            values={formData.events}
                            onChange={value => setFormData('events', value)}
                            errors={errors}
                            service={service}
                        />
                        :
                        <EventPicker
                            id="events-pickers-filter"
                            values={formData.events}
                            onChange={value => setFormData('events', value)}
                            service={service}
                        />
                    }
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <DriverPicker
                        id="driver-picker-filter"
                        values={formData.drivers}
                        onChange={value => setFormData('drivers', value)}
                    />
                </Grid>
                <DateFilters
                    formData={formData}
                    setFormData={setFormData}
                />
                {(service === 1 || service === 8 || service === 9) ?
                    <Grid item xs={12} sm={4} lg={3}>
                        <Select
                            value={formData.location}
                            fullWidth
                            id="location-filter"
                            label={<IntlMessages id="administrator.dataCollectionHistory.filters.location"/>}
                            onChange={e => setFormData('location', e.target.value)}
                        >
                            <MenuItem value="Todo">Todo</MenuItem>
                            <MenuItem value="En Ciudad">En Ciudad</MenuItem>
                            <MenuItem value="En Carretera">En Carretera</MenuItem>
                        </Select>
                    </Grid>
                    :
                    <p></p>
                }
                {service === 9 &&
                    <Grid item xs={12} sm={4} lg={3}>
                        <VisualizationSelect
                            value={formData.visualization}
                            onChange={value => setFormData('visualization', value)}
                        />
                    </Grid>

                }
                <Grid item xs={12} sm={4} lg={3}>
                    {service === 1 || service === 8 ?
                        <Button
                            onClick={() => handleSubmitPDF()}
                            disabled={validateFormField2(formData, ['drivers']) || errors.events.length > 0 || errors.vehicles.length > 0}
                            color="primary"
                        >
                            Generar PDF
                        </Button>
                        :
                        service === 9 ?
                            <Button
                                onClick={() => handleSubmitPDF()}
                                disabled={validateFormField2(formData, ['vehicles', 'events', 'drivers'])}
                                color="primary"
                            >
                                Generar PDF
                            </Button>
                            :
                            <Button
                                onClick={() => handleSubmitPDF()}
                                disabled={validateFormField2(formData, ['events', 'drivers']) || errors.vehicles.length > 0}
                                color="primary"
                            >
                                Generar PDF
                            </Button>
                    }
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    {service === 1 || service === 8 ?
                        <Button
                            onClick={() => handleSubmitExcel()}
                            disabled={validateFormField2(formData, ['drivers']) || errors.events.length > 0 || errors.vehicles.length > 0}
                            color="primary"
                        >
                            Generar Excel
                        </Button>
                        :
                        service === 9 ?
                            <Button
                                onClick={() => handleSubmitExcel()}
                                disabled={validateFormField2(formData, ['vehicles', 'events', 'drivers'])}
                                color="primary"
                            >
                                Generar Excel
                            </Button>
                            :
                            <Button
                                onClick={() => handleSubmitExcel()}
                                disabled={validateFormField2(formData, ['events', 'drivers']) || errors.vehicles.length > 0}
                                color="primary"
                            >
                                Generar Excel
                            </Button>
                    }
                </Grid>
                <SendEmail filters={formData}/>
                <SaveFilters filters={formData}/>
            </GridContainer>
            {/*errors.events.length > 0 && <p style={{color: "red", marginBottom: "16px"}}>{errors.events[0]}</p>*/}
            {service === 1 || service === 8 ?
                validateFormField2(formData, ['drivers']) &&
                <p style={{color: "red", marginBottom: "16px"}}>Los campos Vehículo, Evento, Fecha Inicial y Fecha Fin
                    son obligatorios</p>
                :
                service === 9 ?
                    validateFormField2(formData, ['vehicles', 'events', 'drivers']) &&
                    <p style={{color: "red", marginBottom: "16px"}}>Los campos Fecha Inicial y Fecha Fin son
                        obligatorios</p>
                    :
                    validateFormField2(formData, ['events', 'drivers']) &&
                    <p style={{color: "red", marginBottom: "16px"}}>Los campos Vehículo, Fecha Inicial y Fecha Fin son
                        obligatorios</p>
            }
        </form>
    );
}

export default Form;