import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import toBase64 from "../../../../../utils/files";
import {downloadFile, getFileByName} from "../../../../../services/FileService";

const FilesTable = ({files = [], setFiles, setDeleteFiles}) => {
  const onDownload = file => {
    if (file.id !== 0) {
      getFileByName(file.link)
        .then(({base64File, contentType}) => {
          downloadFile(file.name, `data:${contentType};base64,${base64File}`);
        })
        .catch(error => {
          throw new Error(error.message);
        });
    } else {
      toBase64(file.file)
        .then(base64File => {
          downloadFile(file.name, base64File);
        })
        .catch(error => {
          throw new Error(error.message);
        });
    }
  };
  return (
    <TableContainer
      head={
        <StyledTableRow>
          <StyledTableCell>
            <IntlMessages id={"rrhh.employees.form.files.name"} />
          </StyledTableCell>
          <StyledTableCell>
            <IntlMessages id={"rrhh.employees.form.files.expiration.date"} />
          </StyledTableCell>
          <StyledTableCell />
        </StyledTableRow>
      }
    >
      {files.map((file, index) => (
        <StyledTableRow key={`file-${index}`}>
          <StyledTableCell>{file.name}</StyledTableCell>
          <StyledTableCell>
            {moment(file.expirationDate).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell>
            <IconButton
              onClick={() => {
                const deleteFiles = files
                  .filter((file, i) => i === index && file.id !== 0)
                  .map(file => file.id);
                setFiles(files.filter((file, i) => i !== index));
                setDeleteFiles(deleteFiles);
              }}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => onDownload(file)}>
              <GetAppIcon />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};

export default FilesTable;
