import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import Form from "./Form";
import {setAccountingExpense, updateAccountingExpense} from "../../../../../redux/actions/Guardians/Accounting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const EditExpenseDialog = () => {
    const id = 'edit-expense';
    const {currentExpense} = useSelector(
        ({guardian}) => guardian.accountingList
    );
    const {currentAccounting} = useSelector(
        ({guardian}) => guardian
    );
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setAccountingExpense(null));
    }
    const onSubmit = (data) => {
        dispatch(updateAccountingExpense({
            request: {
                expenseId: currentExpense.id,
                ...data,
            },
            accounting: currentAccounting
        }))
        handleClose();
    }
    return (
        <FormDialog
            id={id}
            isOpen={Boolean(currentExpense)}
            onClose={handleClose}
            title={<IntlMessages id="edit.expense" />}
        >
            {currentExpense &&
                <Form
                    id={id}
                    expense={currentExpense}
                    onSubmit={onSubmit}
                />
            }
        </FormDialog>
    );
};

export default EditExpenseDialog;