import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {connect, useDispatch, useSelector} from "react-redux";
import CrudTableRow from "../../../../Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "../../../../Common/Tables/CrudTable/RestoreTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {Box} from "@material-ui/core";
import Filters from "./Filters";
import {
  fetchAllConfigurationOrganizationModules,
  setCurrentConfigOrganizationModule
} from "../../../../../redux/actions/Configuration/OrganizationsModules";
import DeleteTableRow from "../../../../Common/Tables/CrudTable/DeleteTableRow";
import EditTableRow from "../../../../Common/Tables/CrudTable/EditTableRow";
import FilterBuilder from "../../../../../utils/filters";
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const OrganizationModulesTable = ({
  data,
  dispatch,
  openEdit,
  openDelete,
  openRestore,
    openMassive,
}) => {
  const onEdit = role => {
    openEdit && openEdit();
    dispatch(setCurrentConfigOrganizationModule(role));
  };
  const onDelete = role => {
    openDelete && openDelete();
    dispatch(setCurrentConfigOrganizationModule(role, 2));
  };
  const onRestore = role => {
    openRestore && openRestore();
    dispatch(setCurrentConfigOrganizationModule(role, 2));
  };
  React.useEffect(() => {
    dispatch(fetchAllConfigurationOrganizationModules());
  }, []);

  return (
    <Box display="flex" flexDirection="column" style={{gap: "10px"}}>
      <Filters openMassive={openMassive}/>
      <TableContainer head={<TableHeader />}>
        {Object.values(data).map(role => (
          <React.Fragment key={role.id}>
            {
              <EditTableRow onEdit={() => onEdit(role)}>
                <StyledTableCell>{role.id}</StyledTableCell>
                <StyledTableCell>{role.description}</StyledTableCell>
              </EditTableRow>
              // role.state
              //     ? <CrudTableRow onEdit={() => onEdit(role)} onDelete={() => onDelete(role)}>
              //         <StyledTableCell>{role.id}</StyledTableCell>
              //         <StyledTableCell>{role.description}</StyledTableCell>
              //     </CrudTableRow>
              //     // ? <DeleteTableRow onDelete={() => onDelete(role)}>
              //     //     <StyledTableCell>{role.id}</StyledTableCell>
              //     //     <StyledTableCell>{role.description}</StyledTableCell>
              //     // </DeleteTableRow>
              //     : <RestoreTableRow onRestore={() => onRestore(role)}>
              //         <StyledTableCell>{role.id}</StyledTableCell>
              //         <StyledTableCell>{role.description}</StyledTableCell>
              //     </RestoreTableRow>
            }
          </React.Fragment>
        ))}
      </TableContainer>
    </Box>
  );
};
const mapStateToProps = ({configuration}) => {
  const {data, filters} = configuration.organizationsModules;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("id", filters.code)
    .byFieldIncludes("description", filters.description)
    .build();
  return {data: filteredData};
};

export default connect(mapStateToProps)(OrganizationModulesTable);
