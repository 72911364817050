import React from "react";

import Confirmation from "components/Common/Dialogs/Confirmation";
import IntlMessages from "@jumbo/utils/IntlMessages";

const RestoreConfirmation = ({open, onAccept, onReject, text=<IntlMessages id="administrator.supervisors.restoreConfirmationText" /> }) => (
  <Confirmation
    open={open}
    onAccept={onAccept}
    onReject={onReject}
    text={text}
  />
);

export default RestoreConfirmation;
