import React from "react";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import {fetchVehiclesByEntityId} from "../../../../../../services/FleetManagement/Tachograph/TachographsFilesService";

const VehiclesSelect = ({formData, setFormData}) => {
  const [ vehicles, setVehicles ] = React.useState([]);
  React.useEffect(() => {
      fetchVehiclesByEntityId()
      .then(data => setVehicles(data))
      .catch(e => setVehicles([]));
  }, []);
  return (
    <MultipleSelect
      fullWidth
      options={vehicles}
      label={
        <IntlMessages id={"fleet.management.tachograph.files.table.vehicle"} />
      }
      value={formData.vehicles}
      onChange={value => setFormData("vehicles", value)}
    />
  );
};

export default VehiclesSelect;
