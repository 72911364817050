import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {
    fetchAllDashboards,
    getCurrentDashboardWidgetsData,
    updateDashboardDetailsAndWidgets
} from "../../thunks/ControlPanel/dashboards";
import {getExceedingSpeedData, getExceedingSpeedTableData} from "../../thunks/ControlPanel/exceedingSpeed";
import {createCustomDashboard} from "../../thunks/ControlPanel/customDashboards";
import {
    getEcoDrivingData,
    getEcoDrivingEfficiencyTableData,
    getEcoDrivingSecurityTableData
} from "../../thunks/ControlPanel/ecoDriving";
import {getAavvDashboardData} from "../../thunks/ControlPanel/aavv";

const INIT_DATA = {
    currentDashboard: null,
    filters: {
        period: 1,
        startDate: '',
        endDate: '',
        planningPlace: '',
        date: ''
    },
    dashboards: {
        customDashboards: [],
        defaultDashboards: [],
    },
    widgets: [],
}

const dashboardsSlice = createSlice({
    name: 'dashboards',
    initialState: cloneDeep(INIT_DATA),
    reducers: {
        setCurrentDashboard: (state, action) => {
          state.currentDashboard = action.payload;
          state.filters = {
              period: 1,
              startDate: '',
              endDate: '',
          }
        },
        resetState: (state) => {
            return cloneDeep(INIT_DATA);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllDashboards.fulfilled, (state, action) => {
           state.dashboards = action.payload;
        });
        builder.addCase(getCurrentDashboardWidgetsData.fulfilled, (state, action) => {
           state.currentDashboard = action.payload;
        });
        builder.addCase(getExceedingSpeedData.fulfilled, (state, action) => {
            const {filters, currentDashboard} = action.payload;
           state.currentDashboard = currentDashboard;
           state.filters = filters;
        });
        builder.addCase(getExceedingSpeedTableData.fulfilled, (state, action) => {
           state.currentDashboard.widgets.details = action.payload;
        });
        builder.addCase(getEcoDrivingData.fulfilled, (state, action) => {
            const {filters, currentDashboard} = action.payload;
           state.currentDashboard = currentDashboard;
           state.filters = filters;
        });
        builder.addCase(getEcoDrivingSecurityTableData.fulfilled, (state, action) => {
            state.currentDashboard.widgets.security.details = action.payload;
        });
        builder.addCase(getEcoDrivingEfficiencyTableData.fulfilled, (state, action) => {
            state.currentDashboard.widgets.efficiency.details = action.payload;
        });
        builder.addCase(createCustomDashboard.fulfilled, (state, action) => {
            state.dashboards.customDashboards = [
                ...state.dashboards.customDashboards,
                action.payload
            ];
            state.currentDashboard = action.payload;
        });
        builder.addCase(updateDashboardDetailsAndWidgets.fulfilled, (state, action) => {
            state.dashboards.customDashboards = state.dashboards.customDashboards.map(dashboard => {
                if(dashboard.id === action.payload.dashboard.id) {
                    return cloneDeep(action.payload.dashboard);
                }
                return dashboard;
            })
        })
        builder.addCase(getAavvDashboardData.fulfilled, (state, action) => {
            const {filters, currentDashboard} = action.payload;
            state.currentDashboard = currentDashboard;
            state.filters = filters;
        });
    }
});

export default dashboardsSlice.reducer;

export const {resetState, setCurrentDashboard} = dashboardsSlice.actions;