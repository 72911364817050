import React from 'react';
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import Select from "../../../../../../Common/Forms/Select";

const PlanningPlaceSelect =({value, setValue}) => {
    const options = [
        {
            id: 1002
        },
        {
            id: 1003
        },
        {
            id: 1012
        },
        {
            id: 1017
        },
    ];
    return (
        <Select
            label={<IntlMessages id='planning.place' />}
            value={value}
            onChange={e => setValue(e.target.value)}
            fullWidth

        >
            {options.map(option => (
                <MenuItem key={`planning-place-${option.id}`} value={option.id}>
                    {option.id}
                </MenuItem>
            ))}
        </Select>
    );
};

export default PlanningPlaceSelect;