import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import {mapToBoolean} from "../../utils/mappers";

export const fetchAllClientsByDocumentType = async ({documentTypeId}) => {
    try {
        if(!documentTypeId) return [];
        const {data} = await axiosInstance.post(
            '/Cliente/sel_fe',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tipo_documento: documentTypeId,
            }
        );
        return data.map(client => ({
            id: client.nu_id_entidad,
            name: client.vc_nombre_comercial,
            state: mapToBoolean(client.bi_estado),
        }));
    }catch (e) {
        return Promise.reject(e);
    }
}