import React, {useEffect} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {FormHelperText, Grid, OutlinedInput} from "@material-ui/core";

const AmountInput = ({
  amount,
  setAmount,
  isLoading,
  errors,
  label,
  currentBalance,
  condition,
  addAdvance = false
}) => {
  useEffect(
    () => {
      if (currentBalance !== 0 && addAdvance) {
        setAmount((currentBalance * -1).toString());
      }
    },
    [ currentBalance ]
  );
  return (
    <Grid item xs={12} sm={12}>
      <FormControl
        error={errors.amount.length > 0 || condition}
        variant="outlined"
        size="small"
        fullWidth
      >
        <InputLabel htmlFor="amount">
          <IntlMessages id={label} />
        </InputLabel>
        <OutlinedInput
          value={amount}
          id="amount"
          label={<IntlMessages id={label} />}
          onChange={e => setAmount(e.target.value)}
          disabled={isLoading}
        />
        <FormHelperText>{errors.amount[0]}</FormHelperText>
        {condition && (
          <FormHelperText>
            <IntlMessages
              id={"guardian.add.advance.form.amount.error.petty.cash.amount"}
            />
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default AmountInput;
