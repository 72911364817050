import React from "react";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="form.field.error" />
    },
    {
      rule: v => v.toString().trim().match(/^[a-zA-ZáéíóúüÁÉÍÓÚÜ\d\-_\s]+$/i),
      message: <IntlMessages id="form.field.error.format.alphanumeric" />
    }
  ]
};
