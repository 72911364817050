import React from 'react';
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import BooleanSelect from "../../../../../Common/Forms/Select/BooleanSelect";
import validations from "./validations";
import BrandSelect from "./BrandSelect";
import ModelSelect from "./ModelSelect";
import MeasuresSelect from "./MeasuresSelect";

const Form = ({id, onSubmit, initialData}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-tire"}
                        fullWidth
                        value={formData.tire}
                        onChange={value => setFormData('tire', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.tire"}/>}
                        hasError={errors?.tire?.length > 0}
                        error={errors?.tire[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BrandSelect
                        id={"form-tires-brand"}
                        fullWidth
                        value={formData.brand}
                        setValue={value => setFormData('brand', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.brand"}/>}
                        hasError={errors?.brand?.length > 0}
                        error={errors?.brand[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ModelSelect
                        id={"form-tires-model"}
                        fullWidth
                        value={formData.model}
                        setValue={value => setFormData('model', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.model"}/>}
                        hasError={errors?.model?.length > 0}
                        error={errors?.model[0]}
                        brand={formData.brand}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <MeasuresSelect
                        id={"form-tires-measures"}
                        fullWidth
                        value={formData.measures}
                        setValue={value => setFormData('measures', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.measures"}/>}
                        hasError={errors?.measures?.length > 0}
                        error={errors?.measures[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanSelect
                        id={"form-tires-retreading"}
                        fullWidth
                        value={formData.retreading}
                        onChange={value => setFormData('retreading', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.retreading"}/>}
                        hasError={errors?.retreading?.length > 0}
                        error={errors?.retreading[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"form-tires-purchase-date"}
                        fullWidth
                        value={formData.purchaseDate}
                        onChange={value => setFormData('purchaseDate', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.purchase.date"}/>}
                        hasError={errors?.purchaseDate?.length > 0}
                        error={errors?.purchaseDate[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-purchase-value"}
                        fullWidth
                        type="number"
                        value={formData.purchaseValue}
                        onChange={value => setFormData('purchaseValue', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.purchase.value"}/>}
                        hasError={errors?.purchaseValue?.length > 0}
                        error={errors?.purchaseValue[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-purchase-note"}
                        fullWidth
                        value={formData.purchaseNote}
                        onChange={value => setFormData('purchaseNote', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.purchase.note"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        id={"form-tires-sale-date"}
                        fullWidth
                        value={formData.saleDate}
                        onChange={value => setFormData('saleDate', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.sale.date"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-sale-value"}
                        fullWidth
                        type="number"
                        value={formData.saleValue}
                        onChange={value => setFormData('saleValue', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.sale.value"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-sale-note"}
                        fullWidth
                        value={formData.saleNote}
                        onChange={value => setFormData('saleNote', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.sale.note"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-pressure"}
                        fullWidth
                        type="number"
                        value={formData.pressure}
                        onChange={value => setFormData('pressure', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.pressure"}/>}
                        hasError={errors?.pressure?.length > 0}
                        error={errors?.pressure[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanSelect
                        id={"form-tires-original"}
                        fullWidth
                        value={formData.original}
                        onChange={value => setFormData('original', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.original"}/>}
                        hasError={errors?.original?.length > 0}
                        error={errors?.original[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BooleanSelect
                        id={"form-tires-active"}
                        fullWidth
                        value={formData.active}
                        onChange={value => setFormData('active', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.active"}/>}
                        hasError={errors?.active?.length > 0}
                        error={errors?.active[0]}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-cocada"}
                        fullWidth
                        value={formData.cocada}
                        onChange={value => setFormData('cocada', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.cocada"}/>}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        id={"form-tires-observations"}
                        fullWidth
                        value={formData.observations}
                        onChange={value => setFormData('observations', value)}
                        label={<IntlMessages id={"fleet.management.tires.form.observations"}/>}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;