import {mapToBoolean} from "../../../utils/mappers";

class SinisterState {
  constructor(id, description, status) {
    this.id = id;
    this.description = description;
    this.status = status;
  }

  static map = status => {
    return new SinisterState(
      status.nu_id_tipo_estado_siniestro,
      status.vc_desc_tipo_estado_siniestro,
      mapToBoolean(status.bi_estado)
    );
  };
}

export default SinisterState;
