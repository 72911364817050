import React from "react";
import PageContainer from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ModelTypes/PageContainer";
import ModelsTable from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ModelTypes/ModelsTable";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {
  editElevatorModelState,
  resetCurrentElevatorModel,
  resetElevatorsModelsState
} from "../../../../../../redux/actions/ElevatorsManagement/Models";
import AddModelDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ModelTypes/AddModelDialog";
import EditModelDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/ModelTypes/EditModelDialog";

const ModelTypes = () => {
  const {current} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.models
  );
  const dispatch = useDispatch();
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetElevatorsModelsState());
    };
  }, []);
  return (
    <PageContainer>
      <ModelsTable
        setEdit={setOpenEdit}
        setDelete={setOpenDelete}
        setRestore={setOpenRestore}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentElevatorModel());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editElevatorModelState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentElevatorModel());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editElevatorModelState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <AddModelDialog open={openAdd} setOpen={setOpenAdd} />
      <EditModelDialog open={openEdit} setOpen={setOpenEdit} />
    </PageContainer>
  );
};

export default ModelTypes;
