import {createExpense} from "../../../services/ExpenseService";
import {cloneDeep} from "lodash";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const submitAddExpenseForm = (data, history) => {
  return async dispatch => {
    try {
      dispatch(fetchStart());
      const response = await createExpense(cloneDeep(data));
      dispatch(fetchSuccess(response.transactionMessage))
      setTimeout(() => {
        history.push("advances-list");
      }, 1500);
    }catch (e) {
      dispatch(fetchError(e.message));
    }
  };
};
