import React from 'react';
import {Box} from "@material-ui/core";
import {useSelector} from "react-redux";
import DynamicWidget from "./DynamicWidget";

const WidgetsContainer = () => {
    const {currentDashboard} = useSelector(({controlPanel}) => controlPanel.dashboards);
    const widgets = currentDashboard?.widgets ?? [];

    return (
        <Box
            id='graphs-container'
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
                gap: 5,
                marginTop: '1rem',
                height: '100%'
            }}
        >
            {widgets?.map((widget, index) => (
                <DynamicWidget
                    key={widget.id}
                    widget={widget}
                />
            ))}
        </Box>
    );
};

export default WidgetsContainer;