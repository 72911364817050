import React from "react";
import {Box} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import SearchInput from "../../../Common/Forms/SearchInput";

const AddAndSearchBox = ({search, onSearch}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="right"
      mb={4}
    >
      <SearchInput
        search={search}
        label={<IntlMessages id="logistic.work.orders.table.search" />}
        onSearch={onSearch}
      />
    </Box>
  );
};

export default AddAndSearchBox;
