import React, {useEffect} from "react";
import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import ActiveSelect from "../../../Common/Forms/Select/ActiveSelect";
import useForm from "../../../../hooks/Common/useForm";
import CurrencySelect from "../../../Common/Forms/Select/CurrencySelect";

const initialFormData = {
  state: null,
  tags: "",
  fund: "",
  cash: "",
  traffic: "",
  liquidated: "",
  refund: "",
  currency: ""
};

const BoxesFilters = ({applyFilters, onClose, onSubmit}) => {
  const {formData, setFormData, resetForm} = useForm(initialFormData);
  const handleClose = () => {
    resetForm();
    onClose();
  };

  useEffect(
    () => {
      applyFilters(formData);
    },
    [ applyFilters, formData ]
  );

  return (
    <FiltersContainer
      onReset={handleClose}
      marginBottom={5}
      onClose={handleClose}
    >
      <form onSubmit={onSubmit}>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <ActiveSelect
              value={formData.state}
              onChange={value => setFormData("state", value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.tags}
              id="tags"
              label={
                <IntlMessages id="guardian.operative.plannings.table.header.tags" />
              }
              onChange={value => setFormData("tags", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.fund}
              id="fund"
              label={<IntlMessages id="administrator.guardian.fund" />}
              onChange={value => setFormData("fund", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.cash}
              id="cash"
              label={<IntlMessages id="administrator.guardian.cash" />}
              onChange={value => setFormData("cash", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.traffic}
              id="traffic"
              label={<IntlMessages id="administrator.guardian.traffic" />}
              onChange={value => setFormData("traffic", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.liquidated}
              id="liquidated"
              label={<IntlMessages id="administrator.guardian.liquidated" />}
              onChange={value => setFormData("liquidated", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.refund}
              id="refund"
              label={<IntlMessages id="administrator.guardian.refund" />}
              onChange={value => setFormData("refund", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <CurrencySelect
              fullWidth
              value={formData.currency}
              id="currency"
              onChange={value => setFormData("currency", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default BoxesFilters;
