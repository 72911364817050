import {createSlice} from "@reduxjs/toolkit";
import {getTeltonikaData} from "../../thunks/BackOffice/teltonika";

const INIT_DATA = {
    data: [],
    localFilters: {
        event: '',
        timeFix: ''
    },
    serviceFilters: {
        param: '',
        paramValue: '',
        startDate: '',
        endDate: '',
    }
}

const teltonikaSlice = createSlice({
    name: 'teltonika',
    initialState: {
        ...INIT_DATA
    },
    reducers: {
        setLocalFilters: (state, action) => {
            state.localFilters = action.payload;
        },
        setServiceFilters: (state, action) => {
            state.serviceFilters = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getTeltonikaData.fulfilled, (state, action) => {
            const {filters, data} = action.payload;
            state.serviceFilters = filters;
            state.data = data;
        })
    }
});


export default teltonikaSlice.reducer;

export const {setLocalFilters, setServiceFilters} = teltonikaSlice.actions;