class ProductPicker {
  constructor(
    id,
    name,
    codExterno,
    esEnvase,
    idUnidad,
    unidad,
    volumenUnidad,
    fechaAlta,
    fechaBaja,
    active
  ) {
    this.id = id;
    this.name = name;
    this.codExterno = codExterno;
    this.esEnvase = esEnvase;
    this.idUnidad = idUnidad;
    this.unidad = unidad;
    this.volumenUnidad = volumenUnidad;
    this.fechaAlta = fechaAlta;
    this.fechaBaja = fechaBaja;
    this.active = active;
  }

  static map = product => {
    return new ProductPicker(
      product.nu_id_producto,
      product.vc_desc_producto,
      product.nu_id_externo,
      product.bi_envase === "1",
      product.nu_id_unidad,
      product.vc_desc_unidad,
      product.nu_volumen_unidad,
      product.dt_fec_alta,
      product.dt_fec_baja,
      product.bi_estado === "1"
    );
  };
}

export default ProductPicker;
