import {mapToBoolean} from "../../../utils/mappers";

class SinisterType {
  constructor(id, description, status) {
    this.id = id;
    this.description = description;
    this.status = status;
  }

  static map = type => {
    return new SinisterType(
      type.nu_id_tipo_siniestro,
      type.vc_desc_tipo_siniestro,
      mapToBoolean(type.bi_estado)
    );
  };
}

export default SinisterType;
