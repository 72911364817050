import React from "react";
import PropTypes from "prop-types";

import Row from "./Row";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import useTableSort from "hooks/Common/useTableSort";

const GuardiansByCurrency = ({
  data,
  onEdit,
  onDelete,
  onRestore,
  isLoading
}) => {
  const dataWithAmounts = data.map(row => ({
    ...row,
    amount: row.guardians.reduce(
      (amount, guardian) => amount + guardian.fund,
      0
    )
  }));

  const {tableData, onSort, order, orderBy} = useTableSort(dataWithAmounts);

  const RenderRow = ({row}) => (
    <Row
      rowData={row}
      onEdit={onEdit}
      onDelete={onDelete}
      onRestore={onRestore}
    />
  );

  return (
    <TableComponent
      data={tableData}
      isLoading={isLoading}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.currency}
    />
  );
};

GuardiansByCurrency.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      guardians: PropTypes.arrayOf(
        PropTypes.shape({
          fund: PropTypes.number
        })
      )
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func
};

GuardiansByCurrency.defaultProps = {
  data: {},
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {}
};

export default GuardiansByCurrency;
