import React, {useEffect} from "react";
import {Grid} from "@material-ui/core";
import BaseField from "../BaseField";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const DriverField = ({driver}) => {
  return (
    <Grid item xs={12} sm={12}>
      <BaseField>
        <p>
          {driver.name || (
            <IntlMessages id={"guardian.add.advance.form.driver"} />
          )}
        </p>
      </BaseField>
    </Grid>
  );
};

export default DriverField;
