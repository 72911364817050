export const RESET_ELEVATORS_CATEGORIES_STATE =
  "RESET_ELEVATORS_CATEGORIES_STATE";
export const FETCH_ALL_ELEVATORS_CATEGORIES = "FETCH_ALL_ELEVATORS_CATEGORIES";
export const SET_CURRENT_ELEVATOR_CATEGORY = "SET_CURRENT_ELEVATOR_CATEGORY";
export const RESET_CURRENT_ELEVATOR_CATEGORY =
  "RESET_CURRENT_ELEVATOR_CATEGORY";
export const EDIT_ELEVATOR_CATEGORY = "EDIT_ELEVATOR_CATEGORY";
export const ADD_ELEVATOR_CATEGORY = "ADD_ELEVATOR_CATEGORY";
export const APPLY_ELEVATORS_CATEGORIES_FILTERS =
  "APPLY_ELEVATORS_CATEGORIES_FILTERS";
export const RESET_ELEVATORS_CATEGORIES_FILTERS =
  "RESET_ELEVATORS_CATEGORIES_FILTERS";
