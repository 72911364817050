import React, {useEffect, useCallback, useState} from "react";
import {connect} from "react-redux";

import PageContainer from "components/Administrator/WarehouseTransfer/PageContainer";
import CreateWarehouseTransferTypeDialog from "components/Administrator/WarehouseTransfer/SaveWarehouseTransferDialog/CreateWarehouseTransferDialog";
import EditWarehouseTransferTypeDialog from "components/Administrator/WarehouseTransfer/SaveWarehouseTransferDialog/EditWarehouseTransferDialog";
import DeleteConfirmation from "components/Administrator/WarehouseTransfer/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/WarehouseTransfer/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/WarehouseTransfer/Table";
import Filters from "components/Administrator/WarehouseTransfer/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

import useUiState from "hooks/Administrator/useUiState";
import warehouseTransfer from "redux/actions/Administrator/WarehouseTransfer";
import selectEntity from "redux/actions/Administrator/WarehouseEntitySelect";
import IntlMessages from "@jumbo/utils/IntlMessages";
import {Button, Grid} from "@material-ui/core";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import ImportDialog from "../../../../components/Administrator/WarehouseTransfer/ImportDialog";

const WarehouseTransfer = ({tableData, dispatch}) => {
  const ui = useUiState();
  const [ importOpen, setImportOpen ] = React.useState(false);
  useEffect(
    () => {
      dispatch(warehouseTransfer.get());
      return () => dispatch(warehouseTransfer.resetState());
    },
    [ dispatch ]
  );

  /*useEffect(() => {
        dispatch(selectEntity.get());
        return () => dispatch(selectEntity.resetState());
    },[dispatch])*/

  const applyFilters = useCallback(
    filters =>
      dispatch(warehouseTransfer.applyWarehouseTransferFilter(filters)),
    [ dispatch ]
  );
  const resetFilters = () =>
    dispatch(warehouseTransfer.resetWarehouseTransferFilters());

  const onRestore = () => {
    dispatch(warehouseTransfer.restore(ui.restoringId));
    //dispatch(warehouseTransfer.get());
  };
  const onDelete = () => {
    dispatch(warehouseTransfer.delete(ui.deletingId));
    //dispatch(warehouseTransfer.get());
  };
  const onEdit = data => {
    dispatch(warehouseTransfer.edit(ui.editingId, data));
    //dispatch(warehouseTransfer.get());
  };
  const onCreate = data => {
    dispatch(warehouseTransfer.create(data));
    //dispatch(warehouseTransfer.get());
  };

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      <GridContainer spacing={2}>
        <Grid item xs={12} md={6}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setImportOpen(true)}
          >
            <IntlMessages id={"logistic.warehouse.transfer.import.button"} />
          </Button>
        </Grid>
      </GridContainer>
      {
        <Box marginBottom={25} marginTop={8}>
          <Table
            data={tableData.filter(type => type.active !== false)}
            {...ui.tableProps}
          />
        </Box>
      }
      <CreateWarehouseTransferTypeDialog
        onSubmit={onCreate}
        {...ui.createFormProps}
      />
      {editing && (
        <EditWarehouseTransferTypeDialog
          initialData={editing}
          onSubmit={onEdit}
          {...ui.editFormProps}
        />
      )}
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
      {importOpen && <ImportDialog open={importOpen} setOpen={setImportOpen} />}
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.warehouseTransfer;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("active", filters.active)
    .byFieldBetweenDates("date", filters.fromDate, filters.toDate)
    .byFieldIncludes("entityNameDestiny", filters.destiny)
    .byFieldIncludes("product", filters.products)
    .byFieldIncludes("active", filters.active)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(WarehouseTransfer);
