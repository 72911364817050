import React from 'react';
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {useDispatch, useSelector} from "react-redux";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import {parseDateToDateString} from "../../../../../../utils/dates";
import {getExcelOfUpdateHistory} from "../../../../../../redux/actions/Administrator/WorkOrdersBreakdown";
import {Button} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const HistoryTable = () => {
    const {currentDestination} = useSelector(
        state => state.administrator.breakdown
    );
    const dispatch = useDispatch();
    const downloadExcel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(getExcelOfUpdateHistory(currentDestination.id));
    }
    return (
        <>
            <Button
                variant='contained'
                color="primary"
                onClick={downloadExcel}
                style={{
                    marginBottom: '1rem',
                    marginTop: '1rem',
                }}
            >
                <IntlMessages id="generate.format.excel" />
            </Button>
            <TableContainer
                head={<TableHeader />}
            >
                {currentDestination?.updatesHistory?.map((row, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>{parseDateToDateString(row.date)}</StyledTableCell>
                        <StyledTableCell>{row.duration}</StyledTableCell>
                        <StyledTableCell>{row.distance}</StyledTableCell>
                        <StyledTableCell>{row.user}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableContainer>
        </>
    );
};

export default HistoryTable;