import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "../AddHeadquarterDialog/Form";

const EditHeadquarterDialog = ({isOpen, onClose, onSubmit, initialData}) => {
  const id = "edit-headquarter-form";
  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      title={
        <IntlMessages id="administrator.clients.edit.headquarter.form.title" />
      }
      onClose={onClose}
    >
      {initialData && (
        <Form id={id} initData={initialData} onSubmit={onSubmit} />
      )}
    </FormDialog>
  );
};

export default EditHeadquarterDialog;
