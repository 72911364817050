import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import validations from "./validations";

const initData = {
  description: ""
};

const Form = ({id, onSubmit, initialData = null}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initialData || initData,
    validations
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item sm={12}>
          <OutlinedInput
            fullWidth
            hasError={errors.description.length > 0}
            error={errors.description[0]}
            value={formData.description}
            id="tag-description"
            label={<IntlMessages id="administrator.tags.form.description" />}
            onChange={value => setFormData("description", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
