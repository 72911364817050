import axiosInstance from "../../../utils/axiosInstance";
import {v4 as uuidV4} from "uuid";
const getUrl = (path) => (`/Cipia/${path}`);

export const getAllDrivingControlEvents = async ({filters, page, pageSize}) => {
    try {
        const {data: {data, header}} = await axiosInstance.post(
            getUrl('sel_events'),
            {
                page: page + 1,
                pageSize,
                unitId: filters?.units?.map(unit => unit.id) ?? [],
                driverId: filters?.driver ?? '',
                tripId: filters?.trip ?? '',
                eventType: filters?.eventTypes?.map(eventType => eventType.name) ?? [],
                timeFrom: filters?.startTime ?? '',
                timeTo: filters?.endTime ?? '',
                order: 'eventDateTime'
            }
        );
        return {events: data.map(event => ({id: uuidV4(), ...event})), header};
    }catch (e) {
        return Promise.reject(e.message);
    }

};

export const getDrivingEventsExcel = async ({filters}) => {
    try {
        const {data} = await axiosInstance.post(
            getUrl('xlsx_events'),
            {
                unitId: filters?.units?.map(unit => unit.id) ?? [],
                driverId: filters?.driver ?? '',
                tripId: filters?.trip ?? '',
                eventType: filters?.eventTypes?.map(eventType => eventType.id) ?? [],
                timeFrom: filters?.startTime ?? '',
                timeTo: filters?.endTime ?? '',
                order: 'DriverId'
            }
        );
        return data;
    }catch (e) {
        return Promise.reject(e.message);
    }

};


export const getAllDevices = async () => {
    try {
        const {data: {data}} = await axiosInstance.post(
            getUrl('sel_devices'),
            {}
        );
        return data.map(device => ({
            id: device.id,
            name: device.id,
        }));
    }catch (e) {
        return Promise.reject(e.message);
    }
};

export const getAllEventTypes = async () => {
    try {
        const {data: {data}} = await axiosInstance.post(
            getUrl('sel_event_types'),
            {}
        );
        return data.map(type => ({
            id: uuidV4(),
            name: type,
        }));
    }catch (e) {
        return Promise.reject(e.message);
    }
};

export const getAllEventMedias = async ({eventId, mediaType, deviceId}) => {
    try {
        const {data: media} = await axiosInstance.post(
            getUrl('sel_media'),
            {
                messageId: eventId,
                deviceId,
                type: mediaType,
            }
        );
        return {
            id: media.fileDownloadName,
            downloadName: media.fileDownloadName,
            base64: media.fileContents,
            contentType: media.contentType,
        };
    }catch (e) {
        return Promise.reject(e.message);
    }
};