import {getUserPermissions} from "../../../../../services/StorageService";
import IntlMessages from "../../../../utils/IntlMessages";
import React from "react";
import {PostAdd} from "@material-ui/icons";

const getDailyOperationOptions = submodules => {
    let options = [];
    submodules.some(s => s.id === 5) && options.push({
        name: <IntlMessages id={'sidebar.location.dailyOperations.map'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/localization/daily-operations/map',
    });
    submodules.some(s => s.id === 96) && options.push({
        name: <IntlMessages id={'localization.daily.operation.wall.map.sidebar'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/localization/daily-operations/wall-map',
    });
    submodules.some(s => s.id === 115) && options.push({
        name: <IntlMessages id={'localization.daily.operation.driving.control.sidebar'}/>,
        type: 'item',
        icon: <PostAdd/>,
        link: '/localization/daily-operations/driving-control',
    });
    return options;
}

export const getLocalizationOptions = () => {
    const permissions = getUserPermissions();
    let localization = {
        name: <IntlMessages id={'sidebar.location'}/>,
        type: 'section',
        children: []
    };
    const dailyOperationSubmodules = permissions.find(p => p.id===2)?.submodules;
    if(dailyOperationSubmodules?.length > 0) {
        const dailyOperationOptions = getDailyOperationOptions(dailyOperationSubmodules);
        dailyOperationOptions.length > 0 && localization.children.push({
            name: <IntlMessages id={'sidebar.location.dailyOperations'}/>,
            type: 'collapse',
            children: dailyOperationOptions,
        });
    }

    return localization.children.length > 0? localization : null;
}