import React from "react";
import CheckStockBodyEntity from "./CheckStockBodyEntity";
import CheckStockBodyProduct from "./CheckStockBodyProduct";

const CheckStockRow = ({
  orderGrouped,
  driver,
  setSelectedAdvance,
  isGuardian = true
}) => {
  if (orderGrouped === "Producto") {
    return (
      <React.Fragment>
        <CheckStockBodyProduct
          driver={driver}
          setSelectedAdvance={setSelectedAdvance}
          isGuardian={isGuardian}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <CheckStockBodyEntity
          driver={driver}
          setSelectedAdvance={setSelectedAdvance}
          isGuardian={isGuardian}
        />
      </React.Fragment>
    );
  }
};

export default CheckStockRow;
