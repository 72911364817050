import {withStyles} from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const UnCheckedIcon = withStyles(theme => ({
  root: {
    color: "red"
  }
}))(CheckCircleOutlineIcon);

export const CheckedIcon = withStyles(theme => ({
  root: {
    color: "green"
  }
}))(CheckCircleIcon);
