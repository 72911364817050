import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";
import CrudTableRow from "components/Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "components/Common/Tables/CrudTable/RestoreTableRow";
import useTableSort from "hooks/Common/useTableSort";
import TableComponent from "components/Common/Tables/Table";
import TableHeader from "./TableHeader";
import {setCurrentRejectionType} from "../../../../redux/actions/Logistic/RejectionTypes";
import {useDispatch} from "react-redux";

const Table = ({data, setOpenEdit, setOpenDelete, setOpenRestore}) => {
  const dispatch = useDispatch();
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  const onEdit = type => {
    dispatch(setCurrentRejectionType(type));
    setOpenEdit && setOpenEdit(true);
  };
  const onDelete = type => {
    dispatch(setCurrentRejectionType(type));
    setOpenDelete && setOpenDelete(true);
  };
  const onRestore = type => {
    dispatch(setCurrentRejectionType(type));
    setOpenRestore && setOpenRestore(true);
  };

  const RenderRow = ({row}) =>
    row.status ? (
      <CrudTableRow onEdit={() => onEdit(row)} onDelete={() => onDelete(row)}>
        <StyledTableCell>{row.description}</StyledTableCell>
        <StyledTableCell>{row.code}</StyledTableCell>
      </CrudTableRow>
    ) : (
      <RestoreTableRow onRestore={() => onRestore(row)}>
        <StyledTableCell>{row.description}</StyledTableCell>
        <StyledTableCell>{row.code}</StyledTableCell>
      </RestoreTableRow>
    );

  return (
    <TableComponent
      data={tableData}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      description: PropTypes.string,
      active: PropTypes.bool
    })
  ),
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func
};

Table.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onRestore: () => {}
};

export default Table;
