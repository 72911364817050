import {
  ADD_MESSAGE,
  APPLY_ALERTS_FILTERS,
  APPLY_DISPATCHES_FILTERS,
  APPLY_MONITORING_FILTERS,
  FETCH_ALL_ALERTS_STARTED,
  FETCH_ALL_ALERTS_SUCCEED,
  FETCH_ALL_CHAT_USERS_STARTED,
  FETCH_ALL_CHAT_USERS_SUCCEED,
  FETCH_ALL_CHATS_STARTED,
  FETCH_ALL_CHATS_SUCCEED,
  FETCH_ALL_DISPATCHES_DESTINATIONS_STARTED,
  FETCH_ALL_DISPATCHES_DESTINATIONS_SUCCEED,
  FETCH_ALL_DISPATCHES_WORK_ORDERS_STARTED,
  FETCH_ALL_DISPATCHES_WORK_ORDERS_SUCCEED,
  FETCH_ALL_MONITORING_WORK_ORDERS_STARTED,
  FETCH_ALL_MONITORING_WORK_ORDERS_SUCCEED,
  LOAD_SELECTED_CHAT_STARTED,
  LOAD_SELECTED_CHAT_SUCCEED,
  RESET_ALERTS_FILTERS,
  RESET_CURRENT_WORK_ORDER,
  RESET_DISPATCHES_FILTERS,
  RESET_RESULT,
  RESET_SELECTED_USER,
  SEND_MESSAGE,
  SET_CURRENT_MONITORING_WORK_ORDER,
  SET_SELECTED_USER,
  UPDATE_ALERT_STATE
} from "../../types/Logistic/MonitoringTypes";
import moment from "moment";
import {getUserId} from "../../../services/StorageService";
import WordOrder from "../../../domain/WordOrder";
import {
  getDispatchDestinations,
  getDispatchWorkOrders,
  getMonitoringAlerts,
  getMonitoringWorkOrders
} from "../../../services/MonitoringService";
import {getWorkOrderById} from "../../../services/WorkOrdersService";

const chats = [
  {
    id: 1,
    name: "User 1",
    profilePicture: "",
    lastMessage: {
      time: moment(Date.now()).format("DD/MM/yyyy hh:mm"),
      text: "Message here"
    }
  },
  {
    id: 2,
    name: "User 2",
    profilePicture: "",
    lastMessage: {
      time: moment(Date.now()).format("DD/MM/yyyy hh:mm"),
      text: "Message here"
    }
  }
];
const chatUsers = [
  {
    id: 1,
    name: "User 1",
    profilePicture: ""
  },
  {
    id: 2,
    name: "User 2",
    profilePicture: ""
  }
];

const chatMessages = [
  {
    id: new Date().getTime(),
    user: {
      id: 1,
      name: "User 1",
      profilePicture: ""
    },
    message: "Hello",
    type: "sent",
    messageType: "text",
    sentAt: moment()
  }
];

const fetchAllMonitoringWorkOrdersSucceed = (info, workOrders) => {
  return dispatch => {
    dispatch({
      type: FETCH_ALL_MONITORING_WORK_ORDERS_SUCCEED,
      payload: {
        data: [ ...workOrders ],
        info
      }
    });
  };
};

export const fetchAllMonitoringWorkOrdersStarted = filters => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_MONITORING_WORK_ORDERS_STARTED});
    const list = await getMonitoringWorkOrders(filters);
    dispatch(
      fetchAllMonitoringWorkOrdersSucceed(list.summary, list.workOrders)
    );
  };
};

export const applyMonitoringWorkOrdersFilters = filters => ({
  type: APPLY_MONITORING_FILTERS,
  payload: filters
});

export const fetchMonitoringData = (otsFilters, alertsFilers) => {
  return dispatch => {
    dispatch(fetchAllAlertsStarted(alertsFilers));
    dispatch(fetchAllMonitoringWorkOrdersStarted(otsFilters));
  };
};

const fetchAllChatsSucceed = data => {
  return dispatch => {
    dispatch({type: FETCH_ALL_CHATS_SUCCEED, payload: [ ...data ]});
  };
};

export const fetchAllChatsStarted = () => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_CHATS_STARTED});
    setTimeout(() => {
      dispatch(fetchAllChatsSucceed(chats));
    }, 1000);
  };
};

const fetchAllChatUsersSucceed = data => {
  return dispatch => {
    dispatch({type: FETCH_ALL_CHAT_USERS_SUCCEED, payload: [ ...data ]});
  };
};

export const fetchAllChatUsersStarted = () => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_CHAT_USERS_STARTED});
    setTimeout(() => {
      dispatch(fetchAllChatUsersSucceed(chatUsers));
    }, 1000);
  };
};

const loadChatSucceed = chat => {
  return dispatch => {
    dispatch({type: LOAD_SELECTED_CHAT_SUCCEED, payload: chat});
  };
};

const loadChatStarted = user => {
  return dispatch => {
    dispatch({type: LOAD_SELECTED_CHAT_STARTED});
    setTimeout(() => {
      dispatch(loadChatSucceed(chatMessages));
    }, 1000);
  };
};

export const setSelectedChatUser = user => {
  return dispatch => {
    dispatch({type: SET_SELECTED_USER, payload: user});
    dispatch(loadChatStarted(user));
  };
};

export const resetSelectedUser = () => ({type: RESET_SELECTED_USER});

const addMessage = message => ({type: ADD_MESSAGE, payload: message});

export const sendMessage = data => {
  const message = {
    id: new Date().getTime(),
    user: getUserId(),
    message: data,
    type: "sent",
    messageType: "text",
    sentAt: moment()
  };
  return dispatch => {
    dispatch({type: SEND_MESSAGE, payload: message});
    setTimeout(() => {
      dispatch(addMessage(message));
    }, 1000);
  };
};

export const setMonitoringCurrentWorkOrder = workOrderId => {
  return async dispatch => {
    dispatch({type: SET_CURRENT_MONITORING_WORK_ORDER, payload: null});
    getWorkOrderById(workOrderId)
      .then(workOrder => {
        dispatch({type: SET_CURRENT_MONITORING_WORK_ORDER, payload: workOrder});
      })
      .catch(e => {
        dispatch(resetMonitoringCurrentWorkOrder());
      });
  };
};

export const resetMonitoringCurrentWorkOrder = () => ({
  type: RESET_CURRENT_WORK_ORDER
});

const fetchAllAlertsSucceed = data => ({
  type: FETCH_ALL_ALERTS_SUCCEED,
  payload: data
});

export const fetchAllAlertsStarted = filters => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_ALERTS_STARTED});
    const alerts = await getMonitoringAlerts(filters);
    dispatch(fetchAllAlertsSucceed(alerts));
  };
};

export const applyAlertsFilters = filters => {
  return dispatch => {
    dispatch({type: APPLY_ALERTS_FILTERS, payload: filters});
  };
};

export const resetAlertsFilters = () => ({type: RESET_ALERTS_FILTERS});

export const applyDispatchesFilters = filters => {
  return dispatch => {
    dispatch({type: APPLY_DISPATCHES_FILTERS, payload: filters});
  };
};

export const resetDispatchesFilters = () => ({type: RESET_DISPATCHES_FILTERS});

const fetchAllDispatchesWorkOrdersSucceed = data => ({
  type: FETCH_ALL_DISPATCHES_WORK_ORDERS_SUCCEED,
  payload: data
});
export const fetchAllDispatchesWorkOrdersStarted = filters => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_DISPATCHES_WORK_ORDERS_STARTED});
    const workOrders = await getDispatchWorkOrders(filters);
    dispatch(fetchAllDispatchesWorkOrdersSucceed(workOrders));
  };
};

const fetchAllDispatchesDestinationsSucceed = data => ({
  type: FETCH_ALL_DISPATCHES_DESTINATIONS_SUCCEED,
  payload: data
});
export const fetchAllDispatchesDestinationsStarted = filters => {
  return async dispatch => {
    dispatch({type: FETCH_ALL_DISPATCHES_DESTINATIONS_STARTED});
    const destinations = await getDispatchDestinations(filters);
    dispatch(fetchAllDispatchesDestinationsSucceed(destinations));
  };
};

export const resetOperationState = () => ({type: RESET_RESULT});

export const updateMonitoringAlertState = (alertId, filters) => {
  return async dispatch => {
    const response = await updateMonitoringAlertState(alertId);
    if (response.transactionState === 1) {
      dispatch({
        type: UPDATE_ALERT_STATE,
        payload: {ok: true, message: response.transactionMessage}
      });
      dispatch(fetchAllAlertsStarted(filters));
    } else {
      dispatch({
        type: UPDATE_ALERT_STATE,
        payload: {ok: false, message: response.transactionMessage}
      });
    }
  };
};
