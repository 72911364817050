import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.sidebar.textActiveColor,
    color: "#ffffff"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    marginLeft: "8px"
  },
  list: {
    width: "100%",
    maxWidth: "100%",
    backgroundColor: theme.palette.background.paper,
    color: "#000000"
  },
  noText: {
    width: "100%",
    maxWidth: "100%",
    padding: "12px",
    backgroundColor: theme.palette.background.paper,
    color: "#000000"
  }
}));
