import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import Stock from "../domain/Stock";
import Response from "../domain/Stock";
import {getCompanyEntityId} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevStockFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllStock = async () => {
  try {
    const response = await axiosInstance.post(
      "/Inventario_Kardex/sel",
      {
        nu_id_entidad: getCompanyEntityId()
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(stock => Stock.map(stock));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};
