import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import Picker from "../../../Common/Forms/Pickers/Picker";
import {fetchAllCentrals} from "../../../../services/CentralsService";
import {useDispatch} from "react-redux";

const CentralSelect = ({
  centrals,
  onChange,
  isLoading = false,
  hasError,
  error
}) => {
  const [ options, setOptions ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(() => {
    fetchAllCentrals()
      .then(centrals => setOptions(centrals))
      .catch(e => dispatch(e.message));
  }, []);

  return (
    <Picker
      id="centrals-filter"
      options={options}
      values={centrals}
      onChange={onChange}
      fullWidth
      disabled={isLoading}
      error={error}
      hasError={hasError}
      title={<IntlMessages id="logistic.monitoring.filters.centrals.label" />}
      headerText={
        <IntlMessages id="logistic.monitoring.filters.centrals.select" />
      }
      noOptionsText={
        <IntlMessages id="logistic.monitoring.filters.centrals.none" />
      }
    />
  );
};

export default CentralSelect;
