import React, {useEffect} from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import GridContainer from "@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "components/Common/Forms/Select/ActiveSelect";
import useForm from "hooks/Common/useForm";
import OutlinedInput from "components/Common/Forms/OutlinedInput";
import Select from "components/Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedDatePicker from "components/Common/Forms/OutlinedDatePicker";
import {mapDateToOutput, mapDateToValue} from "utils/parsers";

const initialData = {
  active: undefined,
  destiny: "",
  products: "",
  fromDate: null,
  toDate: null
};

const Form = ({onFilter}) => {
  const {formData, setFormData} = useForm(initialData);

  useEffect(
    () => {
      onFilter(formData);
    },
    [ onFilter, formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        {/*<Grid item xs={12} sm={4} lg={3}>
                    <ActiveSelect
                        id="advance-types-state-filter"
                        value={formData.active}
                        onChange={value => setFormData('active', value)}
                        fullWidth
                    />
                </Grid>*/}
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.destiny}
            id="destiny-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.destiny" />
            }
            onChange={value => setFormData("destiny", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.products}
            id="products-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.product" />
            }
            onChange={value => setFormData("products", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.fromDate)}
            id="date-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.fromDate" />
            }
            onChange={value => setFormData("fromDate", mapDateToOutput(value))}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedDatePicker
            fullWidth
            value={mapDateToValue(formData.toDate)}
            id="date-filter"
            label={
              <IntlMessages id="logistic.warehouseTransfer.filter.toDate" />
            }
            onChange={value => setFormData("toDate", mapDateToOutput(value))}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
