import React from "react";
import {useDispatch} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {addElevatorState} from "../../../../../../redux/actions/ElevatorsManagement/States";

const AddStateDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addElevatorState(data));
    setOpen(false);
  };
  return (
    <BaseDialog
      id="add-state-form"
      title={
        <IntlMessages
          id={"elevators.management.elevators.state.type.add.title"}
        />
      }
      open={open}
      setOpen={setOpen}
      initData={{
        name: ""
      }}
      onSubmit={onSubmit}
    />
  );
};

export default AddStateDialog;
