import React from 'react';
import {Box, Typography} from "@material-ui/core";
import {useStyles} from "../../styles";
import useForm from "../../../../../../hooks/Common/useForm";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import AutomaticStateSelect from "./AutomaticStateSelect";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";

const AutomaticStates = ({current}) => {
    const {automaticStates, states} = current;
    const [edit, setEdit] = React.useState(false);
    const {formData, setFormData} = useForm({
        arrivalState: automaticStates.arrivalState?.id || "",
        departureState: automaticStates.departureState?.id || "",
        nextService: automaticStates.nextService?.id || "",
    })
    const classes = useStyles();
    const onSave = () => {
        // TODO: Add update automatics services
        console.log({formData})
        setEdit(false);
    }
    return(
        <Box className={classes.automaticsBox}>
            <Box mb={4}>
                <Typography variant="h1">
                    <IntlMessages id={"logistic.distribution.work.orders.types.detail.state.automatic.title"}/>
                </Typography>
            </Box>
            <Box className={classes.automaticsListBox}>
                <AutomaticStateSelect
                    states={states}
                    value={formData.arrivalState}
                    setValue={value => setFormData('arrivalState', value)}
                    label={<IntlMessages id={"logistic.distribution.work.orders.types.detail.state.automatic.arrival.destination"}/>}
                    disabled={!edit}
                />
                <AutomaticStateSelect
                    states={states}
                    value={formData.departureState}
                    setValue={value => setFormData('departureState', value)}
                    label={<IntlMessages id={"logistic.distribution.work.orders.types.detail.state.automatic.departure.destination"}/>}
                    disabled={!edit}
                />
                <AutomaticStateSelect
                    states={states}
                    value={formData.nextService}
                    setValue={value => setFormData('nextService', value)}
                    label={<IntlMessages id={"logistic.distribution.work.orders.types.detail.state.automatic.next.service"}/>}
                    disabled={!edit}
                />
            </Box>
            <Box className={classes.buttonBox}>
                {
                    edit
                        ? <React.Fragment>
                            <ContainedButton
                                text={<IntlMessages id={"form.button.cancel"}/>}
                                color={"primary"}
                                onClick={() => setEdit(false)}
                            />
                            <ContainedButton
                                text={<IntlMessages id={"form.button.save"}/>}
                                color={"primary"}
                                onClick={() => onSave()}
                            />
                        </React.Fragment>
                        : <ContainedButton
                            text={<IntlMessages id={"form.button.edit"}/>}
                            color={"primary"}
                            onClick={() => setEdit(true)}
                        />
                }
            </Box>
        </Box>
    );
}

export default AutomaticStates;