import React from "react";
import PageContainer from "../../../../components/Logistic/Monitoring/PageContainer";
import FiltersDialog from "../../../../components/Logistic/Monitoring/FiltersDialog";
import InfoBar from "../../../../components/Logistic/Monitoring/InfoBar";
import {useDispatch, useSelector} from "react-redux";
import {
  fetchAllAlertsStarted,
  fetchAllMonitoringWorkOrdersStarted,
  fetchMonitoringData,
  resetMonitoringCurrentWorkOrder
} from "../../../../redux/actions/Logistic/Monitoring";
import WorkOrderList from "../../../../components/Logistic/Monitoring/WorkOrdersList";
import useSearch from "../../../../hooks/Common/useSearch";
import ChatDialog from "../../../../components/Logistic/Monitoring/ChatDialog";
import DeviceDetailsDialog from "../../../../components/Logistic/Monitoring/DeviceDetailsDialog";
import WorkOrderDialog from "../../../../components/Logistic/WorkOrders/WorkOrderDialog";
import AlertsDialog from "../../../../components/Logistic/Monitoring/AlertsDialog";
import DispatchesDialog from "../../../../components/Logistic/Monitoring/DispatchesDialog";
import ResultAlert from "../../../../components/Logistic/Monitoring/ResultAlert";

const Monitoring = () => {
  const [ openFilters, setOpenFilters ] = React.useState(false);
  const [ expand, setExpand ] = React.useState(true);
  const [ chatDialog, setChatDialog ] = React.useState(false);
  const [ alertsDialog, setAlertsDialog ] = React.useState(false);
  const [ dispatchesDialog, setDispatchesDialog ] = React.useState(false);
  const [ device, setDevice ] = React.useState(null);
  const dispatch = useDispatch();
  const {filters, data, current, alerts} = useSelector(
    state => state.logistic.monitoring
  );
  const {filteredData, onSearch, search} = useSearch(data);
  const [ selectedDestination, setSelectedDestination ] = React.useState(null);

  const fetchData = () => {
    dispatch(fetchMonitoringData(filters, alerts.filters));
  };

  React.useEffect(
    () => {
      const interval = setInterval(() => {
        fetchData();
      }, 120000);

      return () => clearInterval(interval);
    },
    [ filters, alerts.filters ]
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <PageContainer>
      <InfoBar
        openDispatchesDialog={() => setDispatchesDialog(true)}
        openAlertsDialog={() => setAlertsDialog(true)}
        openChatDialog={() => setChatDialog(true)}
        expandAll={expand}
        setExpandAll={setExpand}
        setOpenFilters={setOpenFilters}
        search={search}
        onSearch={onSearch}
      />
      <FiltersDialog open={openFilters} setOpen={setOpenFilters} />
      <WorkOrderList
        setSelectedDestination={setSelectedDestination}
        setDevice={setDevice}
        openChatDialog={() => setChatDialog(true)}
        expandAll={expand}
        data={filteredData}
      />
      {/*<ChatDialog*/}
      {/*    open={chatDialog}*/}
      {/*    setOpen={setChatDialog}*/}
      {/*/>*/}
      {device && (
        <DeviceDetailsDialog onClose={() => setDevice(null)} device={device} />
      )}
      {current !== undefined && (
        <WorkOrderDialog
          noEdit={true}
          preSelectDestination={selectedDestination}
          current={current}
          open={true}
          handleClose={() => {
            dispatch(resetMonitoringCurrentWorkOrder());
          }}
        />
      )}
      <AlertsDialog open={alertsDialog} setOpen={setAlertsDialog} />
      <DispatchesDialog open={dispatchesDialog} setOpen={setDispatchesDialog} />
      <ResultAlert />
    </PageContainer>
  );
};

export default Monitoring;
