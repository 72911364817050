import {
  FETCH_TRACEABILITY_SUCCEEDED,
  APPLY_TRACEABILITY_FILTERS,
  RESET_TRACEABILITY_FILTERS
} from "../../types/Administrator/Traceability";
import moment from "moment";

const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    entityOrigin: "",
    entityDestiny: "",
    product: "",
    type: "",
    OT: "",
    fromDate: moment(new Date().toISOString())
      .subtract(30, "days")
      .format("YYYY-MM-DD"),
    toDate: moment(new Date().toISOString()).format("YYYY-MM-DD"),
    direction: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "RESTORE_ADMINISTRATOR_STATE": {
      return {
        ...INIT_STATE
      };
    }
    case FETCH_TRACEABILITY_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case APPLY_TRACEABILITY_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }
    case RESET_TRACEABILITY_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
    default: {
      return state;
    }
  }
};
