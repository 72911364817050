import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    getAllAavvServices,
    getAllAavvServicesHeaders, updateAavvServicesHeaders
} from "../../../services/Logistics/Distribution/AavvServicesService";

export const fetchAllAavvServices = createAsyncThunk(
    'aavvServices/fetchAllAavvServices',
    async (filters, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const headers = await getAllAavvServicesHeaders();
            const services = await getAllAavvServices(filters);
            dispatch(fetchSuccess());
            return {headers, services};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)


export const editAavvServicesHeaders = createAsyncThunk(
    'aavvServices/editAavvServicesHeaders',
    async (headers, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const { transactionMessage } = await updateAavvServicesHeaders(headers);
            dispatch(fetchSuccess(transactionMessage));
            return headers;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const editAavvServiceNonComplianceReason = createAsyncThunk(
    'aavvServices/editAavvServiceNonComplianceReason',
    async ({service, filters}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const message = "Servicio Actualizado";
            dispatch(fetchSuccess(message));
            setTimeout(() => {
                dispatch(fetchAllAavvServices(filters));
            }, 1500);
            return ;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)

export const getCurrentLocation = createAsyncThunk(
    'aavvServices/getCurrentLocation',
    async (service, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            dispatch(fetchSuccess());
            window.open(
                `https://www.google.com/maps/search/?api=1&query=${-20}%2C${-20}`
            );
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)
