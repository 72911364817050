import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {resetCurrentVehicleSpecification} from "../../../../../redux/actions/FleetManagement/TechnicalSpecification";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import BasicFile from "./BasicFile";
import TechnicalSheet from "./TechnicalSheet";

const VehicleDetailDialog = () => {
    const {current} = useSelector(({fleetManagement}) => fleetManagement.technicalSpecification);
    const dispatch = useDispatch();
    const onCloseDialog = () => {
        dispatch(resetCurrentVehicleSpecification());
    }
    return (
        <FullScreenDialog
            open={Boolean(current)}
            handleClose={onCloseDialog}
            notLanguageTitle={current?.plaque}
        >
            {current &&
                <GridContainer spacing={2} style={{
                    padding: "1.5em"
                }}>
                    <Grid item xs={12} sm={4}>
                        <BasicFile vehicle={current}/>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TechnicalSheet vehicle={current}/>
                    </Grid>
                </GridContainer>
            }
        </FullScreenDialog>
    );
};

export default VehicleDetailDialog;