import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "components/Common/Forms/Select";

import selectWarehouse from '../../../redux/actions/Administrator/WarehouseOriginSelect';

const WarehouseOriginSelect = ({id, label, hasError, error, value, onChange, entityId, campusId, disabled}) => {
    if(value?.value != undefined){value = value.value}
    const {data} = useSelector(state => state.administrator.warehouseOrigin);

    const dispatch = useDispatch();
    useEffect(() => {
        const idCampus = campusId !== null ? campusId.value : 0;
        if(data.length === 0){
            dispatch(selectWarehouse.get(entityId,idCampus));
        }else{
            dispatch(selectWarehouse.get(entityId,idCampus));
        }
    },[entityId, campusId, dispatch])
    
    const options = [
        {value: '', label: 'Seleccione una opcion'},
        ...data.filter(type => type.active !== 0).sort((a,b) => a.warehouse > b.warehouse ? 1 : -1).map(type => ({value: type.id, label: type.warehouse})),
    ];

    return(
        <Select
            value={value}
            error={hasError}
            errorMessage={error}
            fullWidth
            id={id}
            label={label}
            onChange={e => onChange(options.find(option => option.value === e.target.value))}
            options={options}
            disabled={disabled}
        />
    );
};

export default WarehouseOriginSelect