import React from 'react';
import {Box, Divider, IconButton, Typography} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import TableComponent from "../../../../../../Common/Tables/Table";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import CrudTableRow from "../../../../../../Common/Tables/CrudTable/CrudTableRow";
import TableHeader from "./TableHeader";
import AddIcon from "@material-ui/icons/Add";
import TaskDialog from "./TaskDialog";

const TasksTable = ({tasks, setTasks, vehicle}) => {
    const [open, setOpen] = React.useState('');
    const [selectedTask, setSelectedTask] = React.useState(null);

    const onEdit = task => {
        const updatedTasks = tasks.map(t => {
            if(task.id === t.id) {
                return {
                    ...t,
                    ...task,
                }
            }
            return t;
        });
        setTasks(updatedTasks);
        onCloseDialog();
    };
    const onAdd = data => {
        setTasks([...tasks, {
            id: 0,
            ...data
        }]);
        onCloseDialog();
    }
    const onClickEdit = task => {
        setOpen('edit');
        setSelectedTask({
            ...task,
            type: task.type.id,
            status: task.status.id,
            information: task.information.id,
            informationDescription: task.information.description
        });
    }
    const onClickAdd = task => {
        setOpen('add');
        setSelectedTask({
            type: '',
            information: '',
            observations: '',
            workshopObservations: '',
            cost: 0,
            status: ''
        })
    }
    const onDelete = task => {
        setTasks(tasks.filter(t => t.id !== task.id));
    }
    const onCloseDialog = () => {
        setOpen('');
        setSelectedTask(null);
    }
    const RenderRow = ({row}) => (

        <CrudTableRow
            onEdit={() => onClickEdit(row)}
            onDelete={() => onDelete(row)}
        >
            <StyledTableCell>{row.type.description}</StyledTableCell>
            <StyledTableCell>{row.information.description}</StyledTableCell>
            <StyledTableCell>{row.cost}</StyledTableCell>
            <StyledTableCell>{row.status.description}</StyledTableCell>
        </CrudTableRow>
    );
    return (
        <Box
            display="flex"
            flexDirection="column"
            my={4}
        >
            <Box
               display="flex"
               flexDirection="row"
               justifyContent="space-between"
            >
                <Typography variant="subtitle1">
                    <IntlMessages id= "fleet.management.workshop.request.form.tasks.table.title" />
                </Typography>
                <IconButton
                    onClick={onClickAdd}
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <Divider flexItem/>
            <TableComponent
                data={tasks}
                head={<TableHeader />}
                renderRow={RenderRow}
                getRowKey={row => row.id}
            />
            {open && <TaskDialog
                open={Boolean(open)}
                onClose={onCloseDialog}
                add={open === 'add'}
                initialData={selectedTask}
                onSubmit={open === 'add' ? onAdd : onEdit}
                vehicle={vehicle}
            />}
        </Box>
    );
};

TasksTable.propTypes = {

};

export default TasksTable;