import React, {useEffect} from "react";
import {fetchAllAccountingStates} from "../../../../../services/AccountingStateService";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Picker from "../../../../Common/Forms/Pickers/Picker";
import {useFetch} from "../../../../../hooks/Common/useFetch";

const StatesPicker = ({values, onChange}) => {
    const {data: states} = useFetch(fetchAllAccountingStates, {}, []);
  useEffect(
    () => {
      if (states.length > 0) {
        onChange(states.filter(state => state.id === 1 || state.id === 4));
      }
    },
    [ states ]
  );
  return (
    <Picker
      id="accounting-states-picker"
      values={values}
      title={<IntlMessages id="guardian.accounting.table.state.filter" />}
      headerText={
        <IntlMessages id="guardian.accounting.table.state.filter.header" />
      }
      noOptionsText={
        <IntlMessages id="guardian.accounting.table.state.filter.empty" />
      }
      options={states}
      onChange={onChange}
    />
  );
};

export default StatesPicker;
