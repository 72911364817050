import React from "react";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";
import {useDispatch, useSelector} from "react-redux";
import {
  addHeadquarterToClientStarted,
  editHeadquarterStarted,
  resetCurrentClient
} from "../../../../redux/actions/Administrator/Clients";
import {Box} from "@material-ui/core";
import DetailTable from "./DetailTable";
import HeadquartersTable from "./HeadquartersTable";
import AddHeadquarterDialog from "./AddHeadquarterDialog";
import FloatingButtonWithAdd from "../../../Common/Buttons/FloatingButtonWithAdd";
import ErrorAlert from "../../Common/Alerts/ErrorAlert";
import EditHeadquarterDialog from "./EditHeadquarterDialog";

const DetailDialog = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(state => state.administrator.clients);
  const handleClose = () => {
    dispatch(resetCurrentClient());
  };
  const onAddHeadquarter = data => {
    dispatch(addHeadquarterToClientStarted(current, data));
    setOpenAddDialog(false);
  };
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  const [ initialData, setInitialData ] = React.useState(undefined);
  const onOpenEditDialog = headquarter => {
    setOpenEditDialog(true);
    setInitialData(current.headquarters.find(h => h.id === headquarter.id));
  };
  const onCloseDialog = () => {
    setOpenEditDialog(false);
    setInitialData(undefined);
  };
  const onEditHeadquarter = data => {
    dispatch(editHeadquarterStarted(data, current, () => onCloseDialog()));
  };

  return (
    <FullScreenDialog
      open={current !== null}
      handleClose={handleClose}
      title={"administrator.clients.detail.dialog.title"}
    >
      {current && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <DetailTable />
          <HeadquartersTable
            client={current}
            onOpenEditDialog={onOpenEditDialog}
          />
          <AddHeadquarterDialog
            onSubmit={onAddHeadquarter}
            isOpen={openAddDialog}
            onClose={() => setOpenAddDialog(false)}
          />
          <EditHeadquarterDialog
            onSubmit={onEditHeadquarter}
            isOpen={openEditDialog}
            onClose={() => onCloseDialog()}
            initialData={initialData}
          />
          <FloatingButtonWithAdd onAddClicked={() => setOpenAddDialog(true)} />
          <ErrorAlert />
        </Box>
      )}
    </FullScreenDialog>
  );
};

export default DetailDialog;
