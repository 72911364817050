import {
  ADD_WORKSHOP_REQUEST,
  APPLY_WORKSHOP_REQUEST_FILTERS,
  CLOSE_DIALOG_WORKSHOP_REQUEST,
  EDIT_WORKSHOP_REQUEST,
  FETCH_ALL_WORKSHOP_REQUESTS,
  OPEN_DIALOG_WORKSHOP_REQUEST,
  RESET_WORKSHOP_REQUEST_FILTERS,
  RESET_WORKSHOP_REQUEST_STATE,
  SET_CURRENT_WORKSHOP_REQUEST
} from "../../types/FleetManagement/WorkshopRequestTypes";

const INIT_STATE = {
  data: [],
  filters: {
    vehicle: "",
    status: null
  },
  current: null,
  dialog: {
    name: ""
  }
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_WORKSHOP_REQUEST_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_WORKSHOP_REQUEST_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case FETCH_ALL_WORKSHOP_REQUESTS: {
      let list = {};
      action.payload.map(u => {
        list[u.id] = u;
        return null;
      });
      return {
        ...state,
        data: list
      };
    }
    case SET_CURRENT_WORKSHOP_REQUEST: {
      return {
        ...state,
        current: action.payload
      };
    }
    case ADD_WORKSHOP_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case EDIT_WORKSHOP_REQUEST: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload
        }
      };
    }
    case OPEN_DIALOG_WORKSHOP_REQUEST: {
      return {
        ...state,
        dialog: {
          name: action.payload
        }
      };
    }
    case CLOSE_DIALOG_WORKSHOP_REQUEST: {
      return {
        ...state,
        dialog: {
          name: ""
        }
      };
    }
    case RESET_WORKSHOP_REQUEST_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
