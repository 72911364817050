import React, {useEffect, useCallback} from "react";
import {connect, useSelector} from "react-redux";

import PageContainer from "components/Administrator/Warehouse/PageContainer";
import CreateWarehouseDialog from "components/Administrator/Warehouse/SaveWarehouseTypeDialog/CreateWarehouseDialog";
import EditWarehouseDialog from "components/Administrator/Warehouse/SaveWarehouseTypeDialog/EditWarehouseDialog";
import DeleteConfirmation from "components/Administrator/Warehouse/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/Warehouse/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/Warehouse/Table";
import Filters from "components/Administrator/Warehouse/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import FilterBuilder from "utils/filters";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

import useUiState from "hooks/Administrator/useUiState";
import warehouse from "redux/actions/Administrator/Warehouse";
import selectEntity from "redux/actions/Administrator/WarehouseEntitySelect";

const Warehouse = ({tableData, dispatch}) => {
  const ui = useUiState();

  useEffect(
    () => {
      dispatch(warehouse.get());
      return () => dispatch(warehouse.resetState());
    },
    [ dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(warehouse.applyWarehouseFilter(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(warehouse.resetWarehouseFilters());

  const onRestore = () => {
    dispatch(warehouse.restore(ui.restoringId));
  };
  const onDelete = () => {
    dispatch(warehouse.delete(ui.deletingId));
  };
  const onEdit = data => {
    dispatch(warehouse.edit(ui.editingId, data));
  };
  const onCreate = data => {
    dispatch(warehouse.create(data));
  };

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          <Table data={tableData} {...ui.tableProps} />
        </Box>
      )}
      <CreateWarehouseDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditWarehouseDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.warehouses;
  const filtered = new FilterBuilder(data)
    .byFieldIncludes("name", filters.name)
    .byFieldIncludes("active", filters.active)
    .build();
  return {tableData: filtered};
};

export default connect(mapStateToProps)(Warehouse);
