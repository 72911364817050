import React from "react";
import PageContainer from "../../../../components/Administrator/Tags/PageContainer";
import TagTypesTable from "../../../../components/Administrator/Tags/TagTypesTable";
import {useDispatch, useSelector} from "react-redux";
import {
  createTagTypeStarted,
  fetchAllTagTypesStarted
} from "../../../../redux/actions/Administrator/Tags";
import TagListDialog from "../../../../components/Administrator/Tags/TagListDialog";
import FloatingButtonWithAdd from "../../../../components/Common/Buttons/FloatingButtonWithAdd";
import useUiState from "../../../../hooks/Administrator/useUiState";
import ErrorAlert from "../../../../components/Administrator/Common/Alerts/ErrorAlert";
import CreateTagTypeDialog from "../../../../components/Administrator/Tags/SaveTagTypeDialog/CreateTagTypeDialog";
import EditTagTypeDialog from "../../../../components/Administrator/Tags/SaveTagTypeDialog/EditTagTypeDialog";
import {restoreAdministratorState} from "../../../../redux/actions/Administrator/Common";

const Tags = () => {
  const {data} = useSelector(state => state.administrator.tags);
  const dispatch = useDispatch();
  const ui = useUiState();
  const onCreate = data => dispatch(createTagTypeStarted(data));
  React.useEffect(() => {
    dispatch(fetchAllTagTypesStarted());
    return () => {
      dispatch(restoreAdministratorState());
    };
  }, []);
  return (
    <PageContainer>
      {data.length > 0 && <TagTypesTable />}
      <TagListDialog />
      <CreateTagTypeDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditTagTypeDialog {...ui.editFormProps} />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

export default Tags;
