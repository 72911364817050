import React from 'react';
import {Box, Button} from "@material-ui/core";
import {Link} from 'react-router-dom';
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import PanelSelect from "./PanelSelect";
import {useRouteMatch} from "react-router";
import {useSelector} from "react-redux";

const Filters = () => {
    const {url} = useRouteMatch();
    const {currentDashboard} = useSelector(({controlPanel}) => controlPanel.dashboards);
    return (
        <Box
            style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
            }}
        >
            <PanelSelect/>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem'
                }}
            >
                {currentDashboard?.custom
                    && <Link
                        to={url.replace('viewer', 'editor')}
                    >
                        <Button
                            variant='outlined'
                            style={{
                                height: '100%',
                            }}
                        >
                            <IntlMessages id='edit'/>
                        </Button>
                    </Link>
                }
                {/*<Link*/}
                {/*    to={url.replace('viewer', 'creator')}*/}
                {/*>*/}
                {/*    <ContainedButton*/}
                {/*        style={{*/}
                {/*            height: '100%',*/}
                {/*        }}*/}
                {/*        color='primary'*/}
                {/*        text={<IntlMessages id='create.panel'/>}*/}
                {/*    />*/}
                {/*</Link>*/}
            </Box>
        </Box>
    );
};

export default Filters;