import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import toBase64 from "../../../../../../utils/files";
import {fetchError} from "../../../../../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {editFileImage} from "../../../../../../redux/actions/FleetManagement/TechnicalSpecification";

const UpdateImageDialog = ({open, setOpen}) => {
  const id = "update-image-form";
  const dispatch = useDispatch();
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.technicalSpecification
  );
  const {formData, setFormData, resetForm, handleSubmit} = useForm({
    image: null
  });
  const onClose = () => {
    resetForm();
    setOpen(false);
  };
  const onSaveImage = ({image}) => {
    toBase64(image)
      .then(base64File => {
        const file = {
          name: image.name,
          extension: image.name.split(".")[1],
          contentType: image.type,
          base64File: base64File.split(",")[1],
          image
        };
        dispatch(editFileImage(current, file));
        onClose();
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={
            "fleet.management.vehicles.specifications.details.basic.edit.image.title"
          }
        />
      }
      isOpen={open}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit(onSaveImage)}>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <input
              id={"image-input"}
              type="file"
              accept="image/png, image/jpeg"
              onChange={e => {
                const file = e.target.files[0];
                setFormData("image", file);
              }}
            />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default UpdateImageDialog;
