import React from "react";
import {Box, InputBase} from "@material-ui/core";
import {useStyles} from "./styles";
import SearchIcon from "@material-ui/icons/Search";

const SidebarHeader = ({searchText, setSearchText}) => {
  const classes = useStyles();
  return (
    <Box className={classes.sidebarHeaderRoot}>
      <Box className={classes.searchRoot}>
        <InputBase
          placeholder={"Buscar..."}
          inputProps={{"aria-label": "search"}}
          value={searchText}
          onChange={e => setSearchText(e.target.value)}
        />
        <SearchIcon />
      </Box>
    </Box>
  );
};

export default SidebarHeader;
