import React from 'react';
import {Box} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import Form from "./Form";
import {addDestinationStarted, editDestinationStarted} from "../../../../../redux/actions/Administrator/WorkOrders";
import {dateIsBetweenTwoDates} from "../../../../../utils/dates";

const DestinationDetail = ({index, destination, cancelAdd, setSelectToNew, noEdit}) => {
    const {current} = useSelector(state => state.administrator.workOrders);
    const dispatch = useDispatch();
    const initData = {
        state: destination.state || "",
        action: destination.action || 1,
        reference: destination.reference || 1,
        referenceNumber: destination.referenceNumber || 0,
        type: destination.typeId,
        street: destination.street || "",
        number: destination.number || "",
        door: destination.door || "",
        postalCode: destination.postalCode || "",
        district: destination.district || "",
        province: destination.province || "",
        latitude: destination.latitude || "",
        longitude: destination.longitude || "",
        name: destination.name || "",
        phone: destination.phone || "",
        startTime: destination.startDate || "",
        endTime: destination.endDate || "",
        alias: destination.alias || "",
        email: destination.email || "",
        comment: destination.comment || "",
        products: destination.products || [],
        tags: destination.tags || [],
        driver: current?.driver?.id || "",
        deliveryNotes: destination.deliveryNotes || [],
    };

    const [edit, setEdit] = React.useState(destination.id === 0);
    const isValidDate = (date) => {
        return date && dateIsBetweenTwoDates(date, current.startDate, current.endDate);
    }
    const onSubmitWrapper = data => {
        if(isValidDate(data.startTime) && isValidDate(data.endTime)) {
            if(destination.id !== 0) {
                dispatch(editDestinationStarted({id: destination.id, ...data}, current));
            } else {
                let temp = {...data};
                delete destination['driver']
                dispatch(addDestinationStarted({...temp}, setSelectToNew));
            }
        }
    }

    React.useEffect(() => {
        setEdit(destination.id === 0);
    }, [destination]);

    return (
        <Box display="flex" flexDirection="column">
            <Form
                typeId={destination.typeId}
                noEdit={noEdit}
                cancelAdd={cancelAdd}
                destinationId = {destination.id}
                history={destination.history}
                initData={initData}
                edit={edit}
                onSubmit={onSubmitWrapper}
                setEdit={setEdit}
                index={index}
                isNew={destination.id === 0}
            />
        </Box>
    );
}

export default DestinationDetail;