import React, {useEffect} from "react";
import {Box, Button, Table, TableBody, TableHead} from "@material-ui/core";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import TableHeader from "../TableHeader/index";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {getReceptionDetails} from "../../../../../services/MerchandiseReceptionService";

const MerchandiseDetailDialog = ({
  open,
  setOpen,
  idTransfer,
  onAccept,
  onReject,
  setOpenIncidence,
    current,
}) => {
  const {executeService, data} = useFetch(getReceptionDetails, {id: idTransfer}, []);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if(idTransfer) {
      executeService();
    }
  }, [idTransfer, current])

  return (
    <FullScreenDialog
      open={open}
      handleClose={handleClose}
      title={"logistic.merchandise.detail.header"}
    >
      <React.Fragment>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead>
              <TableHeader />
            </TableHead>
            <TableBody>
              {data.map(element => (
                <StyledTableRow>
                  <StyledTableCell>{element.product}</StyledTableCell>
                  <StyledTableCell>{element.amount}</StyledTableCell>
                  <StyledTableCell>{element.incoming}</StyledTableCell>
                  <StyledTableCell>{element.missing}</StyledTableCell>
                  <StyledTableCell>{element.refund}</StyledTableCell>
                  <StyledTableCell>{element.return}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <div
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Button
            color="primary"
            variant="contained"
            style={{
              marginTop: 30,
              marginLeft: 300,
              width: 250
            }}
            onClick={() => onAccept(idTransfer)}
          >
            Aceptar
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{
              marginTop: 30,
              width: 250
            }}
            onClick={() => onReject(idTransfer)}
          >
            Rechazar
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{
              marginTop: 30,
              width: 250
            }}
            onClick={() => setOpenIncidence(true)}
          >
            Abrir Incidencia
          </Button>
        </div>
      </React.Fragment>
    </FullScreenDialog>
  );
};

export default MerchandiseDetailDialog;
