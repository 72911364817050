import React, {useEffect, useCallback} from "react";
import {connect, useSelector} from "react-redux";

import PageContainer from "components/Administrator/Products/PageContainer";
import CreateProductDialog from "components/Administrator/Products/SaveProductDialog/CreateProductDialog";
import EditProductDialog from "components/Administrator/Products/SaveProductDialog/EditProductDialog";
import DeleteConfirmation from "components/Administrator/Products/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/Products/Confirmations/RestoreConfirmation";
import Table from "components/Administrator/Products/Table";
import Filters from "components/Administrator/Products/Filters";
import FloatingButtonWithAdd from "components/Common/Buttons/FloatingButtonWithAdd";
import Box from "@material-ui/core/Box";
import ErrorAlert from "components/Administrator/Common/Alerts/ErrorAlert";

import useUiState from "hooks/Administrator/useUiState";

import products from "redux/actions/Administrator/Products";
import unit from "redux/actions/Administrator/Unit";
import FilterBuilder from "../../../../utils/filters";

const Products = ({tableData, dispatch, filters}) => {
  const ui = useUiState();

  useEffect(
    () => {
      return () => {
        dispatch(products.resetState());
      };
    },
    [ dispatch ]
  );

  const {dataUnit} = useSelector(state => state.administrator.unit);
  useEffect(
    () => {
      if (dataUnit === undefined) {
        dispatch(unit.get());
      }
    },
    [ dataUnit, dispatch ]
  );

  const applyFilters = useCallback(
    filters => dispatch(products.applyProductsFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () => dispatch(products.resetProductsFilters());

  const onDelete = () => {
    dispatch(products.delete(ui.deletingId));
  };
  const onRestore = () => {
    dispatch(products.restore(ui.restoringId));
  };
  const onEdit = data => {
    dispatch(products.edit(data, ui.editingId));
  };
  const onCreate = data => {
    dispatch(products.create(data));
  };

  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;

  return (
    <PageContainer>
      <Filters
        onFilter={applyFilters}
        onClose={resetFilters}
        showLoadingIndicator={ui.showLoadingIndicator}
      />
      <Box marginBottom={25}>
        <Table {...ui.tableProps} />
      </Box>
      <CreateProductDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditProductDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <FloatingButtonWithAdd onAddClicked={ui.floatingButtonProps.onCreate} />
      <ErrorAlert />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.product;
  return {tableData:data, filters};
};

export default connect(mapStateToProps)(Products);
