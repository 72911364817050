class ElevatorRow {
  constructor(id, name, headquarter, state, contract, detail, client,
              type, model, manufacturer, category) {
    this.id = id;
    this.name = name;
    this.headquarter = headquarter;
    this.state = state;
    this.detail = detail;
    this.contract = contract;
    this.client = client;
    this.type = type;
    this.model = model;
    this.manufacturer = manufacturer;
    this.category = category;
  }
  static map = elevator => {
    return new ElevatorRow(
      elevator.nu_id_ficha || "",
      elevator.vc_nombre || "",
      elevator.vc_sede || "",
      elevator.vc_desc_tipo_estado || "",
      elevator.vc_desc_tipo_contrato || "",
      elevator.vc_desc_tipo_detalle || "",
        elevator.vc_cliente || "",
        elevator.vc_desc_tipo || "",
        elevator.vc_desc_tipo_modelo || "",
        elevator.vc_desc_tipo_fabricante || "",
        elevator.vc_desc_tipo_categoria || "",
    );
  };
}

export default ElevatorRow;
