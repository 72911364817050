import {
  CLOSE_KPI_DIALOG,
  FETCH_USER_KPI_REPORTS_LISTS,
  OPEN_KPI_DIALOG,
  REMOVE_FAVORITE_REPORT,
  REMOVE_FILED_REPORT,
  REMOVE_SCHEDULED_REPORT,
  RESET_CURRENT_KPI_REPORT,
  RESET_KPI_STATE,
  SET_CURRENT_KPI_REPORT,
  SET_CURRENT_KPI_REPORT_FILE,
  SET_CURRENT_KPI_REPORT_FILTERS
} from "../../types/ControlPanel/KpiTypes";

const INIT_STATE = {
  userReportsLists: {
    lastConsults: [],
    favorites: [],
    scheduled: [],
    filed: []
  },
  current: {
    id: null,
    type: null,
    filters: null,
    file: null,
    fileType: null,
    title: null,
  },
  dialogs: [],
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_USER_KPI_REPORTS_LISTS: {
      return {
        ...state,
        userReportsLists: {
          ...state.userReportsLists,
          ...action.payload
        }
      };
    }
    case SET_CURRENT_KPI_REPORT: {
      return {
        ...state,
        current: {
          ...state.current,
          ...action.payload
        }
      };
    }
    case SET_CURRENT_KPI_REPORT_FILTERS: {
      return {
        ...state,
        current: {
          ...state.current,
          filters: action.payload
        }
      };
    }
    case SET_CURRENT_KPI_REPORT_FILE: {
      return {
        ...state,
        current: {
          ...state.current,
          file: action.payload.file,
          fileType: action.payload.fileType
        }
      };
    }
    case REMOVE_FAVORITE_REPORT: {
      return {
        ...state,
        userReportsLists: {
          ...state.userReportsLists,
          favorites: state.userReportsLists.favorites.filter(
            r => r.id !== action.payload
          )
        }
      };
    }
    case REMOVE_SCHEDULED_REPORT: {
      return {
        ...state,
        userReportsLists: {
          ...state.userReportsLists,
          scheduled: state.userReportsLists.scheduled.filter(
            r => r.id !== action.payload
          )
        }
      };
    }
    case REMOVE_FILED_REPORT: {
      return {
        ...state,
        userReportsLists: {
          ...state.userReportsLists,
          filed: state.userReportsLists.filed.filter(
            r => r.id !== action.payload
          )
        }
      };
    }
    case RESET_CURRENT_KPI_REPORT: {
      return {
        ...state,
        current: {
          ...INIT_STATE.current
        }
      };
    }
    case OPEN_KPI_DIALOG: {
      return {
        ...state,
        dialogs: [
          ...state.dialogs,
          action.payload
        ]
      };
    }
    case CLOSE_KPI_DIALOG: {
      return {
        ...state,
        dialogs: state.dialogs.filter(d => d !== action.payload),
      };
    }
    case RESET_KPI_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
