import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {applySpecificationsFilters} from "../../../../../../redux/actions/FleetManagement/TechnicalSpecification";
import TagSelect from "./TagSelect";
import ActiveSelect from "../../../../../Common/Forms/Select/ActiveSelect";

const initialFilters = {
  alias: "",
  plaque: "",
  chassis: "",
  brand: "",
  model: "",
  tag: "",
  active: true
};

const Filters = () => {
  const dispatch = useDispatch();
  const {filters, isLoading} = useSelector(
    state => state.fleetManagement.technicalSpecification
  );
  const {formData, setFormData, resetForm, setValues} = useForm(initialFilters);
  React.useEffect(
    () => {
      dispatch(applySpecificationsFilters(formData));
    },
    [ formData ]
  );
  React.useEffect(() => {
    setValues(filters);
  }, []);

  return (
    <FiltersContainer
      active
      showLoadingIndicator={isLoading}
      style={{
        marginBottom: "16px"
      }}
    >
      <form>
        <GridContainer>
          <Grid item xs={12} sm={6} md={4}>
            <OutlinedInput
              fullWidth
              id="specifications-alias"
              value={formData.alias}
              onChange={value => setFormData("alias", value)}
              label={
                <IntlMessages id="fleet.management.vehicles.specifications.table.alias" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OutlinedInput
              fullWidth
              id="specifications-plaque"
              value={formData.plaque}
              onChange={value => setFormData("plaque", value)}
              label={
                <IntlMessages id="fleet.management.vehicles.specifications.table.plaque" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OutlinedInput
              fullWidth
              id="specifications-chassis"
              value={formData.chassis}
              onChange={value => setFormData("chassis", value)}
              label={
                <IntlMessages id="fleet.management.vehicles.specifications.table.chassis" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OutlinedInput
              fullWidth
              id="specifications-brand"
              value={formData.brand}
              onChange={value => setFormData("brand", value)}
              label={
                <IntlMessages id="fleet.management.vehicles.specifications.table.brand" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <OutlinedInput
              fullWidth
              id="specifications-model"
              value={formData.model}
              onChange={value => setFormData("model", value)}
              label={
                <IntlMessages id="fleet.management.vehicles.specifications.table.model" />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TagSelect
              value={formData.tag}
              onChange={value => setFormData("tag", value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <ActiveSelect
              id="specifications-active"
              value={formData.active}
              onChange={value => setFormData("active", value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              onClick={() => {
                resetForm();
              }}
              variant="contained"
              color="primary"
            >
              <IntlMessages
                id={"fleet.management.vehicles.specifications.table.clear"}
              />
            </Button>
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

export default Filters;
