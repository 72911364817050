import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHead = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.insurance.table.plaque"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.insurance.table.policy.number"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.insurance.table.commencement.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.insurance.table.expiration.date"} />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"fleet.management.insurance.table.premium"} />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHead;
