import IntlMessages from "@jumbo/utils/IntlMessages";
import StyledTableCell from "components/Common/Tables/StyledTableCell";
import StyledTableRow from "components/Common/Tables/StyledTableRow";
import React from "react";

const TableHeader = ({isSupervisor}) => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.driver" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.tags" />
    </StyledTableCell>
      <StyledTableCell>
      <IntlMessages id="cost.center" />
    </StyledTableCell>
      <StyledTableCell>
          <IntlMessages id="petty.cash.amount" />
      </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.event" />
    </StyledTableCell>
      <StyledTableCell>
          <IntlMessages id="ot" />
      </StyledTableCell>
      <StyledTableCell>
          <IntlMessages id="expense.date" />
      </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.documentType" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.amount" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.state" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.supportDocument" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="supervisor.unresolvedExpenses.table.reasonOfRejection" />
    </StyledTableCell>
    {isSupervisor && <StyledTableCell />}
  </StyledTableRow>
);

export default TableHeader;
