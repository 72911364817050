import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "./Form";

const CreateIncidentDialog = ({isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="create-incident-form"
    isOpen={isOpen}
    title={<IntlMessages id="logistics.incidents.createFormTitle" />}
    onClose={onClose}
    disableEnforceFocus
    PaperProps={{style: {minWidth: "800px"}}}
  >
    <Form id="create-incident-form" onSubmit={onSubmit} />
  </FormDialog>
);

export default CreateIncidentDialog;
