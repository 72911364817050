import {withStyles} from "@material-ui/core";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";

export const OperativeStyledTableCell = withStyles(theme => ({
  body: {
    cursor: "pointer",
    transition: "all .3s ease",
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(StyledTableCell);
