import ui, {closeCreateForm} from "./ui";
import {restoreAdministratorState} from "./Common";
import {
  createWarehouseTransfer,
  editWarehouseTransfer,
  cancelPrevWarehouseTransferFetchAll,
  fetchAllWarehouseTransfer,
  changeStateWarehouseTransfer
} from "../../../services/WarehouseTransfer";

import {
  FETCH_WAREHOUSE_TRANSFER_SUCCEEDED,
  APPLY_WAREHOUSE_TRANSFER_FILTERS,
  RESET_WAREHOUSE_TRANSFER_FILTERS,
  WAREHOUSE_TRANSFER_CHANGE_STATE,
  WAREHOUSE_TRANSFER_EDIT,
  WAREHOUSE_TRANSFER_CREATE
} from "../../types/Administrator/WarehouseTransfer";

import WarehouseTransfer from "../../../domain/WarehouseTransfer";
import selectEntity from "redux/actions/Administrator/WarehouseEntitySelect";
import {fetchError, fetchStart, fetchSuccess} from "../Common";

export const resetState = () => dispatch => {
  cancelPrevWarehouseTransferFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchWarehouseTransferSucceeded = data => ({
  type: FETCH_WAREHOUSE_TRANSFER_SUCCEEDED,
  payload: {
    data
  }
});

export const fetchWarehouseTransfer = () => {
  return async dispatch => {
    dispatch(fetchStart());
    dispatch(selectEntity.get());
    cancelPrevWarehouseTransferFetchAll();
    fetchAllWarehouseTransfer()
      .then(warehouseTransfer => {
        dispatch(fetchSuccess());
        dispatch(fetchWarehouseTransferSucceeded(warehouseTransfer));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const applyWarehouseTransferFilter = filters => ({
  type: APPLY_WAREHOUSE_TRANSFER_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetWarehouseTransferFilters = () => ({
  type: RESET_WAREHOUSE_TRANSFER_FILTERS
});

const createWarehouseTransferAction = data => ({
  type: WAREHOUSE_TRANSFER_CREATE,
  payload: {
    ...data
  }
});

export const createWarehouseTransferStarted = data => async dispatch => {
  dispatch(closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const response = await createWarehouseTransfer(data);
    const today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate() +
      " " +
      today.getHours() +
      ":" +
      today.getMinutes();
    const warehouseTransfer = new WarehouseTransfer(
      response.id,
      data.entityOrigin.value,
      data.entityOrigin.label,
      data.campusOrigin.value,
      data.campusOrigin.label,
      data.warehouseOrigin.value,
      data.warehouseOrigin.label,
      data.entityDestiny.value,
      data.entityDestiny.label,
      data.campusDestiny.value,
      data.campusDestiny.label,
      data.warehouseDestiny.value,
      data.warehouseDestiny.label,
      date,
      true,
      data.products
    );
    dispatch(createWarehouseTransferAction(warehouseTransfer));
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
  //    }else{
  //        dispatch(ui.createSucceeded());
  //throw new Error(response.transactionMessage);
  //    }
  //});
};

const editWarehouseTransferAction = (id, data) => ({
  type: WAREHOUSE_TRANSFER_EDIT,
  payload: {
    ...data,
    id
  }
});

export const editWarehouseTransferStarted = (id, data) => async dispatch => {
  dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    await editWarehouseTransfer(id, data);
    dispatch(editWarehouseTransferAction(id, data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeWarehouseTransferSucceeded = id => ({
  type: WAREHOUSE_TRANSFER_CHANGE_STATE,
  payload: {
    id
  }
});

const changeWarehouseTransfer = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  await changeStateWarehouseTransfer(id);
  dispatch(changeWarehouseTransferSucceeded(id));
  dispatch(ui.changeStateSucceeded());
};

export const deleteWarehouseTransferStarted = id => dispatch => {
  dispatch(changeWarehouseTransfer(id));
  dispatch(ui.closeDeleteConfirmation());
};

export const restoreWarehouseTransferStarted = id => dispatch => {
  dispatch(changeWarehouseTransfer(id));
  dispatch(ui.closeRestoreConfirmation());
};

export default {
  get: fetchWarehouseTransfer,
  delete: deleteWarehouseTransferStarted,
  restore: restoreWarehouseTransferStarted,
  edit: editWarehouseTransferStarted,
  create: createWarehouseTransferStarted,
  applyWarehouseTransferFilter,
  resetWarehouseTransferFilters,
  resetState
};

/*import * as WarehouseTransfer from 'redux/types/Administrator/WarehouseTransfer';

export const addWarehouseTransfer = (id, origin, destiny, date, active) => dispatch =>{
    dispatch ({type: WarehouseTransfer.ADD_WAREHOUSE_TRANSFER, payload: {id, origin, destiny, date, active: true}})
}

export const removeWarehouseTransfer = (id) => dispatch => {
    dispatch({type:WarehouseTransfer.REMOVE_WAREHOUSE_TRANSFER, payload:id})
}

export const editWarehouseTransfer = (id, newOrigin, newDestiny, newDate) => dispatch => {
    dispatch({type: WarehouseTransfer.EDIT_WAREHOUSE_TRANSFER, payload: {id, newOrigin, newDestiny, newDate}})
}

export const restoreWarehouseTransfer = () => dispatch => {
    dispatch({type: WarehouseTransfer.RESET_WAREHOUSE_TRANSFER})
}*/
