import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "../Form";

const EditPettyCashTypeDialog = ({initialData, isOpen, onClose, onSubmit}) => (
  <FormDialog
    id="edit-petty-cash-type-form"
    isOpen={isOpen}
    title={<IntlMessages id="administrator.pettyCashTypes.editFormTitle" />}
    onClose={onClose}
  >
    <Form
      id="edit-petty-cash-type-form"
      initialData={initialData}
      onSubmit={onSubmit}
    />
  </FormDialog>
);

export default EditPettyCashTypeDialog;
