import {createSlice} from "@reduxjs/toolkit";
import {addGenre, editGenre, editGenreState, fetchAllGenres} from "../../thunks/ElevatorManagement/genres";
import {cloneDeep} from "lodash";

const INITIAL_STATE = {
    data: {},
    filters: {
        code: "",
        name: ""
    },
    current: null,
    dialogs: [],
};

const genresSlice = createSlice({
    initialState: INITIAL_STATE,
    name: "genres",
    reducers: {
        setCurrentGenre: (state, action) => {
            state.current = action.payload;
        },
        openGenreDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload,
            ];
        },
        closeGenreDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setGenreFilters: (state, action) => {
            if (action.payload) {
                state.filters = action.payload;
            }else {
                state.filters = cloneDeep(INITIAL_STATE.filters);
            }
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllGenres.fulfilled, (state, action) => {
            const list = {};
            for(let genre of action.payload.genres) {
                list[genre.id] = genre;
            }
            state.data = list;
        });
        builder.addCase(addGenre.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload;
        });
        builder.addCase(editGenre.fulfilled, (state, action) => {
            state.data[action.payload.id] = action.payload;
        });
        builder.addCase(editGenreState.fulfilled, (state, action) => {
            state.data[action.payload.id].state = action.payload.state;
        });
    }
});

export default genresSlice.reducer;

export const {
    setCurrentGenre,
    openGenreDialog,
    closeGenreDialog,
    setGenreFilters,
} = genresSlice.actions;