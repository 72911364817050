import React from "react";
import ui, {closeCreateForm} from "./ui";
import {
  createDataCollection,
  cancelPrevDataCollectionFetchAll,
  fetchAllDataCollection,
  updateDataCollection,
  changeDataCollection
} from "../../../services/DataCollectionService";
import {
  cancelPrevVehicleSelectFetchAll,
  fetchAllVehicleSelect
} from "../../../services/VehicleServiceSelect";
import {
  cancelPrevRouteSelectFetchAll,
  fetchAllRouteSelect
} from "../../../services/RouteSelectService";
import {
  cancelPrevTurnSelectFetchAll,
  fetchAllTurnSelect
} from "../../../services/TurnSelectService";

import {restoreAdministratorState} from "./Common";

import {
  FETCH_DATA_COLLECTION_SUCCEEDED,
  APPLY_DATA_COLLECTION_FILTERS,
  RESET_DATA_COLLECTION_FILTERS,
  DATA_COLLECTION_CHANGE_STATE,
  DATA_COLLECTION_EDIT,
  DATA_COLLECTION_CREATE
} from "../../types/Administrator/DataCollection";
import {FETCH_VEHICLE_SELECT} from "../../types/Administrator/VehicleSelect";
import {FETCH_ROUTE_SELECT} from "../../types/Administrator/RouteSelect";
import {FETCH_TURN_SELECT} from "../../types/Administrator/TurnSelect";

import DataCollection from "../../../domain/DataCollection";

export const resetState = () => dispatch => {
  cancelPrevDataCollectionFetchAll();
  cancelPrevVehicleSelectFetchAll();
  cancelPrevRouteSelectFetchAll();
  cancelPrevTurnSelectFetchAll();
  dispatch(restoreAdministratorState());
};

const fetchDataCollectSucceeded = data => ({
  type: FETCH_DATA_COLLECTION_SUCCEEDED,
  payload: {
    data
  }
});

const fetchVehicleSelectSucceeded = data => ({
  type: FETCH_VEHICLE_SELECT,
  payload: {
    data
  }
});

const fetchRouteSelectSucceeded = data => ({
  type: FETCH_ROUTE_SELECT,
  payload: {
    data
  }
});

const fetchTurnSelectSucceeded = data => ({
  type: FETCH_TURN_SELECT,
  payload: {
    data
  }
});

export const fetchDataCollection = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevTurnSelectFetchAll();
  cancelPrevRouteSelectFetchAll();
  cancelPrevVehicleSelectFetchAll();
  cancelPrevDataCollectionFetchAll();
  try {
    const dataCollect = await fetchAllDataCollection();
    const vehicle = await fetchAllVehicleSelect();
    const route = await fetchAllRouteSelect();
    const turn = await fetchAllTurnSelect();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchDataCollectSucceeded(dataCollect));
    dispatch(fetchVehicleSelectSucceeded(vehicle));
    dispatch(fetchRouteSelectSucceeded(route));
    dispatch(fetchTurnSelectSucceeded(turn));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const refreshDataCollection = () => async dispatch => {
  dispatch(ui.fetchStarted());
  cancelPrevDataCollectionFetchAll();
  try {
    const dataCollect = await fetchAllDataCollection();
    dispatch(ui.fetchSucceeded());
    dispatch(fetchDataCollectSucceeded(dataCollect));
  } catch (error) {
    dispatch(ui.fetchFailed());
  }
};

export const applyDataCollectionFilters = filters => ({
  type: APPLY_DATA_COLLECTION_FILTERS,
  payload: {
    filters: filters
  }
});

export const resetDataCollectionFilters = () => ({
  type: RESET_DATA_COLLECTION_FILTERS
});

const createDataCollectionAction = data => ({
  type: DATA_COLLECTION_CREATE,
  payload: {
    ...data
  }
});

export const createDataCollectionStarted = data => async dispatch => {
  dispatch(closeCreateForm());
  dispatch(ui.createStarted());
  try {
    const response = await createDataCollection(data);
    const dataCollection = new DataCollection(
      5,
      data.vehicle,
      data.start,
      data.end,
      data.route,
      data.turn,
      data.passengers,
      true
    );
    dispatch(createDataCollectionAction(dataCollection));
    dispatch(ui.createSucceeded());
  } catch (error) {
    dispatch(ui.createFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const editDataCollectionAction = (id, data) => ({
  type: DATA_COLLECTION_EDIT,
  payload: {
    id,
    ...data
  }
});

export const editDataCollectionStarted = (
  id,
  data,
  first,
  last,
  desc,
  resolve
) => async dispatch => {
  // dispatch(ui.closeEditForm());
  dispatch(ui.editStarted());
  try {
    console.log("PRIMERA EDIT: ", id);
    const response = await updateDataCollection(id, data, first, last, desc);
    response.id && dispatch(refreshDataCollection());
    resolve();
    //dispatch(editDataCollectionAction(id,data));
    dispatch(ui.editSucceeded());
  } catch (error) {
    dispatch(ui.editFailed());
    dispatch(ui.setOnErrorMessage(error.message));
  }
};

const changeDataCollectionSucceeded = id => ({
  type: DATA_COLLECTION_CHANGE_STATE,
  payload: {
    id
  }
});

const changeDataCollectionStarted = id => async dispatch => {
  dispatch(ui.changeStateStarted());
  await changeDataCollection(id);
  dispatch(changeDataCollectionSucceeded(id));
  dispatch(ui.changeStateSucceeded());
};

export const deleteDataCollectionStarted = id => dispatch => {
  dispatch(changeDataCollectionStarted(id));
  dispatch(ui.closeRestoreConfirmation());
};

export const restoreDataCollectionStarted = id => dispatch => {
  dispatch(changeDataCollectionStarted(id));
  dispatch(ui.closeRestoreConfirmation());
};

export default {
  get: fetchDataCollection,
  delete: deleteDataCollectionStarted,
  restore: restoreDataCollectionStarted,
  edit: editDataCollectionStarted,
  create: createDataCollectionStarted,
  refresh: refreshDataCollection,
  applyDataCollectionFilters,
  resetDataCollectionFilters,
  resetState
};
