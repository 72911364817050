import StyledTableCell from "components/Common/Tables/StyledTableCell";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import React from "react";
import CrudTableRow from "../../../../../../Common/Tables/CrudTable/CrudTableRow";
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const TableRow = ({row, handleDelete, handleAmount, focusAmount}) => {
  const [ edit, setEdit ] = React.useState(false);
  const [ amount, setAmount ] = React.useState(row.amount);

  return (
    <React.Fragment>
      <CrudTableRow
        onDelete={() => handleDelete(row.id)}
        onEdit={() => setEdit(true)}
      >
        <StyledTableCell>{row.label}</StyledTableCell>
        <StyledTableCell>{row.stock}</StyledTableCell>
        <StyledTableCell>{row.amount}</StyledTableCell>
      </CrudTableRow>
      {edit && (
        <Dialog
          open={edit}
          onClose={() => setEdit(false)}
          aria-labelledby="form-product-amount-dialog-title"
        >
          <DialogTitle id="form-product-amount-dialog-title">
            <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.dialog.title" />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id={`${row.id}-amount`}
              label={
                <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.dialog.field.quantity" />
              }
              type="number"
              fullWidth
              value={amount}
              onChange={e => setAmount(e.target.value)}
              inputProps={{min: 1}}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEdit(false)} color="primary">
              <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.dialog.cancel" />
            </Button>
            <Button
              onClick={() => {
                setEdit(false);
                handleAmount(row, amount, row.stock);
              }}
              color="primary"
            >
              <IntlMessages id="logistic.warehouseTransfer.saveWarehouseTransferDialog.table.dialog.edit" />
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default TableRow;
