import {
  FETCH_PAUSESTYPES_SUCCEEDED,
  APPLY_PAUSESTYPES_FILTERS,
  RESET_PAUSESTYPES_FILTERS
} from "../../types/RRHH/PausesTypes";

const INIT_STATE = {
  data: [],
  filters: {
    active: undefined,
    description: ""
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_PAUSESTYPES_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }

    case "PAUSESTYPES_CREATE": {
      return {
        ...state,
        data: [ ...state.data, action.payload ]
      };
    }

    case "PAUSESTYPES_EDIT": {
      return {
        ...state,
        data: state.data.map(type => {
          if (type.id === action.payload.id) {
            return Object.assign(type, action.payload.data);
          }

          return type;
        })
      };
    }

    case "PAUSESTYPES_DELETE": {
      return {
        ...state,
        data: state.data.map(pausestypes => {
          if (pausestypes.id === action.payload.id) {
            return Object.assign(pausestypes, {active: !pausestypes.active});
          }

          return pausestypes;
        })
      };
    }

    case "PAUSESTYPES_RESTORE": {
      return {
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }

    case APPLY_PAUSESTYPES_FILTERS: {
      return {
        ...state,
        filters: action.payload.filters
      };
    }

    case RESET_PAUSESTYPES_FILTERS: {
      return {
        ...state,
        filters: {...INIT_STATE.filters}
      };
    }
  }
};
