import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import ClientHeadquarter from "../../../domain/Logistic/Distribution/ClientHeadquarter";

export const getAllClientsHeadquarters = async ()  => {
    try {
        const {data} = await axiosInstance.post(
            '/Cliente/sel_global',
            {
                nu_id_entidad: getCompanyEntityId()
            }
        );
        return data.map(e => ClientHeadquarter.map(e));
    }catch (e) {
        return Promise.reject(e);
    }
}