import {mapToBoolean} from "../../../utils/mappers";

class WorkshopRequestState {
    constructor(id, description, state) {
        this.id = id;
        this.description = description;
        this.state = state;
    }

    static map = status => {
        return new WorkshopRequestState(
          status.nu_id_solicitud_taller_estado,
          status.vc_desc_solicitud_taller_estado,
          mapToBoolean(status.bi_estado),
        );
    }
}

export default WorkshopRequestState;