import React from "react";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import {TableHead} from "@material-ui/core";

const PettyCashesTableHeader = ({orderBy, order, onSort}) => {
  return (
    <TableHead>
      <StyledTableRow>
        <TableCellWithSorting
          property="tags"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"guardian.operative.plannings.table.header.tags"} />
        </TableCellWithSorting>
        <TableCellWithSorting
            property="costCenter"
            orderBy={orderBy}
            order={order}
            onSort={onSort}
        >
          <IntlMessages id={"cost.center"} />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="fund"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"administrator.guardian.fund"} />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="cash"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"administrator.guardian.cash"} />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="traffic"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"administrator.guardian.traffic"} />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="liquidated"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"administrator.guardian.liquidated"} />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="refund"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"administrator.guardian.refund"} />
        </TableCellWithSorting>
        <TableCellWithSorting
          property="state"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
        >
          <IntlMessages id={"administrator.guardian.state"} />
        </TableCellWithSorting>
        <StyledTableCell align="right">
          <IntlMessages id={"guardians.operative.plannings.table.actions"} />
        </StyledTableCell>
      </StyledTableRow>
    </TableHead>
  );
};

export default PettyCashesTableHeader;
