import {
  CLEAR_SUPERVISOR_ROLE,
  SET_SUPERVISOR_ROLE
} from "../../types/CustomTypes";

export const clearSupervisorRole = () => {
  return {
    type: CLEAR_SUPERVISOR_ROLE
  };
};

export const setSupervisorRole = () => {
  return {
    type: SET_SUPERVISOR_ROLE
  };
};
