import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {createDashboard} from "../../../services/ControlPanel/Kpi/DashboardService";
import Dashboard from "../../../domain/ControlPanel/Dashboard/Dashboard";

export const createCustomDashboard = createAsyncThunk(
    'dashboards/createCustomDashboard',
    async (request, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await createDashboard(request);
            dispatch(fetchSuccess(response.transactionMessage));
            const dashboard = new Dashboard(
                response.transactionKey,
                request.name,
                [],
                true,
            );
            return dashboard;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)