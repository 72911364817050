import React from 'react';
import {useSelector} from "react-redux";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis} from "recharts";
import {colors} from "./constants";
import {useIntl} from "react-intl";
import {Paper} from "@material-ui/core";

const EventsByDriversGraph = () => {
    const {eventsByDriver} = useSelector(({controlPanel}) => controlPanel.dashboards.currentDashboard.widgets);
    const intl = useIntl();
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                width={500}
                height={300}
                data={eventsByDriver}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="driver" hide />
                <Tooltip
                    cursor={false}
                    content={data =>
                        data?.payload?.at(0) ? (
                            <Paper
                                style={{
                                    padding: "0.5rem 1rem"
                                }}
                            >
                                <p>{data?.payload[0]?.payload?.driver}</p>
                                <p>{`${intl.messages['light']}: ${data?.payload[0]?.payload?.light}`}</p>
                                <p>{`${intl.messages['moderate']}: ${data?.payload[0]?.payload?.moderate}`}</p>
                                <p>{`${intl.messages['serious']}: ${data?.payload[0]?.payload?.serious}`}</p>
                            </Paper>
                        ) : null}
                />
                <Bar dataKey="serious" stackId="a" fill={colors.serious} />
                <Bar dataKey="moderate" stackId="a" fill={colors.moderate}></Bar>
                <Bar dataKey="light" stackId="a" fill={colors.light}></Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default EventsByDriversGraph;