import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    addBill,
    billBudget,
    deleteBill,
    editBill,
    getAllBills,
    getBillServices
} from "../../../services/Treasury/BillsService";

const baseNamespace = 'treasury/budgets';
export const fetchAllBudgets = createAsyncThunk(
    `${baseNamespace}/fetchAllBudgets`,
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const budgets = await getAllBills({type: 8});
            dispatch(fetchSuccess());
            return {budgets};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const getAndSetBudgetServices = createAsyncThunk(
    `${baseNamespace}/getAndSetBudgetServices`,
    async ({budget}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const services = await getBillServices(budget.id);
            dispatch(fetchSuccess());
            return {
                ...budget,
                services,
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const createBudget = createAsyncThunk(
    `${baseNamespace}/createBudget`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await addBill({request, type: 8});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllBudgets());
            }, 1500);
            return response;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const updateBudget = createAsyncThunk(
    `${baseNamespace}/updateBudget`,
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await editBill({request, type: 8});
            dispatch(fetchSuccess(response.transactionMessage));
            setTimeout(() => {
                dispatch(fetchAllBudgets());
            }, 1500);
            return response;
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const removeBudget = createAsyncThunk(
    `${baseNamespace}/removeBudget`,
    async ({budgetId}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const response = await deleteBill({billId: budgetId});
            dispatch(fetchSuccess(response.transactionMessage));
            return {budgetId};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const billSelectedBudgets = createAsyncThunk(
    `${baseNamespace}/billSelectedBudgets`,
    async ({selectedBudgets}, {dispatch, rejectWithValue}) => {
        try {
            if(selectedBudgets.length === 0){
                throw new Error('No se ha seleccionado ningún presupuesto');
            }
            dispatch(fetchStart());
            const responses = await Promise.all(
                selectedBudgets.map(budgetId => {
                    return billBudget({budgetId});
                })
            );
            dispatch(fetchSuccess('Presupuestos facturados correctamente'));
            setTimeout(() => {
                dispatch(fetchAllBudgets());
            }, 1500);
            return {selectedBudgets};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);