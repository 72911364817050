import React from 'react';
import { useSelector } from 'react-redux';

import Select from 'components/Common/Forms/Select';

const PettyCashTypesSelect = ({ id, label, hasError, error, value, onChange }) => {
  const { data } = useSelector(state => state.administrator.pettyCashTypes);


  const options = [
    { value: '', label: 'Seleccione una opción' },
    ...data.filter(type => type.active).map(type => ({ value: type.id, label: type.description })),
  ];

  return (
    <Select
      value={value?.value || ''}
      error={hasError}
      errorMessage={error}
      fullWidth
      id={id}
      label={label}
      onChange={e => onChange(options.find(option => option.value === e.target.value))}
      options={options}
    />
  );
};

export default PettyCashTypesSelect;
