import React from "react";
import useForm from "../../../../../../../../../hooks/Common/useForm";
import {Box} from "@material-ui/core";
import {useStyles} from "../../../styles";
import OutlinedInput from "../../../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";

const Form = ({id, initData, onSubmit}) => {
  const {formData, setFormData, handleSubmit} = useForm(initData);
  const classes = useStyles();
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <Box className={classes.contactFormBox}>
        <OutlinedInput
          id={id + "name"}
          fullWidth
          label={
            <IntlMessages
              id={
                "elevators.management.elevators.index.card.detail.dialog.form.contact.client.table.name"
              }
            />
          }
          value={formData.name}
          onChange={value => setFormData("name", value)}
        />
        <OutlinedInput
          id={id + "email"}
          fullWidth
          label={
            <IntlMessages
              id={
                "elevators.management.elevators.index.card.detail.dialog.form.contact.client.table.email"
              }
            />
          }
          value={formData.email}
          onChange={value => setFormData("email", value)}
        />
        <OutlinedInput
          id={id + "phone"}
          fullWidth
          label={
            <IntlMessages
              id={
                "elevators.management.elevators.index.card.detail.dialog.form.contact.client.table.phone"
              }
            />
          }
          value={formData.phone}
          onChange={value => setFormData("phone", value)}
        />
      </Box>
    </form>
  );
};

export default Form;
