import React, {useEffect} from "react";
import Select from "../index";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import CheckStockStates from "../../../../../models/Logistic/CheckStockStates";
const CheckStockSelect = ({onChange, style, fullWidth, data}) => {
  return (
    <Select
      id="groupedBy"
      label={<IntlMessages id="logistic.header.grouped" />}
      value={data}
      onChange={e => onChange(e.target.value)}
      style={style}
      fullWidth={fullWidth}
    >
      <MenuItem value="Producto" key="Producto">
        <IntlMessages id="logistic.table.statecheckstock.producto" />
      </MenuItem>
      <MenuItem value="Cliente" key="Cliente">
        <IntlMessages id="logistic.table.statecheckstock.client" />
      </MenuItem>
    </Select>
  );
};

export default CheckStockSelect;
