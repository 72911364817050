import SinisterVehicle from "./SinisterVehicle";

class Sinister {
  constructor(id, date, description, driver, vehiclesCount, stateId) {
    this.id = id;
    this.description = description;
    this.date = date;
    this.driver = {
      id: "",
      name: driver,
    };
    this.vehiclesCount = vehiclesCount;
    this.stateId = stateId;
    this.communicationDate = "";
    this.type = null;
    this.vehicles = [];
    this.address = "";
    this.version = "";
    this.opposite = "";
    this.observations = "";
    this.images = [];
  }

  static map = sinister => {
    return new Sinister(
      sinister.nu_id_siniestro,
      sinister.dt_fec_siniestro,
      sinister.vc_desc_tipo_siniestro,
      sinister.vc_conductor,
      sinister.nu_nro_vehiculos,
      sinister.nu_id_tipo_estado_siniestro
    );
  };

  addSinisterDetails(sinister) {
    this.communicationDate = sinister.dt_fec_comunicacion;
    this.type = sinister.nu_id_tipo_siniestro;
    this.vehicles = sinister.ls_vehiculos.map(v => SinisterVehicle.map(v));
    this.address = sinister.vc_direccion;
    this.version = sinister.vc_version;
    this.opposite = sinister.vc_datos_contrario;
    this.observations = sinister.vc_observaciones;
    this.images = sinister.ls_documentos.map(i => ({
      id: i.nu_id_siniestro_documento,
      name: i.vc_nombre_archivo
    }));
    this.driver.id = sinister.nu_id_conductor;
  }
}

export default Sinister;
