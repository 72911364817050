import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {getAllOrders, getOrderDetails} from "../../thunks/Logistic/orders";

const INITIAL_STATE = {
    orders: [],
    currentOrder: null,
    dialogs: [],
    currentItem: null,
};

const ordersSlide = createSlice({
    name: "orders",
    initialState: INITIAL_STATE,
    reducers: {
        setCurrentOrder: (state, action) => {
            state.currentOrder = action.payload;
        },
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload
            ];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentOrderItem: (state, action) => {
            state.currentItem = action.payload;
        },
        resetState: () => {
            return cloneDeep(INITIAL_STATE);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
        });
        builder.addCase(getOrderDetails.fulfilled, (state, action) => {
            state.currentOrder = action.payload;
        });
    }
});

export default ordersSlide.reducer;

export const {
    resetState,
    openDialog,
    closeDialog,
    setCurrentOrder,
    setCurrentOrderItem,
} = ordersSlide.actions;
