import React from "react";
import CheckStockBodyUniquesEntities from "./CheckStockBodyUniquesEntities";

const CheckStockBodyEntity = ({
  driver,
  setSelectedAdvance,
  isGuardian = true
}) => {
  const rows = 6;
  const [ open, setOpen ] = React.useState(rows <= 1);

  const unicos = [];
  for (const i in driver) {
    const aux = false;
    const count = 0;
    for (const j in unicos) {
      if (unicos[j][2] === driver[i][2]) {
        aux = true;
      }
    }
    if (!aux) {
      unicos.push(driver[i]);
    }
  }

  const selectHandler = advance => {
    if (rows > 1 && !open) {
      setOpen(true);
    } else {
      setSelectedAdvance({driver: driver, ...advance});
    }
  };

  return (
    <React.Fragment>
      {unicos.map(element => (
        <CheckStockBodyUniquesEntities
          data={driver}
          driver={element}
          setSelectedAdvance={setSelectedAdvance}
          isGuardian={isGuardian}
        />
      ))}
    </React.Fragment>
  );
};

export default CheckStockBodyEntity;
