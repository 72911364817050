import React from "react";

const InterruptionIcon = props => (
  <img
    {...props}
    src={
      process.env.PUBLIC_URL +
      "/images/icons/tachograph-icons/Iconos_Taco_interrupcion.svg"
    }
    alt=""
  />
);

export default InterruptionIcon;
