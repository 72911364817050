import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {fetchParsedData} from "../../../services/BackOffice/Devices/ParserService";

export const getParsedData = createAsyncThunk(
    'parser/getParsedData',
    async (filters, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const data = await fetchParsedData(filters);
            dispatch(fetchSuccess());
            return {filters, data}
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
)