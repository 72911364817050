import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Sinister from "../../../domain/FleetManagement/Vehicles/Sinister";
import Response from "../../../domain/Response";
import SinisterState from "../../../domain/FleetManagement/Vehicles/SinisterState";
import SinisterVehicleState from "../../../domain/FleetManagement/Vehicles/SinisterVehicleState";
import {generateFileObject} from "../../../utils/files";

export const getAllSinisterStates = async () => {
    try {
        const {data} = await axiosInstance.post("/VehiculoSiniestro/sel_estados", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(d => SinisterState.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getAllSinisterVehicleStates = async () => {
    try {
        const {
            data
        } = await axiosInstance.post("/VehiculoSiniestro/sel_estados_vehiculo", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(d => SinisterVehicleState.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getAllSinisters = async () => {
    try {
        const {data} = await axiosInstance.post("/VehiculoSiniestro/sel", {
            nu_id_entidad: getCompanyEntityId()
        });
        return data.map(d => Sinister.map(d));
    } catch (e) {
        return Promise.reject(e);
    }
};

export const getSinisterById = async sinister => {
    try {
        const {data} = await axiosInstance.post("/VehiculoSiniestro/get", {
            nu_id_siniestro: sinister.id
        });
        sinister.addSinisterDetails(data);
        return sinister;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const createSinister = async sinister => {
    try {
        const images = await Promise.all(
            sinister.images.map(async f => ({
                ...f,
                file: await generateFileObject(f.file)
            }))
        );
        const vehicles = await Promise.all(
            sinister.vehicles.map(async v => ({
                ...v,
                files: await Promise.all(
                    v.files.map(async f => ({
                        ...f,
                        file: await generateFileObject(f.file)
                    }))
                )
            }))
        );
        const {data} = await axiosInstance.post("/VehiculoSiniestro/ins", {
            nu_id_entidad: getCompanyEntityId(),
            dt_fec_siniestro: sinister.date,
            dt_fec_comunicacion: sinister.communicationDate,
            vc_direccion: sinister.address,
            nu_id_tipo_siniestro: sinister.type,
            nu_id_tipo_estado_siniestro: sinister.status,
            nu_id_conductor: sinister.driver,
            vc_version: sinister.version,
            vc_datos_contrario: sinister.opposite,
            vc_observaciones: sinister.observations,
            ls_documentos: images.map(f => ({
                archivobase64: f.file.base64File,
                contentType: f.file.contentType,
                extension: f.file.extension,
                nombre: f.file.name.split(".")[0]
            })),
            ls_vehiculos: vehicles.map(v => ({
                nu_id_vehiculo: v.vehicleId,
                vc_aseguradora: v.insuranceCompany,
                vc_poliza: v.policy,
                nu_id_tipo_estado_siniestro_vehiculo: v.status,
                vc_observaciones: v.observations,
                vc_orden_trabajo: v.ot,
                ls_documentos: v.files.map(f => ({
                    archivobase64: f.file.base64File,
                    contentType: f.file.contentType,
                    extension: f.file.extension,
                    nombre: f.file.name.split(".")[0]
                }))
            }))

        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};

export const updateSinister = async sinister => {
    try {
        const images = await Promise.all(
            sinister.images.filter(i => i.id === 0).map(async f => ({
                ...f,
                file: await generateFileObject(f.file)
            }))
        );
        const vehicles = await Promise.all(
            sinister.vehicles.map(async v => ({
                ...v,
                files: await Promise.all(
                    v.files.filter(f => f.id === 0)
                        .map(async f => ({
                            ...f,
                            file: await generateFileObject(f.file)
                        }))
                )
            }))
        );
        const {data} = await axiosInstance.post("/VehiculoSiniestro/upd", {
            nu_id_entidad: getCompanyEntityId(),
            nu_id_siniestro: sinister.id,
            dt_fec_siniestro: sinister.date,
            dt_fec_comunicacion: sinister.communicationDate,
            vc_direccion: sinister.address,
            nu_id_tipo_siniestro: sinister.type,
            nu_id_tipo_estado_siniestro: sinister.status,
            nu_id_conductor: sinister.driver,
            vc_version: sinister.version,
            vc_datos_contrario: sinister.opposite,
            vc_observaciones: sinister.observations,
            ls_documentos: images.map(f => ({
                archivobase64: f.file.base64File,
                contentType: f.file.contentType,
                extension: f.file.extension,
                nombre: f.file.name.split(".")[0]
            })),
            ls_vehiculos: vehicles.map(v => ({
                nu_id_vehiculo: v.vehicleId,
                vc_aseguradora: v.insuranceCompany,
                vc_poliza: v.policy,
                nu_id_tipo_estado_siniestro_vehiculo: v.status,
                vc_observaciones: v.observations,
                vc_orden_trabajo: v.ot,
                ls_documentos: v.files.map(f => ({
                    archivobase64: f.file.base64File,
                    contentType: f.file.contentType,
                    extension: f.file.extension,
                    nombre: f.file.name.split(".")[0]
                }))
            }))
        });
        const response = Response.map(data);
        if (response.transactionState === "0") {
            throw Error(response.transactionMessage);
        }
        return response;
    } catch (e) {
        return Promise.reject(e);
    }
};
