import React from "react";
import {useDispatch} from "react-redux";
import toBase64 from "../../../../utils/files";
import {fetchError} from "../../../../redux/actions";

import FormDialog from "../FormDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";

const ImportFileDialog = ({
  id,
  title,
  label,
  open,
  setOpen,
  onImport,
  accept
}) => {
  const dispatch = useDispatch();
  const [ file, setFile ] = React.useState(null);
  const readFile = async e => {
    const file = e.target.files[0];
    const base64File = await toBase64(file).catch(error => {
      dispatch(fetchError(error.message));
    });
    setFile({
      name: file.name,
      extension: file.name.split(".")[1],
      contentType: file.type,
      base64File: base64File.split(",")[1],
      file
    });
  };
  const handleSubmit = e => {
    e.preventDefault();
    onImport(file);
  };
  return (
    <FormDialog
      id={id}
      title={title}
      submitText={<IntlMessages id={"form.button.import"} />}
      cancelText={<IntlMessages id={"form.button.cancel"} />}
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <form id={id} onSubmit={handleSubmit}>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6}>
            <input
              accept={accept}
              type="file"
              id={`${id}-file`}
              multiple={false}
              onChange={readFile}
            />
            <label htmlFor={`${id}-file`}>{label}</label>
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default ImportFileDialog;
