import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {connect} from "react-redux";
import {
  fetchAllVehiclesSpecificationsStarted,
  setCurrentVehicleSpecification
} from "../../../../../redux/actions/FleetManagement/TechnicalSpecification";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import TableFooter from "./TableFooter";
import Filters from "./Filters";
import FilterBuilder from "../../../../../utils/filters";
import EditTableRow from "../../../../Common/Tables/CrudTable/EditTableRow";

const VehiclesTable = ({dispatch, vehicles}) => {
  const [ rowsPerPage, setRowsPerPage ] = React.useState(5);
  const [ page, setPage ] = React.useState(0);

  React.useEffect(() => {
    dispatch(fetchAllVehiclesSpecificationsStarted());
  }, []);

  const onEditVehicle = vehicle => {
    dispatch(setCurrentVehicleSpecification(vehicle));
  };

  return (
    <React.Fragment>
      <Filters />
      <TableContainer
        head={<TableHeader />}
        footer={
          <TableFooter
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            vehicles={vehicles}
            page={page}
            setPage={setPage}
          />
        }
      >
        {(rowsPerPage > 0
          ? vehicles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : vehicles).map(vehicle => (
          <EditTableRow key={vehicle.id} onEdit={() => onEditVehicle(vehicle)}>
            <StyledTableCell>{vehicle.alias}</StyledTableCell>
            <StyledTableCell>{vehicle.plaque}</StyledTableCell>
            <StyledTableCell>{vehicle.chassis}</StyledTableCell>
            <StyledTableCell>{vehicle.brand}</StyledTableCell>
            <StyledTableCell>{vehicle.model}</StyledTableCell>
          </EditTableRow>
        ))}
      </TableContainer>
    </React.Fragment>
  );
};
const mapStateToProps = state => {
  const {vehicles, filters} = state.fleetManagement.technicalSpecification;
  const filteredData = new FilterBuilder(vehicles)
    .byFieldIncludes("alias", filters.alias)
    .byFieldIncludes("plaque", filters.plaque)
    .byFieldIncludes("chassis", filters.chassis)
    .byFieldIncludes("brand", filters.brand)
    .byFieldIncludes("model", filters.model)
    .byFieldIncludes("status", filters.active)
    .byFieldIncludes("tags", filters.tag)
    .build();

  return {vehicles: filteredData};
};
export default connect(mapStateToProps)(VehiclesTable);
