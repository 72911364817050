import React, {useCallback, useEffect} from "react";
import {connect, useDispatch, useSelector} from "react-redux";

import FilterBuilder from "utils/filters";
import PageContainer from "components/Administrator/DataCollection/PageContainer";
import Filters from "components/Administrator/DataCollection/Filters";
import Table from "components/Administrator/DataCollection/Table";
import CreateDataCollectionDialog from "components/Administrator/DataCollection/SaveDataCollectionDialog/CreateDataCollectionDialog";
import EditDataCollectionDialog from "components/Administrator/DataCollection/SaveDataCollectionDialog/EditDataCollectionDialog";
import DeleteConfirmation from "components/Administrator/DataCollection/Confirmations/DeleteConfirmation";
import RestoreConfirmation from "components/Administrator/DataCollection/Confirmations/RestoreConfirmation";
import FloatingButton from "components/Administrator/DataCollection/FloatingButton";
import ViewTripInformationDialog from "components/Administrator/DataCollection/TripInformation";
import Select from "components/Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import GridContainer from "@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";

import useUiState from "hooks/Administrator/useUiState";
import dataCollection from "redux/actions/Administrator/DataCollection";
import Box from "@material-ui/core/Box";
import {Button} from "@material-ui/core";

import {
  fetchDataCollection,
  resetState
} from "../../../../redux/actions/Administrator/DataCollection";

const DataCollection = ({tableData, dispatch}) => {
  //const DataCollection = () => {
  const ui = useUiState();
  //const {data} = useSelector(state => state.administrator.dataCollection)
  //const dispatch = useDispatch();
  //useEffect(() => {
  //    dispatch(fetchDataCollection());
  //    return () => {dispatch(resetState())}
  //},[]);

  useEffect(
    () => {
      dispatch(dataCollection.get());
      return () => dispatch(dataCollection.resetState());
    },
    [ dispatch ]
  );

  const [ openViewTrip, setOpenViewTrip ] = React.useState(false);
  const handleCloseViewTrip = () => {
    setOpenViewTrip(false);
  };
  const handleAccept = () => {
    setOpenViewTrip(false);
  };
  const [ dataTrip, setDataTrip ] = React.useState({});
  const [ vehicle, setVehicle ] = React.useState("");
  //const [first,setFirst] = React.useState(null);

  const applyFilters = useCallback(
    filters => dispatch(dataCollection.applyDataCollectionFilters(filters)),
    [ dispatch ]
  );
  const resetFilters = () =>
    dispatch(dataCollection.resetDataCollectionFilters());

  const Refresh = () => {
    dispatch(dataCollection.refresh());
  };

  const onRestore = () => {
    dispatch(dataCollection.restore(ui.restoringId));
  };
  const onDelete = () => {
    dispatch(dataCollection.delete(ui.deletingId));
  };
  const onEdit = (id, data, first, last, desc, resolve) => {
    dispatch(dataCollection.edit(id, data, first, last, desc, resolve));
  };
  const onCreate = data => {
    dispatch(dataCollection.create(data));
  };
  //console.log("DATOS: ",tableData);
  const editing = ui.editingId
    ? tableData.find(e => e.id === ui.editingId)
    : undefined;
  return (
    <PageContainer>
      <GridContainer spacing={4} style={{paddingBottom: "2%"}}>
        <Grid item xs={12} sm={8} lg={9}>
          {/*<Filters onFilter={applyFilters} onClose={resetFilters} showLoadingIndicator={ui.showLoadingIndicator}/>*/}
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <Button color="primary" variant="contained" onClick={() => Refresh()}>
            Refrescar
          </Button>
        </Grid>
      </GridContainer>
      {!ui.isLoadingData && (
        <Box marginBottom={25}>
          {/*<GridContainer spacing={4}>
                        <Grid item xs={12} sm={4} lg={3}>
                            <Select
                                value={vehicle}
                                fullWidth
                                id="vehicle"
                                label="Vehiculo"
                                onChange={e=> setVehicle(e.target.value)}
                            >
                                <MenuItem value="">Seleccione un Vehículo</MenuItem>
                                <MenuItem value="BCA-200">BCA-200</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={4} lg={3}>    
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setOpenViewTrip(true)}
                            >
                                Ver
                            </Button>
                        </Grid>    
                    </GridContainer>*/}
          <Table data={tableData} onEdit={onEdit} />{" "}
          {/*first={first} setFirst={setFirst}/>*/}
        </Box>
      )}
      <CreateDataCollectionDialog onSubmit={onCreate} {...ui.createFormProps} />
      <EditDataCollectionDialog
        initialData={editing}
        onSubmit={onEdit}
        {...ui.editFormProps}
      />
      <DeleteConfirmation onAccept={onDelete} {...ui.deleteConfirmationProps} />
      <RestoreConfirmation
        onAccept={onRestore}
        {...ui.restoreConfirmationProps}
      />
      <ViewTripInformationDialog
        initialData={dataTrip}
        isOpen={openViewTrip}
        onClose={handleCloseViewTrip}
        onSubmit={handleAccept}
      />
    </PageContainer>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.administrator.dataCollection;
  //const {vehicle} = state.administrator.vehicleSelect;
  //const filtered = new FilterBuilder(data)
  //    .byFieldIncludes('vehicle',filters.vehicle)
  //    .byFieldIncludes('route', filters.route)
  //    .byFieldIncludes('turn', filters.turn)
  //    .build();
  //return {tableData: data, vehicles: vehicle}
  return {tableData: data};
};

export default connect(mapStateToProps)(DataCollection);
//export default DataCollection;
