import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {fetchAllBudgets, getAndSetBudgetServices, removeBudget} from "../../thunks/Treasury/budgets";

const INIT_STATE = {
    budgets: {},
    dialogs: [],
    currentBudget: null,
}

const budgetSlice = createSlice({
    name: 'treasury/budgets',
    initialState: cloneDeep(INIT_STATE),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [...state.dialogs, action.payload];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setCurrentBudget: (state, action) => {
            state.currentBudget = action.payload;
        },
        resetBudgetsState: () => cloneDeep(INIT_STATE)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllBudgets.fulfilled, (state, action) => {
                state.budgets = action.payload.budgets.reduce((obj, budget) => {
                    obj[budget.id] = budget;
                    return obj;
                }, {});
            })
            .addCase(removeBudget.fulfilled, (state, action) => {
                const {budgetId} = action.payload;
                const budgets = cloneDeep(state.budgets);
                delete budgets[budgetId];
                state.budgets = budgets;
            }).addCase(getAndSetBudgetServices.fulfilled, (state, action) => {
                state.currentBudget = action.payload;
            });
    }
});

export default budgetSlice.reducer;
export const {
    openDialog,
    closeDialog,
    setCurrentBudget,
    resetBudgetsState
} = budgetSlice.actions;