import React from "react";
import PageContainer from "../../../../../components/Configuration/UserSettings/OrganizationModules/PageContainer";
import OrganizationModulesTable
    from "../../../../../components/Configuration/UserSettings/OrganizationModules/OrganizationModulesTable";
import {useDispatch, useSelector} from "react-redux";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {
    editConfigurationOrganizationModuleState,
    resetConfigurationOrganizationModulesState,
    resetCurrentConfigOrganizationModule,
    setCurrentConfigOrganizationModule
} from "../../../../../redux/actions/Configuration/OrganizationsModules";
import EditOrganizationModulesDialog
    from "../../../../../components/Configuration/UserSettings/OrganizationModules/EditOrganizationModulesDialog";
import MassiveUpdateForm
    from "../../../../../components/Configuration/UserSettings/OrganizationModules/MassiveUpdateForm";

const OrganizationModules = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(
    ({configuration}) => configuration.organizationsModules
  );
  // const [openAdd, setOpenAdd] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  const [openMassive, setOpenMassive ] = React.useState(null);
  React.useEffect(() => {
    return () => {
      dispatch(resetConfigurationOrganizationModulesState());
    };
  }, []);
  const onOpenMassive = (option) => {
      dispatch(setCurrentConfigOrganizationModule({id: 0, permissions: []}));
      setOpenMassive(option);
  }
  return (
    <PageContainer>
      <OrganizationModulesTable
        openEdit={() => setOpenEdit(true)}
        openDelete={() => setOpenDelete(true)}
        openRestore={() => setOpenRestore(true)}
        openMassive={onOpenMassive}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"configuration.user.settings.organization.modules.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentConfigOrganizationModule());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editConfigurationOrganizationModuleState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"configuration.user.settings.organization.modules.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentConfigOrganizationModule());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editConfigurationOrganizationModuleState(current, true));
        }}
      />
      {/*<FloatingButtonWithAdd*/}
      {/*    onAddClicked={() => setOpenAdd(true)}*/}
      {/*/>*/}
      {/*<AddOrganizationModuleDialog*/}
      {/*    openAdd={openAdd}*/}
      {/*    setOpenAdd={setOpenAdd}*/}
      {/*/>*/}
      <EditOrganizationModulesDialog
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
      />
        {openMassive &&
            <MassiveUpdateForm
            open={openMassive}
            setOpen={setOpenMassive}
        />}
    </PageContainer>
  );
};

export default OrganizationModules;
