import React from 'react';
import {arrayMove} from "react-sortable-hoc";
import GraphsContainer from "./GraphsContainer";


const Viewer = ({formWidgets, setFormWidgets}) => {
    const onSortEnd = ({oldIndex, newIndex}) => {
        const newWidgets = arrayMove(formWidgets, oldIndex, newIndex)
            .map((w, index) => ({...w, orderId: index}));
        setFormWidgets(newWidgets);
    };
    const onDelete = widget => {
        setFormWidgets(formWidgets.filter(w => w.id !== widget.id))
    }
    const onUpdateWidget = (widget) => {
        const newWidgets = formWidgets.map(w => {
            if(w.id === widget.id) {
                return widget;
            }
            return w;
        })
        setFormWidgets(newWidgets);
    }
    return   <GraphsContainer
        widgets={formWidgets?.sort((a, b)=> a.orderId - b.orderId)}
        axis="xy"
        useDragHandle
        onSortEnd={onSortEnd}
        onD
        onDelete={onDelete}
        updateWidget={onUpdateWidget}
    />;
};

Viewer.propTypes = {

};

export default Viewer;