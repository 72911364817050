import React from "react";
import PageContainer from "../../../../../components/FleetManagement/Tachograph/Traffic/PageContainer";
import GraphicsGrid from "../../../../../components/FleetManagement/Tachograph/Traffic/GraphicsGrid";
import {useDispatch} from "react-redux";
import {resetTachographTrafficState} from "../../../../../redux/actions/FleetManagement/TachographTraffic";
import DriversList from "../../../../../components/FleetManagement/Tachograph/Traffic/DriversList";

const Traffic = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    return () => {
      dispatch(resetTachographTrafficState());
    };
  }, []);
  return (
    <PageContainer>
      <GraphicsGrid />
      <DriversList />
    </PageContainer>
  );
};

export default Traffic;
