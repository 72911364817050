import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "components/Common/Tables/StyledTableCell";

const TableValues = ({row}) => (
  <React.Fragment>
    <StyledTableCell align="left">{row.stateType}</StyledTableCell>
  </React.Fragment>
);

TableValues.propTypes = {
  row: PropTypes.shape({
    stateType: PropTypes.string
  })
};

export default TableValues;
