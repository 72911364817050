import React from "react";
import {useDispatch} from "react-redux";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import {fetchError} from "../../../../../../../redux/actions";
import {getAllModels} from "../../../../../../../services/FleetManagement/Vehicles/ModelService";

const ModelSelect = ({
  id,
  label,
  value,
  setValue,
  error,
  hasError,
  disabled = false,
  brand
}) => {
  const [ models, setModels ] = React.useState([]);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      brand &&
        getAllModels(brand)
          .then(data => setModels(data))
          .catch(e => dispatch(fetchError(e.message)));
    },
    [ brand ]
  );
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      disabled={disabled}
      error={hasError}
      errorMessage={error}
    >
      {models.map(m => (
        <MenuItem key={`model-${m.id}`} value={m.id}>
          {m.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ModelSelect;
