import React from "react";

import PageContainer from "../../../../../components/FleetManagement/Vehicles/Infractions/PageContainer";
import InfractionsTable from "../../../../../components/FleetManagement/Vehicles/Infractions/InfractionsTable";
import {useDispatch, useSelector} from "react-redux";
import {resetInfractionsState} from "../../../../../redux/actions/FleetManagement/Infractions";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddInfractionDialog from "../../../../../components/FleetManagement/Vehicles/Infractions/AddInfractionDialog";
import EditInfractionDialog from "../../../../../components/FleetManagement/Vehicles/Infractions/EditInfractionDialog";

const Infractions = () => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.infractions
  );
  const dispatch = useDispatch();
  const [ openAddDialog, setOpenAddDialog ] = React.useState(false);
  const [ openEditDialog, setOpenEditDialog ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetInfractionsState());
    };
  }, []);
  return (
    <PageContainer>
      <InfractionsTable openEditDialog={() => setOpenEditDialog(true)} />
      <FloatingButtonWithAdd
        onAddClicked={() => {
          setOpenAddDialog(true);
        }}
      />
      {openAddDialog && (
        <AddInfractionDialog open={openAddDialog} setOpen={setOpenAddDialog} />
      )}
      {openEditDialog &&
      current && (
        <EditInfractionDialog
          open={openEditDialog}
          setOpen={setOpenEditDialog}
        />
      )}
    </PageContainer>
  );
};

export default Infractions;
