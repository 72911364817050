import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import FormDialog from "components/Common/Dialogs/FormDialog";
import Form from "./Form";

const CreateIncidentMerchandiseDialog = ({
  initialData,
  isOpen,
  onClose,
  onSubmit
}) => (
  <FormDialog
    id="create-incident-merchandise-form"
    isOpen={isOpen}
    title={<IntlMessages id="logistics.incidents.createFormTitle" />}
    onClose={onClose}
  >
    <Form
      id="create-incident-merchandise-form"
      initialData={initialData}
      onSubmit={onSubmit}
    />
  </FormDialog>
);

export default CreateIncidentMerchandiseDialog;
