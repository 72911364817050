import React from "react";
import {TableHead} from "@material-ui/core";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <TableHead>
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id="administrator.tag.list.table.id" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.tag.list.table.description" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.tag.list.table.state" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="administrator.tag.list.table.actions" />
      </StyledTableCell>
    </StyledTableRow>
  </TableHead>
);

export default TableHeader;
