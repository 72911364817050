import React from "react";
import Select from "../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const DirectionSelect = ({id, label, value, onChange}) => {
  return (
    <Select
      id={id}
      label={label}
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem key={"direction-all"} value={""}>
        <IntlMessages id={"logistic.traceability.filter.direction.all"} />
      </MenuItem>
      <MenuItem key={"direction-origin"} value={"1"}>
        <IntlMessages id={"logistic.traceability.filter.direction.origin"} />
      </MenuItem>
      <MenuItem key={"direction-destination"} value={"2"}>
        <IntlMessages
          id={"logistic.traceability.filter.direction.destination"}
        />
      </MenuItem>
    </Select>
  );
};

export default DirectionSelect;
