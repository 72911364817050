import React from "react";
import clsx from "clsx";
import {Box, Typography} from "@material-ui/core";

const WorkOrderItem = ({
  classes,
  data,
  onSelectWorkOrder,
  selectedWorkOrder
}) => (
  <Box
    className={clsx(classes.chatCellItem, {
      active: selectedWorkOrder && selectedWorkOrder.id === data.id
    })}
    onClick={() => onSelectWorkOrder(data)}
  >
    <Box className={classes.chatCellInfo}>
      <Box display="flex" alignItems="center">
        <Typography
          component="div"
          variant="subtitle2"
          className={classes.titleRoot}
        >
          {data.number}
        </Typography>
        <Box color="text.secondary" fontSize={12} ml="auto">
          {data.driver}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography className={classes.chatDesRoot}>{data.vehicle}</Typography>
      </Box>
    </Box>
  </Box>
);

export default WorkOrderItem;
