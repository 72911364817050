import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/Parts/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/Parts/Table";
import DetailDialog from "../../../../../components/FleetManagement/DailyOperation/Parts/DetailDialog";
import MoreDetailsDialog from "../../../../../components/FleetManagement/DailyOperation/Parts/MoreDetailsDialog";

const Reports = () => {
  return (
    <PageContainer>
      <Table />
      <DetailDialog />
      <MoreDetailsDialog />
    </PageContainer>
  );
};

export default Reports;
