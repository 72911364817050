import React from "react";
import {useSelector} from "react-redux";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportServices = ({filteredData, children}) => {
  const {tableHeaders} = useSelector(
    ({logistic}) => logistic.serviceList
  );

  return (
    <ExcelFile element={children} fileName={"Lista de Servicios"}>
      <ExcelSheet data={filteredData} name="Lista de Servicios">
        {Object.values(tableHeaders)
          .filter(h => h.state)
          .map(
            header =>
              header.content === "state" ? (
                <ExcelColumn
                  key={header.id}
                  label={header.label}
                  value="statesDescription"
                />
              ) : (
                <ExcelColumn
                  key={header.id}
                  label={header.label}
                  value={header.content}
                />
              )
          )}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportServices;
