import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Button, Grid, Typography} from "@material-ui/core";
import VehiclesTable from "./VehiclesTable";
import VehiclesDialog from "./VehiclesDialog";

const VehicleList = ({
  selectedVehicles,
  setSelectedVehicles,
  show,
  setShow
}) => {
  const [ vehicleDialog, setVehicleDialog ] = React.useState(false);

  return (
    <React.Fragment>
      <GridContainer spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">
            <IntlMessages id="administrator.logistic.assignment.initialInformation.subtitle" />
          </Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography variant="h3" style={{paddingTop: "4px"}}>
            <IntlMessages id="logistic.assignment.vehicles.selected" /> :{" "}
            {selectedVehicles.length}
          </Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Button
            onClick={() => setVehicleDialog(true)}
            fullWidth
            variant="outlined"
          >
            <IntlMessages id={"logistic.assigment.add.vehicle"} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <VehiclesTable
            setSelectedVehicles={setSelectedVehicles}
            vehicles={selectedVehicles}
          />
        </Grid>
        <Grid item xs={12} sm={9} lg={9} />
        <Grid item xs={12} sm={3} lg={3}>
          <Button onClick={() => setShow(2)}>
            <IntlMessages id={"logistic.assigment.next"} />
          </Button>
        </Grid>
      </GridContainer>
      <VehiclesDialog
        setSelectedVehicles={setSelectedVehicles}
        selectedVehicles={selectedVehicles}
        open={vehicleDialog}
        setOpen={setVehicleDialog}
      />
    </React.Fragment>
  );
};

export default VehicleList;
