class Guardian {
  constructor(id, entityId, names, tags, active) {
    this.id = id;
    this.entityId = entityId;
    this.names = names;
    this.tags = tags;
    this.active = active;
  }

  static map = guardian => {
    return new Guardian(
      guardian.nu_id_entidad_gestor_caja,
      guardian.nu_id_entidad,
      guardian.vc_nombre_entidad_gestor_caja,
      guardian.vc_desc_tag.split(","),
      guardian.bi_estado === "1"
    );
  };

  static editMap = guardian => {
    return new Guardian(
      guardian.nu_id_entidad_gestor_caja,
      guardian.nu_id_entidad,
      guardian.vc_nombre_entidad_gestor_caja,
      guardian.ls_tag.map(tag => ({id: tag.nu_id_tag, name: tag.vc_desc_tag})),
      guardian.bi_estado === "1"
    );
  };

  static editMap = guardian => {
    return new Guardian(
        guardian.nu_id_entidad_gestor_caja,
        guardian.nu_id_entidad,
        guardian.vc_nombre_entidad_gestor_caja,
        guardian.ls_tag.map(tag => ({id: tag.nu_id_tag, name: tag.vc_desc_tag})),
        guardian.bi_estado === '1',
    );
  }
}

export default Guardian;
