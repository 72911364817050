import React from 'react';
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import {setCurrentKpiReportFilters} from "../../../../../../redux/actions/ControlPanel/Kpi";

const SaveFilters = ({filters}) => {
    const dispatch = useDispatch();
    const saveFilters = () => {
        dispatch(setCurrentKpiReportFilters(filters));
    }
    return (
        <Grid item xs={12} sm={3}>
            <Button
                variant="contained"
                onClick={saveFilters}
                color="primary"
            >
                <IntlMessages id="save.filters"/>
            </Button>
        </Grid>
    );
};

export default SaveFilters;