import React from "react";

import useForm from 'hooks/Common/useForm';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import TableComponent from 'components/Common/Tables/Table';
import StyledTableRow from 'components/Common/Tables/StyledTableRow';
import StyledTableCell from 'components/Common/Tables/StyledTableCell';

import Box from '@material-ui/core/Box';

const Form = ({id, initialData, onSubmit}) => {
    const {formData, setFormData, errors, handleSubmit} = useForm(
        {
            entityOrigin: initialData?.entityNameOrigin || '',
            campusOrigin: initialData?.campusNameOrigin || '',
            warehouseOrigin: initialData?.warehouseNameOrigin || '',
            entityDestiny: initialData?.entityNameDestiny || '',
            campusDestiny: initialData?.campusNameDestiny || '',
            warehouseDestiny: initialData?.warehouseNameDestiny || '',
            products: initialData?.transfer || [],
        }
    )

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={4}>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        value={formData.entityOrigin}
                        id="show-incident-origin-entity"
                        label="Entidad origen"
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        value={formData.campusOrigin}
                        id="show-incident-origin-entity"
                        label="Sede origen"
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        value={formData.warehouseOrigin}
                        id="show-incident-origin-entity"
                        label="Almacén origen"
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        value={formData.entityDestiny}
                        id="show-incident-origin-entity"
                        label="Entidad destino"
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        value={formData.campusDestiny}
                        id="show-incident-origin-entity"
                        label="Sede destino"
                        disabled
                    />
                </Grid>
                <Grid item xs={4}>
                    <OutlinedInput
                        fullWidth
                        value={formData.warehouseDestiny}
                        id="show-incident-origin-entity"
                        label="Almacén destino"
                        disabled
                    />
                </Grid>    
            </GridContainer>

            <Box marginTop={4}>
                <TableComponent
                    data={formData.products}
                    head={
                        <StyledTableRow>
                            <StyledTableCell width="20%">Producto</StyledTableCell>
                            <StyledTableCell width="15%">Stock</StyledTableCell>
                            <StyledTableCell width="15%">Stock actual</StyledTableCell>
                            <StyledTableCell width="15%">Cantidad</StyledTableCell>
                            <StyledTableCell>Incidente</StyledTableCell>
                        </StyledTableRow>
                    }
                    renderRow={({row}) => (
                        <StyledTableRow>
                            <StyledTableCell>{row.product}</StyledTableCell>
                            <StyledTableCell>{row.stock}</StyledTableCell>
                            <StyledTableCell>{row.currentStock}</StyledTableCell>
                            <StyledTableCell>{row.stock}</StyledTableCell>
                            <StyledTableCell>{row.incidence}</StyledTableCell>
                        </StyledTableRow>
                    )}
                    getRowKey={row => row.id}
                />
            </Box>
        </form>
    )
};

export default Form;