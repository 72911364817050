import React from 'react';

import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import useForm from 'hooks/Common/useForm';

import validations from './validations';

const Form = ({ id, initialData, onSubmit }) => {
  const { formData, setFormData, errors, handleSubmit } = useForm(
    {
      code: initialData?.code || '',
      accountingAccount: initialData?.accountingAccount || '',
      description: initialData?.description || '',
    },
    validations,
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={6}>
          <OutlinedInput
            value={formData.code}
            hasError={errors.code.length > 0}
            error={errors.code[0]}
            fullWidth
            id="code"
            label={<IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.code" />}
            onChange={value => setFormData('code', value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <OutlinedInput
            value={formData.accountingAccount}
            hasError={errors.accountingAccount.length > 0}
            error={errors.accountingAccount[0]}
            fullWidth
            id="accountingAccount"
            label={<IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.accountingAccount" />}
            onChange={value => setFormData('accountingAccount', value)}
          />
        </Grid>
        <Grid item xs={12}>
          <OutlinedInput
            value={formData.description}
            hasError={errors.description.length > 0}
            error={errors.description[0]}
            fullWidth
            id="description"
            label={<IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.description" />}
            onChange={value => setFormData('description', value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
