import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import FloatingSpeedDial from "../../Common/Buttons/SpeedDial";

const FloatingButton = ({setOpenDialog}) => {
  const actions = [
    {
      icon: <AddCircleIcon />,
      name: (
        <IntlMessages id={"guardian.accounting.speed.dial.new.accounting"} />
      ),
      key: "new-accounting",
      onCLick: () => setOpenDialog(true)
    }
  ];

  return <FloatingSpeedDial actions={actions} />;
};

export default FloatingButton;
