import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import useForm from "../../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import defaultDashboards from "../../../defaultDashboards";
import DateFilters from "../../../../../Reports/ReportsContainer/Common/DateFilters";
import {getCurrentDashboardWidgetsData} from "../../../../../../../redux/thunks/ControlPanel/dashboards";
import {defaultDashboardsIds} from "../../../../constants";
import PlanningPlaceSelect from "./PlanningPlaceSelect";
import OutlinedDatePicker from "../../../../../../Common/Forms/OutlinedDatePicker";

const initData = {
    period: 1,
    startDate: '',
    endDate: '',
    planningPlace: '',
    date: ''
}
const Form = () => {
    const id = 'dashboard-filters';
    const {formData, setFormData, handleSubmit, resetForm, setValues} = useForm(initData);
    const {currentDashboard, filters} = useSelector(({controlPanel}) => controlPanel.dashboards);
    const dispatch = useDispatch();
    const onSubmit = filters => {
        if(!currentDashboard?.custom) {
            dispatch(defaultDashboards[currentDashboard?.id].thunk({filters, dashboard: currentDashboard}));
        }else {
            dispatch(getCurrentDashboardWidgetsData({filters, dashboard: currentDashboard}));
        }
    }
    const onClearFilters = () => {
        resetForm();
    }
    React.useEffect(() => {
        setValues(filters);
    }, [filters]);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                {currentDashboard?.id === defaultDashboardsIds.AAVV
                    ? <>
                        <Grid item xs={12} md={6}>
                            <OutlinedDatePicker
                                label={<IntlMessages id='date' />}
                                value={formData.date}
                                onChange={value => setFormData('date', value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <PlanningPlaceSelect
                                value={formData.planningPlace}
                                setValue={(value) => setFormData('planningPlace', value)}
                            />
                        </Grid>
                    </>
                    :  <DateFilters
                        formData={formData}
                        setFormData={setFormData}
                    />
                }
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        type='submit'
                        form={id}
                        color='primary'
                        text={<IntlMessages id='filters.button.apply' />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={onClearFilters}
                        text={<IntlMessages id='filters.button.clear' />}
                    />
                </Grid>
            </GridContainer>

        </form>
    );
};

export default Form;