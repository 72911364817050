import React, {useEffect, useState} from "react";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {Grid, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllPettyCashesStarted} from "../../../../../redux/actions/Guardians/PettyCashes";
import Autocomplete from "@material-ui/lab/Autocomplete";

const BoxInput = ({
  setBox,
  isLoading,
  errors,
  currentBox = null,
  currentAdvanceBox = null,
  label = "guardian.add.advance.form.boxes"
}) => {
  const [ disabled, setDisabled ] = React.useState(false);
  const {list} = useSelector(state => state.guardian.pettyCashes);
  const dispatch = useDispatch();
  const [ boxes, setBoxes ] = useState([]);
  const [ value, setValue ] = useState(null);
  useEffect(() => {
    if (list.length < 1) {
      dispatch(fetchAllPettyCashesStarted());
    }
  }, []);
  useEffect(
    () => {
      setBoxes([
        ...list.map(b => ({
          id: b.id,
          name: b.tags.toString() + "-" + b.fund + " " + b.currencySymbol
        }))
      ]);
    },
    [ list ]
  );

  useEffect(
    () => {
      if (boxes.length > 0) {
        if (currentBox) {
          setValue(boxes?.find(box => box.id === currentBox.id)?? null);
          setDisabled(true);
        }
        if (currentAdvanceBox) {
          setValue(boxes?.find(box => box.id === currentAdvanceBox.id)?? null);
          setDisabled(true);
        }
        if (!currentBox && !currentAdvanceBox) {
          setDisabled(false);
        }
      }
    },
    [ currentBox, currentAdvanceBox, boxes ]
  );

  const handleChange = (e, newValue) => {
    setValue(newValue);
  }

  useEffect(() => {
    setBox(value?.id ?? '');
  }, [value]);

  return (
    <Grid item xs={12} sm={12}>
      <Autocomplete
          id="box"
          getOptionSelected={(option, value) => option.id === value.id}
          getOptionLabel={option => option.name || ""}
          options={boxes ?? []}
          onChange={handleChange}
          value={value}
          autoComplete
          disabled={isLoading || disabled}
          renderInput={params => (
              <TextField
                  {...params}
                  label={<IntlMessages id={label} />}
                  fullWidth
                  variant="outlined"
                  error={errors.box.length > 0}
                  helperText={errors.box[0]}
              />
          )}
      />
    </Grid>
  );
};

export default BoxInput;
