import React from "react";
import PageContainer from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/CategoryTypes/PageContainer";
import {useDispatch, useSelector} from "react-redux";
import {
  editElevatorCategoryState,
  resetCurrentElevatorCategory,
  resetElevatorsCategoriesState
} from "../../../../../../redux/actions/ElevatorsManagement/Categories";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import CategoriesTable from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/CategoryTypes/CategoriesTable";
import AddCategoryDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/CategoryTypes/AddCategoryDialog";
import EditCategoryDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/CategoryTypes/EditCategoryDialog";

const CategoryTypes = () => {
  const {current} = useSelector(
    ({elevatorsManagement}) => elevatorsManagement.categories
  );
  const dispatch = useDispatch();
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  React.useEffect(() => {
    return () => {
      dispatch(resetElevatorsCategoriesState());
    };
  }, []);
  return (
    <PageContainer>
      <CategoriesTable
        setEdit={setOpenEdit}
        setDelete={setOpenDelete}
        setRestore={setOpenRestore}
      />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(resetCurrentElevatorCategory());
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editElevatorCategoryState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"elevators.management.elevators.types.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(resetCurrentElevatorCategory());
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editElevatorCategoryState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <AddCategoryDialog open={openAdd} setOpen={setOpenAdd} />
      <EditCategoryDialog open={openEdit} setOpen={setOpenEdit} />
    </PageContainer>
  );
};

export default CategoryTypes;
