import axiosInstance, {CancelToken} from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../StorageService";
import AavvService from "../../../domain/Logistic/Distribution/AavvService";
import ServiceHeader from "../../../domain/Logistic/Distribution/ServiceHeader";
import {v4 as uuidV4} from "uuid";
import Response from "../../../domain/Response";

const tableHeaders = [
    new ServiceHeader(
        uuidV4(),
        'OT',
        'ot',
        true
    ),
    new ServiceHeader(
        uuidV4(),
        'Fecha',
        'date',
        true
    ),
    new ServiceHeader(
        uuidV4(),
        'Sem.',
        'week',
        true
    ),
    new ServiceHeader(
        uuidV4(),
        'Mes',
        'month',
        true
    ),
    new ServiceHeader(
        uuidV4(),
        'Año',
        'year',
        true
    ),
    new ServiceHeader(uuidV4(), 'Placa Tracto', 'tractPlaque', true),
    new ServiceHeader(uuidV4(), 'Placa Carreta', 'wagonPlaque', true),
    new ServiceHeader(uuidV4(), 'Capac. Jabas', 'jabasCapacity', true),
    new ServiceHeader(uuidV4(), 'Plantel', 'staff', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Cliente Prog.', 'clientScheduledDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Cliente Real', 'clientRealDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Lav. Prog.', 'laundryScheduledArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Lav. Real', 'laundryRealArrivalTime', true),
    new ServiceHeader(uuidV4(), 'TRT Salida-Llegada', 'trtDepartureArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Cumpli. Salida Cliente', 'clientDepartureCompliance', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Lav. Prog.', 'laundryScheduledDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Lav. Real', 'laundryRealDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Granja Prog.', 'farmScheduledArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Granja Real', 'farmRealArrivalTime', true),
    new ServiceHeader(uuidV4(), 'TRT Salida-Llegada (Lav.-Granja)', 'trtDepartureRealArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Cumpli. Salida Cliente', 'clientDepartureCompliance2', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Granja Prog.', 'farmScheduledDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Granja Real', 'farmRealDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Bza. Prog.', 'scalesScheduledArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Bza. Real', 'scalesRealArrivalTime', true),
    new ServiceHeader(uuidV4(), 'TRT Salida-Llegada (Granja-Bza)', 'trtFarmScalesDepartureArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Cumpli. Llegada Bza.', 'scalesArrivalCompliance', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Bza. Prog.', 'scalesScheduledDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Salida Bza. Real', 'scalesRealDepartureTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Cliente Prog.', 'clientScheduledArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Hora Llegada Cliente Real', 'clientRealArrivalTime', true),
    new ServiceHeader(uuidV4(), 'TRT Salida-Llegada(Bza.-Cliente)', 'trtScalesClientDepartureArrivalTime', true),
    new ServiceHeader(uuidV4(), 'Cumpli. Llegada Granja', 'farmArrivalCompliance', true),
    new ServiceHeader(uuidV4(), 'TRT Total Programado', 'trtScheduledTotal', true),
    new ServiceHeader(uuidV4(), 'TRT Total Real', 'trtRealTotal', true),
    new ServiceHeader(uuidV4(), 'Tiempo Adic. TRT Total', 'trtExtraTotal', true),
    new ServiceHeader(uuidV4(), 'Cumpli. TRT', 'trtCompliance', true),
    new ServiceHeader(uuidV4(), 'Motivo de No Cumplimiento de Tiempo de Recorrido', 'nonComplianceReason', true),
    new ServiceHeader(uuidV4(), 'Detalle de No Cumplimiento e Tiempo de Recorrido', 'noComplianceDetail', true),
];

let cancelFetchServices = undefined;
let cancelFetchHeaders = undefined;

const cancelPrevFetchServices = () => {
    cancelFetchServices && cancelFetchServices();
};

const cancelPrevFetchHeaders = () => {
    cancelFetchHeaders && cancelFetchHeaders();
};

export const getAllAavvServices = async (filters) => {
    cancelPrevFetchServices();
    try {
        const {data} = await axiosInstance.post(
            "/Informes/informe_servicios_AAVV",
            {
                nu_id_entidad_empresa: getCompanyEntityId(),
                dt_fec_inicio: filters.date
            },
            {
                cancelToken: new CancelToken(c => (cancelFetchServices = c))
            }
        );
        return data.map((service, index) => AavvService.map({id: index, ...service}));
    } catch (e) {
        return Promise.reject(e);
    }
}


export const getAllAavvServicesHeaders = async () => {
    cancelPrevFetchHeaders();
    try {
        const {data} = await axiosInstance.post(
            "/OT/sel_filtros_servicios_aavv",
            {
                nu_id_entidad: getUserId(),
                nu_id_entidad_empresa: getCompanyEntityId()
            },
            {
                cancelToken: new CancelToken(c => (cancelFetchHeaders = c))
            }
        );
        if (data.length > 0) {
            const headers = JSON.parse(data[0].vc_filtros) || [];
            return headers.map(header => ServiceHeader.map(header));
        } else {
            return tableHeaders;
        }

    } catch (e) {
        return Promise.reject(e);
    }
};


export const updateAavvServicesHeaders = async headers => {
    try {
        const {
            data
        } = await axiosInstance.post(
            "/OT/ins_filtros_servicios_aavv", {
            nu_id_entidad: getUserId(),
            nu_id_entidad_empresa: getCompanyEntityId(),
            vc_filtros: JSON.stringify(headers)
        });
        const response = Response.map(data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    } catch (e) {
        return Promise.reject(e);
    }
};