import React from 'react';
import IntlMessages from '@jumbo/utils/IntlMessages';
import GridContainer from '@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from 'components/Common/Forms/OutlinedInput';
import Checkbox from 'components/Common/Forms/Checkbox';
import useForm from 'hooks/Common/useForm';
import UnitSelect from 'containers/Administrator/UnitSelect';


import validations from './validations';

const Form = ({ id, initialData, onSubmit }) => {
  
    const { formData, setFormData, errors, handleSubmit } = useForm(
      {
        description: initialData?.description || '',
        codExterno: initialData?.codExterno || '',
        esEnvase: initialData?.esEnvase || false,
        unidadType: initialData?.idUnidad || '' ,
        volumenUnidad: initialData?.volumenUnidad || ''
      },
      validations,
    );

    return (
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer spacing={4}>
          <Grid item xs={12}>
            <OutlinedInput
              value={formData.description}
              hasError={errors.description.length > 0}
              error={errors.description[0]}
              fullWidth
              id="description"
              label={<IntlMessages id="administrator.Products.saveProductDialog.field.description" />}
              onChange={value => setFormData('description', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <OutlinedInput
              value={formData.codExterno}
              hasError={errors.codExterno.length > 0}
              error={errors.codExterno[0]}
              fullWidth
              id="codExterno"
              label={<IntlMessages id="administrator.Products.saveProductDialog.field.codExterno" />}
              onChange={value => setFormData('codExterno', value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checked={formData.esEnvase}
              onChange={value => setFormData('esEnvase', value)}
              name="esEnvase"
              label={<IntlMessages id="administrator.Products.saveProductDialog.field.esEnvase" />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
              <UnitSelect
                id="unidadType"
                label={<IntlMessages id="administrator.form.unitSelect.label" />}
                value={formData.unidadType}
                hasError={errors.unidadType.length > 0}
                error={errors.unidadType[0]}
                onChange={value => setFormData('unidadType', value)}
              />
            </Grid>
            <Grid item xs={12}>
              <OutlinedInput
                type="number"
                value={formData.volumenUnidad}
                hasError={errors.volumenUnidad.length > 0}
                error={errors.volumenUnidad[0]}
                fullWidth
                id="volumenUnidad"
                label={<IntlMessages id="administrator.Products.saveProductDialog.field.volumenUnidad" />}
                onChange={value => setFormData('volumenUnidad', value)}
              />
            </Grid>
        </GridContainer>
      </form>
    );
  };
  
  export default Form;