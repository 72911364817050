import React from "react";
import PropTypes from "prop-types";

import FiltersContainer from "components/Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = ({onFilter, onClose}) => (
  <FiltersContainer active marginBottom={5} onClose={onClose}>
    <Form onFilter={onFilter} />
  </FiltersContainer>
);

Filters.propTypes = {
  onFilter: PropTypes.func,
  onClose: PropTypes.func
};

Filters.defaultProps = {
  onFilter: () => {},
  onClose: () => {}
};

export default Filters;
