import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {editInfraction} from "../../../../../redux/actions/FleetManagement/Infractions";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "../AddInfractionDialog/Form";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import moment from "moment";
import {Box, Grid} from "@material-ui/core";
import FilesArea from "../../../Common/FilesArea";

const EditInfractionDialog = ({open, setOpen}) => {
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.infractions
  );
  const [ files, setFiles ] = React.useState(current.files || []);
  const id = "edit-infraction-dialog";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(editInfraction({id: current.id, ...data}, () => setOpen(false)));
  };

  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.infractions.edit.title"} />}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      submitText={
        <IntlMessages id={"fleet.management.infractions.edit.save"} />
      }
      cancelText={
        <IntlMessages id={"fleet.management.infractions.add.cancel"} />
      }
    >
      <Form
        id={id}
        onSubmit={onSubmit}
        initialData={{
          plaque: current.plaque || "",
          date: moment(current.date).format("YYYY-MM-DD") || "",
          time: moment(current.date).format("HH:mm") || "",
          driver: current.driver || "",
          fileNumber: current.fileNumber || "",
          organization: current.organization || "",
          costCentral: current.costCentral || "",
          denunciationPlace: current.denunciationPlace || "",
          postalCode: current.postalCode || "",
          district: current.district || "",
          infringedRule: current.infringedRule || "",
          denunciation: current.denunciation || "",
          notificationDate:
            moment(current.notificationDate).format("YYYY-MM-DD") || "",
          amount: current.amount || "",
          discountExpirationDate:
            moment(current.discountExpirationDate).format("YYYY-MM-DD") || "",
          discount: current.discount || "",
          result: current.result || "",
          defense: current.defense || "",
          limitDate: moment(current.limitDate).format("YYYY-MM-DD") || "",
          management: current.management || "",
          state: current.state || ""
        }}
      />
      <Box>
        <FilesArea
          id={"form-infraction-files"}
          files={files}
          setFiles={setFiles}
        />
      </Box>
    </FormDialog>
  );
};

export default EditInfractionDialog;
