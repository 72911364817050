import React from 'react';
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import {useIntl} from "react-intl";

const ActivitySelect = ({value, onChange, disabled=false}) => {
    const intl = useIntl();
    const activities = [
        {
            id: 0,
            name: intl.messages["rest"]
        },
        {
            id: 1,
            name: intl.messages["available"]
        },
        {
            id: 2,
            name: intl.messages["work"]
        },
        {
            id: 3,
            name: intl.messages["driving"]
        },
        {
            id: 7,
            name: intl.messages["unavailable"]
        },
    ];
    return (
        <MultipleSelect
            fullWidth
            label={<IntlMessages id="current.activity" />}
            value={value}
            onChange={value => onChange(value)}
            disabled={disabled}
            options={activities}
        />
    );
};

export default ActivitySelect;