import React from "react";
import {connect} from "react-redux";
import TableContainer from "../../../../Common/Tables/TableContainer";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {
  fetchAllConfigurationModules,
  setCurrentConfigurationModule
} from "../../../../../redux/actions/Configuration/Modules";
import TableHeader from "./TableHeader";
import EditTableRow from "../../../../Common/Tables/CrudTable/EditTableRow";
import {Box} from "@material-ui/core";
import Filters from "./Filters";
import FilterBuilder from "../../../../../utils/filters";

const ModulesTable = ({data, dispatch, openEdit, openDelete, openRestore}) => {
  const onEdit = module => {
    openEdit && openEdit();
    dispatch(setCurrentConfigurationModule(module));
  };
  const onDelete = module => {
    openDelete && openDelete();
    dispatch(setCurrentConfigurationModule(module, 2));
  };
  const onRestore = module => {
    openRestore && openRestore();
    dispatch(setCurrentConfigurationModule(module, 2));
  };
  React.useEffect(() => {
    dispatch(fetchAllConfigurationModules());
  }, []);

  return (
    <Box display="flex" flexDirection="column" style={{gap: "10px"}}>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {Object.values(data).map(module => (
          <EditTableRow onEdit={() => onEdit(module)} key={module.id}>
            <StyledTableCell>{module.id}</StyledTableCell>
            <StyledTableCell>{module.description}</StyledTableCell>
          </EditTableRow>
          // <React.Fragment key={module.id}>
          //     {
          //         module.state
          //             ? <CrudTableRow onEdit={() => onEdit(module)} onDelete={() => onDelete(module)}>
          //                 <StyledTableCell>{module.id}</StyledTableCell>
          //                 <StyledTableCell>{module.description}</StyledTableCell>
          //             </CrudTableRow>
          //             : <RestoreTableRow onRestore={() => onRestore(module)}>
          //                 <StyledTableCell>{module.id}</StyledTableCell>
          //                 <StyledTableCell>{module.description}</StyledTableCell>
          //             </RestoreTableRow>
          //     }
          // </React.Fragment>
        ))}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = ({configuration}) => {
  const {data, filters} = configuration.modules;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("id", filters.code)
    .byFieldIncludes("description", filters.description)
    .build();
  return {data: filteredData};
};

export default connect(mapStateToProps)(ModulesTable);
