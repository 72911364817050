import axiosInstance, {CancelToken, isCancel} from "utils/axiosInstance";
import Warehouse from "../domain/Warehouse";
import Response from "domain/Response";
import {getCompanyEntityId, getUserName} from "./StorageService";

let fetchAllCancelToken = undefined;

export const cancelPrevWarehouseFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAllWarehouse = async () => {
  //return almacen.map(almacen => Warehouse.map(almacen));
  try {
    const response = await axiosInstance.post(
      "/Inventario_Almacen/sel",
      {nu_id_entidad: getCompanyEntityId()},
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c))
      }
    );
    return response.data.map(alamcenes => Warehouse.map(alamcenes));
  } catch (error) {
    if (isCancel(error)) {
      return [];
    }
    return Promise.reject(error);
  }
};

export const createWarehouse = async data => {
  try {
    const response = await axiosInstance.post("/Inventario_Almacen/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_sede: data.sede.value,
      vc_desc_almacen: data.name,
      bi_stock_negativo: data.allowNegativeStock,
      vc_usuario: getUserName()
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const editWarehouse = async (id, data) => {
  try {
    const response = await axiosInstance.post("/Inventario_Almacen/upd", {
      nu_id_almacen: id,
      nu_id_entidad: getCompanyEntityId(),
      nu_id_entidad_sede: Number(data.sede.value),
      vc_desc_almacen: data.name,
      bi_stock_negativo: +data.allowNegativeStock,
      vc_usuario: getUserName()
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};

export const changeStateWarehouse = async id => {
  try {
    const response = await axiosInstance.post("Inventario_Almacen/del", {
      nu_id_almacen: id,
      nu_id_entidad: getCompanyEntityId()
    });
    const responseObject = Response.map(response.data);
    if (responseObject.transactionState === "0") {
      throw new Error(responseObject.transactionMessage);
    }
    return {
      id: responseObject.transactionKey,
      message: responseObject.transactionMessage
    };
  } catch (error) {
    return Promise.reject(error);
  }
};
