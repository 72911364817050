import React from "react";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="administrator.clients.table.clientCode" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="registered.name" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="administrator.clients.table.businessName" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="administrator.clients.table.country" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="fiscal.identity" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="administrator.clients.table.email" />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
