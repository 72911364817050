import axiosInstance, { CancelToken, isCancel } from '../utils/axiosInstance';
import UnresolvedExpense from '../domain/UnresolvedExpense';
import {getCompanyEntityId, getUserId} from './StorageService';

let fetchAllCancelToken = undefined;

export const cancelPrevFetchAll = () => {
  fetchAllCancelToken && fetchAllCancelToken();
};

export const fetchAll = async () => {
  try {
    const response = await axiosInstance.post(
      '/Caja_Chica_Gasto/sel',
      {
        nu_id_entidad: getCompanyEntityId(),
      },
      {
        cancelToken: new CancelToken(c => (fetchAllCancelToken = c)),
      },
    );
    return response.data.map(expense => UnresolvedExpense.map(expense));
  } catch (error) {
    if (isCancel(error)) {
      return Promise.reject({ cancelled: true, message: undefined });
    }
    return Promise.reject({ cancelled: false, message: error?.message || 'Network error' });
  }
};
