import React from "react";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={
          "configuration.user.settings.modules.submodules.operations.table.code"
        }
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={
          "configuration.user.settings.modules.submodules.operations.table.description"
        }
      />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
