import React from "react";
import Confirmation from "components/Common/Dialogs/Confirmation";
import IntlMessages from "@jumbo/utils/IntlMessages";

const DeleteConfirmation = ({open, onAccept, onRejet}) => (
  <Confirmation
    open={open}
    onAccept={onAccept}
    onReject={onRejet}
    text={<IntlMessages id="administrator.Products.deleteConfirmationText" />}
  />
);

export default DeleteConfirmation;
