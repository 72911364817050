export const RESET_KPI_STATE = "RESET_KPI_STATE";
export const FETCH_USER_KPI_REPORTS_LISTS = "FETCH_USER_KPI_REPORTS_LISTS";
export const SET_CURRENT_KPI_REPORT = "SET_CURRENT_KPI_REPORT";
export const SET_CURRENT_KPI_REPORT_FILTERS = "SET_CURRENT_KPI_REPORT_FILTERS";
export const SET_CURRENT_KPI_REPORT_FILE = "SET_CURRENT_KPI_REPORT_FILE";
export const RESET_CURRENT_KPI_REPORT = "RESET_CURRENT_KPI_REPORT";
export const REMOVE_FAVORITE_REPORT = "REMOVE_FAVORITE_REPORT";
export const REMOVE_SCHEDULED_REPORT = "REMOVE_SCHEDULED_REPORT";
export const REMOVE_FILED_REPORT = "REMOVE_FILED_REPORT";
export const OPEN_KPI_DIALOG = 'OPEN_KPI_DIALOG';
export const CLOSE_KPI_DIALOG = 'CLOSE_KPI_DIALOG';