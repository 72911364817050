import React from "react";
import FloatingButtonContainer from "../../../../Common/Buttons/FloatingButtonContainer";
import {SpeedDial, SpeedDialIcon} from "@material-ui/lab";
import {StyledSpeedDialAction} from "../../../../../routes/Pages/GuardiansPages/Operative/styles";

const FloatingSpeedDial = ({actions}) => {
  const [ open, setOpen ] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClick = onClick => {
    setOpen(false);
    onClick();
  };
  return (
    <FloatingButtonContainer>
      <SpeedDial
        ariaLabel="Actions"
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(action => (
          <StyledSpeedDialAction
            key={action.key}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={() => handleClick(action.onCLick)}
          />
        ))}
      </SpeedDial>
    </FloatingButtonContainer>
  );
};

export default FloatingSpeedDial;
