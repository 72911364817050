import {
  CLEAR_CURRENT_ACCOUNTING,
  SET_CURRENT_ACCOUNTING
} from "../../types/CustomTypes";
import {
  FETCH_ALL_EXPENSES_BY_ACCOUNTING_STARTED,
  FETCH_ALL_EXPENSES_BY_ACCOUNTING_SUCCESS
} from "../../types/Guardian/AccountingTypes";

const initState = {
  id: "",
  openingDate: "",
  liquidationDate: "",
  tags: [],
  quantity: NaN,
  state: "",
  pettyCash: null,
  expenses: [],
  isLoading: false
};
export default (state = initState, action) => {
  switch (action.type) {
    case SET_CURRENT_ACCOUNTING: {
      return {
        ...state,
        ...action.payload
      };
    }
    case CLEAR_CURRENT_ACCOUNTING: {
      return {
        ...state,
        ...initState
      };
    }
    case FETCH_ALL_EXPENSES_BY_ACCOUNTING_STARTED: {
      return {
        ...state,
        isLoading: true
      };
    }
    case FETCH_ALL_EXPENSES_BY_ACCOUNTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        expenses: [ ...action.payload ]
      };
    }

    default: {
      return state;
    }
  }
};
