import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import AreaList from "./AreaList";
import {getAreas} from "./areasData";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {getUserSubmoduleOperations} from "../../../../../services/UserEntityService";

const AreasGrid = () => {
  const {data: operations} = useFetch(
    getUserSubmoduleOperations,
    {module: 12, submodule: 60},
    {}
  );
  const [ areas, setAreas ] = React.useState(getAreas(operations));
  React.useEffect(
    () => {
      setAreas(getAreas(operations));
    },
    [ operations ]
  );
  const validateGrid = options => {
    const reports = options.map(o => o.reports).flat();
    return reports.length > 0;
  };
  return (
    <GridContainer spacing={2}>
      {validateGrid(areas.location.options) && (
        <Grid item xs={12} md={6} lg={3}>
          <AreaList
            title={areas.location.title}
            options={areas.location.options}
          />
        </Grid>
      )}
      {validateGrid(areas.logistic.options) && (
        <Grid item xs={12} md={6} lg={3}>
          <AreaList
            title={areas.logistic.title}
            options={areas.logistic.options}
          />
        </Grid>
      )}
      {validateGrid(areas.treasury.options) && (
        <Grid item xs={12} md={6} lg={3}>
          <AreaList
            title={areas.treasury.title}
            options={areas.treasury.options}
          />
        </Grid>
      )}
      {validateGrid(areas.tachograph.options) && (
        <Grid item xs={12} md={6} lg={3}>
          <AreaList
            title={areas.tachograph.title}
            options={areas.tachograph.options}
          />
        </Grid>
      )}

      {validateGrid(areas.distribution.options) && (
          <Grid item xs={12} md={6} lg={3}>
            <AreaList
                title={areas.distribution.title}
                options={areas.distribution.options}
            />
          </Grid>
      )}
    </GridContainer>
  );
};

export default AreasGrid;
