import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.logistic.workOrders"} />,
    link: "/logistic/distributions/work-orders"
  },
  {
    label: <IntlMessages id={"sidebar.logistic.distribution.return.types"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id={"logistic.distribution.return.types.title"} />}
    description={
      <IntlMessages id={"logistic.distribution.return.types.description"} />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

export default PageContainer;
