import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  addConfigModule,
  addConfigOperationToSubmodule,
  addConfigSubmoduleToModule,
  fetchAllConfigModules,
  fetchAllConfigOperationsBySubmodule,
  fetchAllConfigSubmodulesByModule
} from "../../../services/Configuration/ConfigModuleService";
import {
  ADD_CONFIG_MODULE,
  ADD_CONFIG_SUBMODULE_OPERATION,
  ADD_CONFIG_SUBMODULE,
  EDIT_CONFIG_MODULE_STATE,
  FETCH_ALL_CONFIG_MODULES,
  RESET_CONFIG_MODULES_STATES,
  RESET_CURRENT_CONFIG_MODULE,
  SET_CURRENT_CONFIG_MODULE,
  SET_CURRENT_CONFIG_SUBMODULE_OPERATIONS,
  SET_CURRENT_CONFIG_SUBMODULES,
  RESET_CURRENT_CONFIG_SUBMODULE_OPERATIONS,
  APPLY_CONFIG_MODULES_FILTERS,
  RESET_CONFIG_MODULES_FILTERS
} from "../../types/Configuration/ModulesTypes";
import Module from "../../../domain/Configuration/Module";
import Operation from "../../../domain/Configuration/Operation";
import Submodule from "../../../domain/Configuration/Submodule";

export const resetConfigurationModulesState = () => ({
  type: RESET_CONFIG_MODULES_STATES
});
export const resetCurrentConfigModule = () => ({
  type: RESET_CURRENT_CONFIG_MODULE
});
export const applyConfigModulesFilters = filters => ({
  type: APPLY_CONFIG_MODULES_FILTERS,
  payload: filters
});
export const resetConfigModulesFilters = () => ({
  type: RESET_CONFIG_MODULES_FILTERS
});

export const fetchAllConfigurationModules = () => {
  return async dispatch => {
    dispatch(fetchStart());
    fetchAllConfigModules()
      .then(modules => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_CONFIG_MODULES, payload: modules});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addConfigurationModule = module => {
  return async dispatch => {
    dispatch(fetchStart());
    addConfigModule(module)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newModule = new Module(
          response.transactionKey,
          module.description,
          []
        );
        dispatch({type: ADD_CONFIG_MODULE, payload: newModule});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const setCurrentConfigurationModule = (module, transaction = 1) => {
  return async dispatch => {
    if (transaction === 1) {
      dispatch(fetchStart());
      fetchAllConfigSubmodulesByModule(module)
        .then(submodules => {
          dispatch(fetchSuccess());
          dispatch({type: SET_CURRENT_CONFIG_MODULE, payload: module});
          dispatch({type: SET_CURRENT_CONFIG_SUBMODULES, payload: submodules});
        })
        .catch(e => {
          dispatch(fetchError(e.message));
        });
    } else {
      dispatch({type: SET_CURRENT_CONFIG_MODULE, payload: module});
    }
  };
};

export const resetCurrentConfigurationSubmodule = () => ({
  type: RESET_CURRENT_CONFIG_SUBMODULE_OPERATIONS
});

export const setCurrentConfigurationSubmodule = submodule => {
  return async dispatch => {
    dispatch(fetchStart());
    fetchAllConfigOperationsBySubmodule(submodule)
      .then(operations => {
        dispatch(fetchSuccess());
        const currentSubmodule = {
            ...submodule,
            operations: operations,
        }
        dispatch({
          type: SET_CURRENT_CONFIG_SUBMODULE_OPERATIONS,
          payload: {submodule: currentSubmodule, operations}
        });
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addConfigurationModuleSubmodule = (module, submodule) => {
  return async dispatch => {
    dispatch(fetchStart());
    addConfigSubmoduleToModule(module, submodule)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newSubmodule = new Submodule(
          response.transactionKey,
          submodule.description,
          false,
          []
        );
        dispatch({type: ADD_CONFIG_SUBMODULE, payload: newSubmodule});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addConfigurationSubmoduleOperation = (submodule, operation) => {
  return async dispatch => {
    dispatch(fetchStart());
    addConfigOperationToSubmodule(submodule, operation)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newOperation = new Operation(
          response.transactionKey,
          operation.description,
          false
        );
        dispatch({type: ADD_CONFIG_SUBMODULE_OPERATION, payload: newOperation});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateConfigurationModuleState = (module, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess());
      dispatch({
        type: EDIT_CONFIG_MODULE_STATE,
        payload: {id: module.id, state}
      });
    }, 1500);
  };
};
