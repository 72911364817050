import {makeStyles} from "@material-ui/styles";

export const useStyles = makeStyles({
  dragButton: {
    minWidth: "auto",
    "&:hover": {
      cursor: "grab"
    },
    "&:focus, &:active, &:focus-within": {
      cursor: "grabbing"
    }
  }
});
