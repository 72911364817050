import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import moment from "moment";
import {updateMonitoringAlertState} from "../../../../../redux/actions/Logistic/Monitoring";

const AlertsTable = () => {
    const {data, filters} = useSelector(state => state.logistic.monitoring.alerts);
    const dispatch = useDispatch();
    return(
        <TableContainer
            head={<TableHeader />}
        >
            {data.map(alert => (
                <StyledTableRow key={alert.id}>
                    <StyledTableCell>{alert.guide}</StyledTableCell>
                    <StyledTableCell>{alert.vehicle}</StyledTableCell>
                    <StyledTableCell>{moment(new Date(alert.date)).format("DD-MM-yyyy HH:mm:ss")}</StyledTableCell>
                    <StyledTableCell>{alert.detail}</StyledTableCell>
                    <StyledTableCell>{alert.read?
                        <></>
                        :(<FindInPageIcon
                            onClick={() => {
                                dispatch(updateMonitoringAlertState(alert.id, filters));
                            }}
                            style={{
                                cursor: "pointer"
                            }}
                        />)}
                    </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableContainer>
    );
}

export default AlertsTable;