import React from "react";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {Grid} from "@material-ui/core";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {exportTransferExcelFile} from "../../../../services/WarehouseTransfer";
import toBase64 from "../../../../utils/files";
import {useDispatch} from "react-redux";
import {fetchError, fetchStart, fetchSuccess} from "../../../../redux/actions";

const ImportDialog = ({open, setOpen}) => {
  const id = "warehouse-transfer-import-dialog";
  const dispatch = useDispatch();
  const [ file, setFile ] = React.useState(null);
  const readFile = async e => {
    const file = e.target.files[0];
    const base64File = await toBase64(file).catch(error => {
      dispatch(fetchError(error.message));
    });
    setFile({
      name: file.name,
      extension: file.name.split(".")[1],
      contentType: file.type,
      base64File: base64File.split(",")[1]
    });
  };

  const onExport = e => {
    e.preventDefault();
    dispatch(fetchStart());
    exportTransferExcelFile(file)
      .then(response => {
        dispatch(fetchSuccess("Archivo Guardado"));
        setOpen && setOpen(false);
      })
      .catch(e => {
        dispatch(fetchError("Error guardando el archivo"));
      });
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"logistic.warehouse.transfer.import.button"} />}
      submitText={<IntlMessages id={"form.button.import"} />}
      cancelText={<IntlMessages id={"form.button.cancel"} />}
      isOpen={open}
      onClose={() => setOpen(false)}
    >
      <form id={id} onSubmit={onExport}>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6}>
            <input
              type="file"
              id="transfer-file"
              multiple={false}
              onChange={readFile}
            />
            <label htmlFor="transfer-file" />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default ImportDialog;
