class RucData {
  constructor(
    businessName,
    document,
    state,
    condition,
    address,
    ubigeo,
    via,
    zone
  ) {
    this.businessName = businessName;
    this.document = document;
    this.state = state;
    this.condition = condition;
    this.address = address;
    this.ubigeo = {
      id: ubigeo
    };
    this.via = via;
    this.zone = zone;
  }

  static map = rucData => {
    return new RucData(
      rucData.nombre,
      {
        id: rucData.tipoDocumento,
        number: rucData.numeroDocumento
      },
      rucData.estado,
      rucData.condicion,
      rucData.direccion,
      rucData.ubigeo,
      {
        id: rucData.viaTipo,
        name: rucData.viaNombre
      },
      {
        code: rucData.zonaCodigo,
        type: rucData.zonaTipo
      }
    );
  };
}

export default RucData;
