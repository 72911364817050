import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";

export default {
  code: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.code.error.empty" />
      )
    }
  ],
  description: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.description.error.empty" />
      )
    },
    {
      rule: v => v.length < 50,
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.description.error.length" />
      )
    }
  ],
  accountingAccount: [
    {
      rule: v => !v.includes(".") && Number.isInteger(Number(v)),
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.accountingAccount.error.notAnInteger" />
      )
    },
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.accountingAccount.error.empty" />
      )
    },
    {
      rule: v => v.length === 9,
      message: (
        <IntlMessages id="administrator.expenseTypes.saveCostTypeDialog.field.accountingAccount.error.length" />
      )
    }
  ]
};
