import React from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Box, Divider, Grid, List, ListItem, ListItemText, Tooltip, Typography} from "@material-ui/core";
import StateSelect from "./StateSelect";
import ReferenceSelect from "./ReferenceSelect";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import moment from "moment";
import {CostumedAssignmentReturnIcon, HistoryButton, LastUpdateText, useStyles} from "./styles";
import DestinationTypeSelect from "../../../DestinationsDialog/DestinationTypeSelect";
import clsx from "clsx";
import {useDispatch} from "react-redux";
import {setCurrentFromExternalView} from "../../../../../../../redux/actions/FleetManagement/VehicleReports";
import DetailDialog from "../../../../../../FleetManagement/DailyOperation/Parts/DetailDialog";
import MoreDetailsDialog from "../../../../../../FleetManagement/DailyOperation/Parts/MoreDetailsDialog";
import PersonIcon from "@material-ui/icons/Person";

const BasicInfoArea = ({formData, setFormData, errors = null, edit, history, typeId, isNew, destinationId}) => {
    const [seeHistory, setSeeHistory] = React.useState(false);;
    const classes = useStyles();
    const dispatch = useDispatch();
    const handleClickHistoryChecklist = checklistId => {
        dispatch(setCurrentFromExternalView(checklistId));
    }
    const getDisableDestinationType = () => {
        if(!edit){
            return !edit;
        }else {
            return !(history?.length === 0);
        }
    }
    return (
        <GridContainer spacing={4}>
            <Grid item xs={12} md={6}>
                <DestinationTypeSelect
                    value={formData.type}
                    setValue={value => setFormData('type', value)}
                    disabled
                />
            </Grid>
            <StateSelect
                typeId={formData.type}
                value={formData.state}
                setValue={value => setFormData('state', value)}
                edit={edit}
                destinationId={destinationId}
            />
            {/*<ActionSelect value={formData.action} setValue={value => setFormData('action', value)} edit={edit}/>*/}
            <ReferenceSelect value={formData.reference} setValue={value => setFormData('reference', value)}
                             edit={isNew}/>
            <Grid item sm={12} md={10}>
                <OutlinedInput
                    id="reference-number-input"
                    value={formData.referenceNumber}
                    onChange={value => setFormData('referenceNumber', value)}
                    disabled={!edit}
                    fullWidth
                    hasError={errors?.referenceNumber?.length > 0}
                    error={errors?.referenceNumber[0]}
                    label={<IntlMessages id={"logistic.work.orders.dialog.destination.reference.number.label"}/>}
                />
            </Grid>
            {history?.length > 0 &&
                <React.Fragment>
                    <Grid item sm={12}>
                        <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <LastUpdateText
                                variant="h6">
                                <IntlMessages
                                    id="logistic.work.orders.dialog.destination.last.update.text"/>:  {moment(history[history.length-1].date).format("DD/MM/yyyy HH:mm")}
                            </LastUpdateText>
                            <HistoryButton
                                onClick={()=> setSeeHistory(!seeHistory)}
                            >
                                <IntlMessages id={"logistic.work.orders.dialog.destination.see.history.text"} />
                            </HistoryButton>
                        </Box>
                    </Grid>
                    {seeHistory && <Grid item sm={12}>
                        <List dense>
                            {history.map((h, index) => (
                                <React.Fragment key={`${index}-history`}>
                                    <ListItem>
                                        <ListItemText>
                                            <Box
                                                className={classes.historyListContainer}
                                            >
                                                <Typography
                                                    className={clsx(classes.historyListItem, classes.text)}
                                                    style={{
                                                        justifyContent:"flex-start"
                                                    }}
                                                    component="div" variant="h5">
                                                    {h.name}
                                                </Typography>
                                                <Box
                                                    className={clsx(classes.historyListItem, classes.icon)}
                                                >
                                                    {h.user &&
                                                        <Tooltip
                                                            title={h.user}
                                                            arrow
                                                            placement='top'
                                                        >
                                                            <PersonIcon />
                                                        </Tooltip>
                                                    }
                                                </Box>
                                                <Box
                                                    className={classes.historyListItem}
                                                >
                                                    {h.inChecklist &&
                                                        <CostumedAssignmentReturnIcon
                                                            color="primary"
                                                            onClick={() => handleClickHistoryChecklist(h.inChecklist)}
                                                        />}
                                                </Box>
                                                <Box
                                                    className={clsx(classes.historyListItem)}
                                                >
                                                    {h.outChecklist &&
                                                        <CostumedAssignmentReturnIcon
                                                            onClick={() => handleClickHistoryChecklist(h.outChecklist)}
                                                            className={clsx(classes.outIcon)}
                                                            color="primary"/>
                                                    }
                                                </Box>
                                                <Typography
                                                    className={clsx(classes.historyListItem, classes.date)}
                                                    style={{
                                                        justifyContent:"flex-end"
                                                    }}
                                                    component="div" variant="h5">{moment(h.date).format("DD/MM/yyyy HH:mm")}</Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>}
                </React.Fragment>
            }
            <DetailDialog />
            <MoreDetailsDialog />
        </GridContainer>
    );
}

export default BasicInfoArea;