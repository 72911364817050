import React from "react";

import IntlMessages from "@jumbo/utils/IntlMessages";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 0,
    marginBottom: 0
  }
}));

const SupervisorsAutocomplete = ({
  hasError,
  error,
  options,
  value,
  onChange
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      size="small"
      value={value}
      options={options}
      autoComplete
      getOptionSelected={(option, value) => option.id === value.id}
      id="supervisors-autocomplete"
      getOptionLabel={option => option.name}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={params => (
        <TextField
          {...params}
          error={hasError}
          helperText={error}
          classes={classes}
          label={
            <IntlMessages id="administrator.form.supervisorsAutocomplete.label" />
          }
          margin="normal"
          variant="outlined"
        />
      )}
    />
  );
};

export default SupervisorsAutocomplete;
