import {
  FETCH_HISTORICALSINGING_SUCCEEDED,
  FETCH_DRIVER_SELECT
} from "../../types/RRHH/HistoricalSigning";

import moment from "moment";

const today = moment(Date.now()).format("yyyy-MM-DD");

let date = new Date();

const INIT_STATE = {
  data: [],
  filters: {
    startDate: moment(
      new Date(new Date(date.setDate(date.getDate() - 7)).toString())
    ).format("yyyy-MM-DD"),
    endDate: today,
    name: "",
    drivers: []
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_HISTORICALSINGING_SUCCEEDED: {
      return {
        ...state,
        data: action.payload.data
      };
    }
    case FETCH_DRIVER_SELECT:
      return {
        ...state,
        filters: {...state.filters, drivers: action.payload.data}
      };

    default: {
      return state;
    }
  }
};
