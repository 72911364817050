import {mapToBoolean} from "../../utils/mappers";

class ElevatorState {
  constructor(id, name, state) {
    this.id = id;
    this.name = name;
    this.state = state;
  }

  static map = state => {
    return new ElevatorState(
      state.nu_id_tipo_estado,
      state.vc_desc_tipo_estado,
      mapToBoolean(state.bi_estado)
    );
  };
}

export default ElevatorState;
