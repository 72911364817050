import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import ElevatorState from "../../domain/ElevatorsManagement/ElevatorState";

export const getAllElevatorsStates = async filters => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Estado/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(state => ElevatorState.map(state));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createElevatorState = async state => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Estado/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_estado: state.name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorState = async (state, name) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Estado/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_estado: state.id,
      vc_desc_tipo_estado: name
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateElevatorStateState = async (state, status) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Estado/del", {
      nu_id_tipo_estado: state.id,
      bi_estado: status
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
