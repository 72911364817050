import React from "react";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import AverageFilters from "../AverageReport/FIlters";
import ConsolidatedFilter from "../ConsolidatedReport/FIlters";
import ExpensesFilter from "../ExpensesReport/FIlters";
import KilometerFilter from "../KilometerReport/FIlters";
import {useSelector} from "react-redux";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";

const Filters = () => {
  const options = [
    {
      id: 1,
      name: <IntlMessages id="administrator.expenses.reports.title" />,
      render: <ExpensesFilter onClose={() => {}} />
    },
    {
      id: 2,
      name: <IntlMessages id="administrator.consolidated.reports.title" />,
      render: <ConsolidatedFilter onClose={() => {}} />
    },
    {
      id: 3,
      name: <IntlMessages id="administrator.average.reports.title" />,
      render: <AverageFilters onClose={() => {}} />
    },
    {
      id: 4,
      name: <IntlMessages id="administrator.kilometer.reports.title" />,
      render: <KilometerFilter onClose={() => {}} />
    }
  ];
  const {type: selected} = useSelector(
    ({controlPanel}) => controlPanel.kpi.current
  );
  const renderFilter = () =>
    options.find(option => option.id === selected).render;
  const getTitle = () => options.find(option => option.id === selected).name;

  return (
    <React.Fragment>
      <Box my={4}>
        <Typography variant="h2">{getTitle()}</Typography>
      </Box>
      {renderFilter()}
    </React.Fragment>
  );
};

export default Filters;
