import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const AddHeadquarterDialog = ({isOpen, onClose, onSubmit}) => {
  return (
    <FormDialog
      id="add-headquarter-form"
      isOpen={isOpen}
      title={
        <IntlMessages id="administrator.clients.add.headquarter.form.title" />
      }
      onClose={onClose}
    >
      <Form id={"add-headquarter-form"} onSubmit={onSubmit} />
    </FormDialog>
  );
};

export default AddHeadquarterDialog;
