import React from "react";
import ClientSelect from "../../../../../../../ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog/ElevatorInformation/ClientSelect";
import {Grid} from "@material-ui/core";
import HeadquarterPicker from "./HeadquarterPicker";

const ClientsHeadquarterSelect = ({id, values, onChange, errors}) => {
  const [ client, setClient ] = React.useState("");
  return (
    <React.Fragment>
      <Grid item xs={12} sm={4} lg={3}>
        <ClientSelect
          id={`${id}-select`}
          value={client}
          setValue={value => setClient(value)}
        />
      </Grid>
      <Grid item xs={12} sm={4} lg={3}>
        <HeadquarterPicker
          id={`${id}-picker`}
          values={values}
          onChange={value => onChange(value)}
          errors={errors}
          client={client}
        />
      </Grid>
    </React.Fragment>
  );
};

export default ClientsHeadquarterSelect;
