import {createSlice} from "@reduxjs/toolkit";
import {getIndexFiles} from "../../thunks/BackOffice/indexFile";

const INIT_DATA = {
    data: [],
    currentIndexFile: null
}


const indexFilesSlice = createSlice({
    name: 'indexFiles',
    initialState: {
        ...INIT_DATA
    },
    reducers: {
        setCurrentIndexFile: (state, action) => {
            state.currentIndexFile = {
                ...action.payload
            }
        },
        resetState: (state, action) => {
            state = state.initialState;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getIndexFiles.fulfilled, (state, action) => {
            state.data = action.payload.data;
        })
    }
});


export default indexFilesSlice.reducer;

export const {setCurrentIndexFile} = indexFilesSlice.actions;
