import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import {Grid} from "@material-ui/core";
import useForm from "../../../../../hooks/Common/useForm";

const NameForm = ({id, inputLabel, onSave}) => {
  const {formData, setFormData, handleSubmit} = useForm({
    name: ""
  });
  return (
    <form id={id} onSubmit={handleSubmit(onSave)}>
      <GridContainer>
        <Grid item xs={12}>
          <OutlinedInput
            id={`${id}-name-input`}
            value={formData.name}
            onChange={value => setFormData("name", value)}
            fullWidth
            label={inputLabel}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default NameForm;
