import moment from "moment";

class FilterBuilder {
  constructor(data) {
    this.data = [ ...data ];
  }

  byFieldIncludes(field, value) {
    if (value === undefined || value === null)
      return new FilterBuilder(this.data);
    const filteredData = this.data.filter(e => {
      if (e[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      return e[field]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    });
    return new FilterBuilder(filteredData);
  }

  byFieldEquals(field, value) {
    if (value === undefined || value === null)
      return new FilterBuilder(this.data);
    const filteredData = this.data.filter(e => {
      if (e[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      if (value.toString() === "") {
        return true;
      }
      return (
        e[field].toString().toLowerCase() === value.toString().toLowerCase()
      );
    });
    return new FilterBuilder(filteredData);
  }

  byDateFrom(field, value) {
    if (value === undefined || value === null)
      return new FilterBuilder(this.data);
    const filteredData = this.data.filter(e => {
      if (e[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      return (
        moment(e[field]).set({hour: 0, minute: 0, second: 0, millisecond: 0}) >=
        moment(value)
      );
    });
    return new FilterBuilder(filteredData);
  }

  byDateTo(field, value) {
    if (value === undefined || value === null)
      return new FilterBuilder(this.data);
    const filteredData = this.data.filter(e => {
      if (e[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      return (
        moment(e[field]).set({hour: 0, minute: 0, second: 0, millisecond: 0}) <=
        moment(value)
      );
    });
    return new FilterBuilder(filteredData);
  }

  byFieldBetweenDates(field, from, to) {
    let builder = this.byDateFrom(field, from);
    return builder.byDateTo(field, to);
  }

  byFieldIncludesMultiOptions(field, value) {
    if (value === undefined || value === null || value.length === 0)
      return new FilterBuilder(this.data);
    const filteredData = this.data.filter(e => {
      if (e[field] === undefined) {
        throw new Error("Field parameter must be a valid property");
      }
      return value.some(el => e[field].includes(el));
    });
    return new FilterBuilder(filteredData);
  }

  build() {
    return this.data;
  }
}

export default FilterBuilder;
