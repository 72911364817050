import React from 'react';
import Select from "../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const StateSelect = ({value, onChange, disabled=false}) => {
    const states = [
        {
            id: 0,
            name: <IntlMessages id="all" />
        },
        {
            id: 1,
            name: <IntlMessages id="active" />
        },
        {
            id: 2,
            name: <IntlMessages id="inactive" />
        }
    ]
    return (
        <Select
            fullWidth
            label={<IntlMessages id="state" />}
            value={value}
            onChange={e => onChange(e.target.value)}
            disabled={disabled}
        >
            {states.map(state => (
                <MenuItem key={state.id} value={state.id}>
                    {state.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default StateSelect;