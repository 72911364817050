import React from "react";
import Dashboard from "../../../../components/RRHH/Employees/Dashboard";
import PageContainer from "../../../../@jumbo/components/PageComponents/layouts/PageContainer";

const Employees = () => (
  <PageContainer>
    <Dashboard />
  </PageContainer>
);

export default Employees;
