import React from "react";
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {getAllVehiclesTypes} from "../../../../../../../../services/FleetManagement/Vehicles/VehicleTypeService";

const TypeSelect = ({value, onChange, error, hasError, disabled}) => {
  const [ types, setTypes ] = React.useState([]);
  React.useEffect(() => {
    getAllVehiclesTypes()
      .then(data => setTypes(data))
      .catch(e => console.error(e.message));
  }, []);
  return (
    <Select
      id="vehicle-type-select"
      label={
        <IntlMessages
          id={"fleet.management.vehicles.specifications.details.basic.type"}
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      error={hasError}
      errorMessage={error}
      fullWidth
      disabled={disabled}
    >
      <MenuItem value={""} />
      {types.map(type => (
        <MenuItem value={type.id} key={type.id}>
          {type.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TypeSelect;
