class EventSelect {
  constructor(id, event, active) {
    this.id = id;
    this.name = event;
    this.active = active;
  }

  static map = events => {
    return new EventSelect(events.EventId, events.EventName, events.EventState);
  };
}

export default EventSelect;
