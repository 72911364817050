import moment from "moment";
import {
  ADD_TIRE,
  APPLY_TIRES_FILTERS,
  EDIT_TIRE,
  FETCH_ALL_TIRES,
  RESET_TIRES_FILTERS,
  RESET_TIRES_STATE,
  SET_CURRENT_TIRE
} from "../../types/FleetManagement/TiresTypes";

const INIT_STATE = {
  data: [],
  filters: {
    identification: "",
    active: "",
    purchaseDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
    brand: "",
    measures: "",
    retreading: "",
    pressure: "",
    vehicle: ""
  },
  current: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case APPLY_TIRES_FILTERS: {
      return {
        ...state,
        filters: {
          ...action.payload
        }
      };
    }
    case RESET_TIRES_FILTERS: {
      return {
        ...state,
        filters: {
          ...INIT_STATE.filters
        }
      };
    }
    case FETCH_ALL_TIRES: {
      return {
        ...state,
        data: action.payload
      };
    }
    case SET_CURRENT_TIRE: {
      return {
        ...state,
        current: action.payload
      };
    }
    case ADD_TIRE: {
      return {
        ...state,
        data: [
          ...state.data,
          {
            id: state.data.length + 1,
            ...action.payload
          }
        ]
      };
    }
    case EDIT_TIRE: {
      return {
        ...state,
        data: state.data.map(d => {
          if (d.id === action.payload.id) {
            return action.payload;
          }
          return d;
        })
      };
    }
    case RESET_TIRES_STATE: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default: {
      return state;
    }
  }
};
