import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../actions";
import {
    createCostCenterType, updateCostCenterTypeState,
    getAllCostCenterTypes,
    updateCostCenterType
} from "../../../services/Treasury/CostCenterTypeService";
import {cloneDeep} from "lodash";

export const fetchAllCostCenterTypes = createAsyncThunk(
    'costCenterType/fetchAllCostCenterTypes',
    async (_, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const costCenters = await getAllCostCenterTypes();
            dispatch(fetchSuccess());
            return {costCenters};
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const addCostCenterTypes = createAsyncThunk(
    'costCenterType/addCostCenterTypes',
    async ({request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const {transactionMessage, transactionKey} = await createCostCenterType({
                ...request,
            });
            dispatch(fetchSuccess(transactionMessage));
            return {
                id: transactionKey,
                active: true,
                ...request,
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const editCostCenterTypes = createAsyncThunk(
    'costCenterType/editCostCenterTypes',
    async ({currentCostCenter, request}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const {transactionMessage} = await updateCostCenterType({
                ...currentCostCenter,
                ...request,
            });
            dispatch(fetchSuccess(transactionMessage));
            return {
                ...cloneDeep(currentCostCenter),
                description: request.description,
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

export const editCostCenterTypeState = createAsyncThunk(
    'costCenterType/editCostCenterTypeState',
    async ({currentCostCenter}, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const {transactionMessage} = await updateCostCenterTypeState(currentCostCenter.id);
            dispatch(fetchSuccess(transactionMessage));
            return {
                ...currentCostCenter,
                active: !currentCostCenter.active,
            };
        }catch (e) {
            dispatch(fetchError(e.message));
            return rejectWithValue(e.message);
        }
    }
);

