class CampusOriginSelect {
  constructor(id, campus, active) {
    this.id = id;
    this.campus = campus;
    this.active = active;
  }

  static map = campus => {
    return new CampusOriginSelect(
      campus.nu_id_entidad_sede,
      campus.vc_nombre_sede,
      campus.bi_estado === "1"
    );
  };
}

export default CampusOriginSelect;
