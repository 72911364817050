import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="fleet.management.vehicles.specifications.table.alias" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="fleet.management.vehicles.specifications.table.plaque" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="fleet.management.vehicles.specifications.table.chassis" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="fleet.management.vehicles.specifications.table.brand" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="fleet.management.vehicles.specifications.table.model" />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
