import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeDialog, openDialog} from "../../../redux/reducers/Treasury/bills";
import Box from "@material-ui/core/Box";
import ContainedButton from "../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import {createBill} from "../../../redux/thunks/Treasury/bills";
import {dialogsList} from "./constants";
import BillForm from "../common/BillForm";

const AddDialog = () => {
    const id = 'add-bill-form';
    const {dialogs} = useSelector(({treasury}) => treasury.bills);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.ADD_BILL);
    const handleAddDialog = () => {
        dispatch(openDialog(dialogsList.ADD_BILL));
    };
    const onSubmit = (request) => {
        dispatch(createBill({request}))
            .unwrap()
            .then(() => onClose())
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.ADD_BILL));
    }
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginBottom: 16,
                }}
            >
                <ContainedButton
                    text={<IntlMessages id="add"/>}
                    color="primary"
                    onClick={handleAddDialog}
                />
            </Box>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="add"/>}
                fullScreen
            >
                {open && <BillForm id={id} onSubmit={onSubmit} isAdd />}
            </FormDialog>
        </>
    );
};

export default AddDialog;