import React from "react";
import { useSelector } from "react-redux";
import Select from "components/Common/Forms/Select";

const RouteSelect = ({id, label, hasError, error, value, onChange}) => {
    if(value?.value != undefined){value = value.value}
    const {route} = useSelector(state => state.administrator.routeSelect);

    const options = [
        {value: '', label: 'Seleccione una opción'},
        ...route.map(type => ({value: type.id, label: type.route})),
    ];

    return(
        <Select
            value={value}
            error={hasError}
            errorMessage={error}
            fullWidth
            id={id}
            label={label}
            onChange={e => onChange(options.find(option => option.value === e.target.value))}
            options={options}
        />
    );
};

export default RouteSelect;