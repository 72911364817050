import React from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import RestoreIcon from "@material-ui/icons/Restore";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import {setAdvancesListFilter} from "../../../../redux/actions/Guardians/AdvancesListFilter";
import PendingStates from "../../../../models/Guardian/PendingStates";
import AlarmOnIcon from "@material-ui/icons/AlarmOn";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";
import FloatingSpeedDial from "../../Common/Buttons/SpeedDial";

const FloatingButton = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const actions = [
    {
      icon: <AddBoxIcon />,
      name: <IntlMessages id={"sidebar.add.expenses"} />,
      key: "sidebar.add.expenses",
      onCLick: () => history.push("/treasury/cash-management/add-expenses")
    },
    // {
    //   icon: <RestoreIcon />,
    //   name: <IntlMessages id={'send.refund'} />,
    //   key: 'send.refund',
    //   onCLick: () => history.push('/treasury/cash-management/accounting-list'),
    // },
    {
      icon: <AddCircleIcon />,
      name: <IntlMessages id={"assign.advance"} />,
      key: "assign.advance",
      onCLick: () => history.push("/treasury/cash-management/add-advances")
    }
  ];

  return <FloatingSpeedDial actions={actions} />;
};

export default FloatingButton;
